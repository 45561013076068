import React from 'react'

import { ToolsDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

export default function TableExpanded(props) {
  const { data: _data, isLoading } = props

  const { formatAnoMes, formatIdfReciboPagamento, formatCurrency } = useUtils()

  const data = _data.map((item) => {
    return {
      ...item,
      fatoGerador: item?.fatoGerador ? formatAnoMes(item.fatoGerador) : null,
      anoMes: item.anoMes ? formatAnoMes(item.anoMes) : null,

      idf: item?.idf ? formatIdfReciboPagamento(item.idf) : null,
      vrIRRF: item?.vrIRRF ? formatCurrency(item.vrIRRF) : null,
      dtImpressao: item?.dtImpressao ? moment(item.dtImpressao).format('DD/MM/YYYY') : null,
    }
  })

  const columns = [
    {
      name: 'fatoGerador',
      label: 'Fato Gerador',
    },
    {
      name: 'anoMes',
      label: 'Ano / Mês',
    },
    {
      name: 'idf',
      label: 'Identificação',
    },
    {
      name: 'dtImpressao',
      label: 'Data da Impressão',
    },
    {
      name: 'vrIRRF',
      label: 'Valor do Imposto Retido',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
  ]

  return <ToolsDataTable data={data} isLoading={isLoading} columns={columns} />
}
