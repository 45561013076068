import { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'

import { PageHeader, TreeView } from '~/components'

import Content from './Content'

import useAmbiente from '~/hooks/useAmbiente'

import { VinculoMenuItem, useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

export default function VinculoFaltas() {
  const [vinculo, setVinculo] = useState<VinculoMenuItem | null>(null)

  const { estabelecimento, anoMes } = useAmbiente()

  useEffect(() => {
    setVinculo(null)
  }, [estabelecimento, anoMes])

  const {
    data: dataMenu,
    isLoading: isLoadingMenu,
    isFetching: isFetchingMenu,
  } = useEstabelecimentoVinculosMenu([VinculoTipoEnum.Funcionario_1, VinculoTipoEnum.Estagiario_3])

  return (
    <Box height="100%" width="100%" padding={1}>
      <TreeView
        data={dataMenu}
        onSelectItem={(_, vinculo: VinculoMenuItem) => setVinculo(vinculo)}
        searchBy="vinculoNome"
        renderOption={(option: VinculoMenuItem) => `${option.vinculoNome}`}
        isLoading={isLoadingMenu}
        isLoadingOnClick={isFetchingMenu}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <PageHeader title="Faltas" subtitle={vinculo?.vinculoNome || '-'} />
          <Box flex={1}>
            {vinculo ? (
              <Content vinculoId={vinculo?.vinculoId} />
            ) : (
              <Typography>Selecione um Vínculo</Typography>
            )}
          </Box>
        </Box>
      </TreeView>
    </Box>
  )
}
