import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker } from '~/components'
import {
  AutoCompleteCargo,
  AutoCompleteDepartamento,
  AutoCompleteSetor,
} from '~/components/AutoComplete'

import moment from 'moment'
import * as yup from 'yup'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

const schema = yup.object().shape({
  dtLocal: yup
    .date()
    .required('Informe Data')
    .typeError('Informe uma data válida')
    .nullable()
    .when('$dtAdmissao', (dtAdmissao, schema) => {
      return schema.test('testData', 'Informe uma Data maior que a Data de Admissão', (dtLocal) => {
        if (!dtAdmissao || !dtLocal) return false
        return moment(dtAdmissao).isSameOrBefore(dtLocal)
      })
    }),
  cargo: yup.string().required('Informe Cargo').nullable(),
})

const Form = (props) => {
  const {
    isOpen,
    onClose,
    data: _data,
    onAfterSubmitForm,
    vinculo,
    isFinalizado,
    isInitialRegister,
  } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
    if (isInitialRegister && !_data?.id) {
      setData({
        ..._data,
        dtLocal: vinculo?.dtAdmissao,
      })
    }
  }, [_data, isInitialRegister, vinculo])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/vinculoLocal/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/vinculoLocal', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: {
        dtAdmissao: vinculo?.dtAdmissao,
      },
    },
  })

  return (
    <ActionDialog
      title="Cadastro de Ambiente de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePicker
            label="Data"
            size="small"
            value={data?.dtLocal || null}
            required
            validationErrors={validationErrors}
            name="dtLocal"
            onChange={(date) => {
              const dtLocal = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtLocal })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data?.observacao || ''}
            variant="outlined"
            size="small"
            multiline
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteDepartamento
            onChange={(e, departamento) => {
              const departamentoId = departamento ? departamento.id : ''
              setData({ ...data, departamentoId, departamento })
            }}
            value={data?.departamento || null}
            empregadorId={vinculo?.estabelecimento?.empregadorId}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteSetor
            onChange={(e, setor) => {
              const setorId = setor ? setor.id : ''
              setData({ ...data, setorId, setor })
            }}
            value={data?.setor || null}
            empregadorId={vinculo?.estabelecimento?.empregadorId}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteCargo
            validationErrors={validationErrors}
            name="cargo"
            required
            onChange={(e, cargo) => {
              const cargoId = cargo ? cargo.id : ''
              setData({ ...data, cargoId, cargo })
            }}
            value={data?.cargo || null}
            empregadorId={vinculo.estabelecimento?.empregadorId}
            disabled={isFinalizado}
            title={
              isFinalizado ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho' : ''
            }
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
