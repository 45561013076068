import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'

import { Button, ButtonBox, Finder, PageHeader, TreeView } from '~/components'

import Table from './Table'
import FormCreate from './FormCreate'
import FormUpdate from './FormUpdate'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

export default function ProcessoTrabalhistaFuncionario() {
  const [funcionario, setFuncionario] = useState<VinculoConsulta | null>(null)
  const [query, setQuery] = useState('')

  const { estabelecimento } = useAmbiente()
  const { close: closeFormCreate, isOpen: isOpenFormCreate, open: openFormCreate } = useDialog(null)
  const {
    close: closeFormUpdate,
    isOpen: isOpenFormUpdate,
    open: openFormUpdate,
    data: dataFormUpdate,
  } = useDialog('')

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    estabelecimentoId: estabelecimento.id,
  })
  const _d = _data || []
  const dataMenu = [
    {
      groupName: 'Funcionários',
      data: _d,
    },
  ]
  const isLoadingMenu = isLoading || isFetching

  useEffect(() => {
    setFuncionario(null)
  }, [_data])

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => setFuncionario(vinculo)}
      searchBy="pessoaNome"
      renderOption={(option) => `${option.pessoaNome}`}
      isLoading={isLoadingMenu}
    >
      <PageHeader
        title="Processo Trabalhista Funcionário S-2500"
        subtitle={funcionario ? funcionario.pessoaNome : '-'}
      >
        {funcionario && (
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <Button variant="contained" onClick={() => openFormCreate(null)}>
              Adicionar
            </Button>
          </ButtonBox>
        )}
      </PageHeader>
      {funcionario ? (
        <Table query={query} funcionario={funcionario} handleClickEdit={openFormUpdate} />
      ) : (
        <Typography>Selecione um Funcionário</Typography>
      )}

      {isOpenFormCreate && (
        <FormCreate
          isOpen={isOpenFormCreate}
          onClose={closeFormCreate}
          vinculoId={funcionario?.id || ''}
        />
      )}

      {isOpenFormUpdate && dataFormUpdate && (
        <FormUpdate
          isOpen={isOpenFormUpdate}
          onClose={closeFormUpdate}
          processoTrabalhistaVinculoId={dataFormUpdate}
        />
      )}
    </TreeView>
  )
}
