const menus = [
  {
    label: 'Painel',
    link: '/painel',
    icon: 'dashboard',
  },
  {
    label: 'Cadastros',
    icon: 'storage',
    itens: [
      {
        label: 'Pessoa Física',
        link: '/pessoa-fisica',
      },
      {
        label: 'Empregador',
        link: '/empregador',
      },
      {
        label: 'Estabelecimento',
        link: '/estabelecimento',
      },
      {
        label: 'Lotação',
        link: '/lotacao',
      },
      {
        label: 'Departamento',
        link: '/departamento',
      },
      {
        label: 'Cargo',
        link: '/cargo',
      },
      {
        label: 'Setor',
        link: '/setor',
      },
      {
        label: 'Evento',
        link: '/evento',
      },
      {
        label: 'Evento Empregador',
        link: '/evento-empregador',
      },
      {
        label: 'Horário de Trabalho',
        link: '/horario-trabalho',
      },
      {
        label: 'Grupo',
        link: '/grupo',
      },
      {
        label: 'Sindicato',
        link: '/sindicato',
      },
      {
        label: 'Auxiliares Cadastro',
        link: '/auxiliares-cadastro',
      },
    ],
  },
  {
    label: 'Funcionário',
    icon: 'people',
    itens: [
      {
        label: 'Admissão Preliminar',
        link: '/admissao-preliminar',
      },
      {
        label: 'Cadastro',
        link: '/funcionario',
      },
      {
        label: 'Faltas',
        link: '/vinculo-faltas',
      },
      {
        label: 'Férias',
        link: '/vinculo-ferias',
      },
      {
        label: 'Afastamentos',
        link: '/vinculo-afastamento',
      },
      {
        label: 'Lotação Funcionário',
        link: '/vinculo-lotacao',
      },
      {
        label: 'Eventos Automáticos Funcionário',
        link: '/vinculo-evento-automatico',
      },
      {
        label: 'Processo Trabalhista',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Processo Trabalhista',
            link: '/processo-trabalhista',
          },
          {
            label: 'Processo Trabalhista Funcionário S-2500',
            link: '/processo-trabalhista-funcionario',
          },
          {
            label: 'Processo Trabalhista Tributos S-2501',
            link: '/processo-trabalhista-tributos',
          },
          {
            label: 'Processo Trabalhista Manutenção Cadastro',
            link: '/processo-trabalhista-manutencao-cadastro',
          },
          {
            label: 'Processo Trabalhista Rescisão Zerada',
            link: '/processo-trabalhista-rescisao-zerada',
          },
        ],
      },
      {
        label: 'Estabilidade',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Estabilidade',
            link: '/Estabilidade',
          },
          {
            label: 'Estabilidade por Funcionário',
            link: '/vinculo-estabilidade',
          },
        ],
      },
      {
        label: 'Controle de Feriado',
        link: '/controle-feriado',
      },
      {
        label: 'Auxiliares Funcionário',
        link: '/auxiliares-funcionario',
      },
    ],
  },
  {
    label: 'Trabalhadores sem vínculo',
    icon: 'people_outline',
    itens: [
      {
        label: 'Sócios/Diretores',
        link: '/socios-diretores',
      },
      {
        label: 'Autônomo',
        link: '/autonomo',
      },
      {
        label: 'Cooperado',
        link: '/cooperado',
      },
      {
        label: 'Estagiário',
        link: '/estagiario',
      },
      {
        label: 'Lotação TSV',
        link: '/vinculo-lotacao/tsv',
      },
      {
        label: 'Eventos Automáticos TSV',
        link: '/vinculo-evento-automatico/tsv',
      },
    ],
  },
  {
    icon: 'assignment',
    label: 'Alteração Contrato de Trabalho',
    link: '/alteracao-contratual',
  },
  {
    icon: 'assignment_ind',
    label: 'Alteração Cadastral',
    link: '/alteracao-cadastral',
  },
  {
    label: 'Tabelas de Incidências',
    icon: 'toc',
    itens: [
      {
        label: 'Tabela Mês',
        link: '/tabela-mes',
      },
      {
        label: 'Salário Mínimo',
        link: '/salario-minimo',
      },
      {
        label: 'Salários',
        link: '/salario',
      },
      {
        label: 'Salário Família',
        link: '/tabelaSF',
      },
      {
        label: 'INSS',
        link: '/tabelaINSS',
      },
      {
        label: 'Imposto de Renda',
        link: '/tabelaIR',
      },
      {
        label: 'Índice',
        link: '/indice',
      },
      {
        label: 'Terceiros',
        link: '/terceiros',
      },
    ],
  },
  {
    label: 'Processamentos',
    icon: 'tune',
    itens: [
      {
        label: 'Competência',
        link: '/processar-competencia',
      },
      {
        label: 'CNAB',
        link: '/gerar-cnab',
      },
      {
        label: 'DIRF',
        link: '/dirf',
      },
      {
        label: 'Folha Automática',
        link: '/folha-automatica',
      },
      {
        label: 'Processar Alteração de Salário(2206)',
        link: '/processar-alteracao-salario',
      },
    ],
  },
  {
    label: 'Recibo Pagamento',
    icon: 'payment',
    itens: [
      {
        label: 'Recibos',
        link: '/recibo-pagamento/mensal',
      },
      {
        label: 'Consultar Recibos',
        link: '/recibo-consulta',
      },
      {
        label: 'Consultar Recibos Estabelecimento',
        link: '/recibo-consulta-estabelecimento',
      },
      {
        label: 'Consulta Lançamento Autônomo',
        link: '/autonomos-lancamento',
      },
      {
        label: 'Lançamento Coletivo',
        link: '/lancamento-coletivo',
      },
      {
        label: 'Ponto Eletrônico',
        link: '/ponto-eletronico',
      },
      {
        label: 'Lançamento Folha de Ponto',
        link: '/lancamento-folha-ponto',
      },
      {
        label: 'Antecipação Salarial',
        link: '/antecipacao-salarial',
      },
      {
        label: 'Adiantamento de Salário',
        link: '/adiantamento-salario',
      },
      {
        label: 'Manutenção de Atividade Concomitante',
        link: '/manutencao-atividade-concomitante',
      },
      {
        label: 'Finalizar Recibos',
        link: '/finalizar-recibos',
      },
      {
        label: 'Ajuste Convenção Coletiva',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Acordo',
            link: '/acordo-dissidio-convencao',
          },
          {
            label: 'Apurar Convenção Coletiva',
            link: '/apurar-convencao-coletiva',
          },
          {
            label: 'Processar Convenção Coletiva',
            link: '/processar-convencao-coletiva',
          },
        ],
      },
      {
        label: 'Importação de Planilhas',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Importação de Eventos',
            link: '/importacao-eventos',
          },
          {
            label: 'Importação de Faltas',
            link: '/importacao-faltas',
          },
        ],
      },
      {
        label: 'Recibo Autônomo',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Recibos',
            link: '/recibo-pagamento/autonomo',
          },
          {
            label: 'Manutenção Autônomo',
            link: '/manutencao-autonomo',
          },
        ],
      },
      {
        label: 'Recibo Intermitente',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Convocações',
            link: '/trabalho-intermitente',
          },
          {
            label: 'Recibo',
            link: '/recibo-pagamento/intermitente',
          },
          {
            label: 'Rescisão',
            link: '/recibo-pagamento/intermitente-rescisao',
          },
        ],
      },
    ],
  },
  {
    label: 'Férias',
    icon: 'place',
    itens: [
      {
        label: 'Recibo de Férias',
        link: '/recibo-pagamento/ferias',
      },
      {
        label: 'Programação de Férias',
        link: '/programacao-ferias',
      },
      {
        label: 'Aviso de Férias',
        link: '/aviso-ferias',
      },
      {
        label: 'Comunicado de Perda do Direito a Férias',
        link: '/comunicado-perda-direito-ferias',
      },
      {
        label: 'Requerimento de Abono Pecuniário',
        link: '/requerimento-abono-pecuniario',
      },
      {
        label: 'Férias Coletiva',
        link: '/ferias-coletiva',
      },
    ],
  },
  {
    label: 'Rescisão',
    icon: 'exit_to_app',
    itens: [
      {
        label: 'Termo de Rescisão',
        link: '/recibo-pagamento/rescisao',
      },
      {
        label: 'Aviso Prévio',
        link: '/aviso-previo',
      },
      {
        label: 'Seguro Desemprego',
        link: '/seguro-desemprego',
      },
      {
        label: 'Gerar GRRF',
        link: '/gerar-grrf',
      },
      {
        label: 'Rescisão Simulada',
        link: '/rescisao-simulada',
      },
      {
        label: 'Comunicados Contratos de Experiência',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Rescisão Antecipada Contrato Experiência',
            link: '/rescisao-contrato-experiencia',
          },
          {
            label: 'Término Contrato Experiência',
            link: '/comunicado-termino-experiencia',
          },
        ],
      },
      {
        label: 'Rescisão TSV',
        link: '/recibo-pagamento/rescisao-tsv',
      },
      {
        label: 'Rescisão Complementar',
        link: '/rescisao-complementar',
      },
    ],
  },
  {
    label: 'eSocial',
    icon: 'description',
    itens: [
      {
        label: 'Transmissão eSocial',
        link: '/transmissao-esocial/0',
      },
      {
        label: 'Transmissão eSocial Antiga',
        link: '/transmissao-esocial-old',
      },
      {
        label: 'Exclusão de Eventos',
        link: '/exclusao-eventos-esocial',
      },
      {
        label: 'Reabertura Movimento eSocial',
        link: '/reabertura-movimento-esocial',
      },
      {
        label: 'Apuração de Encargos',
        link: '/apuracao-encargos',
      },
      {
        label: 'Previdência por Beneficiário',
        link: '/previdencia-beneficiario',
      },
      {
        label: 'FGTS por Beneficiário',
        link: '/fgts-beneficiario',
      },
      {
        label: 'IRRF por Beneficiário',
        link: '/irrf-beneficiario',
      },
      {
        label: 'Informação Complementar',
        link: '/informacao-complementar-empregador',
      },
      {
        label: 'Contratação Trabalhador Avulso',
        link: '/contratacao-trabalhador-avulso',
      },
      {
        label: 'Envio de Eventos Automáticos',
        link: '/envio-eventos-automaticos',
      },
      {
        label: 'Fechamento eSocial',
        link: '/fechamento-esocial',
      },
    ],
  },
  {
    label: 'DCTFWeb',
    icon: 'account_balance',
    itens: [
      {
        label: 'Documento de Arrecadação',
        link: '/documento-arrecadacao',
      },
    ],
  },
  {
    label: 'Benefícios',
    icon: 'money_off',
    itens: [
      {
        label: 'Empréstimo',
        link: '/vinculo-emprestimo',
      },
      {
        label: 'Vale Transporte',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Transportadora',
            link: '/vale-transporte-transportadora',
          },
          {
            label: 'Configuração por Funcionário',
            link: '/vale-transporte-funcionario',
          },
          {
            label: 'Apuração do Vale',
            link: '/vale-transporte-apuracao',
          },
        ],
      },
      {
        label: 'Plano de Saúde',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Plano de Saúde',
            link: '/plano-saude',
          },
          {
            label: 'Manutenção de Mensalidade',
            link: '/manutencao-mensalidade',
          },
          {
            label: 'Manutenção de Custo',
            link: '/manutencao-custo',
          },
        ],
      },
    ],
  },
  {
    label: 'SST',
    icon: 'how_to_reg',
    itens: [
      {
        label: 'S-2210 CAT',
        link: '/comunicado-acidente-trabalho',
      },
      {
        label: 'S-2220 ASO',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Atestado Saúde Ocupacional',
            link: '/atestado-saude-ocupacional',
          },
          {
            label: 'ASO Importação',
            link: '/aso-importacao',
          },
        ],
      },
      {
        label: 'S-2240 Ambiente de Trabalho',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Ambiente de Trabalho',
            link: '/condicao-ambiente-trabalho',
          },
          {
            label: 'Ambiente de Trabalho Importação',
            link: '/condicao-ambiente-trabalho-importacao',
          },
        ],
      },
      {
        label: 'Exame Toxicológico',
        link: '/exame-toxicologico',
      },
      {
        label: 'Consultar/Gerar Evento S-2240',
        link: '/condicao-ambiente-trabalho-automatico',
      },
      {
        label: 'EPI',
        link: '/equipamento-protecao-individual',
      },
      {
        label: 'Responsável Registro Ambiental',
        link: '/responsavel-registro-ambiental',
      },
      {
        label: 'Cargo Agente Nocivo',
        link: '/cargo-agente-nocivo',
      },
      {
        label: 'Setor Agente Nocivo',
        link: '/setor-agente-nocivo',
      },
      {
        label: 'Relatórios',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Resumo S-2240',
            link: '/condicao-ambiente-trabalho-resumo',
          },
        ],
      },
    ],
  },
  {
    label: 'SEFIP',
    icon: 'view_module',
    itens: [
      {
        label: 'Gerar Arquivo SEFIP',
        link: '/gerar-sefip',
      },
      {
        label: 'Relação de Empregados',
        link: '/re-sefip',
      },
    ],
  },
  {
    label: 'Comercialização Rural',
    icon: 'home_work',
    itens: [
      {
        label: 'Adquirente',
        link: '/adquirente',
      },
      {
        label: 'Comercialização',
        link: '/comercializacao',
      },
    ],
  },
  {
    label: 'Contribuinte Facultativo',
    icon: 'person_outline',
    itens: [
      {
        label: 'Contribuintes',
        link: '/contribuinte-facultativo',
      },
      {
        label: 'Contribuições',
        link: '/contribuicao-facultativo',
      },
    ],
  },
  {
    label: 'Integração Contabilidade',
    icon: 'assessment',
    itens: [
      {
        label: 'Modelo de Integração',
        link: '/modelo-integracao',
      },
      {
        label: 'Configuração de Lançamento',
        link: '/configuracao-lancamento',
      },
      {
        label: 'Gerar Arquivo de Exportação',
        link: '/gerar-arquivo-exportacao',
      },
    ],
  },
  {
    label: 'Relatórios',
    icon: 'library_books',
    itens: [
      {
        label: 'Gerenciador Documentos',
        link: '/gerenciador-documentos',
      },
      {
        label: 'Admissional',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Relatórios Admissionais',
            link: '/relatorio-admissional',
          },
          {
            label: 'LGPD',
            link: '/lgpd',
          },
          {
            label: 'Ficha de Salário Família',
            link: '/ficha-salario-familia',
          },
          {
            label: 'Declaração de Contribuição Sindical',
            link: '/declaracao-contribuicao-sindical',
          },
          {
            label: 'Termo de Responsabilidade',
            link: '/termo-responsabilidade-dependentes',
          },
          {
            label: 'Contrato Trabalho Indeterminado',
            link: '/contrato-trabalho-indeterminado',
          },
          {
            label: 'Contrato Experiência/Prorrogação',
            link: '/contrato-experiencia-prorrogacao',
          },
          {
            label: 'Autorização de Depósito',
            link: '/autorizacao-deposito',
          },
          {
            label: 'Acordo de hora extra',
            link: '/acordo-hora-extra',
          },
          {
            label: 'Acordo Compensação de Horas Trabalhadas',
            link: '/compensacao-horas-trabalhadas',
          },
          {
            label: 'Acordo Banco de Horas',
            link: '/acordo-banco-horas',
          },
          {
            label: 'Termo de Compromisso de Estágio',
            link: '/termo-compromisso-estagio',
          },
          {
            label: 'Ficha de Registro de Funcionário',
            link: '/ficha-registro-funcionario',
          },
          {
            label: 'Declaração uso de Plano de Saúde',
            link: '/declaracao-uso-plano-saude',
          },
          {
            label: 'Vale Transporte Declaração de Uso',
            link: '/vale-transporte-declaracao-uso',
          },
          {
            label: 'Termo de Autorização de Uso de Imagem e Voz',
            link: '/termo-autorizacao-imagem-voz',
          },
          {
            label: 'Recibo Termo de Conduta',
            link: '/recibo-termo-conduta',
          },
          {
            label: 'Autorização de Descontos',
            link: '/autorizacao-descontos',
          },
          {
            label: 'Contrato Intermitente',
            link: '/contrato-intermitente',
          },
          {
            label: 'Termo de Monitoramento por Câmeras',
            link: '/termo-monitoramento-cameras',
          },
          {
            label: 'Carta de Oposição Sindicato',
            link: '/carta-oposicao-sindicato',
          },
          {
            label: 'Ficha de Fornecimento e Controle de EPI',
            link: '/ficha-fornecimento-control-epi',
          },
          {
            label: 'Recibo Entrega/Devolução CTPS',
            link: '/recibo-entrega-devolucao-ctps',
          },
        ],
      },
      {
        label: 'Mensal',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Folha Coletiva',
            link: '/folha-coletiva',
          },
          {
            label: 'Resumo Folha',
            link: '/resumo-folha',
          },
          {
            label: 'Provisão Saldo Inicial',
            link: '/provisao-saldo-inicial',
          },
          {
            label: 'Provisão de 13° Salário',
            link: '/provisao-13-salario',
          },
          {
            label: 'Provisão de Férias',
            link: '/provisao-ferias',
          },
          {
            label: 'Relação Bancária',
            link: '/relacao-bancaria',
          },
          {
            label: 'Relatório Férias Vencidas',
            link: '/relatorio-ferias-vencidas-main',
          },
          {
            label: 'Relatório Férias Concedidas',
            link: '/form-relatorio-ferias-concedidas',
          },
          {
            label: 'Quadro Horário de Trabalho',
            link: '/quadro-horario-trabalho',
          },
        ],
      },
      {
        label: 'Operacional',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Valores Por Evento',
            link: '/valores-por-evento',
          },
          {
            label: 'Resumo Admissão e Demissão',
            link: '/resumo-admissao-demissao',
          },
          {
            label: 'Relatório - Salário Contratual',
            link: '/relatorio-salario-contratual',
          },
          {
            label: 'Relatório - Vencimento Contrato Experiência',
            link: '/vencimento-contrato-experiencia',
          },
          {
            label: 'Relatório Salarial Por Cargo',
            link: '/relatorio-salarial-cargo',
          },
          {
            label: 'Resumo Contábil da Folha',
            link: '/resumo-contabil-folha',
          },
          {
            label: 'Atualizar CTPS',
            showCollapseIcon: true,
            itens: [
              {
                label: 'Atualizar CTPS',
                link: '/atualizar-ctps',
              },
              {
                label: 'Etiqueta Férias Concedidas',
                link: '/etiqueta-ferias',
              },
              {
                label: 'Etiqueta Alteração Salarial',
                link: '/etiqueta-alteracao-salarial',
              },
            ],
          },
        ],
      },
      {
        label: 'DARF',
        showCollapseIcon: true,
        itens: [
          {
            label: 'IRRF',
            link: '/darf-ir',
          },
          {
            label: 'PIS',
            link: '/darf-pis',
          },
        ],
      },
      {
        label: 'Etiquetas',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Etiqueta CTPS',
            link: '/etiqueta-ctps',
          },
          {
            label: 'Etiqueta Contrato Experiência',
            link: '/etiqueta-contrato-experiencia',
          },
          {
            label: 'Etiqueta Aviso Prévio',
            link: '/etiqueta-aviso-previo',
          },
          {
            label: 'Etiqueta Cartão de Ponto',
            link: '/etiqueta-cartao-ponto',
          },
        ],
      },
      {
        label: 'Consultas',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Consulta Vencimentos',
            link: '/consulta-vencimentos',
          },
        ],
      },
      {
        label: 'Recursos Humanos (RH)',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Relatório Aniversariantes',
            link: '/relatorio-aniversariantes',
          },
          {
            label: 'Advertências',
            link: '/funcionario-advertencia',
          },
          {
            label: 'Suspensões',
            link: '/funcionario-suspensao',
          },
          {
            label: 'Motivos - Advertência / Suspensão',
            link: '/motivos-advertencia-suspensao',
          },
          {
            label: 'Requerimento de Benefício por Incapacidade',
            link: '/requerimento-beneficio-incapacidade',
          },
        ],
      },
      {
        label: 'Carta de Abandono de Emprego',
        link: '/carta-abandono-emprego',
      },
      {
        label: 'Carta de Apresentação',
        link: '/carta-apresentacao',
      },
      {
        label: 'Declaração de Quitação Anual',
        link: '/declaracao-quitacao-anual',
      },
      {
        label: 'Lista de Ponto',
        link: '/lista-ponto',
      },
      {
        label: 'Folha de Ponto',
        link: '/folha-ponto',
      },
      {
        label: 'Relatório de Dados Cadastrais Funcionário',
        link: '/dados-cadastrais-funcionario',
      },
      {
        label: 'Declaração para Salário Contribuição',
        link: '/declaracao-salario-contribuicao',
      },
      {
        label: 'Declaração de Encargos de Família',
        link: '/declaracao-encargos-familia',
      },
      {
        label: 'Demonstrativo por Email',
        link: '/demonstrativo-rp-email',
      },
      {
        label: 'Contracheque',
        link: '/relatorio-contracheque',
      },
      {
        label: 'Recibo Pagamento Autônomo',
        link: '/recibo-pagamento-autonomo',
      },
    ],
  },
  {
    label: 'Configurações',
    icon: 'settings',
    itens: [
      {
        label: 'Parâmetros do Sistema',
        link: '/parametros-sistema',
      },
      {
        label: 'Ordem de Impressão Evento',
        link: '/ordem-impressao-evento',
      },
      {
        label: 'Relatório',
        link: '/configuracao-relatorio',
      },
      {
        label: 'Lotes por Empregador',
        link: '/lotes-empregador',
      },
      {
        label: 'Auditoria por Empregador',
        link: '/auditoria-empregador',
      },
      {
        label: 'Manutenção Empregador',
        link: '/manutencao-empregador',
      },
      {
        label: 'Limpar ou Gerar Ambiente eSocial',
        link: '/limpar-gerar-ambiente',
      },
      {
        label: 'Registro dos Usuários',
        link: '/registro-usuario',
      },
      {
        label: 'Configurações das Notificações',
        link: '/configuracoes-notificacoes',
      },
    ],
  },
]

export default menus
