import { useState } from 'react'

import { Box, Collapse, IconButton } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'

import { PageHeader, StackContainer } from '~/components'

import SearchForm, { DataProps } from './SearchForm'
import Table from './Table'

import useDialog from '~/hooks/useDialog'

import { ConsultaPessoalFilters } from '~/hooks/queries-log/@types/ConsultaPessoalFilters'
import DetalhamentoConsulta from './DetalhamentoConsulta'

export default function LogUsuario() {
  const [isOpenFilter, setOpenFilter] = useState(true)
  const [params, setParams] = useState<ConsultaPessoalFilters>({} as ConsultaPessoalFilters)

  const {
    close: closeDetalhamentoConsulta,
    data: dataDetalhamentoConsulta,
    isOpen: isOpenDetalhamentoConsulta,
    open: openDetalhamentoConsulta,
  } = useDialog('')

  function handleSubmit(dt: DataProps) {
    setParams(dt as ConsultaPessoalFilters)
  }

  return (
    <StackContainer gap={0}>
      <PageHeader title="Registro dos Usuários (Log do Sistema)">
        <IconButton
          size="small"
          onClick={() => setOpenFilter((prev) => !prev)}
          title="Filtros"
          color="primary"
        >
          <FilterList />
        </IconButton>
      </PageHeader>
      <Collapse in={isOpenFilter}>
        <SearchForm onSubmit={handleSubmit} />
      </Collapse>
      <Box flex={1} mt={1}>
        <Table params={params} openDetalhamento={openDetalhamentoConsulta} />
        {isOpenDetalhamentoConsulta && (
          <DetalhamentoConsulta
            isOpen={isOpenDetalhamentoConsulta}
            logId={dataDetalhamentoConsulta}
            onClose={closeDetalhamentoConsulta}
          />
        )}
      </Box>
    </StackContainer>
  )
}
