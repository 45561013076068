import { useState } from 'react'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Finder,
  PageHeader,
  Stack,
} from '~/components'

import Table from './Table'
import Form from './Form'
import DialogCustoDependente from './DialogCustoDependente'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import { useObterPorPlanoSaudeTitular } from '~/hooks/queries/PlanoSaudeDependente/useObterPorPlanoSaudeTitular'
import { useDeletar } from '~/hooks/queries/PlanoSaudeDependente/useDeletar'

import { PlanoSaudeDependente } from '~/hooks/queries/PlanoSaudeDependente/dtos/PlanoSaudeDependente'
import { useObterPessoaPlanoSaudeTitular } from '~/hooks/queries/PlanoSaudeDependente/useObterPessoaPlanoSaudeTitular'

interface ContentDependenteProps {
  planoSaudeTitularId: string
}

export default function ContentDependente({ planoSaudeTitularId }: ContentDependenteProps) {
  const [query, setQuery] = useState('')

  const { anoMes } = useAmbiente()

  const {
    close: closeDialogCustoDependente,
    data: dataDialogCustoDependente,
    isOpen: isOpenDialogCustoDependente,
    open: openDialogCustoDependente,
  } = useDialog('')

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<PlanoSaudeDependente>({} as PlanoSaudeDependente)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterPorPlanoSaudeTitular({
    params: {
      planoSaudeTitularId,
    },
  })
  const data = _d || []

  const {
    data: _pessoaFisicaId,
    isLoading: _isLoadingFindPessoa,
    isFetching: _isFetchingFindPessoa,
  } = useObterPessoaPlanoSaudeTitular({
    params: {
      planoSaudeTitularId,
    },
  })
  const pessoaFisicaId = _pessoaFisicaId?.id || ''
  const isLoadingPessoaFisica = _isLoadingFindPessoa || _isFetchingFindPessoa

  const { mutateAsync: mutateAsyncDeletar, isLoading: isRemoving } = useDeletar()

  function handleClickItem(event: 'edit' | 'delete' | 'custo', value: string) {
    switch (event) {
      case 'custo':
        openDialogCustoDependente(value)
        break
      case 'edit':
        handleClickEdit(value)
        break
      case 'delete':
        openConfirmDelete(value)
        break
    }
  }

  function handleClickEdit(id: string) {
    const dtCurrent = data.find((d) => d.id === id)
    if (dtCurrent) {
      openForm(dtCurrent)
    }
  }

  async function handleDeleteItem() {
    await mutateAsyncDeletar({
      id: dataConfirmDelete,
      params: {
        anoMes,
      },
    })
    closeConfirmDelete()
  }

  return (
    <Stack height="100%">
      <PageHeader title="Plano de Saúde - Dependentes">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button
            size="small"
            color="primary"
            onClick={() =>
              openForm({
                planoSaudeTitularId,
              } as PlanoSaudeDependente)
            }
            variant="contained"
          >
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <ContainerTable>
        <Table
          data={data}
          isLoading={isLoading}
          isFetching={isFetching || isLoadingPessoaFisica}
          query={query}
          onItemClick={handleClickItem}
        />
      </ContainerTable>

      {isOpenForm && pessoaFisicaId && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          initialData={dataForm}
          pessoaFisicaId={pessoaFisicaId}
        />
      )}

      {isOpenDialogCustoDependente && (
        <DialogCustoDependente
          isOpen={isOpenDialogCustoDependente}
          onClose={closeDialogCustoDependente}
          planoSaudeDependenteId={dataDialogCustoDependente}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isRemoving}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}
    </Stack>
  )
}
