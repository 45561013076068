import React from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Lock as BloqueadoIcon, LockOpen as DesbloqueadoIcon } from '@material-ui/icons'

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  desbloqueado: {
    color: theme.palette.alerts.success,
  },
  bloqueado: {
    bloqueado: theme.palette.alerts.danger,
  },
}))

const Bloqueado = ({ bloqueado }) => {
  const classes = useClasses()
  return (
    <Box className={classes.root}>
      {bloqueado ? (
        <BloqueadoIcon className={classes.bloqueado} fontSize="small" />
      ) : (
        <DesbloqueadoIcon className={classes.desbloqueado} fontSize="small" />
      )}
    </Box>
  )
}

export default Bloqueado
