import { useState, useEffect } from 'react'

import api from '~/services/api-pessoal'

import { Finder } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { PageHeader, Checkbox, PDFViewer, PrintButton } from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

export default function FichaRegistroFuncionario() {
  const [query, setQuery] = useState('')
  const [isFuncionariosAtivos, setFuncionariosAtivos] = useState(true)

  const [collectionOld, setCollectionOld] = useState([])
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelecteds] = useState([])
  const [isLoadingTable, setLoadingTable] = useState(false)

  const dialogNotification = useDialogNotification()
  const { estabelecimento } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  useEffect(() => {
    async function getCollection() {
      setLoadingTable(true)
      try {
        const response = await api.get('/Vinculo/ObterVinculosPorEstabelecimento', {
          params: {
            estabelecimentoId: estabelecimento.id,
          },
        })
        const responseCurrent = response?.data?.data || []
        const collectionOnlyAtivos = responseCurrent.filter((x) => x.isAtivo)
        setCollectionOld(responseCurrent)
        setCollection(collectionOnlyAtivos)
        setRowsSelecteds([])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingTable(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [estabelecimento])

  function toggleCollectionAtivosOrAll(value) {
    setFuncionariosAtivos(value)
    if (value) {
      return setCollection((prev) => prev.filter((x) => x.isAtivo))
    }
    return setCollection(collectionOld)
  }

  function handlePrint() {
    if (!(rowsSelecteds.length > 0))
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um funcionário'],
      })
    const ids = rowsSelecteds.map((rowIndex) => collection[rowIndex].id)
    openPDFViewer({
      vinculosIds: ids,
    })
    setRowsSelecteds([])
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Ficha de Registro de Funcionário">
        <Checkbox
          onChange={(_, value) => toggleCollectionAtivosOrAll(value)}
          label="Somente funcionários ativos"
          value={isFuncionariosAtivos}
        />
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Table
        data={collection}
        query={query}
        isLoading={isLoadingTable}
        rowsSelected={rowsSelecteds}
        handleRowSelected={setRowsSelecteds}
      />
      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="FichaRegistroFuncionario"
        title="Ficha de Registro de Funcionário"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/RelRegistroFichaFuncionario/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={handlePrint} />
      </Box>
    </Box>
  )
}
