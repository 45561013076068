import React from 'react'

import { IconButton } from '@material-ui/core'
import { CloudUpload as UploadIcon, GetApp as DownloadIcon } from '@material-ui/icons'
import { ButtonBox, DataTable } from 'mio-library-ui'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

export default function Table(props) {
  const { data: _data, isLoading, onItemClick, query } = props

  const { openDownloadData } = useUtils()

  const data = _data.map((d) => ({
    ...d,
    createdDate: d?.createdDate ? moment(d.createdDate).format('DD/MM/yyyy') : '',
    updatedDate: d?.updatedDate ? moment(d.updatedDate).format('DD/MM/yyyy') : '',
  }))

  const columns = [
    {
      name: 'chaveId',
      label: 'Chave',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'createdDate',
      label: 'Gerado',
    },
    {
      name: 'updatedDate',
      label: 'Atualizado',
    },
    {
      name: 'id',
      label: 'XML',
      options: {
        customBodyRender: (value) => (
          <ButtonBox spacing={1} justifyContent="center">
            <IconButton
              size="small"
              color="primary"
              title="Download FRX"
              aria-label="Download"
              onClick={() => {
                const blob = _data.find((_data) => _data.id === value)
                const fileName = `${blob.chaveId}.frx`
                openDownloadData(fileName, blob.xml)
              }}
            >
              <DownloadIcon fontSize="small" color="primary" />
            </IconButton>

            <IconButton
              size="small"
              title="Editar XML"
              color="primary"
              aria-label="Editar"
              onClick={() => onItemClick(value)}
            >
              <UploadIcon fontSize="small" color="primary" />
            </IconButton>
          </ButtonBox>
        ),
      },
    },
  ]

  return (
    <DataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['chaveId', 'nome'],
      }}
    />
  )
}
