import { formatToCPF } from 'brazilian-values'
import React from 'react'
import { ToolsDataTable } from '~/components'

export default function Table(props) {
  const { data: _data, isLoading, query, onRowSelected, rowsSelected } = props

  const data = _data.map((obj) => ({
    ...obj,
    cpf: obj?.cpf ? formatToCPF(obj.cpf) : '',
  }))

  const columns = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'cpf',
      label: 'CPF',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'banco',
      label: 'Banco',
    },
    {
      name: 'agencia',
      label: 'Agência',
    },
    {
      name: 'contaCorrente',
      label: 'Conta corrente',
    },
    {
      name: 'indicadorContaCorrenteValue',
      label: 'Indicador de conta',
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      isLoading={isLoading}
      columns={columns}
      sherlock={{
        query,
        columns: ['codigo', 'cpf', 'nome', 'banco'],
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected,
        rowsSelected,
        selectOnClick: true,
      }}
    />
  )
}
