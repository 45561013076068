import { useQuery as useTQuery } from 'react-query'
import api from '~/services/api-pessoal'
import useDialogNotification from '../useDialogNotification'

async function getHistoricoConfiguracaoEvento(data) {
  if (!data) return
  const { eventoId, empregadorId } = data

  const response = await api.get('/EventoEmpregador/GetConfiguracaoEvento', {
    params: {
      eventoId,
      empregadorId,
    },
  })
  return response.data.data
}

export function useQuery(data) {
  const dialogNotification = useDialogNotification()
  return useTQuery(
    ['HistoricoConfiguracaoEvento', data],
    () => getHistoricoConfiguracaoEvento(data),
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}
