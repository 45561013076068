import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { AutonomoManutencaoDTO } from './AutonomoManutencaoDTO'

interface RequestProps {
  data: AutonomoManutencaoDTO[]
}

export default function useAtualizarSalariosAutonomos() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest({ data }: RequestProps) {
    await api.put('/Autonomo/AtualizarSalariosAutonomos', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Autônomos atualizados com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
