import { formatAnoMes, formatCurrency } from '~/hooks/useUtils'

import { IconButton } from '@material-ui/core'
import { LibraryBooks as LibraryBooksIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import DialogEventos from './DialogEventos'

import useDialog from '~/hooks/useDialog'

import useObterConvencaoColetivaPorVinculo from '~/hooks/queries/ApurarConvencaoColetiva/useObterConvencaoColetivaPorVinculo'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { ApurarConvencaoColetivaEventoDTO } from '~/hooks/queries/ApurarConvencaoColetiva/ApurarConvencaoColetivaDTO'

interface TableDetailVinculoProps {
  vinculoId: string
  anoMesApuracao: string
}

export default function TableDetailVinculo({ vinculoId, anoMesApuracao }: TableDetailVinculoProps) {
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterConvencaoColetivaPorVinculo({
    anoMes: anoMesApuracao,
    vinculoId,
  })
  const {
    close: closeDialogEventos,
    data: dataDialogEventos,
    isOpen: isOpenDialogEventos,
    open: openDialogEventos,
  } = useDialog<{ data: ApurarConvencaoColetivaEventoDTO[]; subtitle: string }>({
    data: [],
    subtitle: '',
  })

  const data = _d
    ? _d.map((d) => ({
        ...d,
        competenciaRecibo: d?.competenciaRecibo ? formatAnoMes(d.competenciaRecibo) : '',
        vrVencimentoOriginal: d?.vrVencimentoOriginal ? formatCurrency(d.vrVencimentoOriginal) : '',
        vrVencimentoAtualizado: d?.vrVencimentoAtualizado
          ? formatCurrency(d.vrVencimentoAtualizado)
          : '',
        vrVencimentoApurado: d?.vrVencimentoApurado ? formatCurrency(d.vrVencimentoApurado) : '',
        vrAntecipado: d?.vrAntecipado ? formatCurrency(d.vrAntecipado) : '',
        vrINSSApurado: d?.vrINSSApurado ? formatCurrency(d.vrINSSApurado) : '',
      }))
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'competenciaRecibo',
      label: 'Competência',
    },
    {
      name: 'vrVencimentoOriginal',
      label: 'Total',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrVencimentoAtualizado',
      label: 'Total Atualizado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrAntecipado',
      label: 'Antecipado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrVencimentoApurado',
      label: 'Apurado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrINSSApurado',
      label: 'INSS Apurado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'reciboId',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Eventos"
                color="primary"
                aria-label="Eventos"
                onClick={() => {
                  const dtCurrent = _d?.find((d) => d.reciboId === value)
                  if (dtCurrent) {
                    openDialogEventos({
                      data: dtCurrent.eventos,
                      subtitle: formatAnoMes(dtCurrent.competenciaRecibo),
                    })
                  }
                }}
                size="small"
              >
                <LibraryBooksIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <div>
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        disableAutoHeight
        pagination={false}
      />
      {isOpenDialogEventos && (
        <DialogEventos
          isOpen={isOpenDialogEventos}
          onClose={closeDialogEventos}
          data={dataDialogEventos.data}
          subtitle={dataDialogEventos.subtitle}
        />
      )}
    </div>
  )
}
