import { useRef, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import {
  PageHeader,
  PrintButton,
  PDFViewer,
  Finder,
  ContainerTable,
  StackContainer,
  AnoMesTextField,
  Button,
} from '~/components'

import Table from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useValidationErrors from '~/hooks/useValidationErrors'

import { useObterAniversariantes } from '~/hooks/queries/RelAniversariantes/useObterAniversariantes'

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

const TITLE_PAGE = 'Relatório Aniversariantes'

export default function RelatorioAniversariantes() {
  const [query, setQuery] = useState('')

  const tableRef = useRef<FixLater>(null)
  const { estabelecimento, anoMes } = useAmbiente()
  const theme = useTheme()

  const [dataForm, setDataForm] = useState({
    anoMesInicio: anoMes,
    anoMesFim: anoMes,
  })

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog({
    estabelecimentoId: estabelecimento.id,
    anoMesInicio: dataForm.anoMesInicio,
    anoMesFim: dataForm.anoMesFim,
  })

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterAniversariantes({
    estabelecimentoId: estabelecimento.id,
    anoMesInicio: dataForm.anoMesInicio,
    anoMesFim: dataForm.anoMesFim,
  })
  const data = _d || []

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSearch,
    data: dataForm,
  })

  async function handleSearch() {
    await refetch()
  }

  function handleSubmit() {
    openPDFViewer({
      estabelecimentoId: estabelecimento.id,
      anoMesInicio: dataForm.anoMesInicio,
      anoMesFim: dataForm.anoMesFim,
    })
  }

  return (
    <StackContainer>
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} p={1} display="flex" gridGap={theme.spacing(1)} alignItems="center">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <AnoMesTextField
              label="Ano/Mês Início"
              value={dataForm.anoMesInicio}
              validationErrors={validationErrors}
              name="anoMesInicio"
              onChange={(e) => {
                const anoMesInicio = e.target.value ? e.target.value.replace('/', '') : ''
                setDataForm((prev) => ({ ...prev, anoMesInicio }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <AnoMesTextField
              label="Ano/Mês Fim"
              value={dataForm.anoMesFim}
              validationErrors={validationErrors}
              name="anoMesFim"
              onChange={(e) => {
                const anoMesFim = e.target.value ? e.target.value.replace('/', '') : ''
                setDataForm((prev) => ({ ...prev, anoMesFim }))
              }}
            />
          </Grid>
        </Grid>
        <Button variant="contained" isLoading={isLoading || isFetching} onClick={handleValidate}>
          Buscar
        </Button>
      </Box>

      <ContainerTable>
        <Table
          query={query}
          tableRef={tableRef}
          data={data}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      </ContainerTable>

      <Box display="flex" justifyContent="flex-end" p={1} component={Paper}>
        <PrintButton onClick={handleSubmit} />
      </Box>

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="RelAniversariantes"
          title={TITLE_PAGE}
          axiosConfig={{
            method: 'get',
            url: '/Relatorio/RelAniversariantes/ObterRelatorio',
            params: dataPDFViewer,
          }}
        />
      )}
    </StackContainer>
  )
}
