import { useState, useCallback } from 'react'

import { DragDropFile } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useUploadFile from '~/hooks/useUploadFile'

import api from '~/services/api-pessoal'

const UploadPontoEletronico = ({ estabelecimentoId, anoMes, onDownloadSuccess }) => {
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const [handlePrepareUpload] = useUploadFile(async (preparedFile) => {
    const { file } = preparedFile
    const formData = new FormData()
    formData.append('file', file)
    setLoading(true)
    try {
      await api.post(
        `PontoEletronico/ExtractFile?estabelecimentoId=${estabelecimentoId}&anoMes=${anoMes}`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      )
      notification.post()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    onDownloadSuccess()
  })

  const handleDropped = useCallback(
    (files) => {
      handlePrepareUpload(files)
    },
    // eslint-disable-next-line
    [estabelecimentoId, anoMes, onDownloadSuccess],
  )

  return <DragDropFile height="100px" onDropped={handleDropped} isLoading={isLoading} />
}

export default UploadPontoEletronico
