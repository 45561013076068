import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { CNABDataDTO } from './dtos/CNABDataDTO'

const query = '/CNAB/ObterRemessasProcessadas'

interface RequestProps {
  params: {
    nrRemessa?: string
  }
}

export default function useObterRemessasProcessadas({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params.nrRemessa) return
    const response = await api.get<{ data: CNABDataDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
