export enum EpiEpcAplicavelEnum {
  NaoSeAplica = '0',
  NaoImplementa = '1',
  Implementa = '2',
}

export const EpiEpcAplicavelValues = [
  {
    value: EpiEpcAplicavelEnum.NaoSeAplica,
    name: 'Não se aplica',
  },
  {
    value: EpiEpcAplicavelEnum.NaoImplementa,
    name: 'Não implementa',
  },
  {
    value: EpiEpcAplicavelEnum.Implementa,
    name: 'Implementa',
  },
]
