import { formatCurrency } from '~/utils/utils'
import { formatPrettyAnoMes } from '~/hooks/useUtils'

import { IconButton } from '@material-ui/core'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons'

import {
  Button,
  ButtonBox,
  ContainerTable,
  ContentDivider,
  Stack,
  ToolsDataTable,
} from '~/components'

import Form from './Form'
import TributosINSS from './TributosINSS'

import useDialog from '~/hooks/useDialog'

import { useObterTodos } from '~/hooks/queries/ProcessoTrabalhistaTributosPeriodo/useObterTodos'
import { useRemover } from '~/hooks/queries/ProcessoTrabalhistaTributosPeriodo/useRemover'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ProcessoTrabalhistaTributosPeriodo } from '~/hooks/queries/ProcessoTrabalhistaTributosPeriodo/dtos/ProcessoTrabalhistaTributosPeriodo'

interface TributosPeriodosProps {
  processoTrabalhistaTributosId: string
  processoTrabalhistaVinculoId?: string
}

export default function TributosPeriodos({
  processoTrabalhistaTributosId,
  processoTrabalhistaVinculoId,
}: TributosPeriodosProps) {
  const {
    close: closeForm,
    isOpen: isOpenForm,
    open: openForm,
    data: dataForm,
  } = useDialog<ProcessoTrabalhistaTributosPeriodo | null>(null)
  const {
    close: closeTributosINSS,
    data: dataTributosINSS,
    isOpen: isOpenTributosINSS,
    open: openTributosINSS,
  } = useDialog('')

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterTodos({
    params: {
      processoTrabalhistaTributosId,
      processoTrabalhistaVinculoId,
    },
  })
  const data = _data || []

  const isCadastroPreparado =
    processoTrabalhistaTributosId && processoTrabalhistaVinculoId ? true : false

  const { mutateAsync, isLoading: isRemoving } = useRemover()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'periodoReferencia',
      label: 'Período de Referência',
      options: {
        customBodyRender: formatPrettyAnoMes,
      },
    },
    {
      name: 'vrBcCpMensal',
      label: 'Base Mensal',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrBcCp13',
      label: 'Base 13° Salário',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Visualizar Contribuições"
                color="primary"
                aria-label="Contribuições"
                onClick={() => openTributosINSS(value)}
              >
                <VisibilityIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const rowCurrent = data.find((d) => d.id === value)
                  if (rowCurrent) {
                    openForm(rowCurrent)
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={async () => await mutateAsync(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack height="100%">
      <ContentDivider
        title="Períodos"
        renderRight={
          <Button
            variant="contained"
            onClick={() => openForm(null)}
            disabled={isCadastroPreparado ? false : true}
          >
            Adicionar
          </Button>
        }
      />
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          disableAutoHeight
          pagination={false}
          heightHeader="30px"
          isLoading={isLoading}
          isFetching={isFetching || isRemoving}
        />
      </ContainerTable>

      {isOpenForm && isCadastroPreparado && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          processoTrabalhistaTributosId={processoTrabalhistaTributosId}
          processoTrabalhistaVinculoId={processoTrabalhistaVinculoId as string}
          data={dataForm}
        />
      )}

      {isOpenTributosINSS && (
        <TributosINSS
          isOpen={isOpenTributosINSS}
          onClose={closeTributosINSS}
          processoTrabalhistaTributosPeriodoId={dataTributosINSS}
        />
      )}
    </Stack>
  )
}
