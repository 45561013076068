import React, { useState } from 'react'

import api from '~/services/api-pessoal'
import { useQueryClient } from 'react-query'

import { ActionDialog } from '~/components'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import {
  VinculoSalarioBase,
  VinculoSalarioBasePartial,
} from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'
import { query } from '~/hooks/queries/VinculoSalarioBase/useVinculoSalarioBaseGetByVinculo'
import SalarioContratualForm from '~/pages/Funcionario/Forms/SalarioContratualForm'

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoSalarioBasePartial
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data } = props
  const [isSubmitting, setSubmitting] = useState(false)

  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  const handleSubmit = (data: VinculoSalarioBase) => {
    const update = async () => {
      setSubmitting(true)
      try {
        await api.put(`/vinculoSalarioBase/${data.id}`, data)
        queryClient.invalidateQueries(query)
        notification.put()
        onClose()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        await api.post('/vinculoSalarioBase', data)
        queryClient.invalidateQueries(query)
        notification.post()
        onClose()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    if (data.id) {
      update()
      return
    }
    insert()
  }

  function handleClickSave() {
    buttonRef.current?.click()
  }

  return (
    <ActionDialog
      title="Cadastro de Salário Contratual"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleClickSave}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <SalarioContratualForm
        data={_data as FixLater}
        onSubmit={handleSubmit}
        buttonRef={buttonRef}
      />
    </ActionDialog>
  )
}
