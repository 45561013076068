import React from 'react'

import { Box, makeStyles, Tooltip } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import useUtils from '~/hooks/useUtils'
import moment from 'moment'

import { HeightDataTable } from '~/components'

import { CategoriaEnum, CategoriaValues } from '~/@types/enums/CategoriaEnum'

const categoriaOnlyAutonomos = [
  CategoriaEnum.Contribuinte_Individual_Autonomo_701,
  CategoriaEnum.Contribuinte_Individual_Transportador_Passageiros_711,
  CategoriaEnum.Contribuinte_Individual_Transportador_Carga_712,
  CategoriaEnum.Contribuinte_Individual_Microempreendedor_Individual_741,
]
const categoriasAutonomos = CategoriaValues.filter((obj) =>
  categoriaOnlyAutonomos.includes(obj.value),
)

const useStyles = makeStyles((theme) => ({
  cellCategoria: {
    width: '500px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: theme.spacing(1),
  },
}))

function formatCategoria(categoria) {
  if (!categoria) return ''
  const objectFinded = categoriasAutonomos.find((obj) => obj.value === categoria)
  return objectFinded?.name || ''
}

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const classes = useStyles()
  const { formatCurrency } = useUtils()

  const dataFormated = data.map((d) => {
    return {
      ...d,
      dtLancamento: d?.dtLancamento ? moment(d.dtLancamento).format('DD/MM/yyyy') : '',
      dtPagamento: d?.dtPagamento ? moment(d.dtPagamento).format('DD/MM/yyyy') : '',
      vrServico: d?.vrServico ? formatCurrency(d.vrServico) : '',
      categoria: formatCategoria(d?.categoria),
    }
  })

  const columns = [
    {
      name: 'dtLancamento',
      label: 'Data de Lançamento',
    },
    {
      name: 'dtPagamento',
      label: 'Data de Pagamento',
    },
    {
      name: 'vrServico',
      label: 'Valor do Serviço',
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: '150px' },
        }),
        setCellProps: () => ({
          style: { textAlign: 'right' },
        }),
      },
    },
    {
      name: 'descricaoServico',
      label: 'Descrição do Serviço',
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: '150px' },
        }),
      },
    },
    {
      name: 'categoria',
      label: 'Categoria',
      options: {
        setCellProps: (cellValue) => ({
          className: classes.cellCategoria,
          title: cellValue,
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          const dataFinded = data.find((d) => d.id === value)
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Replicar este registro"
                color="primary"
                aria-label="Replicar"
                onClick={() => {
                  onItemClick('replicate', value)
                }}
                size="small"
              >
                <FileCopyIcon fontSize="small" color="primary" size="small" />
              </IconButton>

              <Tooltip title={dataFinded?.rpId ? 'Existe um recibo para esse registro' : ''}>
                <Box>
                  <IconButton
                    title="Editar este registro"
                    color="primary"
                    aria-label="Editar"
                    disabled={dataFinded?.rpId ? true : false}
                    onClick={() => {
                      onItemClick('edit', value)
                    }}
                    size="small"
                  >
                    <EditIcon
                      fontSize="small"
                      color={!dataFinded?.rpId ? 'primary' : ''}
                      size="small"
                    />
                  </IconButton>
                </Box>
              </Tooltip>

              <Tooltip title={dataFinded?.rpId ? 'Existe um recibo para esse registro' : ''}>
                <Box>
                  <IconButton
                    title="Deletar este registro"
                    color="primary"
                    aria-label="Deletar"
                    disabled={dataFinded?.rpId ? true : false}
                    onClick={() => {
                      onItemClick('delete', value)
                    }}
                    size="small"
                  >
                    <DeleteIcon
                      fontSize="small"
                      color={!dataFinded?.rpId ? 'primary' : ''}
                      size="small"
                    />
                  </IconButton>
                </Box>
              </Tooltip>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={dataFormated} columns={columns} isLoading={isLoading} />
}

export default Table
