import { useEffect, useState } from 'react'

import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Box,
  ListSubheader,
  LinearProgress,
  Typography,
} from '@material-ui/core'

import { SearchTextFieldDelay } from '~/components'

const useStyles = makeStyles((theme) => ({
  root: {
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: '100%',
  },
  listRoot: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    overflow: 'auto',
    padding: 0,
  },
  listItemClass: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  subheaderRoot: {
    borderBottom: theme.shape.border,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    gap: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    zIndex: 9,
  },
  renderRightBox: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function MenuSelect(props) {
  const {
    options: _options,
    onCheckOption,
    optionsCheckeds,
    onSelect,
    selectedOption,
    renderOption,
    renderRight,
    isLoading,
    disabledCheckCondition,
    searchField,
    idField,
  } = props
  const [options, setOptions] = useState([])

  const [searchText, setSearchText] = useState('')

  const classes = useStyles()

  useEffect(() => {
    if (!searchText) return setOptions(_options)
    const newOptions = []
    _options.forEach((d) => {
      if (d[searchField].toLowerCase().includes(searchText.toLowerCase())) newOptions.push(d)
    })
    setOptions(newOptions)
  }, [searchText, searchField, _options])

  function handleCheckOption(option) {
    if (!optionsCheckeds) return
    const indexOptionFinded = _options.findIndex((d) => d[idField] === option[idField])
    const newChecked = [...optionsCheckeds]
    const indexFinded = newChecked.findIndex((index) => index === indexOptionFinded)
    if (indexFinded !== -1) {
      newChecked.splice(indexFinded, 1)
    } else {
      newChecked.push(indexOptionFinded)
    }
    onCheckOption(newChecked)
  }

  function handleCheckAll() {
    if (!optionsCheckeds) return
    if (optionsCheckeds.length !== 0) {
      onCheckOption([])
      return
    }
    const indexsToCheck = []

    _options.forEach((d, index) => {
      if (!disabledCheckCondition) {
        return indexsToCheck.push(index)
      }
      if (!disabledCheckCondition(d)) {
        indexsToCheck.push(index)
      }
    })

    onCheckOption(indexsToCheck)
  }

  function handleSelectItem(option) {
    const indexOptionFinded = _options.findIndex((d) => d[idField] === option[idField])
    onSelect(indexOptionFinded)
  }

  return (
    <Box className={classes.root}>
      <List
        classes={{
          root: classes.listRoot,
        }}
        dense
        subheader={
          <ListSubheader
            classes={{
              root: classes.subheaderRoot,
            }}
          >
            {optionsCheckeds && (
              <Checkbox
                edge="start"
                onClick={handleCheckAll}
                checked={optionsCheckeds.length === _options.length}
                indeterminate={
                  optionsCheckeds.length !== _options.length && optionsCheckeds.length > 0
                }
                disabled={_options.length === 0}
                size="small"
                color="secondary"
              />
            )}
            <SearchTextFieldDelay onChange={setSearchText} />
          </ListSubheader>
        }
      >
        {isLoading && <LinearProgress />}
        {options.length > 0
          ? options.map((opt, index) => (
              <ListItem
                key={opt[idField]}
                button
                selected={
                  opt[idField] ===
                  (_options[selectedOption] ? _options[selectedOption][idField] : null)
                }
                className={classes.listItemClass}
              >
                {optionsCheckeds && (
                  <Checkbox
                    edge="start"
                    onClick={() => handleCheckOption(opt)}
                    checked={optionsCheckeds.some((indexCurrent) => {
                      if (!_options[indexCurrent]) return false
                      return _options[indexCurrent][idField] === opt[idField]
                    })}
                    size="small"
                    color="secondary"
                    disabled={disabledCheckCondition ? disabledCheckCondition(opt) : undefined}
                  />
                )}
                <ListItem onClick={() => handleSelectItem(opt)}>
                  <ListItemText id={index} primary={renderOption(opt)} />
                  {renderRight && (
                    <Box className={classes.renderRightBox}>{renderRight(opt, index)}</Box>
                  )}
                </ListItem>
              </ListItem>
            ))
          : !isLoading && <Typography align="center">Registros não encontrados</Typography>}
      </List>
    </Box>
  )
}
