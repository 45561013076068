import React, { useMemo } from 'react'

import { ToolsDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'

export default function TableEventosValor(props) {
  const { data: _data } = props

  const { formatAnoMes, formatCurrency } = useUtils()

  const data = _data.map((d) => ({
    ...d,
    anoMes: formatAnoMes(d.anoMes),
    referencia: formatCurrency(d.referencia),
    vrEvento: formatCurrency(d.vrEvento),
  }))

  const columns = useMemo(
    () => [
      {
        name: 'anoMes',
        label: 'Ano/Mês',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'lotacao',
        label: 'Lotação',
      },
      {
        name: 'lotacaoNome',
        label: 'Lotação Nome',
      },
      {
        name: 'referencia',
        label: 'Referência',
      },
      {
        name: 'vrEvento',
        label: 'Valor Evento',
      },
    ],
    [],
  )

  return <ToolsDataTable data={data} columns={columns} pagination={false} disableAutoHeight />
}
