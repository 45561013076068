export enum IndModeloReciboSocioEnum {
  Modelo_00,
  Modelo_01,
}

export const IndModeloReciboSocioValues = [
  {
    name: 'Modelo 00',
    value: IndModeloReciboSocioEnum.Modelo_00,
  },
  {
    name: 'Modelo 01',
    value: IndModeloReciboSocioEnum.Modelo_01,
  },
]
