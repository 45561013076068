import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  PageHeader,
  Stack,
  ToolsDataTable,
} from '~/components'

import Form from './Form'

import moment from 'moment'
import { formatPrettyAnoMes } from '~/hooks/useUtils'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import useGetByVinculoFerias from '~/hooks/queries/VinculoFeriasItem/useGetByVinculoFerias'
import useDelete from '~/hooks/queries/VinculoFeriasItem/useDelete'
import { queryGetByVinculo } from '~/hooks/queries/VinculoFerias/useGetByVinculo'

import { IndFeriasEnum, IndFeriasLabels } from '~/@types/enums/IndFeriasEnum'
import { VinculoFerias } from '~/hooks/queries/VinculoFerias/VinculoFerias'
import { VinculoFeriasItem } from '~/hooks/queries/VinculoFeriasItem/dtos/VinculoFeriasItem'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useQueryClient } from 'react-query'
import { Ativo } from '~/components/Displays'

interface PeriodoFeirasProps {
  vinculoFerias: VinculoFerias
}

export default function RegistrosFeiras({ vinculoFerias }: PeriodoFeirasProps) {
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useGetByVinculoFerias({ vinculoFeriasId: vinculoFerias.id })
  const data = _data || []

  const queryClient = useQueryClient()

  const { mutateAsync, isLoading: isDeleting } = useDelete()
  const { anoMes } = useAmbiente()

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoFeriasItem | null>(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  async function handleConfirmDelete() {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
    queryClient.invalidateQueries(queryGetByVinculo)
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'anoMes',
      label: 'Ano/Mês',
      options: {
        customBodyRender: (value) => formatPrettyAnoMes(value),
      },
    },
    {
      name: 'dtAvisoFerias',
      label: 'Aviso Férias',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtEmissaoFerias',
      label: 'Emissão Férias',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtInicioConcessao',
      label: 'Início Concessão',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtFimConcessao',
      label: 'Fim Concessão',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtRetorno',
      label: 'Retorno',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtInicioAbono',
      label: 'Início Abono',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtFimAbono',
      label: 'Fim Abono',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'nrDiasAbono',
      label: 'Dias Abono',
    },
    {
      name: 'nrDiasConcedidos',
      label: 'Dias Concedidos',
    },
    {
      name: 'indFerias',
      label: 'Tipo',
      options: {
        customBodyRender: (value: IndFeriasEnum) => IndFeriasLabels[value],
      },
    },
    {
      name: 'rpId',
      label: 'Recibo',
      options: {
        customBodyRender: (value) => <Ativo ativo={value ? true : false} />,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dataFinded = _data?.find((d) => d.id === value)
                  if (dataFinded) {
                    openForm(dataFinded)
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  openConfirmDelete(value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack height="100%">
      <PageHeader title="Registros de Férias">
        <ButtonBox top={1}>
          <Button size="small" color="primary" onClick={() => openForm(null)} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          pagination={false}
          isLoading={isLoading}
          isFetching={isFetching}
          disableAutoHeight
        />
      </ContainerTable>

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          data={dataForm}
          dataInitial={
            {
              anoMes,
              indFerias: IndFeriasEnum.Mensal_0,
              vinculoFeriasId: vinculoFerias.id,
            } as VinculoFeriasItem
          }
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isDeleting}
          onCancel={closeConfirmDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </Stack>
  )
}
