export enum CategoriaEnum {
  Empregado_Geral_101 = 101,
  Empregado_Rural_102 = 102,
  Empregado_Aprendiz_103 = 103,
  Empregado_Domestico_104 = 104,
  Empregado_Contrato_Termo_105 = 105,
  Trabalhador_Temporário_106 = 106,
  Empregado_VerdeAmarelo_107 = 107,
  Empregado_VerdeamareloMultaFGTS_108 = 108,
  Empregado_Intermitente_111 = 111,
  Trabalhador_Avulso_Portuario_201 = 201,
  Trabalhador_Avulso_Nao_Portuario_202 = 202,
  Servidor_301 = 301,
  Servidor_302 = 302,
  Exercente_Mandato_303 = 303,
  Servidor_304 = 304,
  Servidor_305 = 305,
  Servidor_306 = 306,
  Militar_307 = 307,
  Conscrito_308 = 308,
  Agente_Público_309 = 309,
  Servidor_Público_Eventual_310 = 310,
  Ministros_311 = 311,
  Auxiliar_312 = 312,
  Servidor_313 = 313,
  Dirigente_Sindical_401 = 401,
  Trabalhador_Cedido_410 = 410,
  Dirigente_Sindical_501 = 501,
  Contribuinte_Individual_Autonomo_701 = 701,
  Contribuinte_Individual_Transportador_Passageiros_711 = 711,
  Contribuinte_Individual_Transportador_Carga_712 = 712,
  Contribuinte_Individual_Diretor_Com_FGTS_721 = 721,
  Contribuinte_Individual_Diretor_Sem_FGTS_722 = 722,
  Contribuinte_Individual_Empresario_723 = 723,
  Contribuinte_Individual_Cooperado_731 = 731,
  Contribuinte_Individual_Transportador_Cooperado_734 = 734,
  Contribuinte_Individual_Cooperado_Produção_738 = 738,
  Contribuinte_Individual_Microempreendedor_Individual_741 = 741,
  Contribuinte_Individual_Magistrado_751 = 751,
  Contribuinte_Individual_Associado_761 = 761,
  Contribuinte_Individual_Membro_Conselho_Tutelar_771 = 771,
  Ministro_Confissão_Religiosa_781 = 781,
  Estagiario_901 = 901,
  Medico_902 = 902,
  Bolsista_903 = 903,
  Bolsista_904 = 904,
  Beneficiario_prestacao_servico_voluntario_906 = 906,
  NaoDefinida_999 = 999,
}

export const CategoriaValues = [
  {
    value: CategoriaEnum.Empregado_Geral_101,
    name: '101 - Empregado - Geral, inclusive o empregado público da administração direta ou indireta contratado pela CLT.',
  },
  {
    value: CategoriaEnum.Empregado_Rural_102,
    name: '102 - Empregado - Trabalhador Rural por Pequeno Prazo da Lei 11.718/2008 103 Empregado - Aprendiz',
  },
  {
    value: CategoriaEnum.Empregado_Aprendiz_103,
    name: '103 - Empregado - Aprendiz',
  },
  {
    value: CategoriaEnum.Empregado_Domestico_104,
    name: '104 - Empregado - Doméstico',
  },
  {
    value: CategoriaEnum.Empregado_Contrato_Termo_105,
    name: '105 - Empregado - contrato a termo firmado nos termos da Lei 9601/98 106 Trabalhador Temporário - contrato por prazo determinado nos termos da Lei 6019/74  Avulso',
  },
  {
    value: CategoriaEnum.Trabalhador_Temporário_106,
    name: '106 - Trabalhador Temporário - contrato por prazo determinado nos termos da Lei 6019/74  Avulso',
  },
  {
    value: CategoriaEnum.Empregado_VerdeAmarelo_107,
    name: '107 - Empregado - Contrato de trabalho Verde e Amarelo - sem acordo para antecipação mensal da multa rescisória do FGTS',
  },
  {
    value: CategoriaEnum.Empregado_VerdeamareloMultaFGTS_108,
    name: '108 - Empregado - Contrato de trabalho Verde e Amarelo - com acordo para antecipação mensal da multa rescisória do FGTS',
  },
  {
    value: CategoriaEnum.Empregado_Intermitente_111,
    name: '111 - Trabalhador Intermitente',
  },
  {
    value: CategoriaEnum.Trabalhador_Avulso_Portuario_201,
    name: '201 - Trabalhador Avulso Portuário',
  },
  {
    value: CategoriaEnum.Trabalhador_Avulso_Nao_Portuario_202,
    name: '202 - Trabalhador Avulso Não Portuário Agente Público',
  },
  {
    value: CategoriaEnum.Servidor_301,
    name: '301 - Servidor Público Titular de Cargo Efetivo, Magistrado, Ministro de Tribunal de Contas, Conselheiro de Tribunal de Contas e Membro do Ministério Público',
  },
  {
    value: CategoriaEnum.Servidor_302,
    name: '302 - Servidor Público Ocupante de Cargo exclusivo em comissão',
  },
  {
    value: CategoriaEnum.Exercente_Mandato_303,
    name: '303 - Agente Político',
  },
  {
    value: CategoriaEnum.Servidor_304,
    name: '304 - Servidor público exercente de mandato eletivo, inclusive com exercício de cargo em comissão',
  },
  {
    value: CategoriaEnum.Servidor_305,
    name: '305 - Servidor Público indicado para conselho ou órgão representativo, na condição de representante do governo, órgão ou entidade da administração pública.',
  },
  {
    value: CategoriaEnum.Servidor_306,
    name: '306 - Servidor Público Temporário, sujeito a regime administrativo especial definido em lei própria',
  },
  {
    value: CategoriaEnum.Militar_307,
    name: '307 - Militar efetivo',
  },
  {
    value: CategoriaEnum.Conscrito_308,
    name: '308 - Conscrito',
  },
  {
    value: CategoriaEnum.Agente_Público_309,
    name: '309 - Agente Público - Outros Cessão',
  },
  {
    value: CategoriaEnum.Servidor_Público_Eventual_310,
    name: '310 - Servidor público eventual',
  },
  {
    value: CategoriaEnum.Ministros_311,
    name: '311 - Ministros, juízes, procuradores, promotores ou oficiais de justiça à disposição da Justica Eleitoral',
  },
  {
    value: CategoriaEnum.Auxiliar_312,
    name: '312 - Auxiliar local',
  },
  {
    value: CategoriaEnum.Servidor_313,
    name: '313 - Servidor público exercente de atividade de instrutoria, capacitação, treinamento, curso ou concurso, ou convocado para pareceres técnicos ou depoimentos Cessão',
  },
  {
    value: CategoriaEnum.Dirigente_Sindical_401,
    name: '401 - Dirigente Sindical - informação prestada pelo Sindicato',
  },
  {
    value: CategoriaEnum.Trabalhador_Cedido_410,
    name: '410 - Trabalhador cedido - informação prestada pelo Cessionário Contribuinte  Individual',
  },
  {
    value: CategoriaEnum.Dirigente_Sindical_501,
    name: '501 Dirigente sindical - Segurado especial Contribuinte Individual',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Autonomo_701,
    name: '701 - Contribuinte individual - Autônomo em geral, exceto se enquadrado em uma das demais categorias de contribuinte individual',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Transportador_Passageiros_711,
    name: '711 - Contribuinte individual - Transportador autônomo de passageiros',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Transportador_Carga_712,
    name: '712 - Contribuinte individual - Transportador autômomo de carga',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Diretor_Com_FGTS_721,
    name: '721 - Contribuinte individual - Diretor não empregado, com FGTS',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Diretor_Sem_FGTS_722,
    name: '722 - Contribuinte individual - Diretor não empregado, sem FGTS',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Empresario_723,
    name: '723 - Contribuinte individual - empresários, sócios e membro de conselho de administração ou fiscal',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Cooperado_731,
    name: '731 - Contribuinte individual - Cooperado que presta serviços por intermédio de Cooperativa de Trabalho',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Transportador_Cooperado_734,
    name: '734 - Contribuinte individual - Transportador Cooperado que presta serviços por intermédio de cooperativa de trabalho',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Cooperado_Produção_738,
    name: '738 - Contribuinte individual - Cooperado filiado a Cooperativa de Produção',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Microempreendedor_Individual_741,
    name: '741 - Contribuinte individual - Micro Empreendedor Individual',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Magistrado_751,
    name: '751 - Contribuinte individual - magistrado classista temporário da Justiça do Trabalho ou da Justiça Eleitoral que seja aposentado de qualquer regime previdenciário',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Associado_761,
    name: '761 - Contribuinte individual - Associado eleito para direção de Cooperativa, associação ou entidade de classe de qualquer natureza ou finalidade, bem como o síndico ou administrador eleito para exercer atividade de direção condominial, desde que recebam remuneração',
  },
  {
    value: CategoriaEnum.Contribuinte_Individual_Membro_Conselho_Tutelar_771,
    name: '771 - Contribuinte individual - Membro de conselho tutelar, nos termos da Lei nº 8.069, de 13 de julho de 1990',
  },
  {
    value: CategoriaEnum.Ministro_Confissão_Religiosa_781,
    name: '781 - Ministro de confissão religiosa ou membro de vida consagrada, de congregação ou de ordem religiosa Bolsistas',
  },
  {
    value: CategoriaEnum.Estagiario_901,
    name: '901 - Estagiário',
  },
  {
    value: CategoriaEnum.Medico_902,
    name: '902 - Médico Residente',
  },
  {
    value: CategoriaEnum.Bolsista_903,
    name: '903 - Bolsista, nos termos da lei 8958/1994',
  },
  {
    value: CategoriaEnum.Bolsista_904,
    name: '904 - Participante de curso de formação, com etapa de concurso público, sem vínculo de emprego/estatutário',
  },
  {
    value: CategoriaEnum.Beneficiario_prestacao_servico_voluntario_906,
    name: '906 - Beneficiário do Programa Nacional de Prestação de Serviço Civil Voluntário',
  },
  {
    value: CategoriaEnum.NaoDefinida_999,
    name: '999 - Código interno para categoria não definida',
  },
]

export const categoriaEstagiario = [
  CategoriaEnum.Estagiario_901,
  CategoriaEnum.Medico_902,
  CategoriaEnum.Bolsista_903,
]

export const cateogriaEmpregadorContribuinteIndividual = [
  CategoriaEnum.Contribuinte_Individual_Diretor_Com_FGTS_721,
  CategoriaEnum.Contribuinte_Individual_Diretor_Sem_FGTS_722,
  CategoriaEnum.Contribuinte_Individual_Empresario_723,
]
