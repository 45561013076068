import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryObterDocumentos } from './useObterDocumentos'

interface RequestProps {
  estabelecimentoId: string
  competencia: string
  descricao: string
  file: FormData
}

export function useUploadDocumento() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    const { estabelecimentoId, competencia, descricao, file } = props
    await api.post('/GerenciadorDocumentos/UploadDocumentos', file, {
      params: {
        estabelecimentoId,
        competencia,
        descricao,
      },
    })
    notification.success('Documento enviado com Sucesso')
  }

  return useMutation(handleRequest, {
    onSuccess: async () => await queryClient.invalidateQueries(queryObterDocumentos),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
