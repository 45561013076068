const contaCorrente_1 = 1
const contaPoupanca_2 = 2
const contaSalario_3 = 3

export const indicadorContaBancariaValues = [
  {
    value: contaCorrente_1,
    name: 'Conta corrente',
  },
  {
    value: contaPoupanca_2,
    name: 'Conta poupança',
  },
  {
    value: contaSalario_3,
    name: 'Conta salário',
  },
]

export const indicadorContaBancariaConsts = {
  contaCorrente_1,
  contaPoupanca_2,
  contaSalario_3,
}
