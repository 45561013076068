import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryObterDataFaltas } from './useObterDataFaltas'

export function useRemoveVinculoFaltas() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(id: string) {
    await api.delete('/VinculoFaltas/' + id)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.remove()
      await queryClient.invalidateQueries(queryObterDataFaltas)
    },
    onError: dialogNotification.extractErrors,
  })
}
