const geral_1 = 1
const lotacao_2 = 2
const setor_3 = 3

export const modeloImpressaoValues = [
  {
    name: 'Geral',
    value: geral_1,
  },
  {
    name: 'Lotação',
    value: lotacao_2,
  },
  {
    name: 'Setor',
    value: setor_3,
  },
]

export const modeloImpressaoConsts = {
  geral_1,
  lotacao_2,
  setor_3,
}
