import React from 'react'

import PropTypes from 'prop-types'

import {
  Radio,
  RadioGroup as MUIRadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core'

export default function RadioGroup(props) {
  const { label, options, onChange, value, row = true } = props

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <MUIRadioGroup
        row={row}
        aria-label={label}
        name={label}
        value={value}
        onChange={handleChange}
      >
        {options.map(({ value, label, disabled }, index) => (
          <FormControlLabel
            key={index}
            value={value}
            disabled={disabled}
            control={<Radio />}
            label={label}
          />
        ))}
      </MUIRadioGroup>
    </FormControl>
  )
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  row: PropTypes.bool,
}
