import React, { useCallback, useEffect, useState } from 'react'

import { Box } from '@material-ui/core'

import { PageHeader } from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'

export default function LotesPorEmpregador() {
  const [isLoading, setLoading] = useState(false)
  const [isFetching, setFetching] = useState(false)
  const [collection, setCollection] = useState([])

  const { empregador } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const getCollection = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get('/ESocial/ObterLotePorEmpregador', {
        params: {
          empregadorId: empregador?.id,
        },
      })
      setCollection(response?.data?.data || [])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [empregador])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  async function handleClickDelete(loteCurrent) {
    setFetching(true)
    try {
      await api.post('/ESocial/ExcluirLotePorEmpregador', null, {
        params: {
          empregadorId: loteCurrent.empregadorId,
          loteId: loteCurrent.id,
        },
      })
      notification.remove()
      getCollection()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setFetching(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Lotes por Empregador" />
      <Table
        data={collection}
        isLoading={isLoading}
        onClickDelete={handleClickDelete}
        isFetching={isFetching}
      />
    </Box>
  )
}
