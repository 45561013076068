import React, { useEffect, useState } from 'react'

import { MaskedTextField } from '~/components'

import { ErrorMessage } from 'mio-library-ui'

import PropType from 'prop-types'

const obterErro = (name, validationErrors) => {
  if (!validationErrors) return false

  const { inner } = validationErrors
  const erroEncontrado = inner.find((item) => {
    const { path } = item
    return name === path
  })

  if (!erroEncontrado) return false

  return erroEncontrado.message
}

export default function SimpleHoraTextField(props) {
  const { value: _value, name, validationErrors, ...rest } = props

  const mensagemDeErro = obterErro(name, validationErrors)

  const [mask, setMask] = useState('99:99')

  useEffect(() => {
    const valueString = _value.toString().replace('_', '')
    if (valueString.length <= 5) setMask('99:99')
    if (valueString.length >= 6) setMask('999:99')
  }, [_value])

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    if (userInput) {
      let newStart = newState.selection.start
      let oldStart = oldState.selection.start
      if (newStart < 5) {
        setMask('99:99')
      }
      if (newStart === oldStart) {
        setMask('999:99')
      }
    }

    const { value } = newState
    const selection = newState.selection
    return {
      value,
      selection,
    }
  }

  return (
    <React.Fragment>
      <MaskedTextField
        mask={mask}
        value={_value}
        beforeMaskedValueChange={beforeMaskedValueChange}
        variant="outlined"
        size="small"
        fullWidth
        {...rest}
      />
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </React.Fragment>
  )
}

SimpleHoraTextField.propTypes = {
  onChange: PropType.func,
  label: PropType.string,
  title: PropType.string,
  mask: PropType.string,
  value: PropType.any,
  disabled: PropType.bool,
}
