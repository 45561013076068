export enum OrigemRetificacaoAfastamentoEnum {
  PorIniciativaEmpregador_1 = 1,
  RevisãoAdministrativa_2 = 2,
  DeterminaçãoJudicial_3 = 3,
  NaoAplicavel_9 = 9,
}

export const OrigemRetificacaoAfastamentoValues = [
  {
    value: OrigemRetificacaoAfastamentoEnum.NaoAplicavel_9,
    name: '9 - Não informar',
  },
  {
    value: OrigemRetificacaoAfastamentoEnum.PorIniciativaEmpregador_1,
    name: '1 - Por iniciativa do empregador',
  },
  {
    value: OrigemRetificacaoAfastamentoEnum.RevisãoAdministrativa_2,
    name: '2 - Revisão administrativa',
  },
  {
    value: OrigemRetificacaoAfastamentoEnum.DeterminaçãoJudicial_3,
    name: '3 - Determinação judicial',
  },
]
