import React, { useCallback } from 'react'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { formatToBRL } from 'brazilian-values'

const makeColumns = () => [
  {
    name: 'faixa',
    label: 'Faixa',
  },
  {
    name: 'vrFaixaInit',
    label: 'De',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'vrFaixaEnd',
    label: 'Até',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'aliqFaixa',
    label: 'Alíquota',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{`${formatToBRL(value).replace('R$', '')} %`}</ButtonBox>
      },
    },
  },
  {
    name: 'vrDeducaoFaixa',
    label: 'Parcela a Deduzir',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$ ', '')}</ButtonBox>
      },
    },
  },
]

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = useCallback(() => {
    return makeColumns(onItemClick)
  }, [onItemClick])

  return (
    <div>
      <DataTable
        title=""
        data={data}
        columns={columns()}
        options={options}
        isLoading={isLoading}
        pagination={false}
      />
    </div>
  )
}

const options = {
  viewColumns: false,
  filter: false,
  search: false,
  selectableRows: 'none',
  print: false,
  download: false,
  elevation: 0,
  responsive: 'standard',
}

Table.defaultProps = {
  query: '',
}

export default Table
