const eSocialStatusValues_todos_registros = 100
const eSocialStatusValues_inativos = 99
const eSocialStatusValues_Aberto_10 = 10
const eSocialStatusValues_Pendente_GerarXMLEnvio_11 = 11
const eSocialStatusValues_Pendente_GerarXMLLote_12 = 12
const eSocialStatusValues_Em_Transmissao_13 = 13
const eSocialStatusValues_Pendente_ProcessamentoRetorno_14 = 14
const eSocialStatusValues_Finalizado_21 = 21
const eSocialStatusValues_Finalizado_Advertencia_22 = 22
const eSocialStatusValues_Erro_Schema_41 = 41
const eSocialStatusValues_Erro_Envio_42 = 42
const eSocialStatusValues_Erro_BuscarRecibo_43 = 43

export const eSocialStatusValues = [
  { name: 'Todos', value: eSocialStatusValues_todos_registros },
  { name: 'Inativos', value: eSocialStatusValues_inativos },
  { name: 'Registro em Aberto', value: eSocialStatusValues_Aberto_10 },
  {
    name: 'Registro pendente de gerar o xml para envio',
    value: eSocialStatusValues_Pendente_GerarXMLEnvio_11,
  },
  {
    name: 'Registro pendente de gerar o xml do lote',
    value: eSocialStatusValues_Pendente_GerarXMLLote_12,
  },
  {
    name: 'Registro está em processo de transmissao controlado pelo lote',
    value: eSocialStatusValues_Em_Transmissao_13,
  },
  {
    name: 'Registro com recibo aguardando o processamento do retorno',
    value: eSocialStatusValues_Pendente_ProcessamentoRetorno_14,
  },
  {
    name: 'Registro finalizado com sucesso',
    value: eSocialStatusValues_Finalizado_21,
  },
  {
    name: 'Registro finalizado com advertência',
    value: eSocialStatusValues_Finalizado_Advertencia_22,
  },
  {
    name: 'Registro com erro de schema',
    value: eSocialStatusValues_Erro_Schema_41,
  },
  {
    name: 'Registro com erro no envio ',
    value: eSocialStatusValues_Erro_Envio_42,
  },
  {
    name: 'Registro com erro ao buscar Recibo',
    value: eSocialStatusValues_Erro_BuscarRecibo_43,
  },
]

export const eSocialStatusConsts = {
  eSocialStatusValues_todos_registros,
  eSocialStatusValues_inativos,
  eSocialStatusValues_Aberto_10,
  eSocialStatusValues_Pendente_GerarXMLEnvio_11,
  eSocialStatusValues_Pendente_GerarXMLLote_12,
  eSocialStatusValues_Em_Transmissao_13,
  eSocialStatusValues_Pendente_ProcessamentoRetorno_14,
  eSocialStatusValues_Finalizado_21,
  eSocialStatusValues_Finalizado_Advertencia_22,
  eSocialStatusValues_Erro_Schema_41,
  eSocialStatusValues_Erro_Envio_42,
  eSocialStatusValues_Erro_BuscarRecibo_43,
}
