import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoMenuDTO } from './VinculoMenuDTO'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'

export const queryGetVinculosMenu2 = '/RP/GetVinculosMenu2'

interface RequestProps {
  estabelecimentoId: string
  anoMes: string
  categoria: CategoriaEnum | null
  idf: ReciboIDFEnum[]
}

export function useGetVinculosMenu2(props: RequestProps) {
  const { estabelecimentoId, anoMes, categoria, idf } = props

  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: VinculoMenuDTO[] }>(queryGetVinculosMenu2, idf, {
      params: {
        estabelecimentoId,
        anoMes,
        categoria,
      },
    })
    return response.data.data
  }

  return useQuery([queryGetVinculosMenu2, props], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
