import React, { useState, useCallback, useEffect } from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { AutoCompleteContador } from '~/components/AutoComplete'
import { CheckList } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'
import { formatToCPFOrCNPJ } from 'brazilian-values'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    height: '100%',
  },
  fullHeight: {
    height: '70%',
  },
}))

const Form = (props) => {
  const { isOpen, onClose, onAfterSubmitForm, certificadoDigital } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [checked, setChecked] = useState([])
  const [empregadores, setEmpregadores] = useState([])
  const [contador, setContador] = useState({})

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  const getEmpregadores = useCallback(async (contadorId) => {
    setLoading(true)
    try {
      const response = await api.get('/EmpregadorConsulta/GetCertificado', {
        params: {
          contadorId,
        },
      })
      setEmpregadores(response.data.data)
      setChecked([])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isOpen) getEmpregadores()
  }, [isOpen, getEmpregadores])

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)
    try {
      if (checked.length === 0) throw new Error('Informe pelo menos um Empregador')
      const ids = checked.map((item) => item.id)
      await api.put(
        `/CertificadoDigital/AtualizarEmpregadores?certificadoId=${certificadoDigital?.id}`,
        ids,
      )
      notification.post()
      onAfterSubmitForm()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setSubmitting(false)
    // eslint-disable-next-line
  }, [checked, certificadoDigital, onAfterSubmitForm])

  return (
    <ActionDialog
      title="Relacionar Certificado Digital"
      subtitle={certificadoDigital?.requerente || ''}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'xs',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
    >
      <Box className={classes.root}>
        <Box pb={1}>
          <div>Selecione os Empregadores que deseja relacionar o Certificado Digital</div>
          <strong>
            {formatToCPFOrCNPJ(certificadoDigital?.requerenteNrInscricao || '')} -{' '}
            {certificadoDigital?.requerente}
          </strong>
        </Box>

        <Box pb={1}>
          <AutoCompleteContador
            value={contador}
            onChange={(e, obj) => {
              const contadorId = obj?.id || ''
              const contador = obj || null
              setContador(contador)
              getEmpregadores(contadorId)
              setChecked([])
            }}
          />
        </Box>

        <CheckList
          options={empregadores}
          optionsCheckeds={checked}
          onChange={setChecked}
          renderPrimaryOption={(option) => `${option?.codigo} - ${option?.nome}`}
          isLoading={isLoading}
          searchBy="nome"
        />
      </Box>
    </ActionDialog>
  )
}

export default Form
