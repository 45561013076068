import React from 'react'

import clsx from 'clsx'

import { Box, Icon, StepIconProps, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: theme.palette.success.main,
  },
  skipped: {
    backgroundColor: theme.palette.warning.light,
  },
  iconBox: {
    width: '1em',
    height: '1em',
    fontSize: '1.5rem',
  },
}))

interface StepperIconProps extends Omit<StepIconProps, 'icon'> {
  icon: string | number
  skipped: boolean
}

export default function StepperIcon(props: StepperIconProps) {
  const { icon, active, completed, skipped } = props

  const classes = useStyles()

  const isIcon = typeof icon === 'string'

  const classNameToUse = clsx(classes.root, {
    [classes.active]: active,
    [classes.completed]: completed,
    [classes.skipped]: skipped,
  })

  if (completed) return <Icon className={classNameToUse}>check</Icon>
  if (skipped) return <Icon className={classNameToUse}>redo</Icon>
  if (!isIcon) return <Box className={clsx(classNameToUse, classes.iconBox)}>{icon}</Box>
  return <Icon className={classNameToUse}>{icon}</Icon>
}
