import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { VinculoAlteracaoContrato2206DTO } from './VinculoAlteracaoContrato2206DTO'

export function useCriarEvento2206() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: VinculoAlteracaoContrato2206DTO) {
    await api.post('/VinculoAlteracaoContratoCadastro/CriarEvento2206', data)
    notification.post()
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries('VinculoAlteracaoContratoCadastroGetByVinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
