import { useState, useCallback } from 'react'

import { Box, Grid } from '@material-ui/core'
import { TextField, ActionDialog, DatePickerNew, Stack } from '~/components'

import RegistrosFeiras from './RegistrosFeiras'

import * as yup from 'yup'
import { isValid } from 'date-fns'
import moment from 'moment'

import api from '~/services/api-pessoal'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoFerias } from '~/hooks/queries/VinculoFerias/VinculoFerias'

export enum AfterSubmitActions {
  'update',
  'insert',
}

const schema = yup.object().shape({
  dtPeriodoInicio: yup.string().required('Informe Data Início').nullable(),
  dtPeriodoFim: yup.string().required('Informe Data Fim').nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoFerias | null
  dataInitial: VinculoFerias
  onAfterSubmitForm: (event: AfterSubmitActions, data: VinculoFerias) => void
}

export default function Form({
  isOpen,
  onClose,
  data: _data,
  dataInitial,
  onAfterSubmitForm,
}: FormProps) {
  const [data, setData] = useState(_data || dataInitial)
  const [isSubmitting, setSubmitting] = useState(false)
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  const isNovaData = _data ? false : true

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/vinculoFerias/${data.id}`, data)
        onAfterSubmitForm(AfterSubmitActions.update, response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/vinculoFerias', data)
        notification.post()
        onAfterSubmitForm(AfterSubmitActions.insert, response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (isNovaData) {
      insert()
      return
    }
    update()

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Férias"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: isNovaData ? 'sm' : 'lg', fullWidth: true }}
    >
      <Stack height="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={isNovaData ? 6 : 4}>
            <DatePickerNew
              label="Data Início"
              size="small"
              value={data.dtPeriodoInicio || null}
              validationErrors={validationErrors}
              name="dtPeriodoInicio"
              onChange={(date) => {
                const dtPeriodoInicio = date || ''

                if (isValid(new Date(dtPeriodoInicio)) && !data.dtPeriodoFim) {
                  const dtMoment = moment(date)

                  const dtPeriodoFim = dtMoment
                    ? dtMoment.add(1, 'year').add(-1, 'day').format('yyyy-MM-DD')
                    : ''
                  const dtLimiteConcessao = dtMoment
                    ? dtMoment.add(1, 'year').add(-61, 'day').format('yyyy-MM-DD')
                    : ''

                  setData({
                    ...data,
                    dtPeriodoInicio,
                    dtPeriodoFim,
                    dtLimiteConcessao,
                  })
                } else {
                  setData({
                    ...data,
                    dtPeriodoInicio,
                  })
                }
              }}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={isNovaData ? 6 : 4}>
            <DatePickerNew
              label="Data Fim"
              size="small"
              value={data.dtPeriodoFim || null}
              validationErrors={validationErrors}
              name="dtPeriodoFim"
              onChange={(date) => {
                const dtPeriodoFim = date || ''

                if (isValid(new Date(dtPeriodoFim)) && !data.dtLimiteConcessao) {
                  const dtMoment = moment(dtPeriodoFim)

                  const dtLimiteConcessao = dtMoment
                    ? dtMoment.add(1, 'year').add(-61, 'day').format('yyyy-MM-DD')
                    : ''

                  setData({ ...data, dtPeriodoFim, dtLimiteConcessao })
                } else {
                  setData({ ...data, dtPeriodoFim })
                }
              }}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={isNovaData ? 6 : 4}>
            <DatePickerNew
              label="Data Limite Concessão"
              size="small"
              value={data.dtLimiteConcessao || null}
              onChange={(date) => {
                const dtLimiteConcessao = date
                setData({ ...data, dtLimiteConcessao })
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={isNovaData ? 6 : 4}>
            <DatePickerNew
              label="Data Programação"
              size="small"
              value={data.dtProgramacao || null}
              onChange={(date) => {
                const dtProgramacao = date
                setData({ ...data, dtProgramacao })
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={isNovaData ? 6 : 4}>
            <DatePickerNew
              label="Data Período Perdido"
              size="small"
              value={data.dtPeriodoPerdido || null}
              onChange={(date) => {
                const dtPeriodoPerdido = date
                setData({ ...data, dtPeriodoPerdido })
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={isNovaData ? 6 : 4}>
            <DatePickerNew
              label="Data Período Concedido"
              size="small"
              value={data.dtConcedida || null}
              onChange={(date) => {
                const dtConcedida = date
                setData({ ...data, dtConcedida })
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Observação"
              value={data.observacao || ''}
              multiline
              inputProps={{
                maxLength: 200,
              }}
              onChange={(e) => {
                const observacao = e.target.value
                setData({ ...data, observacao })
              }}
            />
          </Grid>
        </Grid>

        <Box height="100%" flex={1}>
          {!isNovaData && <RegistrosFeiras vinculoFerias={data} />}
        </Box>
      </Stack>
    </ActionDialog>
  )
}
