import React, { useState, useEffect } from 'react'

import { Button } from '@material-ui/core'
import { ContentContainer, ButtonBox, Finder, PageHeader } from 'mio-library-ui'

import { ConfirmDeleteDialog } from '~/components'

import Table from './components/Table'
import Container from './components/Container'

import api from '~/services/api-pessoal'
import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

const { CNPJ_1 } = tipoInscricaoConsts

const Lotacao = () => {
  const [items, setItems] = useState([])

  const [container, setContainer] = useState({
    isOpen: false,
    data: {},
  })

  const [dialogDelete, setDialogDelete] = useState({
    isOpen: false,
  })
  const [isProcessing, setIsProcessing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')

  const { empregador, estabelecimento, anoMes } = useAmbiente()
  const notifications = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    const updateList = async () => {
      setIsLoading(true)
      try {
        const response = await api.get(`/lotacao/GetByEmpregador?empregadorId=${empregador?.id}`)
        setItems(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setIsLoading(false)
      }
    }
    updateList()
    //eslint-disable-next-line
  }, [empregador?.id])

  const handleAfterSubmit = (event, data) => {
    const functionsMap = {
      onAdicionar: onAdd,
      onAtualizar: onEditado,
      onDeletar: onDeletado,
    }
    functionsMap[event](data)
  }

  const onAdd = (data) => {
    setItems([data, ...items])
    handleCloseContainer()
    setContainer({
      isOpen: true,
      data,
    })
  }

  const onEditado = (data) => {
    const novaLista = items.map((obj) => {
      if (obj.id === data.id) {
        return data
      } else {
        return obj
      }
    })
    setItems(novaLista)
    handleCloseContainer()
  }

  const onDeletado = (data) => {
    const novaLista = items.filter((obj) => obj.id !== data.id)
    setItems(novaLista)
    handleCloseContainer()
  }

  const openDialogCadastro = (id) => {
    const data = items.find((obj) => obj.id === id)
    setContainer({
      isOpen: true,
      data,
    })
  }

  const handleCloseContainer = () => {
    setContainer({
      isOpen: false,
      data: {},
    })
  }

  const handleClickItem = (event, data) => {
    const functionsMap = {
      edit: openDialogCadastro,
      delete: openConfirmaDeletar,
    }
    functionsMap[event](data)
  }

  const openConfirmaDeletar = (id) => {
    const data = items.find((obj) => obj.id === id)
    setDialogDelete({
      ...dialogDelete,
      isOpen: true,
      data,
    })
  }

  const closeConfirmaDeletar = () => {
    setDialogDelete({
      ...dialogDelete,
      isOpen: false,
    })
  }

  const deletar = async () => {
    setIsProcessing(true)
    try {
      const objDeletar = dialogDelete.data
      await api.delete(`/lotacao/${objDeletar.id}`)
      handleAfterSubmit('onDeletar', objDeletar)
      notifications.remove()
      closeConfirmaDeletar()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsProcessing(false)
  }

  const onClickAdd = async () => {
    let fpasId = ''
    let codigoTerceirosId = ''
    try {
      const response = await api.get(
        `/EstabelecimentoSituacao/GetByEstabelecimento?id=${estabelecimento?.id}`,
      )
      fpasId = response.data.data[0].fpasId
      codigoTerceirosId = response.data.data[0].terceirosId
    } catch (error) {
      console.log(error)
    }
    setContainer({
      isOpen: true,
      data: {
        fpasId,
        codigoTerceirosId,
        empregadorId: empregador?.id,
        tipoInscricao: CNPJ_1,
      },
    })
  }

  return (
    <ContentContainer>
      <PageHeader title="Lotação">
        <ButtonBox>
          <Finder onSearch={setQuery} searching={isLoading} onClose={() => setQuery('')} />
          <Button size="small" color="primary" onClick={onClickAdd} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Table data={items} onItemClick={handleClickItem} isLoading={isLoading} query={query} />

      <Container
        isOpen={container.isOpen}
        data={container.data}
        onClose={handleCloseContainer}
        onAfterSubmitForm={handleAfterSubmit}
        estabelecimento={estabelecimento}
        anoMes={anoMes}
      />

      <ConfirmDeleteDialog
        isOpen={dialogDelete.isOpen}
        isDeleting={isProcessing}
        onCancel={closeConfirmaDeletar}
        onConfirm={deletar}
      />
    </ContentContainer>
  )
}

export default Lotacao
