import React, { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtAssinatura: yup
    .date()
    .required('Informe a Data da Assinatura')
    .typeError('Informe uma data válida')
    .nullable(),
})

interface FormDataProps {
  dtAssinatura: string | null
}
export interface FormDataSubmitProps {
  dtAssinatura: string
}

const initialState: FormDataSubmitProps = {
  dtAssinatura: getDateCurrent(),
}

interface DialogConfirmProps {
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: (dt: FormDataSubmitProps) => void
}

export default function DialogConfirm(props: DialogConfirmProps) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState<FormDataProps>(initialState)
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    onAfterSubmit(data as FormDataSubmitProps)
    onClose()
  }

  return (
    <ActionDialog
      title="Informe os dados para o Termo de Autorização de Uso de Imagem e Voz"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid item xs={12}>
        <DatePickerNew
          label="Data da Assinatura"
          value={data.dtAssinatura}
          required
          name="dtAssinatura"
          validationErrors={validationErrors}
          onChange={(date) => {
            const dtAssinatura = date
            setData((prevState) => ({ ...prevState, dtAssinatura }))
          }}
        />
      </Grid>
    </ActionDialog>
  )
}
