import React, { useEffect, useState } from 'react'

import { TextField } from 'mio-library-ui'
import { Grid } from '@material-ui/core'

import * as yup from 'yup'

import { ActionDialog, DatePicker, RadioGroup } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  planoNome: yup.string().required('Informe o Nome do Plano'),
  planoTipo: yup.string().required('Informe o Tipo do Plano'),
  planoDtImplementacao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data da Implementação')
    .nullable(),
  dtAssinatura: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data da Assinatura')
    .nullable(),
})

const initialState = {
  isInclusao: 'false',
  planoTipo: '',
  planoNome: '',
  planoDtImplementacao: null,
  complementoOutroPlano: '',
  dtAssinatura: getDateCurrent(),
}

export default function DialogConfirm(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState(initialState)

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  useEffect(() => {
    if (isOpen) return
    setData(initialState)
  }, [isOpen])

  return (
    <ActionDialog
      title="Informe dados para a Declaração uso de Plano de Saúde"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <RadioGroup
            value={data?.isInclusao}
            onChange={(value) => {
              const isInclusao = value
              setData((prev) => ({ ...prev, isInclusao, complementoOutroPlano: '' }))
            }}
            options={[
              {
                label: 'Inclusão',
                value: 'true',
              },
              {
                label: 'Exclusão',
                value: 'false',
              },
            ]}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Nome do Plano"
            fullWidth
            value={data?.planoNome || ''}
            required
            validationErrors={validationErrors}
            name="planoNome"
            variant="outlined"
            size="small"
            onChange={(e) => {
              const planoNome = e.target.value
              setData((prev) => ({ ...prev, planoNome }))
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Tipo do Plano"
            fullWidth
            value={data?.planoTipo || ''}
            required
            validationErrors={validationErrors}
            name="planoTipo"
            variant="outlined"
            size="small"
            onChange={(e) => {
              const planoTipo = e.target.value
              setData((prev) => ({ ...prev, planoTipo }))
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Declaro ter o Seguinte Plano"
            fullWidth
            value={data?.complementoOutroPlano || ''}
            placeholder="Se o campo for preenchido será impresso"
            variant="outlined"
            size="small"
            disabled={data?.isInclusao !== 'false'}
            onChange={(e) => {
              const complementoOutroPlano = e.target.value
              setData((prev) => ({ ...prev, complementoOutroPlano }))
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data da Implementação"
            value={data?.planoDtImplementacao || null}
            required
            name="planoDtImplementacao"
            validationErrors={validationErrors}
            onChange={(date) => {
              const planoDtImplementacao = date ? date.format('yyyy-MM-DD') : null
              setData((prevState) => ({ ...prevState, planoDtImplementacao }))
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data da assinatura"
            value={data?.dtAssinatura || null}
            required
            name="dtAssinatura"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtAssinatura = date ? date.format('yyyy-MM-DD') : null
              setData((prevState) => ({ ...prevState, dtAssinatura }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
