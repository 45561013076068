import React, { useState } from 'react'

import { Grid, Box } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { Button, PageHeader } from '~/components'

import { CurrencyTextField, TextField } from '~/components'

import { useStepperContext } from '~/components/StepperForm'

import { MainDataForm } from '..'

export default function FormSalarioAula() {
  const { mainData, onBack, onNext, onSkip } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataSalarioAula)

  function handleBack() {
    onBack()
  }

  function handleNext() {
    if (mainData.dataInitialForm.tabelaSalarioAulaId) {
      onNext({
        ...mainData,
        dataSalarioAula: {
          ...data,
          dtSalario: mainData.dataInitialForm.dtAdmissao,
        },
      })
    } else {
      onSkip({
        ...mainData,
        dataSalarioAula: undefined,
      })
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <PageHeader title="Salário Aula" />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Aliq. Titulação"
          fullWidth
          value={data?.aliqTitulacao || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const aliqTitulacao = parseInt(e?.target?.value || '0')
            setData({ ...data, aliqTitulacao })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Infantil"
          fullWidth
          value={data?.infantil || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const infantil = parseInt(e?.target?.value || '0')
            setData({ ...data, infantil })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Fundamental I"
          fullWidth
          value={data?.fundamentalI || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const fundamentalI = parseInt(e?.target?.value || '0')
            setData({ ...data, fundamentalI })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Fundamental II"
          fullWidth
          value={data?.fundamentalII || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const fundamentalII = parseInt(e?.target?.value || '0')
            setData({ ...data, fundamentalII })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Fundamental III"
          fullWidth
          value={data?.fundamentalIII || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const fundamentalIII = parseInt(e?.target?.value || '0')
            setData({ ...data, fundamentalIII })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Jovem Adulto"
          fullWidth
          value={data?.jovemAdulto || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const jovemAdulto = parseInt(e?.target?.value || '0')
            setData({ ...data, jovemAdulto })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Curso Livre"
          fullWidth
          value={data?.cursoLivre || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const cursoLivre = parseInt(e?.target?.value || '0')
            setData({ ...data, cursoLivre })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Pre-Vestibular"
          fullWidth
          value={data?.preVestibular || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const preVestibular = parseInt(e?.target?.value || '0')
            setData({ ...data, preVestibular })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Superior"
          fullWidth
          value={data?.superior || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const superior = parseInt(e?.target?.value || '0')
            setData({ ...data, superior })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="EAD"
          fullWidth
          value={data?.ead || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const ead = parseInt(e?.target?.value || '0')
            setData({ ...data, ead })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <CurrencyTextField
          label="Especial"
          fullWidth
          value={data?.especial || ''}
          variant="outlined"
          size="small"
          onChange={(e, value) => {
            const especial = value || null
            setData({ ...data, especial })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Iniciacão Científica"
          fullWidth
          value={data?.iniciacaoCientifica || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const iniciacaoCientifica = parseInt(e?.target?.value || '0')
            setData({ ...data, iniciacaoCientifica })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="TCC"
          fullWidth
          value={data?.tcc || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const tcc = parseInt(e?.target?.value || '0')
            setData({ ...data, tcc })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Estágio"
          fullWidth
          value={data?.estagio || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const estagio = parseInt(e?.target?.value || '0')
            setData({ ...data, estagio })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Direito Autoral"
          fullWidth
          value={data?.direitoAutoral || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const direitoAutoral = parseInt(e?.target?.value || '0')
            setData({ ...data, direitoAutoral })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Excedente"
          fullWidth
          value={data?.excedente || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const excedente = parseInt(e?.target?.value || '0')
            setData({ ...data, excedente })
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Observação"
          fullWidth
          value={data?.observacao || ''}
          size="small"
          inputProps={{
            maxLength: 200,
          }}
          multiline
          onChange={(e) => {
            const observacao = e.target.value
            setData({ ...data, observacao })
          }}
        />
      </Grid>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleNext} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
