const exclusivamenteTributaria_E_Ou_FGTS_1 = 1
const exclusivamenteFGTS_CS_Rescisoria_7 = 7

export const processoIndicativoMateriaValues = [
  {
    value: exclusivamenteTributaria_E_Ou_FGTS_1,
    name: '1 - Exclusivamente Tributário ou Relativa ao FGTS',
  },
  {
    value: exclusivamenteFGTS_CS_Rescisoria_7,
    name: '7 - Exclusivamente FGTS e/ou Contribuição Social Rescisória',
  },
]
