import { useState, useEffect, useCallback } from 'react'

import { Grid, Collapse, LinearProgress } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog, ActionAccordion, ContentDivider, Stack } from '~/components'

import { DatePicker, CurrencyTextField } from '~/components'

import * as yup from 'yup'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'
import { AutoCompleteSalario, MUIAutoComplete } from '~/components/AutoComplete'

import {
  IndSalarioAdicionalValues,
  IndSalarioAdicionalEnum,
} from '~/@types/enums/IndSalarioAdicionalEnum'
import {
  IndBaseInsalubridadeEnum,
  IndBaseInsalubridadeValues,
} from '~/@types/enums/IndBaseInsalubridadeEnum'
import { FaixaSalarialEnum, FaixaSalarialValues } from '~/@types/enums/FaixaSalarialEnum'
import useObterSalarioBase from '~/hooks/queries/VinculoSalarioBase/useObterSalarioBase'
import {
  IndSalarioContratualEnum,
  IndSalarioContratualValues,
} from '~/@types/enums/IndSalarioContratualEnum'

const schema = yup.object().shape({
  dtInicio: yup
    .date()
    .required('Informe Data Início')
    .typeError('Informe uma Data válida')
    .nullable(),
  // .when('$dtAdmissao', (dtAdmissao, schema) => {
  //   return schema.test(
  //     'testDt',
  //     'A Data Início não pode ser menor que a Data de Admissão',
  //     (dtInicio) => {
  //       if (!dtAdmissao || !dtInicio) return false
  //       return moment(dtAdmissao).isSameOrBefore(dtInicio)
  //     },
  //   )
  // }),
  indAdicional: yup
    .number()
    .required('Informe o Indicador de Adicional')
    .nullable()
    .typeError('Informe um valor válido'),
  indBaseCalculoInsalubridade: yup
    .number()
    .when(['indAdicional'], (indAdicional, schema) => {
      if (indAdicional === IndSalarioAdicionalEnum.Insalubridade_1) {
        return schema.required('Informe o Indicador Base de Cálculo Insalubridade')
      }
    })
    .nullable()
    .typeError('Informe um valor válido'),
  faixaSalarialInsalubridade: yup
    .number()
    .when(['indBaseCalculoInsalubridade'], (indBaseCalculoInsalubridade, schema) => {
      if (
        indBaseCalculoInsalubridade === IndBaseInsalubridadeEnum.SalarioBaseCategoria_3 ||
        indBaseCalculoInsalubridade === IndBaseInsalubridadeEnum.SalarioBaseTabelaSalario_4
      ) {
        return schema
          .min(FaixaSalarialEnum.FaixaI, 'Informe a Faixa Salarial')
          .max(FaixaSalarialEnum.FaixaIV, 'Informe a Faixa Salarial')
          .required('Informe a Faixa Salarial')
      }
    })
    .nullable()
    .typeError('Informe um valor válido'),
  salarioId: yup
    .string()
    .when(['indBaseCalculoInsalubridade'], (indBaseCalculoInsalubridade, schema) => {
      if (indBaseCalculoInsalubridade === IndBaseInsalubridadeEnum.SalarioBaseTabelaSalario_4) {
        return schema.required('Informe o Salário')
      }
    })
    .nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, vinculo, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [lenghAliquota, setLenghAliquota] = useState(12)
  const [isSubmitting, setSubmitting] = useState(false)
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  const {
    data: dtSalarioBase,
    isLoading,
    isFetching,
  } = useObterSalarioBase({
    vinculoId: data?.vinculoId,
    dtSalario: data?.dtInicio,
  })

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/vinculoSalarioAdicional/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/vinculoSalarioAdicional', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: {
        dtAdmissao: vinculo?.dtAdmissao,
      },
    },
  })

  return (
    <ActionDialog
      title="Cadastro de Salário Adicional"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Início"
            size="small"
            value={data.dtInicio || null}
            required
            disabled
            validationErrors={validationErrors}
            name="dtInicio"
            onChange={(date) => {
              const dtInicio = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtInicio })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Fim"
            size="small"
            value={data.dtFim || null}
            onChange={(date) => {
              const dtFim = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtFim })
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicador de Adicional"
            options={IndSalarioAdicionalValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indAdicional"
            value={data.indAdicional}
            onChange={(e, obj) => {
              const indAdicional = obj ? obj.value : ''

              if (indAdicional > IndSalarioAdicionalEnum.AdicionalNoturno_3) {
                setLenghAliquota(6)
              } else {
                setLenghAliquota(12)
              }

              setData({ ...data, indAdicional })
            }}
          />
        </Grid>

        <Grid
          item
          xl={lenghAliquota}
          lg={lenghAliquota}
          md={lenghAliquota}
          sm={lenghAliquota}
          xs={12}
        >
          <CurrencyTextField
            label="Aliquota"
            fullWidth
            value={data.aliquota || ''}
            variant="outlined"
            maximumValue="999"
            size="small"
            onChange={(e, obj) => {
              const aliquota = obj || null
              setData({ ...data, aliquota })
            }}
          />
        </Grid>

        {data.indAdicional > IndSalarioAdicionalEnum.AdicionalNoturno_3 && (
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <CurrencyTextField
              label="Valor"
              fullWidth
              value={data?.vrAdicional || ''}
              variant="outlined"
              size="small"
              onChange={(e, obj) => {
                const vrAdicional = obj || null
                setData({ ...data, vrAdicional })
              }}
            />
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data.observacao || ''}
            variant="outlined"
            size="small"
            multiline
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Collapse in={data.indAdicional === IndSalarioAdicionalEnum.Insalubridade_1}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <ContentDivider title="Configuração da Insalubridade" />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <ActionAccordion isOpen={false} title="Informações sobre o Salário Base">
                  <Stack width="100%">
                    {(isLoading || isFetching) && <LinearProgress />}

                    <Stack p={1} width="100%">
                      {dtSalarioBase && (
                        <>
                          <DatePicker
                            label="Data Salário Base"
                            value={dtSalarioBase.dtSalario}
                            disabled
                          />
                          <CurrencyTextField
                            label="Valor Salário Base"
                            value={dtSalarioBase.vrSalario}
                            disabled
                          />

                          <MUIAutoComplete
                            label="Indicador Salário Base"
                            options={IndSalarioContratualValues}
                            renderOption={(option) => option?.name || ''}
                            optionId="value"
                            value={dtSalarioBase.indSalario}
                            disabled
                          />

                          {dtSalarioBase.indSalario === IndSalarioContratualEnum.TabelaSalario && (
                            <AutoCompleteSalario
                              label="Salário Base"
                              value={dtSalarioBase.salarioId}
                              disabled
                            />
                          )}

                          {(dtSalarioBase.indSalario === IndSalarioContratualEnum.TabelaSalario ||
                            dtSalarioBase.indSalario ===
                              IndSalarioContratualEnum.CategoriaSindical) && (
                            <MUIAutoComplete
                              label="Faixa Salário Base"
                              options={FaixaSalarialValues}
                              renderOption={(opt) => opt?.name || ''}
                              optionId="value"
                              value={dtSalarioBase.faixaSalarial}
                              disabled
                            />
                          )}
                        </>
                      )}
                    </Stack>
                  </Stack>
                </ActionAccordion>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <MUIAutoComplete
                  label="Indicador Base de Cálculo Insalubridade"
                  options={IndBaseInsalubridadeValues}
                  renderOption={(option) => option?.name || ''}
                  optionId="value"
                  required
                  validationErrors={validationErrors}
                  name="indBaseCalculoInsalubridade"
                  value={data?.indBaseCalculoInsalubridade || ''}
                  onChange={(e, obj) => {
                    const indBaseCalculoInsalubridade = obj?.value || ''
                    setData((prev) => ({ ...prev, indBaseCalculoInsalubridade }))
                  }}
                />
              </Grid>

              {data?.indBaseCalculoInsalubridade ===
                IndBaseInsalubridadeEnum.SalarioBaseTabelaSalario_4 && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <AutoCompleteSalario
                    name="salarioId"
                    required
                    validationErrors={validationErrors}
                    value={data?.salarioId || ''}
                    onChange={(d) => {
                      const salarioId = d?.id || ''
                      setData((prev) => ({ ...prev, salarioId }))
                    }}
                  />
                </Grid>
              )}

              {(data?.indBaseCalculoInsalubridade ===
                IndBaseInsalubridadeEnum.SalarioBaseCategoria_3 ||
                data?.indBaseCalculoInsalubridade ===
                  IndBaseInsalubridadeEnum.SalarioBaseTabelaSalario_4) && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Faixa Salarial"
                    options={FaixaSalarialValues}
                    renderOption={(opt) => opt?.name || ''}
                    optionId="value"
                    value={data?.faixaSalarialInsalubridade}
                    onChange={(e, obj) =>
                      setData((prevState) => ({
                        ...prevState,
                        faixaSalarialInsalubridade: obj?.value || '',
                      }))
                    }
                    required
                    validationErrors={validationErrors}
                    name="faixaSalarialInsalubridade"
                  />
                </Grid>
              )}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
