import React, { useMemo } from 'react'

import { HeightDataTable } from '~/components'

import moment from 'moment'

const Table = (props) => {
  const { data } = props

  const columns = useMemo(
    () => [
      {
        name: 'dtFeriado',
        label: 'Data Feriado',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'descricao',
        label: 'Descrição',
      },
      {
        name: 'cidade',
        label: 'Local',
        options: {
          customBodyRender: (value) => (value ? value.nome : null),
        },
      },
    ],
    [],
  )

  return <HeightDataTable title="" data={data} columns={columns} pagination={false} />
}

export default Table
