import { IconButton, InputAdornment } from '@material-ui/core'
import { Close as CloseIcon, OpenInBrowser } from '@material-ui/icons'
import { TextField } from '~/components'
import React, { useEffect, useState } from 'react'
import useDialog from '~/hooks/useDialog'
import Table from './components/Table'

const _data = [
  {
    codigo: '01',
    descricao:
      'Despedida, pelo empregador, sem justa causa, inclusive a indireta; ou Rescisão antecipada, sem justa causa, pelo empregador, do contrato de trablho por prazo determinado, inclisive do temporário firmado nos termos da Lei 6.019/74, por obra certa ou do contrato de experiência; ou Rescisão antecipada, sem justa causa, pelo empregador, do contrato de trabalho firmando nos termos da Lei 9.601/98, de 21/01/98, conforme o disposto em convenção ou acordo coletivo de trabalho; ou Exoneração do diretor não empregado, sem musta causa, por deliberação da assembléia, dos sócios cotistas ou da autoridade competente. ',
  },

  {
    codigo: '02',
    descricao:
      'Rescisão do contrato de trabalho, inclusive por prazo determinado, por obra certa ou do contrato de experiência, por motivo de culpa recíproca ou de força maior',
  },

  {
    codigo: '03',
    descricao:
      'Rescisão do contrato de trabalho ou extinção total da empresa, fechamento de quaisquer de seus estabelecimentos, filiais ou agências, supressão de parte de suas atividades, declaração de nulidade do contrato de trabalho por infringência ao inciso II do art. 37 da Constituição Federal, quando mantido o direito ao salário; ou Rescisão do contrato de trabalho por falecimento do empregador individual.',
  },

  {
    codigo: '04',
    descricao:
      'Extinção normal do contrato de trabalho por prazo determinado, inclusive do temporário firmado nos termos da Lei 6.019/74, por obra certa ou do contrato de experiência; ou Término do mandato do diretor não empregado que não tenha sido reconduzido ao carto',
  },

  {
    codigo: '05',
    descricao:
      'Aposentadoria, inclusive por invalidez; ou Rescisão contratual do trabalhador, a pedido ou por justa causa, relativo a vínculo empregatício firmado após a aposentadoria; ou Exoneração do diretor não empregado, a pedido ou por justa causa, relativa a mandato exercido após a aposentadoria.',
  },

  {
    codigo: '06',
    descricao: 'Suspensão total do trabalho avulso por período igual ou superior a noventa dias.',
  },

  { codigo: '07', descricao: 'Rescisão por Acordo' },

  {
    codigo: 10,
    descricao:
      'Rescisão do contrato de trabalho de trabalhador com tempo de serviço anterior a 05/10/88, na condição de não optante, tendo havido pagamento de indenização.',
  },

  {
    codigo: 19,
    descricao:
      'Necessidade pessoal, urgente e grave, deocorrente de desastre natual que tenha atingido a área de residência do trabalhador, quando a situação de emergência ou o estado de calamidade pública tenha sido reconhecido por meio de decreto do governo do Distrito Fedeal ou Município e publicado em prazo não superior a 30 dias do primeiro dia útil seguinte ao da ocorrência do desastre natural, se este for assim reconhecido, por meio de portaria do Ministro de Estado da Integração Nacional.',
  },

  {
    codigo: 23,
    descricao: 'Falecimento do trabalhador, diretor não empregado ou trabalhador avulso',
  },

  {
    codigo: 26,
    descricao:
      'Rescisão ou extinção do contrato de trabalho de trabalhador com tempo de serviço anterior a 05/10/88, na condição de não optante, não tendo havido pagamento de indenização, exclusivamente para o contrato de trabalho que vigeu por período igual ou superior a 01 (um) ano.',
  },

  {
    codigo: 27,
    descricao:
      'Pagamento ao trabalhador, pelo empregador, da indenização relativa ao tempo  de serviço em que permaneceu na condição de não optante, nos termos da transação homologada pela autoridade competente, durante a vigência do contrato de trabalho do trabalhador, conforme artigo 6º do Regulamento Consolidado do FGTS; ou Recolhimento, pelo empregador, na conta optante do trabalhador, do valor correspondente à indenização referente ao tempo de serviço não optante, anterior a 05/10/1988, efetuado durante a vigência do contrato de trabalho do trabalhador, conforme artigo 73 do Regulamento Consolidado do FGTS; ou Rescisão do contrato de trabalho, por motivo de acordo, com pagamento de indenização.',
  },

  {
    codigo: 50,
    descricao:
      'Ter conta vinculada com o complemento de atualização monetária de que trata o artigo 4º da LC nº 110/01, cuja importãncia, em 10 de julho de 2001, seja igual ou superior a R$ 100,00 (cem Reais ).',
  },

  {
    codigo: 70,
    descricao: 'Ter o titular da conta vinculada idade igual ou superior a setenta anos.',
  },

  {
    codigo: 80,
    descricao: 'Ser portador ou possuir dependente portador do vírus HIV - SIDA/AIDS',
  },

  {
    codigo: 81,
    descricao: 'Estar cometido ou possuir dependente acometido de neoplasia malign ( câncer ).',
  },

  {
    codigo: 82,
    descricao:
      'Estar o trabalhador ou qualquer de seus dependentes em estágio terminal de vida, em razão de doença grave.',
  },

  {
    codigo: 86,
    descricao:
      'Permanência do titular da conta, por três anos ininterruptos, fora do regime do FGTS, para os contratos de trabalho extintos a partir de 14/07/90, inclusive.',
  },

  {
    codigo: 87,
    descricao:
      'Permanência da conta vinculada em crédito de depoósito, por três anos ininterruptos, cujo afastamento do titular tenha ocorrido até 13/07/90, inclusive.',
  },

  { codigo: 88, descricao: 'Determinação Judicial' },

  {
    codigo: 91,
    descricao:
      'Utilização do FGTS para aquisição de moradia própria, imóvel residencial concluído.',
  },

  {
    codigo: 92,
    descricao:
      'Utilização do FGTS para autorização extraordinária do saldo devedor decorrente de financiamento concedido pelo SFH, obtido pelo titular na aquisição de moradia própria.',
  },

  {
    codigo: 93,
    descricao:
      'Utilização do FGTS para abatimento das prestações decorrentes de financiamento concedido pelo SFH, obtido pelo titular na aquisição de moradia própria.',
  },

  {
    codigo: 94,
    descricao: 'Utilização do FGTS para aplicação em Fundos Mútuos de Privatização.',
  },

  {
    codigo: 95,
    descricao:
      'Utilização do FGTS para pagamento das parcelas de recursos próprios de imóvel residencial em fase de construção vinculado a programas de financiamento ou de autofinanciamento.',
  },

  {
    codigo: 96,
    descricao:
      'Utilização do FGTS para liquidação do saldo devedor decorrente de financiamento concedido pelo SFH, obtido pelo titular na aquisição de moradia própria.',
  },
]

const FgtsTransporter = (props) => {
  const { onChange, value } = props

  const [saqueFGTS, setSaqueFGTS] = useState({})
  const { open, isOpen, close } = useDialog()

  useEffect(() => {
    const saqueFGTS = _data.find((item) => item.codigo.toString() === value.toString())
    setSaqueFGTS(saqueFGTS)
  }, [value])

  const handleAfterClickItemTableCodigoSaque = (data) => {
    setSaqueFGTS(data)
    onChange(data)
    close()
  }

  function handleCleanSaqueFGTSValue() {
    onChange({
      ...saqueFGTS,
      codigo: '',
    })
  }

  const newValue = saqueFGTS?.codigo ? `${saqueFGTS?.codigo} - ${saqueFGTS?.descricao}` : value

  return (
    <>
      <TextField
        label="Saque FGTS"
        variant="outlined"
        size="small"
        fullWidth
        value={newValue || ''}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              {saqueFGTS?.codigo ? (
                <IconButton
                  arial-label="fecharSaqueFGTS"
                  color="default"
                  size="small"
                  onClick={handleCleanSaqueFGTSValue}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <IconButton
                  arial-label="abrirSaqueFGTS"
                  color="default"
                  size="small"
                  onClick={() => open()}
                >
                  <OpenInBrowser />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      <Table
        data={_data}
        isOpen={isOpen}
        onClose={close}
        onClickItem={handleAfterClickItemTableCodigoSaque}
      />
    </>
  )
}

export default FgtsTransporter
