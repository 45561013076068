import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  params: { vinculoId: string }
}

export function useGerarAuditoriaRescisaoZerada() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ params }: RequestProps) {
    await api.post('/RelRescisao/GerarAuditoriaRescisaoZerada', null, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Evento S_2299 da rescisão zerada foi gerado com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
