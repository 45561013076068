import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoConsulta } from './VinculoConsulta'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const query = '/VinculoConsulta/GetByEstabelecimentoPeriodo'

interface RequestProps {
  data: VinculoTipoEnum[]
  params: {
    estabelecimentoId: string
    dtInicio: string
    dtFim: string
  }
}

export function useGetByEstabelecimentoPeriodo({ data, params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: VinculoConsulta[] }>(query, data, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, data, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
