import React, { useState, useEffect, useCallback } from 'react'

import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/Setor/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/Setor', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Setor"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
          <TextField
            label="Código"
            fullWidth
            value={data?.codigo || ''}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const codigo = e.target.value
              setData({ ...data, codigo })
            }}
            disabled
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data?.nome || ''}
            variant="outlined"
            size="small"
            name="nome"
            autoFocus
            required
            inputProps={{
              maxLength: 100,
            }}
            validationErrors={validationErrors}
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={data?.ativo || !data?.id ? true : false}
                onChange={(e) => {
                  const ativo = e.target.checked
                  setData({ ...data, ativo })
                }}
                name="ativo"
                color="primary"
                disabled={data?.id ? false : true}
              />
            }
            label="Ativo"
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Descrição Setor"
            value={data?.descricaoSetor || ''}
            fullWidth
            multiline
            inputProps={{
              maxLength: 100,
            }}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const descricaoSetor = e?.target?.value || ''
              setData((oldState) => ({ ...oldState, descricaoSetor }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
