const geral_1 = 1
const transferenciaSaida_2 = 2
const transferenciaEntrada_3 = 3
const eSocial_4 = 4

export const indObservacaoValues = [
  { value: geral_1, name: '1 - Geral' },
  { value: transferenciaSaida_2, name: '2 - Transferência Saída' },
  { value: transferenciaEntrada_3, name: '3 - Transferência Entrada' },
  { value: eSocial_4, name: '4 - eSocial' },
]
export const indObservacaoConsts = {
  geral_1,
  transferenciaSaida_2,
  transferenciaEntrada_3,
  eSocial_4,
}
