import React from 'react'

import { makeStyles } from '@material-ui/core'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props) => theme.spacing(props?.top || 0),
    marginRight: (props) => theme.spacing(props?.right || 0),
    marginBottom: (props) => theme.spacing(props?.bottom || 0),
    marginLeft: (props) => theme.spacing(props?.left || 0),
    width: '100%',
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '4px',
    boxShadow: 'none',
  },
  sumary: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
  },
  heading: {
    marginLeft: (props) => (props?.titlePosition === 'left' && 5) || 'auto',
    marginRight: (props) => (props?.titlePosition === 'right' && 5) || 'auto',
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const SimpleAccordion = (props) => {
  const { children, title, ...rest } = props
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <Accordion {...rest}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.sumary}
        >
          <div className={classes.heading}>{title}</div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  )
}

export default SimpleAccordion
