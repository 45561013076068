import React, { useState, useCallback, useEffect } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader } from 'mio-library-ui'

import Table from './components/Table'
import Form from './components/Form'
import FormReplicar from './components/FormReplicar'

import { ConfirmDeleteDialog, TreeView } from '~/components'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const HEADER_HEIGHT = '70px'

const AutonomosLancamento = () => {
  const [vinculo, setVinculo] = useState({})

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const [formReplicar, setFormReplicar] = useState({
    isOpen: false,
    rpAutonomoId: '',
  })

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Autonomo_4,
  ])
  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    setVinculo({})
    setCollection({
      isLoading: false,
      itens: [],
    })
  }, [anoMes, estabelecimento])

  const getCollection = useCallback(async () => {
    if (!vinculo?.id) return
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))
    try {
      const response = await api.get('/RPAutonomo/GetByVinculo', {
        params: {
          vinculoId: vinculo?.id,
        },
      })
      setCollection((oldState) => ({
        ...oldState,
        itens: response?.data?.data || [],
        isLoading: false,
      }))
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
    // eslint-disable-next-line
  }, [vinculo])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        setForm({
          isOpen: true,
          data: item,
        })
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }
      const handleClickReplicateItem = (id) => {
        setFormReplicar({
          isOpen: true,
          rpAutonomoId: id,
        })
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
        replicate: handleClickReplicateItem,
      }
      functions[event](value)
    },
    //eslint-disable-next-line
    [collection],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/RPAutonomo/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterReplicate = () => {
        getCollection()
        setFormReplicar({
          isOpen: false,
          rpAutonomoId: '',
        })
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
        replicate: handleAfterReplicate,
      }

      functions[event](value)
    },
    // eslint-disable-next-line
    [collection],
  )

  const handleClickItemMenu = async (id) => {
    if (!id) return
    try {
      const response = await api.get(`Vinculo/${id}`)
      if (response.data.data) {
        setVinculo({
          ...response.data.data,
          id: response.data.data.id,
          nome: response.data.data.pessoaFisica.nome,
        })
      }
    } catch (err) {
      console.log(err)
      return {}
    }
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => handleClickItemMenu(vinculo.vinculoId)}
      searchBy="vinculoNome"
      renderOption={(option) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
      isLoadingOnClick={collection.isLoading}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Consulta Lançamentos - Autônomo" subtitle={vinculo?.nome || '-'} />
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          onItemClick={handleClickItem}
        />
      </Box>

      <Form
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <FormReplicar
        isOpen={formReplicar.isOpen}
        rpAutonomoId={formReplicar.rpAutonomoId}
        onClose={() =>
          setFormReplicar({
            isOpen: false,
            rpAutonomoId: '',
          })
        }
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </TreeView>
  )
}

export default AutonomosLancamento
