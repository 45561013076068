import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoAfastamento } from './VinculoAfastamento'

import { queryKeyVinculoAfastamentoGetByVinculo } from './useVinculoAfastamentoGetByVinculo'
import { notifyPut } from '~/utils/notification'

export function useAfastamentoUpdate() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()

  async function handleRequest(data: VinculoAfastamento) {
    const response = await api.put<{ data: string[] }>('/VinculoAfastamento/' + data.id, data)
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notifyPut()
      await queryClient.invalidateQueries(queryKeyVinculoAfastamentoGetByVinculo)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
