import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'
import moment from 'moment'
import * as yup from 'yup'

import { Grid, Box, InputAdornment, IconButton, makeStyles } from '@material-ui/core'
import { GetApp as SelectIcon } from '@material-ui/icons'
import { TextField } from 'mio-library-ui'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { SuperFinderServico } from '~/components/SuperFinder'
import { DatePicker, CurrencyTextField, ActionDialog } from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { CategoriaEnum, CategoriaValues } from '~/@types/enums/CategoriaEnum'

const categoriaOnlyAutonomos = [
  CategoriaEnum.Contribuinte_Individual_Autonomo_701,
  CategoriaEnum.Contribuinte_Individual_Transportador_Passageiros_711,
  CategoriaEnum.Contribuinte_Individual_Transportador_Carga_712,
  CategoriaEnum.Contribuinte_Individual_Microempreendedor_Individual_741,
]
const categoriasAutonomos = CategoriaValues.filter((obj) =>
  categoriaOnlyAutonomos.includes(obj.value),
)

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '80%',
  },
}))

const schema = yup.object().shape({
  dtLancamento: yup
    .date()
    .required('Informe a Data de Lançamento')
    .typeError('Informe uma Data válida')
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test('test-in-date', 'Informe uma Data dentro da competência', (value) => {
        const startOfMonth = moment(anoMes).subtract(1, 'day').format('yyyy-MM-DD')
        const endOfMonth = moment(anoMes).endOf('month').add(1, 'day').format('yyyy-MM-DD')
        const result = moment(value).isBetween(startOfMonth, endOfMonth)
        return result
      }),
    )
    .nullable(),
  dtPagamento: yup
    .date()
    .required('Informe a Data de Pagamento')
    .typeError('Informe uma Data válida')
    .when(['dtLancamento'], (dtLancamento, schema) =>
      schema.test('test-in-date', 'Informe uma Data maior ou igual a Data de Lançamento', (value) =>
        moment(dtLancamento).isSameOrBefore(value),
      ),
    )
    .nullable(),
  vrServico: yup.string().required('Informe o Valor do Serviço'),
  referencia: yup.string().required('Informe a Referência'),
  categoria: yup.string().required('Informe a Categoria'),
  descricaoServico: yup.string().required('Informe a Descrição do Serviço'),
})

export default function ReciboAutonomo(props) {
  const { isOpen, onClose, vinculo: _vinculo, onAfterSubmit } = props

  const [data, setData] = useState({})
  const [vinculo, setVinculo] = useState({})
  const [isLoading, setLoading] = useState()
  const [superFinderServicos, setSuperFinderServicos] = useState({
    isOpen: false,
  })

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes } = useAmbiente()
  const classes = useStyles()

  async function getTabelaMesItem(tabelaMesId, anoMes) {
    try {
      const response = await api.get('/TabelaMes/GetTabelaMesItem', {
        params: {
          tabelaMesId,
          anoMes,
        },
      })
      return response?.data?.data || {}
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function getVinculoAndStartForm() {
      if (!_vinculo?.vinculoId) return
      try {
        const response = await api.get(`/Vinculo/${_vinculo?.vinculoId}`)
        const responseCurrent = response?.data?.data || {}
        const tabelaMesItem = await getTabelaMesItem(
          responseCurrent?.estabelecimento?.tabelaMesId,
          anoMes,
        )
        setVinculo(responseCurrent)
        setData((oldState) => ({
          ...oldState,
          dtLancamento: moment(anoMes).format('yyyy-MM-DD'),
          dtPagamento: tabelaMesItem?.dtPagamentoRPA,
          categoria: responseCurrent?.categoria,
        }))
      } catch (error) {
        console.log(error)
      }
    }
    getVinculoAndStartForm()
  }, [_vinculo, anoMes])

  async function handleSubmit() {
    async function insert(_data) {
      setLoading(true)
      try {
        const response = await api.post(
          '/RP/NovoAutonomo',
          { ..._data, vinculoId: vinculo?.id },
          {
            params: {
              vinculoId: vinculo?.id,
              anoMes,
            },
          },
        )
        notification.post()
        onAfterSubmit(response?.data?.data?.id || '', vinculo?.id || '')
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    insert(data)
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { anoMes },
    },
  })

  function openSuperFinderServicos() {
    setSuperFinderServicos({
      isOpen: true,
    })
  }

  function closeSuperFinderServicos() {
    setSuperFinderServicos({
      isOpen: false,
    })
  }

  return (
    <ActionDialog
      title="Recibo - Autônomo"
      subtitle={vinculo ? vinculo?.pessoaFisica?.nome : '-'}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      okLabel="Salvar"
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: classes.heightPaper,
        },
      }}
      isOkProcessing={isLoading}
    >
      <Box p={0.5} height="100%">
        <Box pb={1}>
          <Grid container spacing={2}>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <DatePicker
                label="Data de Lançamento"
                value={data?.dtLancamento || null}
                name="dtLancamento"
                validationErrors={validationErrors}
                required
                onChange={(date) => {
                  const dtLancamento = date ? date.format('yyyy-MM-DD') : null
                  setData((oldState) => ({
                    ...oldState,
                    dtLancamento,
                  }))
                }}
              />
            </Grid>

            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <DatePicker
                label="Data de Pagamento"
                value={data?.dtPagamento || null}
                name="dtPagamento"
                validationErrors={validationErrors}
                required
                onChange={(date) => {
                  const dtPagamento = date ? date.format('yyyy-MM-DD') : null
                  setData((oldState) => ({
                    ...oldState,
                    dtPagamento,
                  }))
                }}
              />
            </Grid>

            <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
              <CurrencyTextField
                label="Valor do Serviço"
                fullWidth
                name="vrServico"
                validationErrors={validationErrors}
                required
                value={data?.vrServico || ''}
                onChange={(e, value) => {
                  const vrServico = value || ''
                  setData((oldState) => ({ ...oldState, vrServico }))
                }}
              />
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <CurrencyTextField
                label="Referência"
                fullWidth
                name="referencia"
                validationErrors={validationErrors}
                required
                value={data?.referencia || ''}
                onChange={(e, value) => {
                  const referencia = value || ''
                  setData((oldState) => ({ ...oldState, referencia }))
                }}
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <MUIAutoComplete
                label="Categoria"
                options={categoriasAutonomos}
                renderOption={(option) => option.name}
                optionId="value"
                name="categoria"
                validationErrors={validationErrors}
                required
                value={data.categoria}
                onChange={(e, obj) => {
                  const categoria = obj ? obj.value : ''
                  setData((oldState) => ({ ...oldState, categoria }))
                }}
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Descrição do Serviço"
                fullWidth
                size="small"
                variant="outlined"
                name="descricaoServico"
                validationErrors={validationErrors}
                required
                value={data?.descricaoServico || ''}
                onChange={(e) => {
                  const descricaoServico = e?.target?.value || ''
                  setData((oldState) => ({
                    ...oldState,
                    descricaoServico,
                  }))
                }}
                multiline
                inputProps={{
                  maxLength: 100,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={openSuperFinderServicos}
                        size="small"
                        title="Abrir Serviços"
                        color="primary"
                      >
                        <SelectIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Table vinculoId={vinculo?.id} />

        <SuperFinderServico
          isOpen={superFinderServicos.isOpen}
          onClose={closeSuperFinderServicos}
          onChange={(descricaoServico) => {
            setData((oldState) => ({
              ...oldState,
              descricaoServico,
            }))
          }}
        />
      </Box>
    </ActionDialog>
  )
}
