import api from '~/services/api-pessoal'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '../useDialogNotification'

export async function obterUltimoLocalTrabalhoVinculo(vinculoId) {
  const response = await api.get('/VinculoLocal/ObterUltimoLocalTrabalhoVinculo', {
    params: {
      vinculoId,
    },
  })
  return response?.data?.data || null
}

async function getVinculosLocaisByVinculo(vinculoId) {
  if (!vinculoId) return []
  const response = await api.get(`/VinculoLocal/GetByVinculo/${vinculoId}`)
  return response?.data?.data || []
}

async function remove(id) {
  await api.delete(`/VinculoLocal/${id}`)
}

async function create(dt) {
  await api.post('/VinculoLocal', dt)
}

async function update(dt) {
  await api.put(`/VinculoLocal/${dt.id}`, dt)
}

export function useVinculoLocal(vinculoId) {
  const dialogNotification = useDialogNotification()

  return useQuery(['VinculoLocal', vinculoId], () => getVinculosLocaisByVinculo(vinculoId), {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useVinculoLocalMutate() {
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  const mutateDelete = useMutation(remove, {
    onSuccess: async () => await queryClient.invalidateQueries('VinculoLocal'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateCreate = useMutation(create, {
    onSuccess: async () => await queryClient.invalidateQueries('VinculoLocal'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateUpdate = useMutation(update, {
    onSuccess: async () => await queryClient.invalidateQueries('VinculoLocal'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  return {
    mutateDelete,
    mutateCreate,
    mutateUpdate,
  }
}
