import { useState } from 'react'
import { IconButton, Box, makeStyles, Badge } from '@material-ui/core'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  PostAdd as ItensIcon,
  Comment as CommentIcon,
  Error,
} from '@material-ui/icons'
import { ButtonBox } from 'mio-library-ui'

import { ToolsDataTable } from '~/components'

import { formatCurrency, setImportantCSS } from '~/hooks/useUtils'
import { useContextRecibo } from '~/hooks/useRecibo'
import useDialog from '~/hooks/useDialog'

import MemoriaCalculoTable from './components/MemoriaCalculoTable'
import DialogMemoriaCalculo from './components/DialogMemoriaCalculo'

import { IndUnidadeSalarioEnum } from '~/@types/enums/IndUnidadeSalarioEnum'

import {
  CodigoIncidenciaCPEnum,
  CodigoIncidenciaCPValues,
} from '~/@types/enums/CodigoIncidenciaCPEnum'
import {
  CodigoIncidenciaFGEnum,
  CodigoIncidenciaFGValues,
} from '~/@types/enums/CodigoIncidenciaFGEnum'
import {
  CodigoIncidenciaIREnum,
  CodigoIncidenciaIRValues,
} from '~/@types/enums/CodigoIncidenciaIREnum'
import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import useDialogNotification from '~/hooks/useDialogNotification'

const useStyles = makeStyles((theme) => ({
  renderRight: {
    textAlign: 'right',
  },
  hightLightTableColumn: {
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
  hightLightRow: {
    backgroundColor: setImportantCSS(theme.palette.error.light),
    color: setImportantCSS(theme.palette.error.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.error.lighter),
    },
  },
}))

function hasFormula(evento, unidadeSalarioFixo) {
  if (!evento) return false
  const { formulaMensalista, formulaHorista, formulaDiarista } = evento

  if (unidadeSalarioFixo === IndUnidadeSalarioEnum.Mes_5) {
    return formulaMensalista ? true : false
  }
  if (unidadeSalarioFixo === IndUnidadeSalarioEnum.Hora_1) {
    return formulaHorista ? true : false
  }
  if (unidadeSalarioFixo === IndUnidadeSalarioEnum.Dia_2) {
    return formulaDiarista ? true : false
  }
  return false
}

const Table = (props) => {
  const { onItemClick } = props

  const { reciboPagamento, isLoadingRecibo } = useContextRecibo()

  const [dialogMemoCalc, setDialogMemoCalc] = useState({
    isOpen: false,
    rpItem: {},
    memoriaCalculo: [],
    rpItens: [],
  })

  const classes = useStyles()
  const dialogNotification = useDialogNotification()

  const {
    isOpen: isOpenDialogMemoriaCalculo,
    data: dataDialogMemoriaCalculo,
    open: openDialogMemoriaCalculo,
    close: closeDialogMemoriaCalculo,
  } = useDialog('')

  const _data = reciboPagamento?.rpItens || []

  const data = _data.map((d) => ({
    ...d,
    eventoCodigo: d?.eventoEmpregador?.evento?.codigo || '',
    eventoNome: d?.eventoEmpregador?.evento?.nome || '',
    inss: d?.eventoEmpregador?.codigoIncidenciaCP || CodigoIncidenciaCPEnum.NaoIncide_00,
    irrf: d?.eventoEmpregador?.codigoIncidenciaIR || CodigoIncidenciaIREnum.NaoInformado,
    fgts: d?.eventoEmpregador?.codigoIncidenciaFG || CodigoIncidenciaFGEnum.NaoInformado,
    quantidade: d?.quantidade ? formatCurrency(d.quantidade) : '',
    vrVencimento: d?.vrVencimento ? formatCurrency(d.vrVencimento) : '',
    vrDesconto: d?.vrDesconto ? formatCurrency(d.vrDesconto) : '',
  }))

  function isClassIndevidaFGTSDecimoTerceiro(rpIdf, codigoIncidenciaFG) {
    const isDecimoTerceiro =
      rpIdf === ReciboIDFEnum.DecimoTerceiro1aParcela_7 ||
      rpIdf === ReciboIDFEnum.DecimoTerceiro2aParcela_8
    if (!isDecimoTerceiro) return false
    if (codigoIncidenciaFG === CodigoIncidenciaFGEnum.BaseCalculo_DecimoTerceiro_12) return false
    if (codigoIncidenciaFG === CodigoIncidenciaFGEnum.NaoInformado) return false
    return true
  }

  const handleOpenDialogMemocalc = (id) => {
    const rpItem = _data.find((d) => d.id === id)
    setDialogMemoCalc({
      isOpen: true,
      rpItem,
      memoriaCalculo: reciboPagamento?.memoriaCalculo || [],
      rpItens: _data,
    })
  }

  const handleCloseDialogMemoCalc = () => {
    setDialogMemoCalc({
      isOpen: false,
      rpItem: {},
      memoriaCalculo: [],
      rpItens: [],
    })
  }

  const columns = [
    {
      name: 'eventoCodigo',
      label: 'Código',
    },
    {
      name: 'eventoNome',
      label: 'Nome',
      options: {
        customBodyRender: (value, { rowIndex }) => {
          const rpItemCurrent = _data[rowIndex]
          const isHaveFormula = hasFormula(
            rpItemCurrent?.eventoEmpregador?.evento,
            reciboPagamento?.vinculo?.unidadeSalarioFixo || IndUnidadeSalarioEnum.Mes_5,
          )
          const isCalculoManual = rpItemCurrent?.isCalculoManual || false
          const temMemoriaCalculo = rpItemCurrent?.temMemoriaCalculo || false
          return (
            <Box
              title={isCalculoManual ? 'O Cálculo está manual' : ''}
              color={isCalculoManual ? 'error.main' : ''}
              className={temMemoriaCalculo || isHaveFormula ? classes.hightLightTableColumn : ''}
              onClick={() => {
                if (isHaveFormula) return handleOpenDialogMemocalc(rpItemCurrent.id)
                if (temMemoriaCalculo) return openDialogMemoriaCalculo(rpItemCurrent.id)
              }}
            >
              {value}
            </Box>
          )
        },
      },
    },
    {
      name: 'inss',
      label: 'INSS',
      options: {
        customBodyRender: (value) => {
          const current = CodigoIncidenciaCPValues.find((d) => d.value === value)
          return <div title={current?.name}>{value}</div>
        },
        setCellProps: () => {
          return {
            style: { width: '42px' },
          }
        },
      },
    },
    {
      name: 'fgts',
      label: 'FGTS',
      options: {
        customBodyRender: (value) => {
          const current = CodigoIncidenciaFGValues.find((d) => d.value === value)
          const isIndevidaConfig = isClassIndevidaFGTSDecimoTerceiro(reciboPagamento?.idf, value)
          const content = (
            <div style={{ width: '25px' }} title={current?.name}>
              {value}
            </div>
          )
          if (isIndevidaConfig) {
            return (
              <Badge
                badgeContent={
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() =>
                      dialogNotification.error({
                        title: 'Temos um alerta importante para você!',
                        descriptions: [
                          'A classificação de incidência fgts está indevida para o recibo de décimo terceiro.',
                        ],
                      })
                    }
                  >
                    <Error fontSize="small" />
                  </IconButton>
                }
              >
                {content}
              </Badge>
            )
          } else {
            return content
          }
        },
        setCellProps: () => {
          return {
            style: { width: '42px' },
          }
        },
      },
    },
    {
      name: 'irrf',
      label: 'IRRF',
      options: {
        customBodyRender: (value) => {
          const current = CodigoIncidenciaIRValues.find((d) => d.value === value)
          return <div title={current?.name}>{value}</div>
        },
        setCellProps: () => {
          return {
            style: { width: '42px' },
          }
        },
      },
    },
    {
      name: 'quantidade',
      label: 'Referência',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrVencimento',
      label: 'Vencimento',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrDesconto',
      label: 'Desconto',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value, { rowIndex }) => {
          const rpItemCurrent = _data[rowIndex]

          const dtToEdit = {
            id: rpItemCurrent?.eventoEmpregadorId || null,
            codigo: rpItemCurrent?.eventoEmpregador?.evento?.codigo || null,
            referencia: rpItemCurrent?.quantidade || null,
            valor: rpItemCurrent?.vrDesconto
              ? rpItemCurrent.vrDesconto
              : rpItemCurrent?.vrVencimento || null,
            indCalculo: rpItemCurrent?.eventoEmpregador?.evento?.indCalculo || null,
            complemento: rpItemCurrent?.complemento || null,
            isCalculoManual: rpItemCurrent?.isCalculoManual || null,
          }

          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Abrir Formulário do Evento"
                color="primary"
                aria-label="Formulário do Evento"
                onClick={() => {
                  onItemClick('formEvent', rpItemCurrent?.eventoEmpregador?.eventoId || null)
                }}
              >
                <ItensIcon fontSize="small" color="primary" />
              </IconButton>

              {!reciboPagamento.isFinalizado && (
                <>
                  <IconButton
                    size="small"
                    title="Complemento do Registro"
                    aria-label="Complemento"
                    onClick={() => {
                      onItemClick('complemento', dtToEdit)
                    }}
                  >
                    <CommentIcon
                      fontSize="small"
                      color={dtToEdit.complemento ? 'primary' : 'inherit'}
                    />
                  </IconButton>

                  <IconButton
                    size="small"
                    title="Editar este registro"
                    color="primary"
                    aria-label="Editar"
                    onClick={() => {
                      onItemClick('edit', dtToEdit)
                    }}
                  >
                    <EditIcon fontSize="small" color="primary" />
                  </IconButton>
                  <IconButton
                    size="small"
                    title="Deletar este registro"
                    color="primary"
                    aria-label="Deletar"
                    onClick={() => {
                      onItemClick('delete', value)
                    }}
                  >
                    <DeleteIcon fontSize="small" color="primary" />
                  </IconButton>
                </>
              )}
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Box>
      <ToolsDataTable
        disableAutoHeight
        data={data}
        columns={columns}
        pagination={false}
        isLoading={isLoadingRecibo}
        options={{
          setRowProps: (_, index) => {
            if (
              isClassIndevidaFGTSDecimoTerceiro(
                reciboPagamento?.idf,
                _data[index]?.eventoEmpregador?.codigoIncidenciaFG,
              )
            ) {
              return {
                className: classes.hightLightRow,
              }
            }
          },
        }}
      />
      {dialogMemoCalc.isOpen && (
        <MemoriaCalculoTable
          isOpen={dialogMemoCalc.isOpen}
          onClose={handleCloseDialogMemoCalc}
          rpItem={dialogMemoCalc.rpItem}
          memoriaCalculo={dialogMemoCalc.memoriaCalculo}
          rpItens={dialogMemoCalc.rpItens}
          unidadeSalarioFixoVinculo={
            reciboPagamento?.vinculo?.unidadeSalarioFixo || IndUnidadeSalarioEnum.Mes_5
          }
        />
      )}
      {isOpenDialogMemoriaCalculo && (
        <DialogMemoriaCalculo
          isOpen={isOpenDialogMemoriaCalculo}
          onClose={closeDialogMemoriaCalculo}
          rpItemId={dataDialogMemoriaCalculo}
        />
      )}
    </Box>
  )
}

export default Table
