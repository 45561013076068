import { useState } from 'react'

import { Box, Paper, Tab, Tabs } from '@material-ui/core'

import { PageHeader, StackContainer, TabPanel } from '~/components'

import Agendamento from './Agendamento'
import Processamento from './Processamento'

enum TabsFolhaAutomatica {
  Agendamento,
  Processamento,
}

export default function FolhaAutomatica() {
  const [tab, setTab] = useState(TabsFolhaAutomatica.Agendamento)
  return (
    <StackContainer>
      <PageHeader title="Folha Automática">
        <Box component={Paper} px={1}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => setTab(value)}
          >
            <Tab label="Agendamento" />
            <Tab label="Processamento" />
          </Tabs>
        </Box>
      </PageHeader>

      <Box flex={1}>
        <TabPanel value={tab} index={TabsFolhaAutomatica.Agendamento}>
          <Agendamento />
        </TabPanel>
        <TabPanel value={tab} index={TabsFolhaAutomatica.Processamento}>
          <Processamento />
        </TabPanel>
      </Box>
    </StackContainer>
  )
}
