import { formatCurrency } from '~/hooks/useUtils'

import { ActionDialog, Button, ToolsDataTable, ButtonBox } from '~/components'

import { ApurarConvencaoColetivaEventoDTO } from '~/hooks/queries/ApurarConvencaoColetiva/ApurarConvencaoColetivaDTO'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface DialogEventosProps {
  isOpen: boolean
  onClose: () => void
  data: ApurarConvencaoColetivaEventoDTO[]
  subtitle: string
}

export default function DialogEventos({ isOpen, onClose, data: _d, subtitle }: DialogEventosProps) {
  const data = _d.map((d) => ({
    ...d,
    vrVencimentoOriginal: d?.vrVencimentoOriginal ? formatCurrency(d.vrVencimentoOriginal) : '',
    vrVencimentoAtualizado: d?.vrVencimentoAtualizado
      ? formatCurrency(d.vrVencimentoAtualizado)
      : '',
    vrVencimentoApurado: d?.vrVencimentoApurado ? formatCurrency(d.vrVencimentoApurado) : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'eventoCodigo',
      label: 'Código',
    },
    {
      name: 'eventoNome',
      label: 'Nome',
    },
    {
      name: 'referencia',
      label: 'Referência',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrVencimentoOriginal',
      label: 'Total',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrVencimentoAtualizado',
      label: 'Total Atualizado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrVencimentoApurado',
      label: 'Apurado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
  ]

  return (
    <ActionDialog
      title="Convenção Coletiva Analítico"
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
      }}
    >
      <ToolsDataTable data={data} columns={columns} disableAutoHeight pagination={false} />
    </ActionDialog>
  )
}
