import { useState } from 'react'

import * as yup from 'yup'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Grid, Typography } from '@material-ui/core'

import { ActionDialog, NumeroInscricaoTextField } from '~/components'

import useKeyPress from '~/hooks/useKeyPress'
import useValidationErrors from '~/hooks/useValidationErrors'

const schema = yup.object().shape({
  nrInscricaoDigitado: yup.string().when(['$nrInscricao'], (nrInscricao, schema) =>
    schema.required(`Informe o Número de Inscrição ${formatToCPFOrCNPJ(nrInscricao)}.`).test({
      message: 'Número de Inscrição informado está incorreto.',
      test: (nrInscricaoDigitado: string) => nrInscricaoDigitado === nrInscricao,
    }),
  ),
})
interface ConfirmInscricaoDeleteProps {
  isLoading: boolean
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  nrInscricao: string
}

export default function ConfirmInscricaoDelete(props: ConfirmInscricaoDeleteProps) {
  const { isOpen, onConfirm, onCancel, isLoading, nrInscricao } = props

  const [data, setData] = useState({
    nrInscricaoDigitado: '',
  })

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: onConfirm,
    data,
    schemaOptions: {
      abortEarly: false,
      context: {
        nrInscricao,
      },
    },
  })

  useKeyPress('Enter', handleValidate)

  return (
    <ActionDialog
      title="Excluir Registro!"
      isOpen={isOpen}
      onClose={onCancel}
      onCancelClick={onCancel}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
      okLabel="Confirmar"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                Essa ação não poderá ser desfeita! Tem certeza que deseja continuar?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Informe o Número de Inscrição <b>{formatToCPFOrCNPJ(nrInscricao)}</b> para
                prosseguir com a exclusão do registro.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <NumeroInscricaoTextField
            name="nrInscricaoDigitado"
            validationErrors={validationErrors}
            placeholder="Número de Inscrição"
            value={data.nrInscricaoDigitado}
            onChange={(_, value) => {
              const nrInscricaoDigitado = value || ''
              setData({ nrInscricaoDigitado })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
