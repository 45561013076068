import api from '~/services/api-pessoal'

import { FeriasColetivaRequestDTO } from './obterFuncionariosAnalitico'

export interface FeriasColetivaFullRequestDTO {
  funcionariosIds: string[]
  dadosRequisicao: FeriasColetivaRequestDTO
}

export async function processarFeriasColetiva(data: FeriasColetivaFullRequestDTO) {
  const response = await api.post('/FeriasColetiva/ProcessarFeriasColetiva', data)
  return response.data.data
}
