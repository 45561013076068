import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import { DatePicker } from '~/components'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

import { indObservacaoConsts } from '~/values/indObservacaoValues'

const { eSocial_4 } = indObservacaoConsts

const schema = yup.object().shape({
  dtAlteracao: yup
    .date()
    .required('Informe a Data da Alteração')
    .typeError('Informe uma Data válida')
    .nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    if (!isOpen) return
    setData(_data)
  }, [isOpen, _data])

  const handleSubmit = useCallback(() => {
    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/VinculoAlteracaoContratoCadastro', data)
        notification.post()
        onAfterSubmitForm(response.data.data)

        if (!data?.observacao) return
        const observacao = {
          vinculoId: data?.vinculoId,
          indObservacao: eSocial_4,
          dtObservacao: data?.dtAlteracao,
          observacao: data.observacao,
          origemId: response.data.data.id,
        }
        await api.post('/VinculoObservacao', observacao)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setSubmitting(false)
      }
    }

    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Alteração Contrato de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <DatePicker
            label="Data da Alteração"
            size="small"
            value={data?.dtAlteracao || null}
            required
            validationErrors={validationErrors}
            name="dtAlteracao"
            onChange={(date) => {
              const dtAlteracao = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtAlteracao,
              }))
            }}
          />
        </Grid>

        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <DatePicker
            label="Data Efetiva"
            size="small"
            value={data?.dtEfetiva || null}
            onChange={(date) => {
              const dtEfetiva = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtEfetiva,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Descrição"
            fullWidth
            value={data?.descricaoAlteracao || ''}
            variant="outlined"
            size="small"
            placeholder="Descrição da alteração para o eSocial"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 150,
            }}
            onChange={(e) => {
              const descricaoAlteracao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                descricaoAlteracao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data?.observacao || ''}
            variant="outlined"
            size="small"
            multiline
            inputProps={{
              maxLength: 255,
            }}
            onChange={(e) => {
              const observacao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
