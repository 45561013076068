import { Box } from '@material-ui/core'
import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'
import useObterMemoriaCalculo from '~/hooks/queries/RPMemoriaCalculo/useObterMemoriaCalculo'

interface DialogMemoriaCalculoProps {
  isOpen: boolean
  onClose: () => void
  rpItemId: string
}

export default function DialogMemoriaCalculo({
  isOpen,
  onClose,
  rpItemId,
}: DialogMemoriaCalculoProps) {
  const { data, isLoading, isFetching } = useObterMemoriaCalculo({ rpItemId })
  const descricoes = data?.descricoes || []

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },
  ]

  return (
    <ActionDialog
      title="Memória de Cálculo"
      subtitle={`${data?.eventoPrincipalCodigo || ''} - ${data?.eventoPrincipalNome || ''}`}
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox top={1} bottom={1} right={1}>
          <Button variant="contained" onClick={() => onClose()}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <Box position="relative">
        <ToolsDataTable
          data={descricoes.map((d) => ({ descricao: d }))}
          columns={columns}
          pagination={false}
          disableAutoHeight
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </Box>
    </ActionDialog>
  )
}
