import React, { useState, useEffect, useCallback } from 'react'

import { ContentContainer, PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Container from './components/Container'
import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'
import { indClassficacaoEventoConsts } from '~/values/indClassficacaoEventoValues'
import { eventoIndReferenciaConsts } from '~/values/eventoIndReferenciaValues'
import { indEventoConsts } from '~/values/indEventoValues'
import { IndBaseDSREnum } from '~/@types/enums/IndBaseDSREnum'
import { indModificacaoeSocialConsts } from '~/values/indModificacaoeSocialvalues'
import { EventoIndCalculoEnum } from '~/@types/enums/EventoIndCalculoEnum'

const { semClassificacao } = indClassficacaoEventoConsts
const { mes } = eventoIndReferenciaConsts
const { vencimento_1 } = indEventoConsts

const Evento = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [container, setContainer] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [query, setQuery] = useState('')
  const [isLoadingOnEdit, setLoadingOnEdit] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get('/Evento')
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleOpenContainer = useCallback(
    (
      data = {
        indClassificacaoEvento: semClassificacao,
        indCalculo: EventoIndCalculoEnum.Referencia,
        indReferencia: mes,
        indEvento: vencimento_1,
        indBaseDSR: IndBaseDSREnum.NaoIncide_0,
        indModificacaoeSocial: indModificacaoeSocialConsts.NaoIncide_0,
        dtInclusaoeSocial: null,
      },
    ) => {
      setContainer((oldState) => ({
        ...oldState,
        isOpen: true,
        data,
      }))
    },
    [],
  )

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = async (id) => {
        setLoadingOnEdit(true)
        try {
          const response = await api.get(`/Evento/${id}`)
          handleOpenContainer(response.data.data)
        } catch (error) {
          console.log(error)
        } finally {
          setLoadingOnEdit(false)
        }
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [handleOpenContainer],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/Evento/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenContainer()
  }, [handleOpenContainer])

  const handleCloseContainer = () => {
    setContainer((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseContainer()
        handleOpenContainer(data)
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseContainer()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection, handleOpenContainer],
  )

  return (
    <ContentContainer>
      <PageHeader title="Evento">
        <ButtonBox>
          <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>
      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        isFetching={isLoadingOnEdit}
        query={query}
        onItemClick={handleClickItem}
      />

      <Container
        isOpen={container.isOpen}
        data={container.data}
        onClose={handleCloseContainer}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ContentContainer>
  )
}

export default Evento
