import { useState } from 'react'

import { Typography } from '@material-ui/core'

import { ActionDialog, Stack } from '~/components'

import { useCopiarCofiguracoesLotacao } from '~/hooks/queries/ExportacaoLancamentoConfig/useCopiarCofiguracoesLotacao'

import { FPMovimentoTipoEnum } from '~/@types/enums/FPMovimentoTipoEnum'
import AutoCompleteLotacaoConfigConsulta from '~/components/AutoComplete/AutoCompleteLotacaoConfigConsulta'

interface DialogCopyConfigsProps {
  onClose: () => void
  movimentoTipo: FPMovimentoTipoEnum
  lotacaoDestino: string
  modeloIntegracaoId: string
}

export default function DialogCopyConfigs({
  onClose,
  movimentoTipo,
  lotacaoDestino,
  modeloIntegracaoId,
}: DialogCopyConfigsProps) {
  const [lotacaoOrigem, setLotacaoOrigem] = useState('')

  const { mutateAsync, isLoading: isSubmtting } = useCopiarCofiguracoesLotacao()

  async function handleSubmit() {
    await mutateAsync({
      params: { movimentoTipo, lotacaoDestino, lotacaoOrigem },
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Copiar Configurações"
      isOpen={true}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      okLabel="Processar"
      onOkClick={() => handleSubmit()}
      isOkProcessing={isSubmtting}
    >
      <Stack>
        <Typography>Selecione a lotação para copiar as configurações.</Typography>

        <AutoCompleteLotacaoConfigConsulta
          modeloIntegracaoId={modeloIntegracaoId}
          label="Lotação Origem"
          value={lotacaoOrigem}
          required
          onChange={(obj) => setLotacaoOrigem(obj?.id || '')}
          getOptionDisabled={(option: any) => option.id === lotacaoDestino}
        />
      </Stack>
    </ActionDialog>
  )
}
