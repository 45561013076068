const salarioContratual_1 = 1
const saldoDeSalario_2 = 2
const totalDeVencimento_3 = 3

export const sindicatoValeTransporteBaseCalculoValues = [
  { value: salarioContratual_1, name: '1 - Salário Contratual' },
  { value: saldoDeSalario_2, name: '2 - Saldo do Salário' },
  { value: totalDeVencimento_3, name: '3 - Total do Vencimento' },
]

export const sindicatoValeTransporteBaseCalculoConsts = {
  salarioContratual_1,
  saldoDeSalario_2,
  totalDeVencimento_3,
}
