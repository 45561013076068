export enum ASOTipoExameEnum {
  Admissional_0 = 0,
  Periódico = 1,
  RetornoAoTrabalho_2 = 2,
  MudancaDeFunçãoRiscoOcupacional_3 = 3,
  MonitoracaoPontualNaoEnquadradoNosDemaisCasos_4 = 4,
  Demissional_9 = 9,
}

export const ASOTipoExameLabels = {
  [ASOTipoExameEnum.Admissional_0]: 'Admissional',
  [ASOTipoExameEnum.Periódico]: 'Periódico',
  [ASOTipoExameEnum.RetornoAoTrabalho_2]: 'Retorno ao Trabalho',
  [ASOTipoExameEnum.MudancaDeFunçãoRiscoOcupacional_3]: 'Mudança de Função Risco Ocupacional',
  [ASOTipoExameEnum.MonitoracaoPontualNaoEnquadradoNosDemaisCasos_4]:
    'Monitoração pontual não enquadrado nos demais casos',
  [ASOTipoExameEnum.Demissional_9]: 'Demissional',
}

export const ASOTipoExameValues = [
  {
    value: ASOTipoExameEnum.Admissional_0,
    name: 'Admissional',
  },
  {
    value: ASOTipoExameEnum.Periódico,
    name: 'Periódico',
  },
  {
    value: ASOTipoExameEnum.RetornoAoTrabalho_2,
    name: 'Retorno ao Trabalho',
  },
  {
    value: ASOTipoExameEnum.MudancaDeFunçãoRiscoOcupacional_3,
    name: 'Mudança de Função Risco Ocupacional',
  },
  {
    value: ASOTipoExameEnum.MonitoracaoPontualNaoEnquadradoNosDemaisCasos_4,
    name: 'Monitoração pontual não enquadrado nos demais casos',
  },
  {
    value: ASOTipoExameEnum.Demissional_9,
    name: 'Demissional',
  },
]
