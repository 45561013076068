export enum IndOrgaoClasseEnum {
  CRM_1 = 1,
  CREA_4 = 4,
  Outros_9 = 9,
}

export const IndOrgaoClasseLabels = {
  [IndOrgaoClasseEnum.CRM_1]: '1 - Conselho Regional de Medicina - CRM',
  [IndOrgaoClasseEnum.CREA_4]: '4 - Conselho Regional de Engenharia e Agronomia - CREA',
  [IndOrgaoClasseEnum.Outros_9]: '9 - Outros',
}

export const IndOrgaoClasseValues = [
  {
    name: '1 - Conselho Regional de Medicina - CRM',
    value: IndOrgaoClasseEnum.CRM_1,
  },
  {
    name: '4 - Conselho Regional de Engenharia e Agronomia - CREA',
    value: IndOrgaoClasseEnum.CREA_4,
  },
  {
    name: '9 - Outros',
    value: IndOrgaoClasseEnum.Outros_9,
  },
]
