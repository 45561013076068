import * as yup from 'yup'

import { ActionDialog, CurrencyTextField, DatePickerNew } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { SalarioMinimo } from '~/hooks/queries/SalarioMinimo/SalarioMinimo'
import { useCriarSalarioMinimo } from '~/hooks/queries/SalarioMinimo/useCriarSalarioMinimo'
import { useAtualizarSalarioMinimo } from '~/hooks/queries/SalarioMinimo/useAtualizarSalarioMinimo'
import { useState } from 'react'
import { Grid } from '@material-ui/core'

const schema = yup.object().shape({
  dtSalario: yup
    .date()
    .required('Informe a Data do Salário')
    .typeError('Informe uma data válida')
    .nullable(),
  vrMensal: yup.string().required('Informe o Valor Mensal').nullable(),
  vrDia: yup.string().required('Informe o Valor Dia').nullable(),
  vrHora: yup.string().required('Informe o Valor Hora').nullable(),
})

interface FormProps {
  data: SalarioMinimo | null
  isOpen: boolean
  onClose: () => void
}

export default function Form(props: FormProps) {
  const { data: _data, isOpen, onClose } = props

  const [data, setData] = useState<Nullable<SalarioMinimo>>(
    _data || {
      dtSalario: null,
      vrMensal: null,
      vrDia: null,
      vrHora: null,
      observacao: null,
    },
  )

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const { mutateAsync: mutateAsyncCriar, isLoading: isLoadingCriar } = useCriarSalarioMinimo()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: isLoadingAtualizar } =
    useAtualizarSalarioMinimo()

  const isLoading = isLoadingCriar || isLoadingAtualizar

  async function handleSubmit() {
    const d = data as SalarioMinimo
    if (data.id) {
      await mutateAsyncAtualizar(d)
    } else {
      await mutateAsyncCriar(d)
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Salário Mínimo Formulário"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
      okLabel="Salvar"
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data do Salário"
            value={data.dtSalario || null}
            onChange={(date) => {
              const dtSalario = date || null
              setData((prev) => ({ ...prev, dtSalario }))
            }}
            required
            validationErrors={validationErrors}
            name="dtSalario"
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Valor Hora"
            value={data.vrHora || null}
            onChange={(_, value) => {
              const vrHora = value
              setData((oldState) => ({
                ...oldState,
                vrHora,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrHora"
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Valor Dia"
            value={data.vrDia || null}
            onChange={(_, value) => {
              const vrDia = value
              setData((oldState) => ({
                ...oldState,
                vrDia,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrDia"
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Valor Mensal"
            value={data.vrMensal || null}
            onChange={(_, value) => {
              const vrMensal = value
              setData((oldState) => ({
                ...oldState,
                vrMensal,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrMensal"
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
