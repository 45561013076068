import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'
import { TextField, ContentDivider } from 'mio-library-ui'

import { CEPTextField, ContainerForm } from '~/components'
import { AutoCompleteCidade } from '~/components/AutoComplete'

import apiPessoal from '~/services/api-pessoal'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

const FormLotacao = (props) => {
  const { data: _data, onFormSubmit, onCancelar, setWasModified } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setWasModified(JSON.stringify(data) !== JSON.stringify(_data))
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    setData(_data)
  }, [_data])

  useEffect(() => undefined, [data.tipoLotacao])

  const handleClick = () => {
    if (data.id) {
      update()
      return
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleClick,
    data,
  })

  const update = async () => {
    setSubmitting(true)
    try {
      data.nome = data.nome.trim()
      const response = await apiPessoal.put(`/lotacao/${data.id}`, data)
      notification.put()
      onFormSubmit('onAtualizar', response.data.data)
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setSubmitting(false)
  }

  const insert = async () => {
    setSubmitting(true)
    try {
      data.nome = data.nome.trim()
      const response = await apiPessoal.post(`/lotacao`, data)
      notification.post()
      onFormSubmit('onAdicionar', response.data.data)
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setSubmitting(false)
  }

  return (
    <ContainerForm handleSubmit={handleValidate} onCancel={onCancelar} isLoading={isSubmitting}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider top={2} />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            label="Código"
            fullWidth
            value={data.codigo || ''}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const codigo = e.target.value
              setData({ ...data, codigo })
            }}
            disabled
          />
        </Grid>

        <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data.nome || ''}
            variant="outlined"
            size="small"
            name="nome"
            required
            inputProps={{
              maxLength: 100,
            }}
            autoFocus
            validationErrors={validationErrors}
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Endereço" />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={5} xs={12}>
          <CEPTextField
            label="CEP"
            fullWidth
            value={data?.cep || ''}
            variant="outlined"
            size="small"
            getAddress={
              data?.id
                ? undefined
                : (address) =>
                    setData((oldState) => ({
                      ...oldState,
                      ...address,
                    }))
            }
            onChange={(e) => {
              const cep = e?.target?.value?.replace(/\D/g, '') || ''
              setData({ ...data, cep })
            }}
          />
        </Grid>

        <Grid item xl={5} lg={5} md={5} sm={7} xs={12}>
          <AutoCompleteCidade
            value={data?.cidade || null}
            onChange={(e, cidade) => {
              const cidadeId = cidade?.id || null
              setData({ ...data, cidadeId, cidade })
            }}
          />
        </Grid>

        <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
          <TextField
            label="Complemento"
            fullWidth
            value={data.complemento || ''}
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 80 }}
            onChange={(e) => {
              const complemento = e.target.value
              setData({ ...data, complemento })
            }}
          />
        </Grid>

        <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
          <TextField
            label="Endereço"
            fullWidth
            value={data.endereco || ''}
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              const endereco = e.target.value
              setData({ ...data, endereco })
            }}
          />
        </Grid>

        <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
          <TextField
            label="Bairro"
            fullWidth
            value={data.bairro || ''}
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              const bairro = e.target.value
              setData({ ...data, bairro })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <TextField
            label="Número"
            fullWidth
            value={data.numero || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 20,
            }}
            onChange={(e) => {
              const numero = e.target.value
              setData({ ...data, numero })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data.observacao || ''}
            variant="outlined"
            size="small"
            multiline
            inputProps={{ maxLength: 200 }}
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>
      </Grid>
    </ContainerForm>
  )
}

export default FormLotacao
