import React, { useState } from 'react'

import { ContainerComponent } from '~/components'

import Form from './components/Form'
import ProcessoSituacao from './components/ProcessoSituacao'

const Container = (props) => {
  const { isOpen, onClose, data: processo, onAfterSubmitForm } = props

  const [wasModified, setWasModified] = useState(false)

  return (
    <ContainerComponent
      title="Cadastro de Processo"
      isOpen={isOpen}
      onClose={onClose}
      formWasModified={wasModified}
      tabs={[
        {
          label: 'Cadastro',
          disabled: processo?.id ? false : true,
          component: (
            <Form
              data={processo}
              setWasModified={setWasModified}
              onAfterSubmitForm={onAfterSubmitForm}
              onClickClose={onClose}
            />
          ),
        },
        {
          label: 'Situação',
          disabled: processo?.id ? false : true,
          component: <ProcessoSituacao processoId={processo?.id} />,
        },
      ]}
    />
  )
}

export default Container
