const naoAplicavel_9 = 0
const refugiado_1 = 1
const solicitanteDeRefugio_2 = 2
const permanênciaNoBrasilReuniaoFamiliar_3 = 3
const beneficiadoPeloAcordoMercosul_4 = 4
const dependenteDeAagenteDiplomático_ExercícioAtividadeRemuneradaBrasil_5 = 5
const beneficiadoPeloTratadoDeAmizadeRepublicaPortuguesa_6 = 6
const outraCondicao_7 = 7

export const imigranteCondicaoTrabalhadorValues = [
  { value: naoAplicavel_9, name: '0 - Não informar' },
  { value: refugiado_1, name: '1 - Refugiado' },
  { value: solicitanteDeRefugio_2, name: '2 - Solicitante de Refúgio' },
  {
    value: permanênciaNoBrasilReuniaoFamiliar_3,
    name: '3 - Permanência no Brasil em razão de reunião familiar',
  },
  {
    value: beneficiadoPeloAcordoMercosul_4,
    name: '4 - Beneficiado pelo acordo entre países do Mercosul',
  },
  {
    value: dependenteDeAagenteDiplomático_ExercícioAtividadeRemuneradaBrasil_5,
    name: '5 - Dependente de agente diplomático e/ou consular de países que mantêm acordo de reciprocidade para o exercício de atividade remunerada no Brasil',
  },
  {
    value: beneficiadoPeloTratadoDeAmizadeRepublicaPortuguesa_6,
    name: '6 - Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil e a República Portuguesa',
  },
  { value: outraCondicao_7, name: '7 - Outra condição' },
]

export const imigranteCondicaoTrabalhadorConsts = {
  naoAplicavel_9,
  refugiado_1,
  solicitanteDeRefugio_2,
  permanênciaNoBrasilReuniaoFamiliar_3,
  beneficiadoPeloAcordoMercosul_4,
  dependenteDeAagenteDiplomático_ExercícioAtividadeRemuneradaBrasil_5,
  beneficiadoPeloTratadoDeAmizadeRepublicaPortuguesa_6,
  outraCondicao_7,
}
