const liminarEmMandadoSeguranca_01 = 1
const depositoJudicialMontanteIntegral_02 = 2
const depositoAdministrativoMontanteIntegral_03 = 3
const antecipacaoTutela_04 = 4
const liminarMedidaCautelar_05 = 5
const sentencaMandadoSegurancaFavoravelAoContribuinte_08 = 8
const sentencaFavorávelAoContribuinteConfirmadaPeloTRF_09 = 9
const acordao_TRF_10 = 10
const acordao_STJ_11 = 11
const acordao_STF_12 = 12
const sentençaPrimeiraInstânciaNaoTransitadaEfeitoSuspensivo_13 = 13
const contestacaoAdministrativaFAP_14 = 14
const decisaoDefinitivaFavorDoContribuinte_90 = 90
const semSuspensãoExigibilidade_92 = 92

export const processoIndicativoSuspencaoValues = [
  {
    value: liminarEmMandadoSeguranca_01,
    name: '1 - Liminar em Mandato de Segurança',
  },
  {
    value: depositoJudicialMontanteIntegral_02,
    name: '2 - Depósito Judicial do Montante Integral',
  },
  {
    value: depositoAdministrativoMontanteIntegral_03,
    name: '3 - Depósito Administrativo do Montante Integral',
  },
  { value: antecipacaoTutela_04, name: '4 - Antecipação de Tutela' },
  { value: liminarMedidaCautelar_05, name: '5 - Liminar em Medida Cautelar' },
  {
    value: sentencaMandadoSegurancaFavoravelAoContribuinte_08,
    name: '8 - Sentença em Mandato de Segurança Favorável ao Contribuinte',
  },
  {
    value: sentencaFavorávelAoContribuinteConfirmadaPeloTRF_09,
    name: '9 - Sentença em Ação Ordinária Favorável ao Contribuinte e Confirmarmada pelo TRF',
  },
  {
    value: acordao_TRF_10,
    name: '10 - Acórdão do TRF Favorável ao Contribuinte',
  },
  {
    value: acordao_STJ_11,
    name: '11 - Acórdão do STJ em Recurso Especial Favorável ao Contribuinte',
  },
  {
    value: acordao_STF_12,
    name: '12 - Acórdão do STF em Recurso Extraordinário Favorável ao Contribuinte',
  },
  {
    value: sentençaPrimeiraInstânciaNaoTransitadaEfeitoSuspensivo_13,
    name: '13 - Sentença 1ª instância não transitada em julgado com efeito suspensivo',
  },
  {
    value: contestacaoAdministrativaFAP_14,
    name: '14 - Contestação Administrativa FAP',
  },
  {
    value: decisaoDefinitivaFavorDoContribuinte_90,
    name: '90 - Decisão Definitiva a favor do contribuinte',
  },
  {
    value: semSuspensãoExigibilidade_92,
    name: '92 - Sem suspensão de exigibilidade',
  },
]
