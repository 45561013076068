import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { AnoMesTextField, Button, PercentTextFieldNew } from '~/components'
import { query } from '~/hooks/queries/AntecipacaoSalarial/useObterFuncionarios'
import { AntecipacaoSalarialFuncionarioDTO } from '~/hooks/queries/AntecipacaoSalarial/AntecipacaoSalarialDTO'

interface HeaderFormProps {
  rowsSelected: number[]
}

export default function HeaderForm(props: HeaderFormProps) {
  const { rowsSelected } = props

  const [data, setData] = useState<{
    anoMesInicio: string | null
    anoMesFim: string | null
    percentual: number | null
  }>({
    anoMesInicio: null,
    anoMesFim: null,
    percentual: null,
  })

  const queryClient = useQueryClient()
  const theme = useTheme()

  async function handleApply() {
    await queryClient.cancelQueries(query)
    queryClient.setQueriesData<AntecipacaoSalarialFuncionarioDTO[]>(query, (prev) => {
      if (!prev) return []
      return prev.map<AntecipacaoSalarialFuncionarioDTO>((d, index) =>
        rowsSelected.includes(index)
          ? {
              ...d,
              anoMesInicio: data.anoMesInicio as string,
              anoMesFim: data.anoMesFim as string,
              percentual: data.percentual,
            }
          : d,
      )
    })
  }

  return (
    <Paper>
      <Box padding={2} display="flex" alignItems="center" gridGap={theme.spacing(2)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <AnoMesTextField
              label="Ano/Mês Início"
              value={data.anoMesInicio || null}
              onChange={(e) => {
                const anoMes = e.target.value.replace('/', '')
                setData((prev) => ({ ...prev, anoMesInicio: anoMes }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AnoMesTextField
              label="Ano/Mês Fim"
              value={data.anoMesFim || null}
              onChange={(e) => {
                const anoMes = e.target.value.replace('/', '')
                setData((prev) => ({ ...prev, anoMesFim: anoMes }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <PercentTextFieldNew
              label="Percentual"
              value={data.percentual}
              onChange={(value) => setData((prev) => ({ ...prev, percentual: value }))}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          onClick={handleApply}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Aplicar
        </Button>
      </Box>
    </Paper>
  )
}
