import { useMemo } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerText: {
    fontSize: '1.5rem',
  },
  nomeSistema: {
    fontFamily: 'roboto',
    color: theme.palette.primary.contrastText,
    marginTop: '0px',
  },
}))

export default function Header() {
  const classes = useStyles()

  const version = useMemo(() => {
    return import.meta.env.VITE_VERSION || '9.9.99'
  }, [])

  return (
    <Box component="header" className={classes.headerContainer}>
      <Typography component="h1" className={classes.headerText}>
        Folha
      </Typography>

      <Typography className={classes.nomeSistema} variant="body2" style={{ fontWeight: '100' }}>
        {`versão: ${version}`}
      </Typography>
    </Box>
  )
}
