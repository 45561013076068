import React, { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Divider, useTheme } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Button, Container, PageHeader } from '~/components'

import Form from './Form'
import Table from './Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { dateHasBetweenYearMonth } from '~/hooks/useUtils'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import {
  RescisaoComplementarEventoDTO,
  RescisaoComplementarFuncionarioDTO,
} from '~/hooks/queries/RescisaoComplementar/RescisaoComplementarDTO'
import { AcordoDissidioConvencao } from '~/hooks/queries/AcordoDissidioConvencao/AcordoDissidioConvencao'
import { gerarReciboRescisaoComplementar } from '~/hooks/queries/RescisaoComplementar/gerarReciboRescisaoComplementar'
import FormEvento from './FormEvento'

const schema = yup.object().shape({
  funcionario: yup.object().required('Informe o Funcionário').nullable(),
  acordoDissidioColetivo: yup.object().required('Informe o Acordo ou Dissídio Coletivo').nullable(),
  dtRescisaoComplementar: yup
    .date()
    .required('Informe a Data da Rescisão Complementar')
    .typeError('Informe uma data válida')
    .when(['$anoMes'], (anoMes, schema) => {
      return schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data informada deve está dentro da competência atual',
        (dt: string) => dateHasBetweenYearMonth(dt, anoMes),
      )
    })
    .nullable(),
  dtRescisaoComplementarPagamento: yup
    .date()
    .required('Informe a Data de Pagamento')
    .typeError('Informe uma data válida')
    .nullable(),
})

const initialDataForm = {
  funcionario: null,
  acordoDissidioColetivo: null,
  dtRescisaoComplementar: null,
  dtRescisaoComplementarPagamento: null,
}

export interface DataForm {
  funcionario: RescisaoComplementarFuncionarioDTO | null
  acordoDissidioColetivo: AcordoDissidioConvencao | null
  dtRescisaoComplementar: string | null
  dtRescisaoComplementarPagamento: string | null
}

export default function RescisaoComplementar() {
  const [dataForm, setDataForm] = useState<DataForm>(initialDataForm)
  const [isSubmiting, setSubmiting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const theme = useTheme()
  const { anoMes, estabelecimento } = useAmbiente()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data: dataForm,
    schemaOptions: {
      abortEarly: false,
      context: { anoMes },
    },
  })

  const isFuncionarioSelected = dataForm.funcionario ? true : false

  useEffect(() => {
    setDataForm(initialDataForm)
  }, [anoMes, estabelecimento])

  function handleCancel() {
    setDataForm(initialDataForm)
  }

  async function handleSubmit() {
    if (!dataForm.funcionario) return
    setSubmiting(true)
    try {
      const eventosComDiferenca = dataForm.funcionario.reciboRescisao.eventos.filter(
        (d) => d.vrDiferencaAPagar > 0,
      )
      if (eventosComDiferenca.length === 0) {
        throw new Error('Não foi configurado eventos com Diferença a pagar.')
      }
      await gerarReciboRescisaoComplementar({
        funcionarioId: dataForm.funcionario.id,
        acordoDissidioConvencaoId: dataForm.acordoDissidioColetivo?.id as string,
        dtRecibo: dataForm.dtRescisaoComplementar as string,
        dtReciboPagamento: dataForm.dtRescisaoComplementarPagamento as string,
        eventos: eventosComDiferenca,
      })
      notification.success('Recibo de Rescisão Complementar criado com sucesso.')
      setDataForm(initialDataForm)
      window.open('#/recibo-pagamento/rescisao-complementar', '_blank')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmiting(false)
    }
  }

  function handleDeleteRow(rowIndex: number) {
    setDataForm((prev) => {
      const func = prev?.funcionario as RescisaoComplementarFuncionarioDTO
      const rp = func.reciboRescisao
      return {
        ...prev,
        funcionario: {
          ...func,
          reciboRescisao: {
            ...rp,
            eventos: [...rp.eventos.filter((d, index) => index !== rowIndex)],
          },
        },
      }
    })
  }

  function handleAddEvento(evento: RescisaoComplementarEventoDTO) {
    setDataForm((prev) => {
      const func = prev?.funcionario as RescisaoComplementarFuncionarioDTO
      const rp = func.reciboRescisao
      return {
        ...prev,
        funcionario: {
          ...func,
          reciboRescisao: {
            ...rp,
            eventos: [...rp.eventos, evento],
          },
        },
      }
    })
  }

  function handleChangeRow(eventos: RescisaoComplementarEventoDTO[]) {
    setDataForm((prev) => {
      const func = prev?.funcionario as RescisaoComplementarFuncionarioDTO
      const rp = func.reciboRescisao
      return {
        ...prev,
        funcionario: {
          ...func,
          reciboRescisao: {
            ...rp,
            eventos,
          },
        },
      }
    })
  }

  return (
    <Container>
      <Box height={50}>
        <PageHeader title="Rescisão Complementar" />
      </Box>

      <Form
        data={dataForm}
        setData={setDataForm}
        validationErrors={validationErrors}
        isFuncionarioSelected={isFuncionarioSelected}
      />

      <Table
        data={dataForm?.funcionario?.reciboRescisao?.eventos || []}
        onDeleteRow={handleDeleteRow}
        onChangeRow={handleChangeRow}
        triggersHeight={[validationErrors]}
      />

      <Box gridGap={theme.spacing(1.5)} display="flex" flexDirection="column" marginTop={1.5}>
        <Divider />

        <FormEvento disabled={!isFuncionarioSelected} onChange={handleAddEvento} />

        <ButtonBox>
          <Button onClick={handleCancel} disabled={!dataForm.funcionario}>
            Cancelar
          </Button>
          <Button
            onClick={handleValidate}
            isLoading={isSubmiting}
            variant="contained"
            disabled={!dataForm.funcionario}
          >
            Gerar Rescisão Complementar
          </Button>
        </ButtonBox>
      </Box>
    </Container>
  )
}
