import { useState } from 'react'

import { Box, useTheme } from '@material-ui/core'

import { Button, ButtonBox, ConfirmDeleteDialog, Finder, PageHeader } from '~/components'

import Form from './Form'
import Table from './Table'

import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'

import { VinculoTreinamento } from '~/hooks/queries/VinculoTreinamento/VinculoTreinamento'
import { orderBy } from '~/hooks/useUtils'

interface TreinamentosProps {
  vinculoId: string
  data: VinculoTreinamento[]
  onSubmit: (data: VinculoTreinamento[]) => void
}
export default function Treinamentos(props: TreinamentosProps) {
  const { vinculoId, data: _data, onSubmit } = props

  const [query, setQuery] = useState('')

  const notification = useNotification()

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string | null>(null)

  const theme = useTheme()

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<Partial<VinculoTreinamento> | null>(null)

  function handleClickAdd() {
    openForm({
      vinculoId,
    })
  }

  function handleClickItem(event: 'edit' | 'delete', id: string) {
    switch (event) {
      case 'edit':
        handleEditItem(id)
        break
      case 'delete':
        openConfirmDelete(id)
        break
    }
  }

  function handleEditItem(id: string) {
    const dt = _data.find((d) => d.id === id)
    if (dt) {
      openForm(dt)
    }
  }

  function handleDeleteItem(id: string) {
    onSubmit(_data.filter((d) => d.id !== id))
    closeConfirmDelete()
    notification.remove()
  }

  function afterSubmitForm(event: 'update' | 'insert', data: VinculoTreinamento) {
    switch (event) {
      case 'insert':
        handleInsert(data)
        break
      case 'update':
        handleUpdate(data)
        break
    }
  }

  function handleInsert(data: VinculoTreinamento) {
    const newArray = [..._data]
    newArray.push(data)
    const dt = orderBy(newArray, 'dtRealizacao')
    onSubmit(dt)
    closeForm()
  }

  function handleUpdate(data: VinculoTreinamento) {
    const newArray = _data.map((d) => (d.id === data.id ? data : d))
    const dt = orderBy(newArray, 'dtRealizacao')
    onSubmit(dt)
    closeForm()
  }

  return (
    <Box height="100%" padding={1} display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <PageHeader>
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button size="small" color="primary" onClick={handleClickAdd} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Box minHeight={200} flex={1} overflow="auto" position="relative">
        <Box position="absolute" width="100%">
          <Table data={_data} query={query} onItemClick={handleClickItem} />
        </Box>
      </Box>

      {isOpenForm && dataForm ? (
        <Form
          isOpen={isOpenForm}
          data={dataForm}
          onClose={closeForm}
          afterSubmit={afterSubmitForm}
        />
      ) : (
        <></>
      )}

      {isOpenConfirmDelete && dataConfirmDelete ? (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={() => handleDeleteItem(dataConfirmDelete)}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
