import moment from 'moment'

import { IconButton } from '@material-ui/core'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  PeopleOutlineSharp as PeopleOutlineSharpIcon,
} from '@material-ui/icons'

import { ButtonBox, CopyLabel, ToolsDataTable } from '~/components'

import { useGetAll } from '~/hooks/queries/ProcessoTrabalhista/useGetAll'

import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  InfoProcessoOrigemEnum,
  InfoProcessoOrigemLabels,
} from '~/@types/enums/InfoProcessoOrigemEnum'
import { ProcessoTrabalhista } from '~/hooks/queries/ProcessoTrabalhista/dtos/ProcessoTrabalhista'
import useAmbiente from '~/hooks/useAmbiente'

export type TableActionEvent = 'processos-funcionarios' | 'edit' | 'delete'

interface TableProps {
  query: string
  onActionClick: (event: TableActionEvent, row: ProcessoTrabalhista) => void
}

export default function Table({ query, onActionClick }: TableProps) {
  const { empregador } = useAmbiente()
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useGetAll({
    params: {
      empregadorId: empregador.id,
    },
  })
  const data = _data || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'identificacaoProcesso',
      label: 'Identificação',
    },
    {
      name: 'infoOrigemProcesso',
      label: 'Origem',
      options: {
        customBodyRender: (value: InfoProcessoOrigemEnum) => InfoProcessoOrigemLabels[value],
      },
    },
    {
      name: 'nrProcessoTrabalhista',
      label: 'Número',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{value}</CopyLabel>,
      },
    },
    {
      name: 'dtSentenca',
      label: 'Data da Sentença',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
    {
      name: 'dtCCP',
      label: 'Data CCP',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Processos Funcionários"
                color="primary"
                aria-label="Processos Funcionários"
                onClick={() => {
                  const rowCurrent = data.find((d) => d.id === value)
                  if (rowCurrent) {
                    onActionClick('processos-funcionarios', rowCurrent)
                  }
                }}
              >
                <PeopleOutlineSharpIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const rowCurrent = data.find((d) => d.id === value)
                  if (rowCurrent) {
                    onActionClick('edit', rowCurrent)
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  const rowCurrent = data.find((d) => d.id === value)
                  if (rowCurrent) {
                    onActionClick('delete', rowCurrent)
                  }
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['identificacaoProcesso', 'infoOrigemProcesso', 'nrProcessoTrabalhista'],
      }}
    />
  )
}
