import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

interface RequestParams {
  vinculoId: string
  dtInicioFerias: string
}

export function useValidarFeriasAtual() {
  const dialogNotification = useDialogNotification()

  async function handleRequest(params: RequestParams) {
    const response = await api.get<{ data: string[] }>('/VinculoFerias/ValidarFeriasAtual', {
      params,
    })
    return response.data.data
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
