import { useEffect, useState } from 'react'

export default function useHeightParents({
  refElement = null,
  triggers = null,
  disabled = false,
  delayResize = 500,
  returnHeight = false,
  minHeight = null,
}) {
  const [height, setHeight] = useState(0)
  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        if (disabled) return
        if (!refElement?.current) return

        const parentElement = refElement.current.parentElement
        const computedStyleParentElement = getComputedStyle(parentElement)
        const childrens = parentElement.children

        let heightParent = parentElement.clientHeight

        heightParent -=
          parseFloat(computedStyleParentElement.paddingTop) +
          parseFloat(computedStyleParentElement.paddingBottom)

        for (let index = 0; index < childrens.length; index++) {
          const children = childrens[index]
          if (children !== refElement.current) {
            heightParent -= children.offsetHeight
          }
        }

        heightParent = heightParent < minHeight ? minHeight : heightParent

        if (returnHeight) setHeight(heightParent)
        refElement.current.style.height = heightParent + 'px'
      }, delayResize)
    }
    handleResize()
    window.addEventListener('resize', () => {
      handleResize()
    })
    return () => {
      if (handleResize) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [refElement, triggers, disabled, delayResize, returnHeight, minHeight])

  return height
}
