import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterPorVinculoInvalidate } from './useObterPorVinculo'

import { notifyRemove } from '~/utils/notification'

interface RequestProps {
  params: { anoMes: string }
  id: string
}

export function useDeletar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterPorVinculoInvalidate()

  async function handleRequest({ id, params }: RequestProps) {
    await api.delete('/PlanoSaudeTitular/' + id, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
