function useHandleError() {
  const isHttpError = (err) => {
    return Boolean(err?.response)
  }

  const extractErrorMessage = (err) => {
    if (isHttpError(err)) {
      return String(
        err?.response?.data?.errors || 'Ocorreu um erro ao tentar processar a requisição',
      )
    }
    return err.message
  }

  return { isHttpError, extractErrorMessage }
}

export default useHandleError
