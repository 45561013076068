import api from '~/services/api-pessoal'

import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'
import { openDownloadData } from '~/hooks/useUtils'

export async function relatorioSalarioContratualObterRelatorioExcel(
  estabelecimento: EstabelecimentoConsulta,
  anoMes: string,
) {
  const response = await api.get('/Relatorio/SalarioContratual/ObterRelatorioExcel', {
    params: {
      estabelecimentoId: estabelecimento.id,
      anoMes,
    },
    headers: {
      'DC-XlsResponse': true,
    },
    responseType: 'blob',
  })

  const estabelecimentoNome = estabelecimento.nome.replaceAll(' ', '')

  openDownloadData(
    `${estabelecimentoNome}-${estabelecimento.nrInscricao}-${anoMes}.xlsx`,
    response.data,
  )
}
