import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'

import { Box } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer, Finder, Button } from '~/components'

import Table from './components/Table'

import { useEstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoConsulta'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import {
  RelVencimentoContratoExperienciaRequestDTO,
  enviarRelatoriosPorEmail,
} from '~/hooks/queries/VencimentoContratoExperiencia/enviarRelatoriosPorEmail'

const PAGE_TITLE = 'Relatório - Vencimento Contrato Experiência'

export default function VencimentoContratoExperiencia() {
  const [query, setQuery] = useState('')
  const [rowsSelecteds, setRowsSelected] = useState<number[]>([])
  const [isLoadingSendForEmail, setLoadingSendForEmail] = useState(false)
  const [relatorioId, setRelatorioId] = useState('')

  const { anoMes } = useAmbiente()
  const { data: collection, isLoading, isFetching } = useEstabelecimentoConsulta()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<RelVencimentoContratoExperienciaRequestDTO>({
    anoMes,
    estabelecimentosIds: [],
  })
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    async function getReportKey() {
      const response = await api.get('/Relatorio/GetByChaveId', {
        params: {
          chaveId: 'VencimentoContratoExperiencia',
        },
      })
      setRelatorioId(response?.data?.data[0].id)
    }
    getReportKey()
  }, [])

  function handlePrint() {
    const estabelecimentosIds = rowsSelecteds.map((index) => collection[index].id)
    openPDFViewer({ estabelecimentosIds, anoMes })
  }

  async function handleSendEmail() {
    setLoadingSendForEmail(true)
    try {
      const estabelecimentosIds = rowsSelecteds.map((index) => collection[index].id)
      await enviarRelatoriosPorEmail({ estabelecimentosIds, anoMes }, relatorioId)
      notification.success('Os relatórios foram enviados com sucesso')
      setRowsSelected([])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingSendForEmail(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={PAGE_TITLE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Table
        isLoading={isLoading}
        isFetching={isFetching}
        query={query}
        data={collection}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelecteds}
      />

      <Box display="flex" justifyContent="flex-end" py={1} gridGap={8}>
        <Button
          title="Enviar para os emails selecionados"
          isLoading={isLoadingSendForEmail}
          disabled={!(rowsSelecteds.length > 0)}
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleSendEmail}
        >
          Enviar por Email
        </Button>
        <PrintButton onClick={handlePrint} disabled={!(rowsSelecteds.length > 0)} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="VencimentoContratoExperiencia"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/VencimentoContratoExperiencia/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
