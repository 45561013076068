import React, { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'
import { AutoCompleteMedico } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import { CondicaoAmbienteTrabalhoResponsavelPartial } from '~/hooks/queries/CondicaoAmbienteTrabalhoResponsavel/CondicaoAmbienteTrabalhoResponsavel'

const schema = yup.object().shape({
  responsavelId: yup.string().required('Informe o Responsável'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: CondicaoAmbienteTrabalhoResponsavelPartial
  indexCurrent: number
  onAfterSubmitForm: (
    event: 'insert' | 'update',
    data: CondicaoAmbienteTrabalhoResponsavelPartial,
    indexCurrent: number,
  ) => void
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, indexCurrent, onAfterSubmitForm } = props
  const [data, setData] = useState(_data)
  const notification = useNotification()

  const handleSubmit = () => {
    const update = async () => {
      onAfterSubmitForm('update', data, indexCurrent)
      notification.put()
    }

    const insert = async () => {
      onAfterSubmitForm('insert', data, indexCurrent)
      notification.post()
    }

    if (indexCurrent >= 0) {
      update()
      return
    }
    insert()
  }
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  return (
    <ActionDialog
      title="Cadastro de Responsável"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <AutoCompleteMedico
            label="Responsável"
            required
            name="responsavelId"
            validationErrors={validationErrors}
            value={data?.responsavelId || ''}
            onChange={(e, responsavel) => {
              const responsavelId = responsavel?.id || ''
              setData((oldState) => ({
                ...oldState,
                responsavelId,
                responsavel,
              }))
            }}
            optionId="id"
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
