import React, { useMemo } from 'react'

import { HeightDataTable } from '~/components'

import { formatToBRL } from 'brazilian-values'
import moment from 'moment'

const Table = (props) => {
  const { data, isLoading } = props

  const columns = useMemo(
    () => [
      {
        name: 'anoMes',
        label: 'Ano/Mês',
        options: {
          customBodyRender: (value) => (value ? value.substr(0, 4) + '/' + value.substr(4) : ''),
        },
      },
      {
        name: 'numeroParcela',
        label: 'Parcela',
      },
      {
        name: 'vrParcela',
        label: 'Valor da Parcela',
        options: {
          customBodyRender: (value) => (value ? formatToBRL(value).replace('R$ ', '') : null),
        },
      },
      {
        name: 'dtPagamento',
        label: 'Data de Pagamento',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
    ],
    [],
  )

  return <HeightDataTable title="" data={data} columns={columns} isLoading={isLoading} />
}

export default Table
