import React from 'react'
import { Box, TextField as MUITextField, StandardTextFieldProps } from '@material-ui/core'
import ErrorMessage from '../ErrorMessage'

import { obterErroValidationErrors } from '~/hooks/useUtils'

export interface TextFieldProps extends StandardTextFieldProps {
  onlyNumber?: boolean
  separator?: string
  validationErrors?: ValidationErrors
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void
}

export default function TextField(props: TextFieldProps) {
  const {
    name,
    validationErrors,
    onChange,
    onBlur,
    onlyNumber = false,
    separator = '',
    ...rest
  } = props

  const mensagemDeErro = obterErroValidationErrors(name, validationErrors)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return
    if (onlyNumber) {
      const newValue = e.target.value
      e.target.value = newValue.replace(new RegExp(`[^0-9${separator}]`, 'g'), '')
    }
    onChange(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (!onBlur) return
    if (onlyNumber) {
      const newValue = e.target.value
      e.target.value = newValue.replace(new RegExp(`[^0-9${separator}]`, 'g'), '')
    }
    onBlur(e)
  }

  return (
    <Box>
      <MUITextField
        fullWidth
        size="small"
        color="primary"
        variant="outlined"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        {...rest}
      />
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </Box>
  )
}
