import { MenuItem, TextField } from '@material-ui/core'
import { useState } from 'react'
import { useEffect } from 'react'

const AnoSelect = (props) => {
  const { ...rest } = props
  const [anoList, setAnoList] = useState([])

  useEffect(() => {
    const currentYear = new Date().getFullYear()
    const minor = currentYear - 20
    const major = currentYear + 1

    let list = []

    for (let current = major; current >= minor; current--) {
      list.push(current)
    }

    setAnoList(list)
  }, [])

  return (
    <TextField variant="outlined" size="small" select {...rest}>
      {anoList.map((ano) => (
        <MenuItem key={ano} value={ano}>
          {ano}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default AnoSelect
