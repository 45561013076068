import React, { useState } from 'react'
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Icon,
  Tooltip,
  ClickAwayListener,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core'
import { MoreVert as ContextMenuIcon } from '@material-ui/icons'
import { withStyles, makeStyles, useTheme } from '@material-ui/styles'
import { Switch, BadgeInfo } from '~/components'
import PropTypes from 'prop-types'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: theme.palette.primary.main,
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,
    //     '& span': {
    //       color: theme.palette.common.white,
    //     },
    //   },
    // },
    '&:hover': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.primary.main,
        '& span': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  gutters: {
    paddingLeft: 0,
  },
}))(MenuItem)

const ContextMenu = (props) => {
  const { children, iconSize, disableHandleClickAway, mainIcon } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickAway = () => {
    if (!disableHandleClickAway) {
      setAnchorEl(null)
    }
  }

  return (
    <>
      <div>
        <ClickAwayListener onClickAway={handleClickAway}>
          <IconButton onClick={handleClick} size="small">
            {mainIcon || (
              <ContextMenuIcon color="primary" fontSize="small" style={{ fontSize: iconSize }} />
            )}
          </IconButton>
        </ClickAwayListener>
        <StyledMenu
          onClose={handleClose}
          anchorEl={anchorEl}
          keepMounted
          open={isOpen}
          variant="menu"
        >
          {Array.isArray(children) &&
            children.map(({ props: menuProps }) => {
              const {
                label,
                icon,
                onClick,
                onChange,
                disabled,
                isVisible,
                value,
                type,
                showNumber,
                isLoading,
              } = menuProps
              if (type === 'switch' && isVisible) {
                return (
                  <StyledMenuItem
                    onClick={onClick}
                    key={label}
                    // disabled={disabled || false}
                  >
                    <ListItemIcon>
                      <Icon fontSize="small">{icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={label} />
                    <div>
                      <Switch onChange={onChange} checked={value || false} />
                    </div>
                  </StyledMenuItem>
                )
              }

              return (
                isVisible && (
                  <StyledMenuItem onClick={onClick} key={label} disabled={disabled || false}>
                    <ListItemIcon>
                      <Icon fontSize="small">{icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={label} />
                    {isLoading && <CircularProgress color="inherit" size={20} />}
                    {showNumber > 0 && <BadgeInfo value={showNumber} />}
                  </StyledMenuItem>
                )
              )
            })}
        </StyledMenu>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  plainMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > * ': {
      marginLeft: theme.spacing(1),
      //   width: buttonsWidth,
    },
  },
  onHoverMenu: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}))

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip)

const PlainMenuItem = (props) => {
  const { label, icon, onClick, disabled, showNumber, isLoading } = props
  const classes = useStyles()
  return (
    <Box>
      <LightTooltip title={label}>
        <span>
          {!isLoading ? (
            <IconButton
              onClick={onClick}
              disabled={disabled}
              classes={{ root: classes.onHoverMenu }}
              size="small"
            >
              <Icon color={disabled ? 'disabled' : 'primary'} fontSize="small">
                {icon}
              </Icon>
              {showNumber > 0 && <BadgeInfo value={showNumber} />}
            </IconButton>
          ) : (
            <CircularProgress color="inherit" size={20} />
          )}
        </span>
      </LightTooltip>
    </Box>
  )
}

const PlainMenu = (props) => {
  const { children } = props
  const classes = useStyles()
  return (
    <Box className={classes.plainMenuContainer}>
      {Array.isArray(children) &&
        children.map(({ props: menuProps }) => {
          if (!menuProps.isVisible)
            return <div key={menuProps.label} style={{ display: 'none' }}></div>
          return <PlainMenuItem key={menuProps.label} {...menuProps} />
        })}
    </Box>
  )
}

export const Item = () => {
  return <Box></Box>
}

Item.defaultProps = {
  isVisible: true,
}

const DynamicContextMenu = (props) => {
  const { threshold, forceContextMenu, iconSize, disableHandleClickAway, mainIcon, ...rest } = props
  const theme = useTheme()
  const isThreshold = useMediaQuery(theme.breakpoints.up(threshold), {
    defaultMatches: true,
  })

  return (
    <Box>
      {!isThreshold || forceContextMenu ? (
        <ContextMenu
          iconSize={iconSize}
          disableHandleClickAway={disableHandleClickAway}
          mainIcon={mainIcon}
          {...rest}
        />
      ) : (
        <PlainMenu {...rest} />
      )}
    </Box>
  )
}

DynamicContextMenu.propTypes = {
  threshold: PropTypes.oneOf(['lg', 'md', 'xs', 'sm', 'xs']),
  forceContextMenu: PropTypes.bool,
}

DynamicContextMenu.defaultProps = {
  threshold: 'md',
  forceContextMenu: false,
  disableHandleClickAway: false,
  iconSize: 'inherite',
}

export default DynamicContextMenu
