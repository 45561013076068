import React, { useEffect, useState, useCallback } from 'react'

import { ButtonBox, Finder } from 'mio-library-ui'
import { IconButton, Box, makeStyles } from '@material-ui/core'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'
import { ContainerComponent } from '~/components'

import DinamicTab from './components/DinamicTab'

import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'

const GRUPO_EMPREGADOR = 1
const GRUPO_TABELAS = 2
const GRUPO_NAO_PERIODICOS = 3
export const GRUPO_PERIODICOS = 4
export const GRUPO_FECHAMENTO = 5
const GRUPO_REABERTURA = 6

const useStyles = makeStyles((theme) => ({
  renderRightTab: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.700rem',
    fontWeight: 'bold',
    color: '#fff',
  },
}))

function RenderRightComponent(props) {
  const { group, totalEvents } = props

  const classes = useStyles()

  if (!totalEvents[group]) return null

  return <Box className={classes.renderRightTab}>{totalEvents[group]}</Box>
}

export default function TransmissaoESocial() {
  const [query, setQuery] = useState('')
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)
  const [totalEvents, setTotalEvents] = useState({})
  const [tabValueStart, setTabValueStart] = useState(GRUPO_EMPREGADOR - 1)

  const { anoMes } = useAmbiente()

  const getTotalTitleTabPerGroup = useCallback(async () => {
    try {
      const response = await api.get('/ESocial/ObterTotalEventosPorGrupo', {
        params: {
          anoMes,
        },
      })
      const currentResponse = response?.data?.data || []

      const newTotalEvents = {}

      let group = {
        id: GRUPO_EMPREGADOR,
        totalEventos: 0,
      }

      currentResponse.forEach((item) => {
        if (item.totalEventos >= 100) return (newTotalEvents[item.grupoEventos] = '99+')
        newTotalEvents[item.grupoEventos] = item.totalEventos.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })
        if (item.totalEventos > group.totalEventos)
          group = {
            id: item.grupoEventos,
            totalEventos: item.totalEventos,
          }
      })

      setTabValueStart(group.id - 1)
      setTotalEvents(newTotalEvents)
    } catch (error) {
      console.log(error)
    }
  }, [anoMes])

  useEffect(() => {
    getTotalTitleTabPerGroup()
  }, [getTotalTitleTabPerGroup])

  return (
    <ContainerComponent
      title="Transmissão eSocial"
      fixedContainer
      tabValueStart={tabValueStart}
      renderRight={
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
            {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
          </IconButton>
        </ButtonBox>
      }
      tabs={[
        {
          label: 'Empregador',
          component: (
            <DinamicTab
              formCollapseIsOpen={formCollapseIsOpen}
              query={query}
              grupoEvento={GRUPO_EMPREGADOR}
              onAfterSubmit={getTotalTitleTabPerGroup}
            />
          ),
          renderRightTab: (
            <RenderRightComponent group={GRUPO_EMPREGADOR} totalEvents={totalEvents} />
          ),
        },
        {
          label: 'Tabelas',
          component: (
            <DinamicTab
              formCollapseIsOpen={formCollapseIsOpen}
              query={query}
              grupoEvento={GRUPO_TABELAS}
              onAfterSubmit={getTotalTitleTabPerGroup}
            />
          ),
          renderRightTab: <RenderRightComponent group={GRUPO_TABELAS} totalEvents={totalEvents} />,
        },
        {
          label: 'Não Periódicos',
          component: (
            <DinamicTab
              formCollapseIsOpen={formCollapseIsOpen}
              query={query}
              grupoEvento={GRUPO_NAO_PERIODICOS}
              onAfterSubmit={getTotalTitleTabPerGroup}
            />
          ),
          renderRightTab: (
            <RenderRightComponent group={GRUPO_NAO_PERIODICOS} totalEvents={totalEvents} />
          ),
        },
        {
          label: 'Periódicos',
          component: (
            <DinamicTab
              formCollapseIsOpen={formCollapseIsOpen}
              query={query}
              grupoEvento={GRUPO_PERIODICOS}
              onAfterSubmit={getTotalTitleTabPerGroup}
            />
          ),
          renderRightTab: (
            <RenderRightComponent group={GRUPO_PERIODICOS} totalEvents={totalEvents} />
          ),
        },
        {
          label: 'Fechamento',
          component: (
            <DinamicTab
              formCollapseIsOpen={formCollapseIsOpen}
              query={query}
              grupoEvento={GRUPO_FECHAMENTO}
              onAfterSubmit={getTotalTitleTabPerGroup}
            />
          ),
          renderRightTab: (
            <RenderRightComponent group={GRUPO_FECHAMENTO} totalEvents={totalEvents} />
          ),
        },
        {
          label: 'Reabertura',
          component: (
            <DinamicTab
              formCollapseIsOpen={formCollapseIsOpen}
              query={query}
              grupoEvento={GRUPO_REABERTURA}
              onAfterSubmit={getTotalTitleTabPerGroup}
            />
          ),
          renderRightTab: (
            <RenderRightComponent group={GRUPO_REABERTURA} totalEvents={totalEvents} />
          ),
        },
      ]}
    />
  )
}
