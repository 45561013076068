import { FieldsetHTMLAttributes } from 'react'

import clsx from 'clsx'

import { Tooltip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    border: 0,
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.6',
  },
}))

export default function Fieldset(props: FieldsetHTMLAttributes<HTMLFieldSetElement>) {
  const { disabled = false, className, title, ...rest } = props
  const classes = useStyles()

  return (
    <Tooltip title={title || ''} arrow>
      <span>
        <fieldset
          {...rest}
          disabled={disabled}
          className={clsx(classes.root, className, {
            [classes.disabled]: disabled,
          })}
        />
      </span>
    </Tooltip>
  )
}
