import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'
import { useObterEventosExclusaoESocialInvalidate } from './useObterEventosExclusaoESocial'

interface RequestProps {
  params: {
    empregadorId: string
    anoMes: string
  }
  data: string
}

export function useProcessarExclusaoEventoESocial() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterEventosExclusaoESocialInvalidate()

  async function handleRequest({ params, data }: RequestProps) {
    await api.post('/ESocialExclusaoEvento/ProcessarExclusaoEventoESocial', data, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Eventos excluídos com Sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
