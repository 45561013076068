import { Box, Icon, Tooltip, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(0.5),
    '&:hover': {
      cursor: (props: { disabled: boolean }) => (props.disabled ? 'default' : 'pointer'),
    },
  },
}))

interface HeaderItemSimpleProps {
  title: string
  icon: string
  disabled: boolean
  isLoading: boolean
  onClick: () => void
}

export default function HeaderItemSimple({
  title,
  icon,
  disabled,
  isLoading,
  onClick,
}: HeaderItemSimpleProps) {
  disabled = isLoading || disabled
  title = disabled ? '' : title

  const classes = useStyles({ disabled })

  return (
    <Box className={classes.root} onClick={disabled ? undefined : onClick}>
      <Icon fontSize="small" color={disabled ? 'action' : 'primary'}>
        {icon}
      </Icon>
      <Tooltip arrow title={title}>
        <Box
          fontWeight="bold"
          color="#666"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          width="100%"
        >
          {isLoading ? <Skeleton /> : title}
        </Box>
      </Tooltip>
    </Box>
  )
}
