export enum CodigoTreinamentoCapacitacaoEnum {
  TreinamentoAntesPrimeiroEmbarque_3701 = 3701,
  TreinamentoAntesPrimeiroEmbarqueReciclagem_3702 = 3702,
  TreinamentoEventual_3703 = 3703,
  TreinamentoBasico_3704 = 3704,
  TreinamentoBasicoReciclagem_3705 = 3705,
  TreinamentoAvançado_3706 = 3706,
  TreinamentoAvançadoReciclagem_3707 = 3707,
  TreinamentoEspecíficoEmpregadoIntegranteBrigadaIncêndio_3708 = 3708,
  TreinamentoEspecíficoRiscosRadiológicosPlataforma_3709 = 3709,
  TreinamentoEspecíficoEmpregadoIntegranteEquipeRespostaEmergências_3710 = 3710,
  TreinamentoSinaleiro_3711 = 3711,
  TreinamentoOperadorGuindaste_3712 = 3712,
  TreinamentoCursoBásicoParaManipuladorAlimentos_3713 = 3713,
  TreinamentoCursocomplementarServiçosInstalaçõesElétricasAltaTensão_3714 = 3714,
  TreinamentoCursoBásicoSegurançaOperaçõesMovimentaçãoCargasTransportePessoas_3715 = 3715,
  TreinamentoCursoComplementarParaOperadoresGuindastes_3716 = 3716,
  TreinamentoCursoParaIndivíduosCcupacionalmenteExpostosRadiaçãoIonizante_3717 = 3717,
  TreinamentoProcedimentoOperacionalAcendimentoChamaPiloto_3718 = 3718,
  AutorizaçãoparaTrabalharInstalaçõesElétricas_1006 = 1006,
  OperaçãoRealizaçãoIntervençõesMáquinas_1207 = 1207,
  OperadorEquipamentoDeGuindar_3719 = 3719,
}

export const CodigoTreinamentoCapacitacaoValues = [
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoAntesPrimeiroEmbarque_3701,
    name: '3701 - Treinamento antes do primeiro embarque',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoAntesPrimeiroEmbarqueReciclagem_3702,
    name: '3702 - Treinamento antes do primeiro embarque - Reciclagem',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoEventual_3703,
    name: '3703 - Treinamento eventual',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoBasico_3704,
    name: '3704 - Treinamento básico',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoBasicoReciclagem_3705,
    name: '3705 - Treinamento básico - Reciclagem',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoAvançado_3706,
    name: '3706 - Treinamento avançado',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoAvançadoReciclagem_3707,
    name: '3707 - Treinamento avançado - Reciclagem',
  },
  {
    value:
      CodigoTreinamentoCapacitacaoEnum.TreinamentoEspecíficoEmpregadoIntegranteBrigadaIncêndio_3708,
    name: '3708 - Treinamento específico - Empregado integrante da brigada de incêndio',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoEspecíficoRiscosRadiológicosPlataforma_3709,
    name: '3709 - Treinamento específico - Riscos radiológicos da plataforma',
  },
  {
    value:
      CodigoTreinamentoCapacitacaoEnum.TreinamentoEspecíficoEmpregadoIntegranteEquipeRespostaEmergências_3710,
    name: '3710 - Treinamento específico - Empregado integrante de equipe de resposta a emergências',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoSinaleiro_3711,
    name: '3711 - Treinamento - Sinaleiro (reciclagem)',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoOperadorGuindaste_3712,
    name: '3712 - Treinamento - Operador de guindaste (reciclagem)',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.TreinamentoCursoBásicoParaManipuladorAlimentos_3713,
    name: '3713 - Treinamento - Curso Básico para Manipulador de Alimentos',
  },
  {
    value:
      CodigoTreinamentoCapacitacaoEnum.TreinamentoCursocomplementarServiçosInstalaçõesElétricasAltaTensão_3714,
    name: '3714 - Treinamento - Curso complementar para serviços em instalações elétricas em alta tensão',
  },
  {
    value:
      CodigoTreinamentoCapacitacaoEnum.TreinamentoCursoBásicoSegurançaOperaçõesMovimentaçãoCargasTransportePessoas_3715,
    name: '3715 - Treinamento - Curso básico de segurança em operações de movimentação de cargas e transporte de pessoas',
  },
  {
    value:
      CodigoTreinamentoCapacitacaoEnum.TreinamentoCursoComplementarParaOperadoresGuindastes_3716,
    name: '3716 - Treinamento - Curso complementar para operadores de guindastes',
  },
  {
    value:
      CodigoTreinamentoCapacitacaoEnum.TreinamentoCursoParaIndivíduosCcupacionalmenteExpostosRadiaçãoIonizante_3717,
    name: '3717 - Treinamento - Curso para indivíduos ocupacionalmente expostos à radiação ionizante',
  },
  {
    value:
      CodigoTreinamentoCapacitacaoEnum.TreinamentoProcedimentoOperacionalAcendimentoChamaPiloto_3718,
    name: '3718 - Treinamento - Procedimento operacional - Acendimento da chama piloto',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.AutorizaçãoparaTrabalharInstalaçõesElétricas_1006,
    name: '1006 - Autorização para trabalhar em instalações elétricas',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.OperaçãoRealizaçãoIntervençõesMáquinas_1207,
    name: '1207 - Operação e realização de intervenções em máquinas',
  },
  {
    value: CodigoTreinamentoCapacitacaoEnum.OperadorEquipamentoDeGuindar_3719,
    name: '3719 - Operador do equipamento de guindar',
  },
]
