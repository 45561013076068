import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePicker } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtAssinatura: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data da assinatura')
    .nullable(),
})

const initialState = {
  dtAssinatura: getDateCurrent(),
}

export default function DialogConfirm(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState(initialState)

  function handleSubmit() {
    onAfterSubmit(data.dtAssinatura)
    onClose()
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  useEffect(() => {
    if (isOpen) return
    setData(initialState)
  }, [isOpen])

  return (
    <ActionDialog
      title="Informe a data para Assinatura da Ficha Salário Família"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePicker
            label="Data da assinatura"
            value={data?.dtAssinatura || null}
            name="dtAssinatura"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtAssinatura = date ? date.format('yyyy-MM-DD') : null
              setData((prevState) => ({ ...prevState, dtAssinatura }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
