import React, { useCallback, useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { Button, TextField, ButtonBox, PageHeader } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker } from '~/components'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'
import Table from './components/Table'

import moment from 'moment'
import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'

import * as yup from 'yup'

const schema = yup.object().shape({
  dtObservacao: yup.string().required('Informe a Data').nullable(),
  observacao: yup.string().required('Informe a Observação').nullable(),
})

const HEADER_HEIGHT = '50px'
const EstabelecimentoObservacao = ({ estabelecimentoId, anoMes }) => {
  const [currentList, setCurrentList] = useState([])
  const [current, setCurrent] = useState({})

  const [isOpen, setIsOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const notifications = useNotification()
  const dialogNotification = useDialogNotification()

  const pageTitle = 'Estabelecimento | Observação'
  const apiRoute = '/estabelecimentoObservacao'

  const getEstabelecimentoObservacao = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await api.get(`${apiRoute}/GetByEstabelecimento?id=${estabelecimentoId}`)
      if (response.data.data) {
        setCurrentList(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [estabelecimentoId])

  useEffect(() => {
    getEstabelecimentoObservacao()
    // eslint-disable-next-line
  }, [getEstabelecimentoObservacao])

  const handleStartNew = () => {
    setIsOpen(true)
    setCurrent({
      ...current,
      estabelecimentoId,
      dtObservacao: moment(anoMes).format('YYYY-MM-DD'),
    })
  }

  const handleCancelNew = () => {
    setIsOpen(false)

    setCurrent({})
  }

  const getCurrent = async (id) => {
    try {
      const response = await api.get(`${apiRoute}/${id}`)
      if (response.data.data) {
        setCurrent(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  const handleEdit = async (id) => {
    setCurrent(getCurrent(id))
    setIsOpen(true)
  }

  const handleDelete = (id) => {
    setCurrent({ id })

    if (!id) {
      notifications.warning(`Selecione um ${pageTitle} para excluir!`, 'Ops!', 4000)
      return
    }

    setIsOpenDelete(true)
  }

  const handleClickItem = (event, data) => {
    const functions = {
      edit: handleEdit,
      delete: handleDelete,
    }
    functions[event](data)
  }

  const handleSave = useCallback(() => {
    const update = async () => {
      setIsLoading(true)
      try {
        await api.put(`/estabelecimentoObservacao/${current.id}`, current)
        notifications.put()

        getEstabelecimentoObservacao()

        setIsOpen(false)
        setCurrent({})
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    const insert = async () => {
      setIsLoading(true)
      try {
        await api.post('/estabelecimentoObservacao', current)
        notifications.post()

        getEstabelecimentoObservacao()

        setIsOpen(false)
        setCurrent({})
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    if (current.id) {
      update()
      return
    }
    insert()
    // eslint-disable-next-line
  }, [current])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSave,
    data: current,
  })

  const deletar = async () => {
    setIsProcessing(true)

    try {
      const response = await api.delete(`${apiRoute}/${current.id}`)

      if (response.status === 200) {
        notifications.success('Registro foi removido', 'Sucesso!', 4000)
        getEstabelecimentoObservacao()
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsOpenDelete(false)
    setIsProcessing(false)
    setCurrent({})
  }

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            <Button size="small" onClick={handleStartNew} color="primary" variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={currentList} onItemClick={handleClickItem} isLoading={isLoading} />
      </Box>

      <ActionDialog
        title={'Cadastro de ' + pageTitle}
        okLabel="Salvar"
        isOpen={isOpen}
        onClose={handleCancelNew}
        onOkClick={handleValidate}
        onCancelClick={handleCancelNew}
        isOkProcessing={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <DatePicker
              label="Data"
              size="small"
              value={current.dtObservacao || null}
              required
              validationErrors={validationErrors}
              name="dtObservacao"
              onChange={(date) => {
                const dtObservacao = date ? date.format('yyyy-MM-DD') : null
                setCurrent({ ...current, dtObservacao })
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Observação"
              fullWidth
              multiline
              value={current.observacao || ''}
              variant="outlined"
              size="small"
              required
              rows={5}
              validationErrors={validationErrors}
              name="observacao"
              onChange={(e) => {
                const observacao = e.target.value
                setCurrent({ ...current, observacao })
              }}
            />
          </Grid>
        </Grid>
      </ActionDialog>

      <ActionDialog
        title="Excluir Registro"
        okLabel="Sim"
        isOpen={isOpenDelete}
        onClose={() => setIsOpenDelete(false)}
        onOkClick={deletar}
        onCancelClick={() => setIsOpenDelete(false)}
        isOkProcessing={isProcessing}
      >
        <Typography>Você quer mesmo excluir este registro</Typography>
      </ActionDialog>
    </>
  )
}

export default EstabelecimentoObservacao
