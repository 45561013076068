import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifySuccess } from '~/utils/notification'
import api from '~/services/api-pessoal'
import { IndMotivoDesligamentoTSVEnum } from '~/@types/enums/IndMotivoDesligamentoTSVEnum'

interface RequestProps {
  params: {
    vinculoId: string
    dtRescisao: string
    motivoDesligamentoEmpregador721: IndMotivoDesligamentoTSVEnum | null
  }
}

export function useGerarRescisaoTSV() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ params }: RequestProps) {
    const response = await api.post<{ data: string | null }>(
      '/RescisaoTSV/GerarRescisaoTSV',
      null,
      {
        params,
      },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('A rescisão TSV foi gerada com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
