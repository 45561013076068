import React from 'react'

import { makeStyles } from '@material-ui/core'
import { ActionDialog } from '~/components'
import { HeightDataTable } from '~/components'

import moment from 'moment'
import { IndFeriasValues } from '~/@types/enums/IndFeriasEnum'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '50%',
  },
}))

const formatIndFerias = (indFerias) => {
  const objectFinded = IndFeriasValues.find((obj) => obj.value === indFerias)
  return objectFinded.name
}

const makeColumns = () => [
  {
    name: 'anoMes',
    label: 'Ano/Mês',
    options: {
      customBodyRender: (value) => (value ? `${value.substr(0, 4)}/${value.substr(4)}` : null),
    },
  },
  {
    name: 'dtAvisoFerias',
    label: 'Aviso Férias',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'dtEmissaoFerias',
    label: 'Emissão Férias',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'dtInicioConcessao',
    label: 'Início Concessão',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'dtFimConcessao',
    label: 'Fim Concessão',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'dtRetorno',
    label: 'Retorno',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'nrDiasFerias',
    label: 'Dias',
  },
  {
    name: 'dtInicioAbono',
    label: 'Início Abono',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'dtFimAbono',
    label: 'Fim Abono',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'nrDiasAbono',
    label: 'Dias Abono',
  },
  {
    name: 'nrDiasConcedidos',
    label: 'Dias Concedidos',
  },
  {
    name: 'indFerias',
    label: 'Tipo',
    options: {
      customBodyRender: (value) => formatIndFerias(value),
    },
  },
]

const TableFeriasItens = (props) => {
  const { isOpen, onClose, data, isLoading } = props

  const classes = useStyles()

  return (
    <ActionDialog
      title="Período de Férias"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: { paper: classes.heightPaper },
      }}
      customActions={<></>}
    >
      <HeightDataTable
        title=""
        data={data}
        columns={makeColumns()}
        options={options}
        pagination={false}
        isLoading={isLoading}
      />
    </ActionDialog>
  )
}

const options = {
  viewColumns: false,
  filter: false,
  search: false,
  selectableRows: 'none',
  print: false,
  download: false,
  elevation: 0,
  responsive: 'standard',
}

export default TableFeriasItens
