export enum IndImprimirEventosEnum {
  Imprimir_Geral_1 = 1,
  Nao_Imprimir_Ferias_1 = 2,
}

export const IndImprimirEventosValues = [
  {
    name: 'Imprimir Todos os Eventos',
    value: IndImprimirEventosEnum.Imprimir_Geral_1,
  },
  {
    name: 'Não Imprimir Eventos de Férias',
    value: IndImprimirEventosEnum.Nao_Imprimir_Ferias_1,
  },
]
