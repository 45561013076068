import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { FolhaPontoDia } from './dtos/FolhaPontoDia'

interface RequestProps {
  data: FolhaPontoDia
  params: {
    id: string
  }
}

export function useAtualizarFolhaPontoDia() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    await api.put('/FolhaPonto/AtualizarFolhaPontoDia', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
