import { useState, useEffect, useCallback } from 'react'

import { makeStyles } from '@material-ui/core'
import { ButtonBox, Button, ContentDivider } from 'mio-library-ui'
import { ActionDialog, PDFViewer } from '~/components'

import { ConfirmDeleteDialog } from '~/components'
import FeriasFormComponent from '~/components/FeriasForm'

import TableItem from './components/TableItem'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '50%',
  },
}))

const Form = (props) => {
  const { isOpen, onClose, data, vinculo, titlePage } = props
  const [isLoadingSubmit, setLoadingSubmit] = useState(false)

  const [collectionItem, setCollectionItem] = useState({
    isLoading: false,
    itens: [],
  })
  const [confirmDeleteDialogItem, setConfirmDeleteDialogItem] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()
  const {
    close: closeFormProgramacao,
    data: dataFormProgramacao,
    isOpen: isOpenFormProgramacao,
    open: openFormProgramacao,
  } = useDialog(null)
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  const getCollectionItem = useCallback(async () => {
    if (!data?.id) {
      setCollectionItem({
        isLoading: false,
        itens: [],
      })
      return
    }
    setCollectionItem((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get(
        `/VinculoFeriasProgramacao/ObterVinculoFeriasProgramacao?vinculoFeriasId=${data?.id}`,
      )
      if (response.data.data) {
        setCollectionItem((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollectionItem((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    getCollectionItem()
  }, [getCollectionItem])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collectionItem
        const item = itens.find((i) => i.id === id)
        openFormProgramacao(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialogItem((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      function handleClickPrint(feriasProgramacaoId) {
        openPDFViewer({ feriasProgramacaoId })
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
        print: handleClickPrint,
      }
      functions[event](value)
    },
    // eslint-disable-next-line
    [collectionItem],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collectionItem.itens

    try {
      await api.delete(`/VinculoFeriasProgramacao/${confirmDeleteDialogItem.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialogItem.id)
      handleCloseConfirmDeleteItem()
      setCollectionItem((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collectionItem.itens, confirmDeleteDialogItem.id])

  function handleClickAddItem() {
    openFormProgramacao(null)
  }

  function handleSubmit(data) {
    const update = async () => {
      setLoadingSubmit(true)
      try {
        await api.put(`/VinculoFeriasProgramacao/${data.id}`, data)
        notification.put()
        closeFormProgramacao()
        getCollectionItem()
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingSubmit(false)
      }
    }

    const insert = async () => {
      setLoadingSubmit(true)
      try {
        await api.post('/VinculoFeriasProgramacao', data)
        notification.post()
        closeFormProgramacao()
        getCollectionItem()
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingSubmit(false)
      }
    }

    if (data?.id) {
      update()
      return
    }
    insert()
  }

  return (
    <ActionDialog
      title={titlePage}
      subtitle={vinculo?.nome}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      customActions={<div />}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <ButtonBox bottom={1}>
        <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
          Adicionar
        </Button>
      </ButtonBox>

      <ContentDivider bottom={2} />

      <TableItem
        data={collectionItem.itens}
        isLoading={collectionItem.isLoading}
        onItemClick={handleClickItem}
      />

      <ActionDialog
        title={titlePage}
        subtitle={vinculo.nome}
        isOpen={isOpenFormProgramacao}
        onClose={closeFormProgramacao}
        okLabel="Criar"
        onCancelClick={closeFormProgramacao}
        dialogProps={{ maxWidth: 'md', fullWidth: true }}
        customActions={<></>}
      >
        <FeriasFormComponent
          onSubmit={handleSubmit}
          onCancel={closeFormProgramacao}
          vinculoId={vinculo.id}
          vinculoFeriasId={data?.id}
          isLoading={isLoadingSubmit}
          isFeriasProgramacao={true}
          dataForm={dataFormProgramacao}
        />
      </ActionDialog>

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialogItem.isOpen}
        isDeleting={confirmDeleteDialogItem.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="AvisoFerias"
        title={titlePage}
        axiosConfig={{
          method: 'post',
          url: '/VinculoAvisoFerias/ObterRelatorioAvisoFeriasPorProgramacao',
          params: {
            feriasProgramacaoId: dataPDFViewer?.feriasProgramacaoId,
          },
        }}
      />
    </ActionDialog>
  )
}

export default Form
