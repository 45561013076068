import api from '~/services/api-pessoal'
import { RescisaoComplementarEventoDTO } from './RescisaoComplementarDTO'
export interface RescisaoComplementarDTO {
  funcionarioId: string
  acordoDissidioConvencaoId: string
  dtRecibo: Date | string
  dtReciboPagamento: Date | string
  eventos: RescisaoComplementarEventoDTO[]
}

export async function gerarReciboRescisaoComplementar(
  rescisaoComplementar: RescisaoComplementarDTO,
) {
  await api.post('/RescisaoComplementar/GerarReciboRescisaoComplementar', rescisaoComplementar)
}
