import React, { forwardRef } from 'react'

import { NavLink } from 'react-router-dom'

import { makeStyles, List, ListItem, Icon, ListItemText, Collapse } from '@material-ui/core'

import IconExpandMore from '@material-ui/icons/ExpandMore'

function verifyRouterLink(link) {
  const routerParamCurrent = window?.location?.hash?.toLowerCase().slice(1)
  return routerParamCurrent === link?.toLowerCase()
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: '0 auto',
    width: '94%',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter,
      color: theme.palette.primary.main,
      '&.makeStyles-listItemIcon-301': {
        color: theme.palette.primary.main,
      },
    },
    borderRadius: '4px',
    padding: `
      ${theme.spacing(1)}px
      ${theme.spacing(1)}px
      ${theme.spacing(1)}px
      ${theme.spacing(1)}px`,
  },
  listItemSelected: {
    '&.active': {
      background: theme.palette.primary.lighter,
      color: theme.palette.primary.main,
    },
  },
  listItemTextRoot: {
    margin: 0,
  },
  listItemText: {
    fontSize: '11pt',
    paddingLeft: theme.spacing(1),
  },
  iconCollapse: {
    transition: '0.5s',
    transform: (props) => (props.isOpenCollapse ? 'rotate(180deg)' : 'rotate(0deg)'),
  },
  rootListChilds: {
    backgroundColor: theme.palette.primary.paper,
  },
}))

export default function ListItemComponent(props) {
  const { item, level, id, handleClickItem, menusOpens, handleOpenMenus } = props

  const isOpenCollapse = menusOpens.includes(id)

  const classes = useStyles({ isOpenCollapse, level })

  function onClickItem() {
    if (handleClickItem) handleClickItem()
    if (item?.itens?.length > 0) {
      handleOpenMenus((oldState) => {
        if (!(oldState.length > 0)) return [id]

        const indexFinded = oldState.findIndex((oldMenuOpen) => oldMenuOpen === id)
        if (indexFinded !== -1) {
          const newArray = [...oldState]
          newArray.splice(indexFinded, 1)
          return newArray
        }
        const currentLevel = id.substring(0, 1)
        const newState = [id]

        oldState.forEach((oldMenuOpen) => {
          const oldMenuLevel = oldMenuOpen.substring(0, 1)
          if (oldMenuLevel !== currentLevel) newState.push(oldMenuOpen)
        })

        return newState
      })
    }
  }

  return (
    <>
      <ListItem
        button
        classes={{ root: classes.listItem, selected: classes.listItemSelected }}
        onClick={onClickItem}
        component={
          item?.link
            ? // eslint-disable-next-line react/display-name
              forwardRef((_props, ref) => <NavLink exact {..._props} innerRef={ref} />)
            : 'div'
        }
        to={item?.link || ''}
        selected={verifyRouterLink(item?.link)}
      >
        {item?.icon && <Icon>{item.icon}</Icon>}
        <ListItemText
          primary={item?.label || ''}
          classes={{
            primary: classes.listItemText,
            root: classes.listItemTextRoot,
          }}
        />
        {item?.itens?.length > 0 && item?.showCollapseIcon && (
          <IconExpandMore className={classes.iconCollapse} />
        )}
      </ListItem>

      {item?.itens?.length > 0 && (
        <Collapse timeout="auto" in={isOpenCollapse} mountOnEnter unmountOnExit>
          <List
            component="div"
            disablePadding
            classes={{
              root: classes.rootListChilds,
            }}
          >
            {item.itens.map((nextItem, index) => (
              <ListItemComponent
                id={`${nextItem?.level}|${index}`}
                item={nextItem}
                key={index}
                level={nextItem?.level}
                menusOpens={menusOpens}
                handleOpenMenus={handleOpenMenus}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}
