const segundaFeira_1 = 1
const terça_Feira_2 = 2
const quarta_Feira_3 = 3
const quinta_Feira_4 = 4
const sexta_Feira_5 = 5
const sábado_6 = 6
const domingo_7 = 7
const dia_Variavel_8 = 8

export const diaHorarioValues = [
  { value: segundaFeira_1, name: '1 - Segunda Feira' },
  { value: terça_Feira_2, name: '2 - Terça Feira' },
  { value: quarta_Feira_3, name: '3 - Quarta Feira' },
  { value: quinta_Feira_4, name: '4 - Quinta Feira' },
  { value: sexta_Feira_5, name: '5 - Sexta Feira' },
  { value: sábado_6, name: '6 - Sábado' },
  { value: domingo_7, name: '7 - Domingo' },
  { value: dia_Variavel_8, name: '8 - Dia variável' },
]

export const diaHorarioConsts = {
  segundaFeira_1,
  terça_Feira_2,
  quarta_Feira_3,
  quinta_Feira_4,
  sexta_Feira_5,
  sábado_6,
  domingo_7,
  dia_Variavel_8,
}
