import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterTodosSalarioItemInvalidate } from './useObterTodosSalarioItem'
import { useObterTodosSalarioInvalidate } from '../Salario/useObterTodosSalario'

import { notifyRemove } from '~/utils/notification'

export function useRemoveSalarioItem() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate: handleInvalidateItem } = useObterTodosSalarioItemInvalidate()
  const { handleInvalidate: handleInvalidateSalario } = useObterTodosSalarioInvalidate()

  async function handleRequest(id: string) {
    await api.delete('/SalarioItem/' + id)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      handleInvalidateItem()
      handleInvalidateSalario()
    },
    onError: dialogNotification.extractErrors,
  })
}
