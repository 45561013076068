const NAO_INFOMADO = 0
const DOSE_DIARIA_DE_RUIDO_1 = 1
const DECIBEL_LINEAR_2 = 2
const DECIBEL_C_3 = 3
const DECIBEL_A_4 = 4
const METRO_POR_SEGUNDO_AO_QUADRADO_5 = 5
const METRO_POR_SEGUNDO_ELEVADO_A_1_75_6 = 6
const PARTE_DE_VAPOR_OU_GAS_POR_MILHAO_DE_PARTES_DE_AR_CONTAMINADO_7 = 7
const MILIGRAMA_POR_METRO_CUBICO_DE_AR_8 = 8
const FIBRA_POR_CENTIMETRO_CUBICO_9 = 9
const GRAU_CELSIUS_10 = 10
const METRO_POR_SEGUNDO_11 = 11
const PORCENTUAL_12 = 12
const LUX_13 = 13
const UNIDADE_FORMADORA_DE_COLONIAS_POR_METRO_CUBICO_14 = 14
const DOSE_DIARIA_15 = 15
const DOSE_MENSAL_16 = 16
const DOSE_TRIMESTRAL_17 = 17
const DOSE_ANUAL_18 = 18
const WATT_POR_METRO_QUADRADO_19 = 19
const AMPERE_POR_METRO_20 = 20
const MILITESLA_21 = 21
const MICROTESLA_22 = 22
const MILIAMPERE_23 = 23
const QUILOVOLT_POR_METRO_24 = 24
const VOLT_POR_METRO_25 = 25
const JOULE_POR_METRO_QUADRADO_26 = 26
const MILIJOULE_POR_CENTIMETRO_QUADRADO_27 = 27
const MILISIEVERT_28 = 28
const MILHAO_DE_PARTICULAS_POR_DECIMETRO_CUBICO_29 = 29
const UMIDADE_RELATIVA_DO_AR_30 = 30

export const agenteNocivoUnidadeMedidaValues = [
  { value: NAO_INFOMADO, name: '0 - Não informar' },
  { value: DOSE_DIARIA_DE_RUIDO_1, name: '1 - dose diária de ruído' },
  { value: DECIBEL_LINEAR_2, name: '2 - decibel linear (dB (linear))' },
  { value: DECIBEL_C_3, name: '3 - decibel (C) (dB(C))' },
  { value: DECIBEL_A_4, name: '4 - decibel (A) (dB(A))' },
  {
    value: METRO_POR_SEGUNDO_AO_QUADRADO_5,
    name: '5 - metro por segundo ao quadrado (m/s²)',
  },
  {
    value: METRO_POR_SEGUNDO_ELEVADO_A_1_75_6,
    name: '6 - metro por segundo elevado a 1,75 (m/s^1,75)',
  },
  {
    value: PARTE_DE_VAPOR_OU_GAS_POR_MILHAO_DE_PARTES_DE_AR_CONTAMINADO_7,
    name: '7 - parte de vapor ou gás por milhão de partes de ar contaminado (ppm)',
  },
  {
    value: MILIGRAMA_POR_METRO_CUBICO_DE_AR_8,
    name: '8 - miligrama por metro cúbico de ar (mg/m³)',
  },
  {
    value: FIBRA_POR_CENTIMETRO_CUBICO_9,
    name: '9 - fibra por centímetro cúbico (f/cm³)',
  },
  { value: GRAU_CELSIUS_10, name: '10 - grau Celsius (ºC)' },
  { value: METRO_POR_SEGUNDO_11, name: '11 - metro por segundo (m/s)' },
  { value: PORCENTUAL_12, name: '12 - porcentual' },
  { value: LUX_13, name: '13 - lux (lx)' },
  {
    value: UNIDADE_FORMADORA_DE_COLONIAS_POR_METRO_CUBICO_14,
    name: '14 - unidade formadora de colônias por metro cúbico (ufc/m³)',
  },
  { value: DOSE_DIARIA_15, name: '15 - dose diária' },
  { value: DOSE_MENSAL_16, name: '16 - dose mensal' },
  { value: DOSE_TRIMESTRAL_17, name: '17 - dose trimestral' },
  { value: DOSE_ANUAL_18, name: '18 - dose anual' },
  {
    value: WATT_POR_METRO_QUADRADO_19,
    name: '19 - watt por metro quadrado (W/m²)',
  },
  { value: AMPERE_POR_METRO_20, name: '20 - ampère por metro (A/m)' },
  { value: MILITESLA_21, name: '21 - militesla (mT)' },
  { value: MICROTESLA_22, name: '22 - microtesla (μT)' },
  { value: MILIAMPERE_23, name: '23 - miliampère (mA)' },
  { value: QUILOVOLT_POR_METRO_24, name: '24 - quilovolt por metro (kV/m)' },
  { value: VOLT_POR_METRO_25, name: '25 - volt por metro (V/m)' },
  {
    value: JOULE_POR_METRO_QUADRADO_26,
    name: '26 - joule por metro quadrado (J/m²)',
  },
  {
    value: MILIJOULE_POR_CENTIMETRO_QUADRADO_27,
    name: '27 - milijoule por centímetro quadrado (mJ/cm²)',
  },
  { value: MILISIEVERT_28, name: '28 - milisievert (mSv)' },
  {
    value: MILHAO_DE_PARTICULAS_POR_DECIMETRO_CUBICO_29,
    name: '29 - milhão de partículas por decímetro cúbico (mppdc)',
  },
  {
    value: UMIDADE_RELATIVA_DO_AR_30,
    name: '30 - umidade relativa do ar (UR (%))',
  },
]
