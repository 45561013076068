import React from 'react'

import { makeStyles, Box } from '@material-ui/core'

const useStylesFormulaComponent = makeStyles((theme) => ({
  root: {
    fontSize: (props) => props?.fontSize || '',
    display: '-webkit-box',
    justifyContent: 'center',
  },
  itemFormulaHightLight: {
    cursor: 'pointer',
    padding: theme.spacing(0.5),
    fontWeight: 'bolder',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  itemFormula: {
    padding: theme.spacing(0.5),
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  itemSelected: {
    cursor: 'pointer',
    padding: theme.spacing(0.5),
    fontWeight: 'bolder',
    color: theme.palette.success.light,
  },
}))

export default function FormulaComponent(props) {
  const { formula, array, indexSelected, fontSize, onChange } = props

  const formulaSplited = formula.split(/(?=\[)(.*?\])/g)

  const testFormulaItem = new RegExp(/\[(.*?)\]/g)

  const classes = useStylesFormulaComponent({ fontSize })

  const getIndex = (item) => {
    const variavel = item.replace('[', '').replace(']', '')
    let index = null
    array.forEach((item, index_) => {
      if (item?.variavelAmbiente === variavel) index = index_
    })
    return index
  }

  return (
    <Box className={classes.root}>
      {formulaSplited.map((formulaItem, index) => {
        if (!testFormulaItem.test(formulaItem))
          return (
            <Box key={index} className={classes.itemFormula}>
              {formulaItem}
            </Box>
          )

        return (
          <Box
            key={index}
            onClick={() => {
              const indexCurrent = getIndex(formulaItem)
              onChange(indexCurrent)
            }}
            className={
              getIndex(formulaItem) === indexSelected
                ? classes.itemSelected
                : classes.itemFormulaHightLight
            }
          >
            {formulaItem}
          </Box>
        )
      })}
    </Box>
  )
}
