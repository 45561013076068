import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import { ContentDivider } from 'mio-library-ui'

import { CollapseContent } from '~/components'

import TabelaINSS from './TabelaINSS'
import TabelaIR from './TabelaIR'
import TabelaSF from './TabelaSF'

const ContainerTabelas = () => {
  const [collapseItens, setCollapseItens] = useState({
    tabelaINSS: true,
    tabelaIR: false,
    tabelaSF: false,
  })

  const toggleCollapse = (item) => {
    setCollapseItens({ [item]: !collapseItens[item] })
  }

  return (
    <Box height="100%" width="100%">
      <ContentDivider top={2} />

      <CollapseContent
        title="Tabela INSS"
        isOpen={collapseItens.tabelaINSS}
        onClick={() => toggleCollapse('tabelaINSS')}
        top={1}
      >
        <TabelaINSS />
      </CollapseContent>

      <CollapseContent
        title="Tabela IR"
        isOpen={collapseItens.tabelaIR}
        onClick={() => toggleCollapse('tabelaIR')}
        top={1}
      >
        <TabelaIR />
      </CollapseContent>

      <CollapseContent
        title="Tabela Salário Família"
        isOpen={collapseItens.tabelaSF}
        onClick={() => toggleCollapse('tabelaSF')}
        top={1}
      >
        <TabelaSF />
      </CollapseContent>
    </Box>
  )
}

export default ContainerTabelas
