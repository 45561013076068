import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Collapse, Grid, LinearProgress } from '@material-ui/core'
import {
  AnoMesTextField,
  Button,
  ButtonBox,
  Checkbox,
  DatePickerNew,
  NumeroInscricaoTextField,
  TextField,
} from '~/components'
import { AutoCompleteProcessoTrabalhista, MUIAutoComplete } from '~/components/AutoComplete'

import { useGetById } from '~/hooks/queries/ProcessoTrabalhistaVinculo/useGetById'
import { useUpdate } from '~/hooks/queries/ProcessoTrabalhistaVinculo/useUpdate'

import useValidationErrors from '~/hooks/useValidationErrors'

import {
  TipoContratoProcessoEnum,
  TipoContratoProcessoValues,
} from '~/@types/enums/TipoContratoProcessoEnum'
import {
  IndRepercussaoProcessoEnum,
  IndRepercussaoProcessoValues,
} from '~/@types/enums/IndRepercussaoProcessoEnum'
import { ProcessoTrabalhistaVinculo } from '~/hooks/queries/ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculo'
import { TipoInscricaoValues, TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const tiposInscricao = TipoInscricaoValues.filter(
  (obj) => obj.value === TipoInscricaoEnum.CNPJ_1 || obj.value === TipoInscricaoEnum.CPF_2,
)

const schema = yup.object().shape({
  processoTrabalhistaId: yup.string().required('Informe o Processo Trabalhista'),
  dtEmissao: yup.date().required('Informe a Data de Emissão').typeError('Informe uma data válida'),
  tipoContratoProcesso: yup
    .mixed()
    .oneOf(Object.values(TipoContratoProcessoEnum), 'Informe o Tipo de Contrato'),
  competenciaInicio: yup.string().required('Informe a Competência Início'),
  competenciaFim: yup.string().required('Informe a Competência Fim'),
  indRepercussaoProcesso: yup
    .mixed()
    .oneOf(Object.values(IndRepercussaoProcessoEnum), 'Informe a Repercussão do Processo'),

  tipoInscricaoResponsavel: yup
    .mixed()
    .when(['tipoContratoProcesso'], (tipoContratoProcesso: TipoContratoProcessoEnum, schema) => {
      if (tipoContratoProcesso === TipoContratoProcessoEnum.Responsabilidade_Indireta_8) {
        return schema.test(
          'validate',
          'Informe o Tipo Inscrição Responsável',
          (value: TipoInscricaoEnum) =>
            value === TipoInscricaoEnum.CPF_2 || value === TipoInscricaoEnum.CNPJ_1,
        )
      }
      return schema
    }),
  nrInscricaoResponsavel: yup
    .mixed()
    .when(['tipoContratoProcesso'], (tipoContratoProcesso: TipoContratoProcessoEnum, schema) => {
      if (tipoContratoProcesso === TipoContratoProcessoEnum.Responsabilidade_Indireta_8) {
        return yup.string().required('Informe o Número de Inscrição Responsável')
      }
      return schema
    }),
})

interface TabCadastroProps {
  processoTrabalhistaVinculoId: string
  onClose: () => void
}

export default function TabCadastro({ processoTrabalhistaVinculoId, onClose }: TabCadastroProps) {
  const [data, setData] = useState({} as ProcessoTrabalhistaVinculo)

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
  } = useGetById({ processoTrabalhistaVinculoId })
  const isLoading = _isLoading || _isFetching

  useEffect(() => {
    if (_data) {
      setData(_data)
    }
  }, [_data])

  const { mutateAsync, isLoading: isSubmitting } = useUpdate()

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  async function handleSubmit() {
    if (!data) return
    const { id, ...rest } = data
    await mutateAsync({ data: rest, id })
    onClose()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isLoading && <LinearProgress />}
      </Grid>

      <Grid item xs={12}>
        <Collapse in={data?.id ? true : false}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <AutoCompleteProcessoTrabalhista
                required
                validationErrors={validationErrors}
                name="processoTrabalhistaId"
                value={data.processoTrabalhistaId}
                onChange={(d) => {
                  const processoTrabalhistaId = d?.id || ''
                  setData((prev) => ({ ...prev, processoTrabalhistaId }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePickerNew
                label="Data de Emissão"
                required
                validationErrors={validationErrors}
                name="dtEmissao"
                value={data.dtEmissao || null}
                onChange={(value) => {
                  const dtEmissao = value || ''
                  setData((prevState) => ({ ...prevState, dtEmissao }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <MUIAutoComplete
                label="Tipo de Contrato"
                required
                validationErrors={validationErrors}
                name="tipoContratoProcesso"
                options={TipoContratoProcessoValues}
                renderOption={(option) => option.name}
                optionId="value"
                value={data.tipoContratoProcesso}
                onChange={(e, obj) => {
                  const tipoContratoProcesso = obj?.value || ''
                  setData((prev) => ({ ...prev, tipoContratoProcesso }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Checkbox
                label="Funcionário já cadastrado no eSocial"
                value={data.isCadastradoESocial}
                onChange={(_, checked) => {
                  const isCadastradoESocial = checked
                  setData((prev) => ({ ...prev, isCadastradoESocial }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Checkbox
                label="Indicativo de Reintegração"
                value={data.isIndReintegracao}
                onChange={(_, checked) => {
                  const isIndReintegracao = checked
                  setData((prev) => ({ ...prev, isIndReintegracao }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Checkbox
                label="Houve alteração de Categoria"
                value={data.isIndCategoria}
                onChange={(_, checked) => {
                  const isIndCategoria = checked
                  setData((prev) => ({ ...prev, isIndCategoria }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Checkbox
                label="Houve alteração de Natureza de Atividade"
                value={data.isIndNaturezaAtividade}
                onChange={(_, checked) => {
                  const isIndNaturezaAtividade = checked
                  setData((prev) => ({ ...prev, isIndNaturezaAtividade }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Checkbox
                label="Houve alteração no Motivo de Desligamento"
                value={data.isIndMotivoDesligamento}
                onChange={(_, checked) => {
                  const isIndMotivoDesligamento = checked
                  setData((prev) => ({ ...prev, isIndMotivoDesligamento }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <DatePickerNew
                label="Data de Admissão Original"
                size="small"
                value={data.dtAdmissaoOriginal || null}
                onChange={(value) => {
                  const dtAdmissaoOriginal = value || ''
                  setData((prevState) => ({ ...prevState, dtAdmissaoOriginal }))
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MUIAutoComplete
                label="Tipo Inscrição Responsável"
                options={tiposInscricao}
                optionId="value"
                disabled={
                  data.tipoContratoProcesso !== TipoContratoProcessoEnum.Responsabilidade_Indireta_8
                }
                renderOption={(option) => option.name}
                required={
                  data.tipoContratoProcesso === TipoContratoProcessoEnum.Responsabilidade_Indireta_8
                }
                name="tipoInscricaoResponsavel"
                validationErrors={validationErrors}
                value={data.tipoInscricaoResponsavel}
                onChange={(e, obj) => {
                  const tipoInscricaoResponsavel = obj?.value
                  setData((oldState) => ({
                    ...oldState,
                    tipoInscricaoResponsavel,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <NumeroInscricaoTextField
                label="Número de Inscrição Responsável"
                value={data.nrInscricaoResponsavel || ''}
                typeMask={data.tipoInscricaoResponsavel}
                disabled={
                  data.tipoContratoProcesso !== TipoContratoProcessoEnum.Responsabilidade_Indireta_8
                }
                required={
                  data.tipoContratoProcesso === TipoContratoProcessoEnum.Responsabilidade_Indireta_8
                }
                name="nrInscricaoResponsavel"
                validationErrors={validationErrors}
                onChange={(e, value) => {
                  const nrInscricaoResponsavel = value
                  setData((prev) => ({ ...prev, nrInscricaoResponsavel }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <MUIAutoComplete
                label="Repercussão do Processo"
                required
                validationErrors={validationErrors}
                name="indRepercussaoProcesso"
                options={IndRepercussaoProcessoValues}
                renderOption={(option) => option.name}
                optionId="value"
                value={data.indRepercussaoProcesso}
                onChange={(e, obj) => {
                  const indRepercussaoProcesso = obj?.value || ''
                  setData((prev) => ({ ...prev, indRepercussaoProcesso }))
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Checkbox
                label="Houve decisão para pagamento da indenização substitutiva do seguro-desemprego?"
                value={data.isIndenizacaoSeguroDesemprego}
                onChange={(_, checked) => {
                  const isIndenizacaoSeguroDesemprego = checked
                  setData((prev) => ({ ...prev, isIndenizacaoSeguroDesemprego }))
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Checkbox
                label="Houve decisão para pagamento da indenização substitutiva de abono salarial?"
                value={data.isIndenizacaoAbonoSalarial}
                onChange={(_, checked) => {
                  const isIndenizacaoAbonoSalarial = checked
                  setData((prev) => ({ ...prev, isIndenizacaoAbonoSalarial }))
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <AnoMesTextField
                label="Competência Início"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                validationErrors={validationErrors}
                name="competenciaInicio"
                value={data.competenciaInicio || null}
                onChange={(e) => {
                  const competencia = e.target.value.replace('/', '')
                  setData((prev) => ({ ...prev, competenciaInicio: competencia }))
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <AnoMesTextField
                label="Competência Fim"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                validationErrors={validationErrors}
                name="competenciaFim"
                value={data.competenciaFim || null}
                onChange={(e) => {
                  const competencia = e.target.value.replace('/', '')
                  setData((prev) => ({ ...prev, competenciaFim: competencia }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField label="Recibo eSocial" value={data.reciboEsocial} disabled />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePickerNew label="Data Recibo eSocial" value={data.dtReciboEsocial} disabled />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>

      <Grid item xs={12}>
        <ButtonBox>
          <Button onClick={() => onClose()}>Cancelar</Button>
          <Button variant="contained" onClick={handleValidate} isLoading={isSubmitting}>
            Salvar
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
