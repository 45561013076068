const naoInformado_0 = 0
const contribuinteIndividual_1 = 1
const produtorRural_2 = 2
const seguradoEspecial_3 = 3

export const tipoCAEPFValues = [
  { value: naoInformado_0, name: '0 - Não informar' },
  { value: contribuinteIndividual_1, name: '1 - Contribuinte Individual' },
  { value: produtorRural_2, name: '2 - Produtor Rural' },
  { value: seguradoEspecial_3, name: '3 - Segurado Especial' },
]

export const tipoCAEPFConsts = {
  naoInformado_0,
  contribuinteIndividual_1,
  produtorRural_2,
  seguradoEspecial_3,
}
