import { ToolsDataTable } from '~/components'

import { formatCurrency } from '~/hooks/useUtils'

import { RPItemConsulta } from '~/hooks/queries/RP/RPItemConsulta'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableEventosImportadosProps {
  data: RPItemConsulta[]
}

export default function TableEventosImportados({ data }: TableEventosImportadosProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'eventoCodigo',
      label: 'Código',
    },
    {
      name: 'eventoNome',
      label: 'Nome',
    },
    {
      name: 'quantidade',
      label: 'Quantidade',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrVencimento',
      label: 'Vencimento',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrDesconto',
      label: 'Desconto',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
  ]

  return <ToolsDataTable columns={columns} data={data} disableAutoHeight pagination={false} />
}
