import React from 'react'

import PropType from 'prop-types'

import { ErrorMessage } from 'mio-library-ui'
import { MenuItem, TextField } from '@material-ui/core'

import { obterErroValidationErrors } from '~/hooks/useUtils'

export default function SimpleSelect(props) {
  const { options, optionId, renderOption, onChange, validationErrors, name, ...rest } = props

  const mensagemDeErro = obterErroValidationErrors(name, validationErrors)

  function handleChange(e) {
    onChange(e, e.target.value)
  }

  return (
    <>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        color="primary"
        select
        onChange={handleChange}
        {...rest}
      >
        {options.map((opt) => (
          <MenuItem value={opt[optionId]} key={opt[optionId]}>
            {renderOption(opt)}
          </MenuItem>
        ))}
      </TextField>
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </>
  )
}

SimpleSelect.propTypes = {
  value: PropType.any,
  optionId: PropType.string,
  label: PropType.string,
  name: PropType.string,
  disabled: PropType.bool,
  required: PropType.bool,
  options: PropType.array,
  renderOption: PropType.func,
  onChange: PropType.func,
  validationErrors: PropType.any,
}
