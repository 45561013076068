import { useState } from 'react'

import { Container, Paper } from '@material-ui/core'

import { ButtonBox, ButtonIcon, PageHeader, PDFViewer, Stack } from '~/components'
import { AutoCompleteVinculo } from '~/components/AutoComplete'

import DialogConfirmAuditoriaZerada from './DialogConfirmAuditoriaZerada'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'
import useDialog from '~/hooks/useDialog'

const PAGE_TITLE = 'Processo Trabalhista Rescisão Zerada'

const schema = yup.object().shape({
  vinculoId: yup.string().required('Informe o Vínculo'),
})

export default function ProcessoTrabalhistaRescisaoZerada() {
  const [data, setData] = useState({
    vinculoId: '',
  })

  const { estabelecimento, anoMes } = useAmbiente()

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog('')
  const {
    close: closeDialogConfirmAuditoriaZerada,
    data: dataDialogConfirmAuditoriaZerada,
    isOpen: isOpenDialogConfirmAuditoriaZerada,
    open: openDialogConfirmAuditoriaZerada,
  } = useDialog('')

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: () => openDialogConfirmAuditoriaZerada(data.vinculoId),
    data,
  })

  return (
    <Stack padding={2}>
      <PageHeader title={PAGE_TITLE} />
      <Container maxWidth="sm">
        <Stack component={Paper} p={2}>
          <AutoCompleteVinculo
            value={data.vinculoId}
            estabelecimentoId={estabelecimento?.id}
            anoMes={anoMes}
            required
            name="vinculoId"
            validationErrors={validationErrors}
            onChange={(e, vinculo) => {
              const vinculoId = vinculo?.id || ''
              setData((oldState) => ({
                ...oldState,
                vinculoId,
              }))
            }}
          />

          <ButtonBox>
            <ButtonIcon variant="contained" icon="print" onClick={handleValidate}>
              Imprimir
            </ButtonIcon>
          </ButtonBox>
        </Stack>
      </Container>

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="TRCT"
          title={PAGE_TITLE}
          axiosConfig={{
            method: 'post',
            url: '/RelRescisao/ObterRelatorioRescisaoZerada',
            params: {
              vinculoId: dataPDFViewer,
            },
          }}
        />
      )}

      {isOpenDialogConfirmAuditoriaZerada && (
        <DialogConfirmAuditoriaZerada
          vinculoId={dataDialogConfirmAuditoriaZerada}
          isOpen={isOpenDialogConfirmAuditoriaZerada}
          onClose={closeDialogConfirmAuditoriaZerada}
          afterSubmit={openPDFViewer}
        />
      )}
    </Stack>
  )
}
