import { useState } from 'react'

import { Tab, Tabs } from '@material-ui/core'
import { ActionDialog, TabPanel } from '~/components'

import { TabProcessoTrabalhista } from '../FormCreate'

import TabCadastro from './Tabs/TabCadastro'
import TabPeriodoValores from './Tabs/TabPeriodoValores'
import TabRemuneracoes from './Tabs/TabRemuneracoes'

interface FormUpdateProps {
  isOpen: boolean
  onClose: () => void
  processoTrabalhistaVinculoId: string
}

export default function FormUpdate({
  isOpen,
  onClose,
  processoTrabalhistaVinculoId,
}: FormUpdateProps) {
  const [tab, setTab] = useState<TabProcessoTrabalhista>(TabProcessoTrabalhista.Cadastro)

  return (
    <ActionDialog
      title="Cadastro de Processo Trabalhista Funcionário"
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
      renderRight={
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => setTab(value)}
        >
          <Tab label="Cadastro" />
          <Tab label="Períodos e Valores" />
          <Tab label="Remunerações" />
        </Tabs>
      }
      customActions={<></>}
    >
      <TabPanel value={tab} index={TabProcessoTrabalhista.Cadastro} style={{ height: '100%' }}>
        <TabCadastro
          processoTrabalhistaVinculoId={processoTrabalhistaVinculoId}
          onClose={onClose}
        />
      </TabPanel>
      <TabPanel
        value={tab}
        index={TabProcessoTrabalhista.PeirodoValores}
        style={{ height: '100%' }}
      >
        <TabPeriodoValores
          processoTrabalhistaVinculoId={processoTrabalhistaVinculoId}
          onClose={onClose}
        />
      </TabPanel>
      <TabPanel value={tab} index={TabProcessoTrabalhista.Remuneracoes} style={{ height: '100%' }}>
        <TabRemuneracoes
          processoTrabalhistaVinculoId={processoTrabalhistaVinculoId}
          onClose={onClose}
        />
      </TabPanel>
    </ActionDialog>
  )
}
