import { useState } from 'react'

import { Box, Collapse, IconButton, Paper, useTheme } from '@material-ui/core'
import { ChevronLeft, ExpandMore } from '@material-ui/icons'

import { Button, DragDropFile } from '~/components'

import Header from './Header'
import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import useExtrairPlanilha from '~/hooks/queries/ImportacaoPlanilhaFaltas/useExtrairPlanilha'
import useImportarPlanilha from '~/hooks/queries/ImportacaoPlanilhaFaltas/useImportarPlanilha'

import { ExtrairPlanilhaResponseDTO } from '~/hooks/queries/ImportacaoPlanilhaFaltas/dtos/ExtrairPlanilhaResponseDTO'

export default function ImportarFaltas() {
  const [query, setQuery] = useState('')
  const [data, setData] = useState<{
    data: ExtrairPlanilhaResponseDTO
    file: FormData
  } | null>(null)
  const [isCollapseOpen, setCollapseOpen] = useState(true)

  const theme = useTheme()
  const { anoMes, estabelecimento } = useAmbiente()
  const dialogNotification = useDialogNotification()

  const { mutateAsync, isLoading } = useExtrairPlanilha()
  const { mutateAsync: mutateAsyncImportarPlanilha, isLoading: isSubmitting } =
    useImportarPlanilha()

  async function onDropped(files: File[]) {
    const formData = new FormData()
    formData.append('file', files[0])
    const response = await mutateAsync(formData)
    setCollapseOpen(false)
    setData({
      data: response,
      file: formData,
    })
  }

  function handleCancel() {
    setData(null)
    setCollapseOpen(true)
  }

  async function handleImportarFaltas() {
    if (!data?.file) return
    const problemas = await mutateAsyncImportarPlanilha({
      data: data.file,
      params: {
        anoMes,
        estabelecimentoId: estabelecimento.id,
      },
    })
    if (problemas.length > 0) {
      dialogNotification.warning({
        title: 'Ocorreu alguns problemas na importação das Faltas',
        descriptions: problemas,
      })
    }
    handleCancel()
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <Box display="flex" gridGap={theme.spacing(1)} component={Paper} padding={1}>
        <Collapse in={isCollapseOpen} style={{ width: '100%' }}>
          <Box>
            <DragDropFile onDropped={onDropped} acceptFiles={['.xlsx']} isLoading={isLoading} />
          </Box>
        </Collapse>
        <Box>
          <IconButton color="primary" size="small" onClick={() => setCollapseOpen((prev) => !prev)}>
            {isCollapseOpen ? <ChevronLeft fontSize="small" /> : <ExpandMore fontSize="small" />}
          </IconButton>
        </Box>
      </Box>

      <Header data={data?.data} setQuery={setQuery} />

      <Box flex={1} position="relative" overflow="auto" minHeight={300}>
        <Box position="absolute" width="100%">
          <Table data={data?.data.funcionarios || []} query={query} />
        </Box>
      </Box>

      <Box
        component={Paper}
        p={1}
        display="flex"
        gridGap={theme.spacing(1)}
        justifyContent="flex-end"
      >
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button
          disabled={data ? false : true}
          variant="contained"
          isLoading={isSubmitting}
          onClick={handleImportarFaltas}
        >
          Importar Faltas
        </Button>
      </Box>
    </Box>
  )
}
