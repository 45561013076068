import { makeStyles } from '@material-ui/core'
import { DataTable } from 'mio-library-ui'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
  grfc: { color: '#e57373' },
}))

const Table = (props) => {
  const { data: _data, isLoading, query } = props

  const { formatCurrency } = useUtils()

  const data = _data.map((item) => {
    return {
      ...item,
      vrBaseINSS: item?.vrBaseINSS ? formatCurrency(item.vrBaseINSS) : null,
      vrINSS: item?.vrINSS ? formatCurrency(item.vrINSS) : null,
      vrBaseFGTS: item?.vrBaseFGTS ? formatCurrency(item.vrBaseFGTS) : null,
      vrFGTS: item?.vrFGTS ? formatCurrency(item.vrFGTS) : null,
      vrBaseINSS13: item?.vrBaseINSS13 ? formatCurrency(item.vrBaseINSS13) : null,
      vrINSS13: item?.vrINSS13 ? formatCurrency(item.vrINSS13) : null,
      vrBaseFGTSAvisoPrevio: item?.vrBaseFGTSAvisoPrevio
        ? formatCurrency(item.vrBaseFGTSAvisoPrevio)
        : null,
      vrFGTSAvisoPrevio: item?.vrFGTSAvisoPrevio ? formatCurrency(item.vrFGTSAvisoPrevio) : null,
      vrBaseFGTS13: item?.vrBaseFGTS13 ? formatCurrency(item.vrBaseFGTS13) : null,
      vrFGTS13: item?.vrFGTS13 ? formatCurrency(item.vrFGTS13) : null,
      dtAdmissao: item?.dtAdmissao ? moment(item.dtAdmissao).format('DD/MM/YYYY') : null,
      dtRescisao: item?.dtRescisao ? moment(item.dtRescisao).format('DD/MM/YYYY') : null,
      dtRescisaoComplementar: item?.dtRescisaoComplementar
        ? moment(item.dtRescisaoComplementar).format('DD/MM/YYYY')
        : null,
    }
  })

  const classes = useStyles()

  const columns = [
    {
      name: 'grfc',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'nome',
      label: 'Funcionário',
      options: {
        customBodyRender: (value, tableMeta) => {
          const grfc = tableMeta.rowData[0]
          return (
            <div title={grfc ? 'Funcionário com GRFC' : ''} className={grfc ? classes.grfc : ''}>
              {value}
            </div>
          )
        },
      },
    },
    {
      name: 'dtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'dtRescisao',
      label: 'Rescisão',
    },
    {
      name: 'vinculoId',
      label: 'Lotação',
      options: {
        customBodyRender: (value) => {
          const dtCurrent = _data.find((d) => d.vinculoId === value)
          return dtCurrent.lotacaoCodigo + ' - ' + dtCurrent.lotacaoNome
        },
      },
    },
    {
      name: 'vrBaseINSS',
      label: 'Base INSS',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrINSS',
      label: 'Valor INSS',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrBaseINSS13',
      label: 'Base INSS 13°',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrINSS13',
      label: 'Valor INSS 13°',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },

    {
      name: 'vrBaseFGTSAvisoPrevio',
      label: 'Base FGTS Aviso',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrFGTSAvisoPrevio',
      label: 'Valor FGTS Aviso',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },

    {
      name: 'vrBaseFGTS',
      label: 'Base FGTS',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrFGTS',
      label: 'Valor FGTS',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrBaseFGTS13',
      label: 'Base FGTS 13°',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrFGTS13',
      label: 'Valor FGTS 13°',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'pispasep',
      label: 'PIS/PASEP',
    },
    {
      name: 'categoriaFGTS',
      label: 'Categoria',
    },
    {
      name: 'ocorrenciaFGTS',
      label: 'Ocorrência',
    },
    {
      name: 'rescisaoFGTS',
      label: 'Rescisão FGTS',
    },
    {
      name: 'dtRescisaoComplementar',
      label: 'Data Rescisão Complementar',
    },
  ]

  return (
    <DataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['nome', 'lotacaoNome', 'lotacaoCodigo'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
