import React, { useEffect, useState, useCallback } from 'react'

import api from '~/services/api-pessoal'

import { Box } from '@material-ui/core'
import { Finder } from 'mio-library-ui'

import useDialogNotification from '~/hooks/useDialogNotification'
import useDialog from '~/hooks/useDialog'

import { ActionDialog, PageHeader } from '~/components'

import Form from './components/Form'
import Table from './components/Table'

export default function RelatorioConfiguracao() {
  const [isLoading, setLoading] = useState(false)
  const [collection, setCollection] = useState([])
  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const { open, close, data, isOpen } = useDialog()

  const getCollection = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get('/Relatorio')
      setCollection(response?.data?.data || [])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  function handleClickItem(id) {
    const itemFinded = collection.find((c) => c.id === id)
    open(itemFinded)
  }

  function onAfterSubmit() {
    close()
    getCollection()
  }

  return (
    <Box height="100%" p={2}>
      <PageHeader title="Relatórios">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Table data={collection} isLoading={isLoading} onItemClick={handleClickItem} query={query} />
      <ActionDialog
        title="Atualizar XML"
        subtitle={data?.nome ? `${data.chaveId} - ${data.nome}` : '-'}
        isOpen={isOpen}
        onClose={close}
        onCancelClick={close}
        customActions={<></>}
      >
        <Form data={data} onAfterSubmit={onAfterSubmit} />
      </ActionDialog>
    </Box>
  )
}
