export enum IndBaseDSREnum {
  NaoIncide = 0,

  SalarioHora = 1,

  HoraExtra50 = 2,
  HoraExtra55 = 3,
  HoraExtra60 = 4,
  HoraExtra65 = 5,
  HoraExtra70 = 6,
  HoraExtra75 = 7,
  HoraExtra80 = 8,
  HoraExtra100 = 9,
  HoraExtra = 10,

  HoraExtraNoturna50 = 20,
  HoraExtraNoturna55 = 21,
  HoraExtraNoturna60 = 22,
  HoraExtraNoturna65 = 23,
  HoraExtraNoturna70 = 24,
  HoraExtraNoturna75 = 25,
  HoraExtraNoturna80 = 26,
  HoraExtraNoturna100 = 27,

  Comissao = 40,
  HoraSobreAviso = 45,
  AdicionalNoturno = 50,

  HoraExtraIntermitente50 = 80,
}

export const IndBaseDSRValues = [
  { value: IndBaseDSREnum.NaoIncide, name: '0 - Não Incide' },
  { value: IndBaseDSREnum.SalarioHora, name: '1 - Salário Hora' },
  { value: IndBaseDSREnum.HoraExtra50, name: '2 - Hora Extra 50%' },
  { value: IndBaseDSREnum.HoraExtra55, name: '3 - Hora Extra 55%' },
  { value: IndBaseDSREnum.HoraExtra60, name: '4 - Hora Extra 60%' },
  { value: IndBaseDSREnum.HoraExtra65, name: '5 - Hora Extra 65%' },
  { value: IndBaseDSREnum.HoraExtra70, name: '6 - Hora Extra 70%' },
  { value: IndBaseDSREnum.HoraExtra75, name: '7 - Hora Extra 75%' },
  { value: IndBaseDSREnum.HoraExtra80, name: '8 - Hora Extra 80%' },
  { value: IndBaseDSREnum.HoraExtra100, name: '9 - Hora Extra 100%' },
  { value: IndBaseDSREnum.HoraExtra, name: '10 - Hora Extra' },
  { value: IndBaseDSREnum.HoraExtraNoturna50, name: '20 - Hora Extra Nortuna 50%' },
  { value: IndBaseDSREnum.HoraExtraNoturna55, name: '21 - Hora Extra Nortuna 55%' },
  { value: IndBaseDSREnum.HoraExtraNoturna60, name: '22 - Hora Extra Nortuna 60%' },
  { value: IndBaseDSREnum.HoraExtraNoturna65, name: '23 - Hora Extra Nortuna 65%' },
  { value: IndBaseDSREnum.HoraExtraNoturna70, name: '24 - Hora Extra Nortuna 70%' },
  { value: IndBaseDSREnum.HoraExtraNoturna75, name: '25 - Hora Extra Nortuna 75%' },
  { value: IndBaseDSREnum.HoraExtraNoturna80, name: '26 - Hora Extra Nortuna 80%' },
  { value: IndBaseDSREnum.HoraExtraNoturna100, name: '27 - Hora Extra Nortuna 100%' },
  { value: IndBaseDSREnum.Comissao, name: '40 - Comissão' },
  { value: IndBaseDSREnum.HoraSobreAviso, name: '45 - Hora Sobre Aviso' },
  { value: IndBaseDSREnum.AdicionalNoturno, name: '50 - Adicional Noturno em Horas' },
  { value: IndBaseDSREnum.HoraExtraIntermitente50, name: '80 - Hora Extra Intermitente' },
]
