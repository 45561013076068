import { useQuery, useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'

export function useVinculoGetByVinculo(vinculoId) {
  const dialogNotification = useDialogNotification()
  async function getVinculoById(vinculoId) {
    if (!vinculoId) return
    const response = await api.get('/VinculoEstagio/GetByVinculo', {
      params: {
        vinculoId,
      },
    })
    return response.data.data
  }
  return useQuery('VinculoEstagio', () => getVinculoById(vinculoId), {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useVinculoEstagioMutation() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function update(data) {
    const response = await api.put(`/VinculoEstagio/${data.id}`, data)
    notification.put()
    return response.data.data
  }
  async function insert(data) {
    const response = await api.post('/VinculoEstagio', data)
    notification.post()
    return response.data.data
  }

  return useMutation(
    async (data) => {
      if (data?.id) {
        return update(data)
      }
      return insert(data)
    },
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}

// export function useVinculoEstagioDelete() {
//   const queryClient = useQueryClient()
//   const dialogNotification = useDialogNotification()
//   const notification = useNotification()

//   async function handleDelete(id) {
//     await api.delete(`/VinculoEstagio/${id}`)
//     notification.remove()
//     return id
//   }

//   return useMutation((id) => handleDelete(id), {
//     onSuccess: async (id) => {
//       await queryClient.cancelQueries('VinculoEstagio')
//       queryClient.setQueriesData('VinculoEstagio', (old) => {
//         const newData = old.filter((d) => d.id !== id)
//         return newData
//       })
//     },
//     onError: (err) => dialogNotification.extractErrors(err),
//   })
// }
