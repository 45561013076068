import { useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'

import api from '~/services/api-pessoal'

export function useSubmit() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function insertValoresPorEvento(data) {
    const response = await api.post('/Relatorio/EventosPorValor/ObterEventoPorValor', data)
    notification.post()
    return response?.data?.data
  }

  return useMutation(
    async (data) => {
      return insertValoresPorEvento(data)
    },
    {
      onSuccess: async (data) => {
        await queryClient.cancelQueries('ValoresPorEvento')
        queryClient.setQueriesData('ValoresPorEvento', (oldData) => {
          if (!(oldData?.length > 0)) return [data]

          let finded = false
          const newData = oldData.map((obj) => {
            if (obj.id === data.id) {
              finded = true
              return data
            }
            return obj
          })
          if (finded) return newData
          return [data, ...oldData]
        })
      },
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}
