import React, { useMemo } from 'react'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { IconButton, makeStyles } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { HeightDataTable } from '~/components'
import { useQuery } from '~/hooks/queries/useInformacaoComplementarEmpregador'
import { IndSubsPatronalValues } from '~/@types/enums/IndSubsPatronalEnum'
import useUtils from '~/hooks/useUtils'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const Table = (props) => {
  const { empregadorId, onItemClick } = props
  const { data: _data, isLoading } = useQuery(empregadorId)
  const { formatCurrency, formatAnoMes } = useUtils()
  const classes = useStyles()

  const formatIndSubstituicaoPatronal = (indSubstituicaoPatronal) => {
    const objectFinded = IndSubsPatronalValues.find((obj) => obj.value === indSubstituicaoPatronal)
    return objectFinded.name
  }

  const data = _data?.map((item) => {
    return {
      ...item,
      anoMes: item?.anoMes ? formatAnoMes(item.anoMes) : null,
      indSubstituicaoPatronal: formatIndSubstituicaoPatronal(item?.indSubstituicaoPatronal),
      percentualReducaoContribuicao: item?.percentualReducaoContribuicao
        ? formatCurrency(item.percentualReducaoContribuicao)
        : null,
      fatorMes: item?.fatorMes ? formatCurrency(item.fatorMes) : null,
      fatorDecimoTerceiro: item?.fatorDecimoTerceiro
        ? formatCurrency(item.fatorDecimoTerceiro)
        : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'anoMes',
        label: 'Ano/Mês',
      },
      {
        name: 'indSubstituicaoPatronal',
        label: 'Substituição Patronal',
      },
      {
        name: 'percentualReducaoContribuicao',
        label: 'Percentual de Redução Contribuição',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'fatorMes',
        label: 'Fator Mês',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'fatorDecimoTerceiro',
        label: 'Fator 13°',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    const dataFinded = _data?.find((d) => d.id === value)
                    onItemClick('edit', dataFinded)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => onItemClick('delete', value)}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [classes, _data, onItemClick],
  )

  return <HeightDataTable title="" data={data} columns={columns} isLoading={isLoading} />
}

export default Table
