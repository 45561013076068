import React, { useState } from 'react'

import { ContentContainer, PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import { useCargoDelete } from '~/hooks/queries/useCargo'

const Cargo = () => {
  const [query, setQuery] = useState('')

  const { close: closeForm, open: openForm, isOpen: isOpenForm, data: dataForm } = useDialog()
  const {
    close: closeConfirmDelete,
    open: openConfirmDelete,
    isOpen: isOpenConfirmDelete,
    data: dataConfirmDelete,
  } = useDialog()
  const { empregador } = useAmbiente()
  const { mutateAsync, isLoading } = useCargoDelete()

  const handleClickItem = (event, value) => {
    const functions = {
      edit: openForm,
      delete: openConfirmDelete,
    }
    functions[event](value)
  }

  const handleDeleteItem = async () => {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  return (
    <ContentContainer>
      <PageHeader title="Cargo">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button
            size="small"
            color="primary"
            onClick={() => openForm({ empregadorId: empregador.id })}
            variant="contained"
          >
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>
      <Table empregadorId={empregador?.id} query={query} onItemClick={handleClickItem} />

      <Form isOpen={isOpenForm} data={dataForm} onClose={closeForm} />

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isLoading}
        onCancel={closeConfirmDelete}
        onConfirm={handleDeleteItem}
      />
    </ContentContainer>
  )
}

export default Cargo
