import React from 'react'

import { TextField } from 'mio-library-ui'
import { Grid } from '@material-ui/core'

export default function InputDiaHorario(props) {
  const { label, value, onChange, validationErrors, name, required } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          label={label + ' Horário'}
          value={value?.horario || ''}
          onChange={(e) => {
            const horario = e?.target?.value || ''
            const newValue = {
              ...value,
              horario,
            }
            onChange(newValue)
          }}
          inputProps={{ maxLength: 100 }}
          required={required}
          validationErrors={validationErrors}
          name={name + '.horario'}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          label={label + ' Intervalo'}
          value={value?.intervalo || ''}
          onChange={(e) => {
            const intervalo = e?.target?.value || ''
            const newValue = {
              ...value,
              intervalo,
            }
            onChange(newValue)
          }}
          inputProps={{ maxLength: 100 }}
          required={required}
          validationErrors={validationErrors}
          name={name + '.intervalo'}
        />
      </Grid>
    </Grid>
  )
}
