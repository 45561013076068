import { formatToCNPJ } from 'brazilian-values'
import { formatSimpleDateTime } from '~/utils/utils'

import { Badge, Box, IconButton, useTheme } from '@material-ui/core'
import { Warning } from '@material-ui/icons'

import { CopyLabel, Stack, ToolsDataTable } from '~/components'

import IconTask from '../../Processamento/Table/IconTask'
import DialogProblemas from '../../Processamento/Table/TableTasks/DialogProblemas'

import useDialog from '~/hooks/useDialog'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { FolhaAutomaticaDataEmpDTO } from '~/hooks/queries/FolhaAutomatica/dtos/FolhaAutomaticaDataEmpDTO'

interface TableProps {
  data: FolhaAutomaticaDataEmpDTO[]
  query: string
  isLoading: boolean
  isFetching: boolean
  tableRef: FixLater
}

export default function Table({ data, query, isLoading, isFetching, tableRef }: TableProps) {
  const theme = useTheme()
  const {
    close: closeDialogProblemas,
    data: dataDialogProblemas,
    isOpen: isOpenDialogProblemas,
    open: openDialogProblemas,
  } = useDialog<string[]>([])

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Código',
      name: 'empregadorCodigo',
      options: {
        setCellProps: () => ({
          style: { width: '1px' },
        }),
      },
    },
    {
      label: 'Empregador',
      name: 'empregadorNome',
      options: {
        setCellProps: () => ({
          style: { whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      label: 'CNPJ',
      name: 'empregadorNrInscricao',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCNPJ(value)}</CopyLabel>,
        setCellProps: () => ({
          style: { whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'iconeTarefa',
      label: 'Indicador',
      options: {
        sort: false,
        customBodyRender: (_, { rowIndex }) => {
          const dtCurrent = data.at(rowIndex)
          if (!dtCurrent) return <div>Problema com a tarefa</div>
          return (
            <Stack alignItems="center">
              <IconTask
                status={dtCurrent?.status}
                icone={dtCurrent.iconeTarefa}
                descricao={dtCurrent.descricaoTarefa}
              />
            </Stack>
          )
        },
        setCellProps: () => ({
          style: { width: '1px' },
        }),
      },
    },
    {
      name: 'descricaoStatus',
      label: 'Status',
      options: {
        customBodyRender: (value) => <Stack px={1}>{value}</Stack>,
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'descricaoTarefa',
      label: 'Tarefa',
      options: {
        setCellProps: () => ({
          style: { whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'createdAt',
      label: 'Criação',
      options: {
        customBodyRender: (value) => <Stack px={1}>{formatSimpleDateTime(value)}</Stack>,
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'dtAgendamento',
      label: 'Agendamento',
      options: {
        customBodyRender: (value) => <Stack px={1}>{formatSimpleDateTime(value)}</Stack>,
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'updatedAt',
      label: 'Atualização',
      options: {
        customBodyRender: (value) => <Stack px={1}>{formatSimpleDateTime(value)}</Stack>,
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'errors',
      label: 'Problemas',
      options: {
        customBodyRender: (value) => (
          <Stack alignItems="center">
            <Badge badgeContent={value.length} color="error">
              <IconButton
                size="small"
                onClick={() => openDialogProblemas(value)}
                disabled={value.length > 0 ? false : true}
              >
                <Warning
                  fontSize="small"
                  style={{ color: value.length > 0 ? theme.palette.warning.main : undefined }}
                />
              </IconButton>
            </Badge>
          </Stack>
        ),
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
  ]

  return (
    <Box>
      <ToolsDataTable
        tableRef={tableRef}
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        disableAutoHeight
        optionsSelectable={{
          type: 'multiple',
        }}
        sherlock={{
          query,
          columns: ['empregadorCodigo', 'empregadorNome', 'empregadorNrInscricao'],
        }}
      />
      {isOpenDialogProblemas && (
        <DialogProblemas
          isOpen={isOpenDialogProblemas}
          onClose={closeDialogProblemas}
          data={dataDialogProblemas}
        />
      )}
    </Box>
  )
}
