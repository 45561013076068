import moment from 'moment'

import { ContainerTable, Stack, ToolsDataTable } from '~/components'

import { useVinculoLocalGetByVinculo } from '~/hooks/queries/VinculoLocal/useVinculoLocalGetByVinculo'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface TabAmbienteTrabalhoProps {
  vinculoId: string
}

export default function TabAmbienteTrabalho({ vinculoId }: TabAmbienteTrabalhoProps) {
  const { data: _d, isLoading, isFetching } = useVinculoLocalGetByVinculo(vinculoId)
  const data = _d || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtLocal',
      label: 'Data',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'departamento',
      label: 'Departamento',
      options: {
        sort: false,
        customBodyRender: (value) => value?.nome || '',
      },
    },
    {
      name: 'setor',
      label: 'Setor',
      options: {
        sort: false,
        customBodyRender: (value) => value?.nome || '',
      },
    },
    {
      name: 'cargo',
      label: 'Cargo',
      options: {
        sort: false,
        customBodyRender: (value) => value?.nome || '',
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
  ]
  return (
    <Stack height="100%">
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          disableAutoHeight
        />
      </ContainerTable>
    </Stack>
  )
}
