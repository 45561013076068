import React from 'react'

import { Box, makeStyles } from '@material-ui/core'

import clsx from 'clsx'

import useUtils from '~/hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(2.5),
    fontSize: '1rem',
    fontWeight: 400,
    height: theme.spacing(4.5),
    '&:hover': {
      backgroundColor: 'rgb(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: 'rgb(21, 101, 192)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgb(21, 101, 192)',
    },
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled,
    '&:hover': {
      backgroundColor: theme.palette.action.disabled,
      cursor: 'auto',
    },
  },
}))

export default function YearMonthItem(props) {
  const { disabled, yearMonth, isSelected, onClick } = props

  const classes = useStyles()

  const { formatAnoMes } = useUtils()

  function handleClick() {
    if (disabled) return
    onClick(yearMonth)
  }

  return (
    <Box
      className={clsx(classes.root, {
        [classes.disabled]: disabled,
        [classes.selected]: isSelected,
      })}
      onClick={handleClick}
    >
      {formatAnoMes(yearMonth)}
    </Box>
  )
}
