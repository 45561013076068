import moment from 'moment'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { ToolsDataTable, ButtonBox } from '~/components'

import { Ativo } from '~/components/Displays'

import { VinculoFerias } from '~/hooks/queries/VinculoFerias/VinculoFerias'
import { MUIDataTableColumnDef } from 'mui-datatables'

export enum TableActions {
  'edit',
  'delete',
}

interface TableProps {
  data: VinculoFerias[]
  onItemClick: (event: TableActions, d: VinculoFerias) => void
  isLoading: boolean
  isFetching: boolean
}

export default function Table({ data, onItemClick, isLoading, isFetching }: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtPeriodoInicio',
      label: 'Início',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'dtPeriodoFim',
      label: 'Fim',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'dtLimiteConcessao',
      label: 'Limite Concessão',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'dtConcedida',
      label: 'Data da Concessão',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'dtPeriodoPerdido',
      label: 'Período Perdido',
      options: {
        customBodyRender: (value) => (value ? <Ativo ativo={true} /> : ''),
      },
    },

    {
      name: 'nrDiasDireito',
      label: 'Dias Direito',
    },
    {
      name: 'nrDiasConcedidos',
      label: 'Dias Concedidos',
    },
    {
      name: 'nrDiasSaldoAnterior',
      label: 'Dias Saldo',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtFinded = data.find((d) => d.id === value)
                  if (dtFinded) {
                    onItemClick(TableActions.edit, dtFinded)
                  }
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  const dtFinded = data.find((d) => d.id === value)
                  if (dtFinded) {
                    onItemClick(TableActions.delete, dtFinded)
                  }
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
    />
  )
}
