import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { IndSalarioContratualEnum } from '~/@types/enums/IndSalarioContratualEnum'
import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  params: {
    indSalario: IndSalarioContratualEnum
    dtSalario: string
    sindicatoId: string | null
    salarioId: string | null
  }
  data: string[]
}

export function useProcessarAlteracaoSalarial() {
  const dialogNotification = useDialogNotification()
  async function handleRequest({ data, params }: RequestProps) {
    await api.post('/VinculoAlteracaoContratoCadastro/ProcessarAlteracaoSalarial', data, {
      params,
    })
    notifySuccess('Alterações Salariais processadas com sucesso')
  }
  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
