import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EtiquetaAvisoPrevioAvisoPrevioDTO } from './EtiquetaAvisoPrevioDTO'

export const queryObterFuncionarios = '/EtiquetaAvisoPrevio/ObterFuncionariosAvisosPrevios'

interface RequestProps {
  dtInicioAvisoPrevio: string
  dtFimAvisoPrevio: string
  estabelecimentoId: string | null
}

export function useObterFuncionariosAvisosPrevios(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: EtiquetaAvisoPrevioAvisoPrevioDTO[] }>(
      queryObterFuncionarios,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterFuncionarios], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
