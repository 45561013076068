import React, { useState } from 'react'

import { Box } from '@material-ui/core'

import { PageHeader, PDFViewer, PrintButton } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import { useSubmit } from '~/hooks/queries/useValoresPorEvento'
import useDialogNotification from '~/hooks/useDialogNotification'

const initialState = {
  estabelecimentosValorEvento: [],
  anoMesFim: '',
  anoMesInicio: '',
}

export default function ValoresPorEvento() {
  const [valorPorEvento, setValorPorEvento] = useState(initialState)
  const [isOpenPDFViewer, setOpenPDFViewer] = useState(false)

  const { mutateAsync, isLoading } = useSubmit()
  const dialogNotification = useDialogNotification()

  async function handleSubmit(data) {
    const estabelecimentosId = data?.estabelecimentos.map((item) => item.id)
    const eventosId = data?.eventos.map((item) => item.id)
    const recibosIDF = data?.recibosIDF.map((item) => item.value)

    const newData = {
      ...data,
      estabelecimentosId,
      eventosId,
      recibosIDF,
    }

    const response = await mutateAsync(newData)
    if (!response) return
    setValorPorEvento(response)
  }

  function handleCancel() {
    setValorPorEvento(initialState)
  }

  function handlePrint() {
    if (!(valorPorEvento?.estabelecimentosValorEvento?.length > 0))
      return dialogNotification.warning({
        descriptions: ['Necessário pelo menos um estabelecimento para realizar a impressão.'],
      })
    setOpenPDFViewer(true)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Valores Por Evento" />

      <Form onAfterSubmit={handleSubmit} isLoading={isLoading} onClickCancel={handleCancel} />

      <Table data={valorPorEvento?.estabelecimentosValorEvento || []} isLoading={isLoading} />

      <Box display="flex" justifyContent="flex-end" paddingTop={1}>
        <PrintButton onClick={handlePrint} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={() => setOpenPDFViewer(false)}
        reportKey="ValorPorEvento"
        title="Relatório / Valores por Evento"
        axiosConfig={{
          url: 'Relatorio/EventosPorValor/ObterRelatorio',
          method: 'post',
          data: valorPorEvento,
        }}
      />
    </Box>
  )
}
