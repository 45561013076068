import { useEffect, useState } from 'react'

import { Box, CircularProgress, Grid, LinearProgress } from '@material-ui/core'

import { Button, Stack } from '~/components'
import RowConta from '~/components/RowConta'

import useAmbiente from '~/hooks/useAmbiente'

import { useAtualizarGPSConfig } from '~/hooks/queries/ExportacaoLancamentoConfig/useAtualizarGPSConfig'
import { useObterPorModeloIntegracao } from '~/hooks/queries/ExportacaoLancamentoConfig/useObterPorModeloIntegracao'

import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'
import { GPSEmpregadorConfig } from '~/hooks/queries/ExportacaoLancamentoConfig/dtos/GPSEmpregadorConfig'

const startData = {} as GPSEmpregadorConfig

interface GPSConfiguracaoProps {
  modeloIntegracao: ModeloIntegracao
  isMensal: boolean
}

export default function GPSConfiguracao({ modeloIntegracao, isMensal }: GPSConfiguracaoProps) {
  const [data, setData] = useState(startData)

  const { estabelecimento } = useAmbiente()

  const { mutateAsync, isLoading: isSubmitting } = useAtualizarGPSConfig()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterPorModeloIntegracao({
    params: {
      estabelecimentoId: estabelecimento.id,
      modeloIntegracao: modeloIntegracao.id,
    },
  })

  const gpsConfig =
    (isMensal ? _d?.gpsEmpregadorMensal : _d?.gpsEmpregadorDecimoTerceiro) || startData

  useEffect(() => {
    setData(gpsConfig)
  }, [gpsConfig])

  function handleCancel() {
    setData(gpsConfig)
  }

  async function handleSave() {
    if (_d) {
      const gpsConfig = isMensal
        ? { gpsEmpregadorMensal: data }
        : { gpsEmpregadorDecimoTerceiro: data }

      await mutateAsync({
        data: {
          ..._d,
          ...gpsConfig,
        },
        params: {
          id: _d.id,
        },
      })
    }
  }

  return (
    <Stack position="relative" height="100%" p={0} px={2}>
      <Box flex={1} overflow="auto" style={{ overflowX: 'hidden' }}>
        <Box height={4}>{isFetching && <LinearProgress />}</Box>

        {isLoading ? (
          <Box
            position="absolute"
            right={0}
            left={0}
            top={0}
            bottom={0}
            zIndex={999}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={80} />
          </Box>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <RowConta
                label="Vr. Patronal"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.gpsVrPatronal?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrPatronal: {
                      ...prev.gpsVrPatronal,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.gpsVrPatronal?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrPatronal: {
                      ...prev.gpsVrPatronal,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.gpsVrPatronal?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrPatronal: {
                      ...prev.gpsVrPatronal,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Vr. Recolher"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.gpsVrRecolher?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrRecolher: {
                      ...prev.gpsVrRecolher,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.gpsVrRecolher?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrRecolher: {
                      ...prev.gpsVrRecolher,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.gpsVrRecolher?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrRecolher: {
                      ...prev.gpsVrRecolher,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Vr. Salário Família"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.gpsVrSalarioFamilia?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrSalarioFamilia: {
                      ...prev.gpsVrSalarioFamilia,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.gpsVrSalarioFamilia?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrSalarioFamilia: {
                      ...prev.gpsVrSalarioFamilia,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.gpsVrSalarioFamilia?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrSalarioFamilia: {
                      ...prev.gpsVrSalarioFamilia,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Vr. Salário Maternidade"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.gpsVrSalarioMaternidade?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrSalarioMaternidade: {
                      ...prev.gpsVrSalarioMaternidade,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.gpsVrSalarioMaternidade?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrSalarioMaternidade: {
                      ...prev.gpsVrSalarioMaternidade,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.gpsVrSalarioMaternidade?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrSalarioMaternidade: {
                      ...prev.gpsVrSalarioMaternidade,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Vr. Terceiros"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.gpsVrTerceiros?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrTerceiros: {
                      ...prev.gpsVrTerceiros,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.gpsVrTerceiros?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrTerceiros: {
                      ...prev.gpsVrTerceiros,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.gpsVrTerceiros?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    gpsVrTerceiros: {
                      ...prev.gpsVrTerceiros,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>

      <Stack orientation="horizontal" justifyContent="flex-end">
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button variant="contained" onClick={handleSave} isLoading={isSubmitting}>
          Salvar
        </Button>
      </Stack>
    </Stack>
  )
}
