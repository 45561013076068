const treinamentoAntesPrimeiroEmbarque_3701 = 3701
const treinamentoAntesPrimeiroEmbarqueReciclagem_3702 = 3702
const treinamentoEventual_3703 = 3703
const treinamentoBasico_3704 = 3704
const treinamentoBasicoReciclagem_3705 = 3705
const treinamentoAvançado_3706 = 3706
const treinamentoAvançadoReciclagem_3707 = 3707
const treinamentoEspecíficoEmpregadoIntegranteBrigadaIncêndio_3708 = 3708
const treinamentoEspecíficoRiscosRadiológicosPlataforma_3709 = 3709
const treinamentoEspecíficoEmpregadoIntegranteEquipeRespostaEmergências_3710 = 3710
const treinamentoSinaleiro_3711 = 3711
const treinamentoOperadorGuindaste_3712 = 3712
const treinamentoCursoBásicoParaManipuladorAlimentos_3713 = 3713
const treinamentoCursocomplementarServiçosInstalaçõesElétricasAltaTensão_3714 = 3714
const treinamentoCursoBásicoSegurançaOperaçõesMovimentaçãoCargasTransportePessoas_3715 = 3715
const treinamentoCursoComplementarParaOperadoresGuindastes_3716 = 3716
const treinamentoCursoParaIndivíduosCcupacionalmenteExpostosRadiaçãoIonizante_3717 = 3717
const treinamentoProcedimentoOperacionalAcendimentoChamaPiloto_3718 = 3718
const autorizaçãoparaTrabalharInstalaçõesElétricas_1006 = 1006
const operaçãoRealizaçãoIntervençõesMáquinas_1207 = 1207
const operadorEquipamentoDeGuindar_3719 = 3719

export const codigoTreinamentoCapacitacaoValues = [
  {
    value: treinamentoAntesPrimeiroEmbarque_3701,
    name: '3701 - Treinamento antes do primeiro embarque',
  },
  {
    value: treinamentoAntesPrimeiroEmbarqueReciclagem_3702,
    name: '3702 - Treinamento antes do primeiro embarque - Reciclagem',
  },
  { value: treinamentoEventual_3703, name: '3703 - Treinamento eventual' },
  { value: treinamentoBasico_3704, name: '3704 - Treinamento básico' },
  {
    value: treinamentoBasicoReciclagem_3705,
    name: '3705 - Treinamento básico - Reciclagem',
  },
  { value: treinamentoAvançado_3706, name: '3706 - Treinamento avançado' },
  {
    value: treinamentoAvançadoReciclagem_3707,
    name: '3707 - Treinamento avançado - Reciclagem',
  },
  {
    value: treinamentoEspecíficoEmpregadoIntegranteBrigadaIncêndio_3708,
    name: '3708 - Treinamento específico - Empregado integrante da brigada de incêndio',
  },
  {
    value: treinamentoEspecíficoRiscosRadiológicosPlataforma_3709,
    name: '3709 - Treinamento específico - Riscos radiológicos da plataforma',
  },
  {
    value: treinamentoEspecíficoEmpregadoIntegranteEquipeRespostaEmergências_3710,
    name: '3710 - Treinamento específico - Empregado integrante de equipe de resposta a emergências',
  },
  {
    value: treinamentoSinaleiro_3711,
    name: '3711 - Treinamento - Sinaleiro (reciclagem)',
  },
  {
    value: treinamentoOperadorGuindaste_3712,
    name: '3712 - Treinamento - Operador de guindaste (reciclagem)',
  },
  {
    value: treinamentoCursoBásicoParaManipuladorAlimentos_3713,
    name: '3713 - Treinamento - Curso Básico para Manipulador de Alimentos',
  },
  {
    value: treinamentoCursocomplementarServiçosInstalaçõesElétricasAltaTensão_3714,
    name: '3714 - Treinamento - Curso complementar para serviços em instalações elétricas em alta tensão',
  },
  {
    value: treinamentoCursoBásicoSegurançaOperaçõesMovimentaçãoCargasTransportePessoas_3715,
    name: '3715 - Treinamento - Curso básico de segurança em operações de movimentação de cargas e transporte de pessoas',
  },
  {
    value: treinamentoCursoComplementarParaOperadoresGuindastes_3716,
    name: '3716 - Treinamento - Curso complementar para operadores de guindastes',
  },
  {
    value: treinamentoCursoParaIndivíduosCcupacionalmenteExpostosRadiaçãoIonizante_3717,
    name: '3717 - Treinamento - Curso para indivíduos ocupacionalmente expostos à radiação ionizante',
  },
  {
    value: treinamentoProcedimentoOperacionalAcendimentoChamaPiloto_3718,
    name: '3718 - Treinamento - Procedimento operacional - Acendimento da chama piloto',
  },
  {
    value: autorizaçãoparaTrabalharInstalaçõesElétricas_1006,
    name: '1006 - Autorização para trabalhar em instalações elétricas',
  },
  {
    value: operaçãoRealizaçãoIntervençõesMáquinas_1207,
    name: '1207 - Operação e realização de intervenções em máquinas',
  },
  {
    value: operadorEquipamentoDeGuindar_3719,
    name: '3719 - Operador do equipamento de guindar',
  },
]
