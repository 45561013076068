import React, { useCallback, useMemo, useRef } from 'react'

import { ToolsDataTable, CurrencyTextField } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'
import moment from 'moment'

const Table = (props) => {
  const { data: _data, isLoading, query } = props
  const valorInputRefs = useRef([])
  const quantidadeInputRefs = useRef([])

  const dialogNotification = useDialogNotification()

  const data = _data.map((d) => {
    return {
      ...d,
      matricula: d?.vinculo?.matricula || '',
      nome: d?.vinculo?.pessoaFisica?.nome || '',
      dtAdmissao: d?.vinculo?.dtAdmissao ? moment(d.vinculo.dtAdmissao).format('DD/MM/yyyy') : '',
    }
  })

  const changeRow = useCallback(
    async (value, field, index) => {
      const dataToVerify = { ...data[index] }
      dataToVerify[field] = value

      if (dataToVerify[field] === data[index][field]) return

      const item = data[index]
      item[field] = value

      try {
        await api.put(`/LancamentoColetivo/${item?.id}`, item)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    },
    // eslint-disable-next-line
    [data],
  )

  const handleKeyDownQuantidade = (event, index) => {
    if (event.key === 'Enter') {
      const currentInput = valorInputRefs.current[index].input
      currentInput.focus()
    }
  }

  const handleKeyDownPressValor = (event, index) => {
    if (event.key === 'Enter') {
      const quantidadeInputs = quantidadeInputRefs.current
      const lastInput = quantidadeInputs[quantidadeInputs.length - 1].input
      const currentInput = quantidadeInputs[index].input

      const inputToFocus =
        lastInput !== currentInput ? quantidadeInputs[index + 1].input : quantidadeInputs[0].input
      inputToFocus.focus()
    }
  }

  const columns = useMemo(
    () => [
      {
        name: 'matricula',
        label: 'Matrícula',
        options: {
          sort: false,
        },
      },
      {
        name: 'nome',
        label: 'Nome',
        options: {
          sort: false,
        },
      },
      {
        name: 'dtAdmissao',
        label: 'Data Admissão',
        options: {
          sort: false,
        },
      },
      {
        name: 'quantidade',
        label: 'Quantidade',
        options: {
          sort: false,
          customBodyRender: (value, { rowIndex }) => (
            <CurrencyTextField
              fullWidth
              inputProps={{
                onKeyDown: (event) => handleKeyDownQuantidade(event, rowIndex),
              }}
              ref={(ref) => (quantidadeInputRefs.current[rowIndex] = ref)}
              value={value}
              onBlur={(e, newValue) => changeRow(newValue, 'quantidade', rowIndex)}
            />
          ),
        },
      },
      {
        name: 'vrEvento',
        label: 'Valor',
        options: {
          sort: false,
          customBodyRender: (value, { rowIndex }) => (
            <CurrencyTextField
              fullWidth
              inputProps={{
                onKeyDown: (event) => handleKeyDownPressValor(event, rowIndex),
              }}
              ref={(ref) => (valorInputRefs.current[rowIndex] = ref)}
              value={value}
              onBlur={(e, newValue) => changeRow(newValue, 'vrEvento', rowIndex)}
            />
          ),
        },
      },
    ],
    [changeRow],
  )

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      pagination={false}
      disableAutoHeight
      sherlock={{
        columns: ['matricula', 'nome', 'dtAdmissao'],
        query,
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
