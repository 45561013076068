import { Typography } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { useGerarAuditoriaRescisaoZerada } from '~/hooks/queries/RelRescisao/useGerarAuditoriaRescisaoZerada'

interface DialogConfirmAuditoriaZeradaProps {
  vinculoId: string
  isOpen: boolean
  onClose: () => void
  afterSubmit: (d: string) => void
}

export default function DialogConfirmAuditoriaZerada({
  vinculoId,
  isOpen,
  onClose,
  afterSubmit,
}: DialogConfirmAuditoriaZeradaProps) {
  const { mutateAsync, isLoading } = useGerarAuditoriaRescisaoZerada()

  function handleCancel() {
    afterSubmit(vinculoId)
    onClose()
  }

  async function handleSubmit() {
    await mutateAsync({ params: { vinculoId } })
    afterSubmit(vinculoId)
    onClose()
  }

  return (
    <ActionDialog
      title="Informe as seguintes informações"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={handleCancel}
      onOkClick={handleSubmit}
      okLabel="Sim"
      cancelLabel="Não, apenas imprimir"
      isOkProcessing={isLoading}
    >
      <Typography>
        Deseja gerar o evento S_2299 da rescisão zerada para o envio do eSocial?
      </Typography>
    </ActionDialog>
  )
}
