import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { ActionMenu, ButtonBox, ToolsDataTable } from '~/components'

import { useObterTodos } from '~/hooks/queries/ProcessoTrabalhistaTributos/useObterTodos'

import { formatPrettyAnoMes } from '~/hooks/useUtils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ProcessoTrabalhistaTributos } from '~/hooks/queries/ProcessoTrabalhistaTributos/dtos/ProcessoTrabalhistaTributos'

export type ActionTable = 'delete' | 'edit' | 'retificar'

interface TableProps {
  query: string
  processoTrabalhistaId: string
  onClickAction: (event: ActionTable, row: ProcessoTrabalhistaTributos) => void
  isLoadingRetificar: boolean
}

export default function Table({
  query,
  processoTrabalhistaId,
  onClickAction,
  isLoadingRetificar,
}: TableProps) {
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterTodos({
    params: { processoTrabalhistaId },
  })
  const data = _data || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'periodoApuracao',
      label: 'Período',
      options: {
        customBodyRender: formatPrettyAnoMes,
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'eSocialRecibo',
      label: 'Recibo eSocial',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <ActionMenu
                isLoading={isLoadingRetificar}
                options={[
                  {
                    icon: 'replay',
                    title: 'Retificar este registro',
                    label: 'Retificar',
                    onClick: () => {
                      const rowCurrent = data.find((d) => d.id === value)
                      if (rowCurrent) {
                        onClickAction('retificar', rowCurrent)
                      }
                    },
                  },
                ]}
              />

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const rowCurrent = data.find((d) => d.id === value)
                  if (rowCurrent) {
                    onClickAction('edit', rowCurrent)
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  const rowCurrent = data.find((d) => d.id === value)
                  if (rowCurrent) {
                    onClickAction('delete', rowCurrent)
                  }
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: [],
      }}
    />
  )
}
