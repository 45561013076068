import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

export function useVinculoDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(id: string) {
    await api.post('/Vinculo/ExcluirVinculo/' + id)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.remove()
      await queryClient.invalidateQueries('VinculoConsultaGetByEstabelecimento')
    },
    onError: dialogNotification.extractErrors,
  })
}
