import React from 'react'

import { Box, BoxProps, Divider, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    boxShadow: theme.shadows[5],
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '38px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: theme.palette.grey[700],
  },
  content: {
    paddingTop: theme.spacing(1),
  },
  disabledContent: {
    pointerEvents: 'none',
    opacity: 0.6,
  },
  actions: {
    marginTop: theme.spacing(2),
  },
}))

interface ContainerFeriasProps extends BoxProps {
  title: string
  titleHover?: string
  children: React.ReactNode
  renderRight?: () => React.ReactNode
  renderActions?: () => React.ReactNode
  disabled?: boolean
}

export default function ContainerFerias(props: ContainerFeriasProps) {
  const {
    title,
    children,
    renderRight,
    renderActions,
    disabled = false,
    titleHover = '',
    ...rest
  } = props
  const classes = useStyles()
  return (
    <>
      <Box className={classes.root} {...rest}>
        <Box className={classes.header}>
          <Box className={classes.title}>{title}</Box>
          {renderRight && <Box>{renderRight()}</Box>}
        </Box>
        <Divider />
        <span title={titleHover}>
          <Box
            className={clsx(classes.content, {
              [classes.disabledContent]: disabled,
            })}
          >
            {children}
          </Box>
        </span>
      </Box>
      {renderActions && <Box className={classes.actions}>{renderActions()}</Box>}
    </>
  )
}
