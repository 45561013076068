import { Stack, ContainerTable, ConfirmDeleteDialog } from '~/components'

import Table from './Table'
import Form from './Form'

import useDialog from '~/hooks/useDialog'

import { useObterFeriadosPorVinculo } from '~/hooks/queries/VinculoTrabalhoFeriado/useObterFeriadosPorVinculo'
import { VinculoTrabalhoFeriado } from '~/hooks/queries/VinculoTrabalhoFeriado/dtos/VinculoTrabalhoFeriado'
import { useRemoverVinculoTrabalhoFeriado } from '~/hooks/queries/VinculoTrabalhoFeriado/useRemoverVinculoTrabalhoFeriado'

interface ContentProps {
  vinculoId: string
}

export default function Content({ vinculoId }: ContentProps) {
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog({ vinculoId } as VinculoTrabalhoFeriado)

  const { data: _d, isLoading, isFetching } = useObterFeriadosPorVinculo({ vinculoId })
  const data = _d || []

  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } =
    useRemoverVinculoTrabalhoFeriado()

  function handleClickItem(event: 'delete' | 'edit', data: VinculoTrabalhoFeriado) {
    switch (event) {
      case 'delete':
        openConfirmDelete(data.id)
        break
      case 'edit':
        openForm(data)
        break
    }
  }

  async function handleDelete() {
    await mutateAsyncRemove(dataConfirmDelete)
    closeConfirmDelete()
  }

  return (
    <Stack>
      <ContainerTable>
        <Table
          data={data}
          isFetching={isFetching}
          isLoading={isLoading}
          onItemClick={handleClickItem}
        />
      </ContainerTable>
      {isOpenForm && <Form isOpen={isOpenForm} onClose={closeForm} initialData={dataForm} />}
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDelete}
          isDeleting={isRemoving}
        />
      )}
    </Stack>
  )
}
