import { useEffect, useState } from 'react'

import moment from 'moment'

import PropType from 'prop-types'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'
import { formatToCPF } from 'brazilian-values'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'

export default function AutoCompleteVinculo(props) {
  const { estabelecimentoId, anoMes, vinculosTipo, label, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setOptions([])
  }, [estabelecimentoId, anoMes])

  const getOptions = async (query) => {
    setLoading(true)
    try {
      if (!estabelecimentoId || !anoMes)
        throw Error('O Estabelecimento e/ou Ano/Mês não foram informados na consulta do Vínculo')
      const response = await api.post('/VinculoConsulta/GetByEstabelecimento', vinculosTipo, {
        params: {
          estabelecimentoId,
          anoMes,
          value: query,
        },
      })
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) =>
    option?.pessoaCPF ? `${formatToCPF(option.pessoaCPF)} - ${option.pessoaNome}` : ''

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      renderOptionComponent={(opt) => (
        <AutoCompleteOptionDescription
          title={`${formatToCPF(opt.pessoaCPF)} - ${opt.pessoaNome}`}
          descriptions={[
            {
              description: `Admissão: ${moment(opt.dtAdmissao).format('DD/MM/yyyy')} - Rescisão: ${
                opt.dtRescisao ? moment(opt.dtRescisao).format('DD/MM/yyyy') : '-'
              }`,
            },
          ]}
        />
      )}
      label={label}
      waitSearch
      {...rest}
    />
  )
}

AutoCompleteVinculo.defaultProps = {
  label: 'Vínculo',
  estabelecimentoId: '',
  anoMes: '',
  optionId: 'id',
  vinculosTipo: [
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Autonomo_4,
    VinculoTipoEnum.Cooperado_5,
    VinculoTipoEnum.Empregador_2,
    VinculoTipoEnum.Estagiario_3,
  ],
}

AutoCompleteVinculo.propTypes = {
  getOptionDisabled: PropType.func,
  renderOption: PropType.func,
  onChange: PropType.func,
  options: PropType.array,
  value: PropType.any,
  optionId: PropType.string,
  required: PropType.bool,
  validationErrors: PropType.any,
  name: PropType.string,
  label: PropType.string,
  disabled: PropType.bool,
}
