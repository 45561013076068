import { useMutation, useQueryClient, useQuery as useTQuery } from 'react-query'

import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'

import api from '~/services/api-pessoal'

async function getVinculoExameToxicologico(vinculoId) {
  if (!vinculoId) return
  const response = await api.get('/VinculoExameToxicologico/GetByVinculo', {
    params: {
      vinculoId,
    },
  })

  return response?.data?.data
}

export function useQuery(vinculoId) {
  const dialogNotification = useDialogNotification()
  return useTQuery(['ExameToxicologico', vinculoId], () => getVinculoExameToxicologico(vinculoId), {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useSubmit() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function updateVinculoExameToxicologico(data) {
    await api.put(`/VinculoExameToxicologico/${data?.id}`, data)
    notification.put()
  }
  async function insertVinculoExameToxicologico(data) {
    await api.post('/VinculoExameToxicologico', data)
    notification.post()
  }

  return useMutation(
    async (data) => {
      if (data?.id) {
        await updateVinculoExameToxicologico(data)
      } else {
        await insertVinculoExameToxicologico(data)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries('ExameToxicologico'),
      onError: dialogNotification.extractErrors,
    },
  )
}

export function useDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function deleteVinculoReintegracao(id) {
    await api.delete(`/VinculoExameToxicologico/${id}`)
    notification.remove()

    return id
  }

  return useMutation((id) => deleteVinculoReintegracao(id), {
    onSuccess: async (id) => {
      await queryClient.cancelQueries('ExameToxicologico')
      queryClient.setQueriesData('ExameToxicologico', (old) => {
        const newData = old?.filter((d) => d.id !== id)
        return newData
      })
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
