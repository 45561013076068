import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import moment from 'moment'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { tipoLotacaoValues } from '~/values/tipoLotacaoValues'

const changeNameTipoLotacao = (tipoLotacao) => {
  const lotacao = tipoLotacaoValues.find((lot) => lot.value === tipoLotacao)
  if (!lotacao) return ''
  return (
    <div title={`${lotacao.value} - ${lotacao.name}`}>
      {lotacao.value} - {lotacao.name.slice(0, 50)}...
    </div>
  )
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data.map((d) => {
    return {
      ...d,
      dtSituacao: d?.dtSituacao ? moment(d.dtSituacao).format('DD/MM/yyyy') : '',
      nrInscricao: formatToCPFOrCNPJ(d?.nrInscricao || ''),
      nrInscricaoContratante: formatToCPFOrCNPJ(d?.nrInscricaoContratante || ''),
      nrInscricaoProprietario: formatToCPFOrCNPJ(d?.nrInscricaoProprietario || ''),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtSituacao',
        label: 'Data da Situação',
      },
      {
        name: 'tipoLotacao',
        label: 'Tipo de Lotação',
        options: {
          customBodyRender: changeNameTipoLotacao,
        },
      },
      {
        name: 'fpasId',
        label: 'FPAS',
      },
      {
        name: 'terceirosId',
        label: 'Terceiros',
      },
      {
        name: 'terceirosSuspensoId',
        label: 'Terceiros Suspenso',
      },
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
      {
        name: 'nrInscricaoContratante',
        label: 'Nr Inscrição Contratante',
      },
      {
        name: 'nrInscricaoProprietario',
        label: 'Nr Inscrição Proprietário',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['dtSituacao', 'tipoLotacao'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
