import { IconButton } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import useAmbiente from '~/hooks/useAmbiente'
import { useResumoConfiguracoesLotacoes } from '~/hooks/queries/ExportacaoLancamentoConfig/useResumoConfiguracoesLotacoes'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { LotacaoConfigConsultaDTO } from '~/hooks/queries/ExportacaoLancamentoConfig/dtos/LotacaoConfigConsultaDTO'

interface TableProps {
  modeloIntegracaoId: string
  onClickItem: (value: LotacaoConfigConsultaDTO) => void
}

export default function Table({ modeloIntegracaoId, onClickItem }: TableProps) {
  const { estabelecimento } = useAmbiente()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useResumoConfiguracoesLotacoes({
    params: {
      modeloIntegracaoId,
      estabelecimentoId: estabelecimento.id,
    },
  })
  const data = _d || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'lotacaoCodigo',
      label: 'Código',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'center', width: '120px' },
        }),
      },
    },
    {
      name: 'lotacaoNome',
      label: 'Lotação',
    },
    {
      name: 'total',
      label: 'Total/Eventos',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'center', width: '120px' },
        }),
      },
    },
    {
      name: 'configurado',
      label: 'Configurados',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'center', width: '120px' },
        }),
      },
    },
    {
      name: 'naoConfigurado',
      label: 'Pendentes',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'center', width: '120px' },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => (
          <ButtonBox justifyContent="center" spacing={0}>
            <IconButton
              title="Editar este registro"
              color="primary"
              aria-label="Editar"
              onClick={() => {
                const dt = data.find((d) => d.id === value)
                if (dt) {
                  onClickItem(dt)
                }
              }}
              size="small"
            >
              <EditIcon fontSize="small" />
            </IconButton>

            {/* <IconButton
              title="Deletar este registro"
              color="primary"
              aria-label="Deletar"
              // onClick={() => {
              //   onItemClick('delete', rowIndex)
              // }}
              size="small"
              disabled
            >
              <DeleteIcon fontSize="small" />
            </IconButton> */}
          </ButtonBox>
        ),
        setCellProps: () => ({
          style: { width: '120px' },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  )
}
