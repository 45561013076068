import { useState } from 'react'

import { Box, Tab, Tabs, useTheme } from '@material-ui/core'

import { PageHeader, TabPanel } from '~/components'

import ModeloPlanilhaImportacao from './ModeloPlanilhaImportacao'
import GerarPlanilhaImportacao from './GerarPlanilhaImportacao'
import ImportarPlanilha from './ImportarPlanilha'

enum TabImportacaoEventos {
  ModeloPlanilha,
  GerarPlanilha,
  ImportarEventos,
}

export default function ImportacaoPlanilhasEventos() {
  const [tab, setTab] = useState(TabImportacaoEventos.ModeloPlanilha)

  const theme = useTheme()

  return (
    <Box height="100%" padding={2} display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <PageHeader title="Importação de Eventos">
        <Box>
          <Tabs value={tab} onChange={(e, value) => setTab(value)}>
            <Tab label="Modelo Planilha" />
            <Tab label="Gerar Planilha" />
            <Tab label="Importar Eventos" />
          </Tabs>
        </Box>
      </PageHeader>

      <Box flex={1}>
        <TabPanel
          value={tab}
          index={TabImportacaoEventos.ModeloPlanilha}
          style={{ height: '100%' }}
        >
          <ModeloPlanilhaImportacao />
        </TabPanel>
        <TabPanel value={tab} index={TabImportacaoEventos.GerarPlanilha} style={{ height: '100%' }}>
          <GerarPlanilhaImportacao />
        </TabPanel>
        <TabPanel
          value={tab}
          index={TabImportacaoEventos.ImportarEventos}
          style={{ height: '100%' }}
          keepMount
        >
          <ImportarPlanilha />
        </TabPanel>
      </Box>
    </Box>
  )
}
