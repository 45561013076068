import React from 'react'

import clsx from 'clsx'

import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core'

import MUIExpandableButton from '../../MUIExpandableButton'

import { getDeepDynamicPropValue } from '~/hooks/useUtils'

const useStyles = makeStyles((theme: FixLater) => ({
  tableCell: {
    fontSize: '13px',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  tableRow: {
    height: '30px',
    '&:nth-child(even)': {
      backgroundColor: '#FAFAFA',
    },
    '&:hover': {
      backgroundColor: '#ffffde!important',
    },
    '&:last-child': {
      border: '1px solid #D0D0D0',
    },
  },
  tableRowDeepHeight: {
    height: '25px',
  },
  rowSelected: {
    backgroundColor: theme.palette.secondary.light + '!important',
    '&:hover': {
      backgroundColor: theme.palette.secondary.lighter + '!important',
    },
  },
}))

export default function MUITableRow(props: MUITableRowProps) {
  const { onClickExpand, columns, indexCurrent, dataCurrent, rowExpanded, rowSelected } = props

  const classes = useStyles()

  return (
    <>
      <TableRow
        className={clsx(classes.tableRow, {
          [classes.rowSelected]: indexCurrent === rowSelected,
        })}
        tabIndex={-1}
      >
        {rowExpanded ? (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, textAlign: 'center' }}>
            <MUIExpandableButton
              open={rowExpanded.rowsExpanded.includes(indexCurrent)}
              onClick={() => onClickExpand(indexCurrent)}
            />
          </TableCell>
        ) : (
          <></>
        )}
        {columns.map((column, index) => {
          let firstRowValue = getDeepDynamicPropValue(dataCurrent, column.firstRow)
          let secondRowValue = column.secondRow
            ? getDeepDynamicPropValue(dataCurrent, column.secondRow)
            : undefined

          firstRowValue = column?.options?.customBodyRenderFirstRow
            ? column?.options?.customBodyRenderFirstRow(firstRowValue, indexCurrent)
            : firstRowValue
          secondRowValue = column?.options?.customBodyRenderSecondRow
            ? column?.options?.customBodyRenderSecondRow(secondRowValue, indexCurrent)
            : secondRowValue
          const classeTableCellStyle = column?.options?.customStyleCell
            ? column?.options?.customStyleCell(indexCurrent)
            : undefined

          return (
            <TableCell className={clsx(classes.tableCell, classeTableCellStyle)} key={index}>
              <TableRow
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                component="div"
                className={classes.tableRowDeepHeight}
              >
                {firstRowValue}
              </TableRow>
              {secondRowValue !== undefined ? (
                <TableRow
                  style={{
                    display: 'flex',
                  }}
                  component="div"
                  className={classes.tableRowDeepHeight}
                >
                  {secondRowValue}
                </TableRow>
              ) : (
                <></>
              )}
            </TableCell>
          )
        })}
      </TableRow>
      {rowExpanded?.rowsExpanded && rowExpanded.rowsExpanded.includes(indexCurrent) ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
            <Box style={{ border: '1px solid #D0D0D0' }}>
              {rowExpanded.expandChildren(indexCurrent)}
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        <></>
      )}
    </>
  )
}
