import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EstabelecimentoConsulta } from './EstabelecimentoConsulta'

export const estabelecimentoConsultaQuery = '/EstabelecimentoConsulta'

export function useEstabelecimentoConsulta() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: EstabelecimentoConsulta[] }>(
      estabelecimentoConsultaQuery,
    )
    return response?.data?.data || []
  }

  const query = useQuery(estabelecimentoConsultaQuery, handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })

  return { ...query, data: query.data || [] }
}
