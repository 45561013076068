import { Box, Typography } from '@material-ui/core'
import { TreeView as MuiTreeView, TreeItem as FirstLevel } from '@material-ui/lab'
import { ExpandMore as ExpandMoreIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons'

import CardItem from './components/CardItem'
import SecondLevel from './components/SecondLevel'
import ThirdLevel from './components/ThirdLevel'

import { useContextRecibo } from '~/hooks/useRecibo'
import { formatToDigits } from '~/utils/utils'

const TreeView = (props) => {
  const { data, onSelectItem, onContextMenuItemClick } = props

  const { reciboPagamento, expanded, setExpanded } = useContextRecibo()

  const handleOnLabelClick = (evento, value) => {
    onSelectItem(evento, value)
  }

  const handleToggle = (event, nodeIds) => {
    const nodeIdsReversed = nodeIds.reverse()
    let firstLevel = {}
    let secondLevel = {}

    nodeIdsReversed.forEach((nodeItem) => {
      let currentLevel = {
        level: nodeItem.split('|')[0],
        id: nodeItem.split('|')[1],
      }

      if (currentLevel.level === '0') {
        firstLevel.level = currentLevel.level
        firstLevel.id = currentLevel.id
      }
      if (currentLevel.level === '1') {
        secondLevel.level = currentLevel.level
        secondLevel.id = currentLevel.id
      }
    })

    const firstLevelString = firstLevel?.id ? `${firstLevel.level}|${firstLevel.id}` : ''
    const secondLevelString = secondLevel?.id ? `${secondLevel.level}|${secondLevel.id}` : ''

    setExpanded([firstLevelString, secondLevelString])
  }

  return (
    <MuiTreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleToggle}
      disableSelection={true}
      selected={[reciboPagamento?.id]}
    >
      {data?.length === 0 ? (
        <Typography align="center">Registros não encontrados</Typography>
      ) : (
        <></>
      )}
      {data.map((group) => {
        return (
          <FirstLevel
            key={group.agrupamentoId}
            nodeId={`0|${group.agrupamentoId}`}
            label={
              <CardItem
                labelText={group.agrupamentoNome}
                labelInfo={
                  <Box fontWeight="bold">{formatToDigits(group?.vinculos?.length || 0, 2)}</Box>
                }
                bolder={true}
              />
            }
          >
            {group.vinculos.map((vinculoCurrent) => (
              <SecondLevel
                key={vinculoCurrent.vinculoId}
                vinculoCurrent={vinculoCurrent}
                group={group}
                onContextMenuItemClick={onContextMenuItemClick}
                handleOnLabelClick={handleOnLabelClick}
              >
                {vinculoCurrent.recibos.map((reciboCurrent, index) => (
                  <ThirdLevel
                    key={reciboCurrent.reciboId || `recibo-${index}`}
                    reciboCurrent={reciboCurrent}
                    index={index}
                    handleOnLabelClick={handleOnLabelClick}
                  />
                ))}
              </SecondLevel>
            ))}
          </FirstLevel>
        )
      })}
    </MuiTreeView>
  )
}

TreeView.defaultProps = {
  data: [],
  onSelectItem: () => undefined,
  onContextMenuItemClick: () => undefined,
}

export default TreeView
