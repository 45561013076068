import React, { useMemo, useState } from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Finder } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { HeightDataTable } from '~/components'
import { indOrgaoClasseValues } from '~/values/indOrgaoClasseValues'

const formatOrgaoClasse = (orgaoClasse) => {
  const objectFinded = indOrgaoClasseValues.find((obj) => obj.value === orgaoClasse)
  return objectFinded.name
}

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '400px',
  },
}))

const DialogResponsaveis = (props) => {
  const { data: _data, vinculo, isOpen, onClose } = props

  const [query, setQuery] = useState('')

  const classes = useStyles()

  const data = _data.map((item) => {
    return {
      ...item,
      responsavelCodigo: item?.responsavel?.codigo || '',
      responsavelNome: item?.responsavel?.nome || '',
      responsavelCpf: item?.responsavel?.nrInscricao || '',
      responsavelOrgaoClasse: formatOrgaoClasse(item?.responsavel?.orgaoClasse),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'responsavelCodigo',
        label: 'Médico/Código',
      },
      {
        name: 'responsavelNome',
        label: 'Médico/Nome',
      },
      {
        name: 'responsavelCpf',
        label: 'Médico/CPF',
      },
      {
        name: 'responsavelOrgaoClasse',
        label: 'Orgão de Classe',
      },
    ],
    [],
  )

  return (
    <ActionDialog
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      title="Responsáveis - Evento S-2240"
      subtitle={vinculo ? vinculo?.vinculoNome : '-'}
      renderRight={<Finder onSearch={setQuery} onClose={() => setQuery('')} />}
      customActions={<></>}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <Box height="100%" pb={1}>
        <HeightDataTable
          title=""
          data={data}
          columns={columns}
          sherlock={{
            query,
            columns: [
              'responsavelCodigo',
              'responsavelNome',
              'responsavelCpf',
              'responsavelOrgaoClasse',
            ],
          }}
        />
      </Box>
    </ActionDialog>
  )
}

export default DialogResponsaveis
