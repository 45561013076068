import React, { useState } from 'react'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'

export default function AutoCompleteVinculoGRRF(props) {
  const { label, estabelecimentoId, anoMes, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get(
        `VinculoGRRF/GetVinculoGRRF?estabelecimentoId=${estabelecimentoId}&anoMes=${anoMes}`,
      )
      const currentResponse = response?.data?.data
      if (!Array.isArray(currentResponse)) return []
      if (!(currentResponse.length > 0)) return []

      const newArray = []

      currentResponse.forEach((d) => {
        d.rpId.forEach((rp) => {
          if (rp.idf === ReciboIDFEnum.RescisaoComplementar_6) {
            rp.nomeIdfRP = 'Rescisão Complementar'
            newArray.push({
              ...d,
              rpId: rp,
            })
            return
          }
          rp.nomeIdfRP = 'Rescisão Normal'
          newArray.push({
            ...d,
            rpId: rp,
          })
        })
      })

      const arraySorted = newArray.sort((a, b) =>
        a.rpId.nomeIdfRP.length < b.rpId.nomeIdfRP.length ? -1 : 1,
      )

      setOptions(arraySorted)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => `${option?.rpId?.nomeIdfRP || ''} - ${option?.nome || ''}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label={label}
      {...rest}
    />
  )
}

AutoCompleteVinculoGRRF.defaultProps = {
  label: 'Funcionário',
}
