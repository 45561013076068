export enum ASOOrdemExameEnum {
  NaoInformar_0 = 0,
  Inicial_1 = 1,
  Sequencial_2 = 2,
}

export const ASOOrdemExameLabels = {
  [ASOOrdemExameEnum.NaoInformar_0]: 'Não Informar',
  [ASOOrdemExameEnum.Inicial_1]: 'Inicial',
  [ASOOrdemExameEnum.Sequencial_2]: 'Sequencial',
}

export const ASOOrdemExameValues = [
  { value: ASOOrdemExameEnum.NaoInformar_0, name: 'Não Informar' },
  { value: ASOOrdemExameEnum.Inicial_1, name: 'Inicial' },
  { value: ASOOrdemExameEnum.Sequencial_2, name: 'Sequencial' },
]
