import React, { useState, useEffect } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'

import {
  NumeroInscricaoTextField,
  MaskedTextField,
  UFSelect,
  TelefoneTextField,
  ActionDialog,
} from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import apiPessoal from '~/services/api-pessoal'

import * as yup from 'yup'
import { indOrgaoClasseConsts, indOrgaoClasseValues } from '~/values/indOrgaoClasseValues'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import { MUIAutoComplete } from '~/components/AutoComplete'
import { isCPF } from 'brazilian-values'

const { outros_9 } = indOrgaoClasseConsts
const { CPF_2 } = tipoInscricaoConsts

const schema = yup.object().shape({
  nome: yup.string().required('Informe um nome'),
  cpf: yup
    .string()
    .test('valid-cpf', 'Informe um CPF válido', (cpf) => (cpf ? isCPF(cpf) : true))
    .nullable(),
  orgaoClasse: yup.string().required('Informe o Orgão de Classe'),
  telefone: yup
    .string()
    .test('valid-telefone', 'Por favor inserir no mínimo 10 caracteres para Telefone', (value) => {
      if (!value) return true
      if (value.length > 0 && value.length < 10) return false
      return true
    })
    .nullable(),
  descricaoOrgaoClasse: yup
    .string()
    .nullable()
    .when(['orgaoClasse'], (orgaoClasse, schema) =>
      parseInt(orgaoClasse) === outros_9
        ? schema.required('Informe a Descrição Orgão de Classe')
        : schema,
    ),
})

const CadastroMedico = (props) => {
  const { isOpen, onClose, data: _data, onFormSubmit } = props
  const [data, setData] = useState({})

  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    if (!isOpen) return
    setData(_data)
    // eslint-disable-next-line
  }, [_data])

  const handleClick = () => {
    if (data.id) {
      update()
      return
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleClick,
    data,
  })

  const update = async () => {
    setSubmitting(true)
    try {
      if (!data?.telefone) {
        data.telefone = null
      }
      const response = await apiPessoal.put(`/medico/${data.id}`, data)
      notification.put()
      onFormSubmit('onAtualizar', response.data.data)
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setSubmitting(false)
  }

  const insert = async () => {
    setSubmitting(true)
    try {
      if (!data?.telefone) {
        data.telefone = null
      }
      const response = await apiPessoal.post(`/medico`, data)
      notification.post()
      onFormSubmit('onAdicionar', response.data.data)
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setSubmitting(false)
  }

  return (
    <ActionDialog
      title="Cadastro de Médico/Responsável"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            label="Código"
            fullWidth
            value={data?.codigo || ''}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const codigo = e.target.value
              setData({ ...data, codigo })
            }}
            disabled
          />
        </Grid>

        <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            required
            autoFocus
            value={data?.nome || ''}
            variant="outlined"
            size="small"
            validationErrors={validationErrors}
            name="nome"
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <NumeroInscricaoTextField
            label="CPF"
            validationErrors={validationErrors}
            typeMask={CPF_2}
            name="cpf"
            value={data?.cpf || ''}
            onChange={(e, value) => {
              const cpf = value
              setData({ ...data, cpf })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MaskedTextField
            mask="999.99999.99-9"
            label="NIS"
            fullWidth
            value={data?.nis || ''}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const nis = e?.target?.value?.replace(/\D/g, '') || ''
              setData({ ...data, nis })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <UFSelect
            label="UF Orgão Classe"
            value={data?.ufOrgaoClasse || ''}
            onChange={(e, obj) => {
              const ufOrgaoClasse = obj?.sigla || ''
              setData({ ...data, ufOrgaoClasse })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <TelefoneTextField
            value={data?.telefone || ''}
            validationErrors={validationErrors}
            name="telefone"
            onChange={(e, value) => {
              let telefone = value
              setData({ ...data, telefone })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Orgão de Classe"
            required
            validationErrors={validationErrors}
            name="orgaoClasse"
            options={indOrgaoClasseValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.orgaoClasse}
            onChange={(e, obj) => {
              const orgaoClasse = obj ? obj.value : ''
              setData({ ...data, orgaoClasse })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Número Orgão de Classe"
            fullWidth
            value={data?.numeroOrgaoClasse || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 14,
            }}
            onChange={(e) => {
              const numeroOrgaoClasse = e.target.value
              setData({ ...data, numeroOrgaoClasse })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Descrição Orgão de Classe"
            fullWidth
            value={data?.descricaoOrgaoClasse || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 20,
            }}
            required={data?.orgaoClasse === outros_9 ? true : false}
            validationErrors={validationErrors}
            name="descricaoOrgaoClasse"
            onChange={(e) => {
              const descricaoOrgaoClasse = e?.target?.value || ''
              setData((oldState) => ({ ...oldState, descricaoOrgaoClasse }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default CadastroMedico
