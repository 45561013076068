import { useQuery } from 'react-query'

import api from '~/services/api-log'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ConsultaPessoalDetalhadoResponse } from './@types/ConsultaPessoalDetalhadoResponse'

const query = '/Consulta/Pessoal/Detalhado/'

interface RequestProps {
  params: {
    id: string
  }
}

export function useConsultaPessoalDetalhado({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ConsultaPessoalDetalhadoResponse }>(query + params.id)
    return response.data.data
  }

  return useQuery([query, params.id], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
