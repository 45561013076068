import React, { useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, Finder, ButtonBox } from 'mio-library-ui'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

const HEADER_HEIGHT = '50px'

const Empregadores = (props) => {
  const { data: evento } = props

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()

  const getCollection = useCallback(async () => {
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get('/EventoEmpregador/GetByEventoEmpregador', {
        params: {
          eventoId: evento.id,
        },
      })
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
    // eslint-disable-next-line
  }, [evento])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={collection.itens} isLoading={collection.isLoading} query={query} />
      </Box>
    </>
  )
}

export default Empregadores
