import { Box, Grid } from '@material-ui/core'

import { PageHeader, IconCard } from '~/components'
import { IconCardProps } from '../../components/IconCard'

export default function AuxiliaresCadastro() {
  const data: IconCardProps[] = [
    {
      title: 'Contador',
      icon: 'assignment_ind',
      link: '/contador',
    },
    {
      title: 'Serviço',
      icon: 'work',
      link: '/servico',
    },
    {
      title: 'Feriado',
      icon: 'beach_access',
      link: '/feriado',
    },
    {
      title: 'Agente Integração',
      icon: 'contacts',
      link: '/agente-integracao',
    },
    {
      title: 'Instituição de Ensino',
      icon: 'school',
      link: '/instituicao-ensino',
    },
    {
      title: 'Titulação',
      icon: 'list_alt',
      link: '/titulacao',
    },
    {
      title: 'Médico/Responsável',
      icon: 'local_hospital',
      link: '/medico-responsavel',
    },
    {
      title: 'Agência Bancária',
      icon: 'account_balance',
      link: '/agencia-bancaria',
    },
    {
      title: 'Processos',
      icon: 'format_list_bulleted',
      link: '/processo',
    },
    {
      title: 'Certificado Digital',
      icon: 'assignment',
      link: '/certificado-digital',
    },
  ]

  return (
    <Box height="100%" width="100%" padding={2}>
      <PageHeader title="Auxiliares Cadastro" />
      <Grid container spacing={2}>
        {data.map(({ title, icon, link }) => (
          <Grid key={link} item xs={12} sm={6} md={4} lg={3}>
            <IconCard title={title} icon={icon} link={link} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
