import { MUIAutoComplete } from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import useObterRemessas from '~/hooks/queries/CNAB/useObterRemessas'

import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'

import { CNABRemessaDTO } from '~/hooks/queries/CNAB/dtos/CNABRemessaDTO'

interface AutoCompleteCNABRemessasProps extends MUIAutoCompleteProps {
  value: string | null
  onChange: (value: CNABRemessaDTO | null) => void
}

export default function AutoCompleteCNABRemessas({
  value,
  onChange,
  ...rest
}: AutoCompleteCNABRemessasProps) {
  const { anoMes, estabelecimento } = useAmbiente()

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
    refetch,
  } = useObterRemessas({
    params: {
      estabelecimentoId: estabelecimento.id,
      anoMes,
    },
  })
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: CNABRemessaDTO) => option.nrRemessa

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Remessas"
      value={value}
      onChange={(_, obj: CNABRemessaDTO | null) => onChange(obj)}
      optionId="nrRemessa"
      {...rest}
    />
  )
}
