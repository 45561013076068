import { useMutation, useQueryClient } from 'react-query'
import api from '~/services/api-pessoal'

import { Terceiros } from './Terceiros'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryListarTerceiros } from './useListarTerceiros'

export default function useCriarTerceiros() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest(data: Terceiros) {
    await api.post('/Terceiros', data)
  }
  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
    onSuccess: async () => {
      notification.post()
      await queryClient.invalidateQueries(queryListarTerceiros)
    },
  })
}
