import React, { useEffect, useState } from 'react'
import {
  DialogActions,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Button, ButtonBox } from 'mio-library-ui'
import ActionDialog from '../ActionDialog'
import useKeyPress from '~/hooks/useKeyPress'

import TextField from '../TextField'
import useValidationErrors from '~/hooks/useValidationErrors'
import * as yup from 'yup'

const schema = yup.object().shape({
  codigo: yup
    .string()
    .required('Digite CONFIRMAR para excluir o registro')
    .test(
      'test',
      'CONFIRMAR não está escrito corretamente',
      (codigo) => codigo?.toLowerCase() === 'confirmar',
    )
    .nullable(),
})

const ConfirmSaveAlert = (props) => {
  const { data, isOpen, onConfirm, onCancel, isLoading } = props
  const [message, setMessage] = useState({})

  useEffect(() => {
    if (!isOpen) return
    setMessage({
      codigo: '',
    })
  }, [isOpen])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: onConfirm,
    data: message,
  })

  useKeyPress('Enter', handleValidate)

  return (
    <ActionDialog
      title="Confirme as alterações"
      isOpen={isOpen}
      onClose={onCancel}
      customActions={
        <DialogActions>
          <ButtonBox>
            <Button variant="outlined" size="small" onClick={onCancel}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleValidate}
              isLoading={isLoading}
            >
              Confirmar
            </Button>
          </ButtonBox>
        </DialogActions>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Confira as informações abaixo para continuar</Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell align="right">Valores Antigos</TableCell>
                      <TableCell align="right">Valores Novos</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Valor Salário Base I</TableCell>
                      <TableCell align="right">{data?.oldVrSalarios?.vrSalarioBase}</TableCell>
                      <TableCell align="right">{data?.newVrSalarios?.vrSalarioBase}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Valor Salário Base II</TableCell>
                      <TableCell align="right">{data?.oldVrSalarios?.vrSalarioBaseII}</TableCell>
                      <TableCell align="right">{data?.newVrSalarios?.vrSalarioBaseII}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Valor Salário Base III</TableCell>
                      <TableCell align="right">{data?.oldVrSalarios?.vrSalarioBaseIII}</TableCell>
                      <TableCell align="right">{data?.newVrSalarios?.vrSalarioBaseIII}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Valor Salário Base IV</TableCell>
                      <TableCell align="right">{data?.oldVrSalarios?.vrSalarioBaseIV}</TableCell>
                      <TableCell align="right">{data?.newVrSalarios?.vrSalarioBaseIV}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Digite <strong>CONFIRMAR</strong> para salvar o registro
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="codigo"
            variant="outlined"
            placeholder="CONFIRMAR"
            size="small"
            value={message?.codigo || ''}
            onPaste={(e) => e.preventDefault()}
            fullWidth
            multiline
            onChange={(e) => {
              const codigo = e.target.value || ''
              setMessage({ codigo })
            }}
            validationErrors={validationErrors}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default ConfirmSaveAlert
