import { useState, useEffect, useCallback } from 'react'

import { getDateMinAnoMes } from '~/utils/utils'

import { Box, CircularProgress, Grid } from '@material-ui/core'

import { ContentDivider } from 'mio-library-ui'
import { NumeroInscricaoTextField, DatePicker, ActionDialog } from '~/components'
import { AutoCompleteFPAS, MUIAutoComplete, AutoCompleteTerceiros } from '~/components/AutoComplete'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import moment from 'moment'
import api from '~/services/api-pessoal'
import {
  tipoInscricaoConsts,
  tipoInscricaoValues as _tipoInscricaoValues,
} from '~/values/tipoInscricaoValues'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import * as yup from 'yup'
import { tipoLotacaoValues, tipoLotacaoConsts } from '~/values/tipoLotacaoValues'
import { codigoGpsValues } from '~/values/codigoGpsValues'

import useVerificarExisteProcessosEmpregador from '~/hooks/queries/LotacaoSituacao/useVerificarExisteProcessosEmpregador'
import useAmbiente from '~/hooks/useAmbiente'

const { naoInformar_0, CNPJ_1, CNO_4, CPF_2 } = tipoInscricaoConsts
const { obra_02, contratanteTrabalhadorAvulso_09 } = tipoLotacaoConsts

const tipoInscricaoInUse = [naoInformar_0, CNPJ_1, CPF_2, CNO_4]
const tipoInscricaoInUseWithoutCNO = [naoInformar_0, CNPJ_1, CPF_2]

const tipoInscricaoValues = _tipoInscricaoValues.filter((obj) =>
  tipoInscricaoInUse.includes(obj.value),
)

const tipoInscricaoValuesWithoutCNO = _tipoInscricaoValues.filter((obj) =>
  tipoInscricaoInUseWithoutCNO.includes(obj.value),
)

function validNrInscricao(tipoInscricao, nrInscricao) {
  if (parseInt(tipoInscricao) === CNPJ_1) return cnpj.isValid(nrInscricao)
  if (parseInt(tipoInscricao) === CPF_2) return cpf.isValid(nrInscricao)
  if (parseInt(tipoInscricao) === CNO_4) return nrInscricao?.length === 12
  return true
}

const schema = yup.object().shape({
  dtSituacao: yup
    .string()
    .required('Informe a Data da Situação Tributária')
    .when(['$anoMes', '$isUpdate'], (anoMes, isUpdate, schema, { value: dtSituacao }) => {
      if (isUpdate) return schema

      const dtSituacaoFormatted = moment(dtSituacao).format('YYYYMM')

      if (!moment(dtSituacaoFormatted).isSame(anoMes)) {
        return schema.min(
          dtSituacao,
          'A Data da Situação Tributária deve estar dentro da Competência Atual',
        )
      }

      return schema
    })
    .nullable(),
  tipoLotacao: yup.string().required('Informe o Tipo Lotação'),
  tipoInscricao: yup
    .string()
    .required('Informe o Tipo de Inscrição')
    .when(['nrInscricao', 'tipoLotacao'], (nrInscricao, tipoLotacao, schema) => {
      return schema.test(
        'valid-tipoInscricao',
        'Informe um Tipo de Inscrição válido',
        (tipoInscricao) => {
          if (
            (parseInt(tipoLotacao) >= obra_02 &&
              parseInt(tipoLotacao) <= contratanteTrabalhadorAvulso_09) ||
            nrInscricao
          ) {
            return parseInt(tipoInscricao) !== naoInformar_0
          }
          return true
        },
      )
    }),
  nrInscricao: yup.string().when(['$tipoInscricao'], (tipoInscricao, schema) => {
    return schema
      .test('valid-tipoInscricao', 'Informe um Número de Inscrição válido', (nrInscricao) => {
        if (parseInt(tipoInscricao) !== naoInformar_0) {
          return validNrInscricao(tipoInscricao, nrInscricao)
        }
        return true
      })
      .nullable()
  }),
  tipoInscricaoContratante: yup
    .string()
    .required('Informe o Tipo de Inscrição Contratante')
    .when(
      ['nrInscricaoContratante', 'tipoLotacao'],
      (nrInscricaoContratante, tipoLotacao, schema) => {
        return schema.test(
          'valid-tipoInscricao',
          'Informe um Tipo de Inscrição válido',
          (tipoInscricaoContratante) => {
            if (parseInt(tipoLotacao) === obra_02 || nrInscricaoContratante) {
              return parseInt(tipoInscricaoContratante) !== naoInformar_0
            }
            return true
          },
        )
      },
    ),
  nrInscricaoContratante: yup
    .string()
    .when(['$tipoInscricaoContratante'], (tipoInscricaoContratante, schema) => {
      return schema
        .test(
          'valid-tipoInscricao',
          'Informe um Número de Inscrição válido',
          (nrInscricaoContratante) => {
            if (tipoInscricaoContratante !== naoInformar_0) {
              return validNrInscricao(tipoInscricaoContratante, nrInscricaoContratante)
            }
            return true
          },
        )
        .nullable()
    }),
  tipoInscricaoProprietario: yup
    .string()
    .required('Informe o Tipo de Inscrição Proprietário')
    .when(
      ['nrInscricaoProprietario', 'tipoLotacao'],
      (nrInscricaoProprietario, tipoLotacao, schema) => {
        return schema.test(
          'valid-tipoInscricao',
          'Informe um Tipo de Inscrição válido',
          (tipoInscricaoProprietario) => {
            if (parseInt(tipoLotacao) === obra_02 || nrInscricaoProprietario) {
              return parseInt(tipoInscricaoProprietario) !== naoInformar_0
            }
            return true
          },
        )
      },
    ),
  nrInscricaoProprietario: yup
    .string()
    .when(['$tipoInscricaoProprietario'], (tipoInscricaoProprietario, schema) => {
      return schema
        .test(
          'valid-tipoInscricao',
          'Informe um Número de Inscrição válido',
          (nrInscricaoProprietario) => {
            if (tipoInscricaoProprietario !== naoInformar_0) {
              return validNrInscricao(tipoInscricaoProprietario, nrInscricaoProprietario)
            }
            return true
          },
        )
        .nullable()
    }),
  fpasId: yup.string().required('Informe o FPAS'),
  terceirosId: yup.string().required('Informe o Código de Terceiros').nullable(),
})

const Form = (props) => {
  const { isOpen, anoMes, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { empregador } = useAmbiente()

  const {
    data: _haveProcessos,
    isLoading: _isLoading,
    isFetching: _isFetching,
    isError,
  } = useVerificarExisteProcessosEmpregador(empregador.id)
  const disabledTerceirosSuspensoId = isError ? true : !_haveProcessos
  const isLoadingVerificarProcessos = _isLoading || _isFetching

  useEffect(() => {
    if (_data?.id) {
      setData(_data)
    } else {
      setData({ ..._data, dtSituacao: getDateMinAnoMes(anoMes) })
    }
  }, [_data, anoMes])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const dtMovimentoESocial = moment(anoMes).format('yyyy-MM-DD')
        const response = await api.put(`/LotacaoSituacao/${data.id}`, {
          ...data,
          dtMovimentoESocial,
        })
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/LotacaoSituacao', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm, anoMes])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: {
        tipoInscricao: data?.tipoInscricao,
        tipoInscricaoContratante: data?.tipoInscricaoContratante,
        tipoInscricaoProprietario: data?.tipoInscricaoProprietario,
        anoMes,
        isUpdate: data?.id ? true : false,
      },
    },
  })

  return (
    <ActionDialog
      title="Cadastro de Situação Tributária"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={5} lg={5} md={5} sm={12} xs={12} item>
          <DatePicker
            label="Data da Situação Tributária"
            value={data?.dtSituacao || null}
            disabled
            // required={!data?.id ? true : false}
            // disabled={data?.id ? true : false}
            size="small"
            validationErrors={validationErrors}
            name="dtSituacao"
            onChange={(date) => {
              const dtSituacao = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtSituacao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Tipo Lotação"
            options={tipoLotacaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            name="tipoLotacao"
            validationErrors={validationErrors}
            value={data.tipoLotacao}
            onChange={(e, obj) => {
              const tipoLotacao = obj ? obj.value : ''
              setData({ ...data, tipoLotacao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Código de GPS"
            options={codigoGpsValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data?.gps}
            onChange={(e, obj) => {
              const gps = obj ? obj.value : ''
              setData({ ...data, gps })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Número de Inscrição da Lotação" />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <MUIAutoComplete
            label="Tipo de Inscrição"
            options={tipoInscricaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.tipoInscricao}
            required
            name="tipoInscricao"
            validationErrors={validationErrors}
            onChange={(e, obj) => {
              const tipoInscricao = obj?.value
              setData({
                ...data,
                tipoInscricao,
              })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <NumeroInscricaoTextField
            size="small"
            variant="outlined"
            fullWidth
            label="Número de Inscrição"
            value={data?.nrInscricao || ''}
            typeMask={data?.tipoInscricao}
            name="nrInscricao"
            required={data?.tipoInscricao !== naoInformar_0 ? true : false}
            validationErrors={validationErrors}
            onChange={(e, value) => {
              const nrInscricao = value
              setData({
                ...data,
                nrInscricao,
              })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Número de Inscrição Obra com Empreitada Parcial" />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <MUIAutoComplete
            label="Tipo de Inscrição Contratante"
            options={tipoInscricaoValuesWithoutCNO}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.tipoInscricaoContratante}
            required
            name="tipoInscricaoContratante"
            validationErrors={validationErrors}
            onChange={(e, obj) => {
              const tipoInscricaoContratante = obj?.value
              setData({
                ...data,
                tipoInscricaoContratante,
              })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <NumeroInscricaoTextField
            label="Número de Inscrição Contratante"
            fullWidth
            value={data?.nrInscricaoContratante || ''}
            variant="outlined"
            size="small"
            name="nrInscricaoContratante"
            required={data?.tipoInscricaoContratante !== naoInformar_0 ? true : false}
            validationErrors={validationErrors}
            typeMask={data?.tipoInscricaoContratante}
            onChange={(e, value) => {
              const nrInscricaoContratante = value || null
              setData((oldState) => ({
                ...oldState,
                nrInscricaoContratante,
              }))
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <MUIAutoComplete
            label="Tipo de Inscrição Proprietário"
            options={tipoInscricaoValuesWithoutCNO}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.tipoInscricaoProprietario}
            required
            name="tipoInscricaoProprietario"
            validationErrors={validationErrors}
            onChange={(e, obj) => {
              const tipoInscricaoProprietario = obj?.value
              setData({
                ...data,
                tipoInscricaoProprietario,
              })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <NumeroInscricaoTextField
            label="Número de Inscrição Proprietário"
            fullWidth
            value={data?.nrInscricaoProprietario || ''}
            variant="outlined"
            size="small"
            name="nrInscricaoProprietario"
            typeMask={data?.tipoInscricaoProprietario}
            required={data?.tipoInscricaoProprietario !== naoInformar_0 ? true : false}
            validationErrors={validationErrors}
            onChange={(e, value) => {
              const nrInscricaoProprietario = value || null
              setData((oldState) => ({
                ...oldState,
                nrInscricaoProprietario,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Tributação" />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <AutoCompleteFPAS
            value={data?.fpasId || ''}
            required
            validationErrors={validationErrors}
            name="fpasId"
            optionId="id"
            onChange={(e, fpas) => {
              const fpasId = fpas?.id || ''
              setData((oldState) => ({
                ...oldState,
                fpasId,
                terceirosId: '',
                terceirosSuspensoId: '',
              }))
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <AutoCompleteTerceiros
            label="Código de Terceiros"
            value={data?.terceirosId || ''}
            required
            validationErrors={validationErrors}
            name="terceirosId"
            onChange={(e, terceiros) => {
              const terceirosId = terceiros?.codigo || ''
              setData((oldState) => ({
                ...oldState,
                terceirosId,
              }))
            }}
            filterByFPAS={data?.fpasId}
            disabled={data?.fpasId ? false : true}
            optionId="codigo"
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          {isLoadingVerificarProcessos ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={25} />
            </Box>
          ) : (
            <AutoCompleteTerceiros
              label="Código de Terceiros com Recolhimento Suspenso"
              value={data?.terceirosSuspensoId || null}
              onChange={(e, terceiros) => {
                const terceirosSuspensoId = terceiros?.codigo || null
                setData((oldState) => ({
                  ...oldState,
                  terceirosSuspensoId,
                }))
              }}
              filterByFPAS={data?.fpasId}
              disabled={!data?.fpasId || disabledTerceirosSuspensoId ? true : false}
              optionId="codigo"
              title={
                !_haveProcessos
                  ? 'Não tem processos cadastrados para usar o Código de Terceiros com Recolhimento Suspenso'
                  : ''
              }
            />
          )}
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
