import { ToolsDataTable, ButtonBox } from '~/components'

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  MonetizationOn as MonetizationOnIcon,
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { PlanoSaudeTitular } from '~/hooks/queries/PlanoSaudeTitular/dtos/PlanoSaudeTitular'
import { formatPrettyAnoMes } from '~/hooks/useUtils'
import { formatCurrency } from '~/utils/utils'

interface TableProps {
  data: PlanoSaudeTitular[]
  isLoading: boolean
  isFetching: boolean
  onItemClick: (event: 'edit' | 'delete' | 'custo', id: string) => void
  query: string
}

export default function Table(props: TableProps) {
  const { data: _d, isLoading, isFetching, onItemClick, query } = props

  const data = _d.map((d) => ({
    ...d,
    planoSaudeNome: `${d?.planoSaude?.codigo || ''} - ${d?.planoSaude?.nome || ''}`,
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'planoSaudeNome',
      label: 'Plano de Saúde',
    },
    {
      name: 'anoMesInicio',
      label: 'Ano/Mês Início',
      options: {
        customBodyRender: (value) => (value ? formatPrettyAnoMes(value) : ''),
      },
    },
    {
      name: 'anoMesFim',
      label: 'Ano/Mês Fim',
      options: {
        customBodyRender: (value) => (value ? formatPrettyAnoMes(value) : ''),
      },
    },
    {
      name: 'vrMensalidade',
      label: 'Valor da Mensalidade',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'end',
          },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Lançamento de custos"
                color="primary"
                aria-label="Custos"
                onClick={() => {
                  onItemClick('custo', value)
                }}
              >
                <MonetizationOnIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['anoMesInicio', 'anoMesFim'],
      }}
    />
  )
}
