import React, { useEffect, useState, useCallback } from 'react'
import {
  makeStyles,
  Box,
  Dialog,
  Slide,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  LinearProgress,
  IconButton,
  Icon,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { CollapseContent } from '~/components'

const useStyles = makeStyles((theme) => ({
  containerTitle: {
    height: '60px',
    backgroundColor: (props) => theme.palette[props.type].main,
    color: (props) => theme.palette[props.type].contrastText,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '2px 2px #D0D0D0',
  },
  title: {
    marginLeft: theme.spacing(1),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  containerIconClose: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
  },
  iconClose: {
    color: (props) => theme.palette[props.type].contrastText,
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflow: 'auto',
  },
  containerDescription: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  containerIcon: {
    display: 'flex',
    width: '20%',
  },
  icon: {
    color: (props) => theme.palette[props.type].main,
    fontSize: '5rem',
    marginLeft: 'auto',
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  contentDescription: {
    maxHeight: '10rem',
    overflow: 'auto',
  },
  contentDetails: {
    paddingTop: theme.spacing(1),
    '&:first-child': {
      paddingTop: theme.spacing(1),
    },
  },
  containerProgressbar: {
    marginTop: theme.spacing(1),
  },
  progressColor: {
    backgroundColor: (props) => theme.palette[props.type].lighter,
  },
  progressBarColor: {
    backgroundColor: (props) => theme.palette[props.type].main,
  },
  buttonAgree: {
    backgroundColor: (props) => theme.palette[props.type].main,
    color: (props) => theme.palette[props.type].contrastText,
    '&:hover': {
      backgroundColor: (props) => theme.palette[props.type].light,
    },
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AlertDialog = (props) => {
  const { options } = props

  const [open, setOpen] = useState(true)
  const [myInterval, setMyInterval] = useState({})
  const [progressValue, setProgressValue] = useState(100)
  const [noClose, setNoClose] = useState(false)

  const classes = useStyles({ type: options.type })

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    const changeProgressValue = () => {
      if (options.time === 0) return
      const interval = setInterval(() => {
        setProgressValue((oldProgressValue) => oldProgressValue - 1)
      }, options.time * 0.01)
      setMyInterval(interval)
    }
    changeProgressValue()
  }, [options.time])

  useEffect(() => {
    const stopProgressBar = () => {
      if (progressValue === 0) {
        clearInterval(myInterval)
        setTimeout(() => {
          if (!noClose) handleClose()
        }, 500)
      }
    }
    stopProgressBar()
  }, [progressValue, handleClose, myInterval, noClose])

  const changeIcon = (type) => {
    const maps = {
      error: 'error_outline',
      warning: 'warning',
      info: 'info',
      success: 'done',
    }
    return maps[type]
  }

  const changeOnConfirm = () => {
    if (options?.onConfirm) {
      const { onConfirm } = options
      onConfirm()
    }
    handleClose()
  }

  const changeOnCancel = () => {
    if (options?.onCancel) {
      const { onCancel } = options
      onCancel()
    }
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClick={() => setNoClose(true)}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <Box className={classes.containerTitle}>
        <Box className={classes.title}>
          <Typography variant="h6">{options.title}</Typography>
        </Box>
        <Box className={classes.containerIconClose}>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon size="small" className={classes.iconClose} />
          </IconButton>
        </Box>
      </Box>

      <Box className={classes.content}>
        <Box className={classes.containerDescription}>
          <Box className={classes.containerIcon}>
            <Icon className={classes.icon}>{changeIcon(options.type)}</Icon>
          </Box>

          <DialogContent className={classes.contentDescription}>
            {options.descriptions.map((descriptionMessage, index) => (
              <DialogContentText id="alert-dialog-slide-description" key={index}>
                {descriptionMessage}
              </DialogContentText>
            ))}
          </DialogContent>
        </Box>

        {options.details.length > 0 && (
          <CollapseContent title="Detalhes" showContentFrame={false}>
            <DialogContent className={classes.contentDetails}>
              {options.details.map((detailsMessage, index) => (
                <DialogContentText id="alert-dialog-slide-details" key={index}>
                  {detailsMessage}
                </DialogContentText>
              ))}
            </DialogContent>
          </CollapseContent>
        )}
      </Box>

      {options.time !== 0 && (
        <LinearProgress
          classes={{
            colorPrimary: classes.progressColor,
            barColorPrimary: classes.progressBarColor,
          }}
          className={classes.containerProgressbar}
          variant="determinate"
          value={progressValue}
        />
      )}

      <DialogActions>
        {options?.onConfirm && (
          <Button onClick={changeOnCancel} className={classes.buttonAgree}>
            {options?.labelOnCancel}
          </Button>
        )}

        <Button onClick={changeOnConfirm} className={classes.buttonAgree}>
          {options?.labelOnConfirm}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
