import { useState, useEffect } from 'react'

import { AppBar, Toolbar, Box, makeStyles } from '@material-ui/core'

import SelecaoAmbiente from './components/SelecaoAmbiente'
import DrawerMenu from './components/DrawerMenu'
import UserNotification from './components/UserNotification'

import AlterarSenha from './components/AlterarSenha'
import { ActionMenu } from '~/components'

import clsx from 'clsx'

import MenuContext from '~/contexts/menu-context'
import { Creators as AppActions } from '~/store/ducks/app'

import useAmbiente from '~/hooks/useAmbiente'

import { useDispatch, useSelector } from 'react-redux'

import { copyToClipboard } from '~/hooks/useUtils'

import UserMenu from './components/UserMenu'
import { DRAWER_WIDTH_OPEN } from '~/@types/consts/drawer_size'
import { TOOL_BAR_HEIGHT } from '~/@types/consts/tool_bar_size'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingTop: TOOL_BAR_HEIGHT,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: TOOL_BAR_HEIGHT,
    },
    background: 'rgba(26, 33, 61, 1)',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  drawer: {
    width: DRAWER_WIDTH_OPEN,
    flexShrink: 0,
    backgroundColor: theme.palette.background.default,
  },
  drawerPaper: {
    overflow: 'hidden',
    flexShrink: 0,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH_OPEN,
    backgroundColor: '#222D32',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  slide: {
    width: DRAWER_WIDTH_OPEN,
  },
  fixedHeight: {
    height: DRAWER_WIDTH_OPEN,
  },
  boxTitle: {
    width: DRAWER_WIDTH_OPEN - 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  boxSelecaoAmbiente: {
    flex: 1,
    display: 'flex',
    gap: theme.spacing(1),
  },
}))

export default function Layout(props) {
  const { children, topBar } = props

  const [showChangePass, setShowChangePass] = useState(false)
  const [leftMenuOpen, setLeftMenuOpen] = useState(true)

  const dispatch = useDispatch()
  const classes = useStyles({ leftMenuOpen })
  const historicalLeftMenuOpen = useSelector((state) => state.app.leftMenuOpen)
  const { empregador } = useAmbiente(false)

  useEffect(() => {
    setLeftMenuOpen(historicalLeftMenuOpen)
  }, [historicalLeftMenuOpen])

  const handleToggleMenu = () => {
    dispatch(AppActions.setLeftMenuOpen(!leftMenuOpen))
  }

  function handleAlterarSenha() {
    setShowChangePass(!showChangePass)
  }

  return (
    <Box
      className={clsx({
        [classes.root]: true,
      })}
    >
      <AlterarSenha isOpen={showChangePass} onClose={handleAlterarSenha} />
      <AppBar position="fixed" color="default" className={clsx(classes.appBar)} elevation={0}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Box
            className={classes.appBarContent}
            style={{
              marginLeft: leftMenuOpen === true ? DRAWER_WIDTH_OPEN - 16 : '41px',
              transition: 'ease-in-out 225ms',
            }}
          >
            <Box className={classes.boxSelecaoAmbiente}>
              <SelecaoAmbiente />
              <ActionMenu
                options={[
                  {
                    label: 'Copiar CNPJ',
                    icon: 'file_copy',
                    onClick: () => copyToClipboard(empregador.nrInscricao),
                  },
                ]}
              />
            </Box>
            <UserNotification />
            <UserMenu handleChangePassword={handleAlterarSenha} />
          </Box>
        </Toolbar>
      </AppBar>
      <DrawerMenu open={leftMenuOpen} onToggleMenu={handleToggleMenu} />

      <Box className={classes.content}>
        {topBar !== undefined && topBar(props)}
        <Box className={classes.container}>
          <MenuContext.Provider value={leftMenuOpen}>{children}</MenuContext.Provider>
        </Box>
      </Box>
    </Box>
  )
}
