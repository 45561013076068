import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
} from '@material-ui/core'

import { ContentContainer, PageHeader, Finder, ButtonBox } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import Table from './components/Table'
import apiPessoal from '~/services/api-pessoal'
import { AutoCompleteEstabelecimento, AutoCompleteVinculo } from '~/components/AutoComplete'
import { TextField } from '@material-ui/core'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const ReciboPagamento = () => {
  const history = useHistory()
  const [items, setItems] = useState([])
  const [current, setCurrent] = useState({})
  const [isNew, setIsNew] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [filter, setFilter] = useState({
    findBy: 'estabelecimento',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')

  const ambienteSelecionado = useAmbiente()
  const { anoMes } = ambienteSelecionado

  const notifications = useNotification()
  const dialogNotification = useDialogNotification()

  const pageTitle = 'Recibo de Pagamento'
  const apiRoute = '/rp'
  const pageRoute = '/recibo-pagamento'

  const updateItems = useCallback(async () => {
    setIsLoading(true)
    try {
      // const response = await apiPessoal.get(apiRoute);
      let findRoute = 'rpConsulta'

      if (filter.findBy === 'estabelecimento' && ambienteSelecionado.estabelecimento) {
        findRoute = `rpConsulta/getbyestabelecimento?id=${ambienteSelecionado.estabelecimento.id}&anoMes=${anoMes}`
      } else if (filter.findBy === 'empregador' && ambienteSelecionado.empregador) {
        findRoute = `rpConsulta/getbyempregador?id=${ambienteSelecionado.empregador.id}&anoMes=${anoMes}`
      }

      const response = await apiPessoal.get(findRoute)

      if (response.data.data) {
        setItems(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [anoMes, ambienteSelecionado.empregador, ambienteSelecionado.estabelecimento, filter.findBy])

  useEffect(() => {
    updateItems()
  }, [updateItems])

  const handleStartNew = () => {
    setIsNew(true)

    setCurrent({
      ...current,
    })

    if (ambienteSelecionado.estabelecimento) {
      setCurrent({
        ...current,
        estabelecimento: ambienteSelecionado.estabelecimento,
        estabelecimentoId: ambienteSelecionado.estabelecimento.id,
        idf: '1',
        anoMes,
      })
    }
  }

  const handleCancelNew = () => {
    setIsNew(false)

    setCurrent({})
  }

  const handleNew = async () => {
    setIsProcessing(true)

    if (!current.estabelecimentoId) {
      notifications.warning('Selecione o Estabelecimento do ' + pageTitle, 'Ops!', 4000)
      setIsProcessing(false)
      return
    }

    if (!current.vinculoId) {
      notifications.warning('Selecione a Pessoa do ' + pageTitle, 'Ops!', 4000)
      setIsProcessing(false)
      return
    }

    delete current.estabelecimento
    delete current.vinculo

    try {
      const response = await apiPessoal.post(
        `${apiRoute}/novo?vinculoId=${current.vinculoId}&anoMes=${current.anoMes}&idf=${current.idf}`,
        // JSON.stringify(current),
      )

      if (response.status === 200) {
        notifications.success('Os dados foram salvos', 'Sucesso!', 4000)
        history.push(`${pageRoute}/${response.data.data.id}`)

        setIsNew(false)
        setCurrent({})
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    // finally {
    //   setIsProcessing(false);
    //   setIsNew(false);
    //   setCurrent({});
    // }
    setIsProcessing(false)
  }

  const handleEdit = async (id) => {
    history.push(`${pageRoute}/${id}`)
  }

  const handleDelete = (id) => {
    setCurrent({ id })

    if (!id) {
      notifications.warning('Selecione um Vale Transporte para excluir!', 'Ops!', 4000)
      return
    }

    setIsOpenDelete(true)
  }

  const deletar = async () => {
    setIsProcessing(true)

    try {
      const response = await apiPessoal.delete(`${apiRoute}/${current.id}`)

      if (response.status === 200) {
        notifications.success('Registro foi removido', 'Sucesso!', 4000)
        updateItems()
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsOpenDelete(false)
    setIsProcessing(false)
    setCurrent({})
  }

  const handleClickItem = (event, data) => {
    const functions = {
      edit: handleEdit,
      delete: handleDelete,
    }
    functions[event](data)
  }

  return (
    <ContentContainer>
      <Box>
        <PageHeader title={pageTitle}>
          <ButtonBox>
            <Finder
              onSearch={(value) => {
                setQuery(value)
              }}
              searching={isLoading}
              onClose={() => {
                setQuery('')
              }}
            />
            <Button size="small" color="primary" onClick={handleStartNew}>
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>

        <FormControl component="fieldset">
          <FormLabel component="legend">Filtro</FormLabel>
          <RadioGroup
            row
            aria-label="filter"
            name="filter"
            value={filter.findBy}
            onChange={(e) => {
              setFilter({ ...filter, findBy: e.target.value })
            }}
          >
            <FormControlLabel
              value="estabelecimento"
              control={<Radio size="small" />}
              label="Estabelecimento"
            />
            <FormControlLabel
              value="empregador"
              control={<Radio size="small" />}
              label="Empregador"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box>
        <Table data={items} onItemClick={handleClickItem} isLoading={isLoading} query={query} />
      </Box>

      <ActionDialog
        title={'Cadastro de ' + pageTitle}
        okLabel="Continuar"
        isOpen={isNew}
        onClose={handleCancelNew}
        onOkClick={handleNew}
        onCancelClick={handleCancelNew}
        isOkProcessing={isProcessing}
      >
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <AutoCompleteEstabelecimento
              onChange={(e, estabelecimento) => {
                const estabelecimentoId = estabelecimento ? estabelecimento.id : ''
                setCurrent({ ...current, estabelecimentoId, estabelecimento })
              }}
              value={current.estabelecimento || null}
              disabled={current.vinculo}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <AutoCompleteVinculo
              label="Funcionário"
              vinculosTipo={[VinculoTipoEnum.Funcionario_1]}
              estabelecimentoId={current.estabelecimentoId}
              anoMes={anoMes}
              onChange={(e, vinculo) => {
                const vinculoId = vinculo ? vinculo.id : ''
                setCurrent({ ...current, vinculoId, vinculo })
              }}
              value={current.vinculo || null}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <TextField
              label="IDF"
              fullWidth
              value={current.idf || ''}
              variant="outlined"
              size="small"
              onlyNumber
              onChange={(e) => {
                const idf = e.target.value
                setCurrent({ ...current, idf })
              }}
              select
            >
              <MenuItem value="1">Mensal</MenuItem>
              <MenuItem value="2">Férias</MenuItem>
              <MenuItem value="5">Rescisão</MenuItem>
              <MenuItem value="6">Rescisão Complementar</MenuItem>
              <MenuItem value="7">1ª Parceta Décimo Terceiro</MenuItem>
              <MenuItem value="8">2ª Parceta Décimo Terceiro</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </ActionDialog>

      <ActionDialog
        title="Excluir Registro"
        okLabel="Sim"
        isOpen={isOpenDelete}
        onClose={() => setIsOpenDelete(false)}
        onOkClick={deletar}
        onCancelClick={() => setIsOpenDelete(false)}
        isOkProcessing={isProcessing}
      >
        <Typography>Você quer mesmo excluir este registro</Typography>
      </ActionDialog>
    </ContentContainer>
  )
}

export default ReciboPagamento
