import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

const schema = yup.object().shape({
  certificadoAvaliacao: yup.string().required('Informe o Certificado de Avaliação'),
  descricao: yup.string().required('Informe a Descrição'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/EPI/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/EPI', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Equipamento de Proteção Individual"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            label="Certificado de Avaliação"
            name="certificadoAvaliacao"
            validationErrors={validationErrors}
            required
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            multiline
            value={data?.certificadoAvaliacao || ''}
            onChange={(e) => {
              const certificadoAvaliacao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                certificadoAvaliacao,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            label="Descrição"
            name="descricao"
            validationErrors={validationErrors}
            required
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            multiline
            value={data?.descricao || ''}
            onChange={(e) => {
              const descricao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                descricao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
