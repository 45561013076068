import React, { useState, useCallback, useMemo, useEffect } from 'react'

import api from '~/services/api-pessoal'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import moment from 'moment'

import { Box } from '@material-ui/core'
import { ButtonBox, PageHeader, Finder } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'

const HEADER_HEIGHT = '50px'
const SociosEmpregador = (props) => {
  const { empregadorId } = props

  const [collection, setCollection] = useState({
    isLoading: false,
    items: [],
  })
  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))
      try {
        const response = await api.get(
          `EmpregadorConsulta/GetQuadroSocietario?empregadorId=${empregadorId}`,
        )
        const data = response.data.data.map((d) => {
          const pessoaFisica = d?.pessoaFisica
          return {
            ...d,
            nrInscricao: formatToCPFOrCNPJ(pessoaFisica?.nrInscricao || ''),
            nome: pessoaFisica?.nome,
            isSocioResponsavel: d?.isSocioResponsavel ? 'Sim' : 'Não',
            aliqParticipacaoCapitalSocial: d?.aliqParticipacaoCapitalSocial
              ? `${d.aliqParticipacaoCapitalSocial} %`
              : '',
            dtAdmissao: d?.dtAdmissao ? moment(d.dtAdmissao).format('DD/MM/yyyy') : '',
            dtRescisao: d?.dtRescisao ? moment(d.dtRescisao).format('DD/MM/yyyy') : '',
          }
        })
        setCollection({
          isLoading: false,
          items: data,
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    //eslint-disable-next-line
  }, [empregadorId])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'nrInscricao',
        label: 'CPF',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'isSocioResponsavel',
        label: 'Sócio Responsável',
      },
      {
        name: 'aliqParticipacaoCapitalSocial',
        label: 'Percentual de Participação do Capital',
      },
      {
        name: 'dtAdmissao',
        label: 'Data Admissão',
      },
      {
        name: 'dtRescisao',
        label: 'Data Rescisão',
      },
    ],
    [],
  )

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          </ButtonBox>
        </PageHeader>
      </Box>
      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <HeightDataTable
          title=""
          data={collection.items}
          columns={columns}
          isLoading={collection.isLoading}
          sherlock={{
            query,
            columns: ['nrInscricao', 'nome'],
          }}
        />
      </Box>
    </>
  )
}

export default SociosEmpregador
