import { ReactNode, useContext } from 'react'
import clsx from 'clsx'

import { Box, Theme, makeStyles } from '@material-ui/core'

import menuContext from '~/contexts/menu-context'
import { DRAWER_WIDTH_CLOSE, DRAWER_WIDTH_OPEN } from '~/pages/Layout/components/DrawerMenu'

interface FloatDrawerProps {
  onClick: () => void
  open: boolean
  children: ReactNode
  disabled?: boolean
}

interface StylesProps {
  widthDrawerMenu: string
}

const HEIGHT_BUTTON = '25px'

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: {
    position: 'fixed',
    bottom: '0',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: HEIGHT_BUTTON,
    width: (props) => `calc(100% - ${props.widthDrawerMenu})`,
    zIndex: 999,
    transition: `150ms ease-out`,
    borderTopLeftRadius: theme.shape.borderRadius * 3,
    borderTopRightRadius: theme.shape.borderRadius * 3,
    boxShadow: theme.shadows[10],
    backgroundColor: theme.palette.background.paper,
  },
  buttonClose: {
    cursor: 'pointer',
    height: HEIGHT_BUTTON,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: 'solid 1px ' + theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  buttonMinimize: {
    height: '7px',
    width: '35px',
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
  },
  rootChildren: {
    height: `calc(100% - ${HEIGHT_BUTTON})`,
    width: '100%',
    overflow: 'auto',
  },
  disabled: {
    cursor: 'default',
  },
  open: {
    minHeight: '500px',
    height: '80%',
  },
}))

export default function FloatDrawer(props: FloatDrawerProps) {
  const { onClick, open, children, disabled = false } = props

  const isOpenMenu = useContext(menuContext)

  const widthDrawerMenu = isOpenMenu ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE

  const stylesProps: StylesProps = { widthDrawerMenu }
  const classes = useStyles(stylesProps)

  return (
    <Box className={clsx(classes.root, { [classes.open]: open })}>
      <Box
        className={clsx(classes.buttonClose, { [classes.disabled]: disabled })}
        onClick={disabled ? undefined : onClick}
      >
        <div className={classes.buttonMinimize} />
      </Box>
      <Box className={classes.rootChildren}>{open && children}</Box>
    </Box>
  )
}
