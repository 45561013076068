import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog, Switch } from '~/components'

import { AutoCompleteBancoAgencia, MUIAutoComplete } from '~/components/AutoComplete'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

import {
  indicadorContaBancariaConsts,
  indicadorContaBancariaValues,
} from '~/values/indicadorContaBancariaValues'

const schema = yup.object().shape({
  bancoAgenciaId: yup.string().required('Informe o Banco Agência').nullable(),
  contaCorrente: yup.string().required('Informe a Conta'),
  dvContaCorrente: yup.string().required('Informe o DV'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props

  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        await api.put(`/PessoaFisicaContaCorrente/${data.id}`, data)
        onAfterSubmitForm()
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        await api.post('/PessoaFisicaContaCorrente', data)
        notification.post()
        onAfterSubmitForm()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Conta"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteBancoAgencia
            required
            label="Agência"
            name="bancoAgenciaId"
            validationErrors={validationErrors}
            value={data?.bancoAgencia || null}
            onChange={(e, bancoAgencia) => {
              const bancoAgenciaId = bancoAgencia?.id || null
              setData((oldState) => ({
                ...oldState,
                bancoAgenciaId,
                bancoAgencia,
              }))
            }}
          />
        </Grid>

        <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
          <TextField
            label="Número da Conta"
            fullWidth
            variant="outlined"
            size="small"
            required
            name="contaCorrente"
            validationErrors={validationErrors}
            onlyNumber
            inputProps={{ maxLength: 20 }}
            value={data?.contaCorrente || ''}
            onChange={(e) => {
              const contaCorrente = e.target.value
              setData((oldState) => ({
                ...oldState,
                contaCorrente,
              }))
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            label="DV"
            fullWidth
            variant="outlined"
            size="small"
            required
            name="dvContaCorrente"
            validationErrors={validationErrors}
            onlyNumber
            inputProps={{ maxLength: 1 }}
            value={data?.dvContaCorrente || ''}
            onChange={(e) => {
              const dvContaCorrente = e.target.value
              setData((oldState) => ({
                ...oldState,
                dvContaCorrente,
              }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <MUIAutoComplete
            label="Indicador de conta bancária"
            options={indicadorContaBancariaValues}
            renderOption={(opt) => opt?.name || ''}
            optionId="value"
            value={data?.indContaBancaria}
            onChange={(e, obj) =>
              setData((prevState) => ({
                ...prevState,
                indContaBancaria: obj?.value || indicadorContaBancariaConsts.contaCorrente_1,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Switch
            label="Conta está ativa"
            checked={data?.isAtivo}
            onChange={(e, value) =>
              setData((prevState) => ({
                ...prevState,
                isAtivo: value,
              }))
            }
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
