import { useMutation, useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'

export async function getAll() {
  const response = await api.get('/EmpregadorConsulta')
  return response?.data?.data || []
}

export async function getEmpregadorById(id) {
  const response = await api.get(`/Empregador/${id}`)
  return response?.data?.data || {}
}

async function remove(id) {
  await api.delete(`/Empregador/${id}`)
}

async function createEmpregadorSST(dt) {
  await api.post('/Empregador/CriarEmpregadorSST', dt)
}

async function updateEmpregador(dt) {
  await api.put(`/Empregador/${dt.id}`, dt)
}

export function useEmpregador() {
  const dialogNotification = useDialogNotification()

  return useQuery('Empregador', getAll, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useEmpregadorMutate() {
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  const mutateDelete = useMutation(remove, {
    onSuccess: async () => await queryClient.invalidateQueries('Empregador'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateCreateEmpregadorSST = useMutation(createEmpregadorSST, {
    onSuccess: async () => await queryClient.invalidateQueries('Empregador'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateUpdateEmpregador = useMutation(updateEmpregador, {
    onSuccess: async () => await queryClient.invalidateQueries('Empregador'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  return {
    mutateDelete,
    mutateCreateEmpregadorSST,
    mutateUpdateEmpregador,
  }
}
