import React, { useState, useEffect, useCallback } from 'react'

import { TextField, LabelValue } from 'mio-library-ui'
import { Grid, makeStyles, Box } from '@material-ui/core'

import { AlertContainer, Checkbox, DatePicker, ActionDialog } from '~/components'

import PeriodoAquisitivo from './components/PeriodoAquisitivo'
import PeriodoConcedido from './components/PeriodoConcedido'
import PeriodoProgramado from './components/PeriodoProgramado'

import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

import moment from 'moment'
import * as yup from 'yup'
import { IndFeriasEnum } from '~/@types/enums/IndFeriasEnum'

const DOMINGO = 0
const SABADO = 6

const getFeriadosNacionais = (ano) => [
  `${ano}-01-01`,
  `${ano}-04-21`,
  `${ano}-05-01`,
  `${ano}-09-07`,
  `${ano}-10-12`,
  `${ano}-11-02`,
  `${ano}-11-15`,
  `${ano}-12-25`,
]

const useStyles = makeStyles((theme) => ({
  colorDanger: {
    fontSize: '11pt',
    color: theme.palette.alerts.danger,
  },
  colorOk: {
    fontSize: '11pt',
    color: theme.palette.alerts.success,
  },
  fieldsAlieng: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelField: {
    fontSize: '11pt',
    fontWeight: 'bold',
  },
}))

const schema = yup.object().shape({
  indFerias: yup
    .number()
    .when(
      ['$somatorioTotalDiasDireito', '$somatorioCurrent'],
      (somatorioTotalDiasDireito, somatorioCurrent, schema) => {
        return schema.test({
          name: 'test',
          exclusive: true,
          params: { somatorioCurrent, somatorioTotalDiasDireito },
          message: 'Informa Férias Fracionada',
          test: (indFerias) =>
            !(indFerias === IndFeriasEnum.Mensal_0 && somatorioCurrent < somatorioTotalDiasDireito),
        })
      },
    ),
  nrDiasFerias: yup
    .number()
    .nullable()
    .when(['$validationValues'], (validationValues, schema) => {
      return schema
        .required('Informe o Saldo de Dias')
        .min(
          validationValues.nrDiasFerias.min,
          `Informe pelo menos ${validationValues.nrDiasFerias.min || 0} dias`,
        )
        .max(
          validationValues.nrDiasFerias.max,
          `Informe no máximo ${validationValues.nrDiasFerias.max || 0} dias`,
        )
    }),

  dtInicioConcessao: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .when(['$validationValues', '$anoMes'], (validationValues, anoMes, schema) => {
      return schema
        .min(
          validationValues.dtInicioConcessao.min,
          'Início das Férias deve está dentro do Período Aquisitivo',
        )
        .required('Informe o Início das Férias')
        .test(
          'test-dt-inicio-in-competencia',
          'Informe a Data de Início das Férias dentro da competência atual ' + anoMes,
          (value) =>
            moment(anoMes).isSameOrBefore(value) && moment(anoMes).add(1, 'month').isAfter(value),
        )
    }),
  dtFimConcessao: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .when(['$validationValues'], (validationValues, schema) => {
      return schema
        .min(
          validationValues.dtFimConcessao.min,
          'Fim das Férias deve está dentro do Período Aquisitivo',
        )
        .required('Informe o Fim das Férias')
    }),
  dtEmissaoFerias: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .when(['$validationValues'], (validationValues, schema) => {
      if (validationValues.dtEmissaoFerias) {
        return schema.required('Informe a Data de Pagamento')
        // .max(
        //   validationValues.dtEmissaoFerias.max,
        //   'Data de Pagamento deve ser 2 dias antes ao Início das Férias',
        // )
        // .min(
        //   validationValues.dtEmissaoFerias.min,
        //   'Data de Pagamento deve está dentro do Período Aquisitivo',
        // )
      }
      return schema.required('Informe a Data de Pagamento')
    }),
  dtAvisoFerias: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .when(['$validationValues'], (validationValues, schema) => {
      if (validationValues.dtAvisoFerias) {
        return schema
          .required('Informe o Aviso Férias')
          .max(
            validationValues.dtAvisoFerias.max,
            'Aviso Prévio deve ser um mês antes ao Início das Férias',
          )
          .min(
            validationValues.dtAvisoFerias.min,
            'Aviso Prévio deve está dentro do Período Aquisitivo',
          )
      }
      return schema.required('Informe o Aviso Férias')
    }),

  dtInicioAbono: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .when(
      ['$hasAbono', '$validationValues', 'dtInicioConcessao', 'dtFimConcessao'],
      (hasAbono, validationValues, dtInicioConcessao, dtFimConcessao, schema) => {
        if (hasAbono) {
          const inicioConcessao = moment(dtInicioConcessao).subtract(1, 'd').format('yyyy-MM-DD')
          const fimConcessao = moment(dtFimConcessao).add(1, 'd').format('yyyy-MM-DD')
          return schema
            .required('Informe o Início do Abono')
            .min(
              validationValues.dtInicioAbono.min,
              'Início do Abono deve está dentro do Período Aquisitivo',
            )
            .test({
              name: 'test',
              exclusive: true,
              params: { inicioConcessao, fimConcessao },
              message: 'Informe o Início do Abono fora do Período de Férias',
              test: (dtInicioAbono) =>
                !moment(dtInicioAbono).isBetween(inicioConcessao, fimConcessao),
            })
        }
      },
    ),
  dtFimAbono: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .when(
      ['$hasAbono', '$validationValues', 'dtInicioConcessao', 'dtFimConcessao'],
      (hasAbono, validationValues, dtInicioConcessao, dtFimConcessao, schema) => {
        if (hasAbono) {
          const inicioConcessao = moment(dtInicioConcessao).subtract(1, 'd').format('yyyy-MM-DD')
          const fimConcessao = moment(dtFimConcessao).add(1, 'd').format('yyyy-MM-DD')
          return schema
            .required('Informe o Fim do Abono')
            .min(
              validationValues.dtFimAbono.min,
              'Fim do Abono deve está dentro do Período Aquisitivo',
            )
            .test({
              name: 'test',
              exclusive: true,
              params: { inicioConcessao, fimConcessao },
              message: 'Informe o Fim do Abono fora do Período de Férias',
              test: (dtFimAbono) => !moment(dtFimAbono).isBetween(inicioConcessao, fimConcessao),
            })
        }
      },
    ),
  nrDiasAbono: yup
    .number()
    .when(['$hasAbono', '$validationValues'], (hasAbono, validationValues, schema) => {
      if (hasAbono) {
        return schema
          .required('Informe o Abono Pecuniário')
          .max(
            validationValues.nrDiasAbono.max,
            'Abono Pecuniário deve ser no máximo 1/3 dos Dias de Direito',
          )
          .min(
            validationValues.nrDiasAbono.min,
            `Abono Pecuniário precisa de pelo menos ${validationValues.nrDiasAbono.min} dia`,
          )
      }
    }),
})

const ReciboFeriasForm = (props) => {
  const { isOpen, onClose, data: _reciboFerias, vinculo, onAfterAddReciboFerias } = props
  const [data, setData] = useState({})
  const [reciboFerias, setReciboFerias] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const [hasAbono, setAbono] = useState(false)
  const [feriadosInternos, setFeriadosInternos] = useState([])
  const [descansoSemanal, setDescansoSemanal] = useState('')

  const [validationValues, setValidationValues] = useState({})
  const [somatorioCurrent, setSomatorioCurrent] = useState(0)
  const [somatorioTotalDiasDireito, setSomatorioTotalDiasDireito] = useState(0)

  const [wasCalcAbono, setWasCalcAbono] = useState(false)

  const { anoMes } = useAmbiente(false, true)
  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const classes = useStyles()

  useEffect(() => {
    const startForm = () => {
      setReciboFerias(_reciboFerias)
      setData({
        ..._reciboFerias?.feriasAtual,
        nrDiasAbono: parseInt(_reciboFerias?.feriasAtual?.nrDiasAbono || 0),
      })

      const dtPeriodoInicio = _reciboFerias?.feriasPeriodo?.dtPeriodoInicio
      setValidationValues((oldState) => ({
        ...oldState,
        nrDiasFerias: {
          max: _reciboFerias?.feriasAtual?.vinculoFerias?.nrDiasDireito,
          min: 5,
        },

        dtInicioConcessao: {
          min: dtPeriodoInicio,
        },
        dtFimConcessao: {
          min: dtPeriodoInicio,
        },

        dtInicioAbono: {
          min: dtPeriodoInicio,
        },
        dtFimAbono: {
          min: dtPeriodoInicio,
        },
      }))

      if (_reciboFerias?.feriasAtual?.nrDiasAbono) {
        setAbono(true)
        setValidationValues((oldState) => ({
          ...oldState,
          nrDiasAbono: {
            max: _reciboFerias?.feriasAtual?.nrDiasAbono || 30,
            min: 1,
          },
        }))
      } else {
        setAbono(false)
      }
    }
    if (!_reciboFerias?.feriasAtual) return
    startForm()
  }, [_reciboFerias])

  useEffect(() => {
    const calcDates = () => {
      calcInicioConcessao(moment(reciboFerias?.feriasAtual?.dtInicioConcessao))
      if (hasAbono) changeAbonoByInicioAbono(moment(reciboFerias?.feriasAtual?.dtInicioAbono))
    }

    if (!reciboFerias?.feriasAtual) return
    calcDates()
    //eslint-disable-next-line
  }, [reciboFerias])

  useEffect(() => {
    const getFeriadosInternos = async () => {
      try {
        const response = await api.get('/Feriado')
        if (response.data.data) {
          const feriados = response.data.data.map((feriadoObj) => feriadoObj.dtFeriado)
          setFeriadosInternos(feriados)
        }
      } catch (err) {
        console.log(err)
      }
    }
    const getDescansoSemanalVinculo = async () => {
      try {
        const response = await api.get(`vinculo/${vinculo?.vinculoId}`)
        if (response.data.data) {
          setDescansoSemanal(response.data.data.diaDescansoSemanal - 1)
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (vinculo?.vinculoId) {
      getFeriadosInternos()
      getDescansoSemanalVinculo()
    }
  }, [vinculo])

  useEffect(() => {
    const calcSomatorio = () => {
      let somatorioDias = 0

      if (reciboFerias?.feriasConcedidas) {
        reciboFerias.feriasConcedidas.forEach((periodo) => {
          somatorioDias =
            somatorioDias +
            parseInt(periodo?.nrDiasFerias || 0) +
            parseInt(periodo?.nrDiasAbono || 0)
        })
      }

      setSomatorioTotalDiasDireito(somatorioDias + data?.vinculoFerias?.nrDiasDireito)

      somatorioDias =
        somatorioDias + parseInt(data?.nrDiasAbono || 0) + parseInt(data?.nrDiasFerias || 0)

      setSomatorioCurrent(somatorioDias)
    }

    calcSomatorio()
    // eslint-disable-next-line
  }, [reciboFerias?.feriasConcedidas, data.nrDiasAbono, data.nrDiasFerias])

  const handleChangeDate = (date, name) => {
    const value = date && date.isValid() ? date.format('yyyy-MM-DD') : null
    setData((oldState) => ({
      ...oldState,
      [name]: value,
    }))
  }

  const calcDescansoSemanal = (date) => {
    const diasDescansoSemanal = []
    let newDate = date

    for (let index = 0; index <= 2; index++) {
      const dscSemanal =
        Math.sign(descansoSemanal - index) === -1
          ? descansoSemanal - index + 7
          : descansoSemanal - index

      diasDescansoSemanal.unshift(dscSemanal)
    }

    diasDescansoSemanal.forEach((dscSemanal, index) => {
      if (dscSemanal === date.day()) {
        notification.warning(
          'Data ajustada, Início das Férias precisa ser 2 dias antes do Descanso Semanal',
        )
        newDate = date.subtract(index + 1, 'd')
      }
    })
    return newDate
  }

  const calcFeriados = (date) => {
    const feriadosNacionais = getFeriadosNacionais(date.format('YYYY-MM-DD').substr(0, 4))
    const allFeriados = [...feriadosNacionais, ...feriadosInternos]

    if (allFeriados.some((feriado) => date.isSame(feriado))) {
      return null
    }
    return date
  }

  const handleCalcDates = (date) => {
    if (date.day() === SABADO) {
      handleCalcDates(date.subtract(1, 'd'))
    }

    if (date.day() === DOMINGO) {
      handleCalcDates(date.subtract(2, 'd'))
    }

    if (!calcFeriados(date)) {
      handleCalcDates(date.subtract(1, 'd'))
    }

    return date
  }

  const calcDatesByInicioConcessao = (dtInicioConcessao) => {
    const firstDateCompetencia = moment(anoMes)
    if (dtInicioConcessao.isBefore(firstDateCompetencia)) {
      dtInicioConcessao = firstDateCompetencia
    }

    const nrDiasFerias = data?.nrDiasFerias

    const dtAvisoFerias = handleCalcDates(moment(dtInicioConcessao).subtract(1, 'months'))
    const dtEmissaoFerias = handleCalcDates(moment(dtInicioConcessao).subtract(2, 'days'))
    const dtFimConcessao = moment(dtInicioConcessao).add(nrDiasFerias - 1, 'days')

    const dtInicioPeriodo = reciboFerias?.feriasPeriodo?.dtPeriodoInicio
    setValidationValues((oldState) => ({
      ...oldState,
      dtAvisoFerias: {
        max: dtAvisoFerias.format('yyyy-MM-DD'),
        min: dtInicioPeriodo,
      },
      dtEmissaoFerias: {
        max: dtEmissaoFerias.format('yyyy-MM-DD'),
        min: dtInicioPeriodo,
      },
    }))

    setData((oldState) => ({
      ...oldState,
      dtFimConcessao: dtFimConcessao.format('yyyy-MM-DD'),
      dtAvisoFerias: dtAvisoFerias.format('yyyy-MM-DD'),
      dtEmissaoFerias: dtEmissaoFerias.format('yyyy-MM-DD'),
      dtRetorno: dtFimConcessao.add(1, 'd').format('yyyy-MM-DD'),
      dtInicioConcessao: dtInicioConcessao.format('yyyy-MM-DD'),
    }))
  }

  const calcInicioConcessao = useCallback(
    (dtInicioConcessao) => {
      let inicioConcessaoCurrent = dtInicioConcessao

      if (inicioConcessaoCurrent.day() === SABADO) {
        notification.warning('Data ajustada, Início das Férias não pode começar em um Sabádo')
        calcInicioConcessao(inicioConcessaoCurrent.subtract(1, 'd'))
        return
      }

      if (inicioConcessaoCurrent.day() === DOMINGO) {
        notification.warning('Data ajustada, Início das Férias não pode começar em um Domingo')
        calcInicioConcessao(inicioConcessaoCurrent.subtract(2, 'd'))
        return
      }

      const responseCalcDescansoSemanal = calcDescansoSemanal(inicioConcessaoCurrent)
      if (!inicioConcessaoCurrent.isSame(responseCalcDescansoSemanal)) {
        calcInicioConcessao(responseCalcDescansoSemanal)
        return
      }

      if (!calcFeriados(inicioConcessaoCurrent)) {
        notification.warning('Data ajustada, Início das Férias não pode começar em um Feriado')
        calcInicioConcessao(inicioConcessaoCurrent.subtract(1, 'd'))
        return
      }

      calcDatesByInicioConcessao(inicioConcessaoCurrent)
    },
    //eslint-disable-next-line
    [calcDescansoSemanal, calcFeriados, calcDatesByInicioConcessao],
  )

  const handleChangeAbono = () => {
    if (!hasAbono) {
      const umTercoSaldo = data?.vinculoFerias?.nrDiasDireito / 3
      const newSaldoDias = data?.vinculoFerias?.nrDiasDireito - umTercoSaldo

      setValidationValues((oldState) => ({
        ...oldState,
        nrDiasAbono: { max: Math.trunc(umTercoSaldo) || 0, min: 1 },
      }))

      setData((oldState) => ({
        ...oldState,
        nrDiasAbono: Math.trunc(umTercoSaldo) || 0,
        nrDiasFerias: Math.ceil(newSaldoDias) || 0,

        dtInicioConcessao: null,
        dtFimConcessao: null,
        dtEmissaoFerias: null,
        dtAvisoFerias: null,
      }))
    }
  }

  const changeAbonoByInicioAbono = useCallback(
    (date) => {
      if (!date?.isValid()) {
        setData((oldState) => ({
          ...oldState,
          dtFimAbono: null,
        }))
      }

      const dtFimAbono = date

      setData((oldState) => ({
        ...oldState,
        dtInicioAbono: date?.format('yyyy-MM-DD'),
        dtFimAbono: dtFimAbono?.add(data?.nrDiasAbono - 1, 'd')?.format('yyyy-MM-DD'),
      }))
    },
    [data],
  )

  const validationHandleCreate = () => {
    if (reciboFerias?.feriasConcedidas?.length >= 3) {
      notification.error('Número máximo de Recibos atingido.')
      return true
    }

    if (somatorioCurrent > somatorioTotalDiasDireito) {
      notification.error(
        `A soma dos dias para o Período Aquisitivo atual não pode ultrapassar ${somatorioTotalDiasDireito}`,
      )
      return true
    }

    if (
      data?.indFerias === IndFeriasEnum.Fracionada_3 &&
      somatorioCurrent === somatorioTotalDiasDireito
    ) {
      notification.error('Este período de Férias não pode ser fracionado')
      return true
    }

    if (reciboFerias?.feriasConcedidas?.length >= 2) {
      let erro = true
      reciboFerias.feriasConcedidas.forEach((periodo) => {
        if (periodo.nrDiasConcedidos < 14 && erro) {
          erro = true
        } else {
          erro = false
        }
      })

      if (data?.nrDiasFerias >= 14) {
        erro = false
      }

      if (erro) {
        notification.error('Pelo menos um período deve ser de 14 dias.')
        return true
      }

      if (somatorioCurrent !== somatorioTotalDiasDireito) {
        notification.error(
          '3° Recibo de Férias sendo criado, necessário utilizar todos os dias do Saldo',
        )
        return true
      }
    }

    return false
  }

  const handleSave = async () => {
    if (validationHandleCreate()) return

    setSubmitting(true)
    try {
      const { data: resData } = await api.post(
        `RP/NovoFerias?vinculoFeriasId=${data?.vinculoFeriasId}&anoMes=${anoMes}`,
        data,
      )
      const reciboId = resData.data.id
      const vinculoId = resData.data.vinculoId
      onAfterAddReciboFerias(reciboId, vinculoId)
      notification.success('Recibo de férias criado com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSave,
    data,
    schemaOptions: {
      abortEarly: false,
      context: {
        somatorioTotalDiasDireito,
        hasAbono,
        validationValues,
        somatorioCurrent,
        anoMes,
      },
    },
  })

  return (
    <ActionDialog
      title="Recibo de Férias"
      subtitle={vinculo?.vinculoNome}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Criar Recibo"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PeriodoAquisitivo periodoAquisitivo={reciboFerias?.feriasPeriodo} />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <PeriodoConcedido periodoConcedido={reciboFerias?.feriasConcedidas} />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <PeriodoProgramado feriasProgramadas={reciboFerias?.feriasProgramadas} />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={3} xs={12} className={classes.fieldsAlieng}>
          <Checkbox
            label="Férias Fracionada"
            labelPlacement="end"
            name="indFerias"
            validationErrors={validationErrors}
            disabled={reciboFerias?.feriasConcedidas?.length >= 2 ? true : false}
            value={data?.indFerias === IndFeriasEnum.Fracionada_3 ? true : false}
            onChange={(e, value) => {
              const indFerias = value ? IndFeriasEnum.Fracionada_3 : IndFeriasEnum.Mensal_0
              if (indFerias !== IndFeriasEnum.Fracionada_3 && !hasAbono) {
                setData((oldState) => ({
                  ...oldState,
                  nrDiasFerias: reciboFerias?.feriasAtual?.nrDiasFerias,
                  dtInicioConcessao: null,
                  dtFimConcessao: null,
                  dtEmissaoFerias: null,
                  dtAvisoFerias: null,
                  dtInicioAbono: null,
                  dtFimAbono: null,
                  nrDiasAbono: 0,
                }))
              }
              setData((oldState) => ({
                ...oldState,
                indFerias,
              }))
            }}
          />
        </Grid>

        <Grid item xl={1} lg={1} md={1} sm={3} xs={12} className={classes.fieldsAlieng}>
          <Checkbox
            label="Abono"
            labelPlacement="end"
            value={hasAbono}
            onChange={() => {
              setAbono(!hasAbono)
              if (hasAbono) {
                setData((oldState) => ({
                  ...oldState,
                  nrDiasFerias: reciboFerias?.feriasAtual?.nrDiasFerias,
                  nrDiasAbono: 0,
                  dtInicioAbono: null,
                  dtFimAbono: null,

                  dtInicioConcessao: null,
                  dtFimConcessao: null,
                  dtEmissaoFerias: null,
                  dtAvisoFerias: null,
                }))
              }
              handleChangeAbono()
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={3} xs={12} className={classes.fieldsAlieng}>
          <LabelValue
            label={<div className={classes.labelField}>Total de Faltas</div>}
            value={
              <Box className={classes.colorDanger}>{data?.vinculoFerias?.totalFaltas || 0}</Box>
            }
            showSeparator={false}
            direction="horizontal"
            justifyContent="center"
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={3} xs={12} className={classes.fieldsAlieng}>
          <LabelValue
            label={<div className={classes.labelField}>Dias de Direito</div>}
            value={<Box className={classes.colorOk}>{data?.vinculoFerias?.nrDiasDireito || 0}</Box>}
            showSeparator={false}
            direction="horizontal"
            justifyContent="center"
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            label="Dias de Concessão"
            required
            validationErrors={validationErrors}
            name="nrDiasFerias"
            value={data?.nrDiasFerias || 0}
            onChange={(e) => {
              const nrDiasFerias = e?.target?.value || 0
              setData((oldState) => ({
                ...oldState,
                nrDiasFerias,
                dtInicioConcessao: null,
                dtFimConcessao: null,
                dtEmissaoFerias: null,
                dtAvisoFerias: null,
                dtInicioAbono: null,
                dtFimAbono: null,
              }))
              setWasCalcAbono(false)
            }}
            onBlur={() => {
              if (hasAbono && data?.indFerias === IndFeriasEnum.Fracionada_3 && !wasCalcAbono) {
                const umTercoSaldo = data?.nrDiasFerias / 3
                const nrDiasFerias = data?.nrDiasFerias - umTercoSaldo
                setValidationValues((oldState) => ({
                  ...oldState,
                  nrDiasAbono: { max: Math.trunc(umTercoSaldo) || 0, min: 1 },
                }))
                setData((oldState) => ({
                  ...oldState,
                  nrDiasFerias: Math.ceil(nrDiasFerias) || 0,
                  nrDiasAbono: Math.trunc(umTercoSaldo) || 0,
                  dtInicioConcessao: null,
                  dtFimConcessao: null,
                  dtEmissaoFerias: null,
                  dtAvisoFerias: null,
                  dtInicioAbono: null,
                  dtFimAbono: null,
                }))
                setWasCalcAbono(true)
              }
            }}
            onlyNumber
            inputProps={{
              maxLength: 5,
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            label="Dias de Abono"
            name="nrDiasAbono"
            onlyNumber
            required={hasAbono}
            disabled={hasAbono ? false : true}
            validationErrors={validationErrors}
            value={data?.nrDiasAbono || 0}
            inputProps={{
              maxLength: 5,
            }}
            onChange={(e) => {
              const nrDiasAbono = e?.target?.value || 0
              let dtInicioAbono = null
              let dtFimAbono = null
              if (data?.dtFimConcessao) {
                dtInicioAbono = moment(data.dtFimConcessao).add(1, 'd').format('yyyy-MM-DD')
                dtFimAbono = moment(data.dtFimConcessao).add(nrDiasAbono, 'd').format('yyyy-MM-DD')
              }
              setData((oldState) => ({
                ...oldState,
                nrDiasAbono,
                dtInicioAbono,
                dtFimAbono,
              }))
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            name="dtInicioConcessao"
            label="Início das Férias"
            required
            validationErrors={validationErrors}
            size="small"
            autoFocus
            value={data?.dtInicioConcessao || null}
            onChange={(date) => {
              if (date?.isValid()) calcInicioConcessao(date)
              const dtInicioConcessao = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtInicioConcessao,
              }))
              if (hasAbono) {
                const inicioAbono = moment(dtInicioConcessao).add(data?.nrDiasFerias, 'days')
                changeAbonoByInicioAbono(inicioAbono)
              }
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
          <DatePicker
            name="dtFimConcessao"
            required
            validationErrors={validationErrors}
            label="Fim das Férias"
            size="small"
            disabled
            value={data?.dtFimConcessao || null}
            onChange={(date) => handleChangeDate(date, 'dtFimConcessao')}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
          <DatePicker
            label="Pagamento"
            name="dtEmissaoFerias"
            required
            validationErrors={validationErrors}
            size="small"
            value={data?.dtEmissaoFerias || null}
            onChange={(date) => handleChangeDate(date, 'dtEmissaoFerias')}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
          <DatePicker
            label="Aviso Férias"
            name="dtAvisoFerias"
            required
            validationErrors={validationErrors}
            size="small"
            value={data?.dtAvisoFerias || null}
            onChange={(date) => handleChangeDate(date, 'dtAvisoFerias')}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            label="Início do Abono"
            required={hasAbono}
            disabled={hasAbono ? false : true}
            validationErrors={validationErrors}
            name="dtInicioAbono"
            size="small"
            value={data?.dtInicioAbono || null}
            onChange={(date) => {
              changeAbonoByInicioAbono(date)
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            label="Fim do Abono"
            required={hasAbono}
            disabled
            validationErrors={validationErrors}
            name="dtFimAbono"
            size="small"
            value={data?.dtFimAbono || null}
            onChange={(date) => handleChangeDate(date, 'dtFimAbono')}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <AlertContainer>
            Somatório de dias de todo o Período Aquisitivo{' '}
            <strong
              className={somatorioCurrent > somatorioTotalDiasDireito ? classes.colorDanger : ''}
            >
              {somatorioCurrent}
            </strong>
            /{somatorioTotalDiasDireito || 0}
          </AlertContainer>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            label="Observação"
            name="observacao"
            value={data?.observacao || ''}
            onChange={(e) => {
              const observacao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
            inputProps={{
              maxLength: 250,
            }}
            multiline
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default ReciboFeriasForm
