import { useState, memo, useRef, useEffect } from 'react'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { Button, Finder, PageHeader } from '~/components'
import { AutoCompleteGrupo, MUIAutoComplete } from '~/components/AutoComplete'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'

import useObterEmpregadoresParaFechamento from '~/hooks/queries/Competencia/useObterEmpregadoresParaFechamento'
import useProcessarFechamento from '~/hooks/queries/Competencia/useProcessarFechamento'
import useDialogNotification from '~/hooks/useDialogNotification'
import {
  FiltroCompetenciaFechamentoEnum,
  FiltroCompetenciaFechamentoValues,
} from '~/@types/enums/FiltroCompetenciaFechamentoEnum'
import { IndApuracaoEnum, IndApuracaoValues } from '~/@types/enums/IndApuracaoEnum'
import { IndCompetenciaProcessEnum } from '~/@types/enums/IndCompetenciaProcessEnum'

const MemoTable = memo(Table)

export default function FechamentoESocial() {
  const [query, setQuery] = useState('')
  const [dataForm, setDataForm] = useState<{
    grupoId: string | null
    filtroEmpresas: FiltroCompetenciaFechamentoEnum
    indApuracao: IndApuracaoEnum
  }>({
    grupoId: null,
    filtroEmpresas: FiltroCompetenciaFechamentoEnum.EmpresasAbertas,
    indApuracao: IndApuracaoEnum.Mensal_1,
  })
  const tableRef = useRef<FixLater>(null)

  const { anoMes, mes } = useAmbiente()
  const theme = useTheme()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setDataForm((prev) => ({ ...prev, indApuracao: IndApuracaoEnum.Mensal_1 }))
  }, [anoMes])

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterEmpregadoresParaFechamento({
    anoMes,
    filtroEmpresas: dataForm.filtroEmpresas,
    grupoId: dataForm.grupoId,
    indApuracao: dataForm.indApuracao,
  })
  const data = _d || []
  const { mutateAsync, isLoading: isSubmitting } = useProcessarFechamento()

  async function handleProcess() {
    const rowsSelected: number[] = tableRef?.current?.state?.selectedRows?.data.map(
      (d: FixLater) => d.dataIndex,
    )

    if (rowsSelected.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe os Empregadores que deseja processar o Fechamento'],
      })
    }
    const competenciasEmpregadoresIds = rowsSelected.map((d) => data[d].id)
    await mutateAsync({ competenciasEmpregadoresIds })
  }

  const isDezemebro = mes === '12'

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title="Fechamento eSocial">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} p={1} display="flex" gridGap={theme.spacing(2)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={isDezemebro ? 6 : 8}>
            <AutoCompleteGrupo
              value={dataForm.grupoId}
              onChange={(e, grupo) => {
                const grupoId = grupo?.id || null
                setDataForm((prev) => ({ ...prev, grupoId }))
              }}
            />
          </Grid>

          {isDezemebro && (
            <Grid item xs={12} sm={6} md={3}>
              <MUIAutoComplete
                label="Indicador de Apuração"
                required
                name="indApuracao"
                value={dataForm.indApuracao}
                options={IndApuracaoValues}
                optionId="value"
                renderOption={(option) => option.name}
                onChange={(e, obj) => {
                  const indApuracao = obj?.value || IndApuracaoEnum.Mensal_1
                  setDataForm((prev) => ({ ...prev, indApuracao }))
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={isDezemebro ? 6 : 12} md={isDezemebro ? 3 : 4}>
            <MUIAutoComplete
              label="Filtrar Empresas"
              options={FiltroCompetenciaFechamentoValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={dataForm.filtroEmpresas}
              onChange={(e, obj) => {
                const filtroEmpresas = obj.value
                setDataForm((prev) => ({ ...prev, filtroEmpresas }))
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box flex={1} position="relative" overflow="auto" minHeight={300}>
        <Box position="absolute" width="100%">
          <MemoTable
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            query={query}
            tableRef={tableRef}
            indCompetenciaProcess={
              dataForm.indApuracao === IndApuracaoEnum.Mensal_1
                ? IndCompetenciaProcessEnum.Mensal_00
                : IndCompetenciaProcessEnum.DecimoTerceiro_01
            }
          />
        </Box>
      </Box>

      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={handleProcess} isLoading={isSubmitting}>
          Processar Fechamento
        </Button>
      </Box>
    </Box>
  )
}
