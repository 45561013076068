import { formatToCPF } from 'brazilian-values'

import { CopyLabel, CurrencyTextField, Stack, ToolsDataTable } from '~/components'

import { useObterLancamentosDependente } from '~/hooks/queries/PlanoSaudeLancamento/useObterLancamentosDependente'
import { useGerarLancamentoDependente } from '~/hooks/queries/PlanoSaudeLancamento/useGerarLancamentoDependente'

import useAmbiente from '~/hooks/useAmbiente'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableDependenteProps {
  planoSaudeTitularId: string
}

export default function TableDependente({ planoSaudeTitularId }: TableDependenteProps) {
  const { anoMes } = useAmbiente()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterLancamentosDependente({
    params: {
      planoSaudeTitularId,
      anoMes,
    },
  })
  const data = _d || []

  const { mutateAsync, isLoading: isSubmitting } = useGerarLancamentoDependente()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'pessoaCPF',
      label: 'CPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      name: 'pessoaNome',
      label: 'Nome',
    },
    {
      name: 'lancamentoId',
      label: 'Valor do Custo',
      options: {
        customBodyRender: (value) => {
          const dataCurrent = data.find((d) => d.lancamentoId == value)
          if (!dataCurrent) return
          return (
            <CurrencyTextField
              value={dataCurrent.vrCusto}
              onBlur={async (_, value) => {
                const vrCusto = value
                if (vrCusto === dataCurrent.vrCusto) return
                await mutateAsync({
                  ...dataCurrent,
                  vrCusto,
                })
              }}
            />
          )
        },
      },
    },
    {
      name: 'lancamentoId',
      label: 'Valor do Custo da Empresa',
      options: {
        customBodyRender: (value) => {
          const dataCurrent = data.find((d) => d.lancamentoId == value)
          if (!dataCurrent) return
          return (
            <CurrencyTextField
              value={dataCurrent.vrCustoEmpresa}
              onBlur={async (_, value) => {
                const vrCustoEmpresa = value
                if (vrCustoEmpresa === dataCurrent.vrCustoEmpresa) return
                await mutateAsync({
                  ...dataCurrent,
                  vrCustoEmpresa,
                })
              }}
            />
          )
        },
      },
    },
  ]

  return (
    <Stack p={2}>
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching || isSubmitting}
        disableAutoHeight
        pagination={false}
      />
    </Stack>
  )
}
