import { RP } from '~/hooks/queries/RP/RP'

const RPLabels: Stringable<RP> = {
  vrAdicionalFerias: 'Adicional Férias',
  vrAdicionalFeriasProporcional: 'Adicional Férias Proporcional',
  vrAdicionalFeriasVariaveis: 'Adicional Férias Variáveis',
  vrAdicionalFeriasVencidas: 'Adicional Férias Vencidas',
  vrBaseContribuicaoSindical: 'Base Contribuição Síndical',
  vrBaseDescansoSemanal: 'Base Descanso Semanal',
  vrBaseFGTS: 'Base FGTS',
  vrBaseFGTS13: 'Base FGTS 13°',
  vrBaseFGTS13Suspenso: 'Base FGTS 13° Suspenso',
  vrBaseFGTSAvisoPrevio: 'Base FGTS Aviso Prévio',
  vrBaseFGTSAvisoPrevioSuspenso: 'Base FGTS Aviso Prévio Suspenso',
  vrBaseFGTSSuspenso: 'Base FGTS Suspenso',
  vrBaseINSS: 'Base INSS',
  vrBaseINSS13: 'Base INSS 13°',
  vrOutrasRemuneracoes: 'Base INSS Outras Remunerações',
  vrBaseINSSOutrasRemuneracoes13: 'Base INSS Outras Remunerações 13°',
  vrBaseINSSOutrosVinculos: 'Base INSS Outros Vínculos',
  vrBaseINSSOutrosVinculos13: 'Base INSS Outros Vínculos 13°',
  vrBaseIRRF: 'Base IRRF',
  vrBaseIRRF13: 'Base IRRF 13°',
  vrBaseIRRFBruto: 'Base IRRF Bruto',
  vrBaseIRRFBruto13: 'Base IRRF Bruto 13°',
  vrBaseIRRFOutrosRecibos: 'Base IRRF Outros Vínculos',
  vrBaseIRRFOutrosRecibos13: 'Base IRRF Outros Vínculos 13°',
  vrBaseIRRFPLR: 'Base IRRF PLR',
  vrBaseSalarioFamilia: 'Base Salário Família',
  vrBaseSalarioMaternidade: 'Base Salário Maternidade',
  vrBaseTitulacao: 'Base Titulação',
  vrContratualAdicionalDuplaFuncao: 'Contratual Adicional Dupla Função',
  vrContratualAdicionalNoturno: 'Contratual Adicional Noturno',
  vrContratualExtraCaixa: 'Contratual Extra Caixa',
  vrContratualGratificacao: 'Contratual Gratificação',
  vrContratualInsalubridade: 'Contratual Insalubridade',
  vrContratualPericulosidade: 'Contratual Periculosidade',
  vrSalarioContratual: 'Salário Contratual',
  vrDeducaoIRRF: 'Dedução IRRF',
  vrDeducaoIRRF13: 'Dedução IRRF 13°',
  vrDeducaoPrevidencia: 'Dedução Previdência',
  vrDeducaoSalarioMaternidade: 'Dedução Salário Maternidade',
  vrDeducaoSalarioMaternidade13: 'Dedução Salário Maternidade 13°',
  vrDependenteIR: 'Dependente IR',
  vrFGTS: 'FGTS',
  vrFGTS13: 'FGTS 13°',
  vrFGTS13Suspenso: 'FGTS 13° Suspenso',
  vrFGTSAvisoPrevio: 'FGTS Aviso Prévio',
  vrFGTSAvisoPrevioSuspenso: 'FGTS Aviso Prévio Suspenso',
  vrFGTSSuspenso: 'FGTS Suspenso',
  vrINSS: 'INSS',
  vrINSS13: 'INSS 13°',
  vrIRRF: 'IRRF',
  vrIRRF13: 'IRRF 13°',
  vrIRRFOutrosRecibos: 'IRRF Outros Vínculos',
  vrIRRFOutrosVinculos13: 'IRRF Outros Vínculos 13°',
  vrIRRFPLR: 'IRRF PLR',
  vrSalarioFamilia: 'Salário Família',
  vrTotalDesconto: 'Total Desconto',
  vrTotalLiquido: 'Total Líquido',
  vrTotalVencimento: 'Total Vencimento',
  aliqAdicionalNoturno: 'Alíquota Adicional Noturno',
  aliqFGTS: 'Alíquota FGTS',
  aliqHoraExtra: 'Alíquota Hora Extra',
  aliqINSS: 'Alíquota INSS',
  aliqINSS13: 'Alíquota INSS 13°',
  aliqIRRF: 'Alíquota IRRF',
  aliqIRRF13: 'Alíquota IRRF 13°',
  aliqIRRFPLR: 'Alíquota IRRF PLR',
  diasAbonoPecuniario: 'Dias Abono Pecuniário',
  diasAcidente: 'Dias Acidente',
  diasAfastamento: 'Dias Afastamento',
  diasAuxilio: 'Dias Auxílio',
  diasBaseMes: 'Dias Base Mês',
  diasEnfermidade: 'Dias Enfermidade',
  diasFaltas: 'Dias Faltas',
  diasFaltasDSR: 'Dias Faltas DSR',
  diasFerias: 'Dias Férias',
  diasFeriasMes: 'Dias Férias Mês',
  diasMaternidade: 'Dias Maternidade',
  diasUteis: 'Dias Úteis',
  diasNaoUteis: 'Dias Não Úteis',
  diasTrabalhados: 'Dias Trabalhados',
  horasAfastamento: 'Horas Afastamento',
  horasAuxilio: 'Horas Auxílio',
  horasBaseMes: 'Horas Base Mês',
  horasFaltas: 'Horas Faltas',
  horasFerias: 'Horas Férias',
  horasJornada: 'Horas Jornada',
  horasTrabalhadas: 'Horas Trabalhadas',
  mesesDecimoTerceiro: 'Meses Décimo Terceiro',
  mesesDecimoTerceiroIndenizado: 'Meses Décimo Terceiro Indenizado',
  mesesFeriasDecimoTerceiroIndenizado: 'Meses Férias Décimo Terceiro Indenizado',

  id: '',
  vinculoId: '',
  idf: '',
  nrRecibo: '',
  anoMes: '',
  periodoReferencia: '',
  fatoGerador: '',
  dtPagamento: '',
  tipoPagamento: '',
  departamentoId: '',
  cargoId: '',
  setorId: '',
  lotacaoId: '',
  nrCheque: '',
  feriasId: '',
  vinculoFeriasGeradoId: '',
  vrSalarioVariavelFeriasVencidas: '',
  vrSalarioVariavelFeriasProporcionais: '',
  vrSalarioVariavelAvisoPrevio: '',
  vrSalarioVariavelDtSalario: '',
  vrSalarioVariavelMaternidade: '',
  aliqINSSFeriasMesSeguinte: '',
  aliqISS: '',
  vrSENAT: '',
  vrSEST: '',
  vrISS: '',
  vrContratualCargoConfianca: '',
  vrContratualAdicionalTempoServico: '',
  vrINSSOutrosVinculos: '',
  vrINSSOutrosVinculos13: '',
  isFinalizado: '',
  finalizadoOrdem: '',
  diasMaternidade13: '',
  dependestesSalarioFamilia: '',
  dependentesIR: '',
  observacao: '',
  isPagamentoBanco: '',
  pessoaFisicaContaCorrenteId: '',
  nrRemessaBancaria: '',
  dtRemessaBancaria: '',
  indSimples: '',
  indRetificacao: '',
  categoria: '',
  indMultiplosVinculos: '',
  indGrauExposicao: '',
  indApuracao: '',
  fbCodigo: '',
  diasServicoMilitar: '',
  diasContratoExperiencia: '',
  diasBaseFaltas: '',
  horasDSR: '',
  horasFaltasSabado: '',
  horasNoturna: '',
  horasMediaDtSalario: '',
  horasMediaAvisoPrevio: '',
  horasMediaFeriasVencidas: '',
  horasMediaFeriasProporcional: '',
  mesesFeriasVencida: '',
  mesesFeriasProporcional: '',
  reciboEsocial: '',
  dtReciboEsocial: '',
  reciboEsocialII: '',
  dtReciboEsocialII: '',
  vinculoFeriasItemId: '',
  rpaId: '',
  dtEnvioEmail: '',
  diasConvocacaoIntermitente: '',
  horasConvocacaoIntermitente: '',
  acordoDissidioConvencaoId: '',
  vrDebitoMesAnterior: '',
  vrArredondamentoMesAnterior: '',
  memoriaCalculo: '',
  vinculo: '',
  departamento: '',
  setor: '',
  cargo: '',
  lotacao: '',
  pessoaFisicaContaCorrente: '',
  rpItens: '',
}

export default RPLabels
