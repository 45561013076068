import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { FolhaAutomaticaTaskDTO } from './dtos/FolhaAutomaticaTaskDTO'

const query = '/FolhaAutomatica/ObterProcessamentoEmpregador'

interface RequestProps {
  params: { empregadorId: string; anoMes: string }
}

export function useObterProcessamentoEmpregador({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: FolhaAutomaticaTaskDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
