import { useEffect, useState } from 'react'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  MenuSelect,
  PageHeader,
  PDFViewer,
  Stack,
  StackContainer,
  ToolsDataTable,
  ButtonIcon,
} from '~/components'

import FormCalcularGPS from './FormCalcularGPS'
import FormImprimir from './FormImprimir'

import {
  useFacultativoRemoverVariasContribuicoes,
  useObterContribuicoesPorFacultativo,
  useObterContribuicoesPorFacultativoInvalidate,
  useObterTodosFacultativos,
} from '~/hooks/queries/Facultativo'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import moment from 'moment'
import { formatCurrency, formatToNIT } from '~/utils/utils'
import { formatAnoMes } from '~/hooks/useUtils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { Facultativo } from '~/hooks/queries/Facultativo/dtos/Facultativo'

export type ServicesFacultativo = 'ImprimirContribuicoes' | 'ImprimirContribuicoesPorFacultativos'

export default function Contribuicoes() {
  const [dataMenuSelected, setDataMenuSelected] = useState<number[]>([])
  const [contribuinteIndex, setContribuinteIndex] = useState(-1)
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const { anoMes } = useAmbiente()
  const theme = useTheme()
  const {
    close: closeFormCalcularGPS,
    data: dataFormCalcularGPS,
    isOpen: isOpenFormCalcularGPS,
    open: openFormCalcularGPS,
  } = useDialog<string[]>([])
  const {
    close: closeFormImprimir,
    data: dataFormImprimir,
    isOpen: isOpenFormImprimir,
    open: openFormImprimir,
  } = useDialog<ServicesFacultativo>('ImprimirContribuicoes')
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<{
    service: ServicesFacultativo
    data: string[]
    params: { diaVencimento: number }
  }>({
    service: 'ImprimirContribuicoes',
    data: [],
    params: {
      diaVencimento: 20,
    },
  })
  const {
    close: closeConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog(null)

  const {
    data: _dataMenu,
    isLoading: _isLoadingMenu,
    isFetching: _isFetchingMenu,
  } = useObterTodosFacultativos()
  const dataMenu = _dataMenu || []
  const isLoadingMenu = _isLoadingMenu || _isFetchingMenu

  const isContribuinteSelected = contribuinteIndex > -1 ? true : false
  const contribuinte = dataMenu[contribuinteIndex] || null

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterContribuicoesPorFacultativo({
    params: {
      anoMes,
      facultativoId: contribuinte?.id,
    },
  })
  const data = _data || []
  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } =
    useFacultativoRemoverVariasContribuicoes()
  const { handleInvalidate } = useObterContribuicoesPorFacultativoInvalidate()

  useEffect(() => {
    setRowsSelected([])
  }, [_data])

  async function handleDelete() {
    const constribuicoesIdsRemover = rowsSelected.map((index) => data[index].id)
    await mutateAsyncRemove({ data: constribuicoesIdsRemover })
    closeConfirmDelete()
  }

  function handleAfterSubmitImprimir(event: ServicesFacultativo, diaVencimento: number) {
    let ids = []

    if (event === 'ImprimirContribuicoes') {
      ids = rowsSelected.map((d) => data[d].id)
    } else {
      ids = dataMenuSelected.map((d) => dataMenu[d].id)
    }

    openPDFViewer({
      service: event,
      data: ids,
      params: { diaVencimento },
    })

    setRowsSelected([])
    setDataMenuSelected([])
  }

  async function handleClosePDFViewer() {
    closePDFViewer()
    handleInvalidate()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'anoMes',
      label: 'Ano/Mês',
      options: {
        customBodyRender: formatAnoMes,
      },
    },
    {
      name: 'vrSalarioContribuicao',
      label: 'Salário',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'aliqContribuicao',
      label: 'Alíq.',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrContribuicao',
      label: 'Contribuição',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'dtImpressao',
      label: 'Impressão',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
  ]

  return (
    <StackContainer>
      <Grid container spacing={1} style={{ height: '100%', margin: 0 }}>
        <Grid item xs={12} sm={6} md={5} lg={4} xl={3} style={{ height: '100%' }}>
          <Stack height="100%">
            <MenuSelect
              options={dataMenu}
              onCheckOption={setDataMenuSelected}
              optionsCheckeds={dataMenuSelected}
              onSelect={(index: number) => setContribuinteIndex(index)}
              selectedOption={contribuinteIndex}
              renderOption={(d: Facultativo) => d.nome}
              isLoading={isLoadingMenu}
              searchField="nome"
              idField="id"
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={dataMenuSelected.length > 0 ? false : true}
                  onClick={() => openFormCalcularGPS(dataMenuSelected.map((d) => dataMenu[d].id))}
                  variant="contained"
                  fullWidth
                >
                  Calcular GPS
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ButtonIcon
                  disabled={dataMenuSelected.length > 0 ? false : true}
                  onClick={() => openFormImprimir('ImprimirContribuicoesPorFacultativos')}
                  variant="contained"
                  icon="print"
                  fullWidth
                >
                  Imprimir
                </ButtonIcon>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={8} xl={9} style={{ height: '100%' }}>
          <StackContainer p={0}>
            <PageHeader
              title="Contribuições do Facultativo"
              subtitle={
                contribuinte
                  ? `${formatToNIT(contribuinte?.nrInscricao)} - ${contribuinte?.nome}`
                  : '-'
              }
            >
              <ButtonBox>
                <Button
                  variant="contained"
                  disabled={isContribuinteSelected ? false : true}
                  onClick={() => openFormCalcularGPS([contribuinte?.id || ''])}
                >
                  Calcular GPS
                </Button>
              </ButtonBox>
            </PageHeader>

            <ContainerTable>
              <ToolsDataTable
                data={data}
                columns={columns}
                isLoading={isLoading}
                isFetching={isFetching}
                disableAutoHeight
                pagination={false}
                optionsSelectable={{
                  type: 'multiple',
                  onRowSelected: setRowsSelected,
                  rowsSelected,
                  selectOnClick: true,
                }}
              />
            </ContainerTable>

            <Box
              component={Paper}
              p={1}
              display="flex"
              justifyContent="flex-end"
              gridGap={theme.spacing(1)}
            >
              <ButtonIcon
                disabled={rowsSelected.length > 0 ? false : true}
                onClick={() => openConfirmDelete(null)}
                icon="delete"
              >
                Remover
              </ButtonIcon>

              <ButtonIcon
                disabled={rowsSelected.length > 0 ? false : true}
                onClick={() => openFormImprimir('ImprimirContribuicoes')}
                variant="contained"
                icon="print"
              >
                Imprimir
              </ButtonIcon>
            </Box>

            {isOpenFormCalcularGPS && (
              <FormCalcularGPS
                isOpen={isOpenFormCalcularGPS}
                onClose={closeFormCalcularGPS}
                contribuintesIds={dataFormCalcularGPS}
                afterSubmit={() => setDataMenuSelected([])}
              />
            )}
            {isOpenFormImprimir && (
              <FormImprimir
                isOpen={isOpenFormImprimir}
                onClose={closeFormImprimir}
                event={dataFormImprimir}
                onAfterSubmit={handleAfterSubmitImprimir}
              />
            )}
            {isOpenConfirmDelete && (
              <ConfirmDeleteDialog
                isOpen={isOpenConfirmDelete}
                onCancel={closeConfirmDelete}
                onConfirm={handleDelete}
                isDeleting={isRemoving}
              />
            )}
            {isOpenPDFViewer && (
              <PDFViewer
                isOpen={isOpenPDFViewer}
                onClose={handleClosePDFViewer}
                reportKey="ContribuinteFacultativoGPS"
                title="Imprimir Contribuições"
                axiosConfig={{
                  method: 'post',
                  url: '/Facultativo/' + dataPDFViewer.service,
                  data: dataPDFViewer.data,
                  params: dataPDFViewer.params,
                }}
              />
            )}
          </StackContainer>
        </Grid>
      </Grid>
    </StackContainer>
  )
}
