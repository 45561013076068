import { useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import { ActionDialog, Button, MenuSelect, Stack, StackContainer } from '~/components'

import Content from './Content'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'
import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'

export default function Configuracoes() {
  const [indexSelected, setIndexSelected] = useState(-1)
  const [optionsCheckeds, setOptionsCheckeds] = useState<number[]>([])

  const {
    close: closeContentConfiguracoes,
    data: dataContentConfiguracoes,
    isOpen: isOpenContentConfiguracoes,
    open: openContentConfiguracoes,
  } = useDialog<string[]>([])
  const { anoMes, estabelecimento } = useAmbiente()

  const { data: _d, isLoading: isLoadingMenu } = useEstabelecimentoObterFiltrado({
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
    anoMes,
  })
  const dataMenu = _d || []

  const estabeleCurrent = dataMenu[indexSelected]

  useEffect(() => {
    setIndexSelected(-1)
  }, [estabelecimento, anoMes])

  function handleConfigMultiple() {
    const ids = optionsCheckeds.map((indx) => dataMenu[indx].id)
    openContentConfiguracoes(ids)
  }

  function handleAfterSubmit() {
    closeContentConfiguracoes()
    setOptionsCheckeds([])
  }

  return (
    <StackContainer p={0} orientation="horizontal" mt={-1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <Stack height="100%">
            <Box flex={1} position="relative" minHeight={300} overflow="auto">
              <Box position="absolute" height="100%" width="100%">
                <MenuSelect
                  onCheckOption={setOptionsCheckeds}
                  optionsCheckeds={optionsCheckeds}
                  options={dataMenu}
                  onSelect={(index: number) => setIndexSelected(index)}
                  selectedOption={indexSelected}
                  idField="id"
                  searchField="nome"
                  renderOption={(option: EstabelecimentoConsulta) =>
                    `${option.codigo} - ${option.nome}`
                  }
                  isLoading={isLoadingMenu}
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              disabled={optionsCheckeds.length > 0 ? false : true}
              onClick={handleConfigMultiple}
            >
              Configurar Vários
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          {estabeleCurrent ? (
            <Content estabelecimentoId={estabeleCurrent.id} />
          ) : (
            <Typography>Selecione um Estabelecimento</Typography>
          )}
        </Grid>
      </Grid>
      {isOpenContentConfiguracoes && (
        <ActionDialog
          title="Configurar Múltiplos Estabelecimentos"
          subtitle={'Estabelecimentos selecionados: ' + dataContentConfiguracoes.length}
          isOpen={isOpenContentConfiguracoes}
          onClose={closeContentConfiguracoes}
          dialogProps={{
            maxWidth: 'md',
            fullWidth: true,
          }}
          customActions={<></>}
        >
          <Content
            estabelecimentosIds={dataContentConfiguracoes}
            handleAfterSubmit={handleAfterSubmit}
          />
        </ActionDialog>
      )}
    </StackContainer>
  )
}
