export enum IndMotivoAfastamentoEnum {
  /**01 Acidente/doença do trabalho */
  AcidenteDoenca_01 = 1,

  /**03 Acidente/doença não relacionada ao trabalho */
  AcidenteDoencaTrabalho_03 = 3,

  /**05 Afastamento/licença de servidor público prevista em estatuto, sem remuneração */
  AfastamentoServidorPublico_05 = 5,

  /**06 Aposentadoria por invalidez */
  AposentadoriaInvalidez_06 = 6,

  /**07 Acompanhamento - Licença para acompanhamento de membro da família enfermo */
  Acompanhamento_07 = 7,

  /**08 Afastamento do empregado para participar de atividade do Conselho Curador do FGTS - Art. 65, § 6º, do Decreto 99.684/1990 (Regulamento do FGTS) */
  AfastamentoConselhoCurador_08 = 8,

  /**10 Afastamento/licença de servidor público prevista em estatuto, com remuneração */
  AfastametnoServidorPublico_10 = 10,

  /**11 Cárcere */
  Carcere_11 = 11,

  /**12 Cargo eletivo - Candidato a cargo eletivo - Art. 25, parágrafo único, da Lei 7.664/1988 - Celetistas em geral */
  CargoEletivoCeletista_12 = 12,

  /**13 Cargo eletivo - Candidato a cargo eletivo - Art. 1°, inciso II, alínea "l", da Lei Complementar 64/1990 - Servidor público, estatutário ou não, dos órgãos ou entidades da Administração Direta ou Indireta da União, dos Estados, do Distrito Federal, dos Municípios e dos Territórios, inclusive das fundações mantidas pelo Poder Público */
  CargoEletivo_13 = 13,

  /**15 Gozo de férias ou recesso - Afastamento temporário para o gozo de férias ou recesso */
  Ferias_15 = 15,

  /**16 Licença remunerada - Lei, liberalidade da empresa ou Acordo/Convenção Coletiva de Trabalho */
  LicencaRemunerada_16 = 16,

  /**17 Licença maternidade - 120 dias e suas prorrogações/antecipações, inclusive para o cônjuge sobrevivente */
  LicencaMaternidade_17 = 17,

  /**18 Licença maternidade - Prorrogação por 60 dias, Lei 11.770/2008 (Empresa Cidadã), inclusive para o cônjuge sobrevivente */
  LicencaMaternidade_18 = 18,

  /**19 Licença maternidade - Afastamento temporário por motivo de aborto não criminoso */
  LicencaMaternidade_19 = 19,

  /**20 Licença maternidade - Afastamento temporário por motivo de licença-maternidade decorrente de adoção ou guarda judicial de criança, inclusive para o cônjuge sobrevivente */
  LicencaMaternidade_20 = 20,

  /**21 Licença não remunerada ou sem vencimento */
  LicencaNaoRemunerada_21 = 21,

  /**22 Mandato eleitoral - Afastamento temporário para o exercício de mandato eleitoral, sem remuneração */
  MandatoEleitoral_22 = 22,

  /**23 Mandato eleitoral - Afastamento temporário para o exercício de mandato eleitoral, com remuneração */
  MandatoEleitoral_23 = 23,

  /**24 Mandato sindical - Afastamento temporário para exercício de mandato sindical */
  MandatoSindical_24 = 24,

  /**25 Mulher vítima de violência - Art. 9º, § 2º, inciso II, da Lei 11.340/2006 - Lei Maria da Penha */
  MulherVitimaViolência_25 = 25,

  /**26 Participação de empregado no Conselho Nacional de Previdência Social - CNPS - Art. 3º da Lei 8.213/1991 */
  ParticipacaoEempregadoCNPS_26 = 26,

  /**27 Qualificação - Afastamento por suspensão do contrato de acordo com o art. 476-A da CLT */
  Qualificação_27 = 27,

  /**28 Representante sindical - Afastamento pelo tempo que se fizer necessário, quando, na qualidade de representante de entidade sindical, estiver participando de reunião oficial de organismo internacional do qual o Brasil seja membro */
  RepresentanteSindical_28 = 28,

  /**29 Serviço militar - Afastamento temporário para prestar serviço militar obrigatório */
  ServiçoMilitar_29 = 29,

  /**30 Suspensão disciplinar - Art. 474 da CLT */
  SuspensãoDisciplinar_30 = 30,

  /**31 Servidor público em disponibilidade */
  ServidorPublicDisponibilidade_31 = 31,

  /**33 Licença maternidade - 180 dias, Lei 13.301/2016 */
  LicencaMaternidade_33 = 33,

  /**34 Inatividade do trabalhador avulso (portuário ou não portuário) por período superior a 90 dias */
  Inatividade_34 = 34,

  /**35 Licença maternidade - Antecipação e/ou prorrogação mediante atestado médico */
  LicencaMaternidade_35 = 35,

  /**36 Afastamento temporário de exercente de mandato eletivo para cargo em comissão */
  AfastamentoTemporário_36 = 36,

  /**37 Suspensão temporária do contrato de trabalho nos termos do Programa Emergencial de Manutenção do Emprego e da Renda */
  SuspensãoTemporária_37 = 37,

  /**38 Impedimento de concorrência à escala para trabalho avulso */
  Impedimento_38 = 38,

  /**39 Suspensão de pagamento de servidor público por não recadastramento */
  SuspensãoPagamentoServidor_39 = 39,

  /**40 Exercício em outro órgão de servidor ou empregado público cedido */
  ExercícioOutroOrgao_40 = 40,
}

export const IndMotivoAfastamentoValues = [
  { value: IndMotivoAfastamentoEnum.AcidenteDoenca_01, name: '01 - Acidente/doença do trabalho' },
  {
    value: IndMotivoAfastamentoEnum.AcidenteDoencaTrabalho_03,
    name: '03 - Acidente/doença não relacionada ao trabalho',
  },
  {
    value: IndMotivoAfastamentoEnum.AfastamentoServidorPublico_05,
    name: '05 - Afastamento/licença de servidor público prevista em estatuto, sem remuneração',
  },
  {
    value: IndMotivoAfastamentoEnum.AposentadoriaInvalidez_06,
    name: '06 - Aposentadoria por invalidez',
  },
  {
    value: IndMotivoAfastamentoEnum.Acompanhamento_07,
    name: '07 - Acompanhamento - Licença para acompanhamento de membro da família enfermo',
  },
  {
    value: IndMotivoAfastamentoEnum.AfastamentoConselhoCurador_08,
    name: '08 - Afastamento do empregado para participar de atividade do Conselho Curador do FGTS - Art. 65, § 6º, do Decreto 99.684/1990 (Regulamento do FGTS)',
  },
  {
    value: IndMotivoAfastamentoEnum.AfastametnoServidorPublico_10,
    name: '10 - Afastamento/licença de servidor público prevista em estatuto, com remuneração',
  },
  { value: IndMotivoAfastamentoEnum.Carcere_11, name: '11 - Cárcere' },
  {
    value: IndMotivoAfastamentoEnum.CargoEletivoCeletista_12,
    name: '12 - Cargo eletivo - Candidato a cargo eletivo - Art. 25, parágrafo único, da Lei 7.664/1988 - Celetistas em geral',
  },
  {
    value: IndMotivoAfastamentoEnum.CargoEletivo_13,
    name: '13 - Cargo eletivo - Candidato a cargo eletivo - Art. 1°, inciso II, alínea "l", da Lei Complementar 64/1990 - Servidor público, estatutário ou não, dos órgãos ou entidades da Administração Direta ou Indireta da União, dos Estados, do Distrito Federal, dos Municípios e dos Territórios, inclusive das fundações mantidas pelo Poder Público',
  },
  {
    value: IndMotivoAfastamentoEnum.Ferias_15,
    name: '15 - Gozo de férias ou recesso - Afastamento temporário para o gozo de férias ou recesso',
  },
  {
    value: IndMotivoAfastamentoEnum.LicencaRemunerada_16,
    name: '16 - Licença remunerada - Lei, liberalidade da empresa ou Acordo/Convenção Coletiva de Trabalho',
  },
  {
    value: IndMotivoAfastamentoEnum.LicencaMaternidade_17,
    name: '17 - Licença maternidade - 120 dias e suas prorrogações/antecipações, inclusive para o cônjuge sobrevivente',
  },
  {
    value: IndMotivoAfastamentoEnum.LicencaMaternidade_18,
    name: '18 - Licença maternidade - Prorrogação por 60 dias, Lei 11.770/2008 (Empresa Cidadã), inclusive para o cônjuge sobrevivente',
  },
  {
    value: IndMotivoAfastamentoEnum.LicencaMaternidade_19,
    name: '19 - Licença maternidade - Afastamento temporário por motivo de aborto não criminoso',
  },
  {
    value: IndMotivoAfastamentoEnum.LicencaMaternidade_20,
    name: '20 - Licença maternidade - Afastamento temporário por motivo de licença-maternidade decorrente de adoção ou guarda judicial de criança, inclusive para o cônjuge sobrevivente',
  },
  {
    value: IndMotivoAfastamentoEnum.LicencaNaoRemunerada_21,
    name: '21 - Licença não remunerada ou sem vencimento',
  },
  {
    value: IndMotivoAfastamentoEnum.MandatoEleitoral_22,
    name: '22 - Mandato eleitoral - Afastamento temporário para o exercício de mandato eleitoral, sem remuneração',
  },
  {
    value: IndMotivoAfastamentoEnum.MandatoEleitoral_23,
    name: '23 - Mandato eleitoral - Afastamento temporário para o exercício de mandato eleitoral, com remuneração',
  },
  {
    value: IndMotivoAfastamentoEnum.MandatoSindical_24,
    name: '24 - Mandato sindical - Afastamento temporário para exercício de mandato sindical',
  },
  {
    value: IndMotivoAfastamentoEnum.MulherVitimaViolência_25,
    name: '25 - Mulher vítima de violência - Art. 9º, § 2º, inciso II, da Lei 11.340/2006 - Lei Maria da Penha',
  },
  {
    value: IndMotivoAfastamentoEnum.ParticipacaoEempregadoCNPS_26,
    name: '26 - Participação de empregado no Conselho Nacional de Previdência Social - CNPS - Art. 3º da Lei 8.213/1991',
  },
  {
    value: IndMotivoAfastamentoEnum.Qualificação_27,
    name: '27 - Qualificação - Afastamento por suspensão do contrato de acordo com o art. 476-A da CLT',
  },
  {
    value: IndMotivoAfastamentoEnum.RepresentanteSindical_28,
    name: '28 - Representante sindical - Afastamento pelo tempo que se fizer necessário, quando, na qualidade de representante de entidade sindical, estiver participando de reunião oficial de organismo internacional do qual o Brasil seja membro',
  },
  {
    value: IndMotivoAfastamentoEnum.ServiçoMilitar_29,
    name: '29 - Serviço militar - Afastamento temporário para prestar serviço militar obrigatório',
  },
  {
    value: IndMotivoAfastamentoEnum.SuspensãoDisciplinar_30,
    name: '30 - Suspensão disciplinar - Art. 474 da CLT',
  },
  {
    value: IndMotivoAfastamentoEnum.ServidorPublicDisponibilidade_31,
    name: '31 - Servidor público em disponibilidade',
  },
  {
    value: IndMotivoAfastamentoEnum.LicencaMaternidade_33,
    name: '33 - Licença maternidade - 180 dias, Lei 13.301/2016',
  },
  {
    value: IndMotivoAfastamentoEnum.Inatividade_34,
    name: '34 - Inatividade do trabalhador avulso (portuário ou não portuário) por período superior a 90 dias',
  },
  {
    value: IndMotivoAfastamentoEnum.LicencaMaternidade_35,
    name: '35 - Licença maternidade - Antecipação e/ou prorrogação mediante atestado médico',
  },
  {
    value: IndMotivoAfastamentoEnum.AfastamentoTemporário_36,
    name: '36 - Afastamento temporário de exercente de mandato eletivo para cargo em comissão',
  },
  {
    value: IndMotivoAfastamentoEnum.SuspensãoTemporária_37,
    name: '37 - Suspensão temporária do contrato de trabalho nos termos do Programa Emergencial de Manutenção do Emprego e da Renda',
  },
  {
    value: IndMotivoAfastamentoEnum.Impedimento_38,
    name: '38 - Impedimento de concorrência à escala para trabalho avulso',
  },
  {
    value: IndMotivoAfastamentoEnum.SuspensãoPagamentoServidor_39,
    name: '39 - Suspensão de pagamento de servidor público por não recadastramento',
  },
  {
    value: IndMotivoAfastamentoEnum.ExercícioOutroOrgao_40,
    name: '40 - Exercício em outro órgão de servidor ou empregado público cedido',
  },
]
