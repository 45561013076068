import { Box, Grid, Paper, Tab, Tabs, useTheme } from '@material-ui/core'
import { Checkbox, Finder, PageHeader, TabPanel } from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import Apuracao from './Apuracao'
import Consulta from './Consulta'

import { TabType, useValeTransporteApuracaoContext } from './Context'

export default function ValeTransporteApuracao() {
  const { grupoId, isDescontarFaltas, tab, setQuery, setGrupoId, setDescontarFaltas, setTab } =
    useValeTransporteApuracaoContext()

  const theme = useTheme()

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(0.5)}
    >
      <PageHeader title="Apuração do Vale Transporte">
        <Box>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => setTab(value)}
          >
            <Tab label="Apuração" />
            <Tab label="Consulta" />
          </Tabs>
        </Box>
      </PageHeader>

      <Box display="flex" gridGap={theme.spacing(1)}>
        <Box flex={1} component={Paper} p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={8}>
              <AutoCompleteGrupo
                value={grupoId}
                onChange={(e, grupo) => {
                  const grupoId = grupo?.id || null
                  setGrupoId(grupoId)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            </Grid>
          </Grid>
        </Box>

        {tab === TabType.Apuracao && (
          <Box component={Paper} p={1}>
            <Checkbox
              label="Descontar Vale quando Funcionário Falta"
              value={isDescontarFaltas}
              onChange={(_, checked) => setDescontarFaltas(checked)}
            />
          </Box>
        )}
      </Box>

      <Box flex={1}>
        <TabPanel value={tab} index={TabType.Apuracao} style={{ height: '100%' }}>
          <Apuracao />
        </TabPanel>
        <TabPanel value={tab} index={TabType.Consulta} style={{ height: '100%' }}>
          <Consulta />
        </TabPanel>
      </Box>
    </Box>
  )
}
