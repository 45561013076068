import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { CondicaoAmbienteTrabalhoAgenteNocivoEPIPartial } from '~/hooks/queries/CondicaoAmbienteTrabalhoAgenteNocivoEPI/CondicaoAmbienteTrabalhoAgenteNocivoEPI'

interface TableProps {
  data: CondicaoAmbienteTrabalhoAgenteNocivoEPIPartial[]
  onItemClick: (event: 'delete', index: number) => void
}

export default function Table(props: TableProps) {
  const { data: _data, onItemClick } = props

  const data = _data?.map((item) => {
    return {
      ...item,
      certificadoAvaliacao: item?.epi?.certificadoAvaliacao || '',
      descricao: item?.epi?.descricao || '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'certificadoAvaliacao',
      label: 'Certificado de Avaliação',
    },
    {
      name: 'descricao',
      label: 'Descrição EPI',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', rowIndex)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} />
}
