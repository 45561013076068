import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { AutoCompleteHorarioTrabalho } from '~/components/AutoComplete'
import { MultipleSelect } from '~/components'
import useValidationErrors from '~/hooks/useValidationErrors'
import * as yup from 'yup'

import { diaHorarioValues } from '~/values/diaHorarioValues'

const schema = yup.object().shape({
  diasSemana: yup.string().required('Informe os Dias da semana trabalhado'),
  horarioTrabalho: yup.string().required('Informe o Horário de trabalho').nullable(),
})

const FormItem = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitFormItem, empregadorId } = props
  const [data, setData] = useState({})

  useEffect(() => {
    setData(_data)
    //eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    const newData = data.diasSemana.map((dia) => {
      return {
        diaHorario: dia.value,
        horarioTrabalhoId: data.horarioTrabalhoId,
        horarioTrabalho: data.horarioTrabalho,
      }
    })

    onAfterSubmitFormItem(newData)

    //eslint-disable-next-line
  }, [data, onAfterSubmitFormItem])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Dia da semana trabalhado"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MultipleSelect
            inputProps={{
              label: 'Dias da semana trabalhado',
              required: true,
            }}
            validationErrors={validationErrors}
            name="diasSemana"
            value={data?.diasSemana || [] || data?.diaHorario || []}
            options={diaHorarioValues}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect
            onChange={(e, selecteds) => {
              const diasSemana = selecteds
              setData({ ...data, diasSemana })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteHorarioTrabalho
            value={data?.horarioTrabalho || null}
            empregadorId={empregadorId}
            required
            validationErrors={validationErrors}
            name="horarioTrabalho"
            label="Horário de trabalho"
            onChange={(e, horarioTrabalho) => {
              const horarioTrabalhoId = horarioTrabalho?.id || null
              setData({ ...data, horarioTrabalhoId, horarioTrabalho })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default FormItem
