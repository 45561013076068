import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'

import useAmbiente from '~/hooks/useAmbiente'

import { ListboxComponentVirtualized, MUIAutoComplete } from '~/components/AutoComplete'
import { EventoConsulta } from '~/hooks/queries/EventoConsulta/EventoConsulta'
import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'
import { IndEventoLabels } from '~/@types/enums/IndEventoEnum'
import { EventoIndCalculoLabels } from '~/@types/enums/EventoIndCalculoEnum'
import { EventoIndReferenciaLabels } from '~/@types/enums/EventoIndReferenciaEnum'
import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'

interface AutoCompleteEventoEmpregadorProps extends MUIAutoCompleteProps {
  value: number | string | EventoConsulta | null
  onChange: (value: EventoConsulta | null) => void
  inputRef?: any
  onBlur?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  InputLabelProps?: any
  autoFocus?: boolean
  disabled?: boolean
  required?: boolean
  variant?: any
  InputProps?: any
  optionId?: string
  label?: string
}

export default function AutoCompleteEventoEmpregador(props: AutoCompleteEventoEmpregadorProps) {
  const { value, onChange, ...rest } = props

  const [options, setOptions] = useState<EventoConsulta[]>([])
  const [isLoading, setLoading] = useState(false)

  const { empregador } = useAmbiente()

  useEffect(() => {
    setOptions([])
    onChange(null)
    // eslint-disable-next-line
  }, [empregador])

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/Evento/GetByEmpregador', {
        params: {
          empregadorId: empregador.id,
        },
      })
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option: EventoConsulta) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      ListboxComponent={ListboxComponentVirtualized}
      ListboxProps={{ childSize: 80 }}
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label="Evento"
      value={value}
      onChange={(_, obj: EventoConsulta) => onChange(obj)}
      optionId="codigo"
      renderOptionComponent={(option: EventoConsulta) => (
        <AutoCompleteOptionDescription
          title={`${option.codigo} - ${option.nome}`}
          descriptions={[
            {
              description: `Indicador: ${IndEventoLabels[option.indEvento]}`,
            },
            {
              description: `Cálculo: ${EventoIndCalculoLabels[option.indCalculo]} - Tipo: ${
                EventoIndReferenciaLabels[option.indReferencia]
              }`,
            },
          ]}
        />
      )}
      {...rest}
    />
  )
}
