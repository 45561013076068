import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog, CurrencyTextField } from '~/components'

const FormSalarios = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitFormSalarios } = props
  const [data, setData] = useState({})

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    onAfterSubmitFormSalarios(data)
  }, [data, onAfterSubmitFormSalarios])

  return (
    <ActionDialog
      title="Alterar Salários"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CurrencyTextField
            label="Último Salário"
            fullWidth
            value={data?.ultimoSalario || 0}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const ultimoSalario = value || 0
              setData((oldState) => ({ ...oldState, ultimoSalario }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CurrencyTextField
            label="Penúltimo Salário"
            fullWidth
            value={data?.penultimoSalario || 0}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const penultimoSalario = value || 0
              setData((oldState) => ({ ...oldState, penultimoSalario }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CurrencyTextField
            label="Antepenúltimo Salário"
            fullWidth
            value={data?.antePenultimoSalario || 0}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const antePenultimoSalario = value || 0
              setData((oldState) => ({ ...oldState, antePenultimoSalario }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default FormSalarios
