import { useState, useEffect } from 'react'

import { LinearProgress } from '@material-ui/core'

import { ContainerComponent } from '~/components'

import Form from '~/pages/PessoaFisica/components/Container/Form'
import PessoaFisicaDependente from '~/pages/PessoaFisica/components/Container/PessoaFisicaDependente'
import ContaCorrente from '~/pages/PessoaFisica/components/Container/ContaCorrente'

import useDialogNotification from '~/hooks/useDialogNotification'
import { getPessoaFisicaById } from '~/hooks/queries/usePessoaFisica'
import { pessoaFisicaCheckUso } from '~/hooks/queries/PessoaFisica/pessoaFisicaCheckUso'

interface ContainerPessoaFisicaProps {
  pessoaFisicaId: string
}
export default function ContainerPessoaFisica(props: ContainerPessoaFisicaProps) {
  const { pessoaFisicaId } = props

  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isUsed, setUsed] = useState(false)

  const dialogNotification = useDialogNotification()

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const response = await getPessoaFisicaById(pessoaFisicaId)
        const _isUsed = await pessoaFisicaCheckUso(pessoaFisicaId)
        setUsed(_isUsed)
        setData(response)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getData()
    // eslint-disable-next-line
  }, [pessoaFisicaId])

  if (isLoading) {
    return <LinearProgress />
  }

  if (!data) {
    return <></>
  }

  return (
    <ContainerComponent
      fixedContainer
      removeAutoSize
      tabs={[
        {
          component: <Form data={data} isUsed={isUsed} />,
          label: 'Cadastro',
        },
        {
          component: <PessoaFisicaDependente pessoaFisicaId={pessoaFisicaId} isUsed={isUsed} />,
          disabled: !pessoaFisicaId ? true : false,
          label: 'Dependentes',
        },
        {
          component: <ContaCorrente pessoaFisicaId={pessoaFisicaId} />,
          disabled: !pessoaFisicaId ? true : false,
          label: 'Conta Corrente',
        },
      ]}
    />
  )
}
