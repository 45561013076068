import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import Table from './components/Table'
import InitialForm from './components/InitialForm'
import Form from './components/Form'

import { ConfirmDeleteDialog, ActionDialog } from '~/components'

import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import { usePessoaFisicaMutate } from '~/hooks/queries/usePessoaFisica'

function PessoaFisicaSST() {
  const [query, setQuery] = useState('')

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog({ pessoaFisicaId: null, nrInscricao: null })
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog(null)
  const {
    close: closeInitialForm,
    isOpen: isOpenInitialForm,
    open: openInitialForm,
  } = useDialog(null)
  const { mutateDelete } = usePessoaFisicaMutate()
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  function handleClickItem(event, value) {
    const handleClickEditItem = async (id) => {
      openForm({
        pessoaFisicaId: id,
        nrInscricao: null,
      })
    }

    const handleClickDeleteItem = (id) => {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  async function handleConfirmDelete() {
    await mutateDelete.mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
    notification.remove()
  }

  function handleAfterSubmitInitialForm(event, result) {
    switch (event) {
      case 'registered':
        dialogNotification.info({
          descriptions: [
            `A pessoa ${result.nome} já está cadastrada no sistema!`,
            'Você deseja acessar o cadastro?',
          ],
          onConfirm: () =>
            openForm({
              pessoaFisicaId: result.id,
              nrInscricao: null,
            }),
          labelOnConfirm: 'Sim',
          labelOnCancel: 'Não',
        })
        break
      case 'unregistered':
        openForm({
          pessoaFisicaId: null,
          nrInscricao: result,
        })
        break
      default:
        break
    }
  }

  return (
    <Box padding={2}>
      <Box height={50}>
        <PageHeader title="Pessoa Física">
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <Button
              size="small"
              color="primary"
              onClick={() => openInitialForm(null)}
              variant="contained"
            >
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Table query={query} onItemClick={handleClickItem} />

      {isOpenForm ? (
        <ActionDialog
          title="Cadastro de Pessoa Física"
          isOpen={isOpenForm}
          onClose={closeForm}
          onCancelClick={closeForm}
          customActions={<></>}
          dialogProps={{
            maxWidth: 'xs',
            fullWidth: true,
          }}
        >
          <Form
            pessoaFisicaId={dataForm.pessoaFisicaId}
            nrInscricao={dataForm.nrInscricao}
            onClose={closeForm}
          />
        </ActionDialog>
      ) : (
        <></>
      )}

      {isOpenInitialForm ? (
        <ActionDialog
          title="Cadastro de Pessoa Física"
          isOpen={isOpenInitialForm}
          onClose={closeInitialForm}
          onCancelClick={closeInitialForm}
          customActions={<></>}
          dialogProps={{
            maxWidth: 'xs',
            fullWidth: true,
          }}
        >
          <InitialForm onAfterSubmit={handleAfterSubmitInitialForm} onClose={closeInitialForm} />
        </ActionDialog>
      ) : (
        <></>
      )}

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={mutateDelete.isLoading}
        onCancel={closeConfirmDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  )
}

export default PessoaFisicaSST
