import React, { useState, useEffect, useCallback } from 'react'

import * as yup from 'yup'
import { isCNPJ } from 'brazilian-values'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog, NumeroInscricaoTextField } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import { AutoCompleteEvento } from '~/components/AutoComplete'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { indClassficacaoEventoConsts } from '~/values/indClassficacaoEventoValues'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const { planoSaude } = indClassficacaoEventoConsts

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome.').nullable(),
  registroANS: yup.string().required('Informe o Registro ANS.').nullable(),
  mensalidadeTitularEvento: yup
    .string()
    .required('Informe o Evento Mensalidade Títular.')
    .nullable(),
  custoTitularEvento: yup.string().required('Informe o Evento Custo Títular.').nullable(),
  mensalidadeDependenteEvento: yup
    .string()
    .required('Informe o Evento Mensalidade Dependente.')
    .nullable(),
  custoDependenteEvento: yup.string().required('Informe o Evento Custo Dependente.').nullable(),
  custoEmpresaEvento: yup.string().required('Informe o Evento Custo Empresa.').nullable(),
  nrInscricao: yup
    .string()
    .required('Informe o CNPJ Operadora')
    .test('cnpj-test', 'Informe um CNPJ válido', (value) => isCNPJ(value))
    .nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/planoSaude/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/planoSaude', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Plano de Saúde"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={1} lg={1} md={2} sm={2} xs={12}>
          <TextField
            label="Código"
            fullWidth
            value={data.codigo || ''}
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xl={8} lg={8} md={10} sm={10} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            autoFocus
            value={data.nome || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="nome"
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
          <TextField
            label="Registro ANS"
            fullWidth
            value={data.registroANS || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="registroANS"
            inputProps={{
              maxLength: 6,
            }}
            onChange={(e) => {
              const registroANS = parseInt(e?.target.value.replace(/\D/gim, '')) || ''
              setData({ ...data, registroANS })
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <NumeroInscricaoTextField
            label="CNPJ Operadora"
            typeMask={TipoInscricaoEnum.CNPJ_1}
            value={data.nrInscricao || ''}
            required
            validationErrors={validationErrors}
            name="nrInscricao"
            onChange={(e, value) => {
              const nrInscricao = value
              setData((prev) => ({ ...prev, nrInscricao }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <AutoCompleteEvento
            label="Evento Mensalidade Titular"
            required
            classificacao={planoSaude}
            validationErrors={validationErrors}
            name="mensalidadeTitularEvento"
            value={data?.mensalidadeTitularEvento || null}
            onChange={(e, mensalidadeTitularEvento) => {
              const mensalidadeTitularEventoId = mensalidadeTitularEvento?.id || null
              setData({
                ...data,
                mensalidadeTitularEventoId,
                mensalidadeTitularEvento,
              })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <AutoCompleteEvento
            label="Evento Custo Titular"
            required
            classificacao={planoSaude}
            validationErrors={validationErrors}
            name="custoTitularEvento"
            value={data?.custoTitularEvento || null}
            onChange={(e, custoTitularEvento) => {
              const custoTitularEventoId = custoTitularEvento?.id || null
              setData({ ...data, custoTitularEventoId, custoTitularEvento })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <AutoCompleteEvento
            label="Evento Mensalidade Dependente"
            required
            classificacao={planoSaude}
            validationErrors={validationErrors}
            name="mensalidadeDependenteEvento"
            value={data?.mensalidadeDependenteEvento || null}
            onChange={(e, mensalidadeDependenteEvento) => {
              const mensalidadeDependenteEventoId = mensalidadeDependenteEvento?.id || null
              setData({
                ...data,
                mensalidadeDependenteEventoId,
                mensalidadeDependenteEvento,
              })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <AutoCompleteEvento
            label="Evento Custo Dependente"
            required
            classificacao={planoSaude}
            validationErrors={validationErrors}
            name="custoDependenteEvento"
            value={data?.custoDependenteEvento || null}
            onChange={(e, custoDependenteEvento) => {
              const custoDependenteEventoId = custoDependenteEvento?.id || null
              setData({
                ...data,
                custoDependenteEventoId,
                custoDependenteEvento,
              })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteEvento
            label="Evento Custo Empresa"
            required
            classificacao={planoSaude}
            validationErrors={validationErrors}
            name="custoEmpresaEvento"
            value={data?.custoEmpresaEvento || null}
            onChange={(e, custoEmpresaEvento) => {
              const custoEmpresaEventoId = custoEmpresaEvento?.id || null
              setData({ ...data, custoEmpresaEventoId, custoEmpresaEvento })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
