import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import apiPessoal from '~/services/api-pessoal'
import { ContentContainer, PageHeader, ButtonBox } from 'mio-library-ui'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {},

  container: {
    padding: theme.spacing(2),
  },

  grid: {
    marginTop: 10,
  },

  button: {
    marginTop: 15,
  },
}))

const CadastroCTBClassificacao = (props) => {
  const currentId = props.match.params.id
  const [current, setCurrent] = useState({})
  const [isProcessing, setIsProcessing] = useState(false)

  const notifications = useNotification()
  const dialogNotification = useDialogNotification()
  const history = useHistory()

  const pageTitle = 'CTB Classificação'
  const apiRoute = '/ctbClassificacao'

  useEffect(() => {
    const getDados = async () => {
      try {
        const response = await apiPessoal.get(`${apiRoute}/${currentId}`)
        const { data } = response.data
        setCurrent(data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    }
    getDados()
    // eslint-disable-next-line
  }, [currentId])

  const classes = useStyles()

  const handleSave = async () => {
    setIsProcessing(true)

    try {
      if (!current.codigo) {
        notifications.warning('Insira o Código do ' + pageTitle, 'Ops!', 4000)

        setIsProcessing(false)
        return
      }

      if (!current.nome) {
        notifications.warning('Insira o Nome do ' + pageTitle, 'Ops!', 4000)

        setIsProcessing(false)
        return
      }

      const response = await apiPessoal.put(`${apiRoute}/${current.id}`, JSON.stringify(current))

      if (response.status === 200) {
        notifications.success('Os dados foram atualizados', 'Sucesso!', 4000)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsProcessing(false)
  }

  return (
    <ContentContainer className={classes.container}>
      <PageHeader
        title={'Cadastro de ' + pageTitle}
        subtitle={current?.nome}
        onBack={() => history.goBack()}
      >
        <ButtonBox>
          <Button
            color="primary"
            size="small"
            variant="contained"
            className={classes.button}
            onClick={handleSave}
            isLoading={isProcessing}
          >
            Salvar
          </Button>
        </ButtonBox>
      </PageHeader>
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xl={1} lg={1} md={2} sm={3} xs={12}>
          <TextField
            label="Código"
            fullWidth
            value={current.codigo || ''}
            variant="outlined"
            size="small"
            onlyNumber
            required
            onChange={(e) => {
              const codigo = e.target.value
              setCurrent({ ...current, codigo })
            }}
          />
        </Grid>

        <Grid item xl={11} lg={11} md={10} sm={9} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={current.nome || ''}
            variant="outlined"
            size="small"
            required
            onChange={(e) => {
              const nome = e.target.value
              setCurrent({ ...current, nome })
            }}
          />
        </Grid>

        {/* ------ Provisao Ferias */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Provisão Férias</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.provisaoFeriasDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.provisaoFeriasCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.provisaoFeriasHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Provisão Férias FGTS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.provisaoFeriasFGTSDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasFGTSDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasFGTSDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.provisaoFeriasFGTSCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasFGTSCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasFGTSCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.provisaoFeriasFGTSHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasFGTSHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasFGTSHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Provisão Férias INSS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.provisaoFeriasINSSDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasINSSDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasINSSDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.provisaoFeriasINSSCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasINSSCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasINSSCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.provisaoFeriasINSSHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasINSSHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasINSSHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Provisão Férias PIS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.provisaoFeriasPISDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasPISDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasPISDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.provisaoFeriasPISCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasPISCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasPISCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.provisaoFeriasPISHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoFeriasPISHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoFeriasPISHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ Provisao Decimo Terceiro */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Provisão Décimo Terceiro</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.provisaoDtSalarioDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.provisaoDtSalarioCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.provisaoDtSalarioHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Provisão Décimo Terceiro FGTS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.provisaoDtSalarioFGTSDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioFGTSDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioFGTSDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.provisaoDtSalarioFGTSCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioFGTSCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioFGTSCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.provisaoDtSalarioFGTSHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioFGTSHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioFGTSHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Provisão Décimo Terceiro INSS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.provisaoDtSalarioINSSDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioINSSDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioINSSDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.provisaoDtSalarioINSSCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioINSSCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioINSSCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.provisaoDtSalarioINSSHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioINSSHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioINSSHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Provisão Décimo Terceiro PIS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.provisaoDtSalarioPISDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioPISDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioPISDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.provisaoDtSalarioPISCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioPISCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioPISCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.provisaoDtSalarioPISHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const provisaoDtSalarioPISHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, provisaoDtSalarioPISHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ FGTS */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>FGTS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.fgtsDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const fgtsDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, fgtsDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.fgtsCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const fgtsCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, fgtsCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.fgtsHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const fgtsHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, fgtsHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ GRFC */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>GRFC</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.grfcDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const grfcDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, grfcDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.grfcCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const grfcCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, grfcCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.grfcHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const grfcHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, grfcHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ Multa GRFC */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Multa GRFC</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.multaGRFCDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const multaGRFCDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, multaGRFCDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.multaGRFCCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const multaGRFCCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, multaGRFCCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.multaGRFCHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const multaGRFCHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, multaGRFCHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ GRFC Mes Anterior */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>GRFC Mês Anterior</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.grfcMesAnteriorDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const grfcMesAnteriorDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, grfcMesAnteriorDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.grfcMesAnteriorCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const grfcMesAnteriorCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, grfcMesAnteriorCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.grfcMesAnteriorHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const grfcMesAnteriorHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, grfcMesAnteriorHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ Patronal */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Patronal</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.patronalDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const patronalDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, patronalDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.patronalCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const patronalCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, patronalCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.patronalHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const patronalHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, patronalHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ Terceiros */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Terceiros</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.terceirosDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const terceirosDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, terceirosDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.terceirosCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const terceirosCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, terceirosCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.terceirosHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const terceirosHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, terceirosHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ GRFC Aviso Prévio */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>GRFC Aviso Prévio</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.grfcAvisoPrevioDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const grfcAvisoPrevioDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, grfcAvisoPrevioDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.grfcAvisoPrevioCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const grfcAvisoPrevioCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, grfcAvisoPrevioCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.grfcAvisoPrevioHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const grfcAvisoPrevioHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, grfcAvisoPrevioHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ PIS' */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>PIS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.pisDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const pisDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, pisDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.pisCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const pisCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, pisCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.pisHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const pisHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, pisHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ Reversao Ferias */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Reversão Férias</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.reversaoFeriasDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoFeriasDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoFeriasDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.reversaoFeriasCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoFeriasCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoFeriasCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.reversaoFeriasHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoFeriasHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoFeriasHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Reversão Férias FGTS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.reversaoFeriasFGTSDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoFeriasFGTSDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoFeriasFGTSDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.reversaoFeriasFGTSCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoFeriasFGTSCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoFeriasFGTSCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.reversaoFeriasFGTSHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoFeriasFGTSHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoFeriasFGTSHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Reversão Férias INSS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.reversaoFeriasINSSDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoFeriasINSSDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoFeriasINSSDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.reversaoFeriasINSSCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoFeriasINSSCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoFeriasINSSCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.reversaoFeriasINSSHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoFeriasINSSHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoFeriasINSSHP })
              }}
            />
          </Grid>
        </Grid>

        {/* ------ Reversao Decimo Terceiro */}
        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Reversão Décimo Terceiro</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.reversaoDtSalarioDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoDtSalarioDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoDtSalarioDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.reversaoDtSalarioCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoDtSalarioCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoDtSalarioCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.reversaoDtSalarioHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoDtSalarioHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoDtSalarioHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Reversão Férias FGTS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.reversaoDtSalarioFGTSDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoDtSalarioFGTSDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoDtSalarioFGTSDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.reversaoDtSalarioFGTSCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoDtSalarioFGTSCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoDtSalarioFGTSCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.reversaoDtSalarioFGTSHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoDtSalarioFGTSHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoDtSalarioFGTSHP })
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Reversão Férias INSS</strong>
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Débito"
              fullWidth
              value={current.reversaoDtSalarioINSSDebito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoDtSalarioINSSDebito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoDtSalarioINSSDebito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              label="Crédito"
              fullWidth
              value={current.reversaoDtSalarioINSSCredito || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoDtSalarioINSSCredito = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoDtSalarioINSSCredito })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <TextField
              disabled
              label="Histórico Padrão"
              fullWidth
              value={current.reversaoDtSalarioINSSHP || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const reversaoDtSalarioINSSHP = e.target.value ? e.target.value : null
                setCurrent({ ...current, reversaoDtSalarioINSSHP })
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </ContentContainer>
  )
}

export default CadastroCTBClassificacao
