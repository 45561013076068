import { Button } from 'mio-library-ui'
import { makeStyles, Box, CircularProgress } from '@material-ui/core'
import { FileCopy as FileCopyIcon } from '@material-ui/icons'
import XMLViewer from 'react-xml-viewer'

import { ToolsDataTable, ActionDialog } from '~/components'

import useObterAuditoriaXML from '~/hooks/queries/ESocial/useObterAuditoriaXML'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

const useStyles = makeStyles((theme) => ({
  hightLightContent: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  heightPaper: {
    height: '100%',
  },
  contentDialog: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflow: 'auto',
    height: '100%',
  },
}))

function XMLContent(props) {
  const { value } = props
  return <XMLViewer xml={value} />
}

export function ErrosSchemaContent(props) {
  const { value: values } = props

  const columns = [
    {
      name: 'name',
      label: 'Nome',
    },
    {
      name: 'value',
      label: 'Valor',
    },
    {
      name: 'message',
      label: 'Mensagem',
    },
  ]

  return <ToolsDataTable data={values} columns={columns} pagination={false} />
}

export function OcorrenciasContent(props) {
  const { value: values } = props

  const newValues = values.map((stringOcorrencia) => ({
    ocorrencia: stringOcorrencia,
  }))

  const columns = [
    {
      name: 'ocorrencia',
      label: 'Ocorrências',
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: 'pre-line',
          },
        }),
      },
    },
  ]

  return <ToolsDataTable data={newValues} columns={columns} pagination={false} />
}

export default function TableCellContent(props) {
  const { auditoriaId, children, type, subtitle } = props

  const { mutateAsync: mutateAsyncObterAuditoriaXML, isLoading: isLoadingObterAuditoriaXML } =
    useObterAuditoriaXML()

  const classes = useStyles()
  const notification = useNotification()
  const {
    close: closeDialog,
    data: dataDialog,
    isOpen: isOpenDialog,
    open: openDialog,
  } = useDialog(null)

  async function handleClickCellContent() {
    const response = await mutateAsyncObterAuditoriaXML(auditoriaId)
    if (type === 'xmlEnvio') return openDialog(response.xmlEnvio)
    if (type === 'xmlRetorno') return openDialog(response.retorno_Xml)
    if (type === 'errosSchema') return openDialog(response.errosSchema)
    if (type === 'ocorrencias') return openDialog(response.ocorrencias)
  }

  function renderContent() {
    if (!dataDialog) return <div>Registro não encontrado</div>
    if (type === 'xmlEnvio') return <XMLContent value={dataDialog} />
    if (type === 'xmlRetorno') return <XMLContent value={dataDialog} />
    if (type === 'errosSchema') return <ErrosSchemaContent value={dataDialog} />
    if (type === 'ocorrencias') return <OcorrenciasContent value={dataDialog} />
  }

  const handleCopy = (copy) => {
    if (!navigator.clipboard)
      return notification.error('Não foi possível copiar o conteúdo clicado')
    navigator.clipboard.writeText(copy)
    notification.success('Conteúdo copiado para a Área de transferência')
  }

  return (
    <>
      <Box
        className={classes.hightLightContent}
        title={dataDialog || ''}
        onClick={handleClickCellContent}
      >
        <Box width={100}>
          {isLoadingObterAuditoriaXML ? <CircularProgress size={30} /> : children}
        </Box>
      </Box>
      {isOpenDialog && (
        <ActionDialog
          title={children}
          subtitle={subtitle}
          isOpen={isOpenDialog}
          onClose={closeDialog}
          renderRight={
            type === 'xml' && (
              <Button
                onClick={() => handleCopy(dataDialog)}
                size="small"
                title="Copiar"
                color="primary"
                variant="outlined"
              >
                <Box paddingRight={1}>Copiar</Box>
                <FileCopyIcon fontSize="small" />
              </Button>
            )
          }
          customActions={<></>}
          dialogProps={{
            fullWidth: true,
            maxWidth: 'md',
            classes: {
              paper: classes.heightPaper,
            },
          }}
        >
          <Box className={classes.contentDialog}>{renderContent()}</Box>
        </ActionDialog>
      )}
    </>
  )
}
