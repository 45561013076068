import React, { useState, useEffect } from 'react'

import { ContentDivider } from 'mio-library-ui'
import { Box, Collapse, IconButton } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import { ContentFrame } from '~/components'

import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props) => theme.spacing(props?.top || 0),
    marginRight: (props) => theme.spacing(props?.right || 0),
    marginBottom: (props) => theme.spacing(props?.bottom || 0),
    marginLeft: (props) => theme.spacing(props?.left || 0),
  },
  header: {},
  iconButton: {
    transition: theme.transitions.create(['transform'], {
      duration: 500,
    }),
  },
  iconButtonOpened: {
    transform: 'rotate(-180deg)',
  },
  iconButtonClosed: {
    transform: 'rotate(0)',
  },
}))

const CollapseContent = (props) => {
  const { children, title, isOpen: _isOpen, showContentFrame, onClick, renderRight } = props
  const classes = useStyles(props)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(_isOpen)
  }, [_isOpen])

  const handleToggle = () => {
    if (onClick) {
      onClick()
      return
    }
    setIsOpen(!isOpen)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <ContentDivider
          renderLeft={
            <IconButton size="small" onClick={handleToggle}>
              <ExpandMoreIcon
                className={clsx(classes.iconButton, {
                  [classes.iconButtonOpened]: isOpen,
                  [classes.iconButtonClosed]: !isOpen,
                })}
                fontSize="small"
              />
            </IconButton>
          }
          renderRight={renderRight}
          title={title}
          showDivider={false}
          titleProps={{
            styles: {
              color: '#739',
              backgroundColor: `#456`,
            },
          }}
        />
      </Box>
      <Collapse in={isOpen}>
        {showContentFrame ? (
          <ContentFrame>{isOpen && children}</ContentFrame>
        ) : (
          <Box className={classes.root}>{isOpen && children}</Box>
        )}
      </Collapse>
    </Box>
  )
}

CollapseContent.defaultProps = {
  isOpen: false,
  showContentFrame: true,
  renderRight: false,
}

export default CollapseContent
