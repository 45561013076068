export enum AgenteNocivoTipoAvaliacaoEnum {
  NaoPreencher = 0,
  CriterioQuantitativo_1 = 1,
  CriterioQualitativo_2 = 2,
}

export const AgenteNocivoTipoAvaliacaoLabels = {
  [AgenteNocivoTipoAvaliacaoEnum.NaoPreencher]: 'Não preencher',
  [AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1]: 'Critério quantitativo',
  [AgenteNocivoTipoAvaliacaoEnum.CriterioQualitativo_2]: 'Critério qualitativo',
}

export const AgenteNocivoTipoAvaliacaoValues = [
  { value: AgenteNocivoTipoAvaliacaoEnum.NaoPreencher, name: 'Não preencher' },
  { value: AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1, name: 'Critério quantitativo' },
  { value: AgenteNocivoTipoAvaliacaoEnum.CriterioQualitativo_2, name: 'Critério qualitativo' },
]
