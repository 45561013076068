import React, { useState, useCallback } from 'react'

import { Button, Finder, ButtonBox } from 'mio-library-ui'

import Table from './components/Table'
import Container from './components/Container'

import { Box } from '@material-ui/core'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'
import { PageHeader } from '~/components'

import useDialog from '~/hooks/useDialog'
import { useDelete } from '~/hooks/queries/useSindicato'

export default function Sindicato() {
  const { data: dataForm, isOpen: isOpenForm, open: openForm, close: closeForm } = useDialog()

  const {
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
    close: closeConfirmDelete,
  } = useDialog()

  const [query, setQuery] = useState('')

  const { mutateAsync, isLoading: isDeleting } = useDelete()

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (obj) => {
        openForm(obj)
      }

      const handleClickDeleteItem = (id) => {
        openConfirmDelete(id)
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [openForm, openConfirmDelete],
  )

  async function handleConfirmDelete() {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  return (
    <Box height="100%" p={2}>
      <PageHeader title="Sindicato">
        <ButtonBox>
          <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          <Button
            size="small"
            color="primary"
            onClick={() =>
              openForm({
                mesDtBase: '01',
              })
            }
            variant="contained"
          >
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Table query={query} onItemClick={handleClickItem} />

      <Container isOpen={isOpenForm} data={dataForm} onClose={closeForm} />

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isDeleting}
        onCancel={closeConfirmDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  )
}
