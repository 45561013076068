import { useMemo } from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core'

import useUtils from '~/hooks/useUtils'

import { ToolsDataTable } from '~/components'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const Table = (props) => {
  const { data: _data } = props

  const classes = useStyles()
  const { formatCurrency } = useUtils()

  const data = _data.map((d) => {
    return {
      ...d,
      quantidade: d?.quantidade ? formatCurrency(d.quantidade) : '',
      vrEvento: d?.vrEvento ? formatCurrency(d.vrEvento) : '',
      dtAdmissao: d?.dtAdmissao ? moment(d.dtAdmissao).format('DD/MM/yyyy') : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'vinculoMatricula',
        label: 'Matrícula',
      },
      {
        name: 'vinculoNome',
        label: 'Nome',
      },
      {
        name: 'dtAdmissao',
        label: 'Admissão',
      },
      {
        name: 'quantidade',
        label: 'Quantidade',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrEvento',
        label: 'Valor',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
    ],
    [classes],
  )

  return <ToolsDataTable data={data} columns={columns} pagination={false} disableAutoHeight />
}

export default Table
