import React from 'react'

import { TablePagination } from '@material-ui/core'

export default function MUITablePagination(props: MUITablePaginationProps) {
  const { data, page, setPage, rowsPerPage, setRowsPerPage } = props

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <TablePagination
      rowsPerPageOptions={[25, 50, 100]}
      labelRowsPerPage="Linhas por página:"
      component="div"
      count={data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}
