import React, { useCallback, useState } from 'react'
import { Box } from '@material-ui/core'
import { Button, ButtonBox, Finder, PageHeader } from 'mio-library-ui'
import { ConfirmDeleteDialog } from '~/components'
import { useDelete } from '~/hooks/queries/useContratacaoTrabalhadorAvulso'
import Table from './components/Table'
import useDialog from '~/hooks/useDialog'
import Form from './components/Form'
import useAmbiente from '~/hooks/useAmbiente'

const HEADER_HEIGHT = '50px'

export default function ContratacaoTrabalhadorAvulso() {
  const { empregador, anoMes } = useAmbiente()
  const { mutateAsync, isLoading } = useDelete()
  const [query, setQuery] = useState('')
  const { data: dataForm, isOpen: isOpenForm, open: openForm, close: closeForm } = useDialog()
  const {
    data: dataConfirmDelete,
    open: openConfirmDelete,
    isOpen: isOpenConfirmDelete,
    close: closeConfirmDelete,
  } = useDialog('')

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  function handleClickItem(event, value) {
    const handleClickEditItem = async (obj) => {
      openForm(obj)
    }

    const handleClickDeleteItem = (id) => {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  function handleClickAddItem() {
    openForm({
      empregadorId: empregador.id,
      anoMes,
    })
  }

  async function handleConfirmDelete() {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Contratação Trabalhador Avulso">
          <ButtonBox top={1}>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table query={query} empregadorId={empregador.id} onItemClick={handleClickItem} />
      </Box>

      <Form anoMes={anoMes} data={dataForm} isOpen={isOpenForm} onClose={closeForm} />

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isLoading}
        onCancel={closeConfirmDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  )
}
