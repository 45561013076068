import React, { useEffect, useState } from 'react'

import { TextField } from 'mio-library-ui'
import { Grid } from '@material-ui/core'

import * as yup from 'yup'

import { ActionDialog, DatePicker } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtAssinatura: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data da Assinatura')
    .nullable(),
  mesesParaCompensacao: yup.number().min(1, 'Informe o Número de meses para compensação'),
})

const initialState = {
  dtAssinatura: getDateCurrent(),
  mesesParaCompensacao: 12,
}

export default function DialogConfirm(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState(initialState)

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  useEffect(() => {
    if (isOpen) return
    setData(initialState)
  }, [isOpen])

  return (
    <ActionDialog
      title="Informe dados para o Acordo Banco de Horas"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePicker
            label="Data da Assinatura"
            value={data?.dtAssinatura || null}
            required
            name="dtAssinatura"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtAssinatura = date ? date.format('yyyy-MM-DD') : null
              setData((prevState) => ({ ...prevState, dtAssinatura }))
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Número de meses para compensação"
            value={data?.mesesParaCompensacao || ''}
            name="mesesParaCompensacao"
            validationErrors={validationErrors}
            onChange={(e) => {
              const mesesParaCompensacao = e?.target?.value || 0
              setData((prevState) => ({ ...prevState, mesesParaCompensacao }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
