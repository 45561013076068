import { useState } from 'react'

import { Box } from '@material-ui/core'

import { LinearProgressLabel } from '~/components'
import BarChart from '../components/BarChart'
import ChartHeader from '../components/ChartHeader'
import ContainerChart from '../components/ContainerChart'
import TableChart from '../components/TableChart'

import useAmbiente from '~/hooks/useAmbiente'
import { useHistory } from 'react-router-dom'
import usePainelRecibosChart from '~/hooks/queries/Painel/usePainelRecibosChart'

import { IndReciboChartEnum, IndReciboChartLabel } from '~/@types/enums/IndReciboChartEnum'

const valuesDefault = Object.values(IndReciboChartEnum)
  .filter((value) => typeof value === 'number')
  .map((enumValue) => ({
    indRecibo: (typeof enumValue === 'string'
      ? parseInt(enumValue)
      : enumValue) as IndReciboChartEnum,
    percent: 0,
    totalFinalizados: 0,
    totalNaoFinalizados: 0,
    total: 0,
  }))

export default function RecibosChart() {
  const { anoMes, estabelecimento } = useAmbiente()
  const history = useHistory()

  const [isHover, setHover] = useState(false)
  const [estabelecimentoId, setEstabelecimento] = useState('')

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching,
  } = usePainelRecibosChart({
    anoMes,
    estabelecimentoId,
  })
  const data = _data || valuesDefault

  const isLoading = _isLoading || isFetching

  function handleRedirect(indReciboChart: IndReciboChartEnum) {
    switch (indReciboChart) {
      case IndReciboChartEnum.Ferias_01:
        history.push('/recibo-pagamento/ferias')
        break
      case IndReciboChartEnum.Rescisao_02:
        history.push('/recibo-pagamento/rescisao')
        break
      default:
        history.push('/recibo-pagamento/mensal')
        break
    }
  }

  return (
    <ContainerChart onHover={setHover}>
      <ChartHeader
        title="Recibos"
        icon="payment"
        isActiveAlternate={estabelecimentoId ? false : true}
        onClickRedirect={() => handleRedirect(IndReciboChartEnum.Mensal_00)}
        onClickAlternate={() => setEstabelecimento((prev) => (prev ? '' : estabelecimento.id))}
        isHover={isHover}
        isLoading={isLoading}
      />

      <Box flex={1}>
        <BarChart
          data={data.map((d) => ({
            status: d.indRecibo,
            firstTotal: d.totalFinalizados,
            secondTotal: d.totalNaoFinalizados,
          }))}
          labels={IndReciboChartLabel}
          onClick={(index) => handleRedirect(data[index].indRecibo)}
        />
      </Box>

      <TableChart
        onClickRow={(index) => handleRedirect(data[index].indRecibo)}
        columns={[
          {
            name: 'indRecibo',
            label: 'Recibo',
            options: {
              customBodyRender: (index) => IndReciboChartLabel[data[index].indRecibo],
            },
          },
          {
            name: 'total',
            label: 'Quantidade',
          },
          {
            name: 'percent',
            label: 'Porcentagem',
            options: {
              customBodyRender: (index) => <LinearProgressLabel progress={data[index].percent} />,
            },
          },
        ]}
        data={data}
      />
    </ContainerChart>
  )
}
