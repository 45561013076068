import { formatToCPFOrCNPJ } from 'brazilian-values'

import { CopyLabel, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { EtiquetaFeriasFeriasItemDTO } from '~/hooks/queries/EtiquetaFerias/dtos/EtiquetaFeriasFeriasItemDTO'
import { formatSimpleDate } from '~/utils/utils'

interface TableProps {
  data: EtiquetaFeriasFeriasItemDTO[]
  isLoading: boolean
  onRowSelected: (indexs: number[]) => void
  rowsSelected: number[]
  query: string
}

export default function Table(props: TableProps) {
  const { data, isLoading, onRowSelected, rowsSelected, query } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'estabelecimentoInscricao',
      label: 'CNPJ',
      options: {
        customBodyRender: (value) =>
          value ? <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel> : '',
      },
    },
    {
      name: 'estabelecimentoNome',
      label: 'Estabelecimento',
    },
    {
      name: 'funcionarioNome',
      label: 'Funcionário',
    },
    {
      name: 'funcionarioCPF',
      label: 'CPF',
      options: {
        customBodyRender: (value) =>
          value ? <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel> : '',
      },
    },
    {
      name: 'dtInicioPeriodo',
      label: 'Início Período',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : ''),
      },
    },
    {
      name: 'dtFimPeriodo',
      label: 'Fim Período',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : ''),
      },
    },
    {
      name: 'dtInicioFerias',
      label: 'Início Férias',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : ''),
      },
    },
    {
      name: 'dtFimFerias',
      label: 'Fim Férias',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : ''),
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      isLoading={isLoading}
      columns={columns}
      sherlock={{
        columns: [
          'estabelecimentoInscricao',
          'estabelecimentoNome',
          'funcionarioCPF',
          'funcionarioNome',
        ],
        query,
      }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
    />
  )
}
