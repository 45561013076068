import clsx from 'clsx'

import { makeStyles, Grid } from '@material-ui/core'
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  EventNote as EventNoteIcon,
  Receipt as ReceiptIcon,
  Print as PrintIcon,
  PrintDisabled as PrintDisabledIcon,
  Description as DescriptionIcon,
  Email as EmailIcon,
} from '@material-ui/icons'

import InfoItem from './components/InfoItem'

import { copyToClipboard, formatAnoMes } from '~/hooks/useUtils'

import { RP } from '~/hooks/queries/RP/RP'

const useStyles = makeStyles((theme) => ({
  iconSuccess: {
    color: theme.palette.success.main,
  },
  iconSize: {
    fontSize: '1rem',
  },
}))

interface InformationsFooterProps {
  reciboPagamento: RP | null
}

export default function InformationsFooter(props: InformationsFooterProps) {
  const { reciboPagamento } = props

  const haveRecibo = reciboPagamento ? true : false

  const isFinalizado = reciboPagamento?.isFinalizado || false
  const isImpresso = reciboPagamento?.isImpresso || false
  const isEnviadoEmail = reciboPagamento?.dtEnvioEmail ? true : false
  const nrRecibo = reciboPagamento?.nrRecibo || ''
  const reciboEsocial = reciboPagamento?.reciboEsocial || ''
  const fatoGerador = reciboPagamento?.fatoGerador ? formatAnoMes(reciboPagamento.fatoGerador) : ''

  const classes = useStyles()

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={7} lg={8}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InfoItem
              icon={
                isFinalizado ? (
                  <CheckCircleIcon className={clsx(classes.iconSuccess, classes.iconSize)} />
                ) : (
                  <CancelIcon
                    className={classes.iconSize}
                    color={haveRecibo ? 'error' : 'inherit'}
                  />
                )
              }
              title="Finalizado"
              description={isFinalizado ? 'Está finalizado' : 'Não está finalizado'}
              isPointed={false}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4}>
            <InfoItem
              icon={
                isImpresso ? (
                  <PrintIcon className={clsx(classes.iconSuccess, classes.iconSize)} />
                ) : isEnviadoEmail ? (
                  <EmailIcon className={clsx(classes.iconSuccess, classes.iconSize)} />
                ) : (
                  <PrintDisabledIcon
                    className={classes.iconSize}
                    color={haveRecibo ? 'error' : 'inherit'}
                  />
                )
              }
              title={isImpresso ? 'Impresso' : isEnviadoEmail ? 'Email' : 'Impresso'}
              description={
                isImpresso
                  ? 'Está impresso'
                  : isEnviadoEmail
                  ? 'Foi enviado por email'
                  : 'Não está impresso'
              }
              isPointed={false}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <InfoItem
              icon={
                reciboEsocial ? (
                  <DescriptionIcon className={clsx(classes.iconSuccess, classes.iconSize)} />
                ) : (
                  <DescriptionIcon
                    className={classes.iconSize}
                    color={haveRecibo ? 'error' : 'inherit'}
                  />
                )
              }
              title={reciboEsocial ? reciboEsocial : 'eSocial'}
              description="Recibo eSocial"
              onClick={() => {
                if (!reciboEsocial) return
                copyToClipboard(reciboEsocial)
              }}
              isPointed={reciboEsocial ? true : false}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={5} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <InfoItem
              icon={<ReceiptIcon className={classes.iconSize} />}
              title="Número"
              description="Número do recibo"
              value={nrRecibo}
              onClick={() => {
                if (!nrRecibo) return
                copyToClipboard(nrRecibo)
              }}
              isPointed={nrRecibo ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <InfoItem
              icon={<EventNoteIcon className={classes.iconSize} />}
              title="Fato Gerador"
              description="Fato gerador do recibo"
              value={fatoGerador}
              onClick={() => {
                if (!fatoGerador) return
                copyToClipboard(fatoGerador)
              }}
              isPointed={fatoGerador ? true : false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
