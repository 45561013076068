import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

export default function useUploadFile(handleUpload) {
  const [preparedFiles, setPreparedFiles] = useState([])

  const startUpload = (_preparedFile) => {
    handleUpload(_preparedFile, updateDownloadStatus)
  }

  const updateDownloadStatus = (fileId, params) => {
    setPreparedFiles((state) =>
      state.map((_preparedFile) =>
        _preparedFile.id === fileId ? { ..._preparedFile, ...params } : _preparedFile,
      ),
    )
  }

  const prepareUpload = (files) => {
    const _preparedFiles = files.map((file) => ({
      id: uuidv4(),
      file,
      progress: 0,
      uploaded: false,
      error: false,
    }))

    setPreparedFiles(_preparedFiles)
    _preparedFiles.forEach(startUpload)
  }

  return [prepareUpload, { preparedFiles }]
}
