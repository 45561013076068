import { formatToCPF } from 'brazilian-values'

import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'

import { respReg } from '~/hooks/queries/ImportarXMLS2240/dtos/evtExpRisco'
import { IndOrgaoClasseEnum, IndOrgaoClasseLabels } from '~/@types/enums/IndOrgaoClasseEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface DialogResponsaveisProps {
  isOpen: boolean
  onClose: () => void
  data: respReg[]
}

export default function DialogResponsaveis({ isOpen, onClose, data }: DialogResponsaveisProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'cpfResp',
      label: 'CPF',
      options: {
        customBodyRender: formatToCPF,
      },
    },
    {
      name: 'ideOC',
      label: 'Orgão de Classe',
      options: {
        customBodyRender: (value: IndOrgaoClasseEnum) => IndOrgaoClasseLabels[value],
      },
    },
    {
      name: 'nrOC',
      label: 'Número OC',
    },
    {
      name: 'ufOC',
      label: 'UF OC',
    },
  ]

  return (
    <ActionDialog
      title="Responsáveis"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
      }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <ToolsDataTable data={data} columns={columns} disableAutoHeight pagination={false} />
    </ActionDialog>
  )
}
