import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPut } from '~/utils/notification'
import { useInvalidate } from './useObterLancamentoLiquidacaoConfig'

import { LancamentoLiquidacaoConfig } from './dtos/LancamentoLiquidacaoConfig'
import { LancamentoLiquidacaoConfigAlterarDTO } from './dtos/LancamentoLiquidacaoConfigAlterarDTO'

interface RequestProps {
  data: LancamentoLiquidacaoConfigAlterarDTO
  params: { id: string }
}

export function useAlterar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.put<{ data: LancamentoLiquidacaoConfig }>(
      '/LancamentoLiquidacaoConfig/Alterar',
      data,
      { params },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
