import { Box, makeStyles } from '@material-ui/core'

import { TipoDia } from '~/hooks/queries/VinculoFaltas/VinculoFaltasDTO'
import Icon from './Icon'

function changeTitle(dateType: TipoDia) {
  switch (dateType) {
    case TipoDia.Falta:
      return 'Falta'
    case TipoDia.Atraso:
      return 'Atraso'
    case TipoDia.DSRDescontado:
      return 'DSR Perdido'
    case TipoDia.Ferias:
      return 'Afastamento Férias'
    case TipoDia.Afastado:
      return 'Afastado'
    case TipoDia.DescansoSemanal:
      return 'Descanso'
    case TipoDia.DiaNaoTrabalha:
      return 'Não Trabalha'
    default:
      return 'Título'
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: theme.palette.background.paper,
  },
  title: {
    display: 'flex',
    gridGap: theme.spacing(0.5),
    justifyContent: 'center',
    alignItems: 'center',

    fontWeight: 'bold',
    color: '#666',
  },
}))

interface CalendarDayProps {
  dateType: TipoDia
}

export default function CalendarDay(props: CalendarDayProps) {
  const { dateType } = props

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Icon dateType={dateType} />
        <Box>{changeTitle(dateType)}</Box>
      </Box>
    </Box>
  )
}
