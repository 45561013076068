import { formatCurrency } from '~/utils/utils'

import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { ActionDialog, Button, ButtonBox, ContainerTable, ToolsDataTable } from '~/components'

import Form from './Form'

import useDialog from '~/hooks/useDialog'

import { useObterTodos } from '~/hooks/queries/ProcessoTrabalhistaTributosPeriodoContribuicao/useObterTodos'
import { useRemover } from '~/hooks/queries/ProcessoTrabalhistaTributosPeriodoContribuicao/useRemover'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface TributosPeriodoContribuicaoProps {
  isOpen: boolean
  onClose: () => void
  processoTrabalhistaTributosPeriodoId: string
}

export default function TributosPeriodoContribuicao({
  isOpen,
  onClose,
  processoTrabalhistaTributosPeriodoId,
}: TributosPeriodoContribuicaoProps) {
  const { close: closeForm, isOpen: isOpenForm, open: openForm } = useDialog()
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterTodos({
    params: {
      processoTrabalhistaTributosPeriodoId,
    },
  })
  const data = _data || []

  const { mutateAsync, isLoading: isRemoving } = useRemover()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigoReceita',
      label: 'Código de Recolhimento',
    },
    {
      name: 'vrCodigoReceita',
      label: 'Valor da Receita',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={async () => await mutateAsync(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ActionDialog
      title="I.N.S.S"
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      renderRight={
        <Button variant="contained" onClick={openForm}>
          Adicionar
        </Button>
      }
    >
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          disableAutoHeight
          pagination={false}
          heightHeader="30px"
          isLoading={isLoading}
          isFetching={isFetching || isRemoving}
        />
      </ContainerTable>

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          processoTrabalhistaTributosPeriodoId={processoTrabalhistaTributosPeriodoId}
        />
      )}
    </ActionDialog>
  )
}
