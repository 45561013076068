import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { Ativo } from '~/components/Displays'

const Table = (props) => {
  const { data, onItemClick, isLoading, query } = props

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          customBodyRender: (value) => <Ativo ativo={value} />,
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <DataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['codigo', 'nome'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
