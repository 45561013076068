import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { PlanilhaExtracaoExibicaoDTO } from './ImportarEventosRPDTO'

export default function useExtrairPlanilha() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(file: FormData) {
    const response = await api.post<{ data: PlanilhaExtracaoExibicaoDTO }>(
      '/ImportarEventosRP/ExtrairPlanilha',
      file,
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Planilha extraída com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
