import React from 'react'

import { makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  contentAlert: {
    width: '100%',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function AlertContainer(props) {
  const { children, severity, title } = props

  const classes = useStyles()

  return (
    <Alert
      severity={severity}
      classes={{
        message: classes.contentAlert,
        icon: classes.alignCenter,
      }}
      title={title}
    >
      {children}
    </Alert>
  )
}

AlertContainer.defaultProps = {
  severity: 'info',
}
