import React, { useCallback } from 'react'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { formatToBRL } from 'brazilian-values'

const makeColumns = () => [
  {
    name: 'vrFaixaI',
    label: 'Valor da Primeira Faixa',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'vrCotaI',
    label: 'Valor da Primeira Cota',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'vrFaixaII',
    label: 'Valor da Segunda Faixa',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'vrCotaII',
    label: 'Valor da Segunda Cota',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
]

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = useCallback(() => {
    return makeColumns(onItemClick)
  }, [onItemClick])

  return (
    <div>
      <DataTable
        title=""
        data={data}
        columns={columns()}
        options={options}
        isLoading={isLoading}
        pagination={false}
      />
    </div>
  )
}

const options = {
  viewColumns: false,
  filter: false,
  search: false,
  selectableRows: 'none',
  print: false,
  download: false,
  elevation: 0,
  responsive: 'standard',
}

Table.defaultProps = {
  query: '',
}

export default Table
