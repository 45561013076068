import React from 'react'

import moment from 'moment'
import { formatToCPF } from 'brazilian-values'

import { ButtonBox } from 'mio-library-ui'

import { Badge, Box, IconButton, useTheme } from '@material-ui/core'
import { Error as ErrorIcon, Warning as WarningIcon } from '@material-ui/icons'
import { ToolsDataTable } from '~/components'

import {
  FeriasColetivaConcedidaProgramadaDTO,
  FeriasColetivaSimulacaoValoresDTO,
} from '~/hooks/queries/FeriasColetiva/obterFuncionariosAnalitico'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { TableFeriasColetiva, useFeriasColetiva } from '../Context'
import { formatCurrency } from '~/utils/utils'

export default function Table() {
  const { tableProps, requestProps, dialogsProps } = useFeriasColetiva()
  const { isLoadingSearch } = requestProps
  const {
    collection: _data,
    query,
    rowsSelected,
    setRowsSelected: onRowSelected,
    tableCurrent,
  } = tableProps
  const { openDialogAlerts, openDialogConcedidaProgramada, openDialogLicencaRemunerada } =
    dialogsProps

  const theme = useTheme()

  const data = _data.map((d) => ({
    ...d,
    nrInscricao: formatToCPF(d.nrInscricao),
    dtAdmissao: moment(d.dtAdmissao).format('DD/MM/yyyy'),
    dtPeriodoInicio: d?.dtPeriodoInicio ? moment(d.dtPeriodoInicio).format('DD/MM/yyyy') : '',
    dtPeriodoFim: d?.dtPeriodoFim ? moment(d.dtPeriodoFim).format('DD/MM/yyyy') : '',
  }))

  let columns: MUIDataTableColumnDef[] = [
    {
      name: 'funcionarioId',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'dtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'dtPeriodoInicio',
      label: 'Período Início',
    },
    {
      name: 'dtPeriodoFim',
      label: 'Período Fim',
    },
    {
      name: 'nrDiasLicencaRemunerada',
      label: 'Licença Remunerada',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowData }) => {
          const funcionarioId = rowData[0]
          const dtRow = _data.find((d) => d.funcionarioId === funcionarioId)
          const isDisabled = !(value > 0)
          if (!dtRow) return
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                disabled={isDisabled}
                title="Licença Remuneradas"
                size="small"
                onClick={() => openDialogLicencaRemunerada(dtRow)}
                style={{
                  fontSize: '1rem',
                }}
              >
                {value}
              </IconButton>
            </ButtonBox>
          )
        },
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
      },
    },
    {
      name: 'nrDiasSaldoAnterior',
      label: 'Saldo Anterior',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
      },
    },
    {
      name: 'nrFaltasPeriodo',
      label: 'Faltas',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
      },
    },
    {
      name: 'nrDiasDireito',
      label: 'Dias Direito',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
      },
    },
    {
      name: 'nrDiasConcedidos',
      label: 'Concedidas',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowData }) => {
          const funcionarioId = rowData[0]
          const dtRow = _data.find((d) => d.funcionarioId === funcionarioId)
          const feriasConcedidas = dtRow?.feriasConcedidas || []
          const isDisabled = !(feriasConcedidas.length > 0)
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                disabled={isDisabled}
                title="Férias Concedidas"
                size="small"
                onClick={() => onClickFerias('concedidas', feriasConcedidas)}
                style={
                  isDisabled
                    ? undefined
                    : {
                        color: theme.palette.primary.main,
                      }
                }
              >
                <Box fontSize="1rem">{value}</Box>
              </IconButton>
            </ButtonBox>
          )
        },
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
      },
    },
    {
      name: 'nrDiasSaldo',
      label: 'Saldo Atual',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
      },
    },
    {
      name: 'alertas',
      label: 'Alertas',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          const isDisabled = !(value.length > 0)
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                disabled={isDisabled}
                title="Alertas"
                size="small"
                onClick={() => openDialogAlerts(value)}
              >
                <Badge badgeContent={value.length} color="secondary">
                  <WarningIcon
                    style={
                      isDisabled
                        ? undefined
                        : {
                            color: theme.palette.warning.main,
                          }
                    }
                  />
                </Badge>
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
    {
      name: 'alertasCriticos',
      label: 'Problemas',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          const isDisabled = !(value.length > 0)
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                disabled={isDisabled}
                title="Problemas"
                size="small"
                onClick={() => openDialogAlerts(value)}
              >
                <Badge badgeContent={value.length} color="secondary">
                  <ErrorIcon
                    style={
                      isDisabled
                        ? undefined
                        : {
                            color: theme.palette.error.main,
                          }
                    }
                  />
                </Badge>
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  if (tableCurrent === TableFeriasColetiva.TableValoresSimulacao) {
    columns = [
      {
        name: 'nrInscricao',
        label: 'CPF',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'dtAdmissao',
        label: 'Admissão',
      },
      {
        name: 'dtPeriodoInicio',
        label: 'Período Início',
      },
      {
        name: 'dtPeriodoFim',
        label: 'Período Fim',
      },
      {
        name: 'valoresSimulacao',
        label: 'Salário Contratual',
        options: {
          customBodyRender: (value: FeriasColetivaSimulacaoValoresDTO) =>
            formatCurrency(value?.vrSalarioContratual),
          setCellProps: () => ({
            style: {
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'valoresSimulacao',
        label: 'Férias',
        options: {
          customBodyRender: (value: FeriasColetivaSimulacaoValoresDTO) =>
            formatCurrency(value?.vrFerias),
          setCellProps: () => ({
            style: {
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'valoresSimulacao',
        label: '1/3 Férias',
        options: {
          customBodyRender: (value: FeriasColetivaSimulacaoValoresDTO) =>
            formatCurrency(value?.vrUmTercoFerias),
          setCellProps: () => ({
            style: {
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'valoresSimulacao',
        label: 'Licença',
        options: {
          customBodyRender: (value: FeriasColetivaSimulacaoValoresDTO) =>
            formatCurrency(value?.vrLicenca),
          setCellProps: () => ({
            style: {
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'valoresSimulacao',
        label: 'Total',
        options: {
          customBodyRender: (value: FeriasColetivaSimulacaoValoresDTO) =>
            formatCurrency(value?.vrTotal),
          setCellProps: () => ({
            style: {
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'alertas',
        label: 'Alertas',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            const isDisabled = !(value.length > 0)
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  disabled={isDisabled}
                  title="Alertas"
                  size="small"
                  onClick={() => openDialogAlerts(value)}
                >
                  <Badge badgeContent={value.length} color="secondary">
                    <WarningIcon
                      style={
                        isDisabled
                          ? undefined
                          : {
                              color: theme.palette.warning.main,
                            }
                      }
                    />
                  </Badge>
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
      {
        name: 'alertasCriticos',
        label: 'Problemas',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            const isDisabled = !(value.length > 0)
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  disabled={isDisabled}
                  title="Problemas"
                  size="small"
                  onClick={() => openDialogAlerts(value)}
                >
                  <Badge badgeContent={value.length} color="secondary">
                    <ErrorIcon
                      style={
                        isDisabled
                          ? undefined
                          : {
                              color: theme.palette.error.main,
                            }
                      }
                    />
                  </Badge>
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ]
  }

  function onClickFerias(
    event: 'concedidas' | 'programadas',
    ferias: FeriasColetivaConcedidaProgramadaDTO[],
  ) {
    const functions = {
      concedidas: () =>
        openDialogConcedidaProgramada({
          data: ferias,
          title: 'Férias Concedidas',
        }),
      programadas: () =>
        openDialogConcedidaProgramada({
          data: ferias,
          title: 'Férias Programadas',
        }),
    }
    functions[event]()
  }

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoadingSearch}
      columns={columns}
      sherlock={{
        query,
        columns: ['nrInscricao', 'nome'],
      }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
      disableAutoHeight
    />
  )
}
