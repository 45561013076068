const nivelEstagio_fundamental_1 = 1
const nivelEstagio_Medio_2 = 2
const nivelEstagio_FormacaoProfissional_3 = 3
const nivelEstagio_Superior_4 = 4
const nivelEstagio_Especial_8 = 8
const nivelEstagio_MaeSocial_9 = 9

export const nivelEstagioValues = [
  { value: nivelEstagio_fundamental_1, name: '1 - Fundamental' },
  { value: nivelEstagio_Medio_2, name: '2 - Médio' },
  {
    value: nivelEstagio_FormacaoProfissional_3,
    name: '3 - Formação Profisional',
  },
  { value: nivelEstagio_Superior_4, name: '4 - Superior' },
  { value: nivelEstagio_Especial_8, name: '8 - Especial' },
  { value: nivelEstagio_MaeSocial_9, name: '9 - Mãe social (Lei 7.644/1987)' },
]
export const nivelEstagioConsts = {
  nivelEstagio_fundamental_1,
  nivelEstagio_Medio_2,
  nivelEstagio_FormacaoProfissional_3,
  nivelEstagio_Superior_4,
  nivelEstagio_Especial_8,
  nivelEstagio_MaeSocial_9,
}
