import React, { useState, useMemo } from 'react'

import { Box, IconButton, makeStyles } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { FaCircle } from 'react-icons/fa'
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons'

import { ToolsDataTable } from '~/components'

import TableDetailsExpanded from './components/TableDetailsExpanded'

import useDialogNotification from '~/hooks/useDialogNotification'
import useRedirectByEvent from '~/hooks/useRedirectByEvent'

import api from '~/services/api-pessoal'
import moment from 'moment'
import clsx from 'clsx'

import {
  statusProcessTransmissaoValues,
  statusProcessTransmissaoConsts,
} from '~/values/statusProcessTransmissaoValues'

const {
  statusProcessTransmissao_wait_1,
  statusProcessTransmissao_process_2,
  statusProcessTransmissao_finish_3,
  statusProcessTransmissao_finish_warning_4,
  statusProcessTransmissao_error_5,
} = statusProcessTransmissaoConsts

function changeCircleColorStatus(status) {
  const maps = {
    [statusProcessTransmissao_wait_1]: 'waitCircleColor',
    [statusProcessTransmissao_process_2]: 'processCircleColor',
    [statusProcessTransmissao_finish_3]: 'finishCircleColor',
    [statusProcessTransmissao_finish_warning_4]: 'warningCircleColor',
    [statusProcessTransmissao_error_5]: 'errorCircleColor',
  }
  return maps[status]
}

function formatLabel(status) {
  const element = statusProcessTransmissaoValues.find((obj) => obj.value === status)
  return element?.name || ''
}

const useStyles = makeStyles((theme) => ({
  heightDialog: {
    height: '100%',
  },
  renderRight: {
    textAlign: 'end',
  },
  rootFaCircleStatus: {
    display: 'flex',
    width: '100%',
  },
  waitCircleColor: {
    color: theme.palette.alerts.primary,
  },
  processCircleColor: {
    color: theme.palette.alerts.info,
  },
  finishCircleColor: {
    color: theme.palette.alerts.success,
  },
  warningCircleColor: {
    color: theme.palette.alerts.warning,
  },
  errorCircleColor: {
    color: theme.palette.alerts.danger,
  },
}))

export default function TableEventsExpandeds(props) {
  const { data, anoMes, handleReloadData } = props

  const [status, setStatus] = useState('')
  const [dataTableExpanded, setDataTableExpanded] = useState([])
  const [isLoadingTableExpanded, setLoadingTableExpanded] = useState(false)
  const [rowsExpanded, setRowsExpanded] = useState([])

  const classes = useStyles()
  const redirectByEvent = useRedirectByEvent()
  const dialogNotification = useDialogNotification()

  const columns = useMemo(
    () => [
      {
        name: 'status',
        options: { display: 'excluded' },
      },
      {
        name: 'eventoDescricao',
        label: 'Descrição do Evento',
      },
      {
        name: 'total',
        label: 'Total',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          customBodyRender: (value) => {
            const classString = changeCircleColorStatus(value)
            return (
              <FaCircle
                className={clsx([classes.rootFaCircleStatus, classes[classString]])}
                title={formatLabel(value)}
              />
            )
          },
        },
      },
      {
        name: 'evento',
        label: 'Ir para o Evento',
        options: {
          customBodyRender: (value) => (
            <ButtonBox justifyContent="center">
              <IconButton
                size="small"
                title={`Ir para o Evento ${value}`}
                color="primary"
                aria-label={`Ir para o Evento ${value}`}
                onClick={() => redirectByEvent(value)}
              >
                <ExitToAppIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          ),
          setCellHeaderProps: () => ({
            style: { maxWidth: '20px' },
          }),
        },
      },
    ],
    [classes, redirectByEvent],
  )

  function renderExpandableRow() {
    return (
      <TableDetailsExpanded
        data={dataTableExpanded}
        isLoading={isLoadingTableExpanded}
        status={status}
        handleReloadData={handleReloadData}
      />
    )
  }

  const onRowExpand = async (indexs) => {
    setRowsExpanded(indexs)
    setDataTableExpanded([])
    if (indexs.length === 0) return
    const dataCurrent = data[indexs[0]]
    setLoadingTableExpanded(true)
    try {
      setStatus(dataCurrent?.status || '')
      const response = await api.get('/ESocialDashBoard/ObterAuditoriaAnalitica', {
        params: {
          empregadorId: dataCurrent?.empregadorId || '',
          anoMes,
          eventoId: dataCurrent?.evento || '',
          status: dataCurrent?.status || '',
        },
      })
      if (!response?.data?.data) return
      const newData = response.data.data.map((d) => ({
        ...d,
        dtMovimento: d?.dtMovimento ? moment(d.dtMovimento).format('DD/MM/yyyy - HH:mm:ss') : '',
        retorno_DataHora: d?.retorno_DataHora
          ? moment(d.retorno_DataHora).format('DD/MM/yyyy - HH:mm:ss')
          : '',
        dtReciboeSocial: d?._DtESocialRecibo ? moment(d._DtESocialRecibo).format('DD/MM/yyyy') : '',
        reciboeSocial: d?._ESocialRecibo,
      }))
      setDataTableExpanded(newData)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingTableExpanded(false)
    }
  }

  return (
    <Box height="100%" pb={2}>
      <ToolsDataTable
        data={data}
        columns={columns}
        pagination={false}
        disableAutoHeight
        optionsExpandable={{
          type: 'single',
          expandedChildren: renderExpandableRow,
          onRowExpanded: onRowExpand,
          rowsExpanded: rowsExpanded,
        }}
      />
    </Box>
  )
}
