import React, { useEffect, useState } from 'react'

import { Divider, Grid, Typography } from '@material-ui/core'

import { ActionDialog, Checkbox, MultipleSelectCheck } from '~/components'

import useUtils from '~/hooks/useUtils'
import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'
import { RelDadosCadastraisFuncionarioValues } from '~/@types/enums/RelDadosCadastraisFuncionarioEnum'

const initialState = {
  filtros: [],
  isSepararPagesEstabelecimento: false,
}

export default function DialogConfirm(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState(initialState)

  const { formatAnoMes } = useUtils()
  const { anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()

  function handleSubmit() {
    if (data.filtros.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um Filtro'],
      })
    }
    onAfterSubmit({
      filtros: data.filtros.map((d) => d.value),
      isSepararPagesEstabelecimento: data.isSepararPagesEstabelecimento,
    })
    onClose()
  }

  useEffect(() => {
    if (isOpen) return
    setData(initialState)
  }, [isOpen])

  return (
    <ActionDialog
      title="Informe dados para o Relatório de Dados Cadastrais Funcionário"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography>Competência: {formatAnoMes(anoMes)}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label="Separar páginas por Estabelecimento"
            value={data.isSepararPagesEstabelecimento}
            onChange={(e, value) => {
              const isSepararPagesEstabelecimento = value
              setData((prevState) => ({ ...prevState, isSepararPagesEstabelecimento }))
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultipleSelectCheck
            label="Filtro"
            value={data.filtros}
            options={RelDadosCadastraisFuncionarioValues}
            getOptionLabel={(d) => d.name}
            required
            fieldCompare="value"
            onChange={(value) => setData((prev) => ({ ...prev, filtros: value }))}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
