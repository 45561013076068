import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { HeightDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import { Edit as EditIcon, Delete as DeleteIcon, Print as PrintIcon } from '@material-ui/icons'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'
import { IndFeriasValues } from '~/@types/enums/IndFeriasEnum'

const formatIndFerias = (indFerias) => {
  const objectFinded = IndFeriasValues.find((obj) => obj.value === indFerias)
  return objectFinded?.name || ''
}

const TableItem = (props) => {
  const { data, onItemClick, isLoading } = props

  const { formatAnoMes } = useUtils()

  const dataFormated = data.map((item) => {
    return {
      ...item,
      anoMes: item?.anoMes ? formatAnoMes(item.anoMes) : null,
      dtAvisoFerias: item?.dtAvisoFerias ? moment(item.dtAvisoFerias).format('DD/MM/YYYY') : null,
      dtEmissaoFerias: item?.dtEmissaoFerias
        ? moment(item.dtEmissaoFerias).format('DD/MM/YYYY')
        : null,
      dtInicioConcessao: item?.dtInicioConcessao
        ? moment(item.dtInicioConcessao).format('DD/MM/YYYY')
        : null,
      dtFimConcessao: item?.dtFimConcessao
        ? moment(item.dtFimConcessao).format('DD/MM/YYYY')
        : null,
      dtRetorno: item?.dtRetorno ? moment(item.dtRetorno).format('DD/MM/YYYY') : null,
      dtInicioAbono: item?.dtInicioAbono ? moment(item?.dtInicioAbono).format('DD/MM/YYYY') : null,
      dtFimAbono: item?.dtFimAbono ? moment(item?.dtFimAbono).format('DD/MM/YYYY') : null,
      indFerias: formatIndFerias(item?.indFerias),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'anoMes',
        label: 'Ano/Mês',
      },
      {
        name: 'nrDiasFerias',
        label: 'Dias Concessão',
      },
      {
        name: 'dtInicioConcessao',
        label: 'Início Férias',
      },
      {
        name: 'dtFimConcessao',
        label: 'Fim Férias',
      },
      {
        name: 'dtEmissaoFerias',
        label: 'Pagamento',
      },
      {
        name: 'dtAvisoFerias',
        label: 'Aviso Férias',
      },
      {
        name: 'dtInicioAbono',
        label: 'Início Abono',
      },
      {
        name: 'nrDiasAbono',
        label: 'Dias Abono',
      },
      {
        name: 'indFerias',
        label: 'Tipo',
      },
      {
        name: 'rpId',
        label: 'Recibo',
        options: {
          customBodyRender: (value) => <Ativo ativo={value ? true : false} />,
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            const itemCurrent = data.find((d) => d.id === value)
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Imprimir Aviso de Férias"
                  color="primary"
                  aria-label="Imprimir"
                  onClick={() => {
                    onItemClick('print', value)
                  }}
                >
                  <PrintIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  disabled={itemCurrent?.rpId ? true : false}
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" disabled={itemCurrent?.rpId ? true : false} />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    // eslint-disable-next-line
    [onItemClick],
  )

  return <HeightDataTable title="" data={dataFormated} columns={columns} isLoading={isLoading} />
}

export default TableItem
