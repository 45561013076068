import React, { useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import { ConfirmDeleteDialog, PDFViewer, TreeView } from '~/components'

import Table from './components/Table'
import Container from './components/Container'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const { CNPJ_1 } = tipoInscricaoConsts

const HEADER_HEIGHT = '70px'

const CondicaoAmbienteTrabalho = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [container, setContainer] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [query, setQuery] = useState('')
  const [vinculo, setVinculo] = useState({})

  const notification = useNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const {data: dataMenu, isLoading: isLoadingMenu} = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Cooperado_5,
    VinculoTipoEnum.Empregador_2,
    VinculoTipoEnum.Estagiario_3,
  ])

  useEffect(() => {
    setVinculo({})
    setCollection({
      isLoading: false,
      itens: [],
    })
  }, [anoMes, estabelecimento])

  const getCollectionByVinculo = async (vinculoId) => {
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get('/CondicaoAmbienteTrabalho/GetByVinculo', {
        params: {
          vinculoId,
        },
      })
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
  }

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleOpenContainer = useCallback(
    (
      data = {
        vinculoId: vinculo?.id,
        tipoInscricao: CNPJ_1,
        localAmbiente: '1',
        nrInscricao: estabelecimento.nrInscricao,
      },
    ) => {
      setContainer((oldState) => ({
        ...oldState,
        isOpen: true,
        data,
        vinculo: vinculo,
      }))
    },
    [vinculo, estabelecimento],
  )

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        handleOpenContainer(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      function handleClickPrintItem(id) {
        openPDFViewer(id)
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
        print: handleClickPrintItem,
      }
      functions[event](value)
    },
    [collection, handleOpenContainer, openPDFViewer],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/CondicaoAmbienteTrabalho/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenContainer()
  }, [handleOpenContainer])

  const handleCloseContainer = useCallback(() => {
    setContainer((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }, [])

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseContainer()
        handleOpenContainer(data)
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseContainer()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection, handleCloseContainer, handleOpenContainer],
  )

  async function handleGetVinculo(vinculo) {
    const vinculoId = vinculo?.vinculoId
    if (!vinculoId) return
    try {
      const response = await api.get(`Vinculo/${vinculoId}`)
      const data = response?.data?.data

      setVinculo({
        id: data.id,
        nome: data?.pessoaFisica?.nome,
        dtAdmissao: data?.dtAdmissao,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => {
        getCollectionByVinculo(vinculo.vinculoId)
        handleGetVinculo(vinculo)
      }}
      searchBy="vinculoNome"
      renderOption={(option) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
      isLoadingOnClick={collection.isLoading}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Condição Ambiente de Trabalho" subtitle={vinculo?.nome || '-'}>
          {vinculo?.id && (
            <ButtonBox>
              <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
              <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
                Adicionar
              </Button>
            </ButtonBox>
          )}
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          query={query}
          onItemClick={handleClickItem}
        />
      </Box>

      <Container
        isOpen={container.isOpen}
        data={container.data}
        vinculo={container.vinculo}
        estabelecimento={estabelecimento}
        onClose={handleCloseContainer}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        title="Condição Ambiente de Trabalho"
        reportKey="CondicaoAmbienteTrabalho"
        axiosConfig={{
          method: 'get',
          url: '/CondicaoAmbienteTrabalho/ObterRelatorioCondicaoAmbienteTrabalho',
          params: {
            condicaoAmbienteTrabalhoId: dataPDFViewer,
          },
        }}
      />
    </TreeView>
  )
}

export default CondicaoAmbienteTrabalho
