import { formatToCPFOrCNPJ } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { ToolsDataTable, TextFieldChangeEstabelecimentoEmail } from '~/components'
import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'

interface TableProps {
  data: EstabelecimentoConsulta[]
  isLoading: boolean
  query: string
  rowsSelected: number[]
  onRowSelected: (indexs: number[]) => void
  onAfterSubmit: () => void
}

export default function Table(props: TableProps) {
  const { data: _data, isLoading, query, rowsSelected, onRowSelected, onAfterSubmit } = props

  const data = _data.map((d) => ({
    ...d,
    nrInscricao: d?.nrInscricao ? formatToCPFOrCNPJ(d.nrInscricao) : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nrInscricao',
      label: 'Número de Inscrição',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'id',
      label: 'Email',
      options: {
        setCellHeaderProps: () => ({
          style: { width: 400 },
        }),
        customBodyRender: (id) => {
          const estabelecimento = _data.find((d) => d.id === id)
          return (
            <TextFieldChangeEstabelecimentoEmail
              estabelecimentoId={id}
              email={estabelecimento?.email || ''}
              afterSubmit={() => onAfterSubmit}
            />
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      optionsSelectable={{
        type: 'multiple',
        rowsSelected: rowsSelected,
        onRowSelected: onRowSelected,
      }}
      sherlock={{
        columns: ['codigo', 'nrInscricao', 'nome', 'email'],
        query,
      }}
    />
  )
}
