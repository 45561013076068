import React from 'react'

import { Grid } from '@material-ui/core'

import StepperForm from '~/components/StepperForm'

import FormMain from './FormMain'
import TableResponsaveis from './TableResponsaveis'
import TableAgentesNocivos from './TableAgentesNocivos'

import { useCondicaoAmbienteTrabalhoCriar } from '~/hooks/queries/CondicaoAmbienteTrabalho/useCondicaoAmbienteTrabalhoCriar'
import { useCondicaoAmbienteTrabalhoAtualizar } from '~/hooks/queries/CondicaoAmbienteTrabalho/useCondicaoAmbienteTrabalhoAtualizar'

import { CondicaoAmbienteTrabalhoSaveDTOPartial } from '~/hooks/queries/CondicaoAmbienteTrabalho/CondicaoAmbienteTrabalhoSaveDTO'
import {
  CondicaoAmbienteTrabalho,
  CondicaoAmbienteTrabalhoPartial,
} from '~/hooks/queries/CondicaoAmbienteTrabalho/CondicaoAmbienteTrabalho'
import {
  CondicaoAmbienteTrabalhoResponsavel,
  CondicaoAmbienteTrabalhoResponsavelPartial,
} from '~/hooks/queries/CondicaoAmbienteTrabalhoResponsavel/CondicaoAmbienteTrabalhoResponsavel'
import {
  CondicaoAmbienteTrabalhoAgenteNocivo,
  CondicaoAmbienteTrabalhoAgenteNocivoPartial,
} from '~/hooks/queries/CondicaoAmbienteTrabalhoAgenteNocivo/CondicaoAmbienteTrabalhoAgenteNocivo'

export interface MainDataForm {
  dataMainForm: CondicaoAmbienteTrabalhoPartial
  dataTableResponsaveis: CondicaoAmbienteTrabalhoResponsavelPartial[]
  dataTableAgentesNocivos: CondicaoAmbienteTrabalhoAgenteNocivoPartial[]
}

interface MainDataFormValidate {
  dataMainForm: CondicaoAmbienteTrabalho
  dataTableResponsaveis: CondicaoAmbienteTrabalhoResponsavel[]
  dataTableAgentesNocivos: CondicaoAmbienteTrabalhoAgenteNocivo[]
}

interface FlowCreateProps {
  data: CondicaoAmbienteTrabalhoSaveDTOPartial
  onCancel: () => void
  isRetificar?: boolean
}

export default function FlowCreate(props: FlowCreateProps) {
  const { data, onCancel, isRetificar } = props

  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingCreate } =
    useCondicaoAmbienteTrabalhoCriar()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } =
    useCondicaoAmbienteTrabalhoAtualizar()

  const mainData: MainDataForm = {
    dataMainForm: data.condicaoAmbienteTrabalho,
    dataTableAgentesNocivos: data.agentesNocivos,
    dataTableResponsaveis: data.responsaveis,
  }

  async function handleSubmit(data: MainDataFormValidate) {
    // eslint-disable-next-line
    const agentesNocivosFormated = data.dataTableAgentesNocivos.map(({ agenteNocivo, ...rest }) => {
      const epis = rest?.condicaoAmbienteTrabalhoAgenteNocivoEPIItens || []
      // eslint-disable-next-line
      const episFormated = epis.map(({ epi, ...rest }) => ({ ...rest }))
      return {
        ...rest,
        condicaoAmbienteTrabalhoAgenteNocivoEPIItens: episFormated,
      }
    })
    // eslint-disable-next-line
    const responsaveisFormated = data.dataTableResponsaveis.map(({ responsavel, ...rest }) => ({
      ...rest,
    }))

    const dataToSubmit = {
      condicaoAmbienteTrabalho: data.dataMainForm,
      agentesNocivos: agentesNocivosFormated,
      responsaveis: responsaveisFormated,
    }

    if (data.dataMainForm.id) {
      await mutateAsyncUpdate({
        data: dataToSubmit,
        isRetificar: isRetificar || false,
      })
    } else {
      await mutateAsyncCreate(dataToSubmit)
    }
    onCancel()
  }

  const isEdit = data.condicaoAmbienteTrabalho.id ? true : false

  return (
    <StepperForm.Provider<MainDataForm>
      onSubmit={(d) => handleSubmit(d as MainDataFormValidate)}
      onCancel={onCancel}
      data={mainData}
      isLoading={isLoadingCreate || isLoadingUpdate}
      disabled={isEdit && (isRetificar === undefined ? false : !isRetificar)}
      steps={[
        {
          label: 'Cadastro',
          children: <FormMain />,
          icon: 'assignment_ind',
        },
        {
          label: 'Responsáveis',
          children: <TableResponsaveis />,
          icon: 'people',
        },
        {
          label: 'Agentes Nocivos',
          children: <TableAgentesNocivos />,
          icon: 'category',
        },
      ]}
    >
      <Grid container spacing={0} style={{ marginBottom: '3rem' }}>
        <Grid item xs={1} md={2}>
          <StepperForm.Steps />
        </Grid>
        <Grid item xs={11} md={10}>
          <StepperForm.Content />
        </Grid>
      </Grid>
    </StepperForm.Provider>
  )
}
