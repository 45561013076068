import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { TelefoneTextField } from '~/components'
import { AutoCompleteBanco, AutoCompleteCidade } from '~/components/AutoComplete'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'

const schema = yup.object().shape({
  banco: yup.string().required('Informe o Banco').nullable(),
  nome: yup.string().required('Informe o Nome').nullable(),
  cidade: yup.string().required('Informe a Cidade').nullable(),
  numero: yup.string().required('Informe o Número'),
  endereco: yup.string().required('Informe o Endereço').nullable(),
  dv: yup.string().max(1, 'Informe no máximo um caracter').nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/bancoAgencia/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/bancoAgencia', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Agência Bancária"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <AutoCompleteBanco
            required
            value={data.banco || null}
            name="banco"
            validationErrors={validationErrors}
            onChange={(e, bancoObj) => {
              const bancoId = bancoObj ? bancoObj.id : null
              const banco = bancoObj ? bancoObj : null
              setData({ ...data, bancoId, banco })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data.nome || ''}
            variant="outlined"
            size="small"
            required
            autoFocus
            validationErrors={validationErrors}
            name="nome"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid xl={5} lg={5} md={5} sm={5} xs={12} item>
          <TextField
            label="Número"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.numero || ''}
            required
            validationErrors={validationErrors}
            name="numero"
            inputProps={{
              maxLength: 9,
            }}
            onlyNumber
            onChange={(e) => {
              const numero = e?.target.value.replace(/\D/gim, '')
              setData((oldState) => ({
                ...oldState,
                numero,
              }))
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            label="DV"
            fullWidth
            value={data.dv || ''}
            variant="outlined"
            size="small"
            onlyNumber
            validationErrors={validationErrors}
            name="dv"
            onChange={(e) => {
              const dv = e.target.value
              setData({ ...data, dv })
            }}
          />
        </Grid>

        <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
          <AutoCompleteCidade
            required
            value={data.cidade || null}
            validationErrors={validationErrors}
            name="cidade"
            onChange={(e, cidadeObj) => {
              const cidadeId = cidadeObj ? cidadeObj.id : null
              const cidade = cidadeObj || null
              setData({ ...data, cidadeId, cidade })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Endereço"
            fullWidth
            value={data.endereco || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="endereco"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const endereco = e.target.value
              setData({ ...data, endereco })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TelefoneTextField
            value={data?.telefone || ''}
            onChange={(e, value) => {
              const telefone = value
              setData({ ...data, telefone })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
