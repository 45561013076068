import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core'

import MaskedTextField from '../MaskedTextField'
import { formatToDigits } from '~/utils/utils'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props) => theme.spacing(props?.top || 0),
    marginRight: (props) => theme.spacing(props?.right || 0),
    marginBottom: (props) => theme.spacing(props?.bottom || 0),
    marginLeft: (props) => theme.spacing(props?.left || 0),
  },
}))

const DiaMesTextField = (props) => {
  const { onChange, ...rest } = props
  const [mask, setMask] = useState('99/99')

  const classes = useStyles(props)

  useEffect(() => {
    definirMascara(rest.value)
  }, [rest.value])

  const definirMascara = () => {
    setMask('99/99')
  }

  function handleChange(e) {
    const dateFormated = e.target.value.replace('/', '')
    onChange(e, dateFormated)
  }

  function verifyDateIsCorrectly(e) {
    let [day, month] = e.target.value.split('/').map((i) => parseInt(i))

    if (day > 31) day = 31
    if (day < 1 && !(day > 31)) day = 1

    if (month > 12) month = 12
    if (month < 1 && !(month > 12)) month = 1

    day = formatToDigits(day, 2)
    month = formatToDigits(month, 2)

    const newValue = day + month

    onChange(e, newValue)
  }

  return (
    <React.Fragment>
      <MaskedTextField
        className={classes.root}
        mask={mask}
        placeholder="DD/MM"
        fullWidth
        variant="outlined"
        size="small"
        onBlur={verifyDateIsCorrectly}
        onChange={handleChange}
        {...rest}
      />
    </React.Fragment>
  )
}

export default DiaMesTextField
