import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import * as yup from 'yup'

import { ActionDialog, AnoSelect, DatePicker } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtAssinatura: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data da Assinatura')
    .nullable(),
  anoQuitacao: yup.string().required('Informe o Ano da Quitação'),
})

export default function DialogConfirm(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const { ano } = useAmbiente()

  const initialState = {
    dtAssinatura: getDateCurrent(),
    anoQuitacao: ano,
  }

  const [data, setData] = useState(initialState)

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  useEffect(() => {
    if (isOpen) return
    setData(initialState)
    // eslint-disable-next-line
  }, [isOpen])

  return (
    <ActionDialog
      title="Informe dados para a Declaração de Quitação Anual"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePicker
            label="Data da Assinatura"
            value={data?.dtAssinatura || null}
            required
            name="dtAssinatura"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtAssinatura = date ? date.format('yyyy-MM-DD') : null
              setData((prevState) => ({ ...prevState, dtAssinatura }))
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AnoSelect
            fullWidth
            variant="outlined"
            size="small"
            label="Ano da Quitação"
            value={data?.anoQuitacao || ''}
            name="anoQuitacao"
            validationErrors={validationErrors}
            onChange={(e) => {
              const anoQuitacao = e?.target?.value
              setData((prevState) => ({ ...prevState, anoQuitacao }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
