import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { AtualizarCTPSFeriasItemDTO } from './dtos/AtualizarCTPSFeriasItemDTO'

const query = '/AtualizarCTPS/ObterFerias'

interface RequestProps {
  params: {
    vinculoId: string
  }
}

export function useAtualizarCTPSObterFerias({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: AtualizarCTPSFeriasItemDTO[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
