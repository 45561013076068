import { useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Tooltip } from '@material-ui/core'

import {
  ActionDialog,
  ContentDivider,
  DatePickerNew,
  NumeroInscricaoTextField,
  TextField,
} from '~/components'
import { AutoCompleteCidade, MUIAutoComplete } from '~/components/AutoComplete'

import { useCreate } from '~/hooks/queries/ProcessoTrabalhista/useCreate'
import { useUpdate } from '~/hooks/queries/ProcessoTrabalhista/useUpdate'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { ProcessoTrabalhista } from '~/hooks/queries/ProcessoTrabalhista/dtos/ProcessoTrabalhista'
import {
  InfoProcessoOrigemEnum,
  InfoProcessoOrigemValues,
} from '~/@types/enums/InfoProcessoOrigemEnum'
import { TipoCCPEnum, TipoCCPValues } from '~/@types/enums/TipoCCPEnum'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const dtInitialVara = {
  dtSentenca: '',
  cidadeVaraId: '',
  varaId: '',
}

const dtInitialCCP = {
  dtCCP: '',
  tpCCP: TipoCCPEnum.Nao_Informar_0,
  cnpjCCP: '',
}

const initialData = {
  id: '',
  empregadorId: '',
  identificacaoProcesso: '',
  infoOrigemProcesso: InfoProcessoOrigemEnum.Processo_Judicial_1,
  nrProcessoTrabalhista: '',
  observacaoProcessoTrabalhista: '',
  ...dtInitialVara,
  ...dtInitialCCP,
}

const schema = yup.object().shape({
  identificacaoProcesso: yup.string().required('Informe a Identificação do Processo'),
  infoOrigemProcesso: yup
    .mixed()
    .required('Informe a Origem do Processo')
    .oneOf(
      Object.values(InfoProcessoOrigemEnum),
      'Informe um valor válido para a Origem do Processo',
    ),
  nrProcessoTrabalhista: yup.string().required('Informe o Número do Processo'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: ProcessoTrabalhista | null
}

export default function Form({ isOpen, onClose, data: _data }: FormProps) {
  const [data, setData] = useState(_data || initialData)

  const isNovaData = _data?.id ? false : true

  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingCreate } = useCreate()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = useUpdate()

  const { empregador } = useAmbiente()

  const isLoading = isLoadingCreate || isLoadingUpdate

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    const { id, ...rest } = data
    if (isNovaData) {
      rest.empregadorId = empregador.id
      await mutateAsyncCreate(rest)
    } else {
      await mutateAsyncUpdate({
        id,
        data: rest,
      })
    }
    onClose()
  }

  function handleChangeOrigem(origem: InfoProcessoOrigemEnum) {
    let dt: ProcessoTrabalhista = {
      ...data,
      infoOrigemProcesso: origem,
      nrProcessoTrabalhista: '',
    }

    if (origem === InfoProcessoOrigemEnum.Processo_Judicial_1) {
      dt = {
        ...dt,
        ...dtInitialCCP,
      }
    }

    if (origem === InfoProcessoOrigemEnum.Demanda_Submetida_CCP_Ou_NINTER_2) {
      dt = {
        ...dt,
        ...dtInitialVara,
      }
    }

    setData(dt)
  }

  return (
    <ActionDialog
      title="Cadastro de Processo Trabalhista"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Identificação do Processo"
            value={data.identificacaoProcesso}
            onChange={(e) => {
              const identificacaoProcesso = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                identificacaoProcesso,
              }))
            }}
            required
            name="identificacaoProcesso"
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIAutoComplete
            label="Origem do Processo"
            options={InfoProcessoOrigemValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.infoOrigemProcesso}
            onChange={(_, d) => {
              const origem = d?.value || InfoProcessoOrigemEnum.Processo_Judicial_1
              handleChangeOrigem(origem)
            }}
            required
            name="infoOrigemProcesso"
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Número do Processo"
            inputProps={
              data.infoOrigemProcesso === InfoProcessoOrigemEnum.Processo_Judicial_1
                ? { maxLength: 20 }
                : { maxLength: 15 }
            }
            value={data.nrProcessoTrabalhista}
            onChange={(e) => {
              const nrProcessoTrabalhista = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                nrProcessoTrabalhista,
              }))
            }}
            required
            name="nrProcessoTrabalhista"
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xs={12}>
          <ContentDivider title="Informações complementares do processo judicial" />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Data da Sentença"
            disabled={
              data.infoOrigemProcesso === InfoProcessoOrigemEnum.Demanda_Submetida_CCP_Ou_NINTER_2
            }
            value={data.dtSentenca || null}
            onChange={(date) => {
              const dtSentenca = date || ''
              setData((oldState) => ({
                ...oldState,
                ...dtInitialCCP,
                dtSentenca,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AutoCompleteCidade
            label="Cidade da Vara"
            value={data.cidadeVaraId}
            disabled={
              data.infoOrigemProcesso === InfoProcessoOrigemEnum.Demanda_Submetida_CCP_Ou_NINTER_2
            }
            onChange={(e, cidade) => {
              const cidadeVaraId = cidade?.id || ''
              setData((oldState) => ({
                ...oldState,
                ...dtInitialCCP,
                cidadeVaraId,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Identificador da Vara"
            inputProps={{ maxLength: 4 }}
            disabled={
              data.infoOrigemProcesso === InfoProcessoOrigemEnum.Demanda_Submetida_CCP_Ou_NINTER_2
            }
            value={data.varaId}
            onChange={(e) => {
              const varaId = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                ...dtInitialCCP,
                varaId,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ContentDivider title="Informações complementares da demanda submetida à CCP ou ao NINTER" />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Data CCP"
            disabled={data.infoOrigemProcesso === InfoProcessoOrigemEnum.Processo_Judicial_1}
            value={data.dtCCP || null}
            onChange={(date) => {
              const dtCCP = date || ''
              setData((oldState) => ({
                ...oldState,
                ...dtInitialVara,
                dtCCP,
              }))
            }}
          />
        </Grid>

        <Grid xs={12} item>
          <Tooltip
            arrow
            title={
              data.tpCCP !== TipoCCPEnum.NINTER_3
                ? 'Comissões de Conciliação Prévia'
                : 'Núcleos Intersindicais'
            }
          >
            <Box>
              <MUIAutoComplete
                label="Informação CCP/NINTER"
                options={TipoCCPValues}
                optionId="value"
                renderOption={(option) => option.name}
                disabled={data.infoOrigemProcesso === InfoProcessoOrigemEnum.Processo_Judicial_1}
                value={data.tpCCP}
                onChange={(_, obj) => {
                  const tpCCP = obj?.value || TipoCCPEnum.Nao_Informar_0
                  setData((oldState) => ({
                    ...oldState,
                    ...dtInitialVara,
                    tpCCP,
                  }))
                }}
              />
            </Box>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <NumeroInscricaoTextField
            label="CNPJ CCP"
            typeMask={TipoInscricaoEnum.CNPJ_1}
            disabled={data.infoOrigemProcesso === InfoProcessoOrigemEnum.Processo_Judicial_1}
            value={data.cnpjCCP}
            onChange={(e, value) => {
              const cnpjCCP = value || ''
              setData((oldState) => ({
                ...oldState,
                ...dtInitialVara,
                cnpjCCP,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ContentDivider />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Observação"
            multiline
            rows={4}
            value={data.observacaoProcessoTrabalhista}
            inputProps={{
              maxLength: 999,
            }}
            onChange={(e) => {
              const observacaoProcessoTrabalhista = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                observacaoProcessoTrabalhista,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
