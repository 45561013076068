import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterLotacaoConfigPorMovimentoTipo'

import { notifySuccess } from '~/utils/notification'

import { FPMovimentoTipoEnum } from '~/@types/enums/FPMovimentoTipoEnum'
import { MovimentoConfig } from './dtos/MovimentoConfig'

interface RequestProps {
  params: {
    lotacaoOrigem: string
    lotacaoDestino: string
    movimentoTipo: FPMovimentoTipoEnum
  }
}

export function useCopiarCofiguracoesLotacao() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ params }: RequestProps) {
    const response = await api.get<{ data: MovimentoConfig[] }>(
      '/ExportacaoLancamentoConfig/CopiarCofiguracoesLotacao',
      { params },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Configurações copiadas com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
