import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { Terceiros } from './Terceiros'
import { TerceirosItem } from '../TerceirosItem/TerceirosItem'

export const queryListarTerceiros = '/Terceiros'

interface TerceirosResponse extends Omit<Terceiros, 'terceirosItens'> {
  terceirosItens: TerceirosItem[]
}

export function useListarTerceiros() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: TerceirosResponse[] }>(queryListarTerceiros)
    return response.data.data
  }

  return useQuery([queryListarTerceiros], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
