import { useState, useEffect, memo } from 'react'

import { Box, Grid, IconButton, Paper, useTheme } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'

import { Button, ButtonBox, Finder, PageHeader } from '~/components'
import { AutoCompleteGrupo, MUIAutoComplete } from '~/components/AutoComplete'

import Table from './Table'
import ProcessIndicator from './ProcessIndicator'
import DialogProcessamento from './DialogProcessamento'

import { useHistory } from 'react-router-dom'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useSocket from '~/hooks/useSocket'

import useObterEmpregadores from '~/hooks/queries/Competencia/useObterEmpregadores'
import useProcessarCompetencia from '~/hooks/queries/Competencia/useProcessarCompetencia'

import {
  CompetenciaProcessamentoContent,
  CompetenciaProcessamentoStatusEnum,
} from '~/hooks/queries/Competencia/CompetenciaProcessamentoContent'
import { CompetenciaEmpregadorStatusEnum } from '~/@types/enums/CompetenciaEmpregadorStatusEnum'
import {
  FiltroCompetenciaAberturaEnum,
  FiltroCompetenciaAberturaValues,
} from '~/@types/enums/FiltroCompetenciaAberturaEnum'
import {
  IndCompetenciaProcessEnum,
  IndCompetenciaProcessValues,
} from '~/@types/enums/IndCompetenciaProcessEnum'
import { CompetenciaProcessamentoHubEnum } from '~/@types/enums/CompetenciaProcessamentoHubEnum'

const MemoTable = memo(Table)

export default function Competencia() {
  const [query, setQuery] = useState('')
  const [dataForm, setDataForm] = useState<{
    filtroEmpresas: FiltroCompetenciaAberturaEnum
    grupoId: string | null
    indCompetenciaProcess: IndCompetenciaProcessEnum
  }>({
    filtroEmpresas: FiltroCompetenciaAberturaEnum.EmpresasNaoAbertas,
    grupoId: null,
    indCompetenciaProcess: IndCompetenciaProcessEnum.Mensal_00,
  })

  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [isProcessando, setProcessando] = useState(false)

  const [logs, setLogs] = useState<CompetenciaProcessamentoContent[]>([])
  const [logsEmpregador, setLogsEmpregador] = useState<CompetenciaProcessamentoContent[]>([])
  const [logsFuncionario, setLogsFuncionario] = useState<CompetenciaProcessamentoContent[]>([])

  const history = useHistory()
  const { empresaId, anoMes } = useAmbiente()
  const {
    close: closeDialogProcessamento,
    isOpen: isOpenDialogProcessamento,
    open: openDialogProcessamento,
  } = useDialog(null)
  const theme = useTheme()
  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterEmpregadores({
    anoMes,
    filtroEmpresas: dataForm.filtroEmpresas,
    grupoId: dataForm.grupoId,
    indCompetenciaProcess: dataForm.indCompetenciaProcess,
  })
  const data = _d || []

  const { mutateAsync: mutateAsyncProcessarCompetencia, isLoading: isLoadingProcessarCompetencia } =
    useProcessarCompetencia()

  const socket = useSocket({
    urlHub: '/CompetenciaProcessamentoHub',
    onStartConnection: (socket) => {
      socket.send(CompetenciaProcessamentoHubEnum.RemoveFromGroup, empresaId + anoMes)
      socket.send(CompetenciaProcessamentoHubEnum.AddToGroup, empresaId + anoMes)
    },
  })

  useEffect(() => {
    if (!socket) return
    socket.send(CompetenciaProcessamentoHubEnum.RemoveFromGroup, empresaId + anoMes)
    socket.send(CompetenciaProcessamentoHubEnum.AddToGroup, empresaId + anoMes)
  }, [socket, empresaId, anoMes])

  useEffect(() => {
    if (!socket) return
    socket.on(CompetenciaProcessamentoHubEnum.SendMessageGroupLog, (_response) => {
      const response: CompetenciaProcessamentoContent | undefined = JSON.parse(_response)
      if (!response) return
      if (response.status === CompetenciaProcessamentoStatusEnum.Finalizado) {
        refetch()
      }
      setLogs((prev) => [...prev, response])
    })
    socket.on(CompetenciaProcessamentoHubEnum.SendMessageGroupEmpregador, (_response) => {
      const response: CompetenciaProcessamentoContent | undefined = JSON.parse(_response)
      if (!response) return
      setLogsEmpregador((prev) => [...prev, response])
    })
    socket.on(CompetenciaProcessamentoHubEnum.SendMessageGroupFuncionario, (_response) => {
      const response: CompetenciaProcessamentoContent | undefined = JSON.parse(_response)
      if (!response) return
      setLogsFuncionario((prev) => [...prev, response])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  useEffect(() => {
    const dtCurrent = _d || []
    setProcessando(dtCurrent.some((d) => d.status === CompetenciaEmpregadorStatusEnum.Processando))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_d])

  useEffect(() => {
    setRowsSelected([])
  }, [_d])

  useEffect(() => {
    setLogs([])
    setLogsEmpregador([])
    setLogsFuncionario([])
  }, [anoMes])

  async function onSubmit() {
    const empregadoresIds = rowsSelected.map((indexRow) => data[indexRow].empregadorId)
    await mutateAsyncProcessarCompetencia({
      data: {
        empregadoresIds,
        anoMes,
        indCompetenciaProcess: dataForm.indCompetenciaProcess,
      },
    })
    await refetch()
    setLogs([])
    setLogsEmpregador([])
    setLogsFuncionario([])
    openDialogProcessamento(null)
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title="Competência">
        <ButtonBox>
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
            <IconButton
              size="small"
              onClick={() =>
                history.push({
                  pathname: '/processar-competencia-old',
                })
              }
              title="Retornar para versão antiga"
            >
              <ErrorOutline color="primary" />
            </IconButton>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          </Box>
        </ButtonBox>
      </PageHeader>

      <Box component={Paper} p={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <AutoCompleteGrupo
              value={dataForm.grupoId}
              onChange={(e, grupo) => {
                const grupoId = grupo?.id || null
                setDataForm((prev) => ({ ...prev, grupoId }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <MUIAutoComplete
              label="Indicador de Processamento"
              options={IndCompetenciaProcessValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={dataForm.indCompetenciaProcess}
              onChange={(e, obj) => {
                const indCompetenciaProcess = obj?.value || IndCompetenciaProcessEnum.Mensal_00
                setDataForm((prev) => ({ ...prev, indCompetenciaProcess }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <MUIAutoComplete
              label="Filtrar Empresas"
              options={FiltroCompetenciaAberturaValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={dataForm.filtroEmpresas}
              onChange={(e, obj) => {
                const filtroEmpresas = obj.value
                setDataForm((prev) => ({ ...prev, filtroEmpresas }))
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <ProcessIndicator
        isProcessando={isProcessando}
        logs={logs}
        logsEmpregador={logsEmpregador}
        logsFuncionario={logsFuncionario}
        onClick={() => openDialogProcessamento(null)}
      />

      <Box flex={1} position="relative" overflow="auto" minHeight={300}>
        <Box position="absolute" width="100%">
          <MemoTable
            data={data}
            isFetching={isFetching}
            isLoading={isLoading}
            rowsSelected={rowsSelected}
            onRowSelected={setRowsSelected}
            query={query}
            indCompetenciaProcess={dataForm.indCompetenciaProcess}
          />
        </Box>
      </Box>

      <Box component={Paper} padding={1} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={rowsSelected.length > 0 ? false : true}
          onClick={onSubmit}
          isLoading={isLoadingProcessarCompetencia}
        >
          Processar Abertura
        </Button>
      </Box>

      <DialogProcessamento
        isOpen={isOpenDialogProcessamento}
        onClose={closeDialogProcessamento}
        isProcessando={isProcessando}
        logs={logs}
        logsEmpregador={logsEmpregador}
        logsFuncionario={logsFuncionario}
      />
    </Box>
  )
}
