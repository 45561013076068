import { CopyLabel, ToolsDataTable } from '~/components'

import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatToCPFOrCNPJ } from 'brazilian-values'

interface TableProps {
  data: EstabelecimentoConsulta[]
  isLoading: boolean
  isFetching: boolean
  query: string
  rowsSelected: number[]
  onRowsSelected: (rows: number[]) => void
}

export default function Table({
  data,
  isLoading,
  isFetching,
  query,
  rowsSelected,
  onRowsSelected,
}: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'nrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['codigoString', 'nome', 'nrInscricaoMascara'],
      }}
      optionsSelectable={{
        type: 'multiple',
        rowsSelected: rowsSelected,
        onRowSelected: onRowsSelected,
      }}
    />
  )
}
