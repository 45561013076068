import React, { useState, useEffect, useCallback } from 'react'

import { Collapse, IconButton, makeStyles, Box } from '@material-ui/core'
import { ContentContainer, PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'

import { ConfirmDeleteDialog, Checkbox } from '~/components'

import Table from './components/Table'
import Form from './components/Form'
import Empregadores from './components/Empregadores'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

const useStyles = makeStyles((theme) => ({
  rootForm: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'end',
  },
  boxButton: { alignSelf: 'center' },
}))

const CertificadoDigital = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)
  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })
  const [formEmpregadores, setFormEmpregadores] = useState({
    isOpen: false,
    certificadoDigital: {},
  })
  const [isOnlyVencidos, setOnlyVencidos] = useState(false)
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [query, setQuery] = useState('')

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const classes = useStyles()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get('/CertificadoDigital', {
          params: {
            somenteVencidos: isOnlyVencidos,
          },
        })
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [isOnlyVencidos])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleOpenForm = useCallback((data = {}) => {
    setForm({
      data,
      isOpen: true,
    })
  }, [])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickOpenEmpregadores = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        setFormEmpregadores({
          isOpen: true,
          certificadoDigital: item,
        })
      }

      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        empregadores: handleClickOpenEmpregadores,
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collection, handleOpenForm],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete('/CertificadoDigital/Excluir', {
        params: {
          certificadoId: confirmDeleteDialog.id,
        },
      })
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id, handleCloseConfirmDeleteItem])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [handleOpenForm])

  const handleCloseForm = useCallback(() => {
    setForm((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }, [])

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection, handleCloseForm],
  )

  const handleCloseEmpregadores = () => {
    setFormEmpregadores({
      isOpen: false,
      certificadoDigital: {},
    })
  }

  return (
    <ContentContainer>
      <PageHeader title="Certificado Digital">
        <ButtonBox>
          <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
            {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
          </IconButton>
        </ButtonBox>
      </PageHeader>

      <Collapse in={formCollapseIsOpen}>
        <Box className={classes.rootForm}>
          <Box>
            <Checkbox
              label="Apenas Certificados Vencidos"
              value={isOnlyVencidos}
              onChange={(e, obj) => setOnlyVencidos(obj)}
            />
          </Box>
          <Box className={classes.boxButton}>
            <Button size="small" color="primary" variant="contained" onClick={handleClickAddItem}>
              Adicionar
            </Button>
          </Box>
        </Box>
      </Collapse>

      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        query={query}
        onItemClick={handleClickItem}
      />

      <Form
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <Empregadores
        isOpen={formEmpregadores.isOpen}
        onClose={handleCloseEmpregadores}
        certificadoDigital={formEmpregadores.certificadoDigital}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={() => {
          dialogNotification.info({
            descriptions: [
              'Deseja realmente remover o Certificado Digital?',
              'Os empregadores que estão vinculados ao Certificado Digital atual perderam a configuração.',
              'Se você deseja atualizar o Certificado Digital use a opção de editar',
            ],
            onConfirm: () => handleDeleteItem(),
            labelOnConfirm: 'Confirmar',
          })
        }}
      />
    </ContentContainer>
  )
}

export default CertificadoDigital
