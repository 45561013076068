import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryUseObterEventosTransmissaoAcompanhamento } from './useObterEventosTransmissaoAcompanhamento'
import { queryUseObterTotalEventosGrupoAcompanhamento } from './useObterTotalEventosGrupoAcompanhamento'
import { queryUseObterTotalEventosGrupoEnvio } from './useObterTotalEventosGrupoEnvio'
import { queryUseObterEventosTransmissaoEnvio } from './useObterEventosTransmissaoEnvio'
import { ESocialStatusEnum } from '~/@types/enums/ESocialStatusEnum'

interface RequestProps {
  eventosIds: string[]
  alterarParaStatus: ESocialStatusEnum
}

export function useAlterarStatusEvento() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    const { eventosIds, alterarParaStatus } = props

    await api.post('/ESocial/AlterarStatusEvento', eventosIds, {
      params: {
        status: alterarParaStatus,
      },
    })
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.success('O status foi alterado para aguardando com sucesso')
      await queryClient.invalidateQueries(queryUseObterEventosTransmissaoAcompanhamento)
      await queryClient.invalidateQueries(queryUseObterTotalEventosGrupoAcompanhamento)
      await queryClient.invalidateQueries(queryUseObterTotalEventosGrupoEnvio)
      await queryClient.invalidateQueries(queryUseObterEventosTransmissaoEnvio)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
