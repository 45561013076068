import React from 'react'

import { Box, IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox } from 'mio-library-ui'

import { CurrencyTextField, ToolsDataTable } from '~/components'

import { RescisaoComplementarEventoDTO } from '~/hooks/queries/RescisaoComplementar/RescisaoComplementarDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatCurrency } from '~/hooks/useUtils'

interface TableProps {
  data: RescisaoComplementarEventoDTO[]
  triggersHeight: any[]
  onDeleteRow: (rowIndex: number) => void
  onChangeRow: (data: RescisaoComplementarEventoDTO[]) => void
}

export default function Table(props: TableProps) {
  const { data: _data, triggersHeight, onDeleteRow, onChangeRow } = props

  const data = _data.map((d) => ({
    ...d,
    referencia: d?.referencia ? formatCurrency(d.referencia) : '',
    vrVencimento: d?.vrVencimento ? formatCurrency(d.vrVencimento) : '',
    vrVencimentoAjustado: d?.vrVencimentoAjustado ? formatCurrency(d.vrVencimentoAjustado) : '',
    // vrDiferencaAPagar: d?.vrDiferencaAPagar ? formatCurrency(d.vrDiferencaAPagar) : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'eventoCodigo',
      label: 'Código',
    },
    {
      name: 'eventoNome',
      label: 'Nome',
      options: {
        customBodyRender: (value, { rowIndex }) => {
          const eventoRow = _data[rowIndex]
          if (!eventoRow) return <></>
          const isCalculoManual = eventoRow.vrDiferencaAPagar > 0
          return (
            <Box
              title={isCalculoManual ? 'O Cálculo está manual' : ''}
              color={isCalculoManual ? 'error.main' : ''}
            >
              {value}
            </Box>
          )
        },
      },
    },
    {
      name: 'referencia',
      label: 'Referência',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrVencimento',
      label: 'Vencimento',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrVencimentoAjustado',
      label: 'Vencimento Ajustado',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrDiferencaAPagar',
      label: 'Diferença a Pagar',
      options: {
        customBodyRender: (value, { rowIndex }) => {
          return (
            <CurrencyTextField
              value={value}
              onBlur={(e, value) => {
                const newData = _data.map((evento, index) =>
                  rowIndex === index ? { ...evento, vrDiferencaAPagar: value } : evento,
                )
                onChangeRow(newData)
              }}
            />
          )
        },
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value, { rowIndex }) => {
          if (value) {
            return <></>
          }

          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => onDeleteRow(rowIndex)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      pagination={false}
      triggersHeight={triggersHeight}
    />
  )
}
