import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useGetAllInvalidate } from './useGetAll'

import { notifyPut } from '~/utils/notification'

import { ProcessoTrabalhistaVinculoBase } from './dtos/ProcessoTrabalhistaVinculoBase'

interface RequestProps {
  data: ProcessoTrabalhistaVinculoBase
  id: string
}

export function useUpdate() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useGetAllInvalidate()

  async function handleRequest({ data, id }: RequestProps) {
    await api.put('/ProcessoTrabalhistaVinculoBase/' + id, data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
