import React, { useEffect, useState } from 'react'

import { Box, Grid, Tooltip } from '@material-ui/core'

import { ActionDialog, DatePicker, TextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { ContentDivider } from 'mio-library-ui'

import { useSubmit } from '~/hooks/queries/useVinculoReintegracao'
import useValidationErrors from '~/hooks/useValidationErrors'

import { tipoReintegracaoValues, tipoReintegracaoConsts } from '~/values/tipoReintegracaoValues'
import { IndMotivoDesligamentoValues } from '~/@types/enums/IndMotivoDesligamentoEnum'

import moment from 'moment'
import * as yup from 'yup'

const schema = yup.object().shape({
  nrProcesso: yup
    .string()
    .when('tipoReintegracao', (tipoReintegracao, schema) =>
      parseInt(tipoReintegracao) === tipoReintegracaoConsts.REINTEGRACAO_POR_DECISAO_JUDICIAL_1
        ? schema
            .required('Informe o Número do Processo Judicial')
            .length(20, 'Número Processo Judicial deve possuir 20 caracteres')
        : schema,
    )
    .nullable(),
  nrLeiAnistia: yup
    .string()
    .when('tipoReintegracao', (tipoReintegracao, schema) =>
      parseInt(tipoReintegracao) === tipoReintegracaoConsts.REINTEGRACAO_POR_ANISTIA_LEGAL_2
        ? schema.required('Informe o Número da Lei de Anistia')
        : schema,
    )
    .nullable(),
  dtEfetivaRetorno: yup
    .date()
    .typeError('Informe uma Data válida')
    .required('Informe a Data Efetiva Retorno')
    .when('$dtRescisao', (dtRescisao, schema) => {
      const dtRescisaoFormatted = moment(dtRescisao).format('DD/MM/yyyy')

      return schema
        .test(
          'dtEfetivaRetorno-validator',
          `A Data Efetiva Retorno deve ser posterior à Data de Desligamento do Trabalhador: ${dtRescisaoFormatted}`,
          (dtEfetivaRetorno) => moment(dtEfetivaRetorno).isAfter(dtRescisao),
        )
        .test(
          'dtEfetivaRetorno-validator',
          `A Data Efetiva Retorno não pode ser posterior a
          30 (trinta) dias da data atual: ${moment().format('DD/MM/yyyy')}`,
          (dtEfetivaRetorno) => {
            const dtEfetivaRetornoFormatted = moment(dtEfetivaRetorno)
            const oneMonthAfterToday = moment().add(30, 'days')

            return dtEfetivaRetornoFormatted.isSameOrBefore(oneMonthAfterToday)
          },
        )
    })
    .nullable(),
  dtEfeito: yup
    .date()
    .typeError('Informe uma Data válida')
    .required('Informe a Data Efeito')
    .when(['$dtRescisao', 'dtEfetivaRetorno'], (dtRescisao, dtEfetivaRetorno, schema) => {
      const dtEfetivaRetornoFormatted = moment(dtEfetivaRetorno).format('DD/MM/yyyy')
      const dtRescisaoFormatted = moment(dtRescisao).format('DD/MM/yyyy')

      return schema
        .test(
          'dtEfeito-validator',
          `A Data Efeito deve ser igual ou anterior à Data do Efetivo Retorno: ${dtEfetivaRetornoFormatted}`,
          (dtEfeito) => moment(dtEfeito).isSameOrBefore(dtEfetivaRetorno),
        )
        .test(
          'dtEfeito-validator',
          `A Data Efeito deve ser posterior à Data de Desligamento do Trabalhador: ${dtRescisaoFormatted}`,
          (dtEfeito) => moment(dtEfeito).isAfter(dtRescisao),
        )
    }),
})

export default function Form(props) {
  const { vinculo, data: _data, isOpen, onClose } = props

  const [data, setData] = useState({})

  const { mutateAsync, isLoading } = useSubmit()

  useEffect(() => {
    setData(_data)
  }, [_data])

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: {
        dtRescisao: vinculo?.dtRescisao,
      },
    },
  })

  return (
    <ActionDialog
      title="Cadastro da Reintegração"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <ContentDivider title="Informações da reintegração" bottom={1} />
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MUIAutoComplete
            name="tipoReintegracao"
            label="Tipo de Reintegração"
            value={data?.tipoReintegracao || ''}
            onChange={(_, obj) => {
              const tipoReintegracao = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                tipoReintegracao,
                nrProcesso: '',
                nrLeiAnistia: '',
              }))
            }}
            options={tipoReintegracaoValues}
            renderOption={(option) => option.name}
            optionId="value"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="nrProcesso"
            label="Número do Processo Judicial"
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ maxLength: 20 }}
            disabled={
              data?.tipoReintegracao !== tipoReintegracaoConsts.REINTEGRACAO_POR_DECISAO_JUDICIAL_1
            }
            required={
              data?.tipoReintegracao === tipoReintegracaoConsts.REINTEGRACAO_POR_DECISAO_JUDICIAL_1
            }
            value={data?.nrProcesso || ''}
            onChange={(e) => {
              const nrProcesso = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                nrProcesso,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={6} xs={12}>
          <TextField
            name="nrLeiAnistia"
            label="Número da Lei de Anistia"
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ maxLength: 13 }}
            disabled={
              data?.tipoReintegracao !== tipoReintegracaoConsts.REINTEGRACAO_POR_ANISTIA_LEGAL_2
            }
            required={
              data?.tipoReintegracao === tipoReintegracaoConsts.REINTEGRACAO_POR_ANISTIA_LEGAL_2
            }
            value={data?.nrLeiAnistia || ''}
            onChange={(e) => {
              const nrLeiAnistia = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                nrLeiAnistia,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <Tooltip arrow title="Informar a data do efetivo retorno ao trabalho.">
            <Box>
              <DatePicker
                name="dtEfetivaRetorno"
                label="Data Efetiva Retorno"
                size="small"
                required
                value={data?.dtEfetivaRetorno || null}
                onChange={(date) => {
                  const dtEfetivaRetorno = date ? date.format('yyyy-MM-DD') : null
                  setData((oldState) => ({
                    ...oldState,
                    dtEfetivaRetorno,
                  }))
                }}
                validationErrors={validationErrors}
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <Tooltip arrow title="Informar a data de início dos efeitos financeiros da reintegração.">
            <Box>
              <DatePicker
                name="dtEfeito"
                label="Data Efeito"
                size="small"
                required
                value={data?.dtEfeito || null}
                onChange={(date) => {
                  const dtEfeito = date ? date.format('yyyy-MM-DD') : null
                  setData((oldState) => ({
                    ...oldState,
                    dtEfeito,
                  }))
                }}
                validationErrors={validationErrors}
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <DatePicker
            name="dtRescisao"
            label="Data Rescisão"
            size="small"
            disabled
            value={data?.dtRescisao || null}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            name="motivoDesligamento"
            label="Motivo do Desligamento"
            disabled
            value={data?.motivoDesligamento || ''}
            options={IndMotivoDesligamentoValues}
            renderOption={(option) => option.name}
            optionId="value"
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
