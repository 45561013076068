import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import api from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'
import { AutoCompleteCBO, AutoCompleteEmpregador } from '~/components/AutoComplete'

const schema = yup.object().shape({
  empregadorId: yup.string().required('Informe o Empregador'),
  codigo: yup.string().required('Informe o Código'),
  nome: yup.string().required('Informe o Nome'),
  cboId: yup.string().required('Informe o CBO'),
})

const CadastroFuncao = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/funcao/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/funcao', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Função"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteEmpregador
            disabled={data?.id ? true : false}
            value={data?.empregadorId || ''}
            optionId="id"
            required
            validationErrors={validationErrors}
            name="empregadorId"
            onChange={(e, empregador) => {
              const empregadorId = empregador ? empregador.id : ''
              setData({ ...data, empregadorId })
            }}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            label="Código"
            fullWidth
            value={data?.codigo || ''}
            variant="outlined"
            size="small"
            required
            onlyNumber
            validationErrors={validationErrors}
            name="codigo"
            onChange={(e) => {
              const codigo = e.target.value
              setData({ ...data, codigo })
            }}
          />
        </Grid>

        <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data?.nome || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 100,
            }}
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteCBO
            name="cboId"
            required
            validationErrors={validationErrors}
            optionId="id"
            value={data.cboId || null}
            onChange={(e, cbo) => {
              const cboId = cbo?.id || ''
              setData({ ...data, cboId, cbo })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default CadastroFuncao
