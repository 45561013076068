import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import app from './app'
import menuVinculo from './menuVinculo'

const appPersistConfig = {
  key: 'datac-pessoal:app',
  storage,
  blacklist: ['error', 'loading', 'secundaryLeftMenu'],
}

const appReducer = combineReducers({
  app: persistReducer(appPersistConfig, app),
  menuVinculo,
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    Object.keys(localStorage).forEach(function (key) {
      if (key !== '@datac-pessoal:app') {
        if (/^persist:@datac-pessoal/.test(key)) {
          localStorage.removeItem(key)
        }
      }
    })
  }

  return appReducer(state, action)
}

export default rootReducer
