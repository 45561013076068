import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoAlteracaoContratoCadastro } from './VinculoAlteracaoContratoCadastro'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'

export function useAlteracaoContratoCadastroGetByVinculo(
  vinculoId: string,
  evento: ESocialEventoEnum.S_2205 | ESocialEventoEnum.S_2206 | ESocialEventoEnum.S_2306,
) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoAlteracaoContratoCadastro[] }>(
      '/VinculoAlteracaoContratoCadastro/GetByVinculo',
      {
        params: {
          vinculoId,
          evento,
        },
      },
    )
    return response?.data?.data || []
  }

  return useQuery(
    ['VinculoAlteracaoContratoCadastroGetByVinculo', vinculoId, evento],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}
