import React, { useEffect, useCallback, useState } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Button, ConfirmDeleteDialog, PageHeader, TreeView } from '~/components'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { useDelete } from '~/hooks/queries/useVinculoReintegracao'
import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

import api from '~/services/api-pessoal'

import Table from './components/Table'
import Form from './components/Form'

export default function VinculoReintegracao() {
  const [vinculo, setVinculo] = useState({})
  const [isLoadingOnClick, setLoadingOnClick] = useState(false)

  const { data: dataForm, isOpen: isOpenForm, open: openForm, close: closeForm } = useDialog()

  const {
    data: dataConfirmDelete,
    open: openConfirmDelete,
    isOpen: isOpenConfirmDelete,
    close: closeConfirmDelete,
  } = useDialog('')

  const { mutateAsync, isLoading: isDeleting } = useDelete()

  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Estagiario_3,
    VinculoTipoEnum.Autonomo_4,
    VinculoTipoEnum.Cooperado_5,
    VinculoTipoEnum.Empregador_2,
  ])
  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    setVinculo({})
  }, [anoMes, estabelecimento])

  function handleClickItem(event, value) {
    const handleClickEditItem = async (obj) => {
      openForm({
        ...obj,
        dtRescisao: vinculo?.dtRescisao,
        motivoDesligamento: vinculo?.motivoDesligamento,
      })
    }

    const handleClickDeleteItem = (id) => {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  const handleClickAddItem = useCallback(() => {
    openForm({
      vinculoId: vinculo?.id,
      dtRescisao: vinculo?.dtRescisao,
      motivoDesligamento: vinculo?.motivoDesligamento,
    })
    //eslint-disable-next-line
  }, [vinculo])

  async function handleConfirmDelete() {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  async function handleGetVinculo(vinculo) {
    const vinculoId = vinculo?.vinculoId
    if (!vinculoId) return
    try {
      setLoadingOnClick(true)
      const response = await api.get(`Vinculo/${vinculoId}`)
      const data = response?.data?.data
      setVinculo({
        id: data.id,
        nome: data?.pessoaFisica?.nome,
        dtRescisao: data?.dtRescisao,
        motivoDesligamento: data?.motivoDesligamento,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingOnClick(false)
    }
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(_, vinculo) => handleGetVinculo(vinculo)}
      searchBy="vinculoNome"
      renderOption={(option) => `${option?.vinculoNome}`}
      isLoadingOnClick={isLoadingOnClick}
      isLoading={isLoadingMenu}
    >
      <PageHeader title="Reintegração" subtitle={vinculo?.nome || '-'}>
        {vinculo?.id && (
          <ButtonBox>
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        )}
      </PageHeader>

      <Table vinculo={vinculo} onItemClick={handleClickItem} />

      <Form vinculo={vinculo} data={dataForm} isOpen={isOpenForm} onClose={closeForm} />

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isDeleting}
        onCancel={closeConfirmDelete}
        onConfirm={handleConfirmDelete}
      />
    </TreeView>
  )
}
