import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { VinculoFaltas } from './VinculoFaltas'

import { queryObterDataFaltas } from './useObterDataFaltas'

export function useCreateVinculoFaltas() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: VinculoFaltas) {
    await api.post('/VinculoFaltas', data)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.post()
      await queryClient.invalidateQueries(queryObterDataFaltas)
    },
    onError: dialogNotification.extractErrors,
  })
}
