export enum IndMotivoDesligamentoEnum {
  RescisaoComJustaCusaIniciativaEmpregador_01 = 1,
  RescisaoSemJustaCausaIniciativaEmpregador_02 = 2,
  RescisaoAntecipadaIniciativaEmpregador_03 = 3,
  RescisaoAntecipadaIniciativaEmpregado_04 = 4,
  RescisaoCulptaReciprova_05 = 5,
  RescisãoTerminoContrato_06 = 6,
  RescisãoIniciativaEmpregador_07 = 7,
  RescisãoInteresseEmpregado_08 = 8,
  RescisãoVirtudeFalecimentoEmpregador_09 = 9,
  RescisãoFalecimentoEmpregado_10 = 10,
  TransferênciaEmpregadoMesmoGrupo_11 = 11,
  TransferênciaEmpregadoConsorciada_12 = 12,
  TransferênciaEmpregadoConsorcio_13 = 13,
  RescisãoContratoEncerramentoEmpresa_14 = 14,
  RescisãoContratoAprendizagem_15 = 15,
  DeclaraçãoNulidade_16 = 16,
  RescisãoIndireta_17 = 17,
  AposentadoriaCompulsoria_18 = 18,
  AposentadoriaIdade_19 = 19,
  AposentadoriaIdadeTempoContribuicao_20 = 20,
  ReformaMilitar_21 = 21,
  ReservaMilitar_22 = 22,
  Exoneracao_23 = 23,
  Demissão_24 = 24,
  VacanciaCargoEfetivo_25 = 25,
  RescisaoContratoParalisacao_26 = 26,
  RescisaoMotivoForçaMaior_27 = 27,
  TerminoCessaoRequisicao_28 = 28,
  Redistribuicao_29 = 29,
  MudançaRegimeTrabalhista_30 = 30,
  ReversaoReintegracao_31 = 31,
  ExtravioMilitar_32 = 32,
  RescisaoAcordo_33 = 33,
  TransferênciaTitularidade_34 = 34,
  ExtinçãoContratoTrabalhoIntermitente_35 = 35,
  MudancaCPF_36 = 36,
  Remocao_37 = 37,
  AposentadoriaExcetoInvalidez_38 = 38,
  AposentadoriaServidorEstatutario_39 = 39,
  TerminoMandatoEletivo_40 = 40,
  RescisãoAprendizagemDesempenhoInsuficiente_41 = 41,
  RescisãoAprendizagemAusênciaIinjustificada_42 = 42,
  NaoAplicavel_99 = 99,
}

export const IndMotivoDesligamentoRescisoes = [
  IndMotivoDesligamentoEnum.RescisaoComJustaCusaIniciativaEmpregador_01,
  IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02,
  IndMotivoDesligamentoEnum.RescisaoAntecipadaIniciativaEmpregador_03,
  IndMotivoDesligamentoEnum.RescisaoAntecipadaIniciativaEmpregado_04,
  IndMotivoDesligamentoEnum.RescisaoCulptaReciprova_05,
  IndMotivoDesligamentoEnum.RescisãoTerminoContrato_06,
  IndMotivoDesligamentoEnum.RescisãoIniciativaEmpregador_07,
  IndMotivoDesligamentoEnum.RescisãoInteresseEmpregado_08,
  IndMotivoDesligamentoEnum.RescisãoVirtudeFalecimentoEmpregador_09,
  IndMotivoDesligamentoEnum.RescisãoFalecimentoEmpregado_10,
  IndMotivoDesligamentoEnum.RescisãoContratoEncerramentoEmpresa_14,
  IndMotivoDesligamentoEnum.RescisãoContratoAprendizagem_15,
  IndMotivoDesligamentoEnum.RescisãoIndireta_17,
  IndMotivoDesligamentoEnum.RescisaoContratoParalisacao_26,
  IndMotivoDesligamentoEnum.RescisaoMotivoForçaMaior_27,
  IndMotivoDesligamentoEnum.RescisaoAcordo_33,
  IndMotivoDesligamentoEnum.RescisãoAprendizagemDesempenhoInsuficiente_41,
  IndMotivoDesligamentoEnum.RescisãoAprendizagemAusênciaIinjustificada_42,
]

export const IndMotivoDesligamentoValues = [
  {
    name: '01 - Rescisão com justa causa, por iniciativa do empregador',
    value: IndMotivoDesligamentoEnum.RescisaoComJustaCusaIniciativaEmpregador_01,
  },
  {
    name: '02 - Rescisão sem justa causa, por iniciativa do empregador',
    value: IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02,
  },
  {
    name: '03 - Rescisão antecipada do contrato a termo por iniciativa do empregador',
    value: IndMotivoDesligamentoEnum.RescisaoAntecipadaIniciativaEmpregador_03,
  },
  {
    name: '04 - Rescisão antecipada do contrato a termo por iniciativa do empregado',
    value: IndMotivoDesligamentoEnum.RescisaoAntecipadaIniciativaEmpregado_04,
  },
  {
    name: '05 - Rescisão por culpa recíproca',
    value: IndMotivoDesligamentoEnum.RescisaoCulptaReciprova_05,
  },
  {
    name: '06 - Rescisão por término do contrato a termo',
    value: IndMotivoDesligamentoEnum.RescisãoTerminoContrato_06,
  },
  {
    name: '07 - Rescisão do contrato de trabalho por iniciativa do empregado',
    value: IndMotivoDesligamentoEnum.RescisãoIniciativaEmpregador_07,
  },
  {
    name: '08 - Rescisão do contrato de trabalho por interesse do(a) empregado(a), nas hipóteses previstas nos arts. 394 e 483, § 1º, da CLT',
    value: IndMotivoDesligamentoEnum.RescisãoInteresseEmpregado_08,
  },
  {
    name: '09 - Rescisão por opção do empregado em virtude de falecimento do empregador individual ou empregador doméstico',
    value: IndMotivoDesligamentoEnum.RescisãoVirtudeFalecimentoEmpregador_09,
  },
  {
    name: '10 - Rescisão por falecimento do empregado',
    value: IndMotivoDesligamentoEnum.RescisãoFalecimentoEmpregado_10,
  },
  {
    name: '11 - Transferência de empregado para empresa do mesmo grupo empresarial que tenha assumido os encargos trabalhistas, sem que tenha havido rescisão do contrato de trabalho',
    value: IndMotivoDesligamentoEnum.TransferênciaEmpregadoMesmoGrupo_11,
  },
  {
    name: '12 - Transferência de empregado da empresa consorciada para o consórcio que tenha assumido os encargos trabalhistas, e vice-versa, sem que tenha havido rescisão do contrato de trabalho',
    value: IndMotivoDesligamentoEnum.TransferênciaEmpregadoConsorciada_12,
  },
  {
    name: '13 - Transferência de empregado de empresa ou consórcio, para outra empresa ou consórcio que tenha assumido os encargos trabalhistas por motivo de sucessão (fusão, cisão ou incorporação), sem que tenha havido rescisão do contrato de trabalho',
    value: IndMotivoDesligamentoEnum.TransferênciaEmpregadoConsorcio_13,
  },
  {
    name: '14 - Rescisão do contrato de trabalho por encerramento da empresa, de seus estabelecimentos ou supressão de parte de suas atividades ou falecimento do empregador individual ou empregador doméstico sem continuação da atividade',
    value: IndMotivoDesligamentoEnum.RescisãoContratoEncerramentoEmpresa_14,
  },
  {
    name: '15 - Rescisão do contrato de aprendizagem por desempenho insuficiente, inadaptação ou ausência injustificada do aprendiz à escola que implique perda do ano letivo',
    value: IndMotivoDesligamentoEnum.RescisãoContratoAprendizagem_15,
  },
  {
    name: '16 - Declaração de nulidade do contrato de trabalho por infringência ao inciso II do art. 37 da Constituição Federal, quando mantido o direito ao salário',
    value: IndMotivoDesligamentoEnum.DeclaraçãoNulidade_16,
  },
  {
    name: '17 - Rescisão indireta do contrato de trabalho ',
    value: IndMotivoDesligamentoEnum.RescisãoIndireta_17,
  },
  {
    name: '18 - Aposentadoria compulsória',
    value: IndMotivoDesligamentoEnum.AposentadoriaCompulsoria_18,
  },
  { name: '19 - Aposentadoria por idade ', value: IndMotivoDesligamentoEnum.AposentadoriaIdade_19 },
  {
    name: '20 - Aposentadoria por idade e tempo de contribuição',
    value: IndMotivoDesligamentoEnum.AposentadoriaIdadeTempoContribuicao_20,
  },
  { name: '21 - Reforma militar', value: IndMotivoDesligamentoEnum.ReformaMilitar_21 },
  { name: '22 - Reserva militar', value: IndMotivoDesligamentoEnum.ReservaMilitar_22 },
  { name: '23 - Exoneração', value: IndMotivoDesligamentoEnum.Exoneracao_23 },
  { name: '24 - Demissão ', value: IndMotivoDesligamentoEnum.Demissão_24 },
  {
    name: '25 - Vacância de cargo efetivo',
    value: IndMotivoDesligamentoEnum.VacanciaCargoEfetivo_25,
  },
  {
    name: '26 - Rescisão do contrato de trabalho por paralisação temporária ou definitiva da empresa, estabelecimento ou parte das atividades motivada por atos de autoridade municipal, estadual ou federal',
    value: IndMotivoDesligamentoEnum.RescisaoContratoParalisacao_26,
  },
  {
    name: '27 - Rescisão por motivo de força maior ',
    value: IndMotivoDesligamentoEnum.RescisaoMotivoForçaMaior_27,
  },
  {
    name: '28 - Término da cessão/requisição ',
    value: IndMotivoDesligamentoEnum.TerminoCessaoRequisicao_28,
  },
  { name: '29 - Redistribuição', value: IndMotivoDesligamentoEnum.Redistribuicao_29 },
  {
    name: '30 - Mudança de regime trabalhista',
    value: IndMotivoDesligamentoEnum.MudançaRegimeTrabalhista_30,
  },
  {
    name: '31 - Reversão de reintegração',
    value: IndMotivoDesligamentoEnum.ReversaoReintegracao_31,
  },
  { name: '32 - Extravio de militar', value: IndMotivoDesligamentoEnum.ExtravioMilitar_32 },
  {
    name: '33 - Rescisão por acordo entre as partes (art. 484-A da CLT)',
    value: IndMotivoDesligamentoEnum.RescisaoAcordo_33,
  },
  {
    name: '34 - Transferência de titularidade do empregado doméstico para outro representante da mesma unidade familiar',
    value: IndMotivoDesligamentoEnum.TransferênciaTitularidade_34,
  },
  {
    name: '35 - Extinção do contrato de trabalho intermitente',
    value: IndMotivoDesligamentoEnum.ExtinçãoContratoTrabalhoIntermitente_35,
  },
  { name: '36 - Mudança de CPF', value: IndMotivoDesligamentoEnum.MudancaCPF_36 },
  {
    name: '37 - Remoção, em caso de alteração do órgão declarante',
    value: IndMotivoDesligamentoEnum.Remocao_37,
  },
  {
    name: '38 - Aposentadoria, exceto por invalidez',
    value: IndMotivoDesligamentoEnum.AposentadoriaExcetoInvalidez_38,
  },
  {
    name: '39 - Aposentadoria de servidor estatutário, por invalidez',
    value: IndMotivoDesligamentoEnum.AposentadoriaServidorEstatutario_39,
  },
  {
    name: '40 - Término do exercício do mandato eletivo',
    value: IndMotivoDesligamentoEnum.TerminoMandatoEletivo_40,
  },
  {
    name: '41 - Rescisão do contrato de aprendizagem por desempenho insuficiente ou inadaptação do aprendiz',
    value: IndMotivoDesligamentoEnum.RescisãoAprendizagemDesempenhoInsuficiente_41,
  },
  {
    name: '42 - Rescisão do contrato de aprendizagem por ausência injustificada do aprendiz à escola que implique perda do ano letivo',
    value: IndMotivoDesligamentoEnum.RescisãoAprendizagemAusênciaIinjustificada_42,
  },
]
