import { formatCurrency } from '~/hooks/useUtils'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import clsx from 'clsx'

import { Box, IconButton, makeStyles } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'

import { ButtonBox, CopyLabel, ToolsDataTable } from '~/components'

import FGTSEstabelecimento from './FGTSEstabelecimento'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import useObterApuracaoEncargosEmpregadorFGTS from '~/hooks/queries/ApurarEncargos/useObterApuracaoEncargosEmpregadorFGTS'
import { useApuracaoEncargos } from '~/pages/ApuracaoEncargos'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ApuracaoEncargosFGTSDTO } from '~/hooks/queries/ApurarEncargos/ApuracaoEncargosFGTSDTO'
import { Ativo } from '~/components/Displays'

const useStyles = makeStyles((theme: FixLater) => ({
  alignRight: {
    textAlign: 'right',
  },
  cellError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.error.light,
    },
  },
}))

export default function FGTSDigital() {
  const { anoMes } = useAmbiente()
  const { grupoId, isOnlyError, query } = useApuracaoEncargos()
  const classes = useStyles()
  const {
    close: closeFGTSEstabelecimento,
    data: dataFGTSEstabelecimento,
    isOpen: isOpenFGTSEstabelecimento,
    open: openFGTSEstabelecimento,
  } = useDialog<ApuracaoEncargosFGTSDTO | null>(null)

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterApuracaoEncargosEmpregadorFGTS({
    periodoApuracao: anoMes,
    isSomenteDiferenca: isOnlyError,
    grupoId,
  })

  const _data = _d || []

  const data = _data.map((d) => {
    return {
      ...d,
      vrBaseFolha: d?.vrFolha ? formatCurrency(d.vrBaseFolha) : '',
      vrFolha: d?.vrFolha ? formatCurrency(d.vrFolha) : '',
      vrBaseESocial: d?.vrBaseESocial ? formatCurrency(d.vrBaseESocial) : '',
      vrBaseRescisorio: d?.vrBaseRescisorio ? formatCurrency(d.vrBaseRescisorio) : '',
      vrESocial: d?.vrESocial ? formatCurrency(d.vrESocial) : '',
      vrDiferenca: d?.vrDiferenca ? formatCurrency(d.vrDiferenca) : '',
      vrRescisorio: d?.vrRescisorio ? formatCurrency(d.vrRescisorio) : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'vrBaseFolha',
      label: 'Folha Base',
      options: {
        setCellProps: (_, rowIndex) => ({
          className: clsx(classes.alignRight, {
            [classes.cellError]: _data[rowIndex].temDiferenca,
          }),
        }),
      },
    },
    {
      name: 'vrBaseESocial',
      label: 'ESocial Base',
      options: {
        setCellProps: (_, rowIndex) => ({
          className: clsx(classes.alignRight, {
            [classes.cellError]: _data[rowIndex].temDiferenca,
          }),
        }),
      },
    },
    {
      name: 'vrBaseRescisorio',
      label: 'Rescisão Base',
      options: {
        setCellProps: (_, rowIndex) => ({
          className: clsx(classes.alignRight, {
            [classes.cellError]: _data[rowIndex].temDiferenca,
          }),
        }),
      },
    },
    {
      name: 'vrFolha',
      label: 'Folha',
      options: {
        setCellProps: (_, rowIndex) => ({
          className: clsx(classes.alignRight, {
            [classes.cellError]: _data[rowIndex].temDiferenca,
          }),
        }),
      },
    },
    {
      name: 'vrESocial',
      label: 'ESocial',
      options: {
        setCellProps: (_, rowIndex) => ({
          className: clsx(classes.alignRight, {
            [classes.cellError]: _data[rowIndex].temDiferenca,
          }),
        }),
      },
    },
    {
      name: 'vrRescisorio',
      label: 'Rescisão',
      options: {
        setCellProps: (_, rowIndex) => ({
          className: clsx(classes.alignRight, {
            [classes.cellError]: _data[rowIndex].temDiferenca,
          }),
        }),
      },
    },
    {
      name: 'vrDiferenca',
      label: 'Valor Diferença',
      options: {
        setCellProps: (_, rowIndex) => ({
          className: clsx(classes.alignRight, {
            [classes.cellError]: _data[rowIndex].temDiferenca,
          }),
        }),
      },
    },
    {
      name: 'temDiferenca',
      label: 'Diferença',
      options: {
        customBodyRender: (value) => (value ? <Ativo ativo={false} /> : <></>),
      },
    },
    {
      label: 'Ações',
      name: 'id',
      options: {
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Detalhar registro"
                color="primary"
                aria-label="Detalhar"
                onClick={() => {
                  const rowCurrent = _data.find((d) => d.id === value)
                  if (!rowCurrent) return
                  openFGTSEstabelecimento(rowCurrent)
                }}
              >
                <Visibility fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Box>
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        disableAutoHeight
        sherlock={{
          columns: ['nrInscricao', 'nome'],
          query,
        }}
      />
      {isOpenFGTSEstabelecimento && dataFGTSEstabelecimento ? (
        <FGTSEstabelecimento
          isOpen={isOpenFGTSEstabelecimento}
          onClose={closeFGTSEstabelecimento}
          empregador={dataFGTSEstabelecimento}
          isSomenteDiferenca={isOnlyError}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
