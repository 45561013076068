import React from 'react'

import { Box, useTheme, Icon } from '@material-ui/core'

import Button, { ButtonProps } from '../Button'

interface ButtonIconProps extends ButtonProps {
  icon: string | React.ReactNode
  children: React.ReactNode
}

export default function ButtonIcon({ icon, children, ...rest }: ButtonIconProps) {
  const theme = useTheme()

  return (
    <Button {...rest}>
      <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
        {typeof icon === 'string' ? <Icon>{icon}</Icon> : icon}
        {children}
      </Box>
    </Button>
  )
}
