const hora_1 = 1
const dia_2 = 2
const semana_3 = 3
const quinzena_4 = 4
const mes_5 = 5
const tarefa_6 = 6
const naoAplicavel_7 = 7

export const indUnidadeSalarioValues = [
  { value: hora_1, name: '1 - Por Hora' },
  { value: dia_2, name: '2 - Por Dia' },
  { value: semana_3, name: '3 - Por Semana' },
  { value: quinzena_4, name: '4 - Por Quinzena' },
  { value: mes_5, name: '5 - Por Mês' },
  { value: tarefa_6, name: '6 - Por Tarefa' },
  { value: naoAplicavel_7, name: '7 - Variável' },
]

export const indUnidadeSalarioConsts = {
  hora_1,
  dia_2,
  semana_3,
  quinzena_4,
  mes_5,
  tarefa_6,
  naoAplicavel_7,
}
