import { useEffect, useState } from 'react'

import * as yup from 'yup'
import api from '~/services/api-pessoal'

import { Box, Paper } from '@material-ui/core'
import { Button, Finder, PasswordTextField } from 'mio-library-ui'

import { ActionDialog, PageHeader, RadioGroup } from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

const schema = yup.object().shape({
  senhaEsocial: yup.string().required('Informe uma Senha'),
})

export default function LimparGerarAmbiente() {
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelecteds] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isSubmiting, setSubmiting] = useState(false)
  const [query, setQuery] = useState('')
  const [typeMethod, setTypeMethod] = useState('clear')
  const [senhaEsocial, setSenhaEsocial] = useState('')

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const {
    close: closeConfirmPassword,
    isOpen: isOpenConfirmPassword,
    open: openConfirmPassword,
  } = useDialog()
  const { handleValidate, validationErrors } = useValidationErrors({
    data: { senhaEsocial },
    handleSubmit: handleProcess,
    schema,
  })

  useEffect(() => {
    async function getCollection() {
      setLoading(true)
      try {
        const response = await api.get('/EmpregadorConsulta')
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  async function handleProcess() {
    setSubmiting(true)
    try {
      let url = '/Esocial/LimparAmbienteDeTeste'
      let successMessage = 'Os ambientes foram limpos com sucesso'

      const empregadoresIds = rowsSelecteds.map((index) => collection[index].id)

      if (typeMethod !== 'clear') {
        url = '/Esocial/GerarAmbienteTeste'
        successMessage = 'Os ambientes foram gerados com sucesso'
      }

      await api.post(url, empregadoresIds, {
        params: {
          senhaEsocial,
        },
      })

      notification.success(successMessage)
      closeConfirmPassword()
      setRowsSelecteds([])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmiting(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box height="50px">
        <PageHeader title="Limpar ou Gerar Ambiente eSocial">
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </PageHeader>
      </Box>
      <Box component={Paper} padding={2} paddingBottom={2} paddingTop={2} paddingLeft={2}>
        <RadioGroup
          onChange={setTypeMethod}
          value={typeMethod}
          options={[
            {
              label: 'Limpar',
              value: 'clear',
            },
            {
              label: 'Gerar',
              value: 'generate',
            },
          ]}
        />
      </Box>
      <Table
        data={collection}
        query={query}
        isLoading={isLoading}
        rowsSelected={rowsSelecteds}
        handleRowSelected={setRowsSelecteds}
      />
      <Box display="flex" justifyContent="flex-end" pt={1}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={!(rowsSelecteds.length > 0)}
          onClick={openConfirmPassword}
        >
          {typeMethod === 'clear' ? 'Limpar Ambiente' : 'Gerar Ambiente'}
        </Button>
      </Box>

      <ActionDialog
        title="Informe a senha para prosseguir"
        isOpen={isOpenConfirmPassword}
        onClose={closeConfirmPassword}
        onCancelClick={closeConfirmPassword}
        onOkClick={handleValidate}
        okLabel="Confirmar"
        isOkProcessing={isSubmiting}
      >
        <PasswordTextField
          label="Senha"
          required
          name="senhaEsocial"
          validationErrors={validationErrors}
          onChange={(e) => setSenhaEsocial(e.target.value)}
          value={senhaEsocial}
          variant="outlined"
          size="small"
          fullWidth
        />
      </ActionDialog>
    </Box>
  )
}
