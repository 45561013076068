import React from 'react'

import { Box } from '@material-ui/core'
import { ActionDialog, ToolsDataTable } from '~/components'

export default function TableHorariosTrabalho(props) {
  const { isOpen, onClose, data } = props

  const columns = [
    {
      name: 'diaInicio',
      label: 'Início',
    },
    {
      name: 'diaFim',
      label: 'Fim',
    },
    {
      name: 'horaEntrada',
      label: 'Entrada',
    },
    {
      name: 'horaIntervaloInicio',
      label: 'Início Intervalo',
    },
    {
      name: 'horaIntervaloFim',
      label: 'Fim Intervalo',
    },
    {
      name: 'horaSaida',
      label: 'Saída',
    },
  ]

  return (
    <ActionDialog
      title="Horários de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      customActions={<></>}
    >
      <Box pb={1}>
        <ToolsDataTable data={data} columns={columns} pagination={false} disableAutoHeight />
      </Box>
    </ActionDialog>
  )
}
