import React, { useEffect, useState } from 'react'

import { Button } from 'mio-library-ui'
import { Grid, makeStyles } from '@material-ui/core'

import { AlertContainer, DatePicker } from '~/components'

import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import api from '~/services/api-pessoal'

import moment from 'moment'
import * as yup from 'yup'

const useStyles = makeStyles((theme) => ({
  infoMessageHeader: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  actionsHeader: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}))

const schema = yup.object().shape({
  dtPagamento: yup.date().when(['$anoMes'], (anoMes, schema) =>
    schema
      .nullable()
      .required('Informe a Data de Pagamento')
      .test(
        'verify-dt-in-competencia-current',
        'Informe uma Data dentro da competência atual',
        (value) => {
          const dtStartCompetencia = moment(anoMes)
            .startOf('month')
            .subtract(1, 'day')
            .format('yyyy-MM-DD')
          const dtEndCompetencia = moment(anoMes).endOf('month').add(1, 'day').format('yyyy-MM-DD')
          return moment(value).isBetween(dtStartCompetencia, dtEndCompetencia)
        },
      ),
  ),
})

export default function Header(props) {
  const { isLoadingProcess, handleProcess, rowsSelecteds, collection } = props
  const [validationErrors, setValidationErrors] = useState(false)
  const [data, setData] = useState({})

  const { estabelecimento, anoMes } = useAmbiente()
  const notifications = useNotification()
  const classes = useStyles()

  useEffect(() => {
    async function getDataPagamentoDefault() {
      try {
        const responseEstabelecimento = await api.get(`/Estabelecimento/${estabelecimento?.id}`)
        const estabelecimentoCurrent = responseEstabelecimento?.data?.data || null
        if (!estabelecimentoCurrent?.tabelaMesId) return
        const responseTabelaMes = await api.get('/TabelaMes/GetTabelaMesItem', {
          params: {
            tabelaMesId: estabelecimentoCurrent?.tabelaMesId,
            anoMes,
          },
        })
        const dtPagamento = responseTabelaMes?.data?.data?.dtPagamentoAdiantamento || null
        setData((oldState) => ({
          ...oldState,
          dtPagamento,
        }))
      } catch (error) {
        console.log(error)
      }
    }
    getDataPagamentoDefault()
  }, [estabelecimento, anoMes])

  function onClickProcess() {
    if (!(rowsSelecteds?.length > 0))
      return notifications.error(
        'Selecione pelo menos um Funcionário para aplicar as configurações',
      )

    setValidationErrors(false)
    schema
      .validate(data, { abortEarly: false, context: { anoMes } })
      .then(() => handleProcess(data.dtPagamento))
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <AlertContainer>
      <Grid container spacing={2} alignItems="center">
        <Grid item xl={7} lg={7} md={7} sm={12} xs={12} className={classes.infoMessageHeader}>
          {rowsSelecteds.length > 0 ? (
            <>
              Funcionários selecionados: <strong>{rowsSelecteds.length || 0}</strong>/
              {collection?.length || 0}
            </>
          ) : (
            'Selecione os funcionários para realizar o Adiantamento de Salário'
          )}
        </Grid>

        <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className={classes.actionsHeader}>
          <DatePicker
            label="Data de Pagamento"
            value={data?.dtPagamento || null}
            onChange={(date) => {
              const dtPagamento = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({ ...oldState, dtPagamento }))
            }}
            required
            validationErrors={validationErrors}
            name="dtPagamento"
          />
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onClickProcess}
            isLoading={isLoadingProcess}
          >
            Processar
          </Button>
        </Grid>
      </Grid>
    </AlertContainer>
  )
}
