import { memo, useEffect } from 'react'

import moment from 'moment'

import { Grid, Box, makeStyles, CircularProgress, LinearProgress } from '@material-ui/core'

import { ActionDialog, ContentFrame } from '~/components'

import useAmbiente from '~/hooks/useAmbiente'
import { useGetByVinculoPeriodo } from '~/hooks/queries/VinculoLembrete/useGetByVinculoPeriodo'
import { VinculoLembrete } from '~/hooks/queries/VinculoLembrete/VinculoLembrete'

const useStyles = makeStyles((theme) => ({
  lembreteDatas: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(0.5),
  },
  lembreteDescricao: {
    width: '100%',
    overflow: 'auto',
    fontSize: '11pt',
  },
}))

interface LembreteItemProps {
  lembrete: VinculoLembrete
}

const LembreteItem = ({ lembrete }: LembreteItemProps) => {
  const classes = useStyles()

  return (
    <ContentFrame>
      <Box>
        <Box className={classes.lembreteDatas}>
          {lembrete?.dtInicio && moment(lembrete?.dtInicio).format('DD/MM/YYYY')} -{' '}
          {lembrete?.dtFim && moment(lembrete?.dtFim).format('DD/MM/YYYY')}
        </Box>
        <Box className={classes.lembreteDescricao}>{lembrete?.descricao}</Box>
      </Box>
    </ContentFrame>
  )
}

interface DialogLembretesProps {
  isOpen: boolean
  openDialog: () => void
  onClose: () => void
  vinculoId: string
}

const DialogLembretes = ({ isOpen, openDialog, onClose, vinculoId }: DialogLembretesProps) => {
  const { anoMes } = useAmbiente()
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useGetByVinculoPeriodo({
    anoMes,
    vinculoId,
  })
  const data = _data || []

  useEffect(() => {
    if (_data && _data.length > 0) {
      openDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_data])

  return (
    <ActionDialog
      title="Lembrentes"
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      customActions={<></>}
    >
      <Box pb={4}>
        {isFetching && <LinearProgress />}
        {isLoading && <CircularProgress />}
        {data.length == 0 && <Box>Não temos lembretes por enquanto ...</Box>}
        <Grid container spacing={2}>
          {data.map((lembrete) => (
            <Grid key={lembrete?.id} xl={12} item>
              <LembreteItem lembrete={lembrete} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ActionDialog>
  )
}

export default memo(DialogLembretes)
