import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import moment from 'moment'
import { HeightDataTable } from '~/components'
import { codigoTreinamentoCapacitacaoValues } from '~/values/codigoTreinamentoCapacitacaoValues'

const formatCodigoTreinamento = (codigoTreinamento) => {
  const optionCurrent = codigoTreinamentoCapacitacaoValues.find(
    (opt) => opt.value === codigoTreinamento,
  )
  return optionCurrent?.name || ''
}

const Table = (props) => {
  const { data, onItemClick, isLoading, isFinalizado } = props

  const dataFormated = data.map((data) => {
    return {
      ...data,
      dtRealizacao: data?.dtRealizacao ? moment(data.dtRealizacao).format('DD/MM/YYYY') : '',
      codigoTreinamento: formatCodigoTreinamento(data?.codigoTreinamento),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtRealizacao',
        label: 'Data da Realização',
      },
      {
        name: 'codigoTreinamento',
        label: 'Código do Treinamento',
      },
      {
        name: 'observacao',
        label: 'Observação',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          display: isFinalizado ? 'excluded' : 'true',
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick, isFinalizado],
  )

  return <HeightDataTable title="" data={dataFormated} columns={columns} isLoading={isLoading} />
}

Table.defaultProps = {
  query: '',
}

export default Table
