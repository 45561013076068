import React, { useState, useEffect, useCallback } from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import { ConfirmDeleteDialog, ActionDialog } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '70%',
  },
}))

const Empregadores = (props) => {
  const { isOpen, onClose, certificadoDigital } = props

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [form, setForm] = useState({
    isOpen: false,
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [query, setQuery] = useState('')

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const classes = useStyles()

  const getCollection = useCallback(async () => {
    if (!certificadoDigital?.id) return
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get(
        `/EmpregadorConsulta/GetCertificado?certificadoId=${certificadoDigital?.id}`,
      )
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
    // eslint-disable-next-line
  }, [certificadoDigital])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleOpenForm = useCallback(() => {
    setForm({
      isOpen: true,
    })
  }, [])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [collection, handleOpenForm],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(
        `/CertificadoDigital/RemoverEmpregador?empregadorId=${confirmDeleteDialog.id}`,
      )
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id, handleCloseConfirmDeleteItem])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [handleOpenForm])

  const handleCloseForm = useCallback(() => {
    setForm({
      isOpen: false,
    })
  }, [])

  const handleAfterSubmitForm = useCallback(() => {
    getCollection()
    handleCloseForm()
  }, [getCollection, handleCloseForm])

  return (
    <ActionDialog
      title="Empregadores Certificado"
      subtitle={certificadoDigital?.requerente || ''}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: classes.heightPaper,
        },
      }}
      customActions={<></>}
    >
      <Box height="50px">
        <PageHeader>
          <ButtonBox>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
            <Button size="small" color="primary" variant="contained" onClick={handleClickAddItem}>
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        query={query}
        onItemClick={handleClickItem}
      />

      <Form
        isOpen={form.isOpen}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
        certificadoDigital={certificadoDigital}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ActionDialog>
  )
}

export default Empregadores
