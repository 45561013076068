import { Box } from '@material-ui/core'

interface IndicatorStatusProps {
  color: string
}

export default function IndicatorStatus({ color }: IndicatorStatusProps) {
  return (
    <Box height={4} width={4} border={4} borderColor={color} borderRadius="50%" padding={0.5} />
  )
}
