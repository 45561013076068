export enum GrauParentescoEnum {
  Cônjuge_01 = 1,
  CompanheiroUniaoEstável_02 = 2,
  FilhoOuEnteado_03 = 3,
  FilhoOuEnteadoEstudante_04 = 4,
  IrmaoNetoOuBisneto_06 = 6,
  IrmaoNetoOuBisneto_Estudante_07 = 7,
  PaisAvosBisavos_09 = 9,
  MenorPobreGuardaJudicial_10 = 10,
  PessoaIncapazTutorOuCurador_11 = 11,
  ExConjuge_12 = 12,
  AgregadoOutros_99 = 99,
}

export const GrauParentescoValues = [
  {
    value: GrauParentescoEnum.Cônjuge_01,
    name: '01 - Cônjuge',
  },
  {
    value: GrauParentescoEnum.CompanheiroUniaoEstável_02,
    name: '02 - Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua Declaração de União Estável',
  },
  {
    value: GrauParentescoEnum.FilhoOuEnteado_03,
    name: '03 - Filho(a) ou enteado(a)',
  },
  {
    value: GrauParentescoEnum.FilhoOuEnteadoEstudante_04,
    name: '04 - Filho(a) ou enteado(a), universitário(a) ou cursando escola técnica de 2º grau',
  },
  {
    value: GrauParentescoEnum.IrmaoNetoOuBisneto_06,
    name: '06 - Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do (a)qual detenha a guarda judicial',
  },
  {
    value: GrauParentescoEnum.IrmaoNetoOuBisneto_Estudante_07,
    name: '07 - Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, universitário(a) ou cursando escola técnica de 2° grau, do (a)qual detenha a guarda judicial',
  },
  {
    value: GrauParentescoEnum.PaisAvosBisavos_09,
    name: '09 - Pais, avós e bisavós',
  },
  {
    value: GrauParentescoEnum.MenorPobreGuardaJudicial_10,
    name: '10 - Menor pobre do qual detenha a guarda judicial',
  },
  {
    value: GrauParentescoEnum.PessoaIncapazTutorOuCurador_11,
    name: '11 - A pessoa absolutamente incapaz, da qual seja tutor ou curador',
  },
  {
    value: GrauParentescoEnum.ExConjuge_12,
    name: '12 - Ex - cônjuge',
  },
  {
    value: GrauParentescoEnum.AgregadoOutros_99,
    name: '99 - Agregado / Outros',
  },
]
