import { useState } from 'react'

import { Box, Grid, TextField, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { Button } from '~/components'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-auth'

const useStyles = makeStyles(() => ({
  boxButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    minWidth: '400px',
  },
}))

export default function EsqueciSenha(props) {
  const { onLoginClick } = props

  const [email, setEmail] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles()

  const notifications = useNotification()
  const dialogNotification = useDialogNotification()

  const handleRecuperarSenha = async () => {
    setIsLoading(true)
    try {
      if (!email) {
        notifications.error('Informe o seu e-mail', 'Ops')
        setIsLoading(false)
        return
      }

      await api.put('/passwords/recover', { email })
      notifications.success(
        'Foi enviado um e-mail pra você com sua nova senha de acesso',
        'Seja bem vindo!',
      )
      setTimeout(() => {
        setIsLoading(false)
        onLoginClick()
      }, 2000)
    } catch (err) {
      setIsLoading(false)
      dialogNotification.extractErrors(err)
    }
    setIsLoading(false)
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={3}>
        <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="small"
            margin="none"
            variant="outlined"
            fullWidth
            label="E-mail"
            placeholder="Informe o e-mail que você utiliza para acessar o sistema"
          />
        </Grid>
        <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
          <Box className={classes.boxButtons}>
            <Button onClick={onLoginClick} color="primary" variant="text" size="small">
              Voltar
            </Button>
            <Button
              isLoading={isLoading}
              onClick={handleRecuperarSenha}
              color="primary"
              variant="contained"
              size="small"
            >
              Recuperar Senha
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

EsqueciSenha.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
}
