import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ESocialGrupoEventoEnum } from '~/@types/enums/ESocialGrupoEventoEnum'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'
import { ErroSchema } from './ErroSchema'

export const queryUseObterEventosTransmissaoEnvio = '/ESocial/ObterEventosTransmissaoEnvio'

export interface ESocialAuditoriaXMLDTO {
  errosSchema: ErroSchema[]
  xmlEnvio: string
  retorno_Xml: string
  retorno_DataHora: Date | string | null
  ocorrencias: string[]
}

export interface ESocialAuditoriaTransmitirDTO {
  auditoriaId: string
  auditoriaDescricao: string
  tabelaOrigem: string
  dtMovimento: Date | string
  eSocialRecibo: string
  tipoMovimento: string
  indRetificacao: string
  dtESocialRecibo: Date | string | null
  auditoriaXML: ESocialAuditoriaXMLDTO | null
}

export interface ESocialGrupoEventoTransmitirDTO {
  grupoEventoId: ESocialEventoEnum
  grupoEventoDescricao: string
  totalEventos: number
  eventos: ESocialAuditoriaTransmitirDTO[]
}

export interface ESocialEmpregadorTransmitirDTO {
  empregadorId: string
  empregadorNome: string
  empregadorNrInscricao: string
  totalEventos: number
  grupoEventos: ESocialGrupoEventoTransmitirDTO[]
}

interface UseObterEventosTransmissaoEnvioProps {
  grupoEvento: ESocialGrupoEventoEnum
  anoMes: string
  filterEventos: ESocialEventoEnum[]
  grupoId: string | null
  indApuracao: IndApuracaoEnum
}

export function useObterEventosTransmissaoEnvio(props: UseObterEventosTransmissaoEnvioProps) {
  const { grupoEvento, anoMes, filterEventos, grupoId, indApuracao } = props

  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: ESocialEmpregadorTransmitirDTO[] }>(
      queryUseObterEventosTransmissaoEnvio,
      filterEventos,
      {
        params: { grupoEvento, anoMes, indApuracao, grupoId },
      },
    )
    return response?.data?.data || []
  }

  const query = useQuery(
    [
      queryUseObterEventosTransmissaoEnvio,
      grupoEvento,
      anoMes,
      indApuracao,
      grupoId,
      filterEventos,
    ],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )

  return {
    ...query,
    data: query?.data || [],
  }
}
