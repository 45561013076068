import React from 'react'

import moment from 'moment'

import { ButtonBox } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import { formatCurrency } from '~/hooks/useUtils'

import {
  IndSalarioContratualEnum,
  IndSalarioContratualValues,
} from '~/@types/enums/IndSalarioContratualEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { useVinculoSalarioBaseGetByVinculo } from '~/hooks/queries/VinculoSalarioBase/useVinculoSalarioBaseGetByVinculo'

function formatIndSalario(indSalario: IndSalarioContratualEnum) {
  const objectFinded = IndSalarioContratualValues.find((obj) => obj.value === indSalario)
  return objectFinded?.name || ''
}

interface TableProps {
  vinculoId: string
}

export default function Table(props: TableProps) {
  const { vinculoId } = props
  const { data, isLoading, isFetching } = useVinculoSalarioBaseGetByVinculo(vinculoId)

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtSalario',
      label: 'Data Salário',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : null),
      },
    },
    {
      name: 'dtEfetiva',
      label: 'Data Efetiva',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : null),
      },
    },
    {
      name: 'indSalario',
      label: 'Indicador do Salário',
      options: {
        customBodyRender: (value) => formatIndSalario(value),
      },
    },
    {
      name: 'quantidade',
      label: 'Quantidade',
    },
    {
      name: 'vrSalario',
      label: 'Valor',
      options: {
        customBodyRender: (value) => <ButtonBox>{formatCurrency(value)}</ButtonBox>,
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
  ]

  return (
    <HeightDataTable data={data} columns={columns} isLoading={isLoading} isFetching={isFetching} />
  )
}
