import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ApuracaoESocialDTO } from './ApuracaoESocialDTO'

import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

const query = '/ApurarEncargos/ObterApuracaoEncargos'

interface RequestProps {
  periodoApuracao: string
  indApuracao: IndApuracaoEnum
  grupoId?: string | null
  isOnlyError?: boolean | null
}

export default function useObterApuracaoEncargos(props: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ApuracaoESocialDTO[] }>(query, {
      params: props,
    })
    return response.data.data
  }

  return useQuery([query, props], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
