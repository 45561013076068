import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

import { LancamentoLiquidacaoConfig } from './dtos/LancamentoLiquidacaoConfig'
import { useInvalidate } from './useObterLancamentoLiquidacaoConfig'

interface RequestProps {
  params: {
    estabelecimentoOrigemId: string
    estabelecimentoDestinoId: string
    modeloIntegracaoId: string
  }
}

export function useCopiarConfiguracoes() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ params }: RequestProps) {
    const response = await api.get<{ data: LancamentoLiquidacaoConfig }>(
      '/LancamentoLiquidacaoConfig/CopiarConfiguracoes',
      { params },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Configurações copiadas com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
