import React, { useState, useEffect, useCallback } from 'react'

import { Grid, IconButton, InputAdornment } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'
import { ViewHeadline } from '@material-ui/icons'

import DialogFeriados from './components/DialogFeriados'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { AnoMesTextField, DatePicker } from '~/components'

import * as yup from 'yup'
import moment from 'moment'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useUtils from '~/hooks/useUtils'

const schema = yup.object().shape({
  anoMes: yup.string().required('Informe o Ano/Mês').min(6, 'Informe um Ano/Mês válido'),
  qtdDiasUteis: yup.string().required('Informe a Qtd. Dias Úteis'),
  qtdDiasNaoUteis: yup.string().required('Informe a Qtd. Dias Não Úteis'),
  dtPagamentoReciboMensal: yup.string().required('Informe a Data Pagamento Mensal').nullable(),
  dtPagamentoProLabore: yup.string().required('Informe a Data Pagamento Pró Labore').nullable(),
  dtPagamentoAdiantamento: yup
    .string()
    .required('Informe a Data Pagamento Adiantamento')
    .nullable(),
  dtPagamentoRPA: yup.string().required('Informe a Data Pagamento RPA').nullable(),
})

const feriados = [
  {
    diaMes: '0101',
    nome: 'Confraternização Universal',
  },
  {
    diaMes: '2104',
    nome: 'Tiradentes',
  },
  {
    diaMes: '0105',
    nome: 'Dia do Trabalho',
  },
  {
    diaMes: '0709',
    nome: 'Independência do Brasil',
  },
  {
    diaMes: '1210',
    nome: 'Nossa Senhora Aparecida',
  },
  {
    diaMes: '0211',
    nome: 'Finados',
  },
  {
    diaMes: '1511',
    nome: 'Proclamação da República',
  },
  {
    diaMes: '2512',
    nome: 'Natal',
  },
]

const FormItem = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitFormItem, isSalarioQuintoDiaUtil } = props
  const [data, setData] = useState({})
  const [dialogFeriados, setDialogFeriados] = useState(false)
  const [haveFeriado, setHaveFeriado] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const { obterDiaUtilMes } = useUtils()
  const notification = useNotification()

  useEffect(() => {
    handleCalcDates(_data)
    //eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/tabelaMesItem/${data.id}`, data)
        onAfterSubmitFormItem('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/tabelaMesItem', data)
        notification.post()
        onAfterSubmitFormItem('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const diasTotais = parseInt(data.qtdDiasUteis) + parseInt(data.qtdDiasNaoUteis)
    if (parseInt(moment(data.anoMes).endOf('month').format('DD')) !== diasTotais) {
      return notification.error('O quantidade de dias é diferente do total de dias do mês')
    }
    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitFormItem])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleCalcDates = (_data) => {
    const dataCurrent = _data ? _data : data
    const splitDays = () => {
      const firstDay = '01'
      const lastDay = moment(dataCurrent.anoMes).endOf('month').format('DD')

      let domingos = 0
      let diasSemana = 0
      let diaFeriado = 0

      for (let dayCurrent = firstDay - 1; dayCurrent < lastDay; dayCurrent++) {
        const momentCurrent = moment(dataCurrent?.anoMes).add(dayCurrent, 'd')

        // eslint-disable-next-line
        feriados.forEach((obj) => {
          if (
            obj.diaMes + moment(dataCurrent?.anoMes).format('YYYY') ===
              momentCurrent.format('DDMMYYYY') &&
            momentCurrent.day() !== 0 &&
            momentCurrent.day() !== 6
          ) {
            diaFeriado++
          }
        })

        if (momentCurrent.day() === 0) domingos++

        if (momentCurrent.day() !== 0) diasSemana++
      }
      const qtdDiasUteis = diasSemana - diaFeriado
      const qtdDiasNaoUteis = domingos + diaFeriado

      return { qtdDiasNaoUteis, qtdDiasUteis }
    }

    const montandoDataPagamento = () => {
      let dataPagamento = moment(dataCurrent?.anoMes).endOf('month')
      if (isSalarioQuintoDiaUtil) {
        dataPagamento.add(1, 'd')

        let diasFeriado = []

        feriados.forEach((obj) => {
          const dataCompletaFeriado = moment(
            `${moment(dataCurrent?.anoMes).format('YYYY')}${obj?.diaMes.substr(
              2,
              4,
            )}${obj?.diaMes.substr(0, 2)}`,
          )

          if (moment(dataPagamento).format('MM') === dataCompletaFeriado.format('MM')) {
            diasFeriado.push(parseInt(dataCompletaFeriado.format('DD')))
          }
        })
        const diaUtil = obterDiaUtilMes(dataPagamento, diasFeriado)
        dataPagamento = moment(dataCurrent?.anoMes).endOf('month')
        dataPagamento.add(diaUtil, 'd')
      }
      return dataPagamento
    }

    const montandoFeriadosNacionais = () => {
      const feriadosNacionais = []
      feriados.forEach((obj) => {
        const dateFeriado = moment(
          `${moment(dataCurrent?.anoMes).format('YYYY')}${obj?.diaMes.substr(
            2,
            4,
          )}${obj?.diaMes.substr(0, 2)}`,
        )
        const dateCurrent = moment(dataCurrent?.anoMes)

        if (dateFeriado.format('MM') === dateCurrent.format('MM')) {
          feriadosNacionais.push({
            dtFeriado: dateFeriado,
            descricao: obj?.nome,
            cidade: { nome: 'Nacional' },
          })
        } else {
          return null
        }
      })
      return feriadosNacionais
    }

    if (dataCurrent?.anoMes?.length === 6 && !dataCurrent?.id) {
      const splitedDays = splitDays()
      const feriadosNacionais = montandoFeriadosNacionais()
      const dataPagamento = montandoDataPagamento()
      const dtPagamentoAdiantamento = moment(dataCurrent.anoMes).add(19, 'd')

      setData({
        ...dataCurrent,
        ...splitedDays,
        dtPagamentoReciboMensal: dataPagamento,
        dtPagamentoProLabore: dataPagamento,
        dtPagamentoRPA: dataPagamento,
        dtPagamentoAdiantamento,
        feriadosNacionais,
      })
    } else {
      setData({
        ...dataCurrent,
      })
    }
  }

  const openDialogFeriados = () => {
    if (data?.anoMes?.length === 6) {
      setDialogFeriados(true)
    } else {
      notification.error('Informe o Ano/Mês')
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Item da Tabela Mês"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <AnoMesTextField
            label="Ano/Mês"
            fullWidth
            value={data?.anoMes || ''}
            variant="outlined"
            size="small"
            required
            autoFocus
            validationErrors={validationErrors}
            name="anoMes"
            onChange={(e) => {
              const anoMes = e.target.value.replace('/', '')
              setData({ ...data, anoMes })
            }}
            onBlur={() => handleCalcDates()}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TextField
            label="Qtd. Dias Úteis"
            fullWidth
            value={data?.qtdDiasUteis || ''}
            variant="outlined"
            size="small"
            onlyNumber
            required
            validationErrors={validationErrors}
            name="qtdDiasUteis"
            onChange={(e) => {
              const qtdDiasUteis = e.target.value
              setData({ ...data, qtdDiasUteis })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TextField
            label="Qtd. Dias Não Úteis"
            fullWidth
            value={data?.qtdDiasNaoUteis || ''}
            variant="outlined"
            size="small"
            onlyNumber
            required
            validationErrors={validationErrors}
            name="qtdDiasNaoUteis"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    title="Visualizar Feriados"
                    aria-label="Feriados"
                    onClick={openDialogFeriados}
                  >
                    <ViewHeadline fontSize="small" color={haveFeriado ? 'error' : 'primary'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              const qtdDiasNaoUteis = e.target.value
              setData({ ...data, qtdDiasNaoUteis })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Pagamento Mensal"
            size="small"
            value={data?.dtPagamentoReciboMensal || null}
            required
            validationErrors={validationErrors}
            name="dtPagamentoReciboMensal"
            onChange={(date) => {
              const dtPagamentoReciboMensal = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtPagamentoReciboMensal })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Pagamento Pró Labore"
            size="small"
            value={data?.dtPagamentoProLabore || null}
            required
            validationErrors={validationErrors}
            name="dtPagamentoProLabore"
            onChange={(date) => {
              const dtPagamentoProLabore = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtPagamentoProLabore })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Pagamento Adiantamento"
            size="small"
            value={data?.dtPagamentoAdiantamento || null}
            required
            validationErrors={validationErrors}
            name="dtPagamentoAdiantamento"
            onChange={(date) => {
              const dtPagamentoAdiantamento = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtPagamentoAdiantamento })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Pagamento RPA"
            size="small"
            value={data?.dtPagamentoRPA || null}
            required
            validationErrors={validationErrors}
            name="dtPagamentoRPA"
            onChange={(date) => {
              const dtPagamentoRPA = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtPagamentoRPA })
            }}
          />
        </Grid>
      </Grid>

      <DialogFeriados
        isOpen={dialogFeriados}
        anoMes={data?.anoMes}
        feriadosNacionais={data?.feriadosNacionais}
        onClose={() => {
          setDialogFeriados(false)
        }}
        setHaveFeriado={setHaveFeriado}
      />
    </ActionDialog>
  )
}

export default FormItem
