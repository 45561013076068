export enum IndModeloCompensacaoHorasTrabalhadasEnum {
  Modelo_01,
  Modelo_02,
}

export const IndModeloCompensacaoHorasTrabalhadasValues = [
  {
    value: IndModeloCompensacaoHorasTrabalhadasEnum.Modelo_01,
    name: 'Modelo 1',
  },
  {
    value: IndModeloCompensacaoHorasTrabalhadasEnum.Modelo_02,
    name: 'Modelo 2',
  },
]
