import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, ErrorMessage, TextField } from '~/components'

import TableColunas from './TableColunas'

import useValidationErrors from '~/hooks/useValidationErrors'
import { obterErroValidationErrors } from '~/hooks/useUtils'

import useModeloPlanilhaImportacaoCriar from '~/hooks/queries/ModeloPlanilhaImportacao/useModeloPlanilhaImportacaoCriar'
import useModeloPlanilhaImportacaoAtualizar from '~/hooks/queries/ModeloPlanilhaImportacao/useModeloPlanilhaImportacaoAtualizar'

import { ModeloPlanilhaImportacao } from '~/hooks/queries/ModeloPlanilhaImportacao/ModeloPlanilhaImportacao'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  colunas: yup.array().required('Informe as Colunas').min(1, 'Informe pelo menos uma Coluna'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: ModeloPlanilhaImportacao | null
}

export default function Form({ isOpen, onClose, data: _data }: FormProps) {
  const [data, setData] = useState<ModeloPlanilhaImportacao>(
    _data || ({} as ModeloPlanilhaImportacao),
  )

  const { mutateAsync: onCriar, isLoading: isLoadingCriar } = useModeloPlanilhaImportacaoCriar()
  const { mutateAsync: onAtualizar, isLoading: isLoadingAtualizar } =
    useModeloPlanilhaImportacaoAtualizar()

  const isLoading = isLoadingCriar || isLoadingAtualizar

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    if (_data) {
      await onAtualizar(data)
    } else {
      await onCriar(data)
    }
    onClose()
  }

  const mensagemDeErro = obterErroValidationErrors('colunas', validationErrors)

  return (
    <ActionDialog
      title="Cadastro de Modelo Planilha Importação"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      isOkProcessing={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField label="Código" value={data?.codigo || ''} disabled />
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            label="Nome"
            value={data?.nome || ''}
            required
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData((prev) => ({ ...prev, nome }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TableColunas
            data={data?.colunas || []}
            onChange={(d) => {
              const colunas = d
              setData((prev) => ({ ...prev, colunas }))
            }}
          />
          {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
