import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import moment from 'moment'
import { HeightDataTable } from '~/components'

import { indObservacaoConsts, indObservacaoValues } from '~/values/indObservacaoValues'

const { eSocial_4 } = indObservacaoConsts

const formatObservacao = (obs) => {
  if (!obs) return ''
  if (obs.length > 100) return obs.substr(0, 100) + '...'
  return obs
}

const formatIndObservacao = (indObservacao) => {
  const objectFinded = indObservacaoValues.find((obj) => obj.value === indObservacao)
  return objectFinded?.name || ''
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, isFinalizado } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtObservacao: item?.dtObservacao ? moment(item.dtObservacao).format('DD/MM/YYYY') : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'indObservacao',
        label: 'Indicador Observação',
        options: {
          customBodyRender: (value) => formatIndObservacao(value),
        },
      },
      {
        name: 'dtObservacao',
        label: 'Data da Observação',
      },
      {
        name: 'observacao',
        label: 'Observação',
        options: {
          customBodyRender: (value) => <div title={value}>{formatObservacao(value)}</div>,
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, { rowData }) => {
            const indObservacao = rowData[0]
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                  size="small"
                >
                  <EditIcon fontSize="small" color="primary" size="small" />
                </IconButton>

                {isFinalizado && indObservacao === eSocial_4 ? (
                  <></>
                ) : (
                  <IconButton
                    title="Deletar este registro"
                    color="primary"
                    aria-label="Deletar"
                    onClick={() => {
                      onItemClick('delete', value)
                    }}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" color="primary" size="small" />
                  </IconButton>
                )}
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick, isFinalizado],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
