import Home from '~/pages/Home'
import Painel from '~/pages/Painel'
import PessoaFisicaSST from '~/pages/SST/PessoaFisicaSST'
import EmpregadorSST from '~/pages/SST/EmpregadorSST'
import EstabelecimentoSST from '~/pages/SST/EstabelecimentoSST'
import FuncionarioSST from '~/pages/SST/FuncionarioSST'
import LocalTrabalhoFuncionarioSST from '~/pages/SST/LocalTrabalhoFuncionarioSST'
import TransmissaoESocial from '~/pages/TransmissaoESocialNew'
import ExclusaoEventosESocialSST from '~/pages/SST/ExclusaoEventosESocialSST'
import EnvioEventosAutomaticosSST from '~/pages/SST/EnvioEventosAutomaticosSST'
import TransferenciaSST from '~/pages/SST/TransferenciaSST'
import CancelarTransferenciaSST from '~/pages/SST/CancelarTransferenciaSST'

import Cargo from '~/pages/Cargo'
import Setor from '~/pages/Setor'
import Medico from '~/pages/Medico'
import MedicoCadastro from '~/pages/Medico/components/Cadastro'
import ComunicadoAcidenteTrabalho from '~/pages/ComunicadoAcidenteTrabalho'
import AtestadoSaudeOcupacional from '~/pages/AtestadoSaudeOcupacional'
import AtestadoSaudeOcupacionalNew from '~/pages/S2220Aso/AtestadoSaudeOcupacionalNew'
import ASOImportar from '~/pages/S2220Aso/ASOImportar'
import EquipamentoProtecaoIndividual from '~/pages/EquipamentoProtecaoIndividual'
import CondicaoAmbienteTrabalho from '~/pages/CondicaoAmbienteTrabalho'
import CondicaoAmbienteTrabalhoNew from '~/pages/S2240AmbienteTrabalho/CondicaoAmbienteTrabalhoNew'
import AmbienteTrabalhoImportacao from '~/pages/S2240AmbienteTrabalho/AmbienteTrabalhoImportacao'
import CertificadoDigital from '~/pages/CertificadoDigital'
import CargoAgenteNocivo from '~/pages/CargoAgenteNocivo'
import SetorAgenteNocivo from '~/pages/SetorAgenteNocivo'
import ResponsavelRegistroAmbiental from '~/pages/ResponsavelRegistroAmbiental'
import CondicaoAmbienteTrabalhoAutomatico from '~/pages/CondicaoAmbienteTrabalhoAutomatico'
import CondicaoAmbienteTrabalhoResumo from '~/pages/CondicaoAmbienteTrabalhoResumo'
import RelatorioConfiguracao from '~/pages/RelatorioConfiguracao'
import AuditoriaPorEmpregador from '~/pages/AuditoriaPorEmpregador'
import LimparGerarAmbiente from '~/pages/LimparGerarAmbiente'
import LotesPorEmpregador from '~/pages/LotesPorEmpregador'
import ParametrosSistema from '~/pages/ParametrosSistema'
import VinculoExameToxicologico from '~/pages/VinculoExameToxicologico'

const routesSST = [
  { component: Home, title: 'Home', path: '/home' },
  { component: Painel, title: 'Painel', path: '/painel' },
  { component: PessoaFisicaSST, title: 'Pessoa Física', path: '/pessoa-fisica-sst' },
  { component: EmpregadorSST, title: 'Empregador', path: '/empregador-sst' },
  { component: EstabelecimentoSST, title: 'Estabelecimento', path: '/estabelecimento-sst' },
  { component: Cargo, title: 'Cargo', path: '/cargo' },
  { component: Setor, title: 'Setor', path: '/setor' },
  { component: MedicoCadastro, title: 'Médico/Responsável', path: '/medico-responsavel/:id' },
  { component: Medico, title: 'Médico/Responsável', path: '/medico-responsavel' },
  { component: CertificadoDigital, title: 'CertificadoDigital', path: '/certificado-digital' },
  { component: FuncionarioSST, title: 'Funcionário', path: '/funcionario-sst' },
  {
    component: LocalTrabalhoFuncionarioSST,
    title: 'Ambiente de Trabalho Funcionário',
    path: '/local-trabalho-sst',
  },
  {
    component: TransmissaoESocial,
    title: 'Transmissão eSocial',
    path: '/transmissao-esocial/:step',
  },
  {
    component: ExclusaoEventosESocialSST,
    title: 'Exclusão de Eventos',
    path: '/exclusao-eventos-esocial-sst',
  },
  {
    component: EnvioEventosAutomaticosSST,
    title: 'Envio de Eventos Automáticos',
    path: '/envio-eventos-automaticos-sst',
  },
  {
    component: CondicaoAmbienteTrabalho,
    title: 'Condição Ambiente de Trabalho',
    path: '/condicao-ambiente-trabalho-old',
  },
  {
    component: CondicaoAmbienteTrabalhoNew,
    title: 'Condição Ambiente de Trabalho',
    path: '/condicao-ambiente-trabalho',
  },
  {
    component: AmbienteTrabalhoImportacao,
    title: 'Condição Ambiente de Trabalho Importação',
    path: '/condicao-ambiente-trabalho-importacao',
  },
  {
    component: ComunicadoAcidenteTrabalho,
    title: 'Comunicado Acidente de Trabalho (CAT)',
    path: '/comunicado-acidente-trabalho',
  },
  {
    component: AtestadoSaudeOcupacional,
    title: 'Atestado Saúde Ocupacional (ASO)',
    path: '/atestado-saude-ocupacional-old',
  },
  {
    component: AtestadoSaudeOcupacionalNew,
    title: 'Atestado Saúde Ocupacional (ASO)',
    path: '/atestado-saude-ocupacional',
  },
  {
    component: ASOImportar,
    title: 'ASO Importação',
    path: '/aso-importacao',
  },
  {
    component: EquipamentoProtecaoIndividual,
    title: 'Equipamento de Proteção Individual',
    path: '/equipamento-protecao-individual',
  },
  {
    component: CondicaoAmbienteTrabalhoAutomatico,
    title: 'Condição Ambiente de Trabalho Automático',
    path: '/condicao-ambiente-trabalho-automatico',
  },
  {
    component: ResponsavelRegistroAmbiental,
    title: 'Responsável Registro Ambiental',
    path: '/responsavel-registro-ambiental',
  },
  { component: CargoAgenteNocivo, title: 'Cargo Agente Nocivo', path: '/cargo-agente-nocivo' },
  { component: SetorAgenteNocivo, title: 'Setor Agente Nocivo', path: '/setor-agente-nocivo' },
  {
    component: CondicaoAmbienteTrabalhoResumo,
    title: 'Condição ambiente de trabalho resumo',
    path: '/condicao-ambiente-trabalho-resumo',
  },
  { component: ParametrosSistema, title: 'Parâmetros do Sistema', path: '/parametros-sistema' },
  { component: RelatorioConfiguracao, title: 'Relatório', path: '/configuracao-relatorio' },
  { component: LotesPorEmpregador, title: 'Lotes por Empregador', path: '/lotes-empregador' },
  {
    component: AuditoriaPorEmpregador,
    title: 'Auditoria por Empregador',
    path: '/auditoria-empregador',
  },
  {
    component: LimparGerarAmbiente,
    title: 'Limpar ou Gerar Ambiente eSocial',
    path: '/limpar-gerar-ambiente',
  },
  {
    component: TransferenciaSST,
    title: 'Transferência Funcionário',
    path: '/transferencia-funcionario',
  },
  {
    component: CancelarTransferenciaSST,
    title: 'Cancelar Transferência',
    path: '/cancelar-transferencia-funcionario',
  },
  {
    component: VinculoExameToxicologico,
    title: 'Exame Toxicológico',
    path: '/exame-toxicologico',
  },
]

export default routesSST
