export enum NivelEstagioEnum {
  Fundamental_1 = 1,
  Medio_2 = 2,
  FormacaoProfissional_3 = 3,
  Superior_4 = 4,
  Especial_8 = 8,
  MãeSocial_9 = 9,
}

export const NivelEstagioValues = [
  {
    name: 'Fundamental',
    value: NivelEstagioEnum.Fundamental_1,
  },
  {
    name: 'Médio',
    value: NivelEstagioEnum.Medio_2,
  },
  {
    name: 'Formação Profissional',
    value: NivelEstagioEnum.FormacaoProfissional_3,
  },
  {
    name: 'Superior',
    value: NivelEstagioEnum.Superior_4,
  },
  {
    name: 'Especial',
    value: NivelEstagioEnum.Especial_8,
  },
  {
    name: 'Mãe Social (Lei 7.644/1987)',
    value: NivelEstagioEnum.MãeSocial_9,
  },
]
