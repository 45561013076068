import React, { useState, useEffect, useCallback } from 'react'

import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

const schema = yup.object().shape({
  descricao: yup.string().required('Informe uma Descrição'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/Servico/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/Servico', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Serviço"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <TextField
        label="Descrição do Serviço"
        name="descricao"
        validationErrors={validationErrors}
        required
        variant="outlined"
        size="small"
        fullWidth
        multiline
        rows={2}
        inputProps={{
          maxLength: 100,
        }}
        value={data?.descricao || ''}
        autoFocus
        onChange={(e) => {
          const descricao = e?.target?.value || ''
          setData((oldState) => ({
            ...oldState,
            descricao,
          }))
        }}
      />
    </ActionDialog>
  )
}

export default Form
