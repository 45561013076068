import React, { useState, useEffect, useCallback } from 'react'

import { Box, Divider, Grid, makeStyles, Tooltip } from '@material-ui/core'
import { ButtonBox, Button, ContentDivider } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker, ConfirmDeleteDialog } from '~/components'
import { MUIAutoComplete, AutoCompleteMedico } from '~/components/AutoComplete'

import FormItem from './components/FormItem'
import TableItem from './components/TableItem'

import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'
import * as yup from 'yup'
import moment from 'moment'
import { ASOTipoExameValues } from '~/values/ASOTipoExameValues'
import { ASOResultadoValues } from '~/values/ASOResultadoValues'
import { ASOOrdemExameConsts } from '~/values/ASOOrdemExameValues'
import { ASOIndResultadoConsts } from '~/values/ASOIndResultadoValues'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '100%',
  },
}))

const schema = yup.object().shape({
  tipoASO: yup.string().required('Informe o Tipo ASO'),
  dtASO: yup
    .date()
    .required('Informe a Data ASO')
    .typeError('Informe uma Data Valída')
    .test(
      'DtAcidente-validator',
      `A Data ASO deve ser igual ou anterior à data atual: ${moment().format('DD/MM/yyyy')} `,
      (dtASO) => moment(dtASO).isSameOrBefore(moment()),
    )
    .when(['$dtAdmissao', 'tipoASO'], (dtAdmissao, tipoASO, schema) => {
      const dtAdmissaoFormatted = moment(dtAdmissao).format('DD/MM/yyyy')
      const parsedTipoASO = parseInt(tipoASO)

      if (parsedTipoASO !== 0) {
        return schema.test(
          'dtASO-validator',
          `A Data ASO deve ser igual ou posterior a Data de Adimissão: ${
            dtAdmissao ? dtAdmissaoFormatted : ' - '
          } `,
          (dtASO) => moment(dtASO).isSameOrAfter(dtAdmissao),
        )
      }

      return schema
    })
    .nullable(),
  resultadoASO: yup.string().required('Informe o Resultado ASO'),
  medicoId: yup.string().required('Informe Médico').nullable(),
})

const Form = (props) => {
  const { vinculo, isOpen, onClose, data: _data, onAfterSubmitForm } = props

  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const [formItem, setFormItem] = useState({
    isOpen: false,
    data: {},
  })
  const [collectionItems, setCollectionItems] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [confirmDeleteDialogItem, setConfirmDeleteDialogItem] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const notification = useNotification()
  const classes = useStyles()
  const dialogNotification = useDialogNotification()
  const { anoMes } = useAmbiente()

  useEffect(() => {
    const startForm = () => {
      setData(_data)
    }
    startForm()
    //eslint-disable-next-line
  }, [_data])

  useEffect(() => {
    const getCollectionItems = async () => {
      setLoading(true)
      try {
        const response = await api.get(
          '/AtestadoSaudeOcupacionalItem/GetByAtestadoSaudeOcupacional',
          {
            params: {
              asoId: _data?.id,
            },
          },
        )
        if (!response?.data?.data) throw new Error('No data')
        setCollectionItems(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setLoading(false)
    }
    if (!_data?.id) return
    getCollectionItems()
    // eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/AtestadoSaudeOcupacional/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/AtestadoSaudeOcupacional', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { dtAdmissao: vinculo?.dtAdmissao, anoMes },
    },
  })

  const handleOpenFormItem = useCallback(
    (
      _data = {
        atestadoSaudeOcupacionalId: data?.id,
        atestadoSaudeOcupacional: data,
        indOrdem: ASOOrdemExameConsts.naoInformar_0,
        indResultado: ASOIndResultadoConsts.normal_1,
      },
    ) => {
      setFormItem((oldState) => ({
        ...oldState,
        isOpen: true,
        data: _data,
      }))
    },
    [data],
  )

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const itens = collectionItems
        const item = itens.find((i) => i.id === id)
        handleOpenFormItem(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialogItem((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [collectionItems, handleOpenFormItem],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collectionItems

    try {
      await api.delete(`/AtestadoSaudeOcupacionalItem/${confirmDeleteDialogItem.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialogItem.id)
      handleCloseConfirmDeleteItem()
      setCollectionItems(newItens)
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collectionItems, confirmDeleteDialogItem.id, handleCloseConfirmDeleteItem])

  const handleClickAddItem = useCallback(() => {
    handleOpenFormItem()
  }, [handleOpenFormItem])

  const handleCloseFormItem = useCallback(() => {
    setFormItem({
      data: {},
      isOpen: false,
    })
  }, [])

  const handleAfterSubmitFormItem = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const itens = collectionItems
        const newItens = [data, ...itens]
        setCollectionItems(newItens)
        handleCloseFormItem()
      }

      const handleAfterUpdate = (data) => {
        const itens = collectionItems
        const newItens = itens?.map((i) => (i.id === data.id ? data : i))
        setCollectionItems(newItens)
        handleCloseFormItem()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collectionItems, handleCloseFormItem],
  )

  return (
    <ActionDialog
      title="Cadastro de Atestado Saúde Ocupacional (ASO)"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: data?.id ? classes.heightPaper : undefined,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ContentDivider title="Atestado de Saúde Ocupacional - ASO" />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <MUIAutoComplete
            label="Tipo do exame médico ocupacional"
            name="tipoASO"
            required
            validationErrors={validationErrors}
            value={data.tipoASO}
            options={ASOTipoExameValues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(e, obj) => {
              const tipoASO = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                tipoASO,
              }))
            }}
          />
        </Grid>

        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <DatePicker
            name="dtASO"
            label="Data ASO"
            size="small"
            required
            validationErrors={validationErrors}
            value={data?.dtASO || null}
            onChange={(date) => {
              const dtASO = date?.format('yyyy-MM-DD') || null
              setData((oldState) => ({
                ...oldState,
                dtASO,
              }))
            }}
          />
        </Grid>

        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <MUIAutoComplete
            label="Resultado ASO"
            name="resultadoASO"
            required
            validationErrors={validationErrors}
            value={data.resultadoASO}
            options={ASOResultadoValues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(e, obj) => {
              const resultadoASO = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                resultadoASO,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <Tooltip
            arrow
            placement="bottom-start"
            title="Grupo que detalha as avaliações clínicas e os exames
            complementares porventura realizados pelo trabalhador
            em virtude do determinado nos Anexos da NR-07, além de
            outros solicitados pelo médico e os referentes ao ASO."
          >
            <Box>
              <ContentDivider title="Detalhes de avaliações clínicas e exames" />
            </Box>
          </Tooltip>
        </Grid>

        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <AutoCompleteMedico
            name="medicoId"
            required
            validationErrors={validationErrors}
            value={data?.medico || null}
            onChange={(e, medico) => {
              const medicoId = medico?.id || ''
              setData((oldState) => ({
                ...oldState,
                medicoId,
                medico,
              }))
            }}
          />
        </Grid>

        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <AutoCompleteMedico
            label="Médico PCMSO"
            value={data?.medicoPCMSO || null}
            onChange={(e, medicoPCMSO) => {
              const medicoPCMSOId = medicoPCMSO?.id || ''
              setData((oldState) => ({
                ...oldState,
                medicoPCMSOId,
                medicoPCMSO,
              }))
            }}
          />
        </Grid>

        {data?.id && (
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <Divider />
          </Grid>
        )}
      </Grid>
      {data?.id && (
        <>
          <Box py={1}>
            <ContentDivider
              title="Itens ASO"
              renderRight={
                <ButtonBox>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleClickAddItem}
                    variant="contained"
                  >
                    Adicionar
                  </Button>
                </ButtonBox>
              }
            />
          </Box>

          <TableItem data={collectionItems} isLoading={isLoading} onItemClick={handleClickItem} />
        </>
      )}

      <FormItem
        isOpen={formItem.isOpen}
        data={formItem.data}
        aso={data}
        onClose={handleCloseFormItem}
        onAfterSubmitForm={handleAfterSubmitFormItem}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialogItem.isOpen}
        isDeleting={confirmDeleteDialogItem.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ActionDialog>
  )
}

export default Form
