import React from 'react'

import moment from 'moment'

import { ToolsDataTable } from '~/components'

import { formatCurrency } from '~/hooks/useUtils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { RelSalarioContratualFuncionarioDTO } from '~/hooks/queries/RelatorioSalarioContratual/RelSalarioContratualFuncionarioDTO'

interface TableProps {
  query: string
  data: RelSalarioContratualFuncionarioDTO[]
  isLoading: boolean
  isFetching: boolean
}

export default function Table(props: TableProps) {
  const { query, data: _data, isFetching, isLoading } = props

  const data = _data.map((d) => ({
    ...d,
    dtAdmissao: moment(d.dtAdmissao).format('DD/MM/yyyy'),
    salarioAtual: formatCurrency(d.salarioAtual),
    vrAdicionalDuplaFuncao: formatCurrency(d.vrAdicionalDuplaFuncao),
    vrAdicionalNoturno: formatCurrency(d.vrAdicionalNoturno),
    vrExtraCaixa: formatCurrency(d.vrExtraCaixa),
    vrGratificacao: formatCurrency(d.vrGratificacao),
    vrInsalubridade: formatCurrency(d.vrInsalubridade),
    vrPericulosidade: formatCurrency(d.vrPericulosidade),
    salarioAdicionalTotal: formatCurrency(d.salarioAdicionalTotal),
    salarioTotal: formatCurrency(d.salarioTotal),
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'dtAdmissao',
      label: 'Data de Admissão',
    },
    {
      name: 'setorNome',
      label: 'Setor',
    },
    {
      name: 'cargoNome',
      label: 'Cargo',
    },
    {
      name: 'lotacaoNome',
      label: 'Lotação',
    },
    {
      name: 'salarioAtual',
      label: 'Salário',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrInsalubridade',
      label: 'Insalubridade',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrPericulosidade',
      label: 'Periculosidade',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrAdicionalNoturno',
      label: 'Adicional Noturno',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrExtraCaixa',
      label: 'Quebra de Caixa',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrGratificacao',
      label: 'Gratificação',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrAdicionalDuplaFuncao',
      label: 'Adicional Dupla Função',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'salarioAdicionalTotal',
      label: 'Salário Adicional Total',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'salarioTotal',
      label: 'Salário Total',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      sherlock={{ columns: ['codigo', 'nome', 'dtAdmissao'], query }}
    />
  )
}
