import React from 'react'

import { makeStyles, TableHead, TableRow, TableCell } from '@material-ui/core'

import MUIExpandableButton from '../MUIExpandableButton'

const useStyles = makeStyles((theme) => ({
  tableCellHead: {
    backgroundColor: theme.palette.grey[300],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: 600,
    fontSize: '13px',
    zIndex: 0,
  },
}))

export default function MUITableHead(props: MUITableHeadProps) {
  const { columns, groupColumns, options, data } = props

  const classes = useStyles()

  function handleToggleAll() {
    if (!options?.rowExpanded) return
    const { onRowExpanded, rowsExpanded } = options.rowExpanded

    if (rowsExpanded.length > 0) {
      onRowExpanded([])
    } else {
      const indexs = data?.map((_, index) => index)
      onRowExpanded(indexs)
    }
  }

  let countRemoveCells = 0

  return (
    <TableHead>
      {groupColumns ? (
        <TableRow>
          {options?.rowExpanded ? <TableCell className={classes.tableCellHead} /> : <></>}
          {columns.map((column, index) => {
            const columnFinded = groupColumns.find((d) => d.startIndex === index)

            if (columnFinded) {
              countRemoveCells += columnFinded.count - 1
              return (
                <TableCell
                  key={index}
                  className={classes.tableCellHead}
                  colSpan={columnFinded.count}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {columnFinded.label}
                </TableCell>
              )
            }

            if (countRemoveCells > 0) {
              countRemoveCells -= 1
              return undefined
            } else {
              return <TableCell key={index} className={classes.tableCellHead} />
            }
          })}
        </TableRow>
      ) : (
        <></>
      )}
      <TableRow>
        {options?.rowExpanded ? (
          <TableCell className={classes.tableCellHead} style={{ padding: 0, textAlign: 'center' }}>
            <MUIExpandableButton
              onClick={handleToggleAll}
              open={options.rowExpanded.rowsExpanded.length > 0}
            />
          </TableCell>
        ) : (
          <></>
        )}
        {columns.map((column, index) => (
          <TableCell
            key={index}
            className={classes.tableCellHead}
            style={{
              minWidth: column?.options?.minWidth,
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
