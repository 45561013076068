import { convertToArrayDescription } from '~/utils/utils'

export enum IndMotivoDesligamentoTSVEnum {
  ExoneracaoDiretorNaoEmpregado_01 = 1,
  TerminoMandatoDiretor_02 = 2,
  ExoneracaoPedidoDiretor_03 = 3,
  ExoneracaoDiretorReciproca_04 = 4,
  MorteDiretor_05 = 5,
  ExoneracaoDiretorFalencia_06 = 6,
  MudancaCPF_07 = 7,
  Outros_99 = 99,
}

export const IndMotivoDesligamentoTSVLabel = {
  [IndMotivoDesligamentoTSVEnum.ExoneracaoDiretorNaoEmpregado_01]:
    '01 - Exoneração do diretor não empregado sem justa causa, por deliberação da assembleia, dos sócios cotistas ou da autoridade competente',
  [IndMotivoDesligamentoTSVEnum.TerminoMandatoDiretor_02]:
    '02 - Término de mandato do diretor não empregado que não tenha sido reconduzido ao cargo',
  [IndMotivoDesligamentoTSVEnum.ExoneracaoPedidoDiretor_03]:
    '03 - Exoneração a pedido de diretor não empregado',
  [IndMotivoDesligamentoTSVEnum.ExoneracaoDiretorReciproca_04]:
    '04 - Exoneração do diretor não empregado por culpa recíproca ou força maior',
  [IndMotivoDesligamentoTSVEnum.MorteDiretor_05]: '05 - Morte do diretor não empregado',
  [IndMotivoDesligamentoTSVEnum.ExoneracaoDiretorFalencia_06]:
    '06 - Exoneração do diretor não empregado por falência, encerramento ou supressão de parte da empresa',
  [IndMotivoDesligamentoTSVEnum.MudancaCPF_07]: '07 - Mudança de CPF',
  [IndMotivoDesligamentoTSVEnum.Outros_99]: '99 - Outros',
}

export const IndMotivoDesligamentoTSVValue = convertToArrayDescription(
  IndMotivoDesligamentoTSVEnum,
  IndMotivoDesligamentoTSVLabel,
)
