import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { ProcessoTrabalhistaManutencaoVinculoInformacoesDTO } from './dtos/ProcessoTrabalhistaManutencaoVinculoInformacoesDTO'

const query = '/ProcessoTrabalhistaManutencaoVinculo/ObterInformacoesVinculo'

interface RequestProps {
  params: {
    processoTrabalhistaVinculoId?: string
  }
}

export function useObterInformacoesVinculo({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params.processoTrabalhistaVinculoId) return
    const response = await api.get<{ data: ProcessoTrabalhistaManutencaoVinculoInformacoesDTO }>(
      query,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
