import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { GerenciadorDocumentos } from './GerenciadorDocumentos'

export const queryObterDocumentos = '/GerenciadorDocumentos/ObterDocumentos'

interface UseObterDocumentosProps {
  estabelecimentoId: string
  competencia: string
}

export function useObterDocumentos(props: UseObterDocumentosProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: GerenciadorDocumentos[] }>(queryObterDocumentos, {
      params: props,
    })
    return response.data.data
  }

  return useQuery([queryObterDocumentos, props], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
