import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { formatCurrency, formatSimpleDate, orderBy } from '~/utils/utils'

import { Button, ButtonBox, ContainerTable, Stack, ToolsDataTable } from '~/components'

import FormRemuneracao from './FormRemuneracao'

import useDialog from '~/hooks/useDialog'

import {
  ProcessoTrabalhistaVinculoCreateDTO,
  ProcessoTrabalhistaVinculoRemuneracaoDTO,
} from '~/hooks/queries/ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculoCreateDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  IndUnidadeSalarioEnum,
  IndUnidadeSalarioLabels,
} from '~/@types/enums/IndUnidadeSalarioEnum'

interface TabRemuneracoesProps {
  data: ProcessoTrabalhistaVinculoCreateDTO
  setData: React.Dispatch<React.SetStateAction<ProcessoTrabalhistaVinculoCreateDTO>>
}

export default function TabRemuneracoes({ data, setData }: TabRemuneracoesProps) {
  const {
    close: closeFormRemuneracao,
    data: dataFormRemuneracao,
    isOpen: isOpenFormRemuneracao,
    open: openFormRemuneracao,
  } = useDialog<{ data: ProcessoTrabalhistaVinculoRemuneracaoDTO; index: number }>()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtRemuneracao',
      label: 'Data da Remuneração',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'vrSalarioFixo',
      label: 'Valor do Salário Fixo',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'unidadeSalarioFixo',
      label: 'Unidade do Salário Fixo',
      options: {
        customBodyRender: (value: IndUnidadeSalarioEnum) => IndUnidadeSalarioLabels[value],
      },
    },
    {
      name: 'descricaoSalarioVariavel',
      label: 'Descrição do Salário',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtCurrent = data.remuneracoes[rowIndex]
                  if (dtCurrent) {
                    openFormRemuneracao({ data: dtCurrent, index: rowIndex })
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  setData((prev) => ({
                    ...prev,
                    remuneracoes: prev.remuneracoes.filter((d, index) => index !== rowIndex),
                  }))
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack>
      <ButtonBox>
        <Button
          variant="contained"
          onClick={() =>
            openFormRemuneracao({
              data: {
                dtRemuneracao: '',
                vrSalarioFixo: 0,
                unidadeSalarioFixo: IndUnidadeSalarioEnum.Mes_5,
                descricaoSalarioVariavel: '',
              },
              index: -1,
            })
          }
        >
          Adicionar
        </Button>
      </ButtonBox>
      <ContainerTable>
        <ToolsDataTable
          data={data.remuneracoes}
          columns={columns}
          disableAutoHeight
          pagination={false}
        />
      </ContainerTable>

      {isOpenFormRemuneracao && (
        <FormRemuneracao
          isOpen={isOpenFormRemuneracao}
          onClose={closeFormRemuneracao}
          data={dataFormRemuneracao.data}
          onChange={(d) =>
            setData((prev) => {
              const remuneracoes = [
                ...prev.remuneracoes.filter((_, index) => index !== dataFormRemuneracao.index),
                d,
              ]
              return {
                ...prev,
                remuneracoes: orderBy(remuneracoes, 'dtRemuneracao'),
              }
            })
          }
        />
      )}
    </Stack>
  )
}
