import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import IconButton from '@material-ui/core/IconButton'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { IndAcordoColetivoValues } from '~/@types/enums/IndAcordoColetivoEnum'

import { useQuery } from '~/hooks/queries/useAcordoDissidioConvencao'

import moment from 'moment'
import { HeightDataTable } from '~/components'

const Table = (props) => {
  const { onItemClick, query } = props

  const { data: _data, isLoading } = useQuery()

  function formatIndADC(indADC) {
    const indADCObj = IndAcordoColetivoValues.find((obj) => obj.value === indADC)
    return indADCObj.name
  }

  const data = _data?.map((item) => {
    return {
      ...item,
      indADC: formatIndADC(item?.indADC),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtADC',
        label: 'Data Acordo',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtEfetiva',
        label: 'Data Efetiva',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'indADC',
        label: 'Indicador',
      },
      {
        name: 'descricao',
        label: 'Descrição',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    const dataFinded = _data?.find((data) => data.id === value)
                    onItemClick('edit', dataFinded)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [_data, onItemClick],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['indADC', 'descricao'],
      }}
    />
  )
}

export default Table
