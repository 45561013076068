import { useState, useEffect, useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import api from '~/services/api-pessoal'

import { FileCopy as FileCopyIcon } from '@material-ui/icons'
import { Box, Grid, IconButton, MenuItem, useTheme } from '@material-ui/core'
import { TextField, ContentDivider } from 'mio-library-ui'

import {
  NumeroInscricaoTextField,
  DatePicker,
  ContainerForm,
  CurrencyTextField,
  DatePickerNew,
  TextFieldLocked,
} from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { MUIAutoComplete } from '~/components/AutoComplete'
import { CategoriaValues } from '~/@types/enums/CategoriaEnum'

import * as yup from 'yup'
import { IndSimplesValues } from '~/@types/enums/IndSimplesEnum'
import { IndGrauExposicaoValues } from '~/@types/enums/IndGrauExposicaoEnum'
import { indUnidadeSalarioValues } from '~/values/indUnidadeSalarioValues'
import { RescisaoFGTSValues } from '~/@types/enums/RescisaoFGTSEnum'
import { HomologNetValues } from '~/@types/enums/HomologNetEnum'
import { copyToClipboard } from '~/hooks/useUtils'

const schema = yup.object().shape({
  matricula: yup.string().required('Informe a Matrícula'),
  indGrauExposicao: yup.string().required('Informe o Indicador Grau Exposição'),
  indSimples: yup.string().required('Informe o Indicador Simples Nacional'),
  unidadeSalarioFixo: yup.string().required('Informe a Unidade Salário Fixo'),
})

const Form = (props) => {
  const { data: _data } = props
  const [data, setData] = useState({})

  const [isSubmitting, setSubmitting] = useState(false)
  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const history = useHistory()
  const theme = useTheme()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        await api.put(`/vinculo/${data.id}`, data)
        history.push({
          pathname: '/estagiario',
        })
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        await api.post('/vinculo', data)
        notification.post()
        history.push({
          pathname: '/estagiario',
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    delete data.estabelecimento
    delete data.pessoaFisica
    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ContainerForm
      handleSubmit={handleValidate}
      onCancel={() =>
        history.push({
          pathname: '/estagiario',
        })
      }
      isLoading={isSubmitting}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ContentDivider top={2} />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <TextField
            label="Pessoa Física"
            fullWidth
            value={data?.pessoaFisica?.nome || ''}
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Box display="flex" gridGap={theme.spacing(0.5)} alignItems="center">
            <NumeroInscricaoTextField
              label="CPF"
              value={data?.pessoaFisica?.nrInscricao || ''}
              disabled
            />
            <IconButton
              size="small"
              title="Copiar para a Área de Transferência"
              onClick={() => copyToClipboard(data?.pessoaFisica?.nrInscricao)}
            >
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Admissão"
            size="small"
            value={data?.dtAdmissao || null}
            disabled
          />
        </Grid>

        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Rescisão"
            size="small"
            disabled
            value={data.dtRescisao || null}
            onChange={(date) => {
              const dtRescisao = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtRescisao })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <TextFieldLocked
            label="Matrícula"
            required
            name="matricula"
            validationErrors={validationErrors}
            value={data?.matricula || ''}
            disabledLocked={data?.reciboEsocial ? true : false}
            onChange={(e) => {
              const matricula = e.target.value
              setData((prev) => ({ ...prev, matricula }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
          <MUIAutoComplete
            label="Categoria"
            disabled
            value={data.categoria}
            options={CategoriaValues}
            renderOption={(option) => option.name}
            optionId="value"
          />
        </Grid>

        <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicador Grau Exposição"
            options={IndGrauExposicaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indGrauExposicao"
            value={data.indGrauExposicao}
            onChange={(e, obj) => {
              const indGrauExposicao = obj ? obj.value : ''
              setData({ ...data, indGrauExposicao })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicador Simples Nacional"
            options={IndSimplesValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indSimples"
            value={data.indSimples}
            onChange={(e, obj) => {
              const indSimples = obj ? obj.value : ''
              setData({ ...data, indSimples })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MUIAutoComplete
            label="Unidade Salário Fixo"
            options={indUnidadeSalarioValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="unidadeSalarioFixo"
            value={data.unidadeSalarioFixo}
            onChange={(e, obj) => {
              const unidadeSalarioFixo = obj ? obj.value : ''
              setData({ ...data, unidadeSalarioFixo })
            }}
            disabled={data?.isFinalizado ? true : false}
            title={
              data?.isFinalizado
                ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                : ''
            }
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            label="Data Início da Inatividade"
            size="small"
            value={data?.dtInativo || null}
            onChange={(date) => {
              const dtInativo = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtInativo,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DatePickerNew
            label="Data Laudo Moléstia Grave"
            value={data.dtLaudoMolestiaGrave || null}
            onChange={(date) => {
              const dtLaudoMolestiaGrave = date
              setData({
                ...data,
                dtLaudoMolestiaGrave,
              })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="FGTS/Admissão" />
        </Grid>

        <Grid item lg={4} xs={12}>
          <TextField
            label="Admissão FGTS"
            fullWidth
            value={data?.admissaoFGTS || ''}
            variant="outlined"
            size="small"
            select
            onChange={(e) => {
              const admissaoFGTS = e?.target?.value || ''
              setData((oldState) => ({ ...oldState, admissaoFGTS }))
            }}
          >
            <MenuItem value="1A">1A - Diretor não-empregado Primeiro Emprego</MenuItem>
            <MenuItem value="1B">1B - Diretor não-empregado Reemprego</MenuItem>
            <MenuItem value="1C">
              1C - Diretor não-empregado Oriundo de outro estabelecimento
            </MenuItem>
            <MenuItem value="2A">2A - Trabalhador Rural Primeiro Emprego</MenuItem>
            <MenuItem value="2B">2B - Trabalhador Rural Reemprego</MenuItem>
            <MenuItem value="2C">2C - Trabalhador Rural Oriundo de outro estabelecimento</MenuItem>
            <MenuItem value="3A">3A - Menor aprendiz Primeiro Emprego</MenuItem>
            <MenuItem value="3B">3B - Menor aprendiz Reemprego</MenuItem>
            <MenuItem value="3C">3C - Menor aprendiz Oriundo de outro estabelecimento</MenuItem>
            <MenuItem value="9A">9A - Outros Primeiro Emprego</MenuItem>
            <MenuItem value="9B">9B - Outros Reemprego</MenuItem>
            <MenuItem value="9C">9C - Outros Oriundo de outro estabelecimento</MenuItem>
          </TextField>
        </Grid>

        <Grid item lg={4} xs={12}>
          <TextField
            label="Ocorrência FGTS"
            fullWidth
            value={data?.ocorrenciaFGTS || ''}
            variant="outlined"
            size="small"
            select
            onChange={(e) => {
              const ocorrenciaFGTS = e?.target?.value || ''
              setData((oldState) => ({ ...oldState, ocorrenciaFGTS }))
            }}
          >
            <MenuItem value="00">
              00 - Sem exposição a agente nocivo-trabalhador nunca esteve exposto
            </MenuItem>
            <MenuItem value="01">
              01 - Sem exposição a agente nocivo-trabalhador já esteve exposto
            </MenuItem>
            <MenuItem value="02">
              02 - Exposto a agente nocivo/aposent. especial 15 anos de serviço
            </MenuItem>
            <MenuItem value="03">
              03 - Exposto a agente nocivo/aposent. especial 20 anos de serviço
            </MenuItem>
            <MenuItem value="04">
              04 - Exposto a agente nocivo/aposent. especial 25 anos de serviço
            </MenuItem>
            <MenuItem value="05">05 - Não exposto a agente nocivo-multiplos vínculos</MenuItem>
            <MenuItem value="06">
              06 - Exposto a agente nocivo/aposent. especial 15 anos de serviço-mult. vínculos
            </MenuItem>
            <MenuItem value="07">
              07 - Exposto a agente nocivo/aposent. especial 20 anos de serviço-mult. vínculos
            </MenuItem>
            <MenuItem value="08">
              08 - Exposto a agente nocivo/aposent. especial 25 anos de serviço-mult. vínculos
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item lg={4} xs={12}>
          <TextField
            label="Categoria FGTS"
            fullWidth
            value={data?.categoriaFGTS || ''}
            variant="outlined"
            size="small"
            select
            onChange={(e) => {
              const categoriaFGTS = e?.target?.value || ''
              setData((oldState) => ({ ...oldState, categoriaFGTS }))
            }}
          >
            <MenuItem value="01">01 - Empregado</MenuItem>
            <MenuItem value="02">02 - Trabalhador Avulso</MenuItem>
            <MenuItem value="03">
              03 - Trabalhador não vinculado ao RGPS, mas com direito ao FGTS
            </MenuItem>
            <MenuItem value="04">
              04 - Empregado sob contrato de trabalho por prazo determinado (Lei 9.601,98)
            </MenuItem>
            <MenuItem value="05">
              05 - Contribuinte individual - Diretor não empregado com FGTS (Lei 8.036/90, art 16)
            </MenuItem>
            <MenuItem value="06">06 - Empregado Doméstico</MenuItem>
            <MenuItem value="07">07 - Menor Aprendiz (Lei 10.097/2000)</MenuItem>
            <MenuItem value="11">
              11 - Contribuinte Individual - Diretor não empregado e demais empresários sem FGTS
            </MenuItem>
            <MenuItem value="12">12 - Demais Agentes Públicos</MenuItem>
            <MenuItem value="13">
              13 - Contribuinte Individual - Trab. autônomo ou equiparado, inclusive oper. máquina
              com contribuição sobre remuneração
            </MenuItem>
            <MenuItem value="14">
              14 - Contribuinte Individual - Trab. autônomo ou equiparado, inclusive oper. máquina
              com contribuição sobre salário-base
            </MenuItem>
            <MenuItem value="15">
              15 - Contribuinte Individual - Transportador autônomo com contribuiçào sobre
              remuneração
            </MenuItem>
            <MenuItem value="16">
              16 - Contribuinte Individual - Transportador autônomo com contribuição sobre
              salário-base
            </MenuItem>
            <MenuItem value="17">
              17 - Contribuinte Individual - Cooperado que presta serviços a empresas contratentes
              de Cooperativa de Trabalho
            </MenuItem>
            <MenuItem value="18">
              18 - Contribuinte Individual - Transportador Cooperado que presta serviços a empresas
              contratantes de Cooperativa de Trabalho
            </MenuItem>
            <MenuItem value="19">19 - Agente Político</MenuItem>
            <MenuItem value="20">
              20 - Servidor Público Ocupante, exclusivamente, de cargo em comissão, Servidor Público
              Ocupante de cargo
            </MenuItem>
            <MenuItem value="21">
              21 - Servidor Público titular de cargo efetivo, magistrado, membro do Ministério
              Público e do Tribunal
            </MenuItem>
            <MenuItem value="22">
              22 - Contrib individ contratado por contrib individ, por prod rural PF, por missao
              diplomática/consulado
            </MenuItem>
            <MenuItem value="23">
              23 - Contribuinte individual transportador autônomo contratado por contrib individual,
              por produtor rural
            </MenuItem>
            <MenuItem value="24">
              24 - Contribuinte individual cooperado que presta serviços à entidade beneficente
              isenta da cota patronal
            </MenuItem>
            <MenuItem value="25">
              25 - Contrib individ transportador cooperado que presta serviços à entidade
              beneficente isenta cota patronal
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12}>
          <ContentDivider title="FGTS/Rescisão" />
        </Grid>

        <Grid item md={6} xs={12}>
          <MUIAutoComplete
            label="Rescisão FGTS"
            options={RescisaoFGTSValues}
            renderOption={(option) => `${option.value} - ${option.name}`}
            value={data?.rescisaoFGTS || ''}
            onChange={(e, obj) => {
              const rescisaoFGTS = obj?.value || ''
              setData((oldState) => ({ ...oldState, rescisaoFGTS }))
            }}
            optionId="value"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <MUIAutoComplete
            label="Rescisão Homolognet"
            options={HomologNetValues}
            renderOption={(option) => option.name}
            value={data?.rescisaoHomologNet || ''}
            onChange={(e, obj) => {
              const rescisaoHomologNet = obj?.value || ''
              setData((oldState) => ({
                ...oldState,
                rescisaoHomologNet,
              }))
            }}
            optionId="value"
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <TextField
            label="Código de Saque"
            fullWidth
            value={data?.codigoSaqueFGTS || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 5,
            }}
            onChange={(e) => {
              const codigoSaqueFGTS = e?.target?.value || ''
              setData((prev) => ({
                ...prev,
                codigoSaqueFGTS,
              }))
            }}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <TextField
            label="Chave CEF"
            fullWidth
            value={data?.chaveCEF || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 30,
            }}
            onChange={(e) => {
              const chaveCEF = e?.target?.value || ''
              setData((prev) => ({
                ...prev,
                chaveCEF,
              }))
            }}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <CurrencyTextField
            label="Valor Remuneração Anterior"
            value={data?.vrRemuneracaoMesAnterior || ''}
            onChange={(_, value) => {
              const vrRemuneracaoMesAnterior = value
              setData((prev) => ({
                ...prev,
                vrRemuneracaoMesAnterior,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ContainerForm>
  )
}

export default Form
