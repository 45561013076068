import { Box, Tab, Tabs } from '@material-ui/core'
import { useState } from 'react'

import { PageHeader, StackContainer, TabPanel } from '~/components'

import Impressao from './Impressao'
import Configuracoes from './Configuracoes'

enum TabRelatorioAdmissional {
  Impressao,
  Configuracao,
}

export default function RelatorioAdmissional() {
  const [tab, setTab] = useState(TabRelatorioAdmissional.Impressao)

  return (
    <StackContainer>
      <PageHeader title="Relatórios Admissionais">
        <Box>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => setTab(value)}
          >
            <Tab label="Impressão" />
            <Tab label="Configurações" />
          </Tabs>
        </Box>
      </PageHeader>

      <Box flex={1}>
        <TabPanel value={tab} index={TabRelatorioAdmissional.Impressao}>
          <Impressao />
        </TabPanel>
        <TabPanel value={tab} index={TabRelatorioAdmissional.Configuracao}>
          <Configuracoes />
        </TabPanel>
      </Box>
    </StackContainer>
  )
}
