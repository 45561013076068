import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ActionMenu, ButtonBox, ConfirmDeleteDialog, ToolsDataTable } from '~/components'

import useDialog from '~/hooks/useDialog'

import { useGetAllByVinculo } from '~/hooks/queries/ProcessoTrabalhistaVinculo/useGetAllByVinculo'
import { useRemove } from '~/hooks/queries/ProcessoTrabalhistaVinculo/useRemove'
import { useRetificar } from '~/hooks/queries/ProcessoTrabalhistaVinculo/useRetificar'

import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'
import { MUIDataTableColumnDef } from 'mui-datatables'

import {
  TipoContratoProcessoEnum,
  TipoContratoProcessoLabels,
} from '~/@types/enums/TipoContratoProcessoEnum'
import { formatPrettyAnoMes } from '~/hooks/useUtils'
import { formatSimpleDate } from '~/utils/utils'

interface TableProps {
  query: string
  funcionario: VinculoConsulta
  handleClickEdit: (processoTrabalhistaVinculoId: string) => void
}

export default function Table({ query, funcionario, handleClickEdit }: TableProps) {
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useGetAllByVinculo({
    vinculoId: funcionario.id,
  })
  const _d = _data || []

  const data = _d.map((d) => ({
    ...d,
    identificacaoProcesso: d?.processoTrabalhista?.identificacaoProcesso || '',
  }))

  const { mutateAsync, isLoading: isRemoving } = useRemove()
  const { mutateAsync: mutateAsyncRetificar, isLoading: isLoadingRetificar } = useRetificar()

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  async function handleDelete() {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  async function handleRetificar(id: string) {
    await mutateAsyncRetificar({ processoTrabalhistaVinculoId: id })
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'identificacaoProcesso',
      label: 'Processo',
    },
    {
      name: 'dtEmissao',
      label: 'Emissão',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'tipoContratoProcesso',
      label: 'Tipo de Contrato',
      options: {
        customBodyRender: (value: TipoContratoProcessoEnum) => TipoContratoProcessoLabels[value],
      },
    },
    {
      name: 'competenciaInicio',
      label: 'Competência Início',
      options: {
        customBodyRender: formatPrettyAnoMes,
      },
    },
    {
      name: 'competenciaFim',
      label: 'Competência Fim',
      options: {
        customBodyRender: formatPrettyAnoMes,
      },
    },
    {
      name: 'reciboEsocial',
      label: 'Recibo eSocial',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          const rowCurrent = _d.find((d) => d.id === value)
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <ActionMenu
                isLoading={isLoadingRetificar}
                options={[
                  {
                    icon: 'replay',
                    title: 'Retificar este registro',
                    label: 'Retificar',
                    onClick: () => handleRetificar(value),
                    disabled: !rowCurrent?.reciboEsocial,
                  },
                ]}
              />

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => handleClickEdit(value)}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => openConfirmDelete(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <>
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        disableAutoHeight
        sherlock={{
          query,
          columns: ['identificacaoProcesso', 'tipoContratoProcesso'],
        }}
      />
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isRemoving}
          onCancel={() => closeConfirmDelete()}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
