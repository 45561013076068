import React, { useState } from 'react'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'

export default function AutoCompleteCTBModelo(props) {
  const { ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/CtbModelo')
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label="CTB Modelo"
      {...rest}
    />
  )
}
