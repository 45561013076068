import { useState } from 'react'

import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { Grid } from '@material-ui/core'
import { ActionDialog, MultipleSelectCheck } from '~/components'
import { AutoCompleteHorarioTrabalho } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoHorarioItem } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'
import { DiaHorarioEnum, DiaHorarioValues } from '~/@types/enums/DiaHorarioEnum'

const schema = yup.object().shape({
  horarioTrabalhoId: yup.string().required('Informe o Horário de trabalho').nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  afterSubmit: (event: 'insert', data: VinculoHorarioItem[]) => void
}

export function Form(props: FormProps) {
  const { isOpen, onClose, afterSubmit } = props

  const [data, setData] = useState<{
    horarioTrabalhoId: string
    diasSemana: { value: DiaHorarioEnum; name: string }[]
  }>({
    horarioTrabalhoId: '',
    diasSemana: [],
  })

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { empregador } = useAmbiente()

  const handleSubmit = () => {
    if (!(data.diasSemana.length > 0)) {
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um dia da semana para prosseguir'],
      })
    }

    const insert = () => {
      const diasSemana = data.diasSemana.map<Partial<VinculoHorarioItem>>((x) => ({
        id: uuidv4(),
        diaHorario: x.value,
        horarioTrabalhoId: data.horarioTrabalhoId as string,
      }))
      afterSubmit('insert', diasSemana as VinculoHorarioItem[])
      notification.post()
    }

    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Dia da semana"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MultipleSelectCheck<{ value: DiaHorarioEnum; name: string }>
            label="Dias da semana trabalhado"
            required
            getOptionLabel={(option) => option.name}
            fieldCompare="value"
            value={data.diasSemana}
            options={DiaHorarioValues}
            onChange={(dt) => {
              const diasSemana = dt
              setData((prev) => ({ ...prev, diasSemana }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteHorarioTrabalho
            label="Horário de trabalho"
            value={data?.horarioTrabalhoId || null}
            empregadorId={empregador?.id}
            required
            validationErrors={validationErrors}
            name="horarioTrabalhoId"
            onChange={(e, horarioTrabalho) => {
              const horarioTrabalhoId = horarioTrabalho?.id || null
              setData({ ...data, horarioTrabalhoId })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
