import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EstabelecimentoConsulta } from '../EstabelecimentoConsulta/EstabelecimentoConsulta'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'

export const query = '/RP/ObterEstabelecimentosComRecibosNaoFinalizados'

interface RequestProps {
  recibosIdfs: ReciboIDFEnum[]
  anoMes: string
  grupoId?: string
}

export function useObterEstabelecimentosComRecibosNaoFinalizados(props: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: EstabelecimentoConsulta[] }>(query, props.recibosIdfs, {
      params: { anoMes: props.anoMes, grupoId: props.grupoId },
    })
    return response.data.data
  }

  return useQuery([query], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
