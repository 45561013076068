import React, { useEffect, useState } from 'react'

import { Box, FormControlLabel, Radio, Typography } from '@material-ui/core'
import { Button, ButtonBox } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

const schema = yup.object().shape({
  dtInicioValidade: yup
    .date()
    .typeError('Informe uma Data válida')
    .nullable()
    .when(['$radioControl'], (radioControl, schema) => {
      if (radioControl === 'S') {
        return schema.required('Informe o Início da Atividade')
      }
      return schema
    }),
})

export default function DialogPerguntaData(props) {
  const { isOpen, onClose, onSubmit } = props

  const [data, setData] = useState({ dtInicioValidade: null })
  const [radioControl, setRadioControl] = useState('S')

  useEffect(() => {
    setData({ dtInicioValidade: null })
  }, [])

  function handleSubmit() {
    if (radioControl === 'S') return onSubmit(data.dtInicioValidade)
    onSubmit(null)
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    schemaOptions: {
      abortEarly: false,
      context: {
        radioControl,
      },
    },
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Inclusão para o eSocial"
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button size="small" color="primary" onClick={onClose}>
            Cancelar
          </Button>
          <Button size="small" color="primary" onClick={handleValidate}>
            Prosseguir
          </Button>
        </ButtonBox>
      }
    >
      <Typography>Deseja fazer uma nova inclusão para o eSocial?</Typography>

      <Box display="flex" justifyContent="center">
        <FormControlLabel
          control={<Radio color="primary" />}
          label="Sim"
          checked={radioControl === 'S' ? true : false}
          onChange={() => setRadioControl('S')}
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          label="Não"
          checked={radioControl === 'N' ? true : false}
          onChange={() => setRadioControl('N')}
        />
      </Box>

      {radioControl === 'S' ? (
        <>
          <Typography>
            <strong>Preencha o Início da Atividade</strong> que iremos considerar a alteração a
            partir dessa data.
          </Typography>
          <Box pt={1}>
            <DatePicker
              label="Início da Atividade"
              size="small"
              validationErrors={validationErrors}
              name="dtInicioValidade"
              value={data?.dtInicioValidade || null}
              onChange={(date) => {
                const dtInicioValidade = date ? date.format('yyyy-MM-DD') : null
                setData({ dtInicioValidade })
              }}
              required
            />
          </Box>
        </>
      ) : (
        <Typography>
          Dessa forma iremos alterar o <strong>último registro</strong> do eSocial
        </Typography>
      )}
    </ActionDialog>
  )
}
