import React, { useState } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { PageHeader, Button } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useStepperContext } from '~/components/StepperForm'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

import { MainDataForm } from '..'
import { VinculoLocalPartial } from '~/hooks/queries/VinculoLocal/VinculoLocal'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const HEADER_HEIGHT = '50px'

export default function VinculoLocal() {
  const { mainData, onBack, onNext, onSubmit } = useStepperContext<MainDataForm>()

  const [collection, setCollection] = useState(mainData.dataLocalList)

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<{ data: VinculoLocalPartial; indexCurrent: number } | null>(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<number | null>(null)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  const handleClickItem = (event: 'delete' | 'edit', index: number) => {
    const handleClickEditItem = () => {
      const item = collection.find((i, indexCurrent) => indexCurrent === index)
      if (!item) return
      openForm({ data: item, indexCurrent: index })
    }

    const handleClickDeleteItem = () => {
      openConfirmDelete(index)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event]()
  }

  const handleDeleteItem = () => {
    const newItens = collection.filter((i, indexCurrent) => indexCurrent !== dataConfirmDelete)
    setCollection(newItens)
    closeConfirmDelete()
    notification.remove()
  }

  const handleClickAddItem = () => {
    if (collection.length > 0) {
      // eslint-disable-next-line
      const { id, dtLocal, observacao, ...rest } = collection[0]
      openForm({
        data: {
          ...rest,
          dtLocal: mainData.dataAlteracaoContrato.dtAlteracao || undefined,
        },
        indexCurrent: -1,
      })
    } else {
      openForm({
        data: {
          vinculoId: mainData.dataVinculo.id,
          dtLocal: mainData.dataAlteracaoContrato.dtAlteracao || undefined,
        },
        indexCurrent: -1,
      })
    }
  }

  const handleAfterSubmitForm = (
    event: 'insert' | 'update',
    data: VinculoLocalPartial,
    index: number,
  ) => {
    const handleAfterInsert = () => {
      const newItens = [data, ...collection]
      setCollection(newItens)
      closeForm()
    }

    const handleAfterUpdate = () => {
      const newItens = collection.map((i, indexCurrent) => (indexCurrent === index ? data : i))
      setCollection(newItens)
      closeForm()
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    functions[event]()
  }

  function handleValidate() {
    if (!(collection.length > 0))
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um Ambiente de Trabalho'],
      })

    const data = {
      ...mainData,
      dataLocalList: collection,
    }

    if (mainData?.dataVinculo?.tipoRegistro === VinculoTipoEnum.Cooperado_5) {
      onSubmit(data)
    } else {
      onNext(data)
    }
  }

  return (
    <Box>
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Ambiente de Trabalho">
          <ButtonBox top={1}>
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={collection} onItemClick={handleClickItem} />
      </Box>

      {dataForm && isOpenForm ? (
        <Form
          indexCurrent={dataForm.indexCurrent}
          isOpen={isOpenForm}
          data={dataForm.data}
          onClose={closeForm}
          onAfterSubmitForm={handleAfterSubmitForm}
          vinculo={mainData.dataVinculo}
        />
      ) : (
        <></>
      )}

      {isOpenConfirmDelete ? (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      ) : (
        <></>
      )}

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            {mainData?.dataVinculo?.tipoRegistro === VinculoTipoEnum.Cooperado_5
              ? 'Finalizar'
              : 'Continuar'}
          </Button>
        </ButtonBox>
      </Box>
    </Box>
  )
}
