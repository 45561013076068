import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useGetAllInvalidate } from './useGetAll'

import { notifyPost } from '~/utils/notification'

import { ProcessoTrabalhistaVinculoRemuneracao } from './dtos/ProcessoTrabalhistaVinculoRemuneracao'

export function useCreate() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useGetAllInvalidate()

  async function handleRequest(data: ProcessoTrabalhistaVinculoRemuneracao) {
    await api.post('/ProcessoTrabalhistaVinculoRemuneracao', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
