import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { FPMovimentoTipoEnum } from '~/@types/enums/FPMovimentoTipoEnum'
import { MovimentoConfig } from './dtos/MovimentoConfig'

const query = '/ExportacaoLancamentoConfig/ObterLotacaoConfigPorMovimentoTipo'

interface RequestProps {
  params: {
    lotacaoConfigId: string
    tipo: FPMovimentoTipoEnum
  }
}

export function useObterLotacaoConfigPorMovimentoTipo({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: MovimentoConfig }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
