import { IconButton, useTheme } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon, CheckCircle } from '@material-ui/icons'

import { Button, ButtonBox, ContainerTable, Stack, ToolsDataTable } from '~/components'

import FormPeriodo from './FormPeriodo'

import useDialog from '~/hooks/useDialog'

import {
  ProcessoTrabalhistaVinculoBaseDTO,
  ProcessoTrabalhistaVinculoCreateDTO,
} from '~/hooks/queries/ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculoCreateDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { IndGrauExposicaoEnum, IndGrauExposicaoLabels } from '~/@types/enums/IndGrauExposicaoEnum'

import { formatPrettyAnoMes } from '~/hooks/useUtils'
import { orderBy } from '~/utils/utils'

interface TabPeriodoValoresProps {
  data: ProcessoTrabalhistaVinculoCreateDTO
  setData: React.Dispatch<React.SetStateAction<ProcessoTrabalhistaVinculoCreateDTO>>
}

export default function TabPeriodoValores({ data, setData }: TabPeriodoValoresProps) {
  const {
    close: closeFormPeriodo,
    data: dataFormPeriodo,
    isOpen: isOpenFormPeriodo,
    open: openFormPeriodo,
  } = useDialog<{ data: ProcessoTrabalhistaVinculoBaseDTO; index: number }>()
  const theme = useTheme()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'Preenchido',
      options: {
        setCellProps: () => ({ style: { minWidth: '20px', maxWidth: '20px' } }),
        customBodyRender: (_, { rowIndex }) => {
          const dtCurrent = data.bases[rowIndex]

          const isPreenchido =
            dtCurrent.vrBcCp13 > 0 ||
            dtCurrent.vrBcCpMensal > 0 ||
            dtCurrent.vrBcFGTSDeclaradaAnteriormente > 0 ||
            dtCurrent.vrBcFGTSProcessoTrabalhista > 0 ||
            dtCurrent.vrBcFGTSSefip > 0

          return (
            <ButtonBox justifyContent="center">
              <CheckCircle
                style={{
                  color: isPreenchido ? theme.palette.success.main : theme.palette.action.active,
                }}
              />
            </ButtonBox>
          )
        },
      },
    },
    {
      name: 'periodoReferencia',
      label: 'Competência',
      options: {
        customBodyRender: formatPrettyAnoMes,
      },
    },
    {
      name: 'indGrauExposicao',
      label: 'Grau Exposição',
      options: {
        customBodyRender: (value: IndGrauExposicaoEnum) => IndGrauExposicaoLabels[value],
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtCurrent = data.bases[rowIndex]
                  if (dtCurrent) {
                    openFormPeriodo({ data: dtCurrent, index: rowIndex })
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  setData((prev) => ({
                    ...prev,
                    bases: prev.bases.filter((d, index) => index !== rowIndex),
                  }))
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack height="100%">
      <ButtonBox>
        <Button
          variant="contained"
          onClick={() =>
            openFormPeriodo({
              data: {
                periodoReferencia: '',
                vrBcCpMensal: 0,
                vrBcCp13: 0,
                indGrauExposicao: IndGrauExposicaoEnum.AposentadoriaNormal,
                vrBcFGTSProcessoTrabalhista: 0,
                vrBcFGTSSefip: 0,
                vrBcFGTSDeclaradaAnteriormente: 0,
              },
              index: -1,
            })
          }
        >
          Adicionar
        </Button>
      </ButtonBox>

      <ContainerTable>
        <ToolsDataTable data={data.bases} columns={columns} disableAutoHeight pagination={false} />
      </ContainerTable>

      {isOpenFormPeriodo && (
        <FormPeriodo
          isOpen={isOpenFormPeriodo}
          data={dataFormPeriodo.data}
          onClose={closeFormPeriodo}
          onChange={(d) =>
            setData((prev) => {
              const bases = [...prev.bases.filter((_, index) => index !== dataFormPeriodo.index), d]
              return {
                ...prev,
                bases: orderBy(bases, 'periodoReferencia'),
              }
            })
          }
        />
      )}
    </Stack>
  )
}
