import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { NumeroInscricaoTextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import {
  tipoInscricaoConsts,
  tipoInscricaoValues as _tipoInscricaoValues,
} from '~/values/tipoInscricaoValues'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import apiPessoal from '~/services/api-pessoal'
import * as yup from 'yup'

const { CNPJ_1, CPF_2 } = tipoInscricaoConsts

const tipoInscricaoValues = _tipoInscricaoValues.filter(
  (obj) => obj.value === CNPJ_1 || obj.value === CPF_2,
)

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome').nullable(),
  tipoInscricao: yup.string().required('Informe o Tipo Inscrição'),
  nrInscricao: yup
    .string()
    .required('Informe o Número de Inscrição')
    .when(['tipoInscricao'], (tipoInscricao, schema) =>
      schema.test('test', 'Informe um Número de Inscriçao válido', (nrInscricao) => {
        if (parseInt(tipoInscricao) === CNPJ_1) return cnpj.isValid(nrInscricao)
        if (parseInt(tipoInscricao) === CPF_2) return cpf.isValid(nrInscricao)
      }),
    ),
})

const AdquirenteCadastro = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
    // eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await apiPessoal.put(`/adquirente/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await apiPessoal.post('/adquirente', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Adquirente"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            label="Nome"
            name="nome"
            validationErrors={validationErrors}
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            value={data?.nome || ''}
            required
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const nome = e.target.value
              setData((oldState) => ({
                ...oldState,
                nome,
              }))
            }}
          />
        </Grid>

        <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
          <MUIAutoComplete
            label="Tipo Inscrição"
            name="tipoInscricao"
            required
            validationErrors={validationErrors}
            options={tipoInscricaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data?.tipoInscricao || ''}
            onChange={(e, obj) => {
              const tipoInscricao = obj?.value
              setData((oldState) => ({
                ...oldState,
                tipoInscricao,
              }))
            }}
          />
        </Grid>

        <Grid xl={8} lg={8} md={8} sm={8} xs={12} item>
          <NumeroInscricaoTextField
            typeMask={data?.tipoInscricao}
            label="Número de Inscrição"
            value={data.nrInscricao || ''}
            name="nrInscricao"
            required
            validationErrors={validationErrors}
            onChange={(e, value) => {
              const nrInscricao = value
              setData((oldState) => ({
                ...oldState,
                nrInscricao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default AdquirenteCadastro
