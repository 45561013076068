import { Box, LinearProgress, Theme, useMediaQuery } from '@material-ui/core'

import StepperForm, { useStepperContext } from '~/components/StepperForm'
import { ShowNumber } from '~/components'

import AcompanhamentoAuditoria from './AcompanhamentoAuditoria'
import { DataContextProps, UseStylesProps } from '..'

import useAmbiente from '~/hooks/useAmbiente'

import { useObterTotalEventosGrupoAcompanhamento } from '~/hooks/queries/ESocial/useObterTotalEventosGrupoAcompanhamento'
import useSocketTransmissaoESocial from '~/hooks/queries/ESocial/useSocketTransmissaoESocial'

import { useStylesRootSteps } from '../EnvioESocial'
import { AutoSizer } from 'react-virtualized'
import { ESocialGrupoEventoConsultaEnum } from '~/@types/enums/ESocialGrupoEventoConsultaEnum'

export default function AcompanhamentoESocial() {
  const { anoMes } = useAmbiente()
  const isSmallWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const useStylesProps: UseStylesProps = {
    isSmallWidth,
  }
  const classes = useStylesRootSteps(useStylesProps)
  const { dataControlled: dataContext } = useStepperContext<DataContextProps>()
  const {
    data,
    isLoading: _isLoading,
    isFetching,
  } = useObterTotalEventosGrupoAcompanhamento({
    anoMes,
    filterEventos: dataContext.eventosFilter,
    grupoId: dataContext.grupoId,
    indApuracao: dataContext.indApuracao,
  })
  useSocketTransmissaoESocial()

  const isLoading = _isLoading || isFetching

  const totalEventsPerGroup = {
    [ESocialGrupoEventoConsultaEnum.Processando]: 0,
    [ESocialGrupoEventoConsultaEnum.Finalizado]: 0,
    [ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia]: 0,
    [ESocialGrupoEventoConsultaEnum.Erro]: 0,
  }
  data.forEach((d) => {
    totalEventsPerGroup[d.grupoEventos] = d.totalEventos
  })

  const grupoStepFinded = data.find((d) => d.totalEventos > 0)
  const stepStart = grupoStepFinded ? grupoStepFinded.grupoEventos - 1 : 0

  return (
    <StepperForm.Provider
      disabledAlert
      initialStep={stepStart}
      dataControlled={dataContext}
      steps={[
        {
          label: 'Processando',
          icon: (active) =>
            totalEventsPerGroup[ESocialGrupoEventoConsultaEnum.Processando] > 0 ? (
              <ShowNumber
                number={totalEventsPerGroup[ESocialGrupoEventoConsultaEnum.Processando]}
                severity="info"
                active={active}
              />
            ) : (
              'account_circle'
            ),
          children: <AcompanhamentoAuditoria grupo={ESocialGrupoEventoConsultaEnum.Processando} />,
        },
        {
          label: 'Finalizado',
          icon: (active) =>
            totalEventsPerGroup[ESocialGrupoEventoConsultaEnum.Finalizado] > 0 ? (
              <ShowNumber
                number={totalEventsPerGroup[ESocialGrupoEventoConsultaEnum.Finalizado]}
                severity="success"
                active={active}
              />
            ) : (
              'toc'
            ),
          children: <AcompanhamentoAuditoria grupo={ESocialGrupoEventoConsultaEnum.Finalizado} />,
        },
        {
          label: 'Advertência',
          icon: (active) =>
            totalEventsPerGroup[ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia] > 0 ? (
              <ShowNumber
                number={totalEventsPerGroup[ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia]}
                severity="warning"
                active={active}
              />
            ) : (
              'people_outline'
            ),
          children: (
            <AcompanhamentoAuditoria
              grupo={ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia}
            />
          ),
        },
        {
          label: 'Erro',
          icon: (active) =>
            totalEventsPerGroup[ESocialGrupoEventoConsultaEnum.Erro] > 0 ? (
              <ShowNumber
                number={totalEventsPerGroup[ESocialGrupoEventoConsultaEnum.Erro]}
                severity="error"
                active={active}
              />
            ) : (
              'people'
            ),
          children: <AcompanhamentoAuditoria grupo={ESocialGrupoEventoConsultaEnum.Erro} />,
        },
      ]}
    >
      <Box className={classes.root}>
        <Box className={classes.rootSteps}>
          {isLoading ? <LinearProgress /> : <Box height={4} />}
          <Box className={classes.steps}>
            <StepperForm.Steps orientation="vertical" />
          </Box>
        </Box>

        <Box className={classes.content}>
          <AutoSizer>
            {({ height, width }) => (
              <Box height={height} width={width} overflow="auto">
                <StepperForm.Content
                  style={{
                    height: '100%',
                  }}
                />
              </Box>
            )}
          </AutoSizer>
        </Box>
      </Box>
    </StepperForm.Provider>
  )
}
