import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { PageHeader, Button, ActionDialog } from '~/components'

import useDialog from '~/hooks/useDialog'

import Table from './components/Table'
import Form from './components/Form'

import { VinculoSalarioBasePartial } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'
import { IndSalarioContratualEnum } from '~/@types/enums/IndSalarioContratualEnum'

interface VinculoSalarioContratualProps {
  vinculoId: string
  isOpen: boolean
  onClose: () => void
}

export default function DialogSalarioContratual(props: VinculoSalarioContratualProps) {
  const { vinculoId, isOpen, onClose } = props

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoSalarioBasePartial | null>(null)

  function handleClickAdd() {
    openForm({
      vinculoId,
      indSalario: IndSalarioContratualEnum.Minimo,
    })
  }

  return (
    <ActionDialog
      title="Salário Contratual"
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
      }}
    >
      <PageHeader>
        <ButtonBox top={1}>
          <Button size="small" color="primary" onClick={handleClickAdd} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Table vinculoId={vinculoId} />

      {dataForm && isOpenForm ? (
        <Form data={dataForm} isOpen={isOpenForm} onClose={closeForm} />
      ) : (
        <></>
      )}
    </ActionDialog>
  )
}
