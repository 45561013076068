import { useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { formatToCPF } from 'brazilian-values'

import { CircularProgress, Grid } from '@material-ui/core'

import { ActionDialog } from '~/components'
import StepperForm from '~/components/StepperForm'
import { StepProps } from '~/components/StepperForm/StepperProvider'

import ReciboRescisaoForm from './ReciboRescisaoForm'
import ReciboRescisaoImpressao from './ReciboRescisaoImpressao'
import ReciboRescisaoEmprestimos from './ReciboRescisaoEmprestimos'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { useObterDataParaCriarRescisao } from '~/hooks/queries/Rescisao/useObterDataParaCriarRescisao'
import { TermoRescisaoDTOForm } from '~/hooks/queries/Rescisao/TermoRescisaoDTO'
import { rpNovoRescisao } from '~/hooks/queries/Rescisao/rpNovoRescisao'

interface ReciboRescisaoProps {
  isOpen: boolean
  onClose: () => void
  vinculoId: string
  onAfterAddReciboRescisao: (reciboId: string) => void
}

export default function ReciboRescisao(props: ReciboRescisaoProps) {
  const { isOpen, onClose, vinculoId, onAfterAddReciboRescisao } = props

  const { anoMes } = useAmbiente()
  const { data, isLoading: isLoadingObterData } = useObterDataParaCriarRescisao(vinculoId, anoMes)

  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleSubmit(_data: TermoRescisaoDTOForm) {
    setSubmitting(true)
    try {
      const reciboId = uuidv4()
      await rpNovoRescisao({ ..._data, reciboId })
      onAfterAddReciboRescisao(reciboId)
      notification.success('Recibo de Rescisão criado com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  const steps: StepProps[] = [
    {
      label: 'Rescisão',
      icon: 'people',
      children: <ReciboRescisaoForm />,
    },
    {
      label: 'Impressão',
      icon: 'print',
      children: <ReciboRescisaoImpressao />,
    },
  ]

  data && data.emprestimo
    ? steps.push({
        label: 'Empréstimos',
        icon: 'monetization_on',
        children: <ReciboRescisaoEmprestimos />,
      })
    : undefined

  return (
    <ActionDialog
      title="Cadastro Recibo de Rescisão"
      subtitle={
        data?.vinculoNrInscricao
          ? `${formatToCPF(data.vinculoNrInscricao)} - ${data.vinculoNome}`
          : '-'
      }
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      customActions={<></>}
    >
      {isLoadingObterData && <CircularProgress />}
      {data && (
        <StepperForm.Provider
          data={data}
          onSubmit={handleSubmit}
          onCancel={onClose}
          steps={steps}
          isLoading={isSubmitting}
        >
          <Grid container spacing={0}>
            <Grid item xs={1} md={2}>
              <StepperForm.Steps />
            </Grid>
            <Grid item xs={11} md={10}>
              <StepperForm.Content />
            </Grid>
          </Grid>
        </StepperForm.Provider>
      )}
    </ActionDialog>
  )
}
