import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { AuditoriaXML } from '../AuditoriaXML/AuditoriaXML'

export default function useObterAuditoriaXML() {
  const dialogNotification = useDialogNotification()

  async function handleRequest(auditoriaId: string) {
    const response = await api.get<{ data: AuditoriaXML }>('/ESocial/ObterAuditoriaXML', {
      params: {
        auditoriaId,
      },
    })
    return response.data.data
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
