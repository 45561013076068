import React from 'react'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { processoTipoValues } from '~/values/processoTipoValues'

const formatTipoProcesso = (tipoProcesso) => {
  const objectFinded = processoTipoValues.find((obj) => obj.value === tipoProcesso)
  return objectFinded?.name || ''
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data.map((item) => {
    return {
      ...item,
      tipoProcesso: formatTipoProcesso(item?.tipoProcesso),
    }
  })

  const columns = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'tipoProcesso',
      label: 'Tipo do Processo',
    },
    {
      name: 'numeroProcesso',
      label: 'Número do Processo',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <DataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['nome', 'tipoProcesso', 'numeroProcesso'],
      }}
    />
  )
}

export default Table
