import React from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import InputDiaHorario from './components/InputDiaHorario'

export const schemaFields = {
  domingo: yup.object().shape({
    horario: yup.string().required('Informe o horário de domingo'),
    intervalo: yup.string().required('Informe o intervalo de domingo'),
  }),
  segunda: yup.object().shape({
    horario: yup.string().required('Informe o horário de segunda'),
    intervalo: yup.string().required('Informe o intervalo de segunda'),
  }),
  terca: yup.object().shape({
    horario: yup.string().required('Informe o horário de terça'),
    intervalo: yup.string().required('Informe o intervalo de terça'),
  }),
  quarta: yup.object().shape({
    horario: yup.string().required('Informe o horário de quarta'),
    intervalo: yup.string().required('Informe o intervalo de quarta'),
  }),
  quinta: yup.object().shape({
    horario: yup.string().required('Informe o horário de quinta'),
    intervalo: yup.string().required('Informe o intervalo de quinta'),
  }),
  sexta: yup.object().shape({
    horario: yup.string().required('Informe o horário de sexta'),
    intervalo: yup.string().required('Informe o intervalo de sexta'),
  }),
  sabado: yup.object().shape({
    horario: yup.string().required('Informe o horário de sábado'),
    intervalo: yup.string().required('Informe o intervalo de sábado'),
  }),
}

export default function FormHorariosBase(props) {
  const { data, setData, validationErrors } = props

  return (
    <>
      <Grid item xs={12}>
        <InputDiaHorario
          label="Domingo"
          value={data?.domingo}
          onChange={(value) =>
            setData((prevState) => ({
              ...prevState,
              domingo: value,
            }))
          }
          validationErrors={validationErrors}
          name="domingo"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InputDiaHorario
          label="Segunda"
          value={data?.segunda}
          onChange={(value) =>
            setData((prevState) => ({
              ...prevState,
              segunda: value,
            }))
          }
          validationErrors={validationErrors}
          name="segunda"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InputDiaHorario
          label="Terça"
          value={data?.terca}
          onChange={(value) =>
            setData((prevState) => ({
              ...prevState,
              terca: value,
            }))
          }
          validationErrors={validationErrors}
          name="terca"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InputDiaHorario
          label="Quarta"
          value={data?.quarta}
          onChange={(value) =>
            setData((prevState) => ({
              ...prevState,
              quarta: value,
            }))
          }
          validationErrors={validationErrors}
          name="quarta"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InputDiaHorario
          label="Quinta"
          value={data?.quinta}
          onChange={(value) =>
            setData((prevState) => ({
              ...prevState,
              quinta: value,
            }))
          }
          validationErrors={validationErrors}
          name="quinta"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InputDiaHorario
          label="Sexta"
          value={data?.sexta}
          onChange={(value) =>
            setData((prevState) => ({
              ...prevState,
              sexta: value,
            }))
          }
          validationErrors={validationErrors}
          name="sexta"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InputDiaHorario
          label="Sábado"
          value={data?.sabado}
          onChange={(value) =>
            setData((prevState) => ({
              ...prevState,
              sabado: value,
            }))
          }
          validationErrors={validationErrors}
          name="sabado"
          required
        />
      </Grid>
    </>
  )
}
