export enum IndGrauExposicaoEnum {
  NaoInformar_0 = 0,
  AposentadoriaNormal = 1,
  Aposentadoria15Anos = 2,
  Aposentadoria20Anos = 3,
  Aposentadoria25Anos = 4,
}

export const IndGrauExposicaoLabels = {
  [IndGrauExposicaoEnum.NaoInformar_0]: '0 - Não informar',
  [IndGrauExposicaoEnum.AposentadoriaNormal]: '1 - Não exposto',
  [IndGrauExposicaoEnum.Aposentadoria15Anos]: '2 - FAE 15 anos de trabalho',
  [IndGrauExposicaoEnum.Aposentadoria20Anos]: '3 - FAE 20 anos de trabalho',
  [IndGrauExposicaoEnum.Aposentadoria25Anos]: '4 - FAE 25 anos de trabalho',
}

export const IndGrauExposicaoValues = [
  { value: IndGrauExposicaoEnum.AposentadoriaNormal, name: '1 - Não exposto' },
  { value: IndGrauExposicaoEnum.Aposentadoria15Anos, name: '2 - FAE 15 anos de trabalho' },
  { value: IndGrauExposicaoEnum.Aposentadoria20Anos, name: '3 - FAE 20 anos de trabalho' },
  { value: IndGrauExposicaoEnum.Aposentadoria25Anos, name: '4 - FAE 25 anos de trabalho' },
]
