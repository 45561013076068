import React from 'react'

import PropTypes from 'prop-types'

import { AutoSizer, Table, Column } from 'react-virtualized'

export default function VirtualizeDataTable(props) {
  const { data, columns } = props

  function customBodyRender(value) {
    return value
  }

  const optionsDefault = {
    customBodyRender,
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          width={width}
          height={height}
          headerHeight={48}
          rowHeight={48}
          rowCount={data.length}
          rowGetter={({ index }) => data[index]}
          rowStyle={{
            display: 'flex',
          }}
        >
          {columns.map(({ label, name, options: optionsCurrent }, index) => {
            const options = {
              ...optionsDefault,
              ...optionsCurrent,
            }
            const { customBodyRender } = options
            return (
              <Column
                key={index}
                label={label}
                dataKey={name}
                width={width / columns.length}
                cellRenderer={({ cellData, rowIndex }) =>
                  customBodyRender(cellData, {
                    rowIndex,
                    columnData: {
                      name,
                    },
                  })
                }
              />
            )
          })}
        </Table>
      )}
    </AutoSizer>
  )
}

VirtualizeDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
}
