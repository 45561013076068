import { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'
import { formatToCPF } from 'brazilian-values'

import { Delete as DeleteIcon } from '@material-ui/icons'
import { Box, CircularProgress, Grid, IconButton, Paper, makeStyles } from '@material-ui/core'

import { Container, MenuSelect, PDFViewer, PrintButton } from '~/components'

import Content from './Content'
import Footer from './Totalizador'

import useSimpleStartRequest from '~/hooks/queries/useSimpleStartRequest'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

interface RescisaoSimuladaDataFuncionario {
  vinculoRescisaoSimuladaId: string
  vinculoId: string
  vinculoNome: string
  vinculoCPF: string
}

interface RescisaoSimuladaData {
  vrTotalDesconto: number
  vrTotalLiquido: number
  vrTotalVencimento: number
  vrTotalRecolherFGTS: number
  funcionarios: RescisaoSimuladaDataFuncionario[]
}

export default function RescisoesSimuladas() {
  const [indexMenuSelected, setIndexMenuSelected] = useState(-1)
  const [isLoadingDelete, setLoadingDelete] = useState(false)
  const [optionsCheckeds, setOptionsCheckeds] = useState([])

  const { estabelecimento } = useAmbiente()
  const { data, isLoading, getData } = useSimpleStartRequest<RescisaoSimuladaData>(
    {
      url: '/VinculoRescisaoSimulada/ObterRescisoesSimuladas',
      params: {
        estabelecimentoId: estabelecimento.id,
      },
      method: 'GET',
    },
    {
      funcionarios: [],
      vrTotalDesconto: 0,
      vrTotalLiquido: 0,
      vrTotalRecolherFGTS: 0,
      vrTotalVencimento: 0,
    },
    [estabelecimento],
  )
  const classes = useStyles()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const dialogNotification = useDialogNotification()
  const notificaiton = useNotification()

  useEffect(() => {
    setIndexMenuSelected(-1)
    setOptionsCheckeds([])
  }, [data])

  const vinculoRescisaoSimuladaCurrent = data.funcionarios[indexMenuSelected]

  function handleSelectItem(index: number) {
    setIndexMenuSelected(index)
  }

  async function handlePrintCheckeds() {
    const idsRescisoesSimuladas = optionsCheckeds.map(
      (indexChecked) => data.funcionarios[indexChecked].vinculoRescisaoSimuladaId,
    )
    openPDFViewer(idsRescisoesSimuladas)
  }

  async function handleRemoveItemMenu(rescisaoSimuladaId: string) {
    setLoadingDelete(true)
    try {
      await api.delete(`/VinculoRescisaoSimulada/${rescisaoSimuladaId}`)
      notificaiton.remove()
      getData()
      setOptionsCheckeds([])
      setIndexMenuSelected(-1)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingDelete(false)
    }
  }

  return (
    <Container>
      <Box component={Paper} height="calc(100vh - 190px)">
        <Grid container spacing={2} className={classes.fullHeight}>
          <Grid item xl={3} lg={4} md={5} sm={6} xs={12}>
            <MenuSelect
              onCheckOption={setOptionsCheckeds}
              optionsCheckeds={optionsCheckeds}
              options={data.funcionarios}
              onSelect={(index: number) => handleSelectItem(index)}
              selectedOption={indexMenuSelected}
              renderOption={(d: RescisaoSimuladaDataFuncionario) =>
                formatToCPF(d.vinculoCPF) + ' - ' + d.vinculoNome
              }
              isLoading={isLoading}
              searchField="vinculoNome"
              idField="vinculoRescisaoSimuladaId"
              renderRight={(opt: RescisaoSimuladaDataFuncionario, index: number) => {
                if (index === indexMenuSelected) {
                  if (isLoadingDelete) return <CircularProgress size={20} />
                  return (
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveItemMenu(opt.vinculoRescisaoSimuladaId)}
                    >
                      <DeleteIcon fontSize="small" color="primary" />
                    </IconButton>
                  )
                }
                return <></>
              }}
            />
          </Grid>

          <Grid item xl={9} lg={8} md={7} sm={6} xs={12}>
            {vinculoRescisaoSimuladaCurrent ? (
              <Content
                vinculoRescisaoSimuladaId={vinculoRescisaoSimuladaCurrent.vinculoRescisaoSimuladaId}
              />
            ) : (
              <>Selecione um funcionário</>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 12 }}>
          <Grid item xl={3} lg={4} md={5} sm={6} xs={12}>
            <Box width="100%" height="100%" display="flex" alignItems="center">
              <PrintButton
                disabled={!(optionsCheckeds.length > 0)}
                onClick={handlePrintCheckeds}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item xl={9} lg={8} md={7} sm={6} xs={12}>
            <Footer
              vrTotalDesconto={data.vrTotalDesconto}
              vrTotalLiquido={data.vrTotalLiquido}
              vrTotalVencimento={data.vrTotalVencimento}
              vrTotalRecolherFGTS={data.vrTotalRecolherFGTS}
            />
          </Grid>
        </Grid>
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="RescisaoSimulada"
        title="Rescisão Simulada"
        axiosConfig={{
          method: 'post',
          url: '/VinculoRescisaoSimulada/ImprimirVariasRescisoesSimuladas',
          data: dataPDFViewer,
        }}
      />
    </Container>
  )
}
