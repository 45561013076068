import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryObterDocumentos } from './useObterDocumentos'

export function useRemoverDocumento() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(documentoId: string) {
    await api.delete('/GerenciadorDocumentos/RemoverDocumento', {
      params: {
        documentoId,
      },
    })
    notification.success('Documento removido com Sucesso')
  }

  return useMutation(handleRequest, {
    onSuccess: async () => await queryClient.invalidateQueries(queryObterDocumentos),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
