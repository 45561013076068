import { useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'

import { queryObterEmpregadoresParaFechamento } from './useObterEmpregadoresParaFechamento'
import { CompetenciaEmpregadorUpdateDTO } from './CompetenciaEmpregadorUpdateDTO'

interface RequestProps {
  id: string
  body: CompetenciaEmpregadorUpdateDTO
}

export default function useCompetenciaEmpregadorAtualizar() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest({ id, body }: RequestProps) {
    await api.put('/Competencia/' + id, body)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.put()
      await queryClient.invalidateQueries(queryObterEmpregadoresParaFechamento)
    },
    onError: dialogNotification.extractErrors,
  })
}
