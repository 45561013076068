import { useState, useEffect, useCallback, memo } from 'react'

import { Grid, Checkbox as MUICheckbox, FormControlLabel, Box, makeStyles } from '@material-ui/core'
import { ButtonBox, ContentDivider } from 'mio-library-ui'

import { ConfirmDeleteDialog, ActionDialog, Checkbox, TextField, Button } from '~/components'

import Table from './components/Table'
import FormItem from './components/FormItem'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'
import * as yup from 'yup'
import useAmbiente from '~/hooks/useAmbiente'

const MemoTable = memo(Table)

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '100%',
  },
}))

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  diaPagamentoAdiantamento: yup
    .number()
    .min(1, 'Informe o Dia de Pagamento do Adiantamento')
    .max(31, 'Informe o Dia de Pagamento do Adiantamento válido')
    .nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  const { anoMes } = useAmbiente(false, true)

  const [collectionItem, setCollectionItem] = useState({
    isLoading: false,
    itens: [],
  })
  const [formItem, setFormItem] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialogItem, setConfirmDeleteDialogItem] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  useEffect(() => {
    const getCollection = async () => {
      if (!_data?.id) return
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(`/tabelaMesItem/GetByTabelaMes?id=${_data.id}`)
        if (response.data.data) {
          setCollectionItem((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    setData(_data)
    getCollection()
    // eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/tabelaMes/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/tabelaMes', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const dataCurrent = collectionItem.itens.find((d) => d.id === id)
        handleOpenForm(dataCurrent)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialogItem((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    //eslint-disable-next-line
    [collectionItem],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    const itens = collectionItem.itens
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    try {
      await api.delete(`/tabelaMesItem/${confirmDeleteDialogItem.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialogItem.id)
      handleCloseConfirmDeleteItem()
      setCollectionItem((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    //eslint-disable-next-line
  }, [collectionItem.itens, confirmDeleteDialogItem.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm({ anoMes, tabelaMes: data, tabelaMesId: data?.id })
  }, [data, anoMes])

  const handleOpenForm = (data) => {
    setFormItem((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }

  const handleCloseForm = () => {
    setFormItem({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitFormItem = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collectionItem
        const newItens = [data, ...itens]
        setCollectionItem((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collectionItem
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollectionItem((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    //eslint-disable-next-line
    [collectionItem],
  )

  return (
    <ActionDialog
      title="Cadastro de Tabela Mês"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: data?.id ? 'xl' : 'md',
        fullWidth: true,
        classes: {
          paper: data?.id ? classes.heightPaper : undefined,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nome"
            fullWidth
            autoFocus
            value={data?.nome || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            inputProps={{
              maxLength: 100,
            }}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <FormControlLabel
            control={
              <MUICheckbox
                size="small"
                checked={Boolean(data?.isSalarioQuintoDiaUtil)}
                onChange={(e) => {
                  const isSalarioQuintoDiaUtil = e.target.checked
                  setData({
                    ...data,
                    isSalarioQuintoDiaUtil,
                  })
                }}
                color="primary"
              />
            }
            label="Pagamento do Salário no quinto dia útil"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Checkbox
            label="Pagamento do Pró-Labore no quinto dia útil"
            value={data.isProlaboreQuintoDiaUtil}
            onChange={(_, checked) => {
              const isProlaboreQuintoDiaUtil = checked
              setData((prev) => ({
                ...prev,
                isProlaboreQuintoDiaUtil,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Checkbox
            label="Pagamento do Autônomo no quinto dia útil"
            value={data.isRPAQuintoDiaUtil}
            onChange={(_, checked) => {
              const isRPAQuintoDiaUtil = checked
              setData((prev) => ({
                ...prev,
                isRPAQuintoDiaUtil,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <TextField
            label="Dia de Pagamento do Adiantamento"
            value={data?.diaPagamentoAdiantamento || 0}
            inputProps={{
              maxLength: 2,
            }}
            onlyNumber
            required
            validationErrors={validationErrors}
            name="diaPagamentoAdiantamento"
            onChange={(e) => {
              const diaPagamentoAdiantamento = e.target.value || 0
              setData((prev) => ({ ...prev, diaPagamentoAdiantamento }))
            }}
          />
        </Grid>

        {data?.id && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ContentDivider />
          </Grid>
        )}
      </Grid>

      {data?.id && (
        <>
          <Box py={1}>
            <ContentDivider
              title="Itens da Tabela Mês"
              renderRight={
                <ButtonBox>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleClickAddItem}
                    variant="contained"
                  >
                    Adicionar
                  </Button>
                </ButtonBox>
              }
            />
          </Box>

          <MemoTable
            data={collectionItem.itens}
            isLoading={collectionItem.isLoading}
            onItemClick={handleClickItem}
          />
        </>
      )}

      <FormItem
        isOpen={formItem.isOpen}
        data={formItem.data}
        onClose={handleCloseForm}
        onAfterSubmitFormItem={handleAfterSubmitFormItem}
        isSalarioQuintoDiaUtil={data?.isSalarioQuintoDiaUtil}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialogItem.isOpen}
        isDeleting={confirmDeleteDialogItem.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ActionDialog>
  )
}

export default Form
