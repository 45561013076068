import React, { useMemo, useState } from 'react'

import { ToolsDataTable } from '~/components'

import TableEventosValor from './components/TableEventosValor'

export default function TableEventos(props) {
  const { data } = props

  const [rowsExpanded, setRowsExpanded] = useState([])

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
    ],
    [],
  )

  function renderExpandableRow(indexParent) {
    const dataExpanded = data[indexParent]?.vinculosValorEvento || []
    return <TableEventosValor data={dataExpanded} />
  }

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      disableAutoHeight
      optionsExpandable={{
        type: 'multiple',
        onRowExpanded: setRowsExpanded,
        expandedChildren: renderExpandableRow,
        rowsExpanded: rowsExpanded,
        expandOnClick: true,
      }}
    />
  )
}
