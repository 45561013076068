import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import { openDownloadData } from '~/hooks/useUtils'

interface RequestProps {
  estabelecimentoId: string
  modeloPlanilhaId: string
  anoMes: string
}

export default function useGerarPlanilha() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(params: RequestProps) {
    const response = await api.post<{ data: Blob }>('/ImportarEventosRP/GerarPlanilha', null, {
      params,
      headers: {
        'DC-XlsResponse': true,
      },
      responseType: 'blob',
    })
    return response.data
  }

  return useMutation(handleRequest, {
    onSuccess: (data) => {
      notification.success('Planilha gerada com sucesso')
      openDownloadData('IMPORTACAO-EVENTOS-RP.xlsx', data)
    },
    onError: dialogNotification.extractErrors,
  })
}
