export enum ConsultaPessoalEntidadeEnum {
  RP = 'RP',
  Vinculo = 'Vinculo',
  PessoaFisica = 'PessoaFisica',
  Auditoria = 'Auditoria',
}

export const ConsultaPessoalEntidadeLabel = {
  [ConsultaPessoalEntidadeEnum.RP]: 'Recibo Pagamento',
  [ConsultaPessoalEntidadeEnum.Vinculo]: 'Vínculo',
  [ConsultaPessoalEntidadeEnum.PessoaFisica]: 'Pessoa Física',
  [ConsultaPessoalEntidadeEnum.Auditoria]: 'Auditoria',
}

export const ConsultaPessoalEntidadeValues = [
  {
    name: ConsultaPessoalEntidadeLabel[ConsultaPessoalEntidadeEnum.RP],
    value: ConsultaPessoalEntidadeEnum.RP,
  },
  {
    name: ConsultaPessoalEntidadeLabel[ConsultaPessoalEntidadeEnum.Vinculo],
    value: ConsultaPessoalEntidadeEnum.Vinculo,
  },
  {
    name: ConsultaPessoalEntidadeLabel[ConsultaPessoalEntidadeEnum.PessoaFisica],
    value: ConsultaPessoalEntidadeEnum.PessoaFisica,
  },
  {
    name: ConsultaPessoalEntidadeLabel[ConsultaPessoalEntidadeEnum.Auditoria],
    value: ConsultaPessoalEntidadeEnum.Auditoria,
  },
]
