const conjuge_01 = 1
const companheiroUniaoEstável_02 = 2
const filhoOuEnteado_03 = 3
const filhoOuEnteadoEstudante_04 = 4
const irmaoNetoOuBisneto_06 = 6
const irmaoNetoOuBisneto_Estudante_07 = 7
const paisAvosBisavos_09 = 9
const menorPobreGuardaJudicial_10 = 10
const pessoaIncapazTutorOuCurador_11 = 11
const exConjuge_12 = 12
const agregadoOutros_99 = 99

export const grauParentescoValues = [
  {
    value: conjuge_01,
    name: '01 - Cônjuge',
  },
  {
    value: companheiroUniaoEstável_02,
    name: '02 - Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua Declaração de União Estável',
  },
  {
    value: filhoOuEnteado_03,
    name: '03 - Filho(a) ou enteado(a)',
  },
  {
    value: filhoOuEnteadoEstudante_04,
    name: '04 - Filho(a) ou enteado(a), universitário(a) ou cursando escola técnica de 2º grau',
  },
  {
    value: irmaoNetoOuBisneto_06,
    name: '06 - Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do (a)qual detenha a guarda judicial',
  },
  {
    value: irmaoNetoOuBisneto_Estudante_07,
    name: '07 - Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, universitário(a) ou cursando escola técnica de 2° grau, do (a)qual detenha a guarda judicial',
  },
  {
    value: paisAvosBisavos_09,
    name: '09 - Pais, avós e bisavós',
  },
  {
    value: menorPobreGuardaJudicial_10,
    name: '10 - Menor pobre do qual detenha a guarda judicial',
  },
  {
    value: pessoaIncapazTutorOuCurador_11,
    name: '11 - A pessoa absolutamente incapaz, da qual seja tutor ou curador',
  },
  {
    value: exConjuge_12,
    name: '12 - Ex - cônjuge',
  },
  {
    value: agregadoOutros_99,
    name: '99 - Agregado / Outros',
  },
]

export const grauParentescoConsts = {
  conjuge_01,
  companheiroUniaoEstável_02,
  filhoOuEnteado_03,
  filhoOuEnteadoEstudante_04,
  irmaoNetoOuBisneto_06,
  irmaoNetoOuBisneto_Estudante_07,
  paisAvosBisavos_09,
  menorPobreGuardaJudicial_10,
  pessoaIncapazTutorOuCurador_11,
  exConjuge_12,
  agregadoOutros_99,
}
