import { useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader } from 'mio-library-ui'

import { TreeView } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import useAmbiente from '~/hooks/useAmbiente'
import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useGetByVinculo from '~/hooks/queries/VinculoFerias/useGetByVinculo'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const avisoFeriasRoute = 'aviso-ferias'

const HEADER_HEIGHT = '70px'

const ProgramacaoFeriasAviso = () => {
  const [vinculo, setVinculo] = useState({})
  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const url = window.location.href
  const params = url.split('/')
  const ultimoParametro = params[params.length - 1]

  const TITLE_PAGE =
    ultimoParametro === avisoFeriasRoute ? 'Aviso de Férias' : 'Programação de Férias'

  const { anoMes, estabelecimento } = useAmbiente()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching,
  } = useGetByVinculo({ anoMes, vinculoId: vinculo.id, isApenasAbertas: true })
  const isLoading = _isLoading || isFetching
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = _data || []

  useEffect(() => {
    setVinculo({})
  }, [anoMes, estabelecimento])

  const handleClickItem = useCallback(
    (id) => {
      const item = data.find((i) => i.id === id)
      handleOpenForm(item)
    },
    [data],
  )

  const handleOpenForm = (data = {}) => {
    setForm((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => {
        setVinculo((oldState) => ({
          ...oldState,
          id: vinculo?.vinculoId,
          nome: vinculo?.vinculoNome,
        }))
      }}
      searchBy="vinculoNome"
      renderOption={(option) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
      isLoadingOnClick={isLoading}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title={TITLE_PAGE} subtitle={vinculo?.nome || '-'} />
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={data} isLoading={isLoading} onItemClick={handleClickItem} />
      </Box>

      <Form
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        vinculo={vinculo}
        titlePage={TITLE_PAGE}
      />
    </TreeView>
  )
}

export default ProgramacaoFeriasAviso
