import React from 'react'

import moment from 'moment'

import { ButtonBox } from 'mio-library-ui'

import { ActionDialog, Button, ToolsDataTable } from '~/components'
import { useFeriasColetiva } from '../Context'

export default function DialogConcedidaProgramada() {
  const { dialogsProps } = useFeriasColetiva()
  const {
    isOpenDialogConcedidaProgramada,
    dataDialogConcedidaProgramada,
    closeDialogConcedidaProgramada,
  } = dialogsProps
  const { data: _data, title } = dataDialogConcedidaProgramada

  const data = _data.map((d) => ({
    ...d,
    dtInicio: d?.dtInicio ? moment(d.dtInicio).format('DD/MM/yyyy') : '',
    dtFim: d?.dtFim ? moment(d.dtFim).format('DD/MM/yyyy') : '',
    dtInicioAbono: d?.dtInicioAbono ? moment(d.dtInicioAbono).format('DD/MM/yyyy') : '',
    dtFimAbono: d?.dtFimAbono ? moment(d.dtFimAbono).format('DD/MM/yyyy') : '',
  }))

  return (
    <ActionDialog
      isOpen={isOpenDialogConcedidaProgramada}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button variant="contained" onClick={closeDialogConcedidaProgramada}>
            Fechar
          </Button>
        </ButtonBox>
      }
      onClose={closeDialogConcedidaProgramada}
      title={title}
    >
      <ToolsDataTable
        data={data}
        pagination={false}
        disableAutoHeight
        columns={[
          {
            name: 'dtInicio',
            label: 'Início Férias',
          },
          {
            name: 'dtFim',
            label: 'Fim Férias',
          },
          {
            name: 'nrDiasFerias',
            label: 'Dias Férias',
          },
          {
            name: 'dtInicioAbono',
            label: 'Início Abono',
          },
          {
            name: 'dtFimAbono',
            label: 'Fim Abono',
          },
          {
            name: 'nrDiasAbono',
            label: 'Dias Abono',
          },
        ]}
      />
    </ActionDialog>
  )
}
