import React, { useState } from 'react'

import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Box, Checkbox, FormControlLabel, Grid, MenuItem } from '@material-ui/core'

import {
  Button,
  ContentDivider,
  CurrencyTextField,
  TextField,
  Checkbox as CheckboxComponent,
  DatePickerNew,
  PageHeader,
} from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import { useStepperContext } from '~/components/StepperForm'

import { IndSimplesValues } from '~/@types/enums/IndSimplesEnum'
import { IndPensaoAlimenticiaValues } from '~/@types/enums/IndPensaoAlimenticiaEnum'
import { IndComissionistaValues } from '~/@types/enums/IndComissionistaEnum'
import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import { MainDataForm } from '..'

const schema = yup.object().shape({
  indSimples: yup.string().required('Informe o Indicador Simples Nacional'),
  indPensaoAlimenticia: yup.string().required('Informe o Indicador Pensão Alimenícia'),
})

export default function FormParametros() {
  const { mainData, onBack, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState<VinculoPartial>(mainData.dataInitialForm)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleBack() {
    onBack()
  }

  function handleSubmit() {
    onNext({
      ...mainData,
      dataInitialForm: data,
    })
  }

  return (
    <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <PageHeader title="Parâmetros" />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <FormControlLabel
          label="Descontar Sindical"
          control={
            <Checkbox
              name="Descontar Sindical"
              color="secondary"
              size="small"
              checked={data?.isDescontarSindical || false}
              onChange={(e) => {
                const isDescontarSindical = e.target.checked
                setData({ ...data, isDescontarSindical })
              }}
            />
          }
        />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <FormControlLabel
          label="Escala Revezamento"
          control={
            <Checkbox
              name="Escala Revezamento"
              color="secondary"
              size="small"
              checked={data?.isEscalaRevezamento || false}
              onChange={(e) => {
                const isEscalaRevezamento = e.target.checked
                setData({ ...data, isEscalaRevezamento })
              }}
            />
          }
        />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <FormControlLabel
          label="Rescisão por tarefa, incluir na Média competência da Rescisão"
          control={
            <Checkbox
              color="secondary"
              size="small"
              checked={data?.isRescisaoMediaVariavel || false}
              onChange={(e) => {
                const isRescisaoMediaVariavel = e.target.checked
                setData({ ...data, isRescisaoMediaVariavel })
              }}
            />
          }
        />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <MUIAutoComplete
          label="Comissionista"
          options={IndComissionistaValues}
          optionId="value"
          renderOption={(option) => option.name}
          value={data.indComissionista}
          onChange={(e, obj) => {
            const indComissionista = obj ? obj.value : ''
            setData({ ...data, indComissionista })
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <FormControlLabel
          label="Usuário Vale Transporte"
          control={
            <Checkbox
              name="Usuário Vale Transporte"
              color="secondary"
              size="small"
              checked={data?.isUsuarioValeTransporte || false}
              onChange={(e) => {
                const isUsuarioValeTransporte = e.target.checked
                setData({ ...data, isUsuarioValeTransporte })
              }}
            />
          }
        />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <FormControlLabel
          label="Etiqueta Cartão de Ponto"
          control={
            <Checkbox
              name="Usuário Vale Transporte"
              color="secondary"
              size="small"
              checked={data?.isEtiquetaCartaoPonto || false}
              onChange={(e) => {
                const isEtiquetaCartaoPonto = e.target.checked
                setData({ ...data, isEtiquetaCartaoPonto })
              }}
            />
          }
        />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <FormControlLabel
          label="Horas de sábado compensadas durante a semana"
          control={
            <Checkbox
              name="Usuário Vale Transporte"
              color="secondary"
              size="small"
              checked={data?.isSabadoCompensado || false}
              onChange={(e) => {
                const isSabadoCompensado = e.target.checked
                setData({ ...data, isSabadoCompensado })
              }}
            />
          }
        />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <FormControlLabel
          label="Expediente no Feriado"
          control={
            <Checkbox
              name="Usuário Vale Transporte"
              color="secondary"
              size="small"
              checked={data?.isExpedienteFeriado || false}
              onChange={(e) => {
                const isExpedienteFeriado = e.target.checked
                setData({ ...data, isExpedienteFeriado })
              }}
            />
          }
        />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <FormControlLabel
          label="Desconto Vale Transporte"
          control={
            <Checkbox
              name="Usuário Vale Transporte"
              color="secondary"
              size="small"
              checked={data?.isDescontoValeTransporte || false}
              onChange={(e) => {
                const isDescontoValeTransporte = e.target.checked
                setData({ ...data, isDescontoValeTransporte })
              }}
            />
          }
        />
      </Grid>

      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
        <CheckboxComponent
          label="Utilizar cálculo simplificado do IR"
          value={data?.isCalcularIRSimplificado || false}
          onChange={(e, value) => {
            const isCalcularIRSimplificado = value
            setData({ ...data, isCalcularIRSimplificado })
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ContentDivider title="Outras informações" />
      </Grid>

      <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
        <CurrencyTextField
          label="Aliq. Adiantamento Salário"
          fullWidth
          value={data.aliqAdiantamentoSalario || ''}
          variant="outlined"
          size="small"
          maximumValue="999"
          onChange={(e, obj) => {
            const aliqAdiantamentoSalario = obj || 0
            setData({ ...data, aliqAdiantamentoSalario })
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
        <CurrencyTextField
          label="Vr. Adiantamento Salário"
          fullWidth
          value={data.vrAdiantamentoSalario || ''}
          variant="outlined"
          size="small"
          onChange={(e, obj) => {
            const vrAdiantamentoSalario = obj || 0
            setData({ ...data, vrAdiantamentoSalario })
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
        <MUIAutoComplete
          label="Indicador Pensão Alimenícia"
          options={IndPensaoAlimenticiaValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="indPensaoAlimenticia"
          value={data.indPensaoAlimenticia}
          onChange={(e, obj) => {
            const indPensaoAlimenticia = obj ? obj.value : ''
            setData({ ...data, indPensaoAlimenticia })
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
        <CurrencyTextField
          label="Vr. Pensão Alimentícia"
          fullWidth
          value={data.vrPensaoAlimenticia || ''}
          variant="outlined"
          size="small"
          onChange={(e, obj) => {
            const vrPensaoAlimenticia = obj || 0
            setData({ ...data, vrPensaoAlimenticia })
          }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <CurrencyTextField
          label="Aliq. Adicional Tempo de Serviço"
          fullWidth
          value={data.aliqAdicionalTempoServico || ''}
          variant="outlined"
          size="small"
          maximumValue="999"
          onChange={(e, obj) => {
            const aliqAdicionalTempoServico = obj || 0
            setData({ ...data, aliqAdicionalTempoServico })
          }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <MUIAutoComplete
          label="Indicador Simples Nacional"
          options={IndSimplesValues}
          renderOption={(option) => option.name}
          optionId="value"
          required
          validationErrors={validationErrors}
          name="indSimples"
          value={data.indSimples}
          onChange={(e, obj) => {
            const indSimples = obj ? obj.value : ''
            setData({ ...data, indSimples })
          }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <DatePickerNew
          label="Data Início da Inatividade"
          size="small"
          value={data?.dtInativo || null}
          onChange={(date) => {
            const dtInativo = date
            setData((oldState) => ({
              ...oldState,
              dtInativo,
            }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ContentDivider title="FGTS/Admissão" />
      </Grid>

      <Grid item lg={4} xs={12}>
        <TextField
          label="Admissão FGTS"
          fullWidth
          value={data?.admissaoFGTS || ''}
          size="small"
          select
          onChange={(e) => {
            const admissaoFGTS = e?.target?.value || ''
            setData((oldState) => ({ ...oldState, admissaoFGTS }))
          }}
        >
          <MenuItem value="1A">1A - Diretor não-empregado Primeiro Emprego</MenuItem>
          <MenuItem value="1B">1B - Diretor não-empregado Reemprego</MenuItem>
          <MenuItem value="1C">
            1C - Diretor não-empregado Oriundo de outro estabelecimento
          </MenuItem>
          <MenuItem value="2A">2A - Trabalhador Rural Primeiro Emprego</MenuItem>
          <MenuItem value="2B">2B - Trabalhador Rural Reemprego</MenuItem>
          <MenuItem value="2C">2C - Trabalhador Rural Oriundo de outro estabelecimento</MenuItem>
          <MenuItem value="3A">3A - Menor aprendiz Primeiro Emprego</MenuItem>
          <MenuItem value="3B">3B - Menor aprendiz Reemprego</MenuItem>
          <MenuItem value="3C">3C - Menor aprendiz Oriundo de outro estabelecimento</MenuItem>
          <MenuItem value="9A">9A - Outros Primeiro Emprego</MenuItem>
          <MenuItem value="9B">9B - Outros Reemprego</MenuItem>
          <MenuItem value="9C">9C - Outros Oriundo de outro estabelecimento</MenuItem>
        </TextField>
      </Grid>

      <Grid item lg={4} xs={12}>
        <TextField
          label="Ocorrência FGTS"
          fullWidth
          value={data?.ocorrenciaFGTS || ''}
          size="small"
          select
          onChange={(e) => {
            const ocorrenciaFGTS = e?.target?.value || ''
            setData((oldState) => ({ ...oldState, ocorrenciaFGTS }))
          }}
        >
          <MenuItem value="00">
            00 - Sem exposição a agente nocivo-trabalhador nunca esteve exposto
          </MenuItem>
          <MenuItem value="01">
            01 - Sem exposição a agente nocivo-trabalhador já esteve exposto
          </MenuItem>
          <MenuItem value="02">
            02 - Exposto a agente nocivo/aposent. especial 15 anos de serviço
          </MenuItem>
          <MenuItem value="03">
            03 - Exposto a agente nocivo/aposent. especial 20 anos de serviço
          </MenuItem>
          <MenuItem value="04">
            04 - Exposto a agente nocivo/aposent. especial 25 anos de serviço
          </MenuItem>
          <MenuItem value="05">05 - Não exposto a agente nocivo-multiplos vínculos</MenuItem>
          <MenuItem value="06">
            06 - Exposto a agente nocivo/aposent. especial 15 anos de serviço-mult. vínculos
          </MenuItem>
          <MenuItem value="07">
            07 - Exposto a agente nocivo/aposent. especial 20 anos de serviço-mult. vínculos
          </MenuItem>
          <MenuItem value="08">
            08 - Exposto a agente nocivo/aposent. especial 25 anos de serviço-mult. vínculos
          </MenuItem>
        </TextField>
      </Grid>

      <Grid item lg={4} xs={12}>
        <TextField
          label="Categoria FGTS"
          fullWidth
          value={data?.categoriaFGTS || ''}
          size="small"
          select
          onChange={(e) => {
            const categoriaFGTS = e?.target?.value || ''
            setData((oldState) => ({ ...oldState, categoriaFGTS }))
          }}
        >
          <MenuItem value="01">01 - Empregado</MenuItem>
          <MenuItem value="02">02 - Trabalhador Avulso</MenuItem>
          <MenuItem value="03">
            03 - Trabalhador não vinculado ao RGPS, mas com direito ao FGTS
          </MenuItem>
          <MenuItem value="04">
            04 - Empregado sob contrato de trabalho por prazo determinado (Lei 9.601,98)
          </MenuItem>
          <MenuItem value="05">
            05 - Contribuinte individual - Diretor não empregado com FGTS (Lei 8.036/90, art 16)
          </MenuItem>
          <MenuItem value="06">06 - Empregado Doméstico</MenuItem>
          <MenuItem value="07">07 - Menor Aprendiz (Lei 10.097/2000)</MenuItem>
          <MenuItem value="11">
            11 - Contribuinte Individual - Diretor não empregado e demais empresários sem FGTS
          </MenuItem>
          <MenuItem value="12">12 - Demais Agentes Públicos</MenuItem>
          <MenuItem value="13">
            13 - Contribuinte Individual - Trab. autônomo ou equiparado, inclusive oper. máquina com
            contribuição sobre remuneração
          </MenuItem>
          <MenuItem value="14">
            14 - Contribuinte Individual - Trab. autônomo ou equiparado, inclusive oper. máquina com
            contribuição sobre salário-base
          </MenuItem>
          <MenuItem value="15">
            15 - Contribuinte Individual - Transportador autônomo com contribuiçào sobre remuneração
          </MenuItem>
          <MenuItem value="16">
            16 - Contribuinte Individual - Transportador autônomo com contribuição sobre
            salário-base
          </MenuItem>
          <MenuItem value="17">
            17 - Contribuinte Individual - Cooperado que presta serviços a empresas contratentes de
            Cooperativa de Trabalho
          </MenuItem>
          <MenuItem value="18">
            18 - Contribuinte Individual - Transportador Cooperado que presta serviços a empresas
            contratantes de Cooperativa de Trabalho
          </MenuItem>
          <MenuItem value="19">19 - Agente Político</MenuItem>
          <MenuItem value="20">
            20 - Servidor Público Ocupante, exclusivamente, de cargo em comissão, Servidor Público
            Ocupante de cargo
          </MenuItem>
          <MenuItem value="21">
            21 - Servidor Público titular de cargo efetivo, magistrado, membro do Ministério Público
            e do Tribunal
          </MenuItem>
          <MenuItem value="22">
            22 - Contrib individ contratado por contrib individ, por prod rural PF, por missao
            diplomática/consulado
          </MenuItem>
          <MenuItem value="23">
            23 - Contribuinte individual transportador autônomo contratado por contrib individual,
            por produtor rural
          </MenuItem>
          <MenuItem value="24">
            24 - Contribuinte individual cooperado que presta serviços à entidade beneficente isenta
            da cota patronal
          </MenuItem>
          <MenuItem value="25">
            25 - Contrib individ transportador cooperado que presta serviços à entidade beneficente
            isenta cota patronal
          </MenuItem>
        </TextField>
      </Grid>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
