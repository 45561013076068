import { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { formatToCPF } from 'brazilian-values'

import { Box, Paper } from '@material-ui/core'

import {
  Button,
  ContainerTable,
  CopyLabel,
  DragDropFile,
  Finder,
  PageHeader,
  StackContainer,
  ToolsDataTable,
} from '~/components'

import TableExames from './TableExames'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useExtrairXML } from '~/hooks/queries/ImportarXMLS2220/useExtrairXML'
import { useImportarXML } from '~/hooks/queries/ImportarXMLS2220/useImportarXML'

import { AtestadoSaudeOcupacional } from '~/hooks/queries/AtestadoSaudeOcupacional/AtestadoSaudeOcupacional'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { ASOTipoExameLabels } from '~/@types/enums/ASOTipoExameEnum'
import { ASOResultadoLabels } from '~/@types/enums/ASOResultadoEnum'

export default function ASOImportar() {
  const [query, setQuery] = useState('')
  const [_data, setData] = useState<AtestadoSaudeOcupacional[]>([])
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])

  const dialogNotification = useDialogNotification()
  const { estabelecimento } = useAmbiente()

  const { mutateAsync: mutateAsyncExtrair, isLoading: isLoadingExtrair } = useExtrairXML()
  const { mutateAsync: mutateAsyncImportar, isLoading: isLoadingImportar } = useImportarXML()

  useEffect(() => {
    setRowsExpanded([])
  }, [_data])

  const data = useMemo(
    () =>
      _data.map((d) => ({
        ...d,
        pessoaFisicaCPF: d?.vinculo?.pessoaFisica?.nrInscricao || '',
        pessoaFisicaNome: d?.vinculo?.pessoaFisica?.nome || '',
        tipoASODesc: ASOTipoExameLabels[d.tipoASO],
        resultadoASODesc: ASOResultadoLabels[d.resultadoASO],
      })),
    [_data],
  )

  async function handleDropped(files: File[]) {
    const formData = new FormData()

    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      formData.append('files', file)
    }

    const _data = await mutateAsyncExtrair({
      data: formData,
      params: {
        estabelecimentoId: estabelecimento.id,
      },
    })
    if (_data.alertas.length > 0) {
      dialogNotification.warning({
        title: 'Ocorreu alguns problemas ao importar o arquivo',
        descriptions: _data.alertas,
      })
    }
    setData(_data.data)
  }

  async function handleImport() {
    await mutateAsyncImportar({
      data: _data,
    })
    setData([])
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'CPF',
      name: 'pessoaFisicaCPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      label: 'Nome',
      name: 'pessoaFisicaNome',
    },
    {
      label: 'Tipo ASO',
      name: 'tipoASODesc',
    },
    {
      label: 'Data ASO',
      name: 'dtASO',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
      },
    },
    {
      label: 'Resultado ASO',
      name: 'resultadoASODesc',
    },
  ]

  return (
    <StackContainer>
      <PageHeader title="Atestado Saúde Ocupacional (ASO) - Importação">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} p={2}>
        <DragDropFile
          onDropped={handleDropped}
          acceptFiles={['.xml']}
          maxFiles={20}
          isLoading={isLoadingExtrair}
        />
      </Box>

      <ContainerTable>
        <ToolsDataTable
          data={data}
          isLoading={isLoadingExtrair}
          columns={columns}
          disableAutoHeight
          optionsExpandable={{
            onRowExpanded: setRowsExpanded,
            rowsExpanded: rowsExpanded,
            type: 'multiple',
            expandedChildren: (index) => (
              <TableExames data={_data[index].atestadoSaudeOcupacionalItens || []} />
            ),
          }}
          sherlock={{
            query,
            columns: ['pessoaFisicaCPF', 'pessoaFisicaNome'],
          }}
        />
      </ContainerTable>

      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={_data.length > 0 ? false : true}
          isLoading={isLoadingImportar}
          onClick={handleImport}
        >
          Importar
        </Button>
      </Box>
    </StackContainer>
  )
}
