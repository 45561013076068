import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import { RP } from './RP'

interface RequestProps {
  vinculoId: string
  anoMes: string
  idf: ReciboIDFEnum
  lotacaoId: string | null
  vinculoIntermitenteId: string | null
}

export function useNovo() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    const { vinculoId, anoMes, idf, lotacaoId, vinculoIntermitenteId } = props

    const response = await api.post<{ data: RP }>('/RP/Novo', null, {
      params: {
        vinculoId,
        anoMes,
        idf,
        lotacaoId,
        vinculoIntermitenteId,
      },
    })
    notification.post()
    return response.data.data
  }

  return useMutation(handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
