import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryUseObterTodosSalarioMinimo } from './useObterTodosSalarioMinimo'

import { SalarioMinimo } from './SalarioMinimo'

export function useAtualizarSalarioMinimo() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(salarioMinimo: SalarioMinimo) {
    await api.put('/SalarioMinimo/' + salarioMinimo.id, salarioMinimo)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.put()
      await queryClient.invalidateQueries(queryUseObterTodosSalarioMinimo)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
