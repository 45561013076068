import React from 'react'

import { Grid, Paper } from '@material-ui/core'

import { LabelValueColor } from '~/components'

import { formatCurrency } from '~/hooks/useUtils'

interface TotalizadorProps {
  vrTotalDesconto: number
  vrTotalLiquido: number
  vrTotalVencimento: number
  vrTotalRecolherFGTS: number
}

export default function Totalizador(props: TotalizadorProps) {
  const { vrTotalDesconto, vrTotalLiquido, vrTotalVencimento, vrTotalRecolherFGTS } = props
  return (
    <Paper>
      <Grid container spacing={1}>
        <Grid item md={3} sm={6} xs={12}>
          <LabelValueColor
            label="Total Vencimento"
            value={formatCurrency(vrTotalVencimento) || '0,00'}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <LabelValueColor
            label="Total Desconto"
            value={formatCurrency(vrTotalDesconto) || '0,00'}
            color="#e57373"
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <LabelValueColor
            label="Total Líquido"
            value={formatCurrency(vrTotalLiquido) || '0,00'}
            color="#2E8BC0"
          />
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <LabelValueColor
            label="Total Recolher FGTS"
            value={formatCurrency(vrTotalRecolherFGTS) || '0,00'}
            color="#e57373"
            showDivider={false}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
