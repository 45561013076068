import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { CondicaoAmbienteTrabalhoSaveDTO } from './CondicaoAmbienteTrabalhoSaveDTO'

export function useCondicaoAmbienteTrabalhoCriar() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: CondicaoAmbienteTrabalhoSaveDTO) {
    await api.post('/CondicaoAmbienteTrabalho/CriarCondicaoAmbienteTrabalho', data)
    notification.post()
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries('CondicaoAmbienteTrabalhoGetByVinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
