import clsx from 'clsx'

import { Box, Paper, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  heightSkeleton: {
    height: '20%',
  },
  skeleton1: {
    width: '80%',
  },
  skeleton2: {
    width: '100%',
  },
  skeleton3: {
    width: '70%',
  },
}))

export default function LoadingForm() {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Box className={classes.content}>
        <Skeleton className={clsx(classes.heightSkeleton, classes.skeleton1)} />
        <Skeleton className={clsx(classes.heightSkeleton, classes.skeleton2)} />
        <Skeleton className={clsx(classes.heightSkeleton, classes.skeleton3)} />
      </Box>
    </Paper>
  )
}
