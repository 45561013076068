export enum CodigoRetornoFGTSEnum {
  retorno_afastamento_temporario_licenca_z1 = 'Z1',
  retorno_afastamento_temporario_motivo_acidente_z2 = 'Z2',
  retorno_afastamento_temporario_decorrencia_acidente_z3 = 'Z3',
  retorno_afastamento_temporario_prestacao_militar_z4 = 'Z4',
  outros_retornos_temporario_z5 = 'Z5',
  retorno_afastamento_temporario_acidente_trabalho_periodo_z6 = 'Z6',
}

export const CodigoRetornoFGTSValues = [
  {
    value: CodigoRetornoFGTSEnum.retorno_afastamento_temporario_licenca_z1,
    name: 'Z1 - Retorno de Afastamento temporário por motivo de Licença-Maternidade',
  },
  {
    value: CodigoRetornoFGTSEnum.retorno_afastamento_temporario_motivo_acidente_z2,
    name: 'Z2 - Retorno de Afastamento temporário por motivo de Acidente de Trabalho',
  },
  {
    value: CodigoRetornoFGTSEnum.retorno_afastamento_temporario_decorrencia_acidente_z3,
    name: 'Z3 - Retorno de Afastamento temporário em decorrência do Acidente de Trabalho',
  },
  {
    value: CodigoRetornoFGTSEnum.retorno_afastamento_temporario_prestacao_militar_z4,
    name: 'Z4 - Retorno de Afastamento temporário por motivo de Prestação do Servico Militar',
  },
  {
    value: CodigoRetornoFGTSEnum.outros_retornos_temporario_z5,
    name: 'Z5 - Outros Retornos de Afastamento temporário ou Licença.',
  },
  {
    value: CodigoRetornoFGTSEnum.retorno_afastamento_temporario_acidente_trabalho_periodo_z6,
    name: 'Z6 - Retorno de afastamento temporário por motivo de acidente do trabalho, por período igual ou inferior',
  },
]
