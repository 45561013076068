import { useState, useEffect, useCallback } from 'react'

import { Grid, MenuItem } from '@material-ui/core'
import { TextField, ContentDivider, Button, ButtonBox } from 'mio-library-ui'

import { DatePicker, ActionDialog, CurrencyTextField } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useUtils from '~/hooks/useUtils'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import { MUIAutoComplete } from '~/components/AutoComplete'
import { indGRRFValues } from '~/values/indGRRFValues'

const schema = yup.object().shape({
  indGRRF: yup.string().required('Informe o Indicador GRRF'),
  indAvisoPrevio: yup.string().required('Informe o Aviso Prévio'),
  dtRecolhimento: yup.string().required('Informe a Data de Recolhimento').nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { openDownloadData } = useUtils()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/VinculoGRRF/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
        gerarGRRF(response.data.data.id)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/VinculoGRRF', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
        gerarGRRF(response.data.data.id)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const gerarGRRF = async (id) => {
      setSubmitting(true)
      try {
        const response = await api.get('VinculoGRRF/GerarArquivo', {
          params: {
            vinculoGRRFId: id,
            dtPagamento: data.dtRecolhimento,
          },
        })
        if (response.data) {
          openDownloadData('GRRF.RE', response.data)
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const calcGRRF = useCallback(async () => {
    setLoading(true)
    try {
      const newVrSaldoFGTS = data.vrSaldoFGTS <= 999999999999 ? data.vrSaldoFGTS : 0
      const response = await api.get('VinculoGRRF/CalcularGRRF', {
        params: {
          rpId: data.rpId,
          vrSaldoFGTS: newVrSaldoFGTS,
        },
      })

      delete response.data.data.id

      setData((oldState) => ({
        ...oldState,
        ...response.data.data,
      }))
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    //eslint-disable-next-line
  }, [data])

  return (
    <ActionDialog
      title="Cadastro de GRRF"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar e Gerar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={3} lg={3} md={3} sm={4} xs={6} item>
          <CurrencyTextField
            label="Saldo Rescisório - CEF"
            fullWidth
            value={data?.vrSaldoFGTS || null}
            onChange={(e, value) => {
              const vrSaldoFGTS = value || null
              setData((oldState) => ({
                ...oldState,
                vrSaldoFGTS,
              }))
            }}
          />
        </Grid>

        <Grid xl={9} lg={9} md={9} sm={8} xs={6} item>
          <ButtonBox>
            <Button
              isLoading={isLoading}
              color="primary"
              size="small"
              variant="contained"
              onClick={calcGRRF}
            >
              Calcular GRRF
            </Button>
          </ButtonBox>
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ContentDivider title="Informação de Remuneração" />
        </Grid>

        <Grid xl={2} lg={2} md={2} sm={4} xs={6} item>
          <CurrencyTextField
            label="Anterior à Rescisão"
            fullWidth
            value={data?.vrBaseMesAnterior || 0}
            onChange={(e, value) => {
              const vrBaseMesAnterior = value || 0
              setData((oldState) => ({
                ...oldState,
                vrBaseMesAnterior,
              }))
            }}
          />
        </Grid>

        <Grid xl={2} lg={2} md={2} sm={4} xs={6} item>
          <CurrencyTextField
            label="Mês de Rescisão"
            fullWidth
            value={data?.vrBaseMes || 0}
            onChange={(e, value) => {
              const vrBaseMes = value || 0
              setData((oldState) => ({
                ...oldState,
                vrBaseMes,
              }))
            }}
          />
        </Grid>

        <Grid xl={2} lg={2} md={2} sm={4} xs={6} item>
          <CurrencyTextField
            label="Aviso Indenizado"
            fullWidth
            value={data?.vrBaseAvisoPrevio || 0}
            onChange={(e, value) => {
              const vrBaseAvisoPrevio = value || 0
              setData((oldState) => ({
                ...oldState,
                vrBaseAvisoPrevio,
              }))
            }}
          />
        </Grid>

        <Grid xl={3} lg={3} md={3} sm={6} xs={6} item>
          <CurrencyTextField
            label="Saldo Rescisório"
            fullWidth
            value={data?.vrSaldoRescisorio || 0}
            onChange={(e, value) => {
              const vrSaldoRescisorio = value || 0
              setData((oldState) => ({
                ...oldState,
                vrSaldoRescisorio,
              }))
            }}
          />
        </Grid>

        <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
          <CurrencyTextField
            label="Somatório"
            fullWidth
            value={data?.somatorio || 0}
            onChange={(e, value) => {
              const somatorio = value || 0
              setData((oldState) => ({
                ...oldState,
                somatorio,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ContentDivider title="Valores a Recolher" />
        </Grid>

        <Grid xl={2} lg={2} md={2} sm={4} xs={6} item>
          <CurrencyTextField
            label="Anterior à Rescisão"
            fullWidth
            value={data?.vrFGTSMesAnterior || 0}
            onChange={(e, value) => {
              const vrFGTSMesAnterior = value || 0
              setData((oldState) => ({
                ...oldState,
                vrFGTSMesAnterior,
              }))
            }}
          />
        </Grid>

        <Grid xl={2} lg={2} md={2} sm={4} xs={6} item>
          <CurrencyTextField
            label="Mês de Rescisão"
            fullWidth
            value={data?.vrFGTSMes || 0}
            onChange={(e, value) => {
              const vrFGTSMes = value || 0
              setData((oldState) => ({
                ...oldState,
                vrFGTSMes,
              }))
            }}
          />
        </Grid>

        <Grid xl={2} lg={2} md={2} sm={4} xs={6} item>
          <CurrencyTextField
            label="Aviso Indenizado"
            fullWidth
            value={data?.vrFGTSAvisoPrevio || 0}
            onChange={(e, value) => {
              const vrFGTSAvisoPrevio = value || 0
              setData((oldState) => ({
                ...oldState,
                vrFGTSAvisoPrevio,
              }))
            }}
          />
        </Grid>

        <Grid xl={3} lg={3} md={3} sm={6} xs={6} item>
          <CurrencyTextField
            label="Multa Rescisória"
            fullWidth
            value={data?.vrMultaRescisoria || 0}
            onChange={(e, value) => {
              const vrMultaRescisoria = value || 0
              setData((oldState) => ({
                ...oldState,
                vrMultaRescisoria,
              }))
            }}
          />
        </Grid>

        <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
          <CurrencyTextField
            label="Total a Recolher"
            fullWidth
            value={data?.vrRecolher || 0}
            onChange={(e, value) => {
              const vrRecolher = value || 0
              setData((oldState) => ({
                ...oldState,
                vrRecolher,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ContentDivider />
        </Grid>

        <Grid sm={6} xs={12} item>
          <MUIAutoComplete
            label="Indicador GRRF"
            options={indGRRFValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indGRRF"
            value={data.indGRRF}
            onChange={(e, obj) => {
              const indGRRF = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                indGRRF,
              }))
            }}
          />
        </Grid>

        <Grid sm={6} xs={12} item>
          <TextField
            label="Aviso Prévio"
            variant="outlined"
            size="small"
            fullWidth
            select
            required
            validationErrors={validationErrors}
            name="indAvisoPrevio"
            value={data?.indAvisoPrevio || ''}
            onChange={(e) => {
              const indAvisoPrevio = e.target.value
              setData((oldState) => ({
                ...oldState,
                indAvisoPrevio,
              }))
            }}
          >
            <MenuItem value={1}>Trabalhado</MenuItem>
            <MenuItem value={2}>Indenizado</MenuItem>
            <MenuItem value={3}>Ausência/Dispensa</MenuItem>
          </TextField>
        </Grid>

        <Grid sm={6} xs={12} item>
          <DatePicker
            label="Data de Rescisão"
            size="small"
            disabled
            value={data?.dtRescisao || null}
          />
        </Grid>

        <Grid sm={6} xs={12} item>
          <DatePicker
            label="Data de Recolhimento"
            size="small"
            required
            validationErrors={validationErrors}
            name="dtRecolhimento"
            value={data?.dtRecolhimento || null}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(date) => {
              const dtRecolhimento = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtRecolhimento,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
