import React, { useState } from 'react'

import moment from 'moment'
import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid, Tooltip } from '@material-ui/core'

import { Button, ContentDivider, DatePickerNew } from '~/components'
import { AutoCompleteMedico, MUIAutoComplete } from '~/components/AutoComplete'

import { useStepperContext } from '~/components/StepperForm'
import useValidationErrors from '~/hooks/useValidationErrors'

import { ASOTipoExameValues } from '~/@types/enums/ASOTipoExameEnum'
import { ASOResultadoValues } from '~/@types/enums/ASOResultadoEnum'

import { MainDataForm } from '..'

const schema = yup.object().shape({
  tipoASO: yup.string().required('Informe o Tipo ASO'),
  dtASO: yup
    .date()
    .required('Informe a Data ASO')
    .typeError('Informe uma Data Valída')
    .test(
      'DtAcidente-validator',
      `A Data ASO deve ser igual ou anterior à data atual: ${moment().format('DD/MM/yyyy')} `,
      (dtASO) => moment(dtASO).isSameOrBefore(moment()),
    )
    // .when(['$dtAdmissao', 'tipoASO'], (dtAdmissao, tipoASO, schema) => {
    //   const dtAdmissaoFormatted = moment(dtAdmissao).format('DD/MM/yyyy')
    //   const parsedTipoASO = parseInt(tipoASO)

    //   if (parsedTipoASO !== 0) {
    //     return schema.test(
    //       'dtASO-validator',
    //       `A Data ASO deve ser igual ou posterior a Data de Adimissão: ${dtAdmissaoFormatted} `,
    //       (dtASO: string) => {
    //         return moment(dtASO).isSameOrAfter(dtAdmissao)
    //       },
    //     )
    //   }

    //   return schema
    // })
    .nullable(),
  resultadoASO: yup.string().required('Informe o Resultado ASO'),
  medicoId: yup.string().required('Informe Médico'),
})
export default function FormMain() {
  const { mainData, onCancel, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataMainForm)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    onNext({
      ...mainData,
      dataMainForm: data,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <MUIAutoComplete
          label="Tipo do exame médico ocupacional"
          name="tipoASO"
          required
          validationErrors={validationErrors}
          value={data.tipoASO}
          options={ASOTipoExameValues}
          optionId="value"
          renderOption={(option) => option.name}
          onChange={(e, obj) => {
            const tipoASO = obj ? obj.value : ''
            setData((oldState) => ({
              ...oldState,
              tipoASO,
            }))
          }}
        />
      </Grid>

      <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
        <DatePickerNew
          name="dtASO"
          label="Data ASO"
          size="small"
          required
          validationErrors={validationErrors}
          value={data?.dtASO || null}
          onChange={(date) => {
            const dtASO = date || undefined
            setData((oldState) => ({
              ...oldState,
              dtASO,
            }))
          }}
        />
      </Grid>

      <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
        <MUIAutoComplete
          label="Resultado ASO"
          name="resultadoASO"
          required
          validationErrors={validationErrors}
          value={data.resultadoASO}
          options={ASOResultadoValues}
          optionId="value"
          renderOption={(option) => option.name}
          onChange={(e, obj) => {
            const resultadoASO = obj ? obj.value : ''
            setData((oldState) => ({
              ...oldState,
              resultadoASO,
            }))
          }}
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <Tooltip
          arrow
          placement="bottom-start"
          title="Grupo que detalha as avaliações clínicas e os exames
            complementares porventura realizados pelo trabalhador
            em virtude do determinado nos Anexos da NR-07, além de
            outros solicitados pelo médico e os referentes ao ASO."
        >
          <Box>
            <ContentDivider title="Detalhes de avaliações clínicas e exames" />
          </Box>
        </Tooltip>
      </Grid>

      <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
        <AutoCompleteMedico
          name="medicoId"
          required
          validationErrors={validationErrors}
          value={data?.medicoId || ''}
          onChange={(e, medico) => {
            const medicoId = medico?.id || ''
            setData((oldState) => ({
              ...oldState,
              medicoId,
            }))
          }}
          optionId="id"
        />
      </Grid>

      <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
        <AutoCompleteMedico
          label="Médico PCMSO"
          value={data?.medicoPCMSOId || ''}
          onChange={(e, medicoPCMSO) => {
            const medicoPCMSOId = medicoPCMSO?.id || ''
            setData((oldState) => ({
              ...oldState,
              medicoPCMSOId,
            }))
          }}
          optionId="id"
        />
      </Grid>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
