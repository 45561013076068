import { Box, IconButton, Tooltip } from '@material-ui/core'
import { Cached } from '@material-ui/icons'

interface AlternateButtonProps {
  isActive: boolean
  show: boolean
  onClick: () => void
}

export default function AlternateButton({ isActive, show, onClick }: AlternateButtonProps) {
  return (
    <Box position="absolute" right={0} top={0} display={show || isActive ? 'initial' : 'none'}>
      <Tooltip
        arrow
        title={
          isActive
            ? 'Mostrar informações relativas a empresa do ambiente'
            : 'Mostrar informações de todas as empresas'
        }
      >
        <IconButton size="small" onClick={() => onClick()}>
          <Box
            color={isActive ? 'common.white' : 'primary.main'}
            borderRadius="50%"
            display="flex"
            alignItems="center"
            padding={0.5}
            bgcolor={isActive ? 'primary.main' : 'common.white'}
            sx={{
              '&:hover': {
                transition: '0.5s',
                opacity: 0.6,
              },
            }}
          >
            <Cached />
          </Box>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
