import React from 'react'

import { HeightDataTable } from '~/components'

import moment from 'moment'
import { formatToCPFOrCNPJ } from 'brazilian-values'

export default function Table(props) {
  const { data: _data, isLoading, query } = props

  const data = _data.map((dt) => ({
    ...dt,
    vinculoNrInscricao: dt?.vinculoNrInscricao ? formatToCPFOrCNPJ(dt.vinculoNrInscricao) : '',
    dtRealizacaoEvento: dt?.dtRealizacaoEvento
      ? moment(dt.dtRealizacaoEvento).format('DD/MM/yyyy')
      : '',
    dtReciboESocial: dt?.dtReciboESocial
      ? moment(dt.dtReciboESocial).format('DD/MM/yyyy')
      : 'Evento não foi enviado para o eSocial',
    nrReciboESocial: dt?.nrReciboESocial
      ? dt.nrReciboESocial
      : 'Evento não foi enviado para o eSocial',
  }))

  const columns = [
    {
      name: 'vinculoNrInscricao',
      label: 'CPF',
    },
    {
      name: 'vinculoNome',
      label: 'Nome',
    },
    {
      name: 'dtRealizacaoEvento',
      label: 'Data do Evento',
    },
    {
      name: 'nrReciboESocial',
      label: 'Número do recibo eSocial',
    },
    {
      name: 'dtReciboESocial',
      label: 'Data do recibo eSocial',
    },
  ]

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['vinculoNrInscricao', 'vinculoNome', 'dtRealizacaoEvento', 'dtReciboESocial'],
      }}
    />
  )
}
