import { convertToArrayDescription } from '~/utils/utils'

export enum TipoAlteracaoEnum {
  Formalizado_Alteracao_Admissao,
  Formalizado_Alteracao_Rescisao,
  Formalizado_Alteracao_Admissao_Rescisao,
}

export const TipoAlteracaoLabels = {
  [TipoAlteracaoEnum.Formalizado_Alteracao_Admissao]:
    'Trabalhador com vínculo formalizado, com alteração na data de admissão',
  [TipoAlteracaoEnum.Formalizado_Alteracao_Rescisao]:
    'Trabalhador com vínculo formalizado, com inclusão ou alteração de data de desligamento',
  [TipoAlteracaoEnum.Formalizado_Alteracao_Admissao_Rescisao]:
    'Trabalhador com vínculo formalizado, com alteração nas datas de admissão e de desligamento',
}

export const TipoAlteracaoValues = convertToArrayDescription(TipoAlteracaoEnum, TipoAlteracaoLabels)
