import React, { useState } from 'react'

import { Grid, IconButton, Box, useTheme } from '@material-ui/core'
import { Done as DoneIcon, Close as CloseIcon } from '@material-ui/icons'

import { CurrencyTextField } from '~/components'
import AutoCompleteEventoEmpregador from '~/components/AutoComplete/AutoCompleteEventoEmpregador'

import useDialogNotification from '~/hooks/useDialogNotification'

import { RescisaoComplementarEventoDTO } from '~/hooks/queries/RescisaoComplementar/RescisaoComplementarDTO'

const initialData: RescisaoComplementarEventoDTO = {
  id: '',
  eventoCodigo: 0,
  eventoNome: '',
  referencia: 0,
  vrVencimento: 0,
  vrVencimentoAjustado: 0,
  vrDiferencaAPagar: 0,
}

interface FormEventoProps {
  onChange: (value: RescisaoComplementarEventoDTO) => void
  disabled: boolean
}

export default function FormEvento(props: FormEventoProps) {
  const { onChange, disabled } = props

  const [data, setData] = useState<RescisaoComplementarEventoDTO>(initialData)

  const dialogNotification = useDialogNotification()
  const theme = useTheme()

  function handleCancel() {
    setData(initialData)
  }

  function handleSubmit() {
    if (!data.eventoCodigo) {
      return dialogNotification.warning({
        descriptions: ['Informe o Evento'],
      })
    }

    const dtValdiate = data as RescisaoComplementarEventoDTO
    onChange(dtValdiate)
    setData(initialData)
  }

  return (
    <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={8}>
          <AutoCompleteEventoEmpregador
            value={data.eventoCodigo || null}
            onChange={(evento) => {
              const eventoCodigo = evento?.codigo || 0
              const eventoNome = evento?.nome || ''
              setData({
                ...initialData,
                eventoCodigo,
                eventoNome,
              })
            }}
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <CurrencyTextField
            label="Referência"
            value={data.referencia}
            onChange={(e, value) => {
              const referencia = value
              setData((prev) => ({ ...prev, referencia }))
            }}
            InputLabelProps={{ shrink: true }}
            maximumValue="999999999999"
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <CurrencyTextField
            label="Valor"
            value={data?.vrVencimento}
            onChange={(e, value) => {
              const vrVencimento = value
              const vrVencimentoAjustado = 0
              const vrDiferencaAPagar = value
              setData((prev) => ({
                ...prev,
                vrVencimento,
                vrVencimentoAjustado,
                vrDiferencaAPagar,
              }))
            }}
            InputLabelProps={{ shrink: true }}
            maximumValue="999999999999"
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Box display="flex" gridGap={theme.spacing(1)} alignItems="center">
        <IconButton size="small" onClick={handleCancel} disabled={disabled}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={handleSubmit} disabled={disabled}>
          <DoneIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  )
}
