import React, { useState, useEffect } from 'react'

import PropType from 'prop-types'

import { makeStyles } from '@material-ui/core'

import MaskedTextField from '../MaskedTextField'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: (props) => theme.spacing(props?.top || 0),
    marginRight: (props) => theme.spacing(props?.right || 0),
    marginBottom: (props) => theme.spacing(props?.bottom || 0),
    marginLeft: (props) => theme.spacing(props?.left || 0),
  },
}))

const AnoMesTextField = (props) => {
  const { ...rest } = props
  const [mask, setMask] = useState('9999/99')

  const classes = useStyles(props)

  useEffect(() => {
    definirMascara(rest.value)
  }, [rest.value])

  const definirMascara = () => {
    setMask('9999/99')
  }

  return (
    <React.Fragment>
      <MaskedTextField
        className={classes.root}
        mask={mask}
        placeholder="YYYY/MM"
        fullWidth
        variant="outlined"
        size="small"
        {...rest}
      />
    </React.Fragment>
  )
}

AnoMesTextField.propTypes = {
  required: PropType.bool,
  label: PropType.string,
  name: PropType.string,
  validationErrors: PropType.any,
  value: PropType.any,
  InputLabelProps: PropType.any,
  onChange: PropType.func,
}

export default AnoMesTextField
