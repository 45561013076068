import React from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { ToolsDataTable } from '~/components'

import { VinculoDTO } from '~/hooks/queries/Vinculo/VinculoDTO'
interface TableProps {
  data: VinculoDTO[]
  isLoading: boolean
  query: string
  onRowSelected: (indexs: number[]) => void
  rowsSelected: number[]
}

export default function Table(props: TableProps) {
  const { data: _data, isLoading, query, onRowSelected, rowsSelected } = props

  const data = _data.map((obj) => ({
    ...obj,
    nrInscricao: obj?.nrInscricao ? formatToCPFOrCNPJ(obj.nrInscricao) : '',
  }))

  const columns = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      columns={columns}
      sherlock={{
        query,
        columns: ['codigo', 'nrInscricao', 'nome'],
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected,
        rowsSelected,
        selectOnClick: true,
      }}
    />
  )
}
