import { useState } from 'react'

import { CircularProgress } from '@material-ui/core'
import {
  Button,
  ButtonBox,
  ContentDivider,
  ContentFrame,
  DatePickerNew,
  Fieldset,
  Stack,
} from '~/components'
import {
  AutoCompleteProcessoTrabalhista,
  AutoCompleteProcessoTrabalhistaVinculo,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import { useObterInformacoesVinculo } from '~/hooks/queries/ProcessoTrabalhistaManutencaoVinculo/useObterInformacoesVinculo'
import { useProcessarManutencaoAdmissao } from '~/hooks/queries/ProcessoTrabalhistaManutencaoVinculo/useProcessarManutencaoAdmissao'

import * as yup from 'yup'

import { ProcessoTrabalhistaManutencaoVinculoInformacoesDTO } from '~/hooks/queries/ProcessoTrabalhistaManutencaoVinculo/dtos/ProcessoTrabalhistaManutencaoVinculoInformacoesDTO'
import { IndAdmissaoEnum, IndAdmissaoValues } from '~/@types/enums/IndAdmissaoEnum'
import { TipoAlteracaoEnum, TipoAlteracaoValues } from '~/@types/enums/TipoAlteracaoEnum'
import {
  IndMotivoDesligamentoEnum,
  IndMotivoDesligamentoValues,
} from '~/@types/enums/IndMotivoDesligamentoEnum'

const schema = yup.object().shape({
  processoTrabalhistaId: yup.string().required('Informe o Processo Trabalhista'),
  processoTrabalhistaVinculoId: yup.string().required('Informe o Processo Trabalhista Funcionário'),
  tipoAlteracao: yup.number().required('Informe o Tipo de Alteração'),

  dtAdmissao: yup
    .date()
    .typeError('Informe uma data válida')
    .nullable()
    .when(['tipoAlteracao'], (tipoAlteracao: TipoAlteracaoEnum, schema) => {
      if (tipoAlteracao !== TipoAlteracaoEnum.Formalizado_Alteracao_Rescisao) {
        return schema.required('Informe a Data de Admissão')
      }
      return schema
    }),
  indAdmissao: yup.string().when(['tipoAlteracao'], (tipoAlteracao: TipoAlteracaoEnum, schema) => {
    if (tipoAlteracao !== TipoAlteracaoEnum.Formalizado_Alteracao_Rescisao) {
      return schema.required('Informe o Indicativo de Admissão')
    }
    return schema
  }),
  dtRescisao: yup
    .date()
    .typeError('Informe uma data válida')
    .nullable()
    .when(['tipoAlteracao'], (tipoAlteracao: TipoAlteracaoEnum, schema) => {
      if (tipoAlteracao !== TipoAlteracaoEnum.Formalizado_Alteracao_Admissao) {
        return schema.required('Informe a Data de Rescisão')
      }
      return schema
    }),
  motivoDesligamento: yup
    .mixed()
    .when(['tipoAlteracao'], (tipoAlteracao: TipoAlteracaoEnum, schema) => {
      if (tipoAlteracao !== TipoAlteracaoEnum.Formalizado_Alteracao_Admissao) {
        return schema
          .required('Informe o Motivo de Desligamento')
          .oneOf(Object.values(IndMotivoDesligamentoEnum), 'Informe o Motivo de Desligamento')
          .test(
            'validate',
            'Informe o Motivo de Desligamento',
            (value: IndMotivoDesligamentoEnum) =>
              value !== IndMotivoDesligamentoEnum.NaoAplicavel_99,
          )
      }
      return schema
    }),
})

interface DataType extends ProcessoTrabalhistaManutencaoVinculoInformacoesDTO {
  processoTrabalhistaId: string
  tipoAlteracao: TipoAlteracaoEnum
}

export default function ManutencaoAdmissao() {
  const [data, setData] = useState<DataType>({} as DataType)

  const { anoMes } = useAmbiente()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const {
    data: dataInfoVinculo,
    isLoading: _isLoading,
    isFetching: _isFetching,
  } = useObterInformacoesVinculo({
    params: {
      processoTrabalhistaVinculoId: data.processoTrabalhistaVinculoId,
    },
  })
  const isLoading = _isLoading || _isFetching
  const hasData = dataInfoVinculo ? true : false

  const { mutateAsync, isLoading: isSubmitting } = useProcessarManutencaoAdmissao({
    params: {
      anoMes,
    },
  })

  function handleReset() {
    setData({} as DataType)
  }

  async function handleSubmit() {
    await mutateAsync(data)
    handleReset()
  }

  return (
    <Stack>
      <AutoCompleteProcessoTrabalhista
        name="processoTrabalhistaId"
        validationErrors={validationErrors}
        required
        value={data.processoTrabalhistaId}
        onChange={(d) => {
          const processoTrabalhistaId = d?.id || ''
          setData({
            processoTrabalhistaId,
          } as DataType)
        }}
      />

      <AutoCompleteProcessoTrabalhistaVinculo
        name="processoTrabalhistaVinculoId"
        validationErrors={validationErrors}
        required
        processoTrabalhistaId={data.processoTrabalhistaId}
        value={data.processoTrabalhistaVinculoId}
        onChange={(d) => {
          const processoTrabalhistaVinculoId = d?.id || ''
          setData({
            processoTrabalhistaId: data.processoTrabalhistaId,
            processoTrabalhistaVinculoId,
          } as DataType)
        }}
      />

      <MUIAutoComplete
        label="Tipo de Alteração"
        name="tipoAlteracao"
        validationErrors={validationErrors}
        required
        options={TipoAlteracaoValues}
        renderOption={(opt) => opt.name}
        optionId="value"
        value={data.tipoAlteracao}
        onChange={(_, obj) => {
          const tipoAlteracao = obj?.value
          let indAdmissao = dataInfoVinculo?.indAdmissao || IndAdmissaoEnum.Normal_1
          if (tipoAlteracao !== TipoAlteracaoEnum.Formalizado_Alteracao_Rescisao) {
            indAdmissao = IndAdmissaoEnum.DecorrenteDecisaoJudicial_3
          }
          setData((prev) => ({
            ...prev,
            ...dataInfoVinculo,
            indAdmissao,
            tipoAlteracao,
          }))
        }}
      />
      <ContentFrame>
        <Fieldset disabled={hasData ? false : true}>
          <Stack>
            <ContentDivider
              title="Funcionário"
              showDivider={false}
              renderRight={<ButtonBox>{isLoading && <CircularProgress size={20} />}</ButtonBox>}
            />

            <DatePickerNew
              label="Data de Admissão"
              value={data.dtAdmissao || null}
              name="dtAdmissao"
              validationErrors={validationErrors}
              disabled={data.tipoAlteracao === TipoAlteracaoEnum.Formalizado_Alteracao_Rescisao}
              required={data.tipoAlteracao !== TipoAlteracaoEnum.Formalizado_Alteracao_Rescisao}
              onChange={(date) => {
                const dtAdmissao = date || ''
                setData((prev) => ({
                  ...prev,
                  dtAdmissao,
                }))
              }}
            />

            <MUIAutoComplete
              label="Indicativo de Admissão"
              options={IndAdmissaoValues}
              renderOption={(opt) => opt.name}
              name="indAdmissao"
              validationErrors={validationErrors}
              // disabled={data.tipoAlteracao === TipoAlteracaoEnum.Formalizado_Alteracao_Rescisao}
              disabled
              required={data.tipoAlteracao !== TipoAlteracaoEnum.Formalizado_Alteracao_Rescisao}
              optionId="value"
              value={data.indAdmissao}
              // onChange={(_, obj) => {
              //   setData((prev) => ({
              //     ...prev,
              //     indAdmissao: obj?.value,
              //   }))
              // }}
            />

            <DatePickerNew
              label="Data de Rescisão"
              value={data.dtRescisao || null}
              name="dtRescisao"
              validationErrors={validationErrors}
              disabled={data.tipoAlteracao === TipoAlteracaoEnum.Formalizado_Alteracao_Admissao}
              required={data.tipoAlteracao !== TipoAlteracaoEnum.Formalizado_Alteracao_Admissao}
              onChange={(date) => {
                const dtRescisao = date || ''
                setData((prev) => ({
                  ...prev,
                  dtRescisao,
                }))
              }}
            />

            <MUIAutoComplete
              label="Motivo do Desligamento"
              value={data.motivoDesligamento}
              options={IndMotivoDesligamentoValues}
              name="motivoDesligamento"
              validationErrors={validationErrors}
              renderOption={(option) => option.name}
              disabled={data.tipoAlteracao === TipoAlteracaoEnum.Formalizado_Alteracao_Admissao}
              required={data.tipoAlteracao !== TipoAlteracaoEnum.Formalizado_Alteracao_Admissao}
              optionId="value"
              onChange={(_, obj) => {
                const motivoDesligamento = obj?.value || ''
                setData((prev) => ({
                  ...prev,
                  motivoDesligamento,
                }))
              }}
            />
          </Stack>
        </Fieldset>
      </ContentFrame>

      <ButtonBox>
        <Button onClick={handleReset}>Cancelar</Button>
        <Button variant="contained" onClick={handleValidate} isLoading={isSubmitting}>
          Salvar
        </Button>
      </ButtonBox>
    </Stack>
  )
}
