import React, { useState } from 'react'

import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid, useTheme } from '@material-ui/core'

import { Button, TextField, ActionDialog, DatePickerNew } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import FormItem from './components/FormItem'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

import { VinculoHorarioPartial } from '~/hooks/queries/VinculoHorario/VinculoHorario'
import { VinculoHorarioItemPartial } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'

const schema = yup.object().shape({
  dtIncio: yup.date().required('Informe a Data de Início').typeError('Informe uma data válida'),
})

interface FormHorarioTrabalhoProps {
  isOpen: boolean
  indexCurrent: number
  data: VinculoHorarioPartial
  onClose: () => void
  onAfterSubmitForm: (
    event: 'update' | 'insert',
    data: VinculoHorarioPartial,
    index: number,
  ) => void
}

export default function FormHorarioTrabalho(props: FormHorarioTrabalhoProps) {
  const { isOpen, onClose, indexCurrent, data: _data, onAfterSubmitForm } = props

  const [data, setData] = useState(_data)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const theme = useTheme()

  const {
    close: closeFormItem,
    data: dataFormItem,
    isOpen: isOpenFormItem,
    open: openFormItem,
  } = useDialog<string | undefined>('')

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<number | null>(null)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleClickItem = (rowIndex: number) => {
    openConfirmDelete(rowIndex)
  }

  const handleDeleteItem = () => {
    setData((prev) => {
      const itens = prev.vinculoHorarioItens || []
      return {
        ...prev,
        vinculoHorarioItens: itens.filter((i, index) => index !== dataConfirmDelete),
      }
    })
    closeConfirmDelete()
    notification.remove()
  }

  const handleAfterSubmitFormItem = (dataItens: VinculoHorarioItemPartial[]) => {
    const vinculoHorarioItens = [...dataItens]

    const itens = data.vinculoHorarioItens || []

    itens.forEach((d) => {
      if (!vinculoHorarioItens.some((x) => x.diaHorario === d.diaHorario)) {
        vinculoHorarioItens.push(d)
      }
    })

    setData((prev) => ({
      ...prev,
      vinculoHorarioItens,
    }))
    closeFormItem()
  }

  function handleSubmit() {
    const vinculosHorariosItens = data?.vinculoHorarioItens || []
    if (!(vinculosHorariosItens.length > 0))
      return dialogNotification.warning({ descriptions: ['Informe pelo menos um Horário'] })

    if (indexCurrent >= 0) {
      onAfterSubmitForm('update', data, indexCurrent)
      notification.put()
    } else {
      onAfterSubmitForm('insert', data, indexCurrent)
      notification.post()
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Horário de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
      }}
    >
      <Box height="calc(100vh - 25rem)">
        <Grid container spacing={2} style={{ marginBottom: theme.spacing(1) }}>
          <Grid item xl={2} lg={2} md={3} sm={3} xs={12}>
            <DatePickerNew
              label="Data de início"
              size="small"
              value={data?.dtIncio || null}
              validationErrors={validationErrors}
              name="dtIncio"
              required
              onChange={(date) => {
                const dtIncio = date || undefined
                setData({ ...data, dtIncio })
              }}
              disabled={data?.id ? true : false}
            />
          </Grid>

          <Grid item xl={10} lg={10} md={9} sm={9} xs={12}>
            <TextField
              label="Observação"
              fullWidth
              value={data?.observacao || ''}
              size="small"
              inputProps={{
                maxLength: 200,
              }}
              multiline
              onChange={(e) => {
                const observacao = e?.target.value || ''
                setData({ ...data, observacao })
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ButtonBox top={1}>
              <Button onClick={() => openFormItem(data?.id)} variant="contained">
                Adicionar Horários
              </Button>
            </ButtonBox>
          </Grid>
        </Grid>

        <Table data={data.vinculoHorarioItens || []} onItemClick={handleClickItem} />

        {isOpenFormItem && (
          <FormItem
            isOpen={isOpenFormItem}
            vinculoHorarioId={dataFormItem}
            onClose={closeFormItem}
            onAfterSubmitForm={handleAfterSubmitFormItem}
          />
        )}

        {isOpenConfirmDelete && (
          <ConfirmDeleteDialog
            isOpen={isOpenConfirmDelete}
            onCancel={closeConfirmDelete}
            onConfirm={handleDeleteItem}
          />
        )}
      </Box>
    </ActionDialog>
  )
}
