const VinculoComContaCorrente_1 = 1
const VinculoSemContaCorrente_2 = 2
const TodosVinculos_3 = 3

export const tipoProcessamentoRelacaoBancariaValues = [
  { value: VinculoComContaCorrente_1, name: '1 - Com conta corrente' },
  { value: VinculoSemContaCorrente_2, name: '2 - Sem conta Corrente' },
  { value: TodosVinculos_3, name: '3 - Todos' },
]

export const tipoProcessamentoRelacaoBancariaConsts = {
  VinculoComContaCorrente_1,
  VinculoSemContaCorrente_2,
  TodosVinculos_3,
}
