import { Box, IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'

import { ButtonBox, ConfirmDeleteDialog, CopyLabel, ToolsDataTable } from '~/components'

import FormAtualizar from './FormAtualizar'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { formatCurrency } from '~/hooks/useUtils'
import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import { useValeTransporteApuracaoRemover } from '~/hooks/queries/ValeTransporteApuracao/useValeTransporteApuracaoRemover'
import useObterApuracaoEstabelecimento from '~/hooks/queries/ValeTransporteApuracao/useObterApuracaoEstabelecimento'

import { ValeTransporteApuracaoUpdateDTO } from '~/hooks/queries/ValeTransporteApuracao/ValeTransporteApuracaoDTO'

interface TableProps {
  estabelecimentoId: string
}

export default function TableApurados({ estabelecimentoId }: TableProps) {
  const {
    close: closeFormAtualizar,
    data: dataFormAtualizar,
    isOpen: isOpenFormAtualizar,
    open: openFormAtualizar,
  } = useDialog<{ data: ValeTransporteApuracaoUpdateDTO; subtitle: string } | null>(null)

  const { anoMes } = useAmbiente()
  const { mutateAsync, isLoading: isDeleting } = useValeTransporteApuracaoRemover()
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterApuracaoEstabelecimento({ estabelecimentoId, competencia: anoMes })
  const data = _data || []

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  function handleClickEdit(idCurrent: string) {
    const dataFinded = data.find((d) => d.id === idCurrent)
    if (!dataFinded) return
    openFormAtualizar({
      data: {
        id: dataFinded.id,
        dtUltimaFolga: dataFinded.dtUltimaFolga,
        quantidadeVales: dataFinded.quantidadeVales,
        quantidadeValesDescontar: dataFinded.quantidadeValesDescontar,
      },
      subtitle:
        dataFinded.funcionarioNome +
        ' - ' +
        formatToCPFOrCNPJ(dataFinded.funcionarioNrInscricao) +
        ' / ' +
        dataFinded.valeTransporteNome,
    })
  }

  function handleClickDelete(id: string) {
    openConfirmDelete(id)
  }

  async function handleDeleteItem() {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'funcionarioNome',
      label: 'Funcionário',
    },
    {
      name: 'funcionarioNrInscricao',
      label: 'CPF',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'valeTransporteNome',
      label: 'Transportadora',
    },
    {
      name: 'quantidadeVales',
      label: 'Vales',
      options: {
        customBodyRender: (value) => (value ? formatCurrency(value) : ''),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'quantidadeValesDescontar',
      label: 'Vales Descontar',
      options: {
        customBodyRender: (value) => (value ? formatCurrency(value) : ''),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'valorUnitario',
      label: 'Valor Unitário',
      options: {
        customBodyRender: (value) => (value ? formatCurrency(value) : ''),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'valorTotalVales',
      label: 'Total Vales',
      options: {
        customBodyRender: (value) => (value ? formatCurrency(value) : ''),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'valorTotalValesDescontados',
      label: 'Total Vales Descontar',
      options: {
        customBodyRender: (value) => (value ? formatCurrency(value) : ''),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'valorTotalLiquido',
      label: 'Total Líquido',
      options: {
        customBodyRender: (value) => (value ? formatCurrency(value) : ''),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => handleClickEdit(value)}
              >
                <Edit fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                size="small"
                color="primary"
                aria-label="Deletar"
                onClick={() => handleClickDelete(value)}
              >
                <Delete fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Box>
      <ToolsDataTable
        data={data}
        columns={columns}
        disableAutoHeight
        isLoading={isLoading}
        isFetching={isFetching}
      />
      {dataFormAtualizar && (
        <FormAtualizar
          isOpen={isOpenFormAtualizar}
          data={dataFormAtualizar.data}
          onClose={closeFormAtualizar}
          subtitle={dataFormAtualizar.subtitle}
        />
      )}
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
          isDeleting={isDeleting}
        />
      )}
    </Box>
  )
}
