import { makeStyles, Box, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: '8pt',
  },
  value: {
    fontWeight: 'bolder',
    fontSize: '9pt',
    color: '#666',
  },
}))

interface LabelValueProps {
  title?: string
  label: string
  value: string
}

export default function LabelValue(props: LabelValueProps) {
  const { title, label, value } = props
  const classes = useStyles()
  return (
    <Box className={classes.root} title={title}>
      <Typography className={classes.label} variant="body2">
        {label}
      </Typography>
      <Typography className={classes.value} variant="body2">
        {value}
      </Typography>
    </Box>
  )
}
