export enum IndAvisoPrevioEnum {
  TrabalhadoDadoPeloEmpregadorReducaoHoras_1 = 1,
  TrabalhadoDadoPeloEmpregadorReducaoDias_2 = 2,
  PedidoDemissaoNaoDispensaoDoCumprimento_3 = 3,
  PedidoDeDemissao_4 = 4,
  PedidoDeDemissaoIndenizado_5 = 5,
  TrabalhadoDadoEmpregadorRural_6 = 6,
  AvisoPrevioTrabalhoDecorrenteAcordo_7 = 7,
  AvisoPrevioIndenizadoDecorrenteAcordo_8 = 8,
  AvisoPrevioMisto_9 = 9,
  Indenizado_10 = 10,
}

export const IndAvisoPrevioValues = [
  {
    value: IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoHoras_1,
    name: '01 - Trabalhado dado pelo empregador ao empregado, que optou pela redução de duas horas diárias [caput do art. 488 da CLT]',
  },
  {
    value: IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoDias_2,
    name: '02 - Trabalhado dado pelo empregador ao empregado, que optou pela redução de dias corridos[parágrafo único do art. 488 da CLT]',
  },
  {
    value: IndAvisoPrevioEnum.PedidoDemissaoNaoDispensaoDoCumprimento_3,
    name: '03 - Pedido de Demissão, não dispensado de seu cumprimento, sob pena de desconto, pelo empregador, dos salários ref. ao prazo respectivo',
  },
  {
    value: IndAvisoPrevioEnum.PedidoDeDemissao_4,
    name: '04 - Pedido de Demissão, dispensado do seu cumprimento pelo empregador',
  },
  {
    value: IndAvisoPrevioEnum.PedidoDeDemissaoIndenizado_5,
    name: '05 - Pedido de Demissão INDENIZADO',
  },
  {
    value: IndAvisoPrevioEnum.TrabalhadoDadoEmpregadorRural_6,
    name: '06 - Aviso prévio trabalhado dado pelo empregador rural ao empregado, com redução de um dia por semana conforme artigo 15 da Lei nº 5889/73',
  },
  {
    value: IndAvisoPrevioEnum.AvisoPrevioTrabalhoDecorrenteAcordo_7,
    name: '07 - Aviso prévio trabalhado decorrente de acordo entre empregado e empregador',
  },
  {
    value: IndAvisoPrevioEnum.AvisoPrevioIndenizadoDecorrenteAcordo_8,
    name: '08 - Aviso prévio indenizado decorrente de acordo entre empregado e empregador',
  },
  {
    value: IndAvisoPrevioEnum.AvisoPrevioMisto_9,
    name: '09 - Aviso prévio Misto ( trabalhado e indenizado )',
  },
  {
    value: IndAvisoPrevioEnum.Indenizado_10,
    name: '10 - Aviso prévio INDENIZADO dado pelo empregador',
  },
]
