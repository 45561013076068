import React, { useEffect, useState } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import api from '~/services/api-pessoal'

import { makeStyles } from '@material-ui/core'

import { ActionDialog, Finder, ToolsDataTable } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'

import { IndMultiploVinculoEnum } from '~/@types/enums/IndMultiploVinculoEnum'
import { IndBaseCSESocialEnum } from '~/@types/enums/IndBaseCSESocialEnum'
import { IndGrauExposicaoEnum } from '~/@types/enums/IndGrauExposicaoEnum'
import { IndSimplesEnum } from '~/@types/enums/IndSimplesEnum'
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables'
import FuncExpanded from '../FuncExpanded'
import { ParamsDialogBaseCalculoVinculo } from '..'

export interface DialogBaseCalculoVinculoRP {
  id: string
  indGrauExposicao: IndGrauExposicaoEnum
  indMultiplosVinculos: IndMultiploVinculoEnum
  indSimples: IndSimplesEnum
  reciboESocial: string
  vrBaseINSS?: number
  vrBaseINSS13?: number
  vrBaseFGTS?: number
  vrBaseFGTS13?: number
  vrBaseFGTSAvisoPrevio?: number
}

export interface DialogBaseCalculoVinculoBaseCalculoESocial {
  tpValor: IndBaseCSESocialEnum
  vrContribuicao: number
}

export interface DialogBaseCalculoVinculoData {
  nome: string
  rp: DialogBaseCalculoVinculoRP[]
  baseCalculoESocial: DialogBaseCalculoVinculoBaseCalculoESocial[]
}

interface DialogBaseCalculoVinculoProps {
  isOpen: boolean
  onClose: () => void
  params: ParamsDialogBaseCalculoVinculo
}

const useStyles = makeStyles(() => ({
  hoverTableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  fullHeight: { height: '100%' },
}))

export default function DialogBaseCalculoVinculo(props: DialogBaseCalculoVinculoProps) {
  const { isOpen, onClose, params } = props

  const [rowsExpanded, setRowsExpanded] = useState([])
  const [collection, setCollection] = useState<DialogBaseCalculoVinculoData[]>([])
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')

  const classes = useStyles()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    async function getCollection() {
      setLoading(true)
      try {
        const response = await api.get('/ApurarEncargos/ObterBaseCalculoVinculoeSocial', {
          params,
        })
        setCollection(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [params])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nrInscricao',
      label: 'CPF',
      options: {
        customBodyRender: (value) => formatToCPFOrCNPJ(value),
      },
    },
    {
      name: 'nome',
      label: 'Funcionário',
    },
  ]

  const options: MUIDataTableOptions = {
    setRowProps: () => ({ className: classes.hoverTableRow }),
  }

  function renderExpandableRow(index: number) {
    const funcionarioCurrent = collection[index]
    return <FuncExpanded funcionario={funcionarioCurrent} />
  }

  return (
    <ActionDialog
      title="Apuração Encargos - Base de Cálculo - Funcionários"
      renderRight={<Finder onSearch={setQuery} onClose={() => setQuery('')} />}
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
      customActions={<></>}
    >
      <ToolsDataTable
        data={collection}
        columns={columns}
        isLoading={isLoading}
        sherlock={{
          query,
          columns: ['nome'],
        }}
        options={options}
        optionsExpandable={{
          onRowExpanded: setRowsExpanded,
          rowsExpanded: rowsExpanded,
          type: 'single',
          expandOnClick: true,
          expandedChildren: renderExpandableRow,
        }}
      />
    </ActionDialog>
  )
}
