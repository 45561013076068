import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { formatToBRL, formatToCNPJ, formatToCPF } from 'brazilian-values'
import moment from 'moment'
import { HeightDataTable } from '~/components'

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = useMemo(
    () => [
      {
        name: 'empresa',
        label: 'Empresa',
      },
      {
        name: 'cnpj',
        label: 'CNPJ',
        options: {
          customBodyRender: (value) =>
            value?.length >= 14 ? formatToCNPJ(value) : formatToCPF(value),
        },
      },
      {
        name: 'dtInicio',
        label: 'Data Início',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtFim',
        label: 'Data Fim',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'vrRemuneracao',
        label: 'Valor Remuneração',
        options: {
          customBodyRender: (value) => (
            <ButtonBox>{formatToBRL(value).replace('R$ ', '')}</ButtonBox>
          ),
        },
      },
      {
        name: 'vrDescontoINSS',
        label: 'Valor Desconto INSS',
        options: {
          customBodyRender: (value) => (
            <ButtonBox>{formatToBRL(value).replace('R$ ', '')}</ButtonBox>
          ),
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
