import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { AnoMesTextField, Button, Finder, PageHeader, TextField } from '~/components'
import { AutoCompleteAcordo, AutoCompleteSindicato } from '~/components/AutoComplete'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import useObterConvencaoColetiva from '~/hooks/queries/ApurarConvencaoColetiva/useObterConvencaoColetiva'
import useApurarConvencaoColetiva from '~/hooks/queries/ApurarConvencaoColetiva/useApurarConvencaoColetiva'
import useProcessarConvencaoColetiva from '~/hooks/queries/ApurarConvencaoColetiva/useProcessarConvencaoColetiva'

import { ApurarConvencaoColetivaProcessarVinculoDTO } from '~/hooks/queries/ApurarConvencaoColetiva/ApurarConvencaoColetivaDTO'

const schema = yup.object().shape({
  sindicatoId: yup.string().required('Informe o Sindicato'),
  acordoDissidioConvencaoId: yup.string().required('Informe o Acordo'),
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
  nrParcelas: yup
    .number()
    .min(1, 'Informe no mínimo uma parcela')
    .max(12, 'Informe no máximo 12 parcelas'),
})

interface DataProps {
  sindicatoId: string
  acordoDissidioConvencaoId: string
  anoMesInicio: string
  anoMesFim: string
  nrParcelas: number
}

export default function ApurarConvencaoColetiva() {
  const [query, setQuery] = useState('')
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])

  const [vinculosApuracao, setVinculosApuracao] = useState<
    ApurarConvencaoColetivaProcessarVinculoDTO[]
  >([])

  const [dataForm, setDataForm] = useState<DataProps>({
    sindicatoId: '',
    acordoDissidioConvencaoId: '',
    anoMesInicio: '',
    anoMesFim: '',
    nrParcelas: 1,
  })

  const { anoMes, estabelecimento } = useAmbiente()
  const theme = useTheme()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data: dataForm,
  })

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterConvencaoColetiva({
    anoMes,
    estabelecimentoId: estabelecimento.id,
    sindicatoId: dataForm.sindicatoId,
  })

  const { mutateAsync: mutateAsyncApurar, isLoading: isLoadingApurar } =
    useApurarConvencaoColetiva()
  const { mutateAsync: mutateAsyncProcessar, isLoading: isLoadingProcessar } =
    useProcessarConvencaoColetiva()

  useEffect(() => {
    setRowsExpanded([])
    const dt = _d || []
    setVinculosApuracao(
      dt.map((d) => ({
        vinculoId: d.vinculoId,
        vrProcessar: d.vrVencimentoApurado,
        vrINSSProcessar: d.vrINSSApurado,
      })),
    )
  }, [_d])

  function handleSubmit(event: 'apurar' | 'processar') {
    if (event === 'apurar') {
      onSubmitApurar()
    } else {
      onSubmitProcessar()
    }
  }

  async function onSubmitApurar() {
    await mutateAsyncApurar({
      estabelecimentoId: estabelecimento.id,
      sindicatoId: dataForm.sindicatoId,
      anoMes,
      anoMesInicio: dataForm.anoMesInicio,
      anoMesFim: dataForm.anoMesFim,
    })
  }

  async function onSubmitProcessar() {
    await mutateAsyncProcessar({
      estabelecimentoId: estabelecimento.id,
      sindicatoId: dataForm.sindicatoId,
      anoMes,
      acordoDissidioConvencaoId: dataForm.acordoDissidioConvencaoId,
      nrParcelas: dataForm.nrParcelas,
      data: vinculosApuracao,
    })
  }

  return (
    <Box
      p={2}
      height="100%"
      width="100%"
      display="flex"
      gridGap={theme.spacing(1)}
      flexDirection="column"
    >
      <PageHeader title="Apurar Convenção Coletiva">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} padding={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <AutoCompleteSindicato
              name="sindicatoId"
              validationErrors={validationErrors}
              label="Sindicato"
              value={dataForm.sindicatoId || null}
              onChange={(e, sindicatoObj) => {
                const sindicatoId = sindicatoObj?.id || ''
                setDataForm((prev) => ({
                  ...prev,
                  sindicatoId,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <AutoCompleteAcordo
              name="acordoDissidioConvencaoId"
              validationErrors={validationErrors}
              value={dataForm.acordoDissidioConvencaoId}
              onChange={(value) => {
                setDataForm((prev) => ({
                  ...prev,
                  acordoDissidioConvencaoId: value,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <AnoMesTextField
              name="anoMesInicio"
              validationErrors={validationErrors}
              label="Ano/Mês Início"
              value={dataForm.anoMesInicio || ''}
              onChange={(e: FixLater) => {
                const anoMesInicio = e.target.value.replace('/', '')
                setDataForm((prev) => ({ ...prev, anoMesInicio }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <AnoMesTextField
              name="anoMesFim"
              validationErrors={validationErrors}
              label="Ano/Mês Fim"
              value={dataForm.anoMesFim || ''}
              onChange={(e: FixLater) => {
                const anoMesFim = e.target.value.replace('/', '')
                setDataForm((prev) => ({ ...prev, anoMesFim }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <TextField
              name="nrParcelas"
              validationErrors={validationErrors}
              label="Número de Parcelas"
              value={dataForm.nrParcelas}
              type="number"
              onChange={(e) => {
                const value = parseInt(e.target.value)
                const nrParcelas = isNaN(value) ? 0 : value
                setDataForm((prev) => ({ ...prev, nrParcelas }))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => handleValidate('apurar')}
                isLoading={isLoadingApurar}
              >
                Apurar Recibos
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box flex={1} position="relative" overflow="auto" minHeight="300px">
        <Box position="absolute" width="100%">
          <Table
            query={query}
            data={_d || []}
            isLoading={isLoading}
            isFetching={isFetching}
            rowsExpanded={rowsExpanded}
            onRowExpanded={setRowsExpanded}
            vinculosApuracao={vinculosApuracao}
            changeVinculosApuracao={setVinculosApuracao}
          />
        </Box>
      </Box>

      <Box component={Paper} padding={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={() => handleValidate('processar')}
          isLoading={isLoadingProcessar}
          disabled={vinculosApuracao.length > 0 ? false : true}
        >
          Gerar Parcelas
        </Button>
      </Box>
    </Box>
  )
}
