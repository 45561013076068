import theme from '~/styles/theme'

export enum ESocialGrupoEventoConsultaEnum {
  Processando = 1,
  Finalizado = 2,
  Finalizado_Advertencia = 3,
  Erro = 4,
}

export const ESocialGrupoEventoConsultaLabel = {
  [ESocialGrupoEventoConsultaEnum.Processando]: 'Processando',
  [ESocialGrupoEventoConsultaEnum.Finalizado]: 'Finalizado',
  [ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia]: 'Advertência',
  [ESocialGrupoEventoConsultaEnum.Erro]: 'Erro',
}

export const ESocialGrupoEventoConsultaColor = {
  [ESocialGrupoEventoConsultaEnum.Processando]: theme.palette.alerts.info,
  [ESocialGrupoEventoConsultaEnum.Finalizado]: theme.palette.alerts.success,
  [ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia]: theme.palette.alerts.warning,
  [ESocialGrupoEventoConsultaEnum.Erro]: theme.palette.alerts.danger,
}
