import { useState } from 'react'

import PropType from 'prop-types'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'
import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'
import { insertString } from '~/utils/utils'

export default function AutoCompleteHorarioTrabalho(props) {
  const { empregadorId, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      let url = '/HorarioTrabalho'
      if (empregadorId) url = `/HorarioTrabalho/${empregadorId}/SelecionarEmpregador`
      const response = await api.get(url)
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = ({ codigo, nome }) => `${codigo} - ${nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      renderOptionComponent={(opt) => (
        <AutoCompleteOptionDescription
          title={`${opt.codigo} - ${opt.nome}`}
          descriptions={[
            {
              description: `Entrada: ${insertString(
                opt.horaEntrada,
                2,
                ':',
              )} - Saída: ${insertString(opt.horaSaida, 2, ':')}`,
            },
          ]}
        />
      )}
      label="Horário de Trabalho"
      optionId="id"
      {...rest}
    />
  )
}

AutoCompleteHorarioTrabalho.propTypes = {
  onChange: PropType.func,
  value: PropType.any,
  empregadorId: PropType.string,
  required: PropType.bool,
  disabled: PropType.bool,
  validationErrors: PropType.any,
  name: PropType.string,
  label: PropType.string,
}
