export enum AgenteNocivoUnidadeMedidaEnum {
  NAO_INFOMADO = 0,
  DOSE_DIARIA_DE_RUIDO_1 = 1,
  DECIBEL_LINEAR_2 = 2,
  DECIBEL_C_3 = 3,
  DECIBEL_A_4 = 4,
  METRO_POR_SEGUNDO_AO_QUADRADO_5 = 5,
  METRO_POR_SEGUNDO_ELEVADO_A_1_75_6 = 6,
  PARTE_DE_VAPOR_OU_GAS_POR_MILHAO_DE_PARTES_DE_AR_CONTAMINADO_7 = 7,
  MILIGRAMA_POR_METRO_CUBICO_DE_AR_8 = 8,
  FIBRA_POR_CENTIMETRO_CUBICO_9 = 9,
  GRAU_CELSIUS_10 = 10,
  METRO_POR_SEGUNDO_11 = 11,
  PORCENTUAL_12 = 12,
  LUX_13 = 13,
  UNIDADE_FORMADORA_DE_COLONIAS_POR_METRO_CUBICO_14 = 14,
  DOSE_DIARIA_15 = 15,
  DOSE_MENSAL_16 = 16,
  DOSE_TRIMESTRAL_17 = 17,
  DOSE_ANUAL_18 = 18,
  WATT_POR_METRO_QUADRADO_19 = 19,
  AMPERE_POR_METRO_20 = 20,
  MILITESLA_21 = 21,
  MICROTESLA_22 = 22,
  MILIAMPERE_23 = 23,
  QUILOVOLT_POR_METRO_24 = 24,
  VOLT_POR_METRO_25 = 25,
  JOULE_POR_METRO_QUADRADO_26 = 26,
  MILIJOULE_POR_CENTIMETRO_QUADRADO_27 = 27,
  MILISIEVERT_28 = 28,
  MILHAO_DE_PARTICULAS_POR_DECIMETRO_CUBICO_29 = 29,
  UMIDADE_RELATIVA_DO_AR_30 = 30,
}

export const AgenteNocivoUnidadeMedidaValues = [
  { value: AgenteNocivoUnidadeMedidaEnum.NAO_INFOMADO, name: 'Não informar' },
  { value: AgenteNocivoUnidadeMedidaEnum.DOSE_DIARIA_DE_RUIDO_1, name: 'dose diária de ruído' },
  { value: AgenteNocivoUnidadeMedidaEnum.DECIBEL_LINEAR_2, name: 'decibel linear (dB (linear))' },
  { value: AgenteNocivoUnidadeMedidaEnum.DECIBEL_C_3, name: 'decibel (C) (dB(C))' },
  { value: AgenteNocivoUnidadeMedidaEnum.DECIBEL_A_4, name: 'decibel (A) (dB(A))' },
  {
    value: AgenteNocivoUnidadeMedidaEnum.METRO_POR_SEGUNDO_AO_QUADRADO_5,
    name: 'metro por segundo ao quadrado (m/s²)',
  },
  {
    value: AgenteNocivoUnidadeMedidaEnum.METRO_POR_SEGUNDO_ELEVADO_A_1_75_6,
    name: 'metro por segundo elevado a 1,75 (m/s^1,75)',
  },
  {
    value:
      AgenteNocivoUnidadeMedidaEnum.PARTE_DE_VAPOR_OU_GAS_POR_MILHAO_DE_PARTES_DE_AR_CONTAMINADO_7,
    name: 'parte de vapor ou gás por milhão de partes de ar contaminado (ppm)',
  },
  {
    value: AgenteNocivoUnidadeMedidaEnum.MILIGRAMA_POR_METRO_CUBICO_DE_AR_8,
    name: 'miligrama por metro cúbico de ar (mg/m³)',
  },
  {
    value: AgenteNocivoUnidadeMedidaEnum.FIBRA_POR_CENTIMETRO_CUBICO_9,
    name: 'fibra por centímetro cúbico (f/cm³)',
  },
  { value: AgenteNocivoUnidadeMedidaEnum.GRAU_CELSIUS_10, name: 'grau Celsius (ºC)' },
  { value: AgenteNocivoUnidadeMedidaEnum.METRO_POR_SEGUNDO_11, name: 'metro por segundo (m/s)' },
  { value: AgenteNocivoUnidadeMedidaEnum.PORCENTUAL_12, name: 'porcentual' },
  { value: AgenteNocivoUnidadeMedidaEnum.LUX_13, name: 'lux (lx)' },
  {
    value: AgenteNocivoUnidadeMedidaEnum.UNIDADE_FORMADORA_DE_COLONIAS_POR_METRO_CUBICO_14,
    name: 'unidade formadora de colônias por metro cúbico (ufc/m³)',
  },
  { value: AgenteNocivoUnidadeMedidaEnum.DOSE_DIARIA_15, name: 'dose diária' },
  { value: AgenteNocivoUnidadeMedidaEnum.DOSE_MENSAL_16, name: 'dose mensal' },
  { value: AgenteNocivoUnidadeMedidaEnum.DOSE_TRIMESTRAL_17, name: 'dose trimestral' },
  { value: AgenteNocivoUnidadeMedidaEnum.DOSE_ANUAL_18, name: 'dose anual' },
  {
    value: AgenteNocivoUnidadeMedidaEnum.WATT_POR_METRO_QUADRADO_19,
    name: 'watt por metro quadrado (W/m²)',
  },
  { value: AgenteNocivoUnidadeMedidaEnum.AMPERE_POR_METRO_20, name: 'ampère por metro (A/m)' },
  { value: AgenteNocivoUnidadeMedidaEnum.MILITESLA_21, name: 'militesla (mT)' },
  { value: AgenteNocivoUnidadeMedidaEnum.MICROTESLA_22, name: 'microtesla (μT)' },
  { value: AgenteNocivoUnidadeMedidaEnum.MILIAMPERE_23, name: 'miliampère (mA)' },
  {
    value: AgenteNocivoUnidadeMedidaEnum.QUILOVOLT_POR_METRO_24,
    name: 'quilovolt por metro (kV/m)',
  },
  { value: AgenteNocivoUnidadeMedidaEnum.VOLT_POR_METRO_25, name: 'volt por metro (V/m)' },
  {
    value: AgenteNocivoUnidadeMedidaEnum.JOULE_POR_METRO_QUADRADO_26,
    name: 'joule por metro quadrado (J/m²)',
  },
  {
    value: AgenteNocivoUnidadeMedidaEnum.MILIJOULE_POR_CENTIMETRO_QUADRADO_27,
    name: 'milijoule por centímetro quadrado (mJ/cm²)',
  },
  { value: AgenteNocivoUnidadeMedidaEnum.MILISIEVERT_28, name: 'milisievert (mSv)' },
  {
    value: AgenteNocivoUnidadeMedidaEnum.MILHAO_DE_PARTICULAS_POR_DECIMETRO_CUBICO_29,
    name: 'milhão de partículas por decímetro cúbico (mppdc)',
  },
  {
    value: AgenteNocivoUnidadeMedidaEnum.UMIDADE_RELATIVA_DO_AR_30,
    name: 'umidade relativa do ar (UR (%))',
  },
]
