import React, { useState, useEffect, useCallback } from 'react'

import { Grid, LinearProgress } from '@material-ui/core'
import { TextField, ContentDivider } from 'mio-library-ui'

import {
  AutoCompleteMemoryTable,
  AutoCompletePais,
  AutoCompleteLogradouro,
  AutoCompleteCidade,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import {
  CEPTextField,
  NumeroInscricaoTextField,
  ContainerForm,
  Checkbox,
  TelefoneTextField,
} from '~/components'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

import * as yup from 'yup'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'
import { imigranteCondicaoTrabalhadorValues } from '~/values/imigranteCondicaoTrabalhadorValues'

const { CPF_2 } = tipoInscricaoConsts

const schema = yup.object().shape({
  dtNascimento: yup.string().required('Informe a Data Nascimento').nullable(),
  sexo: yup.string().required('Informe o Sexo').nullable(),
  grauInstrucao: yup.string().required('Informe o Grau Instrução').nullable(),
  estadoCivil: yup.string().required('Informe o Estado Cívil').nullable(),
  racaCor: yup.string().required('Informe a Cor').nullable(),
  naturalidadeCidade: yup.string().required('Informe a Naturalidade').nullable(),
  nacionalidadePais: yup.string().required('Informe a Nacionalidade').nullable(),
  nascimentoPais: yup.string().required('Informe o País Nascimento').nullable(),

  nome: yup.string().required('Informe o Nome'),
  imigranteCondicaoTrabalhador: yup.string().required('Informe a Condição do Imigrante'),
  cep: yup.string().required('Informe o CEP').min(8, 'Inserir no mínimo 8 caracteres.').nullable(),
  logradouroId: yup.string().required('Informe o Logradouro').nullable(),
  endereco: yup.string().required('Informe o Endereço'),
  bairro: yup.string().required('Informe o Bairro'),
  cidade: yup.string().required('Informe a Cidade').nullable(),
})

const Form = (props) => {
  const { pessoaFisicaId, onClickClose, setWasModified } = props

  const [oldData, setOldData] = useState({})
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingForm, setLoadingForm] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    const getPessoaFisica = async () => {
      if (!pessoaFisicaId) return
      setLoadingForm(true)
      try {
        const response = await api.get(`/PessoaFisica/${pessoaFisicaId}`)
        setData(response.data.data)
        setOldData(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingForm(false)
      }
    }
    getPessoaFisica()
    //eslint-disable-next-line
  }, [pessoaFisicaId])

  useEffect(() => {
    const checkModification = () => {
      if (JSON.stringify(oldData) !== JSON.stringify(data)) {
        setWasModified(true)
      } else {
        setWasModified(false)
      }
    }
    checkModification()
  }, [data, oldData, setWasModified])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setIsLoading(true)
      try {
        await api.put(`/pessoaFisica/${data.id}`, data)
        notification.put()
        setWasModified(false)
        onClickClose()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    update()
    // eslint-disable-next-line
  }, [data])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  if (isLoadingForm) return <LinearProgress />

  return (
    <ContainerForm handleSubmit={handleValidate} isLoading={isLoading} onCancel={onClickClose}>
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ContentDivider top={2} />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            label="Nome"
            name="nome"
            validationErrors={validationErrors}
            variant="outlined"
            size="small"
            fullWidth
            value={data?.nome || ''}
            required
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const nome = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                nome,
              }))
            }}
          />
        </Grid>
        <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
          <NumeroInscricaoTextField
            typeMask={CPF_2}
            label="Número de Inscrição"
            value={data?.nrInscricao || ''}
            disabled
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <AutoCompleteMemoryTable
            table="GrauInstrucao"
            label="Grau instrução"
            value={data.grauInstrucao || null}
            required
            validationErrors={validationErrors}
            name="grauInstrucao"
            optionId="codigo"
            onChange={(e, memoryTable) => {
              const grauInstrucao = memoryTable ? memoryTable.codigo : null
              setData({ ...data, grauInstrucao })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <AutoCompleteMemoryTable
            table="EstadoCivil"
            label="Estado Civil"
            value={data.estadoCivil || null}
            required
            validationErrors={validationErrors}
            name="estadoCivil"
            optionId="codigo"
            onChange={(e, memoryTable) => {
              const estadoCivil = memoryTable ? memoryTable.codigo : null
              setData({ ...data, estadoCivil })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <AutoCompletePais
            label="Nacionalidade"
            value={data?.nacionalidadePais || null}
            required
            validationErrors={validationErrors}
            name="nacionalidadePais"
            onChange={(e, obj) => {
              const nacionalidadePaisId = obj?.id || null
              const nacionalidadePais = obj || null
              setData({
                ...data,
                nacionalidadePaisId,
                nacionalidadePais,
              })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <MUIAutoComplete
            label="Sexo"
            value={data?.sexo || ''}
            options={[
              { value: 'M', nome: 'Masculino' },
              { value: 'F', nome: 'Feminino' },
            ]}
            optionId="value"
            required
            renderOption={(option) => `${option.nome}`}
            validationErrors={validationErrors}
            name="sexo"
            onChange={(e, obj) => {
              const sexo = obj?.value || ''
              setData({ ...data, sexo })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <AutoCompleteMemoryTable
            table="racacor"
            label="Cor"
            value={data.racaCor || null}
            required
            validationErrors={validationErrors}
            name="racaCor"
            optionId="codigo"
            onChange={(e, memoryTable) => {
              const racaCor = memoryTable ? memoryTable.codigo : null
              setData({ ...data, racaCor })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Email"
            fullWidth
            value={data.email || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 60,
            }}
            onChange={(e) => {
              const email = e.target.value
              setData({ ...data, email })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Endereço" />
        </Grid>

        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <CEPTextField
            name="cep"
            label="CEP"
            fullWidth
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            value={data?.cep || ''}
            onChange={(e) => {
              const cep = e?.target?.value?.replace(/\D/g, '') || ''
              setData({ ...data, cep })
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
          <AutoCompleteLogradouro
            name="logradouroId"
            required
            validationErrors={validationErrors}
            value={data?.logradouroId || null}
            optionId="id"
            onChange={(e, logradouro) => {
              const logradouroId = logradouro?.id || null
              setData({ ...data, logradouroId, logradouro })
            }}
          />
        </Grid>
        <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
          <TextField
            name="endereco"
            label="Endereço"
            fullWidth
            required
            validationErrors={validationErrors}
            value={data.endereco || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const endereco = e.target.value
              setData({ ...data, endereco })
            }}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
          <TextField
            label="Número"
            fullWidth
            value={data.numero || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{ maxLength: 10 }}
            onChange={(e) => {
              const numero = e.target.value
              setData({ ...data, numero })
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={10} sm={12} xs={12}>
          <TextField
            name="bairro"
            label="Bairro"
            fullWidth
            required
            validationErrors={validationErrors}
            value={data.bairro || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const bairro = e.target.value
              setData({ ...data, bairro })
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <TextField
            label="Complemento"
            fullWidth
            value={data.complemento || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const complemento = e.target.value
              setData({ ...data, complemento })
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <AutoCompleteCidade
            name="cidade"
            required
            validationErrors={validationErrors}
            onChange={(e, cidade) => {
              const cidadeId = cidade?.id || ''
              setData({ ...data, cidadeId, cidade })
            }}
            value={data?.cidade || null}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TelefoneTextField
            value={data?.telefone || ''}
            onChange={(e, value) => {
              const telefone = value
              setData((oldState) => ({
                ...oldState,
                telefone,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Pessoa com Deficiência" />
        </Grid>

        <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
          <Checkbox
            label="Deficiência Física"
            value={data.isDeficienciaFisica}
            onChange={(e, value) => {
              const isDeficienciaFisica = value
              setData({ ...data, isDeficienciaFisica })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
          <Checkbox
            label="Deficiência Auditiva"
            value={data?.isDeficienciaAuditiva}
            onChange={(e, value) => {
              const isDeficienciaAuditiva = value
              setData({ ...data, isDeficienciaAuditiva })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
          <Checkbox
            label="Deficiência Visual"
            value={data.isDeficienciaVisual}
            onChange={(e, value) => {
              const isDeficienciaVisual = value
              setData({ ...data, isDeficienciaVisual })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
          <Checkbox
            label="Deficiência Mental"
            value={data.isDeficienciaMental}
            onChange={(e, value) => {
              const isDeficienciaMental = value
              setData({ ...data, isDeficienciaMental })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
          <Checkbox
            label="Deficiência Intelectual"
            value={data.isDeficienciaIntelectual}
            onChange={(e, value) => {
              const isDeficienciaIntelectual = value
              setData({ ...data, isDeficienciaIntelectual })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
          <Checkbox
            label="Reabilitado"
            value={data.isDeficienciaReabilitado}
            onChange={(e, value) => {
              const isDeficienciaReabilitado = value
              setData({ ...data, isDeficienciaReabilitado })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
          <Checkbox
            label="Preenche Cota"
            value={data.isDeficienciaInfoCota}
            onChange={(e, value) => {
              const isDeficienciaInfoCota = value
              setData({ ...data, isDeficienciaInfoCota })
            }}
          />
        </Grid>

        <Grid item xl={10} lg={10} md={10} sm={8} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data.deficienciaObservacao || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 250,
            }}
            onChange={(e) => {
              const deficienciaObservacao = e.target.value
              setData({ ...data, deficienciaObservacao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Trabalho do Imigrante" />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Tempo Residente"
            options={[
              { value: '1', nome: 'Prazo determinado' },
              { value: '2', nome: 'Prazo Inderteminado' },
            ]}
            optionId="value"
            renderOption={(option) => `${option.nome}`}
            value={data?.imigranteTempoResidencia || ''}
            onChange={(e, obj) => {
              const imigranteTempoResidencia = obj?.value || ''
              setData((oldState) => ({
                ...oldState,
                imigranteTempoResidencia,
                imigranteCondicaoTrabalhador: '',
              }))
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Condição do Imigrante"
            required
            validationErrors={validationErrors}
            name="imigranteCondicaoTrabalhador"
            options={imigranteCondicaoTrabalhadorValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.imigranteCondicaoTrabalhador}
            onChange={(e, obj) => {
              const imigranteCondicaoTrabalhador = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                imigranteCondicaoTrabalhador,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ContainerForm>
  )
}

export default Form
