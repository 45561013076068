import { useState, useEffect } from 'react'

import * as yup from 'yup'

import { Button } from 'mio-library-ui'
import { Box, Grid, Paper } from '@material-ui/core'

import { DatePicker } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

const schema = yup.object().shape({
  dtInicio: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data Admissão Início')
    .nullable(),
  dtFim: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data Admissão Fim')
    .min(yup.ref('dtInicio'), 'Data Admissão Fim não pode ser inferior a Data Admissão Início')
    .nullable(),
})

export default function Form(props) {
  const { isLoading, onSubmit, data: _data } = props

  const [data, setData] = useState({})

  useEffect(() => {
    setData(_data)
  }, [_data])

  function handleSubmit() {
    onSubmit(data)
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    handleSubmit,
    schema,
  })

  return (
    <Box component={Paper} padding={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={5}>
          <DatePicker
            label="Data Admissão Início"
            value={data?.dtInicio || null}
            name="dtInicio"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtInicio = date ? date.format('yyyy-MM-DD') : null
              setData((prevState) => ({ ...prevState, dtInicio }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <DatePicker
            label="Data Admissão Fim"
            value={data?.dtFim || null}
            name="dtFim"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtFim = date ? date.format('yyyy-MM-DD') : null
              setData((prevState) => ({ ...prevState, dtFim }))
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            isLoading={isLoading}
            onClick={handleValidate}
            size="small"
            variant="contained"
            color="primary"
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
