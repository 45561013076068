import React from 'react'

import { Box, Theme, makeStyles } from '@material-ui/core'

type AlignSimpleLabel = 'center' | 'start' | 'end'
type ColorSimpleLabel = 'primary' | 'error' | 'success'
type DisplaySimpleLabel = 'flex' | 'block'

interface StylesProps {
  display: DisplaySimpleLabel
  align: AlignSimpleLabel
  color?: ColorSimpleLabel
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: {
    display: (props) => props.display,
    gridGap: theme.spacing(0.5),
    fontWeight: 'bold',
    fontSize: '0.8rem',
    color: theme.palette.grey[600],
    textAlign: (props) => props.align,
  },
  value: {
    color: (props) =>
      props.color === 'primary'
        ? '#2E8BC0'
        : props.color
        ? theme.palette[props.color].main
        : 'none',
  },
}))

interface SimpleLabelValueProps {
  label: string
  value: string | number
  align?: AlignSimpleLabel
  color?: ColorSimpleLabel
  display?: DisplaySimpleLabel
}

export default function SimpleLabelValue(props: SimpleLabelValueProps) {
  const { label, value, align = 'start', color, display = 'block' } = props

  const classes = useStyles({ align, color, display })

  return (
    <Box className={classes.root}>
      <Box>{label}</Box>
      <Box className={classes.value}>{value}</Box>
    </Box>
  )
}
