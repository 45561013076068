import { Box, Container, Paper } from '@material-ui/core'
import { PageHeader, Stack } from '~/components'

import StepperForm from '~/components/StepperForm'

import ManutencaoAdmissao from './Steps/ManutencaoAdmissao'
import ManutencaoRescisao from './Steps/ManutencaoRescisao'

export default function ProcessoTrabalhistaManutencaoVinculo() {
  return (
    <StepperForm.Provider
      steps={[
        {
          label: 'Manutenção Admissão',
          icon: 'people',
          children: <ManutencaoAdmissao />,
        },
        {
          label: 'Manutenção Rescisão',
          icon: 'people_outline',
          children: <ManutencaoRescisao />,
        },
      ]}
    >
      <Box padding={2}>
        <PageHeader title="Processo Trabalhista Manutenção Cadastro" />
        <Container maxWidth="md">
          <Stack>
            <StepperForm.Steps orientation="horizontal" />
            <Box component={Paper} p={3}>
              <StepperForm.Content />
            </Box>
          </Stack>
        </Container>
      </Box>
    </StepperForm.Provider>
  )
}
