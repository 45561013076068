import { convertToArrayDescription } from '~/utils/utils'

export enum FaixaSalarialEnum {
  FaixaI = 1,
  FaixaII = 2,
  FaixaIII = 3,
  FaixaIV = 4,
  Geral = 9,
}

export const FaixaSalarialLabel = {
  [FaixaSalarialEnum.FaixaI]: 'Faixa I',
  [FaixaSalarialEnum.FaixaII]: 'Faixa II',
  [FaixaSalarialEnum.FaixaIII]: 'Faixa III',
  [FaixaSalarialEnum.FaixaIV]: 'Faixa IV',
}

export const FaixaSalarialValues = convertToArrayDescription(FaixaSalarialEnum, FaixaSalarialLabel)
