import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryObterApuracaoEstabelecimento } from './useObterApuracaoEstabelecimento'

export function useValeTransporteApuracaoRemover() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest(valeTransporteId: string) {
    await api.delete('/ValeTransporteApuracao', {
      params: {
        valeTransporteId,
      },
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notification.remove()
      queryClient.invalidateQueries(queryObterApuracaoEstabelecimento)
    },
    onError: dialogNotification.extractErrors,
  })
}
