import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { ProcessarCompetenciaBackgroundRequestDTO } from './dtos/ProcessarCompetenciaBackgroundRequestDTO'

interface RequestProps {
  data: ProcessarCompetenciaBackgroundRequestDTO
}

export default function useProcessarCompetencia() {
  const dialogNotificaiton = useDialogNotification()
  const notification = useNotification()

  async function handleRequest({ data }: RequestProps) {
    await api.post('/Competencia/ProcessarCompetencia', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Processamento Iniciado com Sucesso'),
    onError: dialogNotificaiton.extractErrors,
  })
}
