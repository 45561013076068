import React, { useState, useEffect, useCallback } from 'react'

import { ButtonBox, Button, TextField, ContentDivider } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { Grid, Box, makeStyles } from '@material-ui/core'

import Table from './components/Table'
import FormItem from './components/FormItem'

import { DatePicker } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import useNotification from '~/hooks/useNotification'

import _ from 'lodash'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '60%',
  },
}))

const VinculoHorario = (props) => {
  const { isOpen, onClose, onAfterSubmitForm, data: _data, empregadorId } = props

  const [vinculoHorario, setVinculoHorario] = useState({})

  const [isSubmitting, setSubmitting] = useState(false)
  const notification = useNotification()

  const [formItem, setFormItem] = useState({
    isOpen: false,
    data: {},
  })

  const [confirmDeleteDialogItem, setConfirmDeleteDialogItem] = useState({
    isOpen: false,
    isDeleting: false,
    index: null,
  })

  const classes = useStyles()

  useEffect(() => {
    setVinculoHorario(_data)
    // eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    setSubmitting(true)
    onAfterSubmitForm(vinculoHorario)
    setSubmitting(false)
    //eslint-disable-next-line
  }, [vinculoHorario, onAfterSubmitForm])

  const handleClickDeleteItem = (index) => {
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isOpen: true,
      index,
    }))
  }

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(() => {
    const newArrayVinculoHorarioItens = [...vinculoHorario.vinculoHorarioItens]

    newArrayVinculoHorarioItens.splice(confirmDeleteDialogItem.index, 1)

    setVinculoHorario((oldState) => ({
      ...oldState,
      vinculoHorarioItens: newArrayVinculoHorarioItens,
    }))
    handleCloseConfirmDeleteItem()
    //eslint-disable-next-line
  }, [vinculoHorario.vinculoHorarioItens, confirmDeleteDialogItem.index])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
    //eslint-disable-next-line
  }, [vinculoHorario.vinculoHorarioItens])

  const handleOpenForm = (otherData = { diasSemana: [] }) => {
    setFormItem((oldState) => ({
      ...oldState,
      isOpen: true,
      data: otherData,
    }))
  }

  const handleCloseForm = () => {
    setFormItem({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitFormItem = useCallback(
    (value) => {
      const { vinculoHorarioItens } = vinculoHorario

      let removerArray = []

      vinculoHorarioItens.forEach((item) => {
        value.forEach((valueForm) => {
          if (item.diaHorario === valueForm.diaHorario) {
            removerArray.push(valueForm)
          }
        })
      })

      if (removerArray.length > 0) {
        notification.error(
          `Dia da Semana se repetiu, não foi possível inserir ${removerArray.length} itens`,
        )
      }

      const newValuesForm = _.pullAllBy(value, removerArray, 'diaHorario')

      let newArray = [...vinculoHorarioItens, ...newValuesForm]

      setVinculoHorario((oldState) => ({
        ...oldState,
        vinculoHorarioItens: _.orderBy(newArray, 'diaHorario'),
      }))
      handleCloseForm()
    },
    //eslint-disable-next-line
    [vinculoHorario.vinculoHorarioItens],
  )

  return (
    <ActionDialog
      title="Cadastro de Horário de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <DatePicker
            label="Data de início"
            size="small"
            value={vinculoHorario?.dtIncio || null}
            disabled
          />
        </Grid>

        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={vinculoHorario?.observacao || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e?.target.value || ''
              setVinculoHorario({ ...vinculoHorario, observacao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider />
        </Grid>
      </Grid>

      <Box py={1}>
        <ContentDivider
          title="Dias da semana"
          renderRight={
            <ButtonBox>
              <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
                Adicionar
              </Button>
            </ButtonBox>
          }
        />
      </Box>

      <Table data={vinculoHorario.vinculoHorarioItens} onItemClick={handleClickDeleteItem} />

      <FormItem
        isOpen={formItem.isOpen}
        data={formItem.data}
        onClose={handleCloseForm}
        onAfterSubmitFormItem={handleAfterSubmitFormItem}
        empregadorId={empregadorId}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialogItem.isOpen}
        isDeleting={confirmDeleteDialogItem.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ActionDialog>
  )
}

export default VinculoHorario
