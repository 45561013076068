import { Grid } from '@material-ui/core'

import { NumeroInscricaoTextField, Fieldset } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { InfoOnusRemuneracaoValues } from '~/@types/enums/InfoOnusRemuneracaoEnum'

import { VinculoAfastamentoForm } from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'
import { useStepperContext } from '~/components/StepperForm'
import { ContextProps, titleAllFieldsDisabled } from '..'

export default function MandatoSindical() {
  const {
    dataControlled: data,
    onChangeControlled: setData,
    context,
  } = useStepperContext<VinculoAfastamentoForm>()
  const { disabledAllFields, validationErrors } = context as ContextProps

  return (
    <Fieldset disabled={disabledAllFields} title={disabledAllFields ? titleAllFieldsDisabled : ''}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NumeroInscricaoTextField
            label="Sindical Nr. Inscrição"
            value={data?.sindicalNrInscricao || ''}
            onChange={(e, value) => {
              const sindicalNrInscricao = value
              setData({ ...data, sindicalNrInscricao })
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIAutoComplete
            label="Sindical Informação Ônus"
            required
            validationErrors={validationErrors}
            name="sindicalInformacaoOnus"
            options={InfoOnusRemuneracaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.sindicalInformacaoOnus}
            onChange={(e, obj) => {
              const sindicalInformacaoOnus = obj ? obj.value : ''
              setData({ ...data, sindicalInformacaoOnus })
            }}
          />
        </Grid>
      </Grid>
    </Fieldset>
  )
}
