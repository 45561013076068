import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ProcessoTrabalhistaVinculo } from './dtos/ProcessoTrabalhistaVinculo'

const query = '/ProcessoTrabalhistaVinculo/ObterTodosPorProcessoTrabalhista'

interface RequestProps {
  params: { processoTrabalhistaId?: string }
  enabled?: boolean
}

export function useObterTodosPorProcessoTrabalhista({ enabled, params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params.processoTrabalhistaId) return
    const response = await api.get<{ data: ProcessoTrabalhistaVinculo[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled,
  })
}

// export function useObterTodosPorProcessoTrabalhistaInvalidate() {
//   const queryClient = useQueryClient()
//   function handleInvalidate() {
//     queryClient.invalidateQueries(query)
//   }
//   return { handleInvalidate }
// }
