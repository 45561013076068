import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { RelAniversariantesDataDTO } from './dtos/RelAniversariantesDataDTO'

const query = '/Relatorio/RelAniversariantes/ObterAniversariantes'

interface RequestParams {
  estabelecimentoId: string
  anoMesInicio: string
  anoMesFim: string
}

export function useObterAniversariantes(params: RequestParams) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: RelAniversariantesDataDTO[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
