import { useState, useEffect } from 'react'

import { formatToBRL } from 'brazilian-values'

import { ContentContainer, PageHeader, ButtonBox } from 'mio-library-ui'
import { Box, IconButton, LinearProgress, Paper, Tooltip } from '@material-ui/core'
import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import {
  CollapsableListYears,
  AnoSelect,
  CollapseContent,
  Button,
  ConfirmDeleteDialog,
} from '~/components'

import Form from './components/Form'

import useAmbiente from '~/hooks/useAmbiente'
import useOnlyDataC from '~/hooks/useOnlyDataC'
import useDialog from '~/hooks/useDialog'

import { useObterTodosTabelaIR } from '~/hooks/queries/TabelaIR/useObterTodosTabelaIR'
import { useRemoverTabelaIR } from '~/hooks/queries/TabelaIR/useRemoverTabelaIR'

const columns = [
  {
    name: 'faixa',
    label: 'Faixa',
  },
  {
    name: 'vrFaixaInit',
    label: 'De',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'vrFaixaEnd',
    label: 'Até',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'aliqFaixa',
    label: 'Alíquota',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{`${formatToBRL(value).replace('R$', '')} %`}</ButtonBox>
      },
    },
  },
  {
    name: 'vrDeducaoFaixa',
    label: 'Parcela a Deduzir',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$ ', '')}</ButtonBox>
      },
    },
  },
]

const TabelaIR = () => {
  const [anoCurrent, setAnoCurrent] = useState('')

  const [collapseItens, setCollapseItens] = useState([])

  const { ano } = useAmbiente(false, true)
  const { isUserDataC } = useOnlyDataC()
  const { data: _d, isLoading, isFetching } = useObterTodosTabelaIR()
  const itens = _d || []

  const { mutateAsync: mutateAsyncRemoverTabelaIR, isLoading: isLoadingRemoverTabelaIR } =
    useRemoverTabelaIR()

  const { close: closeForm, data: dataForm, isOpen: isOpenForm, open: openForm } = useDialog(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog(null)

  useEffect(() => {
    setAnoCurrent(ano)
  }, [ano])

  const handleCollapseAll = (value) => {
    const newArray = collapseItens.map(() => value)
    setCollapseItens(newArray)
  }

  const objectInDataForTable = (obj) => {
    return [
      {
        faixa: 'I',
        vrFaixaInit: 0,
        vrFaixaEnd: obj?.vrFaixaI,
        aliqFaixa: obj?.aliqFaixaI,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaI,
      },

      {
        faixa: 'II',
        vrFaixaInit: obj?.vrFaixaI + 0.01,
        vrFaixaEnd: obj?.vrFaixaII,
        aliqFaixa: obj?.aliqFaixaII,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaII,
      },

      {
        faixa: 'III',
        vrFaixaInit: obj?.vrFaixaII + 0.01,
        vrFaixaEnd: obj?.vrFaixaIII,
        aliqFaixa: obj?.aliqFaixaIII,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaIII,
      },

      {
        faixa: 'IV',
        vrFaixaInit: obj?.vrFaixaIII + 0.01,
        vrFaixaEnd: obj?.vrFaixaIV,
        aliqFaixa: obj?.aliqFaixaIV,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaIV,
      },

      {
        faixa: 'V',
        vrFaixaInit: obj?.vrFaixaIV + 0.01,
        vrFaixaEnd: obj?.vrFaixaV,
        aliqFaixa: obj?.aliqFaixaV,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaV,
      },
    ]
  }

  async function handleConfirmDelete(id) {
    await mutateAsyncRemoverTabelaIR(id)
    closeConfirmDelete()
  }

  return (
    <ContentContainer>
      <PageHeader title="Tabela Imposto de Renda">
        <ButtonBox top={1} bottom={1}>
          <Tooltip title="Expandir todos">
            <IconButton size="small" onClick={() => handleCollapseAll(true)}>
              <CollapseOpenIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Retrair todos">
            <IconButton size="small" onClick={() => handleCollapseAll(false)}>
              <CollapseCloseIcon />
            </IconButton>
          </Tooltip>
        </ButtonBox>
      </PageHeader>

      {isFetching ? <LinearProgress /> : <Box height={4} />}

      <Paper style={{ padding: '16px' }}>
        <Box display="flex" justifyContent="space-between">
          <CollapseContent title="Pesquisar por Ano" top={1} bottom={1} showContentFrame={false}>
            <AnoSelect
              onChange={(e) => {
                const ano = e.target.value
                setAnoCurrent(ano)
              }}
              value={anoCurrent || ''}
            />
          </CollapseContent>

          {isUserDataC && (
            <Box>
              <Button variant="contained" onClick={() => openForm(null)}>
                Adicionar
              </Button>
            </Box>
          )}
        </Box>

        <CollapsableListYears
          isLoading={isLoading}
          ano={anoCurrent}
          itens={itens}
          collapseItens={collapseItens}
          setCollapseItens={setCollapseItens}
          objectInDataForTable={objectInDataForTable}
          columns={columns}
          onClickEdit={openForm}
          onClickRemove={openConfirmDelete}
        />
      </Paper>
      {isOpenForm && <Form isOpen={isOpenForm} data={dataForm} onClose={closeForm} />}
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={() => handleConfirmDelete(dataConfirmDelete)}
          isDeleting={isLoadingRemoverTabelaIR}
        />
      )}
    </ContentContainer>
  )
}

export default TabelaIR
