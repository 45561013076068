import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ApuracaoEncargosFGTSDTO } from './ApuracaoEncargosFGTSDTO'

const queryObterApuracaoEncargosEmpregadorFGTS =
  '/ApurarEncargos/ObterApuracaoEncargosEmpregadorFGTS'

interface RequestProps {
  periodoApuracao: string
  isSomenteDiferenca: boolean
  grupoId?: string | null
}

export default function useObterApuracaoEncargosEmpregadorFGTS(props: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ApuracaoEncargosFGTSDTO[] }>(
      queryObterApuracaoEncargosEmpregadorFGTS,
      {
        params: props,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterApuracaoEncargosEmpregadorFGTS, props], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
