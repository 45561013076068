import React, { useEffect, useState } from 'react'

import { ActionDialog, DatePicker } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtRequerimento: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data do Requerimento')
    .nullable(),
})

export default function DialogConfirm(props) {
  const { isOpen, data: vinculoFeriasItemId, onClose, onAfterSubmit } = props

  const [data, setData] = useState({
    dtRequerimento: getDateCurrent(),
    vinculoFeriasItemId: '',
  })

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  useEffect(() => {
    if (!vinculoFeriasItemId) return
    setData((prev) => ({ ...prev, vinculoFeriasItemId }))
  }, [vinculoFeriasItemId])

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  if (!vinculoFeriasItemId) return <></>

  return (
    <ActionDialog
      title="Confirme a Impressão"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Confirmar e Imprimir"
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xs',
      }}
    >
      <DatePicker
        label="Data do Requerimento"
        value={data?.dtRequerimento || null}
        name="dtRequerimento"
        validationErrors={validationErrors}
        onChange={(date) => {
          const dtRequerimento = date ? date.format('yyyy-MM-DD') : null
          setData((prevState) => ({ ...prevState, dtRequerimento }))
        }}
      />
    </ActionDialog>
  )
}
