import { MUIAutoComplete } from '~/components/AutoComplete'

import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'

import { ProcessoTrabalhistaVinculo } from '~/hooks/queries/ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculo'
import { useObterTodosPorProcessoTrabalhista } from '~/hooks/queries/ProcessoTrabalhistaVinculo/useObterTodosPorProcessoTrabalhista'
import { formatToCPF } from 'brazilian-values'

interface AutoCompleteProcessoTrabalhistaVinculoProps extends MUIAutoCompleteProps {
  processoTrabalhistaId: string
  value: string
  onChange: (value: ProcessoTrabalhistaVinculo | null) => void
}

export default function AutoCompleteProcessoTrabalhistaVinculo({
  processoTrabalhistaId,
  value,
  onChange,
  ...rest
}: AutoCompleteProcessoTrabalhistaVinculoProps) {
  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
    refetch,
  } = useObterTodosPorProcessoTrabalhista({
    params: {
      processoTrabalhistaId,
    },
  })
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: ProcessoTrabalhistaVinculo) =>
    `${
      option.vinculo?.pessoaFisica?.nrInscricao
        ? formatToCPF(option.vinculo?.pessoaFisica?.nrInscricao)
        : ''
    } - ${option.vinculo?.pessoaFisica?.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Processo Trabalhista Funcionário"
      value={value}
      onChange={(_, obj: ProcessoTrabalhistaVinculo) => onChange(obj)}
      optionId="id"
      {...rest}
    />
  )
}
