const EPIEPCNaoSeAplica = '0'
const EPIEPCNaoImplementa = '1'
const EPIEPCImplementa = '2'

export const EPIsEPCsUtilizaValues = [
  {
    value: EPIEPCNaoSeAplica,
    name: 'Não se aplica',
  },
  {
    value: EPIEPCNaoImplementa,
    name: 'Não implementa',
  },
  {
    value: EPIEPCImplementa,
    name: 'Implementa',
  },
]

export const EPIsEPCsUtilizaConts = {
  EPIEPCNaoSeAplica,
  EPIEPCNaoImplementa,
  EPIEPCImplementa,
}
