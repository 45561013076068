export enum CodigoSaidaFGTSEnum {
  afatastamento_temporario_acidente_trabalho_O1 = 'O1',
  afatastamento_temporario_mesmo_acidente_O2 = 'O2',
  afatastamento_temporario_acidente_inferior_15_O3 = 'O3',
  afatastamento_temporario_acidente_superior_15_P1 = 'P1',
  afatastamento_temporario_doenca_P2 = 'P2',
  afatastamento_temporario_doenca_inferior_15_P3 = 'P3',
  afatastamento_temporario_licenca_maternidade_Q1 = 'Q1',
  prorrogacao_afastamento_licenca_maternidade_Q2 = 'Q2',
  afastamento_temporario_aborto_nao_criminoso_Q3 = 'Q3',
  afatastamento_temporario_adocao_Q4 = 'Q4',
  afatastamento_temporario_adocao_Q5 = 'Q5',
  afatastamento_temporario_adocao_Q6 = 'Q6',
  afatastamento_temporario_servico_militar_R = 'R',
  afatastamento_temporario_mandato_sindical_W = 'W',
  afatastamento_temporario_sem_vencimento_X = 'X',
  afatastamento_temporario_outros_Y = 'Y',
}

export const CodigoSaidaFGTSValues = [
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_acidente_trabalho_O1,
    name: 'O1 - Afastamento temporário por motivo de acidente do trabalho, por período superior a 15 dias',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_mesmo_acidente_O2,
    name: 'O2 - Novo afastamento temporário em decorrência do mesmo acidente do trabalho',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_acidente_inferior_15_O3,
    name: 'O3 - Afastamento temporário por motivo de acidente de trabalho, por período igual ou inferior a 15 dias',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_acidente_superior_15_P1,
    name: 'P1 - Afastamento temporário por motivo de doença, por período superior a 15 dias',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_doenca_P2,
    name: 'P2 - Novo afastamento temporário em decorrência da mesma doença, dentro de 60 dias contados da cessação do afastamento anterior',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_doenca_inferior_15_P3,
    name: 'P3 - Afastamento temporário por motivo de doença, por período igual ou inferior a 15 dias',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_licenca_maternidade_Q1,
    name: 'Q1 - Afastamento temporário por motivo de licença-maternidade (120 dias)',
  },
  {
    value: CodigoSaidaFGTSEnum.prorrogacao_afastamento_licenca_maternidade_Q2,
    name: 'Q2 - Prorrogação do afastamento temporário por motivo de licença-maternidade',
  },
  {
    value: CodigoSaidaFGTSEnum.afastamento_temporario_aborto_nao_criminoso_Q3,
    name: 'Q3 - Afastamento temporário por motivo de aborto não criminoso',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_adocao_Q4,
    name: 'Q4 - Afastamento temporário por motivo de licença-maternidade decorrente de adoção ou guarda judicial de criança até 1 (um) ano de idade (120 dias)',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_adocao_Q5,
    name: 'Q5 - Afastamento temporário por motivo de licença-maternidade decorrente de adoção ou guarda judicial de criança a partir de 1 (um) ano até 4 (quatro) anos de idade (60 dias)',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_adocao_Q6,
    name: 'Q6 - Afastamento temporário por motivo de licença-maternidade decorrente de adoção ou guarda judicial de criança a partir de 4 (quatro) anos até 8 (oito) anos de idade (30 dias)',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_servico_militar_R,
    name: 'R - Afastamento temporário para prestar serviço militar',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_mandato_sindical_W,
    name: 'W - Afastamento temporário para exercício de mandato sindical',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_sem_vencimento_X,
    name: 'X - Licença sem vencimentos',
  },
  {
    value: CodigoSaidaFGTSEnum.afatastamento_temporario_outros_Y,
    name: 'Y - Outros motivos de afastamento temporário',
  },
]
