import React, { useState, useEffect } from 'react'

import { Box, Grid } from '@material-ui/core'
import { Button, ButtonBox, Finder, PageHeader } from 'mio-library-ui'

import { AlertContainer, PercentTextField, ContainerForm, CurrencyTextField } from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { useHistory } from 'react-router-dom'
import api from '~/services/api-pessoal'

const HEIGHT_HEADER = '50px'

export default function ConfigurarAdiantamentoSalario() {
  const [isSubmiting, setSubmiting] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [oldCollection, setOldCollection] = useState([])
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelecteds] = useState([])
  const [data, setData] = useState({
    valor: 0,
    percentual: 0,
  })
  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const notification = useNotification()
  const history = useHistory()

  useEffect(() => {
    async function getCollection() {
      setRowsSelecteds([])
      setLoading(true)
      try {
        const response = await api.get('/RP/GetVinculoAdiantamentoSalario', {
          params: {
            estabelecimentoId: estabelecimento?.id,
            anoMes,
            buscarTodos: true,
          },
        })
        setOldCollection(response?.data?.data || [])
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [anoMes, estabelecimento])

  function handleApply() {
    if (!(rowsSelecteds?.length > 0))
      return notification.error('Selecione pelo menos um Funcionário para aplicar as configurações')

    const newCollection = collection.map((currentItem, index) => {
      if (rowsSelecteds.includes(index)) {
        const newCurrentItem = { ...currentItem }
        newCurrentItem.vrAdiantamentoSalario = data?.valor
        newCurrentItem.aliqAdiantamentoSalario = data?.percentual
        return newCurrentItem
      }
      return currentItem
    })

    setCollection(newCollection)
    setData({
      valor: 0,
      percentual: 0,
    })
    setRowsSelecteds([])
  }

  async function concluirAlteracoes() {
    setSubmiting(true)
    try {
      await api.post('/Vinculo/ConfigurarAdiantamentoSalario', collection, {
        params: {
          estabelecimentoId: estabelecimento?.id,
        },
      })
      history.push('/adiantamento-salario')
      notification.success('As alterações em Valor e Aliquota foram aplicadas com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmiting(false)
    }
  }

  function handleClickCancel() {
    setCollection(oldCollection)
  }

  const handleChangeCollection = (newData) => {
    setCollection(newData)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box height={HEIGHT_HEADER}>
        <PageHeader
          title="Manutenção Adiantamento de Salário"
          renderRight={<Finder onSearch={setQuery} onClose={() => setQuery('')} />}
        />
      </Box>

      <ContainerForm
        handleSubmit={concluirAlteracoes}
        isLoading={isSubmiting}
        onCancel={handleClickCancel}
        removeHeight={HEIGHT_HEADER}
        labelOk="Salvar Alterações"
        labelCancel="Resertar Alterações"
      >
        <AlertContainer>
          <Grid container spacing={2} alignItems="center">
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              {rowsSelecteds.length > 0 ? (
                <>
                  Funcionários selecionados: <strong>{rowsSelecteds.length || 0}</strong>/
                  {collection?.length || 0}
                </>
              ) : (
                'Selecione os funcionários para realizar a alteração'
              )}
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
              <CurrencyTextField
                label="Valor"
                fullWidth
                value={data?.valor || 0}
                variant="outlined"
                size="small"
                onChange={(e, value) => {
                  const valor = value
                  const percentual = 0
                  setData({ valor, percentual })
                }}
              />
            </Grid>

            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
              <PercentTextField
                label="Aliquota"
                value={data?.percentual}
                onChange={(e, value) => {
                  const percentual = value
                  const valor = 0
                  setData({ valor, percentual })
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                decimalLimit={0}
              />
            </Grid>
            <Grid
              item
              xl={2}
              lg={2}
              md={4}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ButtonBox>
                <Button size="small" variant="contained" color="primary" onClick={handleApply}>
                  Aplicar Alterações
                </Button>
              </ButtonBox>
            </Grid>
          </Grid>
        </AlertContainer>

        <Table
          data={collection}
          query={query}
          rowsSelected={rowsSelecteds}
          handleRowSelected={setRowsSelecteds}
          isLoading={isLoading}
          onChange={handleChangeCollection}
        />
      </ContainerForm>
    </Box>
  )
}
