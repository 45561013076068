import { useEffect, useRef, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import {
  PageHeader,
  PrintButton,
  PDFViewer,
  Finder,
  DatePickerNew,
  Button,
  DialogConfirmDtAssinatura,
} from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useValidationErrors from '~/hooks/useValidationErrors'

import { useGetByEstabelecimentoPeriodo } from '~/hooks/queries/VinculoConsulta/useGetByEstabelecimentoPeriodo'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { extractSelectedRowsTable, getDateMaxAnoMes, getDateMinAnoMes } from '~/utils/utils'
import { TipoAssinaturaEnum } from '~/@types/enums/TipoAssinaturaEnum'
import { DataFormProps } from '~/components/DialogConfirmDtAssinatura'

const schema = yup.object().shape({
  dtInicio: yup.date().typeError('Informe uma data válida').required('Informe a Data de Início'),
  dtFim: yup.date().typeError('Informe uma data válida').required('Informe a Data de Fim'),
})

const TITLE_PAGE = 'Recibo Termo de Conduta'

export default function ReciboTermoConduta() {
  const [dataForm, setDataForm] = useState({
    dtInicio: '',
    dtFim: '',
  })
  const [query, setQuery] = useState('')

  const tableRef = useRef<FixLater>(null)
  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const theme = useTheme()

  useEffect(() => {
    setDataForm({
      dtInicio: getDateMinAnoMes(anoMes),
      dtFim: getDateMaxAnoMes(anoMes),
    })
  }, [anoMes])

  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog()

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<{
    data: string[]
    params: {
      dtAssinatura: string | null
      tipoAssinatura: TipoAssinaturaEnum
    }
  }>({
    data: [],
    params: {
      tipoAssinatura: TipoAssinaturaEnum.ProprioPunho,
      dtAssinatura: null,
    },
  })

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useGetByEstabelecimentoPeriodo({
    data: [VinculoTipoEnum.Funcionario_1],
    params: {
      estabelecimentoId: estabelecimento.id,
      dtInicio: dataForm.dtInicio,
      dtFim: dataForm.dtFim,
    },
  })
  const data = _d || []

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: refetch,
    data: dataForm,
  })

  function handleAfterConfirm(d: DataFormProps) {
    const rowsSelecteds = extractSelectedRowsTable(tableRef)
    if (rowsSelecteds.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um funcionário para prosseguir'],
      })
    }
    const funcionariosIds = rowsSelecteds.map((rowIndex) => data[rowIndex].id)
    openPDFViewer({
      data: funcionariosIds,
      params: {
        dtAssinatura: d.dtAssinatura,
        tipoAssinatura: d.tipoAssinatura,
      },
    })
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} p={1} display="flex" alignItems="center" gridGap={theme.spacing(1)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <DatePickerNew
              label="Data de Início Admissão"
              value={dataForm?.dtInicio || null}
              name="dtInicio"
              validationErrors={validationErrors}
              onChange={(date) => {
                const dtInicio = date || ''
                setDataForm((prev) => ({ ...prev, dtInicio }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <DatePickerNew
              label="Data de Fim Admissão"
              value={dataForm?.dtFim || null}
              name="dtFim"
              validationErrors={validationErrors}
              onChange={(date) => {
                const dtFim = date || ''
                setDataForm((prev) => ({ ...prev, dtFim }))
              }}
            />
          </Grid>
        </Grid>
        <Button variant="contained" onClick={handleValidate}>
          Buscar
        </Button>
      </Box>

      <Box minHeight={200} flex={1} overflow="auto" position="relative">
        <Box position="absolute" width="100%">
          <Table
            query={query}
            tableRef={tableRef}
            data={data}
            isFetching={isFetching}
            isLoading={isLoading}
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" p={1} component={Paper}>
        <PrintButton onClick={openDialogConfirm} />
      </Box>

      {isOpenDialogConfirm && (
        <DialogConfirmDtAssinatura
          isOpen={isOpenDialogConfirm}
          onClose={closeDialogConfirm}
          onAfterSubmit={handleAfterConfirm}
          startData={{
            dtAssinatura: null,
            tipoAssinatura: TipoAssinaturaEnum.Admissao,
          }}
        />
      )}

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="ReciboTermoConduta"
          title={TITLE_PAGE}
          axiosConfig={{
            method: 'post',
            url: '/Relatorio/ReciboTermoConduta/ObterRelatorio',
            data: dataPDFViewer.data,
            params: dataPDFViewer.params,
          }}
        />
      )}
    </Box>
  )
}
