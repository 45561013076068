import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  params: { empregadorId: string; dtInicioValidade: string }
}

export function useProcessaEventoEmpregador() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ params }: RequestProps) {
    await api.post('/Empregador/ProcessaEventoEmpregador', null, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Eventos da tabela S-1010 foram processados com sucesso.')
    },
    onError: dialogNotification.extractErrors,
  })
}
