import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { ProcessoTrabalhistaVinculo } from '../ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculo'

const query = '/ProcessoTrabalhista/ObterProcessosTrabalhistasFuncionarios/'

interface RequestProps {
  processoTrabalhistaId: string
}

export function useObterProcessosTrabalhistasFuncionarios({ processoTrabalhistaId }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ProcessoTrabalhistaVinculo[] }>(
      query + processoTrabalhistaId,
    )
    return response.data.data
  }

  return useQuery([query, processoTrabalhistaId], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
