import React, { useEffect, useState } from 'react'

import * as yup from 'yup'
import { isCPF } from 'brazilian-values'

import { Box, Grid, LinearProgress } from '@material-ui/core'
import { Button, TextField, ButtonBox } from 'mio-library-ui'

import { NumeroInscricaoTextField } from '~/components'

import { getPessoaFisicaById, usePessoaFisicaMutate } from '~/hooks/queries/usePessoaFisica'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  nrInscricao: yup
    .string()
    .required('Informe o CPF')
    .test('cpf-test', 'Informe um CPF válido', (value) => isCPF(value)),
})

export default function Form(props) {
  const { pessoaFisicaId, nrInscricao, onClose } = props
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })
  const { mutateCreatePessoaFisicaSST, mutateUpdatePessoaFisica } = usePessoaFisicaMutate()
  const notification = useNotification()

  useEffect(() => {
    if (pessoaFisicaId) {
      getPessoaFisica()
    } else {
      setData({ nrInscricao })
    }
    async function getPessoaFisica() {
      setLoading(true)
      try {
        const pessoaFisica = await getPessoaFisicaById(pessoaFisicaId)
        setData(pessoaFisica)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [pessoaFisicaId, nrInscricao])

  async function handleSubmit() {
    if (data?.id) {
      await mutateUpdatePessoaFisica.mutateAsync(data)
      notification.put()
    } else {
      await mutateCreatePessoaFisicaSST.mutateAsync(data)
      notification.post()
    }
    onClose()
  }

  if (isLoading) return <LinearProgress />

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Nome"
            name="nome"
            validationErrors={validationErrors}
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            value={data?.nome || ''}
            required
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const nome = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                nome,
              }))
            }}
          />
        </Grid>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NumeroInscricaoTextField
            label="CPF"
            value={data?.nrInscricao || ''}
            required
            // disabled={data?.id ? true : false}
            disabled
            typeMask={tipoInscricaoConsts.CPF_2}
            name="nrInscricao"
            validationErrors={validationErrors}
            // onChange={(e, value) => {
            //   const nrInscricao = value
            //   setData((oldState) => ({
            //     ...oldState,
            //     nrInscricao,
            //   }))
            // }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ButtonBox>
            <Button onClick={onClose} size="small" variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button
              isLoading={
                mutateCreatePessoaFisicaSST.isLoading || mutateUpdatePessoaFisica.isLoading
              }
              onClick={handleValidate}
              size="small"
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </ButtonBox>
        </Grid>
      </Grid>
    </Box>
  )
}
