export enum IndLancamentoFaltaEnum {
  Falta = 1,
  Atraso = 2,
}

export const IndLancamentoFaltaLabel = {
  [IndLancamentoFaltaEnum.Falta]: '1 - Falta Integral',
  [IndLancamentoFaltaEnum.Atraso]: '2 - Falta por Hora',
}

export const IndLancamentoFaltaValues = [
  {
    value: IndLancamentoFaltaEnum.Falta,
    name: '1 - Falta Integral',
  },
  {
    value: IndLancamentoFaltaEnum.Atraso,
    name: '2 - Falta por Hora',
  },
]
