import { formatToCPF } from 'brazilian-values'
import { formatSimpleDate } from '~/utils/utils'

import { Chip } from '@material-ui/core'

import { CopyLabel, Stack, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoTrabalhoFeriado as VinculoTrabalhoFeriadoType } from '~/hooks/queries/VinculoTrabalhoFeriado/dtos/VinculoTrabalhoFeriado'
import { VinculoTrabalhoFeriadoFuncionarioDTO } from '~/hooks/queries/VinculoTrabalhoFeriado/dtos/VinculoTrabalhoFeriadoFuncionarioDTO'
import { useRemoverVinculoTrabalhoFeriado } from '~/hooks/queries/VinculoTrabalhoFeriado/useRemoverVinculoTrabalhoFeriado'
import { useObterFuncionariosInvalidate } from '~/hooks/queries/VinculoTrabalhoFeriado/useObterFuncionarios'

interface TableProps {
  data: VinculoTrabalhoFeriadoFuncionarioDTO[]
  isFetching: boolean
  isLoading: boolean
  rowsSelected: number[]
  onSelectRow: (d: number[]) => void
  query: string
}

export default function Table({
  data,
  isFetching,
  isLoading,
  rowsSelected,
  onSelectRow,
  query,
}: TableProps) {
  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } =
    useRemoverVinculoTrabalhoFeriado()

  const { handleInvalidate } = useObterFuncionariosInvalidate()

  async function handleRemove(id: string) {
    await mutateAsyncRemove(id)
    handleInvalidate()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
      options: {
        customBodyRender: (value) =>
          value ? <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel> : '',
      },
    },
    {
      name: 'dtAdmissao',
      label: 'Data Admissão',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'feriados',
      label: 'Feriados Trabalhados',
      options: {
        customBodyRender: (value: VinculoTrabalhoFeriadoType[]) => (
          <Stack width="500px" orientation="horizontal" overflow="auto">
            {value.map((d) => (
              <Chip
                key={d.id}
                style={{
                  width: '100px',
                }}
                size="small"
                label={formatSimpleDate(d.dtFeriado)}
                color={d.dtConcessaoFolga ? 'secondary' : 'default'}
                onDelete={() => handleRemove(d.id)}
                disabled={isRemoving}
              />
            ))}
          </Stack>
        ),
        setCellProps: () => ({
          style: {
            width: '500px',
          },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isFetching={isFetching || isRemoving}
      isLoading={isLoading}
      disableAutoHeight
      optionsSelectable={{
        rowsSelected,
        onRowSelected: onSelectRow,
        type: 'multiple',
      }}
      sherlock={{
        columns: ['nome', 'nrInscricao', 'dtAdmissao'],
        query,
      }}
    />
  )
}
