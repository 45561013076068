import React, { useState, useCallback } from 'react'

import { Button, Finder, ButtonBox } from 'mio-library-ui'

import { Box } from '@material-ui/core'

import { PageHeader } from '~/components'

import Table from './components/Table'
import Form from './components/Cadastro'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import { useDelete } from '~/hooks/queries/useAcordoDissidioConvencao'
import useDialog from '~/hooks/useDialog'

const AcordoDissidioConvencao = () => {
  const [query, setQuery] = useState('')
  const { mutateAsync, isLoading: isDeleting } = useDelete()

  const {
    data: dataConfirmDeleteDialog,
    isOpen: isOpenConfirmDeleteDialog,
    open: openConfirmDeleteDialog,
    close: closeConfirmDeleteDialog,
  } = useDialog()

  const { data: dataForm, isOpen: isOpenForm, open: openForm, close: closeForm } = useDialog()

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (data) => {
        openForm({
          ...data,
          indRemuneracaoSucessao: false,
        })
      }

      const handleClickDeleteItem = (id) => {
        openConfirmDeleteDialog(id)
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [openConfirmDeleteDialog, openForm],
  )

  async function handleDeleteItem() {
    await mutateAsync(dataConfirmDeleteDialog)
    closeConfirmDeleteDialog()
  }

  function handleClickAddItem() {
    openForm({ indRemuneracaoSucessao: false })
  }

  return (
    <Box height="100%" p={2}>
      <PageHeader title="Acordo">
        <ButtonBox>
          <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Table query={query} onItemClick={handleClickItem} />

      <Form data={dataForm} isOpen={isOpenForm} onClose={closeForm} />

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDeleteDialog}
        isDeleting={isDeleting}
        onCancel={() => closeConfirmDeleteDialog()}
        onConfirm={handleDeleteItem}
      />
    </Box>
  )
}

export default AcordoDissidioConvencao
