import React, { useMemo } from 'react'

import { ToolsDataTable } from '~/components'

import TableExpanded from './components/TableExpanded'

import { formatToCPFOrCNPJ } from 'brazilian-values'

const Table = (props) => {
  const {
    data: _data,
    isLoading,
    onRowSelected,
    rowsSelected,
    query,
    dataTableExpandable,
    isLoadingTableExpandable,
    rowsExpanded,
    onRowExpanded,
  } = props

  const data = _data.map((obj) => ({
    ...obj,
    nrInscricao: formatToCPFOrCNPJ(obj?.nrInscricao || ''),
  }))

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nome',
        label: 'Estabelecimento',
      },
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
    ],
    [],
  )

  function renderExpandableRow() {
    return <TableExpanded data={dataTableExpandable} isLoading={isLoadingTableExpandable} />
  }

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        columns: ['codigo', 'nome', 'nrInscricao'],
        query,
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected: onRowSelected,
        rowsSelected: rowsSelected,
      }}
      optionsExpandable={{
        type: 'single',
        expandedChildren: renderExpandableRow,
        onRowExpanded,
        rowsExpanded,
      }}
    />
  )
}

export default Table
