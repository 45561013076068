import React, { useEffect, useState } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Button, ConfirmDeleteDialog, PageHeader, TreeView } from '~/components'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { useDelete } from '~/hooks/queries/useVinculoExameToxicologico'
import { useRetificar } from '~/hooks/queries/VinculoExameToxicologico/useRetificar'
import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

import Table from './Table'
import Form from './Form'

export default function VinculoExameToxicologico() {
  const [vinculo, setVinculo] = useState({})

  const { mutateAsync, isLoading: isDeleting } = useDelete()

  const { data: dataForm, isOpen: isOpenForm, open: openForm, close: closeForm } = useDialog(null)

  const {
    data: dataConfirmDelete,
    open: openConfirmDelete,
    isOpen: isOpenConfirmDelete,
    close: closeConfirmDelete,
  } = useDialog('')

  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Empregador_2,
    VinculoTipoEnum.Estagiario_3,
    VinculoTipoEnum.Autonomo_4,
    VinculoTipoEnum.Cooperado_5,
  ])
  const { anoMes, estabelecimento } = useAmbiente()
  const { mutateAsync: mutateAsyncRetificar, isLoading: isLoadingRetificar } = useRetificar()

  useEffect(() => {
    setVinculo({})
  }, [anoMes, estabelecimento])

  function handleClickItem(event, value) {
    async function handleRetificar() {
      await mutateAsyncRetificar({
        exameToxicologicoId: value,
      })
    }

    const handleClickEditItem = async (obj) => {
      openForm(obj)
    }

    const handleClickDeleteItem = (id) => {
      openConfirmDelete(id)
    }

    const functions = {
      retificar: handleRetificar,
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  function handleAddItem() {
    openForm({
      id: '',
      vinculoId: vinculo?.vinculoId,
      medicoId: '',
      dtExame: '',
      dtVencimento: '',
      nrInscricaoLaboratorio: '',
      codigoExame: '',
      reciboEsocial: '',
      dtReciboEsocial: '',
    })
  }

  async function handleConfirmDelete() {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(_, vinculo) => setVinculo(vinculo)}
      searchBy="vinculoNome"
      renderOption={(option) => `${option?.vinculoNome}`}
      isLoading={isLoadingMenu}
    >
      <PageHeader title="Exame Toxicológico" subtitle={vinculo?.vinculoNome || '-'}>
        {vinculo?.vinculoId && (
          <ButtonBox>
            <Button size="small" color="primary" onClick={handleAddItem} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        )}
      </PageHeader>

      <Table
        vinculo={vinculo}
        onItemClick={handleClickItem}
        isLoadingRetificar={isLoadingRetificar}
      />

      {isOpenForm && <Form data={dataForm} isOpen={isOpenForm} onClose={closeForm} />}

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isDeleting}
        onCancel={closeConfirmDelete}
        onConfirm={handleConfirmDelete}
      />
    </TreeView>
  )
}
