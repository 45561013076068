import React, { useState } from 'react'

import { Box, Grid, MenuItem } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import {
  NumeroInscricaoTextField,
  DatePickerNew,
  SimpleHoraTextField,
  Checkbox,
  PageHeader,
  TextField,
  Button,
  ContentDivider,
} from '~/components'
import { MUIAutoComplete, AutoCompleteSindicato } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import moment from 'moment'
import * as yup from 'yup'
import { tipoContratoTrabalhoValues } from '~/values/tipoContratoTrabalhoValues'
import { JornadaTipoEnum, JornadaTipoValues } from '~/@types/enums/JornadaTipoEnum'
import { jornadaTempoParcialValues } from '~/values/jornadaTempoParcialValues'
import { indUnidadeSalarioValues } from '~/values/indUnidadeSalarioValues'
import { naturezaAtividadeValues } from '~/values/naturezaAtividadeValues'
import { useStepperContext } from '~/components/StepperForm'
import { MainDataForm } from '..'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'
import {
  AprendizIndContratacaoEnum,
  AprendizIndContratacaoValues,
} from '~/@types/enums/AprendizIndContratacaoEnum'
import { isCNPJ, isCPF } from 'brazilian-values'
import { TipoInscricaoEnum, TipoInscricaoValues } from '~/@types/enums/TipoInscricaoEnum'
import { TipoContratoTrabalhoEnum } from '~/@types/enums/TipoContratoTrabalhoEnum'

const tipoInscricaoValues = TipoInscricaoValues.filter(
  (obj) => obj.value === TipoInscricaoEnum.CNPJ_1 || obj.value === TipoInscricaoEnum.CPF_2,
)

const schema = yup.object().shape({
  tipoContrato: yup.string().required('Informe o Tipo de Contrato'),
  tipoJornada: yup.string().required('Informe o Tipo de Jornada'),
  descricaoTipoJornada: yup
    .string()
    .when(['tipoJornada'], (tipoJornada, schema, { value }) => {
      if (value) return
      return schema.test(
        'descricao-tipo-jornada-required',
        'Informe a Descrição da Jornada',
        () => parseInt(tipoJornada) !== JornadaTipoEnum.DemaisTipos_9,
      )
    })
    .nullable(),
  jornadaParcial: yup.string().required('Informe a Jornada Parcial'),
  unidadeSalarioFixo: yup.string().required('Informe a Unidade Salário Fixo'),
  naturezaAtividade: yup.string().required('Informe a Natureza Atividade'),

  aprendizIndContratacao: yup.mixed().when(['categoria'], (categoria: CategoriaEnum, schema) => {
    if (categoria === CategoriaEnum.Empregado_Aprendiz_103) {
      return schema
        .required('Informe o Indicador de Modalidade de Contratação')
        .oneOf(
          Object.values(AprendizIndContratacaoEnum),
          'Informe o Indicador de Modalidade de Contratação',
        )
    } else {
      return schema
    }
  }),
  aprendizCNPJQualificadora: yup
    .mixed()
    .when(
      ['categoria', 'aprendizIndContratacao'],
      (categoria: CategoriaEnum, aprendizIndContratacao: AprendizIndContratacaoEnum, schema) => {
        if (
          categoria === CategoriaEnum.Empregado_Aprendiz_103 &&
          aprendizIndContratacao == AprendizIndContratacaoEnum.Contratacao_Direta
        ) {
          return schema
            .required('Informe o CNPJ Qualificadora')
            .test({
              message: 'Informe um CNPJ válido',
              test: (cnpj: string) => isCNPJ(cnpj),
            })
            .nullable()
        } else {
          return schema.nullable()
        }
      },
    ),
  aprendizCNPJAtividadesPraticas: yup
    .mixed()
    .when(['categoria'], (categoria: CategoriaEnum, schema, { value }) => {
      if (categoria === CategoriaEnum.Empregado_Aprendiz_103 && value) {
        return schema
          .required('Informe o CNPJ Atividades Práticas')
          .test({
            message: 'Informe um CNPJ válido',
            test: (cnpj: string) => isCNPJ(cnpj),
          })
          .nullable()
      } else {
        return schema.nullable()
      }
    }),
  aprendizTpInscricaoContratacao: yup
    .mixed()
    .when(
      ['categoria', 'aprendizIndContratacao'],
      (categoria: CategoriaEnum, aprendizIndContratacao: AprendizIndContratacaoEnum, schema) => {
        if (
          categoria === CategoriaEnum.Empregado_Aprendiz_103 &&
          aprendizIndContratacao == AprendizIndContratacaoEnum.Contratacao_Indireta
        ) {
          return schema
            .required('Informe o Tipo de Inscrição Contratante')
            .oneOf(Object.values(TipoInscricaoEnum), 'Informe o Tipo de Inscrição Contratante')
            .test({
              message: 'Informe o Tipo de Inscrição Contratante',
              test: (tipo: TipoInscricaoEnum) => tipo !== TipoInscricaoEnum.NaoInformar_0,
            })
        } else {
          return schema
        }
      },
    ),
  aprendizNrInscricaoContratacao: yup
    .mixed()
    .when(
      ['categoria', 'aprendizIndContratacao', 'aprendizTpInscricaoContratacao'],
      (
        categoria: CategoriaEnum,
        aprendizIndContratacao: AprendizIndContratacaoEnum,
        aprendizTpInscricaoContratacao: TipoInscricaoEnum,
        schema,
      ) => {
        if (
          categoria === CategoriaEnum.Empregado_Aprendiz_103 &&
          aprendizIndContratacao == AprendizIndContratacaoEnum.Contratacao_Indireta
        ) {
          if (aprendizTpInscricaoContratacao === TipoInscricaoEnum.CPF_2) {
            return schema
              .required('Informe o Número Inscrição Contratante')
              .test({
                message: 'Informe um CPF válido',
                test: (cpf: string) => isCPF(cpf),
              })
              .nullable()
          } else {
            return schema
              .required('Informe o Número Inscrição Contratante')
              .test({
                message: 'Informe um CNPJ válido',
                test: (cnpj: string) => isCNPJ(cnpj),
              })
              .nullable()
          }
        } else {
          return schema
        }
      },
    ),
})

export default function FormVinculo() {
  const { mainData, onBack, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataVinculo)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const dialogNotification = useDialogNotification()

  function alertTipoContratoIndeterminado() {
    if (data.tipoContrato === TipoContratoTrabalhoEnum.PrazoIndeterminado_1) {
      return
    }
    const dtAlteracao = mainData.dataAlteracaoContrato.dtAlteracao
    if (!dtAlteracao) {
      return
    }

    if (
      data.contratoExperienciaDtVencimento &&
      !data.contratoExperienciaDtProrrogacao &&
      moment(dtAlteracao).isAfter(data.contratoExperienciaDtVencimento)
    ) {
      throw new Error(
        'A Data de Vencimento do Contrato de Experiência expirou; por favor, atualize o Tipo de Contrato do funcionário.',
      )
    }

    if (
      data.contratoExperienciaDtProrrogacao &&
      moment(dtAlteracao).isAfter(data.contratoExperienciaDtProrrogacao)
    ) {
      throw new Error(
        'A Data de Vencimento da Prorrogação do Contrato de Experiência expirou; por favor, atualize o Tipo de Contrato do funcionário.',
      )
    }
  }

  function handleSubmit() {
    try {
      alertTipoContratoIndeterminado()
      onNext({
        ...mainData,
        dataVinculo: data,
      })
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <PageHeader title="Vínculo" />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
        <TextField
          label="Pessoa Física"
          fullWidth
          value={data?.pessoaFisica?.nome || ''}
          size="small"
          disabled
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <NumeroInscricaoTextField
          label="CPF"
          value={data?.pessoaFisica?.nrInscricao || ''}
          disabled
        />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
        <TextField
          label="Regime Previdência"
          fullWidth
          value={data.tipoRegimePrevidencia || ''}
          size="small"
          onChange={(e) => {
            const tipoRegimePrevidencia = e.target.value
            setData({ ...data, tipoRegimePrevidencia })
          }}
          select
        >
          <MenuItem value="1">Regime Geral da Previdência Social - RGPS</MenuItem>
          <MenuItem value="2">Regime Próprio de Previdência Social - RPPS </MenuItem>
          <MenuItem value="3">Regime Próprio de Previdência Social no Exterior - RPPE</MenuItem>
        </TextField>
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <MUIAutoComplete
          label="Unidade Salário Fixo"
          options={indUnidadeSalarioValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="unidadeSalarioFixo"
          value={data.unidadeSalarioFixo}
          onChange={(e, obj) => {
            const unidadeSalarioFixo = obj ? obj.value : ''
            setData({ ...data, unidadeSalarioFixo })
          }}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompleteSindicato
          label="Sindicato"
          value={data?.sindicatoId || ''}
          onChange={(e, sindicatoObj) => {
            const sindicatoId = sindicatoObj.id || ''
            setData({
              ...data,
              sindicatoId,
            })
          }}
          optionId="id"
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <TextField
          label="Regime Jornada"
          fullWidth
          value={data?.tipoRegimeJornada || ''}
          size="small"
          onChange={(e) => {
            const tipoRegimeJornada = e.target.value
            setData({ ...data, tipoRegimeJornada })
          }}
          select
        >
          <MenuItem value="1">Submetido a Horário de Trabalho</MenuItem>
          <MenuItem value="2">Atividade Externa</MenuItem>
          <MenuItem value="3">Funções Específicas</MenuItem>
          <MenuItem value="4">Teletrabalho</MenuItem>
        </TextField>
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <MUIAutoComplete
          label="Natureza Atividade"
          options={naturezaAtividadeValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="naturezaAtividade"
          value={data.naturezaAtividade}
          onChange={(e, obj) => {
            const naturezaAtividade = obj ? obj.value : ''
            setData({ ...data, naturezaAtividade })
          }}
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <MUIAutoComplete
          label="Tipo de Contrato"
          options={tipoContratoTrabalhoValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="tipoContrato"
          value={data.tipoContrato}
          onChange={(e, obj) => {
            const tipoContrato = obj ? obj.value : ''
            setData({ ...data, tipoContrato })
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Objeto Determinante Para Fim do Contrato"
          fullWidth
          value={data.objetoDeterminante || ''}
          size="small"
          multiline
          inputProps={{
            maxLength: 200,
          }}
          onChange={(e) => {
            const objetoDeterminante = e.target.value
            setData({ ...data, objetoDeterminante })
          }}
        />
      </Grid>

      <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
        <TextField
          label="Número de Dias"
          size="small"
          fullWidth
          onlyNumber
          inputProps={{
            maxLength: 6,
          }}
          value={data?.contratoExperienciaDias || ''}
          onChange={(e) => {
            const contratoExperienciaDias = parseInt(e?.target?.value || '0')
            setData((oldState) => ({
              ...oldState,
              contratoExperienciaDias,
            }))
          }}
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={8} xs={12}>
        <DatePickerNew
          label="Vencimento Experiência"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          value={data?.contratoExperienciaDtVencimento || null}
          onChange={(date) => {
            const contratoExperienciaDtVencimento = date
            setData({
              ...data,
              contratoExperienciaDtVencimento,
            })
          }}
        />
      </Grid>
      <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
        <TextField
          label="Dias Prorrogação"
          fullWidth
          value={data?.contratoExperienciaDiasProrrogacao || ''}
          size="small"
          onlyNumber
          onBlur={() => {
            if (data?.contratoExperienciaDiasProrrogacao && data?.contratoExperienciaDtVencimento) {
              const contratoExperienciaDtProrrogacao = moment(data?.contratoExperienciaDtVencimento)
                .add(data?.contratoExperienciaDiasProrrogacao, 'day')
                .format('yyyy-MM-DD')
              setData((oldState) => ({
                ...oldState,
                contratoExperienciaDtProrrogacao,
              }))
            }
          }}
          onChange={(e) => {
            const contratoExperienciaDiasProrrogacao = parseInt(e?.target?.value || '0')
            setData({
              ...data,
              contratoExperienciaDiasProrrogacao,
            })
          }}
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={8} xs={12}>
        <DatePickerNew
          label="Vencimento Prorrogação do Contrato"
          size="small"
          value={data.contratoExperienciaDtProrrogacao || null}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(date) => {
            const contratoExperienciaDtProrrogacao = date
            setData({
              ...data,
              contratoExperienciaDtProrrogacao,
            })
          }}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
        <DatePickerNew
          label="Término Contrato"
          size="small"
          value={data.dtTerminoContrato || null}
          onChange={(date) => {
            const dtTerminoContrato = date
            setData({ ...data, dtTerminoContrato })
          }}
        />
      </Grid>
      <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
        <TextField
          label="Descrição Justificativa Prorrogação de Contrato"
          fullWidth
          value={data?.justificativaProrrogacaoContrato || ''}
          size="small"
          inputProps={{
            maxLength: 100,
          }}
          onChange={(e) => {
            const justificativaProrrogacaoContrato = e?.target?.value
            setData({ ...data, justificativaProrrogacaoContrato })
          }}
        />
      </Grid>
      <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
        <SimpleHoraTextField
          label="Horas Semana"
          value={data?.horasSemana ? data.horasSemana.toFixed(2) : ''}
          onChange={(e) => {
            const horasSemana = Number.parseFloat(e.target.value.replace(':', '.'))
            setData((oldState) => ({ ...oldState, horasSemana }))
          }}
        />
      </Grid>
      <Grid item xl={10} lg={10} md={10} sm={8} xs={12}>
        <MUIAutoComplete
          label="Tipo de Jornada"
          options={JornadaTipoValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="tipoJornada"
          value={data.tipoJornada}
          onChange={(e, obj) => {
            const tipoJornada = obj ? obj.value : ''
            const descricaoTipoJornada = ''
            setData((oldState) => ({ ...oldState, tipoJornada, descricaoTipoJornada }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Descrição da Jornada"
          fullWidth
          value={data.descricaoTipoJornada || ''}
          size="small"
          inputProps={{
            maxLength: 100,
          }}
          name="descricaoTipoJornada"
          validationErrors={validationErrors}
          required={JornadaTipoEnum.DemaisTipos_9 === data?.tipoJornada}
          disabled={JornadaTipoEnum.DemaisTipos_9 !== data?.tipoJornada}
          onChange={(e) => {
            const descricaoTipoJornada = e.target.value
            setData({ ...data, descricaoTipoJornada })
          }}
        />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
        <MUIAutoComplete
          label="Jornada Parcial"
          options={jornadaTempoParcialValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="jornadaParcial"
          value={data.jornadaParcial}
          onChange={(e, obj) => {
            const jornadaParcial = obj ? obj.value : ''
            setData({ ...data, jornadaParcial })
          }}
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <Checkbox
          label="Horário Nortuno"
          value={data?.isHorarioNoturno || false}
          onChange={(e, value) => {
            const isHorarioNoturno = value
            setData({ ...data, isHorarioNoturno })
          }}
        />
      </Grid>

      {data.categoria === CategoriaEnum.Empregado_Aprendiz_103 && (
        <>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ContentDivider title="Aprendiz" />
          </Grid>

          <Grid item lg={4} md={12} sm={12} xs={12}>
            <MUIAutoComplete
              label="Indicador de Modalidade de Contratação"
              options={AprendizIndContratacaoValues}
              optionId="value"
              name="aprendizIndContratacao"
              validationErrors={validationErrors}
              required
              renderOption={(option) => option.name}
              value={data.aprendizIndContratacao}
              onChange={(e, obj) => {
                const aprendizIndContratacao = obj ? obj.value : ''
                setData({
                  ...data,
                  aprendizIndContratacao,
                  aprendizCNPJQualificadora: null,
                  aprendizTpInscricaoContratacao: TipoInscricaoEnum.NaoInformar_0,
                  aprendizNrInscricaoContratacao: null,
                })
              }}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <NumeroInscricaoTextField
              typeMask={TipoInscricaoEnum.CNPJ_1}
              label="CNPJ Qualificadora"
              value={data?.aprendizCNPJQualificadora || ''}
              disabled={
                data?.aprendizIndContratacao !== AprendizIndContratacaoEnum.Contratacao_Direta
              }
              required={
                data?.aprendizIndContratacao === AprendizIndContratacaoEnum.Contratacao_Direta
              }
              name="aprendizCNPJQualificadora"
              validationErrors={validationErrors}
              onChange={(e, value) => {
                const aprendizCNPJQualificadora = value || null
                setData((oldState) => ({
                  ...oldState,
                  aprendizCNPJQualificadora,
                }))
              }}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <NumeroInscricaoTextField
              typeMask={TipoInscricaoEnum.CNPJ_1}
              label="CNPJ Atividades Práticas"
              value={data?.aprendizCNPJAtividadesPraticas || ''}
              validationErrors={validationErrors}
              name="aprendizCNPJAtividadesPraticas"
              onChange={(e, value) => {
                const aprendizCNPJAtividadesPraticas = value || null
                setData((oldState) => ({
                  ...oldState,
                  aprendizCNPJAtividadesPraticas,
                }))
              }}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <MUIAutoComplete
              label="Tipo de Inscrição Contratante"
              options={tipoInscricaoValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data?.aprendizTpInscricaoContratacao || ''}
              required={
                data.aprendizIndContratacao === AprendizIndContratacaoEnum.Contratacao_Indireta
              }
              disabled={
                data.aprendizIndContratacao !== AprendizIndContratacaoEnum.Contratacao_Indireta
              }
              name="aprendizTpInscricaoContratacao"
              validationErrors={validationErrors}
              onChange={(e, obj) => {
                const aprendizTpInscricaoContratacao = obj?.value
                setData((oldState) => ({
                  ...oldState,
                  aprendizTpInscricaoContratacao,
                  aprendizNrInscricaoContratacao: null,
                }))
              }}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <NumeroInscricaoTextField
              required={
                data.aprendizIndContratacao === AprendizIndContratacaoEnum.Contratacao_Indireta
              }
              disabled={
                data.aprendizIndContratacao !== AprendizIndContratacaoEnum.Contratacao_Indireta
              }
              name="aprendizNrInscricaoContratacao"
              validationErrors={validationErrors}
              typeMask={data?.aprendizTpInscricaoContratacao || undefined}
              label="Número Inscrição Contratante"
              value={data?.aprendizNrInscricaoContratacao || ''}
              onChange={(e, value) => {
                const aprendizNrInscricaoContratacao = value || null
                setData((oldState) => ({
                  ...oldState,
                  aprendizNrInscricaoContratacao,
                }))
              }}
            />
          </Grid>
        </>
      )}

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
