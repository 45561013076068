import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { RPConsulta } from '../RP/RPConsulta'

export const queryObterRecibosComEventosImportados =
  '/ImportarEventosRP/ObterRecibosComEventosImportados'

interface RequestProps {
  anoMes: string
}

export default function useObterRecibosComEventosImportados(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: RPConsulta[] }>(queryObterRecibosComEventosImportados, {
      params,
    })
    return response.data.data
  }

  return useQuery([queryObterRecibosComEventosImportados, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
