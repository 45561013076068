import React, { useEffect, useState } from 'react'

import MUIAutoComplete from '../MUIAutoComplete'

import PropType from 'prop-types'
import api from '~/services/api-pessoal'

export default function AutoCompleteSetor(props) {
  const { empregadorId, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setOptions([])
  }, [empregadorId])

  const getOptions = async () => {
    setLoading(true)
    try {
      let url = '/Setor'
      if (empregadorId) url = `/Setor/${empregadorId}/SelecionarEmpregador`
      const response = await api.get(url)
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label="Setor"
      {...rest}
    />
  )
}

AutoCompleteSetor.propTypes = {
  onChange: PropType.func,
  value: PropType.any,
  required: PropType.bool,
  empregadorId: PropType.string,
  name: PropType.string,
  validationErrors: PropType.any,
}

AutoCompleteSetor.defaultProps = {
  optionId: 'id',
}
