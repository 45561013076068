import React, { useEffect, useState, memo } from 'react'

import api from '~/services/api-pessoal'

import { ButtonBox } from 'mio-library-ui'
import { makeStyles, Box, Grid } from '@material-ui/core'

import { ToolsDataTable, ActionDialog, DatePickerNew, Finder, Button, Checkbox } from '~/components'

import { useObterVinculosParaGerarEvento } from '~/hooks/queries/CondicaoAmbienteTrabalho/useObterVinculosParaGerarEvento'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoTipoEnum, VinculoTipoValues } from '~/@types/enums/VinculoTipoEnum'
import { formatToCPFOrCNPJ } from 'brazilian-values'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

function formatTipoVinculo(tipoRegistro: VinculoTipoEnum) {
  const objectFinded = VinculoTipoValues.find((obj) => obj.value === tipoRegistro)
  return objectFinded?.name || ''
}

const HEADER_HEIGHT = '55px'

const MemoToolsDataTable = memo(ToolsDataTable)

interface GerarEventoProps {
  isOpen: boolean
  onClose: () => void
  handleAfterSubmit: () => void
}

export default function GerarEvento(props: GerarEventoProps) {
  const { isOpen, onClose, handleAfterSubmit } = props

  const [isLoadingGenerate, setLoadingGenerate] = useState(false)
  const [dtCondicao, setDtCondicao] = useState<string | null>(null)
  const [isSomenteAdmitidosAnoMes, setSomenteAdmitidosAnoMes] = useState(false)
  const [query, setQuery] = useState('')

  const [selecteds, setSelecteds] = useState([])

  const classes = useStyles()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes, estabelecimento } = useAmbiente()

  const { data: _data, isLoading } = useObterVinculosParaGerarEvento(
    estabelecimento.id,
    anoMes,
    isSomenteAdmitidosAnoMes,
  )
  const data = _data
    ? _data.map((d) => ({
        ...d,
        nrInscricao: formatToCPFOrCNPJ(d.nrInscricao),
        tipoVinculo: formatTipoVinculo(d.tipoVinculo),
      }))
    : []

  useEffect(() => {
    setSelecteds([])
  }, [estabelecimento, anoMes, isSomenteAdmitidosAnoMes])

  const columns = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'tipoVinculo',
      label: 'Tipo de Vínculo',
    },
  ]

  async function handleSubmit() {
    if (!dtCondicao)
      return dialogNotification.warning({
        descriptions: ['Informe a Data da Condição'],
      })
    setLoadingGenerate(true)
    try {
      const ids = selecteds.map((indexCurrent) => data[indexCurrent]?.id)
      await api.post('/CondicaoAmbienteTrabalho/GerarEventoS2240', ids, {
        params: {
          dtCondicao,
        },
      })
      notification.success('Evento gerado com sucesso')
      handleAfterSubmit()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingGenerate(false)
    }
  }

  return (
    <ActionDialog
      title="Gerar - Evento S-2240"
      isOpen={isOpen}
      onCancelClick={onClose}
      onClose={onClose}
      customActions={
        <ButtonBox bottom={2} right={2}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            size="small"
            color="primary"
            onClick={handleSubmit}
            variant="contained"
            isLoading={isLoadingGenerate}
            disabled={selecteds.length > 0 ? false : true}
          >
            Gerar Evento
          </Button>
        </ButtonBox>
      }
      renderRight={<Finder onSearch={setQuery} onClose={() => setQuery('')} />}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        classes: {
          paper: classes.fullHeight,
        },
      }}
    >
      <Box height={HEADER_HEIGHT}>
        <Grid container spacing={1}>
          <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
            <Checkbox
              label="Somente Admitidos na Competência"
              value={isSomenteAdmitidosAnoMes}
              onChange={(e, value) => {
                setSomenteAdmitidosAnoMes(value)
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <DatePickerNew
              label="Data da Condição"
              value={dtCondicao}
              onChange={(date) => {
                const dtCondicao = date
                setDtCondicao(dtCondicao)
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <MemoToolsDataTable
        data={data}
        isLoading={isLoading}
        columns={columns}
        sherlock={{
          query,
          columns: ['nome', 'nrInscricao', 'tipoVinculo'],
        }}
        optionsSelectable={{
          type: 'multiple',
          onRowSelected: setSelecteds,
          selectOnClick: true,
          rowsSelected: selecteds,
        }}
      />
    </ActionDialog>
  )
}
