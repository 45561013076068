import React, { useState, useEffect } from 'react'

import MaskedTextField from '../MaskedTextField'
import ErrorMessage from '../ErrorMessage'

import PropTypes from 'prop-types'

const maskFixo = '(99) 9999-9999'
const maskCelular = '(99) 9 9999-9999'

const maskMaps = {
  fixo: maskFixo,
  celular: maskCelular,
}

const obterErro = (name, validationErrors) => {
  if (!validationErrors) return false
  const { inner } = validationErrors
  if (!inner) return false
  const erroEncontrado = inner.find((item) => {
    const { path } = item
    return name === path
  })
  if (!erroEncontrado) return false
  return erroEncontrado.message
}

export default function TelefoneTextField(props) {
  const { name, validationErrors, typeMask, onChange, value, ...rest } = props

  const mensagemDeErro = obterErro(name, validationErrors)

  const [mask, setMask] = useState(maskFixo)

  useEffect(() => {
    function changeMask() {
      if (typeMask) {
        const maskCurrent = maskMaps[typeMask]
        setMask(maskCurrent)
        return
      }
      if (value?.length > 10) setMask(maskCelular)
    }
    changeMask()
  }, [typeMask, value])

  const changeMaskOnBeforeValueChange = (newState, oldState, userInput) => {
    if (typeMask) {
      return {
        value: newState.value,
        selection: newState.selection,
      }
    }

    if (userInput) {
      //handle reach max field
      if (newState.value === oldState.value && mask !== maskCelular) {
        newState.value = newState.value + userInput
        newState.selection = {
          start: newState.selection.start + 2,
          end: newState.selection.end + 2,
        }
        setMask(maskCelular)
      }
    }
    //handle delete
    if (newState.value.length < oldState.value.length && mask !== maskFixo) {
      let newStart = newState.selection.start
      if (newStart <= 15) {
        setMask(maskFixo)
      }
    }

    return {
      value: newState.value,
      selection: newState.selection,
    }
  }

  function handleChange(event) {
    if (!onChange) return
    const valueFormated = event?.target?.value?.replace(/\D/g, '') || ''
    onChange(event, valueFormated)
  }

  return (
    <>
      <MaskedTextField
        mask={mask}
        beforeMaskedValueChange={changeMaskOnBeforeValueChange}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        size="small"
        value={value}
        {...rest}
      />
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </>
  )
}

TelefoneTextField.defaultProps = {
  label: 'Telefone',
  name: '',
  validationErrors: false,
}

TelefoneTextField.propTypes = {
  typeMask: PropTypes.oneOf(['fixo', 'celular']),
  name: PropTypes.string,
  validationErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}
