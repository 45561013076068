import { useState } from 'react'

import { formatCurrency, formatPrettyAnoMes } from '~/hooks/useUtils'

import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import {
  ActionDialog,
  Button,
  ToolsDataTable,
  ButtonBox,
  Finder,
  Stack,
  ContainerTable,
} from '~/components'

import Form from './Form'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import { useObterCustosPlanoSaudeTitular } from '~/hooks/queries/PlanoSaudeTitularCusto/useObterCustosPlanoSaudeTitular'
import { useDeletar } from '~/hooks/queries/PlanoSaudeTitularCusto/useDeletar'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { PlanoSaudeTitularCusto } from '~/hooks/queries/PlanoSaudeTitularCusto/dtos/PlanoSaudeTitularCusto'

interface DialogCustoTitularProps {
  isOpen: boolean
  onClose: () => void
  planoSaudeTitularId: string
}

export default function DialogCustoTitular({
  isOpen,
  onClose,
  planoSaudeTitularId,
}: DialogCustoTitularProps) {
  const [query, setQuery] = useState('')

  const { anoMes } = useAmbiente()

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<PlanoSaudeTitularCusto>({} as PlanoSaudeTitularCusto)

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterCustosPlanoSaudeTitular({
    params: {
      planoSaudeTitularId,
    },
  })
  const data = _d || []

  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } = useDeletar()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'anoMes',
      label: 'Ano/Mês',
      options: {
        customBodyRender: (value) => (value ? formatPrettyAnoMes(value) : ''),
      },
    },
    {
      name: 'vrCusto',
      label: 'Valor do Custo',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrCustoEmpresa',
      label: 'Valor do Custo da Empresa',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={async () => {
                  await mutateAsyncRemove(value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ActionDialog
      title="Custos do Plano de Saúde Titular"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
      }}
    >
      <Stack height="100%">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button
            variant="contained"
            onClick={() =>
              openForm({
                anoMes,
                planoSaudeTitularId,
              } as PlanoSaudeTitularCusto)
            }
          >
            Adicionar
          </Button>
        </ButtonBox>

        <ContainerTable>
          <ToolsDataTable
            data={data}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching || isRemoving}
            disableAutoHeight
            sherlock={{
              query,
              columns: ['anoMes'],
            }}
          />
        </ContainerTable>
      </Stack>

      {isOpenForm && <Form isOpen={isOpenForm} onClose={closeForm} initialData={dataForm} />}
    </ActionDialog>
  )
}
