import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { downloadFile, FileType } from '~/utils/utils'

interface RequestProps {
  data: string[]
  params: {
    estabelecimentoId: string
    anoMes: string
  }
}

export function useBaixarRelatoriosAdmissionais() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{
      data: {
        file: FileType
        errors: string[]
      }
    }>('/RelatorioAdmissional/BaixarRelatoriosAdmissionais', data, {
      params,
    })
    const file = response?.data?.data?.file || null
    const errors = response?.data?.data?.errors || null
    if (file) {
      downloadFile(file)
    }
    if (errors && errors.length > 0) {
      dialogNotification.warning({
        descriptions: errors,
      })
    }
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
