import { convertToArrayDescription } from '~/utils/utils'

export enum IndHorarioTrabalhoIntervaloEnum {
  Refeicao_00,
  Lanche_01,
}

export const IndHorarioTrabalhoIntervaloLabels = {
  [IndHorarioTrabalhoIntervaloEnum.Refeicao_00]: '0 - Refeição',
  [IndHorarioTrabalhoIntervaloEnum.Lanche_01]: '1 - Lanche',
}

export const IndHorarioTrabalhoIntervaloValues = convertToArrayDescription(
  IndHorarioTrabalhoIntervaloEnum,
  IndHorarioTrabalhoIntervaloLabels,
)
