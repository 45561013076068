export enum IndSubsPatronalObraEnum {
  NaoInformado_9 = 9,
  ContribuicaoSubstituida_1 = 1,
  ContribuicaoNaoSubstituida_2 = 2,
}

export const IndSubsPatronalObraValues = [
  { value: IndSubsPatronalObraEnum.NaoInformado_9, name: '9 - Não Informar' },
  {
    value: IndSubsPatronalObraEnum.ContribuicaoSubstituida_1,
    name: '1 - Contribuição Substituída',
  },
  {
    value: IndSubsPatronalObraEnum.ContribuicaoNaoSubstituida_2,
    name: '2 - Contribuição Não Substituída',
  },
]
