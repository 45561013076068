import { useEffect, useState } from 'react'

import { CircularProgress } from '@material-ui/core'
import {
  Button,
  ButtonBox,
  ContentDivider,
  ContentFrame,
  DatePickerNew,
  Fieldset,
  Stack,
} from '~/components'
import {
  AutoCompleteProcessoTrabalhista,
  AutoCompleteProcessoTrabalhistaVinculo,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import { useObterInformacoesVinculo } from '~/hooks/queries/ProcessoTrabalhistaManutencaoVinculo/useObterInformacoesVinculo'
import { useProcessarManutencaoRescisao } from '~/hooks/queries/ProcessoTrabalhistaManutencaoVinculo/useProcessarManutencaoRescisao'

import * as yup from 'yup'

import { ProcessoTrabalhistaManutencaoVinculoInformacoesDTO } from '~/hooks/queries/ProcessoTrabalhistaManutencaoVinculo/dtos/ProcessoTrabalhistaManutencaoVinculoInformacoesDTO'
import {
  IndMotivoDesligamentoEnum,
  IndMotivoDesligamentoValues,
} from '~/@types/enums/IndMotivoDesligamentoEnum'

const schema = yup.object().shape({
  processoTrabalhistaId: yup.string().required('Informe o Processo Trabalhista'),
  processoTrabalhistaVinculoId: yup.string().required('Informe o Processo Trabalhista Funcionário'),
  dtRescisao: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Rescisão'),
  motivoDesligamento: yup
    .mixed()
    .required('Informe o Motivo de Desligamento')
    .oneOf(Object.values(IndMotivoDesligamentoEnum), 'Informe o Motivo de Desligamento')
    .test(
      'validate',
      'Informe o Motivo de Desligamento',
      (value: IndMotivoDesligamentoEnum) => value !== IndMotivoDesligamentoEnum.NaoAplicavel_99,
    ),
})

interface DataType extends ProcessoTrabalhistaManutencaoVinculoInformacoesDTO {
  processoTrabalhistaId: string
}

export default function ManutencaoRescisao() {
  const [data, setData] = useState<DataType>({} as DataType)

  const { anoMes } = useAmbiente()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const {
    data: dataInfoVinculo,
    isLoading: _isLoading,
    isFetching: _isFetching,
  } = useObterInformacoesVinculo({
    params: {
      processoTrabalhistaVinculoId: data.processoTrabalhistaVinculoId,
    },
  })
  const isLoading = _isLoading || _isFetching
  const hasData = dataInfoVinculo ? true : false

  const { mutateAsync, isLoading: isSubmitting } = useProcessarManutencaoRescisao({
    params: {
      anoMes,
    },
  })

  useEffect(() => {
    if (dataInfoVinculo) {
      setData((prev) => ({ ...prev, ...dataInfoVinculo }))
    }
  }, [dataInfoVinculo])

  function handleReset() {
    setData({} as DataType)
  }

  async function handleSubmit() {
    await mutateAsync(data)
    handleReset()
  }

  return (
    <Stack>
      <AutoCompleteProcessoTrabalhista
        name="processoTrabalhistaId"
        validationErrors={validationErrors}
        required
        value={data.processoTrabalhistaId}
        onChange={(d) => {
          const processoTrabalhistaId = d?.id || ''
          setData({
            processoTrabalhistaId,
          } as DataType)
        }}
      />

      <AutoCompleteProcessoTrabalhistaVinculo
        name="processoTrabalhistaVinculoId"
        validationErrors={validationErrors}
        required
        processoTrabalhistaId={data.processoTrabalhistaId}
        value={data.processoTrabalhistaVinculoId}
        onChange={(d) => {
          const processoTrabalhistaVinculoId = d?.id || ''
          setData({
            processoTrabalhistaId: data.processoTrabalhistaId,
            processoTrabalhistaVinculoId,
          } as DataType)
        }}
      />

      <ContentFrame>
        <Fieldset disabled={hasData ? false : true}>
          <Stack>
            <ContentDivider
              title="Funcionário"
              showDivider={false}
              renderRight={<ButtonBox>{isLoading && <CircularProgress size={20} />}</ButtonBox>}
            />

            <DatePickerNew
              label="Data de Rescisão"
              value={data.dtRescisao || null}
              name="dtRescisao"
              validationErrors={validationErrors}
              required
              onChange={(date) => {
                const dtRescisao = date || ''
                setData((prev) => ({
                  ...prev,
                  dtRescisao,
                }))
              }}
            />

            <MUIAutoComplete
              label="Motivo do Desligamento"
              value={data.motivoDesligamento}
              options={IndMotivoDesligamentoValues}
              name="motivoDesligamento"
              validationErrors={validationErrors}
              renderOption={(option) => option.name}
              required
              optionId="value"
              onChange={(_, obj) => {
                const motivoDesligamento = obj?.value || ''
                setData((prev) => ({
                  ...prev,
                  motivoDesligamento,
                }))
              }}
            />
          </Stack>
        </Fieldset>
      </ContentFrame>

      <ButtonBox>
        <Button onClick={handleReset}>Cancelar</Button>
        <Button variant="contained" onClick={handleValidate} isLoading={isSubmitting}>
          Salvar
        </Button>
      </ButtonBox>
    </Stack>
  )
}
