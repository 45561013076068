import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoAfastamentoDataInicialDTO } from './dtos/VinculoAfastamentoDataInicialDTO'

export function useObterDataInicial() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoAfastamentoDataInicialDTO }>(
      '/VinculoAfastamento/ObterDataInicial',
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
