import React from 'react'

import moment from 'moment'

import { useTheme } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import {
  AddCircle as AddCircleIcon,
  Update as UpdateIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import { usePropriedadeLogListarTodosPorTabelaPai } from '~/hooks/queries/PropriedadeLog/usePropriedadeLogListarTodosPorTabelaPai'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { IndOperacaoAlteradaEnum } from '~/@types/enums/IndOperacaoAlteradaEnum'

interface TablePropsAlteradas {
  idTabelaPai: string
  query: string
}

export default function TablePropsAlteradas(props: TablePropsAlteradas) {
  const { idTabelaPai, query } = props

  const theme = useTheme()

  // function formatFields(prop?: string, valor?: string) {
  //   switch (prop) {
  //     case 'EstadoCivil':
  //       return formatEstadoCivil(valor)
  //     case 'RacaCor':
  //       return 'RacaCor ' + valor
  //     default:
  //       return valor
  //   }
  // }

  const { data: _data, isLoading } = usePropriedadeLogListarTodosPorTabelaPai(idTabelaPai)
  const data = _data
    ? _data.map((d) => ({
        ...d,
        dataOperacao: moment(d.dataOperacao).format('DD/MM/yyyy - HH:mm:ss'),
        // valorAntigo: formatFields(d?.prop, d.valorAntigo),
        // valorNovo: formatFields(d?.prop, d.valorNovo),
      }))
    : []

  const IconsComponent = {
    [IndOperacaoAlteradaEnum.Invalido]: 'Inválido',
    [IndOperacaoAlteradaEnum.Insercao]: (
      <AddCircleIcon
        style={{
          color: theme.palette.success.main,
        }}
        fontSize="small"
        titleAccess="Inserção"
      />
    ),
    [IndOperacaoAlteradaEnum.Delecao]: (
      <RemoveCircleIcon
        style={{
          color: theme.palette.error.main,
        }}
        fontSize="small"
        titleAccess="Deleção"
      />
    ),
    [IndOperacaoAlteradaEnum.Atualizacao]: (
      <UpdateIcon
        style={{
          color: theme.palette.warning.main,
        }}
        fontSize="small"
        titleAccess="Atualização"
      />
    ),
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'usuarioEmail',
      label: 'Usuário',
    },
    {
      name: 'dataOperacao',
      label: 'Data',
    },
    {
      name: 'tabelaNome',
      label: 'Tabela',
    },
    {
      name: 'propLabel',
      label: 'Campo',
    },
    {
      name: 'valorAntigo',
      label: 'Valor Antigo',
    },
    {
      name: 'valorNovo',
      label: 'Valor Novo',
    },
    {
      name: 'indOperacao',
      label: 'Operação',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value: IndOperacaoAlteradaEnum) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              {IconsComponent[value]}
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: [
          'usuarioEmail',
          'dataOperacao',
          'tabelaNome',
          'propLabel',
          'valorAntigo',
          'valorNovo',
        ],
      }}
    />
  )
}
