import React from 'react'

import { Grid } from '@material-ui/core'

import StepperForm from '~/components/StepperForm'

import FormAlteracaoCadastral from './FormAlteracaoCadastral'
import FormPessoaFisica from './FormPessoaFisica'
import FormPessoaFisicaDependente from './PessoaFisicaDependente'

import { useSalvarEvento2205 } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/useSalvarEvento2205'
import { useCriarEvento2205 } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/useCriarEvento2205'

import {
  VinculoAlteracaoContratoCadastro,
  VinculoAlteracaoContratoCadastroPartial,
} from '~/hooks/queries/VinculoAlteracaoContratoCadastro/VinculoAlteracaoContratoCadastro'
import { PessoaFisica, PessoaFisicaPartial } from '~/hooks/queries/PessoaFisica/PessoaFisica'
import {
  PessoaFisicaDependente,
  PessoaFisicaDependentePartial,
} from '~/hooks/queries/PessoaFisicaDependente/PessoaFisicaDependente'
import { VinculoAlteracaoCadastro2205DTO } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/VinculoAlteracaoCadastro2205DTO'

export interface MainDataForm {
  dataAlteracaoCadastral: VinculoAlteracaoContratoCadastroPartial
  dataPessoaFisica: PessoaFisicaPartial
  dataDependentes: PessoaFisicaDependentePartial[]
}

interface MainDataFormSubmit {
  dataAlteracaoCadastral: VinculoAlteracaoContratoCadastro
  dataPessoaFisica: PessoaFisica
  dataDependentes: PessoaFisicaDependente[]
}

interface FlowCreateProps {
  data: VinculoAlteracaoCadastro2205DTO
  onCancel: () => void
  isEdit: boolean
  isRetificar?: boolean
}

export default function FlowCreate(props: FlowCreateProps) {
  const { data, onCancel, isEdit, isRetificar } = props

  const { mutateAsync: salvarEvento2205, isLoading: isLoadingSalvar } = useSalvarEvento2205()
  const { mutateAsync: criarEvento2205, isLoading: isLoadingCriar } = useCriarEvento2205()

  const isLoading = isLoadingSalvar || isLoadingCriar

  const mainData: MainDataForm = {
    dataAlteracaoCadastral: data?.vinculoAlteracaoCadastro,
    dataPessoaFisica: data.pessoaFisica,
    dataDependentes: data.dependentes,
  }

  async function handleSubmit(_data: MainDataFormSubmit) {
    const data = {
      dependentes: _data.dataDependentes,
      pessoaFisica: _data.dataPessoaFisica,
      vinculoAlteracaoCadastro: _data.dataAlteracaoCadastral,
    }
    if (isEdit) {
      await salvarEvento2205({
        data,
        isRetificar: isRetificar || false,
      })
    } else {
      await criarEvento2205(data)
    }
    onCancel()
  }

  return (
    <StepperForm.Provider<MainDataForm>
      onSubmit={(dt) => handleSubmit(dt as MainDataFormSubmit)}
      onCancel={onCancel}
      data={mainData}
      isLoading={isLoading}
      disabled={isEdit && (isRetificar === undefined ? false : !isRetificar)}
      steps={[
        {
          label: 'Alteração Cadastral',
          children: <FormAlteracaoCadastral />,
          icon: 'assignment_ind',
        },
        {
          label: 'Pessoa Física',
          children: <FormPessoaFisica />,
          icon: 'person',
        },
        {
          label: 'Dependentes',
          children: <FormPessoaFisicaDependente />,
          icon: 'supervisor_account',
        },
      ]}
    >
      <Grid container spacing={0} style={{ marginBottom: '3rem' }}>
        <Grid item xs={1} md={2}>
          <StepperForm.Steps />
        </Grid>
        <Grid item xs={11} md={10}>
          <StepperForm.Content />
        </Grid>
      </Grid>
    </StepperForm.Provider>
  )
}
