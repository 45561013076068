import React from 'react'

import { Box, ButtonProps as MuiButtonProps, useTheme } from '@material-ui/core'
import { FaFilePdf } from 'react-icons/fa'

import Button from '../Button'

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean
}

export default function PDFButton(props: ButtonProps) {
  const { ...rest } = props

  const theme = useTheme()

  return (
    <Button size="small" variant="contained" color="primary" {...rest}>
      <Box display="flex" gridGap={theme.spacing(1)} alignItems="center">
        <FaFilePdf />
        PDF
      </Box>
    </Button>
  )
}
