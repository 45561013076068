import { useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew } from '~/components'

interface DialogConfirmProps {
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: (dtRecebimento?: string, dtDevolucao?: string) => void
}

export default function DialogConfirm({ isOpen, onClose, onAfterSubmit }: DialogConfirmProps) {
  const [data, setData] = useState<{
    dtRecebimento?: string
    dtDevolucao?: string
  }>()

  function handleSubmit() {
    onAfterSubmit(data?.dtRecebimento, data?.dtDevolucao)
    onClose()
  }

  return (
    <ActionDialog
      title="Informe as seguintes informações"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Recebimento"
            value={data?.dtRecebimento || null}
            onChange={(date) => {
              const dtRecebimento = date || ''
              setData((prevState) => ({ ...prevState, dtRecebimento }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Devolução"
            value={data?.dtDevolucao || null}
            onChange={(date) => {
              const dtDevolucao = date || ''
              setData((prevState) => ({ ...prevState, dtDevolucao }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
