import React from 'react'

import * as yup from 'yup'

import { Grid, LinearProgress } from '@material-ui/core'

import { ActionDialog, TextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import { useEstabilidadeById, useEstabilidadeMutation } from '~/hooks/queries/useEstabilidade'

const schema = yup.object().shape({
  descricao: yup.string().required('Informe a Descriçao'),
  numeroDias: yup.string().required('Informe o Nr. Dias'),
})

interface FormEstabilidadeProps {
  isOpen: boolean
  onClose: () => void
  estabilidadeId: string | null
}

export default function FormEstabilidade(props: FormEstabilidadeProps) {
  const { isOpen, onClose, estabilidadeId } = props

  const { data, setData, isLoading } = useEstabilidadeById(estabilidadeId)
  const { mutateAsync, isLoading: isSubmitting } = useEstabilidadeMutation()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Estabilidade"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          {typeof data.codigo === 'number' ? (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField label="Código" value={data.codigo} disabled />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item md={typeof data.codigo === 'number' ? 6 : 12} sm={12} xs={12}>
            <TextField
              label="Número de Dias"
              value={data.numeroDias || ''}
              autoFocus
              onlyNumber
              required
              validationErrors={validationErrors}
              inputProps={{
                maxLength: 5,
              }}
              name="numeroDias"
              onChange={(e) => {
                const numeroDias = parseInt(e.target.value || '0')
                setData((prev) => ({ ...prev, numeroDias }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Descrição"
              value={data.descricao || ''}
              inputProps={{
                maxLength: 100,
              }}
              required
              validationErrors={validationErrors}
              name="descricao"
              onChange={(e) => {
                const descricao = e.target.value
                setData((prev) => ({ ...prev, descricao }))
              }}
            />
          </Grid>
        </Grid>
      )}
    </ActionDialog>
  )
}
