const nao_informar_0 = 0
const sim_1 = 1
const nao_2 = 2

const sim_true = true
const nao_false = false

export const simNaoValues = [
  { name: 'Sim', value: sim_1 },
  { name: 'Não', value: nao_2 },
]

export const simNaoNullValues = [
  { name: 'Não informar', value: nao_informar_0 },
  { name: 'Sim', value: sim_1 },
  { name: 'Não', value: nao_2 },
]

export const simNaoBool = [
  { name: 'Sim', value: sim_true },
  { name: 'Não', value: nao_false },
]

export const simNaoConsts = {
  nao_informar_0,
  sim_1,
  nao_2,
  sim_true,
  nao_false,
}
