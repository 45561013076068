import { useState, useEffect, useRef, useCallback } from 'react'

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
} from '@material-ui/core'
import { Check as CheckIcon, Lock as LockIcon, LockOpen as LockOpenIcon } from '@material-ui/icons'

import { CurrencyTextField, Fieldset } from '~/components'
import { AutoCompleteEventoEmpregador } from '~/components/AutoComplete'

import DialogFaltas from '../../../DynamicMenu/components/DialogFaltas'

import { useRPInsereEvento } from '~/hooks/queries/RP/useRPInsereEvento'
import { useRPGetByCodigo } from '~/hooks/queries/RP/useRPGetByCodigo'

import { RP } from '~/hooks/queries/RP/RP'
import { EventoConsulta } from '~/hooks/queries/EventoConsulta/EventoConsulta'

import useKeyPress from '~/hooks/useKeyPress'
import useDialog from '~/hooks/useDialog'

import { EventoIndCalculoEnum } from '~/@types/enums/EventoIndCalculoEnum'

const EVENTO_FALTA = 185
const EVENTO_FALTAS_POR_HORA = 186
const EVENTO_SUSPENSAO = 189

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}))

export interface FormDataProps {
  id: string | null
  codigo: number | null
  referencia: number | null
  valor: number | null
  indCalculo: EventoIndCalculoEnum | null
  complemento: string | null
  isCalculoManual: boolean | null
}

const initialState: FormDataProps = {
  id: null,
  codigo: null,
  referencia: null,
  valor: null,
  indCalculo: null,
  complemento: null,
  isCalculoManual: null,
}

interface FormProps {
  data: FormDataProps
  reciboPagamento: RP | null
  onAfterSubmitEvento: () => void
}

export default function Form(props: FormProps) {
  const { data: _data, reciboPagamento, onAfterSubmitEvento } = props

  const [data, setData] = useState(initialState)

  const { mutateAsync: mutateAsyncInsereEvento, isLoading: isLoadingInsereEvento } =
    useRPInsereEvento()
  const { mutateAsync: mutateAsyncGetEvento, isLoading: isLoadingGetEvento } = useRPGetByCodigo()

  const classes = useStyles()
  const {
    close: closeDialogFaltas,
    data: dataDialogFaltas,
    isOpen: isOpenDialogFaltas,
    open: openDialogFaltas,
  } = useDialog<RP | null>(null)

  const eventoRef = useRef<HTMLInputElement>(null)
  const referenciaRef = useRef<FixLater>(null)
  const valorRef = useRef<FixLater>(null)
  const btnEventoRef = useRef<HTMLButtonElement>(null)

  const disabledAllFields = reciboPagamento?.isFinalizado ? true : false || !reciboPagamento?.id
  const isCalculoValorOuManual =
    data.indCalculo === EventoIndCalculoEnum.Valor || _data.isCalculoManual ? true : false
  const isEventoSelecionado = data.codigo ? true : false

  const isCalculoManual = data?.isCalculoManual ? true : false

  const isEnableReferencia =
    data?.indCalculo === EventoIndCalculoEnum.Referencia ||
    data?.indCalculo === EventoIndCalculoEnum.Valor ||
    isCalculoManual

  const isEnableValor = data?.indCalculo === EventoIndCalculoEnum.Valor || isCalculoManual

  const rpId = reciboPagamento?.id || null

  useEffect(() => {
    setData({ ...initialState, ..._data })
  }, [_data])

  const nextInputFocus = useCallback(() => {
    const eventoInput = eventoRef?.current || null
    const referenciaInput = referenciaRef?.current?.input || null
    const valorInput = valorRef?.current?.input || null
    const btnEvento = btnEventoRef?.current || null

    if (eventoInput && !eventoInput.value && !eventoInput.disabled) {
      eventoInput.focus()
      return
    }

    if (referenciaInput && !referenciaInput.value && !referenciaInput.disabled) {
      referenciaInput.focus()
      return
    }
    if (valorInput && !valorInput.value && !valorInput.disabled) {
      valorInput.focus()
      return
    }
    if (btnEvento && !btnEvento.disabled) {
      btnEvento.focus()
      return
    }
  }, [])

  useEffect(() => {
    setData(initialState)
    nextInputFocus()
  }, [reciboPagamento, nextInputFocus])

  useKeyPress('F2', () => nextInputFocus())

  function obterEventoExistenteRecibo(eventoCodigo: number): FormDataProps | null {
    const rpItens = reciboPagamento?.rpItens || []

    const rpItem = rpItens.find((d) => d?.eventoEmpregador?.evento?.codigo === eventoCodigo)

    if (!rpItem) {
      return null
    }

    return {
      id: rpItem?.eventoEmpregadorId || null,
      codigo: rpItem?.eventoEmpregador?.evento?.codigo || null,
      referencia: rpItem?.quantidade || null,
      valor: rpItem?.vrDesconto ? rpItem.vrDesconto : rpItem?.vrVencimento || null,
      indCalculo: rpItem?.eventoEmpregador?.evento?.indCalculo || null,
      complemento: rpItem?.complemento || null,
      isCalculoManual: rpItem?.isCalculoManual || null,
    }
  }

  async function onChangeEvento(eventoConsulta: EventoConsulta | null) {
    if (!eventoConsulta?.codigo || !rpId) {
      setData(initialState)
      return
    }

    if (
      eventoConsulta.codigo === EVENTO_FALTA ||
      eventoConsulta.codigo === EVENTO_FALTAS_POR_HORA ||
      eventoConsulta.codigo === EVENTO_SUSPENSAO
    ) {
      openDialogFaltas(reciboPagamento)
      return
    }

    const eventoExistenteRecibo = obterEventoExistenteRecibo(eventoConsulta.codigo)

    if (eventoExistenteRecibo) {
      setData(eventoExistenteRecibo)
      return
    }

    const eventoEmpregador = await mutateAsyncGetEvento({
      rpId,
      codigo: eventoConsulta.codigo,
    })

    setData({
      id: eventoEmpregador.id,
      codigo: eventoEmpregador.evento.codigo,
      indCalculo: eventoEmpregador.evento.indCalculo,
      isCalculoManual: null,
      referencia: null,
      complemento: null,
      valor: null,
    })
  }

  const handleSubmit = async () => {
    if (!rpId) return
    await mutateAsyncInsereEvento({
      rpId,
      eventoEmpregadorId: data?.id as string,
      isCalculoManual: data?.isCalculoManual,
      complemento: data?.complemento,
      referencia: data?.referencia,
      valor: data?.valor,
    })
    onAfterSubmitEvento()
    setData(initialState)
  }

  function onPressEnter(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      nextInputFocus()
    }
  }

  return (
    <Box width="100%">
      <Fieldset disabled={disabledAllFields}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <AutoCompleteEventoEmpregador
              inputRef={eventoRef}
              onKeyDown={onPressEnter}
              value={data?.codigo || null}
              onChange={onChangeEvento}
              InputLabelProps={{ shrink: true }}
              autoFocus
              variant="standard"
              InputProps={{
                startAdornment: isLoadingGetEvento && (
                  <InputAdornment position="end">
                    <CircularProgress size={16} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={2}>
            <CurrencyTextField
              ref={referenciaRef}
              label="Referência"
              variant="standard"
              onKeyDown={onPressEnter}
              value={data?.referencia || ''}
              onChange={(e, value) => {
                const referencia = value || null
                setData((prev) => ({ ...prev, referencia }))
              }}
              InputLabelProps={{ shrink: true }}
              maximumValue="9999"
              disabled={isEnableReferencia ? false : true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={2}>
            <CurrencyTextField
              ref={valorRef}
              label="Valor"
              variant="standard"
              onKeyDown={onPressEnter}
              value={data?.valor || ''}
              onChange={(e, value) => {
                const valor = value || null
                setData((prev) => ({ ...prev, valor }))
              }}
              InputLabelProps={{ shrink: true }}
              maximumValue="999999999999"
              disabled={isEnableValor ? false : true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={2}>
            <Box className={classes.actions}>
              <IconButton
                size="small"
                title="Mudar para Cálculo Manual"
                disabled={isCalculoValorOuManual}
                color="primary"
                onClick={() => {
                  setData((oldState) => ({
                    ...oldState,
                    isCalculoManual: !oldState?.isCalculoManual,
                  }))
                }}
              >
                {data?.isCalculoManual ? (
                  <LockOpenIcon
                    fontSize="small"
                    color={_data?.isCalculoManual ? 'error' : 'primary'}
                  />
                ) : (
                  <LockIcon fontSize="small" />
                )}
              </IconButton>

              <IconButton
                color="primary"
                ref={btnEventoRef}
                size="small"
                onClick={handleSubmit}
                disabled={!isEventoSelecionado}
              >
                {isLoadingInsereEvento ? (
                  <CircularProgress size={16} />
                ) : (
                  <CheckIcon fontSize="small" />
                )}
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Fieldset>

      <DialogFaltas
        isOpen={isOpenDialogFaltas}
        onClose={() => {
          closeDialogFaltas()
          onAfterSubmitEvento()
        }}
        reciboPagamento={dataDialogFaltas}
      />
    </Box>
  )
}
