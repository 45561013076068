import React, { useState, useRef, useEffect, useCallback, memo } from 'react'

import { Box, Button, Typography, IconButton, Collapse, Grid, makeStyles } from '@material-ui/core'
import { PageHeader, Finder, ButtonBox } from 'mio-library-ui'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'

import Table from './components/Table'
import DialogTabelaMes from '~/components/DialogTabelaMes'
import ResumoCompetencia from './components/ResumoCompetencia'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useSocket from '~/hooks/useSocket'
import { haveTabelaMesInAnoMes } from '~/hooks/useUtils'

import api from '~/services/api-pessoal'
import { AlertContainer, ActionDialog } from '~/components'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  paddingCollapse: {
    paddingBottom: theme.spacing(1),
  },
  contentAlert: {
    width: '100%',
  },
}))

const MemoTable = memo(Table)

const ProcessarCompetencia = () => {
  const [items, setItems] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [perguntaAbrirCompetencia, setPerguntaAbrirCompetencia] = useState({
    isOpen: false,
  })
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)
  const [selectedItems, setSelectedItems] = useState({ indexs: [], data: [] })
  const [resumo, setResumo] = useState({
    open: false,
    id: '',
  })

  const notifications = useNotification()
  const dialogNotification = useDialogNotification()
  const { anoMes, empresaId } = useAmbiente()
  const {
    close: closeDialogTabelaMes,
    open: openDialogTabelaMes,
    isOpen: isOpenDialogTabelaMes,
    data: dataDialogTabelaMes,
  } = useDialog()
  const classes = useStyles()

  const previousItems = useRef(null)
  previousItems.current = items

  const handleCompetenciaUpdate = (message) => {
    const nItem = JSON.parse(message)
    const nItens = previousItems.current.map((i) => {
      return i.id === nItem.id ? nItem : i
    })
    setItems(nItens)
  }

  useSocket({
    urlHub: '/CompetenciaHub',
    onStartConnection: (socket) => {
      socket.send('RemoveFromGroup', empresaId)
      socket.send('AddToGroup', empresaId)
      socket.on('CompetenciaUpdated', handleCompetenciaUpdate)
    },
  })

  const getItems = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get(`competenciaempregador/GetByAnoMes?anoMes=${anoMes}`)
      if (response.data.data) {
        setItems(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setLoading(false)
    //eslint-disable-next-line
  }, [anoMes])

  useEffect(() => {
    const getCompetencia = async () => {
      setLoading(true)
      try {
        const response = await api.get(`competenciaempregador/GetByAnoMes?anoMes=${anoMes}`)
        setItems([])
        setSelectedItems({ indexs: [], data: [] })
        if (response.data.data.length === 0) {
          setPerguntaAbrirCompetencia({ isOpen: true })
        } else {
          setItems(response.data.data)
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setLoading(false)
    }
    getCompetencia()
    //eslint-disable-next-line
  }, [anoMes])

  const verifyWasSelectedItems = () => {
    if (selectedItems?.data?.length === 0) {
      notifications.error('Por favor informe pelo menos um Estabelecimento')
      return true
    }
  }

  const handleAbrirCompetencia = async () => {
    try {
      await api.post(`competenciaempregador/AbrirCompetencia?anoMes=${anoMes}`)
      notifications.success('A Competência foi aberta com sucesso!')
      await getItems()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  const handleValidar = async () => {
    if (verifyWasSelectedItems()) return
    setLoading(true)
    try {
      const arrayIds = selectedItems.data.map((item) => item.id)
      await api.post(`competenciaempregador/Validar`, arrayIds)
      notifications.success('O processo de validação foi iniciado com sucesso! Aguarde...')
      setSelectedItems({ indexs: [], data: [] })
      await getItems()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setLoading(false)
  }

  const handleIniciar = async () => {
    if (verifyWasSelectedItems()) return
    setLoading(true)
    try {
      const arrayIds = selectedItems.data.map((item) => item.id)
      await api.post(`competenciaempregador/iniciar`, arrayIds)
      notifications.success('O processo de inicialização foi iniciado com sucesso! Aguarde...')
      setSelectedItems({ indexs: [], data: [] })
      await getItems()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setLoading(false)
  }

  const handleFinalizar = async () => {
    //if (verifyWasSelectedItems()) return
    // setLoading(true);
    // try {
    // const arrayIds = selectedItems.data.map((item) => item.id)
    //   await api.post(
    //     `competenciaempregador/finalizar`,
    //     arrayIds,
    //   );
    //   notifications.success('O processo de finalização foi iniciado com sucesso! Aguarde...')
    //   setSelectedItems({ indexs: [], data: [] })
    //   await getItems();
    // } catch (err) {
    //  dialogNotification.extractErrors(err)
    // }
    // setLoading(false);
  }

  const handleResumo = (id) => {
    setResumo({
      open: true,
      id,
    })
  }

  const handleCloseResumo = () => {
    setResumo({
      open: false,
      id: '',
    })
  }

  const handleRowSelected = useCallback(
    (rowsSelected) => {
      const list = []
      rowsSelected.forEach((rowIndex) => {
        list.push(items[rowIndex])
      })
      setSelectedItems({ indexs: rowsSelected, data: list })
    },
    [items],
  )

  const handleClosePerguntaAbrirCompetencia = () => {
    setPerguntaAbrirCompetencia({ isOpen: false })
  }

  function handleCloseDialogTabelaMesError() {
    closeDialogTabelaMes()
    setPerguntaAbrirCompetencia({ isOpen: true })
  }

  async function verificarTabelaMesExisteCompetenciaAtual() {
    const haveTabelaMes = await haveTabelaMesInAnoMes(anoMes)

    if (!haveTabelaMes) {
      throw dialogNotification.warning({
        descriptions: ['Não foi encontrado a Tabela Mês para a competência atual'],
        onConfirm: () => openDialogTabelaMes(),
        labelOnConfirm: 'Abrir Tabela Mês',
      })
    }
  }

  function alertarSeAnoMesAntecedeDataAtual() {
    const anoMesCurrent = moment().format('YYYYMM')
    if (anoMes < anoMesCurrent) {
      throw dialogNotification.warning({
        descriptions: [
          'O Ano/Mês do ambiente antecede o Ano/Mês da data atual, deseja confirmar a abertura da competência?',
        ],
        onConfirm: () => handleAbrirCompetencia(),
        title: 'Confirme a solicitação',
        labelOnConfirm: 'Confirmar',
      })
    }
  }

  async function confirmAbrirCompetencia() {
    setLoading(true)
    try {
      handleClosePerguntaAbrirCompetencia()

      await verificarTabelaMesExisteCompetenciaAtual()
      alertarSeAnoMesAntecedeDataAtual()

      handleAbrirCompetencia()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box id="root-header">
        <PageHeader title="Processar Competência">
          <Finder
            onSearch={(value) => {
              setQuery(value)
            }}
            searching={isLoading}
            onClose={() => {
              setQuery('')
            }}
          />
          <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
            {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
          </IconButton>
        </PageHeader>

        <Collapse in={formCollapseIsOpen} className={classes.paddingCollapse}>
          <AlertContainer>
            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                {selectedItems?.data?.length === 0 ? (
                  'Selecione os Estabelecimentos para Processar a Competência'
                ) : (
                  <div>
                    <strong>{`${selectedItems.data.length || 0}/${items?.length || 0}`}</strong> -
                    Estabelecimentos Selecionados
                  </div>
                )}
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <ButtonBox>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => setPerguntaAbrirCompetencia({ isOpen: true })}
                  >
                    Abrir
                  </Button>
                  <Button size="small" color="primary" variant="contained" onClick={handleValidar}>
                    Validar
                  </Button>
                  <Button size="small" color="primary" variant="contained" onClick={handleIniciar}>
                    Iniciar
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleFinalizar}
                  >
                    Finalizar
                  </Button>
                </ButtonBox>
              </Grid>
            </Grid>
          </AlertContainer>
        </Collapse>
      </Box>

      <MemoTable
        data={items}
        isLoading={isLoading}
        query={query}
        onResumoClick={handleResumo}
        onRowSelected={handleRowSelected}
        rowsSelecteds={selectedItems.indexs}
        triggersHeight={{ formCollapseIsOpen }}
      />

      <ActionDialog
        title="Resumo Competência"
        isOpen={resumo.open}
        onClose={handleCloseResumo}
        onCancelClick={handleCloseResumo}
        customActions={<></>}
        dialogProps={{ maxWidth: 'lg', fullWidth: true }}
      >
        <ResumoCompetencia competenciaEstabelecimentoId={resumo.id} />
      </ActionDialog>

      <ActionDialog
        title="Abrir Competência"
        okLabel="Sim"
        isOpen={perguntaAbrirCompetencia.isOpen}
        onClose={handleClosePerguntaAbrirCompetencia}
        onOkClick={confirmAbrirCompetencia}
        onCancelClick={handleClosePerguntaAbrirCompetencia}
      >
        <Typography>Deseja abrir a Competência?</Typography>
      </ActionDialog>

      <DialogTabelaMes
        isOpen={isOpenDialogTabelaMes}
        onClose={handleCloseDialogTabelaMesError}
        data={dataDialogTabelaMes}
      />
    </Box>
  )
}

export default ProcessarCompetencia
