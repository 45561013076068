import { useEffect, useState } from 'react'

import { Button, ButtonBox, Finder, PageHeader, TreeView } from '~/components'

import Table from './Table'
import Form from './Form'
import DialogRelatorio from './DialogRelatorio'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { VinculoSuspensaoDTO } from '~/hooks/queries/VinculoSuspensao/dtos/VinculoSuspensaoDTO'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

export const PAGE_TITLE = 'Suspensões do Funcionário'

export default function VinculoSuspensaoPage() {
  const [vinculo, setVinculo] = useState<VinculoMenuData | null>(null)
  const vinculoId = vinculo?.vinculoId

  const [query, setQuery] = useState('')

  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])

  const { anoMes, estabelecimento } = useAmbiente()
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoSuspensaoDTO>({
    id: '',
    vinculoId: '',
    dtSuspensao: '',
    motivoSuspensao: '',
    motivoCodigo: 0,
    motivoId: '',
    motivoNome: '',
    dtRetorno: '',
    nrDiasSuspensao: 0,
  })
  const {
    close: closeDialogRelatorio,
    data: dataDialogRelatorio,
    isOpen: isOpenDialogRelatorio,
    open: openDialogRelatorio,
  } = useDialog('')

  useEffect(() => {
    setVinculo(null)
  }, [estabelecimento, anoMes])

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e: string, vinculo: VinculoMenuData) => setVinculo(vinculo)}
      searchBy="vinculoNome"
      renderOption={(option: VinculoMenuData) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
    >
      <PageHeader title={PAGE_TITLE} subtitle={vinculo?.vinculoNome || '-'}>
        {vinculoId && (
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <Button
              variant="contained"
              onClick={() =>
                openForm({
                  id: '',
                  vinculoId,
                  dtSuspensao: '',
                  motivoSuspensao: '',
                  motivoCodigo: 0,
                  motivoId: '',
                  motivoNome: '',
                  dtRetorno: '',
                  nrDiasSuspensao: 0,
                })
              }
            >
              Adicionar
            </Button>
          </ButtonBox>
        )}
      </PageHeader>
      {vinculoId && (
        <>
          <Table
            query={query}
            onClickEdit={openForm}
            onClickPrint={openDialogRelatorio}
            vinculoId={vinculoId}
          />
          {isOpenForm && (
            <Form
              isOpen={isOpenForm}
              data={dataForm}
              onClose={closeForm}
              subtitle={vinculo?.vinculoNome || '-'}
            />
          )}
          {isOpenDialogRelatorio && (
            <DialogRelatorio
              isOpen={isOpenDialogRelatorio}
              vinculoSuspensaoId={dataDialogRelatorio}
              onClose={closeDialogRelatorio}
              subtitle={vinculo?.vinculoNome || '-'}
            />
          )}
        </>
      )}
    </TreeView>
  )
}
