import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestProps {
  estabelecimentoId: string
  modeloPlanilhaId: string
  anoMes: string
}

export default function useGerarPlanilhaEnviarEmail() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(params: RequestProps) {
    await api.post('/ImportarEventosRP/GerarPlanilhaEnviarEmail', null, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () =>
      notification.success('Planilha enviada para o email do Estabelecimento com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
