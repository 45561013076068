import React, { useEffect, useState } from 'react'

import { Button, Finder } from 'mio-library-ui'
import { Box, Grid, Paper } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer, AnoMesTextField } from '~/components'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

const PAGE_TITLE = 'Vale Transporte Declaração de Uso'

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

export default function ValeTransporteDeclaracaoUso() {
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelected] = useState([])

  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  const [periodo, setPeriodo] = useState({
    anoMesInicio: anoMes,
    anoMesFim: anoMes,
  })

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data: periodo,
    handleSubmit: getCollection,
  })

  useEffect(() => {
    setRowsSelected([])
    setCollection([])
  }, [estabelecimento])

  async function getCollection() {
    setLoading(true)
    try {
      const response = await api.get(
        '/Relatorio/ValeTransnporteDeclaracaoUso/ObterVinculosPeriodo',
        {
          params: {
            estabelecimentoId: estabelecimento.id,
            anoMesInicio: periodo.anoMesInicio,
            anoMesFim: periodo.anoMesFim,
          },
        },
      )
      setRowsSelected([])
      setCollection(response?.data?.data || [])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  function handlePrint() {
    openDialogConfirm()
  }

  function handleAfterSubmitDialogConfirm(dt) {
    const vinculosIds = rowsSelecteds.map((indexCurrent) => collection[indexCurrent].id)
    const newData = {
      vinculosIds,
      ...dt,
    }
    setRowsSelected([])
    openPDFViewer(newData)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={PAGE_TITLE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} padding={2}>
        <Grid container spacing={2}>
          <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
            <AnoMesTextField
              label="Ano/Mês Início"
              value={periodo.anoMesInicio}
              required
              name="anoMesInicio"
              validationErrors={validationErrors}
              onChange={(e) =>
                setPeriodo((prev) => ({
                  ...prev,
                  anoMesInicio: e?.target?.value?.replace('/', '') || '',
                }))
              }
            />
          </Grid>
          <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
            <AnoMesTextField
              label="Ano/Mês Fim"
              value={periodo.anoMesFim}
              required
              name="anoMesFim"
              validationErrors={validationErrors}
              onChange={(e) =>
                setPeriodo((prev) => ({
                  ...prev,
                  anoMesFim: e?.target?.value?.replace('/', '') || '',
                }))
              }
            />
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              isLoading={isLoading}
              onClick={() => handleValidate()}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Table
        isLoading={isLoading}
        query={query}
        data={collection}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelecteds}
      />

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        onClose={closeDialogConfirm}
        onAfterSubmit={handleAfterSubmitDialogConfirm}
      />

      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={handlePrint} disabled={!(rowsSelecteds.length > 0)} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="ValeTransnporteDeclaracaoUso"
        title={PAGE_TITLE}
        isSalvarDocumento
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/ValeTransnporteDeclaracaoUso/ObterRelatorio',
          data: dataPDFViewer,
          params: {
            estabelecimentoId: estabelecimento.id,
            anoMes,
          },
        }}
      />
    </Box>
  )
}
