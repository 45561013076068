import React, { useState, useEffect, useRef } from 'react'

import {
  ListItem,
  Checkbox,
  ListItemText,
  Box,
  CircularProgress,
  useTheme,
} from '@material-ui/core'
import { AutoSizer, List } from 'react-virtualized'
import PropTypes from 'prop-types'

import useHeightParents from '~/hooks/useHeightParents'

import { Header } from './components/Header'
import { SearchTextField } from './components/SearchTextField'

export const HEIGHT_ITEM_LIST = 50

function rowRenderer(params) {
  const { props, data } = params

  const { index, style } = props

  const {
    displayOptions,
    handleChange,
    optionsChecked,
    renderPrimaryOption,
    renderSecondaryOption,
  } = data
  const elementCurrent = displayOptions[index]
  return (
    <ListItem
      key={index}
      role={undefined}
      dense
      button
      onClick={() => handleChange(elementCurrent)}
      style={style}
    >
      <Checkbox
        edge="start"
        checked={optionsChecked.some((dt) => JSON.stringify(dt) === JSON.stringify(elementCurrent))}
        tabIndex={-1}
        disableRipple
      />
      <ListItemText
        primary={
          <Box fontSize="10pt" color="black">
            {renderPrimaryOption(elementCurrent)}
          </Box>
        }
        secondary={renderSecondaryOption(elementCurrent)}
      />
    </ListItem>
  )
}

export default function CheckList(props) {
  const {
    options,
    onChange,
    renderPrimaryOption,
    renderSecondaryOption,
    isLoading,
    searchBy,
    optionsCheckeds,
  } = props

  const [query, setQuery] = useState('')

  const [optionsChecked, setOptionsChecked] = useState([])
  const [displayOptions, setDisplayOptions] = useState([])

  const refRoot = useRef()

  const theme = useTheme()
  useHeightParents({
    refElement: refRoot,
  })

  useEffect(() => {
    setDisplayOptions(options)
  }, [options])

  useEffect(() => {
    if (!optionsCheckeds) return
    setOptionsChecked(optionsCheckeds)
  }, [optionsCheckeds])

  function handleChange(element) {
    const newArray = [...optionsChecked]
    const indexFinded = optionsChecked.findIndex(
      (elem) => JSON.stringify(elem) === JSON.stringify(element),
    )

    if (indexFinded !== -1) {
      newArray.splice(indexFinded, 1)
    } else {
      newArray.push(element)
    }

    onChange(newArray)
    setOptionsChecked(newArray)
  }

  function toggleAll() {
    if (optionsChecked.length > 0 && optionsChecked.length !== options.length) {
      setOptionsChecked([])
      return onChange([])
    }
    if (optionsChecked.length === options.length) {
      setOptionsChecked([])
      return onChange([])
    }
    setOptionsChecked(options)
    onChange(options)
  }

  function handleSearch(q) {
    if (!q) {
      setQuery('')
      setDisplayOptions(options)
      return
    }

    setQuery(q)

    const query = q.toLowerCase()

    const newData = []

    if (searchBy.length > 0 && typeof searchBy === 'object') {
      searchBy.forEach((field) => {
        options.forEach((item) => {
          if (item[field].toString().toLowerCase().includes(query)) newData.push(item)
        })
      })
    } else {
      options.forEach((item) => {
        if (item[searchBy].toString().toLowerCase().includes(query)) newData.push(item)
      })
    }
    setDisplayOptions(newData)
  }

  function changeContent(height, width) {
    if (isLoading) return <CircularProgress size={50} />

    if (displayOptions.length === 0)
      return (
        <Box display="flex" justifyContent="center" pt={1}>
          Não foram encontrados registros
        </Box>
      )

    return (
      <List
        height={height}
        width={width}
        rowHeight={HEIGHT_ITEM_LIST}
        rowCount={displayOptions.length}
        rowRenderer={(props) =>
          rowRenderer({
            props,
            data: {
              displayOptions,
              handleChange,
              optionsChecked,
              renderPrimaryOption,
              renderSecondaryOption,
            },
          })
        }
      />
    )
  }

  return (
    <Box
      style={{
        border: theme.shape.border,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        minHeight: '300px',
      }}
      ref={refRoot}
    >
      <AutoSizer>
        {({ width, height: oldHeight }) => {
          const height = oldHeight - (searchBy ? HEIGHT_ITEM_LIST * 2 : HEIGHT_ITEM_LIST) || 0
          return (
            <>
              <Header
                toggleAll={toggleAll}
                disabled={isLoading || options.length === 0 ? true : false}
                optionsLength={options.length}
                optionsCheckedsLength={optionsChecked.length}
                width={width}
              />
              <Box
                style={{
                  height,
                  width,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {changeContent(height, width)}
              </Box>
              <SearchTextField
                onChange={handleSearch}
                value={query || ''}
                disabled={isLoading}
                show={searchBy ? true : false}
                width={width}
              />
            </>
          )
        }}
      </AutoSizer>
    </Box>
  )
}

CheckList.defaultProps = {
  options: [],
  onChange: () => undefined,
  renderPrimaryOption: () => undefined,
  renderSecondaryOption: () => undefined,
  isLoading: false,
  searchBy: '',
  optionsCheckeds: [],
}

CheckList.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  renderPrimaryOption: PropTypes.func,
  renderSecondaryOption: PropTypes.func,
  isLoading: PropTypes.bool,
  searchBy: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  optionsCheckeds: PropTypes.array,
}
