import React, { useEffect, useState } from 'react'

import { TextField, Box, makeStyles, InputAdornment } from '@material-ui/core'
import { SubdirectoryArrowLeft } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  rootBox: {
    fontSize: theme.typography.fontSize,
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const TextEditableTable = (props) => {
  const { value: _value, onChange } = props

  const [value, setValue] = useState('')
  const [oldValue, setOldValue] = useState('')
  const [isSelect, setSelect] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    setOldValue(_value)
    setValue(_value)
  }, [_value])

  const handleEscape = () => {
    setValue(oldValue)
    setSelect(false)
  }

  const handleEnter = () => {
    onChange(value)
    setSelect(false)
  }

  const handleKeyDown = ({ key }) => {
    if (key === 'Escape') handleEscape()
    if (key === 'Enter') handleEnter()
  }

  if (!isSelect)
    return (
      <Box className={classes.rootBox} onClick={() => setSelect(true)}>
        {value || '...'}
      </Box>
    )
  return (
    <TextField
      variant="outlined"
      size="small"
      autoFocus
      fullWidth
      onKeyDown={handleKeyDown}
      onBlur={() => handleEscape()}
      value={value}
      onChange={(e) => setValue(e?.target?.value || '')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SubdirectoryArrowLeft fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default TextEditableTable
