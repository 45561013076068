import React, { useState, useEffect, useCallback } from 'react'

import { Checkbox, Grid, MenuItem, FormControlLabel } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker, NumeroInscricaoTextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

import { grauParentescoValues } from '~/values/grauParentescoValues'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

const { CPF_2 } = tipoInscricaoConsts

const schema = yup.object().shape({
  grauParentesco: yup.string().required('Informe o Grau Parentesco'),
  nome: yup.string().required('Informe o Nome'),
  dtNascimento: yup
    .date()
    .required('Informe a Data de Nascimento')
    .typeError('Informe uma data válida')
    .nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/pessoaFisicaDependente/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/pessoaFisicaDependente', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Pessoa Física | Dependentes"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Grau Parentesco"
            options={grauParentescoValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.grauParentesco}
            required
            validationErrors={validationErrors}
            name="grauParentesco"
            onChange={(e, obj) => {
              const grauParentesco = obj ? obj.value : ''
              setData({ ...data, grauParentesco })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data.nome || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 100,
            }}
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePicker
            label="Data Nascimento"
            size="small"
            value={data.dtNascimento || null}
            onChange={(date) => {
              const dtNascimento = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtNascimento })
            }}
            required
            validationErrors={validationErrors}
            name="dtNascimento"
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <NumeroInscricaoTextField
            label="CPF"
            value={data?.nrInscricao || ''}
            typeMask={CPF_2}
            onChange={(e, value) => {
              const nrInscricao = value
              setData({ ...data, nrInscricao })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TextField
            select
            label="Sexo"
            variant="outlined"
            fullWidth
            size="small"
            value={data.sexo || ''}
            onChange={(e) => {
              const sexo = e.target.value
              setData({ ...data, sexo })
            }}
          >
            <MenuItem value="M">Masculino</MenuItem>
            <MenuItem value="F">Feminino</MenuItem>
          </TextField>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <FormControlLabel
            label="Dependente SF"
            control={
              <Checkbox
                // checked={data.isDependenteSF}
                checked={data.isDependenteSF || false}
                size="small"
                onChange={(e) => {
                  const isDependenteSF = e.target.checked
                  setData({ ...data, isDependenteSF })
                }}
                color="secondary"
              />
            }
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <FormControlLabel
            label="Dependente IR"
            control={
              <Checkbox
                checked={data.isDependenteIR || false}
                size="small"
                onChange={(e) => {
                  const isDependenteIR = e.target.checked
                  setData({ ...data, isDependenteIR })
                }}
                color="secondary"
              />
            }
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <FormControlLabel
            label="Deficiente"
            control={
              <Checkbox
                checked={data.isDeficiente || false}
                size="small"
                onChange={(e) => {
                  const isDeficiente = e.target.checked
                  setData({ ...data, isDeficiente })
                }}
                color="secondary"
              />
            }
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
