import { useState } from 'react'

import moment from 'moment'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { AcordoDissidioConvencao } from '~/hooks/queries/AcordoDissidioConvencao/AcordoDissidioConvencao'

import { getAcordoDissidioConvencao } from '~/hooks/queries/useAcordoDissidioConvencao'

interface AutoCompleteAcordoProps {
  name: string
  validationErrors: ValidationErrors
  value: string
  onChange: (value: string) => void
}

export default function AutoCompleteAcordo({
  value,
  onChange,
  name,
  validationErrors,
}: AutoCompleteAcordoProps) {
  const [options, setOptions] = useState<AcordoDissidioConvencao[]>([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await getAcordoDissidioConvencao()
      setOptions(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option: AcordoDissidioConvencao) =>
    `${option.descricao} - ${moment(option.dtADC).format('DD/MM/yyyy')}`

  return (
    <MUIAutoComplete
      name={name}
      validationErrors={validationErrors}
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label="Acordo"
      value={value}
      onChange={(_, obj: AcordoDissidioConvencao) => onChange(obj?.id || '')}
      optionId="id"
    />
  )
}
