import React, { useMemo } from 'react'

import { makeStyles } from '@material-ui/core'
import { ToolsDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'
import { EventoIndCalculoValues } from '~/@types/enums/EventoIndCalculoEnum'
import { eventoIndReferenciaValues } from '~/values/eventoIndReferenciaValues'

const formatRVL = (indCalculo) => {
  const objectFinded = EventoIndCalculoValues.find((obj) => obj.value === indCalculo)
  return objectFinded.name
}

const formatMDHPQ = (indReferencia) => {
  const objectFinded = eventoIndReferenciaValues.find((obj) => obj.value === indReferencia)
  return objectFinded.name
}

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const TableRecibosItems = (props) => {
  const { data: _data, isLoading } = props

  const classes = useStyles()
  const { formatCurrency } = useUtils()

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Evento',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'indCalculo',
        label: 'RVL',
      },
      {
        name: 'indReferencia',
        label: 'MDHPQ',
      },
      {
        name: 'quantidade',
        label: 'Referência',
      },
      {
        name: 'vrVencimento',
        label: 'Vencimento',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrDesconto',
        label: 'Desconto',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
    ],
    [classes],
  )

  const data = _data.map((item) => {
    return {
      ...item,
      codigo: item?.eventoEmpregador?.evento?.codigo,
      nome: item?.eventoEmpregador?.evento?.nome,
      indCalculo: formatRVL(item?.eventoEmpregador?.evento?.indCalculo),
      indReferencia: formatMDHPQ(item?.eventoEmpregador?.evento?.indReferencia),
      quantidade: item?.quantidade,
      vrVencimento: formatCurrency(item?.vrVencimento),
      vrDesconto: formatCurrency(item?.vrDesconto),
    }
  })

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      pagination={false}
      isLoading={isLoading}
      disableAutoHeight
    />
  )
}

export default TableRecibosItems
