import { useEffect, useState, useCallback } from 'react'

import { Finder } from 'mio-library-ui'
import { Box, Button, Grid, Paper, Slide, useTheme } from '@material-ui/core'

import {
  AnoMesTextField,
  Checkbox,
  PageHeader,
  TextFieldChangeEstabelecimentoEmail,
  ToolsDataTable,
} from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { useHistory, useLocation } from 'react-router-dom'
import useAmbiente from '~/hooks/useAmbiente'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import api from '~/services/api-pessoal'
import * as yup from 'yup'

export const timeAnimation = 200

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

export default function RelatorioFeriasConcedidas() {
  const [data, setData] = useState({
    rowsSeleceteds: [],
    isIniciarEmpresaNovaFolha: false,
    anoMesInicio: '',
    anoMesFim: '',
  })
  const [collection, setCollection] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [isSlide, setSlide] = useState(true)

  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    handleSubmit: handleSearch,
    schema,
  })
  const dialogNotification = useDialogNotification()
  const { anoMes } = useAmbiente()
  const { state } = useLocation()
  const history = useHistory()
  const theme = useTheme()

  const getCollection = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get('/EstabelecimentoConsulta')
      const responseCurrent = response?.data?.data || []

      const responseFormated = responseCurrent.map((obj) => ({
        ...obj,
        nrInscricao: obj?.nrInscricao ? formatToCPFOrCNPJ(obj.nrInscricao) : '',
      }))

      if (state) {
        const indexsSelecteds = []

        responseFormated.forEach(
          (obj, index) => state.estabelecimentosIds.includes(obj.id) && indexsSelecteds.push(index),
        )

        setData({
          rowsSeleceteds: indexsSelecteds,
          anoMesInicio: state.anoMesInicio,
          anoMesFim: state.anoMesFim,
          isIniciarEmpresaNovaFolha: state.isIniciarEmpresaNovaFolha,
        })
      }

      setCollection(responseFormated)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
    }))
  }, [anoMes])

  function handleSearch() {
    try {
      const newData = {
        estabelecimentosIds: data.rowsSeleceteds.map((index) => collection[index].id),
        isIniciarEmpresaNovaFolha: data.isIniciarEmpresaNovaFolha,
        anoMesInicio: data.anoMesInicio,
        anoMesFim: data.anoMesFim,
      }

      setSlide(false)

      setTimeout(
        () =>
          history.push({
            pathname: '/result-relatorio-ferias-concedidas',
            state: newData,
          }),
        timeAnimation,
      )
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  function resetFields() {
    setData({
      rowsSeleceteds: [],
      isIniciarEmpresaNovaFolha: false,
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
    })
  }

  const columns = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nrInscricao',
      label: 'Número de Inscrição',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'id',
      label: 'Email',
      options: {
        setCellHeaderProps: () => ({
          style: { width: 400 },
        }),
        customBodyRender: (id) => {
          const estabelecimento = collection.find((d) => d.id === id)
          return (
            <TextFieldChangeEstabelecimentoEmail
              estabelecimentoId={id}
              email={estabelecimento?.email || ''}
              afterSubmit={getCollection}
            />
          )
        },
      },
    },
  ]

  return (
    <Slide direction="right" in={isSlide} mountOnEnter unmountOnExit timeout={timeAnimation}>
      <Box height="100%" width="100%" p={2}>
        <PageHeader title="Relatório de Férias Concedidas">
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </PageHeader>
        <Box component={Paper} padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <AnoMesTextField
                label="Ano/Mês Início"
                value={data.anoMesInicio}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    anoMesInicio: e?.target?.value?.replace('/', '') || '',
                  }))
                }
                required
                validationErrors={validationErrors}
                name="anoMesInicio"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5}>
              <AnoMesTextField
                label="Ano/Mês Fim"
                value={data.anoMesFim}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    anoMesFim: e?.target?.value?.replace('/', '') || '',
                  }))
                }
                required
                validationErrors={validationErrors}
                name="anoMesFim"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <Checkbox
                label="Iniciar empresa em nova folha"
                value={data?.isIniciarEmpresaNovaFolha || false}
                onChange={(e, value) => {
                  const isIniciarEmpresaNovaFolha = value || false
                  setData((prev) => ({ ...prev, isIniciarEmpresaNovaFolha }))
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <ToolsDataTable
          data={collection}
          isLoading={isLoading}
          columns={columns}
          sherlock={{
            query,
            columns: ['codigo', 'nrInscricao', 'nome'],
          }}
          triggersHeight={{ validationErrors }}
          optionsSelectable={{
            type: 'multiple',
            onRowSelected: (indexs) =>
              setData((prevState) => ({
                ...prevState,
                rowsSeleceteds: indexs,
              })),
            rowsSelected: data.rowsSeleceteds,
          }}
        />
        <Box display="flex" justifyContent="flex-end" pt={1} gridGap={theme.spacing(1)}>
          <Button variant="outlined" color="primary" size="small" onClick={resetFields}>
            Cancelar
          </Button>
          <Button
            disabled={!(data.rowsSeleceteds.length > 0)}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleValidate}
          >
            Buscar
          </Button>
        </Box>
      </Box>
    </Slide>
  )
}
