import { useState, useEffect } from 'react'

import * as yup from 'yup'

import { Settings } from '@material-ui/icons'
import { Grid } from '@material-ui/core'

import { Button, Checkbox, Stack, TimePicker } from '~/components'
import { AutoCompleteEventoEmpregador } from '~/components/AutoComplete'

import { useGerarFolhaPonto } from '~/hooks/queries/FolhaPonto/useGerarFolhaPonto'
import useValidationErrors from '~/hooks/useValidationErrors'

import { FolhaPonto } from '~/hooks/queries/FolhaPonto/dtos/FolhaPonto'

const dtInitial = {
  isMarcarFeriado: true,
  isMarcarDescansoSemanal: true,
  isMarcarFerias: true,
  isMarcarAfastamento: true,
} as FolhaPonto

const schema = yup.object().shape({
  eventoHoraExtraId: yup.string().required('Informe o Evento de Hora Extra').nullable(),
  eventoHoraNoturnaId: yup.string().required('Informe o Evento de Hora Noturna').nullable(),
})

interface FormNovoProps {
  vinculoId: string
  anoMes: string
}

export default function FormNovo({ vinculoId, anoMes }: FormNovoProps) {
  const [data, setData] = useState(dtInitial)

  useEffect(() => {
    setData(dtInitial)
  }, [vinculoId, anoMes])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleGenerateFolhaPonto,
    data,
  })

  const { mutateAsync, isLoading: isSubmitting } = useGerarFolhaPonto()

  async function handleGenerateFolhaPonto() {
    await mutateAsync({
      data: {
        ...data,
        vinculoId,
        anoMes,
      },
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <Checkbox
          label="Selecionar Descanso Semanal"
          value={data.isMarcarDescansoSemanal || false}
          onChange={(e, value) => {
            const isMarcarDescansoSemanal = value
            setData((prev) => ({
              ...prev,
              isMarcarDescansoSemanal,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Checkbox
          label="Selecionar Feriados"
          value={data.isMarcarFeriado || false}
          onChange={(e, value) => {
            const isMarcarFeriado = value
            setData((prev) => ({
              ...prev,
              isMarcarFeriado,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Checkbox
          label="Selecionar Férias"
          value={data.isMarcarFerias || false}
          onChange={(e, value) => {
            const isMarcarFerias = value
            setData((prev) => ({
              ...prev,
              isMarcarFerias,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Checkbox
          label="Selecionar Afastamentos"
          value={data.isMarcarAfastamento || false}
          onChange={(e, value) => {
            const isMarcarAfastamento = value
            setData((prev) => ({
              ...prev,
              isMarcarAfastamento,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <TimePicker
          label="Tempo de Tolerância"
          value={data.tempoTolerancia}
          onChange={(value) => {
            const tempoTolerancia = value
            setData((prev) => ({
              ...prev,
              tempoTolerancia,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AutoCompleteEventoEmpregador
          optionId="id"
          label="Evento de Hora Extra"
          value={data?.eventoHoraExtraId || ''}
          required
          validationErrors={validationErrors}
          name="eventoHoraExtraId"
          onChange={(d) => {
            const eventoHoraExtraId = d?.id || ''
            setData((prev) => ({ ...prev, eventoHoraExtraId }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AutoCompleteEventoEmpregador
          optionId="id"
          label="Evento de Hora Noturna"
          value={data?.eventoHoraNoturnaId || ''}
          required
          validationErrors={validationErrors}
          name="eventoHoraNoturnaId"
          onChange={(d) => {
            const eventoHoraNoturnaId = d?.id || ''
            setData((prev) => ({ ...prev, eventoHoraNoturnaId }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Stack orientation="horizontal" justifyContent="flex-end">
          <Button
            variant="contained"
            endIcon={<Settings />}
            onClick={handleValidate}
            isLoading={isSubmitting}
          >
            Gerar Folha de Ponto para o Funcionário
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}
