import { formatToCPF } from 'brazilian-values'
import moment from 'moment'

import { ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { EtiquetaCTPSFuncionarioDTO } from '~/hooks/queries/EtiquetaCTPS/EtiquetaCTPSFuncionarioDTO'

interface TableProps {
  data: EtiquetaCTPSFuncionarioDTO[]
  isLoading: boolean
  onRowSelected: (indexs: number[]) => void
  rowsSelected: number[]
  query: string
}

export default function Table(props: TableProps) {
  const { data: _data, isLoading, onRowSelected, rowsSelected, query } = props

  const data = _data.map((d) => ({
    ...d,
    funcionarioCPF: formatToCPF(d.funcionarioCPF),
    dtAdmissao: moment(d.dtAdmissao).format('DD/MM/yyyy'),
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'estabelecimentoNome',
      label: 'Estabelecimento',
    },
    {
      name: 'funcionarioCPF',
      label: 'CPF',
    },
    {
      name: 'funcionarioNome',
      label: 'Funcionário',
    },
    {
      name: 'dtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'nrDiasContratoExperiencia',
      label: 'Dias Experiência',
    },
    {
      name: 'nrDiasContratoExperienciaProrrogacao',
      label: 'Dias Prorrogação',
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      isLoading={isLoading}
      columns={columns}
      sherlock={{
        columns: ['estabelecimentoNome', 'funcionarioCPF', 'funcionarioNome', 'dtAdmissao'],
        query,
      }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
        selectOnClick: true,
      }}
    />
  )
}
