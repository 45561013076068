import { useEffect, useState } from 'react'

import { ActionDialog, TextField } from '~/components'

import { FormDataProps } from '../../../Footer/components/Form'
import { useRPInsereEvento } from '~/hooks/queries/RP/useRPInsereEvento'
import { useContextRecibo } from '~/hooks/useRecibo'

const initialState: FormDataProps = {
  id: null,
  codigo: null,
  referencia: null,
  valor: null,
  indCalculo: null,
  complemento: null,
  isCalculoManual: null,
}

interface DialogComplementoProps {
  isOpen: boolean
  onClose: () => void
  data: FormDataProps
  onAfterSubmit: () => void
}

export default function DialogComplemento(props: DialogComplementoProps) {
  const { isOpen, onClose, data: _data, onAfterSubmit } = props

  const [data, setData] = useState<FormDataProps>(initialState)

  const { mutateAsync: mutateAsyncInsereEvento, isLoading: isLoadingInsereEvento } =
    useRPInsereEvento()
  const { reciboPagamento } = useContextRecibo()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = async () => {
    if (!reciboPagamento) return
    await mutateAsyncInsereEvento({
      rpId: reciboPagamento?.id,
      eventoEmpregadorId: data?.id as string,
      isCalculoManual: data?.isCalculoManual,
      referencia: data?.referencia,
      complemento: data?.complemento,
      valor: data?.valor,
    })
    onAfterSubmit()
    onClose()
  }

  return (
    <ActionDialog
      title="Adicionar Complemento"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleSubmit}
      okLabel="Salvar"
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      isOkProcessing={isLoadingInsereEvento}
    >
      <TextField
        fullWidth
        autoFocus
        label="Complemento"
        value={data.complemento || ''}
        multiline
        rows={5}
        inputProps={{
          maxLength: 30,
        }}
        onChange={(e) => {
          const complemento = e.target.value
          setData((prev) => ({
            ...prev,
            complemento,
          }))
        }}
      />
    </ActionDialog>
  )
}
