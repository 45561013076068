export enum IndProvisaoEnum {
  Mensal = 1,
  FeriasConcedidas = 2,
  FeriasRescisao = 3,
  AlteracaoSalarial = 4,
  AjusteDeSaldo = 5,
  BaixaDtSalario = 6,
  DtSalarioRescisao = 7,
  TransferenciaSaida = 8,
  TransferenciaEntrada = 9,
  SaldoInicial = 99,
}

export const IndProvisaoValues = [
  {
    value: IndProvisaoEnum.Mensal,
    name: 'Mensal',
  },
  { value: IndProvisaoEnum.FeriasConcedidas, name: 'Férias Concedidas' },
  { value: IndProvisaoEnum.FeriasRescisao, name: 'Férias Rescisão' },
  { value: IndProvisaoEnum.AlteracaoSalarial, name: 'Alteração Salarial' },
  { value: IndProvisaoEnum.AjusteDeSaldo, name: 'Ajuste De Saldo' },
  { value: IndProvisaoEnum.BaixaDtSalario, name: 'Baixa 13º Salário' },
  { value: IndProvisaoEnum.DtSalarioRescisao, name: '13º Salário Rescisão' },
  { value: IndProvisaoEnum.TransferenciaSaida, name: 'Transferência por Saída' },
  { value: IndProvisaoEnum.TransferenciaEntrada, name: 'Transferência por Entrada' },
  { value: IndProvisaoEnum.SaldoInicial, name: 'Saldo Inicial' },
]
