import { useState } from 'react'

import MUIAutoComplete from '../MUIAutoComplete'

import PropType from 'prop-types'
import api from '~/services/api-pessoal'

export default function AutoCompleteGrupo(props) {
  const { ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/Grupo')
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => (option ? `${option?.codigo} - ${option?.nome}` : '')

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label="Grupo"
      {...rest}
    />
  )
}

AutoCompleteGrupo.propTypes = {
  onChange: PropType.func,
  value: PropType.any,
  validationErrors: PropType.any,
  empregadorId: PropType.string,
  name: PropType.string,
  label: PropType.string,
  optionId: PropType.string,
  required: PropType.bool,
  disabled: PropType.bool,
}
