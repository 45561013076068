import { useEffect, useState, useCallback } from 'react'

import { useSelector } from 'react-redux'

import { Box, Drawer, makeStyles } from '@material-ui/core'

import useOnlyDataC from '~/hooks/useOnlyDataC'

import SearchTextField from './components/SearchTextField'
import DisplayMenu from './components/DisplayMenu'
import HoverMenu from './components/HoverMenu'

import clsx from 'clsx'

import menus from '~/services/menus'
import menusSST from '~/services/menusSST'

import { tipoLicencaConsts } from '~/values/tipoLicencaValues'
import Logo from '~/components/Logo'

const toolBarHeight = 48
export const DRAWER_WIDTH_OPEN = '240px'
export const DRAWER_WIDTH_CLOSE = '48px'

function verifyRouterLink(link) {
  const routerParamCurrent = window?.location?.hash?.toLowerCase().slice(1)
  return routerParamCurrent === link?.toLowerCase()
}

function addLevelsInMenus(menus, level) {
  const newMenus = menus.map((itemMenu) => {
    if (!(itemMenu?.itens?.length > 0)) return { ...itemMenu, level }
    const newItens = addLevelsInMenus(itemMenu?.itens, level + 1)
    return {
      ...itemMenu,
      itens: newItens,
      level,
    }
  })
  return newMenus
}

function mountMenuHandleSearch(_menus, _query) {
  const query = _query.toLowerCase()
  const newMenus = []
  _menus.forEach((menu) => {
    if (menu.label.toLowerCase().includes(query)) return newMenus.push(menu)
    if (!(menu?.itens?.length > 0)) return
    const itens = mountMenuHandleSearch(menu.itens, query)
    if (itens?.length > 0) newMenus.push({ ...menu, itens })
  })
  return newMenus
}

function openMenusHandleSearch(menus) {
  const newMenus = []
  const id = `${menus[0]?.level}|0`
  if (menus[0]?.itens?.length > 0) {
    const newItensMenus = openMenusHandleSearch(menus[0].itens)
    newMenus.push(id, ...newItensMenus)
  } else {
    newMenus.push(id)
  }
  return newMenus
}

function openMenusWhenOpenMenu(menus) {
  const newMenus = []
  menus.forEach((itemMenu, index) => {
    const id = `${itemMenu?.level}|${index}`
    const result = verifyRouterLink(itemMenu?.link)
    if (result) {
      return newMenus.push(id)
    }
    if (itemMenu?.itens?.length > 0) {
      const newMenusItens = openMenusWhenOpenMenu(itemMenu.itens)
      if (newMenusItens.length) {
        newMenus.push(id, ...newMenusItens)
      }
    }
  })
  return newMenus
}

const useStyles = makeStyles((theme) => ({
  toolBar: {
    height: toolBarHeight,
    minHeight: toolBarHeight,
  },
  drawer: {
    width: DRAWER_WIDTH_OPEN,
    flexShrink: 0,
  },
  drawerOpen: {
    width: DRAWER_WIDTH_OPEN,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: theme.palette.primary.paper,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
    background: theme.palette.primary.paper,
  },
  menuListContainerOpen: {
    marginTop: theme.spacing(1),
  },
  notFoundMenus: {
    color: '#DFF4ED',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}))

const DrawerMenu = (props) => {
  const { open, onToggleMenu } = props

  const [menusOpens, setMenusOpens] = useState([])
  const [displayMenus, setDisplayMenus] = useState([])
  const [startMenus, setStartMenus] = useState([])

  const classes = useStyles()
  const { isUserDataC, routesOnlyDataC } = useOnlyDataC()
  const parametrosSistema = useSelector((state) => state.app.parametrosSistema)

  const removeMenusByLink = useCallback(
    (menus) => {
      const newMenus = []
      menus.forEach((itemMenu) => {
        if (routesOnlyDataC.includes(itemMenu.link) && !isUserDataC) return
        if (!(itemMenu?.itens?.length > 0)) return newMenus.push(itemMenu)
        const newItens = removeMenusByLink(itemMenu?.itens || [])
        newMenus.push({
          ...itemMenu,
          itens: newItens,
        })
      })

      return newMenus
    },
    [isUserDataC, routesOnlyDataC],
  )

  useEffect(() => {
    if (!open) return
    let menusCurrent = []
    if (!parametrosSistema) return
    switch (parametrosSistema.tipoLicenca) {
      case tipoLicencaConsts.normal_1:
        menusCurrent = menus
        break
      case tipoLicencaConsts.SST_2:
        menusCurrent = menusSST
        break
      default:
        menusCurrent = []
    }

    const newMenus = removeMenusByLink(menusCurrent)

    const menuUpdated = addLevelsInMenus(newMenus, 0)
    const menusOpen = openMenusWhenOpenMenu(menuUpdated)

    setDisplayMenus(menuUpdated)
    setStartMenus(menuUpdated)
    setMenusOpens(menusOpen)
  }, [open, parametrosSistema, removeMenusByLink])

  function handleQuery(_query) {
    if (!(startMenus.length > 0)) return
    if (!_query) {
      setDisplayMenus(startMenus)
      return
    }
    const newMenus = mountMenuHandleSearch(startMenus, _query)
    const menusOpen = openMenusHandleSearch(newMenus)

    setDisplayMenus(newMenus)
    setMenusOpens(menusOpen)
  }

  const changeMenuOpen = () => {
    onToggleMenu(!open)
  }

  return (
    <Drawer
      variant="permanent"
      open={true}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Box>{open ? <Logo /> : <Logo smallLogo />}</Box>

      <SearchTextField isOpen={open} handleQuery={handleQuery} changeIsOpen={changeMenuOpen} />
      {open ? (
        <DisplayMenu menus={displayMenus} menusOpens={menusOpens} handleOpenMenus={setMenusOpens} />
      ) : (
        <HoverMenu menus={startMenus} />
      )}
    </Drawer>
  )
}

export default DrawerMenu
