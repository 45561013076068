import { Box } from '@material-ui/core'

import { LabelValueColor } from '~/components'

const Footer = (props) => {
  const { totais } = props

  const {
    totalBaseFGTS,
    totalBaseFGTS13,
    totalBaseINSS,
    totalBaseINSS13,
    totalValorFGTS,
    totalValorFGTS13,
    totalValorINSS,
    totalValorINSS13,
    totalBaseFGTSAviso,
    totalValorFGTSAviso,
  } = totais

  return (
    <Box
      border="1px solid #D0D0D0"
      borderRadius={4}
      width="100%"
      height="100%"
      overflow="auto"
      display="flex"
      gridGap={4}
      flexDirection="column"
      justifyContent="center"
    >
      <Box display="flex">
        <LabelValueColor
          label="Base INSS"
          value={totalBaseINSS || '0,00'}
          color="#666"
          style={{ minWidth: '100px' }}
        />

        <LabelValueColor
          label="Base INSS 13°"
          value={totalBaseINSS13 || '0,00'}
          color="#666"
          style={{ minWidth: '100px' }}
        />

        <LabelValueColor
          label="Base FGTS Aviso"
          value={totalBaseFGTSAviso || '0,00'}
          color="#666"
          style={{ minWidth: '100px' }}
        />

        <LabelValueColor
          label="Base FGTS"
          value={totalBaseFGTS || '0,00'}
          color="#666"
          style={{ minWidth: '100px' }}
        />

        <LabelValueColor
          label="Base FGTS 13°"
          value={totalBaseFGTS13 || '0,00'}
          color="#666"
          style={{ minWidth: '100px' }}
          showDivider={false}
        />
      </Box>

      <Box display="flex">
        <LabelValueColor
          label="Valor INSS"
          value={totalValorINSS || '0,00'}
          color="#2E8BC0"
          style={{ minWidth: '100px' }}
        />

        <LabelValueColor
          label="Valor INSS 13°"
          value={totalValorINSS13 || '0,00'}
          color="#2E8BC0"
          style={{ minWidth: '100px' }}
        />

        <LabelValueColor
          label="Valor FGTS Aviso"
          value={totalValorFGTSAviso || '0,00'}
          color="#2E8BC0"
          style={{ minWidth: '100px' }}
        />

        <LabelValueColor
          label="Valor FGTS"
          value={totalValorFGTS || '0,00'}
          color="#2E8BC0"
          style={{ minWidth: '100px' }}
        />

        <LabelValueColor
          label="Valor FGTS 13°"
          value={totalValorFGTS13 || '0,00'}
          color="#2E8BC0"
          style={{ minWidth: '100px' }}
          showDivider={false}
        />
      </Box>
    </Box>
  )
}

export default Footer
