import React, { useMemo } from 'react'
import moment from 'moment'

import { IconButton, makeStyles } from '@material-ui/core'
import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import useUtils from '~/hooks/useUtils'

import { indGRRFValues } from '~/values/indGRRFValues'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const formatIndGRRF = (indGRRF) => {
  const objectFinded = indGRRFValues.find((obj) => obj.value === indGRRF)
  return objectFinded?.name || ''
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading } = props

  const { formatCurrency } = useUtils()
  const classes = useStyles()

  const data = _data.map((item) => {
    return {
      ...item,
      vrSaldoFGTS: item.vrSaldoFGTS ? formatCurrency(item.vrSaldoFGTS) : 0,
      somatorio: formatCurrency(
        item.vrBaseMesAnterior + item.vrBaseMes + item.vrBaseAvisoPrevio + item.vrSaldoRescisorio,
      ),
      vrRecolher: item.vrRecolher ? formatCurrency(item.vrRecolher) : 0,
      indGRRF: formatIndGRRF(item?.indGRRF),
      dtGeracao: item?.dtGeracao ? moment(item.dtGeracao).format('DD/MM/yyyy') : '',
      dtRecolhimento: item?.dtRecolhimento ? moment(item.dtRecolhimento).format('DD/MM/yyyy') : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'vrSaldoFGTS',
        label: 'Saldo Rescisório',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'somatorio',
        label: 'Somatório da Remuneração',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrRecolher',
        label: 'Total a Recolher',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'indGRRF',
        label: 'Indicador GRRF',
      },
      {
        name: 'dtGeracao',
        label: 'Data de Geração',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    // eslint-disable-next-line
    [onItemClick],
  )

  return <DataTable title="" data={data} columns={columns} isLoading={isLoading} />
}

export default Table
