import { Dispatch, SetStateAction } from 'react'

import moment from 'moment'

import { Checkbox, Icon } from '@material-ui/core'
import { ToolsDataTable } from '~/components'

import { MUIDataTableCheckboxProps, MUIDataTableColumnDef } from 'mui-datatables'
import { ESocialAuditoriaTransmitirDTO } from '~/hooks/queries/ESocial/useObterEventosTransmissaoEnvio'

interface TableEventosAnaliticoProps {
  data: ESocialAuditoriaTransmitirDTO[]
  auditoriasIds: string[]
  setAuditoriasIds: Dispatch<SetStateAction<string[]>>
}

export default function TableEventosAnalitico(props: TableEventosAnaliticoProps) {
  const { data: _data, auditoriasIds, setAuditoriasIds } = props

  const data = _data.map((d) => ({
    ...d,
    dtMovimento: d?.dtMovimento ? moment(d.dtMovimento).format('DD/MM/yyyy - HH:mm:ss') : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'auditoriaDescricao',
      label: 'Descrição',
    },
    {
      name: 'tabelaOrigem',
      label: 'Origem',
    },
    {
      name: 'tipoMovimento',
      label: 'Tipo Movimento',
    },
    {
      name: 'dtMovimento',
      label: 'Data do Movimento',
    },
    {
      name: 'indRetificacao',
      label: 'Ind. Retificação',
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      disableAutoHeight
      components={{
        ExpandButton: () => <Icon />,
        Checkbox: (props: MUIDataTableCheckboxProps) => {
          const indexRow = props['data-index'] || 0
          const auditoriaCurrent = data[indexRow]
          if (!auditoriaCurrent) return <></>
          const checked = auditoriasIds.includes(auditoriaCurrent.auditoriaId)
          return (
            <Checkbox
              size="small"
              checked={checked}
              onChange={() =>
                setAuditoriasIds((prev) => {
                  if (checked) {
                    return prev.filter((d) => auditoriaCurrent.auditoriaId !== d)
                  } else {
                    return [...prev, auditoriaCurrent.auditoriaId]
                  }
                })
              }
            />
          )
        },
      }}
      options={{
        selectableRows: 'multiple',
        expandableRows: true,
      }}
    />
  )
}
