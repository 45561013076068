import { useMemo } from 'react'
import { formatToCPF } from 'brazilian-values'

import { makeStyles } from '@material-ui/core'
import { CopyLabel, ToolsDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'

import { CNABDataDTO } from '~/hooks/queries/CNAB/dtos/CNABDataDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

interface TableProps {
  data: CNABDataDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
  rowsSelecteds: number[]
  onRowSelected: (indexs: number[]) => void
}

const Table = ({
  data,
  isLoading,
  isFetching,
  onRowSelected,
  rowsSelecteds,
  query,
}: TableProps) => {
  const classes = useStyles()
  const { formatCurrency } = useUtils()

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'funcionarioCPF',
        label: 'CPF',
        options: {
          customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
        },
      },
      {
        name: 'funcionarioNome',
        label: 'Nome',
      },
      {
        name: 'agencia',
        label: 'Agência',
      },
      {
        name: 'contaCorrente',
        label: 'Conta Corrente',
      },
      {
        name: 'dv',
        label: 'DV',
      },
      {
        name: 'valorLiquido',
        label: 'Valor Líquido',
        options: {
          setCellProps: () => ({ className: classes.renderRight }),
          customBodyRender: formatCurrency,
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes],
  )

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      optionsSelectable={{
        type: 'multiple',
        onRowSelected,
        rowsSelected: rowsSelecteds,
      }}
      sherlock={{
        query,
        columns: ['funcionarioCPF', 'funcionarioNome'],
      }}
    />
  )
}

export default Table
