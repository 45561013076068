import { useMemo, useState } from 'react'

import { formatToCPF } from 'brazilian-values'
import { setImportantCSS } from '~/hooks/useUtils'

import { Box, IconButton, Paper, makeStyles, useTheme } from '@material-ui/core'

import {
  ActionDialog,
  Button,
  ButtonBox,
  Checkbox,
  Finder,
  LabelValueColor,
  PageHeader,
  ToolsDataTable,
} from '~/components'

import { FuncionarioAnalitico } from './FuncionarioAnalitico'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import useObterFGTSBeneficiario from '~/hooks/queries/ApurarESocial/useObterFGTSBeneficiario'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Ativo } from '~/components/Displays'

import { ApurarFolhaESocialFGTSDTO } from '~/hooks/queries/ApurarESocial/ApurarFolhaESocialFGTSDTO'
import { Assessment } from '@material-ui/icons'
import { formatCurrency } from '~/utils/utils'

const useStyles = makeStyles((theme: FixLater) => ({
  alignRight: {
    textAlign: 'right',
  },
  hightLightRow: {
    backgroundColor: setImportantCSS(theme.palette.error.light),
    color: setImportantCSS(theme.palette.error.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.error.lighter),
    },
  },
}))

interface FGTSBeneficiarioProps {
  empregadorId?: string
  isSomenteDiferenca?: boolean
}

export default function FGTSBeneficiario({
  empregadorId,
  isSomenteDiferenca: _isSomenteDiferenca,
}: FGTSBeneficiarioProps) {
  const [query, setQuery] = useState('')
  const [isSomenteDiferenca, setSomenteDiferenca] = useState(_isSomenteDiferenca || false)
  const [isSomenteGRFC, setSomenteGRFC] = useState(false)

  const classes = useStyles()
  const theme = useTheme()

  const { anoMes, empregador } = useAmbiente()
  const {
    close: closeDialogFuncAnalitico,
    data: dataDialogFuncAnalitico,
    isOpen: isOpenDialogFuncAnalitico,
    open: openDialogFuncAnalitico,
  } = useDialog<ApurarFolhaESocialFGTSDTO | null>(null)

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterFGTSBeneficiario({
    empregadorId: empregadorId || empregador.id,
    periodoApuracao: anoMes,
    isSomenteDiferenca,
    isSomenteGRFC,
  })

  const _data = _d || []

  const data = _data.map((d) => {
    return {
      ...d,
      nrInscricao: d?.nrInscricao ? formatToCPF(d.nrInscricao) : '',
      vrFolha: d?.vrFolha ? formatCurrency(d.vrFolha) : '',
      vrESocial: d?.vrESocial ? formatCurrency(d.vrESocial) : '',
      vrDiferenca: d?.vrDiferenca ? formatCurrency(d.vrDiferenca) : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'isGRFC',
      label: 'GRFC',
      options: {
        customBodyRender: (value) => (value ? <Ativo ativo={true} /> : <></>),
      },
    },
    {
      name: 'vrFolha',
      label: 'Folha',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrESocial',
      label: 'ESocial',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrDiferenca',
      label: 'Diferença',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vinculoId',
      label: 'Ações',
      options: {
        customBodyRender: (value) => {
          const rowCurrent = _data.find((d) => d.vinculoId === value)
          if (!rowCurrent) return <></>
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Analítico"
                color="primary"
                aria-label="Analítico"
                onClick={() => openDialogFuncAnalitico(rowCurrent)}
              >
                <Assessment fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  const { vrFolha, vrESocial, vrDiferenca } = useMemo(() => {
    let vrFolha = 0
    let vrESocial = 0
    let vrDiferenca = 0

    _data.forEach((element) => {
      vrFolha += element.vrFolha
      vrESocial += element.vrESocial
      vrDiferenca += element.vrDiferenca
    })

    return {
      vrFolha,
      vrESocial,
      vrDiferenca,
    }
  }, [_data])

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title="FGTS por Beneficiário">
        <ButtonBox>
          <Checkbox
            label="Somente com diferença"
            value={isSomenteDiferenca}
            onChange={(e, value) => setSomenteDiferenca(value)}
          />
          <Checkbox
            label="Somente com GRFC"
            value={isSomenteGRFC}
            onChange={(e, value) => setSomenteGRFC(value)}
          />
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </ButtonBox>
      </PageHeader>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%">
          <ToolsDataTable
            disableAutoHeight
            data={data}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching}
            options={{
              setRowProps: (_: FixLater, index: number) => {
                const rowCurrent = _data[index]
                if (!rowCurrent) return
                const isDiferenca = rowCurrent.temDiferenca
                return {
                  className: isDiferenca ? classes.hightLightRow : '',
                }
              },
            }}
            sherlock={{
              columns: ['nrInscricao', 'nome'],
              query,
            }}
          />
        </Box>
      </Box>

      <Box component={Paper} padding={1.5} display="flex" justifyContent="flex-end">
        <Box width={200}>
          <LabelValueColor value={formatCurrency(vrFolha, true)} label="Folha" />
        </Box>
        <Box width={200}>
          <LabelValueColor value={formatCurrency(vrESocial, true)} label="ESocial" />
        </Box>
        <Box width={200}>
          <LabelValueColor
            value={formatCurrency(vrDiferenca, true)}
            label="Diferença"
            color="#2E8BC0"
          />
        </Box>
      </Box>

      <ActionDialog
        isOpen={isOpenDialogFuncAnalitico}
        onClose={closeDialogFuncAnalitico}
        title="Funcionário Analítico"
        subtitle={
          dataDialogFuncAnalitico
            ? dataDialogFuncAnalitico.nome +
              ' / ' +
              formatToCPF(dataDialogFuncAnalitico.nrInscricao)
            : '-'
        }
        dialogProps={{ maxWidth: 'md', fullWidth: true }}
        customActions={
          <ButtonBox right={1} bottom={1}>
            <Button variant="contained" onClick={closeDialogFuncAnalitico}>
              Fechar
            </Button>
          </ButtonBox>
        }
      >
        {dataDialogFuncAnalitico && (
          <FuncionarioAnalitico vinculoId={dataDialogFuncAnalitico.vinculoId} />
        )}
      </ActionDialog>
    </Box>
  )
}
