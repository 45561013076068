import { useState } from 'react'

import * as yup from 'yup'

import { ActionDialog, AnoMesTextField, Stack } from '~/components'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import { useFacultativoCalcularContribuicoes } from '~/hooks/queries/Facultativo'

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

interface FormCalcularGPSProps {
  isOpen: boolean
  onClose: () => void
  contribuintesIds: string[]
  afterSubmit: () => void
}

export default function FormCalcularGPS({
  isOpen,
  onClose,
  contribuintesIds,
  afterSubmit,
}: FormCalcularGPSProps) {
  const { ano } = useAmbiente()

  const [data, setData] = useState({
    anoMesInicio: ano + '01',
    anoMesFim: ano + '12',
  })

  const { mutateAsync, isLoading } = useFacultativoCalcularContribuicoes()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    await mutateAsync({
      data: contribuintesIds,
      params: {
        anoMesInicio: data.anoMesInicio,
        anoMesFim: data.anoMesFim,
      },
    })
    onClose()
    afterSubmit()
  }

  return (
    <ActionDialog
      title="Calcular GPS"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
      okLabel="Calcular"
    >
      <Stack orientation="horizontal">
        <AnoMesTextField
          label="Ano/Mês Início"
          required
          name="anoMesInicio"
          validationErrors={validationErrors}
          value={data.anoMesInicio}
          onChange={(e) => {
            const anoMesInicio = e.target.value.replace('/', '')
            setData((oldState) => ({
              ...oldState,
              anoMesInicio,
            }))
          }}
        />
        <AnoMesTextField
          label="Ano/Mês Fim"
          required
          name="anoMesFim"
          validationErrors={validationErrors}
          value={data.anoMesFim}
          onChange={(e) => {
            const anoMesFim = e.target.value.replace('/', '')
            setData((oldState) => ({
              ...oldState,
              anoMesFim,
            }))
          }}
        />
      </Stack>
    </ActionDialog>
  )
}
