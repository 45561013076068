import { Box, Icon, Tooltip, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(0.5),
    '&:hover': {
      cursor: (props: { disabled: boolean }) => (props.disabled ? 'default' : 'pointer'),
    },
  },
}))

interface HeaderItemMainProps {
  title: string
  subtitle: string
  icon: string
  disabled: boolean
  isLoading: boolean
  onClickTitle: () => void
  onClickSubtitle: () => void
}

export default function HeaderItemMain({
  title,
  subtitle,
  icon,
  disabled,
  isLoading,
  onClickTitle,
  onClickSubtitle,
}: HeaderItemMainProps) {
  disabled = isLoading || disabled

  title = disabled ? '' : title
  subtitle = disabled ? '' : subtitle

  const classes = useStyles({ disabled })

  return (
    <Box className={classes.root}>
      <Icon
        onClick={disabled ? undefined : onClickTitle}
        fontSize="large"
        color={disabled ? 'action' : 'primary'}
      >
        {icon}
      </Icon>
      <Box overflow="hidden" textOverflow="ellipsis" width="100%">
        <Tooltip arrow title={title}>
          <Box
            fontSize="medium"
            fontWeight="bold"
            color="#666"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            onClick={disabled ? undefined : onClickTitle}
            width="100%"
          >
            {isLoading ? <Skeleton /> : disabled ? '' : title}
          </Box>
        </Tooltip>
        <Tooltip arrow title={subtitle}>
          <Box
            fontWeight="bold"
            color="#666"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            onClick={disabled ? undefined : onClickSubtitle}
            width="100%"
          >
            {isLoading ? <Skeleton /> : subtitle}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}
