import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoFaltasRelatorioPeriodoDTO } from './VinculoFaltasDTO'

export const queryObterFaltasPeriodo = '/VinculoFaltas/ObterFaltasPeriodo'

interface RequestParams {
  vinculoId?: string
  estabelecimentoId: string
  dtInicioPeriodo: string
  dtFimPeriodo: string
}

export default function useObterFaltasPeriodo(params: RequestParams) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoFaltasRelatorioPeriodoDTO[] }>(
      queryObterFaltasPeriodo,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterFaltasPeriodo, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
