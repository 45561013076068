import React from 'react'

import * as yup from 'yup'
import moment from 'moment'

import { Grid, LinearProgress } from '@material-ui/core'

import AutoCompleteEstabilidade from '~/components/AutoComplete/AutoCompleteEstabilidade'
import { ActionDialog, DatePickerNew } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import {
  useVinculoEstabilidadeById,
  useVinculoEstabilidadeMutation,
} from '~/hooks/queries/useVinculoEstabilidade'
import { EstabilidadeData } from '~/hooks/queries/useEstabilidade'

const schema = yup.object({
  estabilidadeId: yup.string().required('Informe a Estabilidade').nullable(),
  dtInicio: yup
    .date()
    .required('Informe a Data de Início')
    .typeError('Informe uma data válida')
    .nullable(),
  dtFim: yup
    .date()
    .required('Informe a Data Final')
    .typeError('Informe uma data válida')
    .nullable(),
})

interface FormVinculoEstabilidadeProps {
  isOpen: boolean
  onClose: () => void
  vinculoEstabilidadeId: string | null
  vinculoId: string | null
}

export default function FormVinculoEstabilidade(props: FormVinculoEstabilidadeProps) {
  const { isOpen, onClose, vinculoEstabilidadeId, vinculoId } = props

  const { data, setData, isLoading } = useVinculoEstabilidadeById(vinculoEstabilidadeId)
  const { mutateAsync, isLoading: isSubmitting } = useVinculoEstabilidadeMutation()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    await mutateAsync({ ...data, vinculoId })
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Estabilidade por Funcionário"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutoCompleteEstabilidade
              onChange={(e: string, obj: EstabilidadeData | null) => {
                const estabilidadeId = obj?.id || null
                const estabilidade = obj || null
                setData((prev) => ({
                  ...prev,
                  estabilidadeId,
                  estabilidade,
                  dtInicio: null,
                  dtFim: null,
                }))
              }}
              required
              validationErrors={validationErrors}
              name="estabilidadeId"
              value={data?.estabilidade || null}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <DatePickerNew
              label="Data de Início"
              value={data.dtInicio || null}
              onChange={(dt) => {
                const dtInicio = dt || null
                let dtFim: string | null = null
                const numeroDiasEstabilidade = data?.estabilidade?.numeroDias || 0
                if (dtInicio && numeroDiasEstabilidade > 0) {
                  dtFim = moment(dtInicio)
                    .add(numeroDiasEstabilidade - 1, 'days')
                    .format('yyyy-MM-DD')
                }
                setData((prev) => ({ ...prev, dtInicio, dtFim }))
              }}
              required
              validationErrors={validationErrors}
              name="dtInicio"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <DatePickerNew
              label="Data Final"
              value={data.dtFim || null}
              required
              validationErrors={validationErrors}
              name="dtFim"
              onChange={(dt) => {
                const dtFim = dt || null
                setData((prev) => ({ ...prev, dtFim }))
              }}
            />
          </Grid>
        </Grid>
      )}
    </ActionDialog>
  )
}
