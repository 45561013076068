import Provider from './StepperProvider'
import Content from './StepperContent'
import Steps from './StepperSteps'

import { useStepperContext } from './StepperProvider'

const StepperForm = { Provider, Content, Steps }

export default StepperForm

export { useStepperContext }
