import { Box, makeStyles } from '@material-ui/core'

import LinearProgressChart from '../DialogProcessamento/LinearProgressChart'

import { CompetenciaProcessamentoContent } from '~/hooks/queries/Competencia/CompetenciaProcessamentoContent'
import { defaultLog } from '../DialogProcessamento'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: '#f5fffb',
      cursor: 'pointer',
      transition: '0.3s',
    },
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}))

interface ProcessIndicatorProps {
  isProcessando: boolean
  logs: CompetenciaProcessamentoContent[]
  logsEmpregador: CompetenciaProcessamentoContent[]
  logsFuncionario: CompetenciaProcessamentoContent[]
  onClick: () => void
}

export default function ProcessIndicator({
  isProcessando,
  logs,
  logsEmpregador,
  logsFuncionario,
  onClick,
}: ProcessIndicatorProps) {
  const empregadorCurrent = logsEmpregador[logsEmpregador.length - 1] || defaultLog
  const funcionarioCurrent = logsFuncionario[logsFuncionario.length - 1] || defaultLog

  const classes = useStyles()

  return (
    <Box className={classes.root} onClick={onClick}>
      <LinearProgressChart
        isProcessando={isProcessando}
        logs={logs}
        total={funcionarioCurrent.total}
        progress={funcionarioCurrent.progress}
        messageCurrent={
          empregadorCurrent.progress +
          '/' +
          empregadorCurrent.total +
          ' - ' +
          empregadorCurrent.message
        }
        disabledButtonLogs
      />
    </Box>
  )
}
