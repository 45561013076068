import React from 'react'

import { makeStyles, Box, Typography, Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: (props) => (props.direction === 'horizontal' ? 'row' : 'column'),
    alignItems: (props) => props.alignItems,
    justifyContent: (props) => props.justifyContent,
    marginTop: (props) => theme.spacing(props?.top || 0),
    marginBottom: (props) => theme.spacing(props?.bottom || 0),
    marginRight: (props) => theme.spacing(props?.right || 0),
    marginLeft: (props) => theme.spacing(props?.left || 0),
  },
  label: {
    fontWeight: 'bolder',
    fontSize: '9pt',
  },
  value: {
    marginLeft: theme.spacing(0.5),
    fontSize: '10pt',
  },
}))

const LabelValue = (props) => {
  const {
    label,
    value,
    separator,
    direction,
    alignItems,
    justifyContent,
    showSeparator,
    showUnderline,
    top,
    bottom,
    left,
    right,
  } = props
  const classes = useStyles({
    direction,
    alignItems,
    justifyContent,
    top,
    bottom,
    left,
    right,
  })
  return (
    <Box className={classes.root}>
      <Typography className={classes.label} variant="body2">
        {label}
        {showSeparator && separator}
      </Typography>
      {showUnderline && <Divider style={{ flex: 1 }} />}
      <Typography className={classes.value} variant="body2">
        {value}
      </Typography>
    </Box>
  )
}

LabelValue.defaultProps = {
  separator: ':',
  direction: 'horizontal',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  showSeparator: true,
  showUnderline: false,
}

export default LabelValue
