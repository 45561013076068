import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { AutoCompletePessoaFisicaDependente } from '~/components/AutoComplete'
import { CurrencyTextField } from '~/components'

import api from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

const schema = yup.object().shape({
  pessoaFisicaDependente: yup.string().required('Informe o Dependente'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, pessoaFisicaId } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/VinculoPlanoSaudeDependente/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/VinculoPlanoSaudeDependente', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Plano de Saúde | Dependentes"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompletePessoaFisicaDependente
            required
            validationErrors={validationErrors}
            name="pessoaFisicaDependente"
            onChange={(e, pessoaFisicaDependente) => {
              const pessoaFisicaDependenteId = pessoaFisicaDependente?.id || ''

              if (pessoaFisicaDependenteId && !pessoaFisicaDependente?.nrInscricao) {
                return dialogNotification.warning({
                  descriptions: [
                    `Não é possível selecionar o Dependente ${pessoaFisicaDependente.nome}, não possuí CPF registrado`,
                  ],
                })
              }

              setData({
                ...data,
                pessoaFisicaDependenteId,
                pessoaFisicaDependente,
              })
            }}
            value={data?.pessoaFisicaDependente || null}
            pessoaFisicaId={pessoaFisicaId}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Vr. Mensalidade"
            fullWidth
            value={data?.vrMensalidade || 0}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const vrMensalidade = value
              setData({ ...data, vrMensalidade })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Vr. Custo"
            fullWidth
            value={data?.vrCusto || 0}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const vrCusto = value
              setData({ ...data, vrCusto })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
