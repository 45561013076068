import { useState } from 'react'

import { Box, Collapse, IconButton, Paper, useTheme } from '@material-ui/core'
import { ChevronLeft, ExpandMore, Payment } from '@material-ui/icons'

import { Button, DragDropFile, Finder } from '~/components'

import Header from './Header'
import Table from './Table'
import Footer from './Footer'
import DialogRecibosImportados from './DialogRecibosImportados'

import useExtrairPlanilha from '~/hooks/queries/ImportarEventosRP/useExtrairPlanilha'
import useProcessarPlanilha from '~/hooks/queries/ImportarEventosRP/useProcessarPlanilha'
import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { PlanilhaExtracaoExibicaoDTO } from '~/hooks/queries/ImportarEventosRP/ImportarEventosRPDTO'

export default function ImportarPlanilha() {
  const [query, setQuery] = useState('')
  const [data, setData] = useState<{
    data: PlanilhaExtracaoExibicaoDTO
    file: FormData
  } | null>(null)
  const [isCollapseDragComponent, setCollapseDragComponent] = useState(true)

  const { mutateAsync: mutateAsyncExtrair, isLoading: isLoadingExtrair } = useExtrairPlanilha()
  const { mutateAsync: mutateAsyncProcessar, isLoading: isLoadingProcessar } =
    useProcessarPlanilha()
  const theme = useTheme()
  const { anoMes } = useAmbiente()
  const {
    close: closeDialogRecibosImportados,
    isOpen: isOpenDialogRecibosImportados,
    open: openDialogRecibosImportados,
  } = useDialog(null)
  const dialogNotification = useDialogNotification()

  async function onDropped(files: File[]) {
    const formData = new FormData()
    formData.append('file', files[0])
    const response = await mutateAsyncExtrair(formData)
    setCollapseDragComponent(false)
    setData({
      data: response,
      file: formData,
    })
  }

  function handleCancel() {
    setData(null)
    setCollapseDragComponent(true)
  }

  async function handleProcess() {
    if (!data?.file) return
    const response = await mutateAsyncProcessar({
      file: data.file,
      anoMes,
    })
    if (response.length > 0) {
      dialogNotification.warning({
        descriptions: ['Não foi possível processar os seguintes funcionários', ...response],
      })
    }
    handleCancel()
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
      height="100%"
      width="100%"
    >
      <Box
        display="flex"
        gridGap={theme.spacing(1)}
        alignItems="center"
        justifyContent="flex-end"
        padding={1}
        component={Paper}
      >
        <IconButton
          color="primary"
          size="small"
          onClick={() => openDialogRecibosImportados(null)}
          title="Recibos Importados"
        >
          <Payment />
        </IconButton>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </Box>

      <Box display="flex" gridGap={theme.spacing(1)} component={Paper} padding={1}>
        <Collapse in={isCollapseDragComponent} style={{ width: '100%' }}>
          <Box>
            <DragDropFile
              onDropped={onDropped}
              acceptFiles={['.xlsx']}
              isLoading={isLoadingExtrair}
            />
          </Box>
        </Collapse>
        <Box>
          <IconButton
            color="primary"
            size="small"
            onClick={() => setCollapseDragComponent((prev) => !prev)}
          >
            {isCollapseDragComponent ? (
              <ChevronLeft fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </IconButton>
        </Box>
      </Box>

      <Header data={data?.data} />

      <Box flex={1} position="relative" overflow="auto" minHeight={300}>
        <Box position="absolute" width="100%">
          <Table
            data={data?.data.funcionarios || []}
            eventos={data?.data.eventosTotais || []}
            query={query}
          />
        </Box>
      </Box>

      <Footer data={data?.data?.eventosTotais || []} />

      <Box
        component={Paper}
        p={1}
        display="flex"
        gridGap={theme.spacing(1)}
        justifyContent="flex-end"
      >
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button
          disabled={data ? false : true}
          variant="contained"
          isLoading={isLoadingProcessar}
          onClick={handleProcess}
        >
          Processar
        </Button>
      </Box>

      {isOpenDialogRecibosImportados && (
        <DialogRecibosImportados
          isOpen={isOpenDialogRecibosImportados}
          onClose={closeDialogRecibosImportados}
        />
      )}
    </Box>
  )
}
