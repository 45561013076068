import React, { useEffect, useState, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { Finder, ButtonBox, Button, PageHeader } from 'mio-library-ui'

import { TreeView, ConfirmDeleteDialog } from '~/components'

import Form from './components/Form'
import Table from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useHandleError from '~/hooks/useHandleError'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'

const HEIGHT_HEADER = '60px'

export default function CargoAgenteNocivo() {
  const [dataTable, setDataTable] = useState([])
  const [isLoadingDataTable, setLoadingDataTable] = useState(false)
  const [isLoadingOnClick, setLoadingOnClick] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [dataSelected, setDataSelected] = useState({})
  const [collection, setCollection] = useState([])
  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [query, setQuery] = useState('')

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { extractErrorMessage } = useHandleError()
  const { empregador } = useAmbiente()

  useEffect(() => {
    async function getDataMenu() {
      setLoadingDataTable(true)
      try {
        const response = await api.get(`/Cargo/${empregador?.id}/SelecionarEmpregador`)
        setDataTable(response?.data?.data || [])
      } catch (error) {
        const msg = extractErrorMessage(error)
        setErrorMessage(msg)
      } finally {
        setLoadingDataTable(false)
      }
    }
    getDataMenu()
    //eslint-disable-next-line
  }, [empregador])

  async function onClickItem(e, cargo) {
    setLoadingOnClick(true)
    try {
      const response = await api.get('CargoAgenteNocivo/GetByCargo', {
        params: {
          cargoId: cargo?.id,
        },
      })
      setDataSelected(cargo)
      setCollection(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingOnClick(false)
    }
  }

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleOpenForm = useCallback((data = {}) => {
    setForm({
      isOpen: true,
      data,
    })
  }, [])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const item = collection.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [collection, handleOpenForm],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog({
      isOpen: false,
      isDeleting: false,
      id: null,
    })
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    try {
      await api.delete(`/CargoAgenteNocivo/${confirmDeleteDialog.id}`)
      const newItens = collection.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection(newItens)
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection, confirmDeleteDialog.id, handleCloseConfirmDeleteItem])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm({
      cargo: dataSelected,
      cargoId: dataSelected?.id,
    })
  }, [handleOpenForm, dataSelected])

  const handleCloseForm = useCallback(() => {
    setForm({
      isOpen: false,
      data: {},
    })
  }, [])

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const newItens = [data, ...collection]
        setCollection(newItens)
        handleCloseForm()
        handleOpenForm(data)
      }

      const handleAfterUpdate = (data) => {
        const newItens = collection.map((i) => (i.id === data.id ? data : i))
        setCollection(newItens)
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection, handleCloseForm, handleOpenForm],
  )

  return (
    <TreeView
      onSelectItem={onClickItem}
      data={[{ groupName: 'Cargo', data: dataTable }]}
      isLoading={isLoadingDataTable}
      isLoadingOnClick={isLoadingOnClick}
      searchBy={['codigo', 'nome']}
      renderOption={(option) => `${option.codigo} - ${option.nome}`}
      errorMessage={errorMessage}
    >
      <Box height={HEIGHT_HEADER}>
        <PageHeader
          title="Cargo Agente Nocivo"
          subtitle={dataSelected?.id ? `${dataSelected?.codigo} - ${dataSelected?.nome}` : '-'}
        >
          {dataSelected?.id && (
            <ButtonBox>
              <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
              <Button size="small" color="primary" variant="contained" onClick={handleClickAddItem}>
                Adicionar
              </Button>
            </ButtonBox>
          )}
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEIGHT_HEADER})`}>
        <Table
          data={collection}
          isLoading={isLoadingDataTable}
          query={query}
          onItemClick={handleClickItem}
        />
      </Box>

      <Form
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </TreeView>
  )
}
