import React, { useState, useEffect, useCallback } from 'react'

import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { TextField, ButtonBox } from 'mio-library-ui'

import { AutoCompleteCidade } from '~/components/AutoComplete'
import { ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import DiaMesTextField from '~/components/DiaMesTextField'

const schema = yup.object().shape({
  descricao: yup.string().required('Informe a Descrição'),
  dtFeriado: yup.string().required('Informe a Data').nullable(),
  cidade: yup
    .string()
    .when('isFeriadoNacional', (isFeriadoNacional, schema) =>
      isFeriadoNacional !== true ? schema.required('Informe a Cidade') : schema,
    )
    .nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/Feriado/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/Feriado', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Feriado"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Descrição"
            fullWidth
            value={data.descricao || ''}
            variant="outlined"
            size="small"
            required
            autoFocus
            name="descricao"
            inputProps={{
              maxLength: 80,
            }}
            validationErrors={validationErrors}
            onChange={(e) => {
              const descricao = e.target.value
              setData({ ...data, descricao })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DiaMesTextField
            name="dtFeriado"
            label="Data Feriado"
            required
            value={data?.dtFeriado || ''}
            onChange={(e, dtFeriado) =>
              setData((oldState) => ({
                ...oldState,
                dtFeriado,
              }))
            }
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <ButtonBox>
            <FormControlLabel
              label="Feriado Nacional"
              control={
                <Checkbox
                  size="small"
                  checked={data?.isFeriadoNacional || false}
                  onChange={(e) => {
                    const isFeriadoNacional = e.target.checked
                    setData((oldState) => ({
                      ...oldState,
                      isFeriadoNacional,
                    }))

                    if (isFeriadoNacional === true) {
                      setData((oldState) => ({
                        ...oldState,
                        cidade: '',
                      }))
                    }
                  }}
                  color="secondary"
                />
              }
            />
          </ButtonBox>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteCidade
            name="cidade"
            required={data?.isFeriadoNacional !== true}
            disabled={data?.isFeriadoNacional === true}
            value={data.cidade || null}
            onChange={(e, cidade) => {
              const cidadeId = cidade ? cidade.id : null
              setData({ ...data, cidadeId, cidade })
            }}
            validationErrors={validationErrors}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
