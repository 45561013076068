import { useState } from 'react'

import { Box, useTheme, makeStyles, Divider, Typography } from '@material-ui/core'
import { ActionMenu } from '~/components'
import clsx from 'clsx'

const useStyles = makeStyles((theme: FixLater) => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    gridGap: theme.spacing(0.5),
  },
  hightLight: {
    border: theme.shape.border,
  },
}))

interface NotificationItemProps {
  title: string
  description: string
  date: string
  color: string
  hightLight: boolean
  onClick: () => void
}

export default function NotificationItem({
  title,
  description,
  date,
  color,
  hightLight,
  onClick,
}: NotificationItemProps) {
  const [isHover, setHover] = useState(false)

  const theme = useTheme()
  const classes = useStyles()

  return (
    <Box
      className={clsx(classes.root, { [classes.hightLight]: hightLight })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box position="absolute" right={theme.spacing(0.5)} top={theme.spacing(0.5)}>
        {isHover && (
          <ActionMenu
            options={[
              {
                icon: 'check',
                label: 'Marca como Lida',
                onClick,
              },
            ]}
          />
        )}
      </Box>
      <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
        <Box height={4} width={4} border={4} borderColor={color} borderRadius="50%" padding={0.5} />
        <Box fontWeight="bold" color="text.primary">
          {title}
        </Box>
      </Box>
      {description && (
        <Box display="flex" gridGap={theme.spacing(1)}>
          <Box width={16} display="flex" justifyContent="center">
            <Divider orientation="vertical" />
          </Box>
          <Box>
            <Typography variant="subtitle2">{date}</Typography>
            <Box paddingTop={0.5} paddingBottom={0.5} color="text.secondary">
              {description}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
