export enum IndBaseCSESocialEnum {
  Normal_11 = 11,
  Aposentadoria15_12 = 12,
  Aposentadoria20_13 = 13,
  Aposentadoria25_14 = 14,
  Exclusiva_empregador_15 = 15,
  Exclusiva_empregador15_16 = 16,
  Exclusiva_empregador20_17 = 17,
  Exclusiva_empregador25_18 = 18,
  Exclusiva_empregado_19 = 19,
  Vr_total_recolhimento_previdencia_21 = 21,
  Vr_total_recolhimento_sest_22 = 22,
  Vr_total_recolhimento_senat_23 = 23,
  Vr_pago_SA_31 = 31,
  BC_CP_decisao_judicial_91 = 91,
  BC_CP_decisao_judicial15_92 = 92,
  BC_CP_decisao_judicial20_93 = 93,
  BC_CP_decisao_judicial25_94 = 94,
  BC_CP_decisao_judicial_exclusiva_empregador_95 = 95,
  BC_CP_decisao_judicial_exclusiva_empregador15_96 = 96,
  BC_CP_decisao_judicial_exclusiva_empregador20_97 = 97,
  BC_CP_decisao_judicial_exclusiva_empregador25_98 = 98,
}

export const IndBaseCSESocialValues = [
  {
    value: IndBaseCSESocialEnum.Normal_11,
    name: '11 - Base de cálculo da Contribuição Previdenciária normal',
  },
  {
    value: IndBaseCSESocialEnum.Aposentadoria15_12,
    name: '12 - Base de cálculo da Contribuição Previdenciária adicional para o financiamento dos benefícios de aposentadoria especial após 15 anos de contribuição',
  },
  {
    value: IndBaseCSESocialEnum.Aposentadoria20_13,
    name: '13 - Base de cálculo da Contribuição Previdenciária adicional para o financiamento dos benefícios de aposentadoria especial após 20 anos de contribuição',
  },
  {
    value: IndBaseCSESocialEnum.Aposentadoria25_14,
    name: '14 - Base de cálculo da Contribuição Previdenciária adicional para o financiamento dos benefícios de aposentadoria especial após 25 anos de contribuição',
  },
  {
    value: IndBaseCSESocialEnum.Exclusiva_empregador_15,
    name: '15 - Base de cálculo da contribuição previdenciária exclusiva do empregador',
  },
  {
    value: IndBaseCSESocialEnum.Exclusiva_empregador15_16,
    name: '16 - Base de cálculo da Contribuição Previdenciária adicional para o financiamento dos benefícios de aposentadoria especial após 15 anos de contribuição – Exclusiva do empregador',
  },
  {
    value: IndBaseCSESocialEnum.Exclusiva_empregador20_17,
    name: '17 - Base de cálculo da Contribuição Previdenciária adicional para o financiamento dos benefícios de aposentadoria especial após 20 anos de contribuição – Exclusiva do empregador',
  },
  {
    value: IndBaseCSESocialEnum.Exclusiva_empregador25_18,
    name: '18 - Base de cálculo da Contribuição Previdenciária adicional para o financiamento dos benefícios de aposentadoria especial após 25 anos de contribuição – Exclusiva do empregador',
  },
  {
    value: IndBaseCSESocialEnum.Exclusiva_empregado_19,
    name: '19 - Base de cálculo da contribuição previdenciária exclusiva do empregado.',
  },
  {
    value: IndBaseCSESocialEnum.Vr_total_recolhimento_previdencia_21,
    name: '21 - Valor total descontado do trabalhador para recolhimento à Previdência Social',
  },
  {
    value: IndBaseCSESocialEnum.Vr_total_recolhimento_sest_22,
    name: '22 - Valor descontado do trabalhador para recolhimento ao Sest',
  },
  {
    value: IndBaseCSESocialEnum.Vr_total_recolhimento_senat_23,
    name: '23 - Valor descontado do trabalhador para recolhimento ao Senat',
  },
  {
    value: IndBaseCSESocialEnum.Vr_pago_SA_31,
    name: '31 - Valor pago ao trabalhador a título de salário-família',
  },
  {
    value: IndBaseCSESocialEnum.BC_CP_decisao_judicial_91,
    name: '91 - Base de cálculo (BC) da Contribuição Previdenciária (CP) Normal – Incidência suspensa em decorrência de decisão judicial',
  },
  {
    value: IndBaseCSESocialEnum.BC_CP_decisao_judicial15_92,
    name: '92 - BC CP Aposentadoria Especial aos 15 anos de trabalho – Incidência suspensa em decorrência de decisão judicial',
  },
  {
    value: IndBaseCSESocialEnum.BC_CP_decisao_judicial20_93,
    name: '93 - BC CP Aposentadoria Especial aos 20 anos de trabalho – Incidência suspensa em decorrência de decisão judicial',
  },
  {
    value: IndBaseCSESocialEnum.BC_CP_decisao_judicial25_94,
    name: '94 - BC CP Aposentadoria Especial aos 25 anos de trabalho – Incidência suspensa em decorrência de decisão judicial',
  },
  {
    value: IndBaseCSESocialEnum.BC_CP_decisao_judicial_exclusiva_empregador_95,
    name: '95 - Base de cálculo (BC) da Contribuição Previdenciária (CP) Normal – Incidência suspensa em decorrência de decisão judicial – Exclusiva Empregador',
  },
  {
    value: IndBaseCSESocialEnum.BC_CP_decisao_judicial_exclusiva_empregador15_96,
    name: '96 - BC CP Aposentadoria Especial aos 15 anos de trabalho – Incidência suspensa em decorrência de decisão judicial – Exclusiva Empregador',
  },
  {
    value: IndBaseCSESocialEnum.BC_CP_decisao_judicial_exclusiva_empregador20_97,
    name: '97 - BC CP Aposentadoria Especial aos 20 anos de trabalho – Incidência suspensa em decorrência de decisão judicial – Exclusiva Empregador',
  },
  {
    value: IndBaseCSESocialEnum.BC_CP_decisao_judicial_exclusiva_empregador25_98,
    name: '98 - BC CP Aposentadoria Especial aos 25 anos de trabalho – Incidência suspensa em decorrência de decisão judicial – Exclusiva Empregador',
  },
]
