import { useMutation, useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'

async function getAll() {
  const response = await api.get('/EstabelecimentoConsulta')
  return response?.data?.data || []
}

export async function getEstabelecimentoById(id) {
  const response = await api.get(`/Estabelecimento/${id}`)
  return response?.data?.data || {}
}

async function remove(id) {
  await api.delete(`/Estabelecimento/${id}`)
}

async function createEstabelecimentoSST(dt) {
  await api.post('/Estabelecimento/CriarEstabelecimentoSST', dt)
}

async function updateEstabelecimento(dt) {
  await api.put(`/Estabelecimento/${dt.id}`, dt)
}

export function useEstabelecimento() {
  const dialogNotification = useDialogNotification()

  return useQuery('Estabelecimento', getAll, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useEstabelecimentoMutate() {
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  const mutateDelete = useMutation(remove, {
    onSuccess: async () => await queryClient.invalidateQueries('Estabelecimento'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateCreateEstabelecimentoSST = useMutation(createEstabelecimentoSST, {
    onSuccess: async () => await queryClient.invalidateQueries('Estabelecimento'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateUpdateEstabelecimento = useMutation(updateEstabelecimento, {
    onSuccess: async () => await queryClient.invalidateQueries('Estabelecimento'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  return {
    mutateDelete,
    mutateCreateEstabelecimentoSST,
    mutateUpdateEstabelecimento,
  }
}
