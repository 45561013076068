import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestProps {
  eventoEmpregadorId: string
}

export function useGerarAuditoria() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    await api.post('/EventoEmpregador/GerarAuditoria', null, {
      params: props,
    })
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
    onSuccess: () => notification.success('Auditoria gerada com sucesso'),
  })
}
