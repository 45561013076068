import { useEffect, useState } from 'react'

import { useQuery, useQueryClient, useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'

import { EstabilidadeData } from './useEstabilidade'

interface VinculoEstabilidadeData {
  id: string
  vinculoId: string
  estabilidadeId: string
  dtInicio: string
  dtFim: string
  vinculoAfastamentoId?: string

  estabilidade: EstabilidadeData
}

interface VinculoEstabilidadeDataForm {
  id?: string | null
  vinculoId?: string
  estabilidadeId?: string | null
  dtInicio?: string | null
  dtFim?: string | null
  vinculoAfastamentoId?: string | null
  estabilidade?: EstabilidadeData | null
}

export function useVinculoEstabilidadeGetAllPorVinculo(vinculoId: string | null) {
  const dialogNotification = useDialogNotification()

  async function getAllPorVinculo() {
    if (!vinculoId) return []
    const response = await api.get('/VinculoEstabilidade/ObterVinculoEstabilidadesPorVinculo', {
      params: { vinculoId },
    })
    return response.data.data
  }

  return useQuery<FixLater, FixLater, VinculoEstabilidadeData[]>(
    ['VinculoEstabilidade', vinculoId],
    () => {
      return getAllPorVinculo()
    },
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}

export function useVinculoEstabilidadeById(id: string | null) {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<VinculoEstabilidadeDataForm>({})

  const dialogNotification = useDialogNotification()

  useEffect(() => {
    async function getData() {
      if (!id) return
      setLoading(true)
      try {
        const response = await api.get(`/VinculoEstabilidade/${id}`)
        setData(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getData()
    //eslint-disable-next-line
  }, [id])

  return { data, setData, isLoading }
}

export function useVinculoEstabilidadeMutation() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function update(data: FixLater) {
    await api.put(`/VinculoEstabilidade/${data.id}`, data)
    notification.put()
  }
  async function insert(data: FixLater) {
    await api.post('/VinculoEstabilidade', data)
    notification.post()
  }

  return useMutation(
    async (data: FixLater) => {
      if (data.id) {
        await update(data)
        return
      }
      await insert(data)
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries('VinculoEstabilidade'),
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}

export function useVinculoEstabilidadeDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleDelete(id: string) {
    await api.delete(`/VinculoEstabilidade/${id}`)
    notification.remove()
  }

  return useMutation((id: string) => handleDelete(id), {
    onSuccess: async () => await queryClient.invalidateQueries('VinculoEstabilidade'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
