import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'

import { PDFViewer, TreeView, PageHeader } from '~/components'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import api from '~/services/api-pessoal'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const TITLE_PAGE = 'Requerimento de Abono Pecuniário de Férias'

export default function RequerimentoAbonoPecuniario() {
  const [vinculo, setVinculo] = useState(null)

  const [collection, setCollection] = useState([])
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const {
    close: closeDialogConfirm,
    data: dataDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog(null)
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Estagiario_3,
  ])
  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    setVinculo(null)
    setCollection([])
  }, [anoMes, estabelecimento])

  async function getCollectionByVinculo(vinculoCurrent) {
    if (!vinculoCurrent?.vinculoId) return
    setLoading(true)
    try {
      setVinculo(vinculoCurrent)
      const response = await api.get(
        '/Relatorio/RelRequerimentoAbonoPecuniario/ObterPeriodosAquisitivosAbonoVinculo',
        {
          params: {
            vinculoId: vinculoCurrent?.vinculoId,
          },
        },
      )
      setCollection(response?.data?.data || [])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  function handleAfterConfirm(dt) {
    openPDFViewer(dt)
  }

  function onClickPrintItem(id) {
    openDialogConfirm(id)
  }

  return (
    <TreeView
      onSelectItem={(e, vinculoCurrent) => getCollectionByVinculo(vinculoCurrent)}
      data={dataMenu}
      isLoading={isLoadingMenu}
      searchBy={['vinculoNome']}
      renderOption={(option) => option?.vinculoNome}
    >
      <Box height={50}>
        <PageHeader title={TITLE_PAGE} subtitle={vinculo?.vinculoNome} />
      </Box>

      <Table data={collection} isLoading={isLoading} handleClickPrint={onClickPrintItem} />

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        data={dataDialogConfirm}
        onClose={closeDialogConfirm}
        onAfterSubmit={handleAfterConfirm}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="RequerimentoAbonoPecuniario"
        title={TITLE_PAGE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/RelRequerimentoAbonoPecuniario/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </TreeView>
  )
}
