import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { queryObterTodosPorVinculo } from './useObterTodosPorVinculo'

export default function useVinculoValeTransporteRemove() {
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  async function handleRequest(vinculoValeTransporteId: string) {
    await api.delete('/VinculoValeTransporte', {
      params: {
        vinculoValeTransporteId,
      },
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryObterTodosPorVinculo)
    },
    onError: dialogNotification.extractErrors,
  })
}
