import React from 'react'

import { Box } from '@material-ui/core'

interface ContainerTableProps {
  children: React.ReactNode
}

export default function ContainerTable({ children }: ContainerTableProps) {
  return (
    <Box flex={1} position="relative" minHeight={300} overflow="auto">
      <Box position="absolute" width="100%">
        {children}
      </Box>
    </Box>
  )
}
