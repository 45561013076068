import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { VinculoPartial } from './Vinculo'
import { VinculoSalarioBasePartial } from '../VinculoSalarioBase/VinculoSalarioBase'
import { VinculoLotacaoPartial } from '../VinculoLotacao/VinculoLotacao'
import { VinculoSalarioAdicionalPartial } from '../VinculoSalarioAdicional/VinculoSalarioAdicional'
import { VinculoSalarioAulaPartial } from '../VinculoSalarioAula/VinculoSalarioAula'
import { VinculoLocalPartial } from '../VinculoLocal/VinculoLocal'
import { VinculoHorarioPartial } from '../VinculoHorario/VinculoHorario'
import { VinculoObservacaoPartial } from '../VinculoObservacao/VinculoObservacao'
import { VinculoEstagioPartial } from '../VinculoEstagio/VinculoEstagio'

interface DataVinculoCadastroCompleto {
  vinculo: VinculoPartial
  vinculoSalarioBase?: VinculoSalarioBasePartial
  vinculoLotacao?: VinculoLotacaoPartial
  listVinculoSalarioAdicional?: VinculoSalarioAdicionalPartial[]
  vinculoSalarioAula?: VinculoSalarioAulaPartial
  vinculoLocal: VinculoLocalPartial
  vinculoHorario?: VinculoHorarioPartial
  vinculoObservacao?: VinculoObservacaoPartial
  vinculoEstagio?: VinculoEstagioPartial
}

export function useVinculoCadastroCompleto() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: DataVinculoCadastroCompleto) {
    await api.post('/Vinculo/CadastroCompleto', data)
    notification.post()
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries('VinculoConsultaGetByEstabelecimento'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
