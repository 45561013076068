import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestProps {
  data: string[]
  params: {
    competencia: string
  }
}

export function useProcessarParaRecibos() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest({ data, params }: RequestProps) {
    await api.post('/ValeTransporteApuracao/ProcessarParaRecibos', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Apuração processada com sucesso para os recibos'),
    onError: dialogNotification.extractErrors,
  })
}
