import { useState } from 'react'

import { CopyLabel, ToolsDataTable } from '~/components'

import TableApurados from './Table/TableApurados'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { ValeTransporteApuracaoEstabelecimentoDTO } from '~/hooks/queries/ValeTransporteApuracao/ValeTransporteApuracaoDTO'

interface TableProps {
  data: ValeTransporteApuracaoEstabelecimentoDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
  rowsSelected: number[]
  setRowsSelected: (rows: number[]) => void
}

export default function Table({
  data,
  isLoading,
  isFetching,
  query,
  rowsSelected,
  setRowsSelected,
}: TableProps) {
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'estabelecimentoNome',
      label: 'Estabelecimento',
    },
    {
      name: 'estabelecimentoNrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['estabelecimentoNome', 'estabelecimentoNrInscricao'],
      }}
      optionsExpandable={{
        onRowExpanded: setRowsExpanded,
        rowsExpanded: rowsExpanded,
        type: 'single',
        expandedChildren: (index) => (
          <TableApurados estabelecimentoId={data[index].estabelecimentoId} />
        ),
      }}
      optionsSelectable={{
        type: 'multiple',
        rowsSelected: rowsSelected,
        onRowSelected: setRowsSelected,
      }}
    />
  )
}
