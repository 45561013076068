import { useState, useEffect, useCallback } from 'react'

import moment from 'moment'
import * as yup from 'yup'
import api from '~/services/api-pessoal'
import { isCPF } from 'brazilian-values'
import { cpf } from 'cpf-cnpj-validator'

import { Box, CircularProgress, Grid, InputAdornment, LinearProgress } from '@material-ui/core'
import { TextField } from 'mio-library-ui'

import { AutoCompleteCargo, MUIAutoComplete } from '~/components/AutoComplete'
import {
  AlertContainer,
  DatePicker,
  NumeroInscricaoTextField,
  CurrencyTextField,
  ActionDialog,
} from '~/components'

import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import { verifyIfExistsMatricula } from '~/hooks/useUtils'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useVinculoObterMatricula } from '~/hooks/queries/Vinculo/useVinculoObterMatricula'

import { CategoriaValues } from '~/@types/enums/CategoriaEnum'
import {
  tipoContratoTrabalhoValues,
  tipoContratoTrabalhoConsts,
} from '~/values/tipoContratoTrabalhoValues'
import { indUnidadeSalarioValues } from '~/values/indUnidadeSalarioValues'
import { naturezaAtividadeValues } from '~/values/naturezaAtividadeValues'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import { IndCadastroMatriculaEnum } from '~/@types/enums/IndCadastroMatriculaEnum'

const { prazoDeterminado_2 } = tipoContratoTrabalhoConsts
const { CPF_2 } = tipoInscricaoConsts

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  nrInscricao: yup
    .string()
    .required('Informe o CPF')
    .test('cpf-validate', 'Informe um CPF válido', (nrInscricao) => isCPF(nrInscricao)),
  dtNascimento: yup.string().required('Informe a Data Nascimento').nullable(),
  dtAdmissao: yup.string().required('Informe a Data Admissão').nullable(),
  matricula: yup.string().required('Informe a Matrícula'),
  categoria: yup.string().required('Informe a Categoria'),
  cargoId: yup.string().required('Informe o Cargo').nullable(),
  naturezaAtividade: yup.string().required('Informe a Natureza Atividade'),
  tipoContrato: yup.string().required('Informe o Tipo Contrato'),
  vrSalarioFixo: yup.string().required('Informe o Valor Salário Fixo'),
  unidadeSalarioFixo: yup.string().required('Informe a Unidade Salário Fixo'),
  dtTerminoContrato: yup
    .string()
    .when(['tipoContrato'], (tipoContrato, schema) => {
      if (parseInt(tipoContrato) === prazoDeterminado_2)
        return schema.required('Informe o Término Contrato')
    })
    .nullable(),
  nrDias: yup
    .string()
    .nullable()
    .when(['tipoContrato'], (tipoContrato, schema) => {
      if (parseInt(tipoContrato) === prazoDeterminado_2)
        return schema.required('Informe o Número de dias de Contrato')
    }),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props

  const [data, setData] = useState(_data)
  const [isSubmitting, setSubmitting] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { estabelecimento, empregador } = useAmbiente()

  const {
    data: matricula,
    isLoading: _isLoadingMatricula,
    isFetching: isFetchingMatricula,
  } = useVinculoObterMatricula({
    estabelecimentoId: estabelecimento.id,
    cpf: data?.nrInscricao || '',
  })
  const isLoadingMatricula = _isLoadingMatricula || isFetchingMatricula

  const isMatriculaSequencial =
    matricula?.indCadastroMatricula !== IndCadastroMatriculaEnum.Preencher_00

  useEffect(() => {
    if (_data?.id) {
      setData(_data)
      return
    }
    if (!matricula) return
    setData((prev) => ({ ...prev, matricula: matricula.proximaMatricula }))
  }, [matricula, _data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        var dt = { ...data, empregadorId: empregador.id }
        dt.matricula = dt.matricula.trim()

        const response = await api.put(`/vinculoAdmissaoPreliminar/${dt.id}`, dt)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        var dt = { ...data, empregadorId: empregador.id }
        dt.matricula = dt.matricula.trim()

        await verifyIfExistsMatricula(dt.empregadorId, dt.matricula)
        const response = await api.post('/vinculoAdmissaoPreliminar', dt)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setSubmitting(false)
      }
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function getFieldsByPessoaFisica(nrInscricao) {
    if (data?.id) return
    if (!cpf.isValid(nrInscricao)) return
    setLoading(true)
    try {
      const response = await api.get(`PessoaFisicaConsulta/GetByInscricao?Inscricao=${nrInscricao}`)
      const nome = response?.data?.data?.nome || ''
      const dtNascimento = response?.data?.data?.dtNascimento || ''

      setData((oldState) => ({
        ...oldState,
        nome,
        dtNascimento,
      }))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  function handleCalcDiasContratoByDtTerminoContrato(_dtTerminoContrato) {
    const dtTerminoContrato = moment(_dtTerminoContrato)
    const dtAdmissao = moment(data?.dtAdmissao)

    if (!dtTerminoContrato.isValid()) return null
    if (!dtAdmissao.isValid()) return null

    dtTerminoContrato.add(1, 'day')

    const nrDias = dtTerminoContrato.diff(dtAdmissao, 'days')

    return nrDias
  }

  return (
    <ActionDialog
      title="Cadastro de Admissão Preliminar"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Box width="100%" height={4}>
        {isLoadingMatricula && <LinearProgress />}
      </Box>

      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <NumeroInscricaoTextField
            typeMask={CPF_2}
            label="CPF"
            value={data?.nrInscricao || ''}
            required
            autoFocus
            validationErrors={validationErrors}
            name="nrInscricao"
            onChange={(e, value) => {
              const nrInscricao = value
              getFieldsByPessoaFisica(nrInscricao)
              setData({ ...data, nrInscricao })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {isLoading ? <CircularProgress size={16} /> : <></>}
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePicker
            label="Data Nascimento"
            size="small"
            value={data?.dtNascimento || null}
            validationErrors={validationErrors}
            name="dtNascimento"
            onChange={(date) => {
              const dtNascimento = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtNascimento })
            }}
            required
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePicker
            label="Data Admissão"
            size="small"
            value={data?.dtAdmissao || null}
            validationErrors={validationErrors}
            name="dtAdmissao"
            onChange={(date) => {
              const dtAdmissao = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtAdmissao })
            }}
            required
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data?.nome || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 100,
            }}
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Categoria"
            options={CategoriaValues}
            renderOption={(option) => option.name}
            value={data.categoria}
            validationErrors={validationErrors}
            name="categoria"
            required
            optionId="value"
            onChange={(e, obj) => {
              const categoria = obj ? obj.value : ''
              setData({ ...data, categoria })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <AutoCompleteCargo
            onChange={(e, cargo) => {
              const cargoId = cargo ? cargo.id : ''
              setData({ ...data, cargoId })
            }}
            required
            validationErrors={validationErrors}
            name="cargoId"
            value={data?.cargoId || ''}
            empregadorId={data?.empregadorId}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <MUIAutoComplete
            label="Natureza Atividade"
            value={data.naturezaAtividade}
            options={naturezaAtividadeValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="naturezaAtividade"
            onChange={(e, obj) => {
              const naturezaAtividade = obj ? obj.value : ''
              setData({ ...data, naturezaAtividade })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MUIAutoComplete
            label="Tipo Contrato"
            options={tipoContratoTrabalhoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.tipoContrato}
            required
            validationErrors={validationErrors}
            name="tipoContrato"
            onChange={(e, obj) => {
              const tipoContrato = obj ? obj.value : ''
              setData({ ...data, tipoContrato })

              if (tipoContrato !== prazoDeterminado_2) {
                const nrDias = ''
                const dtTerminoContrato = ''
                setData((oldState) => ({
                  ...oldState,
                  tipoContrato,
                  nrDias,
                  dtTerminoContrato,
                }))
              }
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
          <TextField
            label="Número de dias de Contrato"
            fullWidth
            value={data?.nrDias || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 5,
            }}
            onlyNumber
            required={data?.tipoContrato === prazoDeterminado_2 ? true : false}
            disabled={data?.tipoContrato !== prazoDeterminado_2 ? true : false}
            name="nrDias"
            validationErrors={validationErrors}
            onChange={(e) => {
              const nrDias = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                nrDias,
              }))
            }}
            onBlur={() => {
              if (data?.nrDias && data?.dtAdmissao) {
                const dtTerminoContrato = moment(data?.dtAdmissao)
                  .add(data?.nrDias, 'd')
                  .subtract(1, 'd')
                setData((oldState) => ({
                  ...oldState,
                  dtTerminoContrato,
                }))
              }
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={8} xs={12}>
          <DatePicker
            label="Término Contrato"
            size="small"
            value={data?.dtTerminoContrato || null}
            required={data?.tipoContrato === prazoDeterminado_2 ? true : false}
            disabled={data?.tipoContrato !== prazoDeterminado_2 ? true : false}
            name="dtTerminoContrato"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtTerminoContrato = date ? date.format('yyyy-MM-DD') : null
              const nrDias = handleCalcDiasContratoByDtTerminoContrato(dtTerminoContrato)
              if (nrDias) {
                return setData({ ...data, dtTerminoContrato, nrDias })
              }
              setData({ ...data, dtTerminoContrato })
            }}
          />
        </Grid>

        {!_data?.id && !isMatriculaSequencial && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <AlertContainer title="Matrícula anterior do empregador">
              Matrícula anterior: <strong>{matricula?.ultimaMatricula}</strong>
            </AlertContainer>
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Matrícula"
            fullWidth
            value={data?.matricula || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 30,
            }}
            title={
              isMatriculaSequencial
                ? 'A matrícula está configurada como sequêncial no empregador'
                : ''
            }
            disabled={isMatriculaSequencial}
            validationErrors={validationErrors}
            name="matricula"
            onChange={(e) => {
              const matricula = e.target.value
              setData({ ...data, matricula })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <MUIAutoComplete
            label="Unidade Salário Fixo"
            options={indUnidadeSalarioValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="unidadeSalarioFixo"
            value={data.unidadeSalarioFixo}
            onChange={(e, obj) => {
              const unidadeSalarioFixo = obj ? obj.value : ''
              setData({ ...data, unidadeSalarioFixo })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <CurrencyTextField
            label="Valor Salário Fixo"
            fullWidth
            value={data?.vrSalarioFixo || 0}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="vrSalarioFixo"
            onChange={(e, value) => {
              const vrSalarioFixo = value || 0
              setData({ ...data, vrSalarioFixo })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
