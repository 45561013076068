const nao_aplicavel_9 = 9
const previdenciariaPatronal_1 = 1
const previdenciariaPatronalPlusDescontadaSegurados_2 = 2

export const processoExtensaoDecisaoValues = [
  { value: nao_aplicavel_9, name: '9 - Não informar' },
  {
    value: previdenciariaPatronal_1,
    name: '1 - Contribuição Previdenciária Patronal',
  },
  {
    value: previdenciariaPatronalPlusDescontadaSegurados_2,
    name: '2 - Cont. Previdenciária Patronal + Descontada dos Segurados',
  },
]
export const processoExtensaoDecisaoConsts = {
  nao_aplicavel_9,
  previdenciariaPatronal_1,
  previdenciariaPatronalPlusDescontadaSegurados_2,
}
