import { useState } from 'react'

import { Box, useTheme } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ActionDialog, Button, ButtonBox } from '~/components'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import ReciboItem from './components/ReciboItem'

import { RP } from '~/hooks/queries/RP/RP'

interface RecibosListProps {
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: (vinculo: VinculoMenuData) => void
  data: RP[]
  vinculo: VinculoMenuData
  message?: string
}

export default function RecibosList(props: RecibosListProps) {
  const { isOpen, onClose, onAfterSubmit, data, vinculo, message } = props

  const [checked, setChecked] = useState<string[]>([])
  const [isLoading, setLoading] = useState(false)

  const notifications = useNotification()
  const dialogNotification = useDialogNotification()
  const theme = useTheme()

  function handleCheck(recibo: RP) {
    setChecked((prev) => {
      const rpId = recibo.id
      const idFinded = prev.find((rpIdCurrent) => rpIdCurrent === rpId)
      if (idFinded) {
        return prev.filter((d) => d !== rpId)
      } else {
        return [...prev, rpId]
      }
    })
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      const promises = []
      for (let i = 0; i < checked.length; i++) {
        promises.push(api.delete(`RP/${checked[i]}`))
      }
      await Promise.all(promises)
      notifications.success('Os recibos selecionados foram excluídos')
      onAfterSubmit(vinculo)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ActionDialog
      title="Temos um aviso!"
      subtitle={vinculo.vinculoNome}
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            isLoading={isLoading}
            disabled={checked.length > 0 ? false : true}
          >
            Excluir Selecionados
          </Button>
        </ButtonBox>
      }
    >
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
        {message && (
          <Box>
            <Alert style={{ alignItems: 'center' }} severity="info">
              {message}
            </Alert>
          </Box>
        )}
        <Box>
          {data.map((recibo) => (
            <ReciboItem
              key={recibo.id}
              data={recibo}
              isChecked={checked.includes(recibo.id)}
              onCheck={handleCheck}
            />
          ))}
        </Box>
      </Box>
    </ActionDialog>
  )
}
