import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EmpregadorEsocial } from './EmpregadorEsocial'

export const queryUseEmpregadorEsocialObterTodos = '/EmpregadorEsocial'

interface RequestProps {
  grupoId?: string | null
}

export function useEmpregadorEsocialObterTodos(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: EmpregadorEsocial[] }>(
      queryUseEmpregadorEsocialObterTodos,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([queryUseEmpregadorEsocialObterTodos, params], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
