import React from 'react'

import { Box, BoxProps } from '@material-ui/core'

interface ContainerProps {
  children: React.ReactNode
  boxProps?: BoxProps
}

export default function Container(props: ContainerProps) {
  const { children, boxProps } = props
  return (
    <Box padding={2} height="100%" width="100%" {...boxProps}>
      {children}
    </Box>
  )
}
