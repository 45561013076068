import React from 'react'

import moment from 'moment'
import { formatToCNPJ } from 'brazilian-values'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon, Print as PrintIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import { useCondicaoAmbienteTrabalhoGetByVinculo } from '~/hooks/queries/CondicaoAmbienteTrabalho/useCondicaoAmbienteTrabalhoGetByVinculo'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const formatLocalAmbiente = (localAmbiente: string) => {
  switch (localAmbiente) {
    case '1':
      return 'Estabelecimento do próprio Empregador'
    case '2':
      return 'Estabelecimento de terceiros'
    default:
      return ''
  }
}

const formatCAEPF = (nrInscricao: string) => {
  const newNrInscricao = `${nrInscricao.substring(0, 3)}.${nrInscricao.substring(
    3,
    3,
  )}.${nrInscricao.substring(6, 3)}/${nrInscricao.substring(9, 3)}-${nrInscricao.substring(12)}`
  return newNrInscricao
}

const formatCNO = (nrInscricao: string) => {
  const newNrInscricao = `${nrInscricao.substring(0, 2)}.${nrInscricao.substring(
    2,
    3,
  )}.${nrInscricao.substring(5, 5)}/${nrInscricao.substring(10)}`
  return newNrInscricao
}

const formatNrInscricao = (nrInscricao: string, tipoInscricao: TipoInscricaoEnum) => {
  const maps = {
    [TipoInscricaoEnum.CNPJ_1]: formatToCNPJ(nrInscricao),
    [TipoInscricaoEnum.CPF_2]: nrInscricao,
    [TipoInscricaoEnum.CAEPF_3]: formatCAEPF(nrInscricao),
    [TipoInscricaoEnum.CNO_4]: formatCNO(nrInscricao),
    [TipoInscricaoEnum.CGC_5]: nrInscricao,
    [TipoInscricaoEnum.CEI_6]: nrInscricao,
    [TipoInscricaoEnum.NaoInformar_0]: nrInscricao,
  }
  return maps[tipoInscricao]
}
interface TableProps {
  onItemClick: (event: 'edit' | 'delete' | 'print', id: string) => void
  query: string
  vinculoId: string
}

export default function Table(props: TableProps) {
  const { onItemClick, query, vinculoId } = props

  const { data: _data, isLoading, isFetching } = useCondicaoAmbienteTrabalhoGetByVinculo(vinculoId)

  const data = _data
    ? _data.map((item) => {
        return {
          ...item,
          dtInicio: item?.dtInicio ? moment(item.dtInicio).format('DD/MM/yyyy') : '',
          localAmbiente: item?.localAmbiente ? formatLocalAmbiente(item.localAmbiente) : '',
          dtReciboEsocial: item?.dtReciboEsocial
            ? moment(item.dtReciboEsocial).format('DD/MM/yyyy')
            : '',
        }
      })
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'tipoInscricao',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'dtInicio',
      label: 'Data Início',
    },
    {
      name: 'localAmbiente',
      label: 'Tipo Local',
    },
    {
      name: 'descricaoSetor',
      label: 'Descrição Setor',
    },
    {
      name: 'nrInscricao',
      label: 'Número Inscrição',
      options: {
        customBodyRender: (value, tableMeta) => {
          const tipoInscricao = tableMeta.rowData[0]
          return formatNrInscricao(value, tipoInscricao)
        },
      },
    },
    {
      name: 'reciboEsocial',
      label: 'eSocial',
    },
    {
      name: 'dtReciboEsocial',
      label: 'Data eSocial',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Imprimir este registro"
                color="primary"
                aria-label="Imprimir"
                onClick={() => {
                  onItemClick('print', value)
                }}
              >
                <PrintIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                size="small"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['localAmbiente', 'descricaoSetor', 'nrInscricao', 'reciboEsocial'],
      }}
    />
  )
}
