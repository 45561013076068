import React, { useEffect, useRef } from 'react'

import moment from 'moment'

import { makeStyles } from '@material-ui/core'

import '@fullcalendar/react/dist/vdom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

const useStyles = makeStyles(() => ({
  eventContentRemoveOpacity: {
    opacity: 'unset!important',
  },
  pointerCursorHover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'ghostwhite',
    },
  },
}))

interface CalendarProps {
  dates: string[]
  anoMes?: string
  onClickDate?: (date: string) => void
  onClickMultipleDate?: (dateStart: string, dateEnd: string) => void
  renderDate?: (date: string) => React.ReactNode
}

export default function Calendar(props: CalendarProps) {
  const { dates: _dates, anoMes, onClickDate, onClickMultipleDate, renderDate } = props
  const dates = _dates.map((d) => ({ date: d }))

  const calendarRef = useRef<FullCalendar>(null)
  const classes = useStyles()

  useEffect(() => {
    if (calendarRef.current && anoMes) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.gotoDate(anoMes)
    }
  }, [anoMes])

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.setOption('height', '100%')
    }
  }, [])

  function handleDateClick(date: string) {
    if (onClickDate) {
      onClickDate(date)
    }
  }

  function handleSelect(dateStart: string, dateEnd: string) {
    if (onClickMultipleDate) {
      const fixDateEnd = moment(dateEnd).subtract(1, 'day').format('yyyy-MM-DD')
      if (dateStart === fixDateEnd) return
      onClickMultipleDate(dateStart, fixDateEnd)
    }
  }

  function handleEventContent(date: string) {
    if (renderDate) {
      return renderDate(date)
    }
  }

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      locale="pt-br"
      showNonCurrentDates={false}
      headerToolbar={false}
      selectable={true}
      eventDisplay="background"
      eventClassNames={() => classes.eventContentRemoveOpacity}
      dayCellClassNames={() => classes.pointerCursorHover}
      events={dates}
      dateClick={({ dateStr }) => handleDateClick(dateStr)}
      select={({ startStr, endStr }) => handleSelect(startStr, endStr)}
      eventContent={({ event }) => handleEventContent(event.startStr)}
    />
  )
}
