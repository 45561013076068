import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import { useVinculoLotacaoGetAllPorVinculo } from '~/hooks/queries/useVinculoLotacao'
import { formatAnoMes } from '~/hooks/useUtils'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  vinculoId: string
  onItemClick: (event: 'edit' | 'delete', idRow: string) => void
  query: string
}

export default function Table(props: TableProps) {
  const { vinculoId, onItemClick, query } = props

  const { data: _data, isLoading, isFetching } = useVinculoLotacaoGetAllPorVinculo(vinculoId)
  const data = _data
    ? _data.map((d) => ({
        ...d,
        anoMes: d.anoMes ? formatAnoMes(d.anoMes) : '',
        lotacaoNome: d?.lotacao?.nome || '',
      }))
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'anoMes',
      label: 'Ano/Mês',
    },
    {
      name: 'lotacaoNome',
      label: 'Lotação',
    },
    {
      name: 'dias',
      label: 'Dias',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['anoMes', 'lotacaoNome'],
      }}
    />
  )
}
