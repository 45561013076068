import { useState } from 'react'

import { IconButton, Tooltip } from '@material-ui/core'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { ActionDialog, ButtonBox, ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import useCompetenciaEmpregadorAtualizar from '~/hooks/queries/Competencia/useCompetenciaEmpregadorAtualizar'

import { CompetenciaEmpregadorFechamentoDTO } from '~/hooks/queries/Competencia/CompetenciaEmpregadorFechamentoDTO'

interface FormUpdateProps {
  isOpen: boolean
  onClose: () => void
  data: CompetenciaEmpregadorFechamentoDTO
}

export default function FormUpdate({ isOpen, onClose, data: _data }: FormUpdateProps) {
  const [dataForm, setDataForm] = useState<CompetenciaEmpregadorFechamentoDTO>(_data)

  const { mutateAsync, isLoading } = useCompetenciaEmpregadorAtualizar()

  async function handleSubmit() {
    await mutateAsync({ id: dataForm.id, body: dataForm })
    onClose()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'isEvtRemuneracao',
      label: '1200',
      options: {
        customBodyRender: (value: any) => (
          <ButtonBox spacing={0} justifyContent="center">
            <Tooltip title="Envio Evento Remuneração">
              <IconButton
                size="small"
                onClick={() => {
                  const isEvtRemuneracao = !value
                  setDataForm((prev) => ({ ...prev, isEvtRemuneracao }))
                }}
              >
                <Ativo ativo={value} />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        ),
      },
    },
    {
      name: 'isEvtPagamento',
      label: '1210',
      options: {
        customBodyRender: (value: any) => (
          <ButtonBox spacing={0} justifyContent="center">
            <Tooltip title="Envio Evento Pagamentos">
              <IconButton
                size="small"
                onClick={() => {
                  const isEvtPagamento = !value
                  setDataForm((prev) => ({ ...prev, isEvtPagamento }))
                }}
              >
                <Ativo ativo={value} />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        ),
      },
    },
    {
      name: 'isEvtComercializacao',
      label: '1260',
      options: {
        customBodyRender: (value: any) => (
          <ButtonBox spacing={0} justifyContent="center">
            <Tooltip title="Envio Evento Comercialização">
              <IconButton
                size="small"
                onClick={() => {
                  const isEvtComercializacao = !value
                  setDataForm((prev) => ({ ...prev, isEvtComercializacao }))
                }}
              >
                <Ativo ativo={value} />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        ),
      },
    },
    {
      name: 'isEvtContratacaoAvulso',
      label: '1270',
      options: {
        customBodyRender: (value: any) => (
          <ButtonBox spacing={0} justifyContent="center">
            <Tooltip title="Envio Evento Contratação Trab. Avulso">
              <IconButton
                size="small"
                onClick={() => {
                  const isEvtContratacaoAvulso = !value
                  setDataForm((prev) => ({ ...prev, isEvtContratacaoAvulso }))
                }}
              >
                <Ativo ativo={value} />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        ),
      },
    },
    {
      name: 'isEvtInfoComplementar',
      label: '1280',
      options: {
        customBodyRender: (value: any) => (
          <ButtonBox spacing={0} justifyContent="center">
            <Tooltip title="Envio Evento Info. Complementar">
              <IconButton
                size="small"
                onClick={() => {
                  const isEvtInfoComplementar = !value
                  setDataForm((prev) => ({ ...prev, isEvtInfoComplementar }))
                }}
              >
                <Ativo ativo={value} />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        ),
      },
    },
  ]

  const data = [dataForm]

  return (
    <ActionDialog
      title="Atualizar"
      subtitle={dataForm.empregadorNome + ' - ' + formatToCPFOrCNPJ(dataForm.empregadorNrInscricao)}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={() => handleSubmit()}
    >
      <ToolsDataTable columns={columns} data={data} disableAutoHeight pagination={false} />
    </ActionDialog>
  )
}
