import { useEffect, useState } from 'react'

import {
  TableRow,
  TableCell as MUITableCell,
  TableCellProps,
  CircularProgress,
  Badge,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { Error } from '@material-ui/icons'

import { MaskedTextField as MaskedTextFieldOriginal } from '~/components'

import { useAtualizarFolhaPontoDia } from '~/hooks/queries/FolhaPonto/useAtualizarFolhaPontoDia'

import { formatDateToDayAndDayOfWeek } from '~/utils/utils'

import { FolhaPontoDia } from '~/hooks/queries/FolhaPonto/dtos/FolhaPontoDia'
import { IdentificacaoDiaEnum } from '~/@types/enums/IdentificacaoDiaEnum'
import { extract } from '~/hooks/useDialogNotification'

function MaskedTextField(props: any) {
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <MaskedTextFieldOriginal
      {...props}
      mask="99:99"
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      onBlur={() => {
        if (value === props.value) return
        if (value.length > 0 && !/^\d{2}:\d{2}$/.test(value)) {
          setValue(props.value)
          return
        }
        props.onBlur(value)
      }}
      inputProps={{ style: { textAlign: 'center' } }}
    />
  )
}

export function converterParaMinutos(horario: string) {
  if (!horario) return 0
  const [horas, minutos] = horario.split(':').map(Number)
  return horas * 60 + minutos
}

export function converterTimeSpan(minutosConvert: number) {
  if (minutosConvert > 0) {
    const horas = Math.floor(minutosConvert / 60)
    const minutos = minutosConvert % 60
    return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}`
  } else {
    return '00:00'
  }
}

function calcularDiferencaComDuracao(
  hrEntrada: string,
  hrSaida: string,
  duracaoTurno: number,
  tempoTolerancia: number,
): string {
  const minutosEntrada = converterParaMinutos(hrEntrada)
  const minutosSaida = converterParaMinutos(hrSaida)

  const diferencaMinutos = minutosSaida - minutosEntrada
  let minutosExtra = diferencaMinutos > duracaoTurno ? diferencaMinutos - duracaoTurno : 0

  minutosExtra = minutosExtra > tempoTolerancia ? minutosExtra : 0

  return converterTimeSpan(minutosExtra)
}

function calcularDiferenca(hrEntrada: string, hrSaida: string): string {
  const minutosEntrada = converterParaMinutos(hrEntrada)
  const minutosSaida = converterParaMinutos(hrSaida)
  const diferencaMinutos = minutosSaida - minutosEntrada
  return converterTimeSpan(diferencaMinutos)
}

function handleChangeHorasExtras(
  turno1HoraExtra: string,
  turno2HoraExtra: string,
  horaExtraNormal: string,
): string {
  const turno1MinutosExtra = converterParaMinutos(turno1HoraExtra)
  const turno2MinutosExtra = converterParaMinutos(turno2HoraExtra)
  const extraNormal = converterParaMinutos(horaExtraNormal)

  const minutosExtrasSomados = turno1MinutosExtra + turno2MinutosExtra + extraNormal

  return converterTimeSpan(minutosExtrasSomados)
}

function TableCellBody(props: TableCellProps) {
  return <MUITableCell {...props} align="center" />
}

interface RowTableProps {
  data: FolhaPontoDia
  tolerancia: string
}

export default function RowTable({ data: _data, tolerancia }: RowTableProps) {
  const [data, setData] = useState(_data)

  const disabledRow = data.identificacaoDia !== IdentificacaoDiaEnum.Normal
  const tempoTolerancia = converterParaMinutos(tolerancia)

  const { mutateAsync, isLoading, error } = useAtualizarFolhaPontoDia()

  useEffect(() => {
    setData(_data)
  }, [_data])

  async function handleChange(d: FolhaPontoDia) {
    setData(d)
    await mutateAsync({
      data: d,
      params: {
        id: d.id,
      },
    })
  }

  return (
    <TableRow style={disabledRow ? { pointerEvents: 'none', opacity: '0.6' } : undefined}>
      <TableCellBody>
        <Badge
          badgeContent={
            error ? (
              <Tooltip title={extract(error)?.descriptionsExtracted} arrow>
                <IconButton size="small">
                  <Error fontSize="small" color="error" />
                </IconButton>
              </Tooltip>
            ) : (
              isLoading && <CircularProgress size={15} />
            )
          }
        >
          {formatDateToDayAndDayOfWeek(data.dataDia)}
        </Badge>
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.turno1HoraEntrada || ''}
          onBlur={(e: any) => {
            const turno1HoraEntrada = e
            const turno1HoraExtra = calcularDiferencaComDuracao(
              turno1HoraEntrada,
              data?.turno1HoraSaida || '',
              data?.turno1Duracao || 0,
              tempoTolerancia,
            )
            const horaExtraTotal = handleChangeHorasExtras(
              turno1HoraExtra,
              data?.turno2HoraExtra || '',
              data?.horaExtraNormal || '',
            )
            handleChange({
              ...data,
              turno1HoraEntrada,
              turno1HoraExtra,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.turno1HoraSaida || ''}
          onBlur={(e: any) => {
            const turno1HoraSaida = e
            const turno1HoraExtra = calcularDiferencaComDuracao(
              data?.turno1HoraEntrada || '',
              turno1HoraSaida || '',
              data?.turno1Duracao || 0,
              tempoTolerancia,
            )
            const horaExtraTotal = handleChangeHorasExtras(
              turno1HoraExtra,
              data?.turno2HoraExtra || '',
              data?.horaExtraNormal || '',
            )
            handleChange({
              ...data,
              turno1HoraSaida,
              turno1HoraExtra,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.turno1HoraExtra || ''}
          onBlur={(e: any) => {
            const turno1HoraExtra = e
            const horaExtraTotal = handleChangeHorasExtras(
              turno1HoraExtra,
              data?.turno2HoraExtra || '',
              data?.horaExtraNormal || '',
            )
            handleChange({
              ...data,
              turno1HoraExtra,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.turno2HoraEntrada || ''}
          onBlur={(e: any) => {
            const turno2HoraEntrada = e
            const turno2HoraExtra = calcularDiferencaComDuracao(
              turno2HoraEntrada,
              data?.turno2HoraSaida || '',
              data?.turno2Duracao || 0,
              tempoTolerancia,
            )
            const horaExtraTotal = handleChangeHorasExtras(
              data?.turno1HoraExtra || '',
              turno2HoraExtra,
              data?.horaExtraNormal || '',
            )
            handleChange({
              ...data,
              turno2HoraEntrada,
              turno2HoraExtra,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.turno2HoraSaida || ''}
          onBlur={(e: any) => {
            const turno2HoraSaida = e
            const turno2HoraExtra = calcularDiferencaComDuracao(
              data?.turno2HoraEntrada || '',
              turno2HoraSaida || '',
              data?.turno2Duracao || 0,
              tempoTolerancia,
            )
            const horaExtraTotal = handleChangeHorasExtras(
              data?.turno1HoraExtra || '',
              turno2HoraExtra,
              data?.horaExtraNormal || '',
            )
            handleChange({
              ...data,
              turno2HoraSaida,
              turno2HoraExtra,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.turno2HoraExtra || ''}
          onBlur={(e: any) => {
            const turno2HoraExtra = e
            const horaExtraTotal = handleChangeHorasExtras(
              data?.turno1HoraExtra || '',
              turno2HoraExtra,
              data?.horaExtraNormal || '',
            )
            handleChange({
              ...data,
              turno2HoraExtra,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.horaExtraEntrada || ''}
          onBlur={(e: any) => {
            const horaExtraEntrada = e
            const horaExtraNormal = calcularDiferenca(horaExtraEntrada, data?.horaExtraSaida || '')
            const horaExtraTotal = handleChangeHorasExtras(
              data?.turno1HoraExtra || '',
              data?.turno2HoraExtra || '',
              horaExtraNormal || '',
            )
            handleChange({
              ...data,
              horaExtraEntrada,
              horaExtraNormal,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.horaExtraSaida || ''}
          onBlur={(e: any) => {
            const horaExtraSaida = e
            const horaExtraNormal = calcularDiferenca(data?.horaExtraEntrada || '', horaExtraSaida)
            const horaExtraTotal = handleChangeHorasExtras(
              data?.turno1HoraExtra || '',
              data?.turno2HoraExtra || '',
              horaExtraNormal || '',
            )
            handleChange({
              ...data,
              horaExtraSaida,
              horaExtraNormal,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.horaExtraNormal || ''}
          onBlur={(e: any) => {
            const horaExtraNormal = e
            const horaExtraTotal = handleChangeHorasExtras(
              data?.turno1HoraExtra || '',
              data?.turno2HoraExtra || '',
              horaExtraNormal || '',
            )
            handleChange({
              ...data,
              horaExtraNormal,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.horaNoturnaEntrada || ''}
          onBlur={(e: any) => {
            const horaNoturnaEntrada = e
            const horaNoturnaNormal = calcularDiferenca(
              horaNoturnaEntrada,
              data?.horaNoturnaSaida || '',
            )
            handleChange({
              ...data,
              horaNoturnaEntrada,
              horaNoturnaNormal,
              horaNoturnaTotal: horaNoturnaNormal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.horaNoturnaSaida || ''}
          onBlur={(e: any) => {
            const horaNoturnaSaida = e
            const horaNoturnaNormal = calcularDiferenca(
              data?.horaNoturnaEntrada || '',
              horaNoturnaSaida,
            )
            handleChange({
              ...data,
              horaNoturnaSaida,
              horaNoturnaNormal,
              horaNoturnaTotal: horaNoturnaNormal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.horaNoturnaNormal || ''}
          onBlur={(e: any) => {
            const horaNoturnaNormal = e
            handleChange({
              ...data,
              horaNoturnaNormal,
              horaNoturnaTotal: horaNoturnaNormal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.horaExtraTotal || ''}
          onBlur={(e: any) => {
            const horaExtraTotal = e
            handleChange({
              ...data,
              horaExtraTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
      <TableCellBody>
        <MaskedTextField
          value={data.horaNoturnaTotal || ''}
          onBlur={(e: any) => {
            const horaNoturnaTotal = e
            handleChange({
              ...data,
              horaNoturnaTotal,
            })
          }}
          disabled={disabledRow}
        />
      </TableCellBody>
    </TableRow>
  )
}
