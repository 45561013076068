import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { CEPTextField, NumeroInscricaoTextField } from '~/components'
import { AutoCompleteCidade } from '~/components/AutoComplete'

import api from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import * as yup from 'yup'

import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import { cnpj } from 'cpf-cnpj-validator'

const { CNPJ_1 } = tipoInscricaoConsts

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  nrInscricao: yup
    .string()
    .required('Informe o CNPJ')
    .test('cnpj-validate', 'Informe um CNPJ válido', (value) => cnpj.isValid(value)),
  endereco: yup.string().required('Informe o Endereço'),
  numero: yup.string().required('Informe o Número'),
  bairro: yup.string().required('Informe o Bairro'),
  cep: yup.string().required('Informe o CEP'),
  cidade: yup.string().required('Informe a Cidade').nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/agenteIntegracao/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/agenteIntegracao', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Agente Integração"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data?.nome || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="nome"
            autoFocus
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <NumeroInscricaoTextField
            label="CNPJ"
            typeMask={CNPJ_1}
            fullWidth
            required
            validationErrors={validationErrors}
            name="nrInscricao"
            value={data?.nrInscricao || ''}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const nrInscricao = value
              setData({ ...data, nrInscricao })
            }}
            getDataCNPJ={
              !data?.id
                ? (response) => setData((oldState) => ({ ...oldState, ...response }))
                : undefined
            }
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CEPTextField
            label="CEP"
            fullWidth
            value={data?.cep || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="cep"
            getAddress={
              data?.id
                ? undefined
                : (address) => {
                    setData((oldState) => ({
                      ...oldState,
                      ...address,
                    }))
                  }
            }
            onChange={(e) => {
              const cep = e?.target?.value?.replace(/\D/g, '') || ''
              setData((oldData) => ({ ...oldData, cep }))
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            label="Número"
            fullWidth
            value={data?.numero || ''}
            variant="outlined"
            size="small"
            onlyNumber
            required
            inputProps={{
              maxLength: 10,
            }}
            validationErrors={validationErrors}
            name="numero"
            onChange={(e) => {
              const numero = e.target.value
              setData({ ...data, numero })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Bairro"
            fullWidth
            value={data?.bairro || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="bairro"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const bairro = e.target.value
              setData({ ...data, bairro })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Endereço"
            fullWidth
            value={data?.endereco || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="endereco"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const endereco = e.target.value
              setData({ ...data, endereco })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteCidade
            required
            validationErrors={validationErrors}
            name="cidade"
            value={data?.cidade || null}
            onChange={(e, cidade) => {
              const cidadeId = cidade?.id || ''
              setData({ ...data, cidadeId, cidade })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
