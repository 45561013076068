import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, AnoMesTextField, DatePickerNew, Stack, TextField } from '~/components'
import { AutoCompleteProcessoTrabalhista } from '~/components/AutoComplete'

import Content from './Content'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCriar } from '~/hooks/queries/ProcessoTrabalhistaTributos/useCriar'
import { useAtualizar } from '~/hooks/queries/ProcessoTrabalhistaTributos/useAtualizar'

import { ProcessoTrabalhistaTributos } from '~/hooks/queries/ProcessoTrabalhistaTributos/dtos/ProcessoTrabalhistaTributos'

const schema = yup.object().shape({
  processoTrabalhistaId: yup.string().required('Informe o Processo Trabalhista'),
  periodoApuracao: yup.string().required('Informe o Período de Apuração'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: ProcessoTrabalhistaTributos | null
  processoTrabalhistaId: string
}

export default function Form({ isOpen, onClose, data: _data, processoTrabalhistaId }: FormProps) {
  const [data, setData] = useState<ProcessoTrabalhistaTributos>(
    _data || ({ processoTrabalhistaId } as ProcessoTrabalhistaTributos),
  )

  const isNovaData = data?.id ? false : true

  const { mutateAsync: mutateAsyncCriar, isLoading: isLoadingCriar } = useCriar()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: isLoadingAtualizar } = useAtualizar()

  const isLoading = isLoadingCriar || isLoadingAtualizar

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    if (isNovaData) {
      const dtResponse = await mutateAsyncCriar(data)
      setData(dtResponse)
    } else {
      await mutateAsyncAtualizar({
        id: data.id,
        data,
      })
      onClose()
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Processo Trabalhista Tributos"
      isOpen={isOpen}
      onClose={onClose}
      fullScreen={!isNovaData}
      okLabel="Salvar"
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
    >
      <Stack height="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <AutoCompleteProcessoTrabalhista
              required
              name="processoTrabalhistaId"
              validationErrors={validationErrors}
              value={data.processoTrabalhistaId}
              onChange={(obj) => {
                const processoTrabalhistaId = obj?.id || ''
                setData((oldState) => ({
                  ...oldState,
                  processoTrabalhistaId,
                }))
              }}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <AnoMesTextField
              required
              name="periodoApuracao"
              validationErrors={validationErrors}
              label="Período de Apuração"
              InputLabelProps={{
                shrink: true,
              }}
              value={data.periodoApuracao}
              onChange={(e) => {
                const periodoApuracao = e.target.value.replace('/', '')
                setData((prev) => ({ ...prev, periodoApuracao }))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Observação"
              value={data.observacao}
              onChange={(e) => {
                const observacao = e.target.value || ''
                setData((oldState) => ({
                  ...oldState,
                  observacao,
                }))
              }}
            />
          </Grid>

          {!isNovaData && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField label="Recibo eSocial" value={data.eSocialRecibo} disabled />
              </Grid>

              <Grid item xs={12} sm={6}>
                <DatePickerNew label="Data Recibo eSocial" value={data.dtESocialRecibo} disabled />
              </Grid>
            </>
          )}
        </Grid>
        {!isNovaData && <Content processoTrabalhistaTributos={data} />}
      </Stack>
    </ActionDialog>
  )
}
