import React from 'react'

import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core'

import XMLViewer from 'react-xml-viewer'
import ReactJson from 'react-json-view'

import {
  ErrosSchemaContent,
  OcorrenciasContent,
} from '~/pages/Home/components/DialogDetails/components/TableEventsExpandeds/components/TableDetailsExpanded/components/TableCellContent'

import { ActionDialog } from '~/components'

import useDialog from '~/hooks/useDialog'

function LabelValue(props) {
  const { label, value } = props

  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
      <Typography variant="body1">{label}</Typography>:
      <Typography variant="body2">{value}</Typography>
    </Box>
  )
}

export default function AuditoriaXMLForm(props) {
  const { data } = props

  const theme = useTheme()
  const {
    close: closeFileView,
    data: dataFileView,
    isOpen: isOpenFileView,
    open: openFileView,
  } = useDialog({ title: 'Visualização', data: null })

  function changeContent(type, data) {
    let content = null
    switch (type) {
      case 'errosSchema':
        content = <ErrosSchemaContent value={data} />
        break
      case 'ocorrencias':
        content = <OcorrenciasContent value={data} />
        break
      case 'json':
        content = <ReactJson src={data} />
        break
      case 'xml':
        content = <XMLViewer xml={data} />
        break
      default:
        content = <>{data}</>
        break
    }
    return content
  }

  return (
    <Grid container spacing={2} style={{ paddingBottom: theme.spacing(2) }}>
      <Grid item xs={12}>
        <LabelValue label="id" value={data?.id} />
      </Grid>
      <Grid item xs={12}>
        <LabelValue label="auditoriaLoteId" value={data?.auditoriaLoteId} />
      </Grid>
      <Grid item xs={12}>
        <LabelValue label="retorno_Codigo" value={data?.retorno_Codigo} />
      </Grid>
      <Grid item xs={12}>
        <LabelValue label="retorno_Descricao" value={data?.retorno_Descricao} />
      </Grid>
      <Grid item xs={12}>
        <LabelValue label="retorno_DataHora" value={data?.retorno_DataHora} />
      </Grid>

      <Grid
        item
        xs={4}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          size="small"
          color="primary"
          variant="contained"
          fullWidth
          disabled={!data?.errosSchema ? true : false}
          onClick={() =>
            openFileView({ title: 'Erros Schema', data: data?.errosSchema, type: 'errosSchema' })
          }
        >
          Erros Schema
        </Button>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          size="small"
          color="primary"
          variant="contained"
          fullWidth
          disabled={!data?.ocorrencias ? true : false}
          onClick={() =>
            openFileView({ title: 'Ocorrências', data: data?.ocorrencias, type: 'ocorrencias' })
          }
        >
          Ocorrências
        </Button>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          size="small"
          color="primary"
          variant="contained"
          fullWidth
          disabled={!data?.retornoObj ? true : false}
          onClick={() =>
            openFileView({ title: 'Retorno Objeto', data: data?.retornoObj, type: 'json' })
          }
        >
          Retorno Objeto
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          size="small"
          color="primary"
          variant="contained"
          fullWidth
          disabled={!data?.xmlEnvio ? true : false}
          onClick={() => openFileView({ title: 'XML Envio', data: data?.xmlEnvio, type: 'xml' })}
        >
          XML Envio
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          size="small"
          color="primary"
          variant="contained"
          fullWidth
          disabled={!data?.retorno_Xml ? true : false}
          onClick={() =>
            openFileView({ title: 'XML Retorno', data: data?.retorno_Xml, type: 'xml' })
          }
        >
          XML Retorno
        </Button>
      </Grid>

      <ActionDialog
        title={dataFileView.title}
        isOpen={isOpenFileView}
        onClose={closeFileView}
        customActions={<></>}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md',
        }}
      >
        {dataFileView?.data && changeContent(dataFileView?.type, dataFileView?.data)}
      </ActionDialog>
    </Grid>
  )
}
