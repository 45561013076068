import { useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew } from '~/components'

import { useAtualizarVinculoTrabalhoFeriado } from '~/hooks/queries/VinculoTrabalhoFeriado/useAtualizarVinculoTrabalhoFeriado'

import { VinculoTrabalhoFeriado } from '~/hooks/queries/VinculoTrabalhoFeriado/dtos/VinculoTrabalhoFeriado'

interface FormProps {
  isOpen: boolean
  onClose: () => void
  initialData: VinculoTrabalhoFeriado
}

export default function Form({ isOpen, onClose, initialData }: FormProps) {
  const [data, setData] = useState(initialData)

  const { mutateAsync: mutateAsyncUpdate, isLoading } = useAtualizarVinculoTrabalhoFeriado()

  async function handleSubmit() {
    await mutateAsyncUpdate({
      id: data.id,
      data: data,
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Feriado do Funcionário"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew label="Data do Feriado" value={data.dtFeriado || null} disabled />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew label="Data Limite da Folga" value={data.dtLimiteFolga || null} disabled />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Data da Concessão da Folga"
            value={data.dtConcessaoFolga || null}
            onChange={(dt) => {
              const dtConcessaoFolga = dt
              setData((prev) => ({
                ...prev,
                dtConcessaoFolga,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
