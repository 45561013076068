import { Box, Divider, Tooltip, Typography, makeStyles } from '@material-ui/core'

import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  rootItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.action.hover,
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  primaryText: {
    fontWeight: 'bolder',
    fontSize: '9pt',
    color: '#666',
  },
  alert: {
    backgroundColor: theme.palette.error.light,
    animation: '$pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.error.main,
    },
  },
  alertTextColor: {
    color: theme.palette.error.contrastText,
  },
  '@keyframes pulse': {
    '0%, 100%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.6,
    },
  },
}))

interface ChartFooterProps {
  data: { status: number; total: number }[]
  labels: any
  colors: any
  onClick: (status: number) => void
  onAlert?: (status: number) => boolean
  direction?: 'row' | 'column'
  divider?: boolean
}

export default function ChartFooter({
  data,
  labels,
  colors,
  onClick,
  onAlert,
  direction = 'row',
  divider = true,
}: ChartFooterProps) {
  const classes = useStyles()

  return (
    <Box>
      {divider && <Divider />}
      <Box display="flex" flexDirection={direction} justifyContent="space-between" overflow="auto">
        {data.map((d) => {
          const hasError = onAlert && onAlert(d.status)
          return (
            <Box key={d.status} width={105}>
              <Tooltip title={labels[d.status]} arrow>
                <Box
                  className={clsx(classes.rootItem, {
                    [classes.alert]: hasError,
                  })}
                  onClick={() => onClick(d.status)}
                >
                  <Typography
                    className={clsx(classes.primaryText, {
                      [classes.alertTextColor]: hasError,
                    })}
                  >
                    {labels[d.status]}
                  </Typography>
                  <Box
                    className={clsx({
                      [classes.alertTextColor]: hasError,
                    })}
                    component={Typography}
                    color={colors[d.status]}
                    fontWeight="bolder"
                    fontSize="10pt"
                  >
                    {d.total}
                  </Box>
                </Box>
              </Tooltip>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
