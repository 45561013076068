const inclusao = 0
const alteracao = 1
const exclusao = 2
const novaValidade = 3
const retificacao = 4
const zerarBaseTeste = 5

export const tipoMovimentoESocialValues = [
  {
    label: 'Inclusão',
    value: inclusao,
  },
  {
    label: 'Alteração',
    value: alteracao,
  },
  {
    label: 'Exclusão',
    value: exclusao,
  },
  {
    label: 'Nova Validade',
    value: novaValidade,
  },
  {
    label: 'Retificação',
    value: retificacao,
  },
  {
    label: 'Zerar Base Teste',
    value: zerarBaseTeste,
  },
]
