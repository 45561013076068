import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { SalarioBaseRequestDTO } from './dtos/SalarioBaseRequestDTO'

export function useObterValorSalarioBase() {
  const dialogNotification = useDialogNotification()

  async function handleRequest(data: SalarioBaseRequestDTO) {
    const response = await api.post<{ data: number }>(
      '/Relatorio/SalarioContratual/ObterValorSalarioBase',
      data,
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
