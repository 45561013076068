const empresa = 1
const funcionario = 2

export const sindicatoCategoriaValues = [
  { value: empresa, name: '1 - Empresa' },
  { value: funcionario, name: '2 - Funcionário' },
]

export const sindicatoCategoriaConsts = {
  empresa,
  funcionario,
}
