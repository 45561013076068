// import { NotificationManager } from 'react-notifications'
import { toast } from 'react-toastify'

const options = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 4000,
}

const useNotification = () => {
  const success = (message, time = 4000, position) => {
    // NotificationManager.success(message, title, time)
    toast.success(message, { ...options, autoClose: time, position })
  }

  const error = (message, time = 4000) => {
    // NotificationManager.error(message, title, time)
    toast.error(message, { ...options, autoClose: time })
  }

  const warning = (message, time = 4000) => {
    // NotificationManager.warning(message, title, time)
    toast.warning(message, { ...options, autoClose: time })
  }

  const post = () => {
    toast.success('Registro inserido com sucesso.', options)
  }

  const put = (time = 4000, position) => {
    toast.success('Registro atualizado com sucesso.', {
      ...options,
      autoClose: time,
      position,
    })
  }

  const remove = (time = 4000, position) => {
    toast.success('Registro removido com sucesso.', {
      ...options,
      position,
      autoClose: time,
    })
  }

  const failed = () => {
    toast.error('Ocorreu um erro ao tentar processar a requisição.', options)
  }

  return { success, error, warning, post, put, remove, failed }
}

export default useNotification
