import { useState, useMemo } from 'react'

import {
  ActionDialog,
  ButtonBox,
  ButtonIcon,
  ContainerTable,
  CopyLabel,
  StackContainer,
  ToolsDataTable,
} from '~/components'

import AutoCompleteCNABRemessas from '~/components/AutoComplete/AutoCompleteCNABRemessas'

import useObterRemessasProcessadas from '~/hooks/queries/CNAB/useObterRemessasProcessadas'
import useGerarPlanilhaRemessas from '~/hooks/queries/CNAB/useGerarPlanilhaRemessas'
import useAmbiente from '~/hooks/useAmbiente'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { formatCurrency } from '~/utils/utils'
import { formatToCPF } from 'brazilian-values'
import { FaFileExcel } from 'react-icons/fa'

interface TableRemessasProps {
  isOpen: boolean
  onClose: () => void
}

export default function TableRemessas({ isOpen, onClose }: TableRemessasProps) {
  const { estabelecimento } = useAmbiente()

  const [data, setData] = useState<{
    reciboIDF: ReciboIDFEnum | null
    remessaAtual: string | null
  }>({
    reciboIDF: null,
    remessaAtual: null,
  })

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterRemessasProcessadas({
    params: {
      nrRemessa: data.remessaAtual || undefined,
    },
  })
  const collection = _data || []

  const { mutateAsync: mutateAsyncPlanilha, isLoading: isLoadingPlanilha } =
    useGerarPlanilhaRemessas(
      `CNAB-REMESSA/${data.remessaAtual}/${estabelecimento.nrInscricao}.xlsx`,
    )

  async function handleDownloadExcel() {
    await mutateAsyncPlanilha({
      params: {
        nrRemessa: data.remessaAtual || '',
      },
    })
  }

  const columns = useMemo<MUIDataTableColumnDef[]>(
    () => [
      {
        name: 'reciboIDFDescricao',
        label: 'Recibo',
      },
      {
        name: 'funcionarioCPF',
        label: 'CPF',
        options: {
          customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
        },
      },
      {
        name: 'funcionarioNome',
        label: 'Nome',
      },
      {
        name: 'agencia',
        label: 'Agência',
      },
      {
        name: 'contaCorrente',
        label: 'Conta Corrente',
      },
      {
        name: 'dv',
        label: 'DV',
      },
      {
        name: 'valorLiquido',
        label: 'Valor Líquido',
        options: {
          setCellProps: () => ({ style: { textAlign: 'end' } }),
          customBodyRender: (value) => formatCurrency(value),
        },
      },
    ],
    [],
  )

  return (
    <ActionDialog
      title="Remessas"
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <ButtonIcon
            icon={<FaFileExcel />}
            variant="contained"
            fullWidth
            onClick={handleDownloadExcel}
            isLoading={isLoadingPlanilha}
            disabled={collection.length > 0 ? false : true}
          >
            Planilha
          </ButtonIcon>
        </ButtonBox>
      }
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <StackContainer p={0}>
        <AutoCompleteCNABRemessas
          value={data.remessaAtual}
          onChange={(obj) => {
            const remessaAtual = obj?.nrRemessa || null
            setData((prev) => ({
              ...prev,
              remessaAtual,
            }))
          }}
        />

        <ContainerTable>
          <ToolsDataTable
            data={collection}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching}
            disableAutoHeight
          />
        </ContainerTable>
      </StackContainer>
    </ActionDialog>
  )
}
