import { useState, useEffect } from 'react'

import moment from 'moment'
import _ from 'lodash'

import { ContentContainer, PageHeader, ButtonBox } from 'mio-library-ui'
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
} from '@material-ui/core'
import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import {
  ActionMenu,
  AnoSelect,
  Button,
  CollapseContent,
  ConfirmDeleteDialog,
  ContentFrame,
} from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import useOnlyDataC from '~/hooks/useOnlyDataC'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useUtils from '~/hooks/useUtils'

import { useObterTodosSalarioMinimo } from '~/hooks/queries/SalarioMinimo/useObterTodosSalarioMinimo'
import { useRemoverSalarioMinimo } from '~/hooks/queries/SalarioMinimo/useRemoverSalarioMinimo'

const TabelaSalarioMinimo = () => {
  const [displayCollection, setDisplayCollection] = useState([])

  const [collapseItens, setCollapseItens] = useState([])

  const { ano } = useAmbiente(false, true)
  const { formatMonth } = useUtils()
  const { isUserDataC } = useOnlyDataC()

  const [anoCurrent, setAnoCurrent] = useState(ano)

  const { data: _d, isLoading, isFetching } = useObterTodosSalarioMinimo()
  const { mutateAsync: mutateAsyncRemoverSalarioMinimo, isLoading: isLoadingRemoverSalarioMinimo } =
    useRemoverSalarioMinimo()

  const { close: closeForm, data: dataForm, isOpen: isOpenForm, open: openForm } = useDialog(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog(null)

  useEffect(() => {
    setAnoCurrent(ano)
  }, [ano])

  useEffect(() => {
    const _data = _d || []
    const collectionFormated = makeData(_data, anoCurrent)
    setDisplayCollection(collectionFormated)
    setCollapseItens([0])
  }, [_d, anoCurrent])

  const handleCollapseAll = (value) => {
    if (value) {
      const newArray = displayCollection.map((_, index) => index)
      setCollapseItens(newArray)
      return
    }
    setCollapseItens([])
  }

  const makeData = (collectionCurrent = [], anoCurrent = '') => {
    if (!(collectionCurrent.length > 0)) return []
    if (!anoCurrent) return []
    const maiorAno = moment(
      _.maxBy(collectionCurrent, (o) => moment(o.dtSalario)).dtSalario,
    ).format('YYYY')
    const menorAno = moment(
      _.minBy(collectionCurrent, (o) => moment(o.dtSalario)).dtSalario,
    ).format('YYYY')

    //Montando a lista com todos os anos e seus meses
    const newList = []

    for (let index = maiorAno; index >= menorAno; index--) {
      let listMonths = []
      collectionCurrent.forEach((obj) => {
        if (moment(obj?.dtSalario).format('YYYY') === index.toString()) {
          listMonths.push(obj)
        }
      })
      const listMonthsOrdained = _.orderBy(listMonths, 'dtSalario', 'desc')
      newList.push({ ano: index.toString(), listMonthsOrdained })
    }

    //Monta a lista caso n tenha atingido os limites maior ou menor

    let listMounted = []

    if (anoCurrent >= menorAno && anoCurrent <= maiorAno) {
      listMounted = []
      newList.forEach((obj) => {
        if (obj?.ano <= anoCurrent && obj?.ano > anoCurrent - 5) {
          listMounted.push(obj)
        }
      })
    }

    //Monta uma lista caso ultrapasse o maiorAno
    if (anoCurrent > maiorAno) {
      listMounted = []
      newList.forEach((obj) => {
        if (
          moment(obj?.ano).format('YYYY') <= maiorAno &&
          moment(obj?.ano).format('YYYY') > maiorAno - 5
        ) {
          listMounted.push(obj)
        }
      })
    }

    //Monta uma lista caso atinga o Menor ano
    if (anoCurrent - 5 < menorAno) {
      listMounted = []
      newList.forEach((obj) => {
        if (
          moment(obj?.ano).format('YYYY') >= menorAno &&
          moment(obj?.ano).format('YYYY') < parseInt(menorAno) + parseInt(5)
        ) {
          listMounted.push(obj)
        }
      })
    }

    return listMounted
  }

  async function handleConfirmDelete(id) {
    await mutateAsyncRemoverSalarioMinimo(id)
    closeConfirmDelete()
  }

  if (isLoading) return <CircularProgress color="secondary" size={22} />

  if (displayCollection?.length <= 0) return <div>Nenhum registro encontrado</div>

  return (
    <ContentContainer>
      <PageHeader title="Tabela Salário Mínimo">
        <ButtonBox top={1} bottom={1}>
          <Tooltip title="Expandir todos">
            <IconButton size="small" onClick={() => handleCollapseAll(true)}>
              <CollapseOpenIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Retrair todos">
            <IconButton size="small" onClick={() => handleCollapseAll(false)}>
              <CollapseCloseIcon />
            </IconButton>
          </Tooltip>
        </ButtonBox>
      </PageHeader>

      {isFetching ? <LinearProgress /> : <Box height={4} />}

      <Paper style={{ padding: '16px' }}>
        <Box display="flex" justifyContent="space-between">
          <CollapseContent title="Pesquisar por Ano" top={1} bottom={1} showContentFrame={false}>
            <AnoSelect
              onChange={(e) => {
                const ano = e.target.value
                setAnoCurrent(ano)
              }}
              value={anoCurrent || ''}
            />
          </CollapseContent>

          {isUserDataC && (
            <Box>
              <Button variant="contained" onClick={() => openForm(null)}>
                Adicionar
              </Button>
            </Box>
          )}
        </Box>

        <ContentFrame top={1}>
          {displayCollection.map((objAno, index) => {
            return (
              <CollapseContent
                key={objAno?.ano}
                title={moment(objAno?.ano).format('YYYY')}
                isOpen={collapseItens.includes(index)}
                onClick={() =>
                  setCollapseItens((prevState) => {
                    const listFiltred = prevState.filter((indexCurrent) => indexCurrent !== index)
                    if (listFiltred.length === prevState.length) return [...prevState, index]
                    return listFiltred
                  })
                }
                top={1}
              >
                {objAno?.listMonthsOrdained?.map((obj) => {
                  return (
                    <CollapseContent
                      key={obj?.id}
                      title={formatMonth(obj?.dtSalario?.substr(5, 2))}
                      isOpen={
                        objAno?.listMonthsOrdained[0] === obj && displayCollection[0] === objAno
                          ? true
                          : false
                      }
                      top={1}
                      renderRight={
                        isUserDataC ? (
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            width="100%"
                            paddingBottom={1}
                          >
                            <ActionMenu
                              options={[
                                {
                                  icon: 'edit',
                                  onClick: () => openForm(obj),
                                  label: 'Editar',
                                },
                                {
                                  icon: 'close',
                                  onClick: () => openConfirmDelete(obj?.id),
                                  label: 'Remover',
                                  severity: 'danger',
                                },
                              ]}
                            />
                          </Box>
                        ) : undefined
                      }
                    >
                      <Table data={[obj]} />
                    </CollapseContent>
                  )
                })}
              </CollapseContent>
            )
          })}
        </ContentFrame>
      </Paper>
      {isOpenForm && <Form isOpen={isOpenForm} data={dataForm} onClose={closeForm} />}
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={() => handleConfirmDelete(dataConfirmDelete)}
          isDeleting={isLoadingRemoverSalarioMinimo}
        />
      )}
    </ContentContainer>
  )
}

export default TabelaSalarioMinimo
