import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoConsulta } from '../VinculoConsulta/VinculoConsulta'

const query = '/VinculoRescisaoSimulada/ObterFuncionarios'

interface RequestProps {
  estabelecimentoId: string
  anoMes: string
}

export function useObterFuncionarios(props: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoConsulta[] }>(query, {
      params: props,
    })
    return response.data.data
  }

  return useQuery([query, props], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
