import { useState } from 'react'

import * as yup from 'yup'
import { cpf } from 'cpf-cnpj-validator'

import { Grid } from '@material-ui/core'

import {
  NumeroInscricaoTextField,
  CurrencyTextField,
  TextField,
  ButtonBox,
  Button,
} from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'
import FgtsTransporter from '~/components/FgtsTransporter'
import { useStepperContext } from '~/components/StepperForm'

import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import {
  IndPensaoAlimenticiaFGTSValues,
  IndPensaoAlimenticiaFGTSEnum,
} from '~/@types/enums/IndPensaoAlimenticiaFGTSEnum'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import { TermoRescisaoDTOForm } from '~/hooks/queries/Rescisao/TermoRescisaoDTO'
import { IndMotivoDesligamentoRescisoes } from '~/@types/enums/IndMotivoDesligamentoEnum'

const { CPF_2 } = tipoInscricaoConsts

const isPercPensaoOrPercValor = [
  IndPensaoAlimenticiaFGTSEnum.PercentualPensãoAlimentícia_1,
  IndPensaoAlimenticiaFGTSEnum.PercentualEValorPensãoAlimentícia_3,
]

const isValorPensaoOrPercValor = [
  IndPensaoAlimenticiaFGTSEnum.ValorPensãoAlimentícia_2,
  IndPensaoAlimenticiaFGTSEnum.PercentualEValorPensãoAlimentícia_3,
]

const schema = yup.object().shape({
  prepostoCPF: yup
    .string()
    .test('CPF-Validator', 'Informe um CPF válido', (value: FixLater) => {
      if (value?.length > 0) return cpf.isValid(value)
      return true
    })
    .nullable(),
  rescisaoPercPensaoFGTS: yup
    .number()
    .when(['indPensaoAlimenticiaFGTS'], (indPensaoAlimenticiaFGTS, schema) => {
      if (isPercPensaoOrPercValor.includes(indPensaoAlimenticiaFGTS)) {
        return schema.required('Informe o Percentual Pensão FGTS')
      }
      return schema
    })
    .nullable(),
  rescisaoValorPensaoFGTS: yup
    .number()
    .when(['indPensaoAlimenticiaFGTS'], (indPensaoAlimenticiaFGTS, schema) => {
      if (isValorPensaoOrPercValor.includes(indPensaoAlimenticiaFGTS)) {
        return schema.required('Informe o valor da Pensão FGTS')
      }
      return schema
    })
    .nullable(),
})

export default function ReciboRescisaoImpressao() {
  const { mainData, onBack, onNext, onSubmit } = useStepperContext<TermoRescisaoDTOForm>()

  const [data, setData] = useState(mainData)

  const hasEmprestimo = mainData?.emprestimo ? true : false

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleAlert,
    data,
  })
  const dialogNotification = useDialogNotification()

  function handleAlert() {
    if (
      !data?.codigoSaqueFGTS &&
      !IndMotivoDesligamentoRescisoes.includes(mainData?.motivoDesligamento as FixLater)
    ) {
      dialogNotification.info({
        descriptions: [
          'A rescisão está sendo criada sem o Código de Saque FGTS, deseja prosseguir sem informá-lo?',
        ],
        labelOnConfirm: 'Sim',
        labelOnCancel: 'Não',
        onConfirm: handleSubmit,
      })
    } else {
      handleSubmit()
    }
  }

  function handleSubmit() {
    if (hasEmprestimo) {
      onNext(data)
    } else {
      onSubmit(data)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <NumeroInscricaoTextField
          name="prepostoCPF"
          label="Preposto CPF"
          value={data?.prepostoCPF || ''}
          typeMask={CPF_2}
          onChange={(e, value) => {
            const prepostoCPF = value || ''
            setData((oldState) => ({
              ...oldState,
              prepostoCPF,
            }))
          }}
          validationErrors={validationErrors}
        />
      </Grid>

      <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
        <TextField
          label="Preposto Nome"
          inputProps={{ maxLength: 100 }}
          value={data?.prepostoNome || ''}
          onChange={(e) => {
            const prepostoNome = e.target.value
            setData((oldState) => ({
              ...oldState,
              prepostoNome,
            }))
          }}
        />
      </Grid>

      <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
        <TextField
          label="Número Processo Trabalhista Rescisão Judicial"
          value={data?.nrProcessoTrabalhista || ''}
          onlyNumber
          inputProps={{
            maxLength: 20,
          }}
          onChange={(e) => {
            const nrProcTrabRescisao = e.target.value
            setData((oldState) => ({
              ...oldState,
              nrProcTrabRescisao,
            }))
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <MUIAutoComplete
          name="indPensaoAlimenticiaFGTS"
          label="Pensão Alimenticia FGTS"
          options={IndPensaoAlimenticiaFGTSValues}
          renderOption={(option: FixLater) => `${option.value} - ${option.name}`}
          value={data?.indPensaoAlimenticiaFGTS}
          onChange={(e: string, obj: FixLater) => {
            const indPensaoAlimenticiaFGTS = obj.value
            setData((oldState) => ({ ...oldState, indPensaoAlimenticiaFGTS }))
          }}
          optionId="value"
        />
      </Grid>

      <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
        <CurrencyTextField
          label="Remuneração Mês Anterior"
          variant="outlined"
          size="small"
          fullWidth
          value={data?.vrRemuneracaoMesAnterior || null}
          onChange={(e, value) => {
            const vrRemuneracaoMesAnterior = value || null
            setData((oldState) => ({
              ...oldState,
              vrRemuneracaoMesAnterior,
            }))
          }}
        />
      </Grid>

      <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
        <CurrencyTextField
          name="rescisaoValorPensaoFGTS"
          label="Valor Pensão FGTS"
          variant="outlined"
          size="small"
          fullWidth
          value={data?.rescisaoValorPensaoFGTS || null}
          required={
            data?.indPensaoAlimenticiaFGTS
              ? isValorPensaoOrPercValor.includes(data.indPensaoAlimenticiaFGTS)
              : false
          }
          onChange={(e, value) => {
            const rescisaoValorPensaoFGTS = value || null
            setData((oldState) => ({
              ...oldState,
              rescisaoValorPensaoFGTS,
            }))
          }}
          validationErrors={validationErrors}
        />
      </Grid>

      <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
        <CurrencyTextField
          name="rescisaoPercPensaoFGTS"
          label="Percentual Pensão FGTS"
          variant="outlined"
          size="small"
          fullWidth
          value={data?.rescisaoPercPensaoFGTS || null}
          required={
            data?.indPensaoAlimenticiaFGTS
              ? isPercPensaoOrPercValor.includes(data.indPensaoAlimenticiaFGTS)
              : false
          }
          maximumValue="999"
          onChange={(e, value) => {
            const rescisaoPercPensaoFGTS = value || null
            setData((oldState) => ({
              ...oldState,
              rescisaoPercPensaoFGTS,
            }))
          }}
          validationErrors={validationErrors}
        />
      </Grid>

      <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
        <CurrencyTextField
          label="Percentual Pensão TRCT"
          variant="outlined"
          size="small"
          maximumValue="999"
          fullWidth
          value={data?.rescisaoPercPensaoTRCT || null}
          onChange={(e, value) => {
            const rescisaoPercPensaoTRCT = value || null
            setData((oldState) => ({
              ...oldState,
              rescisaoPercPensaoTRCT,
            }))
          }}
        />
      </Grid>

      <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
        <TextField
          label="CEF"
          inputProps={{
            maxLength: 255,
          }}
          value={data?.chaveCEF || ''}
          onChange={(e) => {
            const chaveCEF = e.target.value
            setData((oldState) => ({
              ...oldState,
              chaveCEF,
            }))
          }}
        />
      </Grid>

      <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
        <FgtsTransporter
          value={data?.codigoSaqueFGTS || ''}
          onChange={(value: FixLater) => {
            const codigoSaqueFGTS = value?.codigo || ''

            setData((oldState) => ({
              ...oldState,
              codigoSaqueFGTS,
            }))
          }}
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ButtonBox>
          <Button onClick={onBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            {hasEmprestimo ? 'Continuar' : 'Finalizar'}
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
