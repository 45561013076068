import { useEffect, useState, memo, useCallback } from 'react'

import { formatToCPF } from 'brazilian-values'
import api from '~/services/api-pessoal'
import * as yup from 'yup'

import { Box, Grid, Paper, makeStyles } from '@material-ui/core'

import { Button, DatePickerNew } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import Table from './Table'
import DialogReciboPagamento from './DialogReciboPagamento'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { calcDiasAvisoPrevio, dateHasBetweenYearMonth } from '~/hooks/useUtils'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import { useObterFuncionarios } from '~/hooks/queries/VinculoRescisaoSimulada/useObterFuncionarios'

import {
  IndMotivoDesligamentoEnum,
  IndMotivoDesligamentoValues,
} from '~/@types/enums/IndMotivoDesligamentoEnum'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

const useStyles = makeStyles((theme) => ({
  actionsAbsolute: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}))

function formatVinculos(
  vinculosConsulta: VinculoConsulta[],
  dtRescisao: string | null,
): SimularRescisaoVinculo[] {
  const vinculos = vinculosConsulta.map((d) => {
    let diasAvisoPrevioIndenizados = 0
    let diasAvisoPrevioTrabalhados = 0

    if (dtRescisao) {
      const diasAvisoPrevio = calcDiasAvisoPrevio(dtRescisao, d.dtAdmissao)
      if (diasAvisoPrevio <= 30) {
        diasAvisoPrevioIndenizados = 0
        diasAvisoPrevioTrabalhados = diasAvisoPrevio
      } else {
        diasAvisoPrevioIndenizados = diasAvisoPrevio - 30
        diasAvisoPrevioTrabalhados = 30
      }
    }

    const newData: SimularRescisaoVinculo = {
      vinculoId: d.id,
      diasAvisoPrevioIndenizados,
      diasAvisoPrevioTrabalhados,
      vrSaldoFGTS: 0,
      vinculoNome: d.pessoaNome,
      vinculoNrInscricao: formatToCPF(d.pessoaCPF),
    }
    return newData
  })
  return vinculos
}

const MemoTable = memo(Table)

const schema = yup.object().shape({
  dtRescisao: yup
    .date()
    .required('Informe a Data de Rescisão')
    .typeError('Informe uma data válida')
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test('dt-in-anoMes', 'Informe uma data dentro da competência atual', (value: string) =>
        dateHasBetweenYearMonth(value, anoMes),
      ),
    )
    .nullable(),
  motivoDesligamento: yup.string().required('Informe o Motivo de Desligamento'),
})

interface SimularRescisaoMain {
  relatorioId: string
  vinculoId: string
  dtRescisao: string
  motivoDesligamento: IndMotivoDesligamentoEnum
  diasAvisoPrevioIndenizados: number
  diasAvisoPrevioTrabalhados: number
  vrSaldoFGTS: number
}

export interface SimularRescisaoVinculo {
  vinculoId: string
  diasAvisoPrevioIndenizados: number
  diasAvisoPrevioTrabalhados: number
  vrSaldoFGTS: number

  vinculoNome: string
  vinculoNrInscricao: string
}

interface SimularRescisaoData {
  dtRescisao: string | null
  motivoDesligamento: IndMotivoDesligamentoEnum
  relatorioId: string
}

interface SimularRescisaoProps {
  onCloseReciboPagamento: () => void
  query: string
}

export default function SimularRescisao(props: SimularRescisaoProps) {
  const { onCloseReciboPagamento, query } = props
  const [data, setData] = useState<SimularRescisaoData>({
    dtRescisao: null,
    motivoDesligamento: IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02,
    relatorioId: '',
  })
  const [vinculos, setVinculos] = useState<SimularRescisaoVinculo[]>([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const { anoMes, estabelecimento } = useAmbiente()
  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    schema,
    handleSubmit,
    schemaOptions: {
      abortEarly: false,
      context: {
        anoMes,
      },
    },
  })
  const { isLoading, data: _vinculos } = useObterFuncionarios({
    anoMes,
    estabelecimentoId: estabelecimento.id,
  })
  const classes = useStyles()

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const {
    close: closeDialogReciboPagamento,
    isOpen: isOpenDialogReciboPagamento,
    open: openDialogReciboPagamento,
  } = useDialog(null)

  const handleReset = useCallback(
    (date: string | null) => {
      if (!_vinculos) return
      const vinculos = formatVinculos(_vinculos, date)
      setVinculos(vinculos)
      setRowsSelected([])
    },
    [_vinculos],
  )

  useEffect(() => {
    handleReset(null)
    setData((prev) => ({
      ...prev,
      dtRescisao: null,
    }))
  }, [handleReset])

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await api.get('/Relatorio/GetByChaveId', {
          params: {
            chaveId: 'RescisaoSimulada',
          },
        })
        if (response?.data?.data?.length > 0) {
          setData((prev) => ({ ...prev, relatorioId: response.data.data[0].id }))
        }
      } catch (err) {
        console.log(err)
      }
    }
    getReports()
  }, [])

  function validateData() {
    rowsSelected.forEach((index) => {
      const vinculoCurrent = vinculos[index]
      const funcionarioNomeCPF =
        vinculoCurrent.vinculoNome + '-' + vinculoCurrent.vinculoNrInscricao
      if (!(vinculoCurrent.vrSaldoFGTS > 0))
        throw new Error('Informe o Valor do Saldo FGTS para o funcionário ' + funcionarioNomeCPF)
      if (
        !(vinculoCurrent.diasAvisoPrevioIndenizados > 0) &&
        !(vinculoCurrent.diasAvisoPrevioTrabalhados > 0)
      ) {
        throw new Error(
          'Deve ser informado os Dias Indenizados e/ou os Dias Trabalhados para o funcionário ' +
            funcionarioNomeCPF,
        )
      }
    })
  }

  async function handleSubmit() {
    setSubmitting(true)
    try {
      validateData()
      const dataToSubmit = rowsSelected.map((index) => {
        const vinculoCurrent = vinculos[index]
        const dataFormated: SimularRescisaoMain = {
          relatorioId: data.relatorioId,
          vinculoId: vinculoCurrent.vinculoId,
          dtRescisao: data.dtRescisao as string,
          motivoDesligamento: data.motivoDesligamento,
          diasAvisoPrevioIndenizados: vinculoCurrent.diasAvisoPrevioIndenizados,
          diasAvisoPrevioTrabalhados: vinculoCurrent.diasAvisoPrevioTrabalhados,
          vrSaldoFGTS: vinculoCurrent.vrSaldoFGTS,
        }
        return dataFormated
      })
      await api.post('/VinculoRescisaoSimulada/ProcessarRescisaoSimulada', dataToSubmit)
      notification.success('Processamento da Rescisão Simulada realizado com sucesso')
      openDialogReciboPagamento(null)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  function handleCancel() {
    handleReset(null)
  }

  function handleCloseDialogReciboPagamento() {
    closeDialogReciboPagamento()
    onCloseReciboPagamento()
  }

  function handleChangeDTRescisao(date: string | null) {
    if (date) {
      handleReset(date)
    }
    setData((prev) => ({ ...prev, dtRescisao: date }))
  }

  return (
    <Box>
      <Box component={Paper} padding={2}>
        <Grid
          container
          spacing={2}
          style={{
            paddingBottom: 8,
            paddingTop: 8,
          }}
        >
          <Grid item md={6} xs={12}>
            <DatePickerNew
              required
              label="Data de Rescisão"
              name="dtRescisao"
              validationErrors={validationErrors}
              value={data.dtRescisao}
              onChange={handleChangeDTRescisao}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <MUIAutoComplete
              label="Motivo de Desligamento"
              required
              name="motivoDesligamento"
              options={IndMotivoDesligamentoValues}
              optionId="value"
              renderOption={(option: FixLater) => option.name}
              value={data.motivoDesligamento}
              onChange={(e: string, obj: FixLater) => {
                const motivoDesligamento = obj ? obj.value : ''
                setData((oldState) => ({
                  ...oldState,
                  motivoDesligamento,
                }))
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box paddingBottom={6}>
        <MemoTable
          value={vinculos}
          onChange={setVinculos}
          rowsSelected={rowsSelected}
          setRowsSelected={setRowsSelected}
          isLoading={isLoading}
          query={query}
        />
      </Box>

      {isOpenDialogReciboPagamento && (
        <DialogReciboPagamento
          isOpen={isOpenDialogReciboPagamento}
          onClose={handleCloseDialogReciboPagamento}
        />
      )}

      <Box className={classes.actionsAbsolute}>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button
          onClick={handleValidate}
          isLoading={isSubmitting}
          disabled={!(rowsSelected.length > 0)}
          variant="contained"
        >
          Processar Simulação
        </Button>
      </Box>
    </Box>
  )
}
