const modelo_01 = 1
const modelo_02 = 2
const modelo_03 = 3
const modelo_04 = 4
const modelo_05 = 5
const modelo_06 = 6
const modelo_07 = 7
const modelo_08 = 8
const modelo_09 = 9
const prorrogacao = 99

export const indModeloContratoExperienciaValues = [
  { value: modelo_01, name: 'Modelo 01' },
  { value: modelo_02, name: 'Modelo 02' },
  { value: modelo_03, name: 'Modelo 03' },
  { value: modelo_04, name: 'Modelo 04' },
  { value: modelo_05, name: 'Modelo 05' },
  { value: modelo_06, name: 'Modelo 06' },
  { value: modelo_07, name: 'Modelo 07' },
  { value: modelo_08, name: 'Modelo 08' },
  { value: modelo_09, name: 'Modelo 09' },
]

export const indModeloContratoExperienciaConsts = {
  modelo_01,
  modelo_02,
  modelo_03,
  modelo_04,
  modelo_05,
  modelo_06,
  modelo_07,
  modelo_08,
  modelo_09,
  prorrogacao,
}
