import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ApurarFolhaESocialFGTSDTO } from './ApurarFolhaESocialFGTSDTO'

const queryObterFGTSBeneficiario = '/ApurarESocial/ObterFGTSBeneficiario'

interface RequestProps {
  empregadorId: string
  periodoApuracao: string
  isSomenteDiferenca: boolean
  isSomenteGRFC: boolean
}

export default function useObterFGTSBeneficiario(props: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ApurarFolhaESocialFGTSDTO[] }>(
      queryObterFGTSBeneficiario,
      {
        params: props,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterFGTSBeneficiario, props], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
