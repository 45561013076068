import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, AnoMesTextField, CurrencyTextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCriar } from '~/hooks/queries/ProcessoTrabalhistaTributosPeriodo/useCriar'
import { useAtualizar } from '~/hooks/queries/ProcessoTrabalhistaTributosPeriodo/useAtualizar'

import { ProcessoTrabalhistaTributosPeriodo } from '~/hooks/queries/ProcessoTrabalhistaTributosPeriodo/dtos/ProcessoTrabalhistaTributosPeriodo'

const schema = yup.object().shape({
  periodoReferencia: yup.string().required('Informe o Período de Referência'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  processoTrabalhistaTributosId: string
  processoTrabalhistaVinculoId: string
  data: ProcessoTrabalhistaTributosPeriodo | null
}

export default function Form({
  isOpen,
  onClose,
  processoTrabalhistaTributosId,
  processoTrabalhistaVinculoId,
  data: _data,
}: FormProps) {
  const [data, setData] = useState<ProcessoTrabalhistaTributosPeriodo>(
    _data ||
      ({
        processoTrabalhistaTributosId,
        processoTrabalhistaVinculoId,
      } as ProcessoTrabalhistaTributosPeriodo),
  )

  const isNovoCadastro = _data?.id ? false : true

  const { mutateAsync: mutateAsyncCriar, isLoading: isLoadingCriar } = useCriar()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: isLoadingAtualizar } = useAtualizar()
  const isLoading = isLoadingCriar || isLoadingAtualizar

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    if (isNovoCadastro) {
      await mutateAsyncCriar(data)
    } else {
      await mutateAsyncAtualizar({
        id: data.id,
        data,
      })
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Processo Trabalhista Tributos Período"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AnoMesTextField
            required
            name="periodoReferencia"
            validationErrors={validationErrors}
            label="Período de Referência"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.periodoReferencia}
            onChange={(e) => {
              const periodoReferencia = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, periodoReferencia }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base Mensal"
            value={data.vrBcCpMensal || ''}
            onChange={(_, value) => {
              const vrBcCpMensal = value
              setData((oldState) => ({
                ...oldState,
                vrBcCpMensal,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base 13° Salário"
            value={data.vrBcCp13 || ''}
            onChange={(_, value) => {
              const vrBcCp13 = value
              setData((oldState) => ({
                ...oldState,
                vrBcCp13,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
