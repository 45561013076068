import React from 'react'

import { DataTable } from 'mio-library-ui'

const Table = (props) => {
  const { data, columns } = props

  const options = {
    viewColumns: false,
    filter: false,
    search: false,
    selectableRows: 'none',
    print: false,
    download: false,
    elevation: 0,
    responsive: 'standard',
  }

  return <DataTable data={data} columns={columns} options={options} pagination={false} />
}

Table.defaultProps = {
  pagination: true,
}

export default Table
