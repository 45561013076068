import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import moment from 'moment'
import { processoIndicativoSuspencaoValues } from '~/values/processoIndicativoSuspencaoValues'

const formatIndSuspensao = (indSuspensao) => {
  const objectFinded = processoIndicativoSuspencaoValues.find((obj) => obj.value === indSuspensao)
  return objectFinded.name
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtDecisaoSuspensao: item?.dtDecisaoSuspensao
        ? moment(item.dtDecisaoSuspensao).format('DD/MM/yyyy')
        : '',
      indSuspensao: formatIndSuspensao(item?.indSuspensao),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'codigoSuspensao',
        label: 'Código Suspensão',
      },
      {
        name: 'indSuspensao',
        label: 'Indicador de Suspensão',
      },
      {
        name: 'dtDecisaoSuspensao',
        label: 'Data',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['codigoSuspensao', 'indSuspensao', 'dtDecisaoSuspensao'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
