import clsx from 'clsx'

import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '50%',
    width: '1em',
    height: '1em',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#fff',
    filter: 'brightness(0.9)',
  },
  active: {
    filter: 'none',
  },
  default: {
    backgroundColor: theme.palette.primary.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
}))

interface ShowNumberProps {
  number?: number
  active?: boolean
  maxNumber?: number
  severity?: 'default' | 'info' | 'success' | 'warning' | 'error'
}

export default function ShowNumber(props: ShowNumberProps) {
  const { number, active = false, maxNumber = 1000, severity = 'default' } = props

  const classes = useStyles()

  if (!number) {
    return <></>
  }

  let numberString = ''

  if (number >= maxNumber) {
    numberString = maxNumber - 1 + '+'
  } else {
    numberString = number.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
  }

  return (
    <Box className={clsx(classes.root, classes[severity], { [classes.active]: active })}>
      {numberString}
    </Box>
  )
}
