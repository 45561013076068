import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, CurrencyTextField, DatePickerNew, TextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import { ProcessoTrabalhistaVinculoRemuneracaoDTO } from '~/hooks/queries/ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculoCreateDTO'
import {
  IndUnidadeSalarioEnum,
  IndUnidadeSalarioValues,
} from '~/@types/enums/IndUnidadeSalarioEnum'

const schema = yup.object().shape({
  dtRemuneracao: yup
    .date()
    .required('Informe a Data de Remuneração')
    .typeError('Informe uma Data válida'),
  vrSalarioFixo: yup
    .number()
    .required('Informe o Valor do Salário Fixo')
    .min(0.000001, 'Informe o Valor do Salário Fixo'),
  unidadeSalarioFixo: yup
    .mixed()
    .oneOf(Object.values(IndUnidadeSalarioEnum), 'Informe a Unidade do Salário Fixo'),
})

interface FormRemuneracaoProps {
  isOpen: boolean
  onClose: () => void
  data: ProcessoTrabalhistaVinculoRemuneracaoDTO
  onChange: (d: ProcessoTrabalhistaVinculoRemuneracaoDTO) => void
}

export default function FormRemuneracao({
  isOpen,
  onClose,
  onChange,
  data: _data,
}: FormRemuneracaoProps) {
  const [data, setData] = useState(_data)

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  function handleSubmit() {
    onChange(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Período"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      onOkClick={handleValidate}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Remuneração"
            size="small"
            value={data.dtRemuneracao || null}
            required
            validationErrors={validationErrors}
            name="dtRemuneracao"
            onChange={(value) => {
              const dtRemuneracao = value || ''
              setData((prevState) => ({ ...prevState, dtRemuneracao }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Valor do Salário Fixo"
            value={data.vrSalarioFixo || ''}
            required
            validationErrors={validationErrors}
            name="vrSalarioFixo"
            onChange={(_, value) => {
              const vrSalarioFixo = value
              setData((oldState) => ({
                ...oldState,
                vrSalarioFixo,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIAutoComplete
            label="Unidade do Salário Fixo"
            options={IndUnidadeSalarioValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.unidadeSalarioFixo}
            required
            validationErrors={validationErrors}
            name="unidadeSalarioFixo"
            onChange={(e, obj) => {
              const unidadeSalarioFixo = obj?.value || ''
              setData((prev) => ({ ...prev, unidadeSalarioFixo }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Descrição do Salário"
            value={data.descricaoSalarioVariavel}
            onChange={(e) => {
              const descricaoSalarioVariavel = e.target.value
              setData((prev) => ({ ...prev, descricaoSalarioVariavel }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
