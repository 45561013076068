import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoAfastamento } from './VinculoAfastamento'

export const queryKeyVinculoAfastamentoGetByVinculo = '/VinculoAfastamento/GetByVinculo/'

export function useVinculoAfastamentoGetByVinculo(vinculoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoAfastamento[] }>(
      queryKeyVinculoAfastamentoGetByVinculo + vinculoId,
    )
    return response?.data?.data || []
  }

  const query = useQuery([queryKeyVinculoAfastamentoGetByVinculo, vinculoId], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })

  return {
    ...query,
    data: query?.data || [],
  }
}
