import { useState } from 'react'

import { Box, Tab, Tabs } from '@material-ui/core'

import { Container, Finder, PageHeader, TabPanel } from '~/components'

import SimularRescisao from './SimularRescisao'
import RescisoesSimuladas from './RescisoesSimuladas'

export default function VinculoRescisaoSimulada() {
  const [tab, setTab] = useState(0)
  const [query, setQuery] = useState('')

  function handleCloseReciboPagamento() {
    setTab(1)
    setQuery('')
  }

  return (
    <Container>
      <Box height={50}>
        <PageHeader title="Rescisão Simulada">
          <Box display="flex" alignItems="center" gridGap={8}>
            {tab === 0 && <Finder onSearch={setQuery} onClose={() => setQuery('')} />}
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => {
                setTab(value)
                setQuery('')
              }}
            >
              <Tab label="Simular Rescisão" />
              <Tab label="Rescisões Simuladas" />
            </Tabs>
          </Box>
        </PageHeader>
      </Box>

      <TabPanel value={tab} index={0}>
        <SimularRescisao onCloseReciboPagamento={handleCloseReciboPagamento} query={query} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <RescisoesSimuladas />
      </TabPanel>
    </Container>
  )
}
