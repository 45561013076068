import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { Vinculo } from './Vinculo'
import { PessoaFisica } from '../PessoaFisica/PessoaFisica'
import { PessoaFisicaDependente } from '../PessoaFisicaDependente/PessoaFisicaDependente'
import { VinculoSalarioBase } from '../VinculoSalarioBase/VinculoSalarioBase'
import { VinculoLocal } from '../VinculoLocal/VinculoLocal'
import { VinculoHorario } from '../VinculoHorario/VinculoHorario'
import { VinculoTreinamento } from '../VinculoTreinamento/VinculoTreinamento'

export interface VinculoDataRetificarDTO {
  vinculo: Vinculo
  pessoaFisica: PessoaFisica
  dependentes: PessoaFisicaDependente[]
  salarios: VinculoSalarioBase[]
  ambientesTrabalho: VinculoLocal[]
  horariosTrabalho: VinculoHorario[]
  treinamentos: VinculoTreinamento[]
}

const query = '/Vinculo/ObterDataRetificacao'

export function useVinculoObterDataRetificacao(vinculoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoDataRetificarDTO }>(query, {
      params: {
        vinculoId,
      },
    })
    return response.data.data
  }

  return useQuery([query, vinculoId], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
