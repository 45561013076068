import { useState } from 'react'

import { Box, Paper, useTheme } from '@material-ui/core'

import { Button } from '~/components'

import Table from './Table'

import useObterApuracao from '~/hooks/queries/ValeTransporteApuracao/useObterApuracao'
import useAmbiente from '~/hooks/useAmbiente'

import { useValeTransporteApuracaoContext } from '../Context'
import { useProcessarParaRecibos } from '~/hooks/queries/ValeTransporteApuracao/useProcessarParaRecibos'

export default function Consulta() {
  const theme = useTheme()
  const { anoMes } = useAmbiente()
  const { grupoId, query } = useValeTransporteApuracaoContext()
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterApuracao({
    competencia: anoMes,
    grupoId,
  })
  const data = _data || []
  const { mutateAsync, isLoading: isSubmitting } = useProcessarParaRecibos()

  async function handleSubmit() {
    const estabelecimentosIds = rowsSelected.map((index) => data[index].estabelecimentoId)
    await mutateAsync({
      data: estabelecimentosIds,
      params: {
        competencia: anoMes,
      },
    })
  }

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(0.5)}
    >
      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%">
          <Table
            data={data}
            isFetching={isFetching}
            isLoading={isLoading}
            query={query}
            rowsSelected={rowsSelected}
            setRowsSelected={setRowsSelected}
          />
        </Box>
      </Box>

      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Processar para Recibos
        </Button>
      </Box>
    </Box>
  )
}
