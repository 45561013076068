import { useState } from 'react'

import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import {
  ActionDialog,
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Finder,
  Stack,
  ToolsDataTable,
} from '~/components'

import Form from './components/Form'

import useDialog from '~/hooks/useDialog'

import { useObterTodos } from '~/hooks/queries/EventosNaoIntegrados/useObterTodos'
import { useRemover } from '~/hooks/queries/EventosNaoIntegrados/useRemover'

import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'
import { FPMovimentoTipoEnum, FPMovimentoTipoLabel } from '~/@types/enums/FPMovimentoTipoEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface DialogConfigProps {
  isOpen: boolean
  onClose: () => void
  data: ModeloIntegracao
}

export default function DialogConfig({
  isOpen,
  onClose,
  data: _modeloIntegracao,
}: DialogConfigProps) {
  const [query, setQuery] = useState('')

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterTodos({
    params: {
      modeloIntegracaoId: _modeloIntegracao.id,
    },
  })
  const data = _d || []

  const { mutateAsync, isLoading: isRemoving } = useRemover()

  const {
    close: closeConfirmDelete,
    open: openConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
  } = useDialog('')

  const { close: closeForm, open: openForm, data: dataForm, isOpen: isOpenForm } = useDialog('')

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'eventoCodigo',
      label: 'Código',
    },
    {
      name: 'eventoNome',
      label: 'Nome',
    },
    {
      name: 'fpMovimentoTipoEnum',
      label: 'Tipo de movimento',
      options: {
        customBodyRender: (value: FPMovimentoTipoEnum) => FPMovimentoTipoLabel[value],
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => (
          <ButtonBox spacing={0} justifyContent="center">
            <IconButton
              size="small"
              title="Excluir este registro"
              color="primary"
              aria-label="Excluir"
              onClick={() => openConfirmDelete(value)}
            >
              <DeleteIcon fontSize="small" color="primary" />
            </IconButton>
          </ButtonBox>
        ),
      },
    },
  ]

  const handleDeleteItem = async () => {
    await mutateAsync({ params: { id: dataConfirmDelete } })
    closeConfirmDelete()
  }

  return (
    <ActionDialog
      title="Eventos não integrados"
      subtitle={
        _modeloIntegracao.codigo +
        ' - ' +
        _modeloIntegracao.nome +
        ' - ' +
        _modeloIntegracao.mascaraConta
      }
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <Stack height="100%">
        <Stack orientation="horizontal" justifyContent="flex-end">
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button variant="contained" onClick={() => openForm(_modeloIntegracao.id)}>
            Adicionar
          </Button>
        </Stack>

        <ContainerTable>
          <ToolsDataTable
            data={data}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching}
            disableAutoHeight
            sherlock={{
              columns: ['eventoCodigo', 'eventoNome'],
              query,
            }}
          />
        </ContainerTable>
      </Stack>

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isRemoving}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}

      {isOpenForm && <Form isOpen={isOpenForm} onClose={closeForm} modeloIntegracaoId={dataForm} />}
    </ActionDialog>
  )
}
