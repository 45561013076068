import { IconButton, useTheme } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon, CheckCircle } from '@material-ui/icons'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Stack,
  ToolsDataTable,
} from '~/components'

import FormPeriodo from './FormPeriodo'

import useDialog from '~/hooks/useDialog'
import { useGetAll } from '~/hooks/queries/ProcessoTrabalhistaVinculoBase/useGetAll'
import { useRemove } from '~/hooks/queries/ProcessoTrabalhistaVinculoBase/useRemove'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { IndGrauExposicaoEnum, IndGrauExposicaoLabels } from '~/@types/enums/IndGrauExposicaoEnum'

import { formatPrettyAnoMes } from '~/hooks/useUtils'

import { ProcessoTrabalhistaVinculoBase } from '~/hooks/queries/ProcessoTrabalhistaVinculoBase/dtos/ProcessoTrabalhistaVinculoBase'

interface TabPeriodoValoresProps {
  processoTrabalhistaVinculoId: string
  onClose: () => void
}

export default function TabPeriodoValores({
  processoTrabalhistaVinculoId,
  onClose,
}: TabPeriodoValoresProps) {
  const theme = useTheme()

  const { data: _d, isLoading, isFetching } = useGetAll({ processoTrabalhistaVinculoId })
  const data = _d || []

  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } = useRemove()

  const {
    close: closeFormPeriodo,
    data: dataFormPeriodo,
    isOpen: isOpenFormPeriodo,
    open: openFormPeriodo,
  } = useDialog<ProcessoTrabalhistaVinculoBase | null>(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  async function handleDelete() {
    await mutateAsyncRemove(dataConfirmDelete)
    closeConfirmDelete()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'Preenchido',
      options: {
        setCellProps: () => ({ style: { minWidth: '20px', maxWidth: '20px' } }),
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)

          let isPreenchido = false

          if (dtCurrent) {
            isPreenchido =
              dtCurrent.vrBcCp13 > 0 ||
              dtCurrent.vrBcCpMensal > 0 ||
              dtCurrent.vrBcFGTSDeclaradaAnteriormente > 0 ||
              dtCurrent.vrBcFGTSProcessoTrabalhista > 0 ||
              dtCurrent.vrBcFGTSSefip > 0
          }

          return (
            <ButtonBox justifyContent="center">
              <CheckCircle
                style={{
                  color: isPreenchido ? theme.palette.success.main : theme.palette.action.active,
                }}
              />
            </ButtonBox>
          )
        },
      },
    },
    {
      name: 'periodoReferencia',
      label: 'Competência',
      options: {
        customBodyRender: formatPrettyAnoMes,
      },
    },
    {
      name: 'indGrauExposicao',
      label: 'Grau Exposição',
      options: {
        customBodyRender: (value: IndGrauExposicaoEnum) => IndGrauExposicaoLabels[value],
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtCurrent = data.find((d) => d.id === value)
                  if (dtCurrent) {
                    openFormPeriodo(dtCurrent)
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => openConfirmDelete(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack height="100%">
      <ButtonBox>
        <Button variant="contained" onClick={() => openFormPeriodo(null)}>
          Adicionar
        </Button>
      </ButtonBox>

      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          disableAutoHeight
          pagination={false}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </ContainerTable>

      {isOpenFormPeriodo && (
        <FormPeriodo
          processoTrabalhistaVinculoId={processoTrabalhistaVinculoId}
          isOpen={isOpenFormPeriodo}
          data={dataFormPeriodo}
          onClose={closeFormPeriodo}
        />
      )}

      <ButtonBox>
        <Button variant="contained" onClick={() => onClose()}>
          Fechar
        </Button>
      </ButtonBox>

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isRemoving}
          onCancel={() => closeConfirmDelete()}
          onConfirm={handleDelete}
        />
      )}
    </Stack>
  )
}
