import { formatToCPFOrCNPJ } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { ToolsDataTable, TextFieldChangeEstabelecimentoEmail } from '~/components'
import { RPAConsultaDTO } from '~/hooks/queries/RPAutonomo/RPAConsultaDTO'
import { formatAnoMes } from '~/hooks/useUtils'

interface TableProps {
  data: RPAConsultaDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
  rowsSelected: number[]
  onRowSelected: (indexs: number[]) => void
  onAfterSubmit: () => void
}

export default function Table(props: TableProps) {
  const {
    data: _data,
    isLoading,
    isFetching,
    query,
    rowsSelected,
    onRowSelected,
    onAfterSubmit,
  } = props

  const data = _data.map((d) => ({
    ...d,
    competencia: d?.competencia ? formatAnoMes(d.competencia) : '',
    autonomoNrInscricao: d?.autonomoNrInscricao ? formatToCPFOrCNPJ(d.autonomoNrInscricao) : '',
    estabelecimentoNrInscricao: d?.estabelecimentoNrInscricao
      ? formatToCPFOrCNPJ(d.estabelecimentoNrInscricao)
      : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'competencia',
      label: 'Competência',
    },
    {
      name: 'autonomoNrInscricao',
      label: 'Autônomo Inscrição',
    },
    {
      name: 'autonomoNome',
      label: 'Autônomo',
    },
    {
      name: 'estabelecimentoNrInscricao',
      label: 'Estabelecimento Inscrição',
    },
    {
      name: 'estabelecimentoNome',
      label: 'Estabelecimento',
    },

    {
      name: 'rpId',
      label: 'Estabelecimento Email',
      options: {
        setCellHeaderProps: () => ({
          style: { width: 400 },
        }),
        customBodyRender: (id) => {
          const rp = _data.find((d) => d.rpId === id)
          if (!rp) return
          return (
            <TextFieldChangeEstabelecimentoEmail
              estabelecimentoId={rp.estabelecimentoId}
              email={rp?.estabelecimentoEmail || ''}
              afterSubmit={onAfterSubmit}
            />
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      optionsSelectable={{
        type: 'multiple',
        rowsSelected: rowsSelected,
        onRowSelected: onRowSelected,
      }}
      sherlock={{
        columns: ['competencia', 'autonomoNome', 'estabelecimentoNome', 'estabelecimentoEmail'],
        query,
      }}
    />
  )
}
