import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import moment from 'moment'

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data.map((d) => {
    return {
      ...d,
      aliqRat: d?.aliqRat ? `${d.aliqRat} %` : '',
      aliqFAP: d?.aliqFAP ? `${d.aliqFAP} %` : '',
      aliqRatAjustada: d?.aliqRatAjustada ? `${d.aliqRatAjustada} %` : '',
    }
  })

  const columns = [
    {
      name: 'dtFAP',
      label: 'Data',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
      },
    },
    {
      name: 'aliqRat',
      label: 'Alíquota RAT',
    },
    {
      name: 'aliqFAP',
      label: 'Alíquota FAP',
    },
    {
      name: 'aliqRatAjustada',
      label: 'Alíquota RAT Ajustada',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['dtFAP'],
      }}
    />
  )
}

export default Table
