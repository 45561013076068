import { IndLancamentoFaltaEnum } from '~/@types/enums/IndLancamentoFaltaEnum'
import { VinculoDSR } from '../VinculoDSR/VinculoDSR'
import { VinculoFaltas } from './VinculoFaltas'
import { TipoFaltaEnum } from '~/@types/enums/TipoFaltaEnum'

export enum TipoDia {
  Falta = 1,
  Atraso = 2,
  DSRDescontado = 3,
  Ferias = 4,
  Afastado = 5,
  DescansoSemanal = 6,
  DiaNaoTrabalha = 7,
}

export interface ConfiguracaoEmpregador {
  isDSRAtraso: boolean
  isDSRFalta: boolean
}

export interface DiaOcorrencia {
  tipo: TipoDia
  data: Date | string
}

export interface VinculoFaltasDTO {
  configuracaoEmpregador: ConfiguracaoEmpregador
  isReciboFinalizado: boolean
  faltasAtrasos: VinculoFaltas[]
  dsrDescontados: VinculoDSR[]
  diasOcorrencias: DiaOcorrencia[]
}

export interface VinculoFaltasCriarMultiplosDTO {
  camposFaltaAtraso: VinculoFaltas
  datas: string[]
}

export interface VinculoFaltasRelatorioPeriodoDTO {
  id: string
  indLancamento: IndLancamentoFaltaEnum
  dtFalta: Date | string
  qtdHoras: string | null
  tipoFalta: TipoFaltaEnum
  isDSR: boolean
  observacao: string

  competencia: string
  indLancamentoDesc: string
  dtFaltaDesc: string
  qtdHorasDesc: string
  tipoFaltaDesc: string
  isDSRDesc: string

  estabelecimentoId: string
  estabelecimentoNome: string
  estabelecimentoNrInscricao: string

  funcionarioId: string
  funcionarioNome: string
  funcionarioNrInscricao: string
  dtAdmissao: string
  dtRescisao: string

  dtInicioPeriodo: string
  dtFimPeriodo: string
}
