import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { VinculoTrabalhoFeriado } from '~/hooks/queries/VinculoTrabalhoFeriado/dtos/VinculoTrabalhoFeriado'
import { formatSimpleDate } from '~/utils/utils'

interface TableProps {
  isLoading: boolean
  isFetching: boolean
  data: VinculoTrabalhoFeriado[]
  onItemClick: (event: 'edit' | 'delete', data: VinculoTrabalhoFeriado) => void
}

export default function Table({ isLoading, isFetching, data, onItemClick }: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtFeriado',
      label: 'Data do Feriado',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'dtLimiteFolga',
      label: 'Data Limite da Folga',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'dtConcessaoFolga',
      label: 'Data da Concessão da Folga',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const rowCurrent = data.find((d) => d.id === value)
                  if (rowCurrent) {
                    onItemClick('edit', rowCurrent)
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  const rowCurrent = data.find((d) => d.id === value)
                  if (rowCurrent) {
                    onItemClick('delete', rowCurrent)
                  }
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
    />
  )
}
