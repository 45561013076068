const REINTEGRACAO_POR_DECISAO_JUDICIAL_1 = 1
const REINTEGRACAO_POR_ANISTIA_LEGAL_2 = 2
const REVERSAO_DE_SERVIDOR_PUBLICO_3 = 3
const RECONDUCAO_DE_SERVIDOR_PUBLICO_4 = 4
const REINCLUSAO_DE_MILITAR_5 = 5
const OUTROS_9 = 9

export const tipoReintegracaoValues = [
  {
    value: REINTEGRACAO_POR_DECISAO_JUDICIAL_1,
    name: '1 - Reintegração por decisão judicial',
  },
  {
    value: REINTEGRACAO_POR_ANISTIA_LEGAL_2,
    name: '2 - Reintegração por anistia legal',
  },
  {
    value: REVERSAO_DE_SERVIDOR_PUBLICO_3,
    name: '3 - Reversão de servidor público',
  },
  {
    value: RECONDUCAO_DE_SERVIDOR_PUBLICO_4,
    name: '4 - Recondução de servidor público',
  },
  {
    value: REINCLUSAO_DE_MILITAR_5,
    name: '5 - Reinclusão de militar',
  },
  {
    value: OUTROS_9,
    name: '9 - Outros',
  },
]

export const tipoReintegracaoConsts = {
  REINTEGRACAO_POR_DECISAO_JUDICIAL_1,
  REINTEGRACAO_POR_ANISTIA_LEGAL_2,
  REVERSAO_DE_SERVIDOR_PUBLICO_3,
  RECONDUCAO_DE_SERVIDOR_PUBLICO_4,
  REINCLUSAO_DE_MILITAR_5,
  OUTROS_9,
}
