import { useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, AnoMesTextField, PercentTextFieldNew } from '~/components'

import useAtualizarAntecipacao from '~/hooks/queries/AntecipacaoSalarial/useAtualizarAntecipacao'

import { AntecipacaoSalarial } from '~/hooks/queries/AntecipacaoSalarial/AntecipacaoSalarial'

interface TableForm {
  data: AntecipacaoSalarial
  isOpen: boolean
  onClose: () => void
}

export default function TableForm(props: TableForm) {
  const { data: _data, isOpen, onClose } = props

  const [data, setData] = useState<Nullable<AntecipacaoSalarial>>(_data)

  const { mutateAsync: atualizarAntecipacao, isLoading } = useAtualizarAntecipacao()

  async function handleSubmit() {
    await atualizarAntecipacao({
      antecipacaoSalarialId: data.id as string,
      data: data as AntecipacaoSalarial,
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Atualizar Antecipação Salarial"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onCancelClick={onClose}
      onOkClick={handleSubmit}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AnoMesTextField
            label="Ano/Mês Início"
            value={data.anoMesInicio || null}
            onChange={(e) => {
              const anoMes = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, anoMesInicio: anoMes }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AnoMesTextField
            label="Ano/Mês Fim"
            value={data.anoMesFim || null}
            onChange={(e) => {
              const anoMes = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, anoMesFim: anoMes }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <PercentTextFieldNew
            label="Percentual"
            value={data.percentual || null}
            onChange={(value) => setData((prev) => ({ ...prev, percentual: value }))}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
