import React from 'react'

import moment from 'moment'

import { ToolsDataTable } from '~/components'

const columns = [
  {
    name: 'codigo',
    label: 'Código',
  },
  {
    name: 'nrInscricao',
    label: 'CPF',
  },
  {
    name: 'nome',
    label: 'Nome',
  },
  {
    name: 'periodoInicio',
    label: 'Período Início',
  },
  {
    name: 'periodoFim',
    label: 'Período Fim',
  },
  {
    name: 'inicioFerias',
    label: 'Início',
  },
  {
    name: 'fimFerias',
    label: 'Fim',
  },
  {
    name: 'dtRetorno',
    label: 'Data de Retorno',
  },
  {
    name: 'nrDias',
    label: 'Número de Dias',
  },
]

export default function TableExpanded(props) {
  const { data: _data } = props

  const data = _data.map((d) => ({
    ...d,
    periodoInicio: d?.periodoInicio ? moment(d.periodoInicio).format('DD/MM/yyyy') : '',
    periodoFim: d?.periodoFim ? moment(d.periodoFim).format('DD/MM/yyyy') : '',
    inicioFerias: d?.inicioFerias ? moment(d.inicioFerias).format('DD/MM/yyyy') : '',
    fimFerias: d?.fimFerias ? moment(d.fimFerias).format('DD/MM/yyyy') : '',
    dtRetorno: d?.dtRetorno ? moment(d.dtRetorno).format('DD/MM/yyyy') : '',
  }))

  return <ToolsDataTable data={data} columns={columns} disableAutoHeight />
}
