import React from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import moment from 'moment'

import { ToolsDataTable } from '~/components'

export default function Table(props) {
  const { isLoading, query, data: _data, onRowSelected, rowsSelected } = props

  const data = _data.map((d) => ({
    ...d,
    nrInscricao: d?.nrInscricao ? formatToCPFOrCNPJ(d.nrInscricao) : '',
    dtAdmissao: d?.dtAdmissao ? moment(d.dtAdmissao).format('DD/MM/yyyy') : '',
  }))

  const columns = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'dtAdmissao',
      label: 'Data de Admissão',
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      columns={columns}
      sherlock={{ columns: ['codigo', 'nrInscricao', 'nome'], query }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        selectOnClick: true,
        type: 'multiple',
      }}
    />
  )
}
