import { useState } from 'react'

import { defaultGuid } from '~/utils/utils'
import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import { IndGrauExposicaoEnum, IndGrauExposicaoValues } from '~/@types/enums/IndGrauExposicaoEnum'

import { ActionDialog, AnoMesTextField, ContentDivider, CurrencyTextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCreate } from '~/hooks/queries/ProcessoTrabalhistaVinculoBase/useCreate'
import { useUpdate } from '~/hooks/queries/ProcessoTrabalhistaVinculoBase/useUpdate'

import { ProcessoTrabalhistaVinculoBase } from '~/hooks/queries/ProcessoTrabalhistaVinculoBase/dtos/ProcessoTrabalhistaVinculoBase'

const schema = yup.object().shape({
  periodoReferencia: yup.string().required('Informe a Competência'),
  indGrauExposicao: yup
    .mixed()
    .oneOf(Object.values(IndGrauExposicaoEnum), 'Informe o Grau de Exposição'),
})

interface FormPeriodoProps {
  processoTrabalhistaVinculoId: string
  isOpen: boolean
  onClose: () => void
  data: ProcessoTrabalhistaVinculoBase | null
}

export default function FormPeriodo({
  processoTrabalhistaVinculoId,
  isOpen,
  onClose,
  data: _data,
}: FormPeriodoProps) {
  const [data, setData] = useState(
    _data || {
      id: defaultGuid,
      processoTrabalhistaVinculoId,
      periodoReferencia: '',
      vrBcCpMensal: 0,
      vrBcCp13: 0,
      indGrauExposicao: IndGrauExposicaoEnum.AposentadoriaNormal,
      vrBcFGTSProcessoTrabalhista: 0,
      vrBcFGTSSefip: 0,
      vrBcFGTSDeclaradaAnteriormente: 0,
    },
  )

  const isNovaData = _data ? false : true

  const { mutateAsync: mutateAsyncCreate, isLoading: isCreating } = useCreate()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } = useUpdate()
  const isLoading = isCreating || isUpdating

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  async function handleSubmit() {
    if (isNovaData) {
      await mutateAsyncCreate(data)
    } else {
      await mutateAsyncUpdate({ id: data.id, data })
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Período"
      isOpen={isOpen}
      onClose={onClose}
      isOkProcessing={isLoading}
      okLabel="Salvar"
      onCancelClick={onClose}
      onOkClick={handleValidate}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AnoMesTextField
            label="Competência"
            required
            validationErrors={validationErrors}
            name="periodoReferencia"
            value={data.periodoReferencia || null}
            onChange={(e) => {
              const competencia = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, periodoReferencia: competencia }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIAutoComplete
            label="Grau de Exposição"
            required
            validationErrors={validationErrors}
            name="indGrauExposicao"
            options={IndGrauExposicaoValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.indGrauExposicao}
            onChange={(e, obj) => {
              const indGrauExposicao = obj?.value || ''
              setData((prev) => ({ ...prev, indGrauExposicao }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ContentDivider title="Base de Cálculo de Contribuição Previdenciária" />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo sobre a remuneração mensal do trabalhador"
            value={data.vrBcCpMensal || ''}
            onChange={(_, value) => {
              const vrBcCpMensal = value
              setData((oldState) => ({
                ...oldState,
                vrBcCpMensal,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo sobre a remuneração mensal do trabalhador referente ao 13° Salário"
            value={data.vrBcCp13 || ''}
            onChange={(_, value) => {
              const vrBcCp13 = value
              setData((oldState) => ({
                ...oldState,
                vrBcCp13,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ContentDivider title="Base de Cálculo para o FGTS" />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo ainda não declarada em SEFIP ou eSocial, inclusiva de verbas reconhecidas no processo"
            value={data.vrBcFGTSProcessoTrabalhista || ''}
            onChange={(_, value) => {
              const vrBcFGTSProcessoTrabalhista = value
              setData((oldState) => ({
                ...oldState,
                vrBcFGTSProcessoTrabalhista,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo declarada apenas em SEFIP, não informada no eSocial e ainda não recolhida"
            value={data.vrBcFGTSSefip || ''}
            onChange={(_, value) => {
              const vrBcFGTSSefip = value
              setData((oldState) => ({
                ...oldState,
                vrBcFGTSSefip,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo FGTS declarada anteriormente no eSocial e não recolhida"
            value={data.vrBcFGTSDeclaradaAnteriormente || ''}
            onChange={(_, value) => {
              const vrBcFGTSDeclaradaAnteriormente = value
              setData((oldState) => ({
                ...oldState,
                vrBcFGTSDeclaradaAnteriormente,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
