import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterPorVinculoInvalidate } from './useObterPorVinculo'

import { notifyPut } from '~/utils/notification'

import { VinculoSuspensaoUpdateDTO } from './dtos/VinculoSuspensaoUpdateDTO'

interface RequestParams {
  data: VinculoSuspensaoUpdateDTO
  id: string
}

export function useUpdateVinculoSuspensao() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterPorVinculoInvalidate()

  async function handleRequest({ data, id }: RequestParams) {
    await api.put('/VinculoSuspensao/' + id, data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
