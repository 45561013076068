import { useSelector } from 'react-redux'

import AmbienteError from '~/exceptions/AmbienteError'

import useCredentials from './useCredentials'

import { EmpregadorConsulta } from './queries/EmpregadorConsulta/EmpregadorConsulta'
import { EstabelecimentoConsulta } from './queries/EstabelecimentoConsulta/EstabelecimentoConsulta'

interface ResponseUseAmbiente {
  ano: number
  mes: string
  anoMes: string
  empregador: EmpregadorConsulta
  estabelecimento: EstabelecimentoConsulta
  empresaId: string
}

function useAmbiente(throwError = true, onlyCompetencia = false): ResponseUseAmbiente {
  const ambienteSelecionado = useSelector((state: FixLater) => state.app.ambiente)
  const { userLogged } = useCredentials()

  const { ano, mes, anoMes } = ambienteSelecionado.competencia

  if (!throwError && onlyCompetencia) return { ano, mes, anoMes } as FixLater

  if (throwError && !ambienteSelecionado?.hasAmbiente)
    throw new AmbienteError('Nenhum ambiente foi selecionado')

  if (!ambienteSelecionado.hasAmbiente) return false as FixLater

  const empresaId = userLogged?.empresaSelecionada

  const empregador = ambienteSelecionado.empresa.empregador
  const estabelecimento = ambienteSelecionado.empresa.estabelecimento

  return {
    ano,
    mes,
    anoMes,
    empregador,
    estabelecimento,
    empresaId,
  }
}

export default useAmbiente
