import { formatToCNPJ, formatToCPF } from 'brazilian-values'
import moment from 'moment'

import { Box, Paper, Grid } from '@material-ui/core'

import HeaderItemMain from './HeaderItemMain'
import HeaderItemSimple from './HeaderItemSimple'

import { useContextRecibo } from '~/hooks/useRecibo'
import { copyToClipboard, openBlankTab } from '~/hooks/useUtils'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const Header = () => {
  const { reciboPagamento, isLoadingRecibo } = useContextRecibo()

  const vinculo = reciboPagamento?.vinculo || null

  const hasVinculoSelected = vinculo ? true : false

  const funcionarioNome = vinculo?.pessoaFisica?.nome || ''
  const funcionarioNrInscricao = vinculo?.pessoaFisica?.nrInscricao
    ? formatToCPF(vinculo.pessoaFisica.nrInscricao)
    : ''
  const funcionarioMatricula = vinculo?.matricula || ''
  const funcionarioAdmissao = vinculo?.dtAdmissao
    ? moment(vinculo.dtAdmissao).format('DD/MM/yyyy')
    : ''
  const funcionarioRescisao = vinculo?.dtRescisao
    ? moment(vinculo.dtRescisao).format('DD/MM/yyyy')
    : ''
  const empregadorNrInscricao = vinculo?.estabelecimento?.empregador?.nrInscricao
    ? formatToCNPJ(vinculo.estabelecimento.empregador.nrInscricao)
    : ''
  const cargoNome = reciboPagamento?.cargo?.nome || ''
  const lotacaoNome = reciboPagamento?.lotacao?.nome || ''

  function handleRedirect() {
    let urlRedirect = '/painel'

    switch (vinculo?.tipoRegistro) {
      case VinculoTipoEnum.Funcionario_1:
        urlRedirect = '/funcionario/' + vinculo?.id
        break
      case VinculoTipoEnum.Empregador_2:
        urlRedirect = '/socios-diretores/' + vinculo?.id
        break
      case VinculoTipoEnum.Estagiario_3:
        urlRedirect = '/estagiario/' + vinculo?.id
        break
      case VinculoTipoEnum.Autonomo_4:
        urlRedirect = '/autonomo/' + vinculo?.id
        break
      case VinculoTipoEnum.Cooperado_5:
        urlRedirect = '/cooperado/' + vinculo?.id
        break
    }

    openBlankTab(urlRedirect)
  }

  return (
    <Box component={Paper} p={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={5} lg={3}>
          <Box py={1} display="flex" height="100%">
            <HeaderItemMain
              title={funcionarioNome}
              subtitle={`CPF: ${funcionarioNrInscricao}`}
              icon="account_circle"
              disabled={!hasVinculoSelected}
              isLoading={isLoadingRecibo}
              onClickTitle={handleRedirect}
              onClickSubtitle={() =>
                copyToClipboard(
                  funcionarioNrInscricao,
                  funcionarioNrInscricao + ' foi copiado com sucesso para a área de transferência',
                )
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={9}>
          <Box display="flex" height="100%">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <HeaderItemSimple
                  disabled={!hasVinculoSelected}
                  isLoading={isLoadingRecibo}
                  title={`Admissão: ${funcionarioAdmissao}`}
                  icon="calendar_today"
                  onClick={() =>
                    copyToClipboard(
                      funcionarioAdmissao,
                      funcionarioAdmissao + ' foi copiado com sucesso para a área de transferência',
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <HeaderItemSimple
                  isLoading={isLoadingRecibo}
                  disabled={!hasVinculoSelected}
                  title={`Matrícula: ${funcionarioMatricula}`}
                  icon="chrome_reader_mode"
                  onClick={() =>
                    copyToClipboard(
                      funcionarioMatricula,
                      funcionarioMatricula +
                        ' foi copiado com sucesso para a área de transferência',
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                {funcionarioRescisao && (
                  <HeaderItemSimple
                    isLoading={isLoadingRecibo}
                    disabled={!hasVinculoSelected}
                    title={`Rescisão: ${funcionarioRescisao}`}
                    icon="calendar_today"
                    onClick={() =>
                      copyToClipboard(
                        funcionarioRescisao,
                        funcionarioRescisao +
                          ' foi copiado com sucesso para a área de transferência',
                      )
                    }
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <HeaderItemSimple
                  isLoading={isLoadingRecibo}
                  disabled={!hasVinculoSelected}
                  title={`Cargo: ${cargoNome}`}
                  icon="work"
                  onClick={() =>
                    copyToClipboard(
                      cargoNome,
                      cargoNome + ' foi copiado com sucesso para a área de transferência',
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <HeaderItemSimple
                  isLoading={isLoadingRecibo}
                  disabled={!hasVinculoSelected}
                  title={`Lotação: ${lotacaoNome}`}
                  icon="group"
                  onClick={() =>
                    copyToClipboard(
                      lotacaoNome,
                      lotacaoNome + ' foi copiado com sucesso para a área de transferência',
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <HeaderItemSimple
                  isLoading={isLoadingRecibo}
                  disabled={!hasVinculoSelected}
                  title={`CNPJ: ${empregadorNrInscricao}`}
                  icon="business"
                  onClick={() =>
                    copyToClipboard(
                      empregadorNrInscricao,
                      empregadorNrInscricao +
                        ' foi copiado com sucesso para a área de transferência',
                    )
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
