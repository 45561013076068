import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestProps {
  file: FormData
  anoMes: string
}

export default function useProcessarPlanilha() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest({ file, anoMes }: RequestProps) {
    const response = await api.post<{ data: string[] }>(
      '/ImportarEventosRP/ProcessarPlanilha',
      file,
      {
        params: {
          anoMes,
        },
      },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Planilha processada com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
