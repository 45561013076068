import { useState } from 'react'

import { Theme, useMediaQuery } from '@material-ui/core'

import { ToolsDataTable } from '~/components'

import TableEventosAnalitico from './TableEventosAnalitico'

import { ESocialGrupoEventoTransmitirDTO } from '~/hooks/queries/ESocial/useObterEventosTransmissaoEnvio'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { ESocialGrupoEventoConsultaEnum } from '~/@types/enums/ESocialGrupoEventoConsultaEnum'

interface TableEventosProps {
  data: ESocialGrupoEventoTransmitirDTO[]
  grupo: ESocialGrupoEventoConsultaEnum
}

export default function TableEventos(props: TableEventosProps) {
  const { data, grupo } = props

  const [rowsExpanded, setRowExpanded] = useState<number[]>([])

  const isXSmallWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Descrição',
      name: 'grupoEventoDescricao',
    },
    {
      label: 'Quantidade',
      name: 'totalEventos',
      options: {
        setCellProps: () => ({
          style: {
            width: isXSmallWidth ? undefined : '73px',
          },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      pagination={false}
      showHeader={false}
      disableAutoHeight
      optionsExpandable={{
        type: 'single',
        expandedChildren: (index: number) => {
          const eventoCurrent = data[index]
          return <TableEventosAnalitico data={eventoCurrent.eventos} grupo={grupo} />
        },
        onRowExpanded: setRowExpanded,
        rowsExpanded: rowsExpanded,
      }}
    />
  )
}
