import { Box, Tab, Tabs } from '@material-ui/core'
import { useState } from 'react'

import { PageHeader, StackContainer, TabPanel } from '~/components'

import ProcessamentoFeriado from './ProcessamentoFeriado'
import ConsultaFeriado from './ConsultaFeriado'

enum TabFeriado {
  Processamento,
  Consulta,
}

export default function VinculoTrabalhoFeriado() {
  const [tab, setTab] = useState(TabFeriado.Processamento)

  return (
    <StackContainer>
      <PageHeader title="Controle de Feriado">
        <Box>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => setTab(value)}
          >
            <Tab label="Processamento" />
            <Tab label="Consulta" />
          </Tabs>
        </Box>
      </PageHeader>

      <Box flex={1}>
        <TabPanel value={tab} index={TabFeriado.Processamento}>
          <ProcessamentoFeriado />
        </TabPanel>
        <TabPanel value={tab} index={TabFeriado.Consulta}>
          <ConsultaFeriado />
        </TabPanel>
      </Box>
    </StackContainer>
  )
}
