import React from 'react'

import { Box, BoxProps, useTheme } from '@material-ui/core'

export interface StackProps extends BoxProps {
  children: React.ReactNode
  orientation?: 'vertical' | 'horizontal'
  gap?: number
}

export default function Stack({
  children,
  orientation = 'vertical',
  gap = 1,
  ...rest
}: StackProps) {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDirection={orientation === 'horizontal' ? 'row' : 'column'}
      gridGap={theme.spacing(gap)}
      {...rest}
    >
      {children}
    </Box>
  )
}
