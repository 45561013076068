import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryKeyVinculoAfastamentoGetByVinculo } from './useVinculoAfastamentoGetByVinculo'

interface VinculoAfastamentoInterromperAfastamentoDTO {
  afastamentoOrigemId: string
  dtInterromperAfastamento: Date | string
}

export function useInterromperAfastamento() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: VinculoAfastamentoInterromperAfastamentoDTO) {
    await api.post('/VinculoAfastamento/InterromperAfastamento', data)
    notification.success('Afastamento Interrompido com sucesso')
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries(queryKeyVinculoAfastamentoGetByVinculo),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
