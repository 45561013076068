const naoInformar_0 = 0
const CNPJ_1 = 1
const CPF_2 = 2
const CAEPF_3 = 3
const CNO_4 = 4
const CGC_5 = 5
const CEI_6 = 6
const NIT_7 = 7

export const tipoInscricaoValues = [
  { value: naoInformar_0, name: 'Não informar' },
  { value: CNPJ_1, name: 'CNPJ' },
  { value: CPF_2, name: 'CPF' },
  { value: CAEPF_3, name: 'CAEPF' },
  { value: CNO_4, name: 'CNO' },
  { value: CGC_5, name: 'CGC' },
  { value: CEI_6, name: 'CEI' },
  { value: NIT_7, name: 'NIT' },
]

export const tipoInscricaoConsts = {
  naoInformar_0,
  CNPJ_1,
  CPF_2,
  CAEPF_3,
  CNO_4,
  CGC_5,
  CEI_6,
  NIT_7,
}
