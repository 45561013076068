import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoSalarioBase } from './VinculoSalarioBase'

const query = '/VinculoSalarioBase/ObterSalarioBase'

interface RequestParams {
  vinculoId: string
  dtSalario: string
}

export default function useObterSalarioBase(params: RequestParams) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoSalarioBase }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
