import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterFuncionariosInvalidate } from './useObterFuncionarios'

import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: string[]
  params: { dtFeriado: string }
}

export function useProcessarFeriado() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterFuncionariosInvalidate()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{ data: string[] }>(
      '/VinculoTrabalhoFeriado/ProcessarFeriado',
      data,
      { params },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Feriados processados com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
