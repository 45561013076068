export enum IndAcordoColetivoEnum {
  AcordoColetioTrabalho_A = 1,
  LegislacaoFederal_B = 2,
  ConvencaoColetiaTrabalho_C = 3,
  SentencaoNormativa_D = 4,
  ConversaoLicencaSaude_E = 5,
  OutrasVerbas_F = 6,
  AntecipacaoAcordo_G = 7,
  RecolhimentoMensalFGTS_H = 8,
}

export const IndAcordoColetivoValues = [
  {
    value: IndAcordoColetivoEnum.AcordoColetioTrabalho_A,
    name: 'A - Acordo Coletivo de Trabalho',
  },
  {
    value: IndAcordoColetivoEnum.LegislacaoFederal_B,
    name: 'B - Legislação federal, estadual, municipal ou distrital',
  },
  {
    value: IndAcordoColetivoEnum.ConvencaoColetiaTrabalho_C,
    name: 'C - Convenção Coletiva de Trabalho',
  },
  {
    value: IndAcordoColetivoEnum.SentencaoNormativa_D,
    name: 'D - Sentença normativa - Dissídio',
  },
  {
    value: IndAcordoColetivoEnum.ConversaoLicencaSaude_E,
    name: 'E - Conversão de licença saúde em acidente de trabalho',
  },
  {
    value: IndAcordoColetivoEnum.OutrasVerbas_F,
    name: 'F - Outras verbas de natureza salarial ou não salarial devidas após o desligamento',
  },
  {
    value: IndAcordoColetivoEnum.AntecipacaoAcordo_G,
    name: 'G - Antecipação de diferenças de acordo, convenção ou dissídio coletivo',
  },
  {
    value: IndAcordoColetivoEnum.RecolhimentoMensalFGTS_H,
    name: 'H - Recolhimento mensal de FGTS anterior ao início de obrigatoriedade dos eventos periódicos',
  },
]
