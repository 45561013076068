import React from 'react'

// import { Container } from './styles';

const maskValue = (value) => {
  if (!value) return ''
  return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1:$2')
}

const Hora = (props) => {
  const { value } = props

  return <span>{maskValue(value)}</span>
}

export default Hora
