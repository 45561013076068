import React from 'react'

import moment from 'moment'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { useVinculoEstabilidadeGetAllPorVinculo } from '~/hooks/queries/useVinculoEstabilidade'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  onItemClick: (event: 'edit' | 'delete', idRow: string) => void
  query: string
  vinculoId: string | null
}

export default function Table(props: TableProps) {
  const { onItemClick, query, vinculoId } = props

  const { data: _data, isLoading, isFetching } = useVinculoEstabilidadeGetAllPorVinculo(vinculoId)

  const data = _data
    ? _data.map((d) => ({
        ...d,
        estabilidadeCodigoDescricaoDias:
          d.estabilidade.codigo +
          ' - ' +
          d.estabilidade.descricao +
          ' - ' +
          d.estabilidade.numeroDias +
          ' dias',
        dtInicio: moment(d.dtInicio).format('DD/MM/yyyy'),
        dtFim: moment(d.dtFim).format('DD/MM/yyyy'),
      }))
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'estabilidadeCodigoDescricaoDias',
      label: 'Estabilidade',
    },
    {
      name: 'dtInicio',
      label: 'Data de Início',
    },
    {
      name: 'dtFim',
      label: 'Data Final',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <DataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['estabilidadeCodigoDescricaoDias', 'dtInicio', 'dtFim'],
      }}
      options={{}}
      orderBy=""
    />
  )
}
