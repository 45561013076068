import Immutable from 'seamless-immutable'

export const Types = {
  REQUEST_MENU_VINCULO: 'MENU_VINCULO/REQUEST',
  SUCCESS_MENU_VINCULO: 'MENU_VINCULO/SUCCESS',
  FAILURE_MENU_VINCULO: 'MENU_VINCULO/FAILURE',
}

const initialState = Immutable({
  loading: false,
  error: null,
  data: [],
})

export default function menus(state = initialState, action) {
  switch (action.type) {
    case Types.REQUEST_MENU_VINCULO:
      return {
        ...state,
        loading: true,
        filters: action.payload.filter,
        error: null,
      }
    case Types.SUCCESS_MENU_VINCULO:
      return {
        ...state,
        loading: false,
        data: action.payload.menus,
      }
    case Types.FAILURE_MENU_VINCULO:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}

export const Creators = {
  requestMenuVinculo: (filters) => ({
    type: Types.REQUEST_MENU_VINCULO,
    payload: { filters },
  }),
  successMenuVinculo: (menus) => ({
    type: Types.SUCCESS_MENU_VINCULO,
    payload: { menus },
  }),
  failureMenuVinculo: (error) => ({
    type: Types.FAILURE_MENU_VINCULO,
    payload: { error },
  }),
}
