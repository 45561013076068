import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import moment from 'moment'

const formatLengthDescription = (description) => {
  if (!description) return ''
  if (description.length > 50) return `${description.substr(0, 50)}...`
  return description
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query, isFetching } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtAlteracao: item?.dtAlteracao ? moment(item.dtAlteracao).format('DD/MM/yyyy') : '',
      dtEfetiva: item?.dtEfetiva ? moment(item.dtEfetiva).format('DD/MM/yyyy') : '',
      dtReciboEsocial: item?.dtReciboEsocial
        ? moment(item.dtReciboEsocial).format('DD/MM/yyyy')
        : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtAlteracao',
        label: 'Data da Alteração',
      },
      {
        name: 'dtEfetiva',
        label: 'Data Efetiva',
      },
      {
        name: 'descricaoAlteracao',
        label: 'Descrição',
        options: {
          customBodyRender: (value) => <div title={value}>{formatLengthDescription(value)}</div>,
        },
      },
      {
        name: 'reciboEsocial',
        label: 'Recibo eSocial',
      },
      {
        name: 'dtReciboEsocial',
        label: 'Data Recibo eSocial',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['dtAlteracao', 'dtEfetiva', 'reciboEsocial', 'dtReciboEsocial'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
