import { useState } from 'react'

import moment from 'moment'

import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'

import useAmbiente from '~/hooks/useAmbiente'

import { useObterConvocacoesParaRP } from '~/hooks/queries/VinculoIntermitente/useObterConvocacoesParaRP'
import { useNovo } from '~/hooks/queries/RP/useNovo'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoMenuDataDTO } from '~/hooks/queries/RP/VinculoMenuDataDTO'
import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'

interface ReciboIntermitenteProps {
  isOpen: boolean
  onClose: () => void
  vinculo: VinculoMenuDataDTO
  onAfterSubmitForm: (rpId: string) => void
}

export default function ReciboIntermitente(props: ReciboIntermitenteProps) {
  const { isOpen, onClose, vinculo, onAfterSubmitForm } = props

  const [rowsSelected, setRowsSelected] = useState([])

  const { anoMes } = useAmbiente()
  const { data: _d, isLoading, isFetching } = useObterConvocacoesParaRP(vinculo.vinculoId)
  const _data = _d || []

  const data = _data.map((d) => ({
    ...d,
    dtInicio: d?.dtInicio ? moment(d.dtInicio).format('DD/MM/yyyy') : '',
    dtFim: d?.dtFim ? moment(d.dtFim).format('DD/MM/yyyy') : '',
    dtAceiteConvocacao: d?.dtAceiteConvocacao
      ? moment(d.dtAceiteConvocacao).format('DD/MM/yyyy')
      : '',
  }))

  const { mutateAsync, isLoading: isSubmitting } = useNovo()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigoConvocacao',
      label: 'Código da Convocação',
    },
    {
      name: 'dtInicio',
      label: 'Data de Início',
    },
    {
      name: 'dtFim',
      label: 'Data Final',
    },
    {
      name: 'dtAceiteConvocacao',
      label: 'Data da Confirmação',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
  ]

  async function onSubmit() {
    const convocao = _data[rowsSelected[0]]
    const rp = await mutateAsync({
      vinculoId: vinculo.vinculoId,
      anoMes,
      idf: ReciboIDFEnum.Mensal_1,
      lotacaoId: null,
      vinculoIntermitenteId: convocao.id,
    })
    onAfterSubmitForm(rp.id)
  }

  return (
    <ActionDialog
      title="Selecione uma Convocação para criar o Recibo"
      subtitle={vinculo.vinculoNome}
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            isLoading={isSubmitting}
            disabled={rowsSelected.length > 0 ? false : true}
          >
            Prosseguir
          </Button>
        </ButtonBox>
      }
    >
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        optionsSelectable={{
          type: 'single',
          onRowSelected: setRowsSelected,
          rowsSelected: rowsSelected,
          selectOnClick: true,
        }}
      />
    </ActionDialog>
  )
}
