import { ActionDrawer } from '~/components'

interface DrawerDocumentoProps {
  isOpen: boolean
  onClose: () => void
  documento: string
  title: string
  subtitle?: string
}

export default function DrawerDocumento(props: DrawerDocumentoProps) {
  const { isOpen, onClose, documento, title, subtitle } = props

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      size="lg"
      customActions={<></>}
    >
      <iframe
        title={title}
        src={'data:application/pdf;base64,' + documento}
        height="99%"
        width="100%"
      />
    </ActionDrawer>
  )
}
