import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { AutoCompleteProcesso } from '~/components/AutoComplete'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

const schema = yup.object().shape({
  processo: yup.string().required('Informe o Processo').nullable(),
  terceiros: yup.string().required('Informe o Terceiros').nullable(),
  codigoIndicativoSuspensao: yup.string().required('Informe o Indicativo de Suspensão').nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/LotacaoProcesso/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/LotacaoProcesso', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Processo"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <AutoCompleteProcesso
            required
            validationErrors={validationErrors}
            name="processo"
            value={data?.processo || null}
            onChange={(e, processo) => {
              const processoId = processo?.id || null
              setData((oldState) => ({
                ...oldState,
                processo,
                processoId,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            label="Terceiros"
            fullWidth
            value={data?.terceiros || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 4,
            }}
            onlyNumber
            required
            name="terceiros"
            validationErrors={validationErrors}
            onChange={(e) => {
              const terceiros = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                terceiros,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            label="Indicativo de Suspensão"
            fullWidth
            value={data?.codigoIndicativoSuspensao || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 14,
            }}
            required
            name="codigoIndicativoSuspensao"
            validationErrors={validationErrors}
            onChange={(e) => {
              const codigoIndicativoSuspensao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                codigoIndicativoSuspensao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
