import React, { useEffect, useState } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { Box, Typography } from '@material-ui/core'

import {
  ActionDialog,
  Button,
  ConfirmDeleteDialog,
  Finder,
  PageHeader,
  TreeView,
} from '~/components'

import Table from './Table'
import Form from './Form'

import { VinculoMenuItem, useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import { useVinculoLotacaoDelete } from '~/hooks/queries/useVinculoLotacao'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const vinculosTiposFuncionario = [VinculoTipoEnum.Funcionario_1]
const vinculosTiposRestante = [
  VinculoTipoEnum.Empregador_2,
  VinculoTipoEnum.Estagiario_3,
  VinculoTipoEnum.Autonomo_4,
  VinculoTipoEnum.Cooperado_5,
]

export default function VinculoLotacao() {
  const [vinculo, setVinculo] = useState<VinculoMenuItem | null>(null)
  const [query, setQuery] = useState('')

  const url = window.location.href
  const params = url.split('/')
  const ultimoParametro = params[params.length - 1]

  const isVinculosTSV = ultimoParametro === 'tsv' ? true : false

  const titlePage = isVinculosTSV ? 'Lotação TSV' : 'Lotação por Funcionário'

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<string | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string | null>(null)

  useEffect(() => {
    setVinculo(null)
  }, [estabelecimento, anoMes])

  const { mutateAsync: mutateAsyncDelete, isLoading: isLoadingDelete } = useVinculoLotacaoDelete()
  const {
    data: dataMenu,
    isLoading: isLoadingMenu,
    isFetching: isFetchingMenu,
  } = useEstabelecimentoVinculosMenu(
    isVinculosTSV ? vinculosTiposRestante : vinculosTiposFuncionario,
  )

  function handleClickItem(event: 'edit' | 'delete', id: string) {
    async function handleClickEditItem(id: string) {
      openForm(id)
    }

    function handleClickDeleteItem(id: string) {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](id)
  }

  async function handleConfirmDelete(idToDelete: string) {
    await mutateAsyncDelete(idToDelete)
    closeConfirmDelete()
  }

  return (
    <Box height="100%" width="100%" padding={1}>
      <TreeView
        data={dataMenu}
        onSelectItem={(e: FixLater, vinculo: VinculoMenuItem) => setVinculo(vinculo)}
        searchBy="vinculoNome"
        renderOption={(option: VinculoMenuItem) => `${option.vinculoNome}`}
        isLoading={isLoadingMenu}
        isLoadingOnClick={isFetchingMenu}
      >
        <PageHeader title={titlePage} subtitle={vinculo?.vinculoNome ? vinculo.vinculoNome : '-'}>
          {vinculo?.vinculoNome ? (
            <ButtonBox>
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
              <Button
                size="small"
                color="primary"
                onClick={() => openForm(null)}
                variant="contained"
              >
                Adicionar
              </Button>
            </ButtonBox>
          ) : (
            <></>
          )}
        </PageHeader>
        {vinculo?.vinculoId ? (
          <Table vinculoId={vinculo.vinculoId} onItemClick={handleClickItem} query={query} />
        ) : (
          <Typography>Selecione um Funcionário</Typography>
        )}
      </TreeView>

      {isOpenForm && vinculo && (
        <ActionDialog
          isOpen={isOpenForm}
          title={titlePage + ' - Cadastro'}
          subtitle={vinculo.vinculoNome}
          onClose={closeForm}
          customActions={<></>}
        >
          <Form vinculoLotacaoId={dataForm} vinculoId={vinculo.vinculoId} onClose={closeForm} />
        </ActionDialog>
      )}

      {dataConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isLoadingDelete}
          onCancel={closeConfirmDelete}
          onConfirm={() => handleConfirmDelete(dataConfirmDelete)}
        />
      )}
    </Box>
  )
}
