import moment from 'moment'

import { Grid } from '@material-ui/core'

import StepperForm from '~/components/StepperForm'

import FormAdmissao from './FormAdmissao'
import FormContrato from './FormContrato'
import FormLotacao from './FormLotacao'
import FormParametros from './FormParametros'
import FormSalarioContratual from './FormSalarioContratual'
import FormSalarioAdicional from './FormSalarioAdicional'
import FormSalarioAula from './FormSalarioAula'
import FormAmbienteTrabalho from './FormAmbienteTrabalho'
import FormHorarioTrabalho from './FormHorarioTrabalho'
import FormObservacao from './FormObservacao'

import { useVinculoCadastroCompleto } from '~/hooks/queries/Vinculo/useVinculoCadastroCompleto'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import { VinculoLotacaoPartial } from '~/hooks/queries/VinculoLotacao/VinculoLotacao'
import { VinculoSalarioBasePartial } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'
import { VinculoSalarioAdicionalPartial } from '~/hooks/queries/VinculoSalarioAdicional/VinculoSalarioAdicional'
import { VinculoSalarioAulaPartial } from '~/hooks/queries/VinculoSalarioAula/VinculoSalarioAula'
import { VinculoLocalPartial } from '~/hooks/queries/VinculoLocal/VinculoLocal'
import { VinculoHorarioPartial } from '~/hooks/queries/VinculoHorario/VinculoHorario'
import { VinculoObservacaoPartial } from '~/hooks/queries/VinculoObservacao/VinculoObservacao'

import { IndSalarioContratualEnum } from '~/@types/enums/IndSalarioContratualEnum'

export interface MainDataForm {
  dataInitialForm: VinculoPartial
  dataSalarioContratual: VinculoSalarioBasePartial
  dataLotacao: VinculoLotacaoPartial
  dataSalarioAdicional?: VinculoSalarioAdicionalPartial[]
  dataSalarioAula?: VinculoSalarioAulaPartial
  dataAmbienteTrabalho: VinculoLocalPartial
  dataHorarioTrabalho: VinculoHorarioPartial
  dataObservacao?: VinculoObservacaoPartial
}

interface FlowCreateProps {
  data: VinculoPartial
  onCancel: () => void
  handleAfterSubmit: () => void
}

export default function FlowCreate(props: FlowCreateProps) {
  const { data: dataInitialForm, onCancel, handleAfterSubmit } = props

  const { isLoading, mutateAsync } = useVinculoCadastroCompleto()

  const { estabelecimento } = useAmbiente()

  const mainData: MainDataForm = {
    dataInitialForm,
    dataSalarioContratual: {
      dtSalario: dataInitialForm.dtAdmissao,
      dtEfetiva: dataInitialForm.dtAdmissao,
      indSalario: IndSalarioContratualEnum.Minimo,
      quantidade: 1,
    },
    dataLotacao: {
      anoMes: moment(dataInitialForm.dtAdmissao).format('yyyyMM'),
      lotacaoId: estabelecimento.lotacaoId || undefined,
    },
    dataAmbienteTrabalho: {
      dtLocal: dataInitialForm.dtAdmissao,
    },
    dataHorarioTrabalho: {
      dtIncio: dataInitialForm.dtAdmissao,
    },
  }

  async function handleSubmit(dt: MainDataForm) {
    delete dt.dataInitialForm.pessoaFisica
    delete dt.dataInitialForm.sindicato
    delete dt.dataInitialForm.titulacao
    delete dt.dataAmbienteTrabalho.setor
    delete dt.dataAmbienteTrabalho.departamento

    dt.dataHorarioTrabalho.vinculoHorarioItens = dt.dataHorarioTrabalho.vinculoHorarioItens?.map(
      (d) => ({
        ...d,
        horarioTrabalho: undefined,
      }),
    )

    await mutateAsync({
      vinculo: dt.dataInitialForm,
      vinculoSalarioBase: dt.dataSalarioContratual,
      vinculoLotacao: dt.dataLotacao,
      listVinculoSalarioAdicional: dt.dataSalarioAdicional,
      vinculoSalarioAula: dt.dataSalarioAula,
      vinculoLocal: dt.dataAmbienteTrabalho,
      vinculoHorario: dt.dataHorarioTrabalho,
      vinculoObservacao: dt.dataObservacao,
    })

    handleAfterSubmit()
  }

  return (
    <StepperForm.Provider<MainDataForm>
      onSubmit={handleSubmit}
      onCancel={onCancel}
      data={mainData}
      isLoading={isLoading}
      steps={[
        {
          label: 'Admissão',
          children: <FormAdmissao />,
          icon: 'person',
        },
        {
          label: 'Contrato',
          children: <FormContrato />,
          icon: 'assignment',
        },
        {
          label: 'Lotação',
          children: <FormLotacao />,
          icon: 'group',
        },
        {
          label: 'Salário Contratual',
          children: <FormSalarioContratual />,
          icon: 'attach_money',
        },
        {
          label: 'Salário Adicional',
          children: <FormSalarioAdicional />,
          icon: 'post_add',
        },
        {
          label: 'Salário Aula',
          children: <FormSalarioAula />,
          icon: 'school',
        },
        {
          label: 'Ambiente Trabalho',
          children: <FormAmbienteTrabalho />,
          icon: 'work',
        },
        {
          label: 'Horário de Trabalho',
          children: <FormHorarioTrabalho />,
          icon: 'query_builder',
        },
        {
          label: 'Parâmetros',
          children: <FormParametros />,
          icon: 'tune',
        },
        {
          label: 'Observação',
          children: <FormObservacao />,
          icon: 'message',
        },
      ]}
    >
      <Grid container spacing={0} style={{ marginBottom: '3rem' }}>
        <Grid item xs={1} md={2}>
          <StepperForm.Steps />
        </Grid>
        <Grid item xs={11} md={10}>
          <StepperForm.Content />
        </Grid>
      </Grid>
    </StepperForm.Provider>
  )
}
