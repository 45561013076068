import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'
import api from '~/services/api-pessoal'
import { indObservacaoConsts, indObservacaoValues } from '~/values/indObservacaoValues'
import { MUIAutoComplete } from '~/components/AutoComplete'

const { eSocial_4 } = indObservacaoConsts

const schema = yup.object().shape({
  indObservacao: yup.string().required('Informe o Indicador da Observação'),
  dtObservacao: yup
    .date()
    .required('Informe a Data da Observação')
    .typeError('Informe uma Data Válida'),
  observacao: yup.string().required('Informe a Observação'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, isFinalizado, origemId } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/VinculoObservacao/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/VinculoObservacao', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Observação"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicador da Observação"
            options={indObservacaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            validationErrors={validationErrors}
            required
            name="indObservacao"
            disabled={isFinalizado ? true : false}
            title={
              isFinalizado
                ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho ou Alteração Cadastral'
                : ''
            }
            value={data.indObservacao}
            onChange={(e, obj) => {
              const indObservacao = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                indObservacao,
                observacao: '',
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePicker
            label="Data da Observação"
            size="small"
            validationErrors={validationErrors}
            required
            name="dtObservacao"
            value={data?.dtObservacao || null}
            disabled={isFinalizado && data?.indObservacao === eSocial_4 ? true : false}
            title={
              isFinalizado && data?.indObservacao === eSocial_4
                ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho ou Alteração Cadastral'
                : ''
            }
            onChange={(date) => {
              const dtObservacao = date?.format('yyyy-MM-DD') || null
              setData((oldState) => ({
                ...oldState,
                dtObservacao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            validationErrors={validationErrors}
            required
            name="observacao"
            value={data?.observacao || ''}
            variant="outlined"
            size="small"
            multiline
            inputProps={{
              maxLength: data?.indObservacao === eSocial_4 ? 255 : 2000,
            }}
            disabled={isFinalizado && data?.indObservacao === eSocial_4 && !origemId ? true : false}
            title={
              isFinalizado && data?.indObservacao === eSocial_4 && !origemId
                ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho ou Alteração Cadastral'
                : ''
            }
            onChange={(e) => {
              const observacao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
