export enum TipoAcidenteTransitoEnum {
  Atropelamento_1 = 1,
  Colisao_2 = 2,
  Outros_3 = 3,
  NaoAplicavel_9 = 9,
}

export const TipoAcidenteTransitoValues = [
  { value: TipoAcidenteTransitoEnum.NaoAplicavel_9, name: '9 - Não informar' },
  { value: TipoAcidenteTransitoEnum.Atropelamento_1, name: '1 - Atropelamento' },
  { value: TipoAcidenteTransitoEnum.Colisao_2, name: '2 - Colisão' },
  { value: TipoAcidenteTransitoEnum.Outros_3, name: '3 - Outros' },
]
