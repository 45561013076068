import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

import { AtestadoSaudeOcupacional } from '../AtestadoSaudeOcupacional/AtestadoSaudeOcupacional'

interface RequestProps {
  data: AtestadoSaudeOcupacional[]
}

export function useImportarXML() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data }: RequestProps) {
    await api.post('/ImportarXMLS2220/ImportarXML', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Atestado Saúde Ocupacional importado com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
