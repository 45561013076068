import React, { useState, useEffect } from 'react'

import { Box, IconButton, Typography } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'

import {
  ActionDialog,
  ConfirmDeleteDialog,
  TreeView,
  PageHeader,
  Button,
  Finder,
  PDFViewer,
} from '~/components'

import FlowCreate from './FlowCreate'
import Table from './Table'

import { VinculoMenuItem, useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import { useAtestadoSaudeOcupacionalDelete } from '~/hooks/queries/AtestadoSaudeOcupacional/useAtestadoSaudeOcupacionalDelete'
import { useHistory } from 'react-router-dom'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { AtestadoSaudeOcupacionalPartial } from '~/hooks/queries/AtestadoSaudeOcupacional/AtestadoSaudeOcupacional'

const HEADER_HEIGHT = '70px'

export default function AtestadoSaudeOcupacionalNew() {
  const [query, setQuery] = useState('')
  const [vinculo, setVinculo] = useState<VinculoMenuItem | null>(null)

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closeFlowCreate,
    data: dataFlowCreate,
    isOpen: isOpenFlowCreate,
    open: openFlowCreate,
  } = useDialog<{
    data: AtestadoSaudeOcupacionalPartial
    isRetificar?: boolean
  }>({ data: {} })
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog('')

  const { mutateAsync, isLoading: isLoadingDelete } = useAtestadoSaudeOcupacionalDelete()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])
  const dialogNotification = useDialogNotification()

  const history = useHistory()

  useEffect(() => {
    setVinculo(null)
  }, [anoMes, estabelecimento])

  async function handleClickAdd() {
    const data = {
      vinculoId: vinculo?.vinculoId,
    }
    openFlowCreate({ data })
  }

  async function handleDeleteItem() {
    if (!dataConfirmDelete) return
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  const handleClickItem = (
    event: 'edit' | 'delete' | 'print',
    data: AtestadoSaudeOcupacionalPartial,
  ) => {
    const handleClickEditItem = () => {
      if (data.reciboEsocial)
        return dialogNotification.warning({
          descriptions: [
            'Esse evento já foi enviado para o eSocial, deseja fazer uma retificação?',
          ],
          labelOnCancel: 'Não',
          labelOnConfirm: 'Sim',
          onConfirm: () => openFlowCreate({ data, isRetificar: true }),
          onCancel: () => openFlowCreate({ data, isRetificar: false }),
        } as FixLater)
      openFlowCreate({ data, isRetificar: undefined })
    }

    const handleClickDeleteItem = () => {
      if (!data?.id) return
      openConfirmDelete(data.id)
    }

    function handlePrintItem() {
      if (!data?.id) return
      openPDFViewer(data.id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
      print: handlePrintItem,
    }
    functions[event]()
  }

  function handleChangeOldVersion() {
    history.push({
      pathname: '/atestado-saude-ocupacional-old',
    })
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e: FixLater, vinculo: VinculoMenuItem) => setVinculo(vinculo)}
      searchBy="vinculoNome"
      renderOption={(option: VinculoMenuItem) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Atestado Saúde Ocupacional (ASO)" subtitle={vinculo?.vinculoNome || '-'}>
          {vinculo?.vinculoId && (
            <ButtonBox>
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
              <IconButton
                size="small"
                onClick={handleChangeOldVersion}
                title="Retornar para versão antiga"
              >
                <ErrorOutlineIcon color="primary" />
              </IconButton>
              <Button size="small" color="primary" onClick={handleClickAdd} variant="contained">
                Adicionar
              </Button>
            </ButtonBox>
          )}
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        {vinculo ? (
          <Table query={query} onItemClick={handleClickItem} vinculoId={vinculo.vinculoId} />
        ) : (
          <Typography>Selecione um Funcionário</Typography>
        )}
      </Box>

      {isOpenFlowCreate ? (
        <ActionDialog
          isOpen={isOpenFlowCreate}
          onClose={closeFlowCreate}
          title="Cadastro - Atestado Saúde Ocupacional (ASO)"
          subtitle={vinculo?.vinculoNome || '-'}
          dialogProps={{ maxWidth: 'lg', fullWidth: true }}
          customActions={<></>}
        >
          <FlowCreate
            onCancel={closeFlowCreate}
            data={dataFlowCreate.data}
            isRetificar={dataFlowCreate.isRetificar}
          />
        </ActionDialog>
      ) : (
        <></>
      )}

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isLoadingDelete}
        onCancel={closeConfirmDelete}
        onConfirm={handleDeleteItem}
        description="Você quer realmente excluir este registro? As alterações que foram feitas não serão desfeitas!"
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        reportKey="ASO"
        onClose={closePDFViewer}
        title="Atestado Saúde Ocupacional (ASO)"
        axiosConfig={{
          method: 'get',
          url: '/AtestadoSaudeOcupacional/ObterRelatorio',
          params: {
            asoId: dataPDFViewer,
          },
        }}
      />
    </TreeView>
  )
}
