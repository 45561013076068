import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { HeightDataTable } from '~/components'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

const Table = (props) => {
  const { data: _data, onItemClick, isLoading } = props

  const data = _data.map((item) => {
    return {
      ...item,
      certificadoAvaliacao: item?.epi?.certificadoAvaliacao || '',
      descricao: item?.epi?.descricao || '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'certificadoAvaliacao',
        label: 'Certificado de Avaliação',
      },
      {
        name: 'descricao',
        label: 'Descrição',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      pagination={false}
      delayResize={501}
    />
  )
}

export default Table
