import moment from 'moment'

import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import { VinculoHorario } from '~/hooks/queries/VinculoHorario/VinculoHorario'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  data: VinculoHorario[]
  query: string
  onItemClick: (event: 'edit' | 'delete', id: string) => void
}

export default function Table(props: TableProps) {
  const { data: _data, query, onItemClick } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtIncio: item?.dtIncio ? moment(item.dtIncio).format('DD/MM/yyyy') : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtIncio',
      label: 'Data de Início',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      columns={columns}
      sherlock={{
        query,
        columns: ['dtIncio', 'observacao'],
      }}
    />
  )
}
