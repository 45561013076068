import { useEffect, useState } from 'react'

import moment from 'moment'
import { formatToCPF } from 'brazilian-values'

import { Box, Paper } from '@material-ui/core'

import {
  ContainerTable,
  PDFViewer,
  PrintButton,
  SimpleSelect,
  Stack,
  ToolsDataTable,
} from '~/components'
import { Ativo } from '~/components/Displays'

import { useObterRecibosFeriasColetiva } from '~/hooks/queries/FeriasColetiva/useObterRecibosFeriasColetiva'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import { formatCurrency } from '~/hooks/useUtils'

import { relatorioGetByChaveId } from '~/hooks/queries/Relatorio/relatorioGetByChaveId'

import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  TipoRelatorioFeriasColetivaEnum,
  TipoRelatorioFeriasColetivaValues,
} from '~/@types/enums/TipoRelatorioFeriasColetivaEnum'

export interface FeriasColetivaImprimirRequestDTO {
  recibosIds: string[]
  tipoRelatorio: TipoRelatorioFeriasColetivaEnum
  relatorioFeriasId: string
  relatorioAvisoId: string
}

export default function ImprimirFeriasColetiva() {
  const [rowsSelected, setRowsSelected] = useState([])
  const [dataForm, setDataForm] = useState({
    relatorioAvisoId: '',
    relatorioFeriasId: '',
    tipoRelatorio: TipoRelatorioFeriasColetivaEnum.ReciboFerias,
  })

  const { anoMes, estabelecimento } = useAmbiente()
  const {
    data: _data,
    isLoading,
    isFetching,
    refetch,
  } = useObterRecibosFeriasColetiva(estabelecimento.id, anoMes)
  const data = _data.map((d) => ({
    ...d,
    funcionarioNrInscricao: formatToCPF(d.funcionarioNrInscricao),
    funcionarioDtAdmissao: moment(d.funcionarioDtAdmissao).format('DD/MM/yyyy'),
    vrVencimento: formatCurrency(d.vrVencimento),
    vrDesconto: formatCurrency(d.vrDesconto),
    vrTotalLiquido: formatCurrency(d.vrTotalLiquido),
  }))
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<FeriasColetivaImprimirRequestDTO | null>(null)

  useEffect(() => {
    Promise.all([relatorioGetByChaveId('Ferias'), relatorioGetByChaveId('AvisoFerias')])
      .then((resultados) => {
        const relatoriosReciboFerias = resultados[0]
        const relatoriosAvisoFerias = resultados[1]

        const primeiroRelatorioReciboFerias = relatoriosReciboFerias[0]
        const primeiroRelatorioAvisoFerias = relatoriosAvisoFerias[0]

        setDataForm({
          relatorioFeriasId: primeiroRelatorioReciboFerias?.id || '',
          relatorioAvisoId: primeiroRelatorioAvisoFerias?.id || '',
          tipoRelatorio: TipoRelatorioFeriasColetivaEnum.ReciboFerias,
        })
      })
      .catch((error) => {
        console.error('Erro ao realizar requisições simultâneas:', error)
      })
  }, [])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'funcionarioNrInscricao',
      label: 'CPF',
    },
    {
      name: 'funcionarioNome',
      label: 'Nome',
    },
    {
      name: 'funcionarioDtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'vrVencimento',
      label: 'Vencimento',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrDesconto',
      label: 'Desconto',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrTotalLiquido',
      label: 'Líquido',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'nrRecibo',
      label: 'Recibo',
    },
    {
      name: 'reciboESocial',
      label: 'Recibo eSocial',
    },
    {
      name: 'isFinalizado',
      label: 'Finalizado',
      options: {
        customBodyRender: (value) => <Ativo ativo={value} />,
      },
    },
  ]

  function onClickPrint() {
    const recibosIds = rowsSelected.map((index) => _data[index].rpId)
    openPDFViewer({
      recibosIds,
      relatorioAvisoId: dataForm.relatorioAvisoId,
      relatorioFeriasId: dataForm.relatorioFeriasId,
      tipoRelatorio: dataForm.tipoRelatorio,
    })
    setRowsSelected([])
  }

  function onClosePDFViewer() {
    closePDFViewer()
    refetch()
  }

  return (
    <Stack height="100%">
      <Box component={Paper} p={1}>
        <SimpleSelect
          label="Tipo de Relatório"
          options={TipoRelatorioFeriasColetivaValues}
          renderOption={(opt: FixLater) => opt.name}
          optionId="value"
          value={dataForm.tipoRelatorio}
          onChange={(_: FixLater, tipoRelatorio: TipoRelatorioFeriasColetivaEnum) => {
            setDataForm((oldState) => ({
              ...oldState,
              tipoRelatorio,
            }))
          }}
        />
      </Box>

      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          optionsSelectable={{
            selectOnClick: true,
            type: 'multiple',
            rowsSelected: rowsSelected,
            onRowSelected: setRowsSelected,
          }}
          disableAutoHeight
        />
      </ContainerTable>

      <Box width="100%" display="flex" justifyContent="flex-end">
        <PrintButton onClick={onClickPrint} disabled={rowsSelected.length > 0 ? false : true} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        reportKey="Ferias"
        onClose={onClosePDFViewer}
        title="Férias Coletiva"
        axiosConfig={{
          method: 'post',
          url: '/FeriasColetiva/ImprimirFeriasColetiva',
          data: dataPDFViewer,
        }}
      />
    </Stack>
  )
}
