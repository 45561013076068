export enum AprendizIndContratacaoEnum {
  Contratacao_Direta = 1,
  Contratacao_Indireta = 2,
}

export const AprendizIndContratacaoValues = [
  {
    name: '1 - Contratação Direta',
    value: AprendizIndContratacaoEnum.Contratacao_Direta,
  },
  {
    name: '2 - Contratação Indireta',
    value: AprendizIndContratacaoEnum.Contratacao_Indireta,
  },
]
