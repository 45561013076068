import { useEffect, useState } from 'react'

import { Box, Grid, useTheme } from '@material-ui/core'

import { Button, PageHeader } from '~/components'

import CardEventos from './CardEventos'

import { useAtualizarEmpregadoresEsocial } from '~/hooks/queries/EmpregadorEsocial/useAtualizarEmpregadoresEsocial'

import { eventosSST } from '..'

import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'
import { EmpregadorEsocial } from '~/hooks/queries/EmpregadorEsocial/EmpregadorEsocial'

interface ConfigSeveralsProps {
  data: EmpregadorEsocial
}

export default function Conent(props: ConfigSeveralsProps) {
  const { data } = props

  const [eventosSelecionados, setEventosSelecionados] = useState<ESocialEventoEnum[]>([])

  const theme = useTheme()
  const { mutateAsync, isLoading } = useAtualizarEmpregadoresEsocial()

  useEffect(() => {
    setEventosSelecionados(data.evento)
  }, [data])

  function handleCancel() {
    setEventosSelecionados(data.evento)
  }

  async function handleSave() {
    const newData: EmpregadorEsocial = {
      ...data,
      evento: eventosSelecionados,
    }
    await mutateAsync([newData])
  }

  return (
    <Box p={1} height="100%" overflow="auto" paddingBottom={5}>
      <PageHeader title={data.empregador?.nome} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardEventos
            eventos={eventosSST}
            label="Eventos SST"
            value={eventosSelecionados}
            onChange={setEventosSelecionados}
          />
        </Grid>
      </Grid>

      <Box
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          left: 0,
          height: '50px',
          display: 'flex',
          gap: theme.spacing(1),
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: theme.spacing(2),
          paddingBottom: theme.spacing(1),
        }}
      >
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button variant="contained" isLoading={isLoading} onClick={handleSave}>
          Salvar Alterações
        </Button>
      </Box>
    </Box>
  )
}
