import React from 'react'
import clsx from 'clsx'

import { Box, IconButton, Drawer as MUIDrawer, makeStyles } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import PageHeader from '~/components/PageHeader'

import ActionsButtons from './ActionsButtons'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    minWidth: 300,

    display: 'flex',
    gap: theme.spacing(2),
  },
  sm: {
    width: '30vw',
  },
  md: {
    width: '40vw',
  },
  lg: {
    width: '50vw',
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(1),
  },
}))

type ActionDrawerSize = 'sm' | 'md' | 'lg'

interface ActionDrawerProps {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  onSubmit?: () => void
  title: string
  subtitle?: string
  size?: ActionDrawerSize
  customActions?: React.ReactNode
}

export default function ActionDrawer(props: ActionDrawerProps) {
  const { children, isOpen, onClose, onSubmit, title, subtitle, size = 'sm', customActions } = props

  const classes = useStyles()

  return (
    <MUIDrawer
      classes={{
        paper: clsx(classes.paper, classes[size]),
      }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Box height={34}>
        <PageHeader title={title} subtitle={subtitle}>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </PageHeader>
      </Box>
      <Box className={classes.content}>{children}</Box>
      {customActions ? (
        customActions
      ) : (
        <ActionsButtons onCancel={onClose} onSubmit={() => onSubmit && onSubmit()} />
      )}
    </MUIDrawer>
  )
}
