import { Icon, makeStyles, Tooltip, useTheme } from '@material-ui/core'

import { TaskQueueStatusEnum } from '~/@types/enums/TaskQueueStatusEnum'

const useStyles = makeStyles({
  pulse: {
    animation: `$pulse 2s infinite`,
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      filter: 'brightness(1)',
    },
    '25%': {
      transform: 'scale(1.01)',
      filter: 'brightness(0.9)',
    },
    '50%': {
      transform: 'scale(1.02)',
      filter: 'brightness(0.8)',
    },
    '75%': {
      transform: 'scale(1.01)',
      filter: 'brightness(0.9)',
    },
    '100%': {
      transform: 'scale(1)',
      filter: 'brightness(1)',
    },
  },
})

interface IconTaskProps {
  status: TaskQueueStatusEnum | null
  icone: string
  descricao: string
}

export default function IconTask({ status, icone, descricao }: IconTaskProps) {
  const classes = useStyles()
  const theme = useTheme()

  function changeColor(status: TaskQueueStatusEnum | null) {
    switch (status) {
      case TaskQueueStatusEnum.Pendente:
        return theme.palette.action.active
      case TaskQueueStatusEnum.Fila:
        return theme.palette.info.main
      case TaskQueueStatusEnum.Processando:
        return theme.palette.primary.main
      case TaskQueueStatusEnum.Reagendar:
        return theme.palette.warning.main
      case TaskQueueStatusEnum.Concluido:
        return theme.palette.success.main
      case TaskQueueStatusEnum.Erro:
        return theme.palette.error.main
      default:
        return theme.palette.action.disabled
    }
  }

  return (
    <Tooltip title={descricao} arrow>
      <Icon
        className={status === TaskQueueStatusEnum.Processando ? classes.pulse : undefined}
        fontSize="small"
        style={{
          color: changeColor(status),
        }}
      >
        {icone}
      </Icon>
    </Tooltip>
  )
}
