import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { ValeTransporteApuracaoUpdateDTO } from './ValeTransporteApuracaoDTO'

import { queryObterApuracaoEstabelecimento } from './useObterApuracaoEstabelecimento'

export function useValeTransporteApuracaoAtualizar() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest(data: ValeTransporteApuracaoUpdateDTO) {
    await api.put('/ValeTransporteApuracao', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notification.put()
      queryClient.invalidateQueries(queryObterApuracaoEstabelecimento)
    },
    onError: dialogNotification.extractErrors,
  })
}
