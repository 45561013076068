import { useState, useRef } from 'react'

import { formatToCPF } from 'brazilian-values'
import moment from 'moment'

import { Box, Paper, useTheme } from '@material-ui/core'

import { Button, CopyLabel, Finder, ToolsDataTable } from '~/components'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import useObterFaltasImportadas from '~/hooks/queries/ImportacaoPlanilhaFaltas/useObterFaltasImportadas'
import useRemoverFaltasImportadas from '~/hooks/queries/ImportacaoPlanilhaFaltas/useRemoverFaltasImportadas'

import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  IndLancamentoFaltaEnum,
  IndLancamentoFaltaLabel,
} from '~/@types/enums/IndLancamentoFaltaEnum'

export default function Importadas() {
  const [query, setQuery] = useState('')

  const tableRef = useRef<FixLater>(null)
  const theme = useTheme()

  const { anoMes, estabelecimento } = useAmbiente()
  const dialogNotification = useDialogNotification()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterFaltasImportadas({
    anoMes,
    estabelecimentoId: estabelecimento.id,
  })
  const data = _data || []

  const { mutateAsync, isLoading: isSubmitting } = useRemoverFaltasImportadas()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'funcionarioCPF',
      label: 'CPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      name: 'funcionarioNome',
      label: 'Nome',
    },
    {
      name: 'dtFalta',
      label: 'Data',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
      },
    },
    {
      name: 'indLancamento',
      label: 'Tipo',
      options: {
        customBodyRender: (value: IndLancamentoFaltaEnum) => IndLancamentoFaltaLabel[value],
      },
    },
    {
      name: 'qtdHoras',
      label: 'Atraso em Horas',
      options: {
        customBodyRender: (value) => (value ? moment(value, 'HH:mm:ss').format('HH:mm') : ''),
      },
    },
    {
      name: 'isDSR',
      label: 'DSR',
      options: {
        customBodyRender: (value) => (value ? 'Sim' : 'Não'),
      },
    },
  ]

  async function handleSubmit() {
    const rowsSelected: number[] = tableRef?.current?.state?.selectedRows?.data.map(
      (d: FixLater) => d.dataIndex,
    )

    if (rowsSelected.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe as Faltas/Atrasos que você deseja desfazer a importação'],
      })
    }

    await mutateAsync(rowsSelected.map((index) => data[index].faltaId))
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <Box display="flex" justifyContent="flex-end" alignItems="center" component={Paper} p={1}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </Box>

      <Box flex={1} position="relative" overflow="auto" minHeight={300}>
        <Box position="absolute" width="100%">
          <ToolsDataTable
            columns={columns}
            data={data}
            isFetching={isFetching}
            isLoading={isLoading}
            disableAutoHeight
            sherlock={{
              columns: ['funcionarioCPF', 'funcionarioNome'],
              query,
            }}
            optionsSelectable={{
              type: 'multiple',
            }}
            tableRef={tableRef}
          />
        </Box>
      </Box>

      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={handleSubmit} isLoading={isSubmitting}>
          Desfazer Importações
        </Button>
      </Box>
    </Box>
  )
}
