const sindicato = 1
const federacao = 2
const confederacao = 3
const CEES = 4

export const indEntidadeSindicalValues = [
  { value: sindicato, name: '1 - Sindicato' },
  { value: federacao, name: '2 - Federação' },
  { value: confederacao, name: '3 - Confederação' },
  { value: CEES, name: '4 - CEES' },
]

export const indEntidadeSindicalConsts = {
  sindicato,
  federacao,
  confederacao,
  CEES,
}
