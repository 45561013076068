import clsx from 'clsx'

import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
  selected: {
    backgroundColor: theme.palette.grey[700],
  },
  unselected: {
    backgroundColor: theme.palette.grey[500],
  },
}))

interface ListItemChildrenIconProps {
  isSelected: boolean
}

export default function ListItemChildrenIcon(props: ListItemChildrenIconProps) {
  const { isSelected } = props

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box
        className={clsx([
          classes.icon,
          {
            [classes.selected]: isSelected,
            [classes.unselected]: !isSelected,
          },
        ])}
      ></Box>
    </Box>
  )
}
