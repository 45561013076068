import React, { useState } from 'react'

import { Finder } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { CurrencyTextField, HeightDataTable, PageHeader } from '~/components'

import { useQuery, atualizarOrdemImpressao } from '~/hooks/queries/useEventos'

import { indEventoValuesWithoutInd } from '~/values/indEventoValues'
import { useEffect } from 'react'

function formatIndEvento(indEvento) {
  if (!indEvento) return ''
  return indEventoValuesWithoutInd.find((obj) => obj.value === indEvento).name
}

export default function OrdemImpressaoEvento() {
  const [query, setQuery] = useState('')
  const [data, setData] = useState([])

  const { data: _data, isLoading, isFetching } = useQuery()

  useEffect(() => {
    if (!Array.isArray(_data)) return
    if (!(_data.length > 0)) return
    const newData = _data.map((dt) => ({ ...dt, indEvento: formatIndEvento(dt?.indEvento) }))
    setData(newData)
  }, [_data])

  const columns = [
    {
      label: 'Id',
      name: 'id',
      options: {
        display: 'excluded',
      },
    },
    {
      label: 'Código',
      name: 'codigo',
    },
    {
      label: 'Nome',
      name: 'nome',
    },
    {
      label: 'Indicador do Evento',
      name: 'indEvento',
    },
    {
      label: 'Ordem de impressão',
      name: 'ordemImpressao',
      options: {
        customBodyRender: (ordemImpressao, { rowData }) => {
          const id = rowData[0]
          return (
            <CurrencyTextField
              value={ordemImpressao || 0}
              onBlur={(e, value) => {
                const newValue = value || 0
                if (newValue === ordemImpressao) return
                atualizarOrdemImpressao(id, newValue)
                const newData = data.map((d) => {
                  if (d?.id === id) {
                    return {
                      ...d,
                      ordemImpressao: newValue,
                    }
                  }
                  return d
                })
                setData(newData)
              }}
              decimalPlaces={0}
              decimalPlacesShownOnFocus={0}
              minimumValue="0"
            />
          )
        },
        setCellHeaderProps: () => ({
          style: { width: '150px' },
        }),
      },
    },
  ]

  return (
    <Box height="100%" width="100%" p={2}>
      <Box height="50px">
        <PageHeader title="Ordem de Impressão Evento">
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </PageHeader>
      </Box>
      <HeightDataTable
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        sherlock={{
          columns: ['codigo', 'nome', 'indEvento'],
          query,
        }}
        options={{
          customSort: (data, colIndex, order) => {
            return data.sort((a, b) => {
              const dataA = a.data[colIndex]
              const dataB = b.data[colIndex]
              return (dataA === 0 ? 0 : dataA < dataB ? -1 : 1) * (order === 'desc' ? 1 : -1)
            })
          },
          sortOrder: {
            name: 'codigo',
            direction: 'desc',
          },
        }}
      />
    </Box>
  )
}
