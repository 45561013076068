const semClassificacao = 0
const falta = 1
const atraso = 2
const horaExtra = 3
const horaNoturna = 4
const horaExtraNoturna = 5
const planoSaude = 6
const emprestimo = 7
const bancoDeHoras = 9

export const indClassficacaoEventoValues = [
  { value: semClassificacao, name: '0 - Sem Classificação' },
  { value: falta, name: '1 - Falta' },
  { value: atraso, name: '2 - Atraso' },
  { value: horaExtra, name: '3 - Hora Extra' },
  { value: horaNoturna, name: '4 - Hora Noturna' },
  { value: horaExtraNoturna, name: '5 - Hora Extra Noturna' },
  { value: planoSaude, name: '6 - Plano de Saúde/Odontológico' },
  { value: emprestimo, name: '7 - Empréstimo' },
  { value: bancoDeHoras, name: '9 - Banco de Horas' },
]

export const indClassficacaoEventoConsts = {
  semClassificacao,
  falta,
  atraso,
  horaExtra,
  horaNoturna,
  horaExtraNoturna,
  planoSaude,
  emprestimo,
  bancoDeHoras,
}
