import React, { useMemo } from 'react'

import { makeStyles } from '@material-ui/core'
import { ActionDialog } from '~/components'
import { HeightDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'

const useStyles = makeStyles(() => ({
  aliengRight: { textAlign: 'right' },
  heightPaper: {
    height: '100%',
  },
}))

const formatIndLancamento = (value) => {
  const maps = {
    1: 'Mensal',
    2: 'Férias Concedidas',
    3: 'Férias Rescisão',
    4: 'Alteração Salarial',
    5: 'Ajuste De Saldo',
    6: 'Baixa 13º Salário',
    7: '13º Salário Rescisão',
    8: 'Transferência por Saída',
    9: 'Transferência por Entrada',
  }
  return maps[value]
}

const Table = (props) => {
  const { isOpen, onClose, data: _data, vinculo } = props

  const classes = useStyles()

  const { formatCurrency, formatAnoMes } = useUtils()

  const data = _data.map((item) => {
    return {
      ...item,
      anoMes: item?.provisaoCompetencia?.anoMes
        ? formatAnoMes(item.provisaoCompetencia.anoMes)
        : null,
      vrSalarioContratual: item?.vrSalarioContratual
        ? formatCurrency(item.vrSalarioContratual)
        : null,
      vrSalarioAdicional: item?.vrSalarioAdicional ? formatCurrency(item.vrSalarioAdicional) : null,
      vrSalarioVariavel: item?.vrSalarioVariavel ? formatCurrency(item.vrSalarioVariavel) : null,
      vrBaseProvisao: item?.vrBaseProvisao ? formatCurrency(item.vrBaseProvisao) : null,
      vrProvisao: item?.vrProvisao ? formatCurrency(item.vrProvisao) : null,
      vrContribuicaoPrevidenciaria: item?.vrContribuicaoPrevidenciaria
        ? formatCurrency(item.vrContribuicaoPrevidenciaria)
        : null,
      vrContribuicaoTerceiros: item?.vrContribuicaoTerceiros
        ? formatCurrency(item.vrContribuicaoTerceiros)
        : null,
      vrFGTS: item?.vrFGTS ? formatCurrency(item.vrFGTS) : null,
      vrPIS: item?.vrPIS ? formatCurrency(item.vrPIS) : null,
      indLancamento: item?.indLancamento ? formatIndLancamento(item.indLancamento) : null,
      lotacaoCodigo: item?.lotacao?.codigo ? item.lotacao.codigo : null,
      lotacaoNome: item?.lotacao?.nome ? item.lotacao.nome : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'anoMes',
        label: 'Ano/Mês',
      },
      {
        name: 'vrSalarioContratual',
        label: 'Salário Contratual',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'vrSalarioAdicional',
        label: 'Salário Adicional',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'vrSalarioVariavel',
        label: 'Salário Variável',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'vrBaseProvisao',
        label: 'Base Provisão',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'vrProvisao',
        label: 'Provisão',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'vrContribuicaoPrevidenciaria',
        label: 'Contribuição Previdenciária',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'vrContribuicaoTerceiros',
        label: 'Contribuição Terceiros',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'vrFGTS',
        label: 'FGTS',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'vrPIS',
        label: 'PIS',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'indLancamento',
        label: 'Ind. Lançamento',
      },
      {
        name: 'lotacaoCodigo',
        label: 'Lotação',
      },
      {
        name: 'lotacaoNome',
        label: 'Lotação/Nome',
      },
    ],
    [classes],
  )
  return (
    <ActionDialog
      title="Provisão de 13° Salário"
      subtitle={vinculo?.pessoaFisica?.nome || ''}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      customActions={<></>}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <HeightDataTable title="" data={data} columns={columns} />
    </ActionDialog>
  )
}

export default Table
