import React from 'react'

import { FormControl, FormControlLabel, Switch as MuiSwitch } from '@material-ui/core'

const Switch = ({ label, ...rest }) => {
  return (
    <FormControl component="fieldset" fullWidth>
      <FormControlLabel
        label={label}
        labelPlacement="bottom"
        control={<MuiSwitch size="small" name="ativo" color="primary" {...rest} />}
      ></FormControlLabel>
    </FormControl>
  )
}

export default Switch
