import React, { useState, useEffect } from 'react'

import api from '~/services/api-pessoal'

import { ButtonBox } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'
import { PageHeader, Button } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

import { IndSalarioContratualEnum } from '~/@types/enums/IndSalarioContratualEnum'

const HEADER_HEIGHT = '50px'

interface VinculoSalarioContratualSimpleProps {
  vinculoId: string
}

export default function VinculoSalarioContratualSimple(props: VinculoSalarioContratualSimpleProps) {
  const { vinculoId } = props

  const [collection, setCollection] = useState<{ isLoading: boolean; itens: FixLater[] }>({
    isLoading: false,
    itens: [],
  })
  const [isLoadingDelete, setLoadingDelete] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string | null>(null)
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<FixLater>({})

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(`/vinculoSalarioBase/GetByVinculo/${vinculoId}`)
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [vinculoId])

  const handleClickItem = (event: 'edit' | 'delete', id: string) => {
    const handleClickEditItem = (id: string) => {
      const { itens } = collection
      const item = itens.find((i) => i.id === id)
      openForm(item)
    }

    const handleClickDeleteItem = (id: string) => {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](id)
  }

  const handleDeleteItem = async () => {
    setLoadingDelete(true)
    try {
      await api.delete(`/vinculoSalarioBase/${dataConfirmDelete}`)
      const newItens = collection.itens.filter((i) => i.id !== dataConfirmDelete)
      closeConfirmDelete()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  const handleAfterSubmitForm = (event: 'insert' | 'update', value: FixLater) => {
    const handleAfterInsert = (data: FixLater) => {
      const { itens } = collection
      const newItens = [data, ...itens]
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      closeForm()
    }

    const handleAfterUpdate = (data: FixLater) => {
      const { itens } = collection
      const newItens = itens.map((i) => (i.id === data.id ? data : i))
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      closeForm()
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    functions[event](value)
  }

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            <Button
              size="small"
              color="primary"
              onClick={() =>
                openForm({ vinculoId: vinculoId, indSalario: IndSalarioContratualEnum.Valor })
              }
              variant="contained"
            >
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          onItemClick={handleClickItem}
        />
      </Box>

      <Form
        isOpen={isOpenForm}
        data={dataForm}
        onClose={closeForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isLoadingDelete}
        onCancel={closeConfirmDelete}
        onConfirm={handleDeleteItem}
      />
    </>
  )
}
