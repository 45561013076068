import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { RelCompensacaoHorasTrabalhadasJornadaMainDTO } from './dtos/RelCompensacaoHorasTrabalhadasJornadaMainDTO'

const query = '/Relatorio/CompensacaoHorasTrabalhadas/ObterHorarioTrabalhoFuncionario'

interface RequestProps {
  params: {
    vinculoId: string
  }
}

export function useObterHorarioTrabalhoFuncionario({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: RelCompensacaoHorasTrabalhadasJornadaMainDTO }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
