import { useState, useMemo, useEffect } from 'react'

import moment from 'moment'

import { formatToCPF } from 'brazilian-values'

import { Box, IconButton, Paper } from '@material-ui/core'
import { People as PeopleIcon, Category as CategoryIcon } from '@material-ui/icons'

import {
  Button,
  ButtonBox,
  ContainerTable,
  CopyLabel,
  DragDropFile,
  Finder,
  PageHeader,
  StackContainer,
  ToolsDataTable,
} from '~/components'

import DialogResponsaveis from './DialogResponsaveis'
import DialogAgentesNocivos from './DialogAgentesNocivos'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useExtrairXML } from '~/hooks/queries/ImportarXMLS2240/useExtrairXML'
import { useImportarXML } from '~/hooks/queries/ImportarXMLS2240/useImportarXML'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ImportarXMLS2240ItemDTO } from '~/hooks/queries/ImportarXMLS2240/dtos/ImportarXMLS2240DataDTO'
import {
  CondicaoAmbienteLocalAmbienteEnum,
  CondicaoAmbienteLocalAmbienteLabels,
} from '~/@types/enums/CondicaoAmbienteLocalAmbienteEnum'
import { agNoc, respReg } from '~/hooks/queries/ImportarXMLS2240/dtos/evtExpRisco'

export default function AmbienteTrabalhoImportacao() {
  const [formData, setFormData] = useState<FormData | null>(null)
  const [query, setQuery] = useState('')
  const [_data, setData] = useState<ImportarXMLS2240ItemDTO[]>([])
  const data = useMemo(
    () =>
      _data.map((d) => ({
        ...d,
        dtInicio: d.evento.infoExpRisco.dtIniCondicao,
        localAmbiente: d.evento.infoExpRisco.infoAmb[0].localAmb,
        descricaoSetor: d.evento.infoExpRisco.infoAmb[0].dscSetor,
      })),
    [_data],
  )

  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()

  const {
    close: closeDialogResponsaveis,
    data: dataDialogResponsaveis,
    isOpen: isOpenDialogResponsaveis,
    open: openDialogResponsaveis,
  } = useDialog<respReg[]>([])
  const {
    close: closeDialogAgentesNocivos,
    data: dataDialogAgentesNocivos,
    isOpen: isOpenDialogAgentesNocivos,
    open: openDialogAgentesNocivos,
  } = useDialog<agNoc[]>([])

  const { mutateAsync: mutateAsyncExtrair, isLoading: isLoadingExtrair } = useExtrairXML()
  const { mutateAsync: mutateAsyncImportar, isLoading: isLoadingImportar } = useImportarXML()

  useEffect(() => {
    setData([])
    setFormData(null)
  }, [estabelecimento, anoMes])

  async function handleDropped(files: File[]) {
    const formData = new FormData()

    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      formData.append('files', file)
    }

    const _data = await mutateAsyncExtrair({
      data: formData,
      params: {
        estabelecimentoId: estabelecimento.id,
      },
    })
    if (_data.alertas.length > 0) {
      dialogNotification.warning({
        title: 'Ocorreu alguns problemas ao importar o arquivo',
        descriptions: _data.alertas,
      })
    }
    setData(_data.data)
    setFormData(formData)
  }

  async function handleImport() {
    if (!formData) return
    const dt = await mutateAsyncImportar({
      data: formData,
      params: {
        estabelecimentoId: estabelecimento.id,
      },
    })
    if (dt.length > 0) {
      dialogNotification.warning({
        title: 'Ocorreu alguns problemas ao importar o arquivo',
        descriptions: dt,
      })
    }
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'CPF',
      name: 'funcionarioNrInscricao',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      label: 'Nome',
      name: 'funcionarioNome',
    },
    {
      label: 'Data Início',
      name: 'dtInicio',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
      },
    },
    {
      label: 'Tipo Local',
      name: 'localAmbiente',
      options: {
        customBodyRender: (value: CondicaoAmbienteLocalAmbienteEnum) =>
          CondicaoAmbienteLocalAmbienteLabels[value],
      },
    },
    {
      label: 'Descrição Setor',
      name: 'descricaoSetor',
    },
    {
      name: 'funcionarioId',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Responsáveis"
                color="primary"
                aria-label="abrir-responsaveis"
                onClick={() => {
                  const dtAtual = _data[rowIndex]
                  if (dtAtual) {
                    openDialogResponsaveis(dtAtual.evento.infoExpRisco.respReg)
                  }
                }}
              >
                <PeopleIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Agentes Nocivos"
                color="primary"
                aria-label="abrir-agentes-nocivos"
                onClick={() => {
                  const dtAtual = _data[rowIndex]
                  if (dtAtual) {
                    openDialogAgentesNocivos(dtAtual.evento.infoExpRisco.agNoc)
                  }
                }}
              >
                <CategoryIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <StackContainer>
      <PageHeader title="Condição Ambiente de Trabalho - Importação">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} p={2}>
        <DragDropFile
          onDropped={handleDropped}
          acceptFiles={['.xml']}
          maxFiles={20}
          isLoading={isLoadingExtrair}
        />
      </Box>

      <ContainerTable>
        <ToolsDataTable
          data={data}
          isLoading={isLoadingExtrair}
          columns={columns}
          disableAutoHeight
          sherlock={{
            query,
            columns: ['pessoaFisicaCPF', 'pessoaFisicaNome'],
          }}
        />
      </ContainerTable>

      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={formData === null ? true : false}
          isLoading={isLoadingImportar}
          onClick={handleImport}
        >
          Importar
        </Button>
      </Box>

      {isOpenDialogResponsaveis && (
        <DialogResponsaveis
          isOpen={isOpenDialogResponsaveis}
          onClose={closeDialogResponsaveis}
          data={dataDialogResponsaveis}
        />
      )}
      {isOpenDialogAgentesNocivos && (
        <DialogAgentesNocivos
          isOpen={isOpenDialogAgentesNocivos}
          onClose={closeDialogAgentesNocivos}
          data={dataDialogAgentesNocivos}
        />
      )}
    </StackContainer>
  )
}
