import { memo } from 'react'

import { Badge, Box, CircularProgress, IconButton, Typography, useTheme } from '@material-ui/core'
import { Assignment, CheckCircle, Info } from '@material-ui/icons'

import { LinearProgressLabel } from '~/components'

import DialogLog from './DialogLog'

import useDialog from '~/hooks/useDialog'

import {
  CompetenciaProcessamentoContent,
  CompetenciaProcessamentoStatusEnum,
} from '~/hooks/queries/Competencia/CompetenciaProcessamentoContent'

const MemoDialogLog = memo(DialogLog)

interface LinearProgressChartProps {
  isProcessando: boolean
  progress: number
  total: number
  messageCurrent: string
  logs: CompetenciaProcessamentoContent[]
  disabledButtonLogs: boolean
}

export default function LinearProgressChart({
  isProcessando,
  progress,
  total,
  messageCurrent,
  logs,
  disabledButtonLogs = false,
}: LinearProgressChartProps) {
  const theme = useTheme()
  const {
    close: closeDialogLog,
    isOpen: isOpenDialogLog,
    data: dataDialogLog,
    open: openDialogLog,
  } = useDialog<CompetenciaProcessamentoContent[]>([])

  return (
    <Box display="flex" gridGap={theme.spacing(0.5)} flexDirection="column" width="100%">
      <Box display="flex" gridGap={theme.spacing(1)} justifyContent="space-between" width="100%">
        <Box
          display="flex"
          gridGap={theme.spacing(1)}
          alignItems="center"
          width="calc(100% - 30px)"
        >
          {isProcessando ? (
            <>
              <Box>
                <CircularProgress size={20} />
              </Box>
              <Typography>{progress + '/' + total}</Typography>
              <Typography>-</Typography>
              <Typography
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                title={messageCurrent}
              >
                {messageCurrent}
              </Typography>
            </>
          ) : logs.length > 0 ? (
            <>
              <CheckCircle fontSize="small" style={{ color: theme.palette.success.main }} />
              <Typography>Processamento Concluído</Typography>
            </>
          ) : (
            <>
              <Info color="primary" fontSize="small" />
              <Typography>Aguardando</Typography>
            </>
          )}
        </Box>

        {!disabledButtonLogs && (
          <Box>
            <Badge
              badgeContent={
                logs.filter((d) => d.status === CompetenciaProcessamentoStatusEnum.Erro).length
              }
              color="error"
            >
              <IconButton
                title="Logs Processamento"
                size="small"
                color="primary"
                onClick={() => openDialogLog(logs)}
              >
                <Assignment />
              </IconButton>
            </Badge>
          </Box>
        )}
      </Box>
      <LinearProgressLabel progress={total > 0 ? (progress * 100) / total : 0} />
      {isOpenDialogLog && (
        <MemoDialogLog isOpen={isOpenDialogLog} logs={dataDialogLog} onClose={closeDialogLog} />
      )}
    </Box>
  )
}
