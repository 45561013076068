import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { AutoCompleteEPI } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

const schema = yup.object().shape({
  epiId: yup.string().required('Informe o EPI'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, agenteNocivo } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/CargoAgenteNocivoEPI/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/CargoAgenteNocivoEPI', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de EPI do Cargo Agente Nocivo"
      subtitle={agenteNocivo?.id ? `${agenteNocivo.id} - ${agenteNocivo.nome}` : '-'}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <AutoCompleteEPI
            name="epiId"
            validationErrors={validationErrors}
            required
            value={data?.epi || ''}
            onChange={(e, value) =>
              setData((oldState) => ({
                ...oldState,
                epi: value || null,
                epiId: value?.id || '',
              }))
            }
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
