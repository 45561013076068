import React, { useState } from 'react'

import { TextField } from 'mio-library-ui'
import ActionDialog from '~/components/ActionDialog'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import * as yup from 'yup'
import api from '~/services/api-pessoal'

const schema = yup.object().shape({
  descricao: yup.string().required('Informe uma Descrição'),
})

export default function Form(props) {
  const { isOpen, onClose, handleAfterSubmit } = props

  const [validationErrors, setValidationErrors] = useState(false)
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  function handleSubmit() {
    async function insert() {
      setLoading(true)
      try {
        const response = await api.post('/Servico', data)
        notification.post()
        handleAfterSubmit(response?.data?.data || {})
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }

    setValidationErrors(false)
    schema
      .validate(data, { abortEarly: false })
      .then(insert)
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <ActionDialog
      title="Cadastro de Serviço"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onCancelClick={onClose}
      onOkClick={handleSubmit}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <TextField
        label="Descrição do Serviço"
        name="descricao"
        validationErrors={validationErrors}
        required
        variant="outlined"
        size="small"
        fullWidth
        multiline
        rows={2}
        inputProps={{
          maxLength: 100,
        }}
        value={data?.descricao || ''}
        autoFocus
        onChange={(e) => {
          const descricao = e?.target?.value || ''
          setData((oldState) => ({
            ...oldState,
            descricao,
          }))
        }}
      />
    </ActionDialog>
  )
}
