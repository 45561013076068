import api from '~/services/api-pessoal'

import { RescisaoComplementarFuncionarioDTO } from './RescisaoComplementarDTO'

export default async function obterCalculoFuncionarioRescisao(funcionarioId: string) {
  const response = await api.get<{ data: RescisaoComplementarFuncionarioDTO }>(
    '/RescisaoComplementar/ObterCalculoFuncionarioRescisao',
    {
      params: {
        funcionarioId,
      },
    },
  )
  return response?.data?.data || []
}
