import moment from 'moment'
import { formatToCPF } from 'brazilian-values'

import { IconButton } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import { AntecipacaoSalarialFuncionarioDTO } from '~/hooks/queries/AntecipacaoSalarial/AntecipacaoSalarialDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatCurrency, formatPrettyAnoMes } from '~/hooks/useUtils'

interface TableProps {
  data: AntecipacaoSalarialFuncionarioDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
  rowsSelected: number[]
  onRowSelected: (index: number[]) => void
  onEditItem: (id: string) => void
}

export default function Table(props: TableProps) {
  const { data: _d, isLoading, isFetching, query, rowsSelected, onRowSelected, onEditItem } = props

  const data = _d.map((d) => ({
    ...d,
    nrInscricao: formatToCPF(d.nrInscricao),
    dtAdmissao: d?.dtAdmissao ? moment(d.dtAdmissao).format('DD/MM/yyyy') : '',
    anoMesInicio: d.anoMesInicio ? formatPrettyAnoMes(d.anoMesInicio) : '',
    anoMesFim: d.anoMesFim ? formatPrettyAnoMes(d.anoMesFim) : '',
    percentual: d.percentual ? formatCurrency(d.percentual) : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'dtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'anoMesInicio',
      label: 'Ano/Mês Início',
    },
    {
      name: 'anoMesFim',
      label: 'Ano/Mês Fim',
    },
    {
      name: 'percentual',
      label: 'Percentual',
      options: {
        customBodyRender: (value) => (value ? value + ' %' : ''),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'antecipacaoId',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => onEditItem(value)}
                disabled={value ? false : true}
              >
                <EditIcon fontSize="small" color="inherit" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        columns: ['nrInscricao', 'nome'],
        query,
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected,
        rowsSelected,
      }}
    />
  )
}
