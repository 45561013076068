import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Box, Grid, IconButton, Collapse, makeStyles, Typography } from '@material-ui/core'
import { Button, ButtonBox, Finder, PageHeader } from 'mio-library-ui'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { AlertContainer } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import Table from './components/Table'
import { eSocialEventoValues, eSocialEventoConsts } from '~/values/eSocialEventoValues'

const { S_2210, S_2220, S_2240 } = eSocialEventoConsts
const eSocialEventsToFilter = [S_2210, S_2220, S_2240]

const eSocialEventoValuesFiltred = eSocialEventoValues.filter((obj) =>
  eSocialEventsToFilter.includes(obj.value),
)

const useStyles = makeStyles(() => ({
  alignSelf: {
    alignSelf: 'center',
  },
}))

export default function ExclusaoEventosESocialSST() {
  const [isLoading, setLoading] = useState(false)
  const [isLoadingProcess, setLoadingProcess] = useState(false)
  const [collection, setCollection] = useState([])
  const [indexsSelecteds, setIndexsSelecteds] = useState([])
  const [query, setQuery] = useState('')
  const [data, setData] = useState({ esocialEvento: '' })
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)

  const dialogNotification = useDialogNotification()
  const { anoMes, empregador } = useAmbiente()
  const notification = useNotification()
  const history = useHistory()
  const classes = useStyles()

  function resetFields() {
    setCollection([])
    setIndexsSelecteds([])
  }

  async function getCollection() {
    if (!data?.esocialEvento) return notification.error('Informe o Tipo do Evento')
    setLoading(true)
    try {
      const response = await api.get('/ESocial/GetEventoEsocialTransmitido', {
        params: {
          empregadorId: empregador?.id,
          anoMes,
          esocialEvento: data.esocialEvento,
        },
      })
      setCollection(response?.data?.data || [])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  async function handleProcess() {
    setLoadingProcess(true)
    try {
      const collectionSelected = indexsSelecteds.map((indexCurrent) => collection[indexCurrent])
      await api.post('/ESocial/GerarEventoExclusaoEsocial', collectionSelected, {
        params: {
          empregadorId: empregador?.id,
          anoMes,
          esocialEvento: data.esocialEvento,
        },
      })
      resetFields()
      notification.success('Eventos excluídos com Sucesso')
      history.push('/home')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingProcess(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box>
        <PageHeader title="Exclusão de Eventos">
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
              {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
            </IconButton>
          </ButtonBox>
        </PageHeader>
        <Collapse in={formCollapseIsOpen}>
          <Box pb={1}>
            <Grid container spacing={1}>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className={classes.alignSelf}>
                <MUIAutoComplete
                  label="Tipo de Evento"
                  value={data.esocialEvento}
                  onChange={(e, obj) => {
                    resetFields()
                    setData((oldState) => ({
                      ...oldState,
                      esocialEvento: obj ? obj.value : '',
                    }))
                  }}
                  options={eSocialEventoValuesFiltred}
                  renderOption={(option) => option.name}
                  optionId="value"
                />
              </Grid>

              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <AlertContainer>
                  <Grid container spacing={1}>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className={classes.alignSelf}>
                      <Typography>
                        {indexsSelecteds.length > 0 ? (
                          <>
                            Eventos selecionados: <strong>{indexsSelecteds.length}</strong>/
                            {collection.length}
                          </>
                        ) : (
                          'Selecione os eventos para prosseguir'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12} className={classes.alignSelf}>
                      <ButtonBox>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={getCollection}
                          isLoading={isLoading}
                        >
                          Buscar Eventos
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleProcess}
                          isLoading={isLoadingProcess}
                          disabled={!(indexsSelecteds.length > 0) ? true : false}
                        >
                          Excluir Eventos
                        </Button>
                      </ButtonBox>
                    </Grid>
                  </Grid>
                </AlertContainer>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>
      <Table
        data={collection}
        isLoading={isLoading}
        query={query}
        onRowSelected={setIndexsSelecteds}
        rowsSelected={indexsSelecteds}
        triggers={{ formCollapseIsOpen }}
      />
    </Box>
  )
}
