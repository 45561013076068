import React, { useEffect } from 'react'

import { CircularProgress, InputAdornment } from '@material-ui/core'

import useAddressByCEP from '~/hooks/useAddressByCEP'

import MaskedTextField from '../MaskedTextField'

import PropTypes from 'prop-types'

const CEPTextField = (props) => {
  const { value, getAddress, ...rest } = props

  const mask = '99999-999'

  const { getAddressByCEP, isLoading } = useAddressByCEP()

  useEffect(() => {
    async function getAddressByHook() {
      if (getAddress) {
        const response = await getAddressByCEP(value)
        if (response) getAddress(response)
      }
    }
    getAddressByHook()
    // eslint-disable-next-line
  }, [value])

  return (
    <React.Fragment>
      <MaskedTextField
        mask={mask}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isLoading ? <CircularProgress size={16} /> : <></>}
            </InputAdornment>
          ),
        }}
        size="small"
        fullWidth
        variant="outlined"
        value={value}
        {...rest}
      />
    </React.Fragment>
  )
}

CEPTextField.propTypes = {
  required: PropTypes.bool,
  validationErrors: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  getAddress: PropTypes.func,
}

export default CEPTextField
