import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'

import reducers from './ducks'
import sagas from './sagas'

const middlewares = []

const sagaMonitor =
  import.meta.env.VITE_NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null

const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

middlewares.push(sagaMiddleware)

const composer =
  import.meta.env.VITE_NODE_ENV === 'development'
    ? compose(applyMiddleware(...middlewares), console.tron.createEnhancer())
    : compose(applyMiddleware(...middlewares))

export const store = createStore(reducers, composer)
export const persistor = persistStore(store)
sagaMiddleware.run(sagas)

// export const store;
