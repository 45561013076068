import React from 'react'

import { ActionDialog } from '~/components'
import StepperForm from '~/components/StepperForm'

import Form from './Form'
import TableEpis from './TableEpis'

import useNotification from '~/hooks/useNotification'

import { CondicaoAmbienteTrabalhoAgenteNocivoPartial } from '~/hooks/queries/CondicaoAmbienteTrabalhoAgenteNocivo/CondicaoAmbienteTrabalhoAgenteNocivo'
import { CondicaoAmbienteTrabalhoAgenteNocivoEPIPartial } from '~/hooks/queries/CondicaoAmbienteTrabalhoAgenteNocivoEPI/CondicaoAmbienteTrabalhoAgenteNocivoEPI'
import { Box } from '@material-ui/core'

export interface MainData {
  dataForm: CondicaoAmbienteTrabalhoAgenteNocivoPartial
  dataTableEpis: CondicaoAmbienteTrabalhoAgenteNocivoEPIPartial[]
}

interface FlowAgenteNocivoProps {
  isOpen: boolean
  onClose: () => void
  data: CondicaoAmbienteTrabalhoAgenteNocivoPartial
  indexCurrent: number
  onAfterSubmitForm: (
    event: 'insert' | 'update',
    data: CondicaoAmbienteTrabalhoAgenteNocivoPartial,
    indexCurrent: number,
  ) => void
}

export function FlowAgenteNocivo(props: FlowAgenteNocivoProps) {
  const { isOpen, onClose, data: _data, indexCurrent, onAfterSubmitForm } = props

  const notification = useNotification()

  const mainData: MainData = {
    dataForm: _data,
    dataTableEpis: _data?.condicaoAmbienteTrabalhoAgenteNocivoEPIItens || [],
  }

  const handleSubmit = (data: MainData) => {
    const dataFormated: CondicaoAmbienteTrabalhoAgenteNocivoPartial = {
      ...data.dataForm,
      condicaoAmbienteTrabalhoAgenteNocivoEPIItens: data.dataTableEpis,
    }

    const update = async () => {
      onAfterSubmitForm('update', dataFormated, indexCurrent)
      notification.put()
    }

    const insert = async () => {
      onAfterSubmitForm('insert', dataFormated, indexCurrent)
      notification.post()
    }

    if (indexCurrent >= 0) {
      update()
      return
    }
    insert()
  }

  return (
    <StepperForm.Provider
      onSubmit={(d) => handleSubmit(d)}
      onCancel={onClose}
      data={mainData}
      steps={[
        {
          label: 'Agente Nocivo',
          children: <Form />,
          icon: 'category',
        },
        {
          label: 'EPI/EPC',
          children: <TableEpis />,
          icon: 'security',
        },
      ]}
    >
      <ActionDialog
        title="Cadastro de Agente Nocivo"
        isOpen={isOpen}
        onClose={onClose}
        customActions={<></>}
        dialogProps={{ maxWidth: 'md', fullWidth: true }}
        renderRight={<StepperForm.Steps orientation="horizontal" />}
      >
        <Box style={{ marginBottom: '3rem' }}>
          <StepperForm.Content />
        </Box>
      </ActionDialog>
    </StepperForm.Provider>
  )
}
