import React from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { CopyLabel, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'

interface TableProps {
  data: EstabelecimentoConsulta[]
  isLoading: boolean
  isFetching: boolean
  onRowSelected: (index: number[]) => void
  rowsSelected: number[]
  query: string
}

export default function Table({ data, isLoading, onRowSelected, rowsSelected, query }: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Código',
      name: 'codigo',
    },
    {
      label: 'Nome',
      name: 'nome',
    },
    {
      label: 'Número de Inscrição',
      name: 'nrInscricao',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
  ]

  return (
    <ToolsDataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      optionsSelectable={{
        onRowSelected: onRowSelected,
        rowsSelected: rowsSelected,
        selectOnClick: true,
        type: 'multiple',
      }}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['nome', 'nrInscricao'],
      }}
    />
  )
}
