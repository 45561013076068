import React from 'react'

import { makeStyles } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { ActionDialog, Button } from '~/components'
import IRRFBeneficiario from '~/pages/IRRFBeneficiario'

import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

export interface DialogIRRFBeneficiarioData {
  empregadorId: string
  anoMes: string
  indApuracao: IndApuracaoEnum
}

interface DialogDialogIRRFBeneficiarioProps {
  isOpen: boolean
  onClose: () => void
  data: DialogIRRFBeneficiarioData
}

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))
export default function DialogDialogIRRFBeneficiario(props: DialogDialogIRRFBeneficiarioProps) {
  const { isOpen, onClose, data } = props
  const classes = useStyles()

  return (
    <ActionDialog
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
        classes: {
          paper: classes.fullHeight,
        },
      }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" color="primary" size="small" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <IRRFBeneficiario data={data} />
    </ActionDialog>
  )
}
