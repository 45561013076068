export enum IndSalarioAdicionalEnum {
  Insalubridade_1 = 1,
  Periculosidade_2 = 2,
  AdicionalNoturno_3 = 3,
  QuebraCaixa_4 = 4,
  Gratificacao_5 = 5,
  AdicionalDuplaFuncao_6 = 6,
  AdicionalTempoServico_7 = 7,
  AdicionalCargoConfianca_8 = 8,
}

export const IndSalarioAdicionalValues = [
  { value: IndSalarioAdicionalEnum.Insalubridade_1, name: '1 - Insalubridade' },
  { value: IndSalarioAdicionalEnum.Periculosidade_2, name: '2 - Periculosidade' },
  { value: IndSalarioAdicionalEnum.AdicionalNoturno_3, name: '3 - Adicional Noturno' },
  { value: IndSalarioAdicionalEnum.QuebraCaixa_4, name: '4 - Quebra de Caixa' },
  { value: IndSalarioAdicionalEnum.Gratificacao_5, name: '5 - Gratificação' },
  { value: IndSalarioAdicionalEnum.AdicionalDuplaFuncao_6, name: '6 - Dupla Função' },
  {
    value: IndSalarioAdicionalEnum.AdicionalTempoServico_7,
    name: '7 - Adicional Tempo de Serviço',
  },
  {
    value: IndSalarioAdicionalEnum.AdicionalCargoConfianca_8,
    name: '8 - Cargo de Confiança',
  },
]
