import React from 'react'
import clsx from 'clsx'

import PropTypes from 'prop-types'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Checkbox,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    fontSize: '13px',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  tableCellHead: {
    paddingLeft: theme.spacing(1),
    fontWeight: 600,
    fontSize: '13px',
  },
  tableCellHeadIcon: {
    width: '13px',
    padding: 0,
  },
  tableRow: {
    height: '30px',
    '&:nth-child(even)': {
      backgroundColor: '#FAFAFA',
    },
    '&:hover': {
      backgroundColor: '#ffffde!important',
    },
  },
}))

const skeletonData = [
  {
    col1: '',
  },
]

const skeletonColumns = [
  {
    name: 'col1',
    label: 'Carregando...',
    options: {
      customBodyRender: () => <Skeleton />,
    },
  },
]

function sherlockSearch(sherlock, data) {
  return sherlock?.query?.length > 0
    ? data.filter((d) =>
        sherlock.columns.some((columnName) =>
          d[columnName].toLowerCase().includes(sherlock.query.toLowerCase()),
        ),
      )
    : data
}

function MUITableRow(props) {
  const { data, columns, rowIndex, isChecked, checkRow, showCheckbox } = props

  const classes = useStyles()

  function customBodyRender(value) {
    return value
  }

  const optionsDefault = {
    customBodyRender,
  }

  return (
    <TableRow className={classes.tableRow}>
      {showCheckbox && (
        <TableCell className={clsx([classes.tableCellHead, classes.tableCellHeadIcon])}>
          <Checkbox color="primary" checked={isChecked} onClick={() => checkRow(rowIndex)} />
        </TableCell>
      )}
      {columns.map(({ name, options: optionsCurrent }, columnIndex) => {
        const options = {
          ...optionsDefault,
          ...optionsCurrent,
        }
        const { customBodyRender } = options
        return (
          <TableCell className={classes.tableCell} key={columnIndex}>
            {customBodyRender(data[name], {
              rowIndex,
              columnData: {
                name,
              },
            })}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default function SimpleDataTable(props) {
  const { rowsSelected, onRowSelected, isLoading, sherlock } = props
  let { data, columns } = props

  const classes = useStyles()

  function checkAll() {
    if (rowsSelected.length > 0) {
      return onRowSelected([])
    }
    const newRowsSelected = data.map((d, index) => index)
    onRowSelected(newRowsSelected)
  }

  function checkRow(index) {
    if (rowsSelected.includes(index)) {
      const rowsSelectedFiltred = rowsSelected.filter((row) => row !== index)
      onRowSelected(rowsSelectedFiltred)
      return
    }
    onRowSelected([...rowsSelected, index])
  }

  if (isLoading) {
    data = skeletonData
    columns = skeletonColumns
  }

  const showCheckbox = !isLoading && data.length > 0 && onRowSelected !== undefined

  data = sherlockSearch(sherlock, data)

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple-data-table">
        <TableHead>
          <TableRow>
            {showCheckbox && (
              <TableCell className={clsx([classes.tableCellHead, classes.tableCellHeadIcon])}>
                <Checkbox
                  color="primary"
                  checked={rowsSelected?.length === data.length}
                  indeterminate={rowsSelected?.length !== data.length && rowsSelected?.length > 0}
                  onClick={checkAll}
                />
              </TableCell>
            )}
            {columns.map(({ label }, columnIndex) => (
              <TableCell className={classes.tableCellHead} key={columnIndex}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d, rowIndex) => (
            <MUITableRow
              key={rowIndex}
              data={d}
              isChecked={rowsSelected?.includes(rowIndex) || false}
              checkRow={checkRow}
              columns={columns}
              rowIndex={rowIndex}
              showCheckbox={showCheckbox}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

SimpleDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  rowsSelected: PropTypes.arrayOf(PropTypes.number),
  onRowSelected: PropTypes.func,
  isLoading: PropTypes.bool,
  sherlock: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.string,
  }),
}
