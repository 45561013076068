import { useCallback, useEffect, useState } from 'react'

import { AxiosRequestConfig } from 'axios'
import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'

export default function useSimpleStartRequest<DataType>(
  axiosConfig: AxiosRequestConfig,
  initialState: DataType,
  dependencies: any[] = [],
  lockRequest?: () => boolean,
) {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<DataType>(initialState)

  const dialogNotification = useDialogNotification()

  const getData = useCallback(async () => {
    if (lockRequest && lockRequest()) return
    setLoading(true)
    try {
      const response = await api(axiosConfig)
      setData(response.data.data)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [...dependencies])

  useEffect(() => {
    getData()
  }, [getData])

  return { data, setData, isLoading, getData }
}
