import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoDTO } from './VinculoDTO'

export function useVinculoObterVinculosPorEstabelecimento(estabelecimentoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoDTO[] }>(
      '/Vinculo/ObterVinculosPorEstabelecimento',
      {
        params: {
          estabelecimentoId,
        },
      },
    )
    return response.data.data
  }

  const query = useQuery(
    ['VinculoObterVinculosPorEstabelecimento', estabelecimentoId],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )

  return {
    ...query,
    data: query?.data || [],
  }
}
