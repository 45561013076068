import { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'

import ContainerChart from '../components/ContainerChart'
import ChartHeader from '../components/ChartHeader'
import Chart from '../components/PieChart'
import ChartFooter from '../components/ChartFooter'

import useAmbiente from '~/hooks/useAmbiente'
import { useHistory } from 'react-router-dom'
import usePainelTransmissaoConsultaChart from '~/hooks/queries/Painel/usePainelTransmissaoConsultaChart'

import {
  ESocialGrupoEventoConsultaEnum,
  ESocialGrupoEventoConsultaLabel,
  ESocialGrupoEventoConsultaColor,
} from '~/@types/enums/ESocialGrupoEventoConsultaEnum'
import { MAX_HEIGHT_CHART, MAX_WIDTH_CHART } from '..'

export default function TransmissaoChartConsulta() {
  const { anoMes, empregador } = useAmbiente()
  const history = useHistory()

  const [isHover, setHover] = useState(false)
  const [empregadorId, setEmpregadorId] = useState(empregador?.id || '')

  useEffect(() => {
    setEmpregadorId(empregador?.id || '')
  }, [empregador])

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching,
  } = usePainelTransmissaoConsultaChart({
    anoMes,
    empregadorId,
  })
  const data = _data || []
  const isLoading = _isLoading || isFetching

  const hasError = data.some((d) => d.status === ESocialGrupoEventoConsultaEnum.Erro && d.total > 0)

  function handleRedirect() {
    history.push('/transmissao-esocial/1')
  }

  const hasSomeData = data.some((d) => d.total !== 0)

  return (
    <ContainerChart onHover={setHover}>
      <ChartHeader
        title="Consulta eSocial"
        icon="assessment"
        isActiveAlternate={empregadorId ? false : true}
        onClickRedirect={handleRedirect}
        onClickAlternate={() => setEmpregadorId((prev) => (prev ? '' : empregador.id))}
        isHover={isHover}
        isLoading={isLoading}
      />
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Box maxWidth={MAX_WIDTH_CHART} maxHeight={MAX_HEIGHT_CHART}>
          {hasSomeData ? (
            <Chart
              data={data}
              labels={ESocialGrupoEventoConsultaLabel}
              colors={ESocialGrupoEventoConsultaColor}
              onClick={handleRedirect}
            />
          ) : (
            <Typography>Sem informações</Typography>
          )}
        </Box>
      </Box>
      <ChartFooter
        data={data}
        labels={ESocialGrupoEventoConsultaLabel}
        colors={ESocialGrupoEventoConsultaColor}
        onClick={handleRedirect}
        onAlert={(status) => status === ESocialGrupoEventoConsultaEnum.Erro && hasError}
      />
    </ContainerChart>
  )
}
