import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { AtualizarCTPSFuncionarioDTO } from './dtos/AtualizarCTPSFuncionarioDTO'

const query = '/AtualizarCTPS/ObterVinculo'

interface RequestProps {
  params: {
    vinculoId: string
  }
}

export function useAtualizarCTPSObterVinculo({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: AtualizarCTPSFuncionarioDTO }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
