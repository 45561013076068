import React, { useState, useEffect } from 'react'
import PropType from 'prop-types'

import MaskedTextField from '../MaskedTextField'

const HoraTextField = (props) => {
  const { ...rest } = props

  const [mask, setMask] = useState('99:99')

  useEffect(() => {
    definirMascara(rest.value)
  }, [rest.value])

  const definirMascara = () => {
    setMask('99:99')
  }

  return (
    <React.Fragment>
      <MaskedTextField
        mask={mask}
        // beforeMaskedValueChange={beforeMaskedValueChange}
        {...rest}
      />
    </React.Fragment>
  )
}

HoraTextField.propTypes = {
  onChange: PropType.func,
  label: PropType.string,
  fullWidth: PropType.bool,
  value: PropType.string,
  variant: PropType.string,
  size: PropType.string,
  required: PropType.bool,
  validationErrors: PropType.any,
  name: PropType.string,
}

export default HoraTextField
