import React, { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew, TextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import { codigoTreinamentoCapacitacaoValues } from '~/values/codigoTreinamentoCapacitacaoValues'
import { VinculoTreinamentoPartial } from '~/hooks/queries/VinculoTreinamento/VinculoTreinamento'

const schema = yup.object().shape({
  dtRealizacao: yup
    .date()
    .required('Informe a Data da Realização')
    .typeError('Informe uma data válida'),
  codigoTreinamento: yup.string().required('Informe o Código do Treinamento'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoTreinamentoPartial
  onAfterSubmitForm: (data: VinculoTreinamentoPartial) => void
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState(_data)

  const notification = useNotification()

  const handleSubmit = () => {
    onAfterSubmitForm(data)
    notification.post()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Treinamento"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePickerNew
            label="Data da Realização"
            size="small"
            name="dtRealizacao"
            validationErrors={validationErrors}
            required
            value={data?.dtRealizacao || null}
            onChange={(date) => {
              const dtRealizacao = date || undefined
              setData({ ...data, dtRealizacao })
            }}
          />
        </Grid>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <MUIAutoComplete
            label="Código do Treinamento"
            name="codigoTreinamento"
            validationErrors={validationErrors}
            required
            value={data.codigoTreinamento}
            options={codigoTreinamentoCapacitacaoValues}
            renderOption={(option) => option.name}
            optionId="value"
            onChange={(e, obj) => {
              const codigoTreinamento = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                codigoTreinamento,
              }))
            }}
          />
        </Grid>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            label="Observação"
            size="small"
            fullWidth
            value={data?.observacao || ''}
            inputProps={{ maxLength: 400 }}
            multiline
            onChange={(e) => {
              const observacao = e.target.value
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
