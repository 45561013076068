import { useState, useCallback } from 'react'

import moment from 'moment'

import { Grid } from '@material-ui/core'
import { ActionDialog, TimePicker, TextField } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'

import {
  HorarioTrabalhoIntervalo,
  HorarioTrabalhoIntervaloForm,
} from '~/hooks/queries/HorarioTrabalhoIntervalo/HorarioTrabalhoIntervalo'

import { calcularDuracaoJornada } from '../..'
import {
  TipoHorarioIntervaloEnum,
  TipoHorarioIntervaloValues,
} from '~/@types/enums/TipoHorarioIntervaloEnum'
import {
  IndHorarioTrabalhoIntervaloEnum,
  IndHorarioTrabalhoIntervaloValues,
} from '~/@types/enums/IndHorarioTrabalhoIntervaloEnum'
import { MUIAutoComplete } from '~/components/AutoComplete'

const schema = yup.object().shape({
  tipoIntervalo: yup
    .mixed()
    .required('Informe um Tipo Intervalo')
    .nullable()
    .oneOf(Object.values(TipoHorarioIntervaloEnum), 'Informe um Tipo Intervalo'),
  inicioIntervalo: yup.string().required('Informe o início.').min(4, 'Informa um horário válido'),
  fimIntervalo: yup.string().required('Informe o fim.').min(4, 'Informa um horário válido'),
  duracaoIntervalo: yup.string().required('Informe a duração.'),
  indIntervalo: yup
    .mixed()
    .required('Informe o Indicador de Intervalo')
    .nullable()
    .oneOf(Object.values(IndHorarioTrabalhoIntervaloEnum), 'Informe o Indicador de Intervalo'),
})

interface CadastroIntervaloProps {
  isOpen: boolean
  onClose: () => void
  data: HorarioTrabalhoIntervaloForm
  onAfterSubmitFormIntervalo: (event: 'insert' | 'update', value: HorarioTrabalhoIntervalo) => void
}

const CadastroIntervalo = (props: CadastroIntervaloProps) => {
  const { isOpen, onClose, data: _data, onAfterSubmitFormIntervalo } = props
  const [data, setData] = useState(_data)
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/horarioTrabalhoIntervalo/${data.id}`, data)
        onAfterSubmitFormIntervalo('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/horarioTrabalhoIntervalo', data)
        notification.post()
        onAfterSubmitFormIntervalo('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitFormIntervalo])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Intervalo de Horário de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIAutoComplete
            label="Tipo Intervalo"
            value={data.tipoIntervalo}
            required
            validationErrors={validationErrors}
            renderOption={(opt) => opt.name}
            name="tipoIntervalo"
            options={TipoHorarioIntervaloValues}
            optionId="value"
            onChange={(e, obj) => {
              const tipoIntervalo = obj?.value || TipoHorarioIntervaloEnum.Fixo_01
              setData((prev) => ({ ...prev, tipoIntervalo }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIAutoComplete
            label="Indicador Intervalo"
            value={data.indIntervalo}
            required
            validationErrors={validationErrors}
            renderOption={(opt) => opt.name}
            name="indIntervalo"
            options={IndHorarioTrabalhoIntervaloValues}
            optionId="value"
            onChange={(e, obj) => {
              const indIntervalo = obj?.value || IndHorarioTrabalhoIntervaloEnum.Refeicao_00
              setData((prev) => ({ ...prev, indIntervalo }))
            }}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TimePicker
            label="Inicio"
            value={
              data?.inicioIntervalo ? moment(data.inicioIntervalo, 'HHmm').format('HH:mm') : null
            }
            required
            validationErrors={validationErrors}
            name="inicioIntervalo"
            onChange={(_, date) => {
              const inicioIntervalo = date?.format('HHmm') || null
              const fimIntervalo = data.fimIntervalo
              let duracaoIntervalo: number | null = null

              if (fimIntervalo && inicioIntervalo) {
                duracaoIntervalo = calcularDuracaoJornada(fimIntervalo, inicioIntervalo, [])
              }

              setData((prev) => ({
                ...prev,
                inicioIntervalo,
                fimIntervalo,
                duracaoIntervalo,
              }))
            }}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TimePicker
            label="Fim"
            value={data?.fimIntervalo ? moment(data.fimIntervalo, 'HHmm').format('HH:mm') : null}
            required
            validationErrors={validationErrors}
            name="fimIntervalo"
            onChange={(_, date) => {
              const inicioIntervalo = data.inicioIntervalo
              const fimIntervalo = date?.format('HHmm') || null
              let duracaoIntervalo: number | null = null

              if (fimIntervalo && inicioIntervalo) {
                duracaoIntervalo = calcularDuracaoJornada(fimIntervalo, inicioIntervalo, [])
              }

              setData((prev) => ({
                ...prev,
                inicioIntervalo,
                fimIntervalo,
                duracaoIntervalo,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Duração (min)"
            value={data?.duracaoIntervalo || ''}
            onlyNumber
            required
            validationErrors={validationErrors}
            name="duracaoIntervalo"
            disabled
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default CadastroIntervalo
