import { SimpleAlert } from '~/components'

export default function AvisoFimAfastamento() {
  return (
    <SimpleAlert severity="info">
      <div>
        A data a ser informada no campo <b>Fim do Afastamento</b> deve ser a do último dia em que o
        trabalhador esteve afastado e não a data em que ele retorna ao trabalho.
      </div>
    </SimpleAlert>
  )
}
