import { useMemo, memo } from 'react'

import { makeStyles } from '@material-ui/core'

import { ToolsDataTable } from '~/components'

import { formatToCPF } from 'brazilian-values'
import { formatCurrency, setImportantCSS } from '~/hooks/useUtils'
import { IRRFBeneficiarioDTO } from '~/hooks/queries/ApurarEncargos/ApuracaoESocialDTO'
import { MUIDataTableOptions } from 'mui-datatables'

const MemoToolsDataTable = memo(ToolsDataTable)

const useStyles = makeStyles((theme: FixLater) => ({
  alignRight: {
    textAlign: 'right',
  },
  hightLightRow: {
    backgroundColor: setImportantCSS(theme.palette.error.light),
    color: setImportantCSS(theme.palette.error.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.error.lighter),
    },
  },
}))

interface DetailsSeguradosProps {
  data: IRRFBeneficiarioDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
}

export default function DetailsSegurados({
  data: _data,
  isLoading,
  isFetching,
  query,
}: DetailsSeguradosProps) {
  const classes = useStyles()

  const data = _data.map((d) => {
    return {
      ...d,
      funcionarioCPF: d?.funcionarioCPF ? formatToCPF(d.funcionarioCPF) : '',
      vrFolha: d?.vrFolha ? formatCurrency(d.vrFolha) : '',
      vrESocial: d?.vrESocial ? formatCurrency(d.vrESocial) : '',
      vrDiferenca: d?.vrDiferenca ? formatCurrency(d.vrDiferenca) : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'funcionarioCPF',
        label: 'CPF',
      },
      {
        name: 'funcionarioNome',
        label: 'Nome',
      },
      {
        name: 'vrFolha',
        label: 'Folha',
        options: {
          setCellProps: () => ({ className: classes.alignRight }),
        },
      },
      {
        name: 'vrESocial',
        label: 'ESocial',
        options: {
          setCellProps: () => ({ className: classes.alignRight }),
        },
      },
      {
        name: 'vrDiferenca',
        label: 'Diferença',
        options: {
          setCellProps: () => ({ className: classes.alignRight }),
        },
      },
    ],
    [classes],
  )

  const options: MUIDataTableOptions = {
    setRowProps: (_, dataIndex) => {
      const dtCurrent = data[dataIndex]
      if (!dtCurrent) return {}
      return {
        className: dtCurrent.temDiferenca ? classes.hightLightRow : '',
      }
    },
  }

  return (
    <MemoToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      options={options}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['funcionarioCPF', 'funcionarioNome'],
      }}
    />
  )
}
