import { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'

import ContainerChart from '../components/ContainerChart'
import ChartHeader from '../components/ChartHeader'
import ChartFooter from '../components/ChartFooter'
import Chart from '../components/PieChart'

import useAmbiente from '~/hooks/useAmbiente'
import { useHistory } from 'react-router-dom'
import usePainelTransmissaoEnvioChart from '~/hooks/queries/Painel/usePainelTransmissaoEnvioChart'

import {
  ESocialGrupoEventoEnumLabel,
  ESocialGrupoEventoEnumColor,
} from '~/@types/enums/ESocialGrupoEventoEnum'
import { MAX_HEIGHT_CHART, MAX_WIDTH_CHART } from '..'

export default function TransmissaoChartEnvio() {
  const { anoMes, empregador } = useAmbiente()
  const history = useHistory()

  const [isHover, setHover] = useState(false)
  const [empregadorId, setEmpregadorId] = useState(empregador?.id || '')

  useEffect(() => {
    setEmpregadorId(empregador?.id || '')
  }, [empregador])

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching,
  } = usePainelTransmissaoEnvioChart({
    anoMes,
    empregadorId,
  })
  const data = _data || []
  const isLoading = _isLoading || isFetching

  function handleRedirect() {
    history.push('/transmissao-esocial/0')
  }

  const hasSomeData = data.some((d) => d.total !== 0)

  return (
    <ContainerChart onHover={setHover}>
      <ChartHeader
        title="Envio eSocial"
        icon="description"
        isActiveAlternate={empregadorId ? false : true}
        onClickRedirect={handleRedirect}
        onClickAlternate={() => setEmpregadorId((prev) => (prev ? '' : empregador.id))}
        isHover={isHover}
        isLoading={isLoading}
      />
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Box maxWidth={MAX_WIDTH_CHART} maxHeight={MAX_HEIGHT_CHART}>
          {hasSomeData ? (
            <Chart
              data={data}
              labels={ESocialGrupoEventoEnumLabel}
              colors={ESocialGrupoEventoEnumColor}
              onClick={handleRedirect}
            />
          ) : (
            <Typography>Sem informações</Typography>
          )}
        </Box>
      </Box>
      <ChartFooter
        data={data}
        labels={ESocialGrupoEventoEnumLabel}
        colors={ESocialGrupoEventoEnumColor}
        onClick={handleRedirect}
      />
    </ContainerChart>
  )
}
