import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'

import { Box, Paper, useTheme } from '@material-ui/core'

import { AutoCompleteGrupo } from '~/components/AutoComplete'
import { AnoMesTextField, Finder, PDFViewer, PageHeader, PrintButton } from '~/components'

import Table from './Table'

import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'
import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

const PAGE_TITLE = 'Resumo Contábil da Folha'

export default function ResumoContabilFolha() {
  const [dataForm, setDataForm] = useState({
    anoMesInicio: '',
    anoMesFim: '',
  })
  const [query, setQuery] = useState('')
  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [grupoId, setGrupoId] = useState('')

  const { anoMes } = useAmbiente()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useEstabelecimentoObterFiltrado({
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
    grupoId,
    anoMes,
  })
  const data = _data || []

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<string[]>([])
  const theme = useTheme()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handlePrint,
    data: dataForm,
  })

  useEffect(() => {
    setRowsSelected([])
  }, [_data])

  useEffect(() => {
    setDataForm({
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
    })
  }, [anoMes])

  function handlePrint() {
    const estabelecimentosIds = rowsSelected.map((d) => data[d].id)
    openPDFViewer(estabelecimentosIds)
  }

  function handleClosePDFViewer() {
    setRowsSelected([])
    closePDFViewer()
  }

  return (
    <Box
      padding={2}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title={PAGE_TITLE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box display="flex" gridGap={theme.spacing(1)}>
        <Box component={Paper} p={1} display="flex" gridGap={theme.spacing(1)} width="100%">
          <Box maxWidth={300}>
            <AnoMesTextField
              name="anoMesInicio"
              validationErrors={validationErrors}
              label="Ano/Mês Início"
              value={dataForm.anoMesInicio || ''}
              onChange={(e: FixLater) => {
                const anoMesInicio = e.target.value.replace('/', '')
                setDataForm((prev) => ({ ...prev, anoMesInicio }))
              }}
            />
          </Box>

          <Box maxWidth={300}>
            <AnoMesTextField
              name="anoMesFim"
              validationErrors={validationErrors}
              label="Ano/Mês Fim"
              value={dataForm.anoMesFim || ''}
              onChange={(e: FixLater) => {
                const anoMesFim = e.target.value.replace('/', '')
                setDataForm((prev) => ({ ...prev, anoMesFim }))
              }}
            />
          </Box>
        </Box>

        <Box component={Paper} p={1} width={300}>
          <AutoCompleteGrupo
            value={grupoId}
            onChange={(e, grupo) => {
              const grupoId = grupo?.id || null
              setGrupoId(grupoId)
            }}
          />
        </Box>
      </Box>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%">
          <Table
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            query={query}
            rowsSelected={rowsSelected}
            onRowsSelected={setRowsSelected}
          />
        </Box>
      </Box>

      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <PrintButton disabled={rowsSelected.length > 0 ? false : true} onClick={handleValidate} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={handleClosePDFViewer}
        reportKey="RelResumoContabilFolha"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'post',
          url: '/RelResumoContabilFolha/ObterRelatorio',
          params: dataForm,
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
