import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { AtestadoSaudeOcupacionalSaveDTO } from './AtestadoSaudeOcupacionalSaveDTO'

export function useAtestadoSaudeOcupacionalCriar() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: AtestadoSaudeOcupacionalSaveDTO) {
    await api.post('/AtestadoSaudeOcupacional/CriarAtestadoSaudeOcupacional', data)
    notification.post()
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries('AtestadoSaudeOcupacionalGetByVinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
