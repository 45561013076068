import { FormControlLabel, Switch } from '@material-ui/core'

interface SwitchFieldProps {
  label: string
  value: boolean
  onChange: (value: boolean) => void
}

export default function SwitchField({ label, value, onChange }: SwitchFieldProps) {
  return (
    <FormControlLabel
      control={
        <Switch checked={value} onChange={(_, checked) => onChange(checked)} color="primary" />
      }
      label={label}
    />
  )
}
