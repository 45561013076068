import { useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, useTheme } from '@material-ui/core'

import { ActionDialog, AnoMesTextField, CurrencyTextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import { TabelaIR } from '~/hooks/queries/TabelaIR/TabelaIR'
import { useCriarTabelaIR } from '~/hooks/queries/TabelaIR/useCriarTabelaIR'
import { useAtualizarTabelaIR } from '~/hooks/queries/TabelaIR/useAtualizarTabelaIR'
import { IndTabelaIREnum, IndTabelaIRValues } from '~/@types/enums/IndTabelaIREnum'

const schema = yup.object().shape({
  anoMes: yup.string().required('Informe o Ano/Mês').nullable(),
  vrFaixaI: yup.string().required('Informe o Valor da Faixa I').nullable(),
  vrFaixaII: yup.string().required('Informe o Valor da Faixa II').nullable(),
  vrFaixaIII: yup.string().required('Informe o Valor da Faixa III').nullable(),
  vrFaixaIV: yup.string().required('Informe o Valor da Faixa IV').nullable(),
  vrFaixaV: yup.string().required('Informe o Valor da Faixa V').nullable(),
  aliqFaixaI: yup.string().required('Informe a Alíquota I').nullable(),
  aliqFaixaII: yup.string().required('Informe a Alíquota II').nullable(),
  aliqFaixaIII: yup.string().required('Informe a Alíquota III').nullable(),
  aliqFaixaIV: yup.string().required('Informe a Alíquota IV').nullable(),
  aliqFaixaV: yup.string().required('Informe a Alíquota V').nullable(),
  vrDeducaoFaixaI: yup.string().required('Informe a Dedução da Faixa I').nullable(),
  vrDeducaoFaixaII: yup.string().required('Informe a Dedução da Faixa II').nullable(),
  vrDeducaoFaixaIII: yup.string().required('Informe a Dedução da Faixa III').nullable(),
  vrDeducaoFaixaIV: yup.string().required('Informe a Dedução da Faixa IV').nullable(),
  vrDeducaoFaixaV: yup.string().required('Informe a Dedução da Faixa V').nullable(),
  vrDeducaoDependente: yup.string().required('Informe a Dedução por Dependente').nullable(),
  indTabelaIR: yup.mixed().oneOf(Object.values(IndTabelaIREnum), 'Informe o Indicador Tabela IR'),
})

interface FormProps {
  data: TabelaIR | null
  isOpen: boolean
  onClose: () => void
}

export default function Form(props: FormProps) {
  const { data: _data, isOpen, onClose } = props

  const [data, setData] = useState<Nullable<TabelaIR>>(
    _data || {
      anoMes: null,
      vrFaixaI: null,
      vrFaixaII: null,
      vrFaixaIII: null,
      vrFaixaIV: null,
      vrFaixaV: null,
      aliqFaixaI: null,
      aliqFaixaII: null,
      aliqFaixaIII: null,
      aliqFaixaIV: null,
      aliqFaixaV: null,
      vrDeducaoFaixaI: null,
      vrDeducaoFaixaII: null,
      vrDeducaoFaixaIII: null,
      vrDeducaoFaixaIV: null,
      vrDeducaoFaixaV: null,
    },
  )

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const { mutateAsync: mutateAsyncCriar, isLoading: isLoadingCriar } = useCriarTabelaIR()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: isLoadingAtualizar } =
    useAtualizarTabelaIR()
  const theme = useTheme()

  const isLoading = isLoadingCriar || isLoadingAtualizar

  async function handleSubmit() {
    const d = data as TabelaIR
    if (data.id) {
      await mutateAsyncAtualizar(d)
    } else {
      await mutateAsyncCriar(d)
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Tabela Imposto de Renda Formulário"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
      okLabel="Salvar"
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <AnoMesTextField
            label="Ano/Mês"
            value={data.anoMes || null}
            onChange={(e: FixLater) => {
              const anoMes = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, anoMes }))
            }}
            required
            validationErrors={validationErrors}
            name="anoMes"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MUIAutoComplete
            label="Indicador Tabela IR"
            options={IndTabelaIRValues}
            optionId="value"
            renderOption={(option) => option.name}
            name="indTabelaIR"
            validationErrors={validationErrors}
            value={data.indTabelaIR}
            onChange={(e, obj) => {
              const indTabelaIR = obj ? obj.value : null
              setData((oldState) => ({
                ...oldState,
                indTabelaIR,
              }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CurrencyTextField
            label="Dedução por Dependente"
            value={data.vrDeducaoDependente || null}
            onChange={(_, value) => {
              const vrDeducaoDependente = value
              setData((oldState) => ({
                ...oldState,
                vrDeducaoDependente,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrDeducaoDependente"
          />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gridGap={theme.spacing(1)}>
            <Box display="flex" justifyContent="center" alignItems="center" width={70}>
              Faixa I
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <CurrencyTextField label="De" disabled value={'0'} required />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Até"
                  value={data.vrFaixaI || null}
                  onChange={(_, value) => {
                    const vrFaixaI = value
                    setData((oldState) => ({
                      ...oldState,
                      vrFaixaI,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrFaixaI"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Alíquota"
                  value={data.aliqFaixaI || null}
                  onChange={(_, value) => {
                    const aliqFaixaI = value
                    setData((oldState) => ({
                      ...oldState,
                      aliqFaixaI,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="aliqFaixaI"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Dedução"
                  value={data.vrDeducaoFaixaI || null}
                  onChange={(_, value) => {
                    const vrDeducaoFaixaI = value
                    setData((oldState) => ({
                      ...oldState,
                      vrDeducaoFaixaI,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrDeducaoFaixaI"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gridGap={theme.spacing(1)}>
            <Box display="flex" justifyContent="center" alignItems="center" width={70}>
              Faixa II
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="De"
                  disabled
                  value={data?.vrFaixaI ? data?.vrFaixaI + 0.01 : 0}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Até"
                  value={data.vrFaixaII || null}
                  onChange={(_, value) => {
                    const vrFaixaII = value
                    setData((oldState) => ({
                      ...oldState,
                      vrFaixaII,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrFaixaII"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Alíquota"
                  value={data.aliqFaixaII || null}
                  onChange={(_, value) => {
                    const aliqFaixaII = value
                    setData((oldState) => ({
                      ...oldState,
                      aliqFaixaII,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="aliqFaixaII"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Dedução"
                  value={data.vrDeducaoFaixaII || null}
                  onChange={(_, value) => {
                    const vrDeducaoFaixaII = value
                    setData((oldState) => ({
                      ...oldState,
                      vrDeducaoFaixaII,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrDeducaoFaixaII"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gridGap={theme.spacing(1)}>
            <Box display="flex" justifyContent="center" alignItems="center" width={70}>
              Faixa III
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="De"
                  disabled
                  value={data?.vrFaixaII ? data?.vrFaixaII + 0.01 : 0}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Até"
                  value={data.vrFaixaIII || null}
                  onChange={(_, value) => {
                    const vrFaixaIII = value
                    setData((oldState) => ({
                      ...oldState,
                      vrFaixaIII,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrFaixaIII"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Alíquota"
                  value={data.aliqFaixaIII || null}
                  onChange={(_, value) => {
                    const aliqFaixaIII = value
                    setData((oldState) => ({
                      ...oldState,
                      aliqFaixaIII,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="aliqFaixaIII"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Dedução"
                  value={data.vrDeducaoFaixaIII || null}
                  onChange={(_, value) => {
                    const vrDeducaoFaixaIII = value
                    setData((oldState) => ({
                      ...oldState,
                      vrDeducaoFaixaIII,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrDeducaoFaixaIII"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gridGap={theme.spacing(1)}>
            <Box display="flex" justifyContent="center" alignItems="center" width={70}>
              Faixa IV
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="De"
                  disabled
                  value={data?.vrFaixaIII ? data?.vrFaixaIII + 0.01 : 0}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Até"
                  value={data.vrFaixaIV || null}
                  onChange={(_, value) => {
                    const vrFaixaIV = value
                    setData((oldState) => ({
                      ...oldState,
                      vrFaixaIV,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrFaixaIV"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Alíquota"
                  value={data.aliqFaixaIV || null}
                  onChange={(_, value) => {
                    const aliqFaixaIV = value
                    setData((oldState) => ({
                      ...oldState,
                      aliqFaixaIV,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="aliqFaixaIV"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Dedução"
                  value={data.vrDeducaoFaixaIV || null}
                  onChange={(_, value) => {
                    const vrDeducaoFaixaIV = value
                    setData((oldState) => ({
                      ...oldState,
                      vrDeducaoFaixaIV,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrDeducaoFaixaIV"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gridGap={theme.spacing(1)}>
            <Box display="flex" justifyContent="center" alignItems="center" width={70}>
              Faixa V
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="De"
                  disabled
                  value={data?.vrFaixaIV ? data?.vrFaixaIV + 0.01 : 0}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Até"
                  value={data.vrFaixaV || null}
                  onChange={(_, value) => {
                    const vrFaixaV = value
                    setData((oldState) => ({
                      ...oldState,
                      vrFaixaV,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrFaixaV"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Alíquota"
                  value={data.aliqFaixaV || null}
                  onChange={(_, value) => {
                    const aliqFaixaV = value
                    setData((oldState) => ({
                      ...oldState,
                      aliqFaixaV,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="aliqFaixaV"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CurrencyTextField
                  label="Dedução"
                  value={data.vrDeducaoFaixaV || null}
                  onChange={(_, value) => {
                    const vrDeducaoFaixaV = value
                    setData((oldState) => ({
                      ...oldState,
                      vrDeducaoFaixaV,
                    }))
                  }}
                  required
                  validationErrors={validationErrors}
                  name="vrDeducaoFaixaV"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
