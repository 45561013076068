import { useState } from 'react'

import * as yup from 'yup'

import { Box, Container, Grid, Paper, useTheme } from '@material-ui/core'
import { Send } from '@material-ui/icons'

import { ExcelButton, IconLabelButton } from '~/components'
import { AutoCompleteEstabelecimento, AutoCompleteModeloPlanilha } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import useGerarPlanilha from '~/hooks/queries/ImportarEventosRP/useGerarPlanilha'
import useGerarPlanilhaEnviarEmail from '~/hooks/queries/ImportarEventosRP/useGerarPlanilhaEnviarEmail'

interface DataFormProps {
  estabelecimentoId: string
  modeloPlanilhaId: string
}

const schema = yup.object().shape({
  estabelecimentoId: yup.string().required('Informe o Estabelecimento'),
  modeloPlanilhaId: yup.string().required('Informe o Modelo de Planilha'),
})

export default function GerarPlanilhaImportacao() {
  const [data, setData] = useState<DataFormProps>({
    estabelecimentoId: '',
    modeloPlanilhaId: '',
  })

  const { anoMes } = useAmbiente()
  const theme = useTheme()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const { mutateAsync: onGerarPlanilha, isLoading: isLoadingGerarPlanilha } = useGerarPlanilha()
  const { mutateAsync: onGerarPlanilhaEnviarEmail, isLoading: isLoadingGerarPlanilhaEnviarEmail } =
    useGerarPlanilhaEnviarEmail()

  async function handleSubmit(event: 'send' | 'download') {
    const { estabelecimentoId, modeloPlanilhaId } = data

    if (event === 'download') {
      await onGerarPlanilha({
        estabelecimentoId,
        modeloPlanilhaId,
        anoMes,
      })
    } else {
      await onGerarPlanilhaEnviarEmail({
        estabelecimentoId,
        modeloPlanilhaId,
        anoMes,
      })
    }
  }

  return (
    <Container maxWidth="sm">
      <Box
        component={Paper}
        padding={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(1)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutoCompleteEstabelecimento
              required
              name="estabelecimentoId"
              validationErrors={validationErrors}
              optionId="id"
              value={data.estabelecimentoId}
              onChange={(_: FixLater, obj: FixLater) => {
                const estabelecimentoId = obj?.id || ''
                const modeloPlanilhaId = obj?.modeloPlanilhaImportacaoId || ''
                setData((oldState) => ({
                  ...oldState,
                  estabelecimentoId,
                  modeloPlanilhaId,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <AutoCompleteModeloPlanilha
              required
              name="modeloPlanilhaId"
              validationErrors={validationErrors}
              value={data.modeloPlanilhaId}
              onChange={(_: FixLater, obj: FixLater) => {
                const modeloPlanilhaId = obj?.id || ''
                setData((oldState) => ({
                  ...oldState,
                  modeloPlanilhaId,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)}>
              <IconLabelButton
                isLoading={isLoadingGerarPlanilhaEnviarEmail}
                onClick={() => handleValidate('send')}
                icon={Send}
              >
                Enviar por Email
              </IconLabelButton>
              <ExcelButton
                isLoading={isLoadingGerarPlanilha}
                onClick={() => handleValidate('download')}
              >
                Gerar Planilha
              </ExcelButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
