import React from 'react'

import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '24px',
  },
  labelText: {
    fontSize: '9pt',
    fontWeight: (props) => (props.bolder ? 'bolder' : 'inherite'),
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const CardItem = (props) => {
  const { labelText, labelInfo, bolder, ...rest } = props
  const classes = useStyles({ bolder })
  return (
    <Box className={classes.root} {...rest}>
      <span className={classes.labelText} title={labelText}>
        {labelText}
      </span>
      {labelInfo && labelInfo}
    </Box>
  )
}

CardItem.defaultProps = {
  bolder: false,
}

export default CardItem
