import React, { useState, useEffect, useCallback } from 'react'

import { ContentContainer, PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import Table from './components/Table'
import Form from './components/Container/Form'
import FormInitial from './components/FormInitial'

import { ConfirmDeleteDialog, ActionDialog } from '~/components'

import api from '~/services/api-pessoal'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import { TipoCadastroInicialPessoaFisicaEnum } from '~/hooks/queries/PessoaFisica/pessoaFisicaObterCadastroInicial'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '100%',
  },
}))

const PessoaFisica = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [formInitial, setFormInitial] = useState({
    isOpen: false,
  })
  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [query, setQuery] = useState('')

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get('/PessoaFisicaConsulta')
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = async (id) => {
        history.push({
          pathname: `/pessoa-fisica/${id}`,
        })
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [history],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    const itens = collection.itens
    try {
      await api.delete(`/PessoaFisica/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    // eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id, handleCloseConfirmDeleteItem])

  const handleOpenForm = (data) => {
    setForm({
      isOpen: true,
      data,
    })
  }

  const handleOpenPessoa = (pessoa) => {
    history.push({
      pathname: `/pessoa-fisica/${pessoa?.id}`,
    })
  }

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleClickAddItem = useCallback(() => {
    handleOpenFormInitial()
  }, [])

  const handleOpenFormInitial = () => {
    setFormInitial({
      isOpen: true,
    })
  }

  const onCloseFormInitial = () => {
    setFormInitial({
      isOpen: false,
    })
  }

  const handleAfterSubmitFormInitial = (tipoCadastroInicial, pessoaFisica) => {
    if (
      tipoCadastroInicial === TipoCadastroInicialPessoaFisicaEnum.EncontradoBase ||
      tipoCadastroInicial === TipoCadastroInicialPessoaFisicaEnum.EncontradoBaseCommon
    ) {
      handleOpenPessoa(pessoaFisica)
    } else {
      handleOpenForm(pessoaFisica)
    }
    onCloseFormInitial()
  }

  return (
    <ContentContainer>
      <PageHeader title="Pessoa Física">
        <ButtonBox>
          <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        query={query}
        onItemClick={handleClickItem}
      />

      <ActionDialog
        title="Cadastro de Pessoa Física"
        isOpen={form.isOpen}
        onClose={handleCloseForm}
        onCancelClick={handleCloseForm}
        customActions={<></>}
        dialogProps={{
          maxWidth: 'lg',
          fullWidth: true,
          classes: {
            paper: classes.heightPaper,
          },
        }}
      >
        <Form
          data={form.data}
          hasAdmissaoPreliminar={form.data?.dtNascimento ? true : false}
          onClickClose={handleCloseForm}
        />
      </ActionDialog>

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />

      {formInitial.isOpen && (
        <FormInitial
          isOpen={formInitial.isOpen}
          onClose={onCloseFormInitial}
          onAfterSubmitFormInitial={handleAfterSubmitFormInitial}
        />
      )}
    </ContentContainer>
  )
}

export default PessoaFisica
