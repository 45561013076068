import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryUseObterTodosTabelaINSS } from './useObterTodosTabelaINSS'

export function useRemoverTabelaINSS() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(tabelaINSSId: string) {
    await api.delete('/TabelaINSS/' + tabelaINSSId)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.remove()
      await queryClient.invalidateQueries(queryUseObterTodosTabelaINSS)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
