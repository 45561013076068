export enum IndIncidenciaFGTSEnum {
  Normal = 1,
  SuspensaJudicialmente = 9,
}

export const IndIncidenciaFGTSValues = [
  {
    name: 'Normal',
    value: IndIncidenciaFGTSEnum.Normal,
  },
  {
    name: 'Suspensa Judicialmente',
    value: IndIncidenciaFGTSEnum.SuspensaJudicialmente,
  },
]
