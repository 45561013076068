export enum IndModeloTermoAutUsoImagemEnum {
  Modelo_01,
  Modelo_02,
}

export const IndModeloTermoAutUsoImagemValues = [
  {
    value: IndModeloTermoAutUsoImagemEnum.Modelo_01,
    name: 'Modelo 1',
  },
  {
    value: IndModeloTermoAutUsoImagemEnum.Modelo_02,
    name: 'Modelo 2',
  },
]
