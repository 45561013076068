import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'

import { Grid, LinearProgress } from '@material-ui/core'

import {
  Checkbox,
  ContainerForm,
  ContentDivider,
  CurrencyTextField,
  DatePickerNew,
  NumeroInscricaoTextField,
  TextField,
} from '~/components'
import FgtsTransporter from '~/components/FgtsTransporter'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { useVinculoById } from '~/hooks/queries/Vinculo/useVinculoById'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { IndPensaoAlimenticiaFGTSValues } from '~/@types/enums/IndPensaoAlimenticiaFGTSEnum'
import { RescisaoFGTSValues } from '~/@types/enums/RescisaoFGTSEnum'
import { HomologNetValues } from '~/@types/enums/HomologNetEnum'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import {
  IndMotivoDesligamentoEnum,
  IndMotivoDesligamentoValues,
} from '~/@types/enums/IndMotivoDesligamentoEnum'

import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'

interface VinculoRescisaoProps {
  vinculoId: string
  onClose?: () => void
}

export default function VinculoRescisao(props: VinculoRescisaoProps) {
  const { vinculoId, onClose } = props

  const { data: _data, isLoading } = useVinculoById(vinculoId)
  const [data, setData] = useState<VinculoPartial>({})
  const [isSubmitting, setSubmitting] = useState(false)
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    if (!_data) return
    setData(_data)
  }, [_data])

  async function handleSubmit() {
    setSubmitting(true)
    try {
      await api.put(`Vinculo/${vinculoId}`, data)
      notification.post()
      if (onClose) onClose()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  function handleCancel() {
    // getData()
    if (onClose) onClose()
  }

  return (
    <ContainerForm handleSubmit={handleSubmit} onCancel={handleCancel} isLoading={isSubmitting}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <ContentDivider title="Desligamento" />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <DatePickerNew
              label="Data Admissão"
              size="small"
              value={data?.dtAdmissao || null}
              disabled
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <DatePickerNew
              label="Data Rescisão"
              size="small"
              disabled
              value={data.dtRescisao || null}
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <DatePickerNew
              label="Rescisão Complementar"
              size="small"
              value={data.dtRescisaoComplementar || null}
              disabled
            />
          </Grid>

          <Grid md={3} sm={6} xs={12} item>
            <DatePickerNew
              label="Data de Homologação"
              size="small"
              value={data?.dtHomologacao || null}
              disabled
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MUIAutoComplete
              label="Motivo de Desligamento"
              options={IndMotivoDesligamentoValues}
              optionId="value"
              renderOption={(option: FixLater) => option.name}
              value={data.motivoDesligamento}
              onChange={(e: string, obj: FixLater) => {
                const motivoDesligamento = obj?.value || IndMotivoDesligamentoEnum.NaoAplicavel_99
                let isFGTSGuiaRescisoria = false

                if (
                  motivoDesligamento ===
                  IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02
                ) {
                  isFGTSGuiaRescisoria = true
                }

                if (motivoDesligamento === IndMotivoDesligamentoEnum.RescisãoTerminoContrato_06) {
                  isFGTSGuiaRescisoria = true
                }

                setData((oldState) => ({
                  ...oldState,
                  motivoDesligamento,
                  isFGTSGuiaRescisoria,
                }))
              }}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <NumeroInscricaoTextField
              name="prepostoCPF"
              label="Preposto CPF"
              value={data?.prepostoCPF || ''}
              typeMask={tipoInscricaoConsts.CPF_2}
              onChange={(e, value) => {
                const prepostoCPF = value || ''
                setData((oldState) => ({
                  ...oldState,
                  prepostoCPF,
                }))
              }}
            />
          </Grid>

          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <TextField
              label="Preposto Nome"
              inputProps={{ maxLength: 100 }}
              value={data?.prepostoNome || ''}
              onChange={(e) => {
                const prepostoNome = e.target.value
                setData((oldState) => ({
                  ...oldState,
                  prepostoNome,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Número Processo Trabalhista Rescisão Judicial"
              value={data?.nrProcTrabRescisao || ''}
              onlyNumber
              inputProps={{
                maxLength: 20,
              }}
              onChange={(e) => {
                const nrProcTrabRescisao = e?.target?.value || ''
                setData((oldState) => ({
                  ...oldState,
                  nrProcTrabRescisao,
                }))
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              label="Chave CEF"
              value={data?.chaveCEF || ''}
              inputProps={{
                maxLength: 30,
              }}
              onChange={(e) => {
                const chaveCEF = e?.target?.value || ''
                setData((prev) => ({
                  ...prev,
                  chaveCEF,
                }))
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <FgtsTransporter
              value={data?.codigoSaqueFGTS || ''}
              onChange={(value: FixLater) => {
                const codigoSaqueFGTS = value?.codigo || ''

                setData((oldState) => ({
                  ...oldState,
                  codigoSaqueFGTS,
                }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12}>
            <ContentDivider title="FGTS/Rescisão" />
          </Grid>

          <Grid item sm={6} xs={12}>
            <MUIAutoComplete
              label="Rescisão FGTS"
              options={RescisaoFGTSValues}
              renderOption={(option: FixLater) => `${option.value} - ${option.name}`}
              value={data?.rescisaoFGTS || ''}
              onChange={(e: string, obj: FixLater) => {
                const rescisaoFGTS = obj?.value || ''
                setData((oldState) => ({ ...oldState, rescisaoFGTS }))
              }}
              optionId="value"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <MUIAutoComplete
              label="Rescisão Homolognet"
              options={HomologNetValues}
              renderOption={(option) => option.name}
              value={data?.rescisaoHomologNet || ''}
              onChange={(e: string, obj: FixLater) => {
                const rescisaoHomologNet = obj?.value || ''
                setData((oldState) => ({
                  ...oldState,
                  rescisaoHomologNet,
                }))
              }}
              optionId="value"
            />
          </Grid>

          <Grid item sm={12} xs={12}>
            <MUIAutoComplete
              name="indPensaoAlimenticiaFGTS"
              label="Pensão Alimenticia FGTS"
              options={IndPensaoAlimenticiaFGTSValues}
              renderOption={(option: FixLater) => `${option.value} - ${option.name}`}
              value={data?.indPensaoAlimenticiaFGTS}
              onChange={(e: string, obj: FixLater) => {
                const indPensaoAlimenticiaFGTS = obj.value
                setData((oldState) => ({ ...oldState, indPensaoAlimenticiaFGTS }))
              }}
              optionId="value"
            />
          </Grid>

          <Grid sm={6} xs={12} item>
            <Checkbox
              label="Recolher FGTS do mês anterior em Guia Rescisória"
              value={data?.isFGTSMesAnterior || false}
              onChange={(e, value) => {
                const isFGTSMesAnterior = value
                setData((oldState) => ({
                  ...oldState,
                  isFGTSMesAnterior,
                }))
              }}
            />
          </Grid>

          <Grid sm={6} xs={12} item>
            <Checkbox
              label="FGTS do mês recolhido em Guia Rescisória"
              value={data?.isFGTSGuiaRescisoria || false}
              onChange={(e, value) => {
                const isFGTSGuiaRescisoria = value
                setData((oldState) => ({
                  ...oldState,
                  isFGTSGuiaRescisoria,
                }))
              }}
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <CurrencyTextField
              label="Remuneração Mês Anterior"
              value={data?.vrRemuneracaoMesAnterior || ''}
              onChange={(_, value) => {
                const vrRemuneracaoMesAnterior = value
                setData((prev) => ({
                  ...prev,
                  vrRemuneracaoMesAnterior,
                }))
              }}
            />
          </Grid>

          <Grid md={3} sm={6} xs={12} item>
            <CurrencyTextField
              name="rescisaoValorPensaoFGTS"
              label="Valor Pensão FGTS"
              variant="outlined"
              size="small"
              fullWidth
              value={data?.rescisaoValorPensaoFGTS || null}
              onChange={(e, value) => {
                const rescisaoValorPensaoFGTS = value || null
                setData((oldState) => ({
                  ...oldState,
                  rescisaoValorPensaoFGTS,
                }))
              }}
            />
          </Grid>

          <Grid md={3} sm={6} xs={12} item>
            <CurrencyTextField
              name="rescisaoPercPensaoFGTS"
              label="Percentual Pensão FGTS"
              variant="outlined"
              size="small"
              fullWidth
              value={data?.rescisaoPercPensaoFGTS || null}
              maximumValue="999"
              onChange={(e, value) => {
                const rescisaoPercPensaoFGTS = value || null
                setData((oldState) => ({
                  ...oldState,
                  rescisaoPercPensaoFGTS,
                }))
              }}
            />
          </Grid>

          <Grid md={3} sm={6} xs={12} item>
            <CurrencyTextField
              label="Percentual Pensão TRCT"
              variant="outlined"
              size="small"
              maximumValue="999"
              fullWidth
              value={data?.rescisaoPercPensaoTRCT || null}
              onChange={(e, value) => {
                const rescisaoPercPensaoTRCT = value || null
                setData((oldState) => ({
                  ...oldState,
                  rescisaoPercPensaoTRCT,
                }))
              }}
            />
          </Grid>
        </Grid>
      )}
    </ContainerForm>
  )
}
