import { useState } from 'react'

import * as yup from 'yup'

import { ActionDialog, CurrencyTextField, DatePickerNew } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useValeTransporteApuracaoAtualizar } from '~/hooks/queries/ValeTransporteApuracao/useValeTransporteApuracaoAtualizar'

import { ValeTransporteApuracaoUpdateDTO } from '~/hooks/queries/ValeTransporteApuracao/ValeTransporteApuracaoDTO'
import { Grid } from '@material-ui/core'

const schema = yup.object().shape({
  quantidadeVales: yup.number().min(1, 'Informe a Quantidade de Vales'),
  dtUltimaFolga: yup.date().typeError('Informe uma Data Válida').nullable(),
})

interface FormAtualizarProps {
  isOpen: boolean
  onClose: () => void
  data: ValeTransporteApuracaoUpdateDTO
  subtitle: string
}

export default function FormAtualizar({
  isOpen,
  onClose,
  data: _data,
  subtitle,
}: FormAtualizarProps) {
  const [data, setData] = useState(_data)

  const { mutateAsync, isLoading: isSubmitting } = useValeTransporteApuracaoAtualizar()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Atualizar Apuração"
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data da última folga"
            value={data.dtUltimaFolga || null}
            onChange={(date) => {
              const dtUltimaFolga = date
              setData((prev) => ({ ...prev, dtUltimaFolga }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            label="Vales"
            value={data.quantidadeVales}
            validationErrors={validationErrors}
            decimalPlaces={0}
            decimalPlacesShownOnFocus={0}
            name="quantidadeVales"
            required
            onChange={(_, number) => {
              const quantidadeVales = number || 0
              setData((prev) => ({ ...prev, quantidadeVales }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            label="Vales para Descontar"
            value={data.quantidadeValesDescontar}
            decimalPlaces={0}
            decimalPlacesShownOnFocus={0}
            onChange={(_, number) => {
              const quantidadeValesDescontar = number || 0
              setData((prev) => ({ ...prev, quantidadeValesDescontar }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
