import { useRef } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar, getElementAtEvent } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface BarChartProps {
  data: { status: number; firstTotal: number; secondTotal: number }[]
  labels: any
  onClick: (index: number) => void
}

export default function BarChart({ data, labels, onClick }: BarChartProps) {
  const chartRef = useRef() as any

  function handleClick(event: any) {
    const element = getElementAtEvent(chartRef.current, event)[0]
    if (element?.index === undefined) return
    onClick(element?.index)
  }

  return (
    <Bar
      ref={chartRef}
      data={{
        labels: data.map((d) => labels[d.status]),
        datasets: [
          {
            label: 'Finalizados',
            data: data.map((d) => d.firstTotal),
            backgroundColor: '#ADD899',
          },
          {
            label: 'Não Finalizados',
            data: data.map((d) => d.secondTotal),
            backgroundColor: '#FFDE95',
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: true,
            align: 'center',
          },
        },
        onHover: (event: any, elements) => {
          if (!elements[0]) {
            event.native.target.style.cursor = 'default'
            return
          }
          event.native.target.style.cursor = 'pointer'
        },
      }}
      onClick={handleClick}
    />
  )
}
