import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  params: { estabelecimentoId: string }
  data: FormData
}

export function useImportarXML() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{ data: string[] }>('/ImportarXMLS2240/ImportarXML', data, {
      params,
    })
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Condição Ambiente de Trabalho importada com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
