import moment from 'moment'

import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import { VinculoTreinamento } from '~/hooks/queries/VinculoTreinamento/VinculoTreinamento'

import { MUIDataTableColumnDef } from 'mui-datatables'

import {
  CodigoTreinamentoCapacitacaoEnum,
  CodigoTreinamentoCapacitacaoValues,
} from '~/@types/enums/CodigoTreinamentoCapacitacaoEnum'

const formatCodigoTreinamento = (codigoTreinamento: CodigoTreinamentoCapacitacaoEnum) => {
  const optionCurrent = CodigoTreinamentoCapacitacaoValues.find(
    (opt) => opt.value === codigoTreinamento,
  )
  return optionCurrent?.name || ''
}
interface TableProps {
  data: VinculoTreinamento[]
  query: string
  onItemClick: (event: 'edit' | 'delete', id: string) => void
}

export default function Table(props: TableProps) {
  const { data: _data, query, onItemClick } = props

  const data = _data.map((d) => {
    return {
      ...d,
      dtRealizacao: d?.dtRealizacao ? moment(d.dtRealizacao).format('DD/MM/yyyy') : '',
      codigoTreinamento: d?.codigoTreinamento ? formatCodigoTreinamento(d.codigoTreinamento) : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtRealizacao',
      label: 'Data da Realização',
    },
    {
      name: 'codigoTreinamento',
      label: 'Código do Treinamento',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                size="small"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      columns={columns}
      sherlock={{
        query,
        columns: ['dtRealizacao', 'codigoTreinamento'],
      }}
    />
  )
}
