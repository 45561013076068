import { useState } from 'react'

import api from '~/services/api-pessoal'
import consultaCNPJApi from '~/services/consultaCNPJApi'

const getCidade = async (municipio, uf) => {
  try {
    const response = await api.get('/Cidade')
    const cidadesPossiveis = response?.data?.data || []
    const cidade = cidadesPossiveis.find(
      (cidade) => `${cidade.uf}${cidade.nome}` === `${uf}${municipio}`,
    )
    return { cidade: cidade || null, cidadeId: cidade?.id || '' }
  } catch (error) {
    console.log(error)
  }
}

function getTelefone(telefone_) {
  if (!telefone_) return ''
  const telefone = telefone_.split('/')[0].replace(/[^0-9]/g, '')
  return telefone
}

export default function usePessoaJuridica() {
  const [isLoading, setLoading] = useState(false)

  const getFieldsPessoaJuridica = async (nrInscricao) => {
    setLoading(true)
    try {
      const response = await consultaCNPJApi.get(`/consultacnpj?cnpj=${nrInscricao}`)
      const pessoa = response?.data?.body || null
      if (!pessoa) return

      const { cep, uf, municipio } = pessoa
      const { cidade, cidadeId } = await getCidade(municipio, uf)

      const telefone = getTelefone(pessoa?.telefone)

      return {
        nome: (pessoa?.nome || '').toUpperCase(),
        cep: cep?.replace(/[^0-9]/g, '') || '',
        endereco: (pessoa?.logradouro || '').toUpperCase(),
        numero: pessoa?.numero || '',
        bairro: (pessoa?.bairro || '').toUpperCase(),
        complemento: (pessoa?.complemento || '').toUpperCase(),
        email: pessoa?.email || '',
        telefone,
        cidade,
        cidadeId,
        logradouroId: 'R',
        logradouro: { id: 'R', nome: 'RUA' },
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return { getFieldsPessoaJuridica, isLoading }
}
