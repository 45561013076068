import { IconButton } from '@material-ui/core'
import { Security as SecurityIcon } from '@material-ui/icons'

import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'

import DialogEPCEPI from './DialogEPCEPI'

import useDialog from '~/hooks/useDialog'

import { agNoc, epi } from '~/hooks/queries/ImportarXMLS2240/dtos/evtExpRisco'
import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  AgenteNocivoTipoAvaliacaoEnum,
  AgenteNocivoTipoAvaliacaoLabels,
} from '~/@types/enums/AgenteNocivoTipoAvaliacaoEnum'

interface DialogAgentesNocivosProps {
  isOpen: boolean
  onClose: () => void
  data: agNoc[]
}

export default function DialogAgentesNocivos({ isOpen, onClose, data }: DialogAgentesNocivosProps) {
  const {
    close: closeDialogEPCEPI,
    data: dataDialogEPCEPI,
    isOpen: isOpenDialogEPCEPI,
    open: openDialogEPCEPI,
  } = useDialog<epi[]>([])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codAgNoc',
      label: 'Agente Nocivo',
    },
    {
      name: 'dscAgNoc',
      label: 'Descrição',
    },
    {
      name: 'tpAval',
      label: 'Tipo Avaliação',
      options: {
        customBodyRender: (value: AgenteNocivoTipoAvaliacaoEnum) =>
          AgenteNocivoTipoAvaliacaoLabels[value],
      },
    },
    {
      name: 'intConc',
      label: 'Intensidade de Concentração',
    },
    {
      name: 'codAgNoc',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="EPI/EPC"
                color="primary"
                aria-label="abrir-epi-epc"
                onClick={() => {
                  const dtAtual = data.find((d) => d.codAgNoc === value)
                  if (dtAtual) {
                    openDialogEPCEPI(dtAtual.epcEpi.epi)
                  }
                }}
              >
                <SecurityIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ActionDialog
      title="Agentes Nocivos"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
      }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <ToolsDataTable data={data} columns={columns} disableAutoHeight pagination={false} />

      {isOpenDialogEPCEPI && (
        <DialogEPCEPI
          isOpen={isOpenDialogEPCEPI}
          onClose={closeDialogEPCEPI}
          data={dataDialogEPCEPI}
        />
      )}
    </ActionDialog>
  )
}
