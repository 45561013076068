import { useEffect, useState } from 'react'

import useAmbiente from '~/hooks/useAmbiente'
import { useHistory } from 'react-router-dom'

import usePainelAdmissoesChart from '~/hooks/queries/Painel/usePainelAdmissoesChart'

import ChartHeader from '../components/ChartHeader'
import CardFooter from '../components/CardFooter'
import ContainerChart from '../components/ContainerChart'

import {
  IndCardChartColor,
  IndCardChartEnum,
  IndCardChartIcon,
} from '~/@types/enums/IndCardChartEnum'

export default function AdmissoesChart() {
  const { anoMes, estabelecimento } = useAmbiente()
  const history = useHistory()

  const [isHover, setHover] = useState(false)
  const [estabelecimentoId, setEstabelecimentoId] = useState(estabelecimento?.id || '')

  useEffect(() => {
    setEstabelecimentoId(estabelecimento?.id || '')
  }, [estabelecimento])

  const {
    data,
    isLoading: _isLoading,
    isFetching,
  } = usePainelAdmissoesChart({
    anoMes,
    estabelecimentoId,
  })
  const indCard = data?.indCardChart || IndCardChartEnum.Keep

  const isLoading = _isLoading || isFetching

  const color = IndCardChartColor[indCard]
  const cardIcon = IndCardChartIcon[indCard]

  return (
    <ContainerChart onHover={setHover}>
      <ChartHeader
        title="Admissões"
        subtitle={(data?.total || 0).toString()}
        icon="people"
        isActiveAlternate={estabelecimentoId ? false : true}
        onClickRedirect={() => history.push('/resumo-admissao-demissao')}
        onClickAlternate={() => setEstabelecimentoId((prev) => (prev ? '' : estabelecimento.id))}
        isHover={isHover}
        isLoading={isLoading}
      />
      <CardFooter color={color} cardIcon={cardIcon} percent={data?.percent || 0} />
    </ContainerChart>
  )
}
