import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { query } from './useObterFuncionarios'

import { AntecipacaoSalarial } from './AntecipacaoSalarial'

interface RequestProps {
  antecipacaoSalarialId: string
  data: AntecipacaoSalarial
}

export default function useAtualizarAntecipacao() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest(props: RequestProps) {
    await api.put('/AntecipacaoSalarial/' + props.antecipacaoSalarialId, props.data)
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
    onSuccess: async () => {
      notification.put()
      await queryClient.invalidateQueries(query)
    },
  })
}
