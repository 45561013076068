import { useState } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { useAdicionar } from '~/hooks/queries/EventosNaoIntegrados/useAdicionar'

import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

import { AutoCompleteEvento, MUIAutoComplete } from '~/components/AutoComplete'

import { EventoNaoIntegradoAdicionarDTO } from '~/hooks/queries/EventosNaoIntegrados/dtos/EventoNaoIntegradoAdicionarDTO'

import { FPMovimentoTipoEnum, FPMovimentoTipoValues } from '~/@types/enums/FPMovimentoTipoEnum'
import { Evento } from '~/hooks/queries/Evento/Evento'

const schema = yup.object().shape({
  eventoId: yup.string().required('Informe um Evento'),
})

interface FormProps {
  isOpen: boolean
  modeloIntegracaoId: string
  onClose: () => void
}

export default function Form({ isOpen, onClose, modeloIntegracaoId }: FormProps) {
  const [data, setData] = useState({
    modeloIntegracaoId,
    fpMovimentoTipoEnum: FPMovimentoTipoEnum.ReciboMensal,
  } as EventoNaoIntegradoAdicionarDTO)

  const { mutateAsync: mutateAsyncAdicionar, isLoading } = useAdicionar()

  const handleSubmit = async () => {
    await mutateAsyncAdicionar({
      data,
    })
    onClose()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Modelo de Integração"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutoCompleteEvento
            value={data.eventoId}
            required
            validationErrors={validationErrors}
            name="eventoId"
            onChange={(_: FixLater, evento: Evento) => {
              const eventoId = evento?.id || ''
              const eventoCodigo = evento?.codigo || 0
              const eventoNome = evento?.nome || ''
              setData((prev) => ({ ...prev, eventoId, eventoCodigo, eventoNome }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIAutoComplete
            label="Tipo de movimento"
            optionId="value"
            options={FPMovimentoTipoValues}
            renderOption={(option) => option.name}
            value={data.fpMovimentoTipoEnum}
            onChange={(_, obj) => {
              const fpMovimentoTipoEnum = obj?.value || FPMovimentoTipoEnum.ReciboMensal
              setData((prev) => ({
                ...prev,
                fpMovimentoTipoEnum,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
