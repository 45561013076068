export enum IndCompetenciaChartEnum {
  NaoAbertas_00,
  Abertas_01,
  Fechadas_02,
}

export const IndCompetenciaChartLabel = {
  [IndCompetenciaChartEnum.NaoAbertas_00]: 'Não Abertas',
  [IndCompetenciaChartEnum.Abertas_01]: 'Abertas',
  [IndCompetenciaChartEnum.Fechadas_02]: 'Fechadas',
}

export const IndCompetenciaChartColor = {
  [IndCompetenciaChartEnum.NaoAbertas_00]: '#1679AB',
  [IndCompetenciaChartEnum.Abertas_01]: '#5BBCFF',
  [IndCompetenciaChartEnum.Fechadas_02]: '#7EA1FF',
}
