import { Pie } from 'react-chartjs-2'

import { useTheme } from '@material-ui/core'

import { Chart as ChartJS, SubTitle, ArcElement, Tooltip, Legend, Title } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle)

interface PieChartProps {
  subtitle: string
  progress: number
  total: number
}

export default function PieChart({ subtitle, progress, total }: PieChartProps) {
  const theme = useTheme()

  return (
    <Pie
      data={{
        labels: ['Finalizados', 'Processando'],
        datasets: [
          {
            data: [progress, total - progress],
            backgroundColor: [theme.palette.success.main, theme.palette.secondary.main],
          },
        ],
      }}
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Processamento Empregadores - ' + progress + '/' + total,
          },
          subtitle: {
            display: true,
            text: subtitle,
          },
        },
      }}
    />
  )
}
