import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface DialogProblemasProps {
  isOpen: boolean
  onClose: () => void
  data: string[]
}

export default function DialogProblemas({ isOpen, onClose, data: _d }: DialogProblemasProps) {
  const data = _d.map((d) => ({ descricao: d }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'descricao',
      label: 'Descrição',
    },
  ]

  return (
    <ActionDialog
      title="Problemas da tarefa"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <ToolsDataTable data={data} columns={columns} disableAutoHeight pagination={false} />
    </ActionDialog>
  )
}
