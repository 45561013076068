import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoLocal } from './VinculoLocal'

const query = '/VinculoLocal/GetByVinculo/'

export function useVinculoLocalGetByVinculo(vinculoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoLocal[] }>(query + vinculoId)
    return response?.data?.data || []
  }

  return useQuery([query, vinculoId], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
