import React, { useState, useCallback, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ActionDialog } from '~/components'
import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import RecibosFeriasErroAccordion from './components/RecibosFeriasErroAccordion'

const RecibosFeriasErro = (props) => {
  const { isOpen, onClose, onAfterDeleteRecibos, data } = props

  const {
    recibos,
    msg,
    vinculo,
    // lotacaoId
  } = data
  const notifications = useNotification()
  const dialogNotification = useDialogNotification()
  const [checked, setChecked] = useState([])
  const [isDeleting, setDeleting] = useState(false)

  useEffect(() => {
    setChecked([])
  }, [recibos])

  const handleToggleCheckbox = (value) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleDelete = useCallback(async () => {
    const checkedArrayHasItens = checked?.length > 0

    if (checkedArrayHasItens) {
      setDeleting(true)
      try {
        for (let i = 0; i < checked.length; i++) {
          await api.delete(`RP/${checked[i]}`)
        }
        notifications.success('Os recibos selecionados foram excluídos')
        onAfterDeleteRecibos(vinculo)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setDeleting(false)
      }
    }

    if (!checkedArrayHasItens) {
      notifications.error('Selecione pelo menos um recibo para ser excluído')
    }
    //eslint-disable-next-line
  }, [checked, onAfterDeleteRecibos])

  return (
    <ActionDialog
      title="Temos um aviso!"
      subtitle={vinculo?.vinculoNome}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Excluir Selecionados"
      isOkProcessing={isDeleting}
      onOkClick={handleDelete}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Box mb={4}>
        <Alert style={{ alignItems: 'center' }} severity="info">
          {msg}
        </Alert>
      </Box>
      <Box>
        {recibos?.map((recibo) => (
          <RecibosFeriasErroAccordion
            key={recibo.id}
            recibo={recibo}
            onToggleCheckbox={handleToggleCheckbox}
          />
        ))}
      </Box>
    </ActionDialog>
  )
}

export default RecibosFeriasErro
