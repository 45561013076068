import React from 'react'

import { Box, TableBody, TableCell, TableRow } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import MUITableRow from './components/MUITableRow'

function BaseBody(props: { children: React.ReactNode; colSpan: number }) {
  const { children, colSpan } = props

  return (
    <TableBody>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
          <Box display="flex" justifyContent="center">
            {children}
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

export default function MUITableBody(props: MUITableBodyProps) {
  const { displayData, data, columns, isLoading, options } = props

  function onClickExpand(indexCurrent: number) {
    if (options?.rowExpanded?.onRowExpanded) {
      const rowsExpanded = options.rowExpanded.rowsExpanded
      const onRowExpanded = options.rowExpanded.onRowExpanded

      const rowExpandedIndex = rowsExpanded.indexOf(indexCurrent)
      let newRowsExpanded: number[] = []

      if (rowExpandedIndex === -1) {
        newRowsExpanded = [...rowsExpanded, indexCurrent]
      }

      if (rowExpandedIndex >= 0) {
        newRowsExpanded = rowsExpanded.filter((_, index) => index !== rowExpandedIndex)
      }

      return onRowExpanded(newRowsExpanded)
    }
  }

  const hasData = data.length > 0

  const colSpanCurrent = options?.rowExpanded ? columns.length + 1 : columns.length

  if (isLoading)
    return (
      <BaseBody colSpan={colSpanCurrent}>
        <Skeleton style={{ width: '100%' }} />
      </BaseBody>
    )

  if (!hasData) return <BaseBody colSpan={colSpanCurrent}>Nenhum registro encontrado</BaseBody>

  return (
    <TableBody>
      {displayData.map((dt, index) => {
        const indexCurrent = data.findIndex((x) => JSON.stringify(x) === JSON.stringify(dt))
        return (
          <MUITableRow
            key={index}
            onClickExpand={onClickExpand}
            columns={columns}
            dataCurrent={dt}
            indexCurrent={indexCurrent}
            rowExpanded={options?.rowExpanded}
            rowSelected={options?.rowSelected}
          />
        )
      })}
    </TableBody>
  )
}
