import { useMemo, memo } from 'react'

import { makeStyles } from '@material-ui/core'

import { ToolsDataTable } from '~/components'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import useUtils, { setImportantCSS } from '~/hooks/useUtils'

const MemoToolsDataTable = memo(ToolsDataTable)

const useStyles = makeStyles((theme) => ({
  alignRight: {
    textAlign: 'right',
  },
  hightLightRow: {
    backgroundColor: setImportantCSS(theme.palette.error.light),
    color: setImportantCSS(theme.palette.error.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.error.lighter),
    },
  },
}))

export default function DetailsSegurados(props) {
  const { data: _data, isLoading } = props

  const classes = useStyles()
  const { formatCurrency, parseStringToFloat } = useUtils()

  const data = _data.map((d) => {
    return {
      ...d,
      nrInscricao: d?.nrInscricao ? formatToCPFOrCNPJ(d.nrInscricao) : '',
      vrContribuicaoDescontada: d?.vrContribuicaoDescontada
        ? formatCurrency(d.vrContribuicaoDescontada)
        : 0,
      vrContribuicaoSegurado: d?.vrContribuicaoSegurado
        ? formatCurrency(d.vrContribuicaoSegurado)
        : 0,
      vrDiferenca: d?.vrDiferenca ? formatCurrency(d.vrDiferenca) : 0,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'nrInscricao',
        label: 'CPF',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'vrContribuicaoDescontada',
        label: 'Contribuição Descontada',
        options: {
          setCellProps: () => ({ className: classes.alignRight }),
        },
      },
      {
        name: 'vrContribuicaoSegurado',
        label: 'Contribuição Segurado',
        options: {
          setCellProps: () => ({ className: classes.alignRight }),
        },
      },
      {
        name: 'vrDiferenca',
        label: 'Diferença',
        options: {
          setCellProps: () => ({ className: classes.alignRight }),
        },
      },
    ],
    [classes],
  )

  return (
    <MemoToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      disableAutoHeight
      options={{
        setRowProps: (row) => {
          const vrDiferenca = parseStringToFloat(row[row.length - 1])
          const isDiferenca = vrDiferenca > 0 ? true : false
          return {
            className: isDiferenca ? classes.hightLightRow : '',
          }
        },
      }}
    />
  )
}
