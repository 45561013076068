import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: string[]
  params: {
    estabelecimentoId: string
    anoMes: string
  }
}

export function useEnviarPorEmailRelatoriosAdmissionais() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{ data: string[] }>(
      '/RelatorioAdmissional/EnviarPorEmailRelatoriosAdmissionais',
      data,
      {
        params,
      },
    )
    const errors = response?.data?.data || null
    if (errors && errors.length > 0) {
      dialogNotification.warning({
        descriptions: errors,
      })
    }
  }

  return useMutation(handleRequest, {
    onSuccess: () =>
      notifySuccess('Os relatórios admissionais foram enviados para o email com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
