import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { RPMemoriaCalculoDTO } from './RPMemoriaCalculoDTO'

const queryObterMemoriaCalculo = '/RPMemoriaCalculo/ObterMemoriaCalculo'

interface RequestProps {
  rpItemId: string
}

export default function useObterMemoriaCalculo(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: RPMemoriaCalculoDTO }>(queryObterMemoriaCalculo, {
      params,
    })
    return response.data.data
  }

  return useQuery([queryObterMemoriaCalculo, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
