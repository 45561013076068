export enum ImigranteCondicaoTrabalhadorEnum {
  Refugiado_1 = 1,
  SolicitanteDeRefugio_2 = 2,
  PermanênciaNoBrasilReuniaoFamiliar_3 = 3,
  BeneficiadoPeloAcordoMercosul_4 = 4,
  DependenteDeAagenteDiplomático_ExercícioAtividadeRemuneradaBrasil_5 = 5,
  BeneficiadoPeloTratadoDeAmizadeRepublicaPortuguesa_6 = 6,
  OutraCondição_7 = 7,
  NaoAplicavel_9 = 9,
}
