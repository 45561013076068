import { useState } from 'react'
import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, TextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useAtualizarDocumento } from '~/hooks/queries/GerenciadorDocumentos/useAtualizarDocumento'

import { TITLE_PAGE } from '..'

import { GerenciadorDocumentos } from '~/hooks/queries/GerenciadorDocumentos/GerenciadorDocumentos'

const schema = yup.object().shape({
  descricao: yup.string().required('Informe a Descrição do Documento'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: GerenciadorDocumentos
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data } = props

  const [data, setData] = useState(_data)

  async function handleSubmit() {
    await mutateAsync({
      documentoId: _data.id,
      gerenciadorDocumentos: data,
    })
    onClose()
  }

  const { mutateAsync, isLoading } = useAtualizarDocumento()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title={TITLE_PAGE}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Descrição do Documento"
            value={data.descricao}
            name="descricao"
            validationErrors={validationErrors}
            required
            onChange={(e) => {
              const descricao = e.target.value
              setData((prev) => ({
                ...prev,
                descricao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
