import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { ToolsDataTable } from '~/components'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import moment from 'moment'
import { ClassTribValues } from '~/@types/enums/ClassTribEnum'

function formatClassificacaoTributaria(classificacaoTributaria) {
  const objectFinded = ClassTribValues.find((obj) => obj.value === classificacaoTributaria)
  return objectFinded.name
}

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = useMemo(
    () => [
      {
        name: 'dtSituacao',
        label: 'Data Situação',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'classificacaoTributaria',
        label: 'Classificação Tributária',
        options: {
          customBodyRender: (value) => formatClassificacaoTributaria(value),
        },
      },
      {
        name: 'naturezaJuridicaId',
        label: 'Natureza Jurídica',
        options: {
          customBodyRender: (value) => (value ? `${value.substr(0, 3)}-${value.substr(3)}` : ''),
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <ToolsDataTable data={data} columns={columns} isLoading={isLoading} disableAutoHeight />
}

export default Table
