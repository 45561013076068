import { Box, makeStyles } from '@material-ui/core'
import Button from '~/components/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}))

interface ActionsButtonsProps {
  onSubmit: () => void
  onCancel: () => void
}

export default function ActionsButtons(props: ActionsButtonsProps) {
  const { onSubmit, onCancel } = props

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Button onClick={onCancel} fullWidth>
        Cancelar
      </Button>
      <Button variant="contained" fullWidth onClick={onSubmit}>
        Salvar
      </Button>
    </Box>
  )
}
