import { convertToArrayDescription } from '~/utils/utils'

export enum TipoCCPEnum {
  Nao_Informar_0 = 0,
  CCP_Ambito_Empresa_1 = 1,
  CCP_Ambito_Sindicato_2 = 2,
  NINTER_3 = 3,
}

export const TipoCCPLabels = {
  [TipoCCPEnum.Nao_Informar_0]: '0 - Não informar',
  [TipoCCPEnum.CCP_Ambito_Empresa_1]: '1 - CCP no âmbito de empresa',
  [TipoCCPEnum.CCP_Ambito_Sindicato_2]: '2 - CCP no âmbito de sindicato',
  [TipoCCPEnum.NINTER_3]: '3 - NINTER',
}

export const TipoCCPValues = convertToArrayDescription(TipoCCPEnum, TipoCCPLabels)
