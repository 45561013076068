import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'

import { Box, Dialog, IconButton, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { Close as CloseIcon } from '@material-ui/icons'

import { Button } from '~/components'

import ReciboPagamentoContainer from '~/pages/ReciboPagamento'

import useAmbiente from '~/hooks/useAmbiente'

const HEIGHT_FOOTER = 45

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface DialogReciboPagamentoProps {
  isOpen: boolean
  onClose: () => void
}

export default function DialogReciboPagamento(props: DialogReciboPagamentoProps) {
  const { isOpen, onClose } = props
  const [isLoading, setLoading] = useState(false)

  const { estabelecimento } = useAmbiente()

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = 'Tem certeza que deseja sair? Você pode perder dados não salvos.'
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  async function handleClose() {
    setLoading(true)
    try {
      await api.delete(
        `/VinculoRescisaoSimulada/ExcluirRecibosEResetarVinculos/${estabelecimento.id}`,
      )
    } catch (err) {
      console.log(err)
    } finally {
      onClose()
      setLoading(false)
    }
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
      <Box position="absolute" right={0} top={0} padding={1}>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box pt={3} height={`calc(100% - ${HEIGHT_FOOTER}px)`}>
        <ReciboPagamentoContainer />
      </Box>
      <Box display="flex" justifyContent="flex-end" width="100%" padding={1} height={HEIGHT_FOOTER}>
        <Button isLoading={isLoading} onClick={handleClose} variant="contained">
          Fechar
        </Button>
      </Box>
    </Dialog>
  )
}
