import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifySuccess } from '~/utils/notification'

import { FeriasVencidasRelatorioEnum } from '~/@types/enums/FeriasVencidasRelatorioEnum'

interface RequestProps {
  data: string[]
  params: {
    dtLimite: string
    anoMes: string
    agrupar: FeriasVencidasRelatorioEnum
    setorId: string | null
  }
}

export default function useEnviarPorEmail() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    await api.post('/Relatorio/FeriasVencidas/EnviarPorEmail', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Os relatórios foram enviados com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
