import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { Vinculo } from './Vinculo'

export function useVinculoById(vinculoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: Vinculo }>(`/Vinculo/${vinculoId}`)
    return response.data.data
  }

  return useQuery(['VinculoById', vinculoId], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
