import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryObterDocumentos } from './useObterDocumentos'
import { GerenciadorDocumentos } from './GerenciadorDocumentos'

interface RequestProps {
  documentoId: string
  gerenciadorDocumentos: GerenciadorDocumentos
}

export function useAtualizarDocumento() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    const { documentoId, gerenciadorDocumentos } = props

    await api.put('/GerenciadorDocumentos/AtualizarDocumento', gerenciadorDocumentos, {
      params: {
        documentoId,
      },
    })
    notification.success('Documento atualizado com Sucesso')
  }

  return useMutation(handleRequest, {
    onSuccess: async () => await queryClient.invalidateQueries(queryObterDocumentos),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
