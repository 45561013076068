import { useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog } from '~/components'

import CardEventos from '../Content/CardEventos'

import { useAtualizarEmpregadoresEsocial } from '~/hooks/queries/EmpregadorEsocial/useAtualizarEmpregadoresEsocial'

import {
  eventosNaoPeriodicos,
  eventosPeriodicos,
  eventosProcessoTrabalhista,
  eventosSST,
  eventosTabela,
} from '..'

import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'
import { EmpregadorEsocial } from '~/hooks/queries/EmpregadorEsocial/EmpregadorEsocial'

interface ConfigSeveralsProps {
  data: EmpregadorEsocial[]
  isOpen: boolean
  onClose: () => void
}

export default function DialogSeverals(props: ConfigSeveralsProps) {
  const { data, isOpen, onClose } = props

  const [eventosSelecionados, setEventosSelecionados] = useState<ESocialEventoEnum[]>([])

  const { mutateAsync, isLoading } = useAtualizarEmpregadoresEsocial()

  function handleCancel() {
    onClose()
  }

  async function handleSave() {
    const newCollection = data.map((eventoEsocial) => {
      return {
        ...eventoEsocial,
        evento: eventosSelecionados,
      }
    })
    await mutateAsync(newCollection)
    onClose()
  }

  return (
    <ActionDialog
      title="Configurar vários Empregadores"
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
      okLabel="Configurar Empregadores"
      isOkProcessing={isLoading}
      onCancelClick={handleCancel}
      onOkClick={handleSave}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardEventos
            eventos={eventosTabela}
            label="Eventos de Tabela"
            value={eventosSelecionados}
            onChange={setEventosSelecionados}
          />
        </Grid>

        <Grid item xs={12}>
          <CardEventos
            eventos={eventosPeriodicos}
            label="Eventos Periódicos"
            value={eventosSelecionados}
            onChange={setEventosSelecionados}
          />
        </Grid>

        <Grid item xs={12}>
          <CardEventos
            eventos={eventosNaoPeriodicos}
            label="Eventos Não Periódicos"
            value={eventosSelecionados}
            onChange={setEventosSelecionados}
          />
        </Grid>

        <Grid item xs={12}>
          <CardEventos
            eventos={eventosSST}
            label="Eventos SST"
            value={eventosSelecionados}
            onChange={setEventosSelecionados}
          />
        </Grid>

        <Grid item xs={12}>
          <CardEventos
            eventos={eventosProcessoTrabalhista}
            label="Eventos Processo Trabalhista"
            value={eventosSelecionados}
            onChange={setEventosSelecionados}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
