export enum ASOIndResultadoEnum {
  Normal_1 = 1,
  Alterado_2 = 2,
  Estável_3 = 3,
  Agravamento_4 = 4,
}

export const ASOIndResultadoLabels = {
  [ASOIndResultadoEnum.Normal_1]: 'Normal',
  [ASOIndResultadoEnum.Alterado_2]: 'Alterado',
  [ASOIndResultadoEnum.Estável_3]: 'Estável',
  [ASOIndResultadoEnum.Agravamento_4]: 'Agravamento',
}

export const ASOIndResultadoValues = [
  { value: ASOIndResultadoEnum.Normal_1, name: 'Normal' },
  { value: ASOIndResultadoEnum.Alterado_2, name: 'Alterado' },
  { value: ASOIndResultadoEnum.Estável_3, name: 'Estável' },
  { value: ASOIndResultadoEnum.Agravamento_4, name: 'Agravamento' },
]
