import { useState } from 'react'

import api from '~/services/api-pessoal'

import {
  PageHeader,
  Button,
  ButtonBox,
  Stack,
  ContainerTable,
  ConfirmDeleteDialog,
} from '~/components'

import Table, { TableActions } from './components/Table'
import Form, { AfterSubmitActions } from './components/Form'

import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import useGetByVinculo from '~/hooks/queries/VinculoFerias/useGetByVinculo'

import { VinculoMenuItem } from '~/hooks/queries/useEstabelecimento'
import { VinculoFerias as VinculoFeriasType } from '~/hooks/queries/VinculoFerias/VinculoFerias'

interface VinculoFeriasProps {
  vinculo: VinculoMenuItem
  noTitle?: boolean
}

export default function VinculoFerias({ vinculo, noTitle = false }: VinculoFeriasProps) {
  const [isDeleting, setDeleting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const {
    data: _data,
    isLoading,
    isFetching,
    refetch,
  } = useGetByVinculo({ vinculoId: vinculo?.vinculoId, isApenasAbertas: false })
  const data = _data || []

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoFeriasType | null>(null)

  async function handleAfterSubmitForm(event: AfterSubmitActions, data: VinculoFeriasType) {
    closeForm()
    await refetch()
    if (event === AfterSubmitActions.insert) {
      openForm(data)
    }
  }

  function handleClickAdd() {
    openForm(null)
  }

  function handleClick(event: TableActions, d: VinculoFeriasType) {
    switch (event) {
      case TableActions.edit:
        openForm(d)
        break
      case TableActions.delete:
        openConfirmDelete(d.id)
        break
    }
  }

  async function handleConfirmDelete() {
    setDeleting(true)
    try {
      await api.delete(`/vinculoFerias/${dataConfirmDelete}`)
      await refetch()
      closeConfirmDelete()
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setDeleting(false)
    }
  }

  const title = !noTitle && {
    title: 'Férias',
    subtitle: vinculo.vinculoNome || '-',
  }

  return (
    <Stack height="100%">
      <PageHeader {...title}>
        <ButtonBox top={1}>
          <Button size="small" color="primary" onClick={handleClickAdd} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <ContainerTable>
        <Table
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          onItemClick={handleClick}
        />
      </ContainerTable>

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          data={dataForm}
          onClose={closeForm}
          onAfterSubmitForm={handleAfterSubmitForm}
          dataInitial={{ vinculoId: vinculo.vinculoId } as VinculoFeriasType}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isDeleting}
          onCancel={closeConfirmDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </Stack>
  )
}
