import { formatToCNPJ } from 'brazilian-values'

import { Box, Grid, Paper, Typography, useTheme } from '@material-ui/core'

import { CopyLabel, LabelValueColor } from '~/components'

import { formatAnoMes, formatCurrency } from '~/hooks/useUtils'
import { PlanilhaExtracaoExibicaoDTO } from '~/hooks/queries/ImportarEventosRP/ImportarEventosRPDTO'

interface HeaderProps {
  data?: PlanilhaExtracaoExibicaoDTO
}

export default function Header({ data }: HeaderProps) {
  const theme = useTheme()

  return (
    <Box component={Paper} p={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={9}>
          <Typography
            style={{
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            Competência:
            <Box fontWeight="bolder" fontSize="12pt" color="#666">
              {data?.competencia ? formatAnoMes(data.competencia) : ''}
            </Box>
          </Typography>
          <Typography
            style={{
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            Estabelecimento:
            <CopyLabel value={data?.estabelecimentoNrInscricao || 'Estabelecimento'}>
              <Box fontWeight="bolder" fontSize="12pt" color="#2E8BC0">
                {data?.estabelecimentoNome
                  ? data?.estabelecimentoNome +
                    ' - ' +
                    formatToCNPJ(data.estabelecimentoNrInscricao)
                  : ''}
              </Box>
            </CopyLabel>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)} height="100%">
            <LabelValueColor label="Funcionários:" value={data?.funcionarios.length || ''} />
            <LabelValueColor label="Eventos:" value={data?.eventosTotais.length || ''} />
            <LabelValueColor
              label="Total:"
              value={data?.total ? formatCurrency(data.total) : ''}
              showDivider={false}
              color="#2E8BC0"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
