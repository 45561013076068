import React from 'react'

import { Box, ButtonProps as MuiButtonProps, useTheme } from '@material-ui/core'

import Button from '../Button'

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean
  children: React.ReactNode
  icon: React.ElementType
}

export default function IconLabelButton(props: ButtonProps) {
  const { children, icon: Icon, ...rest } = props

  const theme = useTheme()

  return (
    <Button size="small" variant="contained" color="primary" {...rest}>
      <Box display="flex" gridGap={theme.spacing(1)} alignItems="center">
        <Icon />
        {children}
      </Box>
    </Button>
  )
}
