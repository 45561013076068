import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { Empregador } from './Empregador'

export interface DataEmpregadorCreate {
  data: Empregador
}

export function useEmpregadorCreate() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: DataEmpregadorCreate) {
    props.data.nome = props.data.nome.trim()
    const response = await api.post<{ data: Empregador }>('/Empregador/Criar', props.data)

    return response.data.data || null
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.post()
      await queryClient.invalidateQueries('Empregador')
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
