import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import moment from 'moment'
import { HeightDataTable } from '~/components'

const Table = (props) => {
  const { data: _data, onItemClick, isLoading } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtInicio: item?.dtInicio ? moment(item.dtInicio).format('DD/MM/YYYY') : null,
      dtFim: item?.dtFim ? moment(item.dtFim).format('DD/MM/YYYY') : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtInicio',
        label: 'Data de Início',
      },
      {
        name: 'dtFim',
        label: 'Data de Fim',
      },
      {
        name: 'descricao',
        label: 'Descrição',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
