import { useState } from 'react'

import api from '~/services/api-pessoal'
import { VinculoAdmissaoPreliminar } from './VinculoAdmissaoPreliminar'

export default function useVinculoAdmissaoPreliminarGetByVinculo() {
  const [isLoading, setLoading] = useState(false)

  async function getAdmissaoPreliminar(nrInscricao: string, empregadorId: string) {
    setLoading(true)
    try {
      const response = await api.get<{ data: VinculoAdmissaoPreliminar }>(
        'VinculoAdmissaoPreliminar/GetByVinculo',
        {
          params: {
            empregadorId,
            nrInscricao,
          },
        },
      )
      return response?.data?.data || null
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    isLoading,
    getAdmissaoPreliminar,
  }
}
