import {
  useTheme,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { PlanilhaExtracaoExibicaoEventoDTO } from '~/hooks/queries/ImportarEventosRP/ImportarEventosRPDTO'

import { formatCurrency } from '~/hooks/useUtils'

interface PlanilhaExtracaoExibicaoEventoDTOFooter extends PlanilhaExtracaoExibicaoEventoDTO {
  style: React.CSSProperties
}

interface FooterProps {
  data: PlanilhaExtracaoExibicaoEventoDTO[]
}

export default function Footer({ data: _data }: FooterProps) {
  const theme = useTheme()

  const data: PlanilhaExtracaoExibicaoEventoDTOFooter[] = []

  data.push(
    ...[
      {
        colunaId: 'funcionarioNome',
        colunaLabel: 'Nome',
        colunaOrdem: 0,
        valor: 0,
        style: {
          width: 315,
        },
      },
      {
        colunaId: 'funcionarioCPF',
        colunaLabel: 'CPF',
        colunaOrdem: 0,
        valor: 0,
        style: {
          width: 110,
        },
      },
    ],
  )

  data.push(
    ..._data.map<PlanilhaExtracaoExibicaoEventoDTOFooter>((d) => ({
      ...d,
      style: { textAlign: 'end', width: 100 },
    })),
  )

  return (
    <TableContainer component={Paper}>
      {data ? (
        <Table aria-label="footer-importacao-planilha">
          <TableBody>
            <TableRow>
              {data.map((row) => (
                <TableCell key={row.colunaId} style={{ ...row.style, padding: theme.spacing(0.5) }}>
                  {row.valor ? formatCurrency(row.valor) : '-'}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <Typography>Totais dos eventos</Typography>
      )}
    </TableContainer>
  )
}
