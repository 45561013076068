const naoInformar_0 = 0
const inicial_1 = 1
const sequencial_2 = 2

export const ASOOrdemExameValues = [
  { value: naoInformar_0, name: 'Não Informar' },
  { value: inicial_1, name: '1 - Inicial' },
  { value: sequencial_2, name: '2 - Sequencial' },
]

export const ASOOrdemExameConsts = {
  naoInformar_0,
  inicial_1,
  sequencial_2,
}
