import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Delete as DeleteIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

const Table = (props) => {
  const { data: _data, isLoading, query, onItemClick } = props

  const data = _data.map((item) => {
    return {
      ...item,
      nome: item?.nome || '',
      nrInscricao: formatToCPFOrCNPJ(item?.nrInscricao || ''),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'nrInscricao'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
