import React from 'react'

import moment from 'moment'

import { ButtonBox } from 'mio-library-ui'

import { IconButton, makeStyles } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import { useVinculoEventoAutomaticoGetAllPorVinculo } from '~/hooks/queries/useVinculoEventoAutomatico'
import { formatCurrency } from '~/hooks/useUtils'

import { MUIDataTableColumnDef } from 'mui-datatables'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

interface TableProps {
  vinculoId: string
  onItemClick: (event: 'edit' | 'delete', idRow: string) => void
  query: string
}

export default function Table(props: TableProps) {
  const { vinculoId, onItemClick, query } = props

  const classes = useStyles()
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useVinculoEventoAutomaticoGetAllPorVinculo(vinculoId)
  const data = _data
    ? _data.map((d) => ({
        ...d,
        nome: d?.evento?.nome || '',
        codigo: d?.evento?.codigo || '',
        dtInicio: d?.dtInicio ? moment(d.dtInicio).format('DD/MM/YYYY') : '',
        dtFim: d?.dtFim ? moment(d.dtFim).format('DD/MM/YYYY') : '',
        vrEvento: d?.vrEvento ? formatCurrency(d?.vrEvento) : '',
      }))
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Evento',
    },
    {
      name: 'dtInicio',
      label: 'Data Início',
    },
    {
      name: 'dtFim',
      label: 'Data Fim',
    },
    {
      name: 'quantidade',
      label: 'Quantidade',
    },
    {
      name: 'vrEvento',
      label: 'Valor do Evento',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'dtInicio', 'dtFim'],
      }}
    />
  )
}
