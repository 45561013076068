import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer, Finder } from '~/components'

import Table from './components/Table'
import DialogConfirm, { FormDataSubmitProps } from './components/DialogConfirm'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { useVinculoObterVinculosPorEstabelecimento } from '~/hooks/queries/Vinculo/useVinculoObterVinculosPorEstabelecimento'

export interface RelTermoRequestBodyDTO {
  funcionariosIds: string[]
  estabelecimentoId: string
  dtAssinatura: Date | string
}

const PAGE_TITLE = 'Termo de Autorização de Uso de Imagem e Voz'

export default function TermoAutorizacaoImagemVoz() {
  const [query, setQuery] = useState('')
  const [rowsSelecteds, setRowsSelected] = useState<number[]>([])

  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog(null)
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<RelTermoRequestBodyDTO | null>(null)
  const { estabelecimento } = useAmbiente()
  const { data, isLoading, isFetching } = useVinculoObterVinculosPorEstabelecimento(
    estabelecimento.id,
  )

  useEffect(() => {
    setRowsSelected([])
  }, [estabelecimento])

  function handleAfterSubmitForm(dt: FormDataSubmitProps) {
    openPDFViewer({
      estabelecimentoId: estabelecimento.id,
      funcionariosIds: rowsSelecteds.map((index) => data[index].id),
      dtAssinatura: dt.dtAssinatura,
    })
    setRowsSelected([])
  }

  function handleOpenDialogConfirm() {
    openDialogConfirm(null)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={PAGE_TITLE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Table
        query={query}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelecteds}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
      />

      {isOpenDialogConfirm && (
        <DialogConfirm
          isOpen={isOpenDialogConfirm}
          onClose={closeDialogConfirm}
          onAfterSubmit={handleAfterSubmitForm}
        />
      )}

      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={handleOpenDialogConfirm} disabled={!(rowsSelecteds.length > 0)} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="TermoAutorizacaoImagemVoz"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/RelTermoAutorizacaoImagemUso/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
