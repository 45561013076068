import React from 'react'
import { TreeItem } from '@material-ui/lab'

import CardItem from '../CardItem'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  removeIcon: {
    display: 'none',
  },
}))

export default function ThirdLevel(props) {
  const { reciboCurrent, index, handleOnLabelClick } = props

  const classes = useStyles()

  return (
    <TreeItem
      nodeId={reciboCurrent.reciboId || `recibo-${index}`}
      label={<CardItem labelText={`${reciboCurrent.idfDesc}`} />}
      onLabelClick={(e) => {
        e.preventDefault()
        handleOnLabelClick('reciboSelecionado', reciboCurrent.reciboId)
      }}
      onKeyDown={(e) => {
        const { key } = e
        if (key === 'Enter') {
          handleOnLabelClick('reciboSelecionado', reciboCurrent.reciboId, false)
        }
      }}
      classes={{
        iconContainer: classes.removeIcon,
      }}
    />
  )
}
