import React, { useState } from 'react'

import * as yup from 'yup'
import { isCPF } from 'brazilian-values'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid } from '@material-ui/core'

import {
  DatePickerNew,
  NumeroInscricaoTextField,
  TextField,
  Button,
  PageHeader,
  CurrencyTextField,
  Checkbox,
} from '~/components'
import {
  AutoCompleteAgenteIntegracao,
  AutoCompleteInstituicaoEnsino,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useStepperContext } from '~/components/StepperForm'
import { NivelEstagioValues } from '~/@types/enums/NivelEstagioEnum'
import { MainDataForm } from '..'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const schema = yup.object().shape({
  nivelEstagio: yup.string().required('Informe o Nível do Estágio'),
  dtPrevistaTermino: yup
    .date()
    .required('Informe a Data Prevista para o Término')
    .typeError('Informe uma data válida')
    .nullable(),
  supervisorCPF: yup
    .string()
    .nullable()
    .test('cpf-validate', 'Informe um CPF válido', (supervisorCPF: FixLater) => {
      if (supervisorCPF?.length > 0) return isCPF(supervisorCPF)
      return true
    }),
  instituicaoEnsinoId: yup.string().required('Informe a Instituição de Ensino'),
})

export default function FormInfoEstagio() {
  const { mainData, onNext, onBack } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataEstagio)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleBack() {
    onBack()
  }

  function handleSubmit() {
    onNext({
      ...mainData,
      dataEstagio: data,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <PageHeader title="Informações do Estágio" />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
        <MUIAutoComplete
          label="Nível do Estágio"
          required
          validationErrors={validationErrors}
          name="nivelEstagio"
          options={NivelEstagioValues}
          renderOption={(option) => option.name}
          optionId="value"
          value={data?.nivelEstagio || ''}
          onChange={(e, obj) => {
            const nivelEstagio = obj ? obj.value : ''
            setData((oldState) => ({
              ...oldState,
              nivelEstagio,
            }))
          }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <Checkbox
          label="Estágio Obrigatório"
          value={data?.naturezaEstagio || false}
          onChange={(e, value) => {
            const naturezaEstagio = value
            setData((oldState) => ({ ...oldState, naturezaEstagio }))
          }}
        />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
        <CurrencyTextField
          label="Valor da Bolsa"
          fullWidth
          value={data?.vrBolsa || ''}
          variant="outlined"
          size="small"
          onChange={(e, value) => {
            const vrBolsa = value
            setData((oldState) => ({ ...oldState, vrBolsa }))
          }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <DatePickerNew
          label="Data Prevista para o Término"
          required
          validationErrors={validationErrors}
          name="dtPrevistaTermino"
          size="small"
          value={data?.dtPrevistaTermino}
          onChange={(date) => {
            const dtPrevistaTermino = date as FixLater
            setData((oldState) => ({
              ...oldState,
              dtPrevistaTermino,
            }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Área de Atuação"
          fullWidth
          value={data?.areaAtuacao || ''}
          size="small"
          inputProps={{
            maxLength: 50,
          }}
          onChange={(e) => {
            const areaAtuacao = e?.target?.value || ''
            setData((oldState) => ({ ...oldState, areaAtuacao }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Número da Apólice"
          fullWidth
          value={data?.numeroApolice || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 30,
          }}
          onChange={(e) => {
            const numeroApolice = e?.target?.value || ''
            setData((oldState) => ({ ...oldState, numeroApolice }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompleteAgenteIntegracao
          value={data?.agenteIntegracaoId || ''}
          optionId="id"
          onChange={(e, obj) => {
            const agenteIntegracaoId = obj?.id || ''
            setData((oldState) => ({ ...oldState, agenteIntegracaoId }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompleteInstituicaoEnsino
          value={data?.instituicaoEnsinoId || ''}
          optionId="id"
          required
          validationErrors={validationErrors}
          name="instituicaoEnsinoId"
          onChange={(e, obj) => {
            const instituicaoEnsinoId = obj?.id || ''
            setData((oldState) => ({ ...oldState, instituicaoEnsinoId }))
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <TextField
          label="Nome do Supervisor"
          fullWidth
          value={data?.supervisorNome || ''}
          size="small"
          inputProps={{
            maxLength: 70,
          }}
          onChange={(e) => {
            const supervisorNome = e?.target?.value || ''
            setData((oldState) => ({ ...oldState, supervisorNome }))
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <NumeroInscricaoTextField
          label="CPF do Supervisor"
          validationErrors={validationErrors}
          name="supervisorCPF"
          typeMask={TipoInscricaoEnum.CPF_2}
          value={data?.supervisorCPF || ''}
          onChange={(e, value) => {
            const supervisorCPF = value
            setData((oldState) => ({ ...oldState, supervisorCPF }))
          }}
        />
      </Grid>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
