import React, { useState, useEffect } from 'react'

import { ContentDivider } from 'mio-library-ui'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useUtils from '~/hooks/useUtils'

import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'
import _ from 'lodash'

const TabelaINSS = () => {
  const [data, setData] = useState({
    isLoading: false,
    item: [],
  })

  const dialogNotification = useDialogNotification()
  const { formatMonth } = useUtils()
  const { anoMes } = useAmbiente(false, true)

  useEffect(() => {
    const getCollection = async () => {
      setData((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get('/tabelaINSS')
        if (response.data.data) {
          const newArray = []
          response.data.data.forEach((item) => {
            if (item?.anoMes <= anoMes) {
              newArray.push(item)
            }
          })
          let newItem = {}

          newItem = _.orderBy(newArray, 'anoMes', 'desc')[0]

          if (!newItem) newItem = _.orderBy(response.data.data, 'anoMes', 'asc')[0]

          setData((oldState) => ({
            ...oldState,
            item: objectInDataForTable(newItem),
            anoMes: newItem?.anoMes,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setData((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [anoMes])

  const objectInDataForTable = (obj) => {
    return [
      {
        faixa: 'I',
        vrFaixaInit: 0,
        vrFaixaEnd: obj?.vrFaixaI,
        aliqFaixa: obj?.aliqFaixaI,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaI,
      },

      {
        faixa: 'II',
        vrFaixaInit: obj?.vrFaixaI,
        vrFaixaEnd: obj?.vrFaixaII,
        aliqFaixa: obj?.aliqFaixaII,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaII,
      },

      {
        faixa: 'III',
        vrFaixaInit: obj?.vrFaixaII,
        vrFaixaEnd: obj?.vrFaixaIII,
        aliqFaixa: obj?.aliqFaixaIII,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaIII,
      },

      {
        faixa: 'IV',
        vrFaixaInit: obj?.vrFaixaIII,
        vrFaixaEnd: obj?.vrFaixaIV,
        aliqFaixa: obj?.aliqFaixaIV,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaIV,
      },
    ]
  }

  return (
    <>
      <ContentDivider
        title={`${data?.anoMes?.substr(0, 4)} / ${formatMonth(data?.anoMes?.substr(4))}`}
        showDivider={false}
        bottom={1}
      />
      <Table data={data.item} isLoading={data.isLoading} />
    </>
  )
}

export default TabelaINSS
