import React from 'react'

import { ContainerComponent } from '~/components'

import TabelaSalario from './components/TabelaSalario'
import ContainerTabelas from './components/ContainerTabelas'
import TabelaMes from './components/TabelaMes'

const TabelasIncidencias = (props) => {
  const { isOpen, onClose } = props

  return (
    <ContainerComponent
      title="Tabelas de Incidências"
      isOpen={isOpen}
      onClose={onClose}
      tabs={[
        {
          component: <TabelaSalario />,
          label: 'Salário',
        },
        {
          component: <ContainerTabelas />,
          label: 'INSS/IR/SF',
        },
        {
          component: <TabelaMes />,
          label: 'Tabela Mês',
        },
      ]}
    />
  )
}

export default TabelasIncidencias
