import { convertToArrayDescription } from '~/utils/utils'

export enum IndCompetenciaProcessEnum {
  Mensal_00,
  DecimoTerceiro_01,
}

export const IndCompetenciaProcessLabel = {
  [IndCompetenciaProcessEnum.Mensal_00]: 'Mensal',
  [IndCompetenciaProcessEnum.DecimoTerceiro_01]: 'Décimo Terceiro',
}

export const IndCompetenciaProcessValues = convertToArrayDescription(
  IndCompetenciaProcessEnum,
  IndCompetenciaProcessLabel,
)
