import React, { useEffect, useState } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import api from '~/services/api-pessoal'

import { Button, Finder } from 'mio-library-ui'
import { Box, CircularProgress, Slide, useTheme } from '@material-ui/core'

import { PageHeader, PDFViewer, PrintButton, ToolsDataTable } from '~/components'

import TableExpanded from './components/TableExpanded'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

import { timeAnimation } from '../..'

const columns = [
  {
    name: 'estabelecimentoCodigo',
    label: 'Código',
  },
  {
    name: 'estabelecimentoNrInscricao',
    label: 'Número de Inscrição',
  },
  {
    name: 'estabelecimentoNome',
    label: 'Nome',
  },
  {
    name: 'estabelecimentoEmail',
    label: 'Email',
  },
]

export default function ResultRelatorioFeriasConcedidas() {
  const [collection, setCollection] = useState([])
  const [rowsExpanded, setRowsExpanded] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isLoadingSendToEmail, setLoadingSendToEmail] = useState(false)
  const [query, setQuery] = useState('')
  const [report, setReport] = useState({})

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { state } = useLocation()
  const history = useHistory()
  const theme = useTheme()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  const isPageLoading = state ? false : true

  useEffect(() => {
    async function getCollection() {
      if (isPageLoading) return returnPrevPage()
      setLoading(true)
      try {
        const response = await api.post('/Relatorio/FeriasConcedidas/ObterFeriasConcedidas', state)
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [state, isPageLoading])

  useEffect(() => {
    async function getReportKey() {
      const response = await api.get('/Relatorio/GetByChaveId', {
        params: {
          chaveId: 'FeriasConcedidas',
        },
      })
      setReport(response?.data?.data[0] || {})
    }
    getReportKey()
  }, [])

  function returnPrevPage() {
    history.push({
      pathname: '/form-relatorio-ferias-concedidas',
      state,
    })
  }

  function expandedChildren(index) {
    const dataCurrent = collection[index]?.funcionarios || []
    return <TableExpanded data={dataCurrent} />
  }

  function handlePrint() {
    openPDFViewer(collection)
  }

  async function handleSendToEmail() {
    setLoadingSendToEmail(true)
    try {
      await api.post('/Relatorio/FeriasConcedidas/EnviarPorEmail', collection, {
        headers: {
          'dc-printmode': 'PDF',
          'dc-printrelatorioid': report?.id,
        },
      })
      notification.success('Os relatórios foram enviados com sucesso')
      history.push('/form-relatorio-ferias-concedidas')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingSendToEmail(false)
    }
  }

  if (isPageLoading) return <></>

  const isHaveColletion = collection.length > 0

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={timeAnimation}>
      <Box height="100%" width="100%" p={2}>
        <Box height={50}>
          <PageHeader title="Relatório de Férias Concedidas" onBack={() => returnPrevPage()}>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          </PageHeader>
        </Box>
        <ToolsDataTable
          data={collection}
          isLoading={isLoading}
          columns={columns}
          optionsExpandable={{
            type: 'multiple',
            expandedChildren,
            onRowExpanded: setRowsExpanded,
            rowsExpanded,
            expandOnClick: true,
          }}
          sherlock={{
            query,
            columns: ['estabelecimentoCodigo', 'estabelecimentoNome', 'estabelecimentoNrInscricao'],
          }}
        />
        <Box display="flex" justifyContent="flex-end" pt={1} gridGap={theme.spacing(1)}>
          {isLoading ? (
            <CircularProgress size={25} />
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleSendToEmail}
                title="Enviar para o email de cada estabelecimento"
                isLoading={isLoadingSendToEmail}
                disabled={!isHaveColletion}
              >
                Enviar para Email
              </Button>

              <PrintButton onClick={handlePrint} disabled={!isHaveColletion} />
            </>
          )}
        </Box>

        <PDFViewer
          onClose={closePDFViewer}
          isOpen={isOpenPDFViewer}
          title="Férias Concedidas"
          reportKey="FeriasConcedidas"
          axiosConfig={{
            method: 'post',
            url: '/Relatorio/FeriasConcedidas/ObterRelatorio',
            data: dataPDFViewer,
          }}
        />
      </Box>
    </Slide>
  )
}
