import { useState, useEffect } from 'react'

import * as yup from 'yup'

import { DeleteForever, Settings } from '@material-ui/icons'
import { Grid } from '@material-ui/core'

import {
  Button,
  Checkbox,
  Stack,
  TimePicker,
  ActionAccordion,
  ConfirmDeleteDialog,
} from '~/components'
import { AutoCompleteEventoEmpregador } from '~/components/AutoComplete'

import { useAtualizarFolhaPonto } from '~/hooks/queries/FolhaPonto/useAtualizarFolhaPonto'
import { useDeletarFolhaPonto } from '~/hooks/queries/FolhaPonto/useDeletarFolhaPonto'

import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'

import { FolhaPonto } from '~/hooks/queries/FolhaPonto/dtos/FolhaPonto'

const schema = yup.object().shape({
  eventoHoraExtraId: yup.string().required('Informe o Evento de Hora Extra').nullable(),
  eventoHoraNoturnaId: yup.string().required('Informe o Evento de Hora Noturna').nullable(),
})

interface FormAtualizarProps {
  data: FolhaPonto
}

export default function FormNovo({ data: _d }: FormAtualizarProps) {
  const [data, setData] = useState(_d)

  useEffect(() => {
    setData(_d)
  }, [_d])

  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } = useDeletarFolhaPonto()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } = useAtualizarFolhaPonto()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSave,
    data,
  })

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  async function handleSave() {
    await mutateAsyncUpdate({
      data,
      params: {
        folhaPontoId: data.id,
      },
    })
  }

  async function handleConfirmDelete() {
    await mutateAsyncRemove({
      params: {
        folhaPontoId: dataConfirmDelete,
      },
    })
  }

  return (
    <ActionAccordion title="Configurações da Folha de Ponto" isOpen={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            label="Selecionar Descanso Semanal"
            value={data.isMarcarDescansoSemanal || false}
            onChange={(e, value) => {
              const isMarcarDescansoSemanal = value
              setData((prev) => ({
                ...prev,
                isMarcarDescansoSemanal,
              }))
            }}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            label="Selecionar Feriados"
            value={data.isMarcarFeriado || false}
            onChange={(e, value) => {
              const isMarcarFeriado = value
              setData((prev) => ({
                ...prev,
                isMarcarFeriado,
              }))
            }}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            label="Selecionar Férias"
            value={data.isMarcarFerias || false}
            onChange={(e, value) => {
              const isMarcarFerias = value
              setData((prev) => ({
                ...prev,
                isMarcarFerias,
              }))
            }}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            label="Selecionar Afastamentos"
            value={data.isMarcarAfastamento || false}
            onChange={(e, value) => {
              const isMarcarAfastamento = value
              setData((prev) => ({
                ...prev,
                isMarcarAfastamento,
              }))
            }}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TimePicker
            label="Tempo de Tolerância"
            value={data.tempoTolerancia}
            onChange={(value) => {
              const tempoTolerancia = value
              setData((prev) => ({
                ...prev,
                tempoTolerancia,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AutoCompleteEventoEmpregador
            optionId="id"
            label="Evento de Hora Extra"
            value={data?.eventoHoraExtraId || ''}
            required
            validationErrors={validationErrors}
            name="eventoHoraExtraId"
            onChange={(d) => {
              const eventoHoraExtraId = d?.id || ''
              setData((prev) => ({ ...prev, eventoHoraExtraId }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AutoCompleteEventoEmpregador
            optionId="id"
            label="Evento de Hora Noturna"
            value={data?.eventoHoraNoturnaId || ''}
            required
            validationErrors={validationErrors}
            name="eventoHoraNoturnaId"
            onChange={(d) => {
              const eventoHoraNoturnaId = d?.id || ''
              setData((prev) => ({ ...prev, eventoHoraNoturnaId }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack orientation="horizontal" justifyContent="flex-end">
            <Button
              variant="outlined"
              endIcon={<DeleteForever />}
              onClick={() => openConfirmDelete(data.id)}
            >
              Remover Folha de Ponto
            </Button>
            <Button
              variant="contained"
              endIcon={<Settings />}
              onClick={handleValidate}
              isLoading={isUpdating}
            >
              Atualizar Folha de Ponto
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleConfirmDelete}
          isDeleting={isRemoving}
        />
      )}
    </ActionAccordion>
  )
}
