const prazoIndeterminado_1 = 1
const prazoDeterminado_2 = 2
const prazoDeterminadoOcorrencia_3 = 3

export const tipoContratoTrabalhoValues = [
  { value: prazoIndeterminado_1, name: '1 - Prazo Indeterminado' },
  { value: prazoDeterminado_2, name: '2 - Prazo Determinado definido em dias' },
  {
    value: prazoDeterminadoOcorrencia_3,
    name: '3 - Prazo Determinado, vinculado à ocorrência de um fato',
  },
]

export const tipoContratoTrabalhoConsts = {
  prazoIndeterminado_1,
  prazoDeterminado_2,
  prazoDeterminadoOcorrencia_3,
}
