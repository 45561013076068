import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { ActionDialog, Button, ToolsDataTable } from '~/components'

import { useFeriasColetiva } from '../Context'

export default function DialogAlerts() {
  const { dialogsProps } = useFeriasColetiva()

  const {
    isOpenDialogLicencaRemunerada,
    closeDialogLicencaRemunerada,
    dataDialogLicencaRemunerada,
  } = dialogsProps

  if (!dataDialogLicencaRemunerada) return <></>

  const {
    nrDiasLicencaRemunerada,
    nrDiasLicencaRemuneradaAtual,
    nrDiasLicencaRemuneradaSeguinte,
    competenciaAtual,
    competenciaSeguinte,
  } = dataDialogLicencaRemunerada

  const data = [
    {
      label: 'Dias Licença Remunerada Total',
      value: nrDiasLicencaRemunerada,
    },
    {
      label:
        'Dias Licença Remunerada Competência ' +
        competenciaAtual.substring(0, 4) +
        '/' +
        competenciaAtual.substring(4),
      value: nrDiasLicencaRemuneradaAtual,
    },
  ]

  if (nrDiasLicencaRemuneradaSeguinte > 0) {
    data.push({
      label:
        'Dias Licença Remunerada Competência ' +
        competenciaSeguinte.substring(0, 4) +
        '/' +
        competenciaSeguinte.substring(4),
      value: nrDiasLicencaRemuneradaSeguinte,
    })
  }

  return (
    <ActionDialog
      isOpen={isOpenDialogLicencaRemunerada}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button variant="contained" onClick={closeDialogLicencaRemunerada}>
            Fechar
          </Button>
        </ButtonBox>
      }
      onClose={closeDialogLicencaRemunerada}
      title="Licença Remunerada"
    >
      <ToolsDataTable
        pagination={false}
        data={data}
        columns={[
          {
            name: 'label',
            label: 'Descrição',
          },
          {
            name: 'value',
            label: 'Dias',
          },
        ]}
        disableAutoHeight
      />
    </ActionDialog>
  )
}
