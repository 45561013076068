import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { HeightDataTable } from '~/components'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { formatToBRL } from 'brazilian-values'

const Table = (props) => {
  const { data, onItemClick, isLoading, query } = props

  const columns = useMemo(
    () => [
      {
        name: 'pessoaFisicaDependente',
        label: 'Dependente',
        options: {
          customBodyRender: (value) => value?.nome || null,
        },
      },
      {
        name: 'vrMensalidade',
        label: 'Valor Mensalidade',
        options: {
          customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
        },
      },
      {
        name: 'vrCusto',
        label: 'Valor Custo',
        options: {
          customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                  size="small"
                >
                  <EditIcon fontSize="small" color="primary" size="small" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                  size="small"
                >
                  <DeleteIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['pessoaFisicaDependente.nome'],
      }}
      delayResize={501}
    />
  )
}

export default Table
