import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { VinculoAfastamento } from './VinculoAfastamento'

import { queryKeyVinculoAfastamentoGetByVinculo } from './useVinculoAfastamentoGetByVinculo'

interface VinculoAfastamentoConcederSaldoFeriasDTO {
  afastamentoSaldoFeriasId: string
  novoAfastamento: VinculoAfastamento
}

export function useConcederSaldoFerias() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: VinculoAfastamentoConcederSaldoFeriasDTO) {
    await api.post('/VinculoAfastamento/ConcederSaldoFerias', data)
    notification.success('Saldo de Férias concedido com sucesso')
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries(queryKeyVinculoAfastamentoGetByVinculo),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
