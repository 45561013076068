import { Grid } from '@material-ui/core'

import PieChart from './PieChart'
import LineChart, { EmpregadorLineChartProps } from './LineChart'
import LinearProgressChart from './LinearProgressChart'

import { ActionDialog, Button, ButtonBox } from '~/components'

import {
  CompetenciaProcessamentoContent,
  CompetenciaProcessamentoStatusEnum,
} from '~/hooks/queries/Competencia/CompetenciaProcessamentoContent'

export const defaultLog: CompetenciaProcessamentoContent = {
  id: '',
  message: '',
  date: '',
  progress: 0,
  total: 0,
  totalFuncionarios: 0,
  status: CompetenciaProcessamentoStatusEnum.Processando,
}

interface DialogProcessamentoProps {
  isOpen: boolean
  onClose: () => void
  isProcessando: boolean
  logs: CompetenciaProcessamentoContent[]
  logsEmpregador: CompetenciaProcessamentoContent[]
  logsFuncionario: CompetenciaProcessamentoContent[]
}

export default function DialogProcessamento({
  isOpen,
  onClose,
  isProcessando,
  logs,
  logsEmpregador,
  logsFuncionario,
}: DialogProcessamentoProps) {
  const logCurrent = logs[logs.length - 1] || defaultLog
  const empregadorCurrent = logsEmpregador[logsEmpregador.length - 1] || defaultLog
  const funcionarioCurrent = logsFuncionario[logsFuncionario.length - 1] || defaultLog

  const logsEmpregadorChart: EmpregadorLineChartProps[] = []

  logsEmpregador.forEach((d) => {
    if (logsEmpregadorChart.some((x) => x.id === d.id)) {
      return
    } else {
      logsEmpregadorChart.push({
        id: d.id,
        label: d.message,
        totalFuncionarios: d.totalFuncionarios,
      })
    }
  })

  return (
    <ActionDialog
      title="Processamento"
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox bottom={1} right={1} top={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <PieChart
            subtitle={empregadorCurrent.message}
            progress={empregadorCurrent.progress}
            total={empregadorCurrent.total}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <LineChart subtitle={empregadorCurrent.message} empregadores={logsEmpregadorChart} />
        </Grid>

        <Grid item xs={12}>
          <LinearProgressChart
            isProcessando={isProcessando}
            messageCurrent={logCurrent.message}
            progress={funcionarioCurrent.progress}
            total={funcionarioCurrent.total}
            logs={logs}
            disabledButtonLogs={false}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
