import axios from 'axios'

import { getToken } from '~/services/credentials'

const api = axios.create({
  baseURL: 'https://a0a279x8r6.execute-api.sa-east-1.amazonaws.com/latest/',
})

api.interceptors.request.use(async (config) => {
  const conf = config
  const token = getToken()
  if (token && token !== 'undefined') {
    conf.headers.Authorization = `Bearer ${token}`
  }
  // conf.headers["Content-Type"] = "application/json;charset=UTF-8";
  // conf.headers["Resposta-Compactada"] = "Nunca";
  // conf.headers["Access-Control-Allow-Origin"] = "*";
  // conf.headers["Access-Control-Allow-Headers"] =
  //     "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept";
  // conf.headers["Access-Control-Allow-Methods"] =
  //     "GET, POST, OPTIONS, PUT, PATCH, DELETE";
  // conf.headers["Access-Control-Allow-Credentials"] = true;
  // conf.headers["Allow"] = "GET, POST, PUT, DELETE, OPTIONS, HEAD";
  return conf
})

export default api
