import { useState } from 'react'

import { Box } from '@material-ui/core'

import { Button, ButtonBox, ConfirmDeleteDialog, Container, Finder, PageHeader } from '~/components'

import useRemoveTerceiros from '~/hooks/queries/Terceiros/useRemoveTerceiros'
import useDialog from '~/hooks/useDialog'

import Form from './Form'
import Table from './Table'

export default function Terceiros() {
  const [query, setQuery] = useState('')

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<string | null>(null)
  const { mutateAsync: removeTerceiros, isLoading: isLoadingRemoveTerceiros } = useRemoveTerceiros()

  async function handleItemClick(event: 'edit' | 'delete', id: string) {
    switch (event) {
      case 'edit':
        openForm(id)
        break
      case 'delete':
        openConfirmDelete(id)
        break
      default:
        break
    }
  }

  return (
    <Container>
      <Box>
        <PageHeader title="Terceiros">
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <Button onClick={() => openForm(null)} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Table query={query} onItemClick={handleItemClick} />

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={async () => {
            await removeTerceiros(dataConfirmDelete)
            closeConfirmDelete()
          }}
          isDeleting={isLoadingRemoveTerceiros}
        />
      )}

      {isOpenForm && <Form isOpen={isOpenForm} dataId={dataForm} onClose={closeForm} />}
    </Container>
  )
}
