import React from 'react'

import moment from 'moment'

import { Grid } from '@material-ui/core'

import ContainerFerias from '../components/ContainerFerias'
import SimpleLabelValue from '../components/SimpleLabelValue'

interface PeriodoFeriasProps {
  data: {
    dtPeriodoInicio: string
    dtPeriodoFim: string
    dtLimiteConcessao: string
    observacao: string
  }
}

export default function PeriodoFerias(props: PeriodoFeriasProps) {
  const { data } = props
  const { dtPeriodoInicio, dtPeriodoFim, dtLimiteConcessao, observacao } = data

  const dtPeriodoInicioMoment = moment(dtPeriodoInicio).format('DD/MM/yyyy')
  const dtPeriodoFimMoment = moment(dtPeriodoFim).format('DD/MM/yyyy')
  const dtLimiteConcessaoMoment = moment(dtLimiteConcessao).format('DD/MM/yyyy')

  return (
    <ContainerFerias title="Período de Férias">
      <Grid container spacing={1}>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <SimpleLabelValue
            label="Início do Período"
            color="primary"
            value={dtPeriodoInicioMoment}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <SimpleLabelValue
            label="Fim do Período"
            color="primary"
            value={dtPeriodoFimMoment}
            align="center"
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <SimpleLabelValue
            label="Data Limite"
            color="primary"
            value={dtLimiteConcessaoMoment}
            align="end"
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <SimpleLabelValue label="Observação" color="primary" value={observacao} />
        </Grid>
      </Grid>
    </ContainerFerias>
  )
}
