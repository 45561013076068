import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPost } from '~/utils/notification'

import { MotivoDTO } from './dtos/MotivoDTO'

import { useObterTodosMotivoInvalidate } from './useObterTodosMotivo'

export function useCreateMotivo() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosMotivoInvalidate()

  async function handleRequest(data: MotivoDTO) {
    await api.post('/Motivo', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
