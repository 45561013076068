import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'

import { Button, ButtonBox, ConfirmDeleteDialog, Finder, PageHeader, TreeView } from '~/components'

import Table, { ActionTable } from './Table'
import Form from './Form'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useGetAll } from '~/hooks/queries/ProcessoTrabalhista/useGetAll'
import { useRemover } from '~/hooks/queries/ProcessoTrabalhistaTributos/useRemover'
import { useRetificar } from '~/hooks/queries/ProcessoTrabalhistaTributos/useRetificar'

import { ProcessoTrabalhista } from '~/hooks/queries/ProcessoTrabalhista/dtos/ProcessoTrabalhista'
import { ProcessoTrabalhistaTributos as ProcessoTrabalhistaTributosType } from '~/hooks/queries/ProcessoTrabalhistaTributos/dtos/ProcessoTrabalhistaTributos'

export default function ProcessoTrabalhistaTributos() {
  const [processoTrabalhista, setProcessoTrabalhista] = useState<ProcessoTrabalhista | null>(null)
  const [query, setQuery] = useState('')

  const { empregador } = useAmbiente()
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<ProcessoTrabalhistaTributosType | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useGetAll({
    params: {
      empregadorId: empregador?.id,
    },
  })
  const _d = _data || []
  const dataMenu = [
    {
      groupName: 'Processos Trabalhistas',
      data: _d,
    },
  ]
  const isLoadingMenu = isLoading || isFetching

  const { mutateAsync: mutateAsyncRemover, isLoading: isRemoving } = useRemover()
  const { mutateAsync: mutateAsyncRetificar, isLoading: isLoadingRetificar } = useRetificar()

  useEffect(() => {
    setProcessoTrabalhista(null)
  }, [_data])

  async function handleClickAction(event: ActionTable, row: ProcessoTrabalhistaTributosType) {
    switch (event) {
      case 'edit':
        openForm(row)
        break
      case 'delete':
        openConfirmDelete(row.id)
        break
      case 'retificar':
        await mutateAsyncRetificar({ id: row.id })
        break
    }
  }

  async function handleDelete() {
    await mutateAsyncRemover(dataConfirmDelete)
    closeConfirmDelete()
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, processo) => setProcessoTrabalhista(processo)}
      searchBy="identificacaoProcesso"
      renderOption={(option) => `${option.identificacaoProcesso}`}
      isLoading={isLoadingMenu}
    >
      <PageHeader
        title="Processo Trabalhista Tributos S-2501"
        subtitle={
          processoTrabalhista
            ? `${processoTrabalhista.identificacaoProcesso} - ${processoTrabalhista.nrProcessoTrabalhista}`
            : '-'
        }
      >
        {processoTrabalhista && (
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <Button variant="contained" onClick={() => openForm(null)}>
              Adicionar
            </Button>
          </ButtonBox>
        )}
      </PageHeader>
      {processoTrabalhista ? (
        <Table
          query={query}
          processoTrabalhistaId={processoTrabalhista.id}
          onClickAction={handleClickAction}
          isLoadingRetificar={isLoadingRetificar}
        />
      ) : (
        <Typography>Selecione um Processo Trabalhista</Typography>
      )}
      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          data={dataForm}
          processoTrabalhistaId={processoTrabalhista?.id || ''}
        />
      )}
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isRemoving}
          onCancel={closeConfirmDelete}
          onConfirm={handleDelete}
        />
      )}
    </TreeView>
  )
}
