export enum InfoOnusRemuneracaoEnum {
  ApenasEmpregador_1 = 1,
  ApenasSindicato_2 = 2,
  ParteEmpregadorComplementadaPeloSindicato_3 = 3,
  NaoAplicavel_9 = 9,
}

export const InfoOnusRemuneracaoValues = [
  { value: InfoOnusRemuneracaoEnum.NaoAplicavel_9, name: '9 - Não informar' },
  { value: InfoOnusRemuneracaoEnum.ApenasEmpregador_1, name: '1 - Apenas do empregador' },
  { value: InfoOnusRemuneracaoEnum.ApenasSindicato_2, name: '2 - Apenas do sindicato' },
  {
    value: InfoOnusRemuneracaoEnum.ParteEmpregadorComplementadaPeloSindicato_3,
    name: '3 - Parte do empregador, sendo a diferença e/ou complementação salarial paga pelo sindicato',
  },
]
