import { useState } from 'react'

import { Box, IconButton, Paper, TablePagination } from '@material-ui/core'
import { Assessment } from '@material-ui/icons'

import { ButtonBox, ContainerTable, Stack, ToolsDataTable } from '~/components'

import { useConsultaPessoal } from '~/hooks/queries-log/useConsultaPessoal'

import { formatSimpleDateTime } from '~/utils/utils'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { HeaderRequestType } from '~/@types/HeaderRequestType'
import { ConsultaPessoalFilters } from '~/hooks/queries-log/@types/ConsultaPessoalFilters'
import {
  ConsultaPessoalEntidadeEnum,
  ConsultaPessoalEntidadeLabel,
} from '~/hooks/queries-log/@types/ConsultaPessoalEntidades'

interface TableProps {
  params: ConsultaPessoalFilters
  openDetalhamento: (logId: string) => void
}

export default function Table({ params, openDetalhamento }: TableProps) {
  const [pagination, setPagination] = useState<{
    page: number
    pageSize: number
  }>({
    page: 0,
    pageSize: 25,
  })

  const headers: HeaderRequestType = {
    'DC-Page': 1 + pagination.page,
    'DC-PageSize': pagination.pageSize,
  }

  const { data: _d, isLoading, isFetching } = useConsultaPessoal({ params, headers })

  const data = _d?.data || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dataHora',
      label: 'Data/Hora',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '130px',
              whiteSpace: 'nowrap',
            },
          }
        },
        customBodyRender: formatSimpleDateTime,
      },
    },
    {
      name: 'usuarioEmail',
      label: 'E-mail',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '220px',
              whiteSpace: 'nowrap',
            },
          }
        },
      },
    },
    {
      name: 'usuarioNome',
      label: 'Usuário',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '220px',
              whiteSpace: 'nowrap',
            },
          }
        },
      },
    },
    {
      name: 'operacao',
      label: 'Operação',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '100px',
              whiteSpace: 'nowrap',
            },
          }
        },
      },
    },
    {
      name: 'entidade',
      label: 'Entidade',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '100px',
              whiteSpace: 'nowrap',
            },
          }
        },
        customBodyRender: (value: ConsultaPessoalEntidadeEnum) =>
          ConsultaPessoalEntidadeLabel[value],
      },
    },
    {
      name: 'descricao',
      label: 'Descrição',
      options: {
        sort: false,
        customBodyRender: (value) => (
          <Box
            title={value}
            whiteSpace="nowrap"
            maxWidth="500px"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {value}
          </Box>
        ),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Abrir detalhamento da consulta"
                color="primary"
                aria-label="Transportar"
                onClick={() => openDetalhamento(value)}
              >
                <Assessment fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack height="100%" gap={0.5}>
      <ContainerTable>
        <ToolsDataTable
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          data={data}
          disableAutoHeight
          pagination={false}
        />
      </ContainerTable>
      <Box component={Paper}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          labelRowsPerPage="Linhas por página:"
          component="div"
          count={_d?.pagination?.totalRecords || 0}
          rowsPerPage={pagination.pageSize}
          page={pagination.page}
          onChangePage={(_, page) =>
            setPagination((prev) => ({
              ...prev,
              page,
            }))
          }
          onChangeRowsPerPage={(e) =>
            setPagination((prev) => ({
              ...prev,
              pageSize: +e.target.value,
              page: 0,
            }))
          }
        />
      </Box>
    </Stack>
  )
}
