import { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import { Button, ButtonBox, DatePickerNew, TextField } from '~/components'
import FormHorariosBase, { schemaFields } from '../components/FormHorariosBase'

import * as yup from 'yup'
import useValidationErrors from '~/hooks/useValidationErrors'

import { RelCompensacaoHorasTrabalhadasJornadaNormalDTO } from '~/hooks/queries/CompensacaoHorasTrabalhadas/dtos/RelCompensacaoHorasTrabalhadasJornadaMainDTO'

const schema = yup.object().shape({
  dtEfetiva: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data efetiva')
    .nullable(),
  textoCompensacaoHorasExtraordinarias: yup
    .string()
    .required('Informe a Horas trabalhadas após a oitava hora diária de trabalho'),
  ...schemaFields,
})

interface StepJornadaNormalProps {
  data: RelCompensacaoHorasTrabalhadasJornadaNormalDTO
  onCancel: () => void
  onChange: (d: RelCompensacaoHorasTrabalhadasJornadaNormalDTO) => void
}

export default function StepJornadaNormal({
  data: _d,
  onCancel,
  onChange,
}: StepJornadaNormalProps) {
  const [data, setData] = useState(_d || {})

  useEffect(() => {
    setData(_d)
  }, [_d])

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  function handleSubmit() {
    onChange(data)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={12}>
        <DatePickerNew
          label="Data Efetiva"
          size="small"
          value={data?.dtEfetiva || null}
          onChange={(date) => {
            const dtEfetiva = date || ''
            setData((prevState) => ({ ...prevState, dtEfetiva }))
          }}
          validationErrors={validationErrors}
          name="dtEfetiva"
          required
        />
      </Grid>

      <FormHorariosBase data={data} setData={setData} validationErrors={validationErrors} />

      <Grid item xs={12}>
        <TextField
          label="Horas trabalhadas após a oitava hora diária de trabalho"
          fullWidth
          value={data?.textoCompensacaoHorasExtraordinarias || ''}
          size="small"
          inputProps={{
            maxLength: 250,
          }}
          onChange={(e) => {
            const textoCompensacaoHorasExtraordinarias = e?.target?.value || ''
            setData((prevState) => ({ ...prevState, textoCompensacaoHorasExtraordinarias }))
          }}
          multiline
          rows={4}
          validationErrors={validationErrors}
          name="textoCompensacaoHorasExtraordinarias"
          required
        />
      </Grid>

      <Grid item xs={12}>
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button variant="contained" onClick={handleValidate}>
            Próximo
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
