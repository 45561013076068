import { useState } from 'react'

import { Box, Typography } from '@material-ui/core'

import { PageHeader, Stack, TreeView } from '~/components'

import Content from './Content'

import useAmbiente from '~/hooks/useAmbiente'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

export default function ProvisaoSaldoInicial() {
  const [funcionario, setFuncionario] = useState<VinculoConsulta | null>(null)

  const { anoMes, estabelecimento } = useAmbiente()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    estabelecimentoId: estabelecimento.id,
    anoMes,
  })
  const _d = _data || []
  const dataMenu = [
    {
      groupName: 'Funcionários',
      data: _d,
    },
  ]
  const isLoadingMenu = isLoading || isFetching

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => setFuncionario(vinculo)}
      searchBy="pessoaNome"
      renderOption={(option) => `${option.pessoaNome}`}
      isLoading={isLoadingMenu}
    >
      <Stack height="100%">
        <PageHeader
          title="Provisão Saldo Inicial"
          subtitle={funcionario ? funcionario.pessoaNome : '-'}
        />
        <Box flex={1}>
          {funcionario ? (
            <Content funcionario={funcionario} />
          ) : (
            <Typography>Selecione um Funcionário</Typography>
          )}
        </Box>
      </Stack>
    </TreeView>
  )
}
