import { formatToCPFOrCNPJ } from 'brazilian-values'

import { ToolsDataTable } from '~/components'
import TextFieldChangeVinculoEmail from '~/components/TextFieldChangeVinculoEmail'

export default function Table(props) {
  const { isLoading, query, data: _data, onRowSelected, rowsSelected, afterSubmit } = props

  const data = _data.map((d) => ({
    ...d,
    nrInscricao: d?.nrInscricao ? formatToCPFOrCNPJ(d.nrInscricao) : '',
  }))

  const columns = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'id',
      label: 'Email',
      options: {
        setCellHeaderProps: () => ({
          style: { width: 400 },
        }),
        customBodyRender: (id) => {
          const vinculo = _data.find((d) => d.id === id)
          return (
            <TextFieldChangeVinculoEmail
              email={vinculo?.email || ''}
              afterSubmit={afterSubmit}
              vinculoId={vinculo.id}
            />
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      columns={columns}
      sherlock={{ columns: ['codigo', 'nrInscricao', 'nome'], query }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
    />
  )
}
