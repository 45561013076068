import React, { useState } from 'react'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'
import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'
import { IndEventoLabels } from '~/@types/enums/IndEventoEnum'
import ListboxComponentVirtualized from '../ListboxComponentVirtualized'

export default function AutoCompleteEvento(props) {
  const { classificacao, label, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/Evento')

      let data = response?.data?.data || []

      if (classificacao) {
        data = response?.data?.data?.filter((item) => item.indClassificacaoEvento === classificacao)
      }

      setOptions(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      ListboxComponent={ListboxComponentVirtualized}
      ListboxProps={{ childSize: 80 }}
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label={label}
      renderOptionComponent={(option) => (
        <AutoCompleteOptionDescription
          title={`${option.codigo} - ${option.nome}`}
          descriptions={[
            {
              description: `Indicador: ${IndEventoLabels[option.indEvento]} - Rúbrica eSocial: ${
                option.rubricaId
              }`,
            },
            {
              description: `CP: ${option.codigoIncidenciaCP} - FG: ${option.codigoIncidenciaFG} - IR: ${option.codigoIncidenciaIR}`,
            },
          ]}
        />
      )}
      {...rest}
    />
  )
}

AutoCompleteEvento.defaultProps = {
  classificacao: null,
  label: 'Evento',
}
