import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterTarefasPeriodicasFolha'

import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: string[]
  params: {
    dtAgendamento: string
  }
}

export function useConfigurarAgendamentoPeriodico() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ data, params }: RequestProps) {
    await api.post('/FolhaAutomatica/ConfigurarAgendamentoPeriodico', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Processamento iniciado com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
