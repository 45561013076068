export enum TipoFaltaEnum {
  AteDoisDiasPorFalecimento_1 = 1,
  AteTresDiasCasamento_2 = 2,
  AteCincodiasNascimetnoFilho_3 = 3,
  AteUmDiaDoarSanque_4 = 4,
  AteDoisDiasAlistamento_5 = 5,
  DepoimentoEmJuizo_6 = 6,
  PrestarVestibular_7 = 7,
  ComparecimentoJusticaTrabalho_8 = 8,
  ServirComoJurado_9 = 9,
  Greve_10 = 10,
  ServicosPrestadosJusticaEleitoral_11 = 11,
  Injustificavel_90 = 90,
  Suspensao_91 = 91,
  Abonada_92 = 92,
  AtestadoMedico_93 = 93,
}

export const TipoFaltaValues = [
  {
    value: TipoFaltaEnum.AteDoisDiasPorFalecimento_1,
    name: '01 -	Até 2 dias consecutivos, em caso de falecimento de conjuge, ascendente, descendente, irmão ou pessoa que, declarada em carteira de trabalho, viva sob sua dependencia econômica',
  },
  {
    value: TipoFaltaEnum.AteTresDiasCasamento_2,
    name: '02 -	Até 3 dias consecutivos, em virtudade de casamento',
  },
  {
    value: TipoFaltaEnum.AteCincodiasNascimetnoFilho_3,
    name: '03 -	Por 5 dias, em caso de nascimento do filho, no decorrer da primeira semana',
  },
  {
    value: TipoFaltaEnum.AteUmDiaDoarSanque_4,
    name: '04 -	Por 1 dia, em cada 12 meses de trabalho, em caso de doacao voluntaria de sanque devidamente comprovada',
  },
  {
    value: TipoFaltaEnum.AteDoisDiasAlistamento_5,
    name: '05 -	Até 2 dias consecutivos ou nao, para fim de alistar eleitor, nos termos da Lei',
  },
  { value: TipoFaltaEnum.DepoimentoEmJuizo_6, name: '6 - Depoimento em Juizo' },
  { value: TipoFaltaEnum.PrestarVestibular_7, name: '7 - Prestar vestibular' },
  {
    value: TipoFaltaEnum.ComparecimentoJusticaTrabalho_8,
    name: '08 -	Comparecimento na justica do trabalho',
  },
  { value: TipoFaltaEnum.ServirComoJurado_9, name: '09 - Servir como Jurado' },
  { value: TipoFaltaEnum.Greve_10, name: '10 - Greve' },
  {
    value: TipoFaltaEnum.ServicosPrestadosJusticaEleitoral_11,
    name: '11 -	Servicos prestados na justica Eleitoral',
  },
  { value: TipoFaltaEnum.Injustificavel_90, name: '90 -	Injustificavel' },
  { value: TipoFaltaEnum.Suspensao_91, name: '91 - Suspensão' },
  { value: TipoFaltaEnum.Abonada_92, name: '92 - Abonada' },
  { value: TipoFaltaEnum.AtestadoMedico_93, name: '93 -	Atestado Medico' },
]
