import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterPorVinculoInvalidate } from './useObterPorVinculo'

import { notifyPost } from '~/utils/notification'
import { PlanoSaudeTitular } from './dtos/PlanoSaudeTitular'

interface RequestProps {
  params: { anoMes: string }
  data: PlanoSaudeTitular
}

export function useCriar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterPorVinculoInvalidate()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{ data: PlanoSaudeTitular }>('/PlanoSaudeTitular', data, {
      params,
    })
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
