import React from 'react'

import { formatToCPF } from 'brazilian-values'
import moment from 'moment'

import { VinculoDTO } from '~/hooks/queries/Vinculo/VinculoDTO'

import { ToolsDataTable } from '~/components'

interface TableProps {
  query: string
  onRowSelected: (indexs: number[]) => void
  rowsSelected: number[]
  isLoading: boolean
  isFetching: boolean
  data: VinculoDTO[]
}

export default function Table(props: TableProps) {
  const { query, data: _data, isLoading, isFetching, onRowSelected, rowsSelected } = props

  const data = _data.map((d) => ({
    ...d,
    nrInscricao: d?.nrInscricao ? formatToCPF(d.nrInscricao) : '',
    dtAdmissao: d?.dtAdmissao ? moment(d.dtAdmissao).format('DD/MM/yyyy') : '',
  }))

  const columns = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'dtAdmissao',
      label: 'Data de Admissão',
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      sherlock={{ columns: ['codigo', 'nrInscricao', 'nome', 'dtAdmissao'], query }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        selectOnClick: true,
        type: 'multiple',
      }}
    />
  )
}
