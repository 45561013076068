import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import SimpleCarousel from '~/components/SimpleCarousel'

import ContainerFerias from '../components/ContainerFerias'
import SimpleLabelValue from '../components/SimpleLabelValue'

interface FeriasConcedidaProps {
  dtFimAbono: string
  dtFimConcessao: string
  dtInicioAbono: string
  dtInicioConcessao: string
  nrDiasAbono: number
  nrDiasConcedidos: number
}

interface PeriodoConcedidoProps {
  data: FeriasConcedidaProps[]
}

export default function PeriodoConcedido(props: PeriodoConcedidoProps) {
  const { data } = props

  const hasMoreThanZero = data.length > 0

  if (!hasMoreThanZero) {
    return (
      <ContainerFerias
        title="Período Concedido"
        style={{
          paddingBottom: '16px',
        }}
      >
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Nenhum período concedido encontrado
        </Typography>
      </ContainerFerias>
    )
  }

  return (
    <ContainerFerias
      title="Período Concedido"
      style={{
        paddingBottom: '8px',
      }}
    >
      <SimpleCarousel<FeriasConcedidaProps>
        data={data}
        render={(
          {
            dtFimAbono,
            dtFimConcessao,
            dtInicioAbono,
            dtInicioConcessao,
            nrDiasAbono,
            nrDiasConcedidos,
          },
          index,
        ) => (
          <Grid container key={index} title={`${index + 1}° Período programado`}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Inicio das Férias"
                color="primary"
                value={dtInicioConcessao}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Fim das Férias"
                color="primary"
                value={dtFimConcessao}
                align="center"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Dias Férias"
                color="primary"
                value={nrDiasConcedidos}
                align="end"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue label="Inicio Abono" color="primary" value={dtInicioAbono} />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Fim Abono"
                color="primary"
                value={dtFimAbono}
                align="center"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Dias Abono"
                color="primary"
                value={nrDiasAbono}
                align="end"
              />
            </Grid>
          </Grid>
        )}
      />
    </ContainerFerias>
  )
}
