import React from 'react'
import withAmbiente from '~/hooks/withAmbiente'
import AmbienteError from '~/exceptions/AmbienteError'
import AmbienteErrorDisplay from '~/exceptions/displays/AmbienteErrorDisplay'
import GenricErrorDisplay from '~/exceptions/displays/GenericError'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  //   static getDerivedStateFromError(error) {
  //     // Atualiza o state para que a próxima renderização mostre a UI alternativa.
  //     return { hasError: true }
  //   }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      if (this.state.error instanceof AmbienteError) {
        if (!this.props.ambiente) return <AmbienteErrorDisplay />
        this.setState({ error: null, errorInfo: null })
      }

      // return <GenricErrorDisplay message={this?.state?.errorInfo} />
      return <GenricErrorDisplay message={`Error: ${JSON.stringify(this.state.error.message)}`} />
    }
    // Normally, just render children
    return this.props.children
  }
}

export default withAmbiente(ErrorBoundary)
