import React, { useEffect, useState, memo, useCallback, useRef } from 'react'

import { Grid, Collapse, IconButton, Box, Paper } from '@material-ui/core'
import { Button, PageHeader, ButtonBox } from 'mio-library-ui'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'

import { AnoMesTextField } from '~/components'
import { AutoCompleteVinculo } from '~/components/AutoComplete'

import Table from './components/Table'
import TableBases from './components/TableBases'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import moment from 'moment'

const schema = yup.object().shape({
  vinculoId: yup.string().required('Informe um Funcionário'),
  anoInicio: yup.string().required('Informe o Ano/Mês Inicial').min(6, 'Informe o Ano/Mês válido'),
  anoFim: yup
    .string()
    .required('Informe o Ano/Mês Final')
    .min(6, 'Informe o Ano/Mês válido')
    .when(['anoInicio'], (anoInicio, schema, { value }) => {
      if (moment(anoInicio).isAfter(value)) {
        return schema.min(anoInicio, 'Ano Final deve ser maior que Ano Inicial')
      }
    }),
})

const MemoTable = memo(Table)

const ConsultaRP = () => {
  const [data, setData] = useState({
    vinculoId: '',
    anoInicio: '',
    anoFim: '',
    haveParam: false,
  })
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [tableBases, setTableBases] = useState({
    isOpen: false,
    item: [],
  })
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)
  const [rowsExpanded, setRowsExpanded] = useState([])

  const { estabelecimento, anoMes, ano } = useAmbiente()
  const dialogNotification = useDialogNotification()

  const refAnoMesInicio = useRef(null)
  const refAnoMesFim = useRef(null)

  useEffect(() => {
    const getVinculoByParam = async () => {
      const paramCompleto = window.location.hash.slice(18)
      const params = paramCompleto.split('&')

      const campos = params.map((par) => {
        const camposDivididos = par.split('=')
        return camposDivididos[1]
      })

      const vinculoId = campos[0]

      if (vinculoId) {
        const newData = {
          vinculoId: vinculoId,
          anoInicio: `${ano}01`,
          anoFim: anoMes,
          haveParam: true,
        }
        window.history.pushState('', '', '#/recibo-consulta')
        setData(newData)
        handleValidate(newData)
      }
    }
    getVinculoByParam()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setRowsExpanded([])
    setData((oldState) => ({
      ...oldState,
      anoInicio: `${ano}01`,
      anoFim: anoMes,
    }))
    setCollection((oldState) => ({ ...oldState, itens: [] }))
  }, [anoMes, ano, estabelecimento])

  useEffect(() => {
    setRowsExpanded([])
    setCollection((oldState) => ({ ...oldState, itens: [] }))
    setData((oldState) => ({
      ...oldState,
      haveParam: false,
    }))
  }, [data.vinculoId])

  const handleSearch = async (dataParam) => {
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get('RPConsulta/GetByVinculo', {
        params: {
          vinculoId: dataParam?.vinculoId,
          anoMesInicio: dataParam?.anoInicio,
          anoMesFim: dataParam?.anoFim,
        },
        data: [],
      })
      setCollection((oldState) => ({
        ...oldState,
        itens: response?.data?.data || [],
      }))
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSearch,
    isValidateParams: true,
  })

  const handleClickItem = useCallback(
    (id) => {
      const { itens } = collection
      const item = itens.find((i) => i.id === id)
      setTableBases({ item, isOpen: true })
    },
    [collection],
  )

  const handleCloseTableBases = useCallback(() => {
    setTableBases((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }, [])

  return (
    <Box width="100%" height="100%" p={2}>
      <Box id="root-header">
        <PageHeader title="Consultar Recibos">
          <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
            {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
          </IconButton>
        </PageHeader>

        <Box component={Paper} padding={2}>
          <Collapse in={formCollapseIsOpen}>
            <Grid container spacing={2}>
              <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                <AutoCompleteVinculo
                  estabelecimentoId={estabelecimento?.id}
                  anoMes={anoMes}
                  value={data?.vinculoId || ''}
                  name="vinculoId"
                  validationErrors={validationErrors}
                  onChange={(e, vinculo) => {
                    const vinculoId = vinculo?.id || ''
                    if (vinculoId) refAnoMesInicio.current.focus()
                    setData((oldState) => ({ ...oldState, vinculoId }))
                  }}
                  optionId="id"
                  waitSearch={data?.haveParam ? false : true}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={2} sm={4} xs={6}>
                <AnoMesTextField
                  label="Ano/Mês Início"
                  fullWidth
                  variant="outlined"
                  size="small"
                  validationErrors={validationErrors}
                  name="anoInicio"
                  value={data?.anoInicio || ''}
                  inputRef={refAnoMesInicio}
                  onKeyDown={({ key }) => {
                    if (key !== 'Enter') return
                    refAnoMesFim.current.focus()
                  }}
                  onChange={(e) => {
                    const anoInicio = e?.target?.value?.replace('/', '') || ''
                    setData((oldState) => ({ ...oldState, anoInicio }))
                  }}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={2} sm={4} xs={6}>
                <AnoMesTextField
                  label="Ano/Mês Fim"
                  fullWidth
                  variant="outlined"
                  size="small"
                  validationErrors={validationErrors}
                  name="anoFim"
                  value={data?.anoFim || ''}
                  inputRef={refAnoMesFim}
                  onKeyDown={({ key }) => {
                    if (key !== 'Enter') return
                    handleValidate(data)
                  }}
                  onChange={(e) => {
                    const anoFim = e?.target?.value?.replace('/', '') || ''
                    setData((oldState) => ({ ...oldState, anoFim }))
                  }}
                />
              </Grid>

              <Grid item xl={1} lg={1} md={1} sm={4} xs={12}>
                <ButtonBox bottom={2}>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => handleValidate(data)}
                    variant="contained"
                  >
                    Buscar
                  </Button>
                </ButtonBox>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Box>

      <MemoTable
        data={collection.itens}
        isLoading={collection.isLoading}
        onItemClick={handleClickItem}
        triggersHeight={{ validationErrors, formCollapseIsOpen }}
        rowsExpanded={rowsExpanded}
        setRowsExpanded={setRowsExpanded}
        afterSubmit={() => handleValidate(data)}
      />

      <TableBases
        data={tableBases.item}
        isOpen={tableBases.isOpen}
        onClose={handleCloseTableBases}
      />
    </Box>
  )
}

export default ConsultaRP
