import { CollapseContent, Stack } from '~/components'

import RowConta from '~/components/RowConta'

import { ProvisaoConfigDetalheDTO } from '~/hooks/queries/ExportacaoLancamentoConfig/dtos/ProvisaoConfigDetalheDTO'

interface ProvisaoGroupProps {
  mascaraConta: string
  label: string
  value: ProvisaoConfigDetalheDTO
  onChange: (value: ProvisaoConfigDetalheDTO) => void
}

export default function ProvisaoGroup({
  mascaraConta,
  label,
  value,
  onChange,
}: ProvisaoGroupProps) {
  return (
    <CollapseContent title={label} isOpen={true}>
      <Stack>
        <RowConta
          label="Movimento"
          mask={mascaraConta}
          valueDebito={value?.movimento?.normalDebito || ''}
          onChangeDebito={(d) => {
            const normalDebito = d
            onChange({
              ...value,
              movimento: {
                ...value.movimento,
                normalDebito,
              },
            })
          }}
          valueCredito={value?.movimento?.normalCredito || ''}
          onChangeCredito={(d) => {
            const normalCredito = d
            onChange({
              ...value,
              movimento: {
                ...value.movimento,
                normalCredito,
              },
            })
          }}
          valueIgnorar={value?.movimento?.ignorar}
          onChangeIgnorar={(d) => {
            const ignorar = d
            onChange({
              ...value,
              movimento: {
                ...value.movimento,
                ignorar,
              },
            })
          }}
        />

        <RowConta
          label="FGTS"
          mask={mascaraConta}
          valueDebito={value?.fgts?.normalDebito || ''}
          onChangeDebito={(d) => {
            const normalDebito = d
            onChange({
              ...value,
              fgts: {
                ...value.fgts,
                normalDebito,
              },
            })
          }}
          valueCredito={value?.fgts?.normalCredito || ''}
          onChangeCredito={(d) => {
            const normalCredito = d
            onChange({
              ...value,
              fgts: {
                ...value.fgts,
                normalCredito,
              },
            })
          }}
          valueIgnorar={value?.fgts?.ignorar}
          onChangeIgnorar={(d) => {
            const ignorar = d
            onChange({
              ...value,
              fgts: {
                ...value.fgts,
                ignorar,
              },
            })
          }}
        />

        <RowConta
          label="INSS"
          mask={mascaraConta}
          valueDebito={value?.inss?.normalDebito || ''}
          onChangeDebito={(d) => {
            const normalDebito = d
            onChange({
              ...value,
              inss: {
                ...value.inss,
                normalDebito,
              },
            })
          }}
          valueCredito={value?.inss?.normalCredito || ''}
          onChangeCredito={(d) => {
            const normalCredito = d
            onChange({
              ...value,
              inss: {
                ...value.inss,
                normalCredito,
              },
            })
          }}
          valueIgnorar={value?.inss?.ignorar}
          onChangeIgnorar={(d) => {
            const ignorar = d
            onChange({
              ...value,
              inss: {
                ...value.inss,
                ignorar,
              },
            })
          }}
        />

        <RowConta
          label="INSS/Terceiros"
          mask={mascaraConta}
          valueDebito={value?.inssTerceiros?.normalDebito || ''}
          onChangeDebito={(d) => {
            const normalDebito = d
            onChange({
              ...value,
              inssTerceiros: {
                ...value.inssTerceiros,
                normalDebito,
              },
            })
          }}
          valueCredito={value?.inssTerceiros?.normalCredito || ''}
          onChangeCredito={(d) => {
            const normalCredito = d
            onChange({
              ...value,
              inssTerceiros: {
                ...value.inssTerceiros,
                normalCredito,
              },
            })
          }}
          valueIgnorar={value?.inssTerceiros?.ignorar}
          onChangeIgnorar={(d) => {
            const ignorar = d
            onChange({
              ...value,
              inssTerceiros: {
                ...value.inssTerceiros,
                ignorar,
              },
            })
          }}
        />

        <RowConta
          label="PIS"
          mask={mascaraConta}
          valueDebito={value?.pis?.normalDebito || ''}
          onChangeDebito={(d) => {
            const normalDebito = d
            onChange({
              ...value,
              pis: {
                ...value.pis,
                normalDebito,
              },
            })
          }}
          valueCredito={value?.pis?.normalCredito || ''}
          onChangeCredito={(d) => {
            const normalCredito = d
            onChange({
              ...value,
              pis: {
                ...value.pis,
                normalCredito,
              },
            })
          }}
          valueIgnorar={value?.pis?.ignorar}
          onChangeIgnorar={(d) => {
            const ignorar = d
            onChange({
              ...value,
              pis: {
                ...value.pis,
                ignorar,
              },
            })
          }}
        />
      </Stack>
    </CollapseContent>
  )
}
