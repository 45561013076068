import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import FormHorariosBase, { schemaFields } from '../components/FormHorariosBase'

import useValidationErrors from '~/hooks/useValidationErrors'

import { RelCompensacaoHorasTrabalhadasJornadaDTO } from '~/hooks/queries/CompensacaoHorasTrabalhadas/dtos/RelCompensacaoHorasTrabalhadasJornadaMainDTO'
import { Button, ButtonBox } from '~/components'

const schema = yup.object().shape(schemaFields)

interface StepCompensacaoProps {
  data: RelCompensacaoHorasTrabalhadasJornadaDTO
  onCancel: () => void
  onChange: (d: RelCompensacaoHorasTrabalhadasJornadaDTO) => void
}

export default function StepCompensacao({ data: _d, onCancel, onChange }: StepCompensacaoProps) {
  const [data, setData] = useState(_d || {})

  useEffect(() => {
    setData(_d)
  }, [_d])

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  function handleSubmit() {
    onChange(data)
  }
  return (
    <Grid container spacing={2}>
      <FormHorariosBase data={data} setData={setData} validationErrors={validationErrors} />
      <Grid item xs={12}>
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button variant="contained" onClick={handleValidate}>
            Próximo
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
