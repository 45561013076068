import React, { forwardRef } from 'react'

import UnicefCurrencyTextField from '@unicef/material-ui-currency-textfield'
import ErrorMessage from '../ErrorMessage'

import { obterErroValidationErrors } from '~/hooks/useUtils'
import { TextFieldProps } from '@material-ui/core'

interface CurrencyTextFieldProps extends Omit<TextFieldProps, 'name' | 'onChange' | 'onBlur'> {
  validationErrors?: ValidationErrors
  name?: string
  onChange?: (e: string, value: number) => void
  onBlur?: (e: string, value: number) => void
  maximumValue?: string
  decimalPlaces?: number
  decimalPlacesShownOnFocus?: number
}

// eslint-disable-next-line react/display-name
const CurrencyTextField = forwardRef((props: CurrencyTextFieldProps, ref) => {
  const { name, validationErrors, ...rest } = props

  const validationMessage = obterErroValidationErrors(name, validationErrors)

  return (
    <React.Fragment>
      <UnicefCurrencyTextField
        ref={ref}
        decimalCharacter=","
        digitGroupSeparator="."
        currencySymbol=""
        decimalPlaces={2}
        decimalPlacesShownOnFocus={2}
        variant="outlined"
        size="small"
        fullWidth
        name={name}
        {...rest}
      />
      {validationMessage && <ErrorMessage error={validationMessage} />}
    </React.Fragment>
  )
})

export default CurrencyTextField
