import React, { useEffect, useState } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Button, Finder, PageHeader, TreeView } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import { useVinculoEstabilidadeDelete } from '~/hooks/queries/useVinculoEstabilidade'
import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

export default function VinculoEstabilidade() {
  const [query, setQuery] = useState('')
  const [vinculo, setVinculo] = useState<VinculoMenuData | null>(null)

  const {
    close: closeFormVinculoEstabilidade,
    data: dataFormVinculoEstabilidade,
    isOpen: isOpenFormVinculoEstabilidade,
    open: openFormVinculoEstabilidade,
  } = useDialog<{ vinculoId: string | null; vinculoEstabilidadeId: string | null } | null>(null)
  const {
    close: closeDialogConfirmDelete,
    data: idDialogConfirmDelete,
    isOpen: isOpenDialogConfirmDelete,
    open: openDialogConfirmDelete,
  } = useDialog<string | null>(null)

  const { mutateAsync, isLoading } = useVinculoEstabilidadeDelete()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])
  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    setVinculo(null)
  }, [estabelecimento, anoMes])

  function handleClickItem(event: 'edit' | 'delete', id: string) {
    async function handleClickEditItem(id: string) {
      openFormVinculoEstabilidade({
        vinculoEstabilidadeId: id,
        vinculoId: vinculo?.vinculoId || null,
      })
    }

    function handleClickDeleteItem(id: string) {
      openDialogConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](id)
  }

  async function handleDeleteItem() {
    if (!idDialogConfirmDelete) return
    await mutateAsync(idDialogConfirmDelete)
    closeDialogConfirmDelete()
  }

  function handleSelectItemMenu(e: string, vinculo: VinculoMenuData) {
    setVinculo(vinculo)
  }

  const vinculoId = vinculo?.vinculoId || null

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={handleSelectItemMenu}
      searchBy="vinculoNome"
      renderOption={(option: VinculoMenuData) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
    >
      <PageHeader title="Estabilidade por Funcionário" subtitle={vinculo?.vinculoNome || '-'}>
        {vinculo?.vinculoId && (
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <Button
              size="small"
              color="primary"
              onClick={() =>
                openFormVinculoEstabilidade({
                  vinculoEstabilidadeId: null,
                  vinculoId: vinculo?.vinculoId || null,
                })
              }
              variant="contained"
            >
              Adicionar
            </Button>
          </ButtonBox>
        )}
      </PageHeader>
      <Table query={query} onItemClick={handleClickItem} vinculoId={vinculoId} />
      {isOpenFormVinculoEstabilidade && (
        <Form
          isOpen={isOpenFormVinculoEstabilidade}
          vinculoEstabilidadeId={dataFormVinculoEstabilidade?.vinculoEstabilidadeId || null}
          vinculoId={dataFormVinculoEstabilidade?.vinculoId || null}
          onClose={closeFormVinculoEstabilidade}
        />
      )}
      <ConfirmDeleteDialog
        isOpen={isOpenDialogConfirmDelete}
        isDeleting={isLoading}
        onCancel={closeDialogConfirmDelete}
        onConfirm={handleDeleteItem}
      />
    </TreeView>
  )
}
