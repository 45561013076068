import { useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, ButtonBox, Finder } from 'mio-library-ui'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'

import moment from 'moment'

import api from '~/services/api-pessoal'

const HEADER_HEIGHT = '50px'
const TabelaSalario = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const { anoMes } = useAmbiente(false, true)

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(
          `SalarioMinimo/GetByDate?value=${moment(anoMes).format('yyyy-MM-DD')}`,
        )
        const salarioMinimo = response?.data?.data

        setCollection((oldState) => ({
          ...oldState,
          itens: [salarioMinimo],
          isLoading: false,
        }))
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1} bottom={1}>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={collection.itens} isLoading={collection.isLoading} query={query} />
      </Box>
    </>
  )
}

export default TabelaSalario
