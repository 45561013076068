import { formatToCNPJ } from 'brazilian-values'

import { Box, Divider, Grid, Paper, Typography, useTheme } from '@material-ui/core'

import { CopyLabel, Finder, LabelValueColor } from '~/components'

import { formatAnoMes } from '~/hooks/useUtils'
import { ExtrairPlanilhaResponseDTO } from '~/hooks/queries/ImportacaoPlanilhaFaltas/dtos/ExtrairPlanilhaResponseDTO'
import { IndLancamentoFaltaEnum } from '~/@types/enums/IndLancamentoFaltaEnum'

interface HeaderProps {
  data?: ExtrairPlanilhaResponseDTO
  setQuery: (query: string) => void
}

export default function Header({ data, setQuery }: HeaderProps) {
  const theme = useTheme()

  const funcionarios = data?.funcionarios || []

  let totalFaltas = 0
  let totalAtrasos = 0
  let totalDSRs = 0

  funcionarios.forEach((d) => {
    if (d.indLancamento === IndLancamentoFaltaEnum.Falta) {
      totalFaltas += 1
    }
    if (d.indLancamento === IndLancamentoFaltaEnum.Atraso) {
      totalAtrasos += 1
    }
    if (d.isDSR) {
      totalDSRs += 1
    }
  })

  return (
    <Box component={Paper} p={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            style={{
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            Competência:
            <Box fontWeight="bolder" fontSize="12pt" color="#666">
              {data?.competencia ? formatAnoMes(data.competencia) : ''}
            </Box>
          </Typography>
          <Typography
            style={{
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            Estabelecimento:
            <CopyLabel value={data?.estabelecimentoNrInscricao || 'Estabelecimento'}>
              <Box fontWeight="bolder" fontSize="12pt" color="#2E8BC0">
                {data?.estabelecimentoNome
                  ? data?.estabelecimentoNome +
                    ' - ' +
                    formatToCNPJ(data.estabelecimentoNrInscricao)
                  : ''}
              </Box>
            </CopyLabel>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)} height="100%">
            <Divider orientation="vertical" />
            <Box width="100px">
              <LabelValueColor
                color="#2E8BC0"
                label="Total de Faltas"
                showDivider={false}
                value={totalFaltas}
              />
            </Box>
            <Divider orientation="vertical" />
            <Box width="100px">
              <LabelValueColor
                color="#2E8BC0"
                label="Total de Atrasos"
                showDivider={false}
                value={totalAtrasos}
              />
            </Box>
            <Divider orientation="vertical" />
            <Box width="100px">
              <LabelValueColor
                color="#2E8BC0"
                label="DSR Descontado"
                showDivider={false}
                value={totalDSRs}
              />
            </Box>
            <Divider orientation="vertical" />
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
