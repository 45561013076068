import React, { useState, useEffect, useCallback, memo, useMemo } from 'react'

import { Box, makeStyles, Grid, Collapse, IconButton } from '@material-ui/core'
import { Print as PrintIcon } from '@material-ui/icons'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'

import { AlertContainer, PDFViewer, Button, PageHeader, Finder, ButtonBox } from '~/components'

import Table from './components/Table'
import Footer from './components/Footer'

import TableItens from './components/TableItens'
import Afastamento from './components/Afastamento'
import DialogConfirmPrint from './components/DialogConfirmPrint'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useUtils from '~/hooks/useUtils'

import api from '~/services/api-pessoal'

const useStyles = makeStyles(() => ({
  contentAlert: {
    width: '100%',
    maxHeight: '56px',
  },
  header: {
    maxHeight: '56px',
    overflow: 'auto',
  },
}))

const MemoTable = memo(Table)
const MemoFooter = memo(Footer)

const Provisao13Salario = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    isLoadingProcess: false,
    itens: [],
  })

  const [tableItens, setTableItens] = useState({
    itens: [],
    isOpen: false,
    vinculo: {},
  })
  const [formAfastamento, setFormAfastamento] = useState({
    isOpen: false,
    vinculo: {},
  })

  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)
  const [query, setQuery] = useState('')
  const [isLoadingProvisoes, setLoadingProvisoes] = useState(false)

  const classes = useStyles()
  const { formatCurrency, formatAnoMes } = useUtils()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const {
    close: closeDialogConfirmPrint,
    isOpen: isOpenDialogConfirmPrint,
    open: openDialogConfirmPrint,
  } = useDialog()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(
          '/ProvisaoCompetencia/GetProvisaoDtSalarioByEstabelecimento',
          {
            params: {
              estabelecimentoId: estabelecimento.id,
              anoMes,
            },
          },
        )
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    //eslint-disable-next-line
  }, [estabelecimento, anoMes])

  const handleProcess = useCallback(async () => {
    setCollection((oldState) => ({ ...oldState, isLoadingProcess: true }))
    try {
      const response = await api.get('ProvisaoCompetencia/ProcessarDtSalario', {
        params: {
          estabelecimentoId: estabelecimento.id,
          anoMes,
        },
      })
      notification.success('Provisões processadas com Sucesso')
      setCollection((oldState) => ({
        ...oldState,
        itens: response.data.data,
        isLoadingProcess: false,
      }))
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({ ...oldState, isLoadingProcess: false }))
    //eslint-disable-next-line
  }, [anoMes, estabelecimento.id])

  const handleClickItem = useCallback((event, vinculo) => {
    const handleClickProvisoes = async (vinculo) => {
      setLoadingProvisoes(true)
      try {
        const response = await api.get('ProvisaoCompetencia/GetProvisaoDtSalarioByVinculo', {
          params: {
            vinculoId: vinculo.id,
          },
        })
        setTableItens({
          itens: response.data.data,
          isOpen: true,
          vinculo,
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingProvisoes(false)
      }
    }

    const handleClickAfastamentos = (vinculo) => {
      setFormAfastamento({
        isOpen: true,
        vinculo,
      })
    }

    const maps = {
      provisoes: handleClickProvisoes,
      afastamentos: handleClickAfastamentos,
    }
    maps[event](vinculo)
    //eslint-disable-next-line
  }, [])

  const valuesForFooter = useMemo(() => {
    let totalVrProvisao = 0
    let totalVrContribuicaoPrevidenciaria = 0
    let totalVrContribuicaoTerceiros = 0
    let totalVrFGTS = 0
    let totalVrPIS = 0

    collection.itens.forEach((item) => {
      totalVrProvisao = totalVrProvisao + item.vrProvisao
      totalVrContribuicaoPrevidenciaria =
        totalVrContribuicaoPrevidenciaria + item.vrContribuicaoPrevidenciaria
      totalVrContribuicaoTerceiros = totalVrContribuicaoTerceiros + item.vrContribuicaoTerceiros
      totalVrFGTS = totalVrFGTS + item.vrFGTS
      totalVrPIS = totalVrPIS + item.vrPIS
    })

    return {
      totalVrProvisao: formatCurrency(totalVrProvisao),
      totalVrContribuicaoPrevidenciaria: formatCurrency(totalVrContribuicaoPrevidenciaria),
      totalVrContribuicaoTerceiros: formatCurrency(totalVrContribuicaoTerceiros),
      totalVrFGTS: formatCurrency(totalVrFGTS),
      totalVrPIS: formatCurrency(totalVrPIS),
    }
  }, [collection.itens, formatCurrency])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleCloseTableItens = () => {
    setTableItens((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }

  const handleCloseFormAfastamento = () => {
    setFormAfastamento({
      vinculo: {},
      isOpen: false,
    })
  }

  function handleAfterSubmitDialogConfirmPrint(dt) {
    openPDFViewer({
      estabelecimentoId: estabelecimento.id,
      competencia: anoMes,
      ...dt,
    })
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box id="root-header" pb={1}>
        <PageHeader title="Provisão de 13° Salário">
          <ButtonBox>
            <IconButton
              size="small"
              disabled={!(collection.itens.length > 0)}
              onClick={openDialogConfirmPrint}
              color="primary"
              title="Imprimir relatório de provisão"
            >
              <PrintIcon fontSize="small" />
            </IconButton>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
            <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
              {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
            </IconButton>
          </ButtonBox>
        </PageHeader>

        <Collapse in={formCollapseIsOpen}>
          <AlertContainer>
            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={8} sm={8} xs={12} className={classes.header}>
                Processar Provisões de 13° Salário do Estabelecimento{' '}
                <strong>
                  {estabelecimento.codigo} - {estabelecimento.nome}
                </strong>{' '}
                na competência <strong>{formatAnoMes(anoMes)}</strong>
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <ButtonBox top={1}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleProcess}
                    isLoading={collection.isLoadingProcess}
                  >
                    Processar
                  </Button>
                </ButtonBox>
              </Grid>
            </Grid>
          </AlertContainer>
        </Collapse>
      </Box>

      <MemoTable
        data={collection.itens}
        isLoading={collection.isLoading}
        query={query}
        onItemClick={handleClickItem}
        triggersHeight={formCollapseIsOpen}
        isFetching={isLoadingProvisoes}
      />

      <Box id="root-footer" height="75px">
        <MemoFooter totais={valuesForFooter} />
      </Box>

      <TableItens
        isOpen={tableItens.isOpen}
        onClose={handleCloseTableItens}
        data={tableItens.itens}
        vinculo={tableItens.vinculo}
      />

      <Afastamento
        isOpen={formAfastamento.isOpen}
        onClose={handleCloseFormAfastamento}
        vinculo={formAfastamento.vinculo}
      />

      <DialogConfirmPrint
        isOpen={isOpenDialogConfirmPrint}
        onClose={closeDialogConfirmPrint}
        onAfterSubmit={handleAfterSubmitDialogConfirmPrint}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="ProvisaoDtSalario"
        title="Provisão de 13° Salário"
        axiosConfig={{
          method: 'post',
          url: '/ProvisaoCompetencia/ObterRelatorioDtSalario',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}

export default Provisao13Salario
