import { useState } from 'react'
import clsx from 'clsx'

import {
  makeStyles,
  Box,
  IconButton,
  Icon,
  Menu,
  MenuItem,
  ListItemText,
  Badge,
  CircularProgress,
} from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menu: {},
  menuItem: {
    paddingTop: theme.spacing(0.7),
    paddingBottom: theme.spacing(0.7),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  menuIcon: {
    minWidth: '15px',
    minHeight: '15px',
  },
  primary: {
    default: theme.palette.primary.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  info: {
    color: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  danger: {
    color: theme.palette.error.main,
  },
}))

type ActionMenuSeverity = 'success' | 'info' | 'warning' | 'danger'

export interface ActionMenuOption {
  icon: string
  label: string
  onClick: () => void
  title?: string
  severity?: ActionMenuSeverity
  disabled?: boolean
  badgeContent?: number
  hidden?: boolean
}

interface ActionMenuProps {
  options: ActionMenuOption[]
  disabled?: boolean
  isLoading?: boolean
}

export default function ActionMenu(props: ActionMenuProps) {
  const { options: _options, disabled, isLoading } = props

  const options = _options.filter((opt) => !opt.hidden)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const classes = useStyles()

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Box className={classes.root}>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <IconButton
          aria-label="more"
          aria-controls="action-menu-button"
          aria-haspopup="true"
          onClick={handleClickMenu}
          size="small"
          color="primary"
          disabled={disabled}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      )}
      <Menu
        id="action-menu"
        className={classes.menu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options.map((opt) => (
          <MenuItem
            className={clsx(classes.menuItem, classes[opt.severity || 'primary'])}
            key={opt.label}
            title={opt.title}
            disabled={opt.disabled}
            onClick={() => {
              handleCloseMenu()
              opt.onClick()
            }}
          >
            <Badge badgeContent={opt.badgeContent}>
              <Icon fontSize="small" className={classes.menuIcon}>
                {opt.icon}
              </Icon>
            </Badge>
            <ListItemText primary={opt.label} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
