import React, { useState, useEffect, useCallback } from 'react'

import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker, CurrencyTextField } from '~/components'

import moment from 'moment'
import * as yup from 'yup'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

const schema = yup.object().shape({
  dtSalario: yup
    .date()
    .required('Informe Data Salário')
    .typeError('Informe uma Data válida')
    .nullable()
    .when('$dtAdmissao', (dtAdmissao, schema) => {
      return schema.test(
        'testDt',
        'A Data Salário não pode ser menor que a Data de Admissão',
        (dtSalario) => {
          if (!dtAdmissao || !dtSalario) return false
          return moment(dtAdmissao).isSameOrBefore(dtSalario)
        },
      )
    }),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, vinculo, collection, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
    if (collection.length === 0 && !_data?.id)
      setData({
        ..._data,
        dtSalario: vinculo?.dtAdmissao,
      })
  }, [_data, collection, vinculo])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/vinculoSalarioAula/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/vinculoSalarioAula', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: {
        dtAdmissao: vinculo?.dtAdmissao,
      },
    },
  })

  return (
    <ActionDialog
      title="Cadastro de Salário Aula"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Salário"
            size="small"
            value={data.dtSalario || null}
            required
            validationErrors={validationErrors}
            name="dtSalario"
            onChange={(date) => {
              const dtSalario = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtSalario })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Aliq. Titulação"
            fullWidth
            value={data.aliqTitulacao || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const aliqTitulacao = e.target.value
              setData({ ...data, aliqTitulacao })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Infantil"
            fullWidth
            value={data.infantil || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const infantil = e.target.value
              setData({ ...data, infantil })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Fundamental I"
            fullWidth
            value={data.fundamentalI || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const fundamentalI = e.target.value
              setData({ ...data, fundamentalI })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Fundamental II"
            fullWidth
            value={data.fundamentalII || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const fundamentalII = e.target.value
              setData({ ...data, fundamentalII })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Fundamental III"
            fullWidth
            value={data.fundamentalIII || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const fundamentalIII = e.target.value
              setData({ ...data, fundamentalIII })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Jovem Adulto"
            fullWidth
            value={data.jovemAdulto || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const jovemAdulto = e.target.value
              setData({ ...data, jovemAdulto })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Curso Livre"
            fullWidth
            value={data.cursoLivre || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const cursoLivre = e.target.value
              setData({ ...data, cursoLivre })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Pre-Vestibular"
            fullWidth
            value={data.preVestibular || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const preVestibular = e.target.value
              setData({ ...data, preVestibular })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Superior"
            fullWidth
            value={data.superior || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const superior = e.target.value
              setData({ ...data, superior })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="EAD"
            fullWidth
            value={data.ead || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const ead = e.target.value
              setData({ ...data, ead })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Especial"
            fullWidth
            value={data?.especial || ''}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const especial = value || null
              setData({ ...data, especial })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Iniciacão Científica"
            fullWidth
            value={data.iniciacaoCientifica || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const iniciacaoCientifica = e.target.value
              setData({ ...data, iniciacaoCientifica })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="TCC"
            fullWidth
            value={data.tcc || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const tcc = e.target.value
              setData({ ...data, tcc })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Estágio"
            fullWidth
            value={data.estagio || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const estagio = e.target.value
              setData({ ...data, estagio })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Direito Autoral"
            fullWidth
            value={data.direitoAutoral || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const direitoAutoral = e.target.value
              setData({ ...data, direitoAutoral })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Excedente"
            fullWidth
            value={data.excedente || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const excedente = e.target.value
              setData({ ...data, excedente })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <FormControlLabel
            label="Importado"
            control={
              <Checkbox
                checked={data?.isImported || false}
                color="primary"
                size="small"
                onChange={(e) => {
                  const isImported = e.target.checked
                  setData({ ..._data, isImported })
                }}
              />
            }
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data.observacao || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 200,
            }}
            multiline
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
