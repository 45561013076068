import { useQuery } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import api from '~/services/api-pessoal'

import { PropriedadeLog } from './PropriedadeLog'

export function usePropriedadeLogListarTodosPorTabelaPai(idTabelaPai: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: PropriedadeLog[] }>(
      '/PropriedadeLog/ListarTodosPorTabelaPai',
      {
        params: {
          idTabelaPai,
        },
      },
    )
    return response?.data?.data || []
  }

  return useQuery(['PropriedadeLogListarTodosPorTabelaPai', idTabelaPai], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
