import { useEffect, useRef, useState, memo } from 'react'

import { Box, Paper } from '@material-ui/core'

import { ContainerTable, Finder, Stack } from '~/components'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import useSocket from '~/hooks/useSocket'

import { useObterProcessamentoFolha } from '~/hooks/queries/FolhaAutomatica/useObterProcessamentoFolha'

const MemoTable = memo(Table)

export default function Processamento() {
  // const [dataForm, setDataForm] = useState<{ dtAgendamento: string | null }>({
  //   dtAgendamento: null,
  // })
  const [query, setQuery] = useState('')
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])

  const tableRef = useRef<FixLater>(null)

  const { empresaId, anoMes } = useAmbiente()
  // const dialogNotification = useDialogNotification()

  // const { mutateAsync, isLoading: isSubmitting } = useConfigurarFolhaAutomatica()

  const [anoMesAntigo, setAnoMesAntigo] = useState<string | null>(null)

  const prevAnoMesRef = useRef<string | null>(null)
  useEffect(() => {
    if (anoMes !== prevAnoMesRef.current) {
      setAnoMesAntigo(prevAnoMesRef.current)
      prevAnoMesRef.current = anoMes
    }
  }, [anoMes])

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterProcessamentoFolha({
    params: {
      anoMes,
    },
  })
  const data = _d || []

  // useEffect(() => {
  //   setDataForm({
  //     dtAgendamento: moment()
  //       .set('year', ano)
  //       .set('month', parseInt(mes) - 1)
  //       .format('yyyy-MM-DDTHH:mm'),
  //   })
  // }, [ano, mes])

  const socket = useSocket({
    urlHub: '/TaskQueueHub',
    onStartConnection: (socket) => {
      socket.send('AddToGroup', empresaId + anoMes)
    },
  })

  useEffect(() => {
    if (!socket) return
    socket.on('UpdateTask', () => refetch())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  useEffect(() => {
    if (!socket) return
    if (anoMesAntigo) {
      socket.send('RemoveFromGroup', empresaId + anoMesAntigo)
    }
    socket.send('AddToGroup', empresaId + anoMes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, empresaId, anoMesAntigo])

  // async function handleProcess() {
  //   const rowsSelected: number[] = tableRef?.current?.state?.selectedRows?.data.map(
  //     (d: FixLater) => d.dataIndex,
  //   )

  //   if (rowsSelected.length === 0) {
  //     return dialogNotification.warning({
  //       descriptions: ['Informe os Empregadores para prosseguir'],
  //     })
  //   }

  //   const empregadoresIds = rowsSelected.map((index) => data[index].empregadorId)

  //   await mutateAsync({
  //     data: {
  //       empregadoresIds,
  //       anoMes,
  //       dtAgendamento: dataForm.dtAgendamento,
  //     },
  //   })
  // }

  return (
    <Stack height="100%">
      <Stack
        component={Paper}
        p={1}
        orientation="horizontal"
        alignItems="center"
        justifyContent="space-between"
        height={55}
      >
        <Box width={300}>
          {/* <TextField
            type="datetime-local"
            label="Data de Agendamento"
            InputLabelProps={{
              shrink: true,
            }}
            value={dataForm.dtAgendamento}
            onChange={(e) => {
              const dtAgendamento = e.target.value || null
              setDataForm((prev) => ({ ...prev, dtAgendamento }))
            }}
          /> */}
        </Box>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </Stack>

      <ContainerTable>
        <MemoTable
          data={data}
          rowsExpanded={rowsExpanded}
          setRowsExpanded={setRowsExpanded}
          query={query}
          isLoading={isLoading}
          isFetching={isFetching}
          tableRef={tableRef}
          socket={socket}
        />
      </ContainerTable>

      {/* <Stack alignItems="end" component={Paper} p={1}>
        <Button variant="contained" onClick={handleProcess} isLoading={isSubmitting}>
          Iniciar Processamento
        </Button>
      </Stack> */}
    </Stack>
  )
}
