import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifyRemove } from '~/utils/notification'

import { useNotificacaoObterNotificacoesClient } from './useNotificacaoObterNotificacoes'
import { useNotificacaoObterNotificacoesAmbienteClient } from './useNotificacaoObterNotificacoesAmbiente'

interface RequestProps {
  id: string
  params: { isEnvironmentCommon: boolean }
}

export default function useNotificacaoRemover() {
  const dialogNotification = useDialogNotification()
  const { invalidateQuery } = useNotificacaoObterNotificacoesClient()
  const { invalidateQuery: invalidateQueryAmbiente } =
    useNotificacaoObterNotificacoesAmbienteClient()

  async function handleRequest({ id, params }: RequestProps) {
    await api.delete('/Notificacao/' + id, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      invalidateQuery()
      invalidateQueryAmbiente()
    },
    onError: dialogNotification.extractErrors,
  })
}
