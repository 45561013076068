import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryModeloPlanilhaImportacao } from './useModeloPlanilhaImportacao'

export default function useModeloPlanilhaImportacaoRemover() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest(id: string) {
    await api.delete('/ModeloPlanilhaImportacao/' + id)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.remove()
      await queryClient.invalidateQueries(queryModeloPlanilhaImportacao)
    },
    onError: dialogNotification.extractErrors,
  })
}
