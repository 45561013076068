import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoSalarioBase } from './VinculoSalarioBase'

export const query = 'VinculoConsultaGetByEstabelecimento'

export function useVinculoSalarioBaseGetByVinculo(vinculoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoSalarioBase[] }>(
      '/vinculoSalarioBase/GetByVinculo/' + vinculoId,
    )
    return response?.data?.data || []
  }

  const queryResult = useQuery([query, vinculoId], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })

  return {
    ...queryResult,
    data: queryResult.data || [],
  }
}
