import clsx from 'clsx'

import { Box, Table, TableHead, TableRow, TableCell, Checkbox, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: FixLater) => ({
  root: {
    minWidth: 100,
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter,
      cursor: 'pointer',
    },
  },
}))

interface DayOfWeekProps {
  title: string
  description: string
  isSelected: boolean
  onSelect: () => void
}

export default function DayOfWeek({ title, description, isSelected, onSelect }: DayOfWeekProps) {
  const classes = useStyles()
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            className={clsx(classes.root, classes.hover)}
            onClick={onSelect}
            title={description}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Checkbox color="primary" size="small" checked={isSelected} />
              {title}
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  )
}
