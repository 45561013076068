import React from 'react'

import { ProviderRecibo } from '~/hooks/useRecibo'

import ReciboPagamento from './content'

export default function ReciboPagamentoContainer() {
  return (
    <ProviderRecibo>
      <ReciboPagamento />
    </ProviderRecibo>
  )
}
