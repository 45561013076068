import React, { useState } from 'react'

import { makeStyles, Typography, LinearProgress } from '@material-ui/core'
import { Backup as UploadIcon } from '@material-ui/icons'
import { ErrorMessage } from 'mio-library-ui'

import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  dragDropContainer: {
    border: (props) =>
      props.disabled ? `2px dashed ${theme.palette.action.disabled}` : '2px dashed #ccc',
    borderRadius: theme.shape.borderRadius,
    height: (props) => props.height,
    width: (props) => props.width,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    cursor: (props) => (props.disabled ? 'auto' : 'pointer'),
  },
  backupIcon: {
    fontSize: '42pt',
    color: (props) => (props.disabled ? theme.palette.action.disabled : '#ccc'),
  },
  uploadContainerMessage: {
    fontSize: '13pt',
    fontWeight: 'bolder',
    color: (props) => (props.disabled ? theme.palette.action.disabled : '#666'),
  },
}))

const DragDropFile = (props) => {
  const {
    maxSize,
    maxFiles,
    acceptFiles,
    width,
    height,
    uploadMessage,
    onDropped,
    disabled,
    title,
    isLoading,
  } = props
  const [messageError, setMessageError] = useState('')

  const classes = useStyles({ width, height, disabled })

  const onDrop = (files) => {
    setMessageError('')
    onDropped(files)
  }

  const onDropRejected = (files) => {
    files.forEach((file) => {
      file.errors.forEach((error) => {
        if (error.code === 'file-too-large')
          setMessageError('Não foi possível inserir, tamanho máximo de arquivos atingido')
        if (error.code === 'too-many-files')
          setMessageError('Não foi possível inserir, limite de arquivos atingido')
        if (error.code === 'file-invalid-type')
          setMessageError('Não foi possível inserir, tipo de arquivo inválido')
      })
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: maxSize,
    maxFiles: maxFiles,
    accept: acceptFiles,
    onDrop,
    onDropRejected,
    disabled,
  })

  return (
    <>
      <div className={classes.dragDropContainer} {...getRootProps()} title={title}>
        <input {...getInputProps()} />

        <UploadIcon className={classes.backupIcon} />
        <Typography className={classes.uploadContainerMessage}>{uploadMessage}</Typography>

        {/* {isDragActive && <div>Arraste aqui</div>}
        {isDragReject && <div>Deu um errinho</div>} */}
      </div>
      {messageError && <ErrorMessage error={messageError} />}
      {isLoading && <LinearProgress />}
    </>
  )
}

DragDropFile.defaultProps = {
  maxSize: 10485760,
  maxFiles: 1,
  acceptFiles: ['.txt'],
  width: '100%',
  height: '100%',
  uploadMessage: 'Clique ou solte os arquivos aqui para fazer o upload',
  disabled: false,
  title: '',
  isLoading: false,
}

DragDropFile.propTypes = {
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  acceptFiles: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uploadMessage: PropTypes.string,
  onDropped: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default DragDropFile
