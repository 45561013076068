import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPost } from '~/utils/notification'
import { useInvalidate } from './useObterTodos'

import { ModeloIntegracaoAdicionarDTO } from './dtos/ModeloIntegracaoAdicionarDTO'
import { ModeloIntegracao } from './dtos/ModeloIntegracao'

interface RequestProps {
  data: ModeloIntegracaoAdicionarDTO
}

export function useAdicionar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ data }: RequestProps) {
    const response = await api.post<{ data: ModeloIntegracao }>('/ModeloIntegracao/Adicionar', data)
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
