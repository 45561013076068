import React from 'react'

import { ToolsDataTable } from '~/components'

import { formatCurrency } from '~/hooks/useUtils'

import { IndGrauExposicaoValues } from '~/@types/enums/IndGrauExposicaoEnum'
import { IndMultiploVinculoValues } from '~/@types/enums/IndMultiploVinculoEnum'
import { IndSimplesValues } from '~/@types/enums/IndSimplesEnum'
import { DialogBaseCalculoVinculoRP } from '../DialogBaseCalc'

interface DialogRPsProps {
  data: DialogBaseCalculoVinculoRP[]
}

export default function TableRPs(props: DialogRPsProps) {
  const { data: _data } = props

  const data = _data.map((d) => ({
    ...d,
    indGrauExposicao:
      IndGrauExposicaoValues.find((ind) => ind.value === d.indGrauExposicao)?.name || '',
    indMultiplosVinculos:
      IndMultiploVinculoValues.find((ind) => ind.value === d.indMultiplosVinculos)?.name || '',
    indSimples: IndSimplesValues.find((ind) => ind.value === d.indSimples)?.name || '',
    vrBaseINSS: d?.vrBaseINSS ? formatCurrency(d.vrBaseINSS) : '',
    vrBaseINSS13: d?.vrBaseINSS13 ? formatCurrency(d.vrBaseINSS13) : '',
    vrBaseFGTS: d?.vrBaseFGTS ? formatCurrency(d.vrBaseFGTS) : '',
    vrBaseFGTS13: d?.vrBaseFGTS13 ? formatCurrency(d.vrBaseFGTS13) : '',
    vrBaseFGTSAvisoPrevio: d?.vrBaseFGTSAvisoPrevio ? formatCurrency(d.vrBaseFGTSAvisoPrevio) : '',
  }))

  const columns = [
    {
      name: 'indGrauExposicao',
      label: 'Grau Exposição',
    },
    {
      name: 'indMultiplosVinculos',
      label: 'Múltiplos Vínculos',
    },
    {
      name: 'indSimples',
      label: 'Ind. Simples',
    },
    {
      name: 'reciboESocial',
      label: 'Recibo eSocial',
    },
    {
      name: 'vrBaseINSS',
      label: 'Base INSS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrBaseINSS13',
      label: 'Base INSS 13°',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrBaseFGTS',
      label: 'Base FGTS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrBaseFGTS13',
      label: 'Base FGTS 13°',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrBaseFGTSAvisoPrevio',
      label: 'Base FGTS Aviso Prévio',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
  ]

  return <ToolsDataTable data={data} columns={columns} disableAutoHeight pagination={false} />
}
