import React from 'react'
import { ContainerComponent } from '~/components'
import useAmbiente from '~/hooks/useAmbiente'
import FormEvento from '~/pages/Evento/components/Container/components/Form'
import EventoFormulas from '~/pages/Evento/components/Container/components/EventoFormulas'
import HistoricoConfiguracaoEvento from './components/HistoricoConfiguracaoEvento'

export default function ContainerEvento(props) {
  const { data, isOpen, onClose, onAfterSubmitForm } = props
  const { empregador } = useAmbiente()

  return (
    <ContainerComponent
      title="Cadastro de Evento"
      subtitle={data?.codigo || data?.nome ? `${data?.codigo} - ${data?.nome} ` : '-'}
      isOpen={isOpen}
      onClose={onClose}
      tabs={[
        {
          component: (
            <FormEvento
              data={data}
              onClose={onClose}
              onAfterSubmitForm={onAfterSubmitForm}
              setWasModified={() => undefined}
            />
          ),
          label: 'Cadastro',
        },
        {
          component: (
            <EventoFormulas eventoId={data.id} onClose={onClose} />
          ),
          label: 'Fórmulas',
        },
        {
          component: (
            <HistoricoConfiguracaoEvento eventoId={data?.id} empregadorId={empregador?.id} />
          ),
          label: 'Histórico de Configuração do Evento',
        },
      ]}
    />
  )
}
