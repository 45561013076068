import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useGetAllByVinculoInvalidate } from './useGetAllByVinculo'

import { notifySuccess } from '~/utils/notification'

interface RequestParams {
  processoTrabalhistaVinculoId: string
}

export function useRetificar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useGetAllByVinculoInvalidate()

  async function handleRequest({ processoTrabalhistaVinculoId }: RequestParams) {
    await api.post('/ProcessoTrabalhistaVinculo/Retificar/' + processoTrabalhistaVinculoId)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      handleInvalidate()
      notifySuccess('Processo Trabalhista Funcionário retificado com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
