import { useState } from 'react'

import * as yup from 'yup'
import moment from 'moment'

import { Grid } from '@material-ui/core'

import { DatePickerNew, ContentDivider, TextField, Checkbox, Button } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'
import { useStepperContext } from '~/components/StepperForm'

import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import { DayOfWeekEnum } from '~/@types/enums/DayOfWeekEnum'
import {
  IndMotivoDesligamentoEnum,
  IndMotivoDesligamentoValues,
} from '~/@types/enums/IndMotivoDesligamentoEnum'
import { RescisaoFGTSValues, RescisaoFGTSEnum } from '~/@types/enums/RescisaoFGTSEnum'
import { HomologNetValues, HomologNetEnum } from '~/@types/enums/HomologNetEnum'

import { TermoRescisaoDTOForm } from '~/hooks/queries/Rescisao/TermoRescisaoDTO'
import { ButtonBox } from 'mio-library-ui'

const schema = yup.object().shape({
  dtRescisao: yup
    .string()
    .required('Informe a Data de Rescisão')
    .when(['$anoMes', '$dtAdmissao'], (anoMes, dtAdmissao, schema, { value }) => {
      const inicioCompetencia = moment(anoMes).format('YYYY-MM-DD')
      const fimCompetencia = moment(anoMes).endOf('month').format('YYYY-MM-DD')
      const dtRescisao = moment(value).format('YYYY-MM-DD')
      const dtAdmissaoFormated = moment(dtAdmissao).format('YYYY-MM-DD')

      if (dtRescisao < dtAdmissaoFormated) {
        return schema.min(dtRescisao, 'Data de Rescisão deve ser maior que Data de Admissão')
      }
      if (dtRescisao > fimCompetencia) {
        return schema.max(fimCompetencia, 'Informe uma Data dentro da competência')
      }
      if (dtRescisao < inicioCompetencia) {
        return schema.min(inicioCompetencia, 'Informe uma Data dentro da competência')
      }
    })
    .nullable(),
  motivoDesligamento: yup
    .mixed()
    .oneOf(Object.values(IndMotivoDesligamentoEnum), 'Informe o Motivo de Desligamento')
    .test(
      'valid-enum',
      'Informe o Motivo de Desligamento válido',
      (value) => value !== IndMotivoDesligamentoEnum.NaoAplicavel_99,
    ),
  dtHomologacao: yup.date().required('Informe a Data de Homologação').nullable(),
  rescisaoFGTS: yup.mixed().oneOf(Object.values(RescisaoFGTSEnum), 'Informe a Rescisão FGTS'),
  rescisaoHomologNet: yup
    .mixed()
    .oneOf(Object.values(HomologNetEnum), 'Informe a Rescisão Homolognet')
    .test(
      'valid-enum',
      'Informe a Rescisão Homolognet válida',
      (value) => value !== HomologNetEnum.NaoSeApica_99,
    ),
})

export default function ReciboRescisaoForm() {
  const { mainData, onCancel, onNext } = useStepperContext<TermoRescisaoDTOForm>()

  const [data, setData] = useState(mainData)

  const notification = useNotification()
  const { anoMes } = useAmbiente()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { anoMes, dtAdmissao: data.dtAdmissao },
    },
  })

  const dtFimContratoExperiencia =
    data?.contratoExperienciaDtProrrogacao || data?.contratoExperienciaDtVencimento

  const isAntecipacaoContratoExperiencia = dtFimContratoExperiencia
    ? moment(data.dtRescisao).isBefore(dtFimContratoExperiencia)
    : false

  const isEncerrarContratoExperiencia = dtFimContratoExperiencia
    ? moment(data.dtRescisao).isSame(dtFimContratoExperiencia)
    : false

  const motivosDesligamento = IndMotivoDesligamentoValues.filter((d) => {
    if (isAntecipacaoContratoExperiencia) {
      return (
        d.value === IndMotivoDesligamentoEnum.RescisaoAntecipadaIniciativaEmpregador_03 ||
        d.value === IndMotivoDesligamentoEnum.RescisaoAntecipadaIniciativaEmpregado_04
      )
    }
    if (isEncerrarContratoExperiencia) {
      return d.value === IndMotivoDesligamentoEnum.RescisãoTerminoContrato_06
    }
    return true
  })

  function handleSubmit() {
    onNext(data)
  }

  const calcDtHomologacao = (dtRescisao: string) => {
    const dtHomologacao = moment(dtRescisao, 'yyyy-MM-DD')
    if (!dtHomologacao.isValid()) return
    dtHomologacao.add(9, 'd')
    const notificationMsg = 'Data de Homologação foi ajustada para Segunda Feira!'
    if (dtHomologacao.day() === DayOfWeekEnum.Sabado_6) {
      dtHomologacao.add(2, 'd')
      notification.warning(notificationMsg)
    }
    if (dtHomologacao.day() === DayOfWeekEnum.Domingo_0) {
      dtHomologacao.add(1, 'd')
      notification.warning(notificationMsg)
    }

    setData((prev) => ({
      ...prev,
      dtRescisao,
      dtHomologacao: dtHomologacao.format('yyyy-MM-DD'),
    }))
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
        <DatePickerNew label="Data de Admissão" value={data?.dtAdmissao || null} disabled />
      </Grid>

      <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
        <DatePickerNew
          label="Data de Rescisão"
          value={data?.dtRescisao || null}
          name="dtRescisao"
          required
          validationErrors={validationErrors}
          onChange={(dt) => {
            const dtRescisao = dt
            if (dtRescisao) return calcDtHomologacao(dtRescisao)
            setData((prev) => ({
              ...prev,
              dtRescisao,
              motivoDesligamento: null,
            }))
          }}
        />
      </Grid>

      <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
        <DatePickerNew
          label="Data de Homologação"
          size="small"
          required
          value={data?.dtHomologacao || null}
          name="dtHomologacao"
          validationErrors={validationErrors}
          onChange={(dt) => {
            const dtHomologacao = dt
            setData({
              ...data,
              dtHomologacao,
            })
          }}
        />
      </Grid>

      <Grid xs={12} item>
        <MUIAutoComplete
          label="Motivo de Desligamento"
          name="motivoDesligamento"
          options={motivosDesligamento}
          optionId="value"
          renderOption={(option: FixLater) => option.name}
          required
          validationErrors={validationErrors}
          value={data?.motivoDesligamento || ''}
          onChange={(e: string, obj: FixLater) => {
            const motivoDesligamento = obj ? obj.value : ''
            let rescisaoFGTS: RescisaoFGTSEnum | undefined = undefined
            let rescisaoHomologNet: HomologNetEnum | undefined = undefined
            let codigoSaqueFGTS = undefined
            let isFGTSGuiaRescisoria = false

            if (
              motivoDesligamento ===
              IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02
            ) {
              rescisaoFGTS = RescisaoFGTSEnum.Rescisao_sem_justa_causa_empregador
              rescisaoHomologNet = HomologNetEnum.DespedidaSemJustaCausaPeloEmpregador_SJ2
              codigoSaqueFGTS = '01'
              isFGTSGuiaRescisoria = true
            }

            if (motivoDesligamento === IndMotivoDesligamentoEnum.RescisãoTerminoContrato_06) {
              codigoSaqueFGTS = '04'
              isFGTSGuiaRescisoria = true
            }

            if (motivoDesligamento === IndMotivoDesligamentoEnum.RescisaoAcordo_33) {
              codigoSaqueFGTS = '07'
            }

            setData({
              ...data,
              motivoDesligamento,
              rescisaoFGTS,
              rescisaoHomologNet,
              isFGTSGuiaRescisoria,
              isFGTSMesAnterior: false,
              codigoSaqueFGTS,
            })
          }}
        />
      </Grid>

      <Grid sm={6} xs={12} item>
        <Checkbox
          label="Recolher FGTS do mês anterior em Guia Rescisória"
          value={data?.isFGTSMesAnterior || false}
          disabled={
            data.motivoDesligamento === IndMotivoDesligamentoEnum.RescisãoIniciativaEmpregador_07
          }
          onChange={(e, value) => {
            const isFGTSMesAnterior = value
            setData((oldState) => ({
              ...oldState,
              isFGTSMesAnterior,
            }))
          }}
        />
      </Grid>
      <Grid sm={6} xs={12} item>
        <Checkbox
          label="FGTS do mês recolhido em Guia Rescisória"
          value={data?.isFGTSGuiaRescisoria || false}
          onChange={(e, value) => {
            const isFGTSGuiaRescisoria = value
            setData((oldState) => ({
              ...oldState,
              isFGTSGuiaRescisoria,
            }))
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <MUIAutoComplete
          label="Rescisão FGTS"
          options={RescisaoFGTSValues}
          renderOption={(option: FixLater) => `${option.value} - ${option.name}`}
          value={data?.rescisaoFGTS || null}
          onChange={(e: string, obj: FixLater) => {
            const rescisaoFGTS = obj?.value || null
            setData({ ...data, rescisaoFGTS })
          }}
          optionId="value"
          required
          validationErrors={validationErrors}
          name="rescisaoFGTS"
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <MUIAutoComplete
          label="Rescisão Homolognet"
          options={HomologNetValues}
          renderOption={(option) => option.name}
          value={data?.rescisaoHomologNet || ''}
          onChange={(e: string, obj: FixLater) => {
            const rescisaoHomologNet = obj?.value || ''
            setData({ ...data, rescisaoHomologNet })
          }}
          optionId="value"
          required
          validationErrors={validationErrors}
          name="rescisaoHomologNet"
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ContentDivider title="Informações sobre o Aviso Prévio" />
      </Grid>

      <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
        <DatePickerNew label="Data de Início" disabled value={data?.dtInicioAvisoPrevio || null} />
      </Grid>

      <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
        <DatePickerNew
          label="Data de Projeção"
          disabled
          value={data?.dtProjecaoAvisoPrevio || null}
        />
      </Grid>

      <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
        <TextField label="Número de dias" disabled value={data?.numeroDiasDeAvisoPrevio || ''} />
      </Grid>

      <Grid xl={3} lg={3} md={3} sm={6} xs={12} item>
        <TextField
          label="Número de dias Indenizado"
          disabled
          value={data?.numeroDiasDeAvisoPrevioIndenizado || ''}
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ContentDivider title="Informações Exclusiva eSocial" />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <TextField
          label="Observação"
          value={data?.observacao || ''}
          inputProps={{ maxLength: 1000 }}
          onChange={(e) => {
            const observacao = e.target.value
            setData({
              ...data,
              observacao,
            })
          }}
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
