import { useEffect, useState } from 'react'

import { formatToCPF } from 'brazilian-values'

import { Box, Paper, Typography, useTheme } from '@material-ui/core'

import { AutoCompletePlanoSaude } from '~/components/AutoComplete'
import {
  ContainerTable,
  CopyLabel,
  CurrencyTextField,
  PageHeader,
  Stack,
  StackContainer,
  ToolsDataTable,
} from '~/components'

import TableDependente from './TableDependente'

import { useObterLancamentosTitular } from '~/hooks/queries/PlanoSaudeLancamento/useObterLancamentosTitular'
import { useGerarLancamentoTitular } from '~/hooks/queries/PlanoSaudeLancamento/useGerarLancamentoTitular'

import useAmbiente from '~/hooks/useAmbiente'

import { MUIDataTableColumnDef } from 'mui-datatables'

export default function ManutencaoCusto() {
  const [rowsExpanded, setRowsExpanded] = useState([])

  const [dataForm, setDataForm] = useState({
    planoSaudeId: '',
  })

  const { anoMes, estabelecimento } = useAmbiente()
  const theme = useTheme()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterLancamentosTitular({
    params: {
      estabelecimentoId: estabelecimento.id,
      planoSaudeId: dataForm.planoSaudeId,
      anoMes,
    },
  })
  const data = _d || []

  useEffect(() => {
    setRowsExpanded([])
  }, [_d])

  const { mutateAsync, isLoading: isSubmitting } = useGerarLancamentoTitular()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'pessoaCPF',
      label: 'CPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      name: 'pessoaNome',
      label: 'Nome',
    },
    {
      name: 'lancamentoId',
      label: 'Valor do Custo',
      options: {
        customBodyRender: (value) => {
          const dataCurrent = data.find((d) => d.lancamentoId == value)
          if (!dataCurrent) return
          return (
            <CurrencyTextField
              value={dataCurrent.vrCusto}
              onBlur={async (_, value) => {
                const vrCusto = value
                if (vrCusto === dataCurrent.vrCusto) return
                await mutateAsync({
                  ...dataCurrent,
                  vrCusto,
                })
              }}
            />
          )
        },
      },
    },
    {
      name: 'lancamentoId',
      label: 'Valor do Custo da Empresa',
      options: {
        customBodyRender: (value) => {
          const dataCurrent = data.find((d) => d.lancamentoId == value)
          if (!dataCurrent) return
          return (
            <CurrencyTextField
              value={dataCurrent.vrCustoEmpresa}
              onBlur={async (_, value) => {
                const vrCustoEmpresa = value
                if (vrCustoEmpresa === dataCurrent.vrCustoEmpresa) return
                await mutateAsync({
                  ...dataCurrent,
                  vrCustoEmpresa,
                })
              }}
            />
          )
        },
      },
    },
  ]

  return (
    <StackContainer>
      <PageHeader title="Plano de Saúde - Manutenção de Custo" />

      <Stack component={Paper} p={1} alignItems="center" orientation="horizontal">
        <Typography
          style={{
            display: 'flex',
            gap: theme.spacing(1),
            width: 400,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Lançamento de custos para a competência:
          <Box fontWeight="bolder" fontSize="12pt" color="#2E8BC0">
            {anoMes.substring(0, 4)}/{anoMes.substring(4)}
          </Box>
        </Typography>
        <Box flex={1}>
          <AutoCompletePlanoSaude
            value={dataForm.planoSaudeId}
            onChange={(e, d) => {
              const planoSaudeId = d?.id || ''
              setDataForm((prev) => ({ ...prev, planoSaudeId }))
            }}
          />
        </Box>
      </Stack>

      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching || isSubmitting}
          disableAutoHeight
          optionsExpandable={{
            onRowExpanded: setRowsExpanded,
            rowsExpanded: rowsExpanded,
            type: 'single',
            expandedChildren: (index) => (
              <TableDependente planoSaudeTitularId={data[index].lancamentoId} />
            ),
          }}
        />
      </ContainerTable>
    </StackContainer>
  )
}
