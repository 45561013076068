const normal_1 = 1
const alterado_2 = 2
const estavel_3 = 3
const agravamento_4 = 4

export const ASOIndResultadoValues = [
  { value: normal_1, name: '1 - Normal' },
  { value: alterado_2, name: '2 - Alterado' },
  { value: estavel_3, name: '3 - Estável' },
  { value: agravamento_4, name: '4 - Agravamento' },
]

export const ASOIndResultadoConsts = {
  normal_1,
  alterado_2,
  estavel_3,
  agravamento_4,
}
