import { Box, Grid, useTheme } from '@material-ui/core'

import { ContentDivider, MaskedTextField, SwitchField } from '~/components'

interface TextFieldContaProps {
  label: string
  value: string
  mask: string
  onChange: (value: string) => void
}

function TextFieldConta({ label, value, mask, onChange }: TextFieldContaProps) {
  return (
    <MaskedTextField
      label={label}
      value={value}
      mask={mask}
      placeholder={mask}
      onChange={(e: any) => onChange(e.target.value)}
      fullWidth
      variant="outlined"
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

interface RowContaProps {
  label: string
  mask: string
  valueDebito: string
  onChangeDebito: (value: string) => void
  valueCredito: string
  onChangeCredito: (value: string) => void
  valueIgnorar?: boolean
  onChangeIgnorar?: (value: boolean) => void
}

export default function RowConta({
  label,
  mask,
  onChangeDebito,
  valueDebito,
  onChangeCredito,
  valueCredito,
  onChangeIgnorar,
  valueIgnorar,
}: RowContaProps) {
  const theme = useTheme()

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <ContentDivider title={label} />
      <Box display="flex" gridGap={theme.spacing(2)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextFieldConta
              mask={mask}
              label="Conta Débito"
              value={valueDebito}
              onChange={onChangeDebito}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldConta
              mask={mask}
              label="Conta Crédito"
              value={valueCredito}
              onChange={onChangeCredito}
            />
          </Grid>
        </Grid>

        {onChangeIgnorar && (
          <SwitchField label="Ignorar" value={valueIgnorar || false} onChange={onChangeIgnorar} />
        )}
      </Box>
    </Box>
  )
}
