import React, { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { AutoCompleteHorarioTrabalho } from '~/components/AutoComplete'
import MultipleSelect from '~/components/MultipleSelect'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoHorarioItemPartial } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'
import { DiaHorarioEnum, DiaHorarioValues } from '~/@types/enums/DiaHorarioEnum'
import { HorarioTrabalho } from '~/hooks/queries/HorarioTrabalho/HorarioTrabalho'

const schema = yup.object().shape({
  diasHorarios: yup.array().min(1, 'Informe pelo menos um Dia'),
  horarioTrabalhoId: yup.string().required('Informe o Horário de Trabalho'),
})

interface DiaHorarioData {
  diasHorarios?: { value: DiaHorarioEnum; name: string }[]
  horarioTrabalhoId?: string
  horarioTrabalho?: HorarioTrabalho
}

interface FormItemProps {
  isOpen: boolean
  vinculoHorarioId?: string
  onClose: () => void
  onAfterSubmitForm: (data: VinculoHorarioItemPartial[]) => void
}

export default function FormItem(props: FormItemProps) {
  const { isOpen, vinculoHorarioId, onClose, onAfterSubmitForm } = props

  const [data, setData] = useState<DiaHorarioData | null>(null)

  const { empregador } = useAmbiente()

  const handleSubmit = () => {
    const diasHorariosCurrent = data?.diasHorarios || []
    const dataItems: VinculoHorarioItemPartial[] = diasHorariosCurrent.map((d) => ({
      diaHorario: d.value,
      horarioTrabalhoId: data?.horarioTrabalhoId,
      horarioTrabalho: data?.horarioTrabalho,
      vinculoHorarioId,
    }))
    onAfterSubmitForm(dataItems)
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Adicionar Horários de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Adicionar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MultipleSelect
            inputProps={{
              label: 'Dias da semana trabalhado',
              required: true,
            }}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect
            required
            validationErrors={validationErrors}
            name="diasHorarios"
            value={data?.diasHorarios || []}
            options={DiaHorarioValues}
            onChange={(e, selecteds: { value: DiaHorarioEnum; name: string }[]) => {
              const diasHorarios = selecteds
              setData((prev) => ({ ...prev, diasHorarios }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteHorarioTrabalho
            value={data?.horarioTrabalhoId || ''}
            empregadorId={empregador?.id}
            required
            validationErrors={validationErrors}
            name="horarioTrabalhoId"
            label="Horário de Trabalho"
            onChange={(e, horarioTrabalho) => {
              const horarioTrabalhoId = horarioTrabalho?.id || ''
              setData((prev) => ({ ...prev, horarioTrabalhoId, horarioTrabalho }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
