import { ToolsDataTable, ButtonBox } from '~/components'

import {
  Edit as EditIcon,
  MonetizationOn as MonetizationOnIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { PlanoSaudeDependente } from '~/hooks/queries/PlanoSaudeDependente/dtos/PlanoSaudeDependente'

import { formatPrettyAnoMes } from '~/hooks/useUtils'
import { formatCurrency } from '~/utils/utils'
import { formatToCPF } from 'brazilian-values'

interface TableProps {
  data: PlanoSaudeDependente[]
  isLoading: boolean
  isFetching: boolean
  onItemClick: (event: 'edit' | 'delete' | 'custo', id: string) => void
  query: string
}

export default function Table(props: TableProps) {
  const { data: _d, isLoading, isFetching, onItemClick, query } = props

  const data = _d.map((d) => ({
    ...d,
    dependenteCPF: d.pessoaFisicaDependente?.nrInscricao || '',
    dependenteNome: d.pessoaFisicaDependente?.nome || '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dependenteCPF',
      label: 'CPF',
      options: {
        customBodyRender: (value) => (value ? formatToCPF(value) : ''),
      },
    },
    {
      name: 'dependenteNome',
      label: 'Nome',
    },
    {
      name: 'anoMesInicio',
      label: 'Ano/Mês Início',
      options: {
        customBodyRender: (value) => (value ? formatPrettyAnoMes(value) : ''),
      },
    },
    {
      name: 'anoMesFim',
      label: 'Ano/Mês Fim',
      options: {
        customBodyRender: (value) => (value ? formatPrettyAnoMes(value) : ''),
      },
    },
    {
      name: 'vrMensalidade',
      label: 'Valor da Mensalidade',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'end',
          },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Lançamento de custos"
                color="primary"
                aria-label="Custos"
                onClick={() => {
                  onItemClick('custo', value)
                }}
              >
                <MonetizationOnIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      pagination={false}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['dependenteCPF', 'dependenteNome', 'anoMesInicio', 'anoMesFim'],
      }}
    />
  )
}
