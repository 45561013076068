import React, { useMemo } from 'react'

import moment from 'moment'

import { CopyLabel, ToolsDataTable } from '~/components'

const Table = (props) => {
  const { data, isLoading, query, onRowSelected, rowsSelected, isFetching } = props

  const columns = useMemo(
    () => [
      {
        name: 'descricaoAuditoria',
        label: 'Descrição',
      },
      {
        name: 'reciboESocial',
        label: 'Recibo eSocial',
        options: {
          customBodyRender: (value) => <CopyLabel value={value}>{value}</CopyLabel>,
        },
      },
      {
        name: 'dtReciboESocial',
        label: 'Data Recibo eSocial',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
        },
      },
    ],
    [],
  )

  return (
    <ToolsDataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['descricaoAuditoria', 'reciboESocial', 'dtReciboESocial'],
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected: onRowSelected,
        rowsSelected: rowsSelected,
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
