export enum ESocialEventoEnum {
  S_1000 = 1000,
  S_1005 = 1005,
  S_1010 = 1010,
  S_1020 = 1020,
  S_1070 = 1070,
  S_1200 = 1200,
  S_1202 = 1202,
  S_1207 = 1207,
  S_1210 = 1210,
  S_1260 = 1260,
  S_1270 = 1270,
  S_1280 = 1280,
  S_1298 = 1298,
  S_1299 = 1299,
  S_2190 = 2190,
  S_2200 = 2200,
  S_2205 = 2205,
  S_2206 = 2206,
  S_2210 = 2210,
  S_2220 = 2220,
  S_2221 = 2221,
  S_2230 = 2230,
  S_2231 = 2231,
  S_2240 = 2240,
  S_2298 = 2298,
  S_2299 = 2299,
  S_2300 = 2300,
  S_2306 = 2306,
  S_2399 = 2399,
  S_2400 = 2400,
  S_2405 = 2405,
  S_2410 = 2410,
  S_2416 = 2416,
  S_2418 = 2418,
  S_2420 = 2420,
  S_2500 = 2500,
  S_2501 = 2501,
  S_3000 = 3000,
  S_5001 = 5001,
  S_5002 = 5002,
  S_5003 = 5003,
  S_5011 = 5011,
  S_5013 = 5013,
  S_8299 = 8299,
}

export const ESocialEventoValues = [
  {
    value: ESocialEventoEnum.S_1000,
    name: '1000 - Informações do Empregador/Contribuinte/Órgão Público',
  },
  {
    value: ESocialEventoEnum.S_1005,
    name: '1005 - Tabela de Estabelecimentos, Obras ou Unidades de Órgãos Públicos',
  },
  {
    value: ESocialEventoEnum.S_1010,
    name: '1010 - Tabela de Rubricas',
  },
  {
    value: ESocialEventoEnum.S_1020,
    name: '1020 - Tabela de Lotações Tributárias',
  },
  {
    value: ESocialEventoEnum.S_1070,
    name: '1070 - Tabela de Processos Administrativos/Judiciais',
  },
  {
    value: ESocialEventoEnum.S_1200,
    name: '1200 - Remuneração de Trabalhador vinculado ao Regime Geral de Previd.Social',
  },
  {
    value: ESocialEventoEnum.S_1202,
    name: '1202 - Remuneração de Servidor vinculado ao Regime Próprio de Previd.Social',
  },
  {
    value: ESocialEventoEnum.S_1207,
    name: '1207 - Benefícios - Entes Públicos',
  },
  {
    value: ESocialEventoEnum.S_1210,
    name: '1210 - Pagamentos de Rendimentos do Trabalho',
  },
  {
    value: ESocialEventoEnum.S_1260,
    name: '1260 - Comercialização da Produção Rural Pessoa Física',
  },
  {
    value: ESocialEventoEnum.S_1270,
    name: '1270 - Contratação de Trabalhadores Avulsos Não Portuários',
  },
  {
    value: ESocialEventoEnum.S_1280,
    name: '1280 - Informações Complementares aos Eventos Periódicos',
  },
  {
    value: ESocialEventoEnum.S_1298,
    name: '1298 - Reabertura dos Eventos Periódicos',
  },
  {
    value: ESocialEventoEnum.S_1299,
    name: '1299 - Fechamento dos Eventos Periódicos',
  },
  {
    value: ESocialEventoEnum.S_2190,
    name: '2190 - Registro Preliminar de Trabalhador',
  },
  {
    value: ESocialEventoEnum.S_2200,
    name: '2200 - Cadastramento Inicial do Vínculo e Admissão/Ingresso de Trabalhador',
  },
  {
    value: ESocialEventoEnum.S_2205,
    name: '2205 - Alteração de Dados Cadastrais do Trabalhador',
  },
  {
    value: ESocialEventoEnum.S_2206,
    name: '2206 - Alteração de Contrato de Trabalho/Relação Estatutária',
  },
  {
    value: ESocialEventoEnum.S_2210,
    name: '2210 - Comunicação de Acidente de Trabalho',
  },
  {
    value: ESocialEventoEnum.S_2220,
    name: '2220 - Monitoramento da Saúde do Trabalhador',
  },
  {
    value: ESocialEventoEnum.S_2221,
    name: '2221 - Exame Toxicológico',
  },
  {
    value: ESocialEventoEnum.S_2230,
    name: '2230 - Afastamento Temporário',
  },
  {
    value: ESocialEventoEnum.S_2231,
    name: '2231 - Cessão/Exercício em Outro Órgão',
  },
  {
    value: ESocialEventoEnum.S_2240,
    name: '2240 - Condições Ambientais do Trabalho - Agentes Nocivos',
  },
  {
    value: ESocialEventoEnum.S_2298,
    name: '2298 - Reintegração/Outros Provimentos',
  },
  {
    value: ESocialEventoEnum.S_2299,
    name: '2299 - Desligamento',
  },
  {
    value: ESocialEventoEnum.S_2300,
    name: '2300 - Trabalhador Sem Vínculo de Emprego/Estatutário',
  },
  {
    value: ESocialEventoEnum.S_2306,
    name: '2306 - Trabalhador Sem Vínculo de Emprego/Estatutário - Alteração Contratual',
  },
  {
    value: ESocialEventoEnum.S_2399,
    name: '2399 - Trabalhador Sem Vínculo de Emprego/Estatutário',
  },
  // {
  //   value: ESocialEventoEnum.S_2400,
  //   name: '2400 - Cadastro de Beneficiário - Entes Públicos',
  // },
  // {
  //   value: ESocialEventoEnum.S_2405,
  //   name: '2405 - Cadastro de Beneficiário - Entes Públicos - Alteração',
  // },
  // {
  //   value: ESocialEventoEnum.S_2410,
  //   name: '2410 - Cadastro de Benefício - Entes Públicos - Início',
  // },
  // {
  //   value: ESocialEventoEnum.S_2416,
  //   name: '2416 - Cadastro de Benefício - Entes Públicos - Alteração',
  // },
  // {
  //   value: ESocialEventoEnum.S_2418,
  //   name: '2418 - Reativação de Benefício - Entes Públicos',
  // },
  // {
  //   value: ESocialEventoEnum.S_2420,
  //   name: '2420 - Cadastro de Benefício - Entes Públicos - Término',
  // },
  {
    value: ESocialEventoEnum.S_2500,
    name: '2500 - Processo Trabalhista',
  },
  {
    value: ESocialEventoEnum.S_2501,
    name: '2501 - Informações de Tributos Decorrentes de Processo Trabalhista',
  },
  {
    value: ESocialEventoEnum.S_3000,
    name: '3000 - Exclusão de Eventos',
  },
  {
    value: ESocialEventoEnum.S_5001,
    name: '5001 - Informações das Contribuições Sociais por Trabalhador',
  },
  {
    value: ESocialEventoEnum.S_5002,
    name: '5002 - Imposto de Renda Retido na Fonte por Trabalhador',
  },
  {
    value: ESocialEventoEnum.S_5003,
    name: '5003 - Informações do FGTS por Trabalhador',
  },
  {
    value: ESocialEventoEnum.S_5011,
    name: '5011 - Informações das Contribuições Sociais Consolidadas por Contribuinte',
  },
  {
    value: ESocialEventoEnum.S_5013,
    name: '5013 - Informações do FGTS Consolidadas por Contribuinte',
  },
  {
    value: ESocialEventoEnum.S_8299,
    name: '8299 - Baixa Judicial do Vínculo',
  },
]
