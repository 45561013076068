import React, { useEffect, useState } from 'react'

import { Accordion, AccordionDetails, Divider } from '@material-ui/core'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons'

import Button from '../Button'

interface ActionAccordionProps {
  title: string
  children: React.ReactNode | string
  isOpen?: boolean
}

export default function ActionAccordion({
  isOpen: _open = true,
  title,
  children,
}: ActionAccordionProps) {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    setOpen(_open)
  }, [_open])

  return (
    <Accordion expanded={isOpen}>
      <Button
        fullWidth
        onClick={() => setOpen((prev) => !prev)}
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {title}
      </Button>
      <Divider />
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
