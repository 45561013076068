import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoIntermitente } from './VinculoIntermitente'

const queryObterConvocacoesParaRP = '/VinculoIntermitente/ObterConvocacoesParaRP'

export function useObterConvocacoesParaRP(vinculoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoIntermitente[] }>(queryObterConvocacoesParaRP, {
      params: {
        vinculoId,
      },
    })
    return response.data.data
  }

  return useQuery([queryObterConvocacoesParaRP, vinculoId], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
