export enum IndDSREnum {
  UmSexto_1 = 1,
  DiasUteisNaoUteis_2 = 2,
  JornadaDiaria_3 = 3,
}

export const IndDSRValues = [
  { value: IndDSREnum.UmSexto_1, name: '1 - Um sexto do Valor Apurado' },
  {
    value: IndDSREnum.DiasUteisNaoUteis_2,
    name: '2 - Valor Apurado / Dias Úteis * ( Domingos + Feriado ) [ Dias Úteis Tabela Mês ]',
  },
  {
    value: IndDSREnum.JornadaDiaria_3,
    name: '3 - Jornada Diária * ( Domingos + Feriado )',
  },
]
