import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { ESocialExclusaoEventoAuditoriaDTO } from './dtos/ESocialExclusaoEventoAuditoriaDTO'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

const query = '/ESocialExclusaoEvento/ObterEventosExclusaoESocial'

interface RequestProps {
  params: {
    empregadorId: string
    anoMes: string
    esocialEvento: ESocialEventoEnum
    indApuracao: IndApuracaoEnum
  }
}

export function useObterEventosExclusaoESocial({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ESocialExclusaoEventoAuditoriaDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}

export function useObterEventosExclusaoESocialInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
