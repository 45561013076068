import { useQuery as useTQuery } from 'react-query'

import useDialogNotification from '../useDialogNotification'

import api from '~/services/api-pessoal'

async function getEventos() {
  const response = await api.get('/Evento')
  return response?.data?.data
}

export async function atualizarOrdemImpressao(eventoId, ordemImpressao) {
  await api.patch('/Evento/AtualizarOrdemImpressao', ordemImpressao, {
    params: {
      eventoId,
    },
  })
}

export function useQuery() {
  const dialogNotification = useDialogNotification()
  return useTQuery(['Evento'], () => getEventos(), {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
