import React, { useState, useEffect, useCallback } from 'react'

import { makeStyles } from '@material-ui/core'
import { ActionDialog } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

const useStyles = makeStyles(() => ({
  heigthPaper: {
    height: '100%',
  },
}))

const Afastamento = (props) => {
  const { isOpen, onClose, vinculo } = props

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const dialogNotification = useDialogNotification()
  const classes = useStyles()

  useEffect(() => {
    const getCollection = async () => {
      if (!vinculo?.id) {
        setCollection({
          isLoading: false,
          itens: [],
        })
        return
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))
      try {
        const response = await api.get(`/vinculoAfastamento/GetByVinculo/${vinculo.id}`)
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    //eslint-disable-next-line
  }, [vinculo])

  const handleOpenForm = useCallback(
    (
      data = {
        vinculo: vinculo,
        vinculoId: vinculo?.id,
        isAfastamentoMesmoMotivo: false,
      },
    ) => {
      setForm((oldState) => ({
        ...oldState,
        isOpen: true,
        data,
      }))
    },
    [vinculo],
  )

  const handleClickItem = useCallback(
    (id) => {
      const { itens } = collection
      const item = itens.find((i) => i.id === id)
      handleOpenForm(item)
    },
    [collection, handleOpenForm],
  )

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  return (
    <ActionDialog
      title="Afastamento"
      subtitle={vinculo?.pessoaFisica?.nome || ''}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      customActions={<></>}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: {
          paper: classes.heigthPaper,
        },
      }}
    >
      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        onItemClick={handleClickItem}
      />

      <Form isOpen={form.isOpen} data={form.data} onClose={handleCloseForm} />
    </ActionDialog>
  )
}

export default Afastamento
