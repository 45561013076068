import { IconButton } from '@material-ui/core'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import { ModeloPlanilhaImportacao } from '~/hooks/queries/ModeloPlanilhaImportacao/ModeloPlanilhaImportacao'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  data: ModeloPlanilhaImportacao[]
  isLoading: boolean
  isFetching: boolean
  query: string
  onItemClick: (event: 'edit' | 'delete', value: string) => void
}

export default function Table({ data, isLoading, isFetching, query, onItemClick }: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'colunas',
      label: 'Colunas',
      options: {
        customBodyRender: (value) => value.length,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['codigo', 'nome'],
      }}
    />
  )
}
