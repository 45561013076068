import { useState, useEffect, useCallback } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import api from '~/services/api-pessoal'

import { Box, LinearProgress } from '@material-ui/core'

import { ContainerComponent, Button } from '~/components'

import Cadastro from './Cadastro'
import ContainerPessoaFisica from '~/pages/Funcionario/Container/ContainerPessoaFisica'
import VinculoSalarioContratualSimple from '~/pages/Funcionario/Container/VinculoSalarioContratualSimple'
import VinculoLocal from '~/pages/Funcionario/Container/VinculoLocal'
import VinculoObservacao from '~/pages/Funcionario/Container/VinculoObservacao'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useDesfazerRescisaoTSV } from '~/hooks/queries/RescisaoTSV/useDesfazerRescisaoTSV'

export default function Container() {
  const [vinculo, setVinculo] = useState({})
  const [isLoading, setLoading] = useState(false)

  const history = useHistory()
  const { id: idParam } = useParams()
  const { estabelecimento } = useAmbiente()
  const dialogNotification = useDialogNotification()

  const { mutateAsync, isLoading: isLoadingDesfazerRescisao } = useDesfazerRescisaoTSV()

  useEffect(() => {
    if (!vinculo?.id) return
    if (vinculo.estabelecimentoId === estabelecimento.id) return
    goToPageVinculo()
    //eslint-disable-next-line
  }, [estabelecimento])

  const obterVinculo = useCallback(
    async () => {
      if (!idParam) return
      setLoading(true)
      try {
        const response = await api.get(`vinculo/${idParam}`)
        setVinculo(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    },
    // eslint-disable-next-line
    [idParam],
  )

  useEffect(() => {
    obterVinculo()
  }, [obterVinculo])

  const goToPageVinculo = () => {
    history.push({
      pathname: '/autonomo',
    })
  }

  const isRescisaoSimplesTSV = vinculo?.dtRescisao ? true : false

  async function handleDesfazerRescisaoTSV() {
    await mutateAsync({
      params: {
        vinculoId: vinculo?.id,
      },
    })
    await obterVinculo()
  }

  return (
    <Box height="100%">
      <Box height={4}>{isLoading && <LinearProgress />}</Box>
      <Box height="calc(100% - 4px)">
        <ContainerComponent
          title="Cadastro de Autônomos"
          subtitle={vinculo?.pessoaFisica?.nome || '-'}
          onBack={goToPageVinculo}
          fixedContainer
          menu
          renderRight={
            isRescisaoSimplesTSV && (
              <Button
                variant="contained"
                onClick={handleDesfazerRescisaoTSV}
                isLoading={isLoadingDesfazerRescisao}
              >
                Desfazer Rescisão TSV
              </Button>
            )
          }
          tabs={[
            {
              component: <Cadastro data={vinculo} />,
              label: 'Cadastro',
            },
            {
              component: <ContainerPessoaFisica pessoaFisicaId={vinculo.pessoaFisicaId} />,
              label: 'Pessoa Física',
            },
            {
              component: <VinculoSalarioContratualSimple vinculoId={vinculo?.id} />,
              label: 'Serviços',
            },
            {
              component: <VinculoLocal vinculo={vinculo} />,
              label: 'Ambiente Trabalho',
            },
            {
              component: <VinculoObservacao vinculo={vinculo} />,
              label: 'Observação',
            },
          ]}
        />
      </Box>
    </Box>
  )
}
