const cod_1007 = '1007'
const cod_1104 = '1104'
const cod_1120 = '1120'
const cod_1147 = '1147'
const cod_1163 = '1163'
const cod_1201 = '1201'
const cod_1406 = '1406'
const cod_1457 = '1457'
const cod_1503 = '1503'
const cod_1554 = '1554'
const cod_1600 = '1600'
const cod_1651 = '1651'
const cod_1708 = '1708'
const cod_1910 = '1910'
const cod_2003 = '2003'
const cod_2100 = '2100'
const cod_2119 = '2119'
const cod_2127 = '2127'
const cod_2208 = '2208'
const cod_2216 = '2216'
const cod_2305 = '2305'
const cod_2321 = '2321'
const cod_2402 = '2402'
const cod_2429 = '2429'
const cod_2437 = '2437'
const cod_2445 = '2445'
const cod_2500 = '2500'
const cod_2607 = '2607'
const cod_2615 = '2615'
const cod_2631 = '2631'
const cod_2640 = '2640'
const cod_2658 = '2658'
const cod_2682 = '2682'
const cod_2704 = '2704'
const cod_2712 = '2712'
const cod_2801 = '2801'
const cod_2810 = '2810'
const cod_2909 = '2909'
const cod_2917 = '2917'
const cod_3000 = '3000'
const cod_3107 = '3107'
const cod_3204 = '3204'
const cod_4006 = '4006'
const cod_4103 = '4103'
const cod_4200 = '4200'
const cod_4308 = '4308'
const cod_4316 = '4316'
const cod_6009 = '6009'
const cod_6106 = '6106'
const cod_6203 = '6203'
const cod_6300 = '6300'
const cod_6408 = '6408'
const cod_6432 = '6432'
const cod_6440 = '6440'
const cod_6459 = '6459'
const cod_6467 = '6467'
const cod_6505 = '6505'
const cod_6513 = '6513'
const cod_7307 = '7307'
const cod_7315 = '7315'
const cod_8001 = '8001'
const cod_8109 = '8109'
const cod_8133 = '8133'
const cod_8141 = '8141'
const cod_8150 = '8150'
const cod_8168 = '8168'
const cod_8176 = '8176'
const cod_8206 = '8206'
const cod_8257 = '8257'
const cod_9008 = '9008'

export const codigoRecolhimentoIndividual = [
  { value: cod_1007, name: '1007 - Contribuinte individual - recolhimento mensal - nit/pis/pasep' },
  {
    value: cod_1104,
    name: '1104 - Contribuinte individual - recolhimento trimestral - nit/pis/pasep',
  },
  {
    value: cod_1120,
    name: '1120 - Contribuinte individual - recolhimento mensal - com dedução de 45% (lei nº 9.876/99) - nit/pis/pasep',
  },
  {
    value: cod_1147,
    name: '1147 - Contribuinte individual - recolhimento trimestral - com dedução de 45% (lei nº 9.876/99) - nit/pis/p',
  },
  {
    value: cod_1163,
    name: '1163 - Contribuinte individual - Mensal - Plano simplificado',
  },
  {
    value: cod_1406,
    name: '1406 - Segurado facultativo - recolhimento mensal - nit/pis/pasep',
  },
  { value: cod_1910, name: '1910 - Recolhimento Diferença Base INSS' },
]

export const codigoGpsValues = [
  { value: cod_1007, name: '1007 - Contribuinte individual - recolhimento mensal - nit/pis/pasep' },
  {
    value: cod_1104,
    name: '1104 - Contribuinte individual - recolhimento trimestral - nit/pis/pasep',
  },
  {
    value: cod_1120,
    name: '1120 - Contribuinte individual - recolhimento mensal - com dedução de 45% (lei nº 9.876/99) - nit/pis/pasep',
  },
  {
    value: cod_1147,
    name: '1147 - Contribuinte individual - recolhimento trimestral - com dedução de 45% (lei nº 9.876/99) - nit/pis/p',
  },
  {
    value: cod_1163,
    name: '1163 - Contribuinte individual - Mensal - Plano simplificado',
  },
  {
    value: cod_1201,
    name: '1201 - Grc contribuinte individual - debcad (preenchimento exclusivo pelo inss)',
  },
  { value: cod_1406, name: '1406 - Segurado facultativo - recolhimento mensal - nit/pis/pasep' },
  {
    value: cod_1457,
    name: '1457 - Segurado facultativo - recolhimento trimestral - nit/pis/pasep',
  },
  { value: cod_1503, name: '1503 - Segurado especial - recolhimento mensal - nit/pis/pasep' },
  { value: cod_1554, name: '1554 - Segurado especial - recolhimento trimestral - nit/pis/pasep' },
  { value: cod_1600, name: '1600 - Empregado doméstico - recolhimento mensal - nit/pis/pasep' },
  { value: cod_1651, name: '1651 - Empregado doméstico - recolhimento trimestral - nit/pis/pasep' },
  { value: cod_1708, name: '1708 - Reclamatoria trabalhista - nit/pis/pasep' },
  { value: cod_2003, name: '2003 - Empresas optantes pelo simples nacional - cnpj' },
  { value: cod_2100, name: '2100 - Empresas em geral - cnpj' },
  {
    value: cod_2119,
    name: '2119 - Empresas em geral - cnpj - recolhimento exclusivo para outras entidades (sesc, sesi, senai, etc.)',
  },
  {
    value: cod_2127,
    name: '2127 - Cooperativa de trabalho (Recolhimento de contribuições com vencimento dia 15, relativas a seus coope',
  },
  { value: cod_2208, name: '2208 - Empresas em geral - cei' },
  {
    value: cod_2216,
    name: '2216 - Empresas em geral - cei - recolhimento exclusivo para outras entidades (sesc, sesi, senai, etc.)',
  },
  { value: cod_2305, name: '2305 - Entidades filantrópicas com isenção total ou parcial - cnpj' },
  { value: cod_2321, name: '2321 - Entidades filantrópicas com isenção total ou parcial - cei' },
  { value: cod_2402, name: '2402 - Órgãos do poder publico - cnpj' },
  { value: cod_2429, name: '2429 - Órgãos do poder publico - cei' },
  {
    value: cod_2437,
    name: '2437 - Órgãos do poder publico - cnpj - recolhimento sobre aquisição de produto rural do produtor rural pes',
  },
  {
    value: cod_2445,
    name: '2445 - Órgãos do poder publico - cnpj - recolhimento sobre contratação de transportador rodoviário autônomo',
  },
  {
    value: cod_2500,
    name: '2500 - Recolhimento a sobre receita bruta de espetáculos desportivos e contratos de patrocínio - cnpj',
  },
  { value: cod_2607, name: '2607 - Recolhimento sobre a comercialização de produto rural - cnpj' },
  {
    value: cod_2615,
    name: '2615 - Recolhimento sobre a comercialização de produto rural - cnpj - exclusivo para outras entidades (sen',
  },
  {
    value: cod_2631,
    name: '2631 - Contribuição retida sobre a nf/fatura da empresa prestadora de serviço - cnpj',
  },
  {
    value: cod_2640,
    name: '2640 - Contribuição retida sobre a nf/fatura da prestadora de serviço - cnpj (uso exclusivo do órgão do pod',
  },
  {
    value: cod_2658,
    name: '2658 - Contribuição retida sobre a nf/fatura da empresa prestadora de serviço - cei',
  },
  {
    value: cod_2682,
    name: '2682 - Contribuição retida sobre a nf/fatura da prestadora de serviço - cei (uso exclusivo do órgão do pode',
  },
  { value: cod_2704, name: '2704 - Recolhimento sobre a comercialização de produto rural - cei' },
  {
    value: cod_2712,
    name: '2712 - Recolhimento sobre a comercialização de produto rural - cei - exclusivo para outras entidades (senar',
  },
  { value: cod_2801, name: '2801 - Reclamatoria trabalhista - cei' },
  {
    value: cod_2810,
    name: '2810 - Reclamatoria trabalhista - cei - recolhimento exclusivo para outras entidades (sesc, sesi, senai, et',
  },
  { value: cod_2909, name: '2909 - Reclamatoria trabalhista - cnpj' },
  {
    value: cod_2917,
    name: '2917 - Reclamatoria trabalhista - cnpj - recolhimento exclusivo para outras entidades (sesc, sesi, senai, e',
  },
  { value: cod_3000, name: '3000 - Acal - cnpj' },
  { value: cod_3107, name: '3107 - Acal - cei' },
  {
    value: cod_3204,
    name: '3204 - Grc - contribuição de empresa normal - debcad (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_4006,
    name: '4006 - Pagamento de debito - debcad (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_4103,
    name: '4103 - Pagamento de debito - cnpj (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_4200,
    name: '4200 - Pagamento de debito administrativo - numero do titulo de cobrança (preenchimento exclusivo pelo inss',
  },
  {
    value: cod_4308,
    name: '4308 - Pagamento de parcelamento administrativo - numero do titulo de cobrança (preenchimento exclusivo pel',
  },
  {
    value: cod_4316,
    name: '4316 - Pagamento de parcelamento de clube de futebol - cnpj - (5% da receita bruta destinada ao clube de fu',
  },
  {
    value: cod_6009,
    name: '6009 - Pagamento de divida ativa debito - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_6106,
    name: '6106 - Pagamento de divida ativa parcelamento - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_6203,
    name: '6203 - Recebimento de credito ou de divida ativa - ação judicial - referencia',
  },
  {
    value: cod_6300,
    name: '6300 - Pagamento de divida ativa, cobrança amigável - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_6408,
    name: '6408 - Conversão em receita de deposito judicial - casos anteriores a lei nº 9.703/98 - cnpj',
  },
  {
    value: cod_6432,
    name: '6432 - Conversão em receita de deposito judicial - casos anteriores a lei nº 9.703/98 - cei',
  },
  {
    value: cod_6440,
    name: '6440 - Conversão em receita de deposito judicial - casos anteriores a lei nº 9.703/98 - debcad',
  },
  {
    value: cod_6459,
    name: '6459 - Conversão em receita de deposito judicial - casos anteriores a lei nº 9.703/98 - nb',
  },
  {
    value: cod_6467,
    name: '6467 - Conversão em receita de deposito judicial - casos anteriores a lei nº 9.703/98 - nit/pis/pasep',
  },
  {
    value: cod_6505,
    name: '6505 - COMPREV - Pagamento de Dívida Ativa - Parcelamento de Regime Próprio de Previdência Social RPPS - Ór',
  },
  {
    value: cod_6513,
    name: '6513 - COMPREV - Pagamento de Dívida Ativa - Não parcelada de Regime Próprio de Previdência Social RPPS - Ó',
  },
  {
    value: cod_7307,
    name: '7307 - COMPREV - Recolhimento efetuado por Regime Próprio de Previdência Social RPPS - Órgão do Poder Públi',
  },
  {
    value: cod_7315,
    name: '7315 - COMPREV - Recolhimento efetuado por Regime Próprio de Previdência Social RPPS - Órgão do Poder Públi',
  },
  {
    value: cod_8001,
    name: '8001 - Financiamento imobiliário - referencia (preenchimento exclusivo pelo inss)',
  },
  { value: cod_8109, name: '8109 - Alugueis - referencia (preenchimento exclusivo pelo inss)' },
  {
    value: cod_8133,
    name: '8133 - Condomínio a titulo de reembolso - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_8141,
    name: '8141 - Parcelamento de financiamento imobiliário - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_8150,
    name: '8150 - Parcelamento de alugueis - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_8168,
    name: '8168 - Taxa de ocupação - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_8176,
    name: '8176 - Impostos e taxas de reembolso - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_8206,
    name: '8206 - Alienação de bens imóveis - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_8257,
    name: '8257 - Alienação de bens moveis - referencia (preenchimento exclusivo pelo inss)',
  },
  {
    value: cod_9008,
    name: '9008 - Devolução de beneficio - nb (preenchimento exclusivo pelo inss)',
  },
]

export const codigoGpsConsts = {
  cod_1007,
  cod_1104,
  cod_1120,
  cod_1147,
  cod_1201,
  cod_1406,
  cod_1457,
  cod_1503,
  cod_1554,
  cod_1600,
  cod_1651,
  cod_1708,
  cod_2003,
  cod_2100,
  cod_2119,
  cod_2127,
  cod_2208,
  cod_2216,
  cod_2305,
  cod_2321,
  cod_2402,
  cod_2429,
  cod_2437,
  cod_2445,
  cod_2500,
  cod_2607,
  cod_2615,
  cod_2631,
  cod_2640,
  cod_2658,
  cod_2682,
  cod_2704,
  cod_2712,
  cod_2801,
  cod_2810,
  cod_2909,
  cod_2917,
  cod_3000,
  cod_3107,
  cod_3204,
  cod_4006,
  cod_4103,
  cod_4200,
  cod_4308,
  cod_4316,
  cod_6009,
  cod_6106,
  cod_6203,
  cod_6300,
  cod_6408,
  cod_6432,
  cod_6440,
  cod_6459,
  cod_6467,
  cod_6505,
  cod_6513,
  cod_7307,
  cod_7315,
  cod_8001,
  cod_8109,
  cod_8133,
  cod_8141,
  cod_8150,
  cod_8168,
  cod_8176,
  cod_8206,
  cod_8257,
  cod_9008,
}
