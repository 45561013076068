import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { DCTFWebRequestBodyDTO } from './DCTFWebRequestBodyDTO'

export function useEnviarGerenciadorDocumentos() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(requestProps: DCTFWebRequestBodyDTO) {
    await api.post('/DCTFWeb/EnviarGerenciadorDocumentos', requestProps)
  }

  return useMutation(handleRequest, {
    onSuccess: () =>
      notification.success('Documentos foram enviados para o Gerenciador de Documentos'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
