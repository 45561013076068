import moment from 'moment'

import { useAtualizarCTPSObterFerias } from '~/hooks/queries/AtualizarCTPS/useAtualizarCTPSObterFerias'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ContainerTable, Stack, ToolsDataTable } from '~/components'

interface TabFeriasProps {
  vinculoId: string
}

export default function TabFerias({ vinculoId }: TabFeriasProps) {
  const { data: _d, isLoading, isFetching } = useAtualizarCTPSObterFerias({ params: { vinculoId } })
  const data = _d || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtPeriodoInicio',
      label: 'Período Início',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'dtPeriodoFim',
      label: 'Período Fim',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'dtConcedida',
      label: 'Período Concedido',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'dtInicioConcessao',
      label: 'Início Férias',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'dtFimConcessao',
      label: 'Fim Férias',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'nrDiasFerias',
      label: 'Dias Férias',
    },
    {
      name: 'dtInicioAbono',
      label: 'Início Abono',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'dtFimAbono',
      label: 'Fim Abono',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
      },
    },
    {
      name: 'nrDiasAbono',
      label: 'Dias Abono',
    },
    {
      name: 'nrDiasConcedidos',
      label: 'Dias Concedidos',
    },
  ]

  return (
    <Stack height="100%">
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          disableAutoHeight
        />
      </ContainerTable>
    </Stack>
  )
}
