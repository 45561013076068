import { v4 as uuidv4 } from 'uuid'

import { Box, IconButton, useTheme } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { Button, ButtonBox, ContentDivider, ToolsDataTable } from '~/components'

import FormItem from './FormItem'

import useDialog from '~/hooks/useDialog'
import { formatCurrency } from '~/hooks/useUtils'

import { TerceirosItem } from '~/hooks/queries/TerceirosItem/TerceirosItem'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface TerceiroItensProps {
  data: TerceirosItem[]
  onChange: (data: TerceirosItem[]) => void
}

export default function TerceiroItens(props: TerceiroItensProps) {
  const { data: _d, onChange } = props

  const data = _d.map((d) => ({
    ...d,
    aliqTerceiros: d?.aliqTerceiros ? formatCurrency(d.aliqTerceiros) : '',
  }))

  const {
    close: closeFormItem,
    data: dataFormItem,
    isOpen: isOpenFormItem,
    open: openFormItem,
  } = useDialog<TerceirosItem | null>(null)
  const theme = useTheme()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'aliqTerceiros',
      label: 'Alíquota',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const itemFinded = _d?.find((d) => d.id === value)
                  if (!itemFinded) return
                  openFormItem(itemFinded)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  const newData = _d.filter((d) => d.id !== value)
                  onChange(newData)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  function handleChangeTerceiros(terceirosItem: TerceirosItem) {
    if (terceirosItem.id) {
      onChange(_d.map((d) => (d.id === terceirosItem.id ? terceirosItem : d)))
    } else {
      terceirosItem.id = uuidv4()
      onChange([..._d, terceirosItem])
    }
    closeFormItem()
  }

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <ContentDivider
        title="Terceiros Itens"
        renderRight={
          <Button variant="contained" onClick={() => openFormItem(null)}>
            Adicionar
          </Button>
        }
      />

      <ToolsDataTable data={data} columns={columns} />
      {isOpenFormItem && (
        <FormItem
          isOpen={isOpenFormItem}
          onClose={closeFormItem}
          data={dataFormItem}
          onChange={handleChangeTerceiros}
        />
      )}
    </Box>
  )
}
