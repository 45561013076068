import { useState } from 'react'

import { Box, Tab, Tabs, useTheme } from '@material-ui/core'

import { PageHeader, TabPanel } from '~/components'

import ImportarFaltas from './ImportarFaltas'
import GerarPlanilha from './GerarPlanilha'
import Importadas from './Importadas'

enum TabImportacaoFaltas {
  GerarPlanilha,
  ImportarFaltas,
  Importadas,
}

export default function ImportacaoPlanilhaFaltas() {
  const [tab, setTab] = useState(TabImportacaoFaltas.GerarPlanilha)

  const theme = useTheme()

  return (
    <Box height="100%" padding={2} display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <PageHeader title="Importação de Faltas">
        <Box>
          <Tabs value={tab} onChange={(e, value) => setTab(value)}>
            <Tab label="Gerar Planilha" />
            <Tab label="Importar Faltas" />
            <Tab label="Importadas" />
          </Tabs>
        </Box>
      </PageHeader>

      <Box flex={1}>
        <TabPanel value={tab} index={TabImportacaoFaltas.GerarPlanilha} style={{ height: '100%' }}>
          <GerarPlanilha />
        </TabPanel>
        <TabPanel
          value={tab}
          index={TabImportacaoFaltas.ImportarFaltas}
          style={{ height: '100%' }}
          keepMount
        >
          <ImportarFaltas />
        </TabPanel>
        <TabPanel value={tab} index={TabImportacaoFaltas.Importadas} style={{ height: '100%' }}>
          <Importadas />
        </TabPanel>
      </Box>
    </Box>
  )
}
