import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon, Print as PrintIcon } from '@material-ui/icons'

import moment from 'moment'

import { HeightDataTable } from '~/components'

import { tipoAcidenteValues } from '~/values/tipoAcidenteValues'
import { tipoCATValues } from '~/values/tipoCATValues'

const formatTipoAcidente = (tipoAcidente) => {
  const objFinded = tipoAcidenteValues.find((obj) => obj.value === tipoAcidente)
  return objFinded.name
}

const formatTipoCAT = (tipoCAT) => {
  const objFinded = tipoCATValues.find((obj) => obj.value === tipoCAT)
  return objFinded.name
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtAcidente: item?.dtAcidente ? moment(item.dtAcidente).format('DD/MM/YYYY') : null,
      tipoAcidente: formatTipoAcidente(item.tipoAcidente),
      horaAcidente: item?.horaAcidente ? item?.horaAcidente.substr(0, 5) : '',
      tipoCAT: formatTipoCAT(item.tipoCAT),
      dtReciboeSocial: item?.dtReciboeSocial
        ? moment(item.dtReciboeSocial).format('DD/MM/YYYY')
        : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtAcidente',
        label: 'Data do Acidente',
      },
      {
        name: 'tipoAcidente',
        label: 'Tipo de Acidente',
      },
      {
        name: 'horaAcidente',
        label: 'Hora do Acidente',
      },
      {
        name: 'tipoCAT',
        label: 'Tipo de CAT',
      },
      {
        name: 'reciboeSocial',
        label: 'Recibo eSocial',
      },
      {
        name: 'dtReciboeSocial',
        label: 'Data Recibo eSocial',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Imprimir este registro"
                  color="primary"
                  aria-label="Imprimir"
                  onClick={() => {
                    onItemClick('print', value)
                  }}
                >
                  <PrintIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['dtAcidente', 'tipoAcidente', 'reciboeSocial'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
