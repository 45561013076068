import { Evento } from './Evento'

import useSimpleStartRequest from '../useSimpleStartRequest'

export function useEventoGetById(eventoId: string) {
  const response = useSimpleStartRequest<Evento | null>(
    {
      url: '/Evento/' + eventoId,
    },
    null,
  )
  return response
}
