import { useQuery, useQueryClient, useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'
import useSimpleStartRequest from './useSimpleStartRequest'

interface VinculoLotacao {
  id: string
  empregadorId: string
  codigo: number
  nome: string
  endereco: string | null
  bairro: string | null
  numero: number | null
  complemento: string | null
  cidadeId: string | null
  cep: string | null
  observacao: string | null
  fbEmpresa: number
  empregador: null
  cidade: null
}

export interface VinculoLotacaoData {
  id: string
  vinculoId: string
  lotacaoId: string
  anoMes: string | null
  dias: number | null
  observacao: string | null
  fbCodigo: number
  vinculo: null
  lotacao: VinculoLotacao
}

type VinculoLotacaoDataPartial = Partial<VinculoLotacaoData>

export function useVinculoLotacaoGetAllPorVinculo(vinculoId: string | undefined) {
  const dialogNotification = useDialogNotification()

  async function getAllPorVinculo() {
    const response = await api.get<{ data: VinculoLotacaoData[] }>(
      '/VinculoLotacao/GetByVinculo/' + vinculoId,
    )
    return response.data.data
  }

  return useQuery(['VinculoLotacao', vinculoId], () => getAllPorVinculo(), {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useVinculoLotacaoById(id: string | null, vinculoId: string) {
  return useSimpleStartRequest<VinculoLotacaoDataPartial>(
    {
      url: '/VinculoLotacao/' + id,
      method: 'GET',
    },
    { vinculoId },
    [id],
    () => (id ? false : true),
  )
}

export function useVinculoLotacaoMutation() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function update(data: VinculoLotacaoDataPartial) {
    await api.put('/VinculoLotacao/' + data.id, data)
    notification.put()
  }
  async function insert(data: VinculoLotacaoDataPartial) {
    await api.post('/VinculoLotacao', data)
    notification.post()
  }

  return useMutation(
    async (data: VinculoLotacaoDataPartial) => {
      if (data.id) {
        await update(data)
        return
      }
      await insert(data)
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries('VinculoLotacao'),
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}

export function useVinculoLotacaoDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleDelete(id: string) {
    await api.delete(`/VinculoLotacao/${id}`)
    notification.remove()
  }

  return useMutation((id: string) => handleDelete(id), {
    onSuccess: async () => await queryClient.invalidateQueries('VinculoLotacao'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
