import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifySuccess } from '~/utils/notification'

import { ExtrairPlanilhaResponseDTO } from './dtos/ExtrairPlanilhaResponseDTO'

export default function useExtrairPlanilha() {
  const dialogNotification = useDialogNotification()

  async function handleRequest(file: FormData) {
    const response = await api.post<{ data: ExtrairPlanilhaResponseDTO }>(
      '/ImportacaoPlanilhaFaltas/ExtrairPlanilha',
      file,
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Planilha extraída com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
