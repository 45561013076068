const contratoExperiencia = 1
const prorrogacaoContrato = 2

export const contratoExperienciaValues = [
  {
    value: contratoExperiencia,
    name: 'Contrato de Experiência',
  },
  {
    value: prorrogacaoContrato,
    name: 'Prorrogação do Contrato de Experiência',
  },
]

export const contratoExperienciaConsts = {
  contratoExperiencia,
  prorrogacaoContrato,
}
