import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import { RPFerias } from './RPFerias'
import useDialogNotification from '~/hooks/useDialogNotification'

const query = '/RPFerias/ObterDesmembramentoFeriasPorRecibo/'

export function useObterDesmembramentoFeriasPorRecibo(rpId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: RPFerias[] }>(query + rpId)
    return response.data.data
  }

  return useQuery([query, rpId], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
