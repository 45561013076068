import { useState } from 'react'

import * as yup from 'yup'

import { Box, useTheme } from '@material-ui/core'

import { ActionDialog, TextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCreateMotivo } from '~/hooks/queries/Motivo/useCreateMotivo'
import { useUpdateMotivo } from '~/hooks/queries/Motivo/useUpdateMotivo'

import { Motivo } from '~/hooks/queries/Motivo/Motivo'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: Motivo | null
}

export default function Form({ isOpen, onClose, data: _data }: FormProps) {
  const isUpdate = _data ? true : false

  const [data, setData] = useState<Motivo>(
    _data || {
      id: '',
      codigo: 0,
      nome: '',
    },
  )

  const { mutateAsync: mutateAsyncCreate, isLoading: isCreating } = useCreateMotivo()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } = useUpdateMotivo()

  const isLoading = isCreating || isUpdating

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const theme = useTheme()

  async function handleSubmit() {
    if (isUpdate) {
      await mutateAsyncUpdate({
        data: {
          nome: data.nome,
        },
        id: data.id,
      })
    } else {
      await mutateAsyncCreate({
        nome: data.nome,
      })
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Motivo"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Box display="flex" gridGap={theme.spacing(1)} flexDirection="column">
        {isUpdate && <TextField label="Código" value={data.codigo} disabled />}

        <TextField
          label="Nome"
          value={data.nome}
          onChange={(e) => {
            const nome = e.target.value
            setData((prev) => ({
              ...prev,
              nome,
            }))
          }}
          required
          validationErrors={validationErrors}
          name="nome"
        />
      </Box>
    </ActionDialog>
  )
}
