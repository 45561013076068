import React, { useEffect, useState, memo } from 'react'

import { Box } from '@material-ui/core'
import { Button, PageHeader, Finder } from 'mio-library-ui'

import Table from './components/Table'

import { Checkbox } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'
import { useCallback } from 'react'

const MemoTable = memo(Table)

const DARFIR = () => {
  const [collection, setCollection] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [indexsSelecteds, setIndexsSelecteds] = useState([])
  const [query, setQuery] = useState('')
  const [isOnlyCompetencialAtual, setOnlyCompetenciaAtual] = useState(true)

  const [dataTableExpandable, setDataTableExpandable] = useState([])
  const [isLoadingTableExpandable, setLoadingTableExpandable] = useState(false)
  const [rowsExpanded, setRowsExpanded] = useState([])

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes } = useAmbiente()

  useEffect(() => {
    async function getCollection() {
      setLoading(true)
      try {
        const response = await api.get('/EstabelecimentoConsulta')
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  const resetFields = useCallback(() => {
    setDataTableExpandable([])
    setIndexsSelecteds([])
    setRowsExpanded([])
  }, [])

  useEffect(() => {
    resetFields()
  }, [resetFields, isOnlyCompetencialAtual, anoMes])

  async function handleProcess() {
    setLoading(true)
    try {
      if (indexsSelecteds.length === 0)
        throw new Error('Por favor selecione pelo menos um Estabelecimento')
      const ids = indexsSelecteds.map((indexCurrent) => collection[indexCurrent].id)
      await api.post('DarfPIS/CalcularDarfPIS', ids, {
        params: { anoMes },
      })
      resetFields()
      notification.success('Registros calculados com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  async function onRowExpanded(indexs) {
    setRowsExpanded(indexs)
    if (indexs?.length === 0) return
    setLoadingTableExpandable(true)
    try {
      const estabelecimentoCurrent = collection[indexs[0]]

      const response = await api.get('DarfPIS/GetDarfPIS', {
        params: {
          estabelecimentoId: estabelecimentoCurrent.id,
          anoMes: isOnlyCompetencialAtual ? anoMes : undefined,
        },
      })
      setDataTableExpandable(response?.data?.data || [])
    } catch (error) {
      dialogNotification.extractErrors(error)
    } finally {
      setLoadingTableExpandable(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box height={60} overflow="auto">
        <PageHeader title="DARF PIS">
          <Checkbox
            label="Mostrar DARF PIS apenas da Competência atual"
            value={isOnlyCompetencialAtual}
            onChange={(_, value) => setOnlyCompetenciaAtual(value)}
          />
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </PageHeader>
      </Box>

      <MemoTable
        data={collection}
        isLoading={isLoading}
        onRowSelected={setIndexsSelecteds}
        rowsSelected={indexsSelecteds}
        query={query}
        dataTableExpandable={dataTableExpandable}
        isLoadingTableExpandable={isLoadingTableExpandable}
        rowsExpanded={rowsExpanded}
        onRowExpanded={onRowExpanded}
      />

      <Box display="flex" justifyContent="flex-end" pt={1}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleProcess}
          isLoading={isLoading}
        >
          Calcular PIS
        </Button>
      </Box>
    </Box>
  )
}

export default DARFIR
