import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import {
  ActionDialog,
  CEPTextField,
  CurrencyTextField,
  DatePickerNew,
  NumeroInscricaoTextField,
  TelefoneTextField,
  TextField,
} from '~/components'
import { AutoCompleteCidade, MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useFacultativoAtualizar, useFacultativoCriar } from '~/hooks/queries/Facultativo'

import { Facultativo } from '~/hooks/queries/Facultativo/dtos/Facultativo'

import { codigoRecolhimentoIndividual } from '~/values/codigoGpsValues'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'
import { formatToNIT } from '~/utils/utils'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  endereco: yup.string().required('Informe o Endereço'),
  bairro: yup.string().required('Informe o Bairro'),
  cidadeId: yup.string().required('Informe a Cidade'),
  cep: yup.string().required('Informe o CEP'),
  codigoRecolhimento: yup.string().required('Informe o Código de Recolhimento'),
  nrInscricao: yup
    .string()
    .required('Informe o NIT')
    .test('cpf', 'Informe um NIT válido', (value) => value?.length === 11),
  vrSalarioContribuicao: yup
    .number()
    .min(0.00001, 'Informe o Salário')
    .required('Informe o Salário'),
  aliqContribuicao: yup
    .number()
    .min(0.00001, 'Informe a Alíquota de Contribuição')
    .required('Informe a Alíquota de Contribuição'),
  dtInicioContribuicao: yup
    .date()
    .required('Informe a Data de Início')
    .typeError('Informe uma data válida'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: Facultativo | null
}

export default function Form({ isOpen, onClose, data: _data }: FormProps) {
  const [data, setData] = useState<Facultativo>(
    _data || {
      id: '',
      nome: '',
      endereco: '',
      bairro: '',
      cidadeId: '',
      cep: '',
      telefone: '',
      codigoRecolhimento: '',
      nrInscricao: '',
      vrSalarioContribuicao: 0,
      aliqContribuicao: 0,
      dtInicioContribuicao: '',
      dtFimContribuicao: null,
    },
  )

  const { mutateAsync: mutateAsyncCriar, isLoading: isLoadingCriar } = useFacultativoCriar()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: isLoadingAtualizar } =
    useFacultativoAtualizar()
  const dialogNotification = useDialogNotification()

  const isSubmitting = isLoadingCriar || isLoadingAtualizar

  const isNovaData = _data ? false : true

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    const { id, ...rest } = data
    if (isNovaData) {
      dialogNotification.info({
        descriptions: [
          'Por favor, verifique se o NIT informado está correto.',
          formatToNIT(rest.nrInscricao),
        ],
        onConfirm: async () => {
          await mutateAsyncCriar(rest)
          onClose()
        },
        labelOnConfirm: 'Confirmar',
        labelOnCancel: 'Cancelar',
      })
    } else {
      await mutateAsyncAtualizar({
        id,
        data: rest,
      })
      onClose()
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Contribuinte"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isSubmitting}
      okLabel="Salvar"
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <NumeroInscricaoTextField
            label="NIT"
            typeMask={TipoInscricaoEnum.NIT_7}
            required
            name="nrInscricao"
            validationErrors={validationErrors}
            value={data.nrInscricao}
            onChange={(e, value) => {
              const nrInscricao = value
              setData((prev) => ({ ...prev, nrInscricao }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <TextField
            label="Nome"
            required
            validationErrors={validationErrors}
            name="nome"
            value={data.nome}
            onChange={(e) => {
              const nome = e.target.value
              setData((prev) => ({ ...prev, nome }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <CEPTextField
            required
            validationErrors={validationErrors}
            name="cep"
            label="CEP"
            value={data.cep}
            getAddress={
              isNovaData
                ? (address) => {
                    setData((oldState) => ({
                      ...oldState,
                      ...address,
                    }))
                  }
                : undefined
            }
            onChange={(e) => {
              const cep = e?.target?.value?.replace(/\D/g, '') || ''
              setData((prev) => ({ ...prev, cep }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <TextField
            label="Endereço"
            required
            name="endereco"
            validationErrors={validationErrors}
            value={data.endereco}
            onChange={(e) => {
              const endereco = e.target.value
              setData((prev) => ({ ...prev, endereco }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label="Bairro"
            required
            validationErrors={validationErrors}
            name="bairro"
            value={data.bairro}
            onChange={(e) => {
              const bairro = e.target.value
              setData((prev) => ({ ...prev, bairro }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <AutoCompleteCidade
            required
            validationErrors={validationErrors}
            name="cidadeId"
            value={data.cidadeId}
            onChange={(e, cidade) => {
              const cidadeId = cidade?.id || ''
              setData((prev) => ({ ...prev, cidadeId }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TelefoneTextField
            value={data.telefone}
            onChange={(e, value) => {
              const telefone = value
              setData((prev) => ({
                ...prev,
                telefone,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <MUIAutoComplete
            label="Código de Recolhimento"
            required
            validationErrors={validationErrors}
            name="codigoRecolhimento"
            options={codigoRecolhimentoIndividual}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.codigoRecolhimento}
            onChange={(e, obj) => {
              const codigoRecolhimento = obj?.value || ''
              setData((prev) => ({ ...prev, codigoRecolhimento }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <CurrencyTextField
            label="Alíquota de Contribuição"
            required
            validationErrors={validationErrors}
            name="aliqContribuicao"
            value={data.aliqContribuicao}
            onChange={(_, value) => {
              const aliqContribuicao = value
              setData((oldState) => ({
                ...oldState,
                aliqContribuicao,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <CurrencyTextField
            label="Salário"
            required
            validationErrors={validationErrors}
            name="vrSalarioContribuicao"
            value={data.vrSalarioContribuicao}
            onChange={(_, value) => {
              const vrSalarioContribuicao = value
              setData((oldState) => ({
                ...oldState,
                vrSalarioContribuicao,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <DatePickerNew
            label="Data de Início"
            required
            validationErrors={validationErrors}
            name="dtInicioContribuicao"
            value={data.dtInicioContribuicao || null}
            onChange={(date) => {
              const dtInicioContribuicao = date || ''
              setData((prev) => ({ ...prev, dtInicioContribuicao }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <DatePickerNew
            label="Data de Fim"
            value={data.dtFimContribuicao || null}
            onChange={(date) => {
              const dtFimContribuicao = date || null
              setData((prev) => ({ ...prev, dtFimContribuicao }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
