import { useMemo } from 'react'

import { makeStyles } from '@material-ui/core'
import { HeightDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const Table = (props) => {
  const { data, isLoading, query } = props

  const classes = useStyles()

  const { formatCurrency } = useUtils()

  const dataFormated = data.map((d) => {
    return {
      ...d,
      nome: 'Salário Mínimo',
      dtSalario: d?.dtSalario ? moment(d.dtSalario).format('DD/MM/yyyy') : '',
      vrMensal: d?.vrMensal ? formatCurrency(d.vrMensal) : '',
      vrDia: d?.vrDia ? formatCurrency(d.vrDia) : '',
      vrHora: d?.vrHora ? formatCurrency(d.vrHora) : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'dtSalario',
        label: 'Data do Salário',
      },
      {
        name: 'vrMensal',
        label: 'Valor Mensal',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrDia',
        label: 'Valor Dia',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrHora',
        label: 'Valor Hora',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'observacao',
        label: 'Observação',
      },
    ],
    [classes],
  )

  return (
    <HeightDataTable
      data={dataFormated}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['nome', 'dtSalario'],
      }}
    />
  )
}

export default Table
