import React, { createContext, useContext, useEffect, useState } from 'react'

import useAmbiente from './useAmbiente'

import { RP } from './queries/RP/RP'
import { Vinculo } from './queries/Vinculo/Vinculo'

import { ReciboIDFEnum, ReciboIDFLabels } from '~/@types/enums/ReciboIDFEnum'
import { TODOS as _TODOS } from '~/pages/ReciboPagamento/components/Menu'

const TODOS = _TODOS as FixLater

interface ReciboContextProps {
  reciboPagamento: RP | null
  setReciboPagamento: React.Dispatch<React.SetStateAction<RP | null>>
  isLoadingRecibo: boolean
  setLoadingRecibo: React.Dispatch<React.SetStateAction<boolean>>
  vinculo: Vinculo | null
  setVinculo: React.Dispatch<React.SetStateAction<Vinculo | null>>
  expanded: string[]
  setExpanded: React.Dispatch<React.SetStateAction<string[]>>
  filterValue: ReciboIDFEnum
  setFilterValue: React.Dispatch<React.SetStateAction<ReciboIDFEnum>>
  titlePage: string
  vinculoSelected: string | null
  setVinculoSelected: (vinculoId: string | null) => void
  isOpenDialogLembretes: boolean
  setOpenDialogLembretes: (isOpen: boolean) => void
}

const ReciboContext = createContext<ReciboContextProps>({
  reciboPagamento: null,
  setReciboPagamento: () => undefined,
  isLoadingRecibo: false,
  setLoadingRecibo: () => undefined,
  vinculo: null,
  setVinculo: () => undefined,
  expanded: [],
  setExpanded: () => undefined,
  filterValue: TODOS,
  setFilterValue: () => undefined,
  titlePage: 'Recibo',
  vinculoSelected: null,
  setVinculoSelected: () => undefined,
  isOpenDialogLembretes: false,
  setOpenDialogLembretes: () => undefined,
})

interface ProviderReciboProps {
  children: React.ReactNode
}

export function ProviderRecibo({ children }: ProviderReciboProps) {
  const [reciboPagamento, setReciboPagamento] = useState<RP | null>(null)
  const [isLoadingRecibo, setLoadingRecibo] = useState(false)
  const [vinculo, setVinculo] = useState<Vinculo | null>(null)
  const [expanded, setExpanded] = useState<string[]>([])
  const [filterValue, setFilterValue] = useState<ReciboIDFEnum>(TODOS)
  const [vinculoSelected, setVinculoSelected] = useState<string | null>(null)
  const [isOpenDialogLembretes, setOpenDialogLembretes] = useState(false)

  const { estabelecimento, anoMes } = useAmbiente()

  useEffect(() => {
    setReciboPagamento(null)
  }, [estabelecimento, anoMes])

  const titlePage = reciboPagamento?.idf ? ReciboIDFLabels[reciboPagamento.idf] : 'Recibo'

  return (
    <ReciboContext.Provider
      value={{
        reciboPagamento,
        setReciboPagamento,
        isLoadingRecibo,
        setLoadingRecibo,
        vinculo,
        setVinculo,
        expanded,
        setExpanded,
        filterValue,
        setFilterValue,
        titlePage,
        vinculoSelected,
        setVinculoSelected,
        isOpenDialogLembretes,
        setOpenDialogLembretes,
      }}
    >
      {children}
    </ReciboContext.Provider>
  )
}

export function useContextRecibo() {
  return useContext(ReciboContext)
}
