import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, LinearProgress } from '@material-ui/core'
import { ActionDialog, CurrencyTextField, TextField } from '~/components'
import { AutoCompleteFPAS } from '~/components/AutoComplete'

import TerceiroItens from './TerceiroItens'

import useAtualizarTerceiros from '~/hooks/queries/Terceiros/useAtualizarTerceiros'
import useCriarTerceiros from '~/hooks/queries/Terceiros/useCriarTerceiros'
import useObterPorIdTerceiros from '~/hooks/queries/Terceiros/useObterPorIdTerceiros'

import useValidationErrors from '~/hooks/useValidationErrors'

import { Terceiros } from '~/hooks/queries/Terceiros/Terceiros'

const schema = yup.object().shape({
  codigo: yup.string().required('Informe o Código'),
  nome: yup.string().required('Informe o Nome'),
  fpasId: yup.string().required('Informe o FPAS'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  dataId: string | null
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, dataId: _dataId } = props

  const [data, setData] = useState<Partial<Terceiros>>({})

  const { mutateAsync: obterPorIdTerceiros, isLoading: isLoadingObterPorId } =
    useObterPorIdTerceiros()

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })
  const { mutateAsync: criarTerceiros, isLoading: _loadingCriar } = useCriarTerceiros()
  const { mutateAsync: atualizarTerceiros, isLoading: _loadingAtualizar } = useAtualizarTerceiros()
  const isLoading = _loadingCriar || _loadingAtualizar

  useEffect(() => {
    async function startForm() {
      if (_dataId) {
        const dt = await obterPorIdTerceiros(_dataId)
        setData(dt)
      }
    }
    startForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_dataId])

  async function handleSubmit() {
    if (_dataId) {
      await atualizarTerceiros(data as Terceiros)
    } else {
      await criarTerceiros(data as Terceiros)
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Terceiros"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      isOkProcessing={isLoading}
    >
      <Box height={4}>{isLoadingObterPorId && <LinearProgress />}</Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            validationErrors={validationErrors}
            name="codigo"
            label="Código"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.codigo}
            onChange={(e) => {
              const codigo = e.target.value
              setData((prev) => ({ ...prev, codigo }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            validationErrors={validationErrors}
            name="nome"
            label="Nome"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.nome}
            onChange={(e) => {
              const nome = e.target.value
              setData((prev) => ({ ...prev, nome }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Alíquota Terceiros"
            value={data.aliqTerceiros}
            onChange={(e, value) => {
              const aliqTerceiros = value
              setData((oldState) => ({
                ...oldState,
                aliqTerceiros,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AutoCompleteFPAS
            required
            validationErrors={validationErrors}
            name="fpasId"
            value={data.fpasId}
            onChange={(e, fpas) => {
              const fpasId = fpas?.id
              setData((oldState) => ({
                ...oldState,
                fpasId,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TerceiroItens
            data={data.terceirosItens || []}
            onChange={(d) => {
              const terceirosItens = d
              setData((prev) => ({ ...prev, terceirosItens }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
