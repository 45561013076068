import { useEffect, useState } from 'react'

import { Box, LinearProgress } from '@material-ui/core'

import { ActionDialog, Stack } from '~/components'
import StepperForm from '~/components/StepperForm'

import StepJornadaNormal from './StepJornadaNormal'
import StepCompensacao from './StepCompensacao'
import StepModelo2 from './StepModelo2'

import { useObterHorarioTrabalhoFuncionario } from '~/hooks/queries/CompensacaoHorasTrabalhadas/useObterHorarioTrabalhoFuncionario'

import { RelCompensacaoHorasTrabalhadasRequestDTO } from '~/hooks/queries/CompensacaoHorasTrabalhadas/dtos/RelCompensacaoHorasTrabalhadasRequestDTO'
import { RelCompensacaoHorasTrabalhadasJornadaMainDTO } from '~/hooks/queries/CompensacaoHorasTrabalhadas/dtos/RelCompensacaoHorasTrabalhadasJornadaMainDTO'
import { StateStepsInfo } from '~/components/StepperForm/StepperProvider'
import { IndModeloCompensacaoHorasTrabalhadasEnum } from '~/@types/enums/IndModeloCompensacaoHorasTrabalhadasEnum'

interface StepFormProps {
  vinculoId: string
  isOpen: boolean
  onClose: () => void
  afterSubmit: (d: RelCompensacaoHorasTrabalhadasRequestDTO) => void
}

export default function StepForm({ vinculoId, isOpen, onClose, afterSubmit }: StepFormProps) {
  const [data, setData] = useState({} as RelCompensacaoHorasTrabalhadasJornadaMainDTO)
  const [stepCurrent, setStepCurrent] = useState(0)
  const [stepsCompleted, setStepsCompleted] = useState<StateStepsInfo>({})

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterHorarioTrabalhoFuncionario({
    params: { vinculoId },
  })

  useEffect(() => {
    if (_d) {
      setData(_d)
    }
  }, [_d])

  function handleSubmit(d: RelCompensacaoHorasTrabalhadasJornadaMainDTO) {
    afterSubmit({
      vinculoId,
      jornada: d,
    })
  }

  const steps = [
    {
      label: 'Jornada de trabalho normal',
      children: (
        <StepJornadaNormal
          data={data.jornadaNormal}
          onCancel={onClose}
          onChange={(d) => {
            const jornadaNormal = d
            setData((prev) => ({ ...prev, jornadaNormal }))
            setStepCurrent(1)
            setStepsCompleted((prev) => ({ ...prev, [0]: true }))
          }}
        />
      ),
      icon: 'supervisor_account',
    },
    {
      label: 'Compensação sábado (Feriado que ocorrer no sábado)',
      children: (
        <StepCompensacao
          data={data.jornadaSabadoFeriado}
          onCancel={onClose}
          onChange={(d) => {
            const jornadaSabadoFeriado = d
            setData((prev) => ({ ...prev, jornadaSabadoFeriado }))
            setStepCurrent(2)
            setStepsCompleted((prev) => ({ ...prev, [1]: true }))
          }}
        />
      ),
      icon: 'people',
    },
    {
      label: 'Compensação sábado (Feriado que ocorrer nos dias de trabalhado)',
      children: (
        <StepCompensacao
          data={data.jornadaSemanaFeriado}
          onCancel={onClose}
          onChange={(d) => {
            const jornadaSemanaFeriado = d
            const lastData = { ...data, jornadaSemanaFeriado }

            setData(lastData)
            setStepsCompleted((prev) => ({ ...prev, [2]: true }))

            handleSubmit(lastData)
          }}
        />
      ),
      icon: 'people_outline',
    },
  ]

  return (
    <ActionDialog
      title="Informe dados para o acordo de compensação de horas trabalhadas"
      isOpen={isOpen}
      onCancelClick={onClose}
      onClose={onClose}
      customActions={<></>}
      dialogProps={{
        maxWidth: 'md',
      }}
    >
      <StepperForm.Provider
        isLoading={isLoading}
        steps={
          data?.indModeloRelatorio == IndModeloCompensacaoHorasTrabalhadasEnum.Modelo_01
            ? steps
            : [
                {
                  label: 'Jornada de trabalho normal',
                  children: (
                    <StepModelo2
                      data={data.modelo2Relatorio}
                      onCancel={onClose}
                      onChange={(d) => {
                        const modelo2Relatorio = d
                        const lastData = { ...data, modelo2Relatorio }

                        setData(lastData)
                        setStepsCompleted((prev) => ({ ...prev, [0]: true }))

                        handleSubmit(lastData)
                      }}
                    />
                  ),
                  icon: 'people_outline',
                },
              ]
        }
        stepCurrent={stepCurrent}
        setStepCurrent={setStepCurrent}
        stepsCompleted={stepsCompleted}
        setStepsCompleted={setStepsCompleted}
      >
        <Stack>
          <StepperForm.Steps orientation="horizontal" />
          <Box height={4}>{isFetching && <LinearProgress />}</Box>
          <StepperForm.Content style={{ height: '100%' }} />
        </Stack>
      </StepperForm.Provider>
    </ActionDialog>
  )
}
