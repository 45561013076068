import { useState, useEffect } from 'react'

import api from '~/services/api-pessoal'

import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Creators as AppActions } from '~/store/ducks/app'

import { Box, makeStyles, Container, Paper } from '@material-ui/core'

import appLogo from '~/assets/images/appLogo.png'

import LoginForm from './LoginForm'
import EsqueciSenhaForm from './EsqueciSenhaForm'
import SelecaoEmpresaForm from './SelecaoEmpresaForm'

import useCredentials from '~/hooks/useCredentials'
import useDialogNotification from '~/hooks/useDialogNotification'
import Header from './Header'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  container: {
    width: '100%',
    maxWidth: '444px',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  },
  boxLoginContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
  },
}))

const Login = () => {
  const classes = useStyles()
  const [loginData, setLoginData] = useState({
    empresas: [],
  })
  const { selecionarLicenca, logout } = useCredentials()
  const history = useHistory()
  const dialogNotification = useDialogNotification()
  const dispatch = useDispatch()

  const ambiente = useSelector((state) => state.app.ambiente)

  useEffect(() => {
    logout()
    // eslint-disable-next-line
  }, [])

  const [visibility, setVisibility] = useState({
    login: true,
    esqueciSenha: false,
    novoAcesso: false,
  })

  const exibirLogin = () => {
    setVisibility({
      login: true,
      esqueciSenha: false,
      novoAcesso: false,
    })
  }
  const exibirEsqueciSenha = () => {
    setVisibility({
      login: false,
      esqueciSenha: true,
      novoAcesso: false,
    })
  }

  async function verificarEstabelecimentoEmCache(token) {
    const estabelecimento = ambiente?.empresa?.estabelecimento

    if (!estabelecimento?.id) return

    try {
      const response = await api.get('/EstabelecimentoConsulta/GetById', {
        params: {
          estabelecimentoId: estabelecimento.id,
        },
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      if (!response?.data?.data) throw new Error('Estabelecimento não encontrado')
      dispatch(
        AppActions.setAmbiente({
          ...ambiente,
          empresa: {
            empregador: null,
            estabelecimento: response.data.data,
          },
          hasAmbiente: true,
        }),
      )
    } catch (error) {
      console.warn(error)
      dispatch(
        AppActions.setAmbiente({
          ...ambiente,
          empresa: {
            empregador: null,
            estabelecimento: null,
          },
          hasAmbiente: false,
        }),
      )
    }
  }

  async function obterParametrosSistema(token) {
    try {
      const response = await api.get('/ParametrosSistema', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      dispatch(AppActions.setParametrosSistema(response?.data?.data || null))
    } catch (err) {
      dispatch(AppActions.setParametrosSistema(null))
      dialogNotification.extractErrors(err)
    }
  }

  const handleUserLogged = async (loginData) => {
    try {
      setLoginData(loginData)
      if (loginData?.empresas?.length === 1) {
        const response = await selecionarLicenca(loginData, loginData.empresas[0].uuid)
        await handleSelecionarLicensaSuccess(response)
        return
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  async function handleSelecionarLicensaSuccess(responseLogin) {
    try {
      await verificarEstabelecimentoEmCache(responseLogin.token)
      await obterParametrosSistema(responseLogin.token)

      history.push('/painel')
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  return (
    <Box>
      <Box className={classes.root}>
        <Container className={classes.container}>
          <img src={appLogo} alt="Logo Data C" />
          <Paper>
            <Box className={classes.boxLoginContent}>
              <Header />
              {visibility.login && (
                <Box style={{ marginTop: '24px' }}>
                  {loginData.empresas.length > 0 ? (
                    <SelecaoEmpresaForm
                      loginData={loginData}
                      onSelecionarLicencaSuccess={handleSelecionarLicensaSuccess}
                    />
                  ) : (
                    <LoginForm
                      onEsqueciSenhaClick={exibirEsqueciSenha}
                      onUserLogged={handleUserLogged}
                    />
                  )}
                </Box>
              )}
              {visibility.esqueciSenha && (
                <Box style={{ marginTop: '24px' }}>
                  <EsqueciSenhaForm onLoginClick={exibirLogin} />
                </Box>
              )}
            </Box>
          </Paper>
        </Container>
      </Box>
    </Box>
  )
}

export default Login
