import { memo } from 'react'

import { makeStyles, Paper, Grid, Divider } from '@material-ui/core'

import ValoresBase from './components/ValoresBases'
import Form, { FormDataProps } from './components/Form'
import ValoresPrincipais from './components/ValoresPrincipais'
import InformationsFooter from './components/InformationsFooter'

import LoadingForm from './components/LoadingForm'

import { useContextRecibo } from '~/hooks/useRecibo'

const MemoValoresBase = memo(ValoresBase)
const MemoValoresPrincipais = memo(ValoresPrincipais)
const MemoInformationsFooter = memo(InformationsFooter)

const paddingGrid = 0.5

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(paddingGrid),
    position: 'relative',
  },
  itemGrid: {
    padding: theme.spacing(paddingGrid),
  },
  gridForm: {
    padding: theme.spacing(paddingGrid),
    display: 'flex',
    alignItems: 'center',
  },
  gridValoresPrincipais: {
    padding: theme.spacing(paddingGrid),
    display: 'flex',
  },
}))

interface FooterProps {
  onAfterSubmitEvento: () => void
  formEvento: FormDataProps
}

export default function Footer(props: FooterProps) {
  const { onAfterSubmitEvento, formEvento } = props

  const { reciboPagamento, isLoadingRecibo } = useContextRecibo()
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      {isLoadingRecibo && <LoadingForm />}
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.itemGrid}>
          <MemoValoresBase
            vrBaseINSS={reciboPagamento?.vrBaseINSS || 0}
            vrBaseINSS13={reciboPagamento?.vrBaseINSS13 || 0}
            vrBaseIRRF={reciboPagamento?.vrBaseIRRF || 0}
            vrBaseIRRF13={reciboPagamento?.vrBaseIRRF13 || 0}
            vrBaseFGTS={reciboPagamento?.vrBaseFGTS || 0}
            vrBaseFGTS13={reciboPagamento?.vrBaseFGTS13 || 0}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6} md={7} className={classes.gridForm}>
          <Form
            data={formEvento}
            reciboPagamento={reciboPagamento}
            onAfterSubmitEvento={onAfterSubmitEvento}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={5} className={classes.gridValoresPrincipais}>
          <MemoValoresPrincipais
            vrSalarioContratual={reciboPagamento?.vrSalarioContratual || 0}
            vrTotalVencimento={reciboPagamento?.vrTotalVencimento || 0}
            vrTotalDesconto={reciboPagamento?.vrTotalDesconto || 0}
            vrTotalLiquido={reciboPagamento?.vrTotalLiquido || 0}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} className={classes.itemGrid}>
          <MemoInformationsFooter reciboPagamento={reciboPagamento} />
        </Grid>
      </Grid>
    </Paper>
  )
}
