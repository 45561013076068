import React, { useState, useEffect } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { useGrupoMutation } from '~/hooks/queries/useGrupo'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data } = props
  const [data, setData] = useState({})

  const { mutateAsync, isLoading } = useGrupoMutation()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = async () => {
    await mutateAsync(data)
    onClose()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Grupo"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            label="Código"
            value={data?.codigo || ''}
            disabled
          />
        </Grid>

        <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
          <TextField
            required
            label="Nome"
            name="nome"
            variant="outlined"
            fullWidth
            autoFocus
            size="small"
            inputProps={{
              maxLength: 100,
            }}
            value={data?.nome || ''}
            validationErrors={validationErrors}
            onChange={(e) => {
              const nome = e?.target?.value || ''
              setData({ ...data, nome })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
