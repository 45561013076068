import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, Button, ButtonBox, DragDropFile, TextField } from '~/components'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import { useUploadDocumento } from '~/hooks/queries/GerenciadorDocumentos/useUploadDocumento'

const schema = yup.object().shape({
  descricao: yup.string().required('Informe a Descrição do Documento'),
})

interface DataForm {
  descricao: string
  files: File[]
}

interface DialogUploadFileProps {
  isOpen: boolean
  onClose: () => void
  estabelecimentoId: string
}

export default function DialogUploadFile(props: DialogUploadFileProps) {
  const { isOpen, onClose, estabelecimentoId } = props

  const { anoMes } = useAmbiente()

  const [data, setData] = useState<DataForm>({
    descricao: '',
    files: [],
  })

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleUploadFile,
    data,
  })

  const { mutateAsync: mutateAsyncUploadDocumento, isLoading: isLoadingUploadDocumento } =
    useUploadDocumento()

  async function handleUploadFile() {
    const formData = new FormData()
    for (let index = 0; index < data.files.length; index++) {
      const currentFile = data.files[index]
      formData.append('file', currentFile, 'file.pdf')
    }

    await mutateAsyncUploadDocumento({
      estabelecimentoId,
      competencia: anoMes,
      descricao: data.descricao,
      file: formData,
    })
    onClose()
  }

  function handleDropped(files: File[]) {
    setData((prev) => ({ ...prev, files }))
  }

  return (
    <ActionDialog
      title="Insira Documentos"
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            variant="contained"
            onClick={handleValidate}
            disabled={data.files.length > 0 ? false : true}
            isLoading={isLoadingUploadDocumento}
          >
            Fazer Upload
          </Button>
        </ButtonBox>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Descrição do Documento"
            value={data.descricao}
            onChange={(e) => {
              const descricao = e.target.value
              setData((prev) => ({ ...prev, descricao }))
            }}
            inputProps={{
              maxLength: 250,
            }}
            required
            validationErrors={validationErrors}
            name="descricao"
          />
        </Grid>

        <Grid item xs={12}>
          <DragDropFile
            onDropped={handleDropped}
            acceptFiles={['.pdf']}
            maxFiles={5}
            uploadMessage={
              data.files.length > 0
                ? `${data.files.length} Documentos selecionados para upload`
                : undefined
            }
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
