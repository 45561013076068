import moment from 'moment'

import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import { PessoaFisicaDependente } from '~/hooks/queries/PessoaFisicaDependente/PessoaFisicaDependente'

import { GrauParentescoEnum, GrauParentescoValues } from '~/@types/enums/GrauParentescoEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

function formatGrauParentesco(grauParentesco: GrauParentescoEnum) {
  const objectFinded = GrauParentescoValues.find((obj) => obj.value === grauParentesco)
  return objectFinded?.name || ''
}

interface TableProps {
  data: PessoaFisicaDependente[]
  query: string
  onItemClick: (event: 'edit' | 'delete', id: string) => void
}

export default function Table(props: TableProps) {
  const { data: _data, query, onItemClick } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtNascimento: item?.dtNascimento ? moment(item.dtNascimento).format('DD/MM/yyyy') : '',
      grauParentesco: item?.grauParentesco ? formatGrauParentesco(item.grauParentesco) : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'grauParentesco',
      label: 'Grau de Parentesco',
    },
    {
      name: 'dtNascimento',
      label: 'Data de Nascimento',
    },
    {
      name: 'isDependenteSF',
      label: 'Dependente SF',
      options: {
        customBodyRender: (value) => (value ? <Ativo ativo={value} /> : ''),
      },
    },
    {
      name: 'isDependenteIR',
      label: 'Dependente IR',
      options: {
        customBodyRender: (value) => (value ? <Ativo ativo={value} /> : ''),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                size="small"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      columns={columns}
      sherlock={{
        query,
        columns: ['nome', 'grauParentesco', 'dtNascimento'],
      }}
    />
  )
}
