import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { HeightDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import moment from 'moment'
import { grauParentescoValues } from '~/values/grauParentescoValues'

function formatGrauParentesco(grauParentesco) {
  const objectFinded = grauParentescoValues.find((obj) => obj.value === grauParentesco)
  return objectFinded.name
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query, isUsed } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtNascimento: item?.dtNascimento ? moment(item.dtNascimento).format('DD/MM/yyyy') : '',
      grauParentesco: formatGrauParentesco(item?.grauParentesco),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'grauParentesco',
        label: 'Grau de Parentesco',
      },
      {
        name: 'dtNascimento',
        label: 'Data de Nascimento',
      },
      {
        name: 'isDependenteSF',
        label: 'Dependente SF',
        options: {
          customBodyRender: (value) => (value ? <Ativo ativo={value} /> : ''),
        },
      },
      {
        name: 'isDependenteIR',
        label: 'Dependente IR',
        options: {
          customBodyRender: (value) => (value ? <Ativo ativo={value} /> : ''),
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                {!isUsed && (
                  <IconButton
                    title="Deletar este registro"
                    size="small"
                    color="primary"
                    aria-label="Deletar"
                    onClick={() => {
                      onItemClick('delete', value)
                    }}
                  >
                    <DeleteIcon fontSize="small" color="primary" />
                  </IconButton>
                )}
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick, isUsed],
  )

  return (
    <HeightDataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['nome', 'grauParentesco', 'dtNascimento'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
