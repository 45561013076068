import { useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import {
  PageHeader,
  PrintButton,
  PDFViewer,
  Finder,
  DatePickerNew,
  ExcelButton,
} from '~/components'

import Table from './components/Table'

import { useEstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoConsulta'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useValidationErrors from '~/hooks/useValidationErrors'
import useRelSalarialCargoObterRelatorioExcel from '~/hooks/queries/RelSalarialCargo/useRelSalarialCargoObterRelatorioExcel'

import { RelSalarialCargoRequestDTO } from '~/hooks/queries/RelSalarialCargo/RelSalarialCargoRequestDTO'
import { getDateMinAnoMes } from '~/utils/utils'

const schema = yup.object().shape({
  dtEmissao: yup.date().required('Informe a Data de Emissão').typeError('Informe uma Data válida'),
})

const PAGE_TITLE = 'Relatório Salarial Por Cargo'

export default function RelSalarialCargo() {
  const { anoMes } = useAmbiente()

  const [query, setQuery] = useState('')
  const [rowsSelecteds, setRowsSelected] = useState<number[]>([])
  const [data, setData] = useState<{ dtEmissao: string | null }>({
    dtEmissao: getDateMinAnoMes(anoMes),
  })

  const { data: collection, isLoading, isFetching } = useEstabelecimentoConsulta()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<RelSalarialCargoRequestDTO | null>(null)
  const theme = useTheme()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const { mutateAsync: obterRelatorioExcel, isLoading: isLoadingPrintExcel } =
    useRelSalarialCargoObterRelatorioExcel()

  function handleSubmit() {
    const estabelecimentosIds = rowsSelecteds.map((index) => collection[index].id)
    openPDFViewer({
      dtEmissao: data.dtEmissao as string,
      estabelecimentosIds,
    })
  }

  function handleClosePdf() {
    setRowsSelected([])
    closePDFViewer()
  }

  async function handlePrintExcel() {
    const estabelecimentosIds = rowsSelecteds.map((index) => collection[index].id)
    await obterRelatorioExcel({
      dtEmissao: data.dtEmissao as string,
      estabelecimentosIds,
    })
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title={PAGE_TITLE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} p={2}>
        <Grid container spacing={1}>
          <Grid xs={12} sm={6} item>
            <DatePickerNew
              label="Data de Emissão"
              size="small"
              value={data.dtEmissao || null}
              required
              validationErrors={validationErrors}
              name="dtEmissao"
              onChange={(date) => {
                const dtEmissao = date || null
                setData((prev) => ({ ...prev, dtEmissao }))
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%">
          <Table
            isLoading={isLoading}
            isFetching={isFetching}
            query={query}
            data={collection}
            onRowSelected={setRowsSelected}
            rowsSelected={rowsSelecteds}
          />
        </Box>
      </Box>

      <Box
        component={Paper}
        p={1}
        display="flex"
        justifyContent="flex-end"
        gridGap={theme.spacing(1)}
      >
        <ExcelButton
          disabled={!(rowsSelecteds.length > 0)}
          isLoading={isLoadingPrintExcel}
          onClick={handlePrintExcel}
        />

        <PrintButton onClick={handleValidate} disabled={!(rowsSelecteds.length > 0)} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={handleClosePdf}
        reportKey="RelSalarialCargo"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/RelSalarialCargo/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
