import React from 'react'

import { LinearProgress, Typography } from '@material-ui/core'

import { ToolsDataTable } from '~/components'

import useSimpleStartRequest from '~/hooks/queries/useSimpleStartRequest'
import { formatCurrency } from '~/hooks/useUtils'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface RPItem {
  eventoEmpregador: {
    evento: {
      codigo: number
      nome: string
    }
  }
  complemento: string | null
  quantidade: number
  vrVencimento: number | null
  vrDesconto: number | null
}

interface RPData {
  rpItens: RPItem[]
}

interface TableProps {
  rpId: string
}

export default function TableRPItens(props: TableProps) {
  const { rpId } = props

  const { data: rp, isLoading } = useSimpleStartRequest<RPData | null>(
    {
      url: '/RP/' + rpId,
      method: 'GET',
    },
    null,
    [rpId],
  )

  if (isLoading) return <LinearProgress />

  if (!rp) return <Typography>Recibo não encontrado</Typography>

  const rpItens = rp.rpItens.map((d) => ({
    ...d,
    eventoCodigo: d.eventoEmpregador.evento.codigo,
    eventoNome: d.eventoEmpregador.evento.nome,
    quantidade: formatCurrency(d.quantidade),
    vrVencimento: d?.vrVencimento ? formatCurrency(d.vrVencimento) : '',
    vrDesconto: d?.vrDesconto ? formatCurrency(d.vrDesconto) : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    { name: 'eventoCodigo', label: 'Código' },
    { name: 'eventoNome', label: 'Nome' },
    { name: 'complemento', label: 'Complemento' },
    { name: 'quantidade', label: 'Referência' },
    { name: 'vrVencimento', label: 'Vencimento' },
    { name: 'vrDesconto', label: 'Desconto' },
  ]

  return <ToolsDataTable disableAutoHeight columns={columns} data={rpItens} />
}
