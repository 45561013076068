import { useEffect, useState } from 'react'

import { Box, Grid, Paper, Typography, useTheme } from '@material-ui/core'

import { PageHeader, Button } from '~/components'
import VirtualizedMenu from '~/components/VirtualizedMenu'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import Content from './Content'
import DialogSeverals from './DialogSeverals'

import useDialog from '~/hooks/useDialog'

import { useEmpregadorEsocialObterTodos } from '~/hooks/queries/EmpregadorEsocial/useEmpregadorEsocialObterTodos'
import { EmpregadorEsocial } from '~/hooks/queries/EmpregadorEsocial/EmpregadorEsocial'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'

export const eventosTabela = [
  ESocialEventoEnum.S_1000,
  ESocialEventoEnum.S_1005,
  ESocialEventoEnum.S_1010,
  ESocialEventoEnum.S_1020,
  ESocialEventoEnum.S_1070,
]
export const eventosPeriodicos = [
  ESocialEventoEnum.S_1200,
  ESocialEventoEnum.S_1210,
  ESocialEventoEnum.S_1260,
  ESocialEventoEnum.S_1270,
  ESocialEventoEnum.S_1280,
]
export const eventosNaoPeriodicos = [
  ESocialEventoEnum.S_2190,
  ESocialEventoEnum.S_2200,
  ESocialEventoEnum.S_2205,
  ESocialEventoEnum.S_2206,
  ESocialEventoEnum.S_2230,
  ESocialEventoEnum.S_2298,
  ESocialEventoEnum.S_2299,
  ESocialEventoEnum.S_2300,
  ESocialEventoEnum.S_2306,
  ESocialEventoEnum.S_2399,
]
export const eventosSST = [
  ESocialEventoEnum.S_2210,
  ESocialEventoEnum.S_2220,
  ESocialEventoEnum.S_2240,
]
export const eventosProcessoTrabalhista = [ESocialEventoEnum.S_2500, ESocialEventoEnum.S_2501]

export default function EnvioEventosAutomaticos() {
  const [menuSelected, setMenuSelected] = useState<string | null>(null)
  const [checked, setChecked] = useState<string[]>([])
  const [grupoId, setGrupoId] = useState<string | null>(null)

  const { data: _data, isLoading, isFetching } = useEmpregadorEsocialObterTodos({ grupoId })
  const data = _data ? _data.map((d) => ({ ...d, empregadorNome: d.empregador?.nome || '' })) : []

  useEffect(() => {
    setChecked([])
  }, [_data])

  const empregadorSelected = data.find((d) => d.id === menuSelected)

  const theme = useTheme()
  const {
    close: closeDialogSeverals,
    data: dataDialogSeverals,
    isOpen: isOpenDialogSeverals,
    open: openDialogSeverals,
  } = useDialog<EmpregadorEsocial[] | null>(null)

  function handleOpenConfigSeverals() {
    const dataConfigSeveral: EmpregadorEsocial[] = []
    checked.forEach((idChecked) => {
      const dtFinded = data.find((d) => d.id === idChecked)
      dtFinded && dataConfigSeveral.push(dtFinded)
    })
    openDialogSeverals(dataConfigSeveral)
  }

  const newData = data.map((d) => ({
    id: d.id,
    label: d.empregadorNome,
    children: [],
  }))

  return (
    <Box p={2} pb={6} height="100%">
      <PageHeader title="Envio de Eventos Automáticos" />

      <Grid container spacing={2} style={{ height: '100%' }}>
        <Grid item xl={3} lg={3} md={4} sm={5} xs={12}>
          <Box
            height="100%"
            minHeight={300}
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(1)}
          >
            <Box component={Paper} p={1}>
              <AutoCompleteGrupo
                value={grupoId}
                onChange={(e, grupo) => {
                  const grupoId = grupo?.id || null
                  setGrupoId(grupoId)
                }}
              />
            </Box>

            <VirtualizedMenu
              data={newData}
              onChangeParent={setMenuSelected}
              parentSelected={menuSelected}
              isLoading={isLoading}
              isFetching={isFetching}
              parentsChecked={checked}
              onCheckParent={setChecked}
            />
            <Box zIndex={9}>
              <Button
                onClick={handleOpenConfigSeverals}
                disabled={!(checked?.length > 0)}
                fullWidth
              >
                Configurar vários Empregadores
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={9} lg={9} md={8} sm={7} xs={12}>
          <Box height="calc(100dvh - 10rem)" minHeight={200}>
            {empregadorSelected ? (
              <Content data={empregadorSelected} />
            ) : (
              <Typography>Selecione um Empregador</Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {dataDialogSeverals && (
        <DialogSeverals
          data={dataDialogSeverals}
          isOpen={isOpenDialogSeverals}
          onClose={closeDialogSeverals}
        />
      )}
    </Box>
  )
}
