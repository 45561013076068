import { useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, TextField, Checkbox } from '~/components'

export interface DataConfirm {
  isImprimirComHorarios: boolean
  observacao: string
}

interface DialogConfirmProps {
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: (data: DataConfirm) => void
}

export default function DialogConfirm({ isOpen, onClose, onAfterSubmit }: DialogConfirmProps) {
  const [data, setData] = useState<DataConfirm>({
    isImprimirComHorarios: true,
    observacao: '',
  })

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Informe as seguintes informações"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Checkbox
            label="Imprimir com os Horários de Trabalho"
            value={data.isImprimirComHorarios}
            onChange={(e, checked) => {
              const isImprimirComHorarios = checked
              setData((prev) => ({ ...prev, isImprimirComHorarios }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Observação"
            value={data.observacao}
            multiline
            rows={4}
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData((prev) => ({ ...prev, observacao }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
