import { formatCurrency } from '~/hooks/useUtils'
import { formatToCPF } from 'brazilian-values'
import moment from 'moment'

import { CurrencyTextField, ToolsDataTable } from '~/components'

import TableDetailVinculo from '../TableDetailVinculo'

import useAmbiente from '~/hooks/useAmbiente'

import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  ApurarConvencaoColetivaDTO,
  ApurarConvencaoColetivaProcessarVinculoDTO,
} from '~/hooks/queries/ApurarConvencaoColetiva/ApurarConvencaoColetivaDTO'

interface TableProps {
  query: string
  data: ApurarConvencaoColetivaDTO[]
  isLoading: boolean
  isFetching: boolean
  rowsExpanded: number[]
  onRowExpanded: (rows: number[]) => void
  vinculosApuracao: ApurarConvencaoColetivaProcessarVinculoDTO[]
  changeVinculosApuracao: (vinculos: ApurarConvencaoColetivaProcessarVinculoDTO[]) => void
}

export default function Table({
  query,
  data: _d,
  isLoading,
  isFetching,
  rowsExpanded,
  onRowExpanded,
  vinculosApuracao,
  changeVinculosApuracao,
}: TableProps) {
  const data = _d.map((d) => ({
    ...d,
    vinculoNrInscricao: d?.vinculoNrInscricao ? formatToCPF(d.vinculoNrInscricao) : '',
    vinculoDtAdmissao: d?.vinculoDtAdmissao ? moment(d.vinculoDtAdmissao).format('DD/MM/yyyy') : '',
    vinculoDtRescisao: d?.vinculoDtRescisao ? moment(d.vinculoDtRescisao).format('DD/MM/yyyy') : '',
    vrVencimentoOriginal: d?.vrVencimentoOriginal ? formatCurrency(d.vrVencimentoOriginal) : '',
    vrVencimentoAtualizado: d?.vrVencimentoAtualizado
      ? formatCurrency(d.vrVencimentoAtualizado)
      : '',
    vrVencimentoApurado: d?.vrVencimentoApurado ? formatCurrency(d.vrVencimentoApurado) : '',
    vrAntecipado: d?.vrAntecipado ? formatCurrency(d.vrAntecipado) : '',
    vrINSSApurado: d?.vrINSSApurado ? formatCurrency(d.vrINSSApurado) : '',
  }))

  const { anoMes } = useAmbiente()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'vinculoCodigo',
      label: 'Código',
    },
    {
      name: 'vinculoNome',
      label: 'Nome',
    },
    {
      name: 'vinculoNrInscricao',
      label: 'Número de Inscrição',
    },
    {
      name: 'vinculoDtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'vinculoDtRescisao',
      label: 'Rescisão',
    },
    {
      name: 'vrVencimentoOriginal',
      label: 'Total',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrVencimentoAtualizado',
      label: 'Total Atualizado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrAntecipado',
      label: 'Antecipado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vinculoId',
      label: 'Apurado',
      options: {
        customBodyRender: (vinculoId) => {
          const rowCurrent = vinculosApuracao.find((d) => d.vinculoId === vinculoId)
          if (!rowCurrent) return
          return (
            <CurrencyTextField
              value={rowCurrent.vrProcessar}
              onBlur={(_, value) =>
                changeVinculosApuracao(
                  vinculosApuracao.map((d) =>
                    d.vinculoId === vinculoId ? { ...d, vrProcessar: value } : d,
                  ),
                )
              }
            />
          )
        },
      },
    },
    {
      name: 'vinculoId',
      label: 'INSS Apurado',
      options: {
        customBodyRender: (vinculoId) => {
          const rowCurrent = vinculosApuracao.find((d) => d.vinculoId === vinculoId)
          if (!rowCurrent) return
          return (
            <CurrencyTextField
              value={rowCurrent.vrINSSProcessar}
              onBlur={(_, value) =>
                changeVinculosApuracao(
                  vinculosApuracao.map((d) =>
                    d.vinculoId === vinculoId ? { ...d, vrINSSProcessar: value } : d,
                  ),
                )
              }
            />
          )
        },
      },
    },
  ]
  return (
    <ToolsDataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      optionsExpandable={{
        type: 'single',
        expandedChildren: (index: number) => (
          <TableDetailVinculo vinculoId={_d[index].vinculoId} anoMesApuracao={anoMes} />
        ),
        onRowExpanded: onRowExpanded,
        rowsExpanded: rowsExpanded,
      }}
      disableAutoHeight
      sherlock={{
        columns: ['vinculoCodigo', 'vinculoNome', 'vinculoNrInscricao'],
        query,
      }}
    />
  )
}
