export enum ClassTribEnum {
  SimplesNacional_01 = 1,
  SimplesNacionalNaoSubstituica_02 = 2,
  SimplesNacionalConcomitante_03 = 3,
  MicroEmpreendedor_04 = 4,
  Agroindustria_06 = 6,
  ProdutorRural_07 = 7,
  OrgaoGestorMaodeObra_09 = 9,
  EntidadeSindical_10 = 10,
  AssociacaoDesportiva_11 = 11,
  Bancos_13 = 13,
  SindicatoGeral_14 = 14,
  PessoaFisica_21 = 21,
  SeguradoEspecial_22 = 22,
  MissaoDiplomatica_60 = 60,
  EmpresaDecreto5436_70 = 70,
  EntidadeBeneficente_80 = 80,
  AdministracaoDireta_85 = 85,
  PessoaJuridicaGeral_99 = 99,
}

export const ClassTribValues = [
  {
    value: ClassTribEnum.SimplesNacional_01,
    name: '01 - Simples Nacional com tributação previdenciária substituída',
  },
  {
    value: ClassTribEnum.SimplesNacionalNaoSubstituica_02,
    name: '02 - Simples Nacional com tributação previdenciária não substituída',
  },
  {
    value: ClassTribEnum.SimplesNacionalConcomitante_03,
    name: '03 - Simples Nacional com tributação previdenciária substituída e não substituída',
  },
  {
    value: ClassTribEnum.MicroEmpreendedor_04,
    name: '04 - MEI - Micro Empreendedor Individual',
  },
  {
    value: ClassTribEnum.Agroindustria_06,
    name: '06 - Agroindústria',
  },
  {
    value: ClassTribEnum.ProdutorRural_07,
    name: '07 - Produtor Rural Pessoa Jurídica',
  },
  {
    value: ClassTribEnum.OrgaoGestorMaodeObra_09,
    name: '09 - Órgão Gestor de Mão de Obra',
  },
  {
    value: ClassTribEnum.EntidadeSindical_10,
    name: '10 - Entidade Sindical a que se refere a Lei 12.023/2009',
  },
  {
    value: ClassTribEnum.AssociacaoDesportiva_11,
    name: '11 - Associação Desportiva que mantém Clube de Futebol Profissional',
  },
  {
    value: ClassTribEnum.Bancos_13,
    name: '13 - Banco, caixa econômica, sociedade de crédito, financiamento e investimento e demais empresas relacionadas no parágrafo 1º do art. 22 da Lei 8.212./ 91',
  },
  {
    value: ClassTribEnum.SindicatoGeral_14,
    name: '14 - Sindicatos em geral, exceto aquele classificado no código[10]',
  },
  {
    value: ClassTribEnum.PessoaFisica_21,
    name: '21 - Pessoa Física, exceto Segurado Especial',
  },
  {
    value: ClassTribEnum.SeguradoEspecial_22,
    name: '22 - Segurado Especial, inclusive quando for empregador doméstico',
  },
  {
    value: ClassTribEnum.MissaoDiplomatica_60,
    name: '60 - Missão Diplomática ou Repartição Consular de carreira estrangeira',
  },
  {
    value: ClassTribEnum.EmpresaDecreto5436_70,
    name: '70 - Empresa de que trata o Decreto 5.436 / 2005',
  },
  {
    value: ClassTribEnum.EntidadeBeneficente_80,
    name: '80 - Entidade Beneficente de Assistência Social isenta de contribuições sociais',
  },
  {
    value: ClassTribEnum.AdministracaoDireta_85,
    name: '85 - Administração Direta da União, Estados, Distrito Federal e Municípíos; Autarquias e Fundações Públicas',
  },
  {
    value: ClassTribEnum.PessoaJuridicaGeral_99,
    name: '99 - Pessoas Jurídicas em Geral',
  },
]
