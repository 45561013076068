import { useEffect, useState, memo } from 'react'

import { Grid, IconButton, Collapse, makeStyles, Paper, Box, useTheme } from '@material-ui/core'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'

import { DatePicker, PageHeader, Button } from '~/components'

import Table from './components/Table'
import UploadPontoEletronico from './components/UploadPontoEletronico'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'

import moment from 'moment'
import * as yup from 'yup'
import useNotification from '~/hooks/useNotification'

const schema = yup.object().shape({
  dtInicial: yup
    .string()
    .required('Informe a Data Inicial')
    .when((dtInicial, schema) => {
      if (dtInicial === 'Invalid date') {
        return schema.min(15, 'Informe uma Data Valída')
      }
    })
    .nullable(),
  dtFinal: yup
    .string()
    .required('Informe a Data Final')
    .when(['dtInicial'], (dtInicial, schema, { value }) => {
      if (value === 'Invalid date') {
        return schema.min(15, 'Informe uma Data Valída')
      }
      if (moment(dtInicial).isAfter(value)) {
        return schema.min(dtInicial, 'Data Final deve ser maior que Data Inicial')
      }
    })
    .nullable(),
})

const useStyles = makeStyles((theme) => ({
  paddingCollapse: {
    paddingBottom: theme.spacing(1),
  },
}))

const MemoTable = memo(Table)

const PontoEletronico = () => {
  const [rowsSelected, setRowsSelected] = useState([])

  const [data, setData] = useState({
    dtInicial: '',
    dtFinal: '',
    isLoading: false,
    itens: [],
  })
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)

  const { estabelecimento, anoMes } = useAmbiente()
  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const classes = useStyles()
  const theme = useTheme()

  useEffect(() => {
    setRowsSelected([])
  }, [data?.itens])

  useEffect(() => {
    const dtInicial = moment(anoMes).format('yyyy-MM-DD')
    const dtFinal = moment(anoMes).endOf('month').format('yyyy-MM-DD')
    setData((oldState) => ({
      ...oldState,
      dtInicial,
      dtFinal,
      itens: [],
    }))
    getCollection(dtInicial, dtFinal)
    //eslint-disable-next-line
  }, [anoMes, estabelecimento])

  useEffect(() => {
    setData((oldState) => ({
      ...oldState,
      itens: [],
    }))
  }, [data.dtInicial, data.dtFinal])

  const getCollection = async (dtInicial, dtFinal) => {
    setData((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      let url = `PontoEletronico/GetByEstabelecimento?estabelecimentoId=${estabelecimento?.id}&dtInicio=${data?.dtInicial}&dtFim=${data?.dtFinal}`
      if (dtInicial && dtFinal)
        url = `PontoEletronico/GetByEstabelecimento?estabelecimentoId=${estabelecimento?.id}&dtInicio=${dtInicial}&dtFim=${dtFinal}`
      const response = await api.get(url)

      if (response.data.data) {
        setData((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setData((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
  }

  const handleSearch = () => {
    getCollection()
  }

  const handleProcess = () => {
    const process = async () => {
      setData((oldState) => ({
        ...oldState,
        isLoading: true,
      }))
      try {
        const ids = rowsSelected.map((index) => data.itens[index].id)
        await api.post(`PontoEletronico/ProcessarPontoEletronico`, ids, {
          params: {
            estabelecimentoId: estabelecimento?.id,
            anoMes,
          },
        })
        setData((oldState) => ({
          ...oldState,
          isLoading: false,
        }))
        notification.success('Processamento realizado com sucesso.')
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setData((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
      getCollection()
    }
    process()
  }

  function handleSubmit(event) {
    const functions = {
      process: handleProcess,
      search: handleSearch,
    }
    functions[event]()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleAfterSubmit() {
    getCollection()
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title="Ponto Eletrônico">
        <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
          {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
        </IconButton>
      </PageHeader>

      <Box component={Paper} padding={1}>
        <Collapse in={formCollapseIsOpen} className={classes.paddingCollapse}>
          <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
            <Box display="flex" alignItems="center" gridGap={theme.spacing(4)}>
              <Grid container spacing={1}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Data Inicial"
                    size="small"
                    name="dtInicial"
                    validationErrors={validationErrors}
                    value={data?.dtInicial || null}
                    onChange={(date) => {
                      const dtInicial = date ? date.format('yyyy-MM-DD') : null
                      setData((oldState) => ({
                        ...oldState,
                        dtInicial,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Data Final"
                    size="small"
                    name="dtFinal"
                    validationErrors={validationErrors}
                    value={data?.dtFinal || null}
                    onChange={(date) => {
                      const dtFinal = date ? date.format('yyyy-MM-DD') : null
                      setData((oldState) => ({
                        ...oldState,
                        dtFinal,
                      }))
                    }}
                  />
                </Grid>
              </Grid>

              <Box height="100%" display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleValidate('search')}
                  variant="contained"
                  isLoading={data.isLoading}
                >
                  Pesquisar
                </Button>
              </Box>
            </Box>

            <UploadPontoEletronico
              estabelecimentoId={estabelecimento?.id}
              anoMes={anoMes}
              onDownloadSuccess={() => getCollection()}
            />
          </Box>
        </Collapse>
      </Box>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%" minWidth={300}>
          <MemoTable
            data={data?.itens}
            isLoading={data?.isLoading}
            onAfterSubmit={handleAfterSubmit}
            rowsSelected={rowsSelected}
            setRowsSelected={setRowsSelected}
          />
        </Box>
      </Box>

      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <Button
          onClick={() => handleValidate('process')}
          variant="contained"
          disabled={rowsSelected.length === 0 ? true : false}
          isLoading={data.isLoading}
        >
          Processar para o Recibo
        </Button>
      </Box>
    </Box>
  )
}

export default PontoEletronico
