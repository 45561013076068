import React, { useState } from 'react'

import PropType from 'prop-types'

import MUIAutoComplete from '../MUIAutoComplete'
import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'

import api from '~/services/api-pessoal'
import { TipoInscricaoEnum, TipoInscricaoLabels } from '~/@types/enums/TipoInscricaoEnum'
import { limparString, orderBy } from '~/utils/utils'
import { formatToCPFOrCNPJ } from 'brazilian-values'

export default function AutoCompleteSindicato(props) {
  const { label, categoriaSindicato, disabled, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/Sindicato')

      let currentResponse = response?.data?.data || []

      if (categoriaSindicato) {
        currentResponse = currentResponse.filter(
          (sindicato) => sindicato.indCategoriaEntidade === categoriaSindicato,
        )
      }

      setOptions(currentResponse)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) =>
    option
      ? disabled
        ? `${formatToCPFOrCNPJ(option.nrInscricao)} - ${option.nome}`
        : option.nome
      : '-'

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label={label}
      disabled={disabled}
      renderOptionComponent={
        disabled
          ? undefined
          : (option) => (
              <AutoCompleteOptionDescription
                title={`${option.nome}`}
                descriptions={[
                  {
                    description: `${
                      TipoInscricaoLabels[TipoInscricaoEnum.CNPJ_1]
                    } : ${formatToCPFOrCNPJ(option.nrInscricao)}`,
                  },
                ]}
              />
            )
      }
      filterOptions={(options, { inputValue }) => {
        const isSearchNrInscricao = inputValue.includes('.')

        let field = 'nome'

        if (isSearchNrInscricao) {
          field = 'nrInscricao'
        }

        const value = limparString(inputValue).toLowerCase()

        const opts = options.filter((element) =>
          limparString(`${element[field]}`).toLowerCase().includes(value),
        )

        return orderBy(opts, field)
      }}
      {...rest}
    />
  )
}

AutoCompleteSindicato.defaultProps = {
  label: 'Sindicato',
}

AutoCompleteSindicato.propTypes = {
  getOptionDisabled: PropType.func,
  renderOption: PropType.func,
  onChange: PropType.func,
  options: PropType.array,
  value: PropType.any,
  optionId: PropType.string,
  required: PropType.bool,
  disabled: PropType.bool,
  validationErrors: PropType.any,
  name: PropType.string,
  title: PropType.string,
  label: PropType.string,
}
