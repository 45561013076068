import { useState } from 'react'

import * as yup from 'yup'

import { ActionDialog, TextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { ServicesFacultativo } from '..'

const schema = yup.object().shape({
  diaVencimento: yup
    .number()
    .required('Informe o Dia do Vencimento')
    .min(1, 'Informe o Dia do Vencimento'),
})

interface FormCalcularGPSProps {
  isOpen: boolean
  onClose: () => void
  event: ServicesFacultativo
  onAfterSubmit: (event: ServicesFacultativo, diaVencimento: number) => void
}

export default function FormImprimir({
  isOpen,
  onClose,
  event,
  onAfterSubmit,
}: FormCalcularGPSProps) {
  const [data, setData] = useState({
    diaVencimento: 20,
  })

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    onAfterSubmit(event, data.diaVencimento)
    onClose()
  }

  return (
    <ActionDialog
      title="Imprimir Contribuição"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      okLabel="Imprimir"
    >
      <TextField
        label="Dia do Vencimento"
        required
        validationErrors={validationErrors}
        name="diaVencimento"
        value={data.diaVencimento || ''}
        onChange={(e) => {
          const diaVencimento = parseInt(e?.target?.value || '0')
          setData((prev) => ({ ...prev, diaVencimento }))
        }}
      />
    </ActionDialog>
  )
}
