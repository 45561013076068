import api from '~/services/api-pessoal'
import { notifyPost, notifyPut, notifyRemove, notifySuccess } from '~/utils/notification'

import { useQuery, useMutation, useQueryClient } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { Facultativo } from './dtos/Facultativo'
import { FacultativoManipularDTO } from './dtos/FacultativoManipularDTO'
import { FacultativoItem } from './dtos/FacultativoItem'

const queryFacultativos = '/Facultativo/ObterTodosFacultativos'
const queryContribuicoes = '/Facultativo/ObterContribuicoesPorFacultativo'

export function useFacultativoCriar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosFacultativosInvalidate()

  async function handleRequest(data: FacultativoManipularDTO) {
    await api.post('/Facultativo/Criar', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}

interface AtualizarProps {
  id: string
  data: FacultativoManipularDTO
}

export function useFacultativoAtualizar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosFacultativosInvalidate()

  async function handleRequest({ id, data }: AtualizarProps) {
    await api.put('/Facultativo/Atualizar/' + id, data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}

interface AtualizarSalariosProps {
  params: {
    vrNovoSalario: number
  }
  data: string[]
}

export function useFacultativoAtualizarSalarios() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosFacultativosInvalidate()

  async function handleRequest({ params, data }: AtualizarSalariosProps) {
    await api.patch('/Facultativo/AtualizarSalarios', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Os Salários dos Contribuintes foram atualizados com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}

export function useFacultativoRemover() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosFacultativosInvalidate()

  async function handleRequest(id: string) {
    await api.delete('/Facultativo/Remover/' + id)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}

export function useObterTodosFacultativos() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: Facultativo[] }>(queryFacultativos)
    return response.data.data
  }

  return useQuery([queryFacultativos], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

interface CalcularContribuicoesProps {
  data: string[]
  params: {
    anoMesInicio: string
    anoMesFim: string
  }
}

export function useFacultativoCalcularContribuicoes() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterContribuicoesPorFacultativoInvalidate()

  async function handleRequest({ data, params }: CalcularContribuicoesProps) {
    await api.post('/Facultativo/CalcularContribuicoes', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('As contribuições foram calculadas com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}

interface ObterContribuicoesPorFacultativoProps {
  params: {
    facultativoId?: string
    anoMes: string
  }
}

export function useObterContribuicoesPorFacultativo({
  params,
}: ObterContribuicoesPorFacultativoProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params?.facultativoId) return
    const response = await api.get<{ data: FacultativoItem[] }>(queryContribuicoes, { params })
    return response.data.data
  }

  return useQuery([queryContribuicoes, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

interface RemoverVariasContribuicoesProps {
  data: string[]
}

export function useFacultativoRemoverVariasContribuicoes() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterContribuicoesPorFacultativoInvalidate()

  async function handleRequest({ data }: RemoverVariasContribuicoesProps) {
    await api.post('/Facultativo/RemoverVariasContribuicoes', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('As contribuições foram removidas com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}

function useObterTodosFacultativosInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(queryFacultativos)
  }
  return { handleInvalidate }
}

export function useObterContribuicoesPorFacultativoInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(queryContribuicoes)
  }
  return { handleInvalidate }
}
