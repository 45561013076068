import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { RPAConsultaDTO } from './RPAConsultaDTO'

const query = '/RPAutonomo/ObterRecibosConsulta'

interface RequestProps {
  anoMes: string
  estabelecimentoId?: string | null
  grupoId?: string | null
}

export function useObterRecibosConsulta(props: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: RPAConsultaDTO[] }>(query, {
      params: props,
    })
    return response.data.data
  }

  return useQuery([query, props], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
