const nao_se_aplica = 9
const mes = 0
const dia = 1
const hora = 2
const percentual = 3
const quantidade = 4

export const eventoIndReferenciaValues = [
  { value: nao_se_aplica, name: '9 - Não informar' },
  { value: mes, name: '0 - Mes' },
  { value: dia, name: '1 - Dia' },
  { value: hora, name: '2 - Hora' },
  { value: percentual, name: '3 - Percentual' },
  { value: quantidade, name: '4 - Quantidade' },
]

export const eventoIndReferenciaConsts = {
  nao_se_aplica,
  mes,
  dia,
  hora,
  percentual,
  quantidade,
}
