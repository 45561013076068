import { useState, useEffect, useCallback } from 'react'
import { AutoSizer } from 'react-virtualized'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Box, useTheme } from '@material-ui/core'

import { CopyLabel, ToolsDataTable } from '~/components'

import ActionsButtons from '../ActionsButtons'
import { DataContextProps } from '../..'

import useAmbiente from '~/hooks/useAmbiente'
import { useStepperContext } from '~/components/StepperForm'

import { useObterEventosTransmissaoEnvio } from '~/hooks/queries/ESocial/useObterEventosTransmissaoEnvio'
import { useTransmitirEventosESocial } from '~/hooks/queries/ESocial/useTransmitirEventosESocial'
import { useAlterarStatusEvento } from '~/hooks/queries/ESocial/useAlterarStatusEvento'

import { ESocialGrupoEventoEnum } from '~/@types/enums/ESocialGrupoEventoEnum'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ESocialStatusEnum } from '~/@types/enums/ESocialStatusEnum'

interface TransmissaoEmpregadorProps {
  grupo: ESocialGrupoEventoEnum
}

export default function TransmissaoEmpregador(props: TransmissaoEmpregadorProps) {
  const { grupo } = props

  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const { dataControlled: dataContext, setStepCurrent } = useStepperContext<DataContextProps>()
  const { query, indApuracao, grupoId } = dataContext

  const { anoMes, empregador } = useAmbiente()
  const theme: FixLater = useTheme()

  const { data, isLoading, isFetching } = useObterEventosTransmissaoEnvio({
    grupoEvento: grupo,
    anoMes,
    grupoId,
    indApuracao,
    filterEventos: [],
  })
  const { mutateAsync: mutateAsyncAlterarStatusEvento, isLoading: isLoadingAlterarStatusEvento } =
    useAlterarStatusEvento()

  const { mutateAsync, isLoading: isLoadingSubmit } = useTransmitirEventosESocial()

  const handleReset = useCallback(() => {
    setRowsSelected([])
  }, [])

  useEffect(() => {
    handleReset()
  }, [anoMes, indApuracao, handleReset])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'empregadorId',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'empregadorNrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'empregadorNome',
      label: 'Descrição',
    },
  ]

  async function handleSubmit() {
    const dataSelected = rowsSelected.map((indexSelected) => data[indexSelected])
    const auditoriasIds: string[] = []
    dataSelected.forEach((empregador) =>
      empregador.grupoEventos.forEach((grupo) =>
        grupo.eventos.forEach((auditoria) => auditoriasIds.push(auditoria.auditoriaId)),
      ),
    )
    await mutateAsync(auditoriasIds)
    handleReset()
    setStepCurrent(1)
  }

  async function handleInativarEventos() {
    const dataSelected = rowsSelected.map((indexSelected) => data[indexSelected])
    const auditoriasIds: string[] = []
    dataSelected.forEach((empregador) =>
      empregador.grupoEventos.forEach((grupo) =>
        grupo.eventos.forEach((auditoria) => auditoriasIds.push(auditoria.auditoriaId)),
      ),
    )
    await mutateAsyncAlterarStatusEvento({
      alterarParaStatus: ESocialStatusEnum.RegistroInativo_99,
      eventosIds: auditoriasIds,
    })
    handleReset()
    setStepCurrent(1)
  }

  return (
    <Box height="100%">
      <Box height="100%">
        <AutoSizer>
          {({ height, width }) => (
            <Box height={height} width={width}>
              <ToolsDataTable
                disableAutoHeight
                data={data}
                isLoading={isLoading}
                isFetching={isFetching}
                columns={columns}
                optionsSelectable={{
                  type: 'multiple',
                  onRowSelected: setRowsSelected,
                  rowsSelected: rowsSelected,
                }}
                options={{
                  setRowProps: (row: any) => {
                    const empregadorId = row[0]
                    const isHightLightRow = empregador?.id === empregadorId ? true : false
                    return {
                      style: {
                        backgroundColor: isHightLightRow
                          ? theme.palette.secondary.lighter
                          : 'inherit',
                      },
                    }
                  },
                }}
                sherlock={{
                  query,
                  columns: ['empregadorNome'],
                }}
              />
            </Box>
          )}
        </AutoSizer>
      </Box>

      <ActionsButtons
        disabled={rowsSelected.length > 0 ? false : true}
        isLoading={isLoadingSubmit}
        isLoadingInativarEventos={isLoadingAlterarStatusEvento}
        onCancel={handleReset}
        onSubmit={handleSubmit}
        onInativarEventos={handleInativarEventos}
      />
    </Box>
  )
}
