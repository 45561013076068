import { Box } from '@material-ui/core'

import ChartHeader from '../components/ChartHeader'
import ContainerChart from '../components/ContainerChart'

import { useHistory } from 'react-router-dom'
import useAmbiente from '~/hooks/useAmbiente'
import usePainelCompetenciaChart from '~/hooks/queries/Painel/usePainelCompetenciaChart'
import PieChart from '../components/PieChart'
import {
  IndCompetenciaChartColor,
  IndCompetenciaChartEnum,
  IndCompetenciaChartLabel,
} from '~/@types/enums/IndCompetenciaChartEnum'
import ChartFooter from '../components/ChartFooter'
import { MAX_HEIGHT_CHART, MAX_WIDTH_CHART } from '..'

export default function CompetenciaChart() {
  const { anoMes } = useAmbiente()
  const history = useHistory()

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching,
  } = usePainelCompetenciaChart({
    anoMes,
  })
  const data = _data || []
  const isLoading = _isLoading || isFetching

  function handleRedirect(status: IndCompetenciaChartEnum) {
    if (status === IndCompetenciaChartEnum.Fechadas_02) {
      history.push('/fechamento-esocial')
    } else {
      history.push('/processar-competencia')
    }
  }

  return (
    <ContainerChart onHover={() => undefined}>
      <ChartHeader
        title="Competência"
        icon="tune"
        isActiveAlternate={false}
        onClickRedirect={() => handleRedirect(IndCompetenciaChartEnum.NaoAbertas_00)}
        onClickAlternate={() => undefined}
        isHover={false}
        isLoading={isLoading}
      />
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <Box maxWidth={MAX_WIDTH_CHART} maxHeight={MAX_HEIGHT_CHART}>
          <PieChart
            cutout="75%"
            displayLegend={false}
            data={data}
            labels={IndCompetenciaChartLabel}
            colors={IndCompetenciaChartColor}
            onClick={(index) => handleRedirect(data[index].status)}
            mainTitle={`${data.reduce((sum, item) => sum + item.total, 0)}`}
            mainSubtitle="Empresas"
          />
        </Box>
      </Box>
      <ChartFooter
        labels={IndCompetenciaChartLabel}
        colors={IndCompetenciaChartColor}
        data={data}
        divider={false}
        onClick={handleRedirect}
      />
    </ContainerChart>
  )
}
