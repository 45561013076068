import { convertToArrayDescription } from '~/utils/utils'

export enum FiltroCompetenciaAberturaEnum {
  TodasEmpresas,
  EmpresasNaoAbertas,
  EmpresasAbertas,
}

export const FiltroCompetenciaAberturaLabels = {
  [FiltroCompetenciaAberturaEnum.TodasEmpresas]: 'Todas Empresas',
  [FiltroCompetenciaAberturaEnum.EmpresasNaoAbertas]: 'Empresas Não Abertas',
  [FiltroCompetenciaAberturaEnum.EmpresasAbertas]: 'Empresas Abertas',
}

export const FiltroCompetenciaAberturaValues = convertToArrayDescription(
  FiltroCompetenciaAberturaEnum,
  FiltroCompetenciaAberturaLabels,
)
