import React from 'react'

import { ToolsDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

export default function TableExpanded(props) {
  const { data: _data, isLoading } = props

  const { formatAnoMes, formatCurrency } = useUtils()

  const data = _data.map((item) => {
    return {
      ...item,
      anoMes: item.anoMes ? formatAnoMes(item.anoMes) : null,
      vrBaseCalculo: item?.vrBaseCalculo ? formatCurrency(item.vrBaseCalculo) : null,
      vrPIS: item?.vrPIS ? formatCurrency(item.vrPIS) : null,
      dtImpressao: item?.dtImpressao ? moment(item.dtImpressao).format('DD/MM/YYYY') : null,
    }
  })

  const columns = [
    {
      name: 'anoMes',
      label: 'Ano/Mês',
    },
    {
      name: 'dtImpressao',
      label: 'Data da Impressão',
    },
    {
      name: 'vrBaseCalculo',
      label: 'Base de Cálculo',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrPIS',
      label: 'Valor da Contribuição',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
  ]

  return <ToolsDataTable data={data} isLoading={isLoading} columns={columns} />
}
