import { Box, Paper, useTheme, makeStyles } from '@material-ui/core'
import { Table, TableBody, TableRow } from '@material-ui/core'

import { VinculoValeTransporteDTO } from '~/hooks/queries/VinculoValeTransporte/VinculoValeTransporteDTO'
import { CellContent } from '../Header'
import { ColumnTableType } from '..'

const useStyles = makeStyles(() => ({
  rowRoot: {
    '&:nth-child(even)': {
      backgroundColor: '#F5F5F5',
    },
    '&:hover': { backgroundColor: '#ffffde' },
  },
}))

interface TableDayOfWeekProps {
  title: string
  data: VinculoValeTransporteDTO[]
  columns: ColumnTableType<VinculoValeTransporteDTO>[]
}

export default function TableDayOfWeek({ title, data, columns }: TableDayOfWeekProps) {
  const theme: FixLater = useTheme()
  const classes = useStyles()

  if (data.length == 0) {
    return <></>
  }

  return (
    <Box component={Paper} padding={1}>
      <Box fontSize={16} fontWeight="bold" color="#666">
        {title}
      </Box>
      <Table
        style={{
          border: theme.shape.border,
        }}
      >
        <TableBody>
          {data.map((row, index) => (
            <TableRow className={classes.rowRoot} key={`row-${row.id}-${index}`}>
              {columns.map((col) => {
                const { name, options } = col
                const { customBodyRender, width, minWidth } = options || {}
                const value = row[name]
                return (
                  <CellContent
                    key={`col-${row.id}-${index}-${name}`}
                    width={width}
                    minWidth={minWidth}
                  >
                    {customBodyRender ? customBodyRender(value) : value}
                  </CellContent>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
