import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryUseObterTodosTabelaIR } from './useObterTodosTabelaIR'

import { TabelaIR } from './TabelaIR'

export function useAtualizarTabelaIR() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(tabelaIR: TabelaIR) {
    await api.put('/TabelaIR/' + tabelaIR.id, tabelaIR)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.put()
      await queryClient.invalidateQueries(queryUseObterTodosTabelaIR)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
