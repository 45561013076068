import { IconButton } from '@material-ui/core'
import { Description as DescriptionIcon } from '@material-ui/icons'

import { ActionDialog, ToolsDataTable, ButtonBox } from '~/components'

import AuditoriaXMLForm from './components/AuditoriaXMLForm'

import useDialog from '~/hooks/useDialog'

import { Auditoria } from '~/hooks/queries/Auditoria/Auditoria'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { tipoMovimentoESocialValues } from '~/values/tipoMovimentoESocial'

interface TableProps {
  data: Auditoria[]
  isLoading: boolean
  isFetching: boolean
  rowsSelected: number[]
  onRowSelected: (indexs: number[]) => void
}

export default function Table(props: TableProps) {
  const { data: _data, isLoading, isFetching, rowsSelected, onRowSelected } = props

  const {
    close: closeAuditoriaXMLForm,
    data: dataAuditoriaXMLForm,
    isOpen: isOpenAuditoriaXMLForm,
    open: openAuditoriaXMLForm,
  } = useDialog()

  const data = _data.map((obj) => ({
    ...obj,
    tipoMovimento:
      tipoMovimentoESocialValues.find((x) => x.value === obj.tipoMovimento)?.label || '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Id',
      name: 'id',
    },
    {
      label: 'Data Criação',
      name: 'dtCriacao',
    },
    {
      label: 'Ano/Mês',
      name: 'anoMes',
    },
    {
      label: 'Sequencial',
      name: 'sequencial',
    },
    {
      label: 'Tipo Movimento',
      name: 'tipoMovimento',
    },
    {
      label: 'Tabela',
      name: 'tabela',
    },
    {
      label: 'Campo',
      name: 'campo',
    },
    {
      label: 'Chave',
      name: 'chave',
    },
    {
      label: 'eSocial Status',
      name: 'eSocialStatus',
    },
    {
      label: 'eSocial ID',
      name: 'eSocialID',
    },
    {
      label: 'eSocial Protocolo',
      name: 'eSocialESocialProtocolo',
    },
    {
      label: 'Data eSocial Protocolo',
      name: 'dtESocialProtocolo',
    },
    {
      label: 'eSocial Recibo',
      name: 'eSocialRecibo',
    },
    {
      label: 'Data eSocial Recibo',
      name: 'dtESocialRecibo',
    },
    {
      label: 'eSocial Codigo Resposta',
      name: 'eSocialCodigoResposta',
    },
    {
      label: 'Indicador Retificação',
      name: 'indRetificacao',
    },
    {
      label: 'Indicador Apuração',
      name: 'indApuracao',
    },
    {
      label: 'Auditoria XML',
      name: 'auditoriaXML',
      options: {
        customBodyRender: (value) => (
          <ButtonBox justifyContent="center" spacing={0}>
            <IconButton
              size="small"
              onClick={() => openAuditoriaXMLForm(value)}
              color="primary"
              disabled={!value ? true : false}
            >
              <DescriptionIcon fontSize="small" />
            </IconButton>
          </ButtonBox>
        ),
      },
    },
  ]

  return (
    <>
      <ToolsDataTable
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        optionsSelectable={{
          type: 'multiple',
          rowsSelected: rowsSelected,
          onRowSelected: (index) => onRowSelected(index),
        }}
        disableAutoHeight
      />
      <ActionDialog
        title="Auditoria XML"
        isOpen={isOpenAuditoriaXMLForm}
        onClose={closeAuditoriaXMLForm}
        customActions={<></>}
      >
        <AuditoriaXMLForm data={dataAuditoriaXMLForm} />
      </ActionDialog>
    </>
  )
}
