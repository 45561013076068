import React, { useState } from 'react'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

const Table = (props) => {
  const { data, onItemClick, isLoading, pagination, query } = props
  const [searchText, setSearchText] = useState('')

  const columns = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'cboId',
      label: 'CBO',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  const options = {
    viewColumns: false,
    filter: false,
    search: false,
    selectableRows: 'none',
    searchText,
    print: false,
    download: false,
    elevation: 0,
    onSearchChange: (search) => {
      setSearchText(search)
    },
    responsive: 'standard',
  }

  return (
    <DataTable
      data={data}
      columns={columns}
      options={options}
      isLoading={isLoading}
      pagination={pagination}
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'cboId'],
      }}
    />
  )
}

Table.defaultProps = {
  pagination: true,
}

export default Table
