import { useEffect, useState } from 'react'

import { InputBaseComponentProps, TextField } from '@material-ui/core'

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const suffix = ' %'

function PercentInput(props: InputBaseComponentProps) {
  const { inputRef, decimalLimit, integerLimit, ...restPropsInput } = props

  const currencyMask = createNumberMask({
    prefix: '',
    suffix: suffix,
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit,
    integerLimit,
    allowNegative: false,
    allowLeadingZeroes: false,
  })

  return (
    <MaskedInput
      mask={currencyMask}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      {...restPropsInput}
    />
  )
}

interface PercentTextFieldNewProps {
  label: string
  value: number | null
  onChange: (value: number | null) => void
  maxValue?: number
  minValue?: number
  decimalLimit?: number
  integerLimit?: number
}

export default function PercentTextFieldNew(props: PercentTextFieldNewProps) {
  const {
    label,
    value: _value,
    onChange,
    maxValue = 100,
    minValue = 0,
    decimalLimit = 2,
    integerLimit = 3,
  } = props

  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(formatToString(_value))
  }, [_value])

  function formatToString(value: number | null) {
    if (!value) return ''
    return value.toString().replace('.', ',') + suffix
  }

  function formatToNumber(value: string) {
    if (!value) return null
    const valueFormated = parseFloat(value.replace(suffix, '').replace(',', '.'))
    if (valueFormated > maxValue) return maxValue
    if (valueFormated < minValue) return minValue
    return valueFormated
  }

  return (
    <TextField
      label={label}
      value={value}
      onBlur={() => onChange(formatToNumber(value))}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        inputComponent: PercentInput,
      }}
      size="small"
      variant="outlined"
      fullWidth
      inputProps={{
        style: {
          textAlign: 'right',
        },
        integerLimit,
        decimalLimit,
      }}
    />
  )
}
