import { useState } from 'react'

import * as yup from 'yup'

import { Alert, AlertTitle } from '@material-ui/lab'
import { Box, Grid, useTheme } from '@material-ui/core'

import { ActionDialog, DatePicker } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import {
  indModificacaoeSocialvalues as _indModificacaoeSocialvalues,
  indModificacaoeSocialConsts,
} from '~/values/indModificacaoeSocialvalues'
import { dateHasInAnoMes, formatSimpleDate, getDateMinAnoMes } from '~/utils/utils'

const indModificacaoeSocialvalues = _indModificacaoeSocialvalues.filter(
  (item) => item.value !== indModificacaoeSocialConsts.NaoIncide_0,
)

const schema = yup.object().shape({
  dtInclusaoeSocial: yup
    .date()
    .typeError('Informe uma Data válida')
    .required('Informe a Data de Inclusão eSocial')
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data informada deve está dentro da competência atual',
        (dt) => dateHasInAnoMes(dt, anoMes),
      ),
    )
    .nullable(),
})

export default function DialogMessageEvento(props) {
  const { onChange, isOpen, onClose, fields } = props

  const dialogNotification = useDialogNotification()
  const theme = useTheme()
  const { anoMes } = useAmbiente()

  const dtInicioMes = getDateMinAnoMes(anoMes)

  const [data, setData] = useState({
    indModificacaoeSocial: indModificacaoeSocialConsts.Inclusao_1,
    dtInclusaoeSocial: dtInicioMes,
  })

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSave,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { anoMes },
    },
  })

  function handleSave() {
    if (data.indModificacaoeSocial === indModificacaoeSocialConsts.Inclusao_1) {
      dialogNotification.info({
        descriptions: [
          `A modificação vai entrar em vigor para o eSocial na data: ${formatSimpleDate(
            data.dtInclusaoeSocial,
          )}`,
        ],
        onConfirm: () => {
          handleChange()
        },
        labelOnConfirm: 'Confirmar',
      })
    } else {
      handleChange()
    }
  }

  function handleChange() {
    onChange(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Alteração de campos"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
    >
      <Grid container spacing={2} style={{ padding: theme.spacing(1) }}>
        <Box style={{ paddingBottom: theme.spacing(1) }}>
          <Alert severity="info">
            <AlertTitle>Informação</AlertTitle>
            <Box>
              Os seguintes campos que fazem parte da configuração do Evento foram alterados:
            </Box>
            <Box my={1}>{fields.map((field) => `[${field}] `)}</Box>
            <Box>
              Essas alterações devem gerar uma inclusão ou uma alteração no eSocial! Informe como
              você deseja prosseguir.
            </Box>
          </Alert>
        </Box>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <MUIAutoComplete
            name="indModificacaoeSocial"
            label="Indicador Modificação eSocial"
            required
            value={data?.indModificacaoeSocial}
            options={indModificacaoeSocialvalues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(_, obj) => {
              const indModificacaoeSocial = obj?.value || indModificacaoeSocialConsts.Inclusao_1
              setData({
                indModificacaoeSocial,
                dtInclusaoeSocial: dtInicioMes,
              })
            }}
            validationErrors={validationErrors}
          />
        </Grid>
        {data?.indModificacaoeSocial !== indModificacaoeSocialConsts.Alteracao_2 && (
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <DatePicker
              name="dtInclusaoeSocial"
              label="Data Modificação eSocial"
              required
              value={data?.dtInclusaoeSocial || null}
              onChange={(date) => {
                const dtInclusaoeSocial = date ? date.format('yyyy-MM-DD') : null
                setData((oldState) => ({
                  ...oldState,
                  dtInclusaoeSocial,
                }))
              }}
              validationErrors={validationErrors}
              disabled
            />
          </Grid>
        )}
      </Grid>
    </ActionDialog>
  )
}

DialogMessageEvento.defaultProps = {
  fields: [],
}
