import { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'
import moment from 'moment'

import { Box, CircularProgress, Grid, LinearProgress } from '@material-ui/core'

import EmptyWarning from '../EmptyWarning'

import PeriodoFerias from './PeriodoFerias'
import InformacoesComplementares from './InformacoesComplementares'
import PeriodoConcedido from './PeriodoConcedido'
import PeriodoProgramado from './PeriodoProgramado'
import FormFerias from './FormFerias'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import { DayOfWeekEnum } from '~/@types/enums/DayOfWeekEnum'
import { IndFeriasEnum } from '~/@types/enums/IndFeriasEnum'
import { JornadaTipoEnum } from '~/@types/enums/JornadaTipoEnum'

export interface FeriasFormData {
  anoMes: string | null
  lotacaoId?: string | null
  vinculoId: string | null
  vinculoFeriasId: string | null
  vinculoFeriasProgramacaoId: string | null
  nrDiasAbono: number
  dtInicioConcessao: string | null
  dtFimConcessao: string | null
  dtRetorno: string | null
  dtEmissaoFerias: string | null
  dtAvisoFerias: string | null
  dtInicioAbono: string | null
  dtFimAbono: string | null
  indFerias: IndFeriasEnum | null
  nrDiasConcedidos: number
  rpId: string | null
}
export interface FeriasConcedida {
  vinculoFeriasItemId: string
  dtInicioConcessao: string
  dtFimConcessao?: string
  dtInicioAbono?: string
  dtFimAbono?: string
  nrDiasAbono: number
  nrDiasConcedidos: number
}

interface FeriasFormRootData {
  anoMes: string
  lotacaoId?: string
  vinculoId: string
  vinculoFeriasId: string
  dtPeriodoInicio: string
  dtPeriodoFim: string
  dtLimiteConcessao?: string
  nrDiasJaConcedidos: number
  nrDiasConcedidos: number
  nrDiasDireito: number
  totalFaltas: number
  saldoDias: number
  observacao?: string
  vinculo: {
    nrInscricao: string
    nome: string
    diaDescansoSemanal: DayOfWeekEnum
    tipoJornada: JornadaTipoEnum
  }
  feriasConcedidas: FeriasConcedida[]
  feriasProgramadas: FeriasFormData[]
  feriados: string[]
}

interface FeriasFormProps {
  vinculoId: string
  vinculoFeriasId?: string | null
  onSubmit: (data: FeriasFormData) => void
  onCancel: () => void
  isLoading: boolean
  dataForm: FeriasFormData | null
  isFeriasProgramacao: boolean
}

export const initialStateFormFerias: FeriasFormData = {
  anoMes: null,
  lotacaoId: null,
  vinculoId: null,
  vinculoFeriasProgramacaoId: null,
  vinculoFeriasId: null,
  nrDiasAbono: 0,
  dtInicioConcessao: null,
  dtFimConcessao: null,
  dtRetorno: null,
  dtEmissaoFerias: null,
  dtAvisoFerias: null,
  dtInicioAbono: null,
  dtFimAbono: null,
  indFerias: null,
  nrDiasConcedidos: 0,
  rpId: null,
}

export default function FeriasFormComponent(props: FeriasFormProps) {
  const {
    vinculoId,
    vinculoFeriasId,
    onSubmit,
    onCancel,
    isLoading: isLoadingForm,
    dataForm: _dataForm,
    isFeriasProgramacao,
  } = props

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<FeriasFormRootData | null>(null)
  const [dataForm, setDataForm] = useState<FeriasFormData>(initialStateFormFerias)
  const [periodoProgramadoSelecionado, setPeriodoProgramadoSelecionado] = useState(-1)

  const { anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    async function getData() {
      setLoading(true)
      try {
        const response = await api.get('/VinculoFerias/ObterFeriasAtuais', {
          params: {
            vinculoId,
            anoMes,
          },
        })
        const responseCurrent: FeriasFormRootData = response.data.data

        setData(responseCurrent)

        if (_dataForm) {
          return setDataForm(_dataForm)
        }

        if (responseCurrent.feriasProgramadas.length > 0 && !vinculoFeriasId) {
          setDataForm(responseCurrent.feriasProgramadas[0])
          setPeriodoProgramadoSelecionado(0)
          return
        }

        resetDataForm(
          responseCurrent.anoMes,
          responseCurrent.vinculoId,
          vinculoFeriasId ? vinculoFeriasId : responseCurrent.vinculoFeriasId,
          responseCurrent.nrDiasConcedidos,
          responseCurrent.lotacaoId,
          IndFeriasEnum.Mensal_0,
        )
      } catch (err) {
        onCancel()
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getData()
    // eslint-disable-next-line
  }, [anoMes, vinculoId, vinculoFeriasId])

  if (isLoading) {
    return <LinearProgress />
  }

  if (!data) {
    return (
      <Box padding={4} display="flex" justifyContent="center">
        <EmptyWarning
          style={{
            width: '50%',
            height: '50%',
          }}
          message="Registros não encontrados"
        />
      </Box>
    )
  }

  function resetDataForm(
    anoMes: string,
    vinculoId: string,
    vinculoFeriasId: string,
    nrDiasConcedidos: number,
    lotacaoId: string | undefined,
    indFerias: IndFeriasEnum,
  ) {
    setDataForm({
      ...initialStateFormFerias,
      vinculoFeriasId,
      vinculoId,
      anoMes,
      nrDiasConcedidos,
      lotacaoId,
      indFerias,
    })
  }

  const dataFeriasConcedidas = data.feriasConcedidas.map((d) => ({
    dtFimAbono: d?.dtFimAbono ? moment(d.dtFimAbono).format('DD/MM/yyyy') : '-',
    dtFimConcessao: d?.dtFimConcessao ? moment(d.dtFimConcessao).format('DD/MM/yyyy') : '-',
    dtInicioAbono: d?.dtInicioAbono ? moment(d.dtInicioAbono).format('DD/MM/yyyy') : '-',
    dtInicioConcessao: d?.dtInicioConcessao
      ? moment(d.dtInicioConcessao).format('DD/MM/yyyy')
      : '-',
    nrDiasAbono: d?.nrDiasAbono ? d.nrDiasAbono : 0,
    nrDiasConcedidos: d?.nrDiasConcedidos ? d.nrDiasConcedidos : 0,
  }))

  const dataFeriasProgramadas = data.feriasProgramadas.map((d) => ({
    dtFimAbono: d?.dtFimAbono ? moment(d.dtFimAbono).format('DD/MM/yyyy') : '-',
    dtFimConcessao: d?.dtFimConcessao ? moment(d.dtFimConcessao).format('DD/MM/yyyy') : '-',
    dtInicioAbono: d?.dtInicioAbono ? moment(d.dtInicioAbono).format('DD/MM/yyyy') : '-',
    dtInicioConcessao: d?.dtInicioConcessao
      ? moment(d.dtInicioConcessao).format('DD/MM/yyyy')
      : '-',
    nrDiasAbono: d?.nrDiasAbono ? d.nrDiasAbono : 0,
    nrDiasConcedidos: d?.nrDiasConcedidos ? d.nrDiasConcedidos : 0,
  }))

  const feriadosFormated = data.feriados.map((ddMMFeriado) => {
    const ano = anoMes.substring(0, 4)
    const mes = ddMMFeriado.substring(2)
    const dia = ddMMFeriado.substring(0, 2)
    return ano + '-' + mes + '-' + dia
  })

  function handleChangePeriodoSelected(indexSelected: number) {
    if (!data) return
    setDataForm(data.feriasProgramadas[indexSelected])
    setPeriodoProgramadoSelecionado(indexSelected)
  }

  function handleRemoveProgramacaoPeriodoFerias() {
    if (!data) return
    resetDataForm(
      data.anoMes,
      data.vinculoId,
      data.vinculoFeriasId,
      data.nrDiasConcedidos,
      data.lotacaoId,
      IndFeriasEnum.Mensal_0,
    )
    setPeriodoProgramadoSelecionado(-1)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
        <PeriodoFerias
          data={{
            dtPeriodoInicio: data.dtPeriodoInicio,
            dtPeriodoFim: data.dtPeriodoFim,
            dtLimiteConcessao: data?.dtLimiteConcessao || '-',
            observacao: data?.observacao || '-',
          }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <InformacoesComplementares
          data={{
            nrDiasConcedidos: data?.nrDiasJaConcedidos || 0,
            nrDiasDireito: data?.nrDiasDireito || 0,
            totalFaltas: data?.totalFaltas || 0,
            saldoDias: data?.saldoDias || 0,
          }}
        />
      </Grid>

      <Grid item md={_dataForm ? 12 : 6} sm={12} xs={12}>
        <PeriodoConcedido data={dataFeriasConcedidas} />
      </Grid>

      {!_dataForm && (
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <PeriodoProgramado
            data={dataFeriasProgramadas}
            indexPeriodoSelected={periodoProgramadoSelecionado}
            onChangePeriodoSelected={handleChangePeriodoSelected}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <FormFerias
          data={dataForm}
          onRemovePeriodoFerias={handleRemoveProgramacaoPeriodoFerias}
          feriasAtual={{
            nrDiasDireito: data?.nrDiasDireito || 0,
            nrDiasJaConcedidos: data?.nrDiasJaConcedidos || 0,
            tipoJornadaFuncionario: data.vinculo.tipoJornada,
            descansoSemanalFuncionario: data.vinculo.diaDescansoSemanal,
            feriados: feriadosFormated,
            periodoFeriasConcedidas: data.feriasConcedidas,
            vinculoId,
          }}
          onCancel={onCancel}
          onSubmit={onSubmit}
          isFeriasProgramacao={isFeriasProgramacao}
        />
      </Grid>
      {isLoadingForm && (
        <Box
          position="absolute"
          bottom={60}
          top={60}
          right={0}
          left={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={70} />
        </Box>
      )}
    </Grid>
  )
}
