import React, { useEffect, useState, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, Finder } from 'mio-library-ui'

import Header from './components/Header'
import Table from './components/Table'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import { useHistory } from 'react-router-dom'

import api from '~/services/api-pessoal'

export default function AdiantamentoSalario() {
  const [query, setQuery] = useState('')
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelecteds] = useState([])
  const [isLoadingTable, setLoadingTable] = useState(false)
  const [isLoadingProcess, setLoadingProcess] = useState(false)

  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const history = useHistory()

  const getCollection = useCallback(async () => {
    setLoadingTable(true)
    setRowsSelecteds([])
    try {
      const response = await api.get('/RP/GetVinculoAdiantamentoSalario', {
        params: {
          estabelecimentoId: estabelecimento?.id,
          anoMes,
        },
      })
      setCollection(response?.data?.data || [])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingTable(false)
    }
    // eslint-disable-next-line
  }, [anoMes, estabelecimento])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  async function handleProcess(dtPagamento) {
    setLoadingProcess(true)
    try {
      const dataSelected = rowsSelecteds.map((indexCurrent) => collection[indexCurrent])
      await api.post('/RP/NovoAdiantamento', dataSelected, {
        params: {
          anoMes,
          dtPagamento,
        },
      })
      notification.success('Adiantamento de Salário processado com sucesso')
      history.push('/recibo-pagamento/adiantamento-salario')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingProcess(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box height="50px">
        <PageHeader title="Adiantamento de Salário">
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </PageHeader>
      </Box>
      <Header
        isLoadingProcess={isLoadingProcess}
        handleProcess={handleProcess}
        rowsSelecteds={rowsSelecteds}
        collection={collection}
      />
      <Table
        data={collection}
        query={query}
        isLoading={isLoadingTable}
        rowsSelected={rowsSelecteds}
        handleRowSelected={setRowsSelecteds}
      />
    </Box>
  )
}
