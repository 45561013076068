import React from 'react'
import { Box, Checkbox, ListItem, ListItemText, makeStyles } from '@material-ui/core'

import { HEIGHT_ITEM_LIST } from '../..'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: theme.shape.border,
    display: 'flex',
    height: HEIGHT_ITEM_LIST,
  },
}))

export function Header(props) {
  const { toggleAll, disabled, optionsLength, optionsCheckedsLength, width } = props

  const classes = useStyles()

  return (
    <Box className={classes.root} style={{ width }}>
      <ListItem role={undefined} dense button onClick={toggleAll} disabled={disabled}>
        <Checkbox
          edge="start"
          tabIndex={-1}
          disableRipple
          checked={optionsLength === optionsCheckedsLength}
          indeterminate={optionsCheckedsLength !== optionsLength && optionsCheckedsLength !== 0}
        />
        <ListItemText
          primary={
            <Box fontSize="10pt" color="black" display="flex" justifyContent="space-between">
              Selecionar todos{' '}
              <Box>
                {optionsCheckedsLength}/{optionsLength}
              </Box>
            </Box>
          }
        />
      </ListItem>
    </Box>
  )
}

Header.defaultProps = {
  toggleAll: () => undefined,
  disabled: false,
  optionsLength: 0,
  optionsCheckedsLength: 0,
}
