import React, { useMemo } from 'react'

import { HeightDataTable } from '~/components'

import { formatToCPFOrCNPJ } from 'brazilian-values'

const Table = (props) => {
  const { data: _data, isLoading, query } = props

  const data = _data.map((item) => {
    return {
      ...item,
      codigo: item?.empregador?.codigo ? item.empregador.codigo : null,
      nome: item?.empregador?.nome || null,
      nrInscricao: formatToCPFOrCNPJ(item?.empregador?.nrInscricao || ''),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
    ],
    [],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'nrInscricao'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
