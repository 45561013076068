import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'
import { TreeItem } from '@material-ui/lab'

import { useContextRecibo } from '~/hooks/useRecibo'

import CardItem from '../CardItem'
import CardMenu from './components/CardMenu'

const useStyles = makeStyles((theme) => ({
  highLightItem: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: '2px',
  },
  labelAjusts: {
    width: 'calc(100% - 18px)',
  },
}))

export default function SecondLevel(props) {
  const { children, vinculoCurrent, group, onContextMenuItemClick, handleOnLabelClick } = props

  const [isHovering, setHovering] = useState(false)

  const classes = useStyles()
  const { expanded } = useContextRecibo()

  return (
    <TreeItem
      nodeId={`1|${vinculoCurrent.vinculoId}`}
      onLabelClick={() => {
        const length = vinculoCurrent.recibos.length
        const nodeId = `1|${vinculoCurrent.vinculoId}`
        const isOpen = expanded.includes(nodeId)
        if (length > 0 && !isOpen) {
          setTimeout(() => {
            handleOnLabelClick('reciboSelecionado', vinculoCurrent.recibos[0].reciboId)
          }, 300)
        }
      }}
      classes={{
        expanded: classes.highLightItem,
        label: classes.labelAjusts,
      }}
      label={
        <CardItem
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          labelText={vinculoCurrent.vinculoNome}
          labelInfo={
            <CardMenu
              vinculo={vinculoCurrent}
              agrupamentoId={group.agrupamentoId}
              showActions={isHovering}
              onContextMenuItemClick={onContextMenuItemClick}
            />
          }
        />
      }
    >
      {children}
    </TreeItem>
  )
}
