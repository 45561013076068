import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import { useQuery } from '~/hooks/queries/useVinculoReintegracao'

import { tipoReintegracaoValues } from '~/values/tipoReintegracaoValues'

import moment from 'moment'

export default function Table(props) {
  const { vinculo, onItemClick } = props
  const { data: _data, isLoading } = useQuery(vinculo?.id)

  function formatTipoReintegracao(tipoReintegracao) {
    const tipoReintegracaoFinded = tipoReintegracaoValues.find(
      (obj) => obj.value === tipoReintegracao,
    )
    return tipoReintegracaoFinded.name
  }

  const data = _data?.map((item) => {
    return {
      ...item,
      tipoReintegracao: item?.tipoReintegracao ? formatTipoReintegracao(item.tipoReintegracao) : '',
      dtEfeito: item?.dtEfeito ? moment(item.dtEfeito).format('DD/MM/YYYY') : '',
      dtEfetivaRetorno: item?.dtEfetivaRetorno
        ? moment(item.dtEfetivaRetorno).format('DD/MM/YYYY')
        : '',
      dtRescisao: item?.dtRescisao ? moment(item.dtRescisao).format('DD/MM/YYYY') : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'tipoReintegracao',
        label: 'Tipo Reintegração',
      },
      {
        name: 'nrProcesso',
        label: 'Número do Processo',
      },
      {
        name: 'dtEfeito',
        label: 'Data Efeito',
      },
      {
        name: 'dtEfetivaRetorno',
        label: 'Data Efetiva Retorno',
      },
      {
        name: 'dtRescisao',
        label: 'Data Rescisão',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  size="small"
                  onClick={() => {
                    const dataFinded = _data?.find((d) => d.id === value)
                    onItemClick('edit', dataFinded)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" size="small" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  size="small"
                  onClick={() => onItemClick('delete', value)}
                >
                  <DeleteIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [_data, onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}
