import React from 'react'

import { formatToCPF } from 'brazilian-values'

import { ButtonBox } from 'mio-library-ui'

import { Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { IndOrgaoClasseEnum, IndOrgaoClasseValues } from '~/@types/enums/IndOrgaoClasseEnum'
import { CondicaoAmbienteTrabalhoResponsavelPartial } from '~/hooks/queries/CondicaoAmbienteTrabalhoResponsavel/CondicaoAmbienteTrabalhoResponsavel'

const formatOrgaoClasse = (orgaoClasse: IndOrgaoClasseEnum) => {
  const objectFinded = IndOrgaoClasseValues.find((obj) => obj.value === orgaoClasse)
  return objectFinded?.name || ''
}

interface TableProps {
  data: CondicaoAmbienteTrabalhoResponsavelPartial[]
  onItemClick: (event: 'delete', index: number) => void
}

export default function Table(props: TableProps) {
  const { data: _data, onItemClick } = props

  const data = _data?.map((item) => {
    return {
      ...item,
      responsavelNome: item?.responsavel?.nome || '',
      responsavelCpf: item?.responsavel?.cpf ? formatToCPF(item.responsavel.cpf) : '',
      responsavelOrgaoClasse: item?.responsavel?.orgaoClasse
        ? formatOrgaoClasse(item.responsavel.orgaoClasse)
        : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'responsavelNome',
      label: 'Nome',
    },
    {
      name: 'responsavelCpf',
      label: 'CPF',
    },
    {
      name: 'responsavelOrgaoClasse',
      label: 'Orgão de Classe',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', rowIndex)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} />
}
