import moment from 'moment'

import { IconButton, Box, Paper, Typography, useTheme, Tooltip } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { ToolsDataTable } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'

import { useRemoverVariosPontoEletronico } from '~/hooks/queries/PontoEletronico/useRemoverVariosPontoEletronico'

const Table = (props) => {
  const { data, isLoading, onAfterSubmit, rowsSelected, setRowsSelected } = props

  const theme = useTheme()
  const dialogNotification = useDialogNotification()

  const { mutateAsync: mutateAsyncDelete, isLoading: isDeleting } =
    useRemoverVariosPontoEletronico()

  const dataFormated = data.map((obj) => {
    return {
      ...obj,
      funcionario: obj?.vinculo?.pessoaFisica?.nome || '',
      evento: `${obj.evento?.codigo || ''} - ${obj?.evento?.nome || ''}`,
      dtEvento: obj?.dtEvento ? moment(obj.dtEvento).format('DD/MM/YYYY') : '',
      dtImportacao: obj?.dtImportacao ? moment(obj.dtImportacao).format('DD/MM/YYYY') : '',
      dtProcessamentoRP: obj?.dtProcessamentoRP
        ? moment(obj.dtProcessamentoRP).format('DD/MM/YYYY')
        : '',
    }
  })

  const columns = [
    {
      name: 'funcionario',
      label: 'Funcionário',
    },
    {
      name: 'evento',
      label: 'Evento',
    },
    {
      name: 'dtEvento',
      label: 'Data do Evento',
    },
    {
      name: 'dtImportacao',
      label: 'Data da Importação',
    },
    {
      name: 'dtProcessamentoRP',
      label: 'Data de Processamento RP',
    },
    {
      name: 'qtdHoras',
      label: 'Quantidade de Horas',
    },
    {
      name: 'qtdMinutos',
      label: 'Quantidade de Minutos',
    },
  ]

  function handleConfirmDelete() {
    dialogNotification.info({
      title: 'Excluir Registros Permanentemente!',
      descriptions: [
        'Você não poderá recuperar estes registros após a remoção',
        'Todos os eventos inseridos pela importação serão removidos dos recibos.',
      ],
      onConfirm: async () => {
        const ids = rowsSelected.map((index) => data[index].id)
        await mutateAsyncDelete({ data: ids })
        onAfterSubmit()
      },
      labelOnConfirm: 'Confirmar',
    })
  }

  return (
    <Box>
      <Box
        component={Paper}
        p={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gridGap={theme.spacing(2)}
      >
        <Typography
          style={{
            display: 'flex',
            gap: theme.spacing(1),
          }}
        >
          Registros selecionados:
          <Box fontWeight="bolder" fontSize="12pt" color="#2E8BC0">
            {rowsSelected.length}
          </Box>
        </Typography>
        <Box display="flex" gridGap={theme.spacing(1)}>
          <Tooltip title="Remover registros">
            <IconButton
              size="small"
              color="primary"
              onClick={handleConfirmDelete}
              disabled={rowsSelected.length > 0 ? false : true}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <ToolsDataTable
        disableAutoHeight
        data={dataFormated}
        columns={columns}
        isFetching={isDeleting}
        isLoading={isLoading}
        optionsSelectable={{
          type: 'multiple',
          rowsSelected,
          onRowSelected: setRowsSelected,
        }}
      />
    </Box>
  )
}

export default Table
