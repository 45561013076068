import { useEffect, useState } from 'react'

import {
  Box,
  CircularProgress,
  LinearProgress,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core'

import { Button, ButtonBox, PageHeader } from '~/components'
import StepperForm from '~/components/StepperForm'
import { StateStepsInfo } from '~/components/StepperForm/StepperProvider'

import MainForm from './MainForm'
import PessoaFisicaForm from './PessoaFisicaForm'
import Dependentes from './Dependentes'
import Salarios from './Salarios'
import AmbienteTrabalho from './AmbienteTrabalho'
import HorarioTrabalho from './HorarioTrabalho'
import Treinamentos from './Treinamentos'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import { useHistory, useParams } from 'react-router-dom'
import { useRetificarVinculo } from '~/hooks/queries/Vinculo/useRetificarVinculo'

import {
  useVinculoObterDataRetificacao,
  VinculoDataRetificarDTO,
} from '~/hooks/queries/Vinculo/useVinculoObterDataRetificacao'

interface ParamsPage {
  id: string
}

export default function FlowRetificar() {
  const [data, setData] = useState<VinculoDataRetificarDTO | null>(null)

  const [stepCurrent, setStepCurrent] = useState(0)
  const [stepsCompleted, setStepsCompleted] = useState<StateStepsInfo>({})

  const dialogNotification = useDialogNotification()
  const { id: funcionarioId } = useParams<ParamsPage>()
  const { anoMes } = useAmbiente()
  const history = useHistory()
  const theme = useTheme()

  const {
    data: _data,
    isLoading: isLoadingObterData,
    isFetching,
  } = useVinculoObterDataRetificacao(funcionarioId)
  const { mutateAsync: mutateAsyncRetificar, isLoading: isLoadingRetificar } = useRetificarVinculo()

  const isLoading = isLoadingObterData || isLoadingRetificar

  useEffect(() => {
    if (_data) {
      setData(_data)
    }
  }, [_data])

  async function handleSubmit() {
    if (data) {
      await mutateAsyncRetificar({
        data,
        anoMes,
      })
      history.push('/funcionario-retificar')
    }
  }

  function confirmSubmit() {
    dialogNotification.info({
      descriptions: ['Confirme a retificação do Funcionário'],
      onConfirm: handleSubmit,
      labelOnConfirm: 'Confirmar',
    } as FixLater)
  }

  function handleCancel() {
    dialogNotification.warning({
      descriptions: ['Deseja realmente cancelar a retificação?'],
      onConfirm: () => history.push('/funcionario-retificar'),
      labelOnConfirm: 'Sim',
    } as FixLater)
  }

  if (isLoading) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={70} />
      </Box>
    )
  }

  if (!data) {
    return <Typography>Não foi possível localizar o vínculo</Typography>
  }

  return (
    <StepperForm.Provider
      isLoading={isLoading}
      steps={[
        {
          label: 'Princípal',
          children: (
            <MainForm
              data={data.vinculo}
              onSubmit={(d) => {
                setData({ ...data, vinculo: d })
                setStepCurrent(1)
                setStepsCompleted((prev) => ({
                  ...prev,
                  [0]: true,
                }))
              }}
            />
          ),
          icon: 'assignment_ind',
        },
        {
          label: 'Pessoa Física',
          children: (
            <PessoaFisicaForm
              data={data.pessoaFisica}
              onSubmit={(d) => {
                setData({ ...data, pessoaFisica: d })
                setStepCurrent(2)
                setStepsCompleted((prev) => ({
                  ...prev,
                  [1]: true,
                }))
              }}
            />
          ),
          icon: 'person',
        },
        {
          label: 'Dependentes',
          children: (
            <Dependentes
              pessoaFisicaId={data.pessoaFisica.id}
              data={data.dependentes}
              onSubmit={(d) => {
                setData({ ...data, dependentes: d })
                setStepsCompleted((prev) => ({
                  ...prev,
                  [2]: true,
                }))
              }}
            />
          ),
          icon: 'supervisor_account',
        },
        {
          label: 'Salários',
          children: (
            <Salarios
              vinculoId={data.vinculo.id}
              data={data.salarios}
              onSubmit={(d) => {
                setData({ ...data, salarios: d })
                setStepsCompleted((prev) => ({
                  ...prev,
                  [3]: true,
                }))
              }}
            />
          ),
          icon: 'attach_money',
        },
        {
          label: 'Ambiente de Trabalho',
          children: (
            <AmbienteTrabalho
              vinculoId={data.vinculo.id}
              data={data.ambientesTrabalho}
              onSubmit={(d) => {
                setData({ ...data, ambientesTrabalho: d })
                setStepsCompleted((prev) => ({
                  ...prev,
                  [4]: true,
                }))
              }}
            />
          ),
          icon: 'work',
        },
        {
          label: 'Horário de Trabalho',
          children: (
            <HorarioTrabalho
              vinculoId={data.vinculo.id}
              data={data.horariosTrabalho}
              onSubmit={(d) => {
                setData({ ...data, horariosTrabalho: d })
                setStepsCompleted((prev) => ({
                  ...prev,
                  [5]: true,
                }))
              }}
            />
          ),
          icon: 'query_builder',
        },
        {
          label: 'Treinamentos',
          children: (
            <Treinamentos
              vinculoId={data.vinculo.id}
              data={data.treinamentos}
              onSubmit={(d) => {
                setData({ ...data, treinamentos: d })
                setStepsCompleted((prev) => ({
                  ...prev,
                  [6]: true,
                }))
              }}
            />
          ),
          icon: 'assignment_turned_in',
        },
      ]}
      stepCurrent={stepCurrent}
      setStepCurrent={setStepCurrent}
      stepsCompleted={stepsCompleted}
      setStepsCompleted={setStepsCompleted}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(1)}
        padding={2}
      >
        <PageHeader title="Retificação Funcionário" subtitle={data.pessoaFisica.nome || '-'}>
          <ButtonBox>
            <Button onClick={handleCancel}>Cancelar Retificação</Button>
            <Button variant="contained" onClick={confirmSubmit}>
              Finalizar Retificação
            </Button>
          </ButtonBox>
        </PageHeader>

        <Box height={4}>{isFetching && <LinearProgress />}</Box>

        <Box padding={0.5} component={Paper}>
          <StepperForm.Steps orientation="horizontal" />
        </Box>
        <Box flex={1} padding={1} component={Paper}>
          <StepperForm.Content style={{ height: '100%' }} />
        </Box>
      </Box>
    </StepperForm.Provider>
  )
}
