import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import { openDownloadData } from '~/hooks/useUtils'

import { queryObterDocumentos } from './useObterDocumentos'

interface RequestProps {
  documentosIds: string[]
  nomeArquivo: string
}

export function useBaixaVariosDocumentos() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    const { documentosIds, nomeArquivo } = props
    const response = await api.post('/GerenciadorDocumentos/BaixaVariosDocumentos', documentosIds, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    })
    openDownloadData(nomeArquivo + '.zip', response.data)
    notification.success('Documentos emitidos com Sucesso')
  }

  return useMutation(handleRequest, {
    onSuccess: async () => await queryClient.invalidateQueries(queryObterDocumentos),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
