export enum IndApuracaoEnum {
  Mensal_1 = 1,
  Anual_2 = 2,
  NaoSeAplica_9 = 0,
}

export const IndApuracaoValues = [
  { value: IndApuracaoEnum.Mensal_1, name: '1 - Mensal' },
  { value: IndApuracaoEnum.Anual_2, name: '2 - Décimo Terceiro' },
]
