import { formatToCPFOrCNPJ } from 'brazilian-values'

import { CopyLabel, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { EtiquetaAlteracaoSalarialFuncionarioDTO } from '~/hooks/queries/EtiquetaAlteracaoSalarial/dtos/EtiquetaAlteracaoSalarialFuncionarioDTO'
import { formatCurrency, formatSimpleDate } from '~/utils/utils'

interface TableProps {
  data: EtiquetaAlteracaoSalarialFuncionarioDTO[]
  isLoading: boolean
  onRowSelected: (indexs: number[]) => void
  rowsSelected: number[]
  query: string
}

export default function Table(props: TableProps) {
  const { data, isLoading, onRowSelected, rowsSelected, query } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'estabelecimentoInscricao',
      label: 'CNPJ',
      options: {
        customBodyRender: (value) =>
          value ? <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel> : '',
      },
    },
    {
      name: 'estabelecimentoNome',
      label: 'Estabelecimento',
    },
    {
      name: 'funcionarioNome',
      label: 'Funcionário',
    },
    {
      name: 'funcionarioCPF',
      label: 'CPF',
      options: {
        customBodyRender: (value) =>
          value ? <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel> : '',
      },
    },
    {
      name: 'dtSalario',
      label: 'Data do Salário',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : ''),
      },
    },
    {
      name: 'vrSalario',
      label: 'Valor do Salário',
      options: {
        customBodyRender: (value) => (value ? formatCurrency(value) : ''),
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      isLoading={isLoading}
      columns={columns}
      sherlock={{
        columns: [
          'estabelecimentoInscricao',
          'estabelecimentoNome',
          'funcionarioCPF',
          'funcionarioNome',
          'dtSalario',
        ],
        query,
      }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
    />
  )
}
