import { useState } from 'react'

import * as yup from 'yup'

import { ActionDialog, DatePickerNew, Stack, TextField } from '~/components'

import { useCreateVinculoAdvertencia } from '~/hooks/queries/VinculoAdvertencia/useCreateVinculoAdvertencia'
import { useUpdateVinculoAdvertencia } from '~/hooks/queries/VinculoAdvertencia/useUpdateVinculoAdvertencia'

import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoAdvertenciaDTO } from '~/hooks/queries/VinculoAdvertencia/dtos/VinculoAdvertenciaDTO'
import AutoCompleteMotivo from '~/components/AutoComplete/AutoCompleteMotivo'

const schema = yup.object().shape({
  dtAdvertencia: yup
    .date()
    .typeError('Informe uma dava válida')
    .required('Informe a Data da Advertência'),
  motivoId: yup.string().required('Informe o Motivo'),
  motivoAdvertencia: yup.string().required('Informe a Descrição da Advertência'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoAdvertenciaDTO
  subtitle: string
}

export default function Form({ isOpen, onClose, data: _data, subtitle }: FormProps) {
  const [data, setData] = useState(_data)

  const { mutateAsync: mutateAsyncCreate, isLoading: isCreating } = useCreateVinculoAdvertencia()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } = useUpdateVinculoAdvertencia()

  const isLoading = isCreating || isUpdating

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    const isUpdate = _data.id ? true : false
    const { id, ...rest } = data
    if (isUpdate) {
      await mutateAsyncUpdate({
        data: rest,
        id,
      })
    } else {
      await mutateAsyncCreate(rest)
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Advertência"
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Stack gap={2}>
        <DatePickerNew
          label="Data da Advertência"
          value={data.dtAdvertencia || null}
          onChange={(time) => {
            const dtAdvertencia = time || ''
            setData((prev) => ({ ...prev, dtAdvertencia }))
          }}
          required
          validationErrors={validationErrors}
          name="dtAdvertencia"
        />

        <AutoCompleteMotivo
          value={data.motivoId}
          onChange={(d) => {
            const motivoId = d?.id || ''
            setData((prev) => ({ ...prev, motivoId }))
          }}
          required
          validationErrors={validationErrors}
          name="motivoId"
        />

        <TextField
          label="Descrição da Advertência"
          value={data.motivoAdvertencia}
          onChange={(e) => {
            const motivoAdvertencia = e.target.value
            setData((prev) => ({
              ...prev,
              motivoAdvertencia,
            }))
          }}
          rows={4}
          multiline
          required
          inputProps={{
            maxLength: 2000,
          }}
          validationErrors={validationErrors}
          name="motivoAdvertencia"
        />
      </Stack>
    </ActionDialog>
  )
}
