import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { CondicaoAmbienteTrabalho } from './CondicaoAmbienteTrabalho'

export function useCondicaoAmbienteTrabalhoGetByVinculo(vinculoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: CondicaoAmbienteTrabalho[] }>(
      '/CondicaoAmbienteTrabalho/GetByVinculo',
      {
        params: {
          vinculoId,
        },
      },
    )
    return response?.data?.data || []
  }

  return useQuery(['CondicaoAmbienteTrabalhoGetByVinculo', vinculoId], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
