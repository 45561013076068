import React, { useEffect, useState } from 'react'
import {
  ActionDialog,
  AnoMesTextField,
  CurrencyTextField,
  DatePicker,
  TextField,
} from '~/components'
import { AutoCompleteEstabelecimento, AutoCompleteLotacao } from '~/components/AutoComplete'
import { Grid } from '@material-ui/core'
import { useSubmit } from '~/hooks/queries/useContratacaoTrabalhadorAvulso'
import useValidationErrors from '~/hooks/useValidationErrors'
import * as yup from 'yup'
import moment from 'moment'

const schema = yup.object().shape({
  estabelecimentoId: yup.string().required('Informe o Estabelecimento'),
  anoMes: yup
    .string()
    .required('Infome o Ano/Mês')
    .when('$competenciaAnoMes', (competenciaAnoMes, schema) =>
      schema.test('Ano/Mês-validator', 'O Ano/Mês deve ser igual a Competência Atual ', (anoMes) =>
        moment(anoMes).isSame(competenciaAnoMes),
      ),
    ),
  lotacao: yup.string().required('Informe a Lotação').nullable(),
})

export default function Form(props) {
  const { anoMes, data: _data, isOpen, onClose } = props
  const [data, setData] = useState({})
  const { mutateAsync, isLoading } = useSubmit()

  useEffect(() => {
    setData(_data)
  }, [_data])

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { competenciaAnoMes: anoMes },
    },
  })

  return (
    <ActionDialog
      title="Cadastro de Contratação Trabalhador Avulso"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteEstabelecimento
            name="estabelecimentoId"
            required
            value={data?.estabelecimentoId}
            onChange={(_, estabelecimento) => {
              const estabelecimentoId = estabelecimento?.id || ''
              setData((oldState) => ({
                ...oldState,
                estabelecimentoId,
                estabelecimento,
              }))
            }}
            optionId="id"
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <AnoMesTextField
            name="anoMes"
            label="Ano/Mês"
            variant="outlined"
            size="small"
            fullWidth
            required
            value={data?.anoMes || ''}
            onChange={(e) => {
              const anoMes = e.target.value.replace('/', '')
              setData((oldState) => ({
                ...oldState,
                anoMes,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
          <AutoCompleteLotacao
            name="lotacao"
            required
            empregadorId={data?.empregadorId}
            value={data?.lotacao || null}
            onChange={(_, lotacao) => {
              const lotacaoId = lotacao?.id || null
              setData((oldState) => ({
                ...oldState,
                lotacaoId,
                lotacao,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <CurrencyTextField
            label="Base Cálculo Normal"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.vrBCCP00 || ''}
            onChange={(_, value) => {
              const vrBCCP00 = value
              setData((oldState) => ({
                ...oldState,
                vrBCCP00,
              }))
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Base Cálculo 15 anos"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.vrBCCP15 || ''}
            onChange={(_, value) => {
              const vrBCCP15 = value
              setData((oldState) => ({
                ...oldState,
                vrBCCP15,
              }))
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Base Cálculo 20 anos"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.vrBCCP20 || ''}
            onChange={(_, value) => {
              const vrBCCP20 = value
              setData((oldState) => ({
                ...oldState,
                vrBCCP20,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Base Cálculo 25 anos"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.vrBCCP25 || ''}
            onChange={(_, value) => {
              const vrBCCP25 = value
              setData((oldState) => ({
                ...oldState,
                vrBCCP25,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Base Cálculo 13º Salário"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.vrBCCP13 || ''}
            onChange={(_, value) => {
              const vrBCCP13 = value
              setData((oldState) => ({
                ...oldState,
                vrBCCP13,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Base Cálculo FGTS"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.vrBCFGTS || ''}
            onChange={(_, value) => {
              const vrBCFGTS = value
              setData((oldState) => ({
                ...oldState,
                vrBCFGTS,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Contribuição Descontada"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.vrDESCCP || ''}
            onChange={(_, value) => {
              const vrDESCCP = value
              setData((oldState) => ({
                ...oldState,
                vrDESCCP,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Recibo eSocial"
            variant="outlined"
            size="small"
            fullWidth
            disabled
            value={data?.reciboEsocial || ''}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data eSocial"
            variant="outlined"
            size="small"
            fullWidth
            disabled
            value={data?.dtReciboEsocial || null}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
