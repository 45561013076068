import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { VinculoDataRetificarDTO } from './useVinculoObterDataRetificacao'

interface RequestProps {
  data: VinculoDataRetificarDTO
  anoMes: string
}

export function useRetificarVinculo() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    await api.post('/Vinculo/RetificarVinculo', props.data, {
      params: {
        anoMes: props.anoMes,
      },
    })
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
    onSuccess: () => notification.success('Funcionário retificado com sucesso'),
  })
}
