import React, { useState, useEffect, useCallback, memo, useMemo, useContext } from 'react'

import {
  makeStyles,
  FormControlLabel,
  Checkbox,
  Popover,
  Box,
  IconButton,
  Paper,
} from '@material-ui/core'
import { PageHeader, Finder } from 'mio-library-ui'
import { Info as InfoIcon, Print as PrintIcon } from '@material-ui/icons'

import { PDFViewer } from '~/components'

import Table from './components/Table'
import Footer from './components/Footer'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useUtils from '~/hooks/useUtils'

import api from '~/services/api-pessoal'
import menuContext from '~/contexts/menu-context'
import { DRAWER_WIDTH_CLOSE, DRAWER_WIDTH_OPEN } from '../Layout/components/DrawerMenu'

const HEIGHT_FOOTER = '130px'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'right',
  },
  actionCheckBox: {
    overflow: 'auto',
    maxHeight: '40px',
  },
  actionAlert: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  haveGRFC: { color: '#e57373' },
}))

const MemoTable = memo(Table)
const MemoFooter = memo(Footer)

const RESefip = () => {
  const [collection, setCollection] = useState({
    reSefips: [],
  })
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [isNotGRFC, setNotGRFC] = useState(true)

  const dialogNotification = useDialogNotification()
  const classes = useStyles()
  const { formatCurrency } = useUtils()
  const { anoMes, estabelecimento } = useAmbiente()
  const { close: closePDFViewer, isOpen: isOpenPDFViewer, open: openPDFViewer } = useDialog()

  const isOpenMenu = useContext(menuContext)

  useEffect(() => {
    const getCollection = async () => {
      setLoading(true)
      try {
        const response = await api.get('RESefip/GetRelacaoEmpregados', {
          params: {
            estabelecimentoId: estabelecimento?.id,
            anoMes,
          },
        })
        if (response?.data?.data) {
          setCollection(response.data.data)
        } else {
          setCollection({ reSefips: [] })
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    //eslint-disable-next-line
  }, [anoMes, estabelecimento])

  const valuesForFooter = useMemo(() => {
    let totalBaseINSS = 0
    let totalValorINSS = 0

    let totalBaseFGTS = 0
    let totalValorFGTS = 0

    let totalBaseFGTSAviso = 0
    let totalValorFGTSAviso = 0

    let totalBaseINSS13 = 0
    let totalValorINSS13 = 0

    let totalBaseFGTS13 = 0
    let totalValorFGTS13 = 0

    let newCollection = []

    if (isNotGRFC) {
      collection.reSefips.forEach((item) => {
        if (!item.grfc) newCollection.push(item)
      })
    } else {
      newCollection = collection.reSefips
    }

    newCollection.forEach((item) => {
      totalBaseINSS = totalBaseINSS + item.vrBaseINSS
      totalValorINSS = totalValorINSS + item.vrINSS
      totalBaseFGTS = totalBaseFGTS + item.vrBaseFGTS
      totalBaseFGTSAviso = totalBaseFGTSAviso + item.vrBaseFGTSAvisoPrevio
      totalValorFGTSAviso = totalValorFGTSAviso + item.vrFGTSAvisoPrevio
      totalValorFGTS = totalValorFGTS + item.vrFGTS
      totalBaseINSS13 = totalBaseINSS13 + item.vrBaseINSS13
      totalValorINSS13 = totalValorINSS13 + item.vrINSS13
      totalBaseFGTS13 = totalBaseFGTS13 + item.vrBaseFGTS13
      totalValorFGTS13 = totalValorFGTS13 + item.vrFGTS13
    })

    return {
      totalBaseINSS: formatCurrency(totalBaseINSS),
      totalValorINSS: formatCurrency(totalValorINSS),
      totalBaseFGTS: formatCurrency(totalBaseFGTS),
      totalValorFGTS: formatCurrency(totalValorFGTS),
      totalBaseINSS13: formatCurrency(totalBaseINSS13),
      totalValorINSS13: formatCurrency(totalValorINSS13),
      totalBaseFGTS13: formatCurrency(totalBaseFGTS13),
      totalValorFGTS13: formatCurrency(totalValorFGTS13),
      totalBaseFGTSAviso: formatCurrency(totalBaseFGTSAviso),
      totalValorFGTSAviso: formatCurrency(totalValorFGTSAviso),
    }
  }, [collection.reSefips, isNotGRFC, formatCurrency])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box height="100%" width="100%" p={2}>
      <Box id="root-header" height="60px">
        <PageHeader title="RE Sefip">
          <Box className={classes.actions}>
            <Box className={classes.actionAlert}>
              <InfoIcon
                size="small"
                color="primary"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
            </Box>

            <Box className={classes.actionCheckBox}>
              <FormControlLabel
                label="Não considerar para o somatório de FGTS os funcionários com GRFC"
                control={
                  <Checkbox
                    checked={Boolean(isNotGRFC)}
                    onChange={(e) => {
                      setNotGRFC(e.target.checked)
                    }}
                    color="secondary"
                    size="small"
                  />
                }
              />
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" px={1}>
              <IconButton size="small" color="primary" onClick={openPDFViewer}>
                <PrintIcon />
              </IconButton>
            </Box>

            <Box>
              <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
            </Box>
          </Box>
        </PageHeader>
      </Box>

      <Box
        paddingBottom={HEIGHT_FOOTER}
        width={
          isOpenMenu
            ? `calc(100vw - (${DRAWER_WIDTH_OPEN} + 50px))`
            : `calc(100vw - (${DRAWER_WIDTH_CLOSE} + 50px))`
        }
        overflow="auto"
      >
        <MemoTable data={collection.reSefips} isLoading={isLoading} query={query} />
      </Box>

      <Box
        component={Paper}
        id="root-footer"
        height={HEIGHT_FOOTER}
        position="fixed"
        bottom={0}
        right={0}
        left={isOpenMenu ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE}
        pt={1}
        pr={1}
        pl={1}
        pb={1}
      >
        <MemoFooter totais={valuesForFooter} />
      </Box>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        Funcionários que possuírem <div className={classes.haveGRFC}>GRFC</div> terão destaque na
        tabela.
      </Popover>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="RESefip"
        axiosConfig={{
          url: '/RESefip/ObterRelatorio',
          method: 'post',
          data: collection,
        }}
      />
    </Box>
  )
}

export default RESefip
