import { useState, useEffect, useCallback } from 'react'

import _ from 'lodash'
import moment from 'moment'
import { formatToBRL } from 'brazilian-values'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { ContentDivider, ButtonBox } from 'mio-library-ui'

import { ActionMenu, CollapseContent, ContentFrame } from '~/components'

import Table from './Table'

import useUtils from '~/hooks/useUtils'
import useOnlyDataC from '~/hooks/useOnlyDataC'
import { IndTabelaIRValues } from '~/@types/enums/IndTabelaIREnum'

const CollapsableListYears = (props) => {
  const {
    isLoading,
    ano,
    itens,
    collapseItens,
    setCollapseItens,
    objectInDataForTable,
    columns,
    onClickEdit,
    onClickRemove,
  } = props
  const [data, setData] = useState([])
  const { formatMonth } = useUtils()
  const { isUserDataC } = useOnlyDataC()

  useEffect(() => {
    const makeData = () => {
      const newList = []

      const maiorAno = _.maxBy(itens, (o) => o.anoMes.substr(0, 4)).anoMes.substr(0, 4)
      const menorAno = _.minBy(itens, (o) => o.anoMes.substr(0, 4)).anoMes.substr(0, 4)

      //Montando a lista com todos os anos e seus meses
      for (let index = maiorAno; index >= menorAno; index--) {
        let listMonths = []
        itens.forEach((obj) => {
          if (moment(obj?.anoMes).format('YYYY') === index.toString()) {
            listMonths.push(obj)
          }
        })
        const listMonthsOrdained = _.orderBy(listMonths, 'anoMes', 'desc')
        newList.push({ ano: index.toString(), listMonthsOrdained })
      }

      let listMounted = []

      //Monta a lista caso n tenha atingido os limites maior ou menor
      if (ano >= menorAno && ano <= maiorAno) {
        listMounted = []
        newList.forEach((obj) => {
          if (obj?.ano <= ano && obj?.ano > ano - 5) {
            listMounted.push(obj)
          }
        })
      }

      //Monta uma lista caso ultrapasse o maiorAno
      if (ano > maiorAno) {
        listMounted = []
        newList.forEach((obj) => {
          if (obj?.ano <= maiorAno && obj?.ano > maiorAno - 5) {
            listMounted.push(obj)
          }
        })
      }

      //Monta uma lista caso atinga o Menor ano
      if (ano - 5 < menorAno) {
        listMounted = []
        newList.forEach((obj) => {
          if (obj?.ano >= menorAno && obj?.ano < parseInt(menorAno) + parseInt(5)) {
            listMounted.push(obj)
          }
        })
      }

      setCollapseItens([])

      const arrayCollapse = listMounted.map((obj, index) => {
        if (index === 0) {
          return true
        }
        return false
      })

      setCollapseItens(arrayCollapse)

      setData(listMounted)
    }

    if (itens.length > 0) {
      makeData()
    }
    // eslint-disable-next-line
  }, [itens, ano])

  const makeData = useCallback(
    (obj) => {
      if (objectInDataForTable) {
        return objectInDataForTable(obj)
      }

      return [obj]
    },
    [objectInDataForTable],
  )

  if (isLoading) return <CircularProgress color="secondary" size={22}></CircularProgress>

  if (data.length <= 0) return <div>Nenhum registro encontrado</div>

  return (
    <ContentFrame top={1}>
      {data.map((objAno, index) => {
        return (
          <CollapseContent
            key={objAno?.ano}
            title={objAno?.ano}
            isOpen={collapseItens[index]}
            onClick={() => {
              const newArray = collapseItens.map((i, _index) => {
                if (index === _index) {
                  return !i
                }
                return i
              })
              setCollapseItens(newArray)
            }}
            top={1}
          >
            {objAno?.listMonthsOrdained?.length === 0 ? (
              <Typography>Não há registros para esse ano</Typography>
            ) : (
              objAno?.listMonthsOrdained?.map((obj) => {
                return (
                  <CollapseContent
                    key={obj?.id}
                    title={formatMonth(obj?.anoMes.substr(4, 2))}
                    isOpen={
                      objAno?.listMonthsOrdained[0] === obj && data[0] === objAno ? true : false
                    }
                    top={1}
                    renderRight={
                      isUserDataC ? (
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          width="100%"
                          paddingBottom={1}
                        >
                          <ActionMenu
                            options={[
                              {
                                icon: 'edit',
                                onClick: () => onClickEdit(obj),
                                label: 'Editar',
                              },
                              {
                                icon: 'close',
                                onClick: () => onClickRemove(obj?.id),
                                label: 'Remover',
                                severity: 'danger',
                              },
                            ]}
                          />
                        </Box>
                      ) : undefined
                    }
                  >
                    {obj?.indTabelaIR && (
                      <ContentDivider
                        title={
                          IndTabelaIRValues.find((d) => d.value === obj?.indTabelaIR)?.name ||
                          'Tabela'
                        }
                        showDivider={false}
                        bottom={1}
                      />
                    )}
                    <Table data={makeData(obj)} columns={columns} />
                    {obj?.indTabelaIR && (
                      <ButtonBox top={1}>
                        <ContentDivider
                          title={`Dedução por Depedente ${formatToBRL(obj?.vrDeducaoDependente)}`}
                          showDivider={false}
                        />
                      </ButtonBox>
                    )}
                  </CollapseContent>
                )
              })
            )}
          </CollapseContent>
        )
      })}
    </ContentFrame>
  )
}

CollapsableListYears.defaultProps = {
  itens: [],
}

export default CollapsableListYears
