import { convertToArrayDescription } from '~/utils/utils'

export enum CodigoReceitaIRRFProcessoTrabalhistaEnum {
  IRRF_DECISAO_JUSTICA_TRABALHO_593656 = '593656',
  IRRF_CCP_INTER_056152 = '056152',
  IRRF_RRA_188951 = '188951',
}

export const CodigoReceitaIRRFProcessoTrabalhistaLabels = {
  [CodigoReceitaIRRFProcessoTrabalhistaEnum.IRRF_DECISAO_JUSTICA_TRABALHO_593656]:
    '593656 - IRRF - Decisão da Justiça do Trabalho',
  [CodigoReceitaIRRFProcessoTrabalhistaEnum.IRRF_CCP_INTER_056152]: '056152 - IRRF - CCP/NINTER',
  [CodigoReceitaIRRFProcessoTrabalhistaEnum.IRRF_RRA_188951]: '188951 - IRRF - RRA',
}

export const CodigoReceitaIRRFProcessoTrabalhistaValues = convertToArrayDescription(
  CodigoReceitaIRRFProcessoTrabalhistaEnum,
  CodigoReceitaIRRFProcessoTrabalhistaLabels,
)
