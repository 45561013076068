import { useState } from 'react'

import * as yup from 'yup'
import moment from 'moment'
import { isCPF } from 'brazilian-values'

import { Grid, Container as MUIContainer, Paper, makeStyles } from '@material-ui/core'

import {
  PageHeader,
  Container,
  ButtonBox,
  Button,
  DatePickerNew,
  NumeroInscricaoTextField,
} from '~/components'
import { AutoCompleteEmpregador, AutoCompleteSociosResponsaveis } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useDIRFGerarArquivo } from '~/hooks/queries/DIRF/useDIRFGerarArquivo'

import { Vinculo } from '~/hooks/queries/Vinculo/Vinculo'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))

interface DataProps {
  empregadorId: string | null
  anoBase: number | null
  socioResponsavelId: string | null
  socioResponsavel: Vinculo | null
  nrInscricaoResponsavelArquivo: string | null
}

const dataInitial: DataProps = {
  empregadorId: null,
  anoBase: null,
  socioResponsavelId: null,
  socioResponsavel: null,
  nrInscricaoResponsavelArquivo: null,
}

const schema = yup.object().shape({
  empregadorId: yup.string().required('Informe o Empregador').nullable(),
  anoBase: yup.number().required('Informe o Ano Base').nullable(),
  socioResponsavelId: yup.string().required('Informe o Sócio Responsável pela Empresa').nullable(),
  nrInscricaoResponsavelArquivo: yup
    .string()
    .required('Informe o CPF do Responsável pelo Arquivo')
    .test({
      message: 'Informe um CPF válido',
      test: (cpf) => isCPF(cpf),
    })
    .nullable(),
})

export default function DIRF() {
  const [data, setData] = useState<DataProps>(dataInitial)

  const { mutateAsync, isLoading } = useDIRFGerarArquivo()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: onSubmit,
    data,
  })
  const classes = useStyles()

  function onReset() {
    setData(dataInitial)
  }

  async function onSubmit() {
    await mutateAsync({
      empregadorId: data.empregadorId || '',
      anoBase: data.anoBase || 0,
      nrInscricaoSocioResponsavel: data.socioResponsavel?.pessoaFisica?.nrInscricao || '',
      nrInscricaoResponsavelArquivo: data.nrInscricaoResponsavelArquivo || '',
    })
    onReset()
  }

  return (
    <Container>
      <PageHeader title="DIRF" />
      <MUIContainer maxWidth="sm">
        <Paper className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoCompleteEmpregador
                required
                validationErrors={validationErrors}
                name="empregadorId"
                value={data.empregadorId}
                onChange={(_, obj) => {
                  const empregadorId = obj?.id || null
                  setData({
                    ...dataInitial,
                    empregadorId: empregadorId,
                  })
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <DatePickerNew
                required
                validationErrors={validationErrors}
                name="anoBase"
                format="yyyy"
                views={['year']}
                label="Ano Base"
                value={data.anoBase ? moment(data.anoBase, 'yyyy').format('yyyy-MM-DD') : null}
                onChange={(date, dateMoment) => {
                  const anoBase = dateMoment ? parseInt(dateMoment.format('yyyy')) : null
                  setData((prev) => ({
                    ...prev,
                    anoBase,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AutoCompleteSociosResponsaveis
                required
                validationErrors={validationErrors}
                name="socioResponsavelId"
                empregadorId={data.empregadorId}
                value={data.socioResponsavelId}
                onChange={(socioResponsavel) => {
                  const socioResponsavelId = socioResponsavel?.id || null
                  setData((prev) => ({
                    ...prev,
                    socioResponsavel,
                    socioResponsavelId,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <NumeroInscricaoTextField
                required
                validationErrors={validationErrors}
                typeMask={TipoInscricaoEnum.CPF_2}
                name="nrInscricaoResponsavelArquivo"
                label="CPF do Responsável pelo Arquivo"
                value={data.nrInscricaoResponsavelArquivo || ''}
                onChange={(e, value) => {
                  const nrInscricaoResponsavelArquivo = value || null
                  setData((prev) => ({
                    ...prev,
                    nrInscricaoResponsavelArquivo,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonBox>
                <Button onClick={onReset}>Cancelar</Button>
                <Button isLoading={isLoading} onClick={handleValidate} variant="contained">
                  Gerar DIRF
                </Button>
              </ButtonBox>
            </Grid>
          </Grid>
        </Paper>
      </MUIContainer>
    </Container>
  )
}
