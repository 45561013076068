import React from 'react'

interface TabPanelProps {
  children: React.ReactNode
  value: number
  index: number
  style?: React.CSSProperties
  keepMount?: boolean
}

export default function TabPanel({
  children,
  value,
  index,
  style,
  keepMount = false,
}: TabPanelProps) {
  return (
    <div
      style={{
        display: keepMount ? (value === index ? 'initial' : 'none') : 'initial',
        ...style,
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {keepMount ? children : value === index && children}
    </div>
  )
}
