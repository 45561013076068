import React, { useMemo } from 'react'

import { ToolsDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

export default function Table(props) {
  const { data: _data, isLoading, query, rowsSelected, handleRowSelected } = props

  const { formatCurrency } = useUtils()

  const data = _data.map((d) => ({
    ...d,
    dtAdmissao: d?.dtAdmissao ? moment(d.dtAdmissao).format('DD/MM/yyyy') : '',
    vrAdiantamentoSalario: d?.vrAdiantamentoSalario
      ? formatCurrency(d.vrAdiantamentoSalario)
      : '0,00',
  }))

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'dtAdmissao',
        label: 'Data de Admissão',
      },
      {
        name: 'vrAdiantamentoSalario',
        label: 'Valor Adiantamento',
        options: {
          setCellProps: () => ({
            style: { textAlign: 'end' },
          }),
        },
      },
      {
        name: 'aliqAdiantamentoSalario',
        label: 'Aliq. Adiantamento',
        options: {
          setCellProps: () => ({
            style: { textAlign: 'end' },
          }),
          customBodyRender: (value) => (value || 0) + ' %',
        },
      },
    ],
    [],
  )

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      optionsSelectable={{
        selectOnClick: true,
        type: 'multiple',
        rowsSelected: rowsSelected,
        onRowSelected: handleRowSelected,
      }}
      sherlock={{
        columns: ['nome', 'dtAdmissao'],
        query,
      }}
    />
  )
}
