const tabelaValeTransporte_1 = 1
const tabelaDiasUteis_2 = 2
const percentualEstipulad_3 = 3

export const sindicatoValeTransporteBaseDescontoValues = [
  { value: tabelaValeTransporte_1, name: '1 - Vale Transporte' },
  { value: tabelaDiasUteis_2, name: '2 - Dias Úteis' },
  { value: percentualEstipulad_3, name: '3 - Percentual Estipulado' },
]

export const sindicatoValeTransporteBaseDescontoConsts = {
  tabelaValeTransporte_1,
  tabelaDiasUteis_2,
  percentualEstipulad_3,
}
