import React, { useState, useEffect } from 'react'

import { Popover, Divider, makeStyles } from '@material-ui/core'

import clsx from 'clsx'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    background: '#00000066',
  },
  popoverPaper: {
    padding: theme.spacing(1),
  },
  highlight: {
    cursor: 'pointer',
    '& *': {
      // fontSize: '11pt',
      fontWeight: 'bolder',
    },
  },
  title: {
    fontWeight: 'bolder',
  },
  messagesListContainer: {
    marginTop: theme.spacing(1),
  },
}))

const extractMessages = (memorias, propriedade) => {
  if (!memorias) return []
  const memoria = memorias.find((m) => {
    return m.propriedade === propriedade
  })

  return memoria?.mensagens || []
}

const Message = ({ message }) => {
  const classes = useStyles()
  return (
    <Typography variant="body2" className={classes.messageContainer}>
      {message}
    </Typography>
  )
}

const MemoriaCalculo = (props) => {
  const { children, memoria, propriedade, highlight, title } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [messages, setMessages] = useState([])

  useEffect(() => {
    if (memoria) {
      const m = extractMessages(memoria, propriedade)
      setMessages(m)
    }
  }, [memoria, propriedade])

  const handlePopoverOpen = (event) => {
    if (highlight && messages.length > 0) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handlePopoverClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        className={clsx({
          [classes.highlight]: highlight && messages.length > 0,
        })}
        onClick={handlePopoverOpen}
      >
        {children}
      </div>

      <Popover
        id={`memcal-${propriedade}`}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popoverPaper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <div>
          {title && (
            <>
              <Typography className={classes.title} variant="subtitle2">
                {title}
              </Typography>
              <Divider />
            </>
          )}
          <div className={classes.messagesListContainer}>
            {messages.map((m, i) => (
              <Message key={`id-key-${i}`} message={m} />
            ))}
          </div>
        </div>
      </Popover>
    </>
  )
}

MemoriaCalculo.defaultProps = {
  highlight: false,
  showDivider: true,
}

export default MemoriaCalculo
