import React, { useState } from 'react'

import { Box, Grid, LinearProgress, useTheme } from '@material-ui/core'

import { DatePickerNew } from '~/components'

import CardFuncionario from './CardFuncionario'
import InputAcordoDissidio from './InputAcordoDissidio'

import { DataForm } from '..'

import useDialogNotification from '~/hooks/useDialogNotification'

import obterCalculoFuncionarioRescisao from '~/hooks/queries/RescisaoComplementar/obterCalculoFuncionarioRescisao'

interface FormProps {
  data: DataForm
  setData: React.Dispatch<React.SetStateAction<DataForm>>
  validationErrors: ValidationErrors
  isFuncionarioSelected: boolean
}

export default function Form(props: FormProps) {
  const { data, setData, validationErrors, isFuncionarioSelected } = props

  const [isLoading, setLoading] = useState(false)

  const theme = useTheme()
  const dialogNotification = useDialogNotification()

  async function onChangeFuncionario(funcionarioId: string) {
    setLoading(true)
    try {
      const response = await obterCalculoFuncionarioRescisao(funcionarioId)
      setData((prev) => ({
        ...prev,
        funcionario: response,
      }))
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        style={{
          marginBottom: theme.spacing(1),
        }}
      >
        <Box height={4}>{isLoading && <LinearProgress />}</Box>
        <CardFuncionario
          value={data.funcionario}
          onChange={(obj) => {
            const funcionario = obj
            if (!funcionario) return
            onChangeFuncionario(funcionario?.id)
          }}
          required
          name="funcionario"
          validationErrors={validationErrors}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <DatePickerNew
          label="Data da Rescisão Complementar"
          value={data.dtRescisaoComplementar || null}
          onChange={(date) => {
            const dtRescisaoComplementar = date
            const dtRescisaoComplementarPagamento = date
            setData((prev) => ({
              ...prev,
              dtRescisaoComplementar,
              dtRescisaoComplementarPagamento,
            }))
          }}
          disabled={!isFuncionarioSelected}
          required
          name="dtRescisaoComplementar"
          validationErrors={validationErrors}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <DatePickerNew
          label="Data de Pagamento"
          value={data.dtRescisaoComplementarPagamento || null}
          onChange={(date) => {
            const dtRescisaoComplementarPagamento = date
            setData((prev) => ({
              ...prev,
              dtRescisaoComplementarPagamento,
            }))
          }}
          disabled={!isFuncionarioSelected}
          required
          name="dtRescisaoComplementarPagamento"
          validationErrors={validationErrors}
        />
      </Grid>

      <Grid item xs={12}>
        <InputAcordoDissidio
          value={data.acordoDissidioColetivo}
          onChange={(obj) => {
            const acordoDissidioColetivo = obj
            setData((prev) => ({
              ...prev,
              acordoDissidioColetivo,
            }))
          }}
          disabled={!isFuncionarioSelected}
          required
          name="acordoDissidioColetivo"
          validationErrors={validationErrors}
        />
      </Grid>
    </Grid>
  )
}
