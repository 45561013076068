import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryUseEmpregadorEsocialObterTodos } from './useEmpregadorEsocialObterTodos'
import { EmpregadorEsocial } from './EmpregadorEsocial'

export function useAtualizarEmpregadoresEsocial() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: EmpregadorEsocial[]) {
    await api.put('/EmpregadorEsocial/AtualizarEmpregadoresEsocial', data)
    notification.success('Eventos configurados com sucesso')
  }

  return useMutation(handleRequest, {
    onSuccess: async () => await queryClient.invalidateQueries(queryUseEmpregadorEsocialObterTodos),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
