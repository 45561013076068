const CRM_1 = 1
const CREA_4 = 4
const outros_9 = 9

export const indOrgaoClasseValues = [
  { value: CRM_1, name: '1 - Conselho Regional de Medicina - CRM' },
  {
    value: CREA_4,
    name: '4 - Conselho Regional de Engenharia e Agronomia - CREA',
  },
  { value: outros_9, name: '9 - Outros' },
]

export const indOrgaoClasseConsts = {
  CRM_1,
  CREA_4,
  outros_9,
}
