import React, { useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import { ConfirmDeleteDialog, PDFViewer, TreeView } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import { CATTipoLocalConsts } from '~/values/CATTipoLocalValues'
import { tipoCATConsts } from '~/values/tipoCATValues'
import { CATIniciativaConsts } from '~/values/CATIniciativaValues'

const HEADER_HEIGHT = '70px'

const ComunicadoAcidenteTrabalho = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [form, setForm] = useState({
    isOpen: false,
    isRetificar: false,
    data: {},
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [query, setQuery] = useState('')
  const [vinculo, setVinculo] = useState({})

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])

  const getCollectionByVinculo = async (vinculoId) => {
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get('/ComunicadoAcidenteTrabalho/GetByVinculo', {
        params: {
          vinculoId,
        },
      })
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
  }

  useEffect(() => {
    setVinculo({})
    setCollection({
      isLoading: false,
      itens: [],
    })
  }, [anoMes, estabelecimento])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleOpenForm = useCallback(
    (
      data = {
        vinculoId: vinculo?.id,
        tipoInscricao: tipoInscricaoConsts.CNPJ_1,
        nrInscricao: estabelecimento?.nrInscricao,
        tipoLocal: CATTipoLocalConsts.estabelecimentoEmpregadorBrasil_1,
        tipoCAT: tipoCATConsts.inicial_1,
        iniciativaCAT: CATIniciativaConsts.empregador_1,
      },
      isRetificar,
    ) => {
      setForm({
        isOpen: true,
        data,
        isRetificar,
      })
    },
    [vinculo, estabelecimento],
  )

  const handleClickItem = (event, value) => {
    const handleClickEditItem = (id) => {
      const { itens } = collection
      const item = itens.find((i) => i.id === id)

      if (item.reciboEsocial)
        return dialogNotification.warning({
          descriptions: [
            'Esse evento já foi enviado para o eSocial, deseja fazer uma retificação?',
          ],
          labelOnCancel: 'Não',
          labelOnConfirm: 'Sim',
          onConfirm: () => handleOpenForm(item, true),
          onCancel: () => handleOpenForm(item, false),
        })
      handleOpenForm(item)
    }

    const handleClickDeleteItem = (id) => {
      setConfirmDeleteDialog((oldState) => ({
        ...oldState,
        isOpen: true,
        id,
      }))
    }

    function handleClickPrint(id) {
      openPDFViewer(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
      print: handleClickPrint,
    }
    functions[event](value)
  }

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/ComunicadoAcidenteTrabalho/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [handleOpenForm])

  const handleCloseForm = useCallback(() => {
    setForm({
      data: {},
      isOpen: false,
      isRetificar: false,
    })
  }, [])

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection, handleCloseForm],
  )

  async function handleGetVinculo(vinculo) {
    const vinculoId = vinculo?.vinculoId
    if (!vinculoId) return
    try {
      const response = await api.get(`Vinculo/${vinculoId}`)
      const data = response?.data?.data

      setVinculo({
        id: data.id,
        nome: data?.pessoaFisica?.nome,
        dtAdmissao: data?.dtAdmissao,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => {
        getCollectionByVinculo(vinculo.vinculoId)
        handleGetVinculo(vinculo)
      }}
      searchBy="vinculoNome"
      renderOption={(option) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
      isLoadingOnClick={collection.isLoading}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Comunicado Acidente de Trabalho (CAT)" subtitle={vinculo?.nome || '-'}>
          {vinculo?.id && (
            <ButtonBox>
              <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
              <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
                Adicionar
              </Button>
            </ButtonBox>
          )}
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          query={query}
          onItemClick={handleClickItem}
        />
      </Box>

      <Form
        itens={collection.itens}
        vinculo={vinculo}
        isOpen={form.isOpen}
        data={form.data}
        isRetificar={form.isRetificar}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="CAT"
        title="Comunicado Acidente de Trabalho (CAT)"
        axiosConfig={{
          method: 'get',
          url: '/ComunicadoAcidenteTrabalho/ObterRelatorio',
          params: {
            catId: dataPDFViewer,
          },
        }}
      />
    </TreeView>
  )
}

export default ComunicadoAcidenteTrabalho
