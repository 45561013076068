import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew, TextField, MultipleSelect, Checkbox } from '~/components'

import {
  DescontosPossiveisEnum,
  DescontosPossiveisValues,
} from '~/@types/enums/DescontosPossiveisEnum'
import { AutorizacaoDescontosFormConfirm } from '../../'
import { getDateCurrent } from '~/utils/utils'

interface AutorizacaoDescontosForm
  extends Omit<AutorizacaoDescontosFormConfirm, 'descontosImprimir'> {
  descontosImprimir: { value: DescontosPossiveisEnum; name: string }[]
}

const initialState: AutorizacaoDescontosForm = {
  dtAssinatura: getDateCurrent(),
  outrosEspecificacao: '',
  descontosImprimir: [],
}

interface DialogConfirmProps {
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: (dt: AutorizacaoDescontosFormConfirm) => void
}

export default function DialogConfirm(props: DialogConfirmProps) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState<AutorizacaoDescontosForm>(initialState)
  const [isConsiderDtAdmissao, setConsiderDtAdmissao] = useState(false)

  function handleSubmit() {
    onAfterSubmit({
      ...data,
      descontosImprimir: data.descontosImprimir.map((d) => d.value),
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Informe dados para a Autorização de Descontos"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MultipleSelect
            inputProps={{
              label: 'Descontos para serem impressos no relatório',
            }}
            getOptionLabel={(option) => option.name}
            options={DescontosPossiveisValues}
            value={data.descontosImprimir}
            onChange={(e, selecteds) =>
              setData((prev) => ({ ...prev, descontosImprimir: selecteds }))
            }
            limitTags={5}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Outros Descontos"
            value={data.outrosEspecificacao}
            multiline
            inputProps={{ maxLength: 255 }}
            onChange={(e) => {
              const outrosEspecificacao = e.target.value
              setData((prev) => ({
                ...prev,
                outrosEspecificacao,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Checkbox
            label="Utilizar a Data de Admissão dos Funcionários como Data de Assinatura"
            value={isConsiderDtAdmissao}
            onChange={(e, value) => {
              if (value) {
                setData((prev) => ({
                  ...prev,
                  dtAssinatura: null,
                }))
              } else {
                setData((prev) => ({
                  ...prev,
                  dtAssinatura: initialState.dtAssinatura,
                }))
              }
              setConsiderDtAdmissao(value)
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Data da Assinatura"
            value={data.dtAssinatura}
            disabled={isConsiderDtAdmissao}
            onChange={(date) => {
              const dtAssinatura = date
              setData((prevState) => ({ ...prevState, dtAssinatura }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
