import { useState } from 'react'

import { Typography } from '@material-ui/core'

import { ActionDialog, Stack } from '~/components'
import { AutoCompleteEstabelecimento } from '~/components/AutoComplete'

import { useCopiarConfiguracoes } from '~/hooks/queries/LancamentoLiquidacaoConfig/useCopiarConfiguracoes'

import useAmbiente from '~/hooks/useAmbiente'

interface DialogCopyConfigsProps {
  onClose: () => void
  modeloIntegracaoId: string
}

export default function DialogCopyConfigs({ onClose, modeloIntegracaoId }: DialogCopyConfigsProps) {
  const [estabelecimentoOrigemId, setEstabelecimentoOrigemId] = useState('')

  const { estabelecimento } = useAmbiente()

  const { mutateAsync, isLoading: isSubmtting } = useCopiarConfiguracoes()

  const estabelecimentoDestinoId = estabelecimento.id

  async function handleSubmit() {
    await mutateAsync({
      params: { modeloIntegracaoId, estabelecimentoDestinoId, estabelecimentoOrigemId },
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Copiar Configurações"
      isOpen={true}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      okLabel="Processar"
      onOkClick={() => handleSubmit()}
      isOkProcessing={isSubmtting}
    >
      <Stack>
        <Typography>Selecione o estabelecimento para copiar as configurações.</Typography>
        <AutoCompleteEstabelecimento
          label="Estabelecimento Origem"
          value={estabelecimentoOrigemId}
          onChange={(e: any, obj: any) => setEstabelecimentoOrigemId(obj?.id || '')}
          optionId="id"
          required
          getOptionDisabled={(option: any) => option.id === estabelecimentoDestinoId}
        />
      </Stack>
    </ActionDialog>
  )
}
