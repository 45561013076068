import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterCustosPlanoSaudeTitular'

import { notifyPost } from '~/utils/notification'
import { PlanoSaudeTitularCusto } from './dtos/PlanoSaudeTitularCusto'

export function useCriar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest(data: PlanoSaudeTitularCusto) {
    await api.post('/PlanoSaudeTitularCusto', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
