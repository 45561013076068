import { Box, Divider, Typography } from '@material-ui/core'

export default function NotificationHeader() {
  return (
    <Box>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        Notificações
      </Typography>
      <Divider />
    </Box>
  )
}
