import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestParams {
  rpId: string
}

export function useRemoverRP() {
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  async function handleRequest(params: RequestParams) {
    await api.delete('/RP/RemoverRP', {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Recibo removido com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
