import { useState } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog, Checkbox, TextField } from '~/components'

import { useAdicionar } from '~/hooks/queries/ModeloIntegracao/useAdicionar'
import { useAtualizar } from '~/hooks/queries/ModeloIntegracao/useAtualizar'

import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'
import { MUIAutoComplete } from '~/components/AutoComplete'
import { LayoutEnum, LayoutValues } from '~/@types/enums/LayoutEnum'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  mascaraConta: yup.string().required('Informe a Máscara'),
})

interface FormProps {
  isOpen: boolean
  data: ModeloIntegracao | null
  onClose: () => void
}

export default function Form({ isOpen, onClose, data: _data }: FormProps) {
  const [data, setData] = useState(
    _data ||
      ({
        layoutTipo: LayoutEnum.DataC,
      } as ModeloIntegracao),
  )

  const { mutateAsync: mutateAsyncAdicionar, isLoading: _isLoadingAdicionar } = useAdicionar()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: _isLoadingAtualizar } = useAtualizar()

  const isLoading = _isLoadingAdicionar || _isLoadingAtualizar

  const handleSubmit = async () => {
    if (_data) {
      await mutateAsyncAtualizar({
        data,
        params: {
          id: data.id,
        },
      })
    } else {
      await mutateAsyncAdicionar({
        data,
      })
    }
    onClose()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Modelo de Integração"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        {_data && (
          <Grid item xs={12}>
            <TextField fullWidth size="small" label="Código" value={data?.codigo || ''} disabled />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            required
            label="Nome"
            name="nome"
            autoFocus
            inputProps={{
              maxLength: 80,
            }}
            value={data?.nome || ''}
            validationErrors={validationErrors}
            onChange={(e) => {
              const nome = e?.target?.value || ''
              setData((prev) => ({ ...prev, nome }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            label="Máscara"
            name="mascaraConta"
            inputProps={{
              maxLength: 24,
            }}
            value={data?.mascaraConta || ''}
            validationErrors={validationErrors}
            onChange={(e) => {
              const mascaraConta = e?.target?.value || ''
              setData((prev) => ({ ...prev, mascaraConta }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIAutoComplete
            label="Layout"
            optionId="value"
            options={LayoutValues}
            renderOption={(option) => option.name}
            value={data?.layoutTipo}
            onChange={(_, obj) => {
              const layoutTipo = obj?.value || LayoutEnum.DataC
              setData((prev) => ({
                ...prev,
                layoutTipo,
              }))
            }}
          />
        </Grid>

        {_data && (
          <Grid item xs={12}>
            <Checkbox
              label="Ativo"
              value={data.ativo}
              onChange={(e, value) => {
                const ativo = value
                setData((prev) => ({ ...prev, ativo }))
              }}
            />
          </Grid>
        )}
      </Grid>
    </ActionDialog>
  )
}
