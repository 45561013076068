import React, { useState } from 'react'

import { Table, TableContainer, Paper, Box, makeStyles, LinearProgress } from '@material-ui/core'

import MUITableHead from './components/MUITableHead'
import MUITableBody from './components/MUITableBody'
import MUITablePagination from './components/MUITablePagination'

import { getDeepDynamicPropValue } from '~/hooks/useUtils'

function sherlockSearch(props: { sherlock?: Sherlock; data: any[] }) {
  const { sherlock, data } = props
  if (!sherlock) return data
  return sherlock.query.length > 0
    ? data.filter((d) =>
        sherlock.columns.some((columnName) =>
          getDeepDynamicPropValue(d, columnName)
            .toString()
            .toLowerCase()
            .includes(sherlock.query.toLowerCase()),
        ),
      )
    : data
}

interface UseStylesProps {
  heightFooter: string
}

const useStyles = makeStyles(() => ({
  paperRoot: {
    height: '100%',
  },
  tableContainer: {
    height: (props: UseStylesProps) => `calc(100% - ${props.heightFooter})`,
  },
  tableRoot: {
    borderBottom: '1px solid #D0D0D0',
  },
}))

export default function MUITableTwoRows(props: MUITableTwoRowsProps) {
  const { columns, groupColumns, options, data, isLoading, isFetching, pagination = true } = props

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const heightFooter = pagination ? '52px' : '0'

  const useStylesProps: UseStylesProps = {
    heightFooter,
  }

  const classes = useStyles(useStylesProps)

  let displayData = sherlockSearch({ sherlock: options?.sherlock, data })
  displayData = displayData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <Paper className={classes.paperRoot}>
      <Box height={4}>{isFetching && <LinearProgress />}</Box>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.tableRoot} stickyHeader aria-label="mui-table-two-rows">
          <MUITableHead
            columns={columns}
            options={options}
            data={data}
            groupColumns={groupColumns}
          />
          <MUITableBody
            displayData={displayData}
            data={data}
            columns={columns}
            isLoading={isLoading}
            options={options}
          />
        </Table>
      </TableContainer>
      {pagination ? (
        <Box height={heightFooter}>
          <MUITablePagination
            data={data}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
      ) : (
        <></>
      )}
    </Paper>
  )
}
