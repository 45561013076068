import { Box, Grid, makeStyles } from '@material-ui/core'

import { Checkbox } from '~/components'

import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'

const useStyles = makeStyles((theme: FixLater) => ({
  root: {
    padding: theme.spacing(2),
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    gap: theme.spacing(1),
  },
}))

interface CardEventosProps {
  eventos: ESocialEventoEnum[]
  label: string
  value: ESocialEventoEnum[]
  onChange: (eventos: ESocialEventoEnum[]) => void
}

export default function CardEventos(props: CardEventosProps) {
  const { eventos, label, value, onChange } = props

  const classes = useStyles()

  function handleToggleEvento(evento: ESocialEventoEnum) {
    if (value.includes(evento)) {
      const eventosSelecionadoFiltred = value.filter((eventoCurrent) => eventoCurrent !== evento)
      onChange(eventosSelecionadoFiltred)
      return
    }
    onChange([...value, evento])
  }

  function handleToggleAll() {
    if (value.some((evento) => eventos.includes(evento))) {
      const eventosToUpdate = value.filter((evento) => !eventos.includes(evento))
      onChange(eventosToUpdate)
      return
    }
    onChange([...value, ...eventos])
  }

  const isAllSelected = eventos.every((d) => value.includes(d))

  return (
    <Box className={classes.root}>
      <Checkbox
        label={
          <div
            style={{
              fontWeight: 'bold',
              color: 'GrayText',
              fontSize: '1rem',
            }}
          >
            {label}
          </div>
        }
        value={isAllSelected}
        indeterminate={isAllSelected ? undefined : eventos.some((d) => value.includes(d))}
        onChange={() => handleToggleAll()}
      />
      <Grid container spacing={1}>
        {eventos.map((evento, index) => (
          <Grid key={index} item md={2} sm={6} xs={12}>
            <Checkbox
              label={evento.toString()}
              value={value.includes(evento)}
              onChange={() => handleToggleEvento(evento)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
