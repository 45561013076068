import React, { useState, useCallback, useEffect } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { AutoCompleteEvento } from '~/components/AutoComplete'
import { CurrencyTextField } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

const schema = yup.object().shape({
  eventosRelacionados: yup.string().required('Informe o Evento').nullable(),
  quantidade: yup.number().required('Informe a Quantidade').min(0.01, 'Informe a Quantidade'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/EventoRelacionado/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/EventoRelacionado', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Evento Relacionado"
      subtitle={data?.evento && `${data.evento?.codigo} - ${data.evento?.nome}`}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <AutoCompleteEvento
            required
            validationErrors={validationErrors}
            name="eventosRelacionados"
            value={data?.eventosRelacionados || null}
            getOptionDisabled={(option) => option.id === data?.eventoId}
            onChange={(e, eventosRelacionados) => {
              const eventoRelacionadoId = eventosRelacionados?.id || null
              setData((oldState) => ({
                ...oldState,
                eventoRelacionadoId,
                eventosRelacionados,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            required
            name="quantidade"
            validationErrors={validationErrors}
            label="Quantidade"
            fullWidth
            variant="outlined"
            maximumValue="9999"
            size="small"
            value={data?.quantidade || 0}
            onChange={(e, value) => {
              const quantidade = value || 0
              setData((oldState) => ({ ...oldState, quantidade }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Valor"
            fullWidth
            variant="outlined"
            size="small"
            value={data?.valor || 0}
            onChange={(e, value) => {
              const valor = value || 0
              setData((oldState) => ({
                ...oldState,
                valor,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
