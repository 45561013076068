import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

export interface FeriasColetivaReciboImprimirDTO {
  funcionarioId: string
  funcionarioNome: string
  funcionarioNrInscricao: string
  funcionarioDtAdmissao: Date | string
  rpId: string
  vrVencimento: number
  vrDesconto: number
  vrTotalLiquido: number
  nrRecibo: number
  reciboESocial: string
  isFinalizado: boolean
}

export function useObterRecibosFeriasColetiva(estabelecimentoId: string, anoMes: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: FeriasColetivaReciboImprimirDTO[] }>(
      '/FeriasColetiva/ObterRecibosFeriasColetiva',
      {
        params: {
          estabelecimentoId,
          anoMes,
        },
      },
    )
    return response.data.data
  }

  const query = useQuery(
    ['FeriasColetivaObterRecibosFeriasColetiva', estabelecimentoId, anoMes],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )

  return {
    ...query,
    data: query?.data || [],
  }
}
