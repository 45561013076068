import React from 'react'

import { Box, makeStyles, useTheme } from '@material-ui/core'

import { MUITableTwoRows } from '~/components'

import AlignCellValue from '../../../../../AlignCellValue'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'
import { ApuracaoEncargosBaseCalculoDataCategoria } from '../../../..'
import DialogBaseCalculoVinculo from './DialogBaseCalc'

export interface ParamsDialogBaseCalculoVinculo {
  estabelecimentoId: string
  lotacaoId: string
  categoria: CategoriaEnum
  periodoApuracao: string
  indApuracao: IndApuracaoEnum
}

interface TableBaseCalculoCategoriaProps {
  collection: ApuracaoEncargosBaseCalculoDataCategoria[]
  indApuracao: IndApuracaoEnum
  estabelecimentoId: string
  lotacaoId: string
}

const useStyles = makeStyles((theme) => ({
  tableWithError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.error.light,
    },
  },
}))

export default function TableBaseCalculoCategoria(props: TableBaseCalculoCategoriaProps) {
  const { collection, indApuracao, estabelecimentoId, lotacaoId } = props

  const classes = useStyles()
  const theme = useTheme()

  const { anoMes } = useAmbiente()
  const {
    close: closeDialogBaseCalculoVinculo,
    data: dataDialogBaseCalculoVinculo,
    isOpen: isOpenDialogBaseCalculoVinculo,
    open: openDialogBaseCalculoVinculo,
  } = useDialog<ParamsDialogBaseCalculoVinculo | null>(null)

  function handleClickRow(index: number) {
    const categoriaObject = collection[index]

    const params = {
      estabelecimentoId,
      lotacaoId,
      categoria: categoriaObject.categoria,
      periodoApuracao: anoMes,
      indApuracao,
    }

    openDialogBaseCalculoVinculo(params)
  }

  return (
    <Box mx={2} my={1}>
      <MUITableTwoRows
        data={collection}
        pagination={false}
        options={{
          onClickRow: handleClickRow,
        }}
        groupColumns={[
          {
            label: 'Base de Cálculo',
            count: 4,
            startIndex: 2,
          },
          {
            label: 'Dedução',
            count: 2,
            startIndex: 3,
          },
          {
            label: 'Transportador',
            count: 2,
            startIndex: 4,
          },
          {
            label: 'Base de Cálculo Suspensa',
            count: 4,
            startIndex: 5,
          },
        ]}
        columns={[
          {
            label: 'Categoria',
            firstRow: 'nome',
            options: {
              minWidth: '20rem',
              customBodyRenderFirstRow: (value, indexCurrent) => {
                const dataCurrent = collection[indexCurrent]
                return (
                  <Box display="flex" gridGap={10} alignItems="center">
                    <Box
                      bgcolor={dataCurrent.isErro ? theme.palette.error.light : undefined}
                      height="54px"
                      width="8px"
                      borderRadius="0px 32px 32px 0px"
                      ml={-1.5}
                    />
                    {value}
                  </Box>
                )
              },
            },
          },
          {
            label: 'Apuração',
            firstRow: 'nome',
            secondRow: 'nome',
            options: {
              customBodyRenderFirstRow: () => 'Folha',
              customBodyRenderSecondRow: () => 'eSocial',
            },
          },
          {
            label: 'Normal',
            firstRow: 'folhaPagamento.vrBcCp00',
            secondRow: 'eSocial.vrBcCp00',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrBcCp00 !==
                collection[indexCurrent].eSocial.vrBcCp00
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Aposentadoria 15',
            firstRow: 'folhaPagamento.vrBcCp15',
            secondRow: 'eSocial.vrBcCp15',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrBcCp15 !==
                collection[indexCurrent].eSocial.vrBcCp15
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Aposentadoria 20',
            firstRow: 'folhaPagamento.vrBcCp20',
            secondRow: 'eSocial.vrBcCp20',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrBcCp20 !==
                collection[indexCurrent].eSocial.vrBcCp20
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Aposentadoria 25',
            firstRow: 'folhaPagamento.vrBcCp25',
            secondRow: 'eSocial.vrBcCp25',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrBcCp25 !==
                collection[indexCurrent].eSocial.vrBcCp25
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Sal.Família',
            firstRow: 'folhaPagamento.vrSalarioFamilia',
            secondRow: 'eSocial.vrSalarioFamilia',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrSalarioFamilia !==
                collection[indexCurrent].eSocial.vrSalarioFamilia
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Sal.Maternidade',
            firstRow: 'folhaPagamento.vrSalarioMaternidade',
            secondRow: 'eSocial.vrSalarioMaternidade',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrSalarioMaternidade !==
                collection[indexCurrent].eSocial.vrSalarioMaternidade
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'SEST',
            firstRow: 'folhaPagamento.vrDescontoSEST',
            secondRow: 'eSocial.vrDescontoSEST',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrDescontoSEST !==
                collection[indexCurrent].eSocial.vrDescontoSEST
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'SENAT',
            firstRow: 'folhaPagamento.vrDescontoSENAT',
            secondRow: 'eSocial.vrDescontoSENAT',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrDescontoSENAT !==
                collection[indexCurrent].eSocial.vrDescontoSENAT
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Normal',
            firstRow: 'folhaPagamento.vrSuspBcCp00',
            secondRow: 'eSocial.vrSuspBcCp00',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrSuspBcCp00 !==
                collection[indexCurrent].eSocial.vrSuspBcCp00
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Aposentadoria 15',
            firstRow: 'folhaPagamento.vrSuspBcCp15',
            secondRow: 'eSocial.vrSuspBcCp15',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrSuspBcCp15 !==
                collection[indexCurrent].eSocial.vrSuspBcCp15
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Aposentadoria 20',
            firstRow: 'folhaPagamento.vrSuspBcCp20',
            secondRow: 'eSocial.vrSuspBcCp20',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrSuspBcCp20 !==
                collection[indexCurrent].eSocial.vrSuspBcCp20
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Aposentadoria 25',
            firstRow: 'folhaPagamento.vrSuspBcCp25',
            secondRow: 'eSocial.vrSuspBcCp25',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].folhaPagamento.vrSuspBcCp25 !==
                collection[indexCurrent].eSocial.vrSuspBcCp25
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
        ]}
      />
      {dataDialogBaseCalculoVinculo && (
        <DialogBaseCalculoVinculo
          isOpen={isOpenDialogBaseCalculoVinculo}
          onClose={closeDialogBaseCalculoVinculo}
          params={dataDialogBaseCalculoVinculo}
        />
      )}
    </Box>
  )
}
