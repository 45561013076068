import api from '~/services/api-pessoal'

import { PessoaFisicaConsulta } from './PessoaFisicaConsulta'

export default async function obterPessoaPorNrInscricao(nrInscricao: string) {
  const response = await api.get<{ data: PessoaFisicaConsulta }>(
    '/PessoaFisicaConsulta/GetByInscricao',
    {
      params: {
        inscricao: nrInscricao,
      },
    },
  )
  return response?.data?.data || null
}
