import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import Table from './components/Table'
import Form from './components/Form'

import { ConfirmDeleteDialog, ActionDialog, TreeView } from '~/components'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import { useVinculoLocalMutate } from '~/hooks/queries/useVinculoLocal'
import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

function LocalTrabalhoFuncionarioSST() {
  const [query, setQuery] = useState('')
  const [vinculo, setVinculo] = useState({})

  const { close: closeForm, data: dataForm, isOpen: isOpenForm, open: openForm } = useDialog({})
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog(null)
  const { mutateDelete } = useVinculoLocalMutate()
  const notification = useNotification()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])
  const { estabelecimento, anoMes } = useAmbiente()

  useEffect(() => {
    setVinculo({})
  }, [estabelecimento, anoMes])

  function handleClickItem(event, value) {
    const handleClickEditItem = (dt) => {
      openForm(dt)
    }

    const handleClickDeleteItem = (id) => {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  async function handleConfirmDelete() {
    await mutateDelete.mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
    notification.remove()
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => setVinculo(vinculo)}
      searchBy="vinculoNome"
      renderOption={(option) => option.vinculoNome}
      isLoading={isLoadingMenu}
    >
      <Box>
        <Box height={50}>
          <PageHeader
            title="Ambiente de Trabalho Funcionário"
            subtitle={vinculo?.vinculoNome || '-'}
          >
            <ButtonBox>
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
              <Button
                size="small"
                color="primary"
                onClick={() => openForm({ vinculoId: vinculo?.vinculoId })}
                variant="contained"
                disabled={vinculo?.vinculoId ? false : true}
              >
                Adicionar
              </Button>
            </ButtonBox>
          </PageHeader>
        </Box>

        <Table query={query} onItemClick={handleClickItem} vinculoId={vinculo?.vinculoId} />

        <ActionDialog
          title="Cadastro de Ambiente de Trabalho Funcionário"
          isOpen={isOpenForm}
          onClose={closeForm}
          onCancelClick={closeForm}
          customActions={<></>}
          dialogProps={{
            maxWidth: 'sm',
            fullWidth: true,
          }}
        >
          <Form data={dataForm} onClose={closeForm} />
        </ActionDialog>

        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={mutateDelete.isLoading}
          onCancel={closeConfirmDelete}
          onConfirm={handleConfirmDelete}
        />
      </Box>
    </TreeView>
  )
}

export default LocalTrabalhoFuncionarioSST
