import moment from 'moment'
import { formatToBRL } from 'brazilian-values'

import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import { VinculoSalarioBase } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'

import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  IndSalarioContratualEnum,
  IndSalarioContratualValues,
} from '~/@types/enums/IndSalarioContratualEnum'

function formatIndSalario(indSalario: IndSalarioContratualEnum) {
  const objectFinded = IndSalarioContratualValues.find((obj) => obj.value === indSalario)
  return objectFinded?.name || ''
}
interface TableProps {
  data: VinculoSalarioBase[]
  query: string
  onItemClick: (event: 'edit' | 'delete', id: string) => void
}

export default function Table(props: TableProps) {
  const { data, query, onItemClick } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtSalario',
      label: 'Data Salário',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtEfetiva',
      label: 'Data Efetiva',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'indSalario',
      label: 'Indicador do Salário',
      options: {
        customBodyRender: (value) => formatIndSalario(value),
      },
    },
    {
      name: 'quantidade',
      label: 'Quantidade',
    },
    {
      name: 'vrSalario',
      label: 'Valor',
      options: {
        customBodyRender: (value) =>
          value ? <ButtonBox>{formatToBRL(value).replace('R$ ', '')}</ButtonBox> : '',
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      columns={columns}
      sherlock={{
        query,
        columns: ['dtSalario'],
      }}
    />
  )
}
