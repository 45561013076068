import { makeStyles, List, Box } from '@material-ui/core'

import ListItemComponent from '../ListItemComponent'

const useStyles = makeStyles((theme) => ({
  rootMenusNaoEncontrado: {
    textAlign: 'center',
    color: theme.palette.primary.primary,
    paddingTop: theme.spacing(2),
  },
}))

export default function DisplayMenu(props) {
  const { menus, menusOpens, handleOpenMenus } = props

  const classes = useStyles()

  if (!(menus?.length > 0))
    return <Box className={classes.rootMenusNaoEncontrado}>Menu não encontrado</Box>

  return (
    <List>
      {menus.map((itemMenu, index) => (
        <ListItemComponent
          id={`${itemMenu?.level}|${index}`}
          item={itemMenu}
          key={index}
          level={itemMenu?.level}
          menusOpens={menusOpens}
          handleOpenMenus={handleOpenMenus}
        />
      ))}
    </List>
  )
}
