import { useState } from 'react'

import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core'

import { LabelDescription, Stack, TabPanel } from '~/components'

import TabSalarioContratual from './TabSalarioContratual'
import TabAmbienteTrabalho from './TabAmbienteTrabalho'
import TabFerias from './TabFerias'

import { useAtualizarCTPSObterVinculo } from '~/hooks/queries/AtualizarCTPS/useAtualizarCTPSObterVinculo'

import { formatToCPF } from 'brazilian-values'
import moment from 'moment'

enum TabsAtualizarCTPS {
  SalarioContratual,
  AmbienteTrabalho,
  Ferias,
}

interface ContentProps {
  vinculoId: string
}

export default function Content({ vinculoId }: ContentProps) {
  const [tab, setTab] = useState(TabsAtualizarCTPS.SalarioContratual)

  const { data, isLoading, isFetching } = useAtualizarCTPSObterVinculo({
    params: { vinculoId },
  })

  return (
    <Stack height="100%">
      <Box component={Paper} p={1.5}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <LabelDescription
              label="Funcionário:"
              description={data ? `${data.nome} - ${formatToCPF(data.cpf)}` : '-'}
            />
          </Grid>

          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end">
              {isLoading && <CircularProgress size={16} />}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <LabelDescription
              label="Admissão:"
              description={data ? moment(data.dtAdmissao).format('DD/MM/yyyy') : '-'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              label="Rescisão:"
              description={data?.dtRescisao ? moment(data?.dtRescisao).format('DD/MM/yyyy') : '-'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              label="Data do Aviso:"
              description={
                data?.dtAvisoPrevio ? moment(data?.dtAvisoPrevio).format('DD/MM/yyyy') : '-'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDescription
              label="Número de Dias de Aviso:"
              description={data?.diasAvisoPrevio ? `${data?.diasAvisoPrevio}` : '-'}
            />
          </Grid>
        </Grid>
      </Box>

      <Box height={4} mt={-1}>
        {isFetching && <LinearProgress />}
      </Box>

      <Box component={Paper}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => setTab(value)}
        >
          <Tab label="Salário Contratual" />
          <Tab label="Ambiente Trabalho" />
          <Tab label="Férias" />
        </Tabs>
      </Box>

      <Box flex={1}>
        <TabPanel value={tab} index={TabsAtualizarCTPS.SalarioContratual}>
          <TabSalarioContratual vinculoId={vinculoId} />
        </TabPanel>
        <TabPanel value={tab} index={TabsAtualizarCTPS.AmbienteTrabalho}>
          <TabAmbienteTrabalho vinculoId={vinculoId} />
        </TabPanel>
        <TabPanel value={tab} index={TabsAtualizarCTPS.Ferias}>
          <TabFerias vinculoId={vinculoId} />
        </TabPanel>
      </Box>
    </Stack>
  )
}
