// import { NotificationManager } from 'react-notifications'
import { toast } from 'react-toastify'

export function notifySuccess(message: string) {
  toast.success(message)
}

export function notifyError(message: string) {
  toast.error(message)
}

export function notifyWarning(message: string) {
  toast.warning(message)
}

export function notifyPost() {
  toast.success('Registro inserido com sucesso.')
}

export function notifyPut() {
  toast.success('Registro atualizado com sucesso.')
}

export function notifyRemove() {
  toast.success('Registro removido com sucesso.')
}

export function notifyFailed() {
  toast.error('Ocorreu um erro ao tentar processar a requisição.')
}
