import { useState } from 'react'

import {
  StackContainer,
  PageHeader,
  Button,
  Finder,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
} from '~/components'

import Table from './components/Table'
import Form from './components/Form'
import DialogConfig from './components/DialogConfig'

import { useRemover } from '~/hooks/queries/ModeloIntegracao/useRemover'
import useDialog from '~/hooks/useDialog'

import { ModeloIntegracao as ModeloIntegracaoType } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'

export default function ModeloIntegracao() {
  const [query, setQuery] = useState('')

  const {
    close: closeForm,
    open: openForm,
    data: dataForm,
    isOpen: isOpenForm,
  } = useDialog<ModeloIntegracaoType | null>(null)
  const {
    close: closeConfirmDelete,
    open: openConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
  } = useDialog('')
  const {
    close: closeDialogConfig,
    open: openDialogConfig,
    data: dataDialogConfig,
    isOpen: isOpenDialogConfig,
  } = useDialog<ModeloIntegracaoType | null>(null)

  const { mutateAsync, isLoading } = useRemover()

  const handleDeleteItem = async () => {
    await mutateAsync({ params: { id: dataConfirmDelete } })
    closeConfirmDelete()
  }

  const handleClickItem = (event: 'delete' | 'edit' | 'config', value: ModeloIntegracaoType) => {
    switch (event) {
      case 'edit':
        openForm(value)
        break
      case 'delete':
        openConfirmDelete(value.id)
        break
      case 'config':
        openDialogConfig(value)
        break
    }
  }

  return (
    <StackContainer>
      <PageHeader title="Modelo de Integração">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button size="small" color="primary" onClick={() => openForm(null)} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <ContainerTable>
        <Table query={query} onItemClick={handleClickItem} />
      </ContainerTable>

      {isOpenForm && <Form isOpen={isOpenForm} data={dataForm} onClose={closeForm} />}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isLoading}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}

      {isOpenDialogConfig && (
        <DialogConfig
          isOpen={isOpenDialogConfig}
          onClose={closeDialogConfig}
          data={dataDialogConfig as ModeloIntegracaoType}
        />
      )}
    </StackContainer>
  )
}
