import { useState } from 'react'

import * as yup from 'yup'

import { ActionDialog, DatePickerNew, Stack, TextField } from '~/components'

import { useCreateVinculoSuspensao } from '~/hooks/queries/VinculoSuspensao/useCreateVinculoSuspensao'
import { useUpdateVinculoSuspensao } from '~/hooks/queries/VinculoSuspensao/useUpdateVinculoSuspensao'

import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoSuspensaoDTO } from '~/hooks/queries/VinculoSuspensao/dtos/VinculoSuspensaoDTO'
import AutoCompleteMotivo from '~/components/AutoComplete/AutoCompleteMotivo'

const schema = yup.object().shape({
  dtSuspensao: yup
    .date()
    .typeError('Informe uma dava válida')
    .required('Informe a Data da Suspensão'),
  dtRetorno: yup.date().typeError('Informe uma dava válida').required('Informe a Data do Retorno'),
  motivoId: yup.string().required('Informe o Motivo'),
  motivoSuspensao: yup.string().required('Informe a Descrição da Suspensão'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoSuspensaoDTO
  subtitle: string
}

export default function Form({ isOpen, onClose, data: _data, subtitle }: FormProps) {
  const isUpdate = _data.id ? true : false

  const [data, setData] = useState(_data)

  const { mutateAsync: mutateAsyncCreate, isLoading: isCreating } = useCreateVinculoSuspensao()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } = useUpdateVinculoSuspensao()

  const isLoading = isCreating || isUpdating

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    const { id, ...rest } = data
    if (isUpdate) {
      await mutateAsyncUpdate({
        data: rest,
        id,
      })
    } else {
      await mutateAsyncCreate(rest)
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Suspensão"
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Stack gap={2}>
        <DatePickerNew
          label="Data da Ocorrência"
          value={data.dtSuspensao || null}
          onChange={(time) => {
            const dtSuspensao = time || ''
            setData((prev) => ({ ...prev, dtSuspensao }))
          }}
          required
          validationErrors={validationErrors}
          name="dtSuspensao"
        />

        <DatePickerNew
          label="Data do Retorno"
          value={data.dtRetorno || null}
          onChange={(time) => {
            const dtRetorno = time || ''
            setData((prev) => ({ ...prev, dtRetorno }))
          }}
          required
          validationErrors={validationErrors}
          name="dtRetorno"
        />

        {isUpdate && <TextField label="Dias de Suspensão" value={data.nrDiasSuspensao} disabled />}

        <AutoCompleteMotivo
          value={data.motivoId}
          onChange={(d) => {
            const motivoId = d?.id || ''
            setData((prev) => ({ ...prev, motivoId }))
          }}
          required
          validationErrors={validationErrors}
          name="motivoId"
        />

        <TextField
          label="Descrição da Suspensão"
          value={data.motivoSuspensao}
          onChange={(e) => {
            const motivoSuspensao = e.target.value
            setData((prev) => ({
              ...prev,
              motivoSuspensao,
            }))
          }}
          rows={4}
          multiline
          required
          inputProps={{
            maxLength: 2000,
          }}
          validationErrors={validationErrors}
          name="motivoSuspensao"
        />
      </Stack>
    </ActionDialog>
  )
}
