import React, { useState, useEffect, useCallback, useRef } from 'react'

import PropTypes from 'prop-types'

import { TextField } from 'mio-library-ui'
import {
  LinearProgress,
  Box,
  makeStyles,
  InputAdornment,
  IconButton,
  Paper,
} from '@material-ui/core'
import {
  Search as SearchIcon,
  Close as CloseIcon,
  ChevronRight as NextIcon,
  ChevronLeft as PreviousIcon,
  FirstPage as FirstIcon,
  LastPage as LastIcon,
} from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'

import useHeightParents from '~/hooks/useHeightParents'

import { ErrorMessage } from '~/components'

import TreeViewContent from './components/TreeViewContent'

const HEIGHT_FINDER = '50px'
const HEIGHT_NAVIGATION = '40px'
const HEIGHT_PROGRESSBAR = '4px'

const useStylesComponetTreeView = makeStyles((theme) => ({
  boxRoot: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: (props) => theme.spacing(props.top),
    paddingBottom: (props) => theme.spacing(props.bottom),
    paddingLeft: (props) => theme.spacing(props.left),
    paddingRight: (props) => theme.spacing(props.right),
    height: '100%',
    width: '100%',
  },
  root: {
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  boxFinder: {
    height: HEIGHT_FINDER,
    borderBottom: theme.shape.border,
  },
  boxTreeViewContent: {
    width: '100%',
    height: (props) =>
      `calc(100% - ${HEIGHT_PROGRESSBAR} - ${props.searchBy ? HEIGHT_FINDER : '0px'} - ${
        props.navigation ? HEIGHT_NAVIGATION : '0px'
      })`,
    overflow: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  boxNavigation: {
    borderTop: theme.shape.border,
    height: HEIGHT_NAVIGATION,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      overflowY: 'auto',
      display: 'grid',
    },
  },
}))

const useStylesContainerTreeView = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: theme.spacing(1),
  },
  leftContainer: {
    width: '20%',
    height: '100%',
    [theme.breakpoints.only('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
  rightContainer: {
    padding: theme.spacing(1),
    height: '100%',
    width: '80%',
    [theme.breakpoints.only('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
}))

const ComponentTreeView = (props) => {
  const {
    onSelectItem,
    data,
    isLoading,
    isLoadingOnClick,
    searchBy,
    filterSearch,
    navigation,
    renderOption,
    renderSecondaryOption,
    renderRightItem,
    errorMessage,
    top,
    bottom,
    left,
    right,
  } = props

  const [query, setQuery] = useState('')
  const [displayMenus, setDisplayMenus] = useState([])
  const [expanded, setExpanded] = useState(['0|0'])
  const [selected, setSelected] = useState({})
  const [selectedForGetRequest, setSelectedForGetRequest] = useState(null)

  const refBoxTreeViewContent = useRef(null)

  const classes = useStylesComponetTreeView({
    top,
    bottom,
    left,
    right,
    searchBy,
    navigation,
  })

  // useKeyPress('ArrowLeft', () => changeNavigation('first'))
  // useKeyPress('ArrowRight', () => changeNavigation('last'))
  // useKeyPress('ArrowUp', () => changeNavigation('previous'))
  // useKeyPress('ArrowDown', () => changeNavigation('next'))

  const heightContentMenu = useHeightParents({
    refElement: refBoxTreeViewContent,
    returnHeight: true,
  })

  useEffect(() => {
    function sendRequestOnSelectItem() {
      if (!selectedForGetRequest) return
      onSelectItem('itemSelected', selectedForGetRequest)
    }
    const delayDebounceFn = setTimeout(sendRequestOnSelectItem, 300)
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line
  }, [selectedForGetRequest])

  useEffect(() => {
    setDisplayMenus(data)
  }, [data])

  function expandMenuOnSearch(menus) {
    let indexCurrent = 0
    let biggerSizeDataOfMenu = 0

    menus.forEach((menu, index) => {
      const dataOfMenu = menu?.data || []
      if (dataOfMenu.length > biggerSizeDataOfMenu) {
        indexCurrent = index
        biggerSizeDataOfMenu = dataOfMenu.length
      }
    })

    const menuExpanded = '0|' + indexCurrent
    if (biggerSizeDataOfMenu === 0) return setExpanded([])
    setExpanded([menuExpanded])
  }

  const handleSearchMenu = useCallback(
    (q) => {
      if (filterSearch) {
        setQuery(q)
        const newData = filterSearch(q, data)
        setDisplayMenus(newData)
        return
      }

      if (!q) {
        setQuery('')
        setDisplayMenus(data)
        setExpanded([])
        return
      }

      setQuery(q)

      const query = q.toLowerCase()

      const newAgrupamento = data.map((agrupamento) => {
        const newData = []
        if (searchBy.length > 0 && typeof searchBy === 'object') {
          searchBy.forEach((field) => {
            agrupamento.data.forEach((item) => {
              if (item[field].toString().toLowerCase().includes(query)) newData.push(item)
            })
          })
        } else {
          agrupamento.data.forEach((item) => {
            if (item[searchBy].toString().toLowerCase().includes(query)) newData.push(item)
          })
        }
        return {
          ...agrupamento,
          data: newData || [],
        }
      })
      setDisplayMenus(newAgrupamento)
      expandMenuOnSearch(newAgrupamento)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchBy],
  )

  const changeNavigation = (event) => {
    const firstLevel = {
      level: expanded[0]?.split('|')[0] || '',
      index: expanded[0]?.split('|')[1] || '',
    }

    if (firstLevel?.level !== '0') return

    const dataMenuSelected = displayMenus[firstLevel.index].data
    const boxTreeViewContent = document.getElementsByClassName(
      'fixed-size-list-virtualize-treeview',
    )[0]

    let newSelected = {}

    if ('first' === event) {
      setTimeout(
        () =>
          boxTreeViewContent.scrollTo({
            top: 0,
            behavior: 'smooth',
          }),
        1,
      )
      newSelected = dataMenuSelected[0]
    }

    if ('previous' === event) {
      let indexFinded = 0
      indexFinded = dataMenuSelected.indexOf(selected)

      if (indexFinded < 1) indexFinded = 1

      const previousElement = document.getElementById('1|' + (indexFinded - 1))

      const heightPreviousElement = previousElement?.offsetHeight || 0

      boxTreeViewContent.scrollTo({
        top: previousElement?.offsetTop - 2 * heightPreviousElement,
        behavior: 'smooth',
      })
      newSelected = dataMenuSelected[indexFinded - 1]
    }

    if ('next' === event) {
      let indexFinded = 0
      indexFinded = dataMenuSelected.indexOf(selected)

      if (indexFinded < 0 || indexFinded > dataMenuSelected.length - 2)
        indexFinded = dataMenuSelected.length - 2

      const nextElement = document.getElementById('1|' + (indexFinded + 1))

      const heightNextElement = nextElement?.offsetHeight || 0

      boxTreeViewContent.scrollTo({
        top: nextElement?.offsetTop - 2 * heightNextElement,
        behavior: 'smooth',
      })
      newSelected = dataMenuSelected[indexFinded + 1]
    }

    if ('last' === event) {
      setTimeout(
        () =>
          boxTreeViewContent.scrollTo({
            top: boxTreeViewContent.scrollHeight,
            behavior: 'smooth',
          }),
        1,
      )

      newSelected = dataMenuSelected[dataMenuSelected.length - 1]
    }

    setSelected(newSelected)
    if (newSelected !== selected) setSelectedForGetRequest(newSelected)
  }

  return (
    <Box className={classes.boxRoot}>
      <Box className={classes.root}>
        {searchBy && (
          <Box className={classes.boxFinder}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={query || ''}
              placeholder="Procurar"
              onChange={(e) => handleSearchMenu(e?.target?.value || '')}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton position="end" onClick={() => handleSearchMenu('')}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </Box>
        )}

        <Box height={HEIGHT_PROGRESSBAR}>{isLoadingOnClick && <LinearProgress />}</Box>

        <Box className={classes.boxTreeViewContent} ref={refBoxTreeViewContent}>
          {isLoading ? (
            <>
              <Skeleton variant="text" />
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="text" width="40%" />
            </>
          ) : errorMessage ? (
            <ErrorMessage error={errorMessage} />
          ) : (
            <TreeViewContent
              groups={displayMenus}
              onSelectItem={onSelectItem}
              renderOption={renderOption}
              renderSecondaryOption={renderSecondaryOption}
              renderRightItem={renderRightItem}
              expanded={expanded}
              setExpanded={setExpanded}
              selected={selected}
              setSelected={setSelected}
              changeNavigation={changeNavigation}
              heightContentMenu={heightContentMenu}
            />
          )}
        </Box>

        {navigation && (
          <Box className={classes.boxNavigation}>
            <IconButton title="Primeiro Registro" onClick={() => changeNavigation('first')}>
              <FirstIcon fontSize="small" />
            </IconButton>
            <IconButton title="Registro Anterior" onClick={() => changeNavigation('previous')}>
              <PreviousIcon fontSize="small" />
            </IconButton>
            <IconButton title="Próximo Registro" onClick={() => changeNavigation('next')}>
              <NextIcon fontSize="small" />
            </IconButton>
            <IconButton title="Último Registro" onClick={() => changeNavigation('last')}>
              <LastIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default function TreeView(props) {
  const { children, ...rest } = props

  const classes = useStylesContainerTreeView()

  if (children)
    return (
      <Box className={classes.root}>
        <Box component={Paper} className={classes.leftContainer}>
          <ComponentTreeView {...rest} />
        </Box>
        <Box className={classes.rightContainer}>{children}</Box>
      </Box>
    )

  return <ComponentTreeView {...rest} />
}

TreeView.defaultProps = {
  onSelectItem: () => undefined,
  data: [],
  isLoading: false,
  isLoadingOnClick: false,
  renderOption: () => undefined,
  navigation: true,
  errorMessage: '',
  top: 1,
  bottom: 1,
  left: 1,
  right: 1,
}

TreeView.propTypes = {
  data: PropTypes.array,
  renderOption: PropTypes.func,
  onSelectItem: PropTypes.func,
  children: PropTypes.node,
  searchBy: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  renderSecondaryOption: () => undefined,
  filterSearch: () => undefined,
}
