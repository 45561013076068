import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ValeTransporte } from './ValeTransporte'

const query = '/ValeTransporte'

export default function useValeTransporte() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ValeTransporte[] }>(query)
    return response.data.data
  }

  return useQuery([query], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
