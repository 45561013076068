import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { VinculoAlteracaoCadastro2205DTO } from './VinculoAlteracaoCadastro2205DTO'

interface RequestProps {
  data: VinculoAlteracaoCadastro2205DTO
  isRetificar: boolean
}

export function useSalvarEvento2205() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    const { data, isRetificar } = props
    await api.post('/VinculoAlteracaoContratoCadastro/SalvarEvento2205', data, {
      params: {
        isRetificar,
      },
    })
    notification.post()
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries('VinculoAlteracaoContratoCadastroGetByVinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
