import React, { useEffect, useState } from 'react'

import { Finder } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer } from '~/components'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'

const TITLE_PAGE = 'Declaração uso de Plano de Saúde'

export default function DeclaracaoUsoPlanoSaude() {
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelected] = useState([])

  const dialogNotification = useDialogNotification()
  const { estabelecimento } = useAmbiente()
  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  useEffect(() => {
    async function getCollection() {
      setLoading(true)
      try {
        const response = await api.get('/Vinculo/ObterVinculosPorEstabelecimento', {
          params: {
            estabelecimentoId: estabelecimento.id,
          },
        })
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [estabelecimento])

  function handlePrint() {
    openDialogConfirm()
  }

  function handleAfterSubmitDialogConfirm(dt) {
    const vinculosIds = rowsSelecteds.map((indexCurrent) => collection[indexCurrent].id)
    const newData = {
      vinculosIds,
      ...dt,
    }
    setRowsSelected([])
    openPDFViewer(newData)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Table
        isLoading={isLoading}
        query={query}
        data={collection}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelecteds}
      />

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        onClose={closeDialogConfirm}
        onAfterSubmit={handleAfterSubmitDialogConfirm}
      />

      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={handlePrint} disabled={!(rowsSelecteds.length > 0)} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="DeclaracaoUsoPlanoSaude"
        title={TITLE_PAGE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/DeclaracaoUsoPlanoSaude/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
