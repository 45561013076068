import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { HeightDataTable } from '~/components'

import { Visibility as ViewIcon, WbSunny as WbSunnyIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import moment from 'moment'

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = useMemo(
    () => [
      {
        name: 'dtPeriodoInicio',
        label: 'Início',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtPeriodoFim',
        label: 'Fim',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtLimiteConcessao',
        label: 'Limite Concessão',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtConcedida',
        label: 'Data Período Concedido',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'observacao',
        label: 'Observação',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Visualizar este registro"
                  color="primary"
                  aria-label="Visualizar"
                  onClick={() => {
                    onItemClick('view', value)
                  }}
                  size="small"
                >
                  <ViewIcon fontSize="small" color="primary" size="small" />
                </IconButton>

                <IconButton
                  title="Período de Férias"
                  color="primary"
                  aria-label="Período de Férias"
                  onClick={() => {
                    onItemClick('periodoFerias', value)
                  }}
                  size="small"
                >
                  <WbSunnyIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
