import { useEffect, useState } from 'react'

import { Box, IconButton, Paper, useTheme } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  CopyLabel,
  CurrencyTextField,
  Finder,
  PageHeader,
  StackContainer,
  ToolsDataTable,
} from '~/components'

import Form from './Form'

import {
  useFacultativoAtualizarSalarios,
  useFacultativoRemover,
  useObterTodosFacultativos,
} from '~/hooks/queries/Facultativo'
import useDialog from '~/hooks/useDialog'
import useDialogNotification from '~/hooks/useDialogNotification'

import { formatCurrency, formatToNIT } from '~/utils/utils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { Facultativo } from '~/hooks/queries/Facultativo/dtos/Facultativo'

export default function Contribuintes() {
  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [vrNovoSalario, setVrNovoSalario] = useState(0)
  const [query, setQuery] = useState('')

  const theme = useTheme()
  const dialogNotification = useDialogNotification()

  const { data: _data, isLoading, isFetching } = useObterTodosFacultativos()
  const data = _data || []
  const { mutateAsync: mutateAsyncRemove, isLoading: isLoadingRemove } = useFacultativoRemover()
  const { mutateAsync: mutateAsyncAtualizarSalarios, isLoading: isLoadingAtualizarSalarios } =
    useFacultativoAtualizarSalarios()

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<Facultativo | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  useEffect(() => {
    setVrNovoSalario(0)
    setRowsSelected([])
  }, [_data])

  async function handleDelete() {
    await mutateAsyncRemove(dataConfirmDelete)
    closeConfirmDelete()
  }

  async function handleAtualizarSalarios() {
    if (rowsSelected.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um Contribuinte Facultativo para prosseguir.'],
      })
    }
    const contribuintesIds = rowsSelected.map((d) => data[d].id)
    await mutateAsyncAtualizarSalarios({
      params: {
        vrNovoSalario,
      },
      data: contribuintesIds,
    })
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'NIT',
      name: 'nrInscricao',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToNIT(value)}</CopyLabel>,
      },
    },
    {
      label: 'Nome',
      name: 'nome',
    },
    {
      label: 'Alíq.',
      name: 'aliqContribuicao',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      label: 'Salário',
      name: 'vrSalarioContribuicao',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dataFined = data.find((d) => d.id === value)
                  if (dataFined) {
                    openForm(dataFined)
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => openConfirmDelete(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <StackContainer>
      <PageHeader title="Contribuintes Facultativo">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button variant="contained" onClick={() => openForm(null)}>
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          disableAutoHeight
          optionsSelectable={{
            type: 'multiple',
            rowsSelected: rowsSelected,
            onRowSelected: setRowsSelected,
          }}
          sherlock={{
            query,
            columns: ['nrInscricao', 'nome'],
          }}
        />
      </ContainerTable>

      <Box
        component={Paper}
        p={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gridGap={theme.spacing(1)}
      >
        <Box width={300}>
          <CurrencyTextField
            label="Novo Salário"
            value={vrNovoSalario}
            onChange={(_, value) => {
              const vrNovo = value
              setVrNovoSalario(vrNovo)
            }}
          />
        </Box>
        <Button
          variant="contained"
          disabled={vrNovoSalario > 0 ? false : true}
          onClick={handleAtualizarSalarios}
          isLoading={isLoadingAtualizarSalarios}
        >
          Atualizar Salários
        </Button>
      </Box>
      {isOpenForm && <Form isOpen={isOpenForm} onClose={closeForm} data={dataForm} />}
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDelete}
          isDeleting={isLoadingRemove}
        />
      )}
    </StackContainer>
  )
}
