import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useGetAllByVinculoInvalidate } from './useGetAllByVinculo'

import { notifyPost } from '~/utils/notification'

import { ProcessoTrabalhistaVinculoCreateDTO } from './dtos/ProcessoTrabalhistaVinculoCreateDTO'

export function useCreate() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useGetAllByVinculoInvalidate()

  async function handleRequest(data: ProcessoTrabalhistaVinculoCreateDTO) {
    await api.post('/ProcessoTrabalhistaVinculo', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
