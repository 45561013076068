import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useAmbiente from '../useAmbiente'
import useDialogNotification from '../useDialogNotification'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'

export interface VinculoMenuItem {
  vinculoId: string
  vinculoNome: string
  vinculoTipo: VinculoTipoEnum
  vinculoCategoria: CategoriaEnum
  qtdRecibos: number
  recibos: null
}

interface VinculoMenuGroup {
  agrupamentoId: number
  agrupamentoNome: string
  vinculos: VinculoMenuItem[]
}

export function useEstabelecimentoVinculosMenu(
  vinculosTipo: VinculoTipoEnum[],
  categoria?: CategoriaEnum,
) {
  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()

  async function getVinculosMenus() {
    const response = await api.post<{ data: VinculoMenuGroup[] }>(
      '/Estabelecimento/GetVinculosMenu',
      vinculosTipo,
      {
        params: {
          estabelecimentoId: estabelecimento.id,
          anoMes,
          categoria,
        },
      },
    )
    const responseArray = response?.data?.data || []

    return responseArray.map((d) => ({
      groupName: d.agrupamentoNome,
      data: d.vinculos,
    }))
  }

  return useQuery(
    ['EstabelecimentoMenu', vinculosTipo, estabelecimento.id, anoMes],
    () => getVinculosMenus(),
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}
