import { useState } from 'react'

import api from '~/services/api-pessoal'
import apiCEP from '~/services/consultaCEPApi'

function formatToSimpleText(text) {
  if (!text) return ''
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

async function getLogradouro(endereco) {
  const logradouroSplited = endereco.split(' ')[0]
  try {
    const response = await api.get('/Logradouro')
    const logradouro = response.data.data.find(
      (logradouro_) =>
        formatToSimpleText(logradouro_.nome) === formatToSimpleText(logradouroSplited),
    )
    return {
      logradouro: logradouro || null,
      logradouroId: logradouro?.id || '',
    }
  } catch (error) {
    console.log(error)
  }
}

async function getCidade(municipio, uf) {
  const cidadeParam = `${municipio}${uf}`
  try {
    const response = await api.get('/Cidade')
    const cidade = response.data.data.find(
      (cidade_) =>
        formatToSimpleText(`${cidade_.nome}${cidade_.uf}`) === formatToSimpleText(cidadeParam),
    )
    return { cidade: cidade || null, cidadeId: cidade?.id || '' }
  } catch (error) {
    console.log(error)
  }
}

export default function useAddressByCEP() {
  const [isLoading, setLoading] = useState(false)

  async function getAddressByCEP(cep) {
    if (cep?.length < 8) return
    setLoading(true)
    try {
      const { data } = await apiCEP.get(`/${cep}/json`)

      const { logradouroId, logradouro } = await getLogradouro(data.logradouro)

      const { cidade, cidadeId } = await getCidade(data.localidade, data.uf)

      const logradouroNome = logradouro?.nome ? logradouro?.nome.toUpperCase() : ''

      return {
        endereco: data?.logradouro
          ? data.logradouro.toUpperCase().replace(logradouroNome, '').trim()
          : '',
        bairro: data?.bairro ? data.bairro.toUpperCase() : '',
        complemento: data?.complemento ? data.complemento.toUpperCase() : '',
        logradouroId,
        logradouro,
        cidade,
        cidadeId,
      }
    } catch (error) {
      console.log(error)
      return {
        endereco: '',
        bairro: '',
        complemento: '',
        logradouroId: '',
        logradouro: null,
        cidade: null,
        cidadeId: '',
      }
    } finally {
      setLoading(false)
    }
  }

  return {
    getAddressByCEP,
    isLoading,
  }
}
