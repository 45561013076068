import { useState } from 'react'

import { Box, Paper, useTheme } from '@material-ui/core'

import { Button, ButtonBox, ConfirmDeleteDialog, Finder } from '~/components'

import Table from './Table'
import Form from './Form'

import useDialog from '~/hooks/useDialog'
import useModeloPlanilhaImportacao from '~/hooks/queries/ModeloPlanilhaImportacao/useModeloPlanilhaImportacao'
import useModeloPlanilhaImportacaoRemover from '~/hooks/queries/ModeloPlanilhaImportacao/useModeloPlanilhaImportacaoRemover'

import { ModeloPlanilhaImportacao as ModeloPlanilhaImportacaoType } from '~/hooks/queries/ModeloPlanilhaImportacao/ModeloPlanilhaImportacao'

export default function ModeloPlanilhaImportacao() {
  const [query, setQuery] = useState('')

  const theme = useTheme()
  const { data: _data, isLoading, isFetching } = useModeloPlanilhaImportacao()
  const data = _data || []
  const { mutateAsync: handleRemove, isLoading: isLoadingRemove } =
    useModeloPlanilhaImportacaoRemover()

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<ModeloPlanilhaImportacaoType | null>(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string>('')

  function onItemClick(event: 'edit' | 'delete', value: string) {
    switch (event) {
      case 'edit':
        onClickEdit(value)
        break
      case 'delete':
        openConfirmDelete(value)
        break
      default:
        break
    }
  }

  function onClickEdit(id: string) {
    const dataCurrent = data.find((d) => d.id === id)
    openForm(dataCurrent || null)
  }

  async function onConfirmDelete() {
    await handleRemove(dataConfirmDelete)
    closeConfirmDelete()
  }

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <Box padding={1} component={Paper}>
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button size="small" color="primary" onClick={() => openForm(null)} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </Box>

      <Box flex={1} position="relative" overflow="auto" minHeight={300}>
        <Box position="absolute" width="100%">
          <Table
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            query={query}
            onItemClick={onItemClick}
          />
        </Box>
      </Box>

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isLoadingRemove}
        onCancel={closeConfirmDelete}
        onConfirm={onConfirmDelete}
      />
      {isOpenForm && <Form data={dataForm} isOpen={isOpenForm} onClose={closeForm} />}
    </Box>
  )
}
