import React from 'react'

import { Grid } from '@material-ui/core'

import StepperForm from '~/components/StepperForm'

import FormMain from './FormMain'
import TableExames from './TableExames'

import { useAtestadoSaudeOcupacionalCriar } from '~/hooks/queries/AtestadoSaudeOcupacional/useAtestadoSaudeOcupacionalCriar'
import { useAtestadoSaudeOcupacionalAtualizar } from '~/hooks/queries/AtestadoSaudeOcupacional/useAtestadoSaudeOcupacionalAtualizar'

import {
  AtestadoSaudeOcupacional,
  AtestadoSaudeOcupacionalPartial,
} from '~/hooks/queries/AtestadoSaudeOcupacional/AtestadoSaudeOcupacional'
import {
  AtestadoSaudeOcupacionalItem,
  AtestadoSaudeOcupacionalItemPartial,
} from '~/hooks/queries/AtestadoSaudeOcupacionalItem/AtestadoSaudeOcupacionalItem'

export interface MainDataForm {
  dataMainForm: AtestadoSaudeOcupacionalPartial
  dataTableExames: AtestadoSaudeOcupacionalItemPartial[]
}

interface MainDataFormValidate {
  dataMainForm: AtestadoSaudeOcupacional
  dataTableExames: AtestadoSaudeOcupacionalItem[]
}

interface FlowCreateProps {
  data: AtestadoSaudeOcupacionalPartial
  onCancel: () => void
  isRetificar?: boolean
}

export default function FlowCreate(props: FlowCreateProps) {
  const { data, onCancel, isRetificar } = props

  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingCreate } =
    useAtestadoSaudeOcupacionalCriar()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } =
    useAtestadoSaudeOcupacionalAtualizar()

  const mainData: MainDataForm = {
    dataMainForm: data,
    dataTableExames: data?.atestadoSaudeOcupacionalItens || [],
  }

  async function handleSubmit(_data: MainDataFormValidate) {
    const data = {
      atestadoSaudeOcupacional: _data.dataMainForm,
      atestadoSaudeOcupacionalItemList: _data.dataTableExames,
    }

    if (data.atestadoSaudeOcupacional.id) {
      await mutateAsyncUpdate({
        data,
        isRetificar: isRetificar || false,
      })
    } else {
      await mutateAsyncCreate(data)
    }
    onCancel()
  }

  const isEdit = data.id !== undefined

  return (
    <StepperForm.Provider<MainDataForm>
      onSubmit={(d) => handleSubmit(d as MainDataFormValidate)}
      onCancel={onCancel}
      data={mainData}
      isLoading={isLoadingCreate || isLoadingUpdate}
      disabled={isEdit && (isRetificar === undefined ? false : !isRetificar)}
      steps={[
        {
          label: 'Cadastro',
          children: <FormMain />,
          icon: 'assignment_ind',
        },
        {
          label: 'Exames',
          children: <TableExames />,
          icon: 'person',
        },
      ]}
    >
      <Grid container spacing={0} style={{ marginBottom: '3rem' }}>
        <Grid item xs={1} md={2}>
          <StepperForm.Steps />
        </Grid>
        <Grid item xs={11} md={10}>
          <StepperForm.Content />
        </Grid>
      </Grid>
    </StepperForm.Provider>
  )
}
