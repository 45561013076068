const naoAplicavel_0 = 0
const enquadradaLei12546_1 = 1

export const indDesoneracaoValues = [
  { value: naoAplicavel_0, name: '0 - Não informar' },
  {
    value: enquadradaLei12546_1,
    name: '1 - Empresa enquadrada nos arts. 7º a 9º da Lei 12.546/2011',
  },
]

export const indDesoneracaoConsts = {
  naoAplicavel_0,
  enquadradaLei12546_1,
}
