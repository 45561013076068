import { convertToArrayDescription } from '~/utils/utils'

export enum RelDadosCadastraisFuncionarioEnum {
  Ativos,
  Admitidos,
  Demitidos,
  Transferidos,
  Afastados,
  MudancaCargo,
}

export const RelDadosCadastraisFuncionarioLabel = {
  [RelDadosCadastraisFuncionarioEnum.Ativos]: 'Funcionários Ativos',
  [RelDadosCadastraisFuncionarioEnum.Admitidos]: 'Admitidos',
  [RelDadosCadastraisFuncionarioEnum.Demitidos]: 'Demitidos',
  [RelDadosCadastraisFuncionarioEnum.Transferidos]: 'Transferidos',
  [RelDadosCadastraisFuncionarioEnum.Afastados]: 'Afastados',
  [RelDadosCadastraisFuncionarioEnum.MudancaCargo]: 'Mudança de Cargo',
}

export const RelDadosCadastraisFuncionarioValues = convertToArrayDescription(
  RelDadosCadastraisFuncionarioEnum,
  RelDadosCadastraisFuncionarioLabel,
)
