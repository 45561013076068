import React, { useEffect, useState } from 'react'

import { ContainerComponent } from '~/components'

import Cadastro from './Cadastro'
import VinculoSalarioContratual from '~/pages/Funcionario/Container/VinculoSalarioContratual'
import VinculoLocal from '~/pages/Funcionario/Container/VinculoLocal'
import VinculoHorario from '~/pages/Funcionario/Container/VinculoHorario'
import VinculoTreinamentos from '~/pages/Funcionario/Container/VinculoTreinamentos'
import VinculoObservacao from '~/pages/Funcionario/Container/VinculoObservacao'
import api from '~/services/api-pessoal'
import { LinearProgress } from '@material-ui/core'

const Container = (props) => {
  const { isOpen, onClose, vinculo: _vinculo, origemId, dtEfetiva, dtAlteracao } = props

  const [isLoading, setLoading] = useState(false)
  const [vinculo, setVinculo] = useState({})

  const [wasModified, setWasModified] = useState(false)

  useEffect(() => {
    async function getVinculo() {
      if (!isOpen) return
      if (!_vinculo?.vinculoId) return
      setLoading(true)
      try {
        const response = await api.get(`Vinculo/${_vinculo?.vinculoId}`)
        if (response.data.data) {
          setVinculo(response.data.data)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
    getVinculo()
  }, [_vinculo.vinculoId, isOpen])

  return (
    <ContainerComponent
      title="Alteração Contrato de Trabalho"
      subtitle={vinculo?.pessoaFisica?.nome || ''}
      menu
      isOpen={isOpen}
      onClose={onClose}
      formWasModified={wasModified}
      tabs={[
        {
          component: isLoading ? (
            <LinearProgress />
          ) : (
            <Cadastro vinculo={vinculo} onClickClose={onClose} setWasModified={setWasModified} />
          ),
          label: 'Cadastro',
        },
        {
          component: (
            <VinculoSalarioContratual
              vinculo={vinculo}
              considerIsFinalizado={false}
              dtEfetiva={dtEfetiva}
              dtAlteracao={dtAlteracao}
            />
          ),
          disabled: !vinculo?.id ? true : false,
          label: 'Salário Contratual',
        },
        {
          component: (
            <VinculoLocal
              vinculo={vinculo}
              considerIsFinalizado={false}
              dtAlteracao={dtAlteracao}
            />
          ),
          disabled: !vinculo?.id ? true : false,
          label: 'Ambiente Trabalho',
        },
        {
          component: (
            <VinculoHorario
              vinculo={vinculo}
              considerIsFinalizado={false}
              dtAlteracao={dtAlteracao}
            />
          ),
          disabled: !vinculo?.id ? true : false,
          label: 'Horário de Trabalho',
        },
        {
          component: (
            <VinculoTreinamentos
              vinculo={vinculo}
              considerIsFinalizado={false}
              dtAlteracao={dtAlteracao}
            />
          ),
          disabled: !vinculo?.id ? true : false,
          label: 'Treinamentos',
        },
        {
          component: (
            <VinculoObservacao vinculo={vinculo} origemId={origemId} considerIsFinalizado={false} />
          ),
          disabled: !vinculo?.id ? true : false,
          label: 'Observação',
        },
      ]}
    />
  )
}

export default Container
