import React from 'react'

import { IconButton } from '@material-ui/core'
import { Print as PrintIcon } from '@material-ui/icons'
import { ButtonBox } from 'mio-library-ui'

import { ToolsDataTable } from '~/components'

import moment from 'moment'

const Table = (props) => {
  const { data, isLoading, handleClickPrint } = props

  const columns = [
    {
      name: 'dtPeriodoInicio',
      label: 'Início',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtPeriodoFim',
      label: 'Fim',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtLimiteConcessao',
      label: 'Limite Concessão',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtConcedida',
      label: 'Data Período Concedido',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtPeriodoPerdido',
      label: 'Data Período Perdido',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Imprimir Comunicado Perda Direito de Férias"
                color="primary"
                aria-label="Imprimir"
                onClick={() => handleClickPrint(value)}
                size="small"
              >
                <PrintIcon fontSize="small" color="primary" size="small" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <ToolsDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
