import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { indOrgaoClasseValues } from '~/values/indOrgaoClasseValues'
import { formatToCPF } from 'brazilian-values'

const formatOrgaoClasse = (orgaoClasse) => {
  const objectFinded = indOrgaoClasseValues.find((obj) => obj.value === orgaoClasse)
  return objectFinded.name
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data.map((item) => {
    return {
      ...item,
      responsavelNome: item?.responsavel?.nome || '',
      responsavelCpf: item?.responsavel?.cpf ? formatToCPF(item.responsavel.cpf) : '',
      responsavelOrgaoClasse: formatOrgaoClasse(item?.responsavel?.orgaoClasse),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'responsavelNome',
        label: 'Nome',
      },
      {
        name: 'responsavelCpf',
        label: 'CPF',
      },
      {
        name: 'responsavelOrgaoClasse',
        label: 'Orgão de Classe',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: [
          'responsavelCodigo',
          'responsavelNome',
          'responsavelCpf',
          'responsavelOrgaoClasse',
        ],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
