import moment from 'moment'

import { useVinculoSalarioBaseGetByVinculo } from '~/hooks/queries/VinculoSalarioBase/useVinculoSalarioBaseGetByVinculo'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ContainerTable, Stack, ToolsDataTable } from '~/components'
import {
  IndSalarioContratualLabels,
  IndSalarioContratualEnum,
} from '~/@types/enums/IndSalarioContratualEnum'
import { formatCurrency } from '~/utils/utils'

interface TabSalarioContratualProps {
  vinculoId: string
}

export default function TabSalarioContratual({ vinculoId }: TabSalarioContratualProps) {
  const { data, isLoading, isFetching } = useVinculoSalarioBaseGetByVinculo(vinculoId)

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtSalario',
      label: 'Data Salário',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtEfetiva',
      label: 'Data Efetiva',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'indSalario',
      label: 'Indicador do Salário',
      options: {
        customBodyRender: (value: IndSalarioContratualEnum) => IndSalarioContratualLabels[value],
      },
    },
    {
      name: 'quantidade',
      label: 'Quantidade',
    },
    {
      name: 'vrSalario',
      label: 'Valor',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
  ]

  return (
    <Stack height="100%">
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          disableAutoHeight
        />
      </ContainerTable>
    </Stack>
  )
}
