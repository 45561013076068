export enum ASOResultadoEnum {
  Apto_1 = 1,
  Inapto_2 = 2,
}

export const ASOResultadoLabels = {
  [ASOResultadoEnum.Apto_1]: 'Apto',
  [ASOResultadoEnum.Inapto_2]: 'Inapto',
}

export const ASOResultadoValues = [
  {
    name: 'Apto',
    value: ASOResultadoEnum.Apto_1,
  },
  {
    name: 'Inapto',
    value: ASOResultadoEnum.Inapto_2,
  },
]
