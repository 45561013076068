import moment from 'moment'

import { Box, LinearProgress, useTheme } from '@material-ui/core'

import NotificationItem from './NotificationItem'

import useNotificacaoMarcaNotificacaoLida from '~/hooks/queries/Notificacao/useNotificacaoMarcaNotificacaoLida'
import { useNotificacaoObterNotificacoesAmbienteClient } from '~/hooks/queries/Notificacao/useNotificacaoObterNotificacoesAmbiente'

import { NotificacaoAmbienteDTO } from '~/hooks/queries/Notificacao/dtos/NotificacaoAmbienteDTO'

import { IndNotificacaoColor } from '~/@types/enums/IndNotificacaoEnum'

interface NotificationContentProps {
  data: NotificacaoAmbienteDTO[]
}

export default function NotificationContent({ data }: NotificationContentProps) {
  const { mutateAsync: mutateAsyncMarcaNotificacaoLida, isLoading: isLoadingMarcaNotificacaoLida } =
    useNotificacaoMarcaNotificacaoLida()
  const { removeItem } = useNotificacaoObterNotificacoesAmbienteClient()
  const theme = useTheme()

  async function handleClick(id: string, isNotificacaoGlobal: boolean) {
    if (isNotificacaoGlobal) {
      removeItem(id)
    } else {
      await mutateAsyncMarcaNotificacaoLida(id)
    }
  }

  return (
    <>
      <Box height={4}>{isLoadingMarcaNotificacaoLida && <LinearProgress />}</Box>
      <Box
        height="60vh"
        minHeight={300}
        width={300}
        display="flex"
        flexDirection="column"
        overflow="auto"
        gridGap={theme.spacing(0.5)}
      >
        {data.map(
          ({ id, titulo, descricao, dtInicio, dtFim, indNotificacao, isNotificacaoGlobal }) => (
            <NotificationItem
              key={id}
              title={titulo}
              description={descricao}
              color={IndNotificacaoColor[indNotificacao]}
              onClick={() => handleClick(id, isNotificacaoGlobal)}
              hightLight={isNotificacaoGlobal}
              date={`${
                moment(dtInicio).format('DD/MM/yyyy') +
                (dtFim ? ' - ' + moment(dtFim).format('DD/MM/yyyy') : '')
              }`}
            />
          ),
        )}
      </Box>
    </>
  )
}
