import React, { useEffect, useState, useCallback } from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Button, Finder, ButtonBox, PageHeader } from 'mio-library-ui'

import { TreeView } from '~/components'

import api from '~/services/api-pessoal'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import Table from './components/Table'
import GerarEvento from './components/GerarEvento'
import DialogResponsaveis from './components/DialogResponsaveis'
import DialogAgentesNociveis from './components/DialogAgentesNociveis'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import useDialog from '~/hooks/useDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: theme.spacing(1),
  },
  leftContainer: {
    width: '20%',
    height: '100%',
    [theme.breakpoints.only('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
  rightContainer: {
    padding: theme.spacing(1),
    height: '100%',
    width: '80%',
    [theme.breakpoints.only('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
}))

const HEADER_HEIGHT = '70px'

export default function CondicaoAmbienteTrabalhoAutomatico() {
  const [isLoadingMenuOnClick, setLoadingMenuOnClick] = useState(false)
  const [collection, setCollection] = useState([])
  const [vinculo, setVinculo] = useState({})
  const [query, setQuery] = useState('')

  const classes = useStyles()
  const { anoMes, estabelecimento } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const {
    open: openDialogGerarEvento,
    close: closeDialogGerarEvento,
    isOpen: isOpenDialogGerarEvento,
  } = useDialog()
  const {
    open: openDialogResponsaveis,
    close: closeDialogResponsaveis,
    isOpen: isOpenDialogResponsaveis,
    data: dataDialogResponsaveis,
  } = useDialog([])
  const {
    open: openDialogAgentesNocivos,
    close: closeDialogAgentesNocivos,
    isOpen: isOpenDialogAgentesNocivos,
    data: dataDialogAgentesNocivos,
  } = useDialog([])
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Cooperado_5,
    VinculoTipoEnum.Empregador_2,
    VinculoTipoEnum.Estagiario_3,
  ])

  useEffect(() => {
    setVinculo({})
    setCollection([])
  }, [anoMes, estabelecimento])

  async function getCollectionByVinculo(vinculoId) {
    setLoadingMenuOnClick(true)
    try {
      const response = await api.get('/CondicaoAmbienteTrabalho/GetEventoByVinculo', {
        params: {
          vinculoId,
        },
      })
      setCollection(response?.data?.data || [])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingMenuOnClick(false)
    }
  }

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  function handleAfterSubmitFormGenerate() {
    closeDialogGerarEvento()
    getCollectionByVinculo(vinculo?.vinculoId)
  }

  function handleClickItem(event, value) {
    const functions = {
      responsaveis: openDialogResponsaveis,
      agentesNocivos: openDialogAgentesNocivos,
    }
    functions[event](value)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.leftContainer}>
        <TreeView
          onSelectItem={(event, vinculo) => {
            getCollectionByVinculo(vinculo.vinculoId)
            setVinculo(vinculo)
          }}
          data={dataMenu}
          isLoading={isLoadingMenu}
          isLoadingOnClick={isLoadingMenuOnClick}
          searchBy={['vinculoNome']}
          renderOption={(option) => option.vinculoNome}
        />
      </Box>
      <Box className={classes.rightContainer}>
        <Box height={HEADER_HEIGHT}>
          <PageHeader title="Consultar Evento S-2240" subtitle={vinculo?.vinculoNome || '-'}>
            <ButtonBox>
              <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
              <Button
                size="small"
                color="primary"
                onClick={() => openDialogGerarEvento()}
                variant="contained"
              >
                Gerar Evento
              </Button>
            </ButtonBox>
          </PageHeader>
        </Box>

        <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
          <Table
            data={collection}
            isLoading={isLoadingMenuOnClick}
            query={query}
            onItemClick={handleClickItem}
          />
        </Box>

        <DialogResponsaveis
          data={dataDialogResponsaveis}
          vinculo={vinculo}
          isOpen={isOpenDialogResponsaveis}
          onClose={closeDialogResponsaveis}
        />

        <DialogAgentesNociveis
          data={dataDialogAgentesNocivos}
          vinculo={vinculo}
          isOpen={isOpenDialogAgentesNocivos}
          onClose={closeDialogAgentesNocivos}
        />

        {isOpenDialogGerarEvento && (
          <GerarEvento
            isOpen={isOpenDialogGerarEvento}
            onClose={closeDialogGerarEvento}
            handleAfterSubmit={handleAfterSubmitFormGenerate}
          />
        )}
      </Box>
    </Box>
  )
}
