export enum IndUnidadeSalarioEnum {
  Hora_1 = 1,
  Dia_2 = 2,
  Semana_3 = 3,
  Quinzena_4 = 4,
  Mes_5 = 5,
  Tarefa_6 = 6,
  NaoAplicavel_7 = 7,
}

export const IndUnidadeSalarioLabels = {
  [IndUnidadeSalarioEnum.Hora_1]: '1 - Por Hora',
  [IndUnidadeSalarioEnum.Dia_2]: '2 - Por Dia',
  [IndUnidadeSalarioEnum.Semana_3]: '3 - Por Semana',
  [IndUnidadeSalarioEnum.Quinzena_4]: '4 - Por Quinzena',
  [IndUnidadeSalarioEnum.Mes_5]: '5 - Por Mês',
  [IndUnidadeSalarioEnum.Tarefa_6]: '6 - Por Tarefa',
  [IndUnidadeSalarioEnum.NaoAplicavel_7]: '7 - Variável',
}

export const IndUnidadeSalarioValues = [
  { value: IndUnidadeSalarioEnum.Hora_1, name: '1 - Por Hora' },
  { value: IndUnidadeSalarioEnum.Dia_2, name: '2 - Por Dia' },
  { value: IndUnidadeSalarioEnum.Semana_3, name: '3 - Por Semana' },
  { value: IndUnidadeSalarioEnum.Quinzena_4, name: '4 - Por Quinzena' },
  { value: IndUnidadeSalarioEnum.Mes_5, name: '5 - Por Mês' },
  { value: IndUnidadeSalarioEnum.Tarefa_6, name: '6 - Por Tarefa' },
  { value: IndUnidadeSalarioEnum.NaoAplicavel_7, name: '7 - Variável' },
]
