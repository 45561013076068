import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { makeStyles, IconButton } from '@material-ui/core'

import { Edit as EditIcon } from '@material-ui/icons'

import { ToolsDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'

import { formatToCNPJ, formatToCPF } from 'brazilian-values'
import moment from 'moment'

const formatAvisoPrevio = (value) => {
  const maps = {
    1: 'Sim',
    2: 'Não',
  }
  return maps[value]
}

const useStyles = makeStyles(() => ({
  aliengRight: {
    textAlign: 'right',
  },
  fixedWidth: {
    minWidth: '120px',
  },
}))

const Table = (props) => {
  const {
    data: _data,
    isLoading,
    onRowSelected,
    rowsSelecteds,
    onItemClick,
    query,
    triggersHeight,
  } = props

  const classes = useStyles()
  const { formatCurrency } = useUtils()

  const data = _data.map((obj) => {
    return {
      ...obj,
      nrInscricao: obj?.nrInscricao
        ? obj.nrInscricao >= 14
          ? formatToCNPJ(obj.nrInscricao)
          : formatToCPF(obj.nrInscricao)
        : null,
      avisoPrevio: obj?.avisoPrevio ? formatAvisoPrevio(obj.avisoPrevio) : null,
      dtAdmissao: obj?.dtAdmissao ? moment(obj.dtAdmissao).format('DD/MM/YYYY') : null,
      dtRescisao: obj?.dtRescisao ? moment(obj.dtRescisao).format('DD/MM/YYYY') : null,
      dtGeracaoSD: obj?.dtGeracaoSD ? moment(obj.dtGeracaoSD).format('DD/MM/YYYY') : null,
      antePenultimoSalario: obj?.antePenultimoSalario
        ? formatCurrency(obj.antePenultimoSalario)
        : null,
      penultimoSalario: obj?.penultimoSalario ? formatCurrency(obj.penultimoSalario) : null,
      ultimoSalario: obj?.ultimoSalario ? formatCurrency(obj.ultimoSalario) : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'vinculoNome',
        label: 'Funcionário',
      },
      {
        name: 'nrInscricao',
        label: 'CNPJ/CEI',
      },
      {
        name: 'nomeEmpregador',
        label: 'Empresa',
      },
      {
        name: 'avisoPrevio',
        label: 'Aviso Prévio Indenizado',
        options: {
          setCellHeaderProps: () => ({
            className: classes.fixedWidth,
          }),
        },
      },
      {
        name: 'dtAdmissao',
        label: 'Data de Admissão',
      },
      {
        name: 'dtRescisao',
        label: 'Data de Rescisão',
      },
      {
        name: 'dtGeracaoSD',
        label: 'Data de Entrega',
      },
      {
        name: 'antePenultimoSalario',
        label: 'Antepenúltimo Salário',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'penultimoSalario',
        label: 'Penúltimo Salário',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'ultimoSalario',
        label: 'Último Salário',
        options: {
          setCellProps: () => ({
            className: classes.aliengRight,
          }),
        },
      },
      {
        name: 'vinculoId',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Alterar Salários"
                  color="primary"
                  aria-label="Alterar"
                  onClick={() => {
                    onItemClick(value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick, classes],
  )

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      triggersHeight={triggersHeight}
      sherlock={{
        columns: ['vinculoNome', 'nrInscricao', 'nomeEmpregador'],
        query,
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected: onRowSelected,
        rowsSelected: rowsSelecteds,
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
