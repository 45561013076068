const vencimento_1 = 1
const desconto_2 = 2
const informativo_3 = 3
const informativoDedutora_4 = 4

export const indEventoValues = [
  { value: vencimento_1, name: '1 - Vencimento' },
  { value: desconto_2, name: '2 - Desconto' },
  { value: informativo_3, name: '3 - Informativo' },
  { value: informativoDedutora_4, name: '4 - Informativo Dedutora' },
]

export const indEventoValuesWithoutInd = [
  { value: vencimento_1, name: 'Vencimento' },
  { value: desconto_2, name: 'Desconto' },
  { value: informativo_3, name: 'Informativo' },
  { value: informativoDedutora_4, name: 'Informativo Dedutora' },
]

export const indEventoConsts = {
  vencimento_1,
  desconto_2,
  informativo_3,
  informativoDedutora_4,
}
