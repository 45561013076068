import { useState, useCallback, useEffect } from 'react'

import { Grid, Box, Paper, makeStyles, LinearProgress } from '@material-ui/core'
import {
  PageHeader,
  ContentDivider,
  Button,
  TextField,
  ErrorMessage,
  ButtonBox,
} from 'mio-library-ui'

import {
  AutoCompleteEstabelecimento,
  MUIAutoComplete,
  AutoCompleteVinculo,
} from '~/components/AutoComplete'
import { AlertContainer, DatePicker } from '~/components'

import VinculoHorario from './components/VinculoHorario'
import VinculoLotacao from './components/VinculoLotacao'
import VinculoLocal from './components/VinculoLocal'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { useVinculoObterMatricula } from '~/hooks/queries/Vinculo/useVinculoObterMatricula'

import api from '~/services/api-pessoal'

import { formatToCNPJ } from 'brazilian-values'
import moment from 'moment'
import * as yup from 'yup'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import {
  IndMotivoDesligamentoValues,
  IndMotivoDesligamentoEnum,
} from '~/@types/enums/IndMotivoDesligamentoEnum'
import { RescisaoFGTSValues, RescisaoFGTSEnum } from '~/@types/enums/RescisaoFGTSEnum'
import { IndCadastroMatriculaEnum } from '~/@types/enums/IndCadastroMatriculaEnum'

const onlysMotivoDesligamento = [
  IndMotivoDesligamentoEnum.TransferênciaEmpregadoMesmoGrupo_11,
  IndMotivoDesligamentoEnum.TransferênciaEmpregadoConsorciada_12,
  IndMotivoDesligamentoEnum.TransferênciaEmpregadoConsorcio_13,
]

const onlyTransferenciaRescisaoFGTS = [
  RescisaoFGTSEnum.Transferencia_empregado_para_outro_estabelecimento_mesma_empresa,
  RescisaoFGTSEnum.Transferência_empregado_para_outra_empresa_tenha_assumido_encargos_trabalhistas,
]

const newMotivosDesligamento = IndMotivoDesligamentoValues.filter((obj) =>
  onlysMotivoDesligamento.includes(obj.value),
)
const optionsTransfereincaRescisaoFGTS = RescisaoFGTSValues.filter((obj) =>
  onlyTransferenciaRescisaoFGTS.includes(obj.value),
)

const schema = yup.object().shape({
  vinculo: yup.string().required('Informe o Funcionário').nullable(),
  estabelecimentoDestino: yup.string().required('Informe o Estabelecimento Destino').nullable(),
  dtSaida: yup
    .date()
    .required('Informe a Data Saída')
    .typeError('Informe uma Data válida')
    .nullable(),
  motivoDesligamento: yup.string().required('Informe o Motivo do Desligamento'),
  dtEntrada: yup
    .date()
    .required('Informe a Data Entrada')
    .typeError('Informe uma Data válida')
    .nullable(),
  tipoTransferencia: yup.string().required('Informe o Tipo de Transferência'),
  vinculoHorario: yup.string().required('Informe um Horário de Trabalho').nullable(),
  vinculoLocal: yup.string().required('Informe um Local').nullable(),
  vinculoLotacao: yup.string().required('Informe uma Lotação').nullable(),
  rescisaoFGTS: yup.string().required('Informe a Rescisão FGTS').nullable(),
  novaMatricula: yup.string().required('Informe a Nova Matrícula'),
})

const obterError = (name, validationErrors) => {
  if (!validationErrors) return false

  const { inner } = validationErrors
  const erroEncontrado = inner.find((item) => {
    const { path } = item
    return name === path
  })

  if (!erroEncontrado) return false

  return erroEncontrado.message
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  rootForm: {
    border: theme.shape.border,
    padding: theme.spacing(2),
    margin: 'auto',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const Transferencia = () => {
  const [data, setData] = useState({})

  const [dialogVinculoHorario, setDialogVinculoHorario] = useState({
    isOpen: false,
    data: {},
  })
  const [dialogVinculoLotacao, setDialogVinculoLotacao] = useState({
    isOpen: false,
    data: {},
  })
  const [dialogVinculoLocal, setDialogVinculoLocal] = useState({
    isOpen: false,
    data: {},
  })

  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles()
  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData((oldState) => ({
      ...oldState,
      vinculoLotacao: null,
      vinculoHorario: null,
      vinculoLocal: null,
    }))
    // eslint-disable-next-line
  }, [data.vinculo, data.dtEntrada, data.estabelecimentoDestino])

  const {
    data: matricula,
    isLoading: _isLoadingMatricula,
    isFetching: isFetchingMatricula,
  } = useVinculoObterMatricula({
    estabelecimentoId: data?.estabelecimentoDestino?.id,
    cpf: data?.vinculo?.pessoaCPF || '',
  })
  const isLoadingMatricula = _isLoadingMatricula || isFetchingMatricula

  const isMatriculaSequencial =
    matricula?.indCadastroMatricula !== IndCadastroMatriculaEnum.Preencher_00

  useEffect(() => {
    if (!matricula) return
    setData((prev) => {
      if (prev.estabelecimentoDestino.empregadorId === estabelecimento.empregadorId) {
        return { ...prev, novaMatricula: prev.vinculo.matricula }
      }
      return { ...prev, novaMatricula: matricula.proximaMatricula }
    })
  }, [matricula, estabelecimento])

  useEffect(() => {
    setData({})
  }, [estabelecimento, anoMes])

  const handleSubmit = useCallback(() => {
    const insert = async (cloned) => {
      setIsLoading(true)
      try {
        await api.post(`Vinculo/Transferencia?vinculoId=${data?.vinculoId}`, cloned)
        setData({})
        notification.post()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    const cloned = JSON.parse(JSON.stringify(data))

    delete cloned.vinculo
    delete cloned.vinculoId
    delete cloned.estabelecimentoDestino

    delete cloned.vinculoLotacao.lotacao

    delete cloned.vinculoLocal.departamento
    delete cloned.vinculoLocal.setor
    delete cloned.vinculoLocal.cargo

    cloned.novaMatricula = cloned.novaMatricula.trim()

    cloned.vinculoHorario.vinculoHorarioItens.forEach((item) => {
      delete item.horarioTrabalho
    })

    insert(cloned)
    //eslint-disable-next-line
  }, [data])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const changeObservacaoPadrao = (dtSaida, dtEntrada) => {
    let observacaoPadraoSaida = ''
    let observacaoPadraoEntrada = ''

    if (dtSaida && dtSaida !== 'Invalid date' && data?.estabelecimentoDestino)
      observacaoPadraoSaida = `Funcionário transferido para a Empresa ${
        data?.estabelecimentoDestino?.nome
      } CNPJ ${formatToCNPJ(data?.estabelecimentoDestino?.nrInscricao)} na data ${moment(
        dtSaida,
      ).format('DD/MM/YYYY')}`

    if (dtEntrada && dtEntrada !== 'Invalid date' && data?.vinculo)
      observacaoPadraoEntrada = `Funcionário transferido da Empresa ${
        data?.vinculo?.estabelecimentoNome
      } CNPJ ${formatToCNPJ(data?.vinculo?.estabelecimentoNrInscricao)} na data ${moment(
        dtEntrada,
      ).format('DD/MM/YYYY')}`

    setData((oldState) => ({
      ...oldState,
      obsSaida: observacaoPadraoSaida,
      obsEntrada: observacaoPadraoEntrada,
    }))
  }

  const handleClickAddVinculoHorario = () => {
    let dataForVinculoHorario = {
      vinculoId: data.vinculoId,
      vinculoHorarioItens: [],
      dtIncio: data.dtEntrada,
      observacao: 'Registro inserido na transferência do Funcionário',
    }
    if (data.vinculoHorario) {
      dataForVinculoHorario = data.vinculoHorario
    }

    setDialogVinculoHorario((oldState) => ({
      ...oldState,
      isOpen: true,
      data: dataForVinculoHorario,
    }))
  }

  const handleCloseDialogVinculoHorario = () => {
    setDialogVinculoHorario((oldState) => ({
      ...oldState,
      isOpen: false,
      data: {},
    }))
  }

  const handleAfterSubmitFormVinculoHorario = useCallback(
    (value) => {
      setData((oldState) => ({
        ...oldState,
        vinculoHorario: value,
      }))
      handleCloseDialogVinculoHorario()
    },
    //eslint-disable-next-line
    [data?.vinculoHorario],
  )

  const handleClickAddVinculoLotacao = () => {
    let dataForVinculoLotacao = {
      vinculoId: data.vinculoId,
      anoMes: moment(data.dtEntrada).format('YYYYMM'),
      observacao: 'Registro inserido na transferência do Funcionário',
    }
    if (data.vinculoLotacao) {
      dataForVinculoLotacao = data.vinculoLotacao
    }

    setDialogVinculoLotacao((oldState) => ({
      ...oldState,
      isOpen: true,
      data: dataForVinculoLotacao,
    }))
  }

  const handleCloseDialogVinculoLotacao = () => {
    setDialogVinculoLotacao((oldState) => ({
      ...oldState,
      isOpen: false,
      data: {},
    }))
  }

  const handleAfterSubmitFormVinculoLotacao = useCallback(
    (value) => {
      setData((oldState) => ({
        ...oldState,
        vinculoLotacao: value,
      }))
      handleCloseDialogVinculoLotacao()
    },
    //eslint-disable-next-line
    [data?.vinculoLotacao],
  )

  const handleClickAddVinculoLocal = () => {
    let dataForVinculoLocal = {
      vinculoId: data.vinculoId,
      dtLocal: data.dtEntrada,
      observacao: 'Registro inserido na transferência do Funcionário',
    }
    if (data.vinculoLocal) {
      dataForVinculoLocal = data.vinculoLocal
    }

    setDialogVinculoLocal((oldState) => ({
      ...oldState,
      isOpen: true,
      data: dataForVinculoLocal,
    }))
  }

  const handleCloseDialogVinculoLocal = () => {
    setDialogVinculoLocal((oldState) => ({
      ...oldState,
      isOpen: false,
      data: {},
    }))
  }

  const handleAfterSubmitFormVinculoLocal = useCallback(
    (value) => {
      setData((oldState) => ({
        ...oldState,
        vinculoLocal: value,
      }))
      handleCloseDialogVinculoLocal()
    },
    //eslint-disable-next-line
    [data?.vinculoLocal],
  )

  return (
    <Box className={classes.root}>
      <PageHeader title="Transferência" />
      <Paper className={classes.rootForm}>
        <Grid spacing={2} container>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <AutoCompleteVinculo
              label="Funcionário"
              required
              vinculosTipo={[VinculoTipoEnum.Funcionario_1]}
              validationErrors={validationErrors}
              name="vinculo"
              value={data?.vinculo || null}
              estabelecimentoId={estabelecimento?.id}
              anoMes={anoMes}
              onChange={(e, vinculo) => {
                const vinculoId = vinculo?.id || null
                setData((oldState) => ({
                  ...oldState,
                  vinculoId,
                  vinculo,
                  dtEntrada: '',
                  dtSaida: '',
                  obsSaida: '',
                  obsEntrada: '',
                }))
              }}
            />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <AutoCompleteEstabelecimento
              required
              validationErrors={validationErrors}
              name="estabelecimentoDestino"
              label="Estabelecimento Destino"
              value={data?.estabelecimentoDestino || null}
              onChange={(e, estabelecimentoDestino) => {
                const estabelecimentoDestinoId = estabelecimentoDestino?.id || null
                changeObservacaoPadrao()
                setData((oldState) => ({
                  ...oldState,
                  estabelecimentoDestinoId,
                  estabelecimentoDestino,
                  dtEntrada: '',
                  dtSaida: '',
                  obsSaida: '',
                  obsEntrada: '',
                }))
              }}
            />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <ContentDivider title="Saída" />
          </Grid>

          <Grid xl={3} lg={3} md={4} sm={12} xs={12} item>
            <DatePicker
              required
              validationErrors={validationErrors}
              name="dtSaida"
              label="Data Saída"
              size="small"
              value={data?.dtSaida || null}
              onChange={(date) => {
                const dtSaida = date?.format('yyyy-MM-DD') || null
                let dtEntrada = null
                if (dtSaida !== 'Invalid date')
                  dtEntrada = moment(dtSaida).add(1, 'd').format('yyyy-MM-DD')
                changeObservacaoPadrao(dtSaida, dtEntrada)
                setData((oldState) => ({
                  ...oldState,
                  dtSaida,
                  dtEntrada,
                }))
              }}
            />
          </Grid>

          <Grid xl={9} lg={9} md={8} sm={12} xs={12} item>
            <MUIAutoComplete
              required
              validationErrors={validationErrors}
              name="motivoDesligamento"
              label="Motivo de Transferência"
              value={data.motivoDesligamento}
              options={newMotivosDesligamento}
              optionId="value"
              renderOption={(option) => option.name}
              onChange={(e, obj) => {
                const motivoDesligamento = obj ? obj.value : ''
                const tipoTransferenciaMaps = {
                  [IndMotivoDesligamentoEnum.TransferênciaEmpregadoMesmoGrupo_11]: '2',
                  [IndMotivoDesligamentoEnum.TransferênciaEmpregadoConsorciada_12]: '3',
                  [IndMotivoDesligamentoEnum.TransferênciaEmpregadoConsorcio_13]: '4',
                }
                setData((oldState) => ({
                  ...oldState,
                  motivoDesligamento,
                  tipoTransferencia: tipoTransferenciaMaps[motivoDesligamento],
                }))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <MUIAutoComplete
              label="Rescisão FGTS"
              options={optionsTransfereincaRescisaoFGTS}
              renderOption={(option) => `${option.value} - ${option.name}`}
              value={data?.rescisaoFGTS || ''}
              onChange={(e, obj) => {
                const rescisaoFGTS = obj?.value || ''
                setData((oldState) => ({ ...oldState, rescisaoFGTS }))
              }}
              optionId="value"
              required
              validationErrors={validationErrors}
              name="rescisaoFGTS"
            />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <TextField
              label="Observação de Saída"
              fullWidth
              value={data?.obsSaida || ''}
              variant="outlined"
              size="small"
              multiline
              rows={2}
              inputProps={{ maxLength: 250 }}
              onChange={(e) => {
                const obsSaida = e?.target.value || ''
                setData((oldState) => ({ ...oldState, obsSaida }))
              }}
            />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <ContentDivider title="Entrada" />
          </Grid>

          <Grid xl={3} lg={3} md={4} sm={12} xs={12} item>
            <DatePicker
              required
              validationErrors={validationErrors}
              name="dtEntrada"
              label="Data Entrada"
              size="small"
              value={data?.dtEntrada || null}
              onChange={(date) => {
                const dtEntrada = date?.format('yyyy-MM-DD') || null
                changeObservacaoPadrao(data?.dtSaida, dtEntrada)
                setData((oldState) => ({
                  ...oldState,
                  dtEntrada,
                }))
              }}
            />
          </Grid>

          <Grid xl={9} lg={9} md={8} sm={12} xs={12} item>
            <MUIAutoComplete
              required
              validationErrors={validationErrors}
              name="tipoTransferencia"
              label="Tipo de Transferência"
              value={data?.tipoTransferencia || ''}
              options={[
                {
                  value: '2',
                  descricao: 'Transferência de empresa do mesmo grupo econômico',
                },
                {
                  value: '3',
                  descricao: 'Transferência de empresa consorciada ou de consórcio',
                },
                {
                  value: '4',
                  descricao: 'Transferência por motivo de sucessão, incorporação, cisão ou fusão',
                },
                {
                  value: '5',
                  descricao:
                    'Transferência do empregado doméstico para outro representante da mesma unidade familiar',
                },
              ]}
              optionId="value"
              renderOption={(option) => option.descricao}
              onChange={(e, obj) => {
                const tipoTransferencia = obj?.value || ''
                setData((oldState) => ({
                  ...oldState,
                  tipoTransferencia,
                }))
              }}
            />
          </Grid>

          {!isMatriculaSequencial && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AlertContainer severity="info" title="Matrícula anterior do empregador">
                Matrícula anterior: <strong>{matricula?.ultimaMatricula}</strong>
              </AlertContainer>
            </Grid>
          )}

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            {isLoadingMatricula && <LinearProgress />}
            <TextField
              label="Nova Matrícula"
              fullWidth
              value={data?.novaMatricula || ''}
              variant="outlined"
              size="small"
              required
              disabled={isMatriculaSequencial ? true : false}
              title={
                isMatriculaSequencial
                  ? 'A matrícula está configurada como sequêncial no empregador'
                  : ''
              }
              validationErrors={validationErrors}
              name="novaMatricula"
              inputProps={{ maxLength: 250 }}
              onChange={(e) => {
                const novaMatricula = e?.target.value || ''
                setData((oldState) => ({ ...oldState, novaMatricula }))
              }}
            />
          </Grid>

          <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
            <Button
              fullWidth
              size="small"
              color="primary"
              variant={data?.vinculoHorario ? 'contained' : 'outlined'}
              onClick={handleClickAddVinculoHorario}
              disabled={
                data?.vinculoId && data?.estabelecimentoDestinoId && data?.dtEntrada?.length === 10
                  ? false
                  : true
              }
            >
              Cadastrar Horário de Trabalho
            </Button>
            <ErrorMessage error={obterError('vinculoHorario', validationErrors)} />
          </Grid>

          <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
            <Button
              fullWidth
              size="small"
              color="primary"
              variant={data?.vinculoLotacao ? 'contained' : 'outlined'}
              onClick={handleClickAddVinculoLotacao}
              disabled={
                data?.vinculoId && data?.estabelecimentoDestinoId && data?.dtEntrada?.length === 10
                  ? false
                  : true
              }
            >
              Cadastrar Lotação
            </Button>
            <ErrorMessage error={obterError('vinculoLotacao', validationErrors)} />
          </Grid>

          <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
            <Button
              fullWidth
              size="small"
              color="primary"
              variant={data?.vinculoLocal ? 'contained' : 'outlined'}
              onClick={handleClickAddVinculoLocal}
              disabled={
                data?.vinculoId && data?.estabelecimentoDestinoId && data?.dtEntrada?.length === 10
                  ? false
                  : true
              }
            >
              Cadastrar Ambiente de Trabalho
            </Button>
            <ErrorMessage error={obterError('vinculoLocal', validationErrors)} />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <TextField
              label="Observação de Entrada"
              fullWidth
              value={data?.obsEntrada || ''}
              variant="outlined"
              size="small"
              multiline
              rows={2}
              inputProps={{ maxLength: 250 }}
              onChange={(e) => {
                const obsEntrada = e?.target.value || ''
                setData((oldState) => ({ ...oldState, obsEntrada }))
              }}
            />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <ButtonBox>
              <Button
                fullWidth
                onClick={handleValidate}
                isLoading={isLoading}
                color="primary"
                variant="contained"
                size="small"
              >
                Processar
              </Button>
            </ButtonBox>
          </Grid>
        </Grid>
      </Paper>

      <VinculoHorario
        isOpen={dialogVinculoHorario.isOpen}
        onClose={handleCloseDialogVinculoHorario}
        onAfterSubmitForm={handleAfterSubmitFormVinculoHorario}
        data={dialogVinculoHorario.data}
        empregadorId={data?.estabelecimentoDestino?.empregadorId}
      />

      <VinculoLotacao
        isOpen={dialogVinculoLotacao.isOpen}
        onClose={handleCloseDialogVinculoLotacao}
        onAfterSubmitForm={handleAfterSubmitFormVinculoLotacao}
        data={dialogVinculoLotacao.data}
        empregadorId={data?.estabelecimentoDestino?.empregadorId}
      />

      <VinculoLocal
        isOpen={dialogVinculoLocal.isOpen}
        onClose={handleCloseDialogVinculoLocal}
        onAfterSubmitForm={handleAfterSubmitFormVinculoLocal}
        data={dialogVinculoLocal.data}
        empregadorId={data?.estabelecimentoDestino?.empregadorId}
      />
    </Box>
  )
}

export default Transferencia
