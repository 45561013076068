export enum CessaoInformacaoOnusEnum {
  OnusCcedente_1 = 1,
  OnusCessionário_2 = 2,
  OnusCedenteCessionário_3 = 3,
  NaoAplicavel_9 = 9,
}

export const CessaoInformacaoOnusValues = [
  { value: CessaoInformacaoOnusEnum.NaoAplicavel_9, name: '9 - Não informar' },
  { value: CessaoInformacaoOnusEnum.OnusCcedente_1, name: '1 - Cedente' },
  { value: CessaoInformacaoOnusEnum.OnusCessionário_2, name: '2 - Cessionário' },
  { value: CessaoInformacaoOnusEnum.OnusCedenteCessionário_3, name: '3 - Cedente e Cessionário' },
]
