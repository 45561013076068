import React from 'react'

import { Box, ButtonProps as MuiButtonProps, useTheme } from '@material-ui/core'
import { FaFileExcel } from 'react-icons/fa'
import { Button } from '~/components'

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean
  children?: React.ReactNode
}

export default function ExcelButton(props: ButtonProps) {
  const { children, ...rest } = props

  const theme = useTheme()

  return (
    <Button size="small" variant="contained" color="primary" {...rest}>
      <Box display="flex" gridGap={theme.spacing(1)} alignItems="center">
        <FaFileExcel />
        {children ? children : 'Excel'}
      </Box>
    </Button>
  )
}
