import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ProcessoTrabalhistaTributosIRRF } from './dtos/ProcessoTrabalhistaTributosIRRF'

const query = '/ProcessoTrabalhistaTributosIRRF'

interface RequestProps {
  params: {
    processoTrabalhistaTributosId: string
    processoTrabalhistaVinculoId?: string
  }
}

export function useObterTodos({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params.processoTrabalhistaVinculoId) return
    const response = await api.get<{ data: ProcessoTrabalhistaTributosIRRF[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useObterTodosInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
