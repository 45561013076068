import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { AlertaFeriasVencidaDTO } from './AlertaFeriasVencidaDTO'

export const queryObterAlertaFeriasVencidas = '/RPFerias/ObterAlertaFeriasVencidas'

interface RequestProps {
  vinculoId: string
  anoMes: string
}

export function useObterAlertaFeriasVencidas(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: AlertaFeriasVencidaDTO[] }>(
      queryObterAlertaFeriasVencidas,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterAlertaFeriasVencidas, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
