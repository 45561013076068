import { formatToCPFOrCNPJ } from 'brazilian-values'

import { ToolsDataTable } from '~/components'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

interface TableProps {
  data: VinculoConsulta[]
  isLoading: boolean
  isFetching: boolean
  onRowSelected: (index: number[]) => void
  rowsSelected: number[]
  query: string
}

export function Table(props: TableProps) {
  const { data: _data, isLoading, isFetching, onRowSelected, rowsSelected, query } = props

  const data = _data.map((d) => ({
    ...d,
    pessoaCPF: d?.pessoaCPF ? formatToCPFOrCNPJ(d.pessoaCPF) : '',
  }))

  const columns = [
    {
      label: 'Código',
      name: 'pessoaCodigo',
    },
    {
      label: 'CPF',
      name: 'pessoaCPF',
    },
    {
      label: 'Nome',
      name: 'pessoaNome',
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        columns: ['pessoaCodigo', 'pessoaCPF', 'pessoaNome'],
        query,
      }}
      optionsSelectable={{
        onRowSelected,
        selectOnClick: true,
        rowsSelected,
        type: 'multiple',
      }}
    />
  )
}
