import React, { useState } from 'react'

import { ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import TableRPItens from './TableRPItens'

import { formatAnoMes, formatCurrency } from '~/hooks/useUtils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { RPConsultaData } from '..'

interface TableProps {
  data: RPConsultaData[]
  isLoading: boolean
}

export default function Table(props: TableProps) {
  const { data: _data, isLoading } = props

  const [rowsExpanded, setRowsExpanded] = useState([])

  const data = _data.map((d) => ({
    ...d,
    anoMes: formatAnoMes(d.anoMes),
    fatoGerador: formatAnoMes(d.fatoGerador),
  }))

  const columns: MUIDataTableColumnDef[] = [
    { name: 'nrRecibo', label: 'Nr. Recibo' },
    { name: 'anoMes', label: 'Ano/Mês' },
    { name: 'fatoGerador', label: 'Fato Gerador' },
    { name: 'idf', label: 'IDF. Recibo' },
    {
      name: 'vrTotalVencimento',
      label: 'Vencimento',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrTotalDesconto',
      label: 'Desconto',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrTotalLiquido',
      label: 'Líquido',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'isFinalizado',
      label: 'Finalizado',
      options: {
        customBodyRender: (value) => <Ativo ativo={value} />,
      },
    },
    { name: 'nrReciboeSocial', label: 'Nr. Recibo eSocial' },
  ]
  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      optionsExpandable={{
        type: 'single',
        onRowExpanded: setRowsExpanded,
        expandedChildren: (index) => <TableRPItens rpId={data[index].id} />,
        rowsExpanded: rowsExpanded,
        expandOnClick: true,
      }}
    />
  )
}
