import { makeStyles } from '@material-ui/core'

import { ActionDialog } from '~/components'

import VinculoFaltasContent from '~/pages/VinculoFaltas/Content'

import useAmbiente from '~/hooks/useAmbiente'
import { formatAnoMes } from '~/hooks/useUtils'

import { RP } from '~/hooks/queries/RP/RP'

const useStyles = makeStyles(() => ({
  heigthPaperSmall: {
    height: '60%',
    minHeight: 200,
  },
}))

interface DialogFaltasProps {
  reciboPagamento: RP | null
  isOpen: boolean
  onClose: () => void
}

export default function DialogFaltas(props: DialogFaltasProps) {
  const { reciboPagamento, isOpen, onClose } = props

  const { anoMes } = useAmbiente()
  const classes = useStyles()

  return (
    <ActionDialog
      title="Faltas"
      subtitle={`${reciboPagamento?.vinculo?.pessoaFisica?.nome || '-'} - ${formatAnoMes(anoMes)}`}
      isOpen={isOpen}
      onClose={() => onClose()}
      customActions={<div />}
      onCancelClick={() => onClose()}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: classes.heigthPaperSmall,
        },
      }}
    >
      {isOpen && reciboPagamento ? (
        <VinculoFaltasContent vinculoId={reciboPagamento.vinculoId} />
      ) : (
        <></>
      )}
    </ActionDialog>
  )
}
