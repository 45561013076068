import { useEffect, useState } from 'react'

import { formatToCPF } from 'brazilian-values'
import api from '~/services/api-pessoal'

import { MUIAutoComplete } from '~/components/AutoComplete'

import { Vinculo } from '~/hooks/queries/Vinculo/Vinculo'

interface AutoCompleteSociosResponsaveisProps {
  value: string | null
  onChange: (value: Vinculo | null) => void
  empregadorId: string | null
  required: boolean
  validationErrors: ValidationErrors
  name: string
}

export default function AutoCompleteSociosResponsaveis(props: AutoCompleteSociosResponsaveisProps) {
  const { value, onChange, empregadorId, required, validationErrors, name } = props

  const [options, setOptions] = useState<Vinculo[]>([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setOptions([])
    onChange(null)
    // eslint-disable-next-line
  }, [empregadorId])

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/Vinculo/ObterSociosResponsaveisEmpresa', {
        params: {
          empregadorId,
        },
      })
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option: Vinculo) =>
    option.pessoaFisica
      ? `${formatToCPF(option.pessoaFisica.nrInscricao)} - ${option.pessoaFisica.nome}`
      : 'Vínculo incorreto'

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label="Sócio Responsável pela Empresa"
      value={value}
      required={required}
      validationErrors={validationErrors}
      name={name}
      onChange={(_, obj: Vinculo) => onChange(obj)}
      optionId="id"
    />
  )
}
