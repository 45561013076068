import React from 'react'

import moment from 'moment'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon, List as ListIcon } from '@material-ui/icons'

import { useAlteracaoContratoCadastroGetByVinculo } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/useAlteracaoContratoCadastroGetByVinculo'

import { HeightDataTable } from '~/components'

import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoAlteracaoContratoCadastro } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/VinculoAlteracaoContratoCadastro'

interface TableProps {
  onItemClick: (
    event: 'edit' | 'delete' | 'props-alteradas',
    id: string | VinculoAlteracaoContratoCadastro,
  ) => void
  query: string
  vinculoId: string
  evento: ESocialEventoEnum.S_2206 | ESocialEventoEnum.S_2306
}

export default function Table(props: TableProps) {
  const { onItemClick, query, vinculoId, evento } = props

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useAlteracaoContratoCadastroGetByVinculo(vinculoId, evento)

  const data = _data
    ? _data.map((item) => {
        return {
          ...item,
          dtAlteracao: item?.dtAlteracao ? moment(item.dtAlteracao).format('DD/MM/yyyy') : '',
          dtReciboEsocial: item?.dtReciboEsocial
            ? moment(item.dtReciboEsocial).format('DD/MM/yyyy')
            : '',
        }
      })
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtAlteracao',
      label: 'Data da Alteração',
    },
    {
      name: 'reciboEsocial',
      label: 'Recibo eSocial',
    },
    {
      name: 'dtReciboEsocial',
      label: 'Data Recibo eSocial',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Registros Alterados"
                color="primary"
                aria-label="Registros"
                onClick={() => {
                  onItemClick('props-alteradas', value)
                }}
              >
                <ListIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dataFinded = data.find((d) => d.id === value)
                  if (!dataFinded) return
                  onItemClick('edit', dataFinded)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                size="small"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['dtAlteracao', 'reciboEsocial', 'dtReciboEsocial'],
      }}
    />
  )
}
