import clsx from 'clsx'
import { Box, Icon, Paper, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      transition: '0.5s',
      opacity: 0.6,
      cursor: 'pointer',
    },
  },
}))

interface IconPaperProps {
  icon: string
  onClick?: () => void
}

export default function IconPaper({ icon, onClick }: IconPaperProps) {
  const classes = useStyles()

  return (
    <Box
      component={Paper}
      borderRadius="50%"
      height={50}
      width={50}
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={clsx({ [classes.root]: onClick })}
      onClick={() => onClick && onClick()}
    >
      <Icon>{icon}</Icon>
    </Box>
  )
}
