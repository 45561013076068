import { useState } from 'react'

import { IconButton, InputAdornment } from '@material-ui/core'
import { Lock, LockOpen } from '@material-ui/icons'

import TextField, { TextFieldProps } from '../TextField'

interface TextFieldLockedProps extends TextFieldProps {
  disabledLocked?: boolean
}

export default function TextFieldLocked({ disabledLocked, ...rest }: TextFieldLockedProps) {
  const [isHover, setHover] = useState(false)
  const [isLocked, setLocked] = useState(true)

  return (
    <TextField
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      disabled={isLocked}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isHover && !disabledLocked && (
              <IconButton size="small" onClick={() => setLocked((prev) => !prev)}>
                {isLocked ? <Lock fontSize="small" /> : <LockOpen fontSize="small" />}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}
