import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Box } from '@material-ui/core'

import { Button, ConfirmDeleteDialog, PageHeader } from '~/components'

import useDialog from '~/hooks/useDialog'
import { useDelete } from '~/hooks/queries/useSindicatoConvencaoColetiva'

import Table from './Table'
import Form from './Form'

export default function SindicatoConvencaoColetiva(props) {
  const { sindicato } = props
  const { mutateAsync, isLoading: isDeleting } = useDelete()

  const { data: dataForm, isOpen: isOpenForm, open: openForm, close: closeForm } = useDialog()
  const {
    data: dataConfirmDelete,
    open: openConfirmDelete,
    isOpen: isOpenConfirmDelete,
    close: closeConfirmDelete,
  } = useDialog('')

  function handleClickItem(event, value) {
    const handleClickEditItem = async (obj) => {
      openForm(obj)
    }

    const handleClickDeleteItem = (id) => {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  async function handleConfirmDelete() {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  function handleClickAddItem() {
    openForm({
      sindicatoId: sindicato?.id,
    })
  }

  return (
    <Box height="100%" p={2}>
      <PageHeader>
        <ButtonBox>
          <Button size="small" color="primary" variant="contained" onClick={handleClickAddItem}>
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Table sindicato={sindicato} onItemClick={handleClickItem} />

      <Form data={dataForm} isOpen={isOpenForm} onClose={closeForm} />

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isDeleting}
        onCancel={closeConfirmDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  )
}
