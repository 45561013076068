import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { SalarioDTO } from './dtos/SalarioDTO'

const query = '/Salario'

export function useObterTodosSalario() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: SalarioDTO[] }>(query)
    return response.data.data
  }

  return useQuery([query], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useObterTodosSalarioInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
