const empregador_1 = 1
const ordemJudicial_2 = 2
const determinacaoOrgaoFiscalizador_3 = 3

export const CATIniciativaValues = [
  { value: empregador_1, name: '1 - Empregador' },
  { value: ordemJudicial_2, name: '2 - Ordem Judicial' },
  {
    value: determinacaoOrgaoFiscalizador_3,
    name: '3 - Determinação de órgão fiscalizador',
  },
]

export const CATIniciativaConsts = {
  empregador_1,
  ordemJudicial_2,
  determinacaoOrgaoFiscalizador_3,
}
