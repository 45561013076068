import React, { useState, useEffect, useCallback } from 'react'

import { Grid, InputAdornment, IconButton } from '@material-ui/core'
import { GetApp as GetAppIcon } from '@material-ui/icons'
import { TextField } from 'mio-library-ui'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { DatePicker, CurrencyTextField, ActionDialog } from '~/components'

import { SuperFinderServico } from '~/components/SuperFinder'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import * as yup from 'yup'
import { CategoriaValues } from '~/@types/enums/CategoriaEnum'

const schema = yup.object().shape({
  dtLancamento: yup.string().required('Informe a Data de Lançamento').nullable(),
  dtPagamento: yup.string().required('Informe a Data de Pagamento').nullable(),
  descricaoServico: yup.string().required('Informe a Descrição do Serviço'),
  vrServico: yup.string().required('Informe o Valor do Serviço'),
  categoria: yup.string().required('Informe a Categoria'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const [isOpenDialogSuperFinder, setOpenDialogSuperFinder] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/RPAutonomo/${data?.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/RPA', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })

  function openSuperFinderServicos() {
    setOpenDialogSuperFinder(true)
  }
  function closeSuperFinderServicos() {
    setOpenDialogSuperFinder(false)
  }

  return (
    <ActionDialog
      title="Cadastro de Lançamento - Autônomo"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Categoria"
            options={CategoriaValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            name="categoria"
            validationErrors={validationErrors}
            value={data.categoria}
            onChange={(e, obj) => {
              const categoria = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                categoria,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data de Lançamento"
            size="small"
            required
            validationErrors={validationErrors}
            name="dtLancamento"
            value={data?.dtLancamento || null}
            onChange={(date) => {
              const dtLancamento = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtLancamento,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data de Pagamento"
            size="small"
            required
            validationErrors={validationErrors}
            name="dtPagamento"
            value={data?.dtPagamento || null}
            onChange={(date) => {
              const dtPagamento = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtPagamento,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CurrencyTextField
            label="Valor do Serviço"
            fullWidth
            value={data?.vrServico || ''}
            variant="outlined"
            size="small"
            required
            name="vrServico"
            validationErrors={validationErrors}
            onChange={(e, value) => {
              const vrServico = value || ''
              setData((oldState) => ({
                ...oldState,
                vrServico,
              }))
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Descrição do Serviço"
            fullWidth
            size="small"
            variant="outlined"
            name="descricaoServico"
            validationErrors={validationErrors}
            required
            value={data?.descricaoServico || ''}
            onChange={(e) => {
              const descricaoServico = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                descricaoServico,
              }))
            }}
            multiline
            inputProps={{
              maxLength: 100,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={openSuperFinderServicos}
                    size="small"
                    title="Abrir Serviços"
                    color="primary"
                  >
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <SuperFinderServico
        isOpen={isOpenDialogSuperFinder}
        onClose={closeSuperFinderServicos}
        onChange={(descricaoServico) => {
          setData((oldState) => ({
            ...oldState,
            descricaoServico,
          }))
        }}
      />
    </ActionDialog>
  )
}

export default Form
