import { useEffect, useState, useCallback } from 'react'

import { PageHeader } from 'mio-library-ui'
import { Box, Grid, makeStyles } from '@material-ui/core'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'
import theme from '~/styles/theme'

import BarChart from './components/BarChart'
import PieChart from './components/PieChart'
import DialogDetails from './components/DialogDetails'

import {
  statusProcessTransmissaoConsts,
  statusProcessTransmissaoValues,
} from '~/values/statusProcessTransmissaoValues'

const HEADER_HEIGHT = '50px'

const {
  statusProcessTransmissao_wait_1,
  statusProcessTransmissao_process_2,
  statusProcessTransmissao_finish_3,
  statusProcessTransmissao_finish_warning_4,
  statusProcessTransmissao_error_5,
} = statusProcessTransmissaoConsts

const {
  primary: waitColor,
  info: processColor,
  success: successColor,
  warning: finishWarningColor,
  danger: errorColor,
} = theme.palette.alerts

const labelsPieChart = statusProcessTransmissaoValues.map((obj) => obj.name)
const colorsPieChart = [waitColor, processColor, successColor, finishWarningColor, errorColor]

function formatLabelChart(status) {
  const element = statusProcessTransmissaoValues.find((obj) => obj.value === status)
  return element?.name || ''
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  rootCharts: {
    height: `calc(100% - ${HEADER_HEIGHT})`,
  },
}))

const Home = () => {
  const [dataDetailsBarChart, setDataDetailsBarChart] = useState({})
  const [dataBarChart, setDataBarChart] = useState({
    labels: [''],
    datasets: [
      {
        label: formatLabelChart(statusProcessTransmissao_wait_1),
        data: [0],
        backgroundColor: waitColor,
      },
      {
        label: formatLabelChart(statusProcessTransmissao_process_2),
        data: [0],
        backgroundColor: processColor,
      },
      {
        label: formatLabelChart(statusProcessTransmissao_finish_3),
        data: [0],
        backgroundColor: successColor,
      },
      {
        label: formatLabelChart(statusProcessTransmissao_finish_warning_4),
        data: [0],
        backgroundColor: finishWarningColor,
      },
      {
        label: formatLabelChart(statusProcessTransmissao_error_5),
        data: [0],
        backgroundColor: errorColor,
      },
    ],
  })
  const [dataDetailsPieChart, setDataDetailsPieChart] = useState({})
  const [dataPieChart, setDataPieChart] = useState({
    labels: ['Sem dados'],
    datasets: [
      {
        data: [1],
        backgroundColor: '#F0F0F0',
      },
    ],
  })
  const [hasDataPieChart, setHasDataPieChart] = useState(false)
  const [isLoadingPieChart, setLoadingPieChart] = useState(false)

  const classes = useStyles()
  const { anoMes, empregador } = useAmbiente()
  const {
    close: closeDialogDetails,
    open: openDialogDetails,
    isOpen: isOpenDialogDetails,
    data: dataDialogDetails,
  } = useDialog({})

  const getDataBarChart = useCallback(async () => {
    if (!empregador?.id) return
    try {
      const response = await api.get('/ESocialDashBoard/ObterPorEmpregador', {
        params: {
          anoMes,
          empregadorId: empregador.id,
        },
      })
      const currentResponse = response?.data?.data
      if (!currentResponse) return
      const { aguardando, processamento, finalizado, finalizadoAdvertencia, erro } = currentResponse

      const newDataChart = {
        labels: [''],
        datasets: [
          {
            label: formatLabelChart(statusProcessTransmissao_wait_1),
            data: [aguardando],
            backgroundColor: waitColor,
          },
          {
            label: formatLabelChart(statusProcessTransmissao_process_2),
            data: [processamento],
            backgroundColor: processColor,
          },
          {
            label: formatLabelChart(statusProcessTransmissao_finish_3),
            data: [finalizado],
            backgroundColor: successColor,
          },
          {
            label: formatLabelChart(statusProcessTransmissao_finish_warning_4),
            data: [finalizadoAdvertencia],
            backgroundColor: finishWarningColor,
          },
          {
            label: formatLabelChart(statusProcessTransmissao_error_5),
            data: [erro],
            backgroundColor: errorColor,
          },
        ],
      }
      setDataBarChart(newDataChart)
      setDataDetailsBarChart(currentResponse)
    } catch (error) {
      console.log(error)
    }
  }, [anoMes, empregador])

  const getDataPieChart = useCallback(async () => {
    setDataPieChart({
      labels: ['Sem dados'],
      datasets: [
        {
          data: [1],
          backgroundColor: '#F0F0F0',
        },
      ],
    })
    setHasDataPieChart(false)
    setLoadingPieChart(true)
    try {
      const response = await api.get('/ESocialDashBoard/ObterGeralPorEmpregador', {
        params: {
          anoMes,
        },
      })
      const responseCurrent = response?.data?.data
      if (!responseCurrent) return

      setDataDetailsPieChart(responseCurrent)

      let aguardando = 0
      let processamento = 0
      let finalizado = 0
      let finalizadoAdvertencia = 0
      let erro = 0

      responseCurrent.forEach(({ eSocialDashboard }) => {
        aguardando += eSocialDashboard.aguardando
        processamento += eSocialDashboard.processamento
        finalizado += eSocialDashboard.finalizado
        finalizadoAdvertencia += eSocialDashboard.finalizadoAdvertencia
        erro += eSocialDashboard.erro
      })

      const newDataChart = {
        labels: labelsPieChart,
        datasets: [
          {
            data: [aguardando, processamento, finalizado, finalizadoAdvertencia, erro],
            backgroundColor: colorsPieChart,
          },
        ],
      }

      if (aguardando || processamento || finalizado || finalizadoAdvertencia || erro) {
        setDataPieChart(newDataChart)
        setHasDataPieChart(true)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingPieChart(false)
    }
  }, [anoMes])

  useEffect(() => {
    getDataBarChart()
    getDataPieChart()
  }, [getDataBarChart, getDataPieChart])

  function handleReloadData() {
    closeDialogDetails()
    getDataBarChart()
    getDataPieChart()
  }

  function handleClickBarChar(status) {
    const detailsCurrent = {
      status: status,
      data: [
        {
          empregadorId: empregador?.id,
          nome: empregador?.nome,
          eSocialDashboard: dataDetailsBarChart,
        },
      ],
    }
    openDialogDetails(detailsCurrent)
  }

  function handleClickPieChart(status) {
    const detailsCurrent = {
      status: status,
      data: dataDetailsPieChart,
    }
    openDialogDetails(detailsCurrent)
  }

  return (
    <Box className={classes.root}>
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Painel" />
      </Box>
      <Grid container spacing={2} className={classes.rootCharts}>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <BarChart data={dataBarChart} empregador={empregador} onClick={handleClickBarChar} />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <PieChart
            data={dataPieChart}
            isLoading={isLoadingPieChart}
            hasData={hasDataPieChart}
            onClick={handleClickPieChart}
          />
        </Grid>
      </Grid>

      <DialogDetails
        isOpen={isOpenDialogDetails}
        onClose={closeDialogDetails}
        data={dataDialogDetails}
        anoMes={anoMes}
        handleReloadData={handleReloadData}
      />
    </Box>
  )
}

export default Home
