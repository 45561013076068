import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useInterromperAfastamento } from '~/hooks/queries/VinculoAfastamento/useInterromperAfastamento'

const schema = yup.object().shape({
  dtInterromperAfastamento: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Interrupção do Afastamento')
    .nullable(),
})

interface DialogInterrupcaoProps {
  isOpen: boolean
  afastamentoId: string
  onClose: () => void
}

export default function DialogInterrupcao(props: DialogInterrupcaoProps) {
  const { isOpen, onClose, afastamentoId } = props

  const [data, setData] = useState<{
    dtInterromperAfastamento: string | null
  }>({
    dtInterromperAfastamento: null,
  })

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  const { mutateAsync: interromperAfastamento, isLoading } = useInterromperAfastamento()

  async function handleSubmit() {
    await interromperAfastamento({
      afastamentoOrigemId: afastamentoId,
      dtInterromperAfastamento: data.dtInterromperAfastamento as string,
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Informe a Data de Interrupção do Afastamento"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
      okLabel="Confirmar"
      cancelLabel="Cancelar"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Interrupção do Afastamento"
            value={data.dtInterromperAfastamento}
            onChange={(date) => {
              const dtInterromperAfastamento = date
              setData((prev) => ({
                ...prev,
                dtInterromperAfastamento,
              }))
            }}
            required
            name="dtInterromperAfastamento"
            validationErrors={validationErrors}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
