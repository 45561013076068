import React, { useState } from 'react'

import { Box } from '@material-ui/core'

import { ButtonBox } from 'mio-library-ui'

import { PageHeader, Button } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import { IndSalarioContratualEnum } from '~/@types/enums/IndSalarioContratualEnum'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useStepperContext } from '~/components/StepperForm'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

import { MainDataForm } from '..'
import { VinculoSalarioBasePartial } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const HEADER_HEIGHT = '50px'

export default function TableSalarioContratual() {
  const { mainData, onBack, onNext, onSubmit } = useStepperContext<MainDataForm>()

  const [collection, setCollection] = useState(mainData.dataSalarioBaseList)

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoSalarioBasePartial | null>(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<number | null>(null)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  function handleClickItem(index: number) {
    openConfirmDelete(index)
  }

  const handleDeleteItem = () => {
    const newItens = collection.filter((i, indexCurrent) => indexCurrent !== dataConfirmDelete)
    setCollection(newItens)
    notification.remove()
    closeConfirmDelete()
  }

  const handleClickAddItem = () => {
    openForm({
      vinculoId: mainData.dataVinculo.id,
      indSalario: IndSalarioContratualEnum.Minimo,
      dtEfetiva: mainData.dataAlteracaoContrato?.dtAlteracao || undefined,
      dtSalario: mainData.dataAlteracaoContrato?.dtAlteracao || undefined,
    })
  }

  const handleAfterInsert = (data: VinculoSalarioBasePartial) => {
    const newItens = [data, ...collection]
    setCollection(newItens)
    closeForm()
  }

  function handleValidate() {
    if (!(collection.length > 0))
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um Salário Contratual'],
      })

    if (mainData?.dataVinculo?.tipoRegistro === VinculoTipoEnum.Funcionario_1) {
      onNext({
        ...mainData,
        dataSalarioBaseList: collection,
      })
    } else {
      onSubmit({
        ...mainData,
        dataSalarioBaseList: collection,
      })
    }
  }

  return (
    <Box>
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Salário Contratual">
          <ButtonBox top={1}>
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={collection} onItemClick={handleClickItem} />
      </Box>

      {isOpenForm && dataForm ? (
        <Form
          isOpen={isOpenForm}
          data={dataForm}
          onClose={closeForm}
          onAfterSubmitForm={handleAfterInsert}
          vinculo={mainData.dataVinculo}
        />
      ) : (
        <></>
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            {mainData?.dataVinculo?.tipoRegistro === VinculoTipoEnum.Funcionario_1
              ? 'Continuar'
              : 'Finalizar'}
          </Button>
        </ButtonBox>
      </Box>
    </Box>
  )
}
