import { useQuery } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import api from '~/services/api-pessoal'
import { HorarioTrabalho } from './HorarioTrabalho'

export default function useHorarioTrabalhoEmpregador(empregadorId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: HorarioTrabalho[] }>(
      `/HorarioTrabalho/${empregadorId}/SelecionarEmpregador`,
    )
    return response.data.data
  }

  return useQuery(['HorarioTrabalho', empregadorId], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
