import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import {
  AutoCompleteGrupo,
  AutoCompleteSalario,
  AutoCompleteSindicato,
  MUIAutoComplete,
} from '~/components/AutoComplete'
import {
  PageHeader,
  ContainerTable,
  StackContainer,
  Finder,
  Button,
  DatePickerNew,
} from '~/components'

import Table from './components/Table'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import { useLocation } from 'react-router-dom'

import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'
import { useProcessarAlteracaoSalarial } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/useProcessarAlteracaoSalarial'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'
import {
  IndSalarioContratualEnum,
  IndSalarioContratualValues,
} from '~/@types/enums/IndSalarioContratualEnum'

const initialDataForm = {
  dtAlteracao: '',
  indSalario: IndSalarioContratualEnum.CategoriaSindical,
  sindicatoId: '',
  salarioId: '',
}

const newIndSalarioContratualValues = IndSalarioContratualValues.filter(
  (item) => item.value !== IndSalarioContratualEnum.Valor,
)

const schema = yup.object().shape({
  dtAlteracao: yup
    .date()
    .required('Informe uma Data de Alteração')
    .typeError('Informe uma data válida')
    .nullable(),
  indSalario: yup
    .mixed()
    .oneOf(Object.values(IndSalarioContratualEnum), 'Informe o Indicador de Salário'),
  sindicatoId: yup
    .string()
    .when(['indSalario'], (indSalario, schema) => {
      if (indSalario === IndSalarioContratualEnum.CategoriaSindical)
        return schema.required('Informe o Sindicato')
    })
    .nullable(),
  salarioId: yup
    .string()
    .when(['indSalario'], (indSalario, schema) => {
      if (indSalario === IndSalarioContratualEnum.TabelaSalario)
        return schema.required('Informe o Salário')
    })
    .nullable(),
})

export default function ProcessarAlteracaoSalario() {
  const [dataForm, setDataForm] = useState<{
    dtAlteracao: string
    indSalario: IndSalarioContratualEnum
    sindicatoId: string | null
    salarioId: string | null
  }>(initialDataForm)
  const [grupoId, setGrupoId] = useState('')
  const [query, setQuery] = useState('')
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const { anoMes } = useAmbiente()
  const theme = useTheme()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useEstabelecimentoObterFiltrado({
    anoMes,
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
    grupoId,
  })
  const collection = _data || []

  useEffect(() => {
    setRowsSelected([])
  }, [_data])

  const { mutateAsync, isLoading: isPedding } = useProcessarAlteracaoSalarial()

  const { validationErrors, handleValidate } = useValidationErrors({
    data: dataForm,
    handleSubmit: handleProcess,
    schema,
  })
  const location = useLocation<
    | {
        sindicatoId: string
        dtAlteracao: string
      }
    | undefined
  >()

  useEffect(() => {
    if (location?.state?.sindicatoId) {
      const state = location.state
      setDataForm((prev) => ({
        ...prev,
        dtAlteracao: state.dtAlteracao,
        sindicatoId: state.sindicatoId,
        indSalario: IndSalarioContratualEnum.CategoriaSindical,
      }))
    }
  }, [location])

  async function handleProcess() {
    const estabelecimentosIds = rowsSelected.map((index) => collection[index].id)
    await mutateAsync({
      data: estabelecimentosIds,
      params: {
        dtSalario: dataForm.dtAlteracao,
        indSalario: dataForm.indSalario,
        sindicatoId: dataForm.sindicatoId,
        salarioId: dataForm.salarioId,
      },
    })
    setRowsSelected([])
    setDataForm(initialDataForm)
  }

  return (
    <StackContainer>
      <PageHeader title="Processar Alteração de Salário(2206)">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Box component={Paper} p={1}>
        <AutoCompleteGrupo value={grupoId} onChange={(e, obj) => setGrupoId(obj?.id || null)} />
      </Box>

      <ContainerTable>
        <Table
          data={collection}
          isLoading={isLoading}
          isFetching={isFetching}
          onRowSelected={setRowsSelected}
          rowsSelected={rowsSelected}
          query={query}
        />
      </ContainerTable>

      <Box component={Paper} p={1} display="flex" alignItems="center" gridGap={theme.spacing(1)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <MUIAutoComplete
              label="Indicador de Salário"
              options={newIndSalarioContratualValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={dataForm.indSalario}
              name="indSalario"
              validationErrors={validationErrors}
              onChange={(e, obj) => {
                const indSalario = obj?.value || ''
                setDataForm((prev) => ({
                  ...prev,
                  indSalario,
                  salarioId: '',
                  sindicatoId: '',
                }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerNew
              label="Data da Alteração"
              value={dataForm?.dtAlteracao || null}
              validationErrors={validationErrors}
              name="dtAlteracao"
              onChange={(date) => {
                const dtAlteracao = date || ''
                setDataForm((prev) => ({ ...prev, dtAlteracao }))
              }}
            />
          </Grid>

          {dataForm.indSalario === IndSalarioContratualEnum.CategoriaSindical && (
            <Grid item xs={12}>
              <AutoCompleteSindicato
                value={dataForm.sindicatoId || ''}
                onChange={(_, obj) => {
                  const sindicatoId = obj?.id || ''
                  setDataForm((prev) => ({
                    ...prev,
                    sindicatoId,
                  }))
                }}
                optionId="id"
                validationErrors={validationErrors}
                name="sindicatoId"
              />
            </Grid>
          )}

          {dataForm.indSalario === IndSalarioContratualEnum.TabelaSalario && (
            <Grid item xs={12}>
              <AutoCompleteSalario
                value={dataForm.salarioId || ''}
                onChange={(obj) => {
                  const salarioId = obj?.id || ''
                  setDataForm((prev) => ({
                    ...prev,
                    salarioId,
                  }))
                }}
                validationErrors={validationErrors}
                name="salarioId"
              />
            </Grid>
          )}
        </Grid>
        <Button
          onClick={handleValidate}
          size="small"
          variant="contained"
          color="primary"
          disabled={rowsSelected.length > 0 ? false : true}
          isLoading={isPedding}
        >
          Processar
        </Button>
      </Box>
    </StackContainer>
  )
}
