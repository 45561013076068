import { useEffect, useState } from 'react'

import { Box, Paper, useTheme } from '@material-ui/core'

import { Button, Finder, PageHeader } from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import { useEmpregadorObterFiltrado } from '~/hooks/queries/EmpregadorConsulta/useEmpregadorObterFiltrado'
import useManutencaoEmpregador from '~/hooks/queries/EmpregadorConsulta/useManutencaoEmpregador'
import useAmbiente from '~/hooks/useAmbiente'

import { IndAtividadeEmpregadorEnum } from '~/@types/enums/IndAtividadeEmpregadorEnum'
import Table from './Table'

import { EmpregadorConsulta } from '~/hooks/queries/EmpregadorConsulta/EmpregadorConsulta'

export default function ManutencaoEmpregador() {
  const [query, setQuery] = useState('')
  const [grupoId, setGrupoId] = useState('')
  const [data, setData] = useState<EmpregadorConsulta[]>([])

  const theme = useTheme()
  const { anoMes } = useAmbiente()

  const {
    data: _d,
    isLoading,
    isFetching,
    remove,
    refetch,
  } = useEmpregadorObterFiltrado({
    indAtividadeEmpregadorEnum: IndAtividadeEmpregadorEnum.Todos,
    grupoId,
    anoMes,
  })
  const { mutateAsync: mutateAsyncUpdateEmpregadores, isLoading: isLoadingUpdateEmpregadores } =
    useManutencaoEmpregador()

  useEffect(() => {
    if (!_d) return
    setData(_d)
  }, [_d])

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title="Manutenção Empregador">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} p={1}>
        <AutoCompleteGrupo
          value={grupoId}
          onChange={(e, grupo) => {
            const grupoId = grupo?.id || null
            setGrupoId(grupoId)
          }}
        />
      </Box>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%">
          <Table
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            query={query}
            onChange={setData}
          />
        </Box>
      </Box>

      <Box
        component={Paper}
        p={1}
        display="flex"
        gridGap={theme.spacing(1)}
        justifyContent="flex-end"
      >
        <Button
          onClick={() => {
            remove()
            refetch()
          }}
        >
          Cancelar
        </Button>
        <Button
          isLoading={isLoadingUpdateEmpregadores}
          variant="contained"
          onClick={async () => await mutateAsyncUpdateEmpregadores(data)}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  )
}
