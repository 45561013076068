import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { AntecipacaoSalarialFuncionarioDTO } from './AntecipacaoSalarialDTO'

export const query = '/AntecipacaoSalarial/ObterFuncionarios'

export default function useObterFuncionarios(estabelecimentoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: AntecipacaoSalarialFuncionarioDTO[] }>(query, {
      params: {
        estabelecimentoId,
      },
    })
    return response.data.data
  }

  return useQuery([query, estabelecimentoId], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
