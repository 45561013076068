import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryObterConvencaoColetiva } from './useObterConvencaoColetiva'

interface RequestProps {
  estabelecimentoId: string
  sindicatoId: string
  anoMes: string
  anoMesInicio: string
  anoMesFim: string
}

export default function useApurarConvencaoColetiva() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const queryClient = useQueryClient()

  async function handleRequest(params: RequestProps) {
    await api.post('/ApurarConvencaoColetiva/ApurarConvencaoColetiva', null, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.success('Recibos apurados com sucesso')
      await queryClient.invalidateQueries(queryObterConvencaoColetiva)
    },
    onError: dialogNotification.extractErrors,
  })
}
