import { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker } from '~/components'
import { AutoCompleteCargo, AutoCompleteSetor } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import * as yup from 'yup'

const schema = yup.object().shape({
  cargoId: yup.string().required('Informe Cargo').nullable(),
})

export default function AmbienteTrabalho(props) {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, empregadorId } = props
  const [data, setData] = useState({})

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    onAfterSubmitForm(data)

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Ambiente de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePicker label="Data" size="small" value={data?.dtLocal || null} disabled />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteSetor
            onChange={(e, setor) => {
              const setorId = setor ? setor.id : ''
              setData((prev) => ({ ...prev, setorId }))
            }}
            value={data?.setorId || ''}
            empregadorId={empregadorId}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteCargo
            validationErrors={validationErrors}
            name="cargoId"
            required
            onChange={(e, cargo) => {
              const cargoId = cargo ? cargo.id : ''
              setData((prev) => ({ ...prev, cargoId }))
            }}
            value={data?.cargoId || ''}
            empregadorId={empregadorId}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data?.observacao || ''}
            variant="outlined"
            size="small"
            multiline
            rows={4}
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
