import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon, Print as PrintIcon } from '@material-ui/icons'

import { formatToCNPJ } from 'brazilian-values'
import moment from 'moment'

import { HeightDataTable } from '~/components'

import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

const { CNPJ_1, CAEPF_3, CNO_4 } = tipoInscricaoConsts

const formatLocalAmbiente = (localAmbiente) => {
  if (!localAmbiente) return ''
  const maps = {
    1: 'Estabelecimento do próprio Empregador',
    2: 'Estabelecimento de terceiros',
  }
  return maps[localAmbiente]
}

const formatCAEPF = (nrInscricao) => {
  if (!nrInscricao) return ''
  const newNrInscricao = `${nrInscricao.substr(0, 3)}.${nrInscricao.substr(
    3,
    3,
  )}.${nrInscricao.substr(6, 3)}/${nrInscricao.substr(9, 3)}-${nrInscricao.substr(12)}`
  return newNrInscricao
}

const formatCNO = (nrInscricao) => {
  if (!nrInscricao) return ''
  const newNrInscricao = `${nrInscricao.substr(0, 2)}.${nrInscricao.substr(
    2,
    3,
  )}.${nrInscricao.substr(5, 5)}/${nrInscricao.substr(10)}`
  return newNrInscricao
}

const formatNrInscricao = (nrInscricao, tipoInscricao) => {
  if (!nrInscricao || !tipoInscricao) return ''
  const maps = {
    [CNPJ_1]: formatToCNPJ(nrInscricao),
    [CAEPF_3]: formatCAEPF(nrInscricao),
    [CNO_4]: formatCNO(nrInscricao),
  }
  return maps[tipoInscricao]
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtInicio: item?.dtInicio ? moment(item.dtInicio).format('DD/MM/yyyy') : '',
      localAmbiente: formatLocalAmbiente(item?.localAmbiente || null),
      dtReciboeSocial: item?.dtReciboeSocial
        ? moment(item.dtReciboeSocial).format('DD/MM/yyyy')
        : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'tipoInscricao',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'dtInicio',
        label: 'Data Início',
      },
      {
        name: 'localAmbiente',
        label: 'Tipo Local',
      },
      {
        name: 'descricaoSetor',
        label: 'Descrição Setor',
      },
      {
        name: 'nrInscricao',
        label: 'Número Inscrição',
        options: {
          customBodyRender: (value, tableMeta) => {
            const tipoInscricao = tableMeta.rowData[0]
            return formatNrInscricao(value, tipoInscricao)
          },
        },
      },
      {
        name: 'reciboeSocial',
        label: 'eSocial',
      },
      {
        name: 'dtReciboeSocial',
        label: 'Data eSocial',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Imprimir este registro"
                  color="primary"
                  aria-label="Imprimir"
                  onClick={() => {
                    onItemClick('print', value)
                  }}
                >
                  <PrintIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['localAmbiente', 'descricaoSetor', 'nrInscricao', 'reciboeSocial'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
