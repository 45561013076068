import React, { useState, useEffect } from 'react'

import { formatToCPF } from 'brazilian-values'
import { useHistory, useParams } from 'react-router-dom'

import { LinearProgress } from '@material-ui/core'

import { ContainerComponent } from '~/components'

import Form from './Form'
import PessoaFisicaDependente from './PessoaFisicaDependente'
import OutrasRemuneracoes from './OutrasRemuneracoes'
import ContaCorrente from './ContaCorrente'
import Estabelecimentos from './Estabelecimentos'

import useDialogNotification from '~/hooks/useDialogNotification'
import { getPessoaFisicaById } from '~/hooks/queries/usePessoaFisica'
import { pessoaFisicaCheckUso } from '~/hooks/queries/PessoaFisica/pessoaFisicaCheckUso'

const Container = () => {
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isUsed, setUsed] = useState(false)

  const history = useHistory()
  const { id: pessoaFisicaId } = useParams()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    const getData = async () => {
      if (!pessoaFisicaId) return
      setLoading(true)
      try {
        const response = await getPessoaFisicaById(pessoaFisicaId)
        const _isUsed = await pessoaFisicaCheckUso(pessoaFisicaId)
        setUsed(_isUsed)
        setData(response)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getData()
    // eslint-disable-next-line
  }, [pessoaFisicaId])

  if (isLoading) {
    return <LinearProgress />
  }

  if (!data) {
    return <></>
  }

  return (
    <ContainerComponent
      title="Cadastro de Pessoa Física"
      subtitle={`${data.nome}-${formatToCPF(data.nrInscricao)}`}
      fixedContainer
      onBack={() => {
        history.push({
          pathname: '/pessoa-fisica',
        })
      }}
      tabs={[
        {
          component: <Form data={data} onClickClose={() => undefined} isUsed={isUsed} />,
          label: 'Cadastro',
        },
        {
          component: <PessoaFisicaDependente pessoaFisicaId={pessoaFisicaId} isUsed={isUsed} />,
          disabled: !pessoaFisicaId ? true : false,
          label: 'Dependentes',
        },
        {
          component: <OutrasRemuneracoes pessoaFisicaId={pessoaFisicaId} />,
          disabled: !pessoaFisicaId ? true : false,
          label: 'Outras Remuneraçoes',
        },
        {
          component: <ContaCorrente pessoaFisicaId={pessoaFisicaId} />,
          disabled: !pessoaFisicaId ? true : false,
          label: 'Conta Corrente',
        },
        {
          component: <Estabelecimentos pessoaFisicaId={pessoaFisicaId} />,
          disabled: !pessoaFisicaId ? true : false,
          label: 'Estabelecimentos',
        },
      ]}
    />
  )
}

export default Container
