import React, { useState } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { Button, PageHeader } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'
import { useStepperContext } from '~/components/StepperForm'

import { VinculoSalarioAdicionalPartial } from '~/hooks/queries/VinculoSalarioAdicional/VinculoSalarioAdicional'
import { MainDataForm } from '..'
import { IndSalarioAdicionalEnum } from '~/@types/enums/IndSalarioAdicionalEnum'

const HEADER_HEIGHT = '50px'

export default function FormSalarioAdicional() {
  const { mainData, onBack, onNext, onSkip } = useStepperContext<MainDataForm>()

  const [collection, setCollection] = useState<VinculoSalarioAdicionalPartial[]>(
    mainData?.dataSalarioAdicional || [],
  )

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoSalarioAdicionalPartial | null>(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<number | null>(null)

  const notification = useNotification()

  const initialStateForm = {
    dtInicio: mainData.dataInitialForm.dtAdmissao,
    indAdicional: IndSalarioAdicionalEnum.Insalubridade_1,
  }

  const handleClickItem = (event: 'delete' | 'edit', index: number) => {
    const handleClickEditItem = () => {
      const item = collection.find((i, indexCurrent) => indexCurrent === index)
      if (item) openForm({ ...item, id: index.toString() })
    }

    const handleClickDeleteItem = () => {
      openConfirmDelete(index)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event]()
  }

  const handleDeleteItem = () => {
    setCollection((prev) => prev.filter((d, indexCurrent) => indexCurrent !== dataConfirmDelete))
    closeConfirmDelete()
    notification.remove()
  }

  const handleAfterSubmitForm = (
    event: 'update' | 'insert',
    data: VinculoSalarioAdicionalPartial,
  ) => {
    const handleAfterInsert = () => {
      setCollection((prev) => [...prev, data])
      closeForm()
    }

    const handleAfterUpdate = () => {
      setCollection((prev) =>
        prev.map((d, index) => {
          if (index.toString() === data.id) {
            data.id = ''
            return data
          } else {
            return d
          }
        }),
      )
      closeForm()
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    functions[event]()
  }

  function handleBack() {
    onBack()
  }
  function handleSubmit() {
    if (collection.length > 0) {
      onNext({
        ...mainData,
        dataSalarioAdicional: collection,
      })
    } else {
      onSkip({ ...mainData, dataSalarioAdicional: undefined })
    }
  }

  return (
    <Box height="calc(100vh - 25rem)">
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Salário Adicional">
          <ButtonBox top={1}>
            <Button
              size="small"
              color="primary"
              onClick={() => openForm(initialStateForm)}
              variant="contained"
            >
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={collection} onItemClick={handleClickItem} />
      </Box>

      {dataForm && (
        <Form
          isOpen={isOpenForm}
          data={dataForm}
          onClose={closeForm}
          onAfterSubmitForm={handleAfterSubmitForm}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleSubmit} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Box>
  )
}
