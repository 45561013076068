import { formatToCPF } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { ToolsDataTable } from '~/components'

import {
  PlanilhaExtracaoExibicaoFuncionarioDTO,
  PlanilhaExtracaoExibicaoEventoDTO,
} from '~/hooks/queries/ImportarEventosRP/ImportarEventosRPDTO'
import { formatCurrency } from '~/hooks/useUtils'

interface TableProps {
  data: PlanilhaExtracaoExibicaoFuncionarioDTO[]
  eventos: PlanilhaExtracaoExibicaoEventoDTO[]
  query: string
}
export default function Table({ data: _data, eventos, query }: TableProps) {
  const data: any[] = []
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'funcionarioNome',
      label: 'Nome',
      options: {
        setCellProps: () => ({
          style: { width: 315 },
        }),
      },
    },
    {
      name: 'funcionarioCPF',
      label: 'CPF',
      options: {
        setCellProps: () => ({
          style: { width: 110 },
        }),
        customBodyRender: (value) => formatToCPF(value),
      },
    },
  ]

  _data.forEach((d) => {
    let dtCurrent = {
      funcionarioId: d.funcionarioId,
      funcionarioNome: d.funcionarioNome,
      funcionarioCPF: d.funcionarioCPF,
    }

    d.eventos.forEach(
      (x) =>
        (dtCurrent = {
          ...dtCurrent,
          [x.colunaId]: x.valor,
        }),
    )

    data.push(dtCurrent)
  })

  columns.push(
    ...eventos.map<MUIDataTableColumnDef>((d) => ({
      name: d.colunaId,
      label: d.colunaLabel,
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end', width: 100 },
        }),
        customBodyRender: (value) => formatCurrency(value),
      },
    })),
  )

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['funcionarioNome', 'funcionarioCPF'],
      }}
    />
  )
}
