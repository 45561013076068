export enum DayOfWeekEnum {
  Domingo_0 = 0,
  Segunda_1 = 1,
  Terca_2 = 2,
  Quarta_3 = 3,
  Quinta_4 = 4,
  Sexta_5 = 5,
  Sabado_6 = 6,
}

export const DayOfWeekValues = [
  { value: DayOfWeekEnum.Domingo_0, name: '0 - Domingo' },
  { value: DayOfWeekEnum.Segunda_1, name: '1 - Segunda-Feira' },
  { value: DayOfWeekEnum.Terca_2, name: '2 - Terça-Feira' },
  { value: DayOfWeekEnum.Quarta_3, name: '3 - Quarta-Feira' },
  { value: DayOfWeekEnum.Quinta_4, name: '4 - Quinta-Feira' },
  { value: DayOfWeekEnum.Sexta_5, name: '5 - Sexta-Feira' },
  { value: DayOfWeekEnum.Sabado_6, name: '6 - Sabádo' },
]
