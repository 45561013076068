import credentialsConfig from '~/configs/credentials'

export function getToken() {
  const TOKEN_KEY = credentialsConfig.tokenKey || 'persist:@datac-pessoal:token'
  return localStorage.getItem(TOKEN_KEY)
}

export function getRefreshToken() {
  const REFRESH_TOKEN_KEY =
    credentialsConfig.refreshTokenKey || 'persist:@datac-pessoal:refresh-token'

  return localStorage.getItem(REFRESH_TOKEN_KEY)
}

export function setToken(token) {
  const TOKEN_KEY = credentialsConfig.tokenKey || 'persist:@datac-pessoal:token'
  return localStorage.setItem(TOKEN_KEY, token)
}

export function setRefreshToken(refreshToken) {
  const REFRESH_TOKEN_KEY =
    credentialsConfig.refreshTokenKey || 'persist:@datac-pessoal:refresh-token'

  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
}
