import { Box, Paper, useTheme } from '@material-ui/core'

interface ContainerChartProps {
  onHover: (value: boolean) => void
  children: React.ReactNode
}

export default function ContainerChart({ onHover, children }: ContainerChartProps) {
  const theme = useTheme()
  return (
    <Box
      borderRadius={theme.spacing(1)}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      component={Paper}
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
      padding={2}
    >
      {children}
    </Box>
  )
}
