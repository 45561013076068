import { useState } from 'react'

import { Box, Paper, Tab, Tabs } from '@material-ui/core'

import { Button, FullDialog, Stack, TabPanel } from '~/components'

import TabLotacaoConfig from './components/TabLotacaoConfig'
import TabLotacaoConfigProvisao from './components/TabLotacaoConfigProvisao'
import DialogCopyConfigs from './components/DialogCopyConfigs'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { LotacaoConfigConsultaDTO } from '~/hooks/queries/ExportacaoLancamentoConfig/dtos/LotacaoConfigConsultaDTO'
import { FPMovimentoTipoEnum } from '~/@types/enums/FPMovimentoTipoEnum'
import { FileCopy } from '@material-ui/icons'
import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'

enum TabConfigLotacao {
  ReciboMensal,
  Ferias,
  Rescisao,
  ProLabore,
  RPA,
  Provisao,
  DecimoTerceiroPrimeiraParcela,
  DecimoTerceiroSegundaParcela,
}

const TabConfigLotacaoLabel = {
  [TabConfigLotacao.ReciboMensal]: 'Recibo/Mensal',
  [TabConfigLotacao.Ferias]: 'Recibo/Férias',
  [TabConfigLotacao.Rescisao]: 'Recibo/Rescisão',
  [TabConfigLotacao.ProLabore]: 'Pró-Labore',
  [TabConfigLotacao.RPA]: 'RPA/Autônomo',
  [TabConfigLotacao.Provisao]: 'Provisão',
  [TabConfigLotacao.DecimoTerceiroPrimeiraParcela]: '13°Primeira Parcela',
  [TabConfigLotacao.DecimoTerceiroSegundaParcela]: '13°Segunda Parcela',
}

const TabConfigToFPMovimentoTipoEnum = {
  [TabConfigLotacao.ReciboMensal]: FPMovimentoTipoEnum.ReciboMensal,
  [TabConfigLotacao.Ferias]: FPMovimentoTipoEnum.Ferias,
  [TabConfigLotacao.Rescisao]: FPMovimentoTipoEnum.Rescisao,
  [TabConfigLotacao.ProLabore]: FPMovimentoTipoEnum.ProLabore,
  [TabConfigLotacao.RPA]: FPMovimentoTipoEnum.RPA,
  [TabConfigLotacao.Provisao]: null,
  [TabConfigLotacao.DecimoTerceiroPrimeiraParcela]:
    FPMovimentoTipoEnum.DecimoTerceiroPrimeiraParcela,
  [TabConfigLotacao.DecimoTerceiroSegundaParcela]: FPMovimentoTipoEnum.DecimoTerceiroSegundaParcela,
}

interface DialogConfigLotacaoProps {
  lotacaoConfig: LotacaoConfigConsultaDTO
  onClose: () => void
  modeloIntegracao: ModeloIntegracao
}

export default function DialogConfigLotacao({
  lotacaoConfig,
  onClose,
  modeloIntegracao,
}: DialogConfigLotacaoProps) {
  const [tab, setTab] = useState(TabConfigLotacao.ReciboMensal)

  const { estabelecimento } = useAmbiente()

  const {
    close: closeDialogCopyConfigs,
    data: dataDialogCopyConfigs,
    isOpen: isOpenDialogCopyConfigs,
    open: openDialogCopyConfigs,
  } = useDialog<{ movimentoTipo: FPMovimentoTipoEnum; lotacaoDestino: string } | null>(null)

  return (
    <FullDialog
      title={`Configuração/Lotação: ${lotacaoConfig.lotacaoCodigo} - ${lotacaoConfig.lotacaoNome}`}
      subtitle={`Estabelecimento: ${formatToCPFOrCNPJ(estabelecimento.nrInscricao)} - ${
        estabelecimento.nome
      }`}
      isOpen={true}
      onClose={onClose}
      renderRight={
        <Button
          variant="contained"
          endIcon={<FileCopy />}
          onClick={() =>
            openDialogCopyConfigs({
              lotacaoDestino: lotacaoConfig.id,
              movimentoTipo: FPMovimentoTipoEnum.ParametrosGerais,
            })
          }
        >
          Copiar Configurações / Lotação
        </Button>
      }
    >
      <Stack height="100%">
        <Stack
          orientation="horizontal"
          alignItems="center"
          justifyContent="space-between"
          component={Paper}
          px={2}
        >
          <Box flex={1} overflow="auto">
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => setTab(value)}
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label={TabConfigLotacaoLabel[TabConfigLotacao.ReciboMensal]} />
              <Tab label={TabConfigLotacaoLabel[TabConfigLotacao.Ferias]} />
              <Tab label={TabConfigLotacaoLabel[TabConfigLotacao.Rescisao]} />
              <Tab label={TabConfigLotacaoLabel[TabConfigLotacao.ProLabore]} />
              <Tab label={TabConfigLotacaoLabel[TabConfigLotacao.RPA]} />
              <Tab label={TabConfigLotacaoLabel[TabConfigLotacao.Provisao]} />
              <Tab label={TabConfigLotacaoLabel[TabConfigLotacao.DecimoTerceiroPrimeiraParcela]} />
              <Tab label={TabConfigLotacaoLabel[TabConfigLotacao.DecimoTerceiroSegundaParcela]} />
            </Tabs>
          </Box>

          {tab !== TabConfigLotacao.Provisao && (
            <Button
              variant="contained"
              endIcon={<FileCopy />}
              onClick={() =>
                openDialogCopyConfigs({
                  lotacaoDestino: lotacaoConfig.id,
                  movimentoTipo: TabConfigToFPMovimentoTipoEnum[tab],
                })
              }
            >
              Copiar Configurações / {TabConfigLotacaoLabel[tab]}
            </Button>
          )}
        </Stack>

        <Box flex={1} minHeight={300}>
          <TabPanel value={tab} index={TabConfigLotacao.ReciboMensal}>
            <TabLotacaoConfig
              lotacaoConfigId={lotacaoConfig.id}
              mascaraConta={modeloIntegracao.mascaraConta}
              tipo={FPMovimentoTipoEnum.ReciboMensal}
            />
          </TabPanel>
          <TabPanel value={tab} index={TabConfigLotacao.Ferias}>
            <TabLotacaoConfig
              lotacaoConfigId={lotacaoConfig.id}
              mascaraConta={modeloIntegracao.mascaraConta}
              tipo={FPMovimentoTipoEnum.Ferias}
            />
          </TabPanel>
          <TabPanel value={tab} index={TabConfigLotacao.Rescisao}>
            <TabLotacaoConfig
              lotacaoConfigId={lotacaoConfig.id}
              mascaraConta={modeloIntegracao.mascaraConta}
              tipo={FPMovimentoTipoEnum.Rescisao}
            />
          </TabPanel>
          <TabPanel value={tab} index={TabConfigLotacao.ProLabore}>
            <TabLotacaoConfig
              lotacaoConfigId={lotacaoConfig.id}
              mascaraConta={modeloIntegracao.mascaraConta}
              tipo={FPMovimentoTipoEnum.ProLabore}
            />
          </TabPanel>
          <TabPanel value={tab} index={TabConfigLotacao.RPA}>
            <TabLotacaoConfig
              lotacaoConfigId={lotacaoConfig.id}
              mascaraConta={modeloIntegracao.mascaraConta}
              tipo={FPMovimentoTipoEnum.RPA}
            />
          </TabPanel>
          <TabPanel value={tab} index={TabConfigLotacao.Provisao}>
            <TabLotacaoConfigProvisao
              lotacaoConfigId={lotacaoConfig.id}
              mascaraConta={modeloIntegracao.mascaraConta}
            />
          </TabPanel>
          <TabPanel value={tab} index={TabConfigLotacao.DecimoTerceiroPrimeiraParcela}>
            <TabLotacaoConfig
              lotacaoConfigId={lotacaoConfig.id}
              mascaraConta={modeloIntegracao.mascaraConta}
              tipo={FPMovimentoTipoEnum.DecimoTerceiroPrimeiraParcela}
            />
          </TabPanel>
          <TabPanel value={tab} index={TabConfigLotacao.DecimoTerceiroSegundaParcela}>
            <TabLotacaoConfig
              lotacaoConfigId={lotacaoConfig.id}
              mascaraConta={modeloIntegracao.mascaraConta}
              tipo={FPMovimentoTipoEnum.DecimoTerceiroSegundaParcela}
            />
          </TabPanel>
        </Box>
      </Stack>

      {isOpenDialogCopyConfigs && dataDialogCopyConfigs && (
        <DialogCopyConfigs
          lotacaoDestino={dataDialogCopyConfigs.lotacaoDestino}
          movimentoTipo={dataDialogCopyConfigs.movimentoTipo}
          onClose={closeDialogCopyConfigs}
          modeloIntegracaoId={modeloIntegracao.id}
        />
      )}
    </FullDialog>
  )
}
