import { useState, useCallback, useMemo, useEffect, memo, useRef } from 'react'
import PropTypes from 'prop-types'

import { IconButton, makeStyles, InputAdornment, Box, Popover } from '@material-ui/core'
import { DataTable, ButtonBox, Button, Finder, TextField } from 'mio-library-ui'

import { GetApp as GetAppIcon, Backspace as BackspaceIcon } from '@material-ui/icons'
import { ActionDialog } from '~/components'

import useKeyPress from '~/hooks/useKeyPress'

import { useEventoObterListaVariavelAmbiente } from '~/hooks/queries/Evento/useEventoObterListaVariavelAmbiente'

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  containerButtons: {
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
  },
  contentButtons: {
    borderBottom: theme.shape.border,
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    display: 'flex',
    justifyContent: 'space-between',
    '&:last-child': {
      border: 0,
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  textFieldPopoverRoot: {
    width: '60px',
  },
  textFieldPopoverInput: {
    textAlign: 'center',
  },
}))

const MemoDataTable = memo(DataTable)
const MemoButton = memo(Button, (prev, next) => prev !== next)

const DialogMakeFormula = (props) => {
  const { isOpen, onClose, data, onAfterCreateFormula, keyField, isSubmiting } = props

  const [formula, setFormula] = useState('')
  const [lastIsSymbol, setLastIsSymbol] = useState(false)
  const [query, setQuery] = useState('')
  const [popoverEventoIndice, setPopoverEventoIndice] = useState({
    anchorEl: null,
    variavel: '',
    type: '',
  })

  const [variaveisAmbiente, setVariaveisAmbiente] = useState([])

  const { data: _variaveisAmbiente, isLoading, isFetching } = useEventoObterListaVariavelAmbiente()

  useEffect(() => {
    const variaveisAmbiente = _variaveisAmbiente || []
    setVariaveisAmbiente([
      {
        identificacao: 99,
        variavel: '[EXX]',
        descricao: 'Valor de um determinado Evento',
      },
      {
        identificacao: 98,
        variavel: '[IXX]',
        descricao: 'Valor de um determinado Índice',
      },
      ...variaveisAmbiente,
    ])
  }, [_variaveisAmbiente])

  const formulaRef = useRef(null)

  const open = Boolean(popoverEventoIndice.anchorEl)

  const classes = useStyles()

  useEffect(() => {
    setFormula(data)
  }, [data])

  const handleSubmit = async () => {
    await onAfterCreateFormula(formula, keyField)
    onClose()
  }

  const onItemClick = useCallback(
    (item, isVariavel = false, symbol = false, addParentheses = false) => {
      let newVariavel = item
      if (isVariavel) newVariavel = `[${item}]`

      const cursorStartPosition =
        formulaRef?.current?.selectionStart || formulaRef?.current?.value?.length
      const cursorEndPosition = formulaRef?.current?.selectionEnd

      if (cursorStartPosition !== cursorEndPosition && addParentheses) {
        setFormula((oldFormula) => {
          const newFormula = `${oldFormula.substr(0, cursorStartPosition).trim()} (${oldFormula
            .substr(cursorStartPosition, cursorEndPosition)
            .trim()}) ${oldFormula.substr(cursorEndPosition).trim()}`

          return newFormula.trim()
        })
        return
      }

      if (symbol) {
        setLastIsSymbol(true)
      } else {
        setLastIsSymbol(false)
      }

      setFormula((oldFormula) => {
        const oldFormulaStart = oldFormula?.substr(0, cursorStartPosition)?.trim() || ''
        const oldFormulaEnd = oldFormula?.substr(cursorStartPosition)?.trim() || ''

        const newFormula = `${oldFormulaStart}${
          lastIsSymbol || symbol ? ' ' : ''
        }${newVariavel.trim()}${lastIsSymbol || symbol ? ' ' : ''}${oldFormulaEnd}`
        return newFormula.trim()
      })
    },
    [lastIsSymbol],
  )

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleDeleteLastItem = () => {
    if (!isOpen) return
    const cursorStartPosition = formulaRef?.current?.selectionStart
    const cursorEndPosition = formulaRef?.current?.selectionEnd

    if (cursorStartPosition !== cursorEndPosition) {
      setFormula((oldFormula) => {
        const newFormula =
          oldFormula.substr(0, cursorStartPosition) + oldFormula.substr(cursorEndPosition)
        return newFormula.trim()
      })
      return
    }
    const itens = formula.split(' ')
    itens.pop()
    let newFormula = ''
    itens.forEach((item) => (newFormula = newFormula + ' ' + item))
    setFormula(newFormula.trim())
  }

  useKeyPress('Backspace', handleDeleteLastItem)

  const handleOpenPopover = useCallback((event, type) => {
    setPopoverEventoIndice({
      anchorEl: event.currentTarget,
      variavel: '',
      type,
    })
  }, [])

  const handleClosePopover = () => {
    setPopoverEventoIndice({
      anchorEl: null,
      variavel: '',
      type: '',
    })
  }

  const columns = useMemo(
    () => [
      {
        name: 'variavel',
        label: 'Nome',
      },
      {
        name: 'descricao',
        label: 'Descrição',
      },
      {
        name: 'variavel',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, { rowIndex }) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Utilizar essa variável"
                  color="primary"
                  aria-label="Utilizar variável"
                  onClick={(event) => {
                    if (rowIndex === 0) return handleOpenPopover(event, 'E')
                    if (rowIndex === 1) return handleOpenPopover(event, 'I')
                    onItemClick(value, true)
                  }}
                >
                  <GetAppIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick, handleOpenPopover],
  )

  return (
    <ActionDialog
      title="Fórmula do Evento"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmiting}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
      }}
      renderRight={<Finder onSearch={handleQuery} onClose={() => handleQuery('')} />}
    >
      <MemoDataTable
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        data={variaveisAmbiente}
        sherlock={{
          query,
          columns: ['variavel', 'descricao'],
        }}
        options={{
          tableBodyHeight: '300px',
        }}
        pagination={false}
      />
      <TextField
        className={classes.textField}
        inputRef={formulaRef}
        fullWidth
        variant="outlined"
        size="small"
        label="Fórmula"
        value={formula || ''}
        multiline
        onChange={(e) => setFormula(e?.target?.value || '')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                title="Deletar variável anterior"
                aria-label="Deletar variável anterior"
                onClick={handleDeleteLastItem}
              >
                <BackspaceIcon fontSize="small" color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box className={classes.containerButtons}>
        <Box className={classes.contentButtons}>
          <MemoButton fullWidth onClick={() => onItemClick('+', false, true)}>
            +
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('-', false, true)}>
            -
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('*', false, true)}>
            *
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('/', false, true)}>
            /
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('(', false, true)}>
            (
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick(')', false, true)}>
            )
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('', false, true, true)}>
            ()
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('.')}>
            .
          </MemoButton>
          <MemoButton fullWidth onClick={(event) => handleOpenPopover(event, 'E')}>
            [EXX]
          </MemoButton>
          <MemoButton fullWidth onClick={(event) => handleOpenPopover(event, 'I')}>
            [IXX]
          </MemoButton>
        </Box>

        <Box className={classes.contentButtons}>
          <MemoButton fullWidth onClick={() => onItemClick('0')}>
            0
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('1')}>
            1
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('2')}>
            2
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('3')}>
            3
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('4')}>
            4
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('5')}>
            5
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('6')}>
            6
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('7')}>
            7
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('8')}>
            8
          </MemoButton>
          <MemoButton fullWidth onClick={() => onItemClick('9')}>
            9
          </MemoButton>
        </Box>
      </Box>

      <Popover
        id="mouse-over-popover"
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={popoverEventoIndice.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handleClosePopover}
        disableRestoreFocus
      >
        <TextField
          id="standard-basic"
          inputProps={{
            maxLength: 4,
          }}
          onlyNumber
          autoFocus
          onKeyPress={({ key }) => {
            if (key === 'Enter') {
              if (!popoverEventoIndice.variavel) return handleClosePopover()
              onItemClick(`[${popoverEventoIndice.type}${popoverEventoIndice.variavel}]`)
              handleClosePopover()
            }
          }}
          InputProps={{
            startAdornment: <div>[{popoverEventoIndice.type}</div>,
            endAdornment: <div>]</div>,
            classes: {
              root: classes.textFieldPopoverRoot,
              input: classes.textFieldPopoverInput,
            },
          }}
          value={popoverEventoIndice?.variavel || ''}
          onChange={(e) =>
            setPopoverEventoIndice((oldState) => ({
              ...oldState,
              variavel: e?.target?.value || '',
            }))
          }
        />
      </Popover>
    </ActionDialog>
  )
}

export default DialogMakeFormula

DialogMakeFormula.propTypes = {
  isOpen: PropTypes.bool,
  isSubmiting: PropTypes.bool,
  onAfterCreateFormula: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.any,
  keyField: PropTypes.string,
}
