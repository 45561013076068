export default function useRedirectByEvent() {
  function handleRedirect(url) {
    window.open('#' + url, '_blank')
  }

  function redirectByEvent(event) {
    const eventsMap = {
      1000: () => handleRedirect('/empregador'),
      1005: () => handleRedirect('/estabelecimento'),
      1010: () => handleRedirect('/evento-empregador'),
      1020: () => handleRedirect('/lotacao'),
      1070: () => handleRedirect('/processo'),
      1200: () => handleRedirect('/recibo-pagamento/mensal'),
      1210: () => handleRedirect('/recibo-pagamento/mensal'),
      1260: () => handleRedirect('/comercializacao'),
      1270: () => handleRedirect('/contratacao-trabalhador-avulso'),
      1280: () => handleRedirect('/empregador'),
      1299: () => undefined,
      2190: () => handleRedirect('/admissao-preliminar'),
      2200: () => handleRedirect('/funcionario'),
      2205: () => handleRedirect('/alteracao-cadastral'),
      2206: () => handleRedirect('/alteracao-contratual'),
      2210: () => handleRedirect('/comunicado-acidente-trabalho'),
      2220: () => handleRedirect('/atestado-saude-ocupacional'),
      2230: () => handleRedirect('/vinculo-afastamento'),
      2240: () => handleRedirect('/condicao-ambiente-trabalho'),
      2299: () => handleRedirect('/recibo-pagamento/rescisao'),
      2300: () => handleRedirect('/autonomos'),
      2306: () => handleRedirect('/alteracao-contratual'),
      2399: () => handleRedirect('/recibo-pagamento/mensal'),
      2500: () => handleRedirect('/vinculo-processos'),
    }
    eventsMap[event]()
  }

  return redirectByEvent
}
