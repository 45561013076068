import React, { useState, useEffect } from 'react'

import * as yup from 'yup'

import { Collapse, Grid } from '@material-ui/core'
import { ActionDialog, ContentDivider, TextField } from '~/components'

import { CurrencyTextField } from '~/components'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import { AutoCompleteSalario, MUIAutoComplete } from '~/components/AutoComplete'
import { VinculoSalarioAdicionalPartial } from '~/hooks/queries/VinculoSalarioAdicional/VinculoSalarioAdicional'
import {
  IndSalarioAdicionalEnum,
  IndSalarioAdicionalValues,
} from '~/@types/enums/IndSalarioAdicionalEnum'
import {
  IndBaseInsalubridadeEnum,
  IndBaseInsalubridadeValues,
} from '~/@types/enums/IndBaseInsalubridadeEnum'
import { FaixaSalarialEnum, FaixaSalarialValues } from '~/@types/enums/FaixaSalarialEnum'

const typesNeedValue = [
  IndSalarioAdicionalEnum.QuebraCaixa_4,
  IndSalarioAdicionalEnum.Gratificacao_5,
  IndSalarioAdicionalEnum.AdicionalDuplaFuncao_6,
  IndSalarioAdicionalEnum.AdicionalTempoServico_7,
]

const schema = yup.object().shape({
  indAdicional: yup
    .number()
    .required('Informe o Indicador de Adicional')
    .nullable()
    .typeError('Informe um valor válido'),
  indBaseCalculoInsalubridade: yup
    .number()
    .when(['indAdicional'], (indAdicional, schema) => {
      if (indAdicional === IndSalarioAdicionalEnum.Insalubridade_1) {
        return schema.required('Informe o Indicador Base de Cálculo Insalubridade')
      }
    })
    .nullable()
    .typeError('Informe um valor válido'),
  faixaSalarialInsalubridade: yup
    .number()
    .when(['indBaseCalculoInsalubridade'], (indBaseCalculoInsalubridade, schema) => {
      if (
        indBaseCalculoInsalubridade === IndBaseInsalubridadeEnum.SalarioBaseCategoria_3 ||
        indBaseCalculoInsalubridade === IndBaseInsalubridadeEnum.SalarioBaseTabelaSalario_4
      ) {
        return schema
          .min(FaixaSalarialEnum.FaixaI, 'Informe a Faixa Salarial')
          .max(FaixaSalarialEnum.FaixaIV, 'Informe a Faixa Salarial')
          .required('Informe a Faixa Salarial')
      }
    })
    .nullable()
    .typeError('Informe um valor válido'),
  salarioId: yup
    .string()
    .when(['indBaseCalculoInsalubridade'], (indBaseCalculoInsalubridade, schema) => {
      if (indBaseCalculoInsalubridade === IndBaseInsalubridadeEnum.SalarioBaseTabelaSalario_4) {
        return schema.required('Informe o Salário')
      }
    })
    .nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoSalarioAdicionalPartial
  onAfterSubmitForm: (event: 'update' | 'insert', data: VinculoSalarioAdicionalPartial) => void
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState<VinculoSalarioAdicionalPartial>({})
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = () => {
    const update = () => {
      onAfterSubmitForm('update', data)
      notification.put()
    }

    const insert = () => {
      onAfterSubmitForm('insert', data)
      notification.post()
    }

    if (data.id) {
      update()
      return
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Salário Adicional"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicador de Adicional"
            options={IndSalarioAdicionalValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indAdicional"
            value={data.indAdicional}
            onChange={(e, obj) => {
              const indAdicional = obj ? obj.value : ''
              setData({ ...data, indAdicional })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CurrencyTextField
            label="Aliquota"
            fullWidth
            value={data.aliquota || ''}
            variant="outlined"
            maximumValue="999"
            size="small"
            onChange={(e, obj) => {
              const aliquota = obj || null
              setData({ ...data, aliquota })
            }}
          />
        </Grid>

        {typesNeedValue.includes(data?.indAdicional || IndSalarioAdicionalEnum.Insalubridade_1) && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CurrencyTextField
              label="Valor"
              fullWidth
              value={data?.vrAdicional || ''}
              variant="outlined"
              size="small"
              onChange={(e, obj) => {
                const vrAdicional = obj || null
                setData({ ...data, vrAdicional })
              }}
            />
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data.observacao || ''}
            size="small"
            multiline
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Collapse in={data.indAdicional === IndSalarioAdicionalEnum.Insalubridade_1}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <ContentDivider title="Configuração da Insalubridade" />
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <MUIAutoComplete
                  label="Indicador Base de Cálculo Insalubridade"
                  options={IndBaseInsalubridadeValues}
                  renderOption={(option) => option?.name || ''}
                  optionId="value"
                  required
                  validationErrors={validationErrors}
                  name="indBaseCalculoInsalubridade"
                  value={data?.indBaseCalculoInsalubridade || ''}
                  onChange={(e, obj) => {
                    const indBaseCalculoInsalubridade = obj?.value || ''
                    setData((prev) => ({ ...prev, indBaseCalculoInsalubridade }))
                  }}
                />
              </Grid>

              {data?.indBaseCalculoInsalubridade ===
                IndBaseInsalubridadeEnum.SalarioBaseTabelaSalario_4 && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <AutoCompleteSalario
                    name="salarioId"
                    required
                    validationErrors={validationErrors}
                    value={data?.salarioId || ''}
                    onChange={(d) => {
                      const salarioId = d?.id || ''
                      setData((prev) => ({ ...prev, salarioId }))
                    }}
                  />
                </Grid>
              )}

              {(data?.indBaseCalculoInsalubridade ===
                IndBaseInsalubridadeEnum.SalarioBaseCategoria_3 ||
                data?.indBaseCalculoInsalubridade ===
                  IndBaseInsalubridadeEnum.SalarioBaseTabelaSalario_4) && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Faixa Salarial"
                    options={FaixaSalarialValues}
                    renderOption={(opt) => opt?.name || ''}
                    optionId="value"
                    value={data?.faixaSalarialInsalubridade}
                    onChange={(e, obj) =>
                      setData((prevState) => ({
                        ...prevState,
                        faixaSalarialInsalubridade: obj?.value || '',
                      }))
                    }
                    required
                    validationErrors={validationErrors}
                    name="faixaSalarialInsalubridade"
                  />
                </Grid>
              )}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
