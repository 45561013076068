import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { AutoCompleteGrupo, MUIAutoComplete } from '~/components/AutoComplete'
import { Checkbox, Finder } from '~/components'

import { TabsApuracao, useApuracaoEncargos } from '../..'

import { IndApuracaoEnum, IndApuracaoValues } from '~/@types/enums/IndApuracaoEnum'

export default function Header() {
  const {
    tab,
    indApuracao,
    grupoId,
    isOnlyError,
    changeIndApuracao,
    changeGrupoId,
    changeOnlyError,
    changeQuery,
  } = useApuracaoEncargos()

  const theme = useTheme()

  return (
    <Box display="flex" gridGap={theme.spacing(1)}>
      <Box flex={1}>
        <Box component={Paper} padding={2} paddingY={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <MUIAutoComplete
                label="Indicador de Apuração"
                options={IndApuracaoValues}
                optionId="value"
                renderOption={(option: FixLater) => option.name}
                value={indApuracao}
                onChange={(e: FixLater, obj: FixLater) =>
                  changeIndApuracao(obj?.value || IndApuracaoEnum.Mensal_1)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <AutoCompleteGrupo
                value={grupoId}
                onChange={(e, grupo) => {
                  changeGrupoId(grupo?.id || null)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Finder onSearch={changeQuery} onClose={() => changeQuery('')} />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {tab === TabsApuracao.Demonstracao && (
        <Box
          component={Paper}
          padding={2}
          paddingY={2}
          display="flex"
          alignItems="center"
          gridGap={theme.spacing(1)}
          height="100%"
        >
          <Checkbox
            label="Somente com Diferença"
            value={isOnlyError}
            onChange={(e, checked) => changeOnlyError(checked)}
          />
        </Box>
      )}
    </Box>
  )
}
