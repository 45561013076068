import React from 'react'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { formatToCPF } from 'brazilian-values'

import { usePessoaFisica } from '~/hooks/queries/usePessoaFisica'

function Table(props) {
  const { onItemClick, query } = props

  const { data: _data, isLoading, isFetching } = usePessoaFisica()

  const data =
    _data?.length > 0
      ? _data.map((d) => ({
          ...d,
          nrInscricao: d?.nrInscricao ? formatToCPF(d.nrInscricao) : '',
        }))
      : []

  const columns = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <DataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['nome', 'nrInscricao'],
      }}
    />
  )
}

export default Table
