import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterPorPlanoSaudeTitular'

import { notifyPut } from '~/utils/notification'

import { PlanoSaudeDependente } from './dtos/PlanoSaudeDependente'

interface RequestProps {
  data: PlanoSaudeDependente
  params: { anoMes: string }
  id: string
}

export function useAtualizar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ data, params, id }: RequestProps) {
    const response = await api.put<{ data: PlanoSaudeDependente }>(
      '/PlanoSaudeDependente/' + id,
      data,
      {
        params,
      },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
