import moment from 'moment'
import { formatToCPF } from 'brazilian-values'

import { CopyLabel, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

interface TableProps {
  data: VinculoConsulta[]
  isLoading: boolean
  isFetching: boolean
  query: string
  tableRef: FixLater
}

export default function Table({ data, isLoading, isFetching, query, tableRef }: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Código',
      name: 'pessoaCodigo',
    },
    {
      label: 'CPF',
      name: 'pessoaCPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      label: 'Nome',
      name: 'pessoaNome',
    },
    {
      label: 'Admissão',
      name: 'dtAdmissao',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
  ]

  return (
    <ToolsDataTable
      tableRef={tableRef}
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      disableAutoHeight
      sherlock={{ columns: ['pessoaCodigo', 'pessoaCPF', 'pessoaNome'], query }}
      optionsSelectable={{
        type: 'multiple',
      }}
    />
  )
}
