import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, Checkbox } from '~/components'

const initialState = {
  isSomenteTotalizador: false,
}

export default function DialogConfirmPrint(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState(initialState)

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  useEffect(() => {
    if (isOpen) return
    setData(initialState)
  }, [isOpen])

  return (
    <ActionDialog
      title="Informe dados para o Relatório de Provisão de 13° Salário"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Checkbox
            label="Imprimir somente totalizador"
            value={data.isSomenteTotalizador}
            onChange={(e, value) => {
              const isSomenteTotalizador = value
              setData((prev) => ({ ...prev, isSomenteTotalizador }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
