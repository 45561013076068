import { Box, useTheme } from '@material-ui/core'
import { Print as PrintIcon } from '@material-ui/icons'
import { Button } from '~/components'

export default function PrintButton(props) {
  const { ...rest } = props

  const theme = useTheme()

  return (
    <Button size="small" variant="contained" color="primary" {...rest}>
      <Box display="flex" gridGap={theme.spacing(1)}>
        <PrintIcon fontSize="small" color="inherit" />
        Imprimir
      </Box>
    </Button>
  )
}
