import { useState } from 'react'

import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'

import { Button, PageHeader, Stack, StackContainer, TabPanel } from '~/components'
import AutoCompleteModeloIntegracao from '~/components/AutoComplete/AutoCompleteModeloIntegracao'

import { useObterLancamentoLiquidacaoConfig } from '~/hooks/queries/LancamentoLiquidacaoConfig/useObterLancamentoLiquidacaoConfig'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import ConfiguracoesGerais from './components/ConfiguracoesGerais'
import GPSConfiguracao from './components/GPSConfiguracao'
import GRRFConfiguracao from './components/GRRFConfiguracao'
import DialogCopyConfigs from './components/DialogCopyConfigs'

import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'

enum TabConfiguracaoLancamento {
  Geral,
  GPSMensal,
  GPS13Salario,
  GuiaRescisoria,
}

export default function ConfiguracaoLancamento() {
  const [tab, setTab] = useState(TabConfiguracaoLancamento.Geral)
  const [form, setForm] = useState<{
    modeloIntegracao: ModeloIntegracao | null
  }>({
    modeloIntegracao: null,
  })

  const {
    close: closeDialogCopyConfigs,
    data: dataDialogCopyConfigs,
    isOpen: isOpenDialogCopyConfigs,
    open: openDialogCopyConfigs,
  } = useDialog('')

  const { estabelecimento } = useAmbiente()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterLancamentoLiquidacaoConfig({
    params: {
      estabelecimentoId: estabelecimento.id,
      modeloIntegracaoId: form.modeloIntegracao?.id,
    },
  })

  return (
    <StackContainer component={Paper}>
      <PageHeader title="Configuração de Lançamento" />

      <Box>
        <AutoCompleteModeloIntegracao
          value={form?.modeloIntegracao?.id || ''}
          onChange={(d) => {
            const modeloIntegracao = d || null
            setForm((prev) => ({ ...prev, modeloIntegracao }))
          }}
        />
      </Box>

      {!form?.modeloIntegracao ? (
        <Typography>Selecione o modelo de integração para prosseguir</Typography>
      ) : (
        <>
          <Stack
            orientation="horizontal"
            alignItems="center"
            justifyContent="space-between"
            component={Paper}
            px={2}
          >
            <Box flex={1} overflow="auto">
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, value) => setTab(value)}
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab label="Configurações Gerais" />
                <Tab label="GPS/Mensal" />
                <Tab label="GPS/13°Salário" />
                {_d?.utilizaIntegracaoFGTSRescisorio ? <Tab label="Guia Rescisória" /> : <></>}
              </Tabs>
            </Box>

            {tab === TabConfiguracaoLancamento.Geral && (
              <Button
                variant="contained"
                endIcon={<FileCopy />}
                onClick={() => openDialogCopyConfigs(form.modeloIntegracao?.id || '')}
              >
                Copiar Configurações
              </Button>
            )}
          </Stack>

          <Box flex={1} minHeight={200}>
            <TabPanel value={tab} index={TabConfiguracaoLancamento.Geral}>
              <ConfiguracoesGerais
                modeloIntegracao={form.modeloIntegracao}
                data={_d}
                isLoading={isLoading}
                isFetching={isFetching}
              />
            </TabPanel>
            <TabPanel value={tab} index={TabConfiguracaoLancamento.GPSMensal}>
              <GPSConfiguracao modeloIntegracao={form.modeloIntegracao} isMensal />
            </TabPanel>
            <TabPanel value={tab} index={TabConfiguracaoLancamento.GPS13Salario}>
              <GPSConfiguracao modeloIntegracao={form.modeloIntegracao} isMensal={false} />
            </TabPanel>
            {_d?.utilizaIntegracaoFGTSRescisorio ? (
              <TabPanel value={tab} index={TabConfiguracaoLancamento.GuiaRescisoria}>
                <GRRFConfiguracao modeloIntegracao={form.modeloIntegracao} />
              </TabPanel>
            ) : (
              <></>
            )}
          </Box>
        </>
      )}

      {isOpenDialogCopyConfigs && (
        <DialogCopyConfigs
          modeloIntegracaoId={dataDialogCopyConfigs}
          onClose={closeDialogCopyConfigs}
        />
      )}
    </StackContainer>
  )
}
