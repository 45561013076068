import React, { useState, useMemo } from 'react'

import { ActionDialog } from '~/components'
import { Box, Grid, makeStyles } from '@material-ui/core'

import { ToolsDataTable } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import { useHistory } from 'react-router-dom'
import useUtils from '~/hooks/useUtils'

import api from '~/services/api-pessoal'
import moment from 'moment'

import { IndApuracaoValues } from '~/@types/enums/IndApuracaoEnum'

function formatIndApuracao(indApuracao) {
  if (!indApuracao) return ''
  const element = IndApuracaoValues.find((obj) => obj.value === indApuracao)
  return element?.name || ''
}

function formatPositiveOrNegativeResult(boolean) {
  if (boolean) return 'Sim'
  return 'Não'
}

function formatDataForTable(data) {
  const eventoRemuneracao = {
    desc: 'Foi enviado o evento de S-1200 Remuneração?',
    value: formatPositiveOrNegativeResult(data?.isEvtRemuneracao),
  }
  const eventoPagamento = {
    desc: 'Foi enviado o evento de S-1210 Pagamento?',
    value: formatPositiveOrNegativeResult(data?.isEvtPagamento),
  }
  const eventoComercializacao = {
    desc: 'Foi enviado o evento de S-1260 Comercialização Rural?',
    value: formatPositiveOrNegativeResult(data?.isEvtComercializacao),
  }
  const eventoContratacaoAvulso = {
    desc: 'Foi enviado o evento de S-1270 Contratação de trabalhador avulso?',
    value: formatPositiveOrNegativeResult(data?.isEvtContratacaoAvulso),
  }
  const eventoComplementar = {
    desc: 'Foi enviado o evento de S-1280 Informação complementar?',
    value: formatPositiveOrNegativeResult(data?.isEvtInfoComplementar),
  }
  return [
    eventoRemuneracao,
    eventoPagamento,
    eventoComercializacao,
    eventoContratacaoAvulso,
    eventoComplementar,
  ]
}

const useStyles = makeStyles((theme) => ({
  header: {
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hightLightGrid: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#666',
  },
  hightLightFields: {
    color: theme.palette.secondary.main,
  },
  rootTableContent: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function DialogConfirmReabertura(props) {
  const { isOpen, onClose, data } = props

  const dataTable = formatDataForTable(data)

  const [isLoadingConfirm, setLoadingConfirm] = useState(false)

  const classes = useStyles()
  const history = useHistory()
  const { formatAnoMes } = useUtils()
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  async function handleConfirm() {
    setLoadingConfirm(true)
    try {
      await api.post(
        '/CompetenciaEmpregador/ReaberturaESocial',
        {},
        {
          params: {
            empregadorId: data?.empregadorId || '',
            indApuracao: data?.indApuracao || '',
            anoMes: data?.anoMes || '',
          },
        },
      )
      notification.success('Reabertura realizada com sucesso')
      onClose()
      history.push('/painel')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingConfirm(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        name: 'desc',
        label: 'Descrição',
      },
      {
        name: 'value',
        label: 'Valor',
      },
    ],
    [],
  )

  return (
    <ActionDialog
      title="Reabertura Movimento do eSocial"
      subtitle={`Indicador de Apuração: ${formatIndApuracao(
        data?.indApuracao,
      )} | Ano/Mês: ${formatAnoMes(data?.anoMes)}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleConfirm}
      isOkProcessing={isLoadingConfirm}
      dialogProps={{
        fullWidth: true,
      }}
      okLabel="Confirmar"
    >
      <Box className={classes.header}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.hightLightGrid}>
            <Box>Número do Recibo eSocial</Box>
            <Box className={classes.hightLightFields}>{data?.reciboEsocial}</Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.hightLightGrid}>
            Data de Envio:{' '}
            {data?.dtReciboEsocial ? moment(data.dtReciboEsocial).format('DD/MM/yyyy') : ''}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.hightLightGrid}>
            Data de Fechamento:{' '}
            {data?.dtFechamento ? moment(data.dtFechamento).format('DD/MM/yyyy') : ''}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <ToolsDataTable columns={columns} data={dataTable} showHeader={false} pagination={false} />
      </Box>
    </ActionDialog>
  )
}
