import React, { useState, useCallback, useMemo, useEffect } from 'react'

import { Box } from '@material-ui/core'
import { ButtonBox, PageHeader, Finder } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { formatToCPFOrCNPJ } from 'brazilian-values'

const HEADER_HEIGHT = '50px'
const EstabelecimentosEmpregador = (props) => {
  const { empregadorId } = props

  const [collection, setCollection] = useState({
    isLoading: false,
    items: [],
  })
  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))
      try {
        const response = await api.get(
          `EmpregadorConsulta/GetEstabelecimento?empregadorId=${empregadorId}`,
        )
        const data = response.data.data.map((d) => {
          return {
            ...d,
            nrInscricao: formatToCPFOrCNPJ(d?.nrInscricao || ''),
            nome: d?.nome,
          }
        })
        setCollection({
          isLoading: false,
          items: data,
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    //eslint-disable-next-line
  }, [empregadorId])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
    ],
    [],
  )

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          </ButtonBox>
        </PageHeader>
      </Box>
      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <HeightDataTable
          title=""
          data={collection.items}
          columns={columns}
          isLoading={collection.isLoading}
          sherlock={{
            query,
            columns: ['nrInscricao', 'nome'],
          }}
        />
      </Box>
    </>
  )
}

export default EstabelecimentosEmpregador
