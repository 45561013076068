import { useState } from 'react'

import { Grid } from '@material-ui/core'
import {
  ActionDialog,
  AnoMesTextField,
  ContentDivider,
  DatePickerNew,
  TextField,
} from '~/components'

import * as yup from 'yup'

import useValidationErrors from '~/hooks/useValidationErrors'
import { useQueryClient } from 'react-query'

import { useCreate } from '~/hooks/queries/VinculoFeriasItem/useCreate'
import { useUpdate } from '~/hooks/queries/VinculoFeriasItem/useUpdate'
import { queryGetByVinculo } from '~/hooks/queries/VinculoFerias/useGetByVinculo'

import { VinculoFeriasItem } from '~/hooks/queries/VinculoFeriasItem/dtos/VinculoFeriasItem'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { IndFeriasEnum, IndFeriasValues } from '~/@types/enums/IndFeriasEnum'

import { dateHasBetweenYearMonth } from '~/hooks/useUtils'

const schema = yup.object().shape({
  anoMes: yup.string().required('Informe o Ano/Mês').nullable(),
  dtInicioConcessao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Início da Concessão')
    .when(['anoMes'], (anoMes, schema) =>
      schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data deve está dentro da competência informada',
        (dt: string) => dateHasBetweenYearMonth(dt, anoMes),
      ),
    )
    .nullable(),
  dtFimConcessao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Fim da Concessão')
    .nullable(),
  nrDiasConcedidos: yup
    .number()
    .required('Informe o Número de Dias de Férias Concedidos')
    .min(1, 'Informe o Número de Dias de Férias Concedidos')
    .nullable(),
  dtAvisoFerias: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Aviso das Férias')
    .nullable(),
  dtEmissaoFerias: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Emissão das Férias')
    .nullable(),
  dtRetorno: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Retorno das Férias')
    .nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoFeriasItem | null
  dataInitial: VinculoFeriasItem
}

export default function Form({ isOpen, onClose, data: _data, dataInitial }: FormProps) {
  const [data, setData] = useState(_data || dataInitial)

  const queryClient = useQueryClient()

  const { mutateAsync: mutateAsyncCreate, isLoading: isCreating } = useCreate()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } = useUpdate()
  const isSubmitting = isCreating || isUpdating

  const isNovaData = _data ? false : true

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    if (isNovaData) {
      await mutateAsyncCreate(data)
    } else {
      await mutateAsyncUpdate(data)
    }
    queryClient.invalidateQueries(queryGetByVinculo)
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Registro de Férias"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <AnoMesTextField
            label="Ano/Mês"
            required
            validationErrors={validationErrors}
            name="anoMes"
            value={data.anoMes || ''}
            onChange={(e) => {
              const anoMes = e?.target?.value?.replace('/', '') || ''
              setData((prev) => ({ ...prev, anoMes }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <MUIAutoComplete
            label="Indicador de Férias"
            required
            value={data.indFerias}
            options={IndFeriasValues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(e, obj) => {
              const indFerias = obj?.value || IndFeriasEnum.Mensal_0
              setData((oldState) => ({
                ...oldState,
                indFerias,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePickerNew
            required
            validationErrors={validationErrors}
            name="dtInicioConcessao"
            label="Data de Início da Concessão"
            value={data.dtInicioConcessao || null}
            onChange={(date) => {
              const dtInicioConcessao = date || ''
              setData((prev) => ({ ...prev, dtInicioConcessao }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePickerNew
            required
            validationErrors={validationErrors}
            name="dtFimConcessao"
            label="Data de Fim da Concessão"
            value={data.dtFimConcessao || null}
            onChange={(date) => {
              const dtFimConcessao = date || ''
              setData((prev) => ({ ...prev, dtFimConcessao }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            required
            validationErrors={validationErrors}
            name="nrDiasConcedidos"
            label="Dias de Férias Concedidos"
            value={data.nrDiasConcedidos || ''}
            onlyNumber
            inputProps={{ maxLength: 3 }}
            onChange={(e) => {
              const nrDiasConcedidos = parseInt(e?.target?.value || '0')
              setData((prev) => ({ ...prev, nrDiasConcedidos }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={9}>
          <DatePickerNew
            required
            validationErrors={validationErrors}
            name="dtAvisoFerias"
            label="Data de Aviso das Férias"
            value={data.dtAvisoFerias || null}
            onChange={(date) => {
              const dtAvisoFerias = date || ''
              setData((prev) => ({ ...prev, dtAvisoFerias }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePickerNew
            required
            validationErrors={validationErrors}
            name="dtEmissaoFerias"
            label="Data de Emissão das Férias"
            value={data.dtEmissaoFerias || null}
            onChange={(date) => {
              const dtEmissaoFerias = date || ''
              setData((prev) => ({ ...prev, dtEmissaoFerias }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePickerNew
            required
            validationErrors={validationErrors}
            name="dtRetorno"
            label="Data de Retorno das Férias"
            value={data.dtRetorno || null}
            onChange={(date) => {
              const dtRetorno = date || ''
              setData((prev) => ({ ...prev, dtRetorno }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ContentDivider title="Abono de Férias" />
        </Grid>

        <Grid item xs={12} sm={6} md={5}>
          <DatePickerNew
            label="Data de Início do Abono"
            value={data.dtInicioAbono || null}
            onChange={(date) => {
              const dtInicioAbono = date || ''
              setData((prev) => ({ ...prev, dtInicioAbono }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={5}>
          <DatePickerNew
            label="Data de Fim do Abono"
            value={data.dtFimAbono || null}
            onChange={(date) => {
              const dtFimAbono = date || ''
              setData((prev) => ({ ...prev, dtFimAbono }))
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label="Dias de Abono"
            value={data.nrDiasAbono || ''}
            onlyNumber
            inputProps={{ maxLength: 3 }}
            onChange={(e) => {
              const nrDiasAbono = parseInt(e?.target?.value || '0')
              setData((prev) => ({ ...prev, nrDiasAbono }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
