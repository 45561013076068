import { MUIAutoComplete } from '~/components/AutoComplete'

import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'

import { ProcessoTrabalhista } from '~/hooks/queries/ProcessoTrabalhista/dtos/ProcessoTrabalhista'
import { useGetAll } from '~/hooks/queries/ProcessoTrabalhista/useGetAll'
import useAmbiente from '~/hooks/useAmbiente'

interface AutoCompleteProcessoTrabalhistaProps extends MUIAutoCompleteProps {
  value: string
  onChange: (value: ProcessoTrabalhista | null) => void
}

export default function AutoCompleteProcessoTrabalhista(
  props: AutoCompleteProcessoTrabalhistaProps,
) {
  const { value, onChange, ...rest } = props

  const { empregador } = useAmbiente()
  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
    refetch,
  } = useGetAll({
    params: {
      empregadorId: empregador.id,
    },
    enabled: false,
  })
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: ProcessoTrabalhista) =>
    `${option.nrProcessoTrabalhista} - ${option.identificacaoProcesso}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Processo Trabalhista"
      value={value}
      onChange={(_, obj: ProcessoTrabalhista) => onChange(obj)}
      optionId="id"
      {...rest}
    />
  )
}
