import { IconButton } from '@material-ui/core'
import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { IndEventoValues } from '~/@types/enums/IndEventoEnum'

const Table = (props) => {
  const { data, onItemClick, isLoading, isFetching, query } = props

  const columns = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'indEvento',
      label: 'Ind. Evento',
      options: {
        customBodyRender: (value) => IndEventoValues.find((d) => d.value === value)?.name || '',
      },
    },
    {
      name: 'codigoIncidenciaCP',
      label: 'CP',
    },
    {
      name: 'codigoIncidenciaFG',
      label: 'FG',
    },
    {
      name: 'codigoIncidenciaIR',
      label: 'IR',
    },
    {
      name: 'rubricaId',
      label: 'Rúbrica eSocial',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <DataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      // pagination={false}
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'descricao'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
