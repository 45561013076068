import React, { useEffect, useState, useCallback, useMemo } from 'react'

import { Grid, Collapse, IconButton, makeStyles, Box, Paper } from '@material-ui/core'
import { ContentContainer, PageHeader, Button, ButtonBox } from 'mio-library-ui'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'
import { AutoCompleteVinculoGRRF } from '~/components/AutoComplete'

import Table from './components/Table'
import Form from './components/Form'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    paddingBottom: theme.spacing(1),
  },
}))

const GerarGRRF = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [data, setData] = useState({})
  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)

  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const notification = useNotification()
  const classes = useStyles()

  const getCollection = useCallback(async (vinculoId) => {
    if (!vinculoId) return

    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get(`/VinculoGRRF/GetByVinculo?vinculoId=${vinculoId}`)
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getCollection(data?.vinculoId)
  }, [getCollection, data.vinculoId])

  useEffect(() => {
    setData({})
    setCollection({
      isLoading: false,
      itens: [],
    })
  }, [anoMes, estabelecimento])

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/VinculoGRRF/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const changeFgtsMesAnterior = useCallback(() => {
    const dtInicialCurrent = moment(anoMes, 'YYYYMM').subtract(1, 'd').format('yyyy-MM-DD')
    const dtFinalCurrent = moment(anoMes, 'YYYYMM').add(7, 'days').format('yyyy-MM-DD')
    const dtRescisao = moment(data?.vinculo?.dtRescisao).format('yyyy-MM-DD')
    if (
      moment(dtRescisao).isAfter(dtInicialCurrent) &&
      moment(dtRescisao).isBefore(dtFinalCurrent)
    ) {
      return true
    } else {
      return false
    }
  }, [anoMes, data])

  const handleOpenForm = useCallback(
    (
      otherData = {
        vinculo: data.vinculo,
        vinculoId: data.vinculoId,
        rpId: data.vinculo.rpId.id,
        fgtsMesAnterior: changeFgtsMesAnterior(),
      },
    ) => {
      setForm((oldState) => ({
        ...oldState,
        isOpen: true,
        data: otherData,
      }))
    },
    [data, changeFgtsMesAnterior],
  )

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [handleOpenForm])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [collection, handleOpenForm],
  )

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitForm = useCallback(() => {
    getCollection(data?.vinculoId)
    handleCloseForm()
  }, [getCollection, data.vinculoId])

  const memoizedAutoCompleteVinculoGRRF = useMemo(() => {
    return (
      <AutoCompleteVinculoGRRF
        estabelecimentoId={estabelecimento.id}
        anoMes={anoMes}
        value={data?.vinculo || null}
        fullWidth
        getOptionSelected={(option, value) => {
          return option.id === value.id
        }}
        onChange={(e, vinculo) => {
          const vinculoId = vinculo?.id || null
          setData((oldState) => ({ ...oldState, vinculoId, vinculo }))
        }}
      />
    )
  }, [anoMes, estabelecimento, data.vinculo])

  return (
    <ContentContainer>
      <PageHeader title="GRRF">
        <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
          {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
        </IconButton>
      </PageHeader>

      <Box component={Paper} padding={2} marginBottom={1}>
        <Collapse in={formCollapseIsOpen}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className={classes.autoComplete}>{memoizedAutoCompleteVinculoGRRF}</div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <ButtonBox>
                <Button
                  size="small"
                  color="primary"
                  onClick={handleClickAddItem}
                  variant="contained"
                  disabled={data?.vinculoId ? false : true}
                >
                  Adicionar
                </Button>
              </ButtonBox>
            </Grid>
          </Grid>
        </Collapse>
      </Box>

      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        onItemClick={handleClickItem}
      />

      <Form
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ContentContainer>
  )
}

export default GerarGRRF
