import React, { useCallback, useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import { PageHeader, ButtonBox, Button, TextField } from 'mio-library-ui'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { DatePicker, NumeroInscricaoTextField, ActionDialog, CurrencyTextField } from '~/components'

import Table from './components/Table'

import api from '~/services/api-pessoal'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import * as yup from 'yup'
import { cnpj } from 'cpf-cnpj-validator'
import { CategoriaValues } from '~/@types/enums/CategoriaEnum'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

const { CNPJ_1 } = tipoInscricaoConsts

const schema = yup.object().shape({
  vrRemuneracao: yup.string().required('Informe o Valor Remuneração'),
  cnpj: yup
    .string()
    .required('Informe o CNPJ')
    .test('validat-cnpj', 'Informe um CNPJ válido', (value) => cnpj.isValid(value)),
  dtInicio: yup
    .date()
    .required('Informe a Data Início')
    .typeError('Informe uma Data válida')
    .nullable(),
  categoria: yup.string().required('Informe a Categoria'),
})

const HEADER_HEIGHT = '50px'
const OutrasRemuneracoes = (props) => {
  const { pessoaFisicaId } = props
  const [currentList, setCurrentList] = useState([])
  const [current, setCurrent] = useState({})

  const [isOpen, setIsOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const notifications = useNotification()
  const dialogNotification = useDialogNotification()

  const pageTitle = 'Outras Remunerações'
  const apiRoute = '/outrasRemuneracoes'

  const getData = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await api.get(`${apiRoute}/GetByPessoaFisica?id=${pessoaFisicaId}`)
      if (response.data.data) {
        setCurrentList(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [pessoaFisicaId])

  useEffect(() => {
    getData()
  }, [getData])

  const handleStartNew = () => {
    setIsOpen(true)

    setCurrent({
      ...current,
      pessoaFisicaId: pessoaFisicaId,
    })
  }

  const handleCancelNew = () => {
    setIsOpen(false)

    setCurrent({})
  }

  const handleEdit = async (id) => {
    try {
      const response = await api.get(`${apiRoute}/${id}`)
      if (response.data.data) {
        setCurrent(response.data.data)
        setIsOpen(true)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  const handleDelete = (id) => {
    setCurrent({ id })

    if (!id) {
      notifications.warning(`Selecione um ${pageTitle} para excluir!`, 'Ops!', 4000)
      return
    }

    setIsOpenDelete(true)
  }

  const handleClickItem = (event, data) => {
    const functions = {
      edit: handleEdit,
      delete: handleDelete,
    }
    functions[event](data)
  }

  const handleSave = useCallback(() => {
    const update = async () => {
      setIsLoading(true)
      try {
        await api.put(`${apiRoute}/${current.id}`, current)
        notifications.put()

        getData()

        setIsOpen(false)
        setCurrent({})
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    const insert = async () => {
      setIsLoading(true)
      try {
        await api.post(`${apiRoute}`, current)
        notifications.post()

        getData()

        setIsOpen(false)
        setCurrent({})
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    if (current.id) {
      update()
      return
    }
    insert()
    // eslint-disable-next-line
  }, [current])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSave,
    data: current,
  })

  const deletar = async () => {
    setIsProcessing(true)

    try {
      const response = await api.delete(`${apiRoute}/${current.id}`)

      if (response.status === 200) {
        notifications.success('Registro foi removido', 'Sucesso!', 4000)
        getData()
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsOpenDelete(false)
    setIsProcessing(false)
    setCurrent({})
  }

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            <Button size="small" onClick={handleStartNew} color="primary" variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={currentList} onItemClick={handleClickItem} isLoading={isLoading} />
      </Box>

      <ActionDialog
        title={pageTitle}
        okLabel="Salvar"
        isOpen={isOpen}
        onClose={handleCancelNew}
        onOkClick={handleValidate}
        onCancelClick={handleCancelNew}
        isOkProcessing={isLoading}
        dialogProps={{ maxWidth: 'md', fullWidth: true }}
      >
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Empresa"
              fullWidth
              value={current.empresa || ''}
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 100 }}
              onChange={(e) => {
                const empresa = e.target.value
                setCurrent({ ...current, empresa })
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <NumeroInscricaoTextField
              label="CNPJ"
              typeMask={CNPJ_1}
              value={current?.cnpj || ''}
              required
              validationErrors={validationErrors}
              name="cnpj"
              onChange={(e, value) => {
                const cnpj = value
                setCurrent((oldState) => ({ ...oldState, cnpj }))
              }}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <DatePicker
              label="Data Início"
              size="small"
              value={current?.dtInicio || null}
              required
              validationErrors={validationErrors}
              name="dtInicio"
              onChange={(date) => {
                const dtInicio = date ? date.format('yyyy-MM-DD') : null
                setCurrent({ ...current, dtInicio })
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <DatePicker
              label="Data Fim"
              size="small"
              value={current.dtFim || null}
              onChange={(date) => {
                const dtFim = date ? date.format('yyyy-MM-DD') : null
                setCurrent({ ...current, dtFim })
              }}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <MUIAutoComplete
              label="Categoria"
              required
              name="categoria"
              validationErrors={validationErrors}
              value={current.categoria}
              options={CategoriaValues}
              optionId="value"
              renderOption={(option) => option.name}
              onChange={(e, obj) => {
                const categoria = obj ? obj.value : ''
                setCurrent((oldState) => ({
                  ...oldState,
                  categoria,
                }))
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <CurrencyTextField
              required
              label="Valor Remuneração"
              variant="outlined"
              fullWidth
              size="small"
              value={current?.vrRemuneracao || null}
              validationErrors={validationErrors}
              name="vrRemuneracao"
              onChange={(e, valor) => {
                const vrRemuneracao = valor || null
                setCurrent({ ...current, vrRemuneracao })
              }}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <CurrencyTextField
              label="Valor Desconto INSS"
              name="vrDescontoINSS"
              variant="outlined"
              fullWidth
              size="small"
              value={current?.vrDescontoINSS}
              onChange={(e, valor) => {
                if (valor) {
                  const vrDescontoINSS = valor
                  setCurrent({ ...current, vrDescontoINSS })
                }
              }}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <CurrencyTextField
              label="Valor Remuneração 13°"
              name="vrRemuneracao13"
              variant="outlined"
              fullWidth
              size="small"
              value={current?.vrRemuneracao13}
              onChange={(e, valor) => {
                if (valor) {
                  const vrRemuneracao13 = valor
                  setCurrent({ ...current, vrRemuneracao13 })
                }
              }}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <CurrencyTextField
              label="Valor Desconto INSS 13°"
              name="vrDescontoINSSDtSalario"
              variant="outlined"
              fullWidth
              size="small"
              value={current?.vrDescontoINSSDtSalario}
              onChange={(e, valor) => {
                if (valor) {
                  const vrDescontoINSSDtSalario = valor
                  setCurrent({ ...current, vrDescontoINSSDtSalario })
                }
              }}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              label="Observação"
              fullWidth
              value={current.observacao || ''}
              variant="outlined"
              inputProps={{
                maxLength: 200,
              }}
              size="small"
              onChange={(e) => {
                const observacao = e.target.value
                setCurrent({ ...current, observacao })
              }}
            />
          </Grid>
        </Grid>
      </ActionDialog>

      <ActionDialog
        title="Excluir Registro"
        okLabel="Sim"
        isOpen={isOpenDelete}
        onClose={() => setIsOpenDelete(false)}
        onOkClick={deletar}
        onCancelClick={() => setIsOpenDelete(false)}
        isOkProcessing={isProcessing}
      >
        <Typography>Você quer mesmo excluir este registro</Typography>
      </ActionDialog>
    </>
  )
}

export default OutrasRemuneracoes
