import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { PageHeader, Button } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import { useStepperContext } from '~/components/StepperForm'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

import { MainDataForm } from '..'
import { VinculoTreinamentoPartial } from '~/hooks/queries/VinculoTreinamento/VinculoTreinamento'

const HEADER_HEIGHT = '50px'
export default function TableTreinamentos() {
  const { mainData, onBack, onSkipAndSubmit, onSubmit } = useStepperContext<MainDataForm>()

  const [collection, setCollection] = useState(mainData.dataTreinamentoList)

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoTreinamentoPartial | null>(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog()

  const notification = useNotification()

  const handleClickItem = (index: number) => {
    openConfirmDelete(index)
  }

  const handleDeleteItem = () => {
    const newItens = collection.filter((i, indexCurrent) => indexCurrent !== dataConfirmDelete)
    closeConfirmDelete()
    setCollection(newItens)
    notification.remove()
  }

  const handleClickAddItem = () => {
    openForm({
      vinculoId: mainData.dataVinculo.id,
      dtRealizacao: mainData.dataAlteracaoContrato.dtAlteracao || undefined,
    })
  }

  const handleAfterSubmitForm = (data: VinculoTreinamentoPartial) => {
    const newItens = [data, ...collection]
    setCollection(newItens)
    closeForm()
  }

  function handleValidate() {
    if (collection.length > 0) {
      onSubmit({
        ...mainData,
        dataTreinamentoList: collection,
      })
    } else {
      onSkipAndSubmit({
        ...mainData,
        dataTreinamentoList: collection,
      })
    }
  }

  return (
    <Box>
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Treinamentos">
          <ButtonBox top={1}>
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={collection} onItemClick={handleClickItem} />
      </Box>

      {isOpenForm && dataForm ? (
        <Form
          isOpen={isOpenForm}
          data={dataForm}
          onClose={closeForm}
          onAfterSubmitForm={handleAfterSubmitForm}
        />
      ) : (
        <></>
      )}

      {isOpenConfirmDelete ? (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      ) : (
        <></>
      )}

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            Finalizar
          </Button>
        </ButtonBox>
      </Box>
    </Box>
  )
}
