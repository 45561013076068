import { formatAnoMes, formatCurrency } from '~/hooks/useUtils'
import { formatToCPF } from 'brazilian-values'
import moment from 'moment'

import { ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import TableDetailVinculo from '~/pages/ApurarConvencaoColetiva/TableDetailVinculo'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ApurarConvencaoColetivaProcessamentoDTO } from '~/hooks/queries/ApurarConvencaoColetiva/ApurarConvencaoColetivaDTO'
import { Box } from '@material-ui/core'

interface TableProps {
  query: string
  data: ApurarConvencaoColetivaProcessamentoDTO[]
  isLoading: boolean
  isFetching: boolean
  rowsExpanded: number[]
  onRowExpanded: (rows: number[]) => void
}

export default function Table({
  query,
  data: _d,
  isLoading,
  isFetching,
  rowsExpanded,
  onRowExpanded,
}: TableProps) {
  const data = _d.map((d) => ({
    ...d,
    vinculoNrInscricao: d?.vinculoNrInscricao ? formatToCPF(d.vinculoNrInscricao) : '',
    vinculoDtAdmissao: d?.vinculoDtAdmissao ? moment(d.vinculoDtAdmissao).format('DD/MM/yyyy') : '',
    vinculoDtRescisao: d?.vinculoDtRescisao ? moment(d.vinculoDtRescisao).format('DD/MM/yyyy') : '',
    competenciaInicial: d?.competenciaInicial ? formatAnoMes(d.competenciaInicial) : '',
    vrVencimentoOriginal: d?.vrVencimentoOriginal ? formatCurrency(d.vrVencimentoOriginal) : '',
    vrVencimentoAtualizado: d?.vrVencimentoAtualizado
      ? formatCurrency(d.vrVencimentoAtualizado)
      : '',
    vrVencimentoApurado: d?.vrVencimentoApurado ? formatCurrency(d.vrVencimentoApurado) : '',
    vrParcela: d?.vrParcela ? formatCurrency(d.vrParcela) : '',
    vrVencimentoUtilizado: d?.vrVencimentoUtilizado ? formatCurrency(d.vrVencimentoUtilizado) : '',
    vrAntecipado: d?.vrAntecipado ? formatCurrency(d.vrAntecipado) : '',
    vrINSSApurado: d?.vrINSSApurado ? formatCurrency(d.vrINSSApurado) : '',
    vrINSSParcela: d?.vrINSSParcela ? formatCurrency(d.vrINSSParcela) : '',
    vrINSSUtilizado: d?.vrINSSUtilizado ? formatCurrency(d.vrINSSUtilizado) : '',
  }))

  const hasDiferencaEntreApuradoEUtilizado = _d.some(
    (d) => d.vrVencimentoApurado !== d.vrVencimentoUtilizado,
  )
  const hasDiferencaINSSEntreApuradoEUtilizado = _d.some(
    (d) => d.vrINSSApurado !== d.vrINSSUtilizado,
  )

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'vinculoCodigo',
      label: 'Código',
    },
    {
      name: 'vinculoNome',
      label: 'Nome',
    },
    {
      name: 'vinculoNrInscricao',
      label: 'CPF',
    },
    {
      name: 'vinculoDtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'vinculoDtRescisao',
      label: 'Rescisão',
    },
    {
      name: 'vrVencimentoOriginal',
      label: 'Total',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrVencimentoAtualizado',
      label: 'Total Atualizado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrAntecipado',
      label: 'Antecipado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrVencimentoApurado',
      label: 'Apurado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrINSSApurado',
      label: 'INSS Apurado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'competenciaInicial',
      label: 'Gerador',
    },
    {
      name: 'id',
      label: 'Parcela',
      options: {
        customBodyRender: (value) => {
          const rowCurrent = _d.find((d) => d.id === value)
          if (!rowCurrent) return
          return (
            <Box textAlign="end">
              {rowCurrent.parcela} / {rowCurrent.nrParcelas}
            </Box>
          )
        },
      },
    },
    {
      name: 'vrParcela',
      label: 'Valor Parcela',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrINSSParcela',
      label: 'INSS',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'reciboId',
      label: 'Recibo',
      options: {
        customBodyRender: (value) => <Ativo ativo={value ? true : false} />,
      },
    },
  ]

  if (hasDiferencaEntreApuradoEUtilizado) {
    columns.splice(9, 0, {
      name: 'vrVencimentoUtilizado',
      label: 'Usado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    })
  }

  if (hasDiferencaINSSEntreApuradoEUtilizado) {
    columns.splice(11, 0, {
      name: 'vrINSSUtilizado',
      label: 'INSS Usado',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    })
  }

  return (
    <ToolsDataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      optionsExpandable={{
        type: 'single',
        expandedChildren: (index: number) => (
          <TableDetailVinculo
            vinculoId={_d[index].vinculoId}
            anoMesApuracao={_d[index].competenciaInicial}
          />
        ),
        onRowExpanded: onRowExpanded,
        rowsExpanded: rowsExpanded,
      }}
      disableAutoHeight
      sherlock={{
        columns: ['vinculoCodigo', 'vinculoNome', 'vinculoNrInscricao'],
        query,
      }}
    />
  )
}
