import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Box, IconButton, Tooltip, makeStyles, useTheme } from '@material-ui/core'
import { People as PeopleIcon, PeopleOutline } from '@material-ui/icons'

import AlignCellValue from '../AlignCellValue'

import { ActionDialog, Button, ButtonBox, CopyLabel, Finder, MUITableTwoRows } from '~/components'

import TableBaseCalculoLotacao from './components/TableBaseCalculoLota'
import DialogPrevidenciaBeneficiario, {
  DialogPrevidenciaBeneficiarioData,
} from '../DialogPrevidenciaBeneficiario'
import DialogIRRFBeneficiario, { DialogIRRFBeneficiarioData } from '../DialogIRRFBeneficiario'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import {
  IndSubsPatronalObraEnum,
  IndSubsPatronalObraValues,
} from '~/@types/enums/IndSubsPatronalObraEnum'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'
import { ApuracaoESocialDTO } from '~/hooks/queries/ApurarEncargos/ApuracaoESocialDTO'

interface ApuracaoEncargosBaseCalculoData {
  id: string
  nome: string
  nrInscricao: string
  isErro: boolean

  CNAEPreponderante: string
  aliqRAT?: number
  fap?: number
  aliqRATAjustada?: number
  indSubsPatronalObra: IndSubsPatronalObraEnum

  CNAEPreponderante_ESocial: string
  aliqRAT_ESocial?: number
  faP_ESocial?: number
  aliqRATAjustada_ESocial?: number
  indSubsPatronalObra_ESocial: IndSubsPatronalObraEnum

  listLotacao: ApuracaoEncargosBaseCalculoDataLotacao[]
}

export interface ApuracaoEncargosBaseCalculoDataLotacao {
  id: string
  nome: string
  isErro: boolean

  fpas: string
  terceiros: string
  terceirosSuspenso: string

  fpaS_ESocial: string
  terceiros_ESocial: string
  terceirosSuspenso_ESocial: string

  listCategoria: ApuracaoEncargosBaseCalculoDataCategoria[]
}

export interface ApuracaoEncargosBaseCalculoDataCategoria {
  categoria: CategoriaEnum
  nome: string
  isErro: boolean

  folhaPagamento: ApuracaoEncargosBaseCalculoDataBase

  eSocial: ApuracaoEncargosBaseCalculoDataBase
}

interface ApuracaoEncargosBaseCalculoDataBase {
  vrBcCp00?: number // B.C.N - Base de Cálculo Normal
  vrBcCp15?: number // B.C.A.15 - Base de Cálculo Aposentadoria 15 anos
  vrBcCp20?: number // B.C.A.20 - Base de Cálculo Aposentadoria 20 anos
  vrBcCp25?: number // B.C.A.25 - Base de Cálculo Aposentadoria 25 anos
  vrSuspBcCp00?: number // B.C.N.S - Base de Cálculo Normal Suspenso
  vrSuspBcCp15?: number // B.C.A.S.15 - Base de Cálculo Suspenso Aposentadoria 15 anos
  vrSuspBcCp20?: number // B.C.A.S.20 - Base de Cálculo Suspenso Aposentadoria 20 anos
  vrSuspBcCp25?: number // B.C.A.S.25 - Base de Cálculo Suspenso Aposentadoria 25 anos
  vrDescontoSEST?: number // SEST
  vrDescontoSENAT?: number // SENAT
  vrSalarioFamilia?: number // Sal. Família
  vrSalarioMaternidade?: number // Sal. Maternidade
}

const useStyles = makeStyles((theme) => ({
  tableWithError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.error.light,
    },
  },
}))

interface DialogBaseCalculoEstabelecimentoProps {
  data: ApuracaoESocialDTO
  indApuracao: IndApuracaoEnum
  isOpen: boolean
  close: () => void
}

export default function DialogBaseCalculoEstabelecimento(
  props: DialogBaseCalculoEstabelecimentoProps,
) {
  const { data, indApuracao, isOpen, close } = props

  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])
  const [collection, setCollection] = useState<ApuracaoEncargosBaseCalculoData[]>([])
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const { anoMes } = useAmbiente()
  const classes = useStyles()
  const theme = useTheme()

  const {
    close: closeDialogPrevidenciaBeneficiario,
    data: dataDialogPrevidenciaBeneficiario,
    isOpen: isOpenDialogPrevidenciaBeneficiario,
    open: openDialogPrevidenciaBeneficiario,
  } = useDialog<DialogPrevidenciaBeneficiarioData | null>(null)

  const {
    close: closeDialogIRRFBeneficiario,
    data: dataDialogIRRFBeneficiario,
    isOpen: isOpenDialogIRRFBeneficiario,
    open: openDialogIRRFBeneficiario,
  } = useDialog<DialogIRRFBeneficiarioData | null>(null)

  useEffect(() => {
    const getCollection = async () => {
      setLoading(true)
      try {
        const response = await api.get('/ApurarEncargos/ObterBaseCalculoApuracaoESocial', {
          params: {
            empregadorId: data.empregadorId,
            periodoApuracao: anoMes,
            indApuracao,
          },
        })
        setCollection(response.data.data.listEstabelecimento)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [data, anoMes, indApuracao])

  return (
    <ActionDialog
      title={`Apuração Encargos - ${
        formatToCPFOrCNPJ(data.nrInscriao) + ' - ' + data.empregadorNome
      }`}
      isOpen={isOpen}
      dialogProps={{
        maxWidth: 'xl',
        fullWidth: true,
      }}
      onClose={close}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button variant="contained" onClick={close}>
            Fechar
          </Button>
        </ButtonBox>
      }
      renderRight={
        <ButtonBox>
          <Tooltip title="Previdência por Beneficiário" arrow>
            <span>
              <IconButton
                color="primary"
                size="small"
                onClick={() =>
                  openDialogPrevidenciaBeneficiario({
                    empregadorId: data?.empregadorId as string,
                    indApuracao: indApuracao,
                    anoMes,
                  })
                }
              >
                <PeopleIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="IRRF por Beneficiário" arrow>
            <span>
              <IconButton
                color="primary"
                size="small"
                onClick={() =>
                  openDialogIRRFBeneficiario({
                    empregadorId: data?.empregadorId as string,
                    indApuracao: indApuracao,
                    anoMes,
                  })
                }
              >
                <PeopleOutline />
              </IconButton>
            </span>
          </Tooltip>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </ButtonBox>
      }
    >
      <MUITableTwoRows
        data={collection}
        isLoading={isLoading}
        options={{
          sherlock: {
            query,
            columns: ['nrInscricao', 'nome'],
          },
          rowExpanded: {
            expandChildren: (index) => (
              <TableBaseCalculoLotacao
                collection={collection[index].listLotacao}
                estabelecimentoId={collection[index].id}
                indApuracao={indApuracao}
              />
            ),
            rowsExpanded: rowsExpanded,
            onRowExpanded: setRowsExpanded,
          },
        }}
        columns={[
          {
            label: 'Número de Inscrição',
            firstRow: 'nrInscricao',
            options: {
              customBodyRenderFirstRow: (value, indexCurrent) => {
                const dataCurrent = collection[indexCurrent]
                return (
                  <Box display="flex" gridGap={10} alignItems="center">
                    <Box
                      bgcolor={dataCurrent.isErro ? theme.palette.error.light : undefined}
                      height="54px"
                      width="8px"
                      borderRadius="0px 32px 32px 0px"
                      ml={-1.5}
                    />
                    <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
                  </Box>
                )
              },
            },
          },
          {
            label: 'Estabelecimento',
            firstRow: 'nome',
            options: {
              minWidth: '20rem',
            },
          },
          {
            label: 'Apuração',
            firstRow: 'nome',
            secondRow: 'nome',
            options: {
              customBodyRenderFirstRow: () => 'Folha',
              customBodyRenderSecondRow: () => 'eSocial',
            },
          },
          {
            label: 'CNAE',
            firstRow: 'cnaePreponderante',
            secondRow: 'cnaePreponderante_ESocial',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].CNAEPreponderante !==
                collection[indexCurrent].CNAEPreponderante_ESocial
                  ? classes.tableWithError
                  : '',
            },
          },
          {
            label: 'RAT',
            firstRow: 'aliqRAT',
            secondRow: 'aliqRAT_ESocial',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].aliqRAT !== collection[indexCurrent].aliqRAT_ESocial
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'FAP',
            firstRow: 'fap',
            secondRow: 'faP_ESocial',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].fap !== collection[indexCurrent].faP_ESocial
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'RAT Ajustada',
            firstRow: 'aliqRATAjustada',
            secondRow: 'aliqRATAjustada_ESocial',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].aliqRATAjustada !==
                collection[indexCurrent].aliqRATAjustada_ESocial
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
              customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
            },
          },
          {
            label: 'Ind. Patronal Obra',
            firstRow: 'indSubsPatronalObra',
            secondRow: 'indSubsPatronalObra_ESocial',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].indSubsPatronalObra !==
                collection[indexCurrent].indSubsPatronalObra_ESocial
                  ? classes.tableWithError
                  : '',
              customBodyRenderFirstRow: (value) =>
                IndSubsPatronalObraValues.find((d) => d.value === value)?.name || '',
              customBodyRenderSecondRow: (value) =>
                IndSubsPatronalObraValues.find((d) => d.value === value)?.name || '',
            },
          },
        ]}
      />

      {dataDialogPrevidenciaBeneficiario && (
        <DialogPrevidenciaBeneficiario
          isOpen={isOpenDialogPrevidenciaBeneficiario}
          onClose={closeDialogPrevidenciaBeneficiario}
          data={dataDialogPrevidenciaBeneficiario}
        />
      )}
      {dataDialogIRRFBeneficiario && (
        <DialogIRRFBeneficiario
          isOpen={isOpenDialogIRRFBeneficiario}
          onClose={closeDialogIRRFBeneficiario}
          data={dataDialogIRRFBeneficiario}
        />
      )}
    </ActionDialog>
  )
}
