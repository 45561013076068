import React from 'react'

import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import ErrorMessage from '../ErrorMessage'

const obterErro = (name, validationErrors) => {
  if (!validationErrors) return false

  const { inner } = validationErrors
  const erroEncontrado = inner.find((item) => {
    const { path } = item
    return name === path
  })

  if (!erroEncontrado) return false
  return erroEncontrado.message
}

const MaskedTextField = (props) => {
  const {
    name,
    validationErrors,
    mask,
    value,
    onChange,
    onBlur,
    beforeMaskedValueChange,
    disabled,
    ...rest
  } = props

  const mensagemDeErro = obterErro(name, validationErrors)
  return (
    <>
      <InputMask
        disabled={disabled}
        mask={mask}
        onChange={onChange}
        onBlur={onBlur}
        maskPlaceholder={null}
        maskChar={null}
        beforeMaskedValueChange={beforeMaskedValueChange}
        value={value}
      >
        {({ inputProps }) => (
          <TextField name={name} disabled={disabled} {...inputProps} {...rest} />
        )}
      </InputMask>
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </>
  )
}

export default MaskedTextField
