import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'

import { Box, Grid, Paper } from '@material-ui/core'
import { ContentContainer, PageHeader, Button, ButtonBox, TextField } from 'mio-library-ui'

import { DatePicker, CollapseContent, CurrencyTextField } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'
import { AutoCompleteAdquirente, MUIAutoComplete } from '~/components/AutoComplete'

import {
  IndComercializacaoValues,
  IndComercializacaoEnum,
} from '~/@types/enums/IndComercializacaoEnum'

import * as yup from 'yup'
import { percentNumber } from '~/hooks/useUtils'

const schema = yup.object().shape({
  adquirente: yup.string().required('Informe o Adquirente'),
  nrDocumento: yup.string().required('Informe o Número da Nota Fiscal'),
  dtEmissaoDocumento: yup.date().required('Informe a Data de Emissão').typeError(''),
  indComercializacao: yup.string().required('Informe o Indicativo de Comercialização'),

  vrBruto: yup.number().min(0.000001, 'Informe o Valor Bruto').required('Informe o Valor Bruto'),
  vrContribuicaoPrevidenciaria: yup
    .number()
    .when(['indComercializacao'], (indComercializacao, schema) => {
      if (
        indComercializacao &&
        parseInt(indComercializacao) === IndComercializacaoEnum.ComercializacaoProducaoIsenta_7
      ) {
        return schema
      }
      return schema
        .min(0.000001, 'Informe a Contribuição Previdenciária')
        .required('Informe a Contribuição Previdenciária')
    }),
  vrRat: yup.number().when(['indComercializacao'], (indComercializacao, schema) => {
    if (
      indComercializacao &&
      parseInt(indComercializacao) === IndComercializacaoEnum.ComercializacaoProducaoIsenta_7
    ) {
      return schema
    }
    return schema.min(0.000001, 'Informe o Valor do RAT').required('Informe o Valor do RAT')
  }),
  vrSenar: yup
    .number()
    .min(0.000001, 'Informe o Valor do SENAR')
    .required('Informe o Valor do SENAR'),
})

const Comercializacao = () => {
  const [data, setData] = useState({})

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [isSubmitting, setSubmitting] = useState(false)
  const [collapseItens, setCollapseItens] = useState({
    form: true,
  })

  const serieRef = useRef(null)
  const indComercRef = useRef(null)
  const nrDocumentoRef = useRef(null)
  const dtEmissaoDocumentoRef = useRef(null)
  const vrBrutoRef = useRef(null)
  const vrContribuicaoPrevidenciariaRef = useRef(null)
  const vrRatRef = useRef(null)
  const vrSenarRef = useRef(null)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get('/AdquirenteItem/GetByEstabelecimento', {
          params: {
            estabelecimentoId: estabelecimento?.id,
            anoMes,
          },
        })
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
          setData({})
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [anoMes, estabelecimento])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        setData(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }
      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [collection],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/AdquirenteItem/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/AdquirenteItem/${data.id}`, data)
        handleAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      data.anoMes = anoMes
      data.estabelecimentoId = estabelecimento?.id
      try {
        const response = await api.post('/AdquirenteItem', data)
        notification.post()
        handleAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, anoMes, estabelecimento])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
      }
      setData({})

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection],
  )

  const toggleCollapse = (item) => {
    setData({})
    setCollapseItens({ ...collapseItens, [item]: !collapseItens[item] })
  }

  const memoizedTable = useMemo(() => {
    return (
      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        onItemClick={handleClickItem}
      />
    )
    // eslint-disable-next-line
  }, [collection])

  function handleChangeVrBruto(value) {
    const vrBruto = value

    let vrContribuicaoPrevidenciaria = 0
    let vrRat = 0
    let vrSenar = 0

    switch (data?.indComercializacao) {
      case IndComercializacaoEnum.ComercializacaoVarejo_2:
        vrContribuicaoPrevidenciaria = percentNumber(vrBruto, 1.2)
        vrRat = percentNumber(vrBruto, 0.1)
        vrSenar = percentNumber(vrBruto, 0.2)
        break
      case IndComercializacaoEnum.ComercializacaoProducaoIsenta_7:
        vrContribuicaoPrevidenciaria = 0
        vrRat = 0
        vrSenar = percentNumber(vrBruto, 0.2)
        break
      default:
        break
    }

    setData((oldState) => ({
      ...oldState,
      vrBruto,
      vrContribuicaoPrevidenciaria,
      vrRat,
      vrSenar,
    }))
  }

  return (
    <ContentContainer>
      <PageHeader title="Lançamento Comercialização Rural" />

      <Box component={Paper} padding={2}>
        <CollapseContent
          title="Formulário de Lançamento"
          isOpen={collapseItens.form}
          onClick={() => toggleCollapse('form')}
          bottom={2}
        >
          <Grid container spacing={2}>
            <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
              <AutoCompleteAdquirente
                label="Adquirente"
                fullWidth
                value={data?.adquirente || ''}
                variant="outlined"
                size="small"
                name="adquirente"
                required
                validationErrors={validationErrors}
                onChange={(e, adquirente) => {
                  const adquirenteId = adquirente?.id || null
                  setData({ ...data, adquirenteId, adquirente })
                  if (adquirente) {
                    indComercRef.current.focus()
                  }
                }}
              />
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <MUIAutoComplete
                label="Indicativo de Comercialização"
                required
                validationErrors={validationErrors}
                name="indComercializacao"
                value={data.indComercializacao}
                options={IndComercializacaoValues}
                inputRef={indComercRef}
                optionId="value"
                renderOption={(option) => option.name}
                onChange={(e, obj) => {
                  const indComercializacao = obj?.value || ''
                  setData((oldState) => ({
                    ...oldState,
                    vrBruto: 0,
                    vrContribuicaoPrevidenciaria: 0,
                    vrRat: 0,
                    vrSenar: 0,
                    indComercializacao,
                  }))
                  if (indComercializacao) {
                    serieRef.current.focus()
                    serieRef.current.select()
                  }
                }}
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
              <TextField
                label="Série"
                fullWidth
                value={data?.serie || ''}
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 5 }}
                inputRef={serieRef}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    nrDocumentoRef.current.focus()
                    nrDocumentoRef.current.select()
                  }
                }}
                onChange={(e) => {
                  const serie = e.target.value
                  setData({ ...data, serie })
                }}
              />
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={8} xs={12}>
              <TextField
                label="Número da Nota Fiscal"
                fullWidth
                value={data?.nrDocumento || ''}
                variant="outlined"
                size="small"
                onlyNumber
                inputProps={{ maxLength: 20 }}
                name="nrDocumento"
                required
                validationErrors={validationErrors}
                inputRef={nrDocumentoRef}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    dtEmissaoDocumentoRef.current.focus()
                    dtEmissaoDocumentoRef.current.select()
                  }
                }}
                onChange={(e) => {
                  const nrDocumento = e.target.value
                  setData({ ...data, nrDocumento })
                }}
              />
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <DatePicker
                label="Data de Emissão"
                size="small"
                value={data?.dtEmissaoDocumento || null}
                name="dtEmissaoDocumento"
                required
                validationErrors={validationErrors}
                inputRef={dtEmissaoDocumentoRef}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    vrBrutoRef.current.input.focus()
                    vrBrutoRef.current.input.select()
                  }
                }}
                onChange={(date) => {
                  const dtEmissaoDocumento = date ? date.format('yyyy-MM-DD') : null
                  setData({ ...data, dtEmissaoDocumento })
                }}
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <CurrencyTextField
                label="Valor Bruto"
                fullWidth
                value={data?.vrBruto || ''}
                variant="outlined"
                size="small"
                name="vrBruto"
                required
                validationErrors={validationErrors}
                ref={vrBrutoRef}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    vrContribuicaoPrevidenciariaRef.current.input.focus()
                    vrContribuicaoPrevidenciariaRef.current.input.select()
                  }
                }}
                onChange={(e, value) => handleChangeVrBruto(value)}
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <CurrencyTextField
                label="Contribuição Previdenciária"
                fullWidth
                value={data?.vrContribuicaoPrevidenciaria || ''}
                variant="outlined"
                size="small"
                name="vrContribuicaoPrevidenciaria"
                validationErrors={validationErrors}
                ref={vrContribuicaoPrevidenciariaRef}
                required={
                  data?.indComercializacao ===
                  IndComercializacaoEnum.ComercializacaoProducaoIsenta_7
                    ? false
                    : true
                }
                disabled={
                  data?.indComercializacao ===
                  IndComercializacaoEnum.ComercializacaoProducaoIsenta_7
                    ? true
                    : false
                }
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    vrRatRef.current.input.focus()
                    vrRatRef.current.input.select()
                  }
                }}
                onChange={(e, value) => {
                  const vrContribuicaoPrevidenciaria = value
                  setData((oldState) => ({
                    ...oldState,
                    vrContribuicaoPrevidenciaria,
                  }))
                }}
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <CurrencyTextField
                label="Valor do RAT"
                fullWidth
                value={data?.vrRat || ''}
                variant="outlined"
                size="small"
                name="vrRat"
                required={
                  data?.indComercializacao ===
                  IndComercializacaoEnum.ComercializacaoProducaoIsenta_7
                    ? false
                    : true
                }
                disabled={
                  data?.indComercializacao ===
                  IndComercializacaoEnum.ComercializacaoProducaoIsenta_7
                    ? true
                    : false
                }
                validationErrors={validationErrors}
                ref={vrRatRef}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    vrSenarRef.current.input.focus()
                    vrSenarRef.current.input.select()
                  }
                }}
                onChange={(e, value) => {
                  const vrRat = value
                  setData((oldState) => ({ ...oldState, vrRat }))
                }}
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <CurrencyTextField
                label="Valor do SENAR"
                fullWidth
                value={data?.vrSenar || ''}
                variant="outlined"
                size="small"
                name="vrSenar"
                required
                validationErrors={validationErrors}
                ref={vrSenarRef}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    handleValidate()
                  }
                }}
                onChange={(e, value) => {
                  const vrSenar = value
                  setData((oldState) => ({ ...oldState, vrSenar }))
                }}
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ButtonBox>
                <Button
                  size="small"
                  color="primary"
                  onClick={handleValidate}
                  isLoading={isSubmitting}
                  variant="contained"
                >
                  Salvar
                </Button>
              </ButtonBox>
            </Grid>
          </Grid>
        </CollapseContent>
      </Box>

      {memoizedTable}

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ContentContainer>
  )
}

export default Comercializacao
