import React, { useEffect, useState } from 'react'

import { List, makeStyles } from '@material-ui/core'
import { ActionDialog } from '~/components'

import CheckItem from './components/CheckItem'

import useNotification from '~/hooks/useNotification'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflow: 'auto',
    maxHeight: 500,
    borderRadius: 5,
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
  },
  nameVinculo: {
    fontSize: '10pt',
    color: 'black',
  },
}))

const AvisoArquivoJaGerado = (props) => {
  const { isOpen, onClose, onOkClick, arrayDatas } = props

  const [checked, setChecked] = useState([])

  const notification = useNotification()

  useEffect(() => {
    setChecked(arrayDatas)
  }, [arrayDatas])

  const classes = useStyles()

  const handleClickOk = () => {
    if (checked.length === 0)
      return notification.error('Por favor informe pelo menos um Funcionário')
    const indexsCheckeds = checked.map((checkedItem) => checkedItem.indexPrincipal)
    onOkClick({ indexs: indexsCheckeds, datas: checked })
  }

  return (
    <ActionDialog
      title="Arquivos já gerados!"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Sim"
      onOkClick={handleClickOk}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <div className={classes.subtitle}>
        Arquivos já gerados para os seguintes Funcionários, Deseja selecioná-los mesmo assim?
      </div>
      <List className={classes.root}>
        {arrayDatas.map((vinculo) => (
          <CheckItem
            key={vinculo.vinculoId}
            value={vinculo}
            onChange={setChecked}
            checkedItens={checked}
            primaryText={<div className={classes.nameVinculo}>{vinculo.vinculoNome}</div>}
            secondaryText={`Gerado: ${moment(vinculo.dtGercaoSD).format('DD/MM/YYYY')}`}
          />
        ))}
      </List>
    </ActionDialog>
  )
}

export default AvisoArquivoJaGerado
