import React from 'react'

import moment from 'moment'

import { ButtonBox } from 'mio-library-ui'
import { IconButton } from '@material-ui/core'

import { Delete as DeleteIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import { codigoTreinamentoCapacitacaoValues } from '~/values/codigoTreinamentoCapacitacaoValues'
import { VinculoTreinamentoPartial } from '~/hooks/queries/VinculoTreinamento/VinculoTreinamento'
import { CodigoTreinamentoCapacitacaoEnum } from '~/@types/enums/CodigoTreinamentoCapacitacaoEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

const formatCodigoTreinamento = (codigoTreinamento: CodigoTreinamentoCapacitacaoEnum) => {
  const optionCurrent = codigoTreinamentoCapacitacaoValues.find(
    (opt) => opt.value === codigoTreinamento,
  )
  return optionCurrent?.name || ''
}

interface TableProps {
  data: VinculoTreinamentoPartial[]
  onItemClick: (index: number) => void
}

export default function Table(props: TableProps) {
  const { data, onItemClick } = props

  const dataFormated = data.map((data) => {
    return {
      ...data,
      dtRealizacao: data?.dtRealizacao ? moment(data.dtRealizacao).format('DD/MM/yyyy') : '',
      codigoTreinamento: data?.codigoTreinamento
        ? formatCodigoTreinamento(data.codigoTreinamento)
        : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtRealizacao',
      label: 'Data da Realização',
    },
    {
      name: 'codigoTreinamento',
      label: 'Código do Treinamento',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                title="Deletar este registro"
                size="small"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick(rowIndex)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable title="" data={dataFormated} columns={columns} />
}
