import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { openDownloadData } from '~/hooks/useUtils'
import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: string[]
  params: { estabelecimentoId: string; cnabId: string; dtPagamento: string }
}

export default function useGerarArquivo(nomeArquivo: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{ data: Blob }>('/CNAB/GerarArquivo', data, {
      params,
    })
    return response.data
  }

  return useMutation(handleRequest, {
    onSuccess: (data) => {
      openDownloadData(nomeArquivo, data)
      notifySuccess('Registros processados com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
