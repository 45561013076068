import { useEffect, useState } from 'react'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { PageHeader, Finder, Checkbox, PrintButton, PDFViewer, Button } from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import Table from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useObterRecibosConsulta } from '~/hooks/queries/RPAutonomo/useObterRecibosConsulta'
import { useEnviarRecibosEstabelecimentos } from '~/hooks/queries/RPAutonomo/useEnviarRecibosEstabelecimentos'

import { relatorioGetByChaveId } from '~/hooks/queries/Relatorio/relatorioGetByChaveId'
import { ReportData } from '~/components/ReportSelector'

const TITLE_PAGE = 'Recibo Pagamento Autônomo'

export default function RPAutonomo() {
  const [query, setQuery] = useState('')
  const [report, setReport] = useState<ReportData | null>(null)

  const { anoMes, estabelecimento } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<string[]>([])

  const [rowsSelecteds, setRowsSelecteds] = useState<number[]>([])
  const [estabelecimentoId, setEstabelecimentoId] = useState<string | null>(estabelecimento.id)
  const [grupoId, setGrupoId] = useState<string | null>(null)

  const {
    data: _c,
    isLoading,
    isFetching,
    refetch,
  } = useObterRecibosConsulta({ anoMes, estabelecimentoId, grupoId })
  const collection = _c || []
  const { mutateAsync: mutateAsyncSendEmail, isLoading: isLoadingSendEmail } =
    useEnviarRecibosEstabelecimentos()

  const theme = useTheme()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setRowsSelecteds([])
  }, [_c])

  useEffect(() => {
    if (estabelecimentoId) {
      setEstabelecimentoId(estabelecimento.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estabelecimento])

  useEffect(() => {
    async function getReportKey() {
      const response = await relatorioGetByChaveId('RPA')
      setReport(response[0] || null)
    }
    getReportKey()
  }, [])

  function handlePrint() {
    const rpIds = rowsSelecteds.map((index) => collection[index].rpId)
    openPDFViewer(rpIds)
    setRowsSelecteds([])
  }

  function handleValidateSendEmail() {
    const autonomosSemEmail: string[] = []

    rowsSelecteds.forEach((index) => {
      const dtCurrent = collection[index]
      if (!dtCurrent.estabelecimentoEmail) {
        autonomosSemEmail.push(dtCurrent.autonomoNome + ' - ' + dtCurrent.autonomoNrInscricao)
      }
    })

    if (autonomosSemEmail.length > 0) {
      const descriptions = [
        'Alguns autônomos estão sem o email cadastrado',
        ...autonomosSemEmail,
        'Deseja prosseguir mesmo assim? Alguns emails não serão enviados',
      ]

      return dialogNotification.info({
        descriptions,
        onConfirm: handleSendEmail,
        labelOnConfirm: 'Confirmar',
      })
    } else {
      handleSendEmail()
    }
  }

  async function handleSendEmail() {
    if (!report) return
    const rpIds = rowsSelecteds.map((index) => collection[index].rpId)
    await mutateAsyncSendEmail({
      reportId: report.id,
      rpIds,
    })
    setRowsSelecteds([])
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} padding={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <AutoCompleteGrupo
              value={grupoId}
              onChange={(e, grupo) => {
                const grupoId = grupo?.id || null
                setGrupoId(grupoId)
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Checkbox
              label="Listar apenas o Estabelecimento atual"
              value={estabelecimentoId ? true : false}
              onChange={(e, value) => {
                setEstabelecimentoId(value ? estabelecimento.id : null)
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box flex={1} position="relative" overflow="auto">
        <Table
          data={collection}
          query={query}
          isLoading={isLoading}
          isFetching={isFetching}
          rowsSelected={rowsSelecteds}
          onRowSelected={setRowsSelecteds}
          onAfterSubmit={refetch}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" gridGap={8}>
        <Button
          title="Enviar Recibo Pagamento Autônomo para cada email dos estabelecimentos selecionados"
          isLoading={isLoadingSendEmail}
          disabled={rowsSelecteds.length > 0 ? false : true}
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleValidateSendEmail}
        >
          Enviar para Estabelecimentos
        </Button>
        <PrintButton disabled={rowsSelecteds.length > 0 ? false : true} onClick={handlePrint} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="RPA"
        title={TITLE_PAGE}
        isSalvarDocumento
        axiosConfig={{
          method: 'post',
          url: '/RPAutonomo/ImprimirVariosRecibos',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
