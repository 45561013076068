import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifyPost } from '~/utils/notification'

import { NotificacaoCriarDTO } from './dtos/NotificacaoCriarDTO'

import { useNotificacaoObterNotificacoesClient } from './useNotificacaoObterNotificacoes'
import { useNotificacaoObterNotificacoesAmbienteClient } from './useNotificacaoObterNotificacoesAmbiente'

interface RequestProps {
  data: NotificacaoCriarDTO
  params: { isEnvironmentCommon: boolean }
}

export default function useNotificacaoCriar() {
  const dialogNotification = useDialogNotification()
  const { invalidateQuery } = useNotificacaoObterNotificacoesClient()
  const { invalidateQuery: invalidateQueryAmbiente } =
    useNotificacaoObterNotificacoesAmbienteClient()

  async function handleRequest({ data, params }: RequestProps) {
    await api.post('/Notificacao', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      invalidateQuery()
      invalidateQueryAmbiente()
    },
    onError: dialogNotification.extractErrors,
  })
}
