const admissional_0 = 0
const periódico = 1
const retornoAoTrabalho_2 = 2
const mudancaDeFunçãoRiscoOcupacional_3 = 3
const monitoracaoPontualNaoEnquadradoNosDemaisCasos_4 = 4
const demissional_9 = 9

export const ASOTipoExameValues = [
  { value: admissional_0, name: '0 - Exame médico admissional' },
  {
    value: periódico,
    name: '1 - Exame médico periódico, conforme Norma Regulamentadora 07 - NR-07 e/ou planejamento do Programa de Controle Médico de Saúde Ocupacional - PCMSO',
  },
  {
    value: retornoAoTrabalho_2,
    name: '2 - Exame médico de retorno ao trabalho',
  },
  {
    value: mudancaDeFunçãoRiscoOcupacional_3,
    name: '3 - Exame médico de mudança de função ou de mudança de risco ocupacional',
  },
  {
    value: monitoracaoPontualNaoEnquadradoNosDemaisCasos_4,
    name: '4 - Exame médico de monitoração pontual, não enquadrado nos demais casos',
  },
  { value: demissional_9, name: '9 - Exame médico demissional' },
]
