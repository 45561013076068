import { useEffect, useState } from 'react'

import { Box, IconButton } from '@material-ui/core'
import { Launch, Remove } from '@material-ui/icons'

import { ActionDialog, Button, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'

const title = 'Erros / Inconsistências'

interface DialogErrorsProps {
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
  data: string[]
}

export default function DialogErrors({ isOpen, onClose, onOpen, data: _d }: DialogErrorsProps) {
  const [isMinimezed, setMinimezed] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setMinimezed(false)
    }
  }, [isOpen])

  const data = _d.map((d) => ({ message: d }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'message',
      label: 'Mensagem',
    },
  ]

  return (
    <Box>
      <ActionDialog
        title={title}
        isOpen={isOpen}
        onClose={onClose}
        customActions={<></>}
        dialogProps={{ maxWidth: 'md', fullWidth: true }}
        renderRight={
          <IconButton
            onClick={() => {
              setMinimezed(true)
              onClose()
            }}
            size="small"
          >
            <Remove fontSize="small" />
          </IconButton>
        }
      >
        <ToolsDataTable data={data} columns={columns} />
      </ActionDialog>

      {isMinimezed && (
        <Box position="absolute" left={0} bottom={0} zIndex={9999} padding={1}>
          <Button
            onClick={() => {
              setMinimezed(false)
              onOpen()
            }}
            variant="contained"
            endIcon={<Launch />}
          >
            {title}
          </Button>
        </Box>
      )}
    </Box>
  )
}
