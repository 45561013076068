import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EtiquetaCTPSFuncionarioDTO } from './EtiquetaCTPSFuncionarioDTO'

interface RequestProps {
  estabelecimentoId?: string
  dtInicioAdmissao: string
  dtFimAdmissao: string
}

export function useObterFuncionarios() {
  const dialogNotification = useDialogNotification()

  async function handleRequest(props: RequestProps) {
    return await api.get<{ data: EtiquetaCTPSFuncionarioDTO[] }>(
      '/EtiquetaCTPS/ObterFuncionarios',
      {
        params: {
          ...props,
        },
      },
    )
  }

  return useMutation(handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
