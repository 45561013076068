import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  Tooltip,
  Divider,
} from '@material-ui/core'
import { CheckCircleRounded } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useUtils from '~/hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: '#f7f7f7',
  },
  accordionSummary: {
    color: '#777',
  },
  flexContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
  },
  reciboMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
  },
  isChecked: {
    color: '#a3eda6',
  },
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      padding: theme.spacing(2),
    },
  },
  dividerBox: {
    width: '100%',
  },
  vrTotalLiquidoBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8,
  },
  vrTotalLiquido: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: 'bold',
  },
}))

export default function RecibosFeriasErroAccordion(props) {
  const { recibo, onToggleCheckbox } = props
  const { formatIdfReciboPagamento, formatCurrency, formatAnoMes } = useUtils()
  const classes = useStyles()

  const isReciboEsocialOrFinalizado = !!recibo.reciboEsocial || recibo.isFinalizado

  return (
    <Accordion className={isReciboEsocialOrFinalizado ? classes.accordion : ''}>
      <AccordionSummary
        className={isReciboEsocialOrFinalizado ? classes.accordionSummary : ''}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box className={classes.flexContent}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                onClick={() => onToggleCheckbox(recibo?.id)}
                color="secondary"
                disabled={isReciboEsocialOrFinalizado}
              />
            }
          />

          <Box className={classes.flexContent}>
            <Typography>{formatIdfReciboPagamento(recibo?.idf)}</Typography>

            {recibo?.reciboEsocial && (
              <Tooltip arrow title="Recibo enviado para o eSocial - Fazer a exclusão no e-Social">
                <Typography className={classes.reciboMessage} variant="body2">
                  eSocial
                  <CheckCircleRounded className={classes.isChecked} />
                </Typography>
              </Tooltip>
            )}

            {recibo?.isFinalizado && !recibo?.reciboEsocial && (
              <Tooltip arrow title="Necessário reabrir o recibo">
                <Typography className={classes.reciboMessage} variant="body2">
                  Recibo Finalizado
                  <CheckCircleRounded className={classes.isChecked} />
                </Typography>
              </Tooltip>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>Número Recibo</Typography>
            <Typography color="textSecondary">{recibo?.nrRecibo}</Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>Recibo eSocial</Typography>
            <Typography color="textSecondary">
              {recibo?.reciboEsocial ? recibo?.reciboEsocial : ' - '}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>Ano/Mês</Typography>
            <Typography color="textSecondary">{formatAnoMes(recibo?.anoMes)}</Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography>Fato Gerador</Typography>
            <Typography color="textSecondary">{formatAnoMes(recibo?.fatoGerador)}</Typography>
          </Grid>
          <Box className={classes.dividerBox} py={2}>
            <Divider />
          </Box>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.vrTotalLiquidoGrid}>
            <Box className={classes.vrTotalLiquidoBox}>
              <Typography>Valor Total Recibo:</Typography>
              <Typography
                color={!isReciboEsocialOrFinalizado ? 'secondary' : ''}
                className={classes.vrTotalLiquido}
              >
                {formatCurrency(recibo?.vrTotalLiquido)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
