import React, { useEffect, useState } from 'react'

import { Finder } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer, Checkbox, Stack } from '~/components'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'

const PAGE_TITLE = 'Relatório de Dados Cadastrais Funcionário'

export default function DadosCadastraisFuncionario() {
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelected] = useState([])
  const [isVersaoSimplificada, setVersaoSimplificada] = useState(false)

  const dialogNotification = useDialogNotification()
  const { anoMes } = useAmbiente()
  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog({
    competencia: anoMes,
    estabelecimentosIds: [],
    filtros: [],
    isSepararPagesEstabelecimento: false,
  })
  const {
    close: closePDFViewerSimples,
    data: dataPDFViewerSimples,
    isOpen: isOpenPDFViewerSimples,
    open: openPDFViewerSimples,
  } = useDialog({
    competencia: anoMes,
    estabelecimentosIds: [],
  })

  useEffect(() => {
    async function getCollection() {
      setLoading(true)
      try {
        const response = await api.get('/EstabelecimentoConsulta')
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  function handlePrint() {
    if (isVersaoSimplificada) {
      const estabelecimentosIds = rowsSelecteds.map((indexCurrent) => collection[indexCurrent].id)
      openPDFViewerSimples({
        competencia: anoMes,
        estabelecimentosIds,
      })
      setRowsSelected([])
    } else {
      openDialogConfirm()
    }
  }

  function handleAfterSubmitDialogConfirm(dt) {
    const estabelecimentosIds = rowsSelecteds.map((indexCurrent) => collection[indexCurrent].id)
    setRowsSelected([])
    openPDFViewer({
      competencia: anoMes,
      estabelecimentosIds,
      ...dt,
    })
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={PAGE_TITLE}>
        <Stack orientation="horizontal" alignItems="center">
          <Box width={300}>
            <Checkbox
              label="Versão Simplificada"
              value={isVersaoSimplificada}
              onChange={(_, checked) => setVersaoSimplificada(checked)}
            />
          </Box>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </Stack>
      </PageHeader>
      <Table
        isLoading={isLoading}
        query={query}
        data={collection}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelecteds}
      />

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        onClose={closeDialogConfirm}
        onAfterSubmit={handleAfterSubmitDialogConfirm}
      />

      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={handlePrint} disabled={!(rowsSelecteds.length > 0)} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="DadosCadastraisFuncionario"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/DadosCadastraisFuncionario/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />

      <PDFViewer
        isOpen={isOpenPDFViewerSimples}
        onClose={closePDFViewerSimples}
        reportKey="DadosCadastraisFuncionarioSimples"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/DadosCadastraisFuncionario/ObterRelatorioSimples',
          data: dataPDFViewerSimples.estabelecimentosIds,
          params: {
            competencia: dataPDFViewerSimples.competencia,
          },
        }}
      />
    </Box>
  )
}
