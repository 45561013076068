import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Paper, useTheme } from '@material-ui/core'

import { Button, Finder, PageHeader } from '~/components'
import { AutoCompleteSindicato } from '~/components/AutoComplete'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'
import { formatAnoMes } from '~/hooks/useUtils'

import useObterConvencaoColetivaProcessada from '~/hooks/queries/ApurarConvencaoColetiva/useObterConvencaoColetivaProcessada'
import useProcessarParaReciboPagamento from '~/hooks/queries/ApurarConvencaoColetiva/useProcessarParaReciboPagamento'

const schema = yup.object().shape({
  sindicatoId: yup.string().required('Informe o Sindicato'),
})

interface DataProps {
  sindicatoId: string
}

export default function ProcessarConvencaoColetiva() {
  const [query, setQuery] = useState('')
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])

  const [dataForm, setDataForm] = useState<DataProps>({
    sindicatoId: '',
  })

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data: dataForm,
  })
  const { anoMes, estabelecimento } = useAmbiente()
  const theme = useTheme()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterConvencaoColetivaProcessada({
    anoMes,
    estabelecimentoId: estabelecimento.id,
    sindicatoId: dataForm.sindicatoId,
  })
  const data = _data || []

  const { mutateAsync: mutateAsyncProcessamento, isLoading: isLoadingProcessamento } =
    useProcessarParaReciboPagamento()

  useEffect(() => {
    setRowsExpanded([])
  }, [_data])

  async function handleSubmit() {
    await mutateAsyncProcessamento({
      anoMes,
      estabelecimentoId: estabelecimento.id,
      sindicatoId: dataForm.sindicatoId,
    })
  }

  return (
    <Box
      p={2}
      height="100%"
      width="100%"
      display="flex"
      gridGap={theme.spacing(1)}
      flexDirection="column"
    >
      <PageHeader title={'Processar Convenção Coletiva - ' + formatAnoMes(anoMes)}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} padding={2}>
        <AutoCompleteSindicato
          name="sindicatoId"
          validationErrors={validationErrors}
          label="Sindicato"
          value={dataForm.sindicatoId || null}
          onChange={(e, sindicatoObj) => {
            const sindicatoId = sindicatoObj?.id || ''
            setDataForm((prev) => ({
              ...prev,
              sindicatoId,
            }))
          }}
        />
      </Box>

      <Box flex={1} position="relative" overflow="auto" minHeight="300px">
        <Box position="absolute" width="100%">
          <Table
            query={query}
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            rowsExpanded={rowsExpanded}
            onRowExpanded={setRowsExpanded}
          />
        </Box>
      </Box>

      <Box component={Paper} padding={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleValidate}
          isLoading={isLoadingProcessamento}
          disabled={data.length > 0 ? false : true}
        >
          Processar
        </Button>
      </Box>
    </Box>
  )
}
