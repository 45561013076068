import MUIAutoComplete from '../MUIAutoComplete'

import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'
import useAmbiente from '~/hooks/useAmbiente'

import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'

import { TipoInscricaoLabels } from '~/@types/enums/TipoInscricaoEnum'

import { limparString, orderBy } from '~/utils/utils'
import ListboxComponentVirtualized from '~/components/AutoComplete/ListboxComponentVirtualized'

export default function AutoCompleteEstabelecimento(props) {
  const { label, anoMes: _anoMes, ...rest } = props

  const { anoMes: anoMesAmbiente } = useAmbiente(false)

  const anoMes = _anoMes ?? anoMesAmbiente

  const {
    data: _data,
    isLoading,
    isFetching,
    refetch,
  } = useEstabelecimentoObterFiltrado({
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
    anoMes,
  })
  const options = _data || []

  const renderOption = (option) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      ListboxComponent={ListboxComponentVirtualized}
      isLoading={isLoading || isFetching}
      options={options}
      getOptions={refetch}
      renderOption={renderOption}
      renderOptionComponent={(option) => (
        <AutoCompleteOptionDescription
          title={`${option.codigo} - ${option.nome}`}
          descriptions={[
            {
              description: `${TipoInscricaoLabels[option.tipoInscricao]} : ${
                option.nrInscricaoMascara
              }`,
            },
          ]}
        />
      )}
      filterOptions={(options, { inputValue }) => {
        const isSearchNrInscricao = inputValue.includes('.')
        const isSearchCodigo = /^\d+$/.test(inputValue)

        let field = 'nome'

        if (isSearchNrInscricao) {
          field = 'nrInscricao'
        } else {
          if (isSearchCodigo) {
            field = 'codigo'
          }
        }

        const value = limparString(inputValue).toLowerCase()

        const opts = options.filter((element) =>
          limparString(`${element[field]}`).toLowerCase().includes(value),
        )

        return orderBy(opts, field)
      }}
      label={label}
      {...rest}
    />
  )
}

AutoCompleteEstabelecimento.defaultProps = {
  label: 'Estabelecimento',
}
