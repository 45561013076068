import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'

import useCredentials from '~/hooks/useCredentials'
import useOnlyDataC from '~/hooks/useOnlyDataC'

import ErrorBoundary from '~/components/ErrorBoundary'

import Layout from '~/pages/Layout'
import Login from '~/pages/Login'
import PageNaoEncontrada from '~/pages/PageNaoEncontrada'

import routes from './routes'
import routesSST from './routesSST'
import { tipoLicencaConsts } from '~/values/tipoLicencaValues'

function PrivateRoutes(props) {
  const { routes } = props

  const { isAuthenticated } = useCredentials()

  if (!isAuthenticated) return <Redirect to={{ pathname: '/' }} />

  return (
    <Route>
      <Layout>
        <ErrorBoundary>
          <Switch>
            {routes.map(({ path, component }) => (
              <Route key={path} exact path={path} component={component} />
            ))}
            <Route path="*" component={PageNaoEncontrada} />
          </Switch>
        </ErrorBoundary>
      </Layout>
    </Route>
  )
}

const Routes = () => {
  const [routesCurrent, setRoutesCurrent] = useState([])

  const { isUserDataC, routesOnlyDataC } = useOnlyDataC()
  const parametrosSistema = useSelector((state) => state.app.parametrosSistema)

  useEffect(() => {
    async function processRoutes() {
      let routesCurrent = []
      if (!parametrosSistema) return
      switch (parametrosSistema.tipoLicenca) {
        case tipoLicencaConsts.normal_1:
          routesCurrent = routes
          break
        case tipoLicencaConsts.SST_2:
          routesCurrent = routesSST
          break
        default:
          routesCurrent = []
      }
      const newRoutes = routesCurrent.filter(
        (router) => !(routesOnlyDataC.includes(router.path) && !isUserDataC),
      )
      setRoutesCurrent(newRoutes)
    }
    processRoutes()
  }, [routesOnlyDataC, isUserDataC, parametrosSistema])

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoutes routes={routesCurrent} />
        <Route path="*" component={PageNaoEncontrada} />
      </Switch>
    </HashRouter>
  )
}

export default Routes
