import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ESocialGrupoEventoEnum } from '~/@types/enums/ESocialGrupoEventoEnum'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

export const queryUseObterTotalEventosGrupoEnvio = '/ESocial/ObterTotalEventosGrupoEnvio'

export interface ESocialTotalizarEventosDTO {
  grupoEventos: ESocialGrupoEventoEnum
  totalEventos: number
}

interface UseObterTotalEventosGrupoEnvioProps {
  anoMes: string
  grupoId: string | null
  filterEventos: ESocialEventoEnum[]
  indApuracao: IndApuracaoEnum
}

export function useObterTotalEventosGrupoEnvio(props: UseObterTotalEventosGrupoEnvioProps) {
  const { anoMes, grupoId, filterEventos, indApuracao } = props

  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: ESocialTotalizarEventosDTO[] }>(
      queryUseObterTotalEventosGrupoEnvio,
      filterEventos,
      {
        params: { anoMes, grupoId, indApuracao },
      },
    )
    return response?.data?.data || []
  }

  const query = useQuery(
    [queryUseObterTotalEventosGrupoEnvio, filterEventos, anoMes, grupoId, indApuracao],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )

  return {
    ...query,
    data: query?.data || [],
  }
}
