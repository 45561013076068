const inicial_1 = 1
const reabertura_2 = 2
const comunicacaoObito_3 = 3

export const tipoCATValues = [
  { value: inicial_1, name: '1 - Inicial' },
  { value: reabertura_2, name: '2 - Reabertura' },
  { value: comunicacaoObito_3, name: '3 - Comunicação de Óbito' },
]

export const tipoCATConsts = {
  inicial_1,
  reabertura_2,
  comunicacaoObito_3,
}
