import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ESocialGrupoEventoConsultaEnum } from '~/@types/enums/ESocialGrupoEventoConsultaEnum'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'

import { ESocialEmpregadorTransmitirDTO } from './useObterEventosTransmissaoEnvio'

export const queryUseObterEventosTransmissaoAcompanhamento =
  '/ESocial/ObterEventosTransmissaoAcompanhamento'

interface useObterEventosTransmissaoAcompanhamentoProps {
  grupoEvento: ESocialGrupoEventoConsultaEnum
  anoMes: string
  filterEventos: ESocialEventoEnum[]
  grupoId: string | null
  indApuracao: IndApuracaoEnum
}

export function useObterEventosTransmissaoAcompanhamento(
  props: useObterEventosTransmissaoAcompanhamentoProps,
) {
  const { grupoEvento, anoMes, filterEventos, grupoId, indApuracao } = props

  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: ESocialEmpregadorTransmitirDTO[] }>(
      queryUseObterEventosTransmissaoAcompanhamento,
      filterEventos,
      {
        params: { grupoEvento, anoMes, indApuracao, grupoId },
      },
    )
    return response?.data?.data || []
  }

  const query = useQuery(
    [
      queryUseObterEventosTransmissaoAcompanhamento,
      grupoEvento,
      anoMes,
      indApuracao,
      filterEventos,
      grupoId,
    ],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )

  return {
    ...query,
    data: query?.data || [],
  }
}
