import { useEffect, useState, useMemo } from 'react'

import * as yup from 'yup'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { getDateMinAnoMes } from '~/utils/utils'

import { Box, Button, Grid, Paper, Slide, useTheme } from '@material-ui/core'

import {
  DatePickerNew,
  PageHeader,
  TextFieldChangeEstabelecimentoEmail,
  ToolsDataTable,
  Finder,
  CopyLabel,
} from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import { useHistory, useLocation } from 'react-router-dom'

import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

const schema = yup.object().shape({
  dtLimite: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data Limite da Concessão'),
})

export const timeAnimation = 200

export interface RelatorioFeriasVencidasStateTransferencia {
  dtLimite: string
  grupoId: string
  estabelecimentosId: string[]
}

export default function RelatorioFeriasVencidasMain() {
  const [rowsSeleceteds, setRowsSeleceteds] = useState<number[]>([])
  const [data, setData] = useState({
    dtLimite: '',
    grupoId: '',
  })
  const [query, setQuery] = useState('')
  const [isSlide, setSlide] = useState(true)

  const { state } = useLocation<RelatorioFeriasVencidasStateTransferencia | undefined>()
  const history = useHistory<RelatorioFeriasVencidasStateTransferencia>()
  const theme = useTheme()
  const { anoMes } = useAmbiente()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const {
    data: _data,
    isLoading,
    isFetching,
    refetch,
  } = useEstabelecimentoObterFiltrado({
    anoMes,
    grupoId: data.grupoId,
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
  })
  const collection = useMemo(() => _data || [], [_data])

  useEffect(() => {
    if (state) {
      const indexsSelecteds = collection.reduce<number[]>((array, d, index) => {
        if (state.estabelecimentosId.includes(d.id)) {
          array.push(index)
        }
        return array
      }, [])

      setData({
        dtLimite: state.dtLimite,
        grupoId: state.grupoId,
      })
      setRowsSeleceteds(indexsSelecteds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    if (!state) {
      setData((prev) => ({ ...prev, dtLimite: getDateMinAnoMes(anoMes) }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anoMes])

  function handleSubmit() {
    setSlide(false)
    setTimeout(
      () =>
        history.push({
          pathname: '/relatorio-ferias-vencidas-result',
          state: {
            dtLimite: data.dtLimite,
            grupoId: data.grupoId,
            estabelecimentosId: rowsSeleceteds.map((index) => collection[index].id),
          },
        }),
      timeAnimation,
    )
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'nrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'id',
      label: 'Email',
      options: {
        setCellHeaderProps: () => ({
          style: { width: 400 },
        }),
        customBodyRender: (id) => {
          const estabelecimento = collection.find((d) => d.id === id)
          return (
            <TextFieldChangeEstabelecimentoEmail
              estabelecimentoId={id}
              email={estabelecimento?.email || ''}
              afterSubmit={refetch}
            />
          )
        },
      },
    },
  ]

  return (
    <Slide
      direction="right"
      in={isSlide}
      mountOnEnter
      unmountOnExit
      timeout={state ? timeAnimation : 0}
    >
      <Box
        height="100%"
        width="100%"
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(1)}
      >
        <PageHeader title="Relatório de Férias Vencidas">
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </PageHeader>

        <Box component={Paper} p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <DatePickerNew
                label="Data de Vencimento"
                size="small"
                value={data?.dtLimite || null}
                required
                validationErrors={validationErrors}
                name="dtLimite"
                onChange={(date) => {
                  const dtLimite = date || ''
                  setData((prev) => ({
                    ...prev,
                    dtLimite,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <AutoCompleteGrupo
                value={data.grupoId}
                onChange={(e, grupo) => {
                  const grupoId = grupo?.id || ''
                  setData((prev) => ({ ...prev, grupoId }))
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box flex={1} position="relative" minHeight={300} overflow="auto">
          <Box position="absolute" width="100%">
            <ToolsDataTable
              data={collection}
              isLoading={isLoading}
              isFetching={isFetching}
              columns={columns}
              disableAutoHeight
              sherlock={{
                query,
                columns: ['codigo', 'nrInscricao', 'nome'],
              }}
              optionsSelectable={{
                type: 'multiple',
                onRowSelected: setRowsSeleceteds,
                rowsSelected: rowsSeleceteds,
              }}
            />
          </Box>
        </Box>

        <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleValidate}
            disabled={rowsSeleceteds.length === 0}
          >
            Buscar
          </Button>
        </Box>
      </Box>
    </Slide>
  )
}
