import React from 'react'

import { Box, BoxProps, Typography, makeStyles } from '@material-ui/core'

import emptyImage from '../../assets/images/empty.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gridGap: theme.spacing(1),
  },
}))

interface EmptyWarningProps extends BoxProps {
  message: string
}

export default function EmptyWarning(props: EmptyWarningProps) {
  const { message, ...rest } = props

  const classes = useStyles()

  return (
    <Box className={classes.root} {...rest}>
      <img
        src={emptyImage}
        style={{
          height: '100%',
          width: '100%',
        }}
        alt="empty"
      />
      <Typography>{message}</Typography>
    </Box>
  )
}
