import { Grid } from '@material-ui/core'
import { useState } from 'react'

import * as yup from 'yup'
import { IndEventoValues } from '~/@types/enums/IndEventoEnum'

import { ActionDialog, TextField } from '~/components'
import { AutoCompleteEvento } from '~/components/AutoComplete'

import { ModeloColunaImportacao } from '~/hooks/queries/ModeloPlanilhaImportacao/ModeloColunaImportacao'

import useValidationErrors from '~/hooks/useValidationErrors'

const schema = yup.object().shape({
  eventoId: yup.string().required('Informe o Evento'),
  label: yup.string().required('Informe a Identificação da Coluna'),
  ordem: yup
    .number()
    .required('Informe a ordem')
    .min(1, 'Informe pelo menos 1 para o campo Ordem')
    .max(99, 'Informe no máximo 99 para o campo Ordem'),
})

interface FormColuna {
  isOpen: boolean
  onClose: () => void
  data: ModeloColunaImportacao | null
  onAfterSubmit: (data: ModeloColunaImportacao) => void
  previuosOrdem: number
}

export default function FormColuna({
  isOpen,
  onClose,
  data: _data,
  onAfterSubmit,
  previuosOrdem,
}: FormColuna) {
  const [data, setData] = useState<ModeloColunaImportacao>(
    _data || ({ ordem: previuosOrdem + 1 } as ModeloColunaImportacao),
  )

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Modelo Planilha Importação"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutoCompleteEvento
            label="Evento"
            required
            validationErrors={validationErrors}
            name="eventoId"
            value={data?.eventoId || ''}
            onChange={(e: FixLater, obj: FixLater) => {
              const eventoId = obj?.id || ''
              const label = obj?.nome || ''
              setData((prev) => ({
                ...prev,
                eventoId,
                label,
              }))
            }}
            renderOption={(option: FixLater) =>
              `${option.codigo} - ${option.nome} - ${
                IndEventoValues.find((d) => d.value === option.indEvento)?.name
              }`
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Identificação da Coluna"
            required
            validationErrors={validationErrors}
            name="label"
            value={data?.label || ''}
            onChange={(e) => {
              const label = e.target.value
              setData((prev) => ({
                ...prev,
                label,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Ordem"
            type="number"
            required
            validationErrors={validationErrors}
            name="ordem"
            value={data?.ordem || ''}
            onChange={(e) => {
              const ordem = parseInt(e.target.value || '0')
              setData((prev) => ({
                ...prev,
                ordem,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
