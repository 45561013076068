import React, { useState } from 'react'

import { ContainerComponent } from '~/components'

import Form from './components/Form'
import Responsaveis from './components/Responsaveis'
import AgenteNocivo from './components/AgenteNocivo'

const Container = (props) => {
  const { isOpen, onClose, data, onAfterSubmitForm, vinculo, estabelecimento } = props
  const [wasModified, setWasModified] = useState(false)

  return (
    <ContainerComponent
      title="Cadastro de Condição Ambiente de Trabalho"
      subtitle={vinculo?.nome || ''}
      isOpen={isOpen}
      onClose={onClose}
      formWasModified={wasModified}
      tabs={[
        {
          component: (
            <Form
              vinculo={vinculo}
              estabelecimento={estabelecimento}
              data={data}
              onAfterSubmitForm={onAfterSubmitForm}
              onClose={onClose}
              setWasModified={setWasModified}
            />
          ),
          label: 'Cadastro',
        },
        {
          component: <Responsaveis condicaoAmbienteTrabalhoId={data.id} />,
          label: 'Responsáveis',
          disabled: !data?.id ? true : false,
        },
        {
          component: <AgenteNocivo condicaoAmbienteTrabalhoId={data.id} />,
          label: 'Agentes Nocivos',
          disabled: !data?.id ? true : false,
        },
      ]}
    />
  )
}

export default Container
