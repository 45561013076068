import { useState, memo } from 'react'

import { Box, Paper, Typography } from '@material-ui/core'

import { Button, PageHeader, Stack, StackContainer } from '~/components'
import { AutoCompleteVinculo } from '~/components/AutoComplete'
import { Ativo } from '~/components/Displays'

import FormNovo from './components/FormNovo'
import FormAtualizar from './components/FormAtualizar'
import Table from './components/Table'
import TotalizadorHorasExtra from './components/TotalizadorHorasExtra'

import useAmbiente from '~/hooks/useAmbiente'
import { useObterFolhaPonto } from '~/hooks/queries/FolhaPonto/useObterFolhaPonto'
import { useProcessarParaReciboFolhaPonto } from '~/hooks/queries/FolhaPonto/useProcessarParaReciboFolhaPonto'

const MemoTable = memo(Table)

export default function LancamentoFolhaPonto() {
  const [vinculoId, setVinculoId] = useState(null)

  const { anoMes, estabelecimento } = useAmbiente()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterFolhaPonto({
    params: {
      anoMes,
      vinculoId,
    },
  })

  const { mutateAsync: mutateAsyncProcess, isLoading: isLoadingProcess } =
    useProcessarParaReciboFolhaPonto()

  async function handleProcessRP() {
    await mutateAsyncProcess({
      params: {
        folhaPontoId: _d?.id || '',
      },
    })
  }

  return (
    <StackContainer>
      <PageHeader title="Lançamento Folha de Ponto" />

      <Box component={Paper} p={1}>
        <Stack orientation="horizontal" pb={1}>
          <AutoCompleteVinculo
            label="Funcionário"
            value={vinculoId || ''}
            estabelecimentoId={estabelecimento?.id}
            anoMes={anoMes}
            optionId="id"
            onChange={(_, obj) => {
              const vinculoId = obj?.id || ''
              setVinculoId(vinculoId)
            }}
          />
          <Stack orientation="horizontal" alignItems="center" justifyContent="center" width="200px">
            <Typography variant="body1">Recibo:</Typography>
            <Ativo ativo={_d?.rpId ? true : false} />
          </Stack>
        </Stack>

        {vinculoId ? (
          _d ? (
            <FormAtualizar data={_d} />
          ) : (
            <FormNovo vinculoId={vinculoId} anoMes={anoMes} />
          )
        ) : (
          <></>
        )}
      </Box>

      <Box flex={1} minHeight={300}>
        <MemoTable
          data={_d?.folhaPontoDias || []}
          tolerancia={_d?.tempoTolerancia || ''}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </Box>

      {_d && (
        <Stack orientation="horizontal" justifyContent="space-between" alignItems="center">
          <TotalizadorHorasExtra data={_d?.folhaPontoDias || []} />

          <Button
            variant="contained"
            disabled={_d ? false : true}
            isLoading={isLoadingProcess}
            onClick={handleProcessRP}
          >
            Processar para o Recibo
          </Button>
        </Stack>
      )}
    </StackContainer>
  )
}
