import { MUIDataTableColumnDef } from 'mui-datatables'
import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'

import { useObterDesmembramentoFeriasPorRecibo } from '~/hooks/queries/RPFerias/useObterDesmembramentoFeriasPorRecibo'
import { formatAnoMes, formatCurrency } from '~/hooks/useUtils'

interface DialogDesmembramentoFeriasProps {
  rpId: string
  subtitle: string
  isOpen: boolean
  onClose: () => void
}

export default function DialogDesmembramentoFerias({
  rpId,
  subtitle,
  isOpen,
  onClose,
}: DialogDesmembramentoFeriasProps) {
  const { data: _d, isLoading, isFetching } = useObterDesmembramentoFeriasPorRecibo(rpId)
  const data = _d
    ? _d.map((d) => ({
        ...d,
        anoMes: d?.anoMes ? formatAnoMes(d.anoMes) : '',
        vrBaseINSS: d?.vrBaseINSS ? formatCurrency(d.vrBaseINSS) : '',
        vrINSS: d?.vrINSS ? formatCurrency(d.vrINSS) : '',
        vrBaseFGTS: d?.vrBaseFGTS ? formatCurrency(d.vrBaseFGTS) : '',
        vrFGTS: d?.vrFGTS ? formatCurrency(d.vrFGTS) : '',
        vrSalarioFamilia: d?.vrSalarioFamilia ? formatCurrency(d.vrSalarioFamilia) : '',
      }))
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'anoMes',
      label: 'Ano/Mês',
    },
    {
      name: 'diasFerias',
      label: 'Dias',
    },
    {
      name: 'vrBaseINSS',
      label: 'Base INSS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrINSS',
      label: 'Valor INSS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrBaseFGTS',
      label: 'Base FGTS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrFGTS',
      label: 'Valor FGTS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'qtdCotasSalarioFamilia',
      label: 'Cotas Salário Família',
    },
    {
      name: 'vrSalarioFamilia',
      label: 'Valor Salário Família',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
  ]

  return (
    <ActionDialog
      title="Desmembramento de Férias"
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
      }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        disableAutoHeight
        pagination={false}
      />
    </ActionDialog>
  )
}
