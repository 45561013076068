import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  CircularProgress as MuiCircularProgress,
  Button,
  IconButton,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { PageHeader, ButtonBox } from 'mio-library-ui'

import PropType from 'prop-types'

function Actions(props) {
  const { onOkClick, onCancelClick, cancelLabel, okLabel, isOkProcessing } = props
  return (
    <MuiDialogActions>
      <Button onClick={onCancelClick} size="small" variant="outlined" color="primary">
        {cancelLabel}
      </Button>
      <Button
        disabled={isOkProcessing}
        onClick={onOkClick}
        size="small"
        variant="contained"
        color="primary"
      >
        {!isOkProcessing ? okLabel : <MuiCircularProgress size={16} />}
      </Button>
    </MuiDialogActions>
  )
}

export default function ActionDialog(props) {
  const {
    isOpen,
    title,
    subtitle,
    children,
    customActions,
    onClose,
    disableEnforceFocus,
    dialogProps,
    dialogContentProps,
    renderRight,
    fullScreen,
    ...rest
  } = props

  function handleClose(event, reason) {
    if (reason === 'backdropClick') return
    onClose(event, reason)
  }

  return (
    <MuiDialog
      {...dialogProps}
      open={isOpen}
      onClose={handleClose}
      disableEnforceFocus={disableEnforceFocus}
      fullScreen={fullScreen}
    >
      {title && (
        <MuiDialogTitle>
          <PageHeader
            title={title}
            subtitle={subtitle}
            renderRight={
              <ButtonBox spacing={1}>
                {renderRight && renderRight}
                <IconButton size="small" onClick={onClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </ButtonBox>
            }
          />
        </MuiDialogTitle>
      )}
      <MuiDialogContent {...dialogContentProps}>{children}</MuiDialogContent>
      {customActions ? customActions : <Actions {...props} {...rest} />}
    </MuiDialog>
  )
}

ActionDialog.defaultProps = {
  okLabel: 'Ok',
  cancelLabel: 'Cancelar',
  isOkProcessing: false,
  disableEnforceFocus: false,
  dialogProps: {
    fullWidth: true,
    maxWidth: 'sm',
    open: false,
  },
  subtitle: null,
  renderRight: false,
}

ActionDialog.propTypes = {
  title: PropType.string,
  subtitle: PropType.string,
  onClose: PropType.func,
  isOpen: PropType.bool.isRequired,
  onOkClick: PropType.func,
  onCancelClick: PropType.func,
  cancelLabel: PropType.string,
  okLabel: PropType.string,
  isOkProcessing: PropType.bool,
  customActions: PropType.node,
  children: PropType.node,
  dialogProps: PropType.any,
  dialogContentProps: PropType.any,
  rest: PropType.shape({
    onClose: PropType.func,
    onOkClick: PropType.func,
    onCancelClick: PropType.func,
    cancelLabel: PropType.string,
    okLabel: PropType.string,
    isOkProcessing: PropType.bool,
  }),
  disableEnforceFocus: PropType.bool,
  renderRight: PropType.any,
  fullScreen: PropType.bool,
}
