import React, { useState, useEffect, useCallback } from 'react'

import { Grid, makeStyles, Divider } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { AutoCompletePlanoSaude } from '~/components/AutoComplete'
import { AnoMesTextField, CurrencyTextField } from '~/components'

import VinculoPlanoSaudeDependente from './components/VinculoPlanoSaudeDependente'

import api from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '100%',
  },
}))

const schema = yup.object().shape({
  planoSaude: yup.string().required('Informe o Plano Saúde').nullable(),
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, pessoaFisicaId } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/VinculoPlanoSaude/${data?.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/VinculoPlanoSaude', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const calcValorTotal = () => {
    let vrTotal = 0
    const vrMensalidadeTitular = data?.vrMensalidadeTitular || 0
    const vrMensalidadeDependente = data?.vrMensalidadeDependente || 0
    const vrCustoTitular = data?.vrCustoTitular || 0
    const vrCustoDependente = data?.vrCustoDependente || 0
    vrTotal = vrMensalidadeTitular + vrMensalidadeDependente + vrCustoTitular + vrCustoDependente
    if (vrTotal > 9999999999999) vrTotal = 9999999999999
    setData({ ...data, vrTotal })
  }

  return (
    <ActionDialog
      title="Cadastro de Plano de Saúde - Lançamento"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: data?.id ? classes.heightPaper : undefined,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <AutoCompletePlanoSaude
            required
            value={data?.planoSaude || null}
            validationErrors={validationErrors}
            name="planoSaude"
            onChange={(e, planoSaude) => {
              const planoSaudeId = planoSaude?.id || ''
              setData({ ...data, planoSaudeId, planoSaude })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Vr. Total"
            fullWidth
            value={data?.vrTotal || 0}
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <AnoMesTextField
            label="Ano/Mês Início"
            fullWidth
            value={data?.anoMesInicio || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="anoMesInicio"
            onChange={(e) => {
              const anoMesInicio = e.target.value.replace('/', '')
              setData({ ...data, anoMesInicio })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <AnoMesTextField
            label="Ano/Mês Fim"
            fullWidth
            value={data?.anoMesFim || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="anoMesFim"
            onChange={(e) => {
              const anoMesFim = e.target.value.replace('/', '')
              setData({ ...data, anoMesFim })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Vr. Mensalidade Titular"
            fullWidth
            value={data?.vrMensalidadeTitular || 0}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const vrMensalidadeTitular = value
              setData({ ...data, vrMensalidadeTitular })
            }}
            onBlur={calcValorTotal}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Vr. Mensalidade Dependente"
            fullWidth
            value={data?.vrMensalidadeDependente || 0}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const vrMensalidadeDependente = value
              setData({ ...data, vrMensalidadeDependente })
            }}
            onBlur={calcValorTotal}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Vr. Custo Titular"
            fullWidth
            value={data?.vrCustoTitular || 0}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const vrCustoTitular = value
              setData({ ...data, vrCustoTitular })
            }}
            onBlur={calcValorTotal}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Vr. Custo Dependente"
            fullWidth
            value={data?.vrCustoDependente || 0}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const vrCustoDependente = value
              setData({ ...data, vrCustoDependente })
            }}
            onBlur={calcValorTotal}
          />
        </Grid>
        {data?.id && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Divider />
          </Grid>
        )}
      </Grid>
      {data?.id && (
        <VinculoPlanoSaudeDependente
          vinculoPlanoSaudeId={data?.id}
          pessoaFisicaId={pessoaFisicaId}
        />
      )}
    </ActionDialog>
  )
}

export default Form
