import api from '~/services/api-pessoal'

import { IndDiasFeriasProporcionaisRestantesEnum } from '~/@types/enums/IndDiasFeriasProporcionaisRestantesEnum'

export interface FeriasColetivaRequestDTO {
  estabelecimentoId: string
  setorId: string | null
  nrDiasFerias: number
  dtInicioFerias: Date | string
  dtFimFerias: Date | string
  nrDiasAbono: number | null
  dtInicioAbono: Date | string | null
  dtFimAbono: Date | string | null
  dtPagamentoFerias: Date | string
  dtAvisoFerias: Date | string
  indDiasFeriasProporcionaisRestantes: IndDiasFeriasProporcionaisRestantesEnum
}

export interface FeriasColetivaRequestDTOForm
  extends Omit<
    FeriasColetivaRequestDTO,
    'dtInicioFerias' | 'dtFimFerias' | 'dtPagamentoFerias' | 'dtAvisoFerias'
  > {
  dtInicioFerias: Date | string | null
  dtFimFerias: Date | string | null
  dtPagamentoFerias: Date | string | null
  dtAvisoFerias: Date | string | null
  isImprimirAlertas: boolean
}

export interface FeriasColetivaConcedidaProgramadaDTO {
  id: string
  dtInicio: Date | string
  dtFim: Date | string | null
  dtInicioAbono: Date | string | null
  dtFimAbono: Date | string | null
  nrDiasFerias: number
  nrDiasAbono: number
}

export interface FeriasColetivaAlertaDTO {
  descricao: string
}

export interface FeriasColetivaDTO {
  funcionarioId: string
  nrInscricao: string
  nome: string
  dtAdmissao: Date | string
  feriasPeriodoId: string
  dtPeriodoInicio: Date | string | null
  dtPeriodoFim: Date | string | null
  nrDiasConcedidos: number
  nrDiasProgramados: number
  nrFaltasPeriodo: number
  nrDiasDireito: number
  nrDiasSaldoAnterior: number
  nrDiasLicencaRemunerada: number
  nrDiasLicencaRemuneradaAtual: number
  nrDiasLicencaRemuneradaSeguinte: number
  competenciaAtual: string
  competenciaSeguinte: string
  nrDiasSaldo: number
  nrDiasFeriasProporcionaisRestantes: number
  indDiasFeriasProporcionaisRestantes: IndDiasFeriasProporcionaisRestantesEnum
  lotacaoId: string
  feriasConcedidas: FeriasColetivaConcedidaProgramadaDTO[]
  valoresSimulacao: FeriasColetivaSimulacaoValoresDTO
  alertas: FeriasColetivaAlertaDTO[]
  alertasCriticos: FeriasColetivaAlertaDTO[]
}

export interface FeriasColetivaSimulacaoValoresDTO {
  vrSalarioContratual: number
  vrFerias: number
  vrUmTercoFerias: number
  vrLicenca: number
  vrTotal: number
}

export async function obterFuncionariosAnalitico(data: FeriasColetivaRequestDTO) {
  const response = await api.post<{ data: FeriasColetivaDTO[] }>(
    '/FeriasColetiva/ObterFuncionariosAnalitico',
    data,
  )
  return response.data.data
}
