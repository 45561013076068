import { useEffect, useState } from 'react'

import { PageHeader, TreeView } from '~/components'

import Content from './Content'

import { useEstabelecimentoVinculosMenu, VinculoMenuItem } from '~/hooks/queries/useEstabelecimento'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { Box, Typography } from '@material-ui/core'

export default function VinculoFerias() {
  const [vinculo, setVinculo] = useState<VinculoMenuItem | null>(null)

  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Estagiario_3,
  ])
  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    setVinculo(null)
  }, [anoMes, estabelecimento])

  return (
    <TreeView
      onSelectItem={(event, value) => setVinculo(value)}
      data={dataMenu}
      isLoading={isLoadingMenu}
      searchBy={['vinculoNome']}
      renderOption={(option) => option?.vinculoNome}
    >
      {vinculo ? (
        <Content vinculo={vinculo} />
      ) : (
        <Box>
          <PageHeader title="Férias" subtitle="-" />
          <Typography>Selecione um Funcionário</Typography>
        </Box>
      )}
    </TreeView>
  )
}
