import React, { useEffect, useState, useMemo, useCallback } from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Finder } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { AlertContainer, Checkbox, ToolsDataTable } from '~/components'

import TableEventsExpandeds from './components/TableEventsExpandeds'

import {
  statusProcessTransmissaoValues,
  statusProcessTransmissaoConsts,
} from '~/values/statusProcessTransmissaoValues'

import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  heightDialog: {
    height: '100%',
  },
  rootHeaderActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  waitCheckbox: {
    color: theme.palette.alerts.primary + '!important',
  },
  processCheckbox: {
    color: theme.palette.alerts.info + '!important',
  },
  finishCheckbox: {
    color: theme.palette.alerts.success + '!important',
  },
  warningCheckbox: {
    color: theme.palette.alerts.warning + '!important',
  },
  errorCheckbox: {
    color: theme.palette.alerts.danger + '!important',
  },
}))

function formatLabel(status) {
  const element = statusProcessTransmissaoValues.find((obj) => obj.value === status)
  return element?.name || ''
}

const {
  statusProcessTransmissao_wait_1,
  statusProcessTransmissao_process_2,
  statusProcessTransmissao_finish_3,
  statusProcessTransmissao_finish_warning_4,
  statusProcessTransmissao_error_5,
} = statusProcessTransmissaoConsts

export default function DialogDetails(props) {
  const { isOpen, data: _data, onClose, anoMes, handleReloadData } = props

  const [data, setData] = useState([])
  const [status, setStatus] = useState([])
  const [rowsExpanded, setRowsExpanded] = useState([])
  const [query, setQuery] = useState('')

  const classes = useStyles()

  useEffect(() => {
    if (!isOpen) return
    const newData = _data.data.map((item) => ({
      ...item?.eSocialDashboard,
      empregadorId: item?.empregadorId,
      nome: item?.nome,
    }))
    setData(newData)
    setStatus([_data.status])
    setRowsExpanded([])
    setQuery('')
  }, [_data, isOpen])

  const verifyIfRenderColumn = useCallback(
    (statusCurrent) => {
      return status.includes(statusCurrent) ? true : 'excluded'
    },
    [status],
  )

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'aguardando',
        label: formatLabel(statusProcessTransmissao_wait_1),
        options: {
          display: verifyIfRenderColumn(statusProcessTransmissao_wait_1),
        },
      },
      {
        name: 'processamento',
        label: formatLabel(statusProcessTransmissao_process_2),
        options: {
          display: verifyIfRenderColumn(statusProcessTransmissao_process_2),
        },
      },
      {
        name: 'finalizado',
        label: formatLabel(statusProcessTransmissao_finish_3),
        options: {
          display: verifyIfRenderColumn(statusProcessTransmissao_finish_3),
        },
      },
      {
        name: 'finalizadoAdvertencia',
        label: formatLabel(statusProcessTransmissao_finish_warning_4),
        options: {
          display: verifyIfRenderColumn(statusProcessTransmissao_finish_warning_4),
        },
      },
      {
        name: 'erro',
        label: formatLabel(statusProcessTransmissao_error_5),
        options: {
          display: verifyIfRenderColumn(statusProcessTransmissao_error_5),
        },
      },
    ],
    [verifyIfRenderColumn],
  )

  function changeEventsForTableExpanded(index) {
    const {
      aguardandoDetalhes: _aguardandoDetalhes,
      processamentoDetalhes: _processamentoDetalhes,
      finalizadoDetalhes: _finalizadoDetalhes,
      finalizadoAdvertenciaDetalhes: _finalizadoAdvertenciaDetalhes,
      erroDetalhes: _erroDetalhes,
      empregadorId,
    } = data[index]

    const aguardandoDetalhes = _aguardandoDetalhes.map((item) => ({
      ...item,
      status: statusProcessTransmissao_wait_1,
      empregadorId,
    }))
    const processamentoDetalhes = _processamentoDetalhes.map((item) => ({
      ...item,
      status: statusProcessTransmissao_process_2,
      empregadorId,
    }))
    const finalizadoDetalhes = _finalizadoDetalhes.map((item) => ({
      ...item,
      status: statusProcessTransmissao_finish_3,
      empregadorId,
    }))
    const finalizadoAdvertenciaDetalhes = _finalizadoAdvertenciaDetalhes.map((item) => ({
      ...item,
      status: statusProcessTransmissao_finish_warning_4,
      empregadorId,
    }))
    const erroDetalhes = _erroDetalhes.map((item) => ({
      ...item,
      status: statusProcessTransmissao_error_5,
      empregadorId,
    }))

    const detailsMap = {
      [statusProcessTransmissao_wait_1]: aguardandoDetalhes,
      [statusProcessTransmissao_process_2]: processamentoDetalhes,
      [statusProcessTransmissao_finish_3]: finalizadoDetalhes,
      [statusProcessTransmissao_finish_warning_4]: finalizadoAdvertenciaDetalhes,
      [statusProcessTransmissao_error_5]: erroDetalhes,
    }

    const newData = []
    status.forEach((sts) => newData.push(...detailsMap[sts]))

    return newData
  }

  function renderExpandableRow(index) {
    const newData = changeEventsForTableExpanded(index)
    const dataOrdenad = _.orderBy(newData, ['status'])
    return (
      <TableEventsExpandeds
        data={dataOrdenad}
        anoMes={anoMes}
        handleReloadData={handleReloadData}
      />
    )
  }

  function changeCheckBox(statusCurrent) {
    setRowsExpanded([])
    if (!status.some((sts) => sts === statusCurrent))
      return setStatus((oldState) => [...oldState, statusCurrent])
    const newStatus = status.filter((sts) => sts !== statusCurrent)
    setStatus(newStatus)
  }

  function changeAllCheckBoxs() {
    setRowsExpanded([])
    if (status.length > 0) return setStatus([])
    setStatus([
      statusProcessTransmissao_wait_1,
      statusProcessTransmissao_process_2,
      statusProcessTransmissao_finish_3,
      statusProcessTransmissao_finish_warning_4,
      statusProcessTransmissao_error_5,
    ])
  }

  return (
    <ActionDialog
      title="Transmissão eSocial"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      customActions={<></>}
      renderRight={<Finder onSearch={setQuery} onClose={() => setQuery('')} />}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xl',
        classes: {
          paper: classes.heightDialog,
        },
      }}
    >
      <Box height="100%" pb={2}>
        <AlertContainer>
          <Box className={classes.rootHeaderActions}>
            <Checkbox
              label={status.length > 0 ? 'Remover todos' : 'Selecionar todos'}
              value={status.length > 0 ? true : false}
              onChange={changeAllCheckBoxs}
              fullWidth={false}
              checkBoxProps={{
                color: 'primary',
              }}
              title={status.length > 0 ? 'Remover todos os filtros' : 'Selecionar todos os filtros'}
            />
            <Checkbox
              value={status.includes(statusProcessTransmissao_wait_1)}
              label={formatLabel(statusProcessTransmissao_wait_1)}
              onChange={() => changeCheckBox(statusProcessTransmissao_wait_1)}
              fullWidth={false}
              checkBoxProps={{
                classes: {
                  checked: classes.waitCheckbox,
                },
              }}
            />
            <Checkbox
              value={status.includes(statusProcessTransmissao_process_2)}
              label={formatLabel(statusProcessTransmissao_process_2)}
              onChange={() => changeCheckBox(statusProcessTransmissao_process_2)}
              fullWidth={false}
              checkBoxProps={{
                classes: {
                  checked: classes.processCheckbox,
                },
              }}
            />
            <Checkbox
              value={status.includes(statusProcessTransmissao_finish_3)}
              label={formatLabel(statusProcessTransmissao_finish_3)}
              onChange={() => changeCheckBox(statusProcessTransmissao_finish_3)}
              fullWidth={false}
              checkBoxProps={{
                classes: {
                  checked: classes.finishCheckbox,
                },
              }}
            />
            <Checkbox
              value={status.includes(statusProcessTransmissao_finish_warning_4)}
              label={formatLabel(statusProcessTransmissao_finish_warning_4)}
              onChange={() => changeCheckBox(statusProcessTransmissao_finish_warning_4)}
              fullWidth={false}
              checkBoxProps={{
                classes: {
                  checked: classes.warningCheckbox,
                },
              }}
            />
            <Checkbox
              value={status.includes(statusProcessTransmissao_error_5)}
              label={formatLabel(statusProcessTransmissao_error_5)}
              onChange={() => changeCheckBox(statusProcessTransmissao_error_5)}
              fullWidth={false}
              checkBoxProps={{
                classes: {
                  checked: classes.errorCheckbox,
                },
              }}
            />
          </Box>
        </AlertContainer>
        <ToolsDataTable
          data={data}
          columns={columns}
          sherlock={{
            query,
            columns: ['nome'],
          }}
          pagination={false}
          optionsExpandable={{
            type: 'single',
            expandedChildren: renderExpandableRow,
            onRowExpanded: setRowsExpanded,
            rowsExpanded: rowsExpanded,
            expandOnClick: true,
          }}
        />
      </Box>
    </ActionDialog>
  )
}
