import { MUIAutoComplete } from '~/components/AutoComplete'

import useGetByEstabelecimento from '~/hooks/queries/CNAB/useGetByEstabelecimento'

import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'
import { CNAB } from '~/hooks/queries/CNAB/dtos/CNAB'

import useAmbiente from '~/hooks/useAmbiente'

interface AutoCompleteCNABProps extends MUIAutoCompleteProps {
  value: string
  onChange: (value: CNAB | null) => void
}

export default function AutoCompleteCNAB({ value, onChange, ...rest }: AutoCompleteCNABProps) {
  const { estabelecimento } = useAmbiente()
  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
    refetch,
  } = useGetByEstabelecimento({
    params: {
      id: estabelecimento.id,
    },
  })
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: CNAB) => `${option?.bancoAgencia?.nome || ''}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Banco"
      value={value}
      onChange={(_, obj: CNAB) => onChange(obj)}
      optionId="id"
      {...rest}
    />
  )
}
