import { useState } from 'react'

import { useTheme, Box, Tabs, Tab, Paper } from '@material-ui/core'

import { TabPanel } from '~/components'

import PrevidenciaSocial from './PrevidenciaSocial'
import FGTSDigital from './FGTSDigital'

enum TabsDemonstracao {
  PrevidenciaSocial,
  FGTSDigital,
}

export default function DemonstracaoApuracao() {
  const [tab, setTab] = useState(TabsDemonstracao.PrevidenciaSocial)

  const theme = useTheme()

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <Paper>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => setTab(value)}
        >
          <Tab label="Previdência Social" />
          <Tab label="FGTS" />
        </Tabs>
      </Paper>

      <Box>
        <TabPanel value={tab} index={TabsDemonstracao.PrevidenciaSocial} style={{ height: '100%' }}>
          <PrevidenciaSocial />
        </TabPanel>
        <TabPanel value={tab} index={TabsDemonstracao.FGTSDigital} style={{ height: '100%' }}>
          <FGTSDigital />
        </TabPanel>
      </Box>
    </Box>
  )
}
