import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

const query = '/ImportacaoPlanilhaFaltas/EnviarPlanilhasEmail'

interface RequestProps {
  data: string[]
  params: { anoMes: string }
}

export function useEnviarPlanilhasEmail() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    await api.post(query, data, {
      params,
    })
    notifySuccess('Planilhas enviados por email com sucesso')
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
