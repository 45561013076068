import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestProps {
  email: string
  vinculoId: string
}

export function useVinculoAtualizarEmail() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    await api.patch('/Vinculo/AtualizarEmail', null, {
      params: props,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: async () => notification.success('Email atualizado com sucesso'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
