import { useState } from 'react'

import moment from 'moment'

import { Box, IconButton, LinearProgress, useTheme } from '@material-ui/core'
import { Send as SendIcon } from '@material-ui/icons'

import { ButtonBox } from 'mio-library-ui'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { ActionDialog, Button, ToolsDataTable } from '~/components'

import Form from '../Form'

import useDialog from '~/hooks/useDialog'

import { useConcederSaldoFerias } from '~/hooks/queries/VinculoAfastamento/useConcederSaldoFerias'
import { useObterDataInicial } from '~/hooks/queries/VinculoAfastamento/useObterDataInicial'

import { IndMotivoAfastamentoEnum } from '~/@types/enums/IndMotivoAfastamentoEnum'

import { VinculoAfastamentoSaldoFerias } from '~/hooks/queries/VinculoAfastamentoSaldoFerias/VinculoAfastamentoSaldoFerias'
import {
  VinculoAfastamento,
  VinculoAfastamentoForm,
} from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'

interface DialogSaldoFeriasProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoAfastamentoSaldoFerias[]
  vinculoId: string
}

export default function DialogSaldoFerias(props: DialogSaldoFeriasProps) {
  const { isOpen, onClose, data: _data, vinculoId } = props

  const [idSaldoFeriasToConceder, setIdSaldoFeriasToConceder] = useState<string | null>(null)

  const { mutateAsync: concederSaldoFerias, isLoading } = useConcederSaldoFerias()
  const { mutateAsync: mutateAsyncDataInicial, isLoading: isLoadingDataInicial } =
    useObterDataInicial()

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoAfastamentoForm | null>(null)
  const theme = useTheme()

  const data = _data.map((d) => ({
    ...d,
    dtConcedido: d?.dtConcedido ? moment(d.dtConcedido).format('DD/MM/yyyy') : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nrDiasSaldo',
      label: 'Dias de Saldo',
    },
    {
      name: 'dtConcedido',
      label: 'Concedido',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          const item = _data.find((d) => d.id === value)
          if (!item) return
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Conceder Saldo de Férias"
                color="primary"
                aria-label="Editar"
                disabled={item?.dtConcedido ? true : false}
                onClick={() => handleClickConceder(item)}
              >
                <SendIcon
                  style={{
                    color: item?.dtConcedido ? theme.palette.action.disabled : '',
                  }}
                  fontSize="small"
                  color="primary"
                />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  async function handleClickConceder(saldoFerias: VinculoAfastamentoSaldoFerias) {
    const dtInitial = await mutateAsyncDataInicial(null)

    setIdSaldoFeriasToConceder(saldoFerias.id)

    const qtdDiasAfastado = saldoFerias?.nrDiasSaldo || 0
    const dtInicioAfastamento = saldoFerias?.vinculoAfastamentoGerado?.dtRetorno
      ? moment(saldoFerias?.vinculoAfastamentoGerado?.dtRetorno).add(1, 'day').format('yyyy-MM-DD')
      : null
    const dtRetorno = dtInicioAfastamento
      ? moment(dtInicioAfastamento).add(qtdDiasAfastado, 'days').format('yyyy-MM-DD')
      : null

    openForm({
      ...dtInitial,
      vinculoId,
      motivoAfastamento: IndMotivoAfastamentoEnum.Ferias_15,
      qtdDiasAfastado,
      dtInicioAfastamento,
      dtRetorno,
    })
  }

  async function handleAfterSubmitForm(afastamento: VinculoAfastamento) {
    if (!idSaldoFeriasToConceder) return
    await concederSaldoFerias({
      afastamentoSaldoFeriasId: idSaldoFeriasToConceder,
      novoAfastamento: afastamento,
    })
    onClose()
  }

  function handleCloseForm() {
    setIdSaldoFeriasToConceder(null)
    closeForm()
  }

  return (
    <ActionDialog
      title="Saldos de Férias"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" color="primary" size="small" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <Box height={4}>{isLoadingDataInicial && <LinearProgress />}</Box>
      <ToolsDataTable data={data} columns={columns} pagination={false} disableAutoHeight />
      {isOpen && dataForm ? (
        <Form
          isOpen={isOpenForm}
          onClose={handleCloseForm}
          data={dataForm}
          onAfterSubmitForm={handleAfterSubmitForm}
          isLoading={isLoading}
          disabledFields={['motivoAfastamento', 'qtdDiasAfastado']}
        />
      ) : (
        <></>
      )}
    </ActionDialog>
  )
}
