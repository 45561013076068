const faltouMais32Vezes_1 = 1
const deixouEmpregoENaoReadmitido_2 = 2
const permaneceuGozoLicencaPercepcaoSalario_3 = 3
const deixouTrabalharPercepcaoSalario_4 = 4
const percebeuPrevidenciaSocialPrestaoAcidente_5 = 5

export const motivosPerdaFeriasConts = {
  faltouMais32Vezes_1,
  deixouEmpregoENaoReadmitido_2,
  permaneceuGozoLicencaPercepcaoSalario_3,
  deixouTrabalharPercepcaoSalario_4,
  percebeuPrevidenciaSocialPrestaoAcidente_5,
}

export const motivosPerdaFeriasValues = [
  { value: faltouMais32Vezes_1, name: 'Faltou mais de 32 vezes, sem justificativas;' },
  {
    value: deixouEmpregoENaoReadmitido_2,
    name: 'Deixou o emprego e não foi readmitido dentro de 60 dias subsequêntes à sua saída;',
  },
  {
    value: permaneceuGozoLicencaPercepcaoSalario_3,
    name: 'Permaneceu em gozo de licença, com percepção de salário, por mais de 30 dias;',
  },
  {
    value: deixouTrabalharPercepcaoSalario_4,
    name: 'Deixou de trabalhar, com percepção de salário, por mais de 30 dias, em virtude de paralisação parcial ou total da empresa;',
  },
  {
    value: percebeuPrevidenciaSocialPrestaoAcidente_5,
    name: 'Percebeu da Previdência Social prestação de acidente de trabalho ou auxílio-doença por mais de 6 meses, embora descontínuos.',
  },
]
