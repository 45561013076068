const baseCalculoTrintaDias = 1
const baseCalculoDiaMaximoMes = 2

export const indCalculoFeriasValues = [
  { value: baseCalculoTrintaDias, name: '30 Dias' },
  { value: baseCalculoDiaMaximoMes, name: 'Dia Máximo Mês' },
]

export const indCalculoFeriasConsts = {
  baseCalculoTrintaDias,
  baseCalculoDiaMaximoMes,
}
