import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'
import { CardChartDTO } from './dtos/CardChartDTO'

const query = '/Painel/RescisoesChart'

interface RequestParams {
  anoMes: string
  estabelecimentoId?: string
}

export default function usePainelRescisoesChart(params: RequestParams) {
  async function handleRequest() {
    const response = await api.get<{ data: CardChartDTO }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: console.error,
  })
}
