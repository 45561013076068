import { useCallback } from 'react'

import { IconButton } from '@material-ui/core'
import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { formatToBRL } from 'brazilian-values'
import moment from 'moment'

const makeColumns = (onItemClick) => [
  {
    name: 'nome',
    label: 'Nome do Funcionário',
  },
  {
    name: 'vrEmprestimo',
    label: 'Valor do Empréstimo',
    options: {
      customBodyRender: (value) => (value ? formatToBRL(value).replace('R$ ', '') : null),
    },
  },
  {
    name: 'dtEmissao',
    label: 'Data de Emissão',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'id',
    label: 'Ações',
    options: {
      filter: true,
      sort: false,
      empty: true,
      customBodyRender: (value) => {
        return (
          <ButtonBox spacing={0} justifyContent="center">
            <IconButton
              size="small"
              title="Editar este registro"
              color="primary"
              aria-label="Editar"
              onClick={() => {
                onItemClick('edit', value)
              }}
            >
              <EditIcon fontSize="small" color="primary" />
            </IconButton>

            <IconButton
              title="Deletar este registro"
              size="small"
              color="primary"
              aria-label="Deletar"
              onClick={() => {
                onItemClick('delete', value)
              }}
            >
              <DeleteIcon fontSize="small" color="primary" />
            </IconButton>
          </ButtonBox>
        )
      },
    },
  },
]

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, isFetching, query } = props

  const data = _data.map((d) => ({
    ...d,
    nome: d?.vinculo?.pessoaFisica?.nome || '',
  }))

  const columns = useCallback(() => {
    return makeColumns(onItemClick)
  }, [onItemClick])

  return (
    <DataTable
      title=""
      data={data}
      columns={columns()}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['nome'],
      }}
    />
  )
}

export default Table
