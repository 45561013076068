import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'

import { VinculoValeTransporteDTO } from './VinculoValeTransporteDTO'

import useDialogNotification from '~/hooks/useDialogNotification'

export const queryObterTodosPorVinculo = '/VinculoValeTransporte/ObterTodosPorVinculo'

interface RequestProps {
  vinculoId: string
}

export default function useObterTodosPorVinculo(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoValeTransporteDTO[] }>(
      queryObterTodosPorVinculo,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterTodosPorVinculo, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
