import React, { useState } from 'react'

import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid } from '@material-ui/core'

import { Button, DatePickerNew, PageHeader, TextField } from '~/components'

import { useStepperContext } from '~/components/StepperForm'
import useValidationErrors from '~/hooks/useValidationErrors'

import { MainDataForm } from '..'

const schema = yup.object().shape({
  dtAlteracao: yup
    .date()
    .required('Informe a Data da Alteração')
    .typeError('Informe uma Data válida'),
})

export default function FormAlteracaoCadastral() {
  const { mainData, onCancel, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataAlteracaoCadastral)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    onNext({
      ...mainData,
      dataAlteracaoCadastral: data,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <PageHeader title="Alteração Cadastral" />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <DatePickerNew
          label="Data da Alteração"
          size="small"
          value={data?.dtAlteracao || null}
          required
          validationErrors={validationErrors}
          name="dtAlteracao"
          onChange={(date) => {
            const dtAlteracao = date
            setData((oldState) => ({
              ...oldState,
              dtAlteracao,
            }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Observação do Sistema"
          fullWidth
          value={data?.observacao || ''}
          size="small"
          multiline
          inputProps={{
            maxLength: 150,
          }}
          rows={2}
          onChange={(e) => {
            const observacao = e?.target?.value || ''
            setData((oldState) => ({
              ...oldState,
              observacao,
            }))
          }}
        />
      </Grid>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
