import { useEffect, useState } from 'react'

import { TextField } from 'mio-library-ui'
import { Box, IconButton, makeStyles, fade } from '@material-ui/core'
import { KeyboardArrowRight as OpenIcon, Close as CloseIcon } from '@material-ui/icons'

import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  contentButtons: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  finderRoot: {
    padding: 0,
    margin: 0,
    border: '1px solid #FFFFFF',
    borderRadius: theme.shape.borderRadius,
    color: '#fff',
    backgroundColor: fade(theme.palette.common.white, 0.05),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.1),
    },
  },
  icon: {
    transition: theme.transitions.create(['transform'], {
      duration: 500,
    }),
  },
  iconOpen: {
    transform: 'rotate(-180deg)',
  },
  iconClosed: {
    transform: 'rotate(0)',
  },
  closeOpenIcon: {
    color: theme.palette.primary.main,
  },
}))

export default function SearchTextField(props) {
  const { isOpen, handleQuery, changeIsOpen } = props

  const [term, setTerm] = useState('')

  const classes = useStyles()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleQuery(term)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
    //eslint-disable-next-line
  }, [term])

  function handleChangeOpen() {
    setTerm('')
    changeIsOpen()
  }

  return (
    <Box className={classes.contentButtons}>
      <Box>
        {isOpen && (
          <TextField
            placeholder="Pesquisar Menu"
            variant="outlined"
            fullWidth
            size="small"
            onChange={(e) => setTerm(e?.target?.value || '')}
            classes={{
              root: classes.finderRoot,
            }}
            value={term}
            InputProps={{
              endAdornment: (
                <IconButton position="end" size="small" onClick={() => setTerm('')}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        )}
      </Box>
      <IconButton size="small" onClick={handleChangeOpen} className={classes.closeOpenIcon}>
        <OpenIcon
          className={clsx(classes.icon, {
            [classes.iconOpen]: isOpen,
            [classes.iconClosed]: !isOpen,
          })}
        />
      </IconButton>
    </Box>
  )
}
