import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { DataTable, ButtonBox } from 'mio-library-ui'

import { Delete as DeleteIcon } from '@material-ui/icons'

import moment from 'moment'
import _ from 'lodash'

const TableDates = (props) => {
  const { data: _data, onItemClick } = props

  const dataOrdened = _.orderBy(_data, 'dtConvocacao', 'asc')

  const data = dataOrdened.map((item) => {
    return {
      ...item,
      dtConvocacao: item?.dtConvocacao ? moment(item.dtConvocacao).format('DD/MM/YYYY') : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtConvocacao',
        label: 'Datas Trabalhadas',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick(value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  const options = {
    tableBodyMaxHeight: '300px',
  }

  return <DataTable title="" data={data} columns={columns} pagination={false} options={options} />
}

export default TableDates
