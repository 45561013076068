export enum IndModeloValeTransporterUsoEnum {
  Modelo_01 = 1,
  Modelo_02 = 2,
}

export const IndModeloValeTransporterUsoValues = [
  {
    value: IndModeloValeTransporterUsoEnum.Modelo_01,
    name: 'Modelo 1',
  },
  {
    value: IndModeloValeTransporterUsoEnum.Modelo_02,
    name: 'Modelo 2',
  },
]
