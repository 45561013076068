import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, AnoMesTextField, CurrencyTextField, Stack } from '~/components'

import ContentDependente from './ContentDependente'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { useCriar } from '~/hooks/queries/PlanoSaudeTitular/useCriar'
import { useAtualizar } from '~/hooks/queries/PlanoSaudeTitular/useAtualizar'

import { PlanoSaudeTitular } from '~/hooks/queries/PlanoSaudeTitular/dtos/PlanoSaudeTitular'
import { AutoCompletePlanoSaude } from '~/components/AutoComplete'

const schema = yup.object({
  planoSaudeId: yup.string().required('Informe o Plano de Saúde').nullable(),
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início').nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  initialData: PlanoSaudeTitular
  afterSubmit: (event: 'create' | 'update', d: PlanoSaudeTitular) => void
}

export default function Form({ isOpen, onClose, initialData, afterSubmit }: FormProps) {
  const [data, setData] = useState(initialData)

  const { anoMes } = useAmbiente()

  const isNovaData = initialData.id ? false : true

  const { mutateAsync: mutateAsyncCreating, isLoading: isCreating } = useCriar()
  const { mutateAsync: mutateAsyncUpdating, isLoading: isUpdating } = useAtualizar()

  const isSubmitting = isCreating || isUpdating

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    if (isNovaData) {
      const dt = await mutateAsyncCreating({ data, params: { anoMes } })
      afterSubmit('create', dt)
    } else {
      const dt = await mutateAsyncUpdating({
        id: data.id,
        data,
        params: { anoMes },
      })
      afterSubmit('update', dt)
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Plano de Saúde Titular"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: isNovaData ? 'sm' : 'md', fullWidth: true }}
    >
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={isNovaData ? 12 : 6}>
            <AutoCompletePlanoSaude
              required
              validationErrors={validationErrors}
              name="planoSaudeId"
              value={data.planoSaudeId}
              onChange={(e, d) => {
                const planoSaudeId = d?.id || ''
                setData((prev) => ({ ...prev, planoSaudeId }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={isNovaData ? 6 : 3}>
            <AnoMesTextField
              required
              validationErrors={validationErrors}
              name="anoMesInicio"
              label="Ano/Mês Início"
              value={data.anoMesInicio}
              onChange={(e) => {
                const anoMesInicio = e.target.value.replace('/', '')
                setData((prev) => ({ ...prev, anoMesInicio }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={isNovaData ? 6 : 3}>
            <AnoMesTextField
              label="Ano/Mês Fim"
              value={data.anoMesFim}
              onChange={(e) => {
                const anoMesFim = e.target.value.replace('/', '')
                setData((prev) => ({ ...prev, anoMesFim }))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CurrencyTextField
              label="Valor da Mensalidade"
              value={data.vrMensalidade}
              onChange={(_, value) => {
                const vrMensalidade = value
                setData((oldState) => ({
                  ...oldState,
                  vrMensalidade,
                }))
              }}
            />
          </Grid>
        </Grid>

        {!isNovaData && <ContentDependente planoSaudeTitularId={data.id} />}
      </Stack>
    </ActionDialog>
  )
}
