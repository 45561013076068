import React, { useEffect, useState } from 'react'

import * as yup from 'yup'
import { isCPFOrCNPJ } from 'brazilian-values'

import { Box, Grid, LinearProgress } from '@material-ui/core'
import { Button, TextField, ButtonBox } from 'mio-library-ui'

import { DatePickerNew, NumeroInscricaoTextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { getEmpregadorById, useEmpregadorMutate } from '~/hooks/queries/useEmpregador'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import { tipoInscricaoConsts, tipoInscricaoValues } from '~/values/tipoInscricaoValues'

const tiposInscricao = tipoInscricaoValues.filter(
  (obj) => obj.value === tipoInscricaoConsts.CNPJ_1 || obj.value === tipoInscricaoConsts.CPF_2,
)

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  nrInscricao: yup
    .string()
    .required('Informe o Número de Inscrição')
    .test('cpf-or-cnpj-test', 'Informe um Número de Inscrição válido', (value) =>
      isCPFOrCNPJ(value),
    ),
  tipoInscricao: yup.string().required('Informe o Tipo de Inscrição'),
})

export default function Form(props) {
  const { empregadorId, onClose } = props
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })
  const { mutateCreateEmpregadorSST, mutateUpdateEmpregador } = useEmpregadorMutate()
  const notification = useNotification()

  useEffect(() => {
    async function getEmpregadorFisica() {
      if (!empregadorId) return
      setLoading(true)
      try {
        const empregador = await getEmpregadorById(empregadorId)
        setData(empregador)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getEmpregadorFisica()
    // eslint-disable-next-line
  }, [empregadorId])

  async function handleSubmit() {
    if (data?.id) {
      await mutateUpdateEmpregador.mutateAsync(data)
      notification.put()
    } else {
      await mutateCreateEmpregadorSST.mutateAsync(data)
      notification.post()
    }
    onClose()
  }

  if (isLoading) return <LinearProgress />

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Nome"
            name="nome"
            validationErrors={validationErrors}
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            value={data?.nome || ''}
            required
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const nome = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                nome,
              }))
            }}
          />
        </Grid>

        <Grid xl={5} lg={5} md={5} sm={5} xs={12} item>
          <MUIAutoComplete
            label="Tipo Inscrição"
            name="tipoInscricao"
            disabled={data?.id ? true : false}
            required
            validationErrors={validationErrors}
            options={tiposInscricao}
            optionId="value"
            renderOption={(option) => option.name}
            value={data?.tipoInscricao || ''}
            onChange={(e, obj) => {
              const tipoInscricao = obj?.value
              setData((oldState) => ({
                ...oldState,
                nrInscricao: '',
                tipoInscricao,
              }))
            }}
          />
        </Grid>

        <Grid xl={7} lg={7} md={7} sm={7} xs={12} item>
          <NumeroInscricaoTextField
            label="Número de Inscrição"
            value={data?.nrInscricao || ''}
            required
            disabled={data?.id ? true : false}
            typeMask={data?.tipoInscricao || tipoInscricaoConsts.CNPJ_1}
            name="nrInscricao"
            validationErrors={validationErrors}
            onChange={(e, value) => {
              const nrInscricao = value
              setData((oldState) => ({
                ...oldState,
                nrInscricao,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Fim Atividade"
            size="small"
            value={data.dtFimAtividade}
            onChange={(date) => {
              const dtFimAtividade = date
              setData((prev) => ({ ...prev, dtFimAtividade }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ButtonBox>
            <Button onClick={onClose} size="small" variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button
              isLoading={mutateCreateEmpregadorSST.isLoading || mutateUpdateEmpregador.isLoading}
              onClick={handleValidate}
              size="small"
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </ButtonBox>
        </Grid>
      </Grid>
    </Box>
  )
}
