import React from 'react'

import { Badge, Box, CircularProgress, Divider, IconButton, Popover } from '@material-ui/core'
import { Notifications, NotificationsNone } from '@material-ui/icons'

import NotificationHeader from './NotificationHeader'
import NotificationContent from './NotificationContent'

import useNotificacaoObterNotificacoesAmbiente from '~/hooks/queries/Notificacao/useNotificacaoObterNotificacoesAmbiente'

export default function UserNotification() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
  } = useNotificacaoObterNotificacoesAmbiente()

  const data = _data || []
  const isLoading = _isLoading || _isFetching

  return (
    <Box>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <Badge
          badgeContent={isLoading ? <CircularProgress size={10} color="inherit" /> : data.length}
          color="error"
        >
          {data.length > 0 ? <Notifications /> : <NotificationsNone />}
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box padding={2}>
          <NotificationHeader />
          <NotificationContent data={data} />
          <Divider />
        </Box>
      </Popover>
    </Box>
  )
}
