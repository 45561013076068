import React, { useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, ButtonBox, Finder } from 'mio-library-ui'

import Table from './components/Table'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'

const HEADER_HEIGHT = '50px'
const TabelaMes = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const { anoMes } = useAmbiente(false, true)

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get('/tabelaMes')

        const newArray = []
        const responseArray = response.data.data

        for (let i = 0; i < responseArray.length; i++) {
          const tabelaMes = responseArray[i]
          const tabelaMesItens = await api.get(`/tabelaMesItem/GetByTabelaMes?id=${tabelaMes.id}`)

          newArray.push({ nome: tabelaMes.nome })

          tabelaMesItens.data.data.forEach((item) => {
            if (item.anoMes === anoMes) {
              newArray[i] = { ...item, nome: tabelaMes.nome }
            }
          })
        }
        setCollection((oldState) => ({
          ...oldState,
          itens: newArray,
          isLoading: false,
        }))
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1} bottom={1}>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={collection.itens} isLoading={collection.isLoading} query={query} />
      </Box>
    </>
  )
}

export default TabelaMes
