import React, { useEffect, useState, useMemo } from 'react'

import { makeStyles } from '@material-ui/core'
import { ContentDivider } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'
import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'
import moment from 'moment'

import { CategoriaValues, CategoriaEnum } from '~/@types/enums/CategoriaEnum'

const categoriaOnlyAutonomos = [
  CategoriaEnum.Contribuinte_Individual_Autonomo_701,
  CategoriaEnum.Contribuinte_Individual_Transportador_Passageiros_711,
  CategoriaEnum.Contribuinte_Individual_Transportador_Carga_712,
  CategoriaEnum.Contribuinte_Individual_Microempreendedor_Individual_741,
]
const categoriasAutonomos = CategoriaValues.filter((obj) =>
  categoriaOnlyAutonomos.includes(obj.value),
)

const useStyles = makeStyles((theme) => ({
  cellCategoria: {
    width: '500px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: theme.spacing(1),
  },
}))

function formatCategoria(categoria) {
  if (!categoria) return ''
  const objectFinded = categoriasAutonomos.find((obj) => obj.value === categoria)
  return objectFinded?.name || ''
}

const Table = (props) => {
  const { vinculoId } = props

  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)

  const classes = useStyles()
  const { anoMes } = useAmbiente()
  const { formatCurrency, formatAnoMes } = useUtils()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    async function getCollection() {
      if (!vinculoId) return
      setLoading(true)
      try {
        const response = await api.get('RPAutonomo/GetByVinculo', {
          params: {
            anoMes,
            vinculoId,
          },
        })
        const responseData = response?.data?.data || []
        const dataFormated = responseData.map((d) => ({
          ...d,
          dtLancamento: d?.dtLancamento ? moment(d.dtLancamento).format('DD/MM/yyyy') : '',
          dtPagamento: d?.dtPagamento ? moment(d.dtPagamento).format('DD/MM/yyyy') : '',
          vrServico: d?.vrServico ? formatCurrency(d.vrServico) : '',
          categoria: formatCategoria(d?.categoria),
        }))
        setData(dataFormated)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [anoMes, vinculoId])

  const columns = useMemo(
    () => [
      {
        name: 'dtLancamento',
        label: 'Data de Lançamento',
      },
      {
        name: 'dtPagamento',
        label: 'Data de Pagamento',
      },
      {
        name: 'vrServico',
        label: 'Valor do Serviço',
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '150px' },
          }),
          setCellProps: () => ({
            style: { textAlign: 'right' },
          }),
        },
      },
      {
        name: 'descricaoServico',
        label: 'Descrição do Serviço',
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '150px' },
          }),
        },
      },
      {
        name: 'categoria',
        label: 'Categoria',
        options: {
          setCellProps: (cellValue) => ({
            className: classes.cellCategoria,
            title: cellValue,
          }),
        },
      },
    ],
    [classes],
  )

  return (
    <>
      <ContentDivider
        title={`Lançamentos da competência: ${formatAnoMes(anoMes)}`}
        top={1}
        bottom={1}
      />
      <HeightDataTable
        title=""
        data={data}
        columns={columns}
        isLoading={isLoading}
        pagination={false}
      />
    </>
  )
}

export default Table
