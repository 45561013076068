import { useEffect, useState, useCallback } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import api from '~/services/api-pessoal'

import { Box, CircularProgress, Grid, Paper, makeStyles, useTheme } from '@material-ui/core'
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons'

import { MenuSelect, PageHeader, SimpleSelect, Button, ActionMenu } from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import FullDialog from './components/FullDialog'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useEnviarGerenciadorDocumentos } from '~/hooks/queries/DCTFWeb/useEnviarGerenciadorDocumentos'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
    margin: 0,
  },
  fullHeight: {
    height: '100%',
  },
  colorIconChecked: {
    color: theme.palette.success.main,
  },
}))

const TITLE_PAGE = 'Documento de Arrecadação'

export default function DocumentoArrecadacao() {
  const [dataMenu, setDataMenu] = useState([])
  const [checked, setChecked] = useState([])
  const [isDecimoTerceiro, setDecimoTerceiro] = useState(false)
  const [onlyHasDocument, setOnlyHasDocument] = useState(false)
  const [grupoId, setGrupoId] = useState(null)

  const [isLoadingMenu, setLoadingMenu] = useState(false)
  const [isLoadingGerarDocumentos, setLoadingGerarDocumentos] = useState(false)
  const [isLoadingGerarSelectItem, setLoadingGerarSelectItem] = useState(false)
  const [isLoadingPrintAll, setLoadingPrintAll] = useState(false)
  const [isLoadingSendEmail, setLoadingSendEmail] = useState(false)

  const [urlPDF, setUrlPDF] = useState(null)
  const [indexMenuSelected, setIndexMenuSelected] = useState(null)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes } = useAmbiente()
  const classes = useStyles()
  const theme = useTheme()
  const {
    close: closePDFPrintAll,
    data: dataPDFPrintAll,
    isOpen: isOpenPDFPrintAll,
    open: openPDFPrintAll,
  } = useDialog()

  const {
    mutateAsync: mutateAsyncEnviarGerenciadorDocumentos,
    isLoading: isLoadingEnviarGerenciadorDocumentos,
  } = useEnviarGerenciadorDocumentos()

  const hasInDezembro = anoMes.substring(4) === '12'
  const isDecimoTerceiroToSend = hasInDezembro ? isDecimoTerceiro : false

  const getEmpregadoresComDocumentoExistentes = useCallback(async () => {
    setLoadingMenu(true)
    try {
      const response = await api.get('/DCTFWeb/ObterEmpregadoresDocumentoArrecadacao', {
        params: {
          competencia: anoMes,
          isDecimoTerceiro: isDecimoTerceiroToSend,
          onlyHasDocument,
          grupoId,
        },
      })
      setDataMenu(response?.data?.data || [])
      setUrlPDF(null)
      setIndexMenuSelected(null)
    } catch (err) {
      console.log(err)
    } finally {
      setLoadingMenu(false)
    }
  }, [anoMes, isDecimoTerceiroToSend, onlyHasDocument, grupoId])

  useEffect(() => {
    getEmpregadoresComDocumentoExistentes()
  }, [getEmpregadoresComDocumentoExistentes])

  useEffect(() => {
    setDecimoTerceiro(false)
  }, [anoMes])

  async function gerarVariosDocumentoArrecadacao(
    empregadoresId,
    indexEmpregadorParaAbrirDocumento = null,
  ) {
    setLoadingGerarDocumentos(true)
    try {
      await api.post('/DCTFWeb/GerarDocumentoArrecadacao', {
        empregadoresId,
        competencia: anoMes,
        isDecimoTerceiro: isDecimoTerceiroToSend,
      })
      notification.success('Os documentos de arrecadação foram gerados com sucesso')
      await getEmpregadoresComDocumentoExistentes()
      if (indexEmpregadorParaAbrirDocumento)
        await handleSelectItem(indexEmpregadorParaAbrirDocumento, true)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingGerarDocumentos(false)
    }
  }

  async function handleSelectItem(index, pularVerificaoDeRegistro = false) {
    const empregadorCurrent = dataMenu[index]
    const hasRegistro = empregadorCurrent?.haveDocumentoArrecadacao || false
    if (!hasRegistro && !pularVerificaoDeRegistro)
      return dialogNotification.info({
        descriptions: [
          `Deseja gerar o Documento de Arrecadação para a competência ${anoMes.substring(
            0,
            4,
          )}/${anoMes.substring(4)} para o empregador ${formatToCPFOrCNPJ(
            empregadorCurrent.nrInscricao,
          )}-${empregadorCurrent.nome}?`,
        ],
        onConfirm: () => gerarVariosDocumentoArrecadacao([empregadorCurrent.id], index),
        title: 'Confirme a solicitação',
        labelOnConfirm: 'Confirmar',
      })

    if (dataMenu[indexMenuSelected]?.id === empregadorCurrent.id) return

    setIndexMenuSelected(index)
    setUrlPDF(null)
    setLoadingGerarSelectItem(true)
    try {
      const response = await api.get('/DCTFWeb/ObterDocumentoArrecadacaoIndividual', {
        params: {
          empregadorId: empregadorCurrent.id,
          competencia: anoMes,
          isDecimoTerceiro: isDecimoTerceiroToSend,
        },
        responseType: 'blob',
      })

      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)

      setUrlPDF(fileURL)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingGerarSelectItem(false)
    }
  }

  function handleClickGerarDocumento() {
    const empregadores = checked.map((indexChecked) => dataMenu[indexChecked])
    const someHaveDocumentoArrecadacao = empregadores.some((d) => d.haveDocumentoArrecadacao)
    const empregadoresId = empregadores.map((empregador) => empregador.id)

    if (someHaveDocumentoArrecadacao) {
      return dialogNotification.warning({
        descriptions: [
          'Alguns dos Empregadores selecionados já possuem um Documento de Arrecadação, deseja prosseguir com a requisição?',
        ],
        onConfirm: () => gerarVariosDocumentoArrecadacao(empregadoresId),
      })
    }
    gerarVariosDocumentoArrecadacao(empregadoresId)
  }

  async function handlePrintAll() {
    setLoadingPrintAll(true)
    try {
      const empregadoresId = checked.map((indexChecked) => dataMenu[indexChecked].id)
      const response = await api.post(
        '/DCTFWeb/ImprimirVariosDocumentosArrecadacao',
        {
          empregadoresId,
          competencia: anoMes,
          isDecimoTerceiro: isDecimoTerceiroToSend,
        },
        {
          responseType: 'blob',
        },
      )
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)

      openPDFPrintAll(fileURL)
    } catch (err) {
      if (err?.response?.data) err.response.data = JSON.parse(await err?.response?.data.text())
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingPrintAll(false)
    }
  }

  async function handleEnviarGerenciador() {
    const empregadoresId = checked.map((indexChecked) => dataMenu[indexChecked].id)
    await mutateAsyncEnviarGerenciadorDocumentos({
      competencia: anoMes,
      empregadoresId,
      isDecimoTerceiro,
    })
  }

  async function handleSendAllEmail() {
    setLoadingSendEmail(true)
    try {
      const empregadoresId = checked.map((indexChecked) => dataMenu[indexChecked].id)
      await api.post('/DCTFWeb/EnviarVariosDocumentosPorEmailEmpregadores', {
        empregadoresId,
        competencia: anoMes,
        isDecimoTerceiro: isDecimoTerceiroToSend,
      })
      notification.success('Os documentos de arrecadação foram enviados por email com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingSendEmail(false)
    }
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item className={classes.fullHeight} xl={3} lg={4} md={5} sm={6} xs={12}>
        <Box
          component={Paper}
          padding={1}
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          gridGap={8}
        >
          <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
            <SimpleSelect
              label="Filtro Empregadores"
              options={[
                {
                  name: 'Listar todos',
                  value: false,
                },
                {
                  name: 'Somente com documento já gerado',
                  value: true,
                },
              ]}
              renderOption={(opt) => opt.name}
              optionId="value"
              value={onlyHasDocument}
              onChange={(_, _onlyHasDocument) => {
                setOnlyHasDocument(_onlyHasDocument)
              }}
            />

            <AutoCompleteGrupo
              value={grupoId}
              onChange={(e, grupo) => {
                const grupoId = grupo?.id || null
                setGrupoId(grupoId)
              }}
            />
          </Box>

          {hasInDezembro && (
            <Box>
              <SimpleSelect
                label="Indicador de Apuração"
                options={[
                  {
                    name: 'Mensal',
                    value: false,
                  },
                  {
                    name: 'Décimo Terceiro',
                    value: true,
                  },
                ]}
                renderOption={(opt) => opt.name}
                optionId="value"
                value={isDecimoTerceiro}
                onChange={(_, _isDecimoTerceiro) => {
                  setDecimoTerceiro(_isDecimoTerceiro)
                }}
              />
            </Box>
          )}
          <Box flex={1} overflow="auto" minHeight={300}>
            <MenuSelect
              options={dataMenu}
              onCheckOption={setChecked}
              optionsCheckeds={checked}
              onSelect={(index) => handleSelectItem(index)}
              selectedOption={indexMenuSelected}
              renderOption={(d) => d.nome}
              renderRight={(d) => {
                const hasRegistro = d?.haveDocumentoArrecadacao || false
                if (!hasRegistro) return <></>
                return <CheckCircleIcon fontSize="small" className={classes.colorIconChecked} />
              }}
              isLoading={isLoadingMenu}
              searchField="nome"
              idField="id"
            />
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                <Button
                  onClick={handleClickGerarDocumento}
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!(checked.length > 0)}
                  isLoading={isLoadingGerarDocumentos}
                  fullWidth
                >
                  Gerar Documento
                </Button>
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ActionMenu
                  disabled={checked.length > 0 ? false : true}
                  isLoading={
                    isLoadingSendEmail || isLoadingPrintAll || isLoadingEnviarGerenciadorDocumentos
                  }
                  options={[
                    {
                      onClick: handleSendAllEmail,
                      label: 'Enviar por Email',
                      title: 'Enviar por Email todos Documentos de Arrecadações selecionados',
                      icon: 'email',
                    },
                    {
                      onClick: handlePrintAll,
                      label: 'Imprimir Todos',
                      title: 'Imprimir todos Documentos de Arrecadações selecionados',
                      icon: 'print',
                    },
                    {
                      onClick: handleEnviarGerenciador,
                      label: 'Enviar Gerenciador',
                      title:
                        'Enviar todos Documentos de Arrecadações selecionados para o Gerenciador de Documentos',
                      icon: 'settings',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Grid item className={classes.fullHeight} xl={9} lg={8} md={7} sm={6} xs={12}>
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(0.5)}
        >
          <Box>
            <PageHeader title={TITLE_PAGE} subtitle={dataMenu[indexMenuSelected]?.nome || '-'} />
          </Box>
          <Box
            flex={1}
            minHeight={300}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {isLoadingGerarSelectItem ? (
              <CircularProgress size={70} />
            ) : (
              urlPDF && <iframe title={TITLE_PAGE} src={urlPDF} height="100%" width="100%" />
            )}
          </Box>
        </Box>
      </Grid>
      <FullDialog
        title="Imprimir Todos Documentos de Arrecadação"
        isOpen={isOpenPDFPrintAll}
        onClose={closePDFPrintAll}
        pdfUrl={dataPDFPrintAll}
      />
    </Grid>
  )
}
