import React, { useState, useEffect } from 'react'

import { TextField, ContentDivider } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { Box, Grid, makeStyles } from '@material-ui/core'

import Table from './components/Table'

import { DatePicker } from '~/components'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '50%',
  },
}))

const FormHorariosVinculo = (props) => {
  const { isOpen, onClose, vinculo } = props
  const notification = useNotification()
  const [data, setData] = useState({})
  const [collectionItem, setCollectionItem] = useState({
    isLoading: false,
    itens: [],
  })

  const classes = useStyles()

  useEffect(() => {
    const getCollection = async () => {
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const responseVinculoHorario = await api.get(
          `/VinculoHorario/GetByVinculo?vinculoId=${vinculo.id}`,
        )
        setData(responseVinculoHorario.data.data[0])

        const responseVinculoHorarioItem = await api.get(
          `/VinculoHorarioItem/GetByVinculoHorario?vinculoHorarioId=${responseVinculoHorario.data.data[0].id}`,
        )

        setCollectionItem({
          itens: responseVinculoHorarioItem.data.data,
          isLoading: false,
        })
      } catch (err) {
        notification.failed()
      }
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    if (!vinculo.id) return
    getCollection()
    //eslint-disable-next-line
  }, [vinculo.id])

  return (
    <ActionDialog
      title="Horário de Trabalho"
      subtitle={vinculo?.pessoa ? `${vinculo?.pessoa?.codigo} - ${vinculo?.pessoa?.nome}` : ''}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
      customActions={<></>}
    >
      <Box height="100%" width="100%">
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <DatePicker
              label="Data de início"
              size="small"
              value={data?.dtIncio || null}
              disabled
            />
          </Grid>

          <Grid item xl={9} lg={9} md={9} sm={6} xs={12}>
            <TextField
              label="Observação"
              fullWidth
              value={data?.observacao || ''}
              variant="outlined"
              size="small"
              multiline
              disabled
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ContentDivider />
          </Grid>
        </Grid>

        <Table data={collectionItem.itens} isLoading={collectionItem.isLoading} />
      </Box>
    </ActionDialog>
  )
}

export default FormHorariosVinculo
