import api from '~/services/api-pessoal'

import { useQueryClient, useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { NotificacaoAmbienteDTO } from './dtos/NotificacaoAmbienteDTO'

const query = '/Notificacao/ObterNotificacoesAmbiente'

export default function useNotificacaoObterNotificacoesAmbiente() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: NotificacaoAmbienteDTO[] }>(query)
    return response.data.data
  }

  return useQuery([query], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useNotificacaoObterNotificacoesAmbienteClient() {
  const queryClient = useQueryClient()

  function invalidateQuery() {
    queryClient.invalidateQueries(query)
  }

  function removeItem(id: string) {
    queryClient.cancelQueries(query)
    queryClient.setQueriesData<NotificacaoAmbienteDTO[]>(query, (old) => {
      if (!old) return []
      const newData = old.filter((d) => d.id !== id)
      return newData
    })
  }

  return {
    invalidateQuery,
    removeItem,
  }
}
