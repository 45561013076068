import React from 'react'

import { ContentDivider } from 'mio-library-ui'
import { makeStyles, Box, Grid, Typography } from '@material-ui/core'

import { ContentFrame } from '~/components'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '105px',
    overflow: 'auto',
    scrollSnapType: 'y mandatory',
  },
  content: {
    scrollSnapAlign: 'start',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '99%',
    height: '105px',
    padding: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.secondary.lighter,
    },
  },
  valueItem: {
    color: '#2E8BC0',
    fontWeight: 'bold',
    fontSize: '10pt',
  },
  labelItem: {
    fontWeight: 'bold',
    fontSize: '9pt',
  },
}))

const ItemList = (props) => {
  const { label, value } = props

  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.labelItem}>{label}</Box>{' '}
      <Box className={classes.valueItem}>{value}</Box>
    </Box>
  )
}

const PeriodoConcedido = (props) => {
  const { periodoConcedido } = props

  const classes = useStyles()

  return (
    <ContentFrame
      padding={1}
      headerRenderer={<ContentDivider title="Período Concedido" showDivider={false} />}
    >
      <Box className={classes.root}>
        {periodoConcedido?.length > 0 ? (
          periodoConcedido.map((periodo, index) => (
            <Box className={classes.content} key={index}>
              <Grid container title={`${index + 1}° Período concedido`}>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <ItemList
                    label="Inicio das Férias"
                    value={
                      periodo?.dtInicioConcessao
                        ? moment(periodo.dtInicioConcessao).format('DD/MM/YYYY')
                        : null
                    }
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <ItemList
                    label="Fim das Férias"
                    value={
                      periodo?.dtFimConcessao
                        ? moment(periodo.dtFimConcessao).format('DD/MM/YYYY')
                        : null
                    }
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <ItemList label="Dias Concedidos" value={periodo?.nrDiasFerias} />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <ItemList
                    label="Inicio Abono"
                    value={
                      periodo?.dtInicioAbono
                        ? moment(periodo.dtInicioAbono).format('DD/MM/YYYY')
                        : null
                    }
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <ItemList
                    label="Fim Abono"
                    value={
                      periodo?.dtFimAbono ? moment(periodo.dtFimAbono).format('DD/MM/YYYY') : null
                    }
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <ItemList label="Dias de Abono" value={periodo?.nrDiasAbono || 0} />
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <Typography align="center">Nenhum período concedido encontrado.</Typography>
        )}
      </Box>
    </ContentFrame>
  )
}

export default PeriodoConcedido
