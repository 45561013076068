import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Visibility as ViewIcon } from '@material-ui/icons'

import moment from 'moment'

import { HeightDataTable } from '~/components'

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const dataFormated = data.map((item) => {
    return {
      ...item,
      dtPeriodoInicio: item?.dtPeriodoInicio
        ? moment(item.dtPeriodoInicio).format('DD/MM/YYYY')
        : null,
      dtPeriodoFim: item?.dtPeriodoFim ? moment(item.dtPeriodoFim).format('DD/MM/YYYY') : null,
      dtLimiteConcessao: item?.dtLimiteConcessao
        ? moment(item.dtLimiteConcessao).format('DD/MM/YYYY')
        : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtPeriodoInicio',
        label: 'Início Período',
      },
      {
        name: 'dtPeriodoFim',
        label: 'Fim Período',
      },
      {
        name: 'dtLimiteConcessao',
        label: 'Limite Concessão',
      },
      {
        name: 'nrDiasDireito',
        label: 'Dias Direito',
      },
      {
        name: 'observacao',
        label: 'Observação',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Visualizar este registro"
                  color="primary"
                  aria-label="Visualizar"
                  onClick={() => {
                    onItemClick(value)
                  }}
                >
                  <ViewIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable data={dataFormated} columns={columns} isLoading={isLoading} />
}

Table.defaultProps = {
  query: '',
}

export default Table
