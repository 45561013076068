import React, { useEffect, useState, useCallback } from 'react'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'

export default function AutoCompleteTerceiros(props) {
  const { label, filterByFPAS, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = useCallback(async () => {
    if (!filterByFPAS) return
    setLoading(true)
    try {
      const response = await api.get(`/Terceiros/GetByFPAS?fpasId=${filterByFPAS}`)
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [filterByFPAS])

  useEffect(() => {
    getOptions()
  }, [filterByFPAS, getOptions])

  const renderOption = (option) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label={label}
      {...rest}
    />
  )
}

AutoCompleteTerceiros.defaultProps = {
  label: 'Terceiros',
  filterByFPAS: '',
}
