export enum JornadaTipoEnum {
  Jornada_12x36_2 = 2,
  HorarioFixoFolgaVariável_3 = 3,
  HorarioFixoFolgaFixaDomingo_4 = 4,
  HorarioFixoFolgaFixaExcetoDomingo_5 = 5,
  HorarioFixoFolgaFixaOutroDiaSemana_6 = 6,
  TurnoIninterruptoRevezamento_7 = 7,
  DemaisTipos_9 = 9,
}

export const JornadaTipoValues = [
  {
    value: JornadaTipoEnum.Jornada_12x36_2,
    name: '2 - Jornada 12 x 36 (12 horas de trabalho seguidas de 36 horas ininterruptas de descanso)',
  },
  {
    value: JornadaTipoEnum.HorarioFixoFolgaVariável_3,
    name: '3 - Jornada com horário diário fixo e folga variável',
  },
  {
    value: JornadaTipoEnum.HorarioFixoFolgaFixaDomingo_4,
    name: '4 - Jornada com horário diário fixo e folga fixa (no domingo)',
  },
  {
    value: JornadaTipoEnum.HorarioFixoFolgaFixaExcetoDomingo_5,
    name: '5 - Jornada com horário diário fixo e folga fixa (exceto no domingo)',
  },
  {
    value: JornadaTipoEnum.HorarioFixoFolgaFixaOutroDiaSemana_6,
    name: '6 - Jornada com horário diário fixo e folga fixa (em outro dia da semana), com folga adicional periódica no domingo',
  },
  {
    value: JornadaTipoEnum.TurnoIninterruptoRevezamento_7,
    name: '7 - Turno ininterrupto de revezamento',
  },
  { value: JornadaTipoEnum.DemaisTipos_9, name: '9 - Demais tipo de jornada' },
]
