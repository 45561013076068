import { useEffect } from 'react'

import { formatToCPF } from 'brazilian-values'

import MUIAutoComplete from '../MUIAutoComplete'
import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'

import moment from 'moment'

import { useObterVinculosParaRetificar } from '~/hooks/queries/VinculoConsulta/useObterVinculosParaRetificar'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

interface AutoCompleteRetificarFuncionarioProps {
  value: string | null
  onChange: (value: string | null) => void
}

export default function AutoCompleteRetificarFuncionario({
  value,
  onChange,
}: AutoCompleteRetificarFuncionarioProps) {
  const { anoMes, estabelecimento } = useAmbiente()

  const { data: _d, isLoading } = useObterVinculosParaRetificar(estabelecimento.id, anoMes)
  const options = _d || []

  useEffect(() => {
    if (value) {
      onChange(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_d])

  const renderOption = (opt: VinculoConsulta) => `${formatToCPF(opt.pessoaCPF)} - ${opt.pessoaNome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      renderOption={renderOption}
      renderOptionComponent={(opt) => (
        <AutoCompleteOptionDescription
          title={`${formatToCPF(opt.pessoaCPF)} - ${opt.pessoaNome}`}
          descriptions={[
            {
              description: `Admissão: ${moment(opt.dtAdmissao).format('DD/MM/yyyy')} - Rescisão: ${
                opt.dtRescisao ? moment(opt.dtRescisao).format('DD/MM/yyyy') : '-'
              }`,
            },
          ]}
        />
      )}
      label="Funcionários"
      value={value}
      onChange={(e, obj: VinculoConsulta | null) => onChange(obj?.id || null)}
    />
  )
}
