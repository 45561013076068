import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

import { ProcessoTrabalhistaManutencaoVinculoInformacoesDTO } from './dtos/ProcessoTrabalhistaManutencaoVinculoInformacoesDTO'

interface RequestProps {
  params: {
    anoMes: string
  }
}

export function useProcessarManutencaoAdmissao({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest(data: ProcessoTrabalhistaManutencaoVinculoInformacoesDTO) {
    await api.patch('/ProcessoTrabalhistaManutencaoVinculo/ProcessarManutencaoAdmissao', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Manutenção no Cadastro realizada com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
