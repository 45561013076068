import { useState } from 'react'

import moment from 'moment'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { ToolsDataTable } from '~/components'

import TableItens from '../Form/components/Table'

import { VinculoHorarioPartial } from '~/hooks/queries/VinculoHorario/VinculoHorario'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  data: VinculoHorarioPartial[]
  onItemClick: (event: 'edit' | 'delete', index: number) => void
}

export default function Table(props: TableProps) {
  const { data, onItemClick } = props

  const [rowsExpanded, setRowsExpanded] = useState<number[]>([data.length - 1])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtIncio',
      label: 'Data de Início',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', rowIndex)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', rowIndex)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      disableAutoHeight
      optionsExpandable={{
        type: 'single',
        onRowExpanded: setRowsExpanded,
        expandedChildren: (index) => <TableItens data={data[index].vinculoHorarioItens || []} />,
        rowsExpanded: rowsExpanded,
      }}
    />
  )
}
