export enum IndSimplesEnum {
  ContribuicaoSubstituida_1 = 1,
  ContribuicaoNaoSubstituida_2 = 2,
  ContribuicaoConcomitante_3 = 3,
  NaoAplicavel_9 = 9,
}

export const IndSimplesValues = [
  { value: IndSimplesEnum.NaoAplicavel_9, name: '9 - Não informar' },
  {
    value: IndSimplesEnum.ContribuicaoSubstituida_1,
    name: '1 - Contribuição Substituída Integralmente',
  },
  {
    value: IndSimplesEnum.ContribuicaoNaoSubstituida_2,
    name: '2 - Contribuição Não Substituída',
  },
  {
    value: IndSimplesEnum.ContribuicaoConcomitante_3,
    name: '3 - Contribuição não substituída concomitante com contribuição substituída',
  },
]
