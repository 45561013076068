import { useEffect, useState } from 'react'

import { Box, Divider, IconButton, Paper } from '@material-ui/core'
import { Button, Checkbox, ContainerTable, Finder, Stack, ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import { useObterConfiguracoes } from '~/hooks/queries/RelatorioAdmissional/useObterConfiguracoes'
import { useSalvarConfiguracoes } from '~/hooks/queries/RelatorioAdmissional/useSalvarConfiguracoes'
import { useSalvarMultiplasConfiguracoes } from '~/hooks/queries/RelatorioAdmissional/useSalvarMultiplasConfiguracoes'

import { RelatorioAdmissionalConfiguracao } from '~/hooks/queries/RelatorioAdmissional/dtos/RelatorioAdmissionalConfiguracao'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface ContentProps {
  estabelecimentoId?: string
  estabelecimentosIds?: string[]
  handleAfterSubmit?: () => void
}

export default function Content({
  estabelecimentoId,
  estabelecimentosIds,
  handleAfterSubmit,
}: ContentProps) {
  const [query, setQuery] = useState('')
  const [data, setData] = useState<RelatorioAdmissionalConfiguracao[]>([])

  const { mutateAsync: mutateAsyncSingle, isLoading: isLoadingSingle } = useSalvarConfiguracoes()
  const { mutateAsync: mutateAsyncMultiple, isLoading: isLoadingMultiple } =
    useSalvarMultiplasConfiguracoes()

  const isSubmitting = isLoadingSingle || isLoadingMultiple

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterConfiguracoes({
    params: {
      estabelecimentoId,
    },
  })

  useEffect(() => {
    setData(_d || [])
  }, [_d])

  function handleCancel() {
    if (handleAfterSubmit) {
      handleAfterSubmit()
    }
    setData(_d || [])
  }

  async function handleSubmit() {
    if (estabelecimentoId) {
      await mutateAsyncSingle(data)
    }
    if (estabelecimentosIds) {
      await mutateAsyncMultiple({
        estabelecimentos: estabelecimentosIds,
        data: data,
      })
    }
    if (handleAfterSubmit) {
      handleAfterSubmit()
    }
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'indRelatorioAdmissionalDescricao',
      label: 'Relatório',
    },
    {
      name: 'id',
      label: 'Imprimir',
      options: {
        sort: false,
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return
          return (
            <Box width="100%" display="flex" justifyContent="center">
              <IconButton
                size="small"
                onClick={() => {
                  const newData = {
                    ...dtCurrent,
                    isAtivo: !dtCurrent.isAtivo,
                  }
                  setData((prev) => prev.map((d) => (d.id === value ? newData : d)))
                }}
              >
                <Ativo ativo={dtCurrent.isAtivo} />
              </IconButton>
            </Box>
          )
        },
      },
    },
  ]

  return (
    <Stack height="100%">
      <Stack orientation="horizontal" justifyContent="flex-end" alignItems="center">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        <Divider orientation="vertical" />
        <Box>
          <Checkbox
            label="Selecionar todos"
            value={data.every((d) => d.isAtivo)}
            indeterminate={data.some((d) => d.isAtivo) && !data.every((d) => d.isAtivo)}
            onChange={() => {
              const isSomeActive = data.some((d) => d.isAtivo)
              setData((prev) => prev.map((d) => ({ ...d, isAtivo: !isSomeActive })))
            }}
          />
        </Box>
      </Stack>

      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          disableAutoHeight
          pagination={false}
          sherlock={{
            columns: ['indRelatorioAdmissionalDescricao'],
            query,
          }}
        />
      </ContainerTable>

      <Stack orientation="horizontal" component={Paper} justifyContent="flex-end" p={1}>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button variant="contained" onClick={handleSubmit} isLoading={isSubmitting}>
          Salvar
        </Button>
      </Stack>
    </Stack>
  )
}
