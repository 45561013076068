import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { useObterTodosSalarioItemInvalidate } from './useObterTodosSalarioItem'
import { useObterTodosSalarioInvalidate } from '../Salario/useObterTodosSalario'

import { notifyPut } from '~/utils/notification'

import { SalarioItemDTO } from './dtos/SalarioItemDTO'

interface RequestParams {
  data: SalarioItemDTO
  id: string
}

export function useUpdateSalarioItem() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate: handleInvalidateItem } = useObterTodosSalarioItemInvalidate()
  const { handleInvalidate: handleInvalidateSalario } = useObterTodosSalarioInvalidate()

  async function handleRequest({ data, id }: RequestParams) {
    await api.put('/SalarioItem/' + id, data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidateItem()
      handleInvalidateSalario()
    },
    onError: dialogNotification.extractErrors,
  })
}
