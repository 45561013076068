import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ValeTransporteApuracaoApuracaoDTO } from './ValeTransporteApuracaoDTO'

export const queryObterApuracaoEstabelecimento =
  '/ValeTransporteApuracao/ObterApuracaoEstabelecimento'

interface RequestProps {
  estabelecimentoId: string
  competencia: string
}

export default function useObterApuracaoEstabelecimento(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ValeTransporteApuracaoApuracaoDTO[] }>(
      queryObterApuracaoEstabelecimento,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterApuracaoEstabelecimento, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
