import api from '~/services/api-pessoal'

export async function pessoaFisicaCheckUso(pessoaFisicaId: string) {
  const response = await api.get<{ data: boolean }>('/PessoaFisica/CheckUso', {
    params: {
      pessoaFisicaId,
    },
  })
  return response.data.data
}
