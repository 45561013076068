import { Box, IconButton } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { ButtonBox, ContainerTable, ToolsDataTable } from '~/components'

import Form from './Form'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import useGetByVinculo from '~/hooks/queries/VinculoFerias/useGetByVinculo'

import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { formatSimpleDate } from '~/utils/utils'

interface TableProps {
  funcionario: VinculoConsulta
}

export default function Content({ funcionario }: TableProps) {
  const { anoMes } = useAmbiente()

  const vinculoId = funcionario.id

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useGetByVinculo({
    vinculoId,
    anoMes,
    isApenasAbertas: false,
  })
  const data = _data || []

  const { close: closeForm, data: dataForm, isOpen: isOpenForm, open: openForm } = useDialog('')

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtPeriodoInicio',
      label: 'Início',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : null),
      },
    },
    {
      name: 'dtPeriodoFim',
      label: 'Fim',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : null),
      },
    },
    {
      name: 'dtLimiteConcessao',
      label: 'Limite Concessão',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : null),
      },
    },
    {
      name: 'dtConcedida',
      label: 'Data Período Concedido',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : null),
      },
    },
    {
      name: 'dtPeriodoPerdido',
      label: 'Data Período Perdido',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDate(value) : null),
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Cadastrar Saldo Inicial"
                color="primary"
                aria-label="Saldo Inicial"
                onClick={() => openForm(value)}
                size="small"
              >
                <AddCircleIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Box display="flex" height="100%">
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          disableAutoHeight
        />
      </ContainerTable>
      {isOpenForm && <Form onClose={closeForm} vinculoId={vinculoId} vinculoFeriasId={dataForm} />}
    </Box>
  )
}
