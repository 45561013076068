import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { ActionDialog, CurrencyTextField, TextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { TerceirosItem } from '~/hooks/queries/TerceirosItem/TerceirosItem'
import { Grid } from '@material-ui/core'

const schema = yup.object().shape({
  codigo: yup.string().required('Informe o Código'),
  nome: yup.string().required('Informe o Nome'),
})

interface FormItemProps {
  isOpen: boolean
  onClose: () => void
  data: TerceirosItem | null
  onChange: (data: TerceirosItem) => void
}

export default function FormItem(props: FormItemProps) {
  const { data: _data, isOpen, onChange, onClose } = props
  const [data, setData] = useState<Partial<TerceirosItem>>({})

  useEffect(() => {
    if (_data) {
      setData(_data)
    }
  }, [_data])

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  function handleSubmit() {
    onChange(data as TerceirosItem)
  }

  return (
    <ActionDialog
      title="Cadastro de Terceiros Item"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            validationErrors={validationErrors}
            name="codigo"
            label="Código"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.codigo}
            onChange={(e) => {
              const codigo = e.target.value
              setData((prev) => ({ ...prev, codigo }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            validationErrors={validationErrors}
            name="nome"
            label="Nome"
            InputLabelProps={{
              shrink: true,
            }}
            value={data.nome}
            onChange={(e) => {
              const nome = e.target.value
              setData((prev) => ({ ...prev, nome }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Alíquota Terceiros"
            value={data.aliqTerceiros}
            onChange={(e, value) => {
              const aliqTerceiros = value
              setData((oldState) => ({
                ...oldState,
                aliqTerceiros,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
