const admissao_1 = 1
const transferenciaEmpresaMesmoGrupo_2 = 2
const transferenciaEmpresaConsorcio_3 = 3
const transferenciaSucessao_4 = 4
const transferenciaDomestico_5 = 5
const mudancaDeCPF_6 = 6

export const tipoAdmissaoValues = [
  { value: admissao_1, name: '1 - Admissão Normal' },
  {
    value: transferenciaEmpresaMesmoGrupo_2,
    name: '2 - Transferência de empresa do mesmo grupo econômico',
  },
  {
    value: transferenciaEmpresaConsorcio_3,
    name: '3 - Transferência de empresa consorciada ou de consórcio',
  },
  {
    value: transferenciaSucessao_4,
    name: '4 - Transferência por motivo de sucessão, incorporação, cisão ou fusão',
  },
  {
    value: transferenciaDomestico_5,
    name: '5 - Transferência do empregado doméstico para outro representante da mesma unidade familiar',
  },
  { value: mudancaDeCPF_6, name: '6 - Mudança de CPF' },
]

export const tipoAdmissaoConsts = {
  admissao_1,
  transferenciaEmpresaMesmoGrupo_2,
  transferenciaEmpresaConsorcio_3,
  transferenciaSucessao_4,
  transferenciaDomestico_5,
  mudancaDeCPF_6,
}
