import React from 'react'

import { Grid, makeStyles, Divider } from '@material-ui/core'

import { LabelValueColor } from '~/components'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    border: '1px solid #D0D0D0',
    padding: theme.spacing(1),
    borderRadius: '4px',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
}))

const Footer = (props) => {
  const { totais } = props

  const {
    totalVrProvisao,
    totalVrContribuicaoPrevidenciaria,
    totalVrContribuicaoTerceiros,
    totalVrFGTS,
    totalVrPIS,
  } = totais

  const classes = useStyles()

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid xl={2} lg={2} md={4} sm={4} xs={6} item>
        <LabelValueColor label="Provisão" value={totalVrProvisao || '0,00'} color="#2E8BC0" />
      </Grid>

      <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
        <LabelValueColor
          label="Contribuição Previdenciária"
          value={totalVrContribuicaoPrevidenciaria || '0,00'}
          color="#2E8BC0"
          showDivider={window.innerWidth < 600 ? false : true}
        />
      </Grid>

      {window.innerWidth < 600 && (
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <Divider />
        </Grid>
      )}

      <Grid xl={2} lg={2} md={4} sm={4} xs={6} item>
        <LabelValueColor
          label="Contribuição Terceiros"
          value={totalVrContribuicaoTerceiros || '0,00'}
          color="#2E8BC0"
          showDivider={window.innerWidth < 1280 && window.innerWidth > 600 ? false : true}
        />
      </Grid>

      {window.innerWidth < 1280 && window.innerWidth > 600 && (
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <Divider />
        </Grid>
      )}

      <Grid xl={3} lg={3} md={6} sm={6} xs={6} item>
        <LabelValueColor
          label="FGTS"
          value={totalVrFGTS || '0,00'}
          color="#2E8BC0"
          showDivider={window.innerWidth < 600 ? false : true}
        />
      </Grid>

      {window.innerWidth < 600 && (
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <Divider />
        </Grid>
      )}

      <Grid xl={2} lg={2} md={6} sm={6} xs={12} item>
        <LabelValueColor
          label="PIS"
          value={totalVrPIS || '0,00'}
          color="#2E8BC0"
          showDivider={false}
        />
      </Grid>
    </Grid>
  )
}

export default Footer
