import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { TabelaSF } from './TabelaSF'

export const queryUseObterTodosTabelaSF = '/TabelaSF'

export function useObterTodosTabelaSF() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: TabelaSF[] }>(queryUseObterTodosTabelaSF)
    return response?.data?.data || []
  }

  return useQuery([queryUseObterTodosTabelaSF], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
