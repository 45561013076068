import { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import * as yup from 'yup'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { ActionDialog, DatePickerNew } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import { TextField } from 'mio-library-ui'
import { TipoAssinaturaEnum, TipoAssinaturaValues } from '~/@types/enums/TipoAssinaturaEnum'
import { notifyWarning } from '~/utils/notification'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  tipoAssinatura: yup.number().required('Informe a Data da Emissão do Documento'),
})

const initialState = {
  dtAssinatura: getDateCurrent(),
  tipoAssinatura: TipoAssinaturaEnum.ProprioPunho,
  motivoDeclaracaoCustomizado: '',
}

export default function DialogConfirm(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState(initialState)

  function handleSubmit() {
    if (data.tipoAssinatura == TipoAssinaturaEnum.InformarData && !data.dtAssinatura) {
      return notifyWarning('Informe a Data de Assinatura')
    }
    onAfterSubmit(data)
    onClose()
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  useEffect(() => {
    if (isOpen) return
    setData(initialState)
  }, [isOpen])

  return (
    <ActionDialog
      title="Informe dados para o Vale Transporte Declaração de Uso"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIAutoComplete
            label="Data da Emissão do Documento"
            options={TipoAssinaturaValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.tipoAssinatura}
            required
            validationErrors={validationErrors}
            name="tipoAssinatura"
            onChange={(e, obj) => {
              const tipoAssinatura = obj?.value || TipoAssinaturaEnum.ProprioPunho
              setData((prev) => ({
                ...prev,
                tipoAssinatura,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Assinatura"
            value={data.dtAssinatura}
            disabled={data.tipoAssinatura !== TipoAssinaturaEnum.InformarData}
            onChange={(date) => {
              const dtAssinatura = date
              setData((prev) => ({
                ...prev,
                dtAssinatura,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Descrição para Não Beneficiário"
            fullWidth
            value={data.motivoDeclaracaoCustomizado}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 35,
            }}
            onChange={(e) => {
              const motivoDeclaracaoCustomizado = e?.target?.value || ''
              setData((prev) => ({ ...prev, motivoDeclaracaoCustomizado }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
