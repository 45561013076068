import React, { useState } from 'react'

import * as yup from 'yup'
import { isCPF } from 'brazilian-values'

import { ButtonBox } from 'mio-library-ui'
import { Grid } from '@material-ui/core'

import { Button, NumeroInscricaoTextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import obterPessoaPorNrInscricao from '~/hooks/queries/PessoaFisicaConsulta/obterPessoaPorNrInscricao'

import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'
import { PessoaFisicaConsulta } from '~/hooks/queries/PessoaFisicaConsulta/PessoaFisicaConsulta'

const schema = yup.object().shape({
  nrInscricao: yup
    .string()
    .required('Informe o CPF')
    .test('cpf-test', 'Informe um CPF válido', (value) => (value ? isCPF(value) : false)),
})

interface InitialFormProps {
  onAfterSubmit: (
    event: 'registered' | 'unregistered',
    result: string | PessoaFisicaConsulta,
  ) => void
  onClose: () => void
}

export default function InitialForm(props: InitialFormProps) {
  const { onAfterSubmit, onClose } = props

  const [data, setData] = useState({ nrInscricao: '' })
  const [isLoadingSearch, setLoadingSearch] = useState(false)

  const dialogNotification = useDialogNotification()
  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })

  async function handleSubmit() {
    setLoadingSearch(true)
    try {
      const response = await obterPessoaPorNrInscricao(data.nrInscricao)
      if (response) {
        onAfterSubmit('registered', response)
      } else {
        onAfterSubmit('unregistered', data.nrInscricao)
      }
      onClose()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingSearch(false)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <NumeroInscricaoTextField
          label="CPF"
          value={data.nrInscricao}
          required
          typeMask={TipoInscricaoEnum.CPF_2}
          name="nrInscricao"
          validationErrors={validationErrors}
          onChange={(e, value) => {
            const nrInscricao = value
            setData((oldState) => ({
              ...oldState,
              nrInscricao,
            }))
          }}
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ButtonBox>
          <Button onClick={onClose} variant="outlined">
            Cancelar
          </Button>
          <Button isLoading={isLoadingSearch} onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
