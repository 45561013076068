import React, { useState, useEffect, useCallback } from 'react'

import moment from 'moment'

import { ContentContainer, PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import Table from './components/Table'
import Form from './components/Form'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import api from '~/services/api-pessoal'
import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import { tipoContratoTrabalhoConsts } from '~/values/tipoContratoTrabalhoValues'
import { indUnidadeSalarioConsts } from '~/values/indUnidadeSalarioValues'
import { naturezaAtividadeConsts } from '~/values/naturezaAtividadeValues'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'
import { Checkbox } from '~/components'

const { prazoDeterminado_2 } = tipoContratoTrabalhoConsts
const { mes_5 } = indUnidadeSalarioConsts
const { trabalhoUrbano_1 } = naturezaAtividadeConsts

const AdmissaoPreliminar = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const [onlyCompetencia, setOnlyCompetencia] = useState(true)

  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { empregador, anoMes } = useAmbiente()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      let dtInicio = onlyCompetencia ? moment(anoMes).format('yyyy-MM-DD') : null
      let dtFim = onlyCompetencia ? moment(anoMes).endOf('month').format('yyyy-MM-DD') : null

      try {
        const response = await api.get('/VinculoAdmissaoPreliminar/GetByEmpregador', {
          params: {
            empregadorId: empregador?.id,
            dtInicio,
            dtFim,
          },
        })
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    //eslint-disable-next-line
  }, [empregador?.id, anoMes, onlyCompetencia])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleOpenForm = useCallback(
    (
      data = {
        unidadeSalarioFixo: mes_5,
        empregador: empregador,
        empregadorId: empregador?.id,
        categoria: CategoriaEnum.Empregado_Geral_101,
        naturezaAtividade: trabalhoUrbano_1,
        tipoContrato: prazoDeterminado_2,
      },
    ) => {
      setForm((oldState) => ({
        ...oldState,
        isOpen: true,
        data,
      }))
    },
    [empregador],
  )

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [collection, handleOpenForm],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/vinculoAdmissaoPreliminar/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [handleOpenForm])

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection],
  )

  return (
    <ContentContainer>
      <PageHeader title="Admissão Preliminar">
        <ButtonBox>
          <Checkbox
            label="Somente registros da competência atual"
            value={onlyCompetencia}
            onChange={(e, value) => setOnlyCompetencia(value)}
          />
          <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        query={query}
        onItemClick={handleClickItem}
      />

      {form.isOpen && (
        <Form
          isOpen={form.isOpen}
          data={form.data}
          onClose={handleCloseForm}
          onAfterSubmitForm={handleAfterSubmitForm}
        />
      )}

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ContentContainer>
  )
}

export default AdmissaoPreliminar
