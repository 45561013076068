import React, { useEffect, useState } from 'react'

import moment from 'moment'

import { Box, IconButton, useTheme } from '@material-ui/core'
import { AddCircle as AddCircleIcon } from '@material-ui/icons'

import { MUIAutoComplete } from '~/components/AutoComplete'

import DialogAcordoDissidio from './DialogAcordoDissidio'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import { AcordoDissidioConvencao } from '~/hooks/queries/AcordoDissidioConvencao/AcordoDissidioConvencao'
import obterAcordoDissidioPorCompetencia from '~/hooks/queries/RescisaoComplementar/obterAcordoDissidioPorCompetencia'
import { IndAcordoColetivoValues } from '~/@types/enums/IndAcordoColetivoEnum'

interface InputAcordoDissidioProps {
  value: AcordoDissidioConvencao | null
  onChange: (value: AcordoDissidioConvencao | null) => void
  disabled: boolean
  required: boolean
  name: string
  validationErrors: ValidationErrors
}

export default function InputAcordoDissidio(props: InputAcordoDissidioProps) {
  const {
    value: acordoDissidioConvencao,
    onChange,
    disabled,
    required,
    name,
    validationErrors,
  } = props

  const [options, setOptions] = useState<AcordoDissidioConvencao[]>([])
  const [isLoading, setLoading] = useState(false)

  const theme = useTheme()
  const { anoMes } = useAmbiente()
  const {
    close: closeDialogAcordoDissidio,
    isOpen: isOpenDialogAcordoDissidio,
    open: openDialogAcordoDissidio,
  } = useDialog(null)

  useEffect(() => {
    setOptions([])
    onChange(null)
    // eslint-disable-next-line
  }, [anoMes])

  async function getData() {
    setLoading(true)
    let result: AcordoDissidioConvencao[] = []
    try {
      result = await obterAcordoDissidioPorCompetencia(anoMes)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
    return result
  }

  const getOptions = async () => {
    const newOptions = await getData()
    setOptions(newOptions)
  }

  const renderOption = (option: AcordoDissidioConvencao) => {
    const indicador = IndAcordoColetivoValues.find((d) => d.value === option.indADC)
    return `${moment(option.dtADC).format('DD/MM/yyyy')} - ${indicador?.name || ''} - ${
      option.descricao
    }`
  }

  async function onCloseDialogAcordoDissidio() {
    const newOptions = await getData()
    setOptions(newOptions)
    closeDialogAcordoDissidio()

    if (acordoDissidioConvencao) {
      const newAcordoDissidioConvencao =
        newOptions.find((d) => d.id === acordoDissidioConvencao.id) || null
      onChange(newAcordoDissidioConvencao)
    }
  }

  return (
    <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
      <MUIAutoComplete
        isLoading={isLoading}
        options={options}
        getOptions={getOptions}
        renderOption={renderOption}
        label="Acordo ou Dissídio Coletivo"
        value={acordoDissidioConvencao}
        onChange={(_, obj) => onChange(obj)}
        disabled={disabled}
        required={required}
        name={name}
        validationErrors={validationErrors}
      />

      <IconButton size="small" onClick={() => openDialogAcordoDissidio(null)} disabled={disabled}>
        <AddCircleIcon
          style={{
            color: disabled ? '' : theme.palette.success.main,
          }}
          fontSize="small"
          titleAccess="Adicionar Acordo ou Dissídio Coletivo"
        />
      </IconButton>

      {isOpenDialogAcordoDissidio ? (
        <DialogAcordoDissidio
          isOpen={isOpenDialogAcordoDissidio}
          onClose={onCloseDialogAcordoDissidio}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
