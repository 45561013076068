import { useMemo } from 'react'

import { Box } from '@material-ui/core'

import { CurrencyTextField, DatePickerNew, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { AutonomoManutencaoEstabelecimentoDTO } from '~/hooks/queries/Autonomo/AutonomoManutencaoDTO'

interface TableUltimoSalarioProps {
  data: AutonomoManutencaoEstabelecimentoDTO[]
  isFetching: boolean
  isLoading: boolean
  query: string
}
export default function TableUltimoSalario({
  data,
  isFetching,
  isLoading,
  query,
}: TableUltimoSalarioProps) {
  const columns: MUIDataTableColumnDef[] = useMemo(() => {
    return [
      {
        name: 'dtUltimoSalario',
        label: 'Último Salário',
        options: {
          sort: false,
          customBodyRender: (value) => (
            <DatePickerNew
              value={value}
              InputProps={{
                readOnly: true,
              }}
            />
          ),
        },
      },
      {
        name: 'valorUltimoSalario',
        label: 'Último Valor',
        options: {
          sort: false,
          customBodyRender: (value) => {
            return (
              <Box p={0.5}>
                <CurrencyTextField
                  value={value || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            )
          },
        },
      },
    ]
  }, [])

  return (
    <ToolsDataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      pagination={false}
      sherlock={{
        query,
        columns: ['estabelecimentoNrInscricao', 'estabelecimentoNome'],
      }}
    />
  )
}
