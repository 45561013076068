import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { VinculoValeTransporteCreateDTO } from './VinculoValeTransporteDTO'
import { queryObterTodosPorVinculo } from './useObterTodosPorVinculo'

interface RequestProps {
  data: VinculoValeTransporteCreateDTO[]
  isUsarHorarioTrabalho?: boolean
}

export default function useVinculoValeTransporteCreate() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest({ data, isUsarHorarioTrabalho }: RequestProps) {
    await api.post('/VinculoValeTransporte', data, {
      params: {
        isUsarHorarioTrabalho,
      },
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notification.post()
      queryClient.invalidateQueries(queryObterTodosPorVinculo)
    },
    onError: dialogNotification.extractErrors,
  })
}
