import React, { useState } from 'react'

import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid } from '@material-ui/core'

import { Button, DatePickerNew, PageHeader, TextField } from '~/components'
import { AutoCompleteLotacao } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import { useStepperContext } from '~/components/StepperForm'
import useAmbiente from '~/hooks/useAmbiente'

import { MainDataForm } from '..'

const schema = yup.object().shape({
  lotacaoId: yup.string().required('Informe Lotação').nullable(),
  anoMes: yup.string().required('Informe Ano/Mês').nullable(),
})

export default function FormLotacao() {
  const { mainData, onBack, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataLotacao)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const { empregador } = useAmbiente()

  function handleSubmit() {
    onNext({
      ...mainData,
      dataLotacao: data,
    })
  }

  function handleBack() {
    onBack()
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <PageHeader title="Lotação" />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompleteLotacao
          required
          validationErrors={validationErrors}
          name="lotacaoId"
          onChange={(e: FixLater, lotacao: FixLater) => {
            const lotacaoId = lotacao?.id || ''
            setData((oldState) => ({ ...oldState, lotacaoId }))
          }}
          value={data?.lotacaoId || ''}
          empregadorId={empregador.id}
          optionId="id"
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          label="Ano/Mês"
          fullWidth
          value={data?.anoMes || null}
          size="small"
          format="yyyy/MM"
          views={['year', 'month']}
          required
          validationErrors={validationErrors}
          name="anoMes"
          onChange={(date) => {
            const anoMes = date || undefined
            setData({ ...data, anoMes })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Dias"
          value={data?.dias || ''}
          onlyNumber
          inputProps={{ maxLength: 5 }}
          onChange={(e) => {
            const diasString = e.target.value
            const dias = diasString ? parseInt(diasString) : 0
            setData({ ...data, dias })
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Observação"
          value={data?.observacao || ''}
          multiline
          inputProps={{
            maxLength: 200,
          }}
          onChange={(e) => {
            const observacao = e.target.value
            setData({ ...data, observacao })
          }}
        />
      </Grid>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
