import { useState } from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { ActionDialog, Finder, ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import TableEventosImportados from './TableEventosImportados'

import useObterRecibosComEventosImportados from '~/hooks/queries/ImportarEventosRP/useObterRecibosComEventosImportados'
import useDesfazerImportacaoEventos from '~/hooks/queries/ImportarEventosRP/useDesfazerImportacaoEventos'
import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import { formatAnoMes, formatCurrency } from '~/hooks/useUtils'
import { formatToCPF } from 'brazilian-values'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
    minHeight: 300,
  },
}))

interface DialogRecibosImportadosProps {
  isOpen: boolean
  onClose: () => void
}

export default function DialogRecibosImportados({ isOpen, onClose }: DialogRecibosImportadosProps) {
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])
  const [query, setQuery] = useState('')

  const { anoMes } = useAmbiente()

  const {
    mutateAsync: mutateAsyncDesfazerImportacaoEventos,
    isLoading: isLoadingDesfazerImportacaoEventos,
  } = useDesfazerImportacaoEventos()
  const { data: _data, isLoading, isFetching } = useObterRecibosComEventosImportados({ anoMes })
  const data = _data || []
  const dialogNotification = useDialogNotification()

  const classes = useStyles()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'vinculoNrInscricao',
      label: 'CPF',
      options: {
        customBodyRender: (value) => formatToCPF(value),
      },
    },
    {
      name: 'vinculoNome',
      label: 'Nome',
    },
    {
      name: 'idf',
      label: 'Identificação',
      options: {
        customBodyRender: (value) => ReciboIDFValues.find((x) => x.value === value)?.name || '',
      },
    },
    {
      name: 'vrTotalVencimento',
      label: 'Vencimento',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrTotalDesconto',
      label: 'Desconto',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'vrTotalLiquido',
      label: 'Líquido',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'isFinalizado',
      label: 'Finalizado',
      options: {
        customBodyRender: (value) => <Ativo ativo={value} />,
      },
    },
    {
      name: 'nrReciboeSocial',
      label: 'eSocial',
    },
  ]

  function expandedChildren(index: number) {
    const dtCurrent = data[index]
    return <TableEventosImportados data={dtCurrent.rpItens} />
  }

  async function handleDesfazerImportacoes() {
    await mutateAsyncDesfazerImportacaoEventos({ anoMes })
    onClose()
  }

  function confirmDesfazerImportacoes() {
    dialogNotification.warning({
      descriptions: [
        'Todos eventos dos Recibos que foram importados serão removidos, deseja confirma essa remoção?',
      ],
      title: 'Temos uma informação importante',
      labelOnConfirm: 'Confirmar',
      onConfirm: () => handleDesfazerImportacoes(),
    } as FixLater)
  }

  return (
    <ActionDialog
      title={'Recibos Importados - ' + formatAnoMes(anoMes)}
      renderRight={<Finder onSearch={setQuery} onClose={() => setQuery('')} />}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
      onOkClick={confirmDesfazerImportacoes}
      okLabel="Desfazer Importações"
      isOkProcessing={isLoadingDesfazerImportacaoEventos}
    >
      <Box position="relative" overflow="auto">
        <ToolsDataTable
          data={data}
          columns={columns}
          disableAutoHeight
          isLoading={isLoading}
          isFetching={isFetching}
          optionsExpandable={{
            type: 'single',
            expandedChildren,
            onRowExpanded: setRowsExpanded,
            rowsExpanded,
            expandOnClick: true,
          }}
          sherlock={{
            columns: ['vinculoNrInscricao', 'vinculoNome'],
            query,
          }}
        />
      </Box>
    </ActionDialog>
  )
}
