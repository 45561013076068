import React from 'react'

import clsx from 'clsx'

import { ListRowProps } from 'react-virtualized'

import {
  Box,
  Checkbox,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { ChevronRight as ChevronRightIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import ListItemChildrenIcon from './ListItemChildrenIcon'

import { HEIGHT_ROW, VirtualizedMenuData } from '..'

const useStyles = makeStyles((theme: FixLater) => ({
  rowRendererSelected: {
    backgroundColor: theme.palette.secondary.lighter,
    borderRadius: theme.shape.borderRadius,
  },
  listItemRoot: {
    borderRadius: theme.shape.borderRadius,
    height: HEIGHT_ROW,
  },
  listItemIconRoot: {
    justifyContent: 'start',
    minWidth: theme.spacing(4.5),
  },
  ellipsisOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listItemText: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}))

interface RowRendererProps {
  listRowProps: ListRowProps
  data: VirtualizedMenuData[]
  onChangeParent: (id: string, index: number) => void
  parentSelected: string | null
  onChangeChildren?: (id: string) => void
  childrenSelected?: string
  renderRightParent?: (id: string) => React.ReactNode
  renderRightChildren?: (id: string) => React.ReactNode
  onCheckParent?: (ids: string[]) => void
  parentsChecked?: string[]
}

export default function RowRenderer(props: RowRendererProps) {
  const {
    listRowProps,
    data,
    onChangeParent,
    parentSelected,
    onChangeChildren,
    childrenSelected,
    renderRightParent,
    renderRightChildren,
    onCheckParent,
    parentsChecked,
  } = props
  const { index, style } = listRowProps

  const classes = useStyles()

  const dataCurrent = data[index]
  const isSelected = parentSelected === dataCurrent.id
  const isExpanded = dataCurrent.children.length > 0

  function onChangeCheck(id: string) {
    if (!onCheckParent || !parentsChecked) return
    if (parentsChecked.includes(id)) {
      onCheckParent(parentsChecked.filter((d) => d !== id))
    } else {
      onCheckParent([...parentsChecked, id])
    }
  }

  return (
    <Box style={style} className={clsx({ [classes.rowRendererSelected]: isSelected })}>
      <ListItem button className={classes.listItemRoot} dense data-index={index}>
        <ListItemIcon className={classes.listItemIconRoot}>
          {parentsChecked ? (
            <Checkbox
              edge="start"
              onClick={() => onChangeCheck(dataCurrent.id)}
              checked={parentsChecked.includes(dataCurrent.id)}
              size="small"
              color="secondary"
            />
          ) : isExpanded ? (
            isSelected ? (
              <ChevronRightIcon fontSize="small" color="inherit" />
            ) : (
              <ExpandMoreIcon fontSize="small" color="inherit" />
            )
          ) : (
            <ListItemChildrenIcon isSelected={isSelected} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={dataCurrent.label}
          title={dataCurrent.label}
          classes={{ primary: classes.ellipsisOverflow }}
          className={classes.listItemText}
          onClick={() => onChangeParent(dataCurrent.id, index)}
        />
        {renderRightParent && (
          <Box onClick={(e) => e.stopPropagation()}>{renderRightParent(dataCurrent.id)}</Box>
        )}
      </ListItem>
      <Collapse in={isSelected} timeout="auto" unmountOnExit>
        {isSelected &&
          dataCurrent.children.map((children) => {
            const isSelectedChildren = childrenSelected === children.id
            return (
              <ListItem
                key={'root-children-' + children.id}
                onClick={() => onChangeChildren && onChangeChildren(children.id)}
                button
                className={classes.listItemRoot}
                dense
                selected={isSelectedChildren}
              >
                <ListItemIcon className={classes.listItemIconRoot}>
                  <ListItemChildrenIcon isSelected={isSelectedChildren} />
                </ListItemIcon>
                <ListItemText
                  primary={children.label}
                  title={children.label}
                  classes={{ primary: classes.ellipsisOverflow }}
                />
                {renderRightChildren && (
                  <Box onClick={(e) => e.stopPropagation()}>{renderRightChildren(children.id)}</Box>
                )}
              </ListItem>
            )
          })}
      </Collapse>
    </Box>
  )
}
