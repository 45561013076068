import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifyRemove } from '~/utils/notification'

import { useNotificacaoObterNotificacoesClient } from './useNotificacaoObterNotificacoes'
import { useNotificacaoObterNotificacoesAmbienteClient } from './useNotificacaoObterNotificacoesAmbiente'

export default function useNotificacaoMarcaNotificacaoLida() {
  const dialogNotification = useDialogNotification()
  const { invalidateQuery } = useNotificacaoObterNotificacoesClient()
  const { invalidateQuery: invalidateQueryAmbiente } =
    useNotificacaoObterNotificacoesAmbienteClient()

  async function handleRequest(id: string) {
    await api.patch('/Notificacao/MarcaNotificacaoLida/' + id)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      invalidateQuery()
      invalidateQueryAmbiente()
    },
    onError: dialogNotification.extractErrors,
  })
}
