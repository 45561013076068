import React, { useState, useEffect, useCallback, useRef } from 'react'

import { Button, ButtonBox, ContentDivider } from 'mio-library-ui'

import { ConfirmDeleteDialog } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import useNotification from '~/hooks/useNotification'
import useHeightParents from '~/hooks/useHeightParents'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'
import { Box } from '@material-ui/core'

const EPI = (props) => {
  const { cargoAgenteNocivo, agenteNocivo } = props

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const refRootEPI = useRef(null)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  useHeightParents({ refElement: refRootEPI })

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        if (!cargoAgenteNocivo?.id) return
        const response = await api.get('/CargoAgenteNocivoEPI/GetByCargoAgenteNocivo', {
          params: {
            cargoAgenteNocivoId: cargoAgenteNocivo?.id,
          },
        })
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setCollection((oldState) => ({
          ...oldState,
          isLoading: false,
        }))
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [cargoAgenteNocivo])

  const handleOpenForm = useCallback((data = {}) => {
    setForm({
      isOpen: true,
      data,
    })
  }, [])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [collection, handleOpenForm],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog({
      isOpen: false,
      isDeleting: false,
      id: null,
    })
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/CargoAgenteNocivoEPI/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id, handleCloseConfirmDeleteItem])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm({
      cargoAgenteNocivo: cargoAgenteNocivo,
      cargoAgenteNocivoId: cargoAgenteNocivo?.id,
    })
  }, [handleOpenForm, cargoAgenteNocivo])

  const handleCloseForm = useCallback(() => {
    setForm({
      data: {},
      isOpen: false,
    })
  }, [])

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection, handleCloseForm],
  )

  return (
    <Box ref={refRootEPI}>
      <Box py={1}>
        <ContentDivider
          title="EPIs"
          renderRight={
            <ButtonBox>
              <Button size="small" color="primary" variant="contained" onClick={handleClickAddItem}>
                Adicionar
              </Button>
            </ButtonBox>
          }
        />
      </Box>
      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        onItemClick={handleClickItem}
      />

      <Form
        agenteNocivo={agenteNocivo}
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </Box>
  )
}

export default EPI
