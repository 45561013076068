import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import { indicadorContaBancariaValues } from '~/values/indicadorContaBancariaValues'

function formatIndicadorContaBancaria(indicador) {
  const indicadorFinded = indicadorContaBancariaValues.find((obj) => obj.value === indicador)
  if (!indicadorFinded) return ''
  return indicadorFinded.name
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading } = props

  const data = _data.map((obj) => {
    return {
      ...obj,
      bancoAgencia: `${obj.bancoAgencia?.numero} - ${obj.bancoAgencia?.nome}`,
      indContaBancaria: obj?.indContaBancaria
        ? formatIndicadorContaBancaria(obj.indContaBancaria)
        : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'bancoAgencia',
        label: 'Agência',
      },
      {
        name: 'contaCorrente',
        label: 'Número da Conta',
      },
      {
        name: 'dvContaCorrente',
        label: 'DV',
      },
      {
        name: 'indContaBancaria',
        label: 'Indicador conta bancária',
      },
      {
        name: 'isAtivo',
        label: 'Conta ativa',
        options: {
          customBodyRender: (value) => (value ? <Ativo ativo={value} /> : <></>),
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                  size="small"
                >
                  <EditIcon fontSize="small" color="primary" size="small" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                  size="small"
                >
                  <DeleteIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
