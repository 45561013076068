const naoAplica_999 = 999

const saldoSalario_50 = 50

const comissao_51 = 51

const gratificacao_52 = 52

const adicionalInsalubridade_53 = 53

const adicionalPericulosidade_54 = 54

const adicionalNoturno_55 = 55

const horasExtras_56 = 56

const gorjetas_57 = 57

const descansoSemanalRemuneradoDSR_58 = 58

const reflexoDSRsobreSalarioVariavel_59 = 59

const multaArt477_8CLT_60 = 60

const multaArt479_CLT_61 = 61

const salarioFamilia_62 = 62

const decimoTerceiroSalarioProporcional_63 = 63

const decimoTerceiroSalarioExerciciosAnteriores_64 = 64

const feriasProporcionais_65 = 65

const feriasVencidas_66 = 66

const feriasVencidasReflexoDobra_67 = 67

const tercoConstitucionalFerias_68 = 68

const avisoPrevioIndenizado_69 = 69

const decimoTerceiroSalarioAvisoPrevioIndenizado_70 = 70

const feriasAvisoPrevioIndenizado_71 = 71

const percentagem_72 = 72

const premios_73 = 73

const viagens_74 = 74

const sobreaviso_75 = 75

const prontidao_76 = 76

const adicionalTempoServiço_77 = 77

const adicionalTransferenciaLocalidadeTrabalho_78 = 78

const salarioFamiliaExcedenteValorLegal_79 = 79

const abonoGratificacaoFeriasExcedente20DiasSalario_80 = 80

const valorGlobalDiariasViagemExcedente50Salario_81 = 81

const AjudaCustoArt470_CLT_82 = 82

const EtapasMaritimos_83 = 83

const LicencaPremioIndenizada_84 = 84

const QuebraCaixa_85 = 85

const ParticipacaoLucrosResultados_86 = 86

const IndenizacaoTituloIncentivoDemissao_87 = 87

const BolsaAprendizagem_88 = 88

const AbonosDesvinculadosSalario_89 = 89

const GanhosEventuaisDesvinculadosSalario_90 = 90

const ReembolsoCreche_91 = 91

const ReembolsoBaba_92 = 92

const GratificacaoSemestral_93 = 93

const SalarioMesAnteriorRescisao_94 = 94

const OutrasVerbas_95 = 95

const IndenizacaoArt9Lei7238_84_96 = 96

const IndenizacaoFeriasEscolares_97 = 97

const MultaArt476A5_CLT_98 = 98

const AjusteSaldoDevedor_99 = 99

const PensaoAlimenticia_100 = 100

const AdiantamentoSalarial_101 = 101

const Adiantamento13Salario_102 = 102

const AvisoPrevioIndenizado_103 = 103

const IndenizacaoArt480_CLT_104 = 104

const EmprestimoConsignacao_105 = 105

const ValeTransporte_106 = 106

const ReembolsoValeTransporte_107 = 107

const ValeAlimentacao_108 = 108

const ReembolsoValeAlimentacao_109 = 109

const ContribuicaoParaFAPI_110 = 110

const ContribuicaoSindicalLaboral_111 = 111

const PrevidenciaSocial_112 = 112

const ContribuicaoPrevidenciaComplementar_113 = 113

const IRRF_114 = 114

const OutrosDescontos_115 = 115

const DescontoValorLiquidoTRCTQuitadoDecisaoJudicial_116 = 116

export const indTRCTValues = [
  { value: naoAplica_999, name: '999 - Não se aplica' },
  { value: saldoSalario_50, name: '50 - Saldo de Salário' },
  { value: comissao_51, name: '51 - Comissão' },
  { value: gratificacao_52, name: '52 - Gratificação' },
  { value: adicionalInsalubridade_53, name: '53 - Adicional de Insalubridade' },
  {
    value: adicionalPericulosidade_54,
    name: '54 - Adicional de Periculosidade',
  },
  { value: adicionalNoturno_55, name: '55 - Adicional Noturno' },
  { value: horasExtras_56, name: '56 - Horas Extras' },
  { value: gorjetas_57, name: '57 - Gorjetas' },
  {
    value: descansoSemanalRemuneradoDSR_58,
    name: '58 - Descanso Semanal Remunerado (DSR)',
  },
  {
    value: reflexoDSRsobreSalarioVariavel_59,
    name: '59 - Reflexo do DSR sobre Salário Variável',
  },
  { value: multaArt477_8CLT_60, name: '60 - Multa Art. 477, § 8º/CLT' },
  { value: multaArt479_CLT_61, name: '61 - Multa Art. 479/CLT' },
  { value: salarioFamilia_62, name: '62 - Salário Família' },
  {
    value: decimoTerceiroSalarioProporcional_63,
    name: '63 - Décimo Terceiro Salário Proporcional',
  },
  {
    value: decimoTerceiroSalarioExerciciosAnteriores_64,
    name: '64 - Décimo Terceiro Salário Exercícios Anteriores',
  },
  { value: feriasProporcionais_65, name: '65 - Férias Proporcionais' },
  { value: feriasVencidas_66, name: '66 - Férias Vencidas' },
  {
    value: feriasVencidasReflexoDobra_67,
    name: '67 - Férias Vencidas (Reflexo Dobra)',
  },
  {
    value: tercoConstitucionalFerias_68,
    name: '68 - Terço Constitucional de Férias',
  },
  { value: avisoPrevioIndenizado_69, name: '69 - Aviso Prévio Indenizado' },
  {
    value: decimoTerceiroSalarioAvisoPrevioIndenizado_70,
    name: '70 - Décimo Terceiro Salário (Aviso–Prévio Indenizado)',
  },
  {
    value: feriasAvisoPrevioIndenizado_71,
    name: '71 - Férias (Aviso Prévio Indenizado)',
  },
  { value: percentagem_72, name: '72 - Percentagem' },
  { value: premios_73, name: '73 - Prêmios' },
  { value: viagens_74, name: '74 - Viagens' },
  { value: sobreaviso_75, name: '75 - Sobreaviso' },
  { value: prontidao_76, name: '76 - Prontidão' },
  {
    value: adicionalTempoServiço_77,
    name: '77 - Adicional por Tempo de Serviço',
  },
  {
    value: adicionalTransferenciaLocalidadeTrabalho_78,
    name: '78 - Adicional por Transferência de Localidade de Trabalho',
  },
  {
    value: salarioFamiliaExcedenteValorLegal_79,
    name: '79 - Salário Família Excedente ao Valor Legal',
  },
  {
    value: abonoGratificacaoFeriasExcedente20DiasSalario_80,
    name: '80 - Abono/Gratificação de Férias Excedente 20 Dias Salário',
  },
  {
    value: valorGlobalDiariasViagemExcedente50Salario_81,
    name: '81 - Valor Global Diárias para Viagem Excedente 50% Salário',
  },
  { value: AjudaCustoArt470_CLT_82, name: '82 - Ajuda de Custo Art. 470/CLT' },
  { value: EtapasMaritimos_83, name: '83 - Etapas Marítimos' },
  { value: LicencaPremioIndenizada_84, name: '84 - Licença Prêmio Indenizada' },
  { value: QuebraCaixa_85, name: '85 - Quebra de Caixa' },
  {
    value: ParticipacaoLucrosResultados_86,
    name: '86 - Participação nos Lucros ou Resultados',
  },
  {
    value: IndenizacaoTituloIncentivoDemissao_87,
    name: '87 - Indenização a Título de Incentivo à Demissão',
  },
  { value: BolsaAprendizagem_88, name: '88 - Bolsa Aprendizagem' },
  {
    value: AbonosDesvinculadosSalario_89,
    name: '89 - Abonos Desvinculados do Salário',
  },
  {
    value: GanhosEventuaisDesvinculadosSalario_90,
    name: '90 - Ganhos Eventuais Desvinculados do Salário',
  },
  { value: ReembolsoCreche_91, name: '91 - Reembolso Creche' },
  { value: ReembolsoBaba_92, name: '92 - Reembolso Babá' },
  { value: GratificacaoSemestral_93, name: '93 - Gratificação Semestral' },
  {
    value: SalarioMesAnteriorRescisao_94,
    name: '94 - Salário do Mês Anterior à Rescisão',
  },
  { value: OutrasVerbas_95, name: '95 - Outras Verbas' },
  {
    value: IndenizacaoArt9Lei7238_84_96,
    name: '96 - Indenização Art. 9º, Lei n.º 7.238/84',
  },
  {
    value: IndenizacaoFeriasEscolares_97,
    name: '97 - Indenização Férias Escolares',
  },
  {
    value: MultaArt476A5_CLT_98,
    name: '98 - Multa do Art. 476–A, § 5º, da CLT',
  },
  { value: AjusteSaldoDevedor_99, name: '99 - Ajuste do saldo devedor' },
  { value: PensaoAlimenticia_100, name: '100 - Pensão Alimentícia' },
  { value: AdiantamentoSalarial_101, name: '101 - Adiantamento Salarial' },
  {
    value: Adiantamento13Salario_102,
    name: '102 - Adiantamento de 13º Salário',
  },
  { value: AvisoPrevioIndenizado_103, name: '103 - Aviso Prévio Indenizado' },
  { value: IndenizacaoArt480_CLT_104, name: '104 - Indenização Art. 480 CLT' },
  { value: EmprestimoConsignacao_105, name: '105 - Empréstimo em Consignação' },
  { value: ValeTransporte_106, name: '106 - Vale Transporte' },
  {
    value: ReembolsoValeTransporte_107,
    name: '107 - Reembolso do Vale–Transporte',
  },
  { value: ValeAlimentacao_108, name: '108 - Vale Alimentação' },
  {
    value: ReembolsoValeAlimentacao_109,
    name: '109 - Reembolso do Vale Alimentação',
  },
  { value: ContribuicaoParaFAPI_110, name: '110 - Contribuição para o FAPI' },
  {
    value: ContribuicaoSindicalLaboral_111,
    name: '111 - Contribuição Sindical Laboral',
  },
  { value: PrevidenciaSocial_112, name: '112 - Previdência Social' },
  {
    value: ContribuicaoPrevidenciaComplementar_113,
    name: '113 - Contribuição Previdência Complementar',
  },
  { value: IRRF_114, name: '114 - IRRF' },
  { value: OutrosDescontos_115, name: '115 - Outros Descontos' },
  {
    value: DescontoValorLiquidoTRCTQuitadoDecisaoJudicial_116,
    name: '116 - Desconto de Valor Líquido de TRCT Quitado Decisão Judicial',
  },
]
