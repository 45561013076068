import api from '~/services/api-pessoal'

import { VinculoAlteracaoContrato2206DTO } from './VinculoAlteracaoContrato2206DTO'

export async function obterDataFormulario2206(
  vinculoId: string,
  anoMes: string,
  vinculoAlteracaoContratoId?: string,
) {
  const response = await api.get<{ data: VinculoAlteracaoContrato2206DTO }>(
    '/VinculoAlteracaoContratoCadastro/ObterDataFormulario2206',
    {
      params: {
        vinculoId,
        vinculoAlteracaoContratoId,
        anoMes,
      },
    },
  )
  return response.data.data
}
