import React, { useState, useEffect } from 'react'

import { ContentDivider, ButtonBox } from 'mio-library-ui'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useUtils from '~/hooks/useUtils'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'

import { formatToBRL } from 'brazilian-values'
import _ from 'lodash'

const TabelaIR = () => {
  const [data, setData] = useState({
    isLoading: false,
    item: [],
  })
  const dialogNotification = useDialogNotification()

  const { formatMonth } = useUtils()
  const { anoMes } = useAmbiente(false, true)

  useEffect(() => {
    const getCollection = async () => {
      setData((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get('/tabelaIR')
        if (response.data.data) {
          const newArray = response.data.data.filter((item) => {
            if (item?.anoMes <= anoMes && item?.indTabelaIR === '1') {
              return true
            } else {
              return false
            }
          })

          let newItem = {}

          newItem = _.orderBy(newArray, 'anoMes', 'desc')[0]

          if (!newItem) newItem = _.orderBy(response.data.data, 'anoMes', 'asc')[0]

          setData((oldState) => ({
            ...oldState,
            item: objectInDataForTable(newItem),
            anoMes: newItem?.anoMes,
            indTabelaIR: newItem?.indTabelaIR,
            vrDeducaoDependente: newItem?.vrDeducaoDependente,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setData((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  const objectInDataForTable = (obj) => {
    return [
      {
        faixa: 'I',
        vrFaixaInit: 0,
        vrFaixaEnd: obj?.vrFaixaI,
        aliqFaixa: obj?.aliqFaixaI,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaI,
      },

      {
        faixa: 'II',
        vrFaixaInit: obj?.vrFaixaI,
        vrFaixaEnd: obj?.vrFaixaII,
        aliqFaixa: obj?.aliqFaixaII,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaII,
      },

      {
        faixa: 'III',
        vrFaixaInit: obj?.vrFaixaII,
        vrFaixaEnd: obj?.vrFaixaIII,
        aliqFaixa: obj?.aliqFaixaIII,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaIII,
      },

      {
        faixa: 'IV',
        vrFaixaInit: obj?.vrFaixaIII,
        vrFaixaEnd: obj?.vrFaixaIV,
        aliqFaixa: obj?.aliqFaixaIV,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaIV,
      },

      {
        faixa: 'V',
        vrFaixaInit: obj?.vrFaixaIV,
        vrFaixaEnd: obj?.vrFaixaV,
        aliqFaixa: obj?.aliqFaixaV,
        vrDeducaoFaixa: obj?.vrDeducaoFaixaV,
      },
    ]
  }

  return (
    <>
      <ContentDivider
        title={`${data?.anoMes?.substr(0, 4)} / ${formatMonth(data?.anoMes?.substr(4))}`}
        showDivider={false}
        bottom={1}
      />
      <Table data={data.item} isLoading={data.isLoading} />
      <ButtonBox top={1}>
        <ContentDivider
          title={`Dedução por Depedente ${formatToBRL(data?.vrDeducaoDependente)}`}
          showDivider={false}
        />
      </ButtonBox>
    </>
  )
}

export default TabelaIR
