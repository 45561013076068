import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { ModeloPlanilhaImportacao } from './ModeloPlanilhaImportacao'

export const queryModeloPlanilhaImportacao = '/ModeloPlanilhaImportacao'

export default function useModeloPlanilhaImportacao() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ModeloPlanilhaImportacao[] }>(
      queryModeloPlanilhaImportacao,
    )
    return response.data.data
  }

  return useQuery([queryModeloPlanilhaImportacao], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
