import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'

interface DialogProblemsProps {
  isOpen: boolean
  onClose: () => void
  data: string[]
  subtitle: string
}

export default function DialogProblems({
  isOpen,
  onClose,
  data: _data,
  subtitle,
}: DialogProblemsProps) {
  const data = _data.map((d) => ({ value: d }))
  return (
    <ActionDialog
      title="Problemas"
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <ToolsDataTable
        data={data}
        columns={[
          {
            name: 'value',
            label: 'Problema',
          },
        ]}
        disableAutoHeight
        pagination={false}
      />
    </ActionDialog>
  )
}
