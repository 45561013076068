import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { RP } from './RP'

interface RequestProps {
  vinculoId: string
  competencia: string
}

export function useObterRecibosPosterioresIguaisCompetencia() {
  const dialogNotification = useDialogNotification()

  async function handleRequest(props: RequestProps) {
    const response = await api.get<{ data: RP[] }>('/RP/ObterRecibosPosterioresIguaisCompetencia', {
      params: {
        ...props,
      },
    })
    return response.data.data
  }

  return useMutation(handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
