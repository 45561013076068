const naoAplicavel_0 = 0
const esquerda_1 = 1
const direita_2 = 2
const ambas_3 = 3

export const CATLateralidadeValues = [
  { value: naoAplicavel_0, name: '0 - Não informar' },
  { value: esquerda_1, name: '1 - Esquerda' },
  { value: direita_2, name: '2 - Direita' },
  { value: ambas_3, name: '3 - Ambas' },
]
