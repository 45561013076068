import React, { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { AutoCompleteHorarioTrabalho } from '~/components/AutoComplete'
import MultipleSelect from '~/components/MultipleSelect'

import { useStepperContext } from '~/components/StepperForm'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoHorarioItemPartial } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'
import { DiaHorarioEnum, DiaHorarioValues } from '~/@types/enums/DiaHorarioEnum'
import { MainDataForm } from '../../..'
import { HorarioTrabalho } from '~/hooks/queries/HorarioTrabalho/HorarioTrabalho'

const schema = yup.object().shape({
  diasHorarios: yup.array().min(1, 'Informe pelo menos um Dia'),
  horarioTrabalhoId: yup.string().required('Informe o Horário de Trabalho'),
})

interface DiaHorarioData {
  diasHorarios?: { value: DiaHorarioEnum; name: string }[]
  horarioTrabalhoId?: string
  horarioTrabalho?: HorarioTrabalho
}

interface FormItemProps {
  isOpen: boolean
  onClose: () => void
  onAfterSubmitForm: (data: VinculoHorarioItemPartial[]) => void
}

export default function FormItem(props: FormItemProps) {
  const { isOpen, onClose, onAfterSubmitForm } = props
  const { empregador, estabelecimento } = useAmbiente()

  const { mainData } = useStepperContext<MainDataForm>()

  const [data, setData] = useState<DiaHorarioData>({
    horarioTrabalhoId: estabelecimento.horarioTrabalhoId || undefined,
  })

  const dialogNotification = useDialogNotification()

  function handleVerifyVinculoHasSabadoCompensado(horarioTrabalho?: HorarioTrabalho) {
    if (!horarioTrabalho) return
    const duracaoJornadaSabado = horarioTrabalho.duracaoJornadaSabado || 0
    const isSabadoCompensado = mainData.dataInitialForm.isSabadoCompensado
    if (isSabadoCompensado && !(duracaoJornadaSabado > 0)) {
      throw dialogNotification.info({
        descriptions: [
          `O vínculo ${mainData.dataInitialForm?.pessoaFisica?.nome} possui em suas configurações a opção de 
          "Horas de sábado compensadas durante a semana", mas nenhum horário de trabalho com horas compensadas de sábado foi definido!`,
        ],
      })
    }
  }

  const handleSubmit = () => {
    try {
      handleVerifyVinculoHasSabadoCompensado(data?.horarioTrabalho)
      const diasHorariosCurrent = data?.diasHorarios || []
      const dataItems: VinculoHorarioItemPartial[] = diasHorariosCurrent.map((d) => ({
        diaHorario: d.value,
        horarioTrabalhoId: data?.horarioTrabalhoId,
        horarioTrabalho: data?.horarioTrabalho,
      }))
      onAfterSubmitForm(dataItems)
    } catch (e) {
      console.log(e)
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Adicionar Horários de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Adicionar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MultipleSelect
            inputProps={{
              label: 'Dias da semana trabalhado',
              required: true,
            }}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect
            required
            validationErrors={validationErrors}
            name="diasHorarios"
            value={data?.diasHorarios || []}
            options={DiaHorarioValues}
            onChange={(e, selecteds: { value: DiaHorarioEnum; name: string }[]) => {
              const diasHorarios = selecteds
              setData((prev) => ({ ...prev, diasHorarios }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteHorarioTrabalho
            value={data?.horarioTrabalhoId || ''}
            empregadorId={empregador?.id}
            required
            validationErrors={validationErrors}
            name="horarioTrabalhoId"
            label="Horário de Trabalho"
            onChange={(e, horarioTrabalho) => {
              const horarioTrabalhoId = horarioTrabalho?.id || ''
              setData((prev) => ({ ...prev, horarioTrabalhoId, horarioTrabalho }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
