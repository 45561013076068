import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ApurarFolhaESocialFGTSAnaliticoDTO } from './ApurarFolhaESocialFGTSDTO'

const queryObterFGTSAnaliticoVinculo = '/ApurarESocial/ObterFGTSAnaliticoVinculo'

interface RequestProps {
  vinculoId: string
  periodoApuracao: string
}

export default function useObterFGTSAnaliticoVinculo(props: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ApurarFolhaESocialFGTSAnaliticoDTO[] }>(
      queryObterFGTSAnaliticoVinculo,
      {
        params: props,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterFGTSAnaliticoVinculo, props], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
