import React, { useMemo, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import { DataTable, Finder } from 'mio-library-ui'

import { ActionDialog } from '~/components'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '400px',
  },
}))

const DialogEpis = (props) => {
  const { data: _data, isOpen, onClose, vinculo } = props

  const [query, setQuery] = useState('')

  const classes = useStyles()

  const data = _data.map((item) => {
    return {
      ...item,
      certificadoAvaliacao: item?.epi?.certificadoAvaliacao || '',
      descricao: item?.epi?.descricao || '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'certificadoAvaliacao',
        label: 'Certificado de Avaliação',
      },
      {
        name: 'descricao',
        label: 'Descrição EPI',
      },
    ],
    [],
  )

  return (
    <ActionDialog
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      title="EPIs - Agentes Nocivos - Evento S-2240"
      subtitle={vinculo ? vinculo?.vinculoNome : '-'}
      renderRight={<Finder onSearch={setQuery} onClose={() => setQuery('')} />}
      customActions={<></>}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <DataTable
        title=""
        data={data}
        columns={columns}
        pagination={false}
        sherlock={{
          query,
          columns: ['certificadoAvaliacao', 'descricao'],
        }}
      />
    </ActionDialog>
  )
}

export default DialogEpis
