import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { PessoaFisica } from '../PessoaFisica/PessoaFisica'

const query = '/PlanoSaudeDependente/ObterPessoaPlanoSaudeTitular'

interface RequestProps {
  params: {
    planoSaudeTitularId: string
  }
}

export function useObterPessoaPlanoSaudeTitular({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: PessoaFisica }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
