import { Grid } from '@material-ui/core'

import { SimpleSelect } from '~/components'

import { CodigoSaidaFGTSValues } from '~/@types/enums/CodigoSaidaFGTSEnum'
import { CodigoRetornoFGTSValues } from '~/@types/enums/CodigoRetornoFGTSEnum'

import { VinculoAfastamentoForm } from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'
import { useStepperContext } from '~/components/StepperForm'

export default function DadosSEFIP() {
  const { dataControlled: data, onChangeControlled: setData } =
    useStepperContext<VinculoAfastamentoForm>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SimpleSelect
          label="Código de Saída FGTS"
          options={CodigoSaidaFGTSValues}
          renderOption={(opt) => opt.name}
          optionId="value"
          value={data?.codigoSaidaFGTS || null}
          onChange={(_, codigoSaidaFGTS) => {
            setData((oldState) => ({
              ...oldState,
              codigoSaidaFGTS,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <SimpleSelect
          label="Código de Retorno FGTS"
          options={CodigoRetornoFGTSValues}
          renderOption={(opt) => opt.name}
          optionId="value"
          value={data?.codigoRetornoFGTS || null}
          onChange={(_, codigoRetornoFGTS) => {
            setData((oldState) => ({
              ...oldState,
              codigoRetornoFGTS,
            }))
          }}
        />
      </Grid>
    </Grid>
  )
}
