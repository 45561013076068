import React from 'react'

import { Box } from '@material-ui/core'

import { Button, ButtonBox, PageHeader } from '~/components'

import { useStepperContext } from '~/components/StepperForm'

import { MainDataForm } from '../../../Autonomo/FlowCreate'
import { VinculoSalarioBase } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'
import SalarioContratualFormSimple from '~/pages/Funcionario/Forms/SalarioContratualFormSimple'

interface FormSalarioContratualSimpleProps {
  title: string
}

export default function FormSalarioContratualSimple({ title }: FormSalarioContratualSimpleProps) {
  const { mainData, onBack, onNext } = useStepperContext<MainDataForm>()
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  function handleBack() {
    onBack()
  }

  function handleNext() {
    buttonRef.current?.click()
  }

  function handleSubmit(data: VinculoSalarioBase) {
    onNext({
      ...mainData,
      dataSalarioContratual: data,
    })
  }

  return (
    <Box>
      <PageHeader title={title} />

      <SalarioContratualFormSimple
        data={mainData.dataSalarioContratual as FixLater}
        onSubmit={handleSubmit}
        buttonRef={buttonRef}
      />

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleNext} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Box>
  )
}
