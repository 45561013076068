import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'

import { formatToBRL } from 'brazilian-values'
import moment from 'moment'
import { IndSalarioContratualValues } from '~/@types/enums/IndSalarioContratualEnum'

function formatIndSalario(indSalario) {
  const objectFinded = IndSalarioContratualValues.find((obj) => obj.value === indSalario)
  return objectFinded?.name || ''
}

const Table = (props) => {
  const { data, onItemClick, isLoading, isFinalizado } = props

  const columns = useMemo(
    () => [
      {
        name: 'dtSalario',
        label: 'Data Salário',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtEfetiva',
        label: 'Data Efetiva',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'indSalario',
        label: 'Indicador do Salário',
        options: {
          customBodyRender: (value) => formatIndSalario(value),
        },
      },
      {
        name: 'quantidade',
        label: 'Quantidade',
      },
      {
        name: 'vrSalario',
        label: 'Valor',
        options: {
          customBodyRender: (value) => (
            <ButtonBox>{formatToBRL(value).replace('R$ ', '')}</ButtonBox>
          ),
        },
      },
      {
        name: 'observacao',
        label: 'Observação',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          display: isFinalizado ? 'excluded' : 'true',
          customBodyRender: (value) => {
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick(value)
                  }}
                  size="small"
                >
                  <DeleteIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick, isFinalizado],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
