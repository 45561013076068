import React from 'react'

import { Checkbox, TextField } from '@material-ui/core'
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@material-ui/icons'

import { Autocomplete } from '@material-ui/lab'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface MultipleSelectCheckProps<T> {
  options: T[]
  getOptionLabel: (opt: T) => string
  label: string
  disabled?: boolean
  required?: boolean
  limitTags?: number
  placeholder?: string
  fieldCompare: keyof T
  value: T[]
  onChange(value: T[]): void
  getOptionDisabled?(value: T): boolean
}

export default function MultipleSelectCheck<T>(props: MultipleSelectCheckProps<T>) {
  const {
    options,
    getOptionLabel,
    label,
    disabled,
    required,
    limitTags = 4,
    placeholder = 'Digite aqui...',
    fieldCompare,
    value,
    onChange,
    getOptionDisabled,
  } = props

  return (
    <Autocomplete<T, true>
      multiple
      id="multiple-select-check"
      options={options}
      disabled={disabled}
      limitTags={limitTags}
      fullWidth
      size="small"
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => {
        if (!option) return false
        if (!value) return false
        return option[fieldCompare] === value[fieldCompare]
      }}
      value={value}
      onChange={(_, value) => onChange(value)}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {getOptionLabel(option)}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label={label}
          placeholder={placeholder}
          required={required}
          fullWidth
        />
      )}
      getOptionDisabled={getOptionDisabled}
    />
  )
}
