import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import { codigoTreinamentoCapacitacaoValues } from '~/values/codigoTreinamentoCapacitacaoValues'

const schema = yup.object().shape({
  dtRealizacao: yup.string().required('Informe a Data da Realização').nullable(),
  codigoTreinamento: yup.string().required('Informe o Código do Treinamento'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/VinculoTreinamento/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/VinculoTreinamento', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Treinamento"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePicker
            label="Data da Realização"
            size="small"
            name="dtRealizacao"
            validationErrors={validationErrors}
            required
            value={data?.dtRealizacao || null}
            onChange={(date) => {
              const dtRealizacao = date?.format('yyyy-MM-DD') || null
              setData({ ...data, dtRealizacao })
            }}
          />
        </Grid>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <MUIAutoComplete
            label="Código do Treinamento"
            name="codigoTreinamento"
            validationErrors={validationErrors}
            required
            value={data.codigoTreinamento}
            options={codigoTreinamentoCapacitacaoValues}
            renderOption={(option) => option.name}
            optionId="value"
            onChange={(e, obj) => {
              const codigoTreinamento = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                codigoTreinamento,
              }))
            }}
          />
        </Grid>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            label="Observação"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.observacao || ''}
            inputProps={{ maxLength: 400 }}
            multiline
            onChange={(e) => {
              const observacao = e.target.value
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
