import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ProcessoTrabalhistaVinculo } from './dtos/ProcessoTrabalhistaVinculo'

const query = '/ProcessoTrabalhistaVinculo/ObterPorId/'

interface RequestProps {
  processoTrabalhistaVinculoId: string
}

export function useGetById({ processoTrabalhistaVinculoId }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ProcessoTrabalhistaVinculo }>(
      query + processoTrabalhistaVinculoId,
    )
    return response.data.data
  }

  return useQuery([query, processoTrabalhistaVinculoId], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
