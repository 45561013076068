import React from 'react'

import moment from 'moment'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'

import { VinculoLocalPartial } from '~/hooks/queries/VinculoLocal/VinculoLocal'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  data: VinculoLocalPartial[]
  onItemClick: (event: 'delete' | 'edit', index: number) => void
}

export default function Table(props: TableProps) {
  const { data, onItemClick } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtLocal',
      label: 'Data',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'departamento',
      label: 'Departamento',
      options: {
        sort: false,
        customBodyRender: (value) => (value ? value.nome : ''),
      },
    },
    {
      name: 'setor',
      label: 'Setor',
      options: {
        sort: false,
        customBodyRender: (value) => (value ? value.nome : ''),
      },
    },
    {
      name: 'cargo',
      label: 'Cargo',
      options: {
        sort: false,
        customBodyRender: (value) => (value ? value.nome : ''),
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', rowIndex)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', rowIndex)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} />
}
