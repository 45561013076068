import Immutable from 'seamless-immutable'

export const Types = {
  SET_LOADING: 'APP/SET_LOADING',
  SET_USUARIO: 'APP/SET_USUARIO',
  SET_TOKEN_USUARIO: 'APP/SET_TOKEN_USUARIO',
  SET_TOKEN_PESSOAL: 'APP/SET_TOKEN_PESSOAL',
  LOGOUT: 'USER_LOGOUT',
  SET_EMPRESA: 'APP/SET_EMPRESA',
  SET_COMPETENCIA: 'APP/SET_COMPETENCIA',
  SET_AMBIENTE: 'APP/SET_AMBIENTE',
  SET_LEFT_MENU_OPEN: 'APP/SET_LEFT_MENU_OPEN',
  SET_PARAMETROS_SISTEMA: 'APP/SET_PARAMETROS_SISTEMA',
}

const initialState = Immutable({
  loading: false,
  error: null,
  leftMenuOpen: true,
  ambiente: {
    empresa: {
      empregador: null,
      estabelecimento: null,
    },
    competencia: {
      ano: '2021',
      mes: '01',
      anoMes: '202101',
      diaMaxMes: 0,
    },
  },
  parametrosSistema: null,
})

export default function app(state = initialState, action) {
  switch (action.type) {
    case Types.SET_LOADING:
      return {
        ...state,
        loading: action.payload.isLoading,
      }
    case Types.SET_USUARIO:
      return {
        ...state,
        usuario: action.payload.data,
      }
    case Types.SET_TOKEN_USUARIO:
      return {
        ...state,
        tokenUsuario: action.payload.data,
      }
    case Types.SET_TOKEN_PESSOAL:
      return {
        ...state,
        tokenPessoal: action.payload.data,
      }
    case Types.SET_EMPRESA:
      return {
        ...state,
        ambiente: {
          ...state.ambiente,
          empresa: action.payload.data,
        },
      }
    case Types.SET_COMPETENCIA:
      return {
        ...state,
        ambiente: {
          ...state.ambiente,
          competencia: action.payload.data,
        },
      }
    case Types.SET_AMBIENTE:
      return {
        ...state,
        ambiente: action.payload.data,
      }
    case Types.SET_LEFT_MENU_OPEN:
      return {
        ...state,
        leftMenuOpen: action.payload.data,
      }
    case Types.SET_PARAMETROS_SISTEMA:
      return {
        ...state,
        parametrosSistema: action.payload.data,
      }
    default:
      return state
  }
}

export const Creators = {
  setUsuario: (data) => ({
    type: Types.SET_USUARIO,
    payload: {
      data,
    },
  }),
  seLoading: (isLoading) => ({
    type: Types.isLoading,
    payload: {
      isLoading,
    },
  }),
  setTokenUsuario: (data) => ({
    type: Types.SET_TOKEN_USUARIO,
    payload: {
      data,
    },
  }),
  setTokenPessoal: (data) => ({
    type: Types.SET_TOKEN_PESSOAL,
    payload: {
      data,
    },
  }),
  doLogout: () => ({
    type: Types.LOGOUT,
  }),

  setEmpresa: (data) => ({
    type: Types.SET_EMPRESA,
    payload: {
      data,
    },
  }),

  setCompetencia: (data) => ({
    type: Types.SET_COMPETENCIA,
    payload: {
      data,
    },
  }),

  setAmbiente: (data) => ({
    type: Types.SET_AMBIENTE,
    payload: {
      data,
    },
  }),

  setLeftMenuOpen: (data) => ({
    type: Types.SET_LEFT_MENU_OPEN,
    payload: {
      data,
    },
  }),

  setParametrosSistema: (data) => ({
    type: Types.SET_PARAMETROS_SISTEMA,
    payload: {
      data,
    },
  }),
}
