import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { useQuery } from '~/hooks/queries/useSindicato'

import { HeightDataTable } from '~/components'

import { formatToPhone, formatToCPFOrCNPJ } from 'brazilian-values'

import { sindicatoCategoriaValues } from '~/values/sindicatoCategoriaValues'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

export default function Table(props) {
  const { onItemClick, query } = props

  const { data: _data, isLoading } = useQuery()

  function formatIndCategoriaEntidade(indCategoriaEntidade) {
    const indCategoriaEntidadeFinded = sindicatoCategoriaValues.find(
      (obj) => obj.value === indCategoriaEntidade,
    )
    return indCategoriaEntidadeFinded.name
  }

  const data = _data?.map((item) => {
    return {
      ...item,
      nrInscricao: formatToCPFOrCNPJ(item?.nrInscricao),
      telefone: item?.telefone ? formatToPhone(item.telefone) : '',
      indCategoriaEntidade: formatIndCategoriaEntidade(item.indCategoriaEntidade),
      cidade: item?.cidadeUF ? `${item.cidadeUF} - ${item.cidadeNome}` : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
      {
        name: 'indCategoriaEntidade',
        label: 'Categoria Entidade',
      },
      {
        name: 'cidade',
        label: 'Cidade',
      },
      {
        name: 'telefone',
        label: 'Telefone',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    const dataFinded = _data?.find((d) => d.id === value)
                    onItemClick('edit', dataFinded)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick, _data],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['nome', 'nrInscricao'],
      }}
    />
  )
}
