import React from 'react'

import { Grid } from '@material-ui/core'

import ContainerFerias from '../components/ContainerFerias'
import SimpleLabelValue from '../components/SimpleLabelValue'

interface InformacoesComplementaresProps {
  data: {
    nrDiasConcedidos: number
    nrDiasDireito: number
    totalFaltas: number
    saldoDias: number
  }
}

export default function InformacoesComplementares(props: InformacoesComplementaresProps) {
  const { data } = props
  const { nrDiasDireito, totalFaltas, nrDiasConcedidos, saldoDias } = data

  return (
    <ContainerFerias title="Outras Informações">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SimpleLabelValue
            label="Dias de Direito:"
            value={nrDiasDireito}
            color="success"
            display="flex"
          />
        </Grid>
        <Grid item xs={12}>
          <SimpleLabelValue label="Dias Concedidos:" value={nrDiasConcedidos} display="flex" />
        </Grid>
        <Grid item xs={12}>
          <SimpleLabelValue
            label="Total de Faltas:"
            value={totalFaltas}
            color="error"
            display="flex"
          />
        </Grid>
        <Grid item xs={12}>
          <SimpleLabelValue
            label="Saldo de Dias:"
            value={saldoDias}
            color="primary"
            display="flex"
          />
        </Grid>
      </Grid>
    </ContainerFerias>
  )
}
