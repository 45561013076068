import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterConfiguracoes'
import { notifySuccess } from '~/utils/notification'

import { RelatorioAdmissionalConfiguracao } from './dtos/RelatorioAdmissionalConfiguracao'

export function useSalvarConfiguracoes() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest(data: RelatorioAdmissionalConfiguracao[]) {
    await api.post('/RelatorioAdmissional/SalvarConfiguracoes', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Configurações processadas com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
