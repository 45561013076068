const NaoIncide_0 = 0
const Inclusao_1 = 1
const Alteracao_2 = 2

export const indModificacaoeSocialvalues = [
  { value: NaoIncide_0, name: '0 - Não Incide' },
  { value: Inclusao_1, name: '1 - Inclusão' },
  { value: Alteracao_2, name: '2 - Alteração' },
]
export const indModificacaoeSocialConsts = {
  NaoIncide_0,
  Inclusao_1,
  Alteracao_2,
}
