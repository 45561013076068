import React, { useState, useCallback, useEffect } from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { CheckList } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'

import { CodigoIncidenciaCPValues } from '~/@types/enums/CodigoIncidenciaCPEnum'
import { CodigoIncidenciaIRValues } from '~/@types/enums/CodigoIncidenciaIREnum'
import { CodigoIncidenciaFGValues } from '~/@types/enums/CodigoIncidenciaFGEnum'

const useStyles = makeStyles((theme) => ({
  rootConfigs: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  contentConfigs: {
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
  },
  rootItem: {
    display: 'flex',
    '&:hover': {
      backgroundColor: '#ffffde',
    },
  },
  labelItem: {
    width: '50%',
  },
  dataItem: {
    width: '50%',
  },
  heightPaper: {
    height: '100%',
  },
}))

function ItemConfig(props) {
  const { label, data } = props

  const classes = useStyles()

  return (
    <Box className={classes.rootItem}>
      <Box className={classes.labelItem}>{label || ''}</Box>
      <Box className={classes.dataItem}>{data || ''}</Box>
    </Box>
  )
}

const FormReplicar = (props) => {
  const { isOpen, onClose, eventoEmpregador, dtInicioValidade, onAfterSubmitForm } = props
  const [empregadores, setEmpregadores] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [checked, setChecked] = useState([])

  const [incidencias, setIncidencias] = useState({
    codigoIncidenciaCP: { value: '', name: '' },
    codigoIncidenciaIR: { value: '', name: '' },
    codigoIncidenciaFG: { value: '', name: '' },
  })

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  useEffect(() => {
    const getEmpregadores = async () => {
      setLoading(true)
      try {
        const response = await api.get('/EventoEmpregador/GetByEventoEmpregador', {
          params: {
            eventoId: eventoEmpregador?.evento?.id,
          },
        })
        setEmpregadores(response.data.data)
        setChecked([])
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    function getIncidencias() {
      const codigoIncidenciaCP = CodigoIncidenciaCPValues.find(
        (item) => item.value === eventoEmpregador.codigoIncidenciaCP,
      )
      const codigoIncidenciaFG = CodigoIncidenciaFGValues.find(
        (item) => item.value === eventoEmpregador.codigoIncidenciaFG,
      )
      const codigoIncidenciaIR = CodigoIncidenciaIRValues.find(
        (item) => item.value === eventoEmpregador.codigoIncidenciaIR,
      )
      setIncidencias({
        codigoIncidenciaCP,
        codigoIncidenciaFG,
        codigoIncidenciaIR,
      })
    }
    if (!eventoEmpregador?.id) return
    getEmpregadores()
    getIncidencias()
  }, [eventoEmpregador])

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)
    try {
      if (checked.length === 0) throw new Error('Informe pelo menos um Empregador')
      const ids = checked.map((item) => item.id)
      await api.post('/EventoEmpregador/ReplicarConfiguracaoEvento', ids, {
        params: {
          eventoEmpregadorId: eventoEmpregador?.id,
          dtInicioValidade,
        },
      })
      notification.success('Registros atualizados com sucesso.')
      onAfterSubmitForm('insert')
      onClose()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setSubmitting(false)
    //eslint-disable-next-line
  }, [checked, onAfterSubmitForm, onClose, eventoEmpregador, dtInicioValidade])

  return (
    <ActionDialog
      title="Replicar Evento"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'xs',
        fullWidth: true,
        classes: { paper: classes.heightPaper },
      }}
    >
      <Typography>
        Selecione os Empregadores que deseja replicar as configurações do Evento{' '}
        <strong>
          {eventoEmpregador?.evento?.codigo || ''} - {eventoEmpregador?.evento?.nome || ''}
        </strong>
      </Typography>

      <Box className={classes.rootConfigs}>
        Configurações:
        <Box className={classes.contentConfigs}>
          <ItemConfig
            label="Configurações Incidência CP"
            data={`${incidencias.codigoIncidenciaCP.value} - ${incidencias.codigoIncidenciaCP.name}`}
          />
          <ItemConfig
            label="Incidência IRRF"
            data={`${incidencias.codigoIncidenciaIR.value} - ${incidencias.codigoIncidenciaIR.name}`}
          />
          <ItemConfig
            label="Incidência FGTS"
            data={`${incidencias.codigoIncidenciaFG.value} - ${incidencias.codigoIncidenciaFG.name}`}
          />
        </Box>
      </Box>

      <CheckList
        options={empregadores}
        onChange={setChecked}
        renderPrimaryOption={(option) =>
          `${option?.empregador?.codigo} - ${option?.empregador?.nome}`
        }
        isLoading={isLoading}
      />
    </ActionDialog>
  )
}

export default FormReplicar
