import { convertToArrayDescription } from '~/utils/utils'

export enum IndEventoEnum {
  Vencimento_1 = 1,
  Desconto_2 = 2,
  Informativo_3 = 3,
  InformativoDedutora_4 = 4,
}

export const IndEventoLabels = {
  [IndEventoEnum.Vencimento_1]: 'Vencimento',
  [IndEventoEnum.Desconto_2]: 'Desconto',
  [IndEventoEnum.Informativo_3]: 'Informativo',
  [IndEventoEnum.InformativoDedutora_4]: 'Informativo Dedutora',
}

export const IndEventoValues = convertToArrayDescription(IndEventoEnum, IndEventoLabels)
