import MUIAutoComplete from '../MUIAutoComplete'

import PropType from 'prop-types'
import useModeloPlanilhaImportacao from '~/hooks/queries/ModeloPlanilhaImportacao/useModeloPlanilhaImportacao'

export default function AutoCompleteModeloPlanilha(props) {
  const { ...rest } = props

  const { data: _data, isLoading: _isLoading, isFetching, refetch } = useModeloPlanilhaImportacao()
  const options = _data || []
  const isLoading = _isLoading || isFetching

  const renderOption = (option) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Modelo de Planilha"
      optionId="id"
      {...rest}
    />
  )
}

AutoCompleteModeloPlanilha.propTypes = {
  onChange: PropType.func,
  value: PropType.any,
  validationErrors: PropType.any,
  empregadorId: PropType.string,
  name: PropType.string,
  optionId: PropType.string,
  required: PropType.bool,
  disabled: PropType.bool,
}
