import { ToolsDataTable } from '~/components'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import useUtils from '~/hooks/useUtils'
import { formatTelefone } from '~/utils/utils'

export default function Table(props) {
  const { data: _data, isLoading, query, rowsSelecteds, setRowsSelected } = props
  const { formatCurrency } = useUtils()

  const data = _data.map((dt) => ({
    ...dt,
    cpf: dt?.cpf ? formatToCPFOrCNPJ(dt.cpf) : '',
    bancoNome: dt?.contaCorrente?.bancoAgencia?.nome || '',
    agencia: dt?.contaCorrente?.bancoAgencia?.numero || '',
    contaCorrente: dt?.contaCorrente?.contaCorrente
      ? `${dt?.contaCorrente?.contaCorrente}-${dt?.contaCorrente?.dvContaCorrente}`
      : '',
    vrLiquido: dt?.vrLiquido ? formatCurrency(dt.vrLiquido) : '',
    descricaoConta: dt?.contaCorrente?.descricaoIndContaBancaria || '',
    telefone: formatTelefone(dt?.telefone || ''),
  }))

  const columns = [
    {
      label: 'Matrícula',
      name: 'matricula',
    },
    {
      label: 'Nome',
      name: 'nome',
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
          },
        }),
      },
    },
    {
      label: 'CPF',
      name: 'cpf',
      options: {
        setCellProps: () => ({
          style: {
            minWidth: '120px',
          },
        }),
      },
    },
    {
      label: 'Chave PIX',
      name: 'chavePix',
    },
    {
      label: 'Telefone',
      name: 'telefone',
    },
    {
      label: 'Banco/Nome',
      name: 'bancoNome',
    },
    {
      label: 'Agência',
      name: 'agencia',
    },
    {
      label: 'Conta corrente',
      name: 'contaCorrente',
    },
    {
      label: 'Tipo de Conta',
      name: 'descricaoConta',
      options: {
        setCellProps: () => ({
          style: {
            minWidth: '115px',
          },
        }),
      },
    },
    {
      label: 'Lotação/Nome',
      name: 'lotacaoNome',
    },
    {
      label: 'Líquido',
      name: 'vrLiquido',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      isLoading={isLoading}
      columns={columns}
      sherlock={{
        query,
        columns: ['cpf', 'matricula', 'nome'],
      }}
      optionsSelectable={{
        type: 'multiple',
        rowsSelected: rowsSelecteds,
        onRowSelected: setRowsSelected,
      }}
    />
  )
}
