export enum CondicaoAmbienteLocalAmbienteEnum {
  EstabelecimentoProprioEmpregador = 1,
  EstabelecimentoTerceiros = 2,
}

export const CondicaoAmbienteLocalAmbienteLabels = {
  [CondicaoAmbienteLocalAmbienteEnum.EstabelecimentoProprioEmpregador]:
    'Estabelecimento do próprio Empregador',
  [CondicaoAmbienteLocalAmbienteEnum.EstabelecimentoTerceiros]: 'Estabelecimento de terceiros',
}
