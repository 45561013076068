import { useState, useEffect, useCallback, memo } from 'react'

import { PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'
import { Box, LinearProgress } from '@material-ui/core'

import { TreeView, ConfirmDeleteDialog } from '~/components'

import Table from './components/Table'
import Form from './components/Form'
import FormEventoEmpregador from './components/FormEventoEmpregador'

import useHandleError from '~/hooks/useHandleError'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useGerarAuditoria } from '~/hooks/queries/EventoEmpregador/useGerarAuditoria'

import api from '~/services/api-pessoal'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { TipoInscricaoLabels } from '~/@types/enums/TipoInscricaoEnum'
import { limparString } from '~/utils/utils'

const HEADER_HEIGHT = '70px'

const MemoTable = memo(Table)

const EventoEmpregador = () => {
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [form, setForm] = useState({ isOpen: false })
  const [formEventoEmpregador, setFormEventoEmpregador] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [query, setQuery] = useState('')
  const [empregador, setEmpregador] = useState({})

  const [empregadores, setEmpregadores] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const { extractErrorMessage } = useHandleError()
  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { mutateAsync: mutateAsyncGerarAuditoria, isLoading: isLoadingGerarAuditoria } =
    useGerarAuditoria()

  const getEmpregadores = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get('/EmpregadorConsulta')
      setEmpregadores(response.data.data)
    } catch (error) {
      const msg = extractErrorMessage(error)
      setError(msg)
    }
    setLoading(false)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getEmpregadores()
  }, [getEmpregadores])

  const getCollectionByEmpregador = useCallback(async (empregador) => {
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get('/EventoEmpregador/GetByEmpregador', {
        params: { empregadorId: empregador.id },
      })
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
        }))
        setEmpregador(empregador)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    //eslint-disable-next-line
  }, [])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleOpenForm = useCallback(() => {
    setForm({ isOpen: true })
  }, [])

  const handleClickItem = (event, value) => {
    async function handleGerarAuditoria(id) {
      await mutateAsyncGerarAuditoria({ eventoEmpregadorId: id })
    }

    const handleClickEditItem = (id) => {
      const { itens } = collection
      const item = itens.find((i) => i.id === id)
      handleOpenFormEventoEmpregador(item)
    }

    const handleClickDeleteItem = (id) => {
      setConfirmDeleteDialog((oldState) => ({
        ...oldState,
        isOpen: true,
        id,
      }))
    }

    const functions = {
      'gerar-auditoria': handleGerarAuditoria,
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/EventoEmpregador/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [handleOpenForm])

  const handleCloseForm = () => {
    setForm({ isOpen: false })
  }

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = () => {
        handleCloseForm()
        handleCloseFormEventoEmpregador()
        if (empregador?.id) getCollectionByEmpregador(empregador)
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseFormEventoEmpregador()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [empregador, getCollectionByEmpregador, collection],
  )

  const handleOpenFormEventoEmpregador = (data) => {
    setFormEventoEmpregador({
      isOpen: true,
      data,
    })
  }

  const handleCloseFormEventoEmpregador = () => {
    setFormEventoEmpregador({
      isOpen: false,
      data: {},
    })
  }

  return (
    <TreeView
      onSelectItem={(event, item) => getCollectionByEmpregador(item)}
      data={[
        {
          groupName: 'Empregadores',
          data: empregadores,
        },
      ]}
      isLoading={isLoading}
      isLoadingOnClick={collection.isLoading}
      searchBy={['codigo', 'nome']}
      filterSearch={(q, dta) => {
        return dta
          .map((d) => ({
            ...d,
            data: d.data.filter((item) => {
              const isSearchNrInscricao = q.includes('.')
              const isSearchCodigo = /^\d+$/.test(q)

              let field = 'nome'

              if (isSearchNrInscricao) {
                field = 'nrInscricao'
              } else {
                if (isSearchCodigo) {
                  field = 'codigo'
                }
              }

              const value = limparString(q).toLowerCase()

              return limparString(`${item[field]}`).toLowerCase().includes(value)
            }),
          }))
          .filter((d) => d.data.length > 0)
      }}
      renderOption={(option) => `${option?.codigo} - ${option?.nome}`}
      renderSecondaryOption={(option) =>
        `${TipoInscricaoLabels[option.tipoInscricao]}: ${formatToCPFOrCNPJ(option?.nrInscricao)}`
      }
      error={error}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader
          title="Eventos Empregador"
          subtitle={empregador?.id ? `${empregador?.codigo} - ${empregador?.nome}` : '-'}
        >
          <ButtonBox>
            {empregador?.id && <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />}
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Relacionar eventos
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Box height={4}>{isLoadingGerarAuditoria && <LinearProgress />}</Box>
        <MemoTable
          data={collection.itens}
          isLoading={collection.isLoading}
          isFetching={isLoadingGerarAuditoria}
          query={query}
          onItemClick={handleClickItem}
        />
      </Box>

      <Form
        isOpen={form.isOpen}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />
      <FormEventoEmpregador
        isOpen={formEventoEmpregador.isOpen}
        data={formEventoEmpregador.data}
        onClose={handleCloseFormEventoEmpregador}
        onAfterSubmitForm={handleAfterSubmitForm}
      />
      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </TreeView>
  )
}

export default EventoEmpregador
