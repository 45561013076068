import React, { useMemo } from 'react'

import { Box, IconButton, Tooltip } from '@material-ui/core'
import { DataTable, ButtonBox } from 'mio-library-ui'

import { Ativo } from '~/components/Displays'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import useUtils from '~/hooks/useUtils'

const feriadosNacionais = ['0101', '2104', '0105', '0709', '1210', '0211', '1511', '2512']

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const { formatDiaMes } = useUtils()

  const data = _data.map((item) => {
    return {
      ...item,
      dtFeriado: formatDiaMes(item?.dtFeriado),
      cidade: item?.cidade?.nome ? item.cidade.nome : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtFeriado',
        label: 'Data Feriado',
      },
      {
        name: 'descricao',
        label: 'Descrição',
      },
      {
        name: 'cidade',
        label: 'Cidade',
      },
      {
        name: 'isFeriadoNacional',
        label: 'Feriado Nacional',
        options: {
          customBodyRender: (value) => (value ? <Ativo ativo={value} /> : ''),
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            const feriado = _data?.find((data) => data?.id === value)
            const isFeriadoNacional = feriadosNacionais?.find(
              (feriadoNacional) => feriadoNacional === feriado?.dtFeriado,
            )

            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <Tooltip arrow title={isFeriadoNacional ? 'Este feriado não pode ser editado' : ''}>
                  <Box>
                    <IconButton
                      size="small"
                      title="Editar este registro"
                      color="primary"
                      aria-label="Editar"
                      disabled={!!isFeriadoNacional}
                      onClick={() => {
                        onItemClick('edit', value)
                      }}
                    >
                      <EditIcon
                        fontSize="small"
                        color={isFeriadoNacional ? 'disabled' : 'primary'}
                      />
                    </IconButton>
                  </Box>
                </Tooltip>
                <Tooltip
                  arrow
                  title={isFeriadoNacional ? 'Este feriado não pode ser excluído' : ''}
                >
                  <Box>
                    <IconButton
                      size="small"
                      title="Deletar este registro"
                      color="secondary"
                      aria-label="Deletar"
                      disabled={!!isFeriadoNacional}
                      onClick={() => {
                        onItemClick('delete', value)
                      }}
                    >
                      <DeleteIcon
                        fontSize="small"
                        color={isFeriadoNacional ? 'disabled' : 'primary'}
                      />
                    </IconButton>
                  </Box>
                </Tooltip>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [_data, onItemClick],
  )

  return (
    <DataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['descricao', 'cidade'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
