import { Dispatch, SetStateAction, useState } from 'react'

import { Checkbox, Theme, useMediaQuery } from '@material-ui/core'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
} from '@material-ui/icons'
import { ToolsDataTable } from '~/components'

import TableEventosAnalitico from './TableEventosAnalitico'

import { ESocialGrupoEventoTransmitirDTO } from '~/hooks/queries/ESocial/useObterEventosTransmissaoEnvio'
import { MUIDataTableCheckboxProps, MUIDataTableColumnDef } from 'mui-datatables'

interface TableEventosProps {
  data: ESocialGrupoEventoTransmitirDTO[]
  auditoriasIds: string[]
  setAuditoriasIds: Dispatch<SetStateAction<string[]>>
}

export default function TableEventos(props: TableEventosProps) {
  const { data, auditoriasIds, setAuditoriasIds } = props

  const [rowsExpanded, setRowExpanded] = useState<number[]>([])

  const isXSmallWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Descrição',
      name: 'grupoEventoDescricao',
    },
    {
      label: 'Quantidade',
      name: 'totalEventos',
      options: {
        setCellProps: () => ({
          style: {
            width: isXSmallWidth ? undefined : '73px',
          },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      pagination={false}
      showHeader={false}
      disableAutoHeight
      components={{
        Checkbox: (props: MUIDataTableCheckboxProps) => {
          const indexRow = props['data-index'] || 0
          const grupoCurrent = data[indexRow]
          if (!grupoCurrent) return <></>
          const auditoriasIdsGrupo: string[] = []
          grupoCurrent.eventos.forEach((evento) => auditoriasIdsGrupo.push(evento.auditoriaId))
          const checked = auditoriasIdsGrupo.every((auditoriaGrupoId) =>
            auditoriasIds.includes(auditoriaGrupoId),
          )
          const indeterminate = auditoriasIds.some((d) => auditoriasIdsGrupo.includes(d))
          const fontSize = 22
          return (
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon style={{ fontSize }} />}
              checkedIcon={<CheckBoxIcon style={{ fontSize }} />}
              indeterminateIcon={<IndeterminateCheckBoxIcon style={{ fontSize }} />}
              size="small"
              checked={checked}
              indeterminate={checked ? undefined : indeterminate}
              onChange={() =>
                setAuditoriasIds((prev) => {
                  if (indeterminate) {
                    return prev.filter((d) => !auditoriasIdsGrupo.includes(d))
                  } else {
                    return [...prev, ...auditoriasIdsGrupo]
                  }
                })
              }
            />
          )
        },
      }}
      optionsExpandable={{
        type: 'single',
        expandedChildren: (index: number) => {
          const eventoCurrent = data[index]
          return (
            <TableEventosAnalitico
              data={eventoCurrent.eventos}
              auditoriasIds={auditoriasIds}
              setAuditoriasIds={setAuditoriasIds}
            />
          )
        },
        onRowExpanded: setRowExpanded,
        rowsExpanded: rowsExpanded,
      }}
      options={{
        selectableRows: 'multiple',
      }}
    />
  )
}
