import { useState } from 'react'

import { Box, Grid, Paper, Typography, useTheme } from '@material-ui/core'

import { Container } from '~/components'
import VirtualizedMenu, { VirtualizedMenuData } from '~/components/VirtualizedMenu'

import Content from './Content'

import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'
import useAmbiente from '~/hooks/useAmbiente'

import { AutoCompleteGrupo } from '~/components/AutoComplete'
import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'

export default function GerenciadorDocumentos() {
  const [estabelecimentoIdSelected, setEstabelecimentoIdSelected] = useState<string | null>(null)
  const [grupoId, setGrupoId] = useState<string>()

  const { anoMes } = useAmbiente()
  const {
    data: _d,
    isLoading: isLoadingMenu,
    isFetching: isFetchingMenu,
  } = useEstabelecimentoObterFiltrado({
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
    grupoId,
    anoMes,
  })
  const _data = _d || []
  const dataMenu = _data
    ? _data.map<VirtualizedMenuData>((d) => ({
        id: d.id,
        label: d.nome,
        children: [],
      }))
    : []
  const theme = useTheme()

  const estabelecimento = _data.find((d) => d.id === estabelecimentoIdSelected)

  return (
    <Container>
      <Grid container spacing={1} style={{ height: '100%' }}>
        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
          <Box display="flex" flexDirection="column" height="100%" gridGap={theme.spacing(0.5)}>
            <Paper>
              <Box padding={1}>
                <AutoCompleteGrupo
                  value={grupoId || null}
                  onChange={(e, obj) => setGrupoId(obj?.id || null)}
                />
              </Box>
            </Paper>
            <Box flex={1}>
              <VirtualizedMenu
                data={dataMenu}
                onChangeParent={setEstabelecimentoIdSelected}
                parentSelected={estabelecimentoIdSelected}
                isLoading={isLoadingMenu}
                isFetching={isFetchingMenu}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={7} md={8} lg={9} xl={9}>
          {estabelecimento ? (
            <Content estabelecimento={estabelecimento} />
          ) : (
            <Typography>Selecione um Estabelecimento</Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
