import React, { useState, useEffect } from 'react'

import { Box, IconButton, LinearProgress, Typography } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'

import {
  ActionDialog,
  ConfirmDeleteDialog,
  TreeView,
  PageHeader,
  Button,
  Finder,
  PDFViewer,
} from '~/components'

import FlowCreate from './FlowCreate'
import Table from './Table'

import { useCondicaoAmbienteTrabalhoDelete } from '~/hooks/queries/CondicaoAmbienteTrabalho/useCondicaoAmbienteTrabalhoDelete'
import { obterCondicaoAmbienteTrabalhoCompleto } from '~/hooks/queries/CondicaoAmbienteTrabalho/obterCondicaoAmbienteTrabalhoCompleto'

import { VinculoMenuItem, useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useHistory } from 'react-router-dom'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { CondicaoAmbienteTrabalhoSaveDTOPartial } from '~/hooks/queries/CondicaoAmbienteTrabalho/CondicaoAmbienteTrabalhoSaveDTO'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const HEADER_HEIGHT = '70px'

export default function CondicaoAmbienteTrabalhoNew() {
  const [query, setQuery] = useState('')
  const [vinculo, setVinculo] = useState<VinculoMenuItem | null>(null)
  const [isLoadingEdit, setLoadingEdit] = useState(false)

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closeFlowCreate,
    data: dataFlowCreate,
    isOpen: isOpenFlowCreate,
    open: openFlowCreate,
  } = useDialog<{
    data: CondicaoAmbienteTrabalhoSaveDTOPartial
    isRetificar?: boolean
  } | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog('')
  const dialogNotification = useDialogNotification()

  const { mutateAsync, isLoading: isLoadingDelete } = useCondicaoAmbienteTrabalhoDelete()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Cooperado_5,
    VinculoTipoEnum.Empregador_2,
    VinculoTipoEnum.Estagiario_3,
  ])

  const history = useHistory()

  useEffect(() => {
    setVinculo(null)
  }, [anoMes, estabelecimento])

  async function handleClickAdd() {
    openFlowCreate({
      data: {
        condicaoAmbienteTrabalho: {
          vinculoId: vinculo?.vinculoId as string,
          tipoInscricao: TipoInscricaoEnum.CNPJ_1,
          localAmbiente: '1',
          nrInscricao: estabelecimento.nrInscricao,
        },
        responsaveis: [],
        agentesNocivos: [],
      },
    })
  }

  async function handleDeleteItem() {
    if (!dataConfirmDelete) return
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  const handleClickItem = (event: 'edit' | 'delete' | 'print', id: string) => {
    const handleClickEditItem = async () => {
      setLoadingEdit(true)
      try {
        const response = await obterCondicaoAmbienteTrabalhoCompleto(id)
        if (!response) return

        if (response.condicaoAmbienteTrabalho.reciboEsocial)
          return dialogNotification.warning({
            descriptions: [
              'Esse evento já foi enviado para o eSocial, deseja fazer uma retificação?',
            ],
            labelOnCancel: 'Não',
            labelOnConfirm: 'Sim',
            onConfirm: () =>
              openFlowCreate({
                data: response,
                isRetificar: true,
              }),
            onCancel: () =>
              openFlowCreate({
                data: response,
                isRetificar: false,
              }),
          })
        openFlowCreate({ data: response, isRetificar: undefined })
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingEdit(false)
      }
    }

    const handleClickDeleteItem = () => {
      openConfirmDelete(id)
    }

    function handlePrintItem() {
      openPDFViewer(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
      print: handlePrintItem,
    }
    functions[event]()
  }

  function handleChangeOldVersion() {
    history.push({
      pathname: '/condicao-ambiente-trabalho-old',
    })
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e: FixLater, vinculo: VinculoMenuItem) => setVinculo(vinculo)}
      searchBy="vinculoNome"
      renderOption={(option: VinculoMenuItem) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Condição Ambiente de Trabalho" subtitle={vinculo?.vinculoNome || '-'}>
          {vinculo?.vinculoId && (
            <ButtonBox>
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
              <IconButton
                size="small"
                onClick={handleChangeOldVersion}
                title="Retornar para versão antiga"
              >
                <ErrorOutlineIcon color="primary" />
              </IconButton>
              <Button size="small" color="primary" onClick={handleClickAdd} variant="contained">
                Adicionar
              </Button>
            </ButtonBox>
          )}
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        {isLoadingEdit ? <LinearProgress /> : <Box height={4} />}
        {vinculo ? (
          <Table query={query} onItemClick={handleClickItem} vinculoId={vinculo.vinculoId} />
        ) : (
          <Typography>Selecione um Funcionário</Typography>
        )}
      </Box>

      {isOpenFlowCreate && dataFlowCreate ? (
        <ActionDialog
          isOpen={isOpenFlowCreate}
          onClose={closeFlowCreate}
          title="Cadastro - Condição Ambiente de Trabalho"
          subtitle={vinculo?.vinculoNome || '-'}
          dialogProps={{ maxWidth: 'lg', fullWidth: true }}
          customActions={<></>}
        >
          <FlowCreate
            onCancel={closeFlowCreate}
            data={dataFlowCreate.data}
            isRetificar={dataFlowCreate.isRetificar}
          />
        </ActionDialog>
      ) : (
        <></>
      )}

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isLoadingDelete}
        onCancel={closeConfirmDelete}
        onConfirm={handleDeleteItem}
        description="Você quer realmente excluir este registro? As alterações que foram feitas não serão desfeitas!"
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        title="Condição Ambiente de Trabalho"
        reportKey="CondicaoAmbienteTrabalho"
        axiosConfig={{
          method: 'get',
          url: '/CondicaoAmbienteTrabalho/ObterRelatorioCondicaoAmbienteTrabalho',
          params: {
            condicaoAmbienteTrabalhoId: dataPDFViewer,
          },
        }}
      />
    </TreeView>
  )
}
