import React, { useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Tooltip } from '@material-ui/core'

import {
  ContentDivider,
  CurrencyTextField,
  SimpleSelect,
  TextField,
  Checkbox,
  Button,
} from '~/components'
import { AutoCompleteAgenteNocivo, MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import {
  AgenteNocivoTipoAvaliacaoEnum,
  AgenteNocivoTipoAvaliacaoValues,
} from '~/@types/enums/AgenteNocivoTipoAvaliacaoEnum'
import { AgenteNocivo } from '~/hooks/queries/AgenteNocivo/AgenteNocivo'
import { AgenteNocivoUnidadeMedidaValues } from '~/@types/enums/AgenteNocivoUnidadeMedidaEnum'
import { EpiEpcAplicavelEnum, EpiEpcAplicavelValues } from '~/@types/enums/EpiEpcAplicavelEnum'
import { SimNaoEnum, SimNaoValues } from '~/@types/enums/SimNaoEnum'
import { useStepperContext } from '~/components/StepperForm'
import { MainData } from '..'
import { ButtonBox } from 'mio-library-ui'

const schema = yup.object().shape({
  agenteNocivoId: yup.string().required('Informe o Agente Nocivo').nullable(),
  tipoAvaliacao: yup.string().required('Informe o Tipo de Avaliação'),
  intensidadeConcentracao: yup
    .string()
    .when(['tipoAvaliacao'], (tipoAvaliacao, schema) => {
      if (parseInt(tipoAvaliacao) === AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1)
        return schema.required('Informe a Intensidade da Concentração').nullable()
    })
    .nullable(),
  tecnicaMedicao: yup
    .string()
    .when(['tipoAvaliacao'], (tipoAvaliacao, schema) => {
      if (parseInt(tipoAvaliacao) === AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1)
        return schema.required('Informe a Técnica de Medição').nullable()
    })
    .nullable(),
  limiteTolerancia: yup
    .string()
    .when(['tipoAvaliacao', 'agenteNocivoId'], (tipoAvaliacao, agenteNocivoId, schema) => {
      if (
        parseInt(tipoAvaliacao) === AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1 &&
        (agenteNocivoId === '01.18.001' || agenteNocivoId === '02.01.014')
      )
        return schema.required('Informe o Limite da Tolerância').nullable()
    })
    .nullable(),
  unidadeMedida: yup
    .string()
    .when(['tipoAvaliacao'], (tipoAvaliacao, schema, { value: unidadeMedida }) => {
      const parsedTipoAvaliacao = parseInt(tipoAvaliacao)
      const parsedUnidadeMedida = parseInt(unidadeMedida)

      if (
        parsedUnidadeMedida === 0 &&
        parsedTipoAvaliacao === AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1
      ) {
        return schema.test(
          'UnidadeMedida-validator',
          'A Unidade de Medida deve possuir um valor válido',
          () => parsedUnidadeMedida !== 0,
        )
      }

      if (parsedTipoAvaliacao === AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1) {
        return schema.required('Informe a Unidade de Medida')
      }

      return schema
    })
    .nullable(),
})

export default function Form() {
  const { mainData, onCancel, onNext } = useStepperContext<MainData>()
  const [data, setData] = useState(mainData.dataForm)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    onNext({
      ...mainData,
      dataForm: data,
    })
  }

  function handleChangeAgenteNocivo(_: any, agenteNocivo: AgenteNocivo) {
    const agenteNocivoId = agenteNocivo?.id || ''
    setData((oldState) => ({
      ...oldState,
      agenteNocivoId,
      agenteNocivo,
      limiteTolerancia: null,
    }))

    if (agenteNocivoId === '09.01.001') {
      setData((oldState) => ({
        ...oldState,
        tipoAvaliacao: AgenteNocivoTipoAvaliacaoEnum.NaoPreencher,
      }))
    }
  }

  const isHaveAgenteNocivo = data?.agenteNocivoId !== '09.01.001'

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <Tooltip
          arrow
          placement="top-start"
          title="Agente(s) nocivo(s) ao(s) qual(is) o trabalhador está
            exposto."
        >
          <Box>
            <ContentDivider title="Agente(s) nocivo(s)" />
          </Box>
        </Tooltip>
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <AutoCompleteAgenteNocivo
          required
          validationErrors={validationErrors}
          name="agenteNocivoId"
          value={data?.agenteNocivo || null}
          onChange={handleChangeAgenteNocivo}
        />
      </Grid>
      {isHaveAgenteNocivo && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <MUIAutoComplete
                name="tipoAvaliacao"
                label="Tipo de Avaliação"
                options={AgenteNocivoTipoAvaliacaoValues}
                optionId="value"
                renderOption={(option) => option.name}
                required={data?.agenteNocivoId !== '09.01.001'}
                disabled={data?.agenteNocivoId === '09.01.001'}
                validationErrors={validationErrors}
                value={data.tipoAvaliacao}
                onChange={(e, obj) => {
                  const tipoAvaliacao = obj?.value || AgenteNocivoTipoAvaliacaoEnum.NaoPreencher
                  setData((oldState) => ({
                    ...oldState,
                    tipoAvaliacao,
                    intensidadeConcentracao: null,
                    limiteTolerancia: null,
                    tecnicaMedicao: '',
                    unidadeMedida: 0,
                  }))
                }}
              />
            </Grid>
            <Grid xl={3} lg={3} md={3} sm={6} xs={6} item>
              <CurrencyTextField
                label="Intensidade da Concentração"
                maximumValue="9999999999"
                required={
                  data?.tipoAvaliacao === AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1
                    ? true
                    : false
                }
                disabled={
                  data?.tipoAvaliacao !== AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1
                }
                validationErrors={validationErrors}
                name="intensidadeConcentracao"
                value={data?.intensidadeConcentracao || ''}
                onChange={(e, value) => {
                  const intensidadeConcentracao = value
                  setData((oldState) => ({
                    ...oldState,
                    intensidadeConcentracao,
                  }))
                }}
              />
            </Grid>
            <Grid xl={3} lg={3} md={3} sm={6} xs={6} item>
              <TextField
                fullWidth
                onlyNumber
                size="small"
                inputProps={{ maxLength: 2 }}
                label="Limite da Tolerância"
                required={
                  data?.tipoAvaliacao === AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1 &&
                  (data?.agenteNocivoId === '01.18.001' || data?.agenteNocivoId === '02.01.014')
                    ? true
                    : false
                }
                disabled={
                  data?.tipoAvaliacao !== AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1 ||
                  (data?.agenteNocivoId !== '01.18.001' && data?.agenteNocivoId !== '02.01.014')
                }
                validationErrors={validationErrors}
                name="limiteTolerancia"
                value={data?.limiteTolerancia || ''}
                onChange={(e) => {
                  const limiteTolerancia = parseInt(e?.target?.value || '0')
                  setData((oldState) => ({
                    ...oldState,
                    limiteTolerancia,
                  }))
                }}
              />
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={6} xs={12} item>
              <Tooltip
                arrow
                placement="bottom-start"
                title="Técnica utilizada para medição da intensidade ou
              concentração."
              >
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    inputProps={{ maxLength: 40 }}
                    label="Técnica de Medição"
                    required={
                      data?.tipoAvaliacao === AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1
                        ? true
                        : false
                    }
                    disabled={
                      data?.tipoAvaliacao !== AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1
                    }
                    validationErrors={validationErrors}
                    name="tecnicaMedicao"
                    value={data?.tecnicaMedicao || ''}
                    onChange={(e) => {
                      const tecnicaMedicao = e?.target?.value || ''
                      setData((oldState) => ({
                        ...oldState,
                        tecnicaMedicao,
                      }))
                    }}
                  />
                </Box>
              </Tooltip>
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <MUIAutoComplete
                options={AgenteNocivoUnidadeMedidaValues}
                optionId="value"
                renderOption={(option) => option.name}
                label="Unidade de Medida"
                required={
                  data?.tipoAvaliacao === AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1
                }
                disabled={
                  data?.tipoAvaliacao !== AgenteNocivoTipoAvaliacaoEnum.CriterioQuantitativo_1
                }
                validationErrors={validationErrors}
                name="unidadeMedida"
                value={data.unidadeMedida}
                onChange={(e, obj) => {
                  const unidadeMedida = obj ? obj.value : ''
                  setData((oldState) => ({
                    ...oldState,
                    unidadeMedida,
                  }))
                }}
              />
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <TextField
                label="Número Processo Judicial"
                validationErrors={validationErrors}
                name="nrProcessoJudicial"
                disabled={data.agenteNocivoId === '05.01.001' ? false : true}
                value={data.nrProcessoJudicial}
                onChange={(e) => {
                  const nrProcessoJudicial = e.target.value
                  setData((prev) => ({
                    ...prev,
                    nrProcessoJudicial,
                  }))
                }}
              />
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <Tooltip
                arrow
                placement="top-start"
                title="Informações relativas a Equipamentos de Proteção Coletiva
                - EPC e Equipamentos de Proteção Individual - EPI."
              >
                <Box>
                  <ContentDivider
                    top={1}
                    bottom={1}
                    title="Informações relativas a Equipamentos de Proteção"
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
              <MUIAutoComplete
                label="Utiliza EPC"
                value={data?.utilizaEPC}
                options={EpiEpcAplicavelValues}
                renderOption={(opt) => opt.name}
                optionId="value"
                onChange={(e, obj) => {
                  const utilizaEPC = obj?.value || EpiEpcAplicavelEnum.NaoSeAplica
                  setData((oldState) => ({
                    ...oldState,
                    utilizaEPC,
                  }))
                  if (utilizaEPC === EpiEpcAplicavelEnum.Implementa) {
                    setData((oldState) => ({
                      ...oldState,
                      isEficienciaEPC: true,
                    }))
                  } else {
                    setData((oldState) => ({
                      ...oldState,
                      isEficienciaEPC: false,
                    }))
                  }
                }}
              />
            </Grid>
            <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
              <SimpleSelect
                label="EPC são Eficazes"
                options={SimNaoValues}
                renderOption={(opt) => opt.name}
                optionId="value"
                disabled={data?.utilizaEPC !== EpiEpcAplicavelEnum.Implementa}
                value={data?.isEficienciaEPC ? SimNaoEnum.Sim : SimNaoEnum.Nao}
                onChange={(_, value: SimNaoEnum) => {
                  const isEficienciaEPC = value === SimNaoEnum.Sim
                  setData((oldState) => ({
                    ...oldState,
                    isEficienciaEPC,
                  }))
                }}
              />
            </Grid>
            <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
              <MUIAutoComplete
                label="Utiliza EPI"
                value={data?.utilizaEPI || EpiEpcAplicavelEnum.NaoSeAplica}
                options={EpiEpcAplicavelValues}
                renderOption={(opt) => opt.name}
                optionId="value"
                onChange={(e, obj) => {
                  const utilizaEPI = obj?.value || EpiEpcAplicavelEnum.NaoSeAplica
                  setData((oldState) => ({
                    ...oldState,
                    utilizaEPI,
                  }))

                  if (utilizaEPI === EpiEpcAplicavelEnum.Implementa) {
                    setData((oldState) => ({
                      ...oldState,
                      isEficienciaEPI: true,
                    }))
                  } else {
                    setData((oldState) => ({
                      ...oldState,
                      isEficienciaEPI: false,
                    }))
                  }
                }}
              />
            </Grid>
            <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
              <SimpleSelect
                label="EPI são Eficazes"
                options={SimNaoValues}
                renderOption={(opt) => opt.name}
                optionId="value"
                disabled={data?.utilizaEPI !== EpiEpcAplicavelEnum.Implementa}
                value={data?.isEficienciaEPI ? SimNaoEnum.Sim : SimNaoEnum.Nao}
                onChange={(_, value: SimNaoEnum) => {
                  const isEficienciaEPI = value === SimNaoEnum.Sim
                  setData((oldState) => ({
                    ...oldState,
                    isEficienciaEPI,
                  }))
                }}
              />
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <Tooltip
                arrow
                placement="top-start"
                title="Requisitos da Norma Regulamentadora 06 - NR-06 e da
            Norma Regulamentadora 09 - NR-09 pelo(s) EPI(s)
            informado(s)."
              >
                <Box>
                  <ContentDivider top={1} title="Requisitos da Norma Regulamentadora" />
                </Box>
              </Tooltip>
            </Grid>

            <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
              <Tooltip
                arrow
                title="Foi tentada a implementação de medidas de proteção
            coletiva, de caráter administrativo ou de organização,
            optando-se pelo EPI por inviabilidade técnica, insuficiência
            ou interinidade, ou ainda em caráter complementar ou
            emergencial?"
              >
                <Checkbox
                  label="Medida de Proteção"
                  value={data?.isMedidaProtecao || false}
                  onChange={(e, value) => {
                    const isMedidaProtecao = value
                    setData({ ...data, isMedidaProtecao })
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
              <Tooltip
                arrow
                title="Foram observadas as condições de funcionamento do EPI
            ao longo do tempo, conforme especificação técnica do
            fabricante nacional ou importador, ajustadas às condições
            de campo?"
              >
                <Checkbox
                  label="Condição de Funcionamento"
                  value={data?.isCondicaoFuncionamento || false}
                  onChange={(e, value) => {
                    const isCondicaoFuncionamento = value
                    setData({ ...data, isCondicaoFuncionamento })
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
              <Tooltip
                arrow
                title="Foi observado o uso ininterrupto do EPI ao longo do 
            tempo, conforme especificação técnica do fabricante
            nacional ou importador, ajustadas às condições de campo?"
              >
                <Checkbox
                  label="Uso ininterrupto"
                  value={data?.isUsoIninterrupto || false}
                  onChange={(e, value) => {
                    const isUsoIninterrupto = value
                    setData({ ...data, isUsoIninterrupto })
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
              <Tooltip
                arrow
                title="Foi observado o prazo de validade do CA no momento da compra do EPI?"
              >
                <Checkbox
                  label="Prazo de Validade"
                  value={data?.isPrazoValidade || false}
                  onChange={(e, value) => {
                    const isPrazoValidade = value
                    setData({ ...data, isPrazoValidade })
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
              <Tooltip
                arrow
                title="É observada a periodicidade de troca definida pelo
            fabricante nacional ou importador e/ou programas
            ambientais, comprovada mediante recibo assinado pelo
            usuário em época própria?"
              >
                <Checkbox
                  label="Periodicidade Troca"
                  value={data?.isPeriodicidadeTroca || false}
                  onChange={(e, value) => {
                    const isPeriodicidadeTroca = value
                    setData({ ...data, isPeriodicidadeTroca })
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
              <Tooltip
                arrow
                title="É observada a higienização conforme orientação do
            fabricante nacional ou importador?"
              >
                <Checkbox
                  label="Higienização"
                  value={data?.isHigienizacao || false}
                  onChange={(e, value) => {
                    const isHigienizacao = value
                    setData({ ...data, isHigienizacao })
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
