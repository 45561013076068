import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ModeloIntegracao } from './dtos/ModeloIntegracao'

const query = '/ModeloIntegracao/ObterTodos'

export function useObterTodos() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ModeloIntegracao[] }>(query)
    return response.data.data
  }

  return useQuery([query], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
