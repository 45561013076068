export enum EventoIndReferenciaEnum {
  Mes = 0,
  Dia = 1,
  Hora = 2,
  Percentual = 3,
  Quantidade = 4,
  NaoSeAplica = 9,
}

export const EventoIndReferenciaLabels = {
  [EventoIndReferenciaEnum.Mes]: 'Mês',
  [EventoIndReferenciaEnum.Dia]: 'Dia',
  [EventoIndReferenciaEnum.Hora]: 'Hora',
  [EventoIndReferenciaEnum.Percentual]: 'Percentual',
  [EventoIndReferenciaEnum.Quantidade]: 'Quantidade',
  [EventoIndReferenciaEnum.NaoSeAplica]: 'Não se aplica',
}
