import { useState } from 'react'

import { Box } from '@material-ui/core'

import { PageHeader, PDFViewer, Button, Finder } from '~/components'

import { Table } from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const TITLE_PAGE = 'Ficha de Fornecimento e Controle de EPI'

export default function FichaFornecimentoEPI() {
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const [query, setQuery] = useState('')

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [
      VinculoTipoEnum.Funcionario_1,
      VinculoTipoEnum.Empregador_2,
      VinculoTipoEnum.Estagiario_3,
      VinculoTipoEnum.Autonomo_4,
      VinculoTipoEnum.Cooperado_5,
    ],
    estabelecimentoId: estabelecimento.id,
  })
  const data = _d || []

  function handlePrint() {
    const ids = rowsSelected.map((index) => data[index].id)
    openPDFViewer(ids)
  }

  function handleClosePDFViewer() {
    setRowsSelected([])
    closePDFViewer()
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={TITLE_PAGE}>
        <Box height={4}>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </Box>
      </PageHeader>

      <Table
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        onRowSelected={(indexs) => setRowsSelected(indexs)}
        rowsSelected={rowsSelected}
        query={query}
      />

      <Box width="100%" display="flex" justifyContent="flex-end" py={1}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handlePrint}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Imprimir
        </Button>
      </Box>
      <PDFViewer
        title={TITLE_PAGE}
        reportKey="FichaFornecimentoEPI"
        isOpen={isOpenPDFViewer}
        onClose={handleClosePDFViewer}
        isSalvarDocumento
        axiosConfig={{
          url: '/FichaFornecimentoEPI/ObterRelatorio',
          method: 'post',
          data: dataPDFViewer,
          params: {
            estabelecimentoId: estabelecimento?.id,
            anoMes,
          },
        }}
      />
    </Box>
  )
}
