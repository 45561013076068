import moment from 'moment'

import { ActionMenu, HeightDataTable } from '~/components'

import {
  IndMotivoAfastamentoEnum,
  IndMotivoAfastamentoValues,
} from '~/@types/enums/IndMotivoAfastamentoEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { useVinculoAfastamentoGetByVinculo } from '~/hooks/queries/VinculoAfastamento/useVinculoAfastamentoGetByVinculo'
import { VinculoAfastamento } from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'

function formatMotivoAfastamento(motivoAfastamento: IndMotivoAfastamentoEnum) {
  const motivo = IndMotivoAfastamentoValues.find((x) => x.value === motivoAfastamento)
  return motivo?.name || ''
}

export enum AcoesTabela {
  'EDIT',
  'DELETE',
  'INTERROMPER',
  'SALDO-FERIAS',
  'RETIFICAR',
  'DT-FIM',
  'MOTIVO-AFASTAMENTO',
}

interface TableProps {
  onItemClick: (event: AcoesTabela, vinculoAfastamento: VinculoAfastamento) => void
  vinculoId: string
}

export default function Table(props: TableProps) {
  const { onItemClick, vinculoId } = props

  const { data: _data, isLoading, isFetching } = useVinculoAfastamentoGetByVinculo(vinculoId)

  const data = _data.map((d) => ({
    ...d,
    dtInicioAfastamento: d?.dtInicioAfastamento
      ? moment(d.dtInicioAfastamento).format('DD/MM/yyyy')
      : '',
    dtRetorno: d?.dtRetorno ? moment(d.dtRetorno).format('DD/MM/yyyy') : '',
    dtPrevistaRetorno: d?.dtPrevistaRetorno ? moment(d.dtPrevistaRetorno).format('DD/MM/yyyy') : '',
    dtReciboEsocial: d?.dtReciboEsocial
      ? moment(d.dtReciboEsocial).format('DD/MM/yyyy - HH:mm:ss')
      : '',
    motivoAfastamento: d?.motivoAfastamento ? formatMotivoAfastamento(d.motivoAfastamento) : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtInicioAfastamento',
      label: 'Início',
    },
    {
      name: 'dtRetorno',
      label: 'Fim',
    },
    {
      name: 'motivoAfastamento',
      label: 'Motivo Afastamento',
    },
    {
      name: 'dtPrevistaRetorno',
      label: 'Previsão de retorno',
    },
    {
      name: 'qtdDiasAfastado',
      label: 'Dias Afastados',
    },
    {
      name: 'reciboEsocial',
      label: 'Recibo eSocial',
    },
    {
      name: 'dtReciboEsocial',
      label: 'Data Recibo eSocial',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          const dt = _data.find((x) => x.id === value)
          if (!dt) return

          const isAfastamentoFerias = dt.motivoAfastamento === IndMotivoAfastamentoEnum.Ferias_15

          const saldosFerias = dt?.afastamentosSaldoFerias || []
          const saldosFeriasNaoConcedidos = saldosFerias.filter((d) => d.dtConcedido === null)
          const hasSaldoFerias = saldosFerias.length > 0

          const hasEsocial = dt?.reciboEsocial ? true : false
          const hasDataRetorno = dt?.dtRetorno ? true : false

          return (
            <ActionMenu
              options={[
                {
                  icon: 'edit',
                  label: 'Editar',
                  title: 'Editar este registro',
                  onClick: () => onItemClick(AcoesTabela.EDIT, dt),
                },
                {
                  icon: 'delete',
                  label: 'Deletar',
                  title: 'Deletar este registro',
                  severity: 'danger',
                  onClick: () => onItemClick(AcoesTabela.DELETE, dt),
                  disabled: hasEsocial ? true : false,
                },
                {
                  icon: 'beach_access',
                  label: 'Saldo de Férias',
                  onClick: () => onItemClick(AcoesTabela['SALDO-FERIAS'], dt),
                  badgeContent: saldosFeriasNaoConcedidos.length,
                  hidden: isAfastamentoFerias && hasSaldoFerias ? false : true,
                },
                {
                  icon: 'pan_tool',
                  label: 'Interromper Afastamento',
                  onClick: () => onItemClick(AcoesTabela.INTERROMPER, dt),
                  hidden:
                    hasEsocial && isAfastamentoFerias && !hasSaldoFerias && hasDataRetorno
                      ? false
                      : true,
                },
                {
                  icon: 'today',
                  label: 'Informar Fim do Afastamento',
                  onClick: () => onItemClick(AcoesTabela['DT-FIM'], dt),
                  hidden: hasEsocial && !hasDataRetorno ? false : true,
                },
                {
                  icon: 'assignment_late',
                  label: 'Alterar Motivo do Afastamento',
                  onClick: () => onItemClick(AcoesTabela['MOTIVO-AFASTAMENTO'], dt),
                  hidden: hasEsocial ? false : true,
                },
                {
                  icon: 'replay',
                  label: 'Retificar Afastamento',
                  onClick: () => onItemClick(AcoesTabela.RETIFICAR, dt),
                  hidden: hasEsocial ? false : true,
                },
              ]}
            />
          )
        },
      },
    },
  ]

  return (
    <HeightDataTable data={data} columns={columns} isLoading={isLoading} isFetching={isFetching} />
  )
}
