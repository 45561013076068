import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ToolsDataTable,
  ContainerTable,
  Stack,
  ContentDivider,
} from '~/components'

import Form from './Form'

import useDialog from '~/hooks/useDialog'

import { useObterTodosSalarioItem } from '~/hooks/queries/SalarioItem/useObterTodosSalarioItem'
import { useRemoveSalarioItem } from '~/hooks/queries/SalarioItem/useRemoveSalarioItem'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { SalarioItem } from '~/hooks/queries/SalarioItem/SalarioItem'
import { formatCurrency, formatSimpleDate } from '~/utils/utils'

interface SalarioItensProps {
  salarioId: string
}

export default function SalarioItens({ salarioId }: SalarioItensProps) {
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterTodosSalarioItem({
    salarioId,
  })
  const data = _data || []

  const { mutateAsync: mutateAsyncDelete, isLoading: isDeleting } = useRemoveSalarioItem()

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<SalarioItem>({
    id: '',
    salarioId: '',
    dtSalario: '',
    vrSalarioBase: 0,
    vrSalarioBaseII: 0,
    vrSalarioBaseIII: 0,
    vrSalarioBaseIV: 0,
    observacao: '',
    salario: null,
  })

  async function handleConfirmDelete() {
    await mutateAsyncDelete(dataConfirmDelete)
    closeConfirmDelete()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtSalario',
      label: 'Data',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'vrSalarioBase',
      label: 'Valor Base I',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: 'vrSalarioBaseII',
      label: 'Valor Base II',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: 'vrSalarioBaseIII',
      label: 'Valor Base III',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: 'vrSalarioBaseIV',
      label: 'Valor Base IV',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtCurrent = data.find((d) => d.id === value)
                  if (!dtCurrent) return
                  openForm(dtCurrent)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => openConfirmDelete(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack>
      <ContentDivider
        title="Salários Itens"
        renderRight={
          <ButtonBox>
            <Button
              variant="contained"
              onClick={() =>
                openForm({
                  id: '',
                  salarioId,
                  dtSalario: '',
                  vrSalarioBase: 0,
                  vrSalarioBaseII: 0,
                  vrSalarioBaseIII: 0,
                  vrSalarioBaseIV: 0,
                  observacao: '',
                  salario: null,
                })
              }
            >
              Adicionar
            </Button>
          </ButtonBox>
        }
      />

      <ContainerTable>
        <ToolsDataTable
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          columns={columns}
          disableAutoHeight
        />
      </ContainerTable>

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isDeleting}
          onCancel={closeConfirmDelete}
          onConfirm={handleConfirmDelete}
        />
      )}

      {isOpenForm && <Form isOpen={isOpenForm} onClose={closeForm} data={dataForm} />}
    </Stack>
  )
}
