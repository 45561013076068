import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EventoEmpregador } from '../EventoEmpregador/EventoEmpregador'

interface RequestParams {
  rpId: string
  codigo: number
}

export function useRPGetByCodigo() {
  const dialogNotification = useDialogNotification()

  async function handleRequest(params: RequestParams) {
    const response = await api.get<{ data: EventoEmpregador }>('/RP/GetByCodigo', {
      params,
    })

    return response.data.data
  }

  return useMutation(handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
