import { useState } from 'react'

import { Box, Container, Paper, useTheme } from '@material-ui/core'

import { Button, PageHeader } from '~/components'
import AutoCompleteRetificarFuncionario from '~/components/AutoComplete/AutoCompleteRetificarFuncionario'

import { useHistory } from 'react-router-dom'

export default function RetificarFuncionario() {
  const [funcionarioId, setFuncionarioId] = useState<string | null>(null)

  const history = useHistory()
  const theme = useTheme()

  function startRetificacao() {
    history.push({ pathname: '/funcionario-retificar/' + funcionarioId })
  }

  return (
    <Box padding={2}>
      <PageHeader title="Retificar Funcionário" />

      <Container maxWidth="sm">
        <Box
          component={Paper}
          padding={2}
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(1)}
        >
          <AutoCompleteRetificarFuncionario
            value={funcionarioId}
            onChange={(id) => setFuncionarioId(id)}
          />

          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={funcionarioId === null}
              onClick={startRetificacao}
              variant="contained"
            >
              Iniciar Retificação
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
