export enum RescisaoFGTSEnum {
  Rescisao_com_justa_causa_empregador = 'H',
  Rescisao_sem_justa_causa_empregador = 'I1',
  Rescisao_culpa_reciproca_forca_maior = 'I2',
  Rescisao_termino_contrato_termo = 'I3',
  Rescisao_sem_justa_causa_contrato_trabalho_empregado_domestico_iniciativa_empregador = 'I4',
  Rescisao_contrato_motivo_acordo = 'I5',
  Rescisao_contrato_trabalho_iniciativa_empregado = 'J',
  Rescisao_pedido_empregado_iniciativa_empregador_justa_causa_no_caso_empregado = 'K',
  Outros_motivos_rescisão_contrato_trabalho = 'L',
  Mudanca_regime_estatutario = 'M',
  Transferencia_empregado_para_outro_estabelecimento_mesma_empresa = 'N1',
  Transferência_empregado_para_outra_empresa_tenha_assumido_encargos_trabalhistas = 'N2',
  Falecimento = 'S2',
  Falecimento_motivado_acidente_trabalho = 'S3',
  Aposentadoria_tempo_contribuição_idade_sem_continuidade_vinculo = 'U1',
  Aposentadoria_tempo_contribuição_idade_com_continuidade_vinculo = 'U2',
  Aposentadoria_invalidez = 'U3',
}

export const RescisaoFGTSValues = [
  {
    value: RescisaoFGTSEnum.Rescisao_com_justa_causa_empregador,
    name: 'Rescisão, com justa causa, por iniciativa do empregador',
  },
  {
    value: RescisaoFGTSEnum.Rescisao_sem_justa_causa_empregador,
    name: 'Rescisão, sem justa causa, por iniciativa do empregador',
  },
  {
    value: RescisaoFGTSEnum.Rescisao_culpa_reciproca_forca_maior,
    name: 'Rescisão por culpa reciproca ou força maior',
  },
  {
    value: RescisaoFGTSEnum.Rescisao_termino_contrato_termo,
    name: 'Rescisão por término do contrato a termo',
  },
  {
    value:
      RescisaoFGTSEnum.Rescisao_sem_justa_causa_contrato_trabalho_empregado_domestico_iniciativa_empregador,
    name: 'Rescisão sem justa causa, do contrato de trabalho do empregado doméstico, por iniciativa do empregador',
  },
  {
    value: RescisaoFGTSEnum.Rescisao_contrato_motivo_acordo,
    name: 'Rescisão do Contrato por motivo de Acordo',
  },
  {
    value: RescisaoFGTSEnum.Rescisao_contrato_trabalho_iniciativa_empregado,
    name: 'Rescisão do contrato de trabalho por iniciativa do empregado',
  },
  {
    value:
      RescisaoFGTSEnum.Rescisao_pedido_empregado_iniciativa_empregador_justa_causa_no_caso_empregado,
    name: 'Rescisão a pedido do empregado ou por iniciativa do empregador, com justa causa, no caso de empregado',
  },
  {
    value: RescisaoFGTSEnum.Outros_motivos_rescisão_contrato_trabalho,
    name: 'Outros motivos de rescisão do contrato de trabalho',
  },
  {
    value: RescisaoFGTSEnum.Mudanca_regime_estatutario,
    name: 'Mudança de regime estatutário',
  },
  {
    value: RescisaoFGTSEnum.Transferencia_empregado_para_outro_estabelecimento_mesma_empresa,
    name: 'Transferência de empregado para outro estabelecimento da mesma empresa',
  },
  {
    value:
      RescisaoFGTSEnum.Transferência_empregado_para_outra_empresa_tenha_assumido_encargos_trabalhistas,
    name: 'Transferência de empregado para outra empresa que tenha assumido os encargos trabalhistas, sem que tenha havido rescisão de contrato de trabalho',
  },
  {
    value: RescisaoFGTSEnum.Falecimento,
    name: 'Falecimento',
  },
  {
    value: RescisaoFGTSEnum.Falecimento_motivado_acidente_trabalho,
    name: 'Falecimento motivado por acidente do trabalho',
  },
  {
    value: RescisaoFGTSEnum.Aposentadoria_tempo_contribuição_idade_sem_continuidade_vinculo,
    name: 'Aposentadoria por tempo de contribuição ou idade sem continuidade de vínculo empregatício',
  },
  {
    value: RescisaoFGTSEnum.Aposentadoria_tempo_contribuição_idade_com_continuidade_vinculo,
    name: 'Aposentadoria por tempo de contribuição ou idade com continuidade de vínculo empregatício',
  },
  {
    value: RescisaoFGTSEnum.Aposentadoria_invalidez,
    name: 'Aposentadoria por invalidez',
  },
]
