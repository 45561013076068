import { useState, forwardRef, useRef } from 'react'

import { List, ListItem, Icon, makeStyles } from '@material-ui/core'

import ListItemComponent from '../ListItemComponent'

import { NavLink } from 'react-router-dom'

import { PopoverComponent } from '~/components'

function verifyRouterLink(link) {
  const routerParamCurrent = window?.location?.hash?.toLowerCase()
  return routerParamCurrent.includes(link?.toLowerCase())
}

const useStyles = makeStyles((theme) => ({
  rootPopover: {
    pointerEvents: 'none',
  },
  contentPopover: {
    pointerEvents: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: 2,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);',
  },
  listItem: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter,
      color: theme.palette.primary.main,
      '&.makeStyles-listItemIcon-301': {
        color: theme.palette.primary.main,
      },
    },
    paddingLeft: (props) => theme.spacing(2 + props.level),
  },
  listItemSelected: {
    '&.active': {
      background: theme.palette.primary.lighter,
      color: theme.palette.primary.main,
    },
  },
  listItemGutters: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'center',
  },
}))

function SimpleListItem(props) {
  const { item } = props

  const actionRef = useRef(null)
  const [anchorElItem, setAnchorElItem] = useState(null)
  const [menusOpens, setMenusOpens] = useState([])

  const classes = useStyles()

  function handleClickItem() {
    setTimeout(() => actionRef?.current?.updatePosition && actionRef.current.updatePosition(), 500)
  }

  return (
    <ListItem
      button
      classes={{
        root: classes.listItem,
        selected: classes.listItemSelected,
        gutters: classes.listItemGutters,
      }}
      component={
        item?.link
          ? // eslint-disable-next-line react/display-name
            forwardRef((_props, ref) => <NavLink exact {..._props} innerRef={ref} />)
          : 'div'
      }
      to={item?.link || ''}
      selected={verifyRouterLink(item?.link)}
      onMouseEnter={(e) => item?.itens?.length > 0 && setAnchorElItem(e.currentTarget)}
      onMouseLeave={() => setAnchorElItem(null)}
    >
      {item?.icon && <Icon>{item.icon}</Icon>}
      <PopoverComponent
        action={actionRef}
        anchorEl={anchorElItem}
        className={classes.rootPopover}
        classes={{
          paper: classes.contentPopover,
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <List>
          {item?.itens?.map((nextItem, index) => (
            <ListItemComponent
              item={nextItem}
              key={index}
              level={0}
              handleClickItem={handleClickItem}
              id={`${nextItem?.level}|${index}`}
              menusOpens={menusOpens}
              handleOpenMenus={setMenusOpens}
            />
          ))}
        </List>
      </PopoverComponent>
    </ListItem>
  )
}

export default function HoverMenu(props) {
  const { menus } = props

  return (
    <List>
      {menus.map((itemMenu, index) => (
        <SimpleListItem item={itemMenu} key={index} />
      ))}
    </List>
  )
}
