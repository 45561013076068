import React from 'react'

import XMLViewer from 'react-xml-viewer'
import ReactJson from 'react-json-view'

import { Button, IconButton, Box } from '@material-ui/core'
import { FileCopy as FileCopyIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { ButtonBox } from 'mio-library-ui'

import { ActionDialog, ToolsDataTable } from '~/components'

import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'
import useOnlyDataC from '~/hooks/useOnlyDataC'

export default function Table(props) {
  const { data, isLoading, isFetching, onClickDelete } = props

  const notification = useNotification()

  const { isUserDataC } = useOnlyDataC()

  const {
    close: closeDialogInfo,
    data: dataDialogInfo,
    isOpen: isOpenDialogInfo,
    open: openDialogInfo,
  } = useDialog({ type: '', title: '', data: '' })

  const columns = [
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'sequencial',
      label: 'Sequencial',
    },
    {
      name: 'envio_CodigoResposta',
      label: 'Envio Código Resposta',
    },
    {
      name: 'envio_DescResposta',
      label: 'Envio Descrição Resposta',
    },
    {
      name: 'envio_ReciboEnvio',
      label: 'Envio Recibo Envio',
    },
    {
      name: 'envio_ReciboDataHora',
      label: 'Envio Recibo Data Hora',
    },
    {
      name: 'retorno_CodigoResposta',
      label: 'Retorno código Resposta',
    },
    {
      name: 'retorno_DescResposta',
      label: 'Retorno Descrição Resposta',
    },
    {
      name: 'retorno_DataHora',
      label: 'Retorno Data Hora',
    },
    {
      name: 'retornoProcessado',
      label: 'Retorno Processando',
      options: {
        customBodyRender: (value) => (value ? 'Sim' : 'Não'),
      },
    },
    {
      name: 'grupoEvento',
      label: 'Grupo Evento',
    },
    {
      name: 'envio_XML',
      label: 'XML Envio',
      options: {
        customBodyRender: (value) => (
          <IconButton
            size="small"
            onClick={() => openDialogInfo({ type: 'xml', title: 'XML Envio', data: value })}
          >
            XML
          </IconButton>
        ),
      },
    },
    {
      name: 'retorno_Xml',
      label: 'XML Retorno',
      options: {
        customBodyRender: (value) => (
          <IconButton
            size="small"
            onClick={() => openDialogInfo({ type: 'xml', title: 'XML Retorno', data: value })}
          >
            XML
          </IconButton>
        ),
      },
    },
    {
      name: 'retornoObj',
      label: 'Objeto de Retorno',
      options: {
        customBodyRender: (value) => (
          <IconButton
            size="small"
            onClick={() =>
              openDialogInfo({ type: 'json', title: 'JSON Objeto Retorno', data: value })
            }
          >
            JSON
          </IconButton>
        ),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        display: isUserDataC ? true : 'excluded',
        customBodyRender: (value) => (
          <ButtonBox justifyContent="center" spacing={0}>
            <IconButton
              title="Excluir este registro"
              color="primary"
              aria-label="Excluir"
              size="small"
              onClick={() => {
                const dataFinded = data.find((d) => d.id === value)
                onClickDelete(dataFinded)
              }}
            >
              <DeleteIcon fontSize="small" color="primary" />
            </IconButton>
          </ButtonBox>
        ),
      },
    },
  ]

  const handleCopy = (copy) => {
    if (!navigator.clipboard)
      return notification.error('Não foi possível copiar o conteúdo clicado')
    navigator.clipboard.writeText(copy)
    notification.success('Conteúdo copiado para a Área de transferência')
  }

  return (
    <>
      <ToolsDataTable data={data} isLoading={isLoading} isFetching={isFetching} columns={columns} />
      <ActionDialog
        title={dataDialogInfo.title}
        isOpen={isOpenDialogInfo}
        onClose={closeDialogInfo}
        renderRight={
          <Button
            onClick={() =>
              handleCopy(
                dataDialogInfo.type === 'xml'
                  ? dataDialogInfo.data
                  : JSON.stringify({ retornoEventos: dataDialogInfo.data }),
              )
            }
            size="small"
            title="Copiar"
            color="primary"
            variant="outlined"
          >
            <Box paddingRight={1}>Copiar</Box>
            <FileCopyIcon fontSize="small" />
          </Button>
        }
        customActions={<></>}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md',
        }}
      >
        {dataDialogInfo.type === 'xml' ? (
          <XMLViewer xml={dataDialogInfo.data} />
        ) : (
          <ReactJson src={{ retornoEventos: dataDialogInfo.data }} />
        )}
      </ActionDialog>
    </>
  )
}
