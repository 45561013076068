import React, { useState } from 'react'

import { Grid, makeStyles, Box, Paper } from '@material-ui/core'
import { TextField, Button, ButtonBox, PageHeader } from 'mio-library-ui'

import { MUIAutoComplete, AutoCompleteVinculo } from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { CategoriaEnum, CategoriaValues } from '~/@types/enums/CategoriaEnum'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  rootForm: {
    border: theme.shape.border,
    margin: 'auto',
    width: '60%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const schema = yup.object().shape({
  vinculoId: yup.string().required('Informe um Funcionário'),
  novaCategoria: yup.string().required('Informe uma Nova Categoria'),
})

const AlterarCategoria = () => {
  const [data, setData] = useState({})
  const [categoriaCurrent, setCategoriaCurrent] = useState({
    value: '',
    name: '',
  })
  const [categorias, setCategorias] = useState([])
  const [isLoading, setLoading] = useState(false)

  const { anoMes, estabelecimento } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  const handelResetData = () => {
    setData({})
    setCategoriaCurrent({
      value: '',
      name: '',
    })
  }

  const handleSubmit = () => {
    const insert = async () => {
      setLoading(true)
      try {
        await api.post(
          `Vinculo/AlterarCategoriaVinculo?vinculoId=${data?.vinculoId}&categoria=${data?.novaCategoria}`,
        )
        handelResetData()
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const changeCategories = (categoria) => {
    if (!categoria) return
    const newCategoria = CategoriaValues.find((obj) => obj.value === categoria)
    setCategoriaCurrent(newCategoria || { value: '', name: '' })

    const maps = {
      [CategoriaEnum.Empregado_Geral_101]: [CategoriaEnum.Empregado_Intermitente_111],
      [CategoriaEnum.Empregado_Rural_102]: [CategoriaEnum.Empregado_Geral_101],
      [CategoriaEnum.Empregado_Aprendiz_103]: [
        CategoriaEnum.Empregado_Geral_101,
        CategoriaEnum.Empregado_Intermitente_111,
      ],
      [CategoriaEnum.Empregado_Contrato_Termo_105]: [CategoriaEnum.Empregado_Geral_101],
      [CategoriaEnum.Trabalhador_Temporário_106]: [CategoriaEnum.Empregado_Geral_101],
      [CategoriaEnum.Empregado_Intermitente_111]: [CategoriaEnum.Empregado_Geral_101],
    }
    const mapsResult = maps[categoria]
    if (mapsResult?.length > 0) {
      const newCategorias = CategoriaValues.filter((obj) => mapsResult.includes(obj.value))
      setCategorias(newCategorias)
      return
    }
    setCategorias([])
  }

  return (
    <Box className={classes.root}>
      <PageHeader title="Alteração de Categoria" />
      <Paper className={classes.rootForm}>
        <Grid container spacing={2}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <AutoCompleteVinculo
              label="Funcionário"
              value={data?.vinculo || null}
              vinculosTipo={[VinculoTipoEnum.Funcionario_1]}
              estabelecimentoId={estabelecimento?.id}
              anoMes={anoMes}
              required
              name="vinculoId"
              optionId="id"
              validationErrors={validationErrors}
              onChange={(e, vinculo) => {
                const vinculoId = vinculo?.id || ''
                changeCategories(vinculo?.categoria)
                setData((oldState) => ({
                  ...oldState,
                  vinculoId,
                  vinculo,
                  novaCategoria: '',
                }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Categoria Atual"
              value={categoriaCurrent?.value ? categoriaCurrent.name : ''}
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MUIAutoComplete
              label="Nova Categoria"
              options={categorias}
              optionId="value"
              renderOption={(option) => option.name}
              required
              name="novaCategoria"
              validationErrors={validationErrors}
              value={data.novaCategoria}
              disabled={data?.vinculo ? false : true}
              onChange={(e, obj) => {
                const novaCategoria = obj ? obj.value : ''
                setData((oldState) => ({ ...oldState, novaCategoria }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ButtonBox>
              <Button size="small" color="primary" variant="contained" onClick={handelResetData}>
                Cancelar
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleValidate}
                isLoading={isLoading}
              >
                Salvar
              </Button>
            </ButtonBox>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default AlterarCategoria
