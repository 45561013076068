import React, { useEffect, useState } from 'react'

import { Grid, Box, Paper } from '@material-ui/core'

import { Button } from 'mio-library-ui'

import { AnoMesTextField, MultipleSelect } from '~/components'

import { ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'

import { useEstabelecimento as useQueryEstabelecimentos } from '~/hooks/queries/useEstabelecimentoOld'
import { useQuery as useQueryEventos } from '~/hooks/queries/useEventos'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import * as yup from 'yup'
import moment from 'moment'
import theme from '~/styles/theme'

const schema = yup.object().shape({
  anoMesInicio: yup
    .string()
    .required('Informe o Ano/Mês Início')
    .when(['$anoMesFim'], (anoMesFim, schema) =>
      schema.test(
        'anoMesInicio-Validator',
        'O Ano/Mês Início não pode ser posterior ao Ano/Mês Fim',
        (anoMesInicio) => moment(anoMesInicio).isSameOrBefore(anoMesFim),
      ),
    )
    .nullable(),
  anoMesFim: yup
    .string()
    .required('Informe o Ano/Mês Fim')
    .when(['$anoMesInicio'], (anoMesInicio, schema) =>
      schema.test(
        'anoMesFim-Validator',
        'O Ano/Mês Fim não pode ser anterior ao Ano/Mês Início',
        (anoMesFim) => moment(anoMesFim).isSameOrAfter(anoMesInicio),
      ),
    )
    .nullable(),
  recibosIDF: yup
    .array()
    .required('Informe os Recibos IDF')
    .min(1, 'Informe pelo menos um Recibos IDF')
    .nullable(),
  estabelecimentos: yup
    .array()
    .required('Informe os Estabelecimento')
    .min(1, 'Informe pelo menos um Estabelecimento')
    .nullable(),
  eventos: yup
    .array()
    .required('Informe os Eventos')
    .min(1, 'Informe pelo menos um Evento')
    .nullable(),
})

const initialStateData = {
  anoMesInicio: '',
  anoMesFim: '',
  recibosIDF: [],
  estabelecimentos: [],
  eventos: [],
}

export default function Form(props) {
  const { onAfterSubmit, isLoading, onClickCancel } = props

  const [data, setData] = useState(initialStateData)

  const { anoMes } = useAmbiente()
  const { data: eventos, isLoading: isLoadingEventos } = useQueryEventos()
  const { data: estabelecimentos, isLoading: isLoadingEstabelecimentos } =
    useQueryEstabelecimentos()

  useEffect(() => {
    setData((old) => ({
      ...old,
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
    }))
  }, [anoMes])

  function onAfterValidate() {
    onAfterSubmit(data)
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: onAfterValidate,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { anoMesInicio: data?.anoMesInicio, anoMesFim: data?.anoMesFim },
    },
  })

  function handleCancel() {
    setData({
      ...initialStateData,
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
    })
    onClickCancel()
  }

  return (
    <Box component={Paper} padding={2}>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <AnoMesTextField
            name="anoMesInicio"
            label="Ano/Mês Início"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.anoMesInicio || ''}
            onChange={(e) => {
              const anoMesInicio = e.target.value.replace('/', '')
              setData((oldState) => ({
                ...oldState,
                anoMesInicio,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <AnoMesTextField
            name="anoMesFim"
            label="Ano/Mês Fim"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.anoMesFim || ''}
            onChange={(e) => {
              const anoMesFim = e.target.value.replace('/', '')
              setData((oldState) => ({
                ...oldState,
                anoMesFim,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <MultipleSelect
            limitTags={3}
            name="recibosIDF"
            inputProps={{
              label: 'Recibos IDF',
            }}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect
            required
            value={data?.recibosIDF || []}
            options={ReciboIDFValues}
            onChange={(_, selecteds) => {
              const recibosIDF = selecteds
              setData((oldState) => ({
                ...oldState,
                recibosIDF,
              }))
            }}
            validationErrors={validationErrors}
            isSelectAll
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MultipleSelect
            limitTags={3}
            name="estabelecimentos"
            inputProps={{
              label: 'Estabelecimentos',
            }}
            getOptionLabel={(option) => `${option?.codigo} - ${option?.nome}`}
            disableCloseOnSelect
            required
            value={data?.estabelecimentos || []}
            options={estabelecimentos || []}
            onChange={(_, selecteds) => {
              const estabelecimentos = selecteds
              setData((oldState) => ({
                ...oldState,
                estabelecimentos,
              }))
            }}
            validationErrors={validationErrors}
            isLoading={isLoadingEstabelecimentos}
            isSelectAll
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MultipleSelect
            limitTags={3}
            name="eventos"
            inputProps={{
              label: 'Eventos',
            }}
            getOptionLabel={(option) => `${option?.codigo} - ${option?.nome}`}
            disableCloseOnSelect
            required
            value={data?.eventos || []}
            options={eventos || []}
            onChange={(_, selecteds) => {
              const eventos = selecteds
              setData((oldState) => ({
                ...oldState,
                eventos,
              }))
            }}
            validationErrors={validationErrors}
            isLoading={isLoadingEventos}
            isSelectAll
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)}>
            <Button onClick={handleCancel} variant="outlined" size="small" color="primary">
              Cancelar
            </Button>
            <Button
              onClick={handleValidate}
              isLoading={isLoading}
              variant="contained"
              size="small"
              color="primary"
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
