import { convertToArrayDescription } from '~/utils/utils'

export enum IndFeriasEnum {
  Mensal_0 = 0,
  Coletiva_PrimeiroPeriodo_1 = 1,
  Coletiva_SegundoPeriodo_2 = 2,
  Fracionada_3 = 3,
}

export const IndFeriasLabels = {
  [IndFeriasEnum.Mensal_0]: '0 - Mensal',
  [IndFeriasEnum.Coletiva_PrimeiroPeriodo_1]: '1 - Coletiva Primeiro Período',
  [IndFeriasEnum.Coletiva_SegundoPeriodo_2]: '2 - Coletiva Segundo Período',
  [IndFeriasEnum.Fracionada_3]: '3 - Fracionada',
}

export const IndFeriasValues = convertToArrayDescription(IndFeriasEnum, IndFeriasLabels)
