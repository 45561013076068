import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { VinculoAfastamento } from './VinculoAfastamento'

import { queryKeyVinculoAfastamentoGetByVinculo } from './useVinculoAfastamentoGetByVinculo'

export function useAfastamentoCreate() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: VinculoAfastamento) {
    const response = await api.post<{ data: string[] }>('/VinculoAfastamento', data)
    notification.post()
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries(queryKeyVinculoAfastamentoGetByVinculo),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
