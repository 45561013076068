import React, { useState, useEffect, useCallback } from 'react'

import { Grid, Box, makeStyles } from '@material-ui/core'
import { ButtonBox, Button, TextField, ContentDivider } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import Table from './components/Table'
import FormItem from './components/FormItem'

import useDialogNotification from '~/hooks/useDialogNotification'
import { DatePicker } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import * as yup from 'yup'

import api from '~/services/api-pessoal'
import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import DialogIntervalos from './components/DialogIntervalos'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '60%',
  },
}))

const schema = yup.object().shape({
  dtIncio: yup.string().required('Informe a Data de Início').nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, isFinalizado, vinculo } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  const [collectionItem, setCollectionItem] = useState({
    isLoading: false,
    itens: [],
  })

  const [formItem, setFormItem] = useState({
    isOpen: false,
    data: {},
  })

  const {
    close: closeDialogIntervalos,
    data: dataDialogIntervalos,
    isOpen: isOpenDialogIntervalos,
    open: openDialogIntervalos,
  } = useDialog(null)

  const [confirmDeleteDialogItem, setConfirmDeleteDialogItem] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  useEffect(() => {
    const getCollection = async () => {
      if (!_data?.id) return
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(
          `/VinculoHorarioItem/GetByVinculoHorario?vinculoHorarioId=${_data?.id}`,
        )
        if (response.data.data) {
          setCollectionItem((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    setData(_data)
    getCollection()
    // eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/VinculoHorario/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/VinculoHorario', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleOpenForm = useCallback(
    (
      otherData = {
        vinculoHorarioId: data?.id,
        vinculoHorario: data,
        dias: [],
      },
    ) => {
      setFormItem((oldState) => ({
        ...oldState,
        isOpen: true,
        data: otherData,
      }))
    },
    [data],
  )

  const handleClickItem = (event, value) => {
    const handleClickEditItem = (id) => {
      const { itens } = collectionItem
      const item = itens.find((i) => i.id === id)
      handleOpenForm(item)
    }

    const handleClickDeleteItem = (id) => {
      setConfirmDeleteDialogItem((oldState) => ({
        ...oldState,
        isOpen: true,
        id,
      }))
    }

    function handleClickIntervalos(id) {
      openDialogIntervalos(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
      intervalos: handleClickIntervalos,
    }
    functions[event](value)
  }

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    const itens = collectionItem.itens
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    try {
      await api.delete(`/VinculoHorarioItem/${confirmDeleteDialogItem.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialogItem.id)
      handleCloseConfirmDeleteItem()
      setCollectionItem((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    //eslint-disable-next-line
  }, [collectionItem.itens, confirmDeleteDialogItem.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
    //eslint-disable-next-line
  }, [data])

  const handleCloseForm = () => {
    setFormItem({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitFormItem = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collectionItem
        const newItens = [...data, ...itens]
        setCollectionItem((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collectionItem
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollectionItem((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    //eslint-disable-next-line
    [collectionItem],
  )

  return (
    <ActionDialog
      title="Cadastro de Horário de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: data?.id ? 'lg' : 'xs',
        fullWidth: true,
        classes: {
          paper: data?.id ? classes.heightPaper : undefined,
        },
      }}
      customActions={
        !isFinalizado ? (
          <ButtonBox bottom={1} right={1}>
            <Button color="primary" onClick={onClose}>
              Cancelar
            </Button>
            <Button color="primary" onClick={handleValidate}>
              Salvar
            </Button>
          </ButtonBox>
        ) : (
          <></>
        )
      }
    >
      <Grid container spacing={2}>
        <Grid
          item
          xl={data?.id ? 2 : 12}
          lg={data?.id ? 2 : 12}
          md={data?.id ? 3 : 12}
          sm={data?.id ? 3 : 12}
          xs={12}
        >
          <DatePicker
            label="Data de início"
            size="small"
            value={data?.dtIncio || null}
            validationErrors={validationErrors}
            name="dtIncio"
            required
            onChange={(date) => {
              const dtIncio = date?.format('yyyy-MM-DD') || null
              setData({ ...data, dtIncio })
            }}
            disabled={isFinalizado ? true : false}
            title={
              isFinalizado ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho' : ''
            }
          />
        </Grid>

        <Grid
          item
          xl={data?.id ? 10 : 12}
          lg={data?.id ? 10 : 12}
          md={data?.id ? 9 : 12}
          sm={data?.id ? 9 : 12}
          xs={12}
        >
          <TextField
            label="Observação"
            fullWidth
            value={data?.observacao || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 200,
            }}
            multiline
            rows={data?.id ? 0 : 4}
            onChange={(e) => {
              const observacao = e?.target.value || ''
              setData({ ...data, observacao })
            }}
            disabled={isFinalizado ? true : false}
            title={
              isFinalizado ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho' : ''
            }
          />
        </Grid>

        {data?.id && !isFinalizado && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ContentDivider />
          </Grid>
        )}
      </Grid>

      {data?.id && (
        <>
          <Box py={1}>
            <ContentDivider
              title="Dias da semana"
              renderRight={
                !isFinalizado && (
                  <ButtonBox>
                    <Button
                      size="small"
                      color="primary"
                      onClick={handleClickAddItem}
                      variant="contained"
                    >
                      Adicionar
                    </Button>
                  </ButtonBox>
                )
              }
            />
          </Box>
          <Table
            data={collectionItem.itens}
            isLoading={collectionItem.isLoading}
            onItemClick={handleClickItem}
            isFinalizado={isFinalizado}
          />
        </>
      )}

      <FormItem
        vinculo={vinculo}
        isOpen={formItem.isOpen}
        data={formItem.data}
        diasOld={collectionItem?.itens}
        onClose={handleCloseForm}
        onAfterSubmitFormItem={handleAfterSubmitFormItem}
      />

      {isOpenDialogIntervalos && (
        <DialogIntervalos
          isOpen={isOpenDialogIntervalos}
          onClose={closeDialogIntervalos}
          horarioId={dataDialogIntervalos}
        />
      )}

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialogItem.isOpen}
        isDeleting={confirmDeleteDialogItem.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ActionDialog>
  )
}

export default Form
