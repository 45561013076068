import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EmpregadorConsulta } from './EmpregadorConsulta'

import { IndAtividadeEmpregadorEnum } from '~/@types/enums/IndAtividadeEmpregadorEnum'

export const empregadorObterFiltradoQuery = '/EmpregadorConsulta/ObterFiltrado'

interface RequestProps {
  indAtividadeEmpregadorEnum: IndAtividadeEmpregadorEnum
  anoMes: string
  grupoId?: string
}

export function useEmpregadorObterFiltrado(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: EmpregadorConsulta[] }>(empregadorObterFiltradoQuery, {
      params,
    })
    return response.data.data
  }

  return useQuery([empregadorObterFiltradoQuery, params], () => handleRequest(), {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
