import React, { useMemo } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { ToolsDataTable } from '~/components'

export default function Table(props) {
  const { data: _data, isLoading, query, rowsSelected, handleRowSelected } = props

  const data = _data.map((d) => ({
    ...d,
    nrInscricao: d?.nrInscricao ? formatToCPFOrCNPJ(d.nrInscricao) : '',
  }))

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
    ],
    [],
  )

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      optionsSelectable={{
        selectOnClick: true,
        type: 'multiple',
        rowsSelected: rowsSelected,
        onRowSelected: handleRowSelected,
      }}
      sherlock={{
        columns: ['codigo', 'nrInscricao', 'nome'],
        query,
      }}
    />
  )
}
