import React, { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, makeStyles, Paper } from '@material-ui/core'

import { DatePicker, PageHeader, PrintButton, PDFViewer } from '~/components'
import { AutoCompleteEstabelecimento, MUIAutoComplete } from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'
import { modeloImpressaoValues, modeloImpressaoConsts } from '~/values/modeloImpressaoValues'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  estabelecimentoId: yup.string().required('Informe o Estabelecimento'),
  modeloImpressao: yup.number().required('Informe o Modelo de Impressão'),
  dtEmissao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Emissão')
    .nullable(),
})

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '720px',
    padding: theme.spacing(2),
  },
}))

export default function QuadroHorarioTrabalho() {
  const [data, setData] = useState({
    estabelecimentoId: '',
    modeloImpressao: modeloImpressaoConsts.geral_1,
    dtEmissao: getDateCurrent(),
  })

  const { estabelecimento } = useAmbiente()
  const classes = useStyles()

  const { validationErrors, handleValidate } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      estabelecimentoId: estabelecimento.id,
    }))
    // eslint-disable-next-line
  }, [])

  function handleSubmit() {
    openPDFViewer(data)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Quadro Horário de Trabalho" />

      <Box display="flex" justifyContent="center">
        <Paper className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoCompleteEstabelecimento
                value={data?.estabelecimentoId || ''}
                onChange={(e, obj) =>
                  setData((prevState) => ({
                    ...prevState,
                    estabelecimentoId: obj?.id || '',
                  }))
                }
                optionId="id"
                required
                name="estabelecimentoId"
                validationErrors={validationErrors}
              />
            </Grid>

            <Grid item xs={12}>
              <MUIAutoComplete
                label="Modelo de impressão"
                options={modeloImpressaoValues}
                renderOption={(opt) => opt.name}
                optionId="value"
                value={data.modeloImpressao}
                required
                name="modeloImpressao"
                validationErrors={validationErrors}
                onChange={(_, obj) =>
                  setData((prevState) => ({
                    ...prevState,
                    modeloImpressao: obj?.value || modeloImpressaoConsts.geral_1,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12}>
              <DatePicker
                label="Data de Emissão"
                size="small"
                value={data?.dtEmissao || null}
                required
                validationErrors={validationErrors}
                name="dtEmissao"
                onChange={(date) => {
                  const dtEmissao = date ? date.format('yyyy-MM-DD') : null
                  setData((prevState) => ({ ...prevState, dtEmissao }))
                }}
              />
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              xs={12}
            >
              <PrintButton onClick={handleValidate} />
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="QuadroHorarioTrabalho"
        title="Quadro Horário de Trabalho"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/QuadroHorarioTrabalho/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
