import React from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { ArrowBack as BackIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  container: {
    '&:after': {
      content: '""',
      display: 'block',
      height: 2,
      width: '100%',
      background: '#DDD',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },

  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'block',
    color: '#666',
  },

  subTitle: {
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    color: '#AAA;',
  },

  titleContent: {
    display: 'flex',
    flexDirection: 'row',
  },

  button: {
    marginRight: theme.spacing(0.5),
  },

  secondaryActions: {},
}))

const PageTitle = ({ title, subtitle, onBack, children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.titleContent}>
          {/* <Box className={classes.button}> */}
          {onBack && (
            <IconButton size="small" onClick={onBack}>
              <BackIcon color="primary" size="small" />
            </IconButton>
          )}
          {/* </Box> */}
          <Box>
            {/* <Typography className={classes.title} variant="h6" component="h2">
              {title}
            </Typography> */}
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subTitle}>{subtitle}</Typography>
          </Box>
        </Box>
        {children && <Box className={classes.secondaryActions}>{children}</Box>}
      </Box>
    </Box>
  )
}

export default PageTitle
