import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'

import {
  AgenteNocivoTipoAvaliacaoValues,
  AgenteNocivoTipoAvaliacaoEnum,
} from '~/@types/enums/AgenteNocivoTipoAvaliacaoEnum'
import {
  AgenteNocivoUnidadeMedidaValues,
  AgenteNocivoUnidadeMedidaEnum,
} from '~/@types/enums/AgenteNocivoUnidadeMedidaEnum'
import { CondicaoAmbienteTrabalhoAgenteNocivoPartial } from '~/hooks/queries/CondicaoAmbienteTrabalhoAgenteNocivo/CondicaoAmbienteTrabalhoAgenteNocivo'

const formatTipoAvaliacao = (tipoAvaliacao: AgenteNocivoTipoAvaliacaoEnum) => {
  const objectFinded = AgenteNocivoTipoAvaliacaoValues.find((obj) => obj.value === tipoAvaliacao)
  return objectFinded?.name || ''
}

const formatUnidadeMedida = (unidadeMedida: AgenteNocivoUnidadeMedidaEnum) => {
  const objFinded = AgenteNocivoUnidadeMedidaValues.find((obj) => obj.value === unidadeMedida)
  return objFinded?.name || ''
}
interface TableProps {
  data: CondicaoAmbienteTrabalhoAgenteNocivoPartial[]
  onItemClick: (event: 'delete' | 'edit', index: number) => void
}

export default function Table(props: TableProps) {
  const { data: _data, onItemClick } = props

  const data = _data?.map((item) => {
    return {
      ...item,
      agenteNocivo: item?.agenteNocivo ? `${item.agenteNocivo.id} - ${item.agenteNocivo.nome}` : '',
      tipoAvaliacao: item?.tipoAvaliacao ? formatTipoAvaliacao(item.tipoAvaliacao) : '',
      unidadeMedida: item?.unidadeMedida ? formatUnidadeMedida(item.unidadeMedida) : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'agenteNocivo',
      label: 'Agente Nocivo',
    },
    {
      name: 'tipoAvaliacao',
      label: 'Tipo de Avaliação',
    },
    {
      name: 'intensidadeConcentracao',
      label: 'Intensidade Concentração',
    },
    {
      name: 'limiteTolerancia',
      label: 'Limite Tolerância',
    },
    {
      name: 'unidadeMedida',
      label: 'Unidade Medida',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', rowIndex)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', rowIndex)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} />
}
