import { useEffect, useState } from 'react'

import { Box, CircularProgress, LinearProgress } from '@material-ui/core'
import { Button, Stack } from '~/components'

import ProvisaoGroup from './ProvisaoGroup'

import { useObterLotacaoConfigPorId } from '~/hooks/queries/ExportacaoLancamentoConfig/useObterLotacaoConfigPorId'
import { useAtualizarLotacaoConfigProvisao } from '~/hooks/queries/ExportacaoLancamentoConfig/useAtualizarLotacaoConfigProvisao'

import { ProvisaoConfig } from '~/hooks/queries/ExportacaoLancamentoConfig/dtos/ProvisaoConfig'
const startData = {} as ProvisaoConfig

interface TabLotacaoConfigProvisaoProps {
  lotacaoConfigId: string
  mascaraConta: string
}

export default function TabLotacaoConfigProvisao({
  lotacaoConfigId,
  mascaraConta,
}: TabLotacaoConfigProvisaoProps) {
  const [data, setData] = useState(startData)

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterLotacaoConfigPorId({
    params: {
      lotacaoConfigId,
    },
  })
  const { mutateAsync, isLoading: isSubmitting } = useAtualizarLotacaoConfigProvisao()

  useEffect(() => {
    setData(_d?.provisao || startData)
  }, [_d])

  function handleCancel() {
    setData(_d?.provisao || startData)
  }

  async function handleSave() {
    if (_d) {
      await mutateAsync({
        data: {
          ..._d,
          provisao: data,
        },
        params: {
          id: _d.id,
        },
      })
    }
  }

  return (
    <Stack height="100%" position="relative" p={0} px={2}>
      <Box height={4}>{isFetching && <LinearProgress />}</Box>

      <Stack flex={1} overflow="auto" style={{ overflowX: 'hidden' }}>
        {isLoading ? (
          <Box
            position="absolute"
            right={0}
            left={0}
            top={0}
            bottom={0}
            zIndex={999}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={80} />
          </Box>
        ) : (
          <Stack>
            <ProvisaoGroup
              label="Provisão de Férias"
              mascaraConta={mascaraConta}
              value={data.ferias}
              onChange={(d) => setData((prev) => ({ ...prev, ferias: d }))}
            />
            <ProvisaoGroup
              label="Provisão de 13°Salário"
              mascaraConta={mascaraConta}
              value={data.decimoTerceiro}
              onChange={(d) => setData((prev) => ({ ...prev, decimoTerceiro: d }))}
            />
          </Stack>
        )}
      </Stack>

      <Stack orientation="horizontal" justifyContent="flex-end">
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button variant="contained" onClick={handleSave} isLoading={isSubmitting}>
          Salvar
        </Button>
      </Stack>
    </Stack>
  )
}
