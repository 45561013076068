import { useState } from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { ActionDialog, ButtonBox, Finder, ToolsDataTable } from '~/components'
import PopoverMemoria from './PopoverMemoria'

import useDialog from '~/hooks/useDialog'
import { formatCurrency, orderBy } from '~/hooks/useUtils'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { RP } from '~/hooks/queries/RP/RP'
import RPLabels from '~/hooks/queries/RP/RPLabels'
import { RPMemoriaCalculo } from '~/hooks/queries/RP/RPMemoriaCalculo'

const useStyles = makeStyles((theme: FixLater) => ({
  heigthPaper: {
    height: '100%',
  },
  tableContent: {
    position: 'absolute',
    right: theme.spacing(3),
    left: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  hightLightRow: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.lighter,
    '&:nth-child(even)': {
      backgroundColor: theme.palette.primary.lighter,
    },
  },
  alignRight: {
    textAlign: 'right',
  },
}))

const getMemoriaCalculo = (prop: keyof RP, memoriaCalculo: RPMemoriaCalculo[] | null) => {
  if (!memoriaCalculo) return null

  const memoriaCalc = memoriaCalculo.find(
    (memoCalc) => memoCalc.propriedade.toLowerCase() === prop.toLowerCase(),
  )
  if (!memoriaCalc) return null

  return {
    mensagens: memoriaCalc.mensagens,
  }
}

export interface DataTableMemoriaProp {
  label: string
  value: any
  mensagens: string[]
}

interface DataTableProps {
  descricao: string
  valor: any
  memoria: DataTableMemoriaProp | null
}

interface MemoriaCalculoRPProps {
  reciboPagamento: RP
  isOpen: boolean
  onClose: () => void
}

export default function MemoriaCalculoRP(props: MemoriaCalculoRPProps) {
  const { reciboPagamento, isOpen, onClose } = props

  const [query, setQuery] = useState('')

  const classes = useStyles()
  const {
    close: closePopoverMemoria,
    data: dataPopoverMemoria,
    open: openPopoverMemoria,
  } = useDialog<{ data: DataTableMemoriaProp | null; anchorEl: HTMLDivElement | null }>({
    anchorEl: null,
    data: null,
  })

  function customBodyRender(value: any, rowIndex: number) {
    const dataClicked = data[rowIndex]
    const hasMemoria = dataClicked.memoria ? true : false
    return (
      <div
        onClick={
          hasMemoria
            ? (e) =>
                openPopoverMemoria({
                  anchorEl: e.currentTarget,
                  data: dataClicked.memoria,
                })
            : undefined
        }
      >
        {value}
      </div>
    )
  }

  function setCellProps(rowIndex: number) {
    const dataRow = data[rowIndex]
    const isHightLightRow = dataRow?.memoria ? true : false
    return {
      style: isHightLightRow
        ? {
            fontWeight: 'bold',
          }
        : undefined,
    }
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'memoria',
      label: 'Memória',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'descricao',
      label: 'Descrição',
      options: {
        customBodyRender: (value, { rowIndex }) => customBodyRender(value, rowIndex),
        setCellProps: (_, rowIndex) => setCellProps(rowIndex),
      },
    },
    {
      name: 'valor',
      label: 'Valor',
      options: {
        customBodyRender: (value, { rowIndex }) => customBodyRender(value, rowIndex),
        setCellProps: (_, rowIndex) => ({
          className: classes.alignRight,
          ...setCellProps(rowIndex),
        }),
      },
    },
  ]

  const rpProps = Object.keys(reciboPagamento) as (keyof RP)[]

  const dataCreated: DataTableProps[] = []
  rpProps.forEach((prop) => {
    const memoria = getMemoriaCalculo(prop, reciboPagamento.memoriaCalculo)

    if (!RPLabels[prop]) return
    if (!reciboPagamento[prop]) return

    const descricao = RPLabels[prop]
    const valor = reciboPagamento[prop]

    dataCreated.push({
      descricao,
      valor,
      memoria: memoria
        ? {
            label: descricao,
            value: valor,
            mensagens: memoria.mensagens,
          }
        : null,
    })
  })

  const dataFormated = dataCreated.map((d) => ({
    ...d,
    valor: formatCurrency(d.valor),
  }))

  const data = orderBy(dataFormated, 'descricao')

  return (
    <ActionDialog
      title="Memória de Cálculo"
      isOpen={isOpen}
      onClose={onClose}
      customActions={<></>}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
        classes: {
          paper: classes.heigthPaper,
        },
      }}
    >
      <ButtonBox>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </ButtonBox>

      <Box className={classes.tableContent}>
        <ToolsDataTable
          columns={columns}
          disableAutoHeight
          data={data}
          pagination={false}
          sherlock={{
            columns: ['descricao'],
            query,
          }}
          options={{
            setRowProps: (row: any) => {
              const memoria = row[0]
              const isHightLightRow = memoria ? true : false
              return {
                className: isHightLightRow ? classes.hightLightRow : undefined,
              }
            },
          }}
        />
      </Box>
      {dataPopoverMemoria.anchorEl && (
        <PopoverMemoria
          anchorEl={dataPopoverMemoria.anchorEl}
          data={dataPopoverMemoria.data}
          onClose={closePopoverMemoria}
        />
      )}
    </ActionDialog>
  )
}
