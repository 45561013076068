export enum IndTabelaIREnum {
  Mensal = '1',
  DistribuicaoLucro = '2',
  RRA = '3',
}

export const IndTabelaIRValues = [
  {
    name: '1 - Mensal',
    value: IndTabelaIREnum.Mensal,
  },
  {
    name: '2 - Distribuição de Lucro',
    value: IndTabelaIREnum.DistribuicaoLucro,
  },
  {
    name: '3 - RRA',
    value: IndTabelaIREnum.RRA,
  },
]
