export enum IndHomologacaoEnum {
  EstabelecimentoEmpregador = 1,
  SedeSindicatoFuncionairo = 2,
  Outros = 3,
}

export const IndHomologacaoValues = [
  {
    value: IndHomologacaoEnum.EstabelecimentoEmpregador,
    name: '1 - Estabelecimento do Empregador',
  },
  {
    value: IndHomologacaoEnum.SedeSindicatoFuncionairo,
    name: '2 - Sede do Sindicato do Funcionário',
  },
  { value: IndHomologacaoEnum.Outros, name: '3 - Outros, preenchimento manual' },
]
