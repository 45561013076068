import { ButtonBox } from 'mio-library-ui'

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'
import { diaHorarioValues } from '~/values/diaHorarioValues'

const formatDiaHorario = (diaHorario) => {
  const objectFinded = diaHorarioValues.find((obj) => obj.value === diaHorario)
  return objectFinded?.name || ''
}

const formatNumberInHour = (number) => {
  if (!number) return ''
  return `${number.substring(0, 2)}:${number.substring(2)}`
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, isFinalizado } = props

  const data = _data.map((item) => {
    return {
      ...item,
      diaHorario: formatDiaHorario(item?.diaHorario),
      horarioTrabalhoNome: item?.horarioTrabalho?.nome || '',
      horaEntrada: formatNumberInHour(item?.horarioTrabalho?.horaEntrada),
      horaSaida: formatNumberInHour(item?.horarioTrabalho?.horaSaida),
    }
  })

  const columns = [
    {
      name: 'diaHorario',
      label: 'Dia da Semana Trabalhado',
    },
    {
      name: 'horarioTrabalhoNome',
      label: 'Horário de Trabalho',
    },
    {
      name: 'horaEntrada',
      label: 'Entrada',
    },
    {
      name: 'horaSaida',
      label: 'Saída',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Visualizar Intervalos"
                color="primary"
                aria-label="Intervalos"
                onClick={() => {
                  const dtCurrent = _data.find((d) => d.id === value)
                  if (!dtCurrent?.horarioTrabalhoId) return
                  onItemClick('intervalos', dtCurrent.horarioTrabalhoId)
                }}
              >
                <VisibilityIcon fontSize="small" color="primary" />
              </IconButton>

              {!isFinalizado && (
                <>
                  <IconButton
                    size="small"
                    title="Editar este registro"
                    color="primary"
                    aria-label="Editar"
                    onClick={() => {
                      onItemClick('edit', value)
                    }}
                  >
                    <EditIcon fontSize="small" color="primary" />
                  </IconButton>

                  <IconButton
                    size="small"
                    title="Deletar este registro"
                    color="primary"
                    aria-label="Deletar"
                    onClick={() => {
                      onItemClick('delete', value)
                    }}
                  >
                    <DeleteIcon fontSize="small" color="primary" />
                  </IconButton>
                </>
              )}
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} pagination={false} />
}

export default Table
