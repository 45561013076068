import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker } from '~/components'
import { MUIAutoComplete, AutoCompleteProcedimentoMedico } from '~/components/AutoComplete'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'
import moment from 'moment'

import * as yup from 'yup'
import { ASOOrdemExameConsts, ASOOrdemExameValues } from '~/values/ASOOrdemExameValues'
import { ASOIndResultadoValues } from '~/values/ASOIndResultadoValues'

const isProcedimentoMedico = [
  '0583',
  '0998',
  '0999',
  '1128',
  '1230',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '9999',
]

const schema = yup.object().shape({
  dtExame: yup.date().when('$dtASO', (dtASO, schema) => {
    return schema
      .max(
        dtASO,
        `A Data do Exame deve ser igual ou anterior à Data do ASO: ${moment(dtASO).format(
          'DD/MM/yyyy',
        )}`,
      )
      .required('Informe a Data do Exame')
      .typeError('Informe uma Data Valída')
  }),
  procedimentoMedico: yup.string().required('Informe o Procedimento Médico').nullable(),
  indOrdem: yup
    .number()
    .when('$procedimentoMedicoId', (procedimentoMedicoId, schema) =>
      schema.test('indOrdem-test', 'Informe a Ordem', (value) =>
        procedimentoMedicoId === '0281' ? value !== ASOOrdemExameConsts.naoInformar_0 : true,
      ),
    ),
  indResultado: yup.string().required('Informe o Resultado'),
  observacao: yup
    .string()
    .when('procedimentoMedicoId', (procedimentoMedicoId, schema) =>
      isProcedimentoMedico.includes(procedimentoMedicoId)
        ? schema.required('Informe a Observação')
        : schema,
    )
    .nullable(),
})

const FormItem = (props) => {
  const { isOpen, onClose, data: _data, aso, onAfterSubmitForm } = props

  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/AtestadoSaudeOcupacionalItem/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/AtestadoSaudeOcupacionalItem', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    schemaOptions: {
      abortEarly: false,
      context: {
        dtASO: aso?.dtASO,
        procedimentoMedicoId: data?.procedimentoMedicoId,
      },
    },
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Item ASO"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
          <DatePicker
            label="Data do Exame"
            size="small"
            name="dtExame"
            required
            validationErrors={validationErrors}
            value={data?.dtExame || null}
            onChange={(date) => {
              const dtExame = date?.format('yyyy-MM-DD') || null
              let dtVencimentoExame = ''
              if (data?.validadeExame) {
                dtVencimentoExame = moment(dtExame).add(data.validadeExame - 1, 'd')
              }
              setData((oldState) => ({
                ...oldState,
                dtExame,
                dtVencimentoExame,
              }))
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <AutoCompleteProcedimentoMedico
            name="procedimentoMedico"
            required
            validationErrors={validationErrors}
            value={data?.procedimentoMedico || null}
            onChange={(e, procedimentoMedico) => {
              const procedimentoMedicoId = procedimentoMedico?.id || null
              setData((oldState) => ({
                ...oldState,
                procedimentoMedicoId,
                procedimentoMedico,
              }))
            }}
          />
        </Grid>

        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <MUIAutoComplete
            label="Resultado"
            name="indResultado"
            required
            validationErrors={validationErrors}
            value={data.indResultado}
            options={ASOIndResultadoValues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(e, obj) => {
              const indResultado = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                indResultado,
              }))
            }}
          />
        </Grid>

        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <MUIAutoComplete
            label="Ordem"
            name="indOrdem"
            required={data?.procedimentoMedico?.id === '0281'}
            validationErrors={validationErrors}
            value={data.indOrdem}
            options={ASOOrdemExameValues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(e, obj) => {
              const indOrdem = obj ? obj.value : ASOOrdemExameConsts.naoInformar_0
              setData((oldState) => ({
                ...oldState,
                indOrdem,
              }))
            }}
          />
        </Grid>

        <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
          <TextField
            fullWidth
            onlyNumber
            variant="outlined"
            size="small"
            label="Validade em Dias"
            inputProps={{
              maxLength: 9,
            }}
            value={data?.validadeExame || ''}
            onChange={(e) => {
              const validadeExame = e?.target?.value || ''
              let dtVencimentoExame = ''
              if (data?.dtExame) {
                dtVencimentoExame = moment(data.dtExame).add(validadeExame - 1, 'd')
              }
              setData((oldState) => ({
                ...oldState,
                validadeExame,
                dtVencimentoExame,
              }))
            }}
          />
        </Grid>

        <Grid xl={8} lg={8} md={8} sm={8} xs={12} item>
          <DatePicker
            label="Data de Validade"
            size="small"
            value={data?.dtVencimentoExame || null}
            onChange={(date) => {
              const dtVencimentoExame = date?.format('yyyy-MM-DD') || null
              setData((oldState) => ({
                ...oldState,
                dtVencimentoExame,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            name="observacao"
            label="Observação"
            variant="outlined"
            size="small"
            fullWidth
            required={isProcedimentoMedico.includes(data?.procedimentoMedicoId)}
            value={data?.observacao || ''}
            inputProps={{
              maxLength: 250,
            }}
            multiline
            onChange={(e) => {
              const observacao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default FormItem
