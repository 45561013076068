import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

export interface RelAutorizacaoDescontosVinculoDTO {
  id: string
  codigo: number
  cpf: string
  nome: string
  dtAdmissao: Date | string
}

export function useAutorizacaoDescontosObterFuncionarios(
  estabelecimentoId: string,
  anoMes: string,
  isSomenteAdmitidosAnoMes: boolean,
) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: RelAutorizacaoDescontosVinculoDTO[] }>(
      '/Relatorio/AutorizacaoDescontos/ObterFuncionarios',
      {
        params: {
          estabelecimentoId,
          anoMes,
          isSomenteAdmitidosAnoMes,
        },
      },
    )
    return response.data.data
  }

  const query = useQuery(
    ['AutorizacaoDescontosObterFuncionarios', estabelecimentoId, anoMes, isSomenteAdmitidosAnoMes],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )

  return {
    ...query,
    data: query?.data || [],
  }
}
