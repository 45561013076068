import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import {
  PageHeader,
  PDFViewer,
  Button,
  Finder,
  DatePickerNew,
  ContainerTable,
  StackContainer,
  ButtonBox,
} from '~/components'

import Table from './Table'

import { useEtiquetaAlteracaoSalarialObterFuncionarios } from '~/hooks/queries/EtiquetaAlteracaoSalarial/useEtiquetaFeriasObterFerias'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { getDateMaxAnoMes, getDateMinAnoMes } from '~/utils/utils'

const schema = yup.object().shape({
  dtInicio: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Início')
    .nullable(),
  dtFim: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Fim')
    .nullable(),
})

const TITLE_PAGE = 'Etiqueta de Alteração Salarial'

export default function EtiquetaAlteracaoSalarial() {
  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [filter, setFilter] = useState<{
    dtInicio: string
    dtFim: string
  }>({
    dtInicio: '',
    dtFim: '',
  })
  const [query, setQuery] = useState('')

  const { anoMes } = useAmbiente()
  const theme = useTheme()

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data: filter,
    handleSubmit: handleSearch,
  })

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<string[]>([])

  const {
    refetch,
    data: _d,
    isLoading,
  } = useEtiquetaAlteracaoSalarialObterFuncionarios({
    params: {
      dtInicio: filter.dtInicio,
      dtFim: filter.dtFim,
    },
  })
  const data = _d || []

  useEffect(() => {
    setRowsSelected([])
  }, [_d])

  useEffect(() => {
    setFilter({
      dtInicio: getDateMinAnoMes(anoMes),
      dtFim: getDateMaxAnoMes(anoMes),
    })
  }, [anoMes])

  async function handleSearch() {
    await refetch()
  }

  function handlePrint() {
    const ids = rowsSelected.map((index) => data[index].id)
    openPDFViewer(ids)
  }

  function handleClosePDFViewer() {
    closePDFViewer()
    setRowsSelected([])
  }

  return (
    <StackContainer>
      <PageHeader title={TITLE_PAGE}>
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </ButtonBox>
      </PageHeader>

      <Paper>
        <Box
          p={1}
          display="flex"
          gridGap={theme.spacing(1)}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={5}>
                <DatePickerNew
                  label="Início do Período"
                  value={filter.dtInicio || null}
                  onChange={(date) => {
                    const dtInicio = date || ''
                    setFilter((prev) => ({
                      ...prev,
                      dtInicio,
                    }))
                  }}
                  validationErrors={validationErrors}
                  name="dtInicio"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
                <DatePickerNew
                  label="Fim do Período"
                  value={filter.dtFim || null}
                  onChange={(date) => {
                    const dtFim = date || ''
                    setFilter((prev) => ({
                      ...prev,
                      dtFim,
                    }))
                  }}
                  validationErrors={validationErrors}
                  name="dtFim"
                />
              </Grid>
            </Grid>
          </Box>

          <Button variant="contained" onClick={handleValidate}>
            Buscar
          </Button>
        </Box>
      </Paper>

      <ContainerTable>
        <Table
          data={data}
          isLoading={isLoading}
          onRowSelected={setRowsSelected}
          rowsSelected={rowsSelected}
          query={query}
        />
      </ContainerTable>

      <Box p={1} display="flex" justifyContent="flex-end" component={Paper}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handlePrint}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Imprimir
        </Button>
      </Box>

      {isOpenPDFViewer && (
        <PDFViewer
          title={TITLE_PAGE}
          reportKey="EtiquetaAlteracaoSalarial"
          isOpen={isOpenPDFViewer}
          onClose={handleClosePDFViewer}
          axiosConfig={{
            url: 'EtiquetaAlteracaoSalarial/ObterRelatorio',
            method: 'post',
            data: dataPDFViewer,
          }}
        />
      )}
    </StackContainer>
  )
}
