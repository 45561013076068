import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { PlanoSaudeDependenteCusto } from './dtos/PlanoSaudeDependenteCusto'

const query = '/PlanoSaudeDependenteCusto/ObterCustosPlanoSaudeDependente'

interface RequestProps {
  params: {
    planoSaudeDependenteId: string
  }
}

export function useObterCustosPlanoSaudeDependente({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: PlanoSaudeDependenteCusto[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
