import React, { useState, useEffect, useCallback } from 'react'

import { TextField, ContentDivider } from 'mio-library-ui'
import { Grid } from '@material-ui/core'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import { ContainerForm } from '~/components'

import * as yup from 'yup'
import api from '~/services/api-pessoal'
import { MUIAutoComplete } from '~/components/AutoComplete'
import { processoTipoValues } from '~/values/processoTipoValues'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  tipoProcesso: yup.string().required('Informe o Tipo do Processo'),
  numeroProcesso: yup.string().required('Informe o Número Processo'),
})

const Form = (props) => {
  const { data: _data, onAfterSubmitForm, setWasModified, onClickClose } = props

  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setWasModified(JSON.stringify(data) !== JSON.stringify(_data))
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/processo/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/processo', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ContainerForm handleSubmit={handleValidate} onCancel={onClickClose} isLoading={isSubmitting}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider top={2} />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            autoFocus
            value={data?.nome || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 80,
            }}
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
          <MUIAutoComplete
            label="Tipo do Processo"
            options={processoTipoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.tipoProcesso}
            required
            validationErrors={validationErrors}
            name="tipoProcesso"
            onChange={(e, obj) => {
              const tipoProcesso = obj ? obj.value : ''
              setData({ ...data, tipoProcesso })
            }}
          />
        </Grid>

        <Grid item xl={9} lg={9} md={8} sm={8} xs={12}>
          <TextField
            label="Número do Processo"
            fullWidth
            value={data?.numeroProcesso || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 21,
            }}
            validationErrors={validationErrors}
            name="numeroProcesso"
            onChange={(e) => {
              const numeroProcesso = e.target.value
              setData({ ...data, numeroProcesso })
            }}
          />
        </Grid>
      </Grid>
    </ContainerForm>
  )
}

export default Form
