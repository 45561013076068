import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { SalarioMinimo } from './SalarioMinimo'

export const queryUseObterTodosSalarioMinimo = '/SalarioMinimo'

export function useObterTodosSalarioMinimo() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: SalarioMinimo[] }>(queryUseObterTodosSalarioMinimo)
    return response?.data?.data || []
  }

  return useQuery([queryUseObterTodosSalarioMinimo], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
