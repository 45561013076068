export enum IndComercializacaoEnum {
  ComercializacaoVarejo_2 = 2,
  ComercializacaoPorProdutorRural_3 = 3,
  ComercializacaoProducaoIsenta_7 = 7,
  ComercializacaoPorPessoaFisicaPAA_8 = 8,
  ComercializacaoMercadoInterno_9 = 9,
}
export const IndComercializacaoValues = [
  {
    value: IndComercializacaoEnum.ComercializacaoVarejo_2,
    name: '2 - Comercialização da produção efetuada diretamente no varejo a consumidor final ou a outro produtor rural pessoa física por produtor rural pessoa física, inclusive por segurado especial, ou por pessoa física não produtor rural',
  },
  {
    value: IndComercializacaoEnum.ComercializacaoPorProdutorRural_3,
    name: '3 - Comercialização da produção por prod. rural PF/seg. especial - Vendas a PJ (exceto entidade inscrita no Programa de Aquisição de Alimentos - PAA) ou a intermediário PF',
  },
  {
    value: IndComercializacaoEnum.ComercializacaoProducaoIsenta_7,
    name: '7 - Comercialização da produção isenta de acordo com a Lei 13.606/2018 efetuada diretamente no varejo a consumidor final ou a outro produtor rural pessoa física por produtor rural pessoa física, inclusive por segurado especial, ou por pessoa física não produtor rural',
  },
  {
    value: IndComercializacaoEnum.ComercializacaoPorPessoaFisicaPAA_8,
    name: '8 - Comercialização da produção da pessoa física/segurado especial para entidade inscrita no PAA',
  },
  {
    value: IndComercializacaoEnum.ComercializacaoMercadoInterno_9,
    name: '9 - Comercialização da produção no mercado externo',
  },
]
