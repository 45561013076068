import { convertToArrayDescription } from '~/utils/utils'

export enum EventoIndCalculoEnum {
  Referencia = 0,
  Valor = 1,
  Logico = 2,
}

export const EventoIndCalculoLabels = {
  [EventoIndCalculoEnum.Referencia]: 'Referência',
  [EventoIndCalculoEnum.Valor]: 'Valor',
  [EventoIndCalculoEnum.Logico]: 'Lógico',
}

export const EventoIndCalculoValues = convertToArrayDescription(
  EventoIndCalculoEnum,
  EventoIndCalculoLabels,
)
