import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPost } from '~/utils/notification'
import { useInvalidate } from './useObterTodos'

import { EventoNaoIntegradoAdicionarDTO } from './dtos/EventoNaoIntegradoAdicionarDTO'
import { EventoNaoIntegrado } from './dtos/EventoNaoIntegrado'

interface RequestProps {
  data: EventoNaoIntegradoAdicionarDTO
}

export function useAdicionar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ data }: RequestProps) {
    const response = await api.post<{ data: EventoNaoIntegrado }>(
      '/EventosNaoIntegrados/Adicionar',
      data,
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
