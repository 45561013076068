import React, { useMemo, useState } from 'react'

import { ToolsDataTable } from '~/components'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import TableEventos from './components/TableEventos'

export default function Table(props) {
  const { data: _data, isLoading } = props

  const [rowsExpanded, setRowsExpanded] = useState([])

  const data = _data?.map((item) => {
    return {
      ...item,
      nrInscricao: item?.nrInscricao ? formatToCPFOrCNPJ(item?.nrInscricao) : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
    ],
    [],
  )

  function renderExpandableRow(indexParent) {
    const dataExpanded = data[indexParent]?.eventosValorEvento || []
    return <TableEventos data={dataExpanded} />
  }

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      optionsExpandable={{
        type: 'multiple',
        onRowExpanded: setRowsExpanded,
        expandedChildren: renderExpandableRow,
        rowsExpanded: rowsExpanded,
        expandOnClick: true,
      }}
    />
  )
}
