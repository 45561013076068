import React, { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Container, Grid, Paper, useTheme } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import {
  Button,
  PDFViewer,
  PageHeader,
  Checkbox,
  TextField,
  DatePickerNew,
  SimpleSelect,
  PrintButton,
} from '~/components'
import { AutoCompleteCID, AutoCompleteVinculo } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import {
  MotivoAfastamentoEnum,
  MotivoAfastamentoValues,
} from '~/@types/enums/MotivoAfastamentoEnum'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  funcionarioId: yup.string().required('Informe o Funcionário'),
  dtUltimoDiaTrabalhado: yup
    .date()
    .required('Informe a Data do Último Dia Trabalhado')
    .typeError('Informe uma data válida')
    .nullable(),
  dtAssinatura: yup
    .date()
    .required('Informe a Data da Assinatura')
    .typeError('Informe uma data válida')
    .nullable(),
})

const titlePage = 'Requerimento de Benefício por Incapacidade'

interface DataFormProps {
  funcionarioId: string
  temOutraAtividadePrevidenciaSocial: boolean
  cid: string
  dtUltimoDiaTrabalhado: Date | string | null
  motivoAfastamento: MotivoAfastamentoEnum
  observacao: string
  dtAssinatura: Date | string | null
}

const initialState: DataFormProps = {
  funcionarioId: '',
  temOutraAtividadePrevidenciaSocial: false,
  cid: '',
  dtUltimoDiaTrabalhado: null,
  motivoAfastamento: MotivoAfastamentoEnum.Doenca,
  observacao: '',
  dtAssinatura: getDateCurrent(),
}

export default function RequerimentoBeneficioIncapacidade() {
  const [data, setData] = useState(initialState)

  const theme = useTheme()
  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog(initialState)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      funcionarioId: '',
    }))
  }, [estabelecimento, anoMes])

  async function handleSubmit() {
    openPDFViewer(data)
    resetForm()
  }

  function resetForm() {
    setData(initialState)
  }

  return (
    <Box padding={2}>
      <PageHeader title={titlePage} />

      <Container maxWidth="md">
        <Paper
          style={{
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoCompleteVinculo
                label="Funcionário"
                estabelecimentoId={estabelecimento.id}
                anoMes={anoMes}
                vinculosTipo={[VinculoTipoEnum.Funcionario_1]}
                validationErrors={validationErrors}
                name="funcionarioId"
                required
                value={data.funcionarioId}
                onChange={(e, funcionario) => {
                  const funcionarioId = funcionario?.id || ''
                  setData((oldState) => ({
                    ...oldState,
                    funcionarioId,
                  }))
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <SimpleSelect
                label="Motivo Afastamento"
                options={MotivoAfastamentoValues}
                renderOption={(opt: FixLater) => opt.name}
                optionId="value"
                value={data.motivoAfastamento}
                onChange={(_: FixLater, motivoAfastamento: MotivoAfastamentoEnum) => {
                  setData((oldState) => ({
                    ...oldState,
                    motivoAfastamento,
                  }))
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Checkbox
                label="Tem outra atividade com vinculação à previdência social?"
                value={data.temOutraAtividadePrevidenciaSocial}
                onChange={(e, value) => {
                  const temOutraAtividadePrevidenciaSocial = value
                  setData((prev) => ({
                    ...prev,
                    temOutraAtividadePrevidenciaSocial,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AutoCompleteCID
                value={data.cid}
                name="cid"
                optionId="id"
                onChange={(e, cidObject) => {
                  const cid = cidObject?.id || ''
                  setData((oldState) => ({
                    ...oldState,
                    cid,
                  }))
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <DatePickerNew
                validationErrors={validationErrors}
                name="dtUltimoDiaTrabalhado"
                label="Data do Último Dia Trabalhado"
                required
                value={data.dtUltimoDiaTrabalhado}
                onChange={(date) => {
                  const dtUltimoDiaTrabalhado = date
                  setData((prev) => ({ ...prev, dtUltimoDiaTrabalhado }))
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <DatePickerNew
                validationErrors={validationErrors}
                name="dtAssinatura"
                label="Data da Assinatura"
                required
                value={data.dtAssinatura}
                onChange={(date) => {
                  const dtAssinatura = date
                  setData((prev) => ({ ...prev, dtAssinatura }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Observação"
                value={data.observacao}
                rows={3}
                multiline
                inputProps={{
                  maxLength: 255,
                }}
                onChange={(e) => {
                  const observacao = e.target.value
                  setData((prev) => ({
                    ...prev,
                    observacao,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonBox>
                <Button onClick={resetForm}>Cancelar</Button>
                <PrintButton onClick={handleValidate} />
              </ButtonBox>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="RequerimentoBeneficioIncapacidade"
        title={titlePage}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/RequerimentoBeneficioIncapacidade/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
