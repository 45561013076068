import moment from 'moment'

import { Grid } from '@material-ui/core'

import { Checkbox, DatePickerNew, TextField, SimpleAlert } from '~/components'
import { MUIAutoComplete, AutoCompleteMedico } from '~/components/AutoComplete'
import { useStepperContext } from '~/components/StepperForm'

import AvisoFimAfastamento from '../../AvisoFimAfastamento'

import { incrementDayToDate } from '~/hooks/useUtils'

import { TipoAcidenteTransitoValues } from '~/@types/enums/TipoAcidenteTransitoEnum'
import {
  IndMotivoAfastamentoEnum,
  IndMotivoAfastamentoValues,
} from '~/@types/enums/IndMotivoAfastamentoEnum'

import { VinculoAfastamentoForm } from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'

import { ContextProps } from '..'

const motivoAfastamentoAcidenteDoenca = [
  IndMotivoAfastamentoEnum.AcidenteDoenca_01,
  IndMotivoAfastamentoEnum.AcidenteDoencaTrabalho_03,
]

const motivoAfastamentoMaternidade = [
  IndMotivoAfastamentoEnum.LicencaMaternidade_17,
  IndMotivoAfastamentoEnum.LicencaMaternidade_18,
  IndMotivoAfastamentoEnum.LicencaMaternidade_19,
  IndMotivoAfastamentoEnum.LicencaMaternidade_20,
  IndMotivoAfastamentoEnum.LicencaMaternidade_35,
]

export const titleAllFieldsDisabled = 'Afastamento enviado para o eSocial, não é possível editar.'

export default function MainAfastamento() {
  const {
    dataControlled: data,
    onChangeControlled: setData,
    context,
  } = useStepperContext<VinculoAfastamentoForm>()
  const {
    disabledAllFields: hasReciboEsocial,
    disabledFields,
    validationErrors,
  } = context as ContextProps

  const isNewAfastamento = data?.id ? false : true

  function handleChangeDtInicio(date: string | null) {
    const dtInicioAfastamento = date as string

    const qtdDiasAfastado = data.qtdDiasAfastado

    if (qtdDiasAfastado && dtInicioAfastamento) {
      setData({
        ...data,
        dtInicioAfastamento,
        dtRetorno: moment(dtInicioAfastamento)
          .add(qtdDiasAfastado - 1, 'days')
          .format('yyyy-MM-DD'),
      })
    } else {
      setData({
        ...data,
        dtInicioAfastamento,
      })
    }
  }

  function handleChangeMotivoAfastamento(motivoAfastamento: IndMotivoAfastamentoEnum) {
    const dtInicioAfastamento = data?.dtInicioAfastamento
    const dtRetorno = data?.dtRetorno

    setData((oldState) => ({
      ...oldState,
      motivoAfastamento,
    }))

    if (motivoAfastamentoAcidenteDoenca.includes(motivoAfastamento)) {
      if (!dtInicioAfastamento || dtRetorno) return

      const dtInicioBeneficio = incrementDayToDate(dtInicioAfastamento, 15)
      const dtInicioBeneficioFormatted = moment(dtInicioBeneficio).format('yyyy-MM-DD')

      setData((oldState) => ({
        ...oldState,
        dtInicioBeneficio: dtInicioBeneficioFormatted,
      }))
    }

    if (motivoAfastamentoMaternidade.includes(motivoAfastamento) && dtInicioAfastamento) {
      let dtRetorno = null as any

      if (motivoAfastamento === IndMotivoAfastamentoEnum.LicencaMaternidade_17) {
        dtRetorno = incrementDayToDate(dtInicioAfastamento, 119)
      }
      if (motivoAfastamento === IndMotivoAfastamentoEnum.LicencaMaternidade_18) {
        dtRetorno = incrementDayToDate(dtInicioAfastamento, 59)
      }
      if (motivoAfastamento === IndMotivoAfastamentoEnum.LicencaMaternidade_19) {
        dtRetorno = incrementDayToDate(dtInicioAfastamento, 13)
      }

      setData((oldState) => ({
        ...oldState,
        dtInicioBeneficio: dtInicioAfastamento,
        dtRetorno,
      }))
    }
  }

  return (
    <Grid container spacing={2}>
      {isNewAfastamento && (
        <Grid item xs={12}>
          <SimpleAlert severity="info">
            <div>
              A data a ser informada no campo <b>Início</b> deve ser a data do efetivo afastamento
              do trabalhador.
            </div>
          </SimpleAlert>
        </Grid>
      )}

      {isNewAfastamento && (
        <Grid item xs={12}>
          <AvisoFimAfastamento />
        </Grid>
      )}

      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <DatePickerNew
          label="Início"
          size="small"
          value={data?.dtInicioAfastamento || null}
          validationErrors={validationErrors}
          name="dtInicioAfastamento"
          onChange={handleChangeDtInicio}
          disabled={disabledFields.some((d) => d === 'dtInicioAfastamento') || hasReciboEsocial}
          required
        />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
        <MUIAutoComplete
          label="Motivo do Afastamento"
          required
          name="motivoAfastamento"
          validationErrors={validationErrors}
          value={data.motivoAfastamento}
          options={IndMotivoAfastamentoValues}
          disabled={disabledFields.some((d) => d === 'motivoAfastamento') || hasReciboEsocial}
          optionId="value"
          renderOption={(option) => option.name}
          onChange={(_, obj: any) => handleChangeMotivoAfastamento(obj.value)}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          name="dtRetorno"
          label="Fim do Afastamento"
          size="small"
          value={data?.dtRetorno || null}
          onChange={(date) => {
            const dtRetorno = date
            let dtInicioBeneficio = null

            if (
              dtRetorno &&
              data?.dtInicioAfastamento &&
              data?.motivoAfastamento &&
              motivoAfastamentoAcidenteDoenca.includes(data.motivoAfastamento)
            ) {
              const dtInicioAfastamentoMoment = moment(data?.dtInicioAfastamento)
              const dtRetornoMoment = moment(dtRetorno)
              const diferencaEmDias = dtRetornoMoment.diff(dtInicioAfastamentoMoment, 'days')
              if (diferencaEmDias >= 15) {
                dtInicioBeneficio = dtInicioAfastamentoMoment.add(15, 'days').format('yyyy-MM-DD')
              }
            }

            setData((oldState) => ({
              ...oldState,
              dtRetorno,
              dtInicioBeneficio,
              dtPrevistaRetorno: null,
            }))
          }}
          validationErrors={validationErrors}
          disabled={hasReciboEsocial}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          label="Previsão Retorno"
          size="small"
          value={data?.dtPrevistaRetorno || null}
          disabled={data?.dtRetorno ? true : false}
          onChange={(date) => {
            const dtPrevistaRetorno = date
            setData({ ...data, dtPrevistaRetorno })
          }}
          name="dtPrevistaRetorno"
          validationErrors={validationErrors}
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <DatePickerNew
          label="Início Benefício"
          size="small"
          value={data?.dtInicioBeneficio || null}
          onChange={(date) => {
            const dtInicioBeneficio = date
            setData({ ...data, dtInicioBeneficio })
          }}
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <Checkbox
          label="Mesmo motivo anterior"
          value={data?.isAfastamentoMesmoMotivo || false}
          onChange={(e, value) => {
            const isAfastamentoMesmoMotivo = value
            setData({ ...data, isAfastamentoMesmoMotivo })
          }}
          disabled={hasReciboEsocial}
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <MUIAutoComplete
          label="Tipo Acidente"
          options={TipoAcidenteTransitoValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="tipoAcidenteTransito"
          value={data.tipoAcidenteTransito}
          onChange={(e, obj) => {
            const tipoAcidenteTransito = obj ? obj.value : ''
            setData({ ...data, tipoAcidenteTransito })
          }}
          disabled={hasReciboEsocial}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Nr. Benefício"
          fullWidth
          value={data?.nrBeneficio || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 30,
          }}
          onChange={(e) => {
            const nrBeneficio = e.target.value
            setData({ ...data, nrBeneficio })
          }}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Qtd. Dias Afastado"
          fullWidth
          value={data?.qtdDiasAfastado || '0'}
          size="small"
          onlyNumber
          disabled={disabledFields.some((d) => d === 'qtdDiasAfastado') || hasReciboEsocial}
          inputProps={{ maxLength: 9 }}
          onChange={(e) => {
            const qtdDiasAfastado = parseInt(e.target.value || '0')
            setData({ ...data, qtdDiasAfastado })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <AutoCompleteMedico
          value={data?.medicoId}
          optionId="id"
          onChange={(e, obj) => {
            const medicoId = obj?.id || ''
            setData({ ...data, medicoId })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <Checkbox
          label="Realizar a tributação do auxílio enfermidade"
          value={data?.isEnfermidadeTributavel || false}
          onChange={(e, value) => {
            const isEnfermidadeTributavel = value
            setData({ ...data, isEnfermidadeTributavel })
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Observação"
          fullWidth
          value={data?.observacao || ''}
          size="small"
          multiline
          inputProps={{
            maxLength: 250,
          }}
          onChange={(e) => {
            const observacao = e.target.value
            setData({ ...data, observacao })
          }}
          disabled={hasReciboEsocial}
        />
      </Grid>

      <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
        <TextField label="Recibo eSocial" value={data?.reciboEsocial || ''} disabled />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePickerNew label="Data Recibo eSocial" value={data?.dtReciboEsocial || null} disabled />
      </Grid>
    </Grid>
  )
}
