import React from 'react'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, IconButton, InputAdornment } from '@material-ui/core'
import { DoneAll as DoneAllIcon, Clear as ClearIcon } from '@material-ui/icons'

import { ErrorMessage } from 'mio-library-ui'

import PropTypes from 'prop-types'

const obterErro = (name, validationErrors) => {
  if (!validationErrors) return false

  const { inner } = validationErrors
  const erroEncontrado = inner.find((item) => {
    const { path } = item
    return name === path
  })

  if (!erroEncontrado) return false

  return erroEncontrado.message
}

export default function MultipleSelect(props) {
  const {
    inputProps,
    name,
    validationErrors,
    isLoading,
    isSelectAll,
    onChange,
    options,
    value,
    ...rest
  } = props

  const mensagemDeErro = obterErro(name, validationErrors)

  function handleChange(event, value, reason, details) {
    onChange(event, value, reason, details)
  }

  function handleSelectAll(event) {
    onChange(event, options, 'selectAllButton')
  }

  function handleUnselectAll(event) {
    onChange(event, [], 'unselectAllButton')
  }

  function changeSelectButton() {
    if (options?.length === value?.length)
      return (
        <IconButton size="small" title="Remover todos" onClick={handleUnselectAll}>
          <ClearIcon />
        </IconButton>
      )
    return (
      <IconButton size="small" title="Adicionar todos" onClick={handleSelectAll}>
        <DoneAllIcon />
      </IconButton>
    )
  }

  return (
    <>
      <Autocomplete
        loading={isLoading}
        loadingText="Carregando..."
        multiple
        id="multiple-select-autocomplete"
        filterSelectedOptions
        closeText="Fechar"
        openText="Abrir"
        noOptionsText="Sem resultados"
        clearText="Limpar"
        fullWidth
        size="small"
        disableClearable={isSelectAll}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            name={name}
            InputProps={
              isSelectAll && !isLoading
                ? {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <InputAdornment position="end">{changeSelectButton()}</InputAdornment>
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }
                : params.InputProps
            }
            {...inputProps}
          />
        )}
        onChange={handleChange}
        options={options}
        value={value}
        {...rest}
      />
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </>
  )
}

MultipleSelect.defaultProps = {
  name: '',
  validationErrors: false,
  isLoading: false,
  isSelectAll: true,
}

MultipleSelect.propTypes = {
  inputProps: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSelectAll: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  validationErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  value: PropTypes.array,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  limitTags: PropTypes.number,
}
