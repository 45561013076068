import { useState } from 'react'
import moment from 'moment'

import * as yup from 'yup'

import { Box, Grid, useTheme } from '@material-ui/core'

import {
  ActionDialog,
  Button,
  ButtonBox,
  DatePickerNew,
  ToolsDataTable,
  PDFViewer,
  PrintButton,
  Checkbox,
} from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useObterFaltasPeriodo from '~/hooks/queries/VinculoFaltas/useObterFaltasPeriodo'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { agruparPorTipo } from '~/hooks/useUtils'

enum ActionType {
  PRINT,
  SEARCH,
}

const TITLE_PAGE = 'Faltas por Período'

const schema = yup.object().shape({
  dtInicioPeriodo: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe o Início do Período')
    .nullable(),
  dtFimPeriodo: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe o Fim do Período')
    .nullable(),
})

interface DialogFaltasPeriodoProps {
  isOpen: boolean
  onClose: () => void
  vinculoId: string
}

export default function DialogFaltasPeriodo({
  isOpen,
  onClose,
  vinculoId: _vinculoId,
}: DialogFaltasPeriodoProps) {
  const { ano, mes, estabelecimento } = useAmbiente()

  const momentDate = moment(`${ano}-${mes}-01`)
  const dtFimPeriodo = momentDate.endOf('month').format('yyyy-MM-DD')
  const dtInicioPeriodo = momentDate.startOf('year').format('yyyy-MM-DD')

  const [data, setData] = useState<{
    dtInicioPeriodo: string
    dtFimPeriodo: string
    vinculoId?: string
  }>({
    dtInicioPeriodo,
    dtFimPeriodo,
    vinculoId: _vinculoId,
  })

  const { close: closePDFViewer, isOpen: isOpenPDFViewer, open: openPDFViewer } = useDialog(null)
  const theme = useTheme()

  const {
    data: _data,
    isLoading,
    isFetching,
    refetch,
  } = useObterFaltasPeriodo({
    vinculoId: data.vinculoId,
    estabelecimentoId: estabelecimento.id,
    dtInicioPeriodo: data.dtInicioPeriodo,
    dtFimPeriodo: data.dtFimPeriodo,
  })
  const collection = agruparPorTipo(_data || [], 'competencia')

  async function handleSubmit(actionType: ActionType) {
    if (actionType === ActionType.PRINT) {
      openPDFViewer(null)
    } else {
      await refetch()
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'funcionarioNrInscricao',
      label: 'CPF',
    },
    {
      name: 'funcionarioNome',
      label: 'Nome',
    },
    {
      name: 'dtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'dtRescisao',
      label: 'Rescisão',
    },
    {
      name: 'indLancamentoDesc',
      label: 'Tipo',
    },
    {
      name: 'dtFaltaDesc',
      label: 'Data',
    },
    {
      name: 'qtdHorasDesc',
      label: 'Horas',
    },
    {
      name: 'tipoFaltaDesc',
      label: 'Tipo de Falta',
    },
    {
      name: 'isDSRDesc',
      label: 'DSR',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
  ]

  return (
    <ActionDialog
      title={TITLE_PAGE}
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
      okLabel="Imprimir"
      customActions={
        <ButtonBox right={1} bottom={1}>
          <PrintButton onClick={() => handleValidate(ActionType.PRINT)} />
        </ButtonBox>
      }
    >
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
        <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <DatePickerNew
                label="Início do Período"
                name="dtInicioPeriodo"
                validationErrors={validationErrors}
                required
                value={data?.dtInicioPeriodo || null}
                onChange={(date) => {
                  const dtInicioPeriodo = date || ''
                  setData((prev) => ({ ...prev, dtInicioPeriodo }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <DatePickerNew
                label="Fim do Período"
                name="dtFimPeriodo"
                validationErrors={validationErrors}
                required
                value={data?.dtFimPeriodo || null}
                onChange={(date) => {
                  const dtFimPeriodo = date || ''
                  setData((prev) => ({ ...prev, dtFimPeriodo }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Checkbox
                label="Relatório Individual"
                value={data.vinculoId ? true : false}
                onChange={() =>
                  setData((prev) => ({ ...prev, vinculoId: prev?.vinculoId ? '' : _vinculoId }))
                }
              />
            </Grid>
          </Grid>
          <Button variant="contained" onClick={() => handleValidate(ActionType.SEARCH)}>
            Buscar
          </Button>
        </Box>

        <Box position="relative" overflow="auto">
          <ToolsDataTable
            data={collection}
            columns={[
              {
                name: 'field',
                label: 'Competência',
              },
            ]}
            isLoading={isLoading}
            isFetching={isFetching}
            disableAutoHeight
            optionsExpandable={{
              type: 'multiple',
              expandedChildren: (index) => (
                <ToolsDataTable
                  data={collection[index]?.data || []}
                  columns={columns}
                  isLoading={isLoading}
                  isFetching={isFetching}
                  disableAutoHeight
                  pagination={false}
                />
              ),
            }}
          />
        </Box>
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="FaltasPeriodo"
        title={TITLE_PAGE}
        axiosConfig={{
          method: 'get',
          url: '/VinculoFaltas/ObterRelatorioFaltasPeriodo',
          params: {
            vinculoId: data.vinculoId,
            dtInicioPeriodo: data.dtInicioPeriodo,
            dtFimPeriodo: data.dtFimPeriodo,
            estabelecimentoId: estabelecimento.id,
          },
        }}
      />
    </ActionDialog>
  )
}
