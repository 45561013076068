import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { IndConsultaVencimentosEnum } from '~/@types/enums/IndConsultaVencimentosEnum'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

export interface ConsultaVencimentosFuncionarioDTO {
  estabelecimentoId: string
  estabelecimentoTipoInscricao: TipoInscricaoEnum
  estabelecimentoNrInscricao: string
  estabelecimentoNome: string
  funcionarioId: string
  funcionarioCPF: string
  funcionarioNome: string
  funcionarioDtAdmissao: Date | string
  funcionarioDtRescisao: Date | string | null
  dtInicio: Date | string
  dtVencimento: Date | string | null
  tipoConsulta: IndConsultaVencimentosEnum
}

const query = '/ConsultaVencimentos/ObterConsultaVencimentos'

interface RequestProps {
  params: {
    dtInicio: string
    dtVencimento: string
  }
  data: IndConsultaVencimentosEnum[]
}

export function useObterConsultaVencimentos({ data, params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: ConsultaVencimentosFuncionarioDTO[] }>(query, data, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, data, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
