import { useMemo } from 'react'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import appLogo from '~/assets/images/logoTopo2.png'
import smallAppLogo from '~/assets/images/miniLogo2.png'

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px',
  },
  nomeSistema: {
    fontFamily: 'roboto',
    fontWeight: 'normal',
    color: theme.palette.primary.contrastText,
    marginTop: '4px',
  },
}))

interface LogoProps {
  smallLogo: boolean
}

export default function Logo(props: LogoProps) {
  const { smallLogo } = props

  const classes = useStyles()

  const version = useMemo(() => {
    return import.meta.env.VITE_VERSION || ''
  }, [])

  return (
    <Box className={classes.logoContainer}>
      <img src={!smallLogo ? appLogo : smallAppLogo} alt="App Logo Folha" />
      <Typography
        variant="body2"
        className={classes.nomeSistema}
        style={{ fontWeight: !smallLogo ? '400' : '700' }}
      >
        {!smallLogo ? 'Folha' : 'FP'}
      </Typography>

      {!smallLogo && (
        <Typography className={classes.nomeSistema} variant="body2">
          {`versão: ${version}`}
        </Typography>
      )}
    </Box>
  )
}
