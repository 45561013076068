import { LinearProgress, Typography, Box } from '@material-ui/core'

interface LinearProgressLabelProps {
  progress: number
}

export default function LinearProgressLabel({ progress }: LinearProgressLabelProps) {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>
  )
}
