import React from 'react'

import { IconButton } from '@material-ui/core'
import { Print as PrintIcon } from '@material-ui/icons'
import { ButtonBox } from 'mio-library-ui'

import { formatToCPF } from 'brazilian-values'

import { ToolsDataTable } from '~/components'

import moment from 'moment'

const Table = (props) => {
  const { data: _data, isLoading, handleClickPrint } = props

  const data = _data.map((d) => ({
    ...d,
    cpf: d?.cpf ? formatToCPF(d.cpf) : '',
    dtPeriodoInicio: d?.dtPeriodoInicio ? moment(d.dtPeriodoInicio).format('DD/MM/yyyy') : '',
    dtPeriodoFim: d?.dtPeriodoFim ? moment(d.dtPeriodoFim).format('DD/MM/yyyy') : '',
    dtAbonoInicio: d?.dtAbonoInicio ? moment(d.dtAbonoInicio).format('DD/MM/yyyy') : '',
    dtAbonoFim: d?.dtAbonoFim ? moment(d.dtAbonoFim).format('DD/MM/yyyy') : '',
    diasAbono: d?.diasAbono ? moment(d.diasAbono).format('DD/MM/yyyy') : '',
  }))

  const columns = [
    { name: 'cpf', label: 'CPF' },
    { name: 'nome', label: 'Nome' },
    { name: 'dtPeriodoInicio', label: 'Período Início' },
    { name: 'dtPeriodoFim', label: 'Período Fim' },
    { name: 'dtAbonoInicio', label: 'Início Abono' },
    { name: 'dtAbonoFim', label: 'Fim Abono' },
    { name: 'diasAbono', label: 'Dias de Abono' },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Imprimir Requerimento de Abono Pecuniário de Férias"
                color="primary"
                aria-label="Imprimir"
                onClick={() => handleClickPrint(value)}
                size="small"
              >
                <PrintIcon fontSize="small" color="primary" size="small" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <ToolsDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
