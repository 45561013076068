const statusProcessTransmissao_wait_1 = 1
const statusProcessTransmissao_process_2 = 2
const statusProcessTransmissao_finish_3 = 3
const statusProcessTransmissao_finish_warning_4 = 4
const statusProcessTransmissao_error_5 = 5

export const statusProcessTransmissaoValues = [
  { value: statusProcessTransmissao_wait_1, name: 'Aguardando' },
  { value: statusProcessTransmissao_process_2, name: 'Processando' },
  { value: statusProcessTransmissao_finish_3, name: 'Finalizado' },
  {
    value: statusProcessTransmissao_finish_warning_4,
    name: 'Finalizado Advertência',
  },
  { value: statusProcessTransmissao_error_5, name: 'Erro' },
]

export const statusProcessTransmissaoConsts = {
  statusProcessTransmissao_wait_1,
  statusProcessTransmissao_process_2,
  statusProcessTransmissao_finish_3,
  statusProcessTransmissao_finish_warning_4,
  statusProcessTransmissao_error_5,
}
