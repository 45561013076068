import React, { useMemo } from 'react'

import { ToolsDataTable } from '~/components'

import TableFuncionarios from './components/TableFuncionarios'

export default function Table(props) {
  const { data, isLoading, query, rowsSelected, handleRowSelected, rowsExpanded, setRowsExpanded } =
    props

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nrInscricaoString',
        label: 'Número de Inscrição',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'quantidadeAdmissoes',
        label: 'Admissões',
      },
      {
        name: 'quantidadeDemissoes',
        label: 'Demissões',
      },
    ],
    [],
  )

  function renderExpandableRow(index) {
    const dataTableExpanded = data[index].funcionarios
    return <TableFuncionarios data={dataTableExpanded} />
  }

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      optionsSelectable={{
        type: 'multiple',
        rowsSelected: rowsSelected,
        onRowSelected: handleRowSelected,
      }}
      optionsExpandable={{
        expandedChildren: renderExpandableRow,
        rowsExpanded: rowsExpanded,
        onRowExpanded: setRowsExpanded,
        expandOnClick: true,
        type: 'multiple',
      }}
      sherlock={{
        columns: ['codigo', 'nrInscricao', 'nome'],
        query,
      }}
    />
  )
}
