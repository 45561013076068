import React, { useState } from 'react'

import { ContainerComponent } from '~/components'

import Form from './components/Form'
import EventoFormulas from './components/EventoFormulas'
import Empregadores from './components/Empregadores'
import EventosRelacionados from './components/EventosRelacionados'

const Container = (props) => {
  const { isOpen, onClose, data, onAfterSubmitForm } = props
  const [wasModified, setWasModified] = useState(false)

  return (
    <ContainerComponent
      title="Cadastro de Evento"
      subtitle={data?.codigo ? `${data?.codigo} - ${data?.nome}` : '-'}
      isOpen={isOpen}
      onClose={onClose}
      formWasModified={wasModified}
      tabs={[
        {
          component: (
            <Form
              data={data}
              onAfterSubmitForm={onAfterSubmitForm}
              onClose={onClose}
              setWasModified={setWasModified}
            />
          ),
          label: 'Cadastro',
        },
        {
          component: <EventoFormulas eventoId={data?.id} onClose={onClose} />,
          disabled: !data?.id ? true : false,
          label: 'Fórmulas',
        },
        {
          component: <Empregadores data={data} />,
          disabled: !data?.id ? true : false,
          label: 'Empregadores Relacionados',
        },
        {
          component: <EventosRelacionados data={data} />,
          disabled: !data?.id ? true : false,
          label: 'Eventos Relacionados',
        },
      ]}
    />
  )
}

export default Container
