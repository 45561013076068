import React from 'react'

import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Grid, LinearProgress } from '@material-ui/core'

import { AutoCompleteEvento } from '~/components/AutoComplete'
import { Button, CurrencyTextField, DatePickerNew, TextField } from '~/components'

import {
  useVinculoEventoAutomaticoById,
  useVinculoEventoAutomaticoMutation,
} from '~/hooks/queries/useVinculoEventoAutomatico'
import useValidationErrors from '~/hooks/useValidationErrors'

const schema = yup.object().shape({
  evento: yup.string().required('Informe Evento').nullable(),
  dtInicio: yup.string().required('Informe Data Início').nullable(),
})

interface FormProps {
  vinculoEventoAutomaticoId: string | null
  vinculoId: string
  onClose: () => void
}

export default function Form(props: FormProps) {
  const { vinculoEventoAutomaticoId, vinculoId, onClose } = props

  const { data, setData, isLoading } = useVinculoEventoAutomaticoById(
    vinculoEventoAutomaticoId,
    vinculoId,
  )
  const { mutateAsync, isLoading: isSubmitting } = useVinculoEventoAutomaticoMutation()
  const { handleValidate, validationErrors } = useValidationErrors({ data, schema, handleSubmit })

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
  }

  if (isLoading) {
    return <LinearProgress />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompleteEvento
          label="Evento"
          required
          validationErrors={validationErrors}
          name="evento"
          onChange={(e: FixLater, evento: FixLater) => {
            const eventoId = evento ? evento.id : null
            setData({ ...data, eventoId, evento })
          }}
          value={data.evento || null}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          label="Data Início"
          size="small"
          value={data.dtInicio || null}
          required
          validationErrors={validationErrors}
          name="dtInicio"
          onChange={(date) => {
            const dtInicio = date
            setData({
              ...data,
              dtInicio,
            })
          }}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          label="Data Fim"
          size="small"
          value={data.dtFim || null}
          onChange={(date) => {
            const dtFim = date
            setData({
              ...data,
              dtFim,
            })
          }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <CurrencyTextField
          label="Quantidade"
          fullWidth
          variant="outlined"
          size="small"
          value={data?.quantidade || ''}
          onChange={(e, value) => {
            const quantidade = value || undefined
            setData({ ...data, quantidade })
          }}
        />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
        <CurrencyTextField
          label="Valor Evento"
          fullWidth
          value={data?.vrEvento || ''}
          variant="outlined"
          size="small"
          onChange={(e, value) => {
            const vrEvento = value || null
            setData({ ...data, vrEvento })
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Observação"
          value={data.observacao || ''}
          inputProps={{ maxLength: 200 }}
          onChange={(e) => {
            const observacao = e.target.value
            setData({ ...data, observacao })
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ButtonBox>
          <Button onClick={onClose}>Cancelar</Button>
          <Button isLoading={isSubmitting} onClick={handleValidate} variant="contained">
            Salvar
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
