import { TermoRescisaoDTOForm } from './TermoRescisaoDTO'

import useSimpleStartRequest from '../useSimpleStartRequest'

export function useObterDataParaCriarRescisao(vinculoId: string, anoMes: string) {
  const { data, isLoading, setData } = useSimpleStartRequest<TermoRescisaoDTOForm | null>(
    {
      url: '/RP/ObterDataParaCriarRescisao',
      method: 'GET',
      params: {
        vinculoId,
        anoMes,
      },
    },
    null,
    [vinculoId, anoMes],
  )
  return { data, setData, isLoading }
}
