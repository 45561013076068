const naoAplicavelIndPensaoAlimenticia_9 = 9
const salarioMinimo_1 = 1
const salarioLiquido_2 = 2
const salarioBruto_3 = 3
const valor_4 = 4

export const indPensaoAlimenticiaValues = [
  { value: naoAplicavelIndPensaoAlimenticia_9, name: '9 - Não informar' },
  { value: salarioMinimo_1, name: '1 - Percentual Salário Mínimo' },
  { value: salarioLiquido_2, name: '2 - Percentual Liquido' },
  { value: salarioBruto_3, name: '3 - Percentual Bruto' },
  { value: valor_4, name: '4 - Valor' },
]
export const indPensaoAlimenticiaConsts = {
  naoAplicavelIndPensaoAlimenticia_9,
  salarioMinimo_1,
  salarioLiquido_2,
  salarioBruto_3,
  valor_4,
}
