import moment from 'moment'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { CopyLabel, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { EtiquetaAvisoPrevioAvisoPrevioDTO } from '~/hooks/queries/EtiquetaAvisoPrevio/EtiquetaAvisoPrevioDTO'

interface TableProps {
  data: EtiquetaAvisoPrevioAvisoPrevioDTO[]
  isLoading: boolean
  isFetching: boolean
  onRowSelected: (indexs: number[]) => void
  rowsSelected: number[]
  query: string
}

export default function Table(props: TableProps) {
  const { data, isLoading, onRowSelected, rowsSelected, query } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'estabelecimentoNrInscricao',
      label: 'CNPJ',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'estabelecimentoNome',
      label: 'Estabelecimento',
    },
    {
      name: 'funcionarioCPF',
      label: 'CPF',
      options: {
        customBodyRender: (value) => formatToCPFOrCNPJ(value),
      },
    },
    {
      name: 'funcionarioNome',
      label: 'Funcionário',
    },
    {
      name: 'dtAdmissao',
      label: 'Admissão',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
      },
    },
    {
      name: 'dtRescisao',
      label: 'Rescisão',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      isLoading={isLoading}
      columns={columns}
      sherlock={{
        columns: [
          'estabelecimentoNrInscricao',
          'estabelecimentoNome',
          'funcionarioCPF',
          'funcionarioNome',
        ],
        query,
      }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
    />
  )
}
