import { useMutation, useQueryClient, useQuery as useTQuery } from 'react-query'
import api from '~/services/api-pessoal'
import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'

export async function getAcordoDissidioConvencao() {
  const response = await api.get('/acordoDissidioConvencao')
  return response.data.data
}

export function useQuery() {
  const dialogNotification = useDialogNotification()
  return useTQuery(['AcordoDissidioConvencao'], () => getAcordoDissidioConvencao(), {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useSubmit() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function updateAcordoDissidioConvencao(data) {
    const response = await api.put(`/acordoDissidioConvencao/${data.id}`, data)
    notification.put()
    return response.data.data
  }
  async function insertAcordoDissidioConvencao(data) {
    const response = await api.post('/acordoDissidioConvencao', data)
    notification.post()
    return response.data.data
  }

  return useMutation(
    async (data) => {
      if (data?.id) {
        return updateAcordoDissidioConvencao(data)
      }
      return insertAcordoDissidioConvencao(data)
    },
    {
      onSuccess: async (data) => {
        await queryClient.cancelQueries('AcordoDissidioConvencao')
        queryClient.setQueriesData('AcordoDissidioConvencao', (oldData) => {
          if (!(oldData?.length > 0)) return [data]

          let finded = false
          const newData = oldData.map((obj) => {
            if (obj.id === data.id) {
              finded = true
              return data
            }
            return obj
          })
          if (finded) return newData
          return [data, ...oldData]
        })
      },
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}

export function useDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function deleteAcordoDissidioConvencao(id) {
    await api.delete(`/acordoDissidioConvencao/${id}`)
    notification.remove()
    return id
  }

  return useMutation((id) => deleteAcordoDissidioConvencao(id), {
    onSuccess: async (id) => {
      await queryClient.cancelQueries('AcordoDissidioConvencao')
      queryClient.setQueriesData('AcordoDissidioConvencao', (old) => {
        const newData = old?.filter((d) => d.id !== id)
        return newData
      })
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
