import { call, put, select } from 'redux-saga/effects'
import api from '~/services/api-pessoal'

import { Creators as MenuVinculoActions } from '../ducks/menuVinculo'

const obterAmbiente = (state) => state.app.ambiente

export function* obterMenuVinculo(action) {
  const { filters } = action.payload
  try {
    const ambiente = yield select(obterAmbiente)

    if (!ambiente) {
      yield put(
        MenuVinculoActions.failureMenuVinculo(
          'Não foi possível determinar o ambiente para carregar os vínculos.',
        ),
      )
      return
    }
    const estabelecimentoId = ambiente.empresa.estabelecimento.id
    const anoMes = ambiente.competencia.anoMes
    const response = yield call(
      api.post,
      `RP/GetVinculosMenu2?estabelecimentoId=${estabelecimentoId}&anoMes=${anoMes}`,
      filters,
    )
    yield put(MenuVinculoActions.successMenuVinculo(response.data.data))
  } catch (err) {
    // console.log(err)
    // yield put(MenuVinculoActions.failureMenuVinculo(err.message))
    const msg = String(err.response.data.errors)
    yield put(MenuVinculoActions.failureMenuVinculo(msg))
    yield put(MenuVinculoActions.successMenuVinculo([]))
  }
}
