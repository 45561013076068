import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import { IRRFBeneficiarioDTO } from '../ApurarEncargos/ApuracaoESocialDTO'

const queryObterIRRFBeneficiario = '/ApurarESocial/ObterIRRFBeneficiario'

interface RequestProps {
  empregadorId: string
  periodoApuracao: string
  indApuracao: IndApuracaoEnum
  isSomenteDiferenca: boolean
}

export default function useObterIRRFBeneficiario(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: IRRFBeneficiarioDTO[] }>(queryObterIRRFBeneficiario, {
      params,
    })
    return response.data.data
  }

  return useQuery([queryObterIRRFBeneficiario, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
