import React, { useMemo } from 'react'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { IconButton } from '@material-ui/core'

import { ButtonBox } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import { useQuery } from '~/hooks/queries/useSindicatoConvencaoColetiva'
import useUtils from '~/hooks/useUtils'

export default function Table(props) {
  const { sindicato, onItemClick } = props
  const { data: _data, isLoading } = useQuery(sindicato?.id)

  const { dateFormatDayMonthYear } = useUtils()

  const data = _data?.map((item) => {
    return {
      ...item,
      dtConvencaoColetiva: dateFormatDayMonthYear(item.dtConvencaoColetiva),
      dtPeriodoInicio: item?.dtPeriodoInicio ? dateFormatDayMonthYear(item.dtPeriodoInicio) : '',
      dtPeriodoFim: item?.dtPeriodoFim ? dateFormatDayMonthYear(item?.dtPeriodoFim) : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtConvencaoColetiva',
        label: 'Data Convenção Coletiva',
      },
      {
        name: 'dtPeriodoInicio',
        label: 'Data Período Início',
      },
      {
        name: 'dtPeriodoFim',
        label: 'Data Período Fim',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  size="small"
                  onClick={() => {
                    const dataFinded = _data?.find((d) => d.id === value)
                    onItemClick('edit', dataFinded)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" size="small" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  size="small"
                  onClick={() => onItemClick('delete', value)}
                >
                  <DeleteIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [_data, onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}
