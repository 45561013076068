import React from 'react'

import PropTypes from 'prop-types'

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { Box, TextField } from '@material-ui/core'
import { ErrorMessage } from 'mio-library-ui'
import { obterErroValidationErrors } from '~/hooks/useUtils'

const suffix = ' %'

const PercentInput = (props) => {
  const { inputRef, maskOptions, decimalLimit, integerLimit, ...rest } = props

  const defaultMaskOptions = {
    prefix: '',
    suffix: suffix,
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: decimalLimit,
    integerLimit: integerLimit,
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  return (
    <MaskedInput
      mask={currencyMask}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      {...rest}
    />
  )
}

const PercentTextField = (props) => {
  const {
    value: _value,
    onChange,
    name,
    validationErrors,
    maxValue,
    minValue,
    decimalLimit,
    integerLimit,
    onBlur,
    ...rest
  } = props

  const mensagemDeErro = obterErroValidationErrors(name, validationErrors)

  function formatToString(valueNumber) {
    if (!valueNumber) return ''
    return valueNumber.toString().replace('.', ',') + suffix
  }

  function formatToNumber(valueString) {
    if (!valueString) return 0
    const valueFormated = parseFloat(valueString.replace(suffix, '').replace(',', '.'))
    if (valueFormated > maxValue) return maxValue
    if (valueFormated < minValue) return minValue
    return valueFormated
  }

  function handleChange(e) {
    if (!onChange) return
    const valueFormated = formatToNumber(e.target.value)
    onChange(e, valueFormated)
  }

  function handleOnBlur(e) {
    if (!onBlur) return
    const valueFormated = formatToNumber(e.target.value)
    onBlur(e, valueFormated)
  }

  const value = formatToString(_value)

  return (
    <Box>
      <TextField
        value={value}
        onChange={handleChange}
        onBlur={handleOnBlur}
        InputProps={{
          inputComponent: PercentInput,
        }}
        size="small"
        variant="outlined"
        fullWidth
        inputProps={{
          style: {
            textAlign: 'right',
          },
          decimalLimit,
          integerLimit,
        }}
        {...rest}
      />
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </Box>
  )
}

PercentInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
}

PercentInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
}

PercentTextField.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  decimalLimit: PropTypes.number,
  integerLimit: PropTypes.number,
}

PercentTextField.defaultProps = {
  maxValue: 100,
  minValue: 0,
  decimalLimit: 2,
  integerLimit: 3,
}

export default PercentTextField
