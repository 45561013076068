import React from 'react'
import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

interface ButtonBoxProps {
  children: React.ReactNode
  /**
   * Espaçamento do top
   * paddingTop
   */
  top?: number
  /**
   * Espaçamento da margem direita
   * paddingRight
   */
  right?: number
  /**
   * Espaçamento inferior
   * paddingBottom
   */
  bottom?: number
  /**
   * Espaçamento da margem esquerda
   * paddingLeft
   */
  left?: number
  /**
   * Tamanho da largura que os botão irão ter,
   * O valor padrão é "auto"
   */
  buttonsWidth?: number | string
  /**
   * Espaçamento entre os botões
   */
  spacing?: number
  /**
   * alinhamento horizontal
   */
  justifyContent?: string
  /**
   * tamanho horizontal
   */
  width?: number | string
}

const useStyles = makeStyles<Theme, ButtonBoxProps>((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) => props.justifyContent,
    paddingTop: (props) => theme.spacing(props?.top || 0),
    paddingRight: (props) => theme.spacing(props?.right || 0),
    paddingBottom: (props) => theme.spacing(props?.bottom || 0),
    paddingLeft: (props) => theme.spacing(props?.left || 0),
    width: (props) => props.width,
    '& > * ': {
      marginLeft: (props) => theme.spacing(props?.spacing || 0),
      width: (props) => props.buttonsWidth,
    },
  },
}))

const ButtonBox: React.FC<ButtonBoxProps> = (props) => {
  const { children } = props
  const classes = useStyles(props)
  return <Box className={classes.box}>{children}</Box>
}

ButtonBox.defaultProps = {
  buttonsWidth: 'auto',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  spacing: 1,
  justifyContent: 'flex-end',
  width: '100%',
}

export default ButtonBox
