import clsx from 'clsx'

import {
  Step,
  Stepper,
  makeStyles,
  StepLabel,
  useMediaQuery,
  Theme,
  StepConnector,
  Box,
} from '@material-ui/core'

import { useStepperContext } from '../StepperProvider'
import StepperIcon from './StepperIcon'

const useStyles = makeStyles((theme) => ({
  rootStepper: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  rootStepItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  rootStepLabel: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.6,
    },
  },
  stepConnectorVertical: {
    marginLeft: theme.spacing(2),
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  hightLightLabel: {
    fontWeight: 'bolder',
    color: theme.palette.grey[600],
  },
}))

interface StepperStepsProps {
  orientation?: 'horizontal' | 'vertical'
}

export default function StepperSteps(props: StepperStepsProps) {
  const { orientation = 'vertical' } = props
  const { steps, stepCurrent, setStepCurrent, stepsCompleted, stepsSkipped } = useStepperContext()

  const classes = useStyles()
  const isSmallWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  function handleClickStep(index: number) {
    setStepCurrent(index)
  }

  return (
    <Stepper
      className={classes.rootStepper}
      activeStep={stepCurrent}
      orientation={orientation}
      nonLinear
      connector={
        <StepConnector
          classes={{
            vertical: classes.stepConnectorVertical,
          }}
        />
      }
    >
      {steps.map((step, index) => (
        <Step
          className={classes.rootStepItem}
          key={step.label}
          onClick={() => handleClickStep(index)}
          completed={stepsCompleted[index]}
          title={step.label}
        >
          <StepLabel
            className={classes.rootStepLabel}
            StepIconComponent={(props) => (
              <StepperIcon
                {...props}
                icon={
                  typeof step.icon === 'function'
                    ? step.icon(stepCurrent === index)
                    : step.icon || index + 1
                }
                skipped={stepsSkipped[index]}
              />
            )}
            onClick={step.onClick}
          >
            <Box
              className={clsx(classes.labelRoot, {
                [classes.hightLightLabel]: stepCurrent === index,
              })}
            >
              <Box>{isSmallWidth ? '' : step.label}</Box>
              {step.renderRight && <Box>{step.renderRight}</Box>}
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
