import api from '~/services/api-pessoal'

import { useQuery, useQueryClient } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { NotificacaoDTO } from './dtos/NotificacaoDTO'

const query = '/Notificacao/ObterNotificacoes'

interface RequestParams {
  anoMes?: string
  isEnvironmentCommon: boolean
}

export default function useNotificacaoObterNotificacoes(params: RequestParams) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: NotificacaoDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useNotificacaoObterNotificacoesClient() {
  const queryClient = useQueryClient()

  function invalidateQuery() {
    queryClient.invalidateQueries(query)
  }

  return {
    invalidateQuery,
  }
}
