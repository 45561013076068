export enum ProcessoTipoAfastamentoEnum {
  Administrativo_1 = 1,
  Judicial_2 = 2,
  NumeroBeneficioINSS_3 = 3,
  NaoInformado_9 = 9,
}

export const ProcessoTipoAfastamentoValues = [
  { value: ProcessoTipoAfastamentoEnum.NaoInformado_9, name: '9 - Não informar' },
  { value: ProcessoTipoAfastamentoEnum.Administrativo_1, name: '1 - Administrativo' },
  { value: ProcessoTipoAfastamentoEnum.Judicial_2, name: '2 - Judicial' },
  {
    value: ProcessoTipoAfastamentoEnum.NumeroBeneficioINSS_3,
    name: '3 - Número do benefício INSS',
  },
]
