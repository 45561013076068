import React from 'react'

import { ListItem, Checkbox, ListItemText } from '@material-ui/core'

const CheckItem = (props) => {
  const { value, onChange, checkedItens, primaryText, secondaryText } = props

  const handleChange = (value) => () => {
    const currentIndex = checkedItens.indexOf(value)
    const newChecked = [...checkedItens]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    onChange(newChecked)
  }

  return (
    <ListItem role={undefined} dense button onClick={handleChange(value)}>
      <Checkbox
        edge="start"
        checked={checkedItens.indexOf(value) !== -1}
        tabIndex={-1}
        disableRipple
      />
      <ListItemText primary={primaryText} secondary={secondaryText} />
    </ListItem>
  )
}

export default CheckItem
