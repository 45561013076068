export enum CompetenciaProcessamentoStatusEnum {
  Processando,
  Iniciado,
  Finalizado,
  Erro,
}

export const CompetenciaProcessamentoStatusValues = [
  { name: 'Processando', value: CompetenciaProcessamentoStatusEnum.Processando },
  { name: 'Iniciado', value: CompetenciaProcessamentoStatusEnum.Iniciado },
  { name: 'Finalizado', value: CompetenciaProcessamentoStatusEnum.Finalizado },
  { name: 'Erro', value: CompetenciaProcessamentoStatusEnum.Erro },
]

export interface CompetenciaProcessamentoContent {
  id: string
  message: string
  date: Date | string
  progress: number
  total: number
  totalFuncionarios: number
  status: CompetenciaProcessamentoStatusEnum
}
