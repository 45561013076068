import React, { useState } from 'react'

import { cnpj } from 'cpf-cnpj-validator'
import moment from 'moment'
import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid, MenuItem, Tooltip } from '@material-ui/core'

import {
  Button,
  ContentDivider,
  DatePickerNew,
  NumeroInscricaoTextField,
  TextField,
} from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { TipoInscricaoEnum, TipoInscricaoValues } from '~/@types/enums/TipoInscricaoEnum'

import { useStepperContext } from '~/components/StepperForm'
import useValidationErrors from '~/hooks/useValidationErrors'
import { dateHasBetweenYearMonth } from '~/hooks/useUtils'
import useAmbiente from '~/hooks/useAmbiente'

import { MainDataForm } from '..'

const schema = yup.object().shape({
  dtInicio: yup
    .date()
    // .when(['$dtAdmissao'], (dtAdmissao, schema) => {
    //   const dtAdmissaoFormatted = moment(dtAdmissao).format('DD/MM/yyyy')

    //   return schema.test(
    //     'DtInicio-validator',
    //     `A Data de Início deve ser igual ou posterior a Data de Admissão: ${dtAdmissaoFormatted} `,
    //     (dtInicio: string) => moment(dtInicio).isSameOrAfter(dtAdmissao),
    //   )
    // })
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data informada deve está dentro da competência atual',
        (dt: string) => dateHasBetweenYearMonth(dt, anoMes),
      ),
    )
    .test(
      'DtInicio-validator',
      `A Data de Início não pode ser posterior a
      30 (trinta) dias da data atual: ${moment().format('DD/MM/yyyy')}`,
      (dtInicio) => {
        const dtInicioFormatted = moment(dtInicio)
        const oneMonthAfterToday = moment().add(30, 'days')

        return dtInicioFormatted.isSameOrBefore(oneMonthAfterToday)
      },
    )
    .required('Informe a Data de Início')
    .typeError('Informe uma Data válida')
    .nullable(),
  localAmbiente: yup.string().required('Informe o Tipo Local'),
  descricaoSetor: yup.string().required('Informe a Descrição do Setor'),
  tipoInscricao: yup.string().required('Informe o Tipo de Inscrição'),
  nrInscricao: yup
    .string()
    .required('Informe o Número de Inscrição')
    .when(['tipoInscricao'], (tipoInscricao, schema) =>
      schema.test('test', 'Informe um Número de Inscriçao válido', (nrInscricao: string) => {
        if (parseInt(tipoInscricao) === TipoInscricaoEnum.CNPJ_1) return cnpj.isValid(nrInscricao)
        if (parseInt(tipoInscricao) === TipoInscricaoEnum.CAEPF_3) return nrInscricao.length === 14
        if (parseInt(tipoInscricao) === TipoInscricaoEnum.CNO_4) return nrInscricao.length === 12
      }),
    ),
  descricaoAtividade: yup.string().required('Informe a Descrição das Atividades'),
})

export default function FormMain() {
  const { mainData, onCancel, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataMainForm)

  const { estabelecimento, anoMes } = useAmbiente()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { anoMes },
    },
  })

  function handleSubmit() {
    onNext({
      ...mainData,
      dataMainForm: data,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ContentDivider bottom={1} title="Informações sobre o ambiente de trabalho" />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <DatePickerNew
          label="Data de Início"
          size="small"
          value={data?.dtInicio || null}
          required
          validationErrors={validationErrors}
          name="dtInicio"
          onChange={(date) => {
            const dtInicio = date || undefined
            setData((oldState) => ({
              ...oldState,
              dtInicio,
            }))
          }}
        />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
        <TextField
          fullWidth
          select
          size="small"
          label="Tipo Local"
          required
          validationErrors={validationErrors}
          name="localAmbiente"
          value={data?.localAmbiente || ''}
          onChange={(e) => {
            const localAmbiente = e?.target?.value
            if (localAmbiente === '1') {
              const tipoInscricao = TipoInscricaoEnum.CNPJ_1
              const nrInscricao = estabelecimento.nrInscricao
              setData((oldState) => ({
                ...oldState,
                tipoInscricao,
                nrInscricao,
              }))
            }
            setData((oldState) => ({
              ...oldState,
              localAmbiente,
            }))
          }}
        >
          <MenuItem value="1">Estabelecimento do próprio Empregador</MenuItem>
          <MenuItem value="2">Estabelecimento de terceiros</MenuItem>
        </TextField>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Tooltip
          arrow
          placement="bottom-start"
          title="Descrição do lugar administrativo, na estrutura
            organizacional da empresa, onde o trabalhador exerce suas
            atividades laborais."
        >
          <Box>
            <TextField
              fullWidth
              size="small"
              inputProps={{
                maxLength: 100,
              }}
              multiline
              label="Descrição do Setor"
              required
              validationErrors={validationErrors}
              name="descricaoSetor"
              value={data?.descricaoSetor || ''}
              onChange={(e) => {
                const descricaoSetor = e?.target?.value || ''
                setData((oldState) => ({
                  ...oldState,
                  descricaoSetor,
                }))
              }}
            />
          </Box>
        </Tooltip>
      </Grid>

      <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
        <MUIAutoComplete
          label="Tipo de Inscrição"
          name="tipoInscricao"
          required
          validationErrors={validationErrors}
          options={TipoInscricaoValues}
          optionId="value"
          renderOption={(option) => option.name}
          value={data.tipoInscricao}
          onChange={(e, obj) => {
            const tipoInscricao = obj?.value
            setData((oldState) => ({
              ...oldState,
              tipoInscricao,
            }))
          }}
        />
      </Grid>

      <Grid xl={8} lg={8} md={8} sm={8} xs={12} item>
        <NumeroInscricaoTextField
          typeMask={data?.tipoInscricao}
          label="Número de Inscrição"
          value={data?.nrInscricao || ''}
          name="nrInscricao"
          required
          validationErrors={validationErrors}
          onChange={(e, value) => {
            const nrInscricao = value
            setData((oldState) => ({
              ...oldState,
              nrInscricao,
            }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          fullWidth
          multiline
          size="small"
          label="Observação"
          inputProps={{
            maxLength: 999,
          }}
          value={data?.observacao || ''}
          onChange={(e) => {
            const observacao = e?.target?.value || ''
            setData((oldState) => ({
              ...oldState,
              observacao,
            }))
          }}
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ContentDivider top={1} bottom={1} title="Descrição das atividades desempenhadas" />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Tooltip
          arrow
          placement="bottom-start"
          title="Descrição das atividades, físicas ou mentais, realizadas pelo
            trabalhador, por força do poder de comando a que se
            submete. As atividades deverão ser escritas com exatidão,
            e de forma sucinta, com a utilização de verbos no infinitivo
            impessoal. Ex.: Distribuir panfletos, operar máquina de
            envase, etc."
        >
          <Box>
            <TextField
              fullWidth
              multiline
              size="small"
              required
              name="descricaoAtividade"
              inputProps={{
                maxLength: 999,
              }}
              validationErrors={validationErrors}
              label="Descrição das Atividades"
              value={data?.descricaoAtividade || ''}
              onChange={(e) => {
                const descricaoAtividade = e?.target?.value || ''
                setData((oldState) => ({
                  ...oldState,
                  descricaoAtividade,
                }))
              }}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
