export enum IndValorFGTSEnum {
  Mensal_11 = 11,
  DtSalário_12 = 12,
  PeriodoAnteriorMensal_13 = 13,
  PeriodoAnteriorDtSalário_14 = 14,
  MensalVerdeAmarelo_15 = 15,
  DtSalarioVerdeAmarelo_16 = 16,
  PeriodoAnteriorMensalVerdeAmarelo_17 = 17,
  PeriodoAnteriorDtSalarioVerdeAmarelo_18 = 18,
  Rescisão_21 = 21,
  DtSalarioRescisório_22 = 22,
  AvisoPrévioIndenizado_23 = 23,
  PeriodoAnteriorMesRescisão_24 = 24,
  PeriodoAnteriorDtSalarioRescisao_25 = 25,
  PeriodoAnteriorAvisoPrevioIndenizado_26 = 26,
  MesRescisaoVerdeAmarelo_27 = 27,
  DtSalarioRescisaoVerdeAmarelo_28 = 28,
  AvisoPevioIndenizadoVerdeAmarelo_29 = 29,
  PeriodoAnteriorRescisaoVerdeAmarelo_30 = 30,
  PeriodoAnteriorDtSalarioVerdeAmarelo_31 = 31,
  PeriodoAnteriorAvisoPrevioIndenizadoVerdeAmarelo_32 = 32,
  MensalIndenizacaoDomestico_41 = 41,
  DtSalarioIndenizaçãodomestico_42 = 42,
  PeriodoAnteriorMensalDomestico_43 = 43,
  PeriodoAnteriorDtSalarioIndenizaçãoDomestico_44 = 44,
  MesRescisaoIndenizacaoDomestico_45 = 45,
  DtSalarioRescisorioDomestico_46 = 46,
  AvisoPrevioIndenizadoDomestico_47 = 47,
  PeriodoAnteriorMesRescisaoDomestico_48 = 48,
  PeriodoAnteriorDtSalarioRescisaoDomestico_49 = 49,
  PeriodoAnteriorAvisoPrevioIndenizadoDomestico_50 = 50,
}

export const IndValorFGTSValues = [
  { value: IndValorFGTSEnum.Mensal_11, name: '11 - FGTS mensal' },
  { value: IndValorFGTSEnum.DtSalário_12, name: '12 - FGTS 13° salário' },
  { value: IndValorFGTSEnum.PeriodoAnteriorMensal_13, name: '13 - FGTS (período anterior) mensal' },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorDtSalário_14,
    name: '14 - FGTS (período anterior) 13º salário',
  },
  {
    value: IndValorFGTSEnum.MensalVerdeAmarelo_15,
    name: '15 - FGTS mensal - Aprendiz/Contrato Verde e Amarelo',
  },
  {
    value: IndValorFGTSEnum.DtSalarioVerdeAmarelo_16,
    name: '16 - FGTS 13° salário - Aprendiz/Contrato Verde eAmarelo',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorMensalVerdeAmarelo_17,
    name: '17 - FGTS (período anterior) mensal - Aprendiz/Contrato Verde e Amarelo',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorDtSalarioVerdeAmarelo_18,
    name: '18 - FGTS (período anterior) 13º salário - Aprendiz/Contrato Verde e Amarelo',
  },
  { value: IndValorFGTSEnum.Rescisão_21, name: '21 - FGTS mês da rescisão' },
  { value: IndValorFGTSEnum.DtSalarioRescisório_22, name: '22 - FGTS 13° salário rescisório' },
  { value: IndValorFGTSEnum.AvisoPrévioIndenizado_23, name: '23 - FGTS aviso prévio indenizado' },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorMesRescisão_24,
    name: '24 - FGTS (período anterior) mês da rescisão',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorDtSalarioRescisao_25,
    name: '25 - FGTS (período anterior) 13º salário rescisório',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorAvisoPrevioIndenizado_26,
    name: '26 - FGTS (período anterior) aviso prévio indenizado',
  },
  {
    value: IndValorFGTSEnum.MesRescisaoVerdeAmarelo_27,
    name: '27 - FGTS mês da rescisão - Aprendiz/Contrato Verde e Amarelo',
  },
  {
    value: IndValorFGTSEnum.DtSalarioRescisaoVerdeAmarelo_28,
    name: '28 - FGTS 13° salário rescisório - Aprendiz/ContratoVerde e Amarelo',
  },
  {
    value: IndValorFGTSEnum.AvisoPevioIndenizadoVerdeAmarelo_29,
    name: '29 - FGTS aviso prévio indenizado - Aprendiz/Contrato Verde e Amarelo',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorRescisaoVerdeAmarelo_30,
    name: '30 - FGTS (período anterior) mês da rescisão - Aprendiz/Contrato Verde e Amarelo',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorDtSalarioVerdeAmarelo_31,
    name: '31 - FGTS (período anterior) 13° salário rescisório -Aprendiz/Contrato Verde e Amarelo',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorAvisoPrevioIndenizadoVerdeAmarelo_32,
    name: '32 - FGTS (período anterior) aviso prévio indenizado - Aprendiz/Contrato Verde e Amarelo',
  },
  {
    value: IndValorFGTSEnum.MensalIndenizacaoDomestico_41,
    name: '41 - FGTS mensal - Indenização compensatória do empregado doméstico',
  },
  {
    value: IndValorFGTSEnum.DtSalarioIndenizaçãodomestico_42,
    name: '42 - FGTS 13° salário - Indenização compensatória do empregado doméstico',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorMensalDomestico_43,
    name: '43 - FGTS (período anterior) mensal - Indenização compensatória do empregado doméstico',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorDtSalarioIndenizaçãoDomestico_44,
    name: '44 - FGTS (período anterior) 13º salário - Indenização compensatória do empregado doméstico',
  },
  {
    value: IndValorFGTSEnum.MesRescisaoIndenizacaoDomestico_45,
    name: '45 - FGTS mês da rescisão - Indenização compensatória do empregado doméstico',
  },
  {
    value: IndValorFGTSEnum.DtSalarioRescisorioDomestico_46,
    name: '46 - FGTS 13° salário rescisório - Indenização compensatória do empregado doméstico',
  },
  {
    value: IndValorFGTSEnum.AvisoPrevioIndenizadoDomestico_47,
    name: '47 - FGTS aviso prévio indenizado - Indenização compensatória do empregado doméstico',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorMesRescisaoDomestico_48,
    name: '48 - FGTS (período anterior) mês da rescisão - Indenização compensatória do empregado doméstico',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorDtSalarioRescisaoDomestico_49,
    name: '49 - FGTS (período anterior) 13º salário rescisório - Indenização compensatória do empregado doméstico',
  },
  {
    value: IndValorFGTSEnum.PeriodoAnteriorAvisoPrevioIndenizadoDomestico_50,
    name: '50 - FGTS (período anterior) aviso prévio indenizado - Indenização compensatória do empregado doméstico',
  },
]
