import { Grid } from '@material-ui/core'

import { LabelValueColor } from '~/components'

import { formatCurrencyAcceptZeros } from '~/hooks/useUtils'

interface ValoresPrincipaisProps {
  vrSalarioContratual: number
  vrTotalVencimento: number
  vrTotalDesconto: number
  vrTotalLiquido: number
}

export default function ValoresPrincipais(props: ValoresPrincipaisProps) {
  const { vrSalarioContratual, vrTotalVencimento, vrTotalDesconto, vrTotalLiquido } = props

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <LabelValueColor
          size="medium"
          value={formatCurrencyAcceptZeros(vrSalarioContratual)}
          label="Salário Contratual"
          title="Valor Salário Contratual"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3}>
        <LabelValueColor
          size="medium"
          value={formatCurrencyAcceptZeros(vrTotalVencimento)}
          label="Total Vencimento"
          title="Valor Total Vencimento"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3}>
        <LabelValueColor
          size="medium"
          value={formatCurrencyAcceptZeros(vrTotalDesconto)}
          label="Total Desconto"
          title="Valor Total Desconto"
          color="#e57373"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3}>
        <LabelValueColor
          size="medium"
          value={formatCurrencyAcceptZeros(vrTotalLiquido)}
          label="Total Líquido"
          title="Valor Total Líquido"
          color="#2E8BC0"
          showDivider={false}
        />
      </Grid>
    </Grid>
  )
}
