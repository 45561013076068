import { Typography } from '@material-ui/core'
import { ActionDialog, Button, Stack } from '~/components'

import { useHistory } from 'react-router-dom'

interface DialogKeepFlowProps {
  isOpen: boolean
  onClose: () => void
  keepRegistering: () => void
}

export default function DialogKeepFlow({ isOpen, onClose, keepRegistering }: DialogKeepFlowProps) {
  const history = useHistory()

  function handleGoRelatoriosAdmissionais() {
    history.push({
      pathname: '/relatorio-admissional',
    })
  }

  return (
    <ActionDialog
      title="O que deseja fazer?"
      onCancelClick={onClose}
      onClose={onClose}
      isOpen={isOpen}
      customActions={
        <Stack orientation="horizontal" justifyContent="flex-end" p={1}>
          <Button onClick={onClose}>Fechar operação</Button>
          <Button variant="contained" onClick={handleGoRelatoriosAdmissionais}>
            Imprimir os relatórios admissionais
          </Button>
          <Button variant="contained" onClick={keepRegistering}>
            Continuar cadastrando
          </Button>
        </Stack>
      }
    >
      <Typography>
        Você deseja fechar a operação, imprimir os relatórios admissionais ou continuar cadastrando?
      </Typography>
    </ActionDialog>
  )
}
