import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryUseObterTodosSalarioMinimo } from './useObterTodosSalarioMinimo'

export function useRemoverSalarioMinimo() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(salarioMinimoId: string) {
    await api.delete('/SalarioMinimo/' + salarioMinimoId)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.remove()
      await queryClient.invalidateQueries(queryUseObterTodosSalarioMinimo)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
