import { memo, useCallback, useEffect, useState } from 'react'

import { Box, Paper, useTheme } from '@material-ui/core'

import { Button, CurrencyTextField, Finder, PageHeader } from '~/components'
import { AutoCompletePessoaFisica } from '~/components/AutoComplete'

import Table from './Table'
import TableUltimoSalario from './TableUltimoSalario'
import TableNovoSalario from './TableNovoSalario'

import useAmbiente from '~/hooks/useAmbiente'

import useObterEstabelecimentosManutencao from '~/hooks/queries/Autonomo/useObterEstabelecimentosManutencao'
import useAtualizarSalariosAutonomos from '~/hooks/queries/Autonomo/useAtualizarSalariosAutonomos'

import { AutonomoManutencaoDTO } from '~/hooks/queries/Autonomo/AutonomoManutencaoDTO'
import { getDateMinAnoMes } from '~/utils/utils'

const MemoTable = memo(Table)
const MemoTableUltimoSalario = memo(TableUltimoSalario)
const MemoTableNovoSalario = memo(TableNovoSalario)

export default function ManutencaoAutonomo() {
  const [query, setQuery] = useState('')
  const [dataForm, setDataForm] = useState({
    pessoaFisicaId: '',
  })
  const [dataSubmit, setDataSubmit] = useState<AutonomoManutencaoDTO[]>([])

  const theme = useTheme()
  const { anoMes } = useAmbiente()
  const {
    data: _data,
    isLoading,
    isFetching,
    refetch,
    remove,
  } = useObterEstabelecimentosManutencao({
    pessoaFisicaId: dataForm.pessoaFisicaId,
    anoMes,
  })
  const data = _data || []
  const { mutateAsync: handleAtualizarSalariosAutonomos, isLoading: isSubmitting } =
    useAtualizarSalariosAutonomos()

  let totalValoresAnteriores = 0
  let totalValoresAtuais = 0

  data.forEach((d) => {
    totalValoresAnteriores += d.valorUltimoSalario
  })

  dataSubmit.forEach((d) => {
    if (d.valorNovoSalario > 0) {
      totalValoresAtuais += d.valorNovoSalario
    } else {
      const dataAnterior = data.find((x) => x.autonomoId === d.autonomoId)
      totalValoresAtuais += dataAnterior?.valorUltimoSalario || 0
    }
  })

  useEffect(() => {
    if (_data) {
      setDataSubmit(
        _data.map((d) => ({
          autonomoId: d.autonomoId,
          dtNovoSalario: getDateMinAnoMes(anoMes),
          valorNovoSalario: 0,
          indMultiploVinculo: d.indMultiploVinculo,
          isReciboRecorrente: d.isReciboRecorrente,
        })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_data])

  const changeDataSubmit = useCallback((dt: AutonomoManutencaoDTO[]) => {
    setDataSubmit(dt)
  }, [])

  async function handleCancel() {
    remove()
    await refetch()
  }

  async function handleSubmit() {
    await handleAtualizarSalariosAutonomos({ data: dataSubmit })
    handleCancel()
  }

  return (
    <Box
      p={2}
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title="Manutenção Autônomo">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} p={1}>
        <AutoCompletePessoaFisica
          value={dataForm.pessoaFisicaId}
          onChange={(e: FixLater, pessoaFisica: FixLater) => {
            const pessoaFisicaId = pessoaFisica?.id || ''
            setDataForm((prev) => ({
              ...prev,
              pessoaFisicaId,
            }))
          }}
        />
      </Box>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%" display="flex" gridGap={theme.spacing(1)}>
          <Box flex={1}>
            <MemoTable
              data={data}
              isFetching={isFetching}
              isLoading={isLoading}
              query={query}
              changeDataSubmit={changeDataSubmit}
              dataSubmit={dataSubmit}
            />
          </Box>
          <Box minWidth={320} width={320}>
            <MemoTableUltimoSalario
              data={data}
              isFetching={isFetching}
              isLoading={isLoading}
              query={query}
            />
          </Box>
          <Box minWidth={320} width={320}>
            <MemoTableNovoSalario
              data={data}
              isFetching={isFetching}
              isLoading={isLoading}
              query={query}
              changeDataSubmit={changeDataSubmit}
              dataSubmit={dataSubmit}
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" gridGap={theme.spacing(1)} justifyContent="flex-end">
        <Box component={Paper} p={1} bottom={theme.spacing(9)} minWidth={320} width={320}>
          <CurrencyTextField
            label="Valor total dos valores anteriores"
            value={totalValoresAnteriores}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box component={Paper} p={1} bottom={theme.spacing(9)} minWidth={320} width={320}>
          <CurrencyTextField
            label="Valor total dos valores atuais"
            value={totalValoresAtuais}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>

      <Box
        component={Paper}
        p={1}
        display="flex"
        justifyContent="flex-end"
        gridGap={theme.spacing(1)}
      >
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button
          variant="contained"
          disabled={dataSubmit.length > 0 ? false : true}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  )
}
