import { useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { PageHeader, PDFViewer, Button, Finder, Checkbox, DatePickerNew } from '~/components'

import Table from './Table'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useObterFuncionariosAvisosPrevios } from '~/hooks/queries/EtiquetaAvisoPrevio/useObterFuncionariosAvisosPrevios'
import { getDateMinAnoMes, getDateMaxAnoMes } from '~/utils/utils'

const schema = yup.object().shape({
  dtInicioAvisoPrevio: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Início da Admissão')
    .nullable(),
  dtFimAvisoPrevio: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Fim da Admissão')
    .nullable(),
})

const TITLE_PAGE = 'Etiqueta Aviso Prévio'

export default function EtiquetaAvisoPrevio() {
  const { estabelecimento, anoMes } = useAmbiente()

  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [filter, setFilter] = useState<{
    estabelecimentoId: string | null
    dtInicioAvisoPrevio: string
    dtFimAvisoPrevio: string
  }>({
    estabelecimentoId: estabelecimento.id,
    dtInicioAvisoPrevio: getDateMinAnoMes(anoMes),
    dtFimAvisoPrevio: getDateMaxAnoMes(anoMes),
  })
  const [query, setQuery] = useState('')

  const { data: _data, isLoading, isFetching, refetch } = useObterFuncionariosAvisosPrevios(filter)
  const data = _data || []

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data: filter,
    handleSubmit: onSearch,
  })
  const theme = useTheme()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<string[]>([])

  async function onSearch() {
    await refetch()
  }

  function handlePrint() {
    const avisosIds = rowsSelected.map((d) => data[d].avisoPrevioId)
    openPDFViewer(avisosIds)
  }

  function handleClosePDFViewer() {
    closePDFViewer()
    setRowsSelected([])
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      gridGap={theme.spacing(1)}
      flexDirection="column"
    >
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box
        component={Paper}
        p={1.5}
        display="flex"
        gridGap={theme.spacing(1)}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={4}>
              <DatePickerNew
                label="Início Aviso Prévio"
                value={filter.dtInicioAvisoPrevio || null}
                onChange={(date) => {
                  const dtInicioAvisoPrevio = date || ''
                  setFilter((prev) => ({
                    ...prev,
                    dtInicioAvisoPrevio,
                  }))
                }}
                validationErrors={validationErrors}
                name="dtInicioAvisoPrevio"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <DatePickerNew
                label="Fim Aviso Prévio"
                value={filter.dtFimAvisoPrevio || null}
                onChange={(date) => {
                  const dtFimAvisoPrevio = date || ''
                  setFilter((prev) => ({
                    ...prev,
                    dtFimAvisoPrevio,
                  }))
                }}
                validationErrors={validationErrors}
                name="dtFimAvisoPrevio"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Checkbox
                label="Listar todos Estabelecimentos"
                value={filter.estabelecimentoId ? false : true}
                onChange={(e, checked) => {
                  const estabelecimentoId = checked ? null : estabelecimento.id
                  setFilter((prev) => ({
                    ...prev,
                    estabelecimentoId,
                  }))
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Button variant="contained" onClick={handleValidate}>
          Buscar
        </Button>
      </Box>

      <Box flex={1} overflow="auto" position="relative">
        <Table
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          onRowSelected={setRowsSelected}
          rowsSelected={rowsSelected}
          query={query}
        />
      </Box>

      <Box component={Paper} display="flex" justifyContent="flex-end" padding={1}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handlePrint}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Imprimir
        </Button>
      </Box>

      <PDFViewer
        title={TITLE_PAGE}
        reportKey="EtiquetaAvisoPrevio"
        isOpen={isOpenPDFViewer}
        onClose={handleClosePDFViewer}
        axiosConfig={{
          url: '/EtiquetaAvisoPrevio/ObterRelatorio',
          method: 'post',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
