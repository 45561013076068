import { useEffect, useState, memo, useCallback } from 'react'

import * as yup from 'yup'
import { dateHasInAnoMes } from '~/utils/utils'

import { Box, Grid, Paper } from '@material-ui/core'

import { Button, Finder, DatePickerNew, ContainerTable, Stack } from '~/components'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useObterFuncionarios } from '~/hooks/queries/VinculoTrabalhoFeriado/useObterFuncionarios'
import { useProcessarFeriado } from '~/hooks/queries/VinculoTrabalhoFeriado/useProcessarFeriado'

const MemoTable = memo(Table)

const schema = yup.object().shape({
  dtFeriado: yup
    .date()
    .typeError('Informe uma Data válida')
    .required('Informe a Data do Feriado')
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data informada deve está dentro da competência atual',
        (dt: string) => dateHasInAnoMes(dt, anoMes),
      ),
    )
    .nullable(),
})

export default function ProcessamentoFeriado() {
  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [dataForm, setDataForm] = useState<{
    dtFeriado: string
  }>({
    dtFeriado: '',
  })
  const [query, setQuery] = useState('')

  const { anoMes, estabelecimento } = useAmbiente()
  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data: dataForm,
    handleSubmit,
    schemaOptions: {
      abortEarly: false,
      context: {
        anoMes,
      },
    },
  })
  const dialogNotification = useDialogNotification()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterFuncionarios({
    params: {
      anoMes,
      estabelecimentoId: estabelecimento.id,
    },
  })
  const data = _d || []

  const { mutateAsync, isLoading: isSubmitting } = useProcessarFeriado()

  useEffect(() => {
    setRowsSelected([])
  }, [_d])

  async function handleSubmit() {
    const ids = rowsSelected.map((d) => data[d].id)
    const dt = await mutateAsync({
      data: ids,
      params: {
        dtFeriado: dataForm.dtFeriado,
      },
    })
    if (dt.length > 0) {
      dialogNotification.warning({ descriptions: dt })
    }
  }

  const handleSelectRow = useCallback((d: number[]) => {
    setRowsSelected(d)
  }, [])

  return (
    <Stack height="100%">
      <Box component={Paper} p={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <DatePickerNew
              label="Data do Feriado"
              value={dataForm.dtFeriado || null}
              name="dtFeriado"
              validationErrors={validationErrors}
              onChange={(date) => {
                const dtFeriado = date || ''
                setDataForm((prev) => ({
                  ...prev,
                  dtFeriado,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={8}>
            <Stack alignItems="flex-end" justifyContent="center" height="100%">
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <ContainerTable>
        <MemoTable
          data={data}
          isFetching={isFetching}
          isLoading={isLoading}
          rowsSelected={rowsSelected}
          onSelectRow={handleSelectRow}
          query={query}
        />
      </ContainerTable>

      <Stack
        p={1}
        component={Paper}
        justifyContent="flex-end"
        alignItems="center"
        orientation="horizontal"
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleValidate}
          isLoading={isSubmitting}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Processar
        </Button>
      </Stack>
    </Stack>
  )
}
