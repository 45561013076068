import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { ApurarConvencaoColetivaProcessarVinculoDTO } from './ApurarConvencaoColetivaDTO'

interface RequestProps {
  anoMes: string
  nrParcelas: number
  estabelecimentoId: string
  acordoDissidioConvencaoId: string
  sindicatoId: string
  data: ApurarConvencaoColetivaProcessarVinculoDTO[]
}

export default function useProcessarConvencaoColetiva() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest({ data, ...params }: RequestProps) {
    await api.post('/ApurarConvencaoColetiva/ProcessarConvencaoColetiva', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: async () => notification.success('Parcelas geradas com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
