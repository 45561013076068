export enum JornadaTempoParcialEnum {
  ContratoNaoTempoParcial_0 = 0,
  Limitado_25_HorasSemanais_1 = 1,
  Limitado_30_HorasSemanais_2 = 2,
  Limitado_26_HorasSemanais_3 = 3,
}

export const JornadaTempoParcialValues = [
  { value: JornadaTempoParcialEnum.ContratoNaoTempoParcial_0, name: '0 - Não é jornada Parcial' },
  {
    value: JornadaTempoParcialEnum.Limitado_25_HorasSemanais_1,
    name: '1 - Limitada a 25 horas semanais (Somente Domésticas)',
  },
  {
    value: JornadaTempoParcialEnum.Limitado_30_HorasSemanais_2,
    name: '2 - Limitada a 30 horas semanais',
  },
  {
    value: JornadaTempoParcialEnum.Limitado_26_HorasSemanais_3,
    name: '3 - Limitada a 26 horas semanais',
  },
]
