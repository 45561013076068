const geral_01 = 1
const obra_02 = 2
const pessoaFisica_03 = 3
const pessoaJuridica_04 = 4
const pessoaJuridicaPrestadoPorCooperado_05 = 5
const entidadeBeneficente_06 = 6
const pessoaFisica_07 = 7
const operadorPortuario_08 = 8
const contratanteTrabalhadorAvulso_09 = 9
const embarcacao_10 = 10
const construcaoCivil_21 = 21
const empregadorDomestico_24 = 24
const atividadeExercidaExterior_90 = 90
const atividadeExercidaExterior_91 = 91
const bolsistaSemContribuicaoPatronal_92 = 92

export const tipoLotacaoValues = [
  {
    value: geral_01,
    name: '01 - Classificação da atividade econômica exercida pela Pessoa Jurídica para fins de atribuição de código FPAS, inclusive obras de construção civil própria, exceto: a) empreitada parcial ou sub-empreitada de obra de construção civil (utilizar opção 02); b) prestação de serviços em instalações de terceiros (utilizar opções 03 a 09); c) Embarcação inscrita no Registro Especial Brasileiro - REB (utilizar opção 10).',
  },
  {
    value: obra_02,
    name: '02 - Obra de Construção Civil - Empreitada Parcial ou Subempreitada',
  },
  {
    value: pessoaFisica_03,
    name: '03 - Pessoa Física Tomadora de Serviços prestados mediante cessão de mão de obra, exceto contratante de cooperativa',
  },
  {
    value: pessoaJuridica_04,
    name: '04 - Pessoa Jurídica Tomadora de Serviços prestados mediante cessão de mão de obra, exceto contratante de cooperativa, nos termos da lei 8.212/1991',
  },
  {
    value: pessoaJuridicaPrestadoPorCooperado_05,
    name: '05 - Pessoa Jurídica Tomadora de Serviços prestados por cooperados por intermédio de cooperativa de trabalho, exceto aqueles prestados a entidade beneficente/isenta',
  },
  {
    value: entidadeBeneficente_06,
    name: '06 - Entidade beneficente/isenta Tomadora de Serviços prestados por cooperados por intermédio de cooperativa de trabalho',
  },
  {
    value: pessoaFisica_07,
    name: '07 - Pessoa Física tomadora de Serviços prestados por Cooperados por intermédio de Cooperativa de Trabalho',
  },
  {
    value: operadorPortuario_08,
    name: '08 - Operador Portuário tomador de serviços de trabalhadores avulsos',
  },
  {
    value: contratanteTrabalhadorAvulso_09,
    name: '09 - Contratante de trabalhadores avulsos não portuários por intermédio de Sindicato',
  },
  {
    value: embarcacao_10,
    name: '10 - Embarcação inscrita no Registro Especial Brasileiro - REB',
  },
  {
    value: construcaoCivil_21,
    name: '21 - Classificação da atividade econômica ou obra própria de construção civil da Pessoa Física',
  },
  { value: empregadorDomestico_24, name: '24 - Empregador Doméstico' },
  {
    value: atividadeExercidaExterior_90,
    name: '90 - Atividades desenvolvidas no exterior por trabalhador vinculado ao Regime Geral de Previdência Social (expatriados)',
  },
  {
    value: atividadeExercidaExterior_91,
    name: '91 - Atividades desenvolvidas por trabalhador estrangeiro vinculado a Regime de Previdência Social Estrangeiro',
  },
  {
    value: bolsistaSemContribuicaoPatronal_92,
    name: '92 - Bolsista contribuinte individual sem contribuição patronal',
  },
]

export const tipoLotacaoConsts = {
  geral_01,
  obra_02,
  pessoaFisica_03,
  pessoaJuridica_04,
  pessoaJuridicaPrestadoPorCooperado_05,
  entidadeBeneficente_06,
  pessoaFisica_07,
  operadorPortuario_08,
  contratanteTrabalhadorAvulso_09,
  embarcacao_10,
  construcaoCivil_21,
  empregadorDomestico_24,
  atividadeExercidaExterior_90,
  atividadeExercidaExterior_91,
  bolsistaSemContribuicaoPatronal_92,
}
