import { MUIAutoComplete } from '~/components/AutoComplete'

import { useObterTodosMotivo } from '~/hooks/queries/Motivo/useObterTodosMotivo'

import { Motivo } from '~/hooks/queries/Motivo/Motivo'
import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'

interface AutoCompleteMotivoProps extends MUIAutoCompleteProps {
  value: string
  onChange: (value: Motivo | null) => void
}

export default function AutoCompleteMotivo(props: AutoCompleteMotivoProps) {
  const { value, onChange, ...rest } = props

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
    refetch,
  } = useObterTodosMotivo()
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: Motivo) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Motivo"
      value={value}
      onChange={(_, obj: Motivo) => onChange(obj)}
      optionId="id"
      {...rest}
    />
  )
}
