export enum IndDiasFeriasProporcionaisRestantesEnum {
  NaoVerificar_1 = 1,
  Usar_Dias_Ferias_Atual_2 = 2,
  Usar_Dias_Periodo_Atual_3 = 3,
  Usar_Dias_Periodo_Seguinte_4 = 4,
  Iniciar_Periodo_Apenas_Licenca_5 = 5,
}

export const IndDiasFeriasProporcionaisRestantesValues = [
  {
    value: IndDiasFeriasProporcionaisRestantesEnum.NaoVerificar_1,
    name: 'Não verificar se funcionário possui menos de um ano de trabalho',
  },
  {
    value: IndDiasFeriasProporcionaisRestantesEnum.Usar_Dias_Ferias_Atual_2,
    name: 'Fazer férias coletiva com o total de dias de direito',
  },
  {
    value: IndDiasFeriasProporcionaisRestantesEnum.Usar_Dias_Periodo_Atual_3,
    name: 'Manter o período atual em aberto com o saldo dos dias restantes',
  },
  {
    value: IndDiasFeriasProporcionaisRestantesEnum.Usar_Dias_Periodo_Seguinte_4,
    name: 'Manter o saldo dos dias restantes no período seguinte',
  },
  {
    value: IndDiasFeriasProporcionaisRestantesEnum.Iniciar_Periodo_Apenas_Licenca_5,
    name: 'Iniciar período apenas para funcionários com licença remunerada',
  },
]
