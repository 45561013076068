import { useMemo } from 'react'

import moment from 'moment'

import { Box, Chip, IconButton, useTheme } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox, ConfirmDeleteDialog, ToolsDataTable } from '~/components'

import FormUpdate from '../FormUpdate'

import useDialog from '~/hooks/useDialog'

import useNotificacaoObterNotificacoes from '~/hooks/queries/Notificacao/useNotificacaoObterNotificacoes'
import useNotificacaoRemover from '~/hooks/queries/Notificacao/useNotificacaoRemover'

import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  IndNotificacaoColor,
  IndNotificacaoEnum,
  IndNotificacaoLabel,
} from '~/@types/enums/IndNotificacaoEnum'

import { NotificacaoDTO } from '~/hooks/queries/Notificacao/dtos/NotificacaoDTO'
import { Ativo } from '~/components/Displays'

interface TableProps {
  isEnvironmentCommon: boolean
  anoMes?: string
}

export default function Table({ isEnvironmentCommon, anoMes }: TableProps) {
  const { mutateAsync: mutateAsyncNotificacaoRemover, isLoading: isLoadingNotificacaoRemover } =
    useNotificacaoRemover()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useNotificacaoObterNotificacoes({
    isEnvironmentCommon,
    anoMes,
  })
  const data = _data || []

  const theme = useTheme()
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')
  const {
    close: closeFormUpdate,
    data: dataFormUpdate,
    isOpen: isOpenFormUpdate,
    open: openFormUpdate,
  } = useDialog<NotificacaoDTO>()

  async function handleConfirmDelete() {
    await mutateAsyncNotificacaoRemover({
      id: dataConfirmDelete,
      params: {
        isEnvironmentCommon,
      },
    })
    closeConfirmDelete()
  }

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'titulo',
        label: 'Título',
      },
      {
        name: 'descricao',
        label: 'Descrição',
        options: {
          setCellProps: () => ({
            style: {
              width: '300px',
            },
          }),
          customBodyRender: (value) => (
            <Box
              title={value}
              width="300px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {value}
            </Box>
          ),
        },
      },
      {
        name: 'dtInicio',
        label: 'Início',
        options: {
          customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
        },
      },
      {
        name: 'dtFim',
        label: 'Fim',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
        },
      },
      {
        name: 'isMarcadaLida',
        label: 'Lida',
        options: {
          customBodyRender: (value) => <Ativo ativo={value} />,
        },
      },
      {
        name: 'isNotificarAnual',
        label: 'Repetir todos os anos',
        options: {
          customBodyRender: (value) => <Ativo ativo={value} />,
        },
      },
      {
        name: 'indNotificacao',
        label: 'Indicador',
        options: {
          customBodyRender: (value: IndNotificacaoEnum) => (
            <Box display="flex" justifyContent="center">
              <Chip
                style={{
                  backgroundColor: IndNotificacaoColor[value],
                  color: theme.palette.common.white,
                  width: 100,
                }}
                label={IndNotificacaoLabel[value]}
              />
            </Box>
          ),
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    const dtCurrent = data.find((d) => d.id === value)
                    if (dtCurrent) {
                      openFormUpdate(dtCurrent)
                    }
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => openConfirmDelete(value)}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  return (
    <Box>
      <ToolsDataTable
        data={data}
        columns={columns}
        disableAutoHeight
        isLoading={isLoading}
        isFetching={isFetching}
      />

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleConfirmDelete}
          isDeleting={isLoadingNotificacaoRemover}
        />
      )}

      {isOpenFormUpdate && (
        <FormUpdate
          isOpen={isOpenFormUpdate}
          onClose={closeFormUpdate}
          data={dataFormUpdate}
          isEnvironmentCommon={isEnvironmentCommon}
        />
      )}
    </Box>
  )
}
