import React, { useState, useEffect } from 'react'

import { Grid, Box } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog, SimpleSelect } from '~/components'

import { MUIAutoComplete, AutoCompleteMedico } from '~/components/AutoComplete'
import { Checkbox, DatePicker, NumeroInscricaoTextField } from '~/components'

import { CodigoSaidaFGTSValues } from '~/@types/enums/CodigoSaidaFGTSEnum'
import { IndMotivoAfastamentoValues } from '~/@types/enums/IndMotivoAfastamentoEnum'
import { ProcessoTipoAfastamentoValues } from '~/@types/enums/ProcessoTipoAfastamentoEnum'
import { TipoAcidenteTransitoValues } from '~/@types/enums/TipoAcidenteTransitoEnum'
import { CessaoInformacaoOnusValues } from '~/@types/enums/CessaoInformacaoOnusEnum'
import { InfoOnusRemuneracaoValues } from '~/@types/enums/InfoOnusRemuneracaoEnum'
import { OrigemRetificacaoAfastamentoValues } from '~/@types/enums/OrigemRetificacaoAfastamentoEnum'
import { CodigoRetornoFGTSValues } from '~/@types/enums/CodigoRetornoFGTSEnum'

const Form = (props) => {
  const { isOpen, onClose, data: _data } = props
  const [data, setData] = useState({})

  useEffect(() => {
    setData(_data)
  }, [_data])

  return (
    <ActionDialog
      title="Detalhes do Afastamento"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      customActions={<></>}
    >
      <Box pb={2}>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <DatePicker
              label="Início"
              size="small"
              value={data?.dtInicioAfastamento || null}
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <DatePicker
              label="Previsão Retorno"
              size="small"
              value={data?.dtPrevistaRetorno || null}
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <DatePicker label="Retorno" size="small" value={data?.dtRetorno || null} disabled />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <DatePicker
              label="Início Benefício"
              size="small"
              value={data?.dtInicioBeneficio || null}
              disabled
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <MUIAutoComplete
              label="Motivo do Afastamento"
              disabled
              value={data.motivoAfastamento}
              options={IndMotivoAfastamentoValues}
              optionId="value"
              renderOption={(option) => option.name}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Checkbox
              label="Mesmo motivo anterior"
              value={data?.isAfastamentoMesmoMotivo || false}
              onChange={(e, value) => {
                const isAfastamentoMesmoMotivo = value
                setData({ ...data, isAfastamentoMesmoMotivo })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <MUIAutoComplete
              label="Tipo Acidente"
              options={TipoAcidenteTransitoValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.tipoAcidenteTransito}
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <TextField
              label="Nr. Benefício"
              fullWidth
              value={data?.nrBeneficio || ''}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <TextField
              label="Qtd. Dias Afastado"
              fullWidth
              value={data?.qtdDiasAfastado || ''}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <AutoCompleteMedico value={data?.medicoId} disabled optionId="id" />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <NumeroInscricaoTextField
              label="Cessão Nr. Inscrição"
              fullWidth
              value={data?.cessaoNrInscricao || ''}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <MUIAutoComplete
              label="Cessão Informação Ônus"
              options={CessaoInformacaoOnusValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.cessaoInformacaoOnus}
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <NumeroInscricaoTextField
              label="Sindical Nr. Inscrição"
              fullWidth
              value={data?.sindicalNrInscricao || ''}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <MUIAutoComplete
              label="Sindical Informação Ônus"
              options={InfoOnusRemuneracaoValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.sindicalInformacaoOnus}
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <NumeroInscricaoTextField
              label="Eletivo Nr. Inscrição"
              fullWidth
              value={data?.eletivoNrInscricao || ''}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Checkbox
              label="Eletivo Informação Ônus"
              value={data?.eletivoInformacaoRemuneracao || false}
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <MUIAutoComplete
              label="Origem Retificação"
              options={OrigemRetificacaoAfastamentoValues}
              optionId="value"
              renderOption={(option) => option.value}
              value={data.origemRetificacao}
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <MUIAutoComplete
              label="Tipo Processo"
              options={ProcessoTipoAfastamentoValues}
              renderOption={(option) => option.name}
              optionId="value"
              value={data.tipoProcesso}
              disabled
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <TextField
              label="Nr. Processo"
              fullWidth
              value={data?.nrProcesso || ''}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>

          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <TextField
              label="Observação"
              fullWidth
              value={data?.observacao || ''}
              variant="outlined"
              size="small"
              multiline
              disabled
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <SimpleSelect
              label="Código de Saída FGTS"
              options={CodigoSaidaFGTSValues}
              renderOption={(opt) => opt.name}
              optionId="value"
              value={data?.codigoSaidaFGTS || null}
              onChange={(_, codigoSaidaFGTS) => {
                setData((oldState) => ({
                  ...oldState,
                  codigoSaidaFGTS,
                }))
              }}
              disabled
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <SimpleSelect
              label="Código de Retorno FGTS"
              options={CodigoRetornoFGTSValues}
              renderOption={(opt) => opt.name}
              optionId="value"
              value={data?.codigoRetornoFGTS || null}
              onChange={(_, codigoRetornoFGTS) => {
                setData((oldState) => ({
                  ...oldState,
                  codigoRetornoFGTS,
                }))
              }}
              disabled
            />
          </Grid>
        </Grid>
      </Box>
    </ActionDialog>
  )
}

export default Form
