import { useState, useEffect, useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'

import { TextField, MenuItem, CircularProgress, Box, fade, makeStyles } from '@material-ui/core'

import { AnoSelect, SimpleAlert } from '~/components'
import { AutoCompleteEstabelecimento } from '~/components/AutoComplete'

import useUtils, { getEmpregadorPorId } from '~/hooks/useUtils'
import { useTabelaMesInserirTabelaMes } from '~/hooks/queries/TabelaMes/useTabelaMesInserirTabelaMes'

import { Creators as AppActions } from '~/store/ducks/app'
import { TOOL_BAR_HEIGHT } from '~/@types/consts/tool_bar_size'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },

  boxEmpresa: {
    width: 400,
  },

  boxData: {
    width: 120,
    marginLeft: theme.spacing(1),
  },

  boxAlert: {
    marginLeft: theme.spacing(1),
  },

  boxMessage: {
    marginLeft: theme.spacing(1),
    fontStyle: 'italic',

    display: 'flex',
    alignItems: 'center',
  },

  loadingColor: {
    // color: 'white',
  },

  root: {
    padding: 0,
    margin: 0,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  inputInputBold: {
    fontSize: '9pt',
    fontWeight: 'bold',
    // color: 'white',
    height: '16px',
    paddding: 0,
    margin: 0,
  },
  inputInput: {
    fontSize: '9pt',
    // color: 'white',
    height: '16px',
    paddding: 0,
    margin: 0,
  },
  inputInputRoot: {
    flexWrap: 'inherit',
    padding: 0,
    margin: 0,
  },
  inputEndAdornment: {
    // color: 'white',
  },
  inputPopupIndicator: {
    // color: 'white',
  },
  textFieldNotchedOutline: {
    // border: 'none',
  },
  autoCompleteListbox: {
    fontSize: '9pt',
    // '& li:nth-child(even)': { backgroundColor: '#EDEDED' },
    // '& li:nth-child(odd)': { backgroundColor: '#FFF' },
  },
  calendarIcon: {
    fontSize: '10pt',
    // color: 'white',
  },

  calendarIconMonth: {
    // fontSize: "10pt",
    // color: 'white',
    // marginLeft: "-83px",
    // pointerEvents: "inherit",
  },

  monthIcon: {
    // color: '#FFF',
  },
  simpleAlertRoot: {
    position: 'absolute',
    top: TOOL_BAR_HEIGHT + 5,
    right: 5,
  },
  boxLoading: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
}))

const DatePickerTextField = (props) => {
  const classes = useStyles()
  return (
    <TextField
      {...props}
      size="small"
      InputProps={{
        ...props.InputProps,
        classes: {
          input: classes.inputInputBold,
          notchedOutline: classes.textFieldNotchedOutline,
        },
      }}
      classes={{
        root: classes.root,
      }}
    />
  )
}

const SelecaoAmbiente = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { formatAnoMes } = useUtils()

  const [empresa, setEmpresa] = useState({
    empregador: null,
    estabelecimento: null,
  })
  const [competencia, setCompetencia] = useState({
    ano: '',
    mes: '',
  })

  const empresaSelected = useSelector((state) => state.app.ambiente.empresa)
  const competenciaSelected = useSelector((state) => state.app.ambiente.competencia)
  const [peridoStatus, setPeriodoStatus] = useState({
    isSelected: false,
    isLoading: false,
    error: false,
  })

  useEffect(() => {
    setPeriodoStatus({
      isSelected: false,
      isLoading: false,
      error: false,
    })
    if (empresaSelected) {
      setEmpresa(empresaSelected)
    }

    if (competenciaSelected) {
      setCompetencia(competenciaSelected)
    }
    // eslint-disable-next-line
  }, [empresaSelected?.estabelecimento])

  useEffect(() => {
    if (empresa?.estabelecimento && competencia?.ano && competencia?.mes) handleSelect()
    // eslint-disable-next-line
  }, [competencia.ano, competencia.mes, empresa.estabelecimento])

  useTabelaMesInserirTabelaMes(competencia.ano + competencia.mes)

  const handleSelect = async () => {
    setPeriodoStatus({
      error: false,
      isLoading: true,
      isSelected: false,
    })

    try {
      const empregador = await getEmpregadorPorId(empresa.estabelecimento.empregadorId)

      const ano = competencia.ano
      const mes = competencia.mes
      const diaMaxMes = new Date(ano, mes, 0).getDate()

      dispatch(
        AppActions.setAmbiente({
          hasAmbiente: true,
          competencia: {
            ...competencia,
            ano,
            mes,
            anoMes: ano + mes,
            diaMaxMes,
          },
          empresa: {
            ...empresa,
            empregador,
          },
        }),
      )

      setPeriodoStatus({
        error: false,
        isLoading: false,
        isSelected: true,
      })
    } catch (err) {
      setPeriodoStatus({
        error: err.response.data.errors,
        isLoading: false,
        isSelected: false,
      })
      return
    }
  }

  const memoizedAutoCompleteEstabelecimento = useMemo(() => {
    return (
      <AutoCompleteEstabelecimento
        label=""
        placeholder="Estabelecimento"
        value={empresa?.estabelecimento || null}
        onChange={(e, obj) => {
          setEmpresa((oldState) => ({
            ...oldState,
            estabelecimento: obj || null,
          }))
        }}
        classes={{
          root: classes.root,
          input: classes.inputInput,
          inputRoot: classes.inputInputRoot,
          endAdornment: classes.inputEndAdornment,
          popupIndicator: classes.inputPopupIndicator,
          clearIndicator: classes.inputPopupIndicator,
          listbox: classes.autoCompleteListbox,
        }}
        classesInput={{ notchedOutline: classes.textFieldNotchedOutline }}
        onBlur={() => {
          if (!empresa?.estabelecimento?.id) {
            setEmpresa((oldState) => ({
              ...oldState,
              estabelecimento: empresaSelected?.estabelecimento || null,
            }))
          }
        }}
        anoMes={competencia.ano + competencia.mes}
      />
    )
  }, [empresaSelected.estabelecimento, classes, empresa.estabelecimento, competencia])

  function RenderBoxAlert() {
    let alertProps = {
      severity: 'error',
      show: false,
      message: 'Erro inesperado!',
    }

    const dateCurrent = moment(new Date(competencia.ano, parseInt(competencia.mes) - 1, 1))
    const showWarningYearhMonth = dateCurrent.diff(new Date(), 'months') >= 1

    if (showWarningYearhMonth) {
      alertProps.severity = 'warning'
      alertProps.show = true
      alertProps.message = `O Ano/Mês (${formatAnoMes(
        competencia.ano.toString() + '/' + competencia.mes.toString(),
      )}) está muito acima da data atual: ${moment().format('DD/MM/yyyy')}`
    }

    if (!peridoStatus.isSelected && !peridoStatus.error && !peridoStatus.isLoading) {
      alertProps.severity = 'error'
      alertProps.show = true
      alertProps.message = 'O ambiente de trabalho não está definido'
    }

    if (peridoStatus.error) {
      alertProps.severity = 'error'
      alertProps.show = true
      alertProps.message = peridoStatus.error
    }

    if (!alertProps.show) return <></>

    return (
      <Box className={classes.simpleAlertRoot}>
        <SimpleAlert show={alertProps.show} severity={alertProps.severity}>
          {alertProps.message}
        </SimpleAlert>
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <RenderBoxAlert />

      <Box className={classes.boxEmpresa}>{memoizedAutoCompleteEstabelecimento}</Box>

      <Box className={classes.boxData}>
        <AnoSelect
          fullWidth
          onChange={(e) => {
            const ano = e.target.value
            setCompetencia({ ...competencia, ano })
          }}
          value={competencia.ano || ''}
          SelectProps={{
            classes: {
              icon: classes.monthIcon,
            },
          }}
          InputProps={{
            ...props.InputProps,
            classes: {
              input: classes.inputInputBold,
              notchedOutline: classes.textFieldNotchedOutline,
            },
          }}
          classes={{
            root: classes.root,
          }}
        />
      </Box>

      <Box className={classes.boxData}>
        <DatePickerTextField
          fullWidth
          value={competencia.mes || ''}
          variant="outlined"
          onChange={(e) => {
            const mes = e.target.value
            setCompetencia({ ...competencia, mes })
          }}
          select
          SelectProps={{
            classes: {
              icon: classes.monthIcon,
            },
          }}
        >
          <MenuItem value="01">Janeiro</MenuItem>
          <MenuItem value="02">Fevereiro</MenuItem>
          <MenuItem value="03">Março</MenuItem>
          <MenuItem value="04">Abril</MenuItem>
          <MenuItem value="05">Maio</MenuItem>
          <MenuItem value="06">Junho</MenuItem>
          <MenuItem value="07">Julho</MenuItem>
          <MenuItem value="08">Agosto</MenuItem>
          <MenuItem value="09">Setembro</MenuItem>
          <MenuItem value="10">Outubro</MenuItem>
          <MenuItem value="11">Novembro</MenuItem>
          <MenuItem value="12">Dezembro</MenuItem>
        </DatePickerTextField>
      </Box>

      {peridoStatus.isLoading && (
        <Box className={classes.boxLoading}>
          <CircularProgress size={16} />
        </Box>
      )}
    </Box>
  )
}

export default SelecaoAmbiente
