import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import { notifySuccess } from '~/utils/notification'
import { openDownloadData } from '~/hooks/useUtils'

interface RequestProps {
  data: string[]
  params: {
    cnabId: string
    estabelecimentoId: string
    anoMes: string
    reciboIDF: ReciboIDFEnum
  }
}

export default function useGerarPlanilha(nomeArquivo: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{ data: Blob }>('/CNAB/GerarPlanilha', data, {
      params,
      responseType: 'blob',
    })
    return response.data
  }

  return useMutation(handleRequest, {
    onSuccess: (d) => {
      openDownloadData(nomeArquivo, d)
      notifySuccess('Planilha gerada com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
