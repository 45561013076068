import { convertToArrayDescription } from '~/utils/utils'

export enum CRReclamatoriaTrabalhistaEnum {
  NAO_INFORMAR = '000000',
  CP_PATRONAL_CARGO_EMPRESA_113851 = '113851',
  CP_GILRAT_CARGO_EMPRESA_164651 = '164651',
  CP_FINANCIAMENTO_APOSENTADORIA_CARGO_EMPRESA_114151 = '114151',
  CP_ADICIONAL_CARGO_INSTITUICOES_113852 = '113852',
  CP_PATRONAL_CARGO_EMPRESA_CONTRIBUINTE_INDIVIDUAL_113854 = '113854',
  CP_FINANCIAMENTO_APOSENTADORIA_ESPECIAL_114155 = '114155',
  CP_ADICIONAL_CARGO_INSTITUICOES_113855 = '113855',
  CP_PATRONAL_CARGO_EMPREGADOR_113858 = '113858',
  CP_GILRAT_CARGO_EMPREGADOR_164659 = '164659',
  CP_PATRONAL_CARGO_MICROEMPREENDEDOR_113857 = '113857',
  CP_PATRONAL_CARGO_EMPRESA_SIMPLES_113853 = '113853',
  CP_GILRAT_CARGO_EMPRESA_SIMPES_164652 = '164652',
  CP_ADICIONAL_GILRAT_CARGO_EMPRESA_114152 = '114152',
  CP_PATRONAL_CARGO_EMPRESA_SIMPLES_113856 = '113856',
  SALARIO_EDUCACAO_CARGO_EMPRESA_117051 = '117051',
  INCRA_CARGO_EMPRESA_SOBRE_REMUNERACAO_117651 = '117651',
  INCRA_FPAS_531_795_825_CARGO_EMPRESA_117652 = '117652',
  SENAI_CARGO_EMPRESA_REMUNERACAO_118151 = '118151',
  SESI_CARGO_EMPRESA_118451 = '118451',
  SENAC_CARGO_EMPRESA_119151 = '119151',
  SESC_CARGO_EMPRESA_119651 = '119651',
  SEBRAE_CARGO_EMPRESA_120051 = '120051',
  SEBRAE_FPAS_566_574_647_CARGO_EMPRESA_120052 = '120052',
  FDEPM_CARGO_EMPRESA_120551 = '120551',
  FUNDO_AEROVIARIO_CARGO_EMPRESA_120951 = '120951',
  SENAR_CARGO_EMPRESA_121353 = '121353',
  SEST_CARGO_EMPRESA_121851 = '121851',
  SEST_CARGO_TRABALHADOR_121852 = '121852',
  SENAT_CARGO_EMPRESA_122151 = '122151',
  SENAT_CARGO_TRABALHADOR_122152 = '122152',
  SESCOOP_CARGO_EMPRESA_122551 = '122551',
  CP_SEGURADO_EMPREGADO_TRABALHADOR_AVULSO_108251 = '108251',
  CP_SEGURADO_EMPREGADO_CONTRATO_CURTO_PRAZO_108252 = '108252',
  CP_SEGURADO_EMPREGADO_DOMESTICO_108253 = '108253',
  CP_SEGURADO_EMPREGADO_CONTRATADO_CURTO_PRAZO_108254 = '108254',
  CP_SEGURADO_EMPREGADO_CONTRATO_SEGURADOR_ESPECIAL_108255 = '108255',
  CP_SEGURADO_EMPREGADO_CONTRATADO_EMPREGADOR_MEI_108257 = '108257',
  CP_SEGURADO_CONTRIBUINTE_INDIVIDUAL_11_109951 = '109951',
  CP_SEGURADO_CONTRIBUINTE_INDIVIDUAL_20_109952 = '109952',
  IRRF_DECISAO_JUSTICA_TRABALHO_593656 = '593656',
  IRRF_RRA_DECISAO_JUSTICA_TRABALHO_188951 = '188951',
}

export const CRReclamatoriaTrabalhistaLabels = {
  [CRReclamatoriaTrabalhistaEnum.NAO_INFORMAR]: '0000-00 - Não informar',
  [CRReclamatoriaTrabalhistaEnum.CP_PATRONAL_CARGO_EMPRESA_113851]:
    '1138-51 - CP patronal a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.CP_GILRAT_CARGO_EMPRESA_164651]:
    '1646-51 - CP GILRAT a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.CP_FINANCIAMENTO_APOSENTADORIA_CARGO_EMPRESA_114151]:
    '1141-51 - CP para financiamento de aposentadoria especial a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.CP_ADICIONAL_CARGO_INSTITUICOES_113852]:
    '1138-52 - CP adicional a cargo das instituições financeiras sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.CP_PATRONAL_CARGO_EMPRESA_CONTRIBUINTE_INDIVIDUAL_113854]:
    '1138-54 - CP patronal a cargo da empresa sobre a remuneração do segurado contribuinte individual',
  [CRReclamatoriaTrabalhistaEnum.CP_FINANCIAMENTO_APOSENTADORIA_ESPECIAL_114155]:
    '1141-55 - CP para financiamento de aposentadoria especial a cargo da empresa sobre a remuneração do segurado contribuinte individual',
  [CRReclamatoriaTrabalhistaEnum.CP_ADICIONAL_CARGO_INSTITUICOES_113855]:
    '1138-55 - CP adicional a cargo das instituições financeiras sobre a remuneração do segurado contribuinte individual',
  [CRReclamatoriaTrabalhistaEnum.CP_PATRONAL_CARGO_EMPREGADOR_113858]:
    '1138-58 - CP patronal a cargo do empregador doméstico sobre a remuneração do segurado empregado doméstico',
  [CRReclamatoriaTrabalhistaEnum.CP_GILRAT_CARGO_EMPREGADOR_164659]:
    '1646-59 - CP GILRAT a cargo do empregador doméstico sobre a remuneração do segurado empregado doméstico',
  [CRReclamatoriaTrabalhistaEnum.CP_PATRONAL_CARGO_MICROEMPREENDEDOR_113857]:
    '1138-57 - CP patronal a cargo do Microempreendedor - MEI sobre a remuneração do segurado empregado',
  [CRReclamatoriaTrabalhistaEnum.CP_PATRONAL_CARGO_EMPRESA_SIMPLES_113853]:
    '1138-53 - CP patronal a cargo da empresa SIMPLES com atividade concomitante sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.CP_GILRAT_CARGO_EMPRESA_SIMPES_164652]:
    '1646-52 - CP GILRAT a cargo da empresa SIMPLES com atividade concomitante sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.CP_ADICIONAL_GILRAT_CARGO_EMPRESA_114152]:
    '1141-52 - CP adicional GILRAT a cargo da empresa SIMPLES com atividade concomitante sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.CP_PATRONAL_CARGO_EMPRESA_SIMPLES_113856]:
    '1138-56 - CP patronal a cargo da empresa SIMPLES com atividade concomitante sobre a remuneração do segurado contribuinte individual',
  [CRReclamatoriaTrabalhistaEnum.SALARIO_EDUCACAO_CARGO_EMPRESA_117051]:
    '1170-51 - Salário-Educação a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.INCRA_CARGO_EMPRESA_SOBRE_REMUNERACAO_117651]:
    '1176-51 - Incra a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.INCRA_FPAS_531_795_825_CARGO_EMPRESA_117652]:
    '1176-52 - Incra (FPAS 531/795/825) a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SENAI_CARGO_EMPRESA_REMUNERACAO_118151]:
    '1181-51 - Senai a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SESI_CARGO_EMPRESA_118451]:
    '1184-51 - Sesi a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SENAC_CARGO_EMPRESA_119151]:
    '1191-51 - Senac a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SESC_CARGO_EMPRESA_119651]:
    '1196-51 - Sesc a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SEBRAE_CARGO_EMPRESA_120051]:
    '1200-51 - Sebrae a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SEBRAE_FPAS_566_574_647_CARGO_EMPRESA_120052]:
    '1200-52 - Sebrae (FPAS 566/574/647) a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.FDEPM_CARGO_EMPRESA_120551]:
    '1205-51 - FDEPM a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.FUNDO_AEROVIARIO_CARGO_EMPRESA_120951]:
    '1209-51 - Fundo Aeroviário a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SENAR_CARGO_EMPRESA_121353]:
    '1213-53 - Senar a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SEST_CARGO_EMPRESA_121851]:
    '1218-51 - Sest a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SEST_CARGO_TRABALHADOR_121852]:
    '1218-52 - Sest a cargo do trabalhador (descontado pela empresa) sobre a remuneração do segurado transportador autônomo',
  [CRReclamatoriaTrabalhistaEnum.SENAT_CARGO_EMPRESA_122151]:
    '1221-51 - Senat a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.SENAT_CARGO_TRABALHADOR_122152]:
    '1221-52 - Senat a cargo do trabalhador (descontado pela empresa) sobre a remuneração do segurado transportador autônomo',
  [CRReclamatoriaTrabalhistaEnum.SESCOOP_CARGO_EMPRESA_122551]:
    '1225-51 - Sescoop a cargo da empresa sobre a remuneração do segurado empregado ou trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.CP_SEGURADO_EMPREGADO_TRABALHADOR_AVULSO_108251]:
    '1082-51 - CP do segurado empregado e trabalhador avulso',
  [CRReclamatoriaTrabalhistaEnum.CP_SEGURADO_EMPREGADO_CONTRATO_CURTO_PRAZO_108252]:
    '1082-52 - CP do segurado empregado contratado por curto prazo - Lei 11.718/2009',
  [CRReclamatoriaTrabalhistaEnum.CP_SEGURADO_EMPREGADO_DOMESTICO_108253]:
    '1082-53 - CP do segurado empregado doméstico',
  [CRReclamatoriaTrabalhistaEnum.CP_SEGURADO_EMPREGADO_CONTRATADO_CURTO_PRAZO_108254]:
    '1082-54 - CP do segurado empregado contratado por curto prazo por empregador segurado especial - Lei 11.718/2009',
  [CRReclamatoriaTrabalhistaEnum.CP_SEGURADO_EMPREGADO_CONTRATO_SEGURADOR_ESPECIAL_108255]:
    '1082-55 - CP do segurado empregado contratado por empregador segurado especial',
  [CRReclamatoriaTrabalhistaEnum.CP_SEGURADO_EMPREGADO_CONTRATADO_EMPREGADOR_MEI_108257]:
    '1082-57 - CP do segurado empregado contratado por empregador MEI',
  [CRReclamatoriaTrabalhistaEnum.CP_SEGURADO_CONTRIBUINTE_INDIVIDUAL_11_109951]:
    '1099-51 - CP do segurado contribuinte individual - 11%',
  [CRReclamatoriaTrabalhistaEnum.CP_SEGURADO_CONTRIBUINTE_INDIVIDUAL_20_109952]:
    '1099-52 -  CP do segurado contribuinte individual - 20%',
  //Não tem esses seguintes códigos na tabela 29 do ESocial
  // [CRReclamatoriaTrabalhistaEnum.IRRF_DECISAO_JUSTICA_TRABALHO_593656]: '5936-56 - IRRF Decisão Justiça Trabalho',
  // [CRReclamatoriaTrabalhistaEnum.IRRF_RRA_DECISAO_JUSTICA_TRABALHO_188951]: '1889-51 - IRRF RRA Descisão Justiça Trabalho',
}

export const CRReclamatoriaTrabalhistaValues = convertToArrayDescription(
  CRReclamatoriaTrabalhistaEnum,
  CRReclamatoriaTrabalhistaLabels,
)
