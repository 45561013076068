import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterCustosPlanoSaudeTitular'

import { notifyRemove } from '~/utils/notification'

export function useDeletar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest(id: string) {
    await api.delete('/PlanoSaudeTitularCusto/' + id)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
