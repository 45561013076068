import React from 'react'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { useEstabilidadeGetAll } from '~/hooks/queries/useEstabilidade'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  onItemClick: (event: 'edit' | 'delete', idRow: string) => void
  query: string
}

export default function Table(props: TableProps) {
  const { onItemClick, query } = props

  const { data, isLoading, isFetching } = useEstabilidadeGetAll()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'descricao',
      label: 'Descrição',
    },
    {
      name: 'numeroDias',
      label: 'Número de Dias',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <DataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['codigo', 'descricao', 'numeroDias'],
      }}
      options={{}}
      orderBy=""
    />
  )
}
