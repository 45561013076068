import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterPorVinculoInvalidate } from './useObterPorVinculo'

import { notifyPost } from '~/utils/notification'

import { VinculoAdvertenciaCreateDTO } from './dtos/VinculoAdvertenciaCreateDTO'

export function useCreateVinculoAdvertencia() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterPorVinculoInvalidate()

  async function handleRequest(data: VinculoAdvertenciaCreateDTO) {
    await api.post('/VinculoAdvertencia', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
