import { useState, useEffect } from 'react'

import { Box, LinearProgress } from '@material-ui/core'

import { ContainerComponent } from '~/components'

import Cadastro from './Cadastro'
import ContainerPessoaFisica from './ContainerPessoaFisica'
import VinculoRescisao from './VinculoRescisao'
import VinculoSalarioContratual from './VinculoSalarioContratual'
import VinculoSalarioAdicional from './VinculoSalarioAdicional'
import VinculoSalarioAula from './VinculoSalarioAula'
import VinculoLocal from './VinculoLocal'
import VinculoHorario from './VinculoHorario'
import VinculoLembrete from './VinculoLembrete'
import VinculoObservacao from './VinculoObservacao'
import VinculoTreinamentos from './VinculoTreinamentos'

import { useHistory, useParams } from 'react-router-dom'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'
import api from '~/services/api-pessoal'

const Container = () => {
  const [vinculo, setVinculo] = useState({})
  const [isLoading, setLoading] = useState(false)

  const history = useHistory()
  const { id: idParam } = useParams()
  const { estabelecimento } = useAmbiente()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    if (!vinculo?.id) return
    if (vinculo.estabelecimentoId === estabelecimento.id) return
    goToPageVinculo()
    //eslint-disable-next-line
  }, [estabelecimento])

  useEffect(() => {
    const obterVinculo = async () => {
      if (!idParam) return
      setLoading(true)
      try {
        const response = await api.get(`vinculo/${idParam}`)
        setVinculo(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    obterVinculo()
    // eslint-disable-next-line
  }, [idParam])

  const goToPageVinculo = () => {
    history.push({
      pathname: '/funcionario',
    })
  }

  return (
    <Box height="100%">
      <Box height={4}>{isLoading && <LinearProgress />}</Box>
      <Box height={'calc(100% - 4px)'}>
        <ContainerComponent
          title="Cadastro de Funcionário"
          subtitle={vinculo?.pessoaFisica?.nome || '-'}
          fixedContainer
          menu
          onBack={goToPageVinculo}
          tabs={[
            {
              component: <Cadastro vinculo={vinculo} onClickClose={() => undefined} />,
              label: 'Cadastro',
            },
            {
              component: <ContainerPessoaFisica pessoaFisicaId={vinculo.pessoaFisicaId} />,
              label: 'Pessoa Física',
            },
            {
              component: <VinculoRescisao vinculoId={vinculo?.id} />,
              label: 'Rescisão',
              show: vinculo?.dtRescisao ? true : false,
            },
            {
              component: <VinculoSalarioContratual vinculo={vinculo} />,
              disabled: !vinculo?.id ? true : false,
              label: 'Salário Contratual',
            },
            {
              component: <VinculoSalarioAdicional vinculo={vinculo} />,
              disabled: !vinculo?.id ? true : false,
              label: 'Salário Adicional',
            },
            {
              component: <VinculoSalarioAula vinculo={vinculo} />,
              disabled: !vinculo?.id ? true : false,
              label: 'Salário Aula',
            },
            {
              component: <VinculoLocal vinculo={vinculo} />,
              disabled: !vinculo?.id ? true : false,
              label: 'Ambiente Trabalho',
            },
            {
              component: <VinculoHorario vinculo={vinculo} />,
              disabled: !vinculo?.id ? true : false,
              label: 'Horário de Trabalho',
            },
            {
              component: <VinculoLembrete vinculo={vinculo} />,
              disabled: !vinculo?.id ? true : false,
              label: 'Lembrete',
            },
            {
              component: <VinculoObservacao vinculo={vinculo} />,
              disabled: !vinculo?.id ? true : false,
              label: 'Observação',
            },
            {
              component: <VinculoTreinamentos vinculo={vinculo} />,
              disabled: !vinculo?.id ? true : false,
              label: 'Treinamentos',
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default Container
