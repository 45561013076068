import { useState } from 'react'

import moment from 'moment'

import { Box, Grid, Typography } from '@material-ui/core'

import { Button, ButtonBox, Checkbox, ToolsDataTable } from '~/components'
import { useStepperContext } from '~/components/StepperForm'

import { formatAnoMes, formatCurrency } from '~/hooks/useUtils'

import { TermoRescisaoDTOForm } from '~/hooks/queries/Rescisao/TermoRescisaoDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { TermoRescisaoEmprestimoDTO } from '~/hooks/queries/Rescisao/TermoRescisaoEmprestimoDTO'

export default function ReciboRescisaoEmprestimos() {
  const { mainData, onBack, onSubmit } = useStepperContext<TermoRescisaoDTOForm>()

  const [data, setData] = useState<TermoRescisaoEmprestimoDTO>(
    mainData.emprestimo as TermoRescisaoEmprestimoDTO,
  )

  const parcelas = data?.parcelas
    ? data.parcelas.map((d) => ({
        ...d,
        dtEmissao: d?.dtEmissao ? moment(d.dtEmissao).format('DD/MM/yyyy') : '',
        valorTotalEmprestimo: d?.valorTotalEmprestimo ? formatCurrency(d.valorTotalEmprestimo) : '',
        anoMes: d?.anoMes ? formatAnoMes(d.anoMes) : '',
        vlrParcela: d?.vlrParcela ? formatCurrency(d.vlrParcela) : '',
      }))
    : []

  function handleSubmit() {
    onSubmit({ ...mainData, emprestimo: data })
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtEmissao',
      label: 'Emissão',
    },
    {
      name: 'banco',
      label: 'Banco',
    },
    {
      name: 'valorTotalEmprestimo',
      label: 'Valor do Empréstimo',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'anoMes',
      label: 'Ano/Mês',
    },
    {
      name: 'nrParcela',
      label: 'Número da Parcela',
    },
    {
      name: 'vlrParcela',
      label: 'Valor da Parcela',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
  ]

  return (
    <Box>
      <Box height="30rem" overflow="auto" marginBottom={1}>
        <ToolsDataTable data={parcelas} columns={columns} />
      </Box>

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Typography>Valor total em aberto: {formatCurrency(data?.vrTotalAberto || 0)}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Checkbox
            label="Deseja descontar o valor de 30% do Saldo Líquido da Rescisão?"
            value={data?.isDescontar30SaldoRescisao}
            onChange={(_, check) => {
              setData((prev) => ({
                ...prev,
                isDescontar30SaldoRescisao: check,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ButtonBox>
            <Button onClick={onBack}>Voltar</Button>
            <Button onClick={handleSubmit} variant="contained">
              Finalizar
            </Button>
          </ButtonBox>
        </Grid>
      </Grid>
    </Box>
  )
}
