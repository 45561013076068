import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  labelInfo: {
    backgroundColor: theme.palette.error.main,
    textAlign: 'center',
    color: 'white',
    fontSize: '9pt',
    fontWeight: 'bolder',
    borderRadius: 90,
    width: 16,
    height: 16,
  },
}))

const BadgeInfo = (props) => {
  const { value } = props
  const classes = useStyles()
  return (
    <Typography variant="caption" color="inherit" className={classes.labelInfo}>
      {value}
    </Typography>
  )
}

export default BadgeInfo
