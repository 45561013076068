import React, { useRef } from 'react'

import { createMuiTheme, ThemeProvider, Box } from '@material-ui/core'
import { DataTable } from 'mio-library-ui'
import theme from '~/styles/theme'

import useHeightParents from '~/hooks/useHeightParents'

const HeightDataTable = (props) => {
  const { pagination, triggersHeight, delayResize, ...rest } = props

  const refRootTable = useRef(null)

  useHeightParents({
    refElement: refRootTable,
    triggers: triggersHeight,
    delayResize,
    minHeight: 300,
  })

  const height = pagination ? 'calc(100% - 60px - 1px)' : '100%'

  const tableTheme = createMuiTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MUIDataTableFooter: {
        root: {
          height: pagination ? '60px' : '0',
        },
      },
      MUIDataTable: {
        paper: {
          height: '100%',
        },
      },
    },
  })

  return (
    <Box ref={refRootTable}>
      <ThemeProvider theme={tableTheme}>
        <DataTable
          {...rest}
          options={{
            ...rest.options,
            tableBodyHeight: height,
          }}
          pagination={pagination}
        />
      </ThemeProvider>
    </Box>
  )
}

export default HeightDataTable

HeightDataTable.defaultProps = {
  pagination: true,
  triggersHeight: {},
  delayResize: 500,
}
