import React, { useState, useEffect } from 'react'

import { Grid, makeStyles, Box, Paper } from '@material-ui/core'
import { Button, PageHeader } from 'mio-library-ui'

import { MUIAutoComplete } from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'
import * as yup from 'yup'

import DialogConfirmReabertura from './components/DialogConfirmReabertura'
import { IndApuracaoValues, IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

const schema = yup.object().shape({
  indApuracao: yup.string().required('Informe o Indicador de Apuração'),
})

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  rootForm: {
    border: theme.shape.border,
    margin: 'auto',
    width: '40%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const AlterarCategoria = () => {
  const [isLoadingAnalize, setLoadingAnalize] = useState(false)
  const [indApuracao, setIndApuracao] = useState(IndApuracaoEnum.Mensal_1)

  const classes = useStyles()
  const { empregador, anoMes, mes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const {
    isOpen: isOpenDialogConfirmReabertura,
    data: dataDialogConfirmReabertura,
    open: openDialogConfirmReabertura,
    close: closeDialogConfirmReabertura,
  } = useDialog()

  useEffect(() => {
    setIndApuracao(IndApuracaoEnum.Mensal_1)
  }, [anoMes])

  function handleAnalizeCompetencia() {
    async function analize() {
      setLoadingAnalize(true)
      try {
        const response = await api.post(
          '/CompetenciaEmpregador/GetCompetenciaByEmpregador',
          {},
          {
            params: {
              empregadorId: empregador?.id || '',
              indApuracao,
              anoMes,
            },
          },
        )
        openDialogConfirmReabertura(response?.data?.data || {})
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingAnalize(false)
      }
    }
    analize()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleAnalizeCompetencia,
    data: { indApuracao },
  })

  return (
    <Box className={classes.root}>
      <PageHeader title="Reabertura Movimento do eSocial" />
      <Paper className={classes.rootForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MUIAutoComplete
              label="Indicador de Apuração"
              required
              name="indApuracao"
              validationErrors={validationErrors}
              value={indApuracao || ''}
              options={IndApuracaoValues}
              optionId="value"
              renderOption={(option) => option.name}
              onChange={(e, obj) => setIndApuracao(obj?.value || '')}
              getOptionDisabled={(option) =>
                option.value === IndApuracaoEnum.Anual_2 && mes !== '12'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleValidate}
                isLoading={isLoadingAnalize}
              >
                Fazer a Reabertura da Competência
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <DialogConfirmReabertura
        isOpen={isOpenDialogConfirmReabertura}
        onClose={closeDialogConfirmReabertura}
        data={dataDialogConfirmReabertura}
      />
    </Box>
  )
}

export default AlterarCategoria
