import React, { useState } from 'react'

import { ContainerComponent } from '~/components'

import FormPessoaFisica from './FormPessoaFisica'
import PessoaFisicaDependente from './PessoaFisicaDependente'
import VinculoObservacao from '~/pages/Funcionario/Container/VinculoObservacao'

const Container = (props) => {
  const { isOpen, onClose, vinculo, origemId } = props

  const [wasModified, setWasModified] = useState(false)

  return (
    <ContainerComponent
      title="Cadastro de Alteração Cadastral"
      subtitle={vinculo?.pessoaFisica?.nome || ''}
      menu
      isOpen={isOpen}
      onClose={onClose}
      formWasModified={wasModified}
      tabs={[
        {
          component: (
            <FormPessoaFisica
              pessoaFisicaId={vinculo?.pessoaFisica?.id}
              onClickClose={onClose}
              setWasModified={setWasModified}
            />
          ),
          disabled: !vinculo?.id ? true : false,
          label: 'Pessoa Física',
        },
        {
          component: <PessoaFisicaDependente pessoaFisicaId={vinculo?.pessoaFisica?.id} />,
          disabled: !vinculo?.id ? true : false,
          label: 'Dependentes',
        },
        {
          component: (
            <VinculoObservacao vinculo={vinculo} origemId={origemId} considerIsFinalizado={false} />
          ),
          disabled: !vinculo?.id ? true : false,
          label: 'Observação',
        },
      ]}
    />
  )
}

export default Container
