import { useEffect } from 'react'

import { useQuery, useQueryClient, useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'
import useAmbiente from '../useAmbiente'

export function useCargoGetAll() {
  const { empregador } = useAmbiente()
  const dialogNotification = useDialogNotification()

  async function getAll(empregadorId) {
    if (!empregadorId) return
    const response = await api.get(`/Cargo/${empregadorId}/selecionarEmpregador`)
    return response.data.data
  }

  const query = useQuery('Cargo', () => getAll(empregador?.id), {
    onError: (err) => dialogNotification.extractErrors(err),
  })

  useEffect(() => {
    query.refetch()
    // eslint-disable-next-line
  }, [empregador?.id])

  return query
}

export function useCargoMutation() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function update(data) {
    const response = await api.put(`/Cargo/${data.id}`, data)
    notification.put()
    return response.data.data
  }
  async function insert(data) {
    const response = await api.post('/Cargo', data)
    notification.post()
    return response.data.data
  }

  return useMutation(
    async (data) => {
      if (data?.id) {
        return update(data)
      }
      return insert(data)
    },
    {
      onSuccess: async (data) => {
        await queryClient.cancelQueries('Cargo')
        queryClient.setQueriesData('Cargo', (oldData) => {
          let finded = false
          const newData = oldData.map((obj) => {
            if (obj.id === data.id) {
              finded = true
              return data
            }
            return obj
          })
          if (finded) return newData
          return [data, ...oldData]
        })
      },
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}

export function useCargoDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleDelete(id) {
    await api.delete(`/Cargo/${id}`)
    notification.remove()
    return id
  }

  return useMutation((id) => handleDelete(id), {
    onSuccess: async (id) => {
      await queryClient.cancelQueries('Cargo')
      queryClient.setQueriesData('Cargo', (old) => {
        const newData = old.filter((d) => d.id !== id)
        return newData
      })
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
