import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, TextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import {
  IndMotivoAfastamentoValues,
  IndMotivoAfastamentoEnum,
} from '~/@types/enums/IndMotivoAfastamentoEnum'
import {
  OrigemRetificacaoAfastamentoEnum,
  OrigemRetificacaoAfastamentoValues,
} from '~/@types/enums/OrigemRetificacaoAfastamentoEnum'
import {
  ProcessoTipoAfastamentoEnum,
  ProcessoTipoAfastamentoValues,
} from '~/@types/enums/ProcessoTipoAfastamentoEnum'

import {
  VinculoAfastamento,
  VinculoAfastamentoForm,
} from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'

const schema = yup.object().shape({
  motivoAfastamento: yup
    .mixed()
    .oneOf(Object.values(IndMotivoAfastamentoEnum), 'Informe o Motivo do Afastamento'),
  origemRetificacao: yup
    .mixed()
    .oneOf(Object.values(OrigemRetificacaoAfastamentoEnum), 'Informe a Origem Retificação')
    .test(
      'valor-valido-enum',
      'Informe a Origem Retificação',
      (value: any) => value !== OrigemRetificacaoAfastamentoEnum.NaoAplicavel_9,
    ),
  tipoProcesso: yup
    .mixed()
    .oneOf(Object.values(ProcessoTipoAfastamentoEnum), 'Informe o Tipo Processo')
    .when(['origemRetificacao'], (origemRetificacao: OrigemRetificacaoAfastamentoEnum, schema) =>
      schema.test(
        'tipo-processo-obrigatorio-quando',
        'Informe o Tipo Processo',
        (value: ProcessoTipoAfastamentoEnum) =>
          !(
            value === ProcessoTipoAfastamentoEnum.NaoInformado_9 &&
            (origemRetificacao === OrigemRetificacaoAfastamentoEnum.RevisãoAdministrativa_2 ||
              origemRetificacao === OrigemRetificacaoAfastamentoEnum.DeterminaçãoJudicial_3)
          ),
      ),
    ),
  nrProcesso: yup
    .string()
    .nullable()
    .when(
      ['origemRetificacao'],
      (origemRetificacao: OrigemRetificacaoAfastamentoEnum, schema, { value }) => {
        let minLength = 0

        switch (origemRetificacao) {
          case OrigemRetificacaoAfastamentoEnum.PorIniciativaEmpregador_1:
            if (value) {
              minLength = 17
            } else {
              minLength = 0
            }
            break
          case OrigemRetificacaoAfastamentoEnum.RevisãoAdministrativa_2:
            minLength = 20
            break
          case OrigemRetificacaoAfastamentoEnum.DeterminaçãoJudicial_3:
            minLength = 10
            break
        }

        return schema
          .test(
            'tipo-processo-obrigatorio-quando',
            'Informe o Nr. Processo',
            (value: string | null) =>
              !(
                !value &&
                (origemRetificacao === OrigemRetificacaoAfastamentoEnum.RevisãoAdministrativa_2 ||
                  origemRetificacao === OrigemRetificacaoAfastamentoEnum.DeterminaçãoJudicial_3)
              ),
          )
          .min(minLength, `Informe no mínimo ${minLength} algarismos`)
      },
    ),
})

interface DialogMotivoAfastamentoProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoAfastamentoForm
  onAfterSubmitForm: (afastamento: VinculoAfastamento) => void
  isLoading: boolean
}

export default function DialogMotivoAfastamento(props: DialogMotivoAfastamentoProps) {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, isLoading } = props

  const [data, setData] = useState(_data)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    await onAfterSubmitForm(data as VinculoAfastamento)
    onClose()
  }

  return (
    <ActionDialog
      title="Alterar Motivo do Afastamento"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
      okLabel="Salvar"
      cancelLabel="Cancelar"
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Motivo do Afastamento"
            required
            name="motivoAfastamento"
            validationErrors={validationErrors}
            value={data.motivoAfastamento}
            options={IndMotivoAfastamentoValues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(_, obj: any) => {
              const motivoAfastamento = obj?.value || 0
              setData((oldState) => ({
                ...oldState,
                motivoAfastamento,
              }))
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MUIAutoComplete
            label="Origem Retificação"
            required
            validationErrors={validationErrors}
            name="origemRetificacao"
            options={OrigemRetificacaoAfastamentoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.origemRetificacao}
            onChange={(e, obj) => {
              const origemRetificacao = obj ? obj.value : 0
              setData({ ...data, origemRetificacao })
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MUIAutoComplete
            label="Tipo Processo"
            required={
              data.origemRetificacao === OrigemRetificacaoAfastamentoEnum.RevisãoAdministrativa_2 ||
              data.origemRetificacao === OrigemRetificacaoAfastamentoEnum.DeterminaçãoJudicial_3
            }
            validationErrors={validationErrors}
            name="tipoProcesso"
            options={ProcessoTipoAfastamentoValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.tipoProcesso}
            onChange={(e, obj) => {
              const tipoProcesso = obj ? obj.value : 0
              setData({ ...data, tipoProcesso })
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Nr. Processo"
            fullWidth
            value={data?.nrProcesso || ''}
            size="small"
            onlyNumber
            required={
              data.origemRetificacao === OrigemRetificacaoAfastamentoEnum.RevisãoAdministrativa_2 ||
              data.origemRetificacao === OrigemRetificacaoAfastamentoEnum.DeterminaçãoJudicial_3
            }
            name="nrProcesso"
            validationErrors={validationErrors}
            inputProps={{
              maxLength: 21,
            }}
            onChange={(e) => {
              const nrProcesso = e.target.value
              setData({ ...data, nrProcesso })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
