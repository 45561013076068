import { makeStyles, Divider, Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const changeLabelSize = {
  small: '7pt',
  medium: '8pt',
  normal: '9pt',
}

const changeValueSize = {
  small: '9pt',
  medium: '10pt',
  normal: '11pt',
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: '#f5fffb',
      transition: '0.5s',
    },
  },
  content: {
    margin: 'auto',
  },
  label: {
    fontSize: (props) => changeLabelSize[props.size],
  },
  boxValue: {
    textAlign: 'right',
  },
  fontValue: {
    fontSize: (props) => changeValueSize[props.size],
    fontWeight: 'bolder',
    color: (props) => props.color,
  },
  rootSkeleton: {
    paddingTop: theme.spacing(0.5),
    width: '100%',
  },
  skeleton: {
    width: '90%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

const LabelValueColor = (props) => {
  const { value, label, color, showDivider, size, isLoading, title, ...rest } = props

  const classes = useStyles({ color, size })

  if (isLoading)
    return (
      <Box className={classes.rootSkeleton} {...rest}>
        <Skeleton variant="text" className={classes.skeleton} />
        <Skeleton variant="text" className={classes.skeleton} />
      </Box>
    )

  return (
    <Box className={classes.root} title={title} {...rest}>
      <Box className={classes.content}>
        <Typography omponent="span" className={classes.label} variant="subtitle2">
          {label}
        </Typography>
        <Box className={classes.boxValue}>
          <Typography className={classes.fontValue}>{value}</Typography>
        </Box>
      </Box>
      {showDivider && <Divider orientation="vertical" />}
    </Box>
  )
}

LabelValueColor.defaultProps = {
  showDivider: true,
  color: '#666',
  label: '',
  value: '',
  size: 'normal',
  isLoading: false,
}

export default LabelValueColor
