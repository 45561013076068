import { useQueryClient } from 'react-query'

import useSocket from '~/hooks/useSocket'
import useAmbiente from '~/hooks/useAmbiente'

import { queryUseObterTotalEventosGrupoAcompanhamento } from './useObterTotalEventosGrupoAcompanhamento'
import { queryUseObterEventosTransmissaoAcompanhamento } from './useObterEventosTransmissaoAcompanhamento'

export default function useSocketTransmissaoESocial() {
  const { empresaId } = useAmbiente()

  const queryClient = useQueryClient()
  useSocket({
    urlHub: '/ESocialDashBoardHub',
    onStartConnection: (socket) => {
      socket.send('RemoveFromGroup', empresaId)
      socket.send('AddToGroup', empresaId)
      socket.on('ESocialDashBoardUpdated', () => {
        queryClient.invalidateQueries(queryUseObterTotalEventosGrupoAcompanhamento)
        queryClient.invalidateQueries(queryUseObterEventosTransmissaoAcompanhamento)
      })
    },
  })
}
