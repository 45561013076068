import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { formatToBRL } from 'brazilian-values'
import moment from 'moment'

import { HeightDataTable } from '~/components'

import { IndSalarioAdicionalValues } from '~/@types/enums/IndSalarioAdicionalEnum'

function formatIndAdicional(indAdicional) {
  const objectFinded = IndSalarioAdicionalValues.find((obj) => obj.value === indAdicional)
  return objectFinded?.name || ''
}

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = useMemo(
    () => [
      {
        name: 'dtInicio',
        label: 'Data Início',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtFim',
        label: 'Data Fim',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'indAdicional',
        label: 'Indicador do Adcional',
        options: {
          customBodyRender: (value) => formatIndAdicional(value),
        },
      },
      {
        name: 'aliquota',
        label: 'Aliquota',
        options: {
          customBodyRender: (value) => (
            <ButtonBox>{value && `${formatToBRL(value).replace('R$ ', '')}%`}</ButtonBox>
          ),
        },
      },
      {
        name: 'vrAdicional',
        label: 'Valor',
        options: {
          customBodyRender: (value) => (
            <ButtonBox>{value && formatToBRL(value).replace('R$ ', '')}</ButtonBox>
          ),
        },
      },
      {
        name: 'observacao',
        label: 'Observação',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                  size="small"
                >
                  <EditIcon fontSize="small" color="primary" size="small" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                  size="small"
                >
                  <DeleteIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
