import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
  IconButton,
  useTheme,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox } from '~/components'

import Header from './Header'
import TableDayOfWeek from './TableDayOfWeek'

import { formatCurrency } from '~/hooks/useUtils'
import useObterTodosPorVinculo from '~/hooks/queries/VinculoValeTransporte/useObterTodosPorVinculo'
import useVinculoValeTransporteRemove from '~/hooks/queries/VinculoValeTransporte/useVinculoValeTransporteRemove'

import { DiaHorarioValues } from '~/@types/enums/DiaHorarioEnum'
import { VinculoValeTransporteDTO } from '~/hooks/queries/VinculoValeTransporte/VinculoValeTransporteDTO'

export interface ColumnTableType<TData> {
  label: string
  name: keyof TData
  options?: {
    customBodyRender?: (value: any) => React.ReactNode | string
    width?: number
    minWidth?: number
    height?: number
  }
}

interface ContentProps {
  vinculoId: string
}

export default function Content({ vinculoId }: ContentProps) {
  const theme = useTheme()

  const { mutateAsync: mutateAsyncRemove, isLoading: isDeleting } = useVinculoValeTransporteRemove()
  const { data: _data, isLoading, isFetching: _isFetching } = useObterTodosPorVinculo({ vinculoId })
  const data = _data || []

  const isFetching = _isFetching || isDeleting

  async function handleRemove(vinculoValeTransporteId: string) {
    await mutateAsyncRemove(vinculoValeTransporteId)
  }

  const columns: ColumnTableType<VinculoValeTransporteDTO>[] = [
    {
      label: 'Transportadora',
      name: 'valeTransporteNome',
      options: {
        minWidth: 200,
        height: 30,
      },
    },
    {
      label: 'Quantidade',
      name: 'quantidadeVales',
      options: {
        width: 100,
        height: 30,
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      label: 'Valor',
      name: 'valeTransporteValor',
      options: {
        width: 200,
        height: 30,
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      label: 'Total',
      name: 'valeTransporteTotal',
      options: {
        width: 200,
        height: 30,
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      label: 'Ações',
      name: 'id',
      options: {
        width: 70,
        height: 30,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => handleRemove(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  if (isLoading) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={70} />
      </Box>
    )
  }

  return (
    <Box>
      <Header columns={columns} />
      <Box height={4}>{isFetching && <LinearProgress />}</Box>
      {data.length > 0 ? (
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
          {DiaHorarioValues.map((d) => (
            <TableDayOfWeek
              key={d.value}
              title={d.name.toUpperCase()}
              data={data.filter((x) => x.diaSemana == d.value)}
              columns={columns}
            />
          ))}
        </Box>
      ) : (
        <Typography>Nenhum Vale Transporte configurado para este funcionário.</Typography>
      )}
    </Box>
  )
}
