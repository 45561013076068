export enum IndSubsPatronalEnum {
  IntegralmenteSubstituida_1 = 1,
  ParcialmenteSubstituida_2 = 2,
  NaoAplicavel_9 = 9,
}

export const IndSubsPatronalValues = [
  { value: IndSubsPatronalEnum.NaoAplicavel_9, name: '9 - Não informar' },
  { value: IndSubsPatronalEnum.IntegralmenteSubstituida_1, name: '1 - Integralmente Substituída' },
  { value: IndSubsPatronalEnum.ParcialmenteSubstituida_2, name: '2 - Parcialmente Substituída' },
]
