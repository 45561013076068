import { formatCurrency } from '~/hooks/useUtils'

import { makeStyles } from '@material-ui/core'

import { ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ApuracaoEncargosFGTSApuracaoDTO } from '~/hooks/queries/ApurarEncargos/ApuracaoEncargosFGTSDTO'
import { IndIncidenciaFGTSValues } from '~/@types/enums/IndIncidenciaFGTSEnum'
import { IndValorFGTSValues } from '~/@types/enums/IndValorFGTSEnum'

const useStyles = makeStyles(() => ({
  alignRight: {
    textAlign: 'right',
  },
}))

interface FGTSApuracaoProps {
  data: ApuracaoEncargosFGTSApuracaoDTO[]
}

export default function FGTSApuracao({ data: _data }: FGTSApuracaoProps) {
  const classes = useStyles()

  const data = _data.map((d) => {
    return {
      ...d,
      vrBaseFGTS: d?.vrBaseFGTS ? formatCurrency(d.vrBaseFGTS) : '',
      vrFGTS: d?.vrFGTS ? formatCurrency(d.vrFGTS) : '',
      indIncidencia: d?.indIncidencia
        ? IndIncidenciaFGTSValues.find((x) => x.value === d.indIncidencia)?.name || ''
        : '',
      tpValor: d?.tpValor ? IndValorFGTSValues.find((x) => x.value === d.tpValor)?.name || '' : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'calculo',
      label: 'Cálculo',
    },
    {
      name: 'indIncidencia',
      label: 'Incidência',
    },
    {
      name: 'tpValor',
      label: 'Tipo Valor',
    },
    {
      name: 'vrBaseFGTS',
      label: 'Base FGTS',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrFGTS',
      label: 'Valor FGTS',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
  ]

  return <ToolsDataTable data={data} columns={columns} disableAutoHeight />
}
