import React from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'
import ThinkingImage from '~/assets/images/thinking.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%',
    width: '100%',
  },
  typo: {
    fontSize: 24,
    fontWeight: 'bold',
    color: theme.palette.secondary.dark,
  },
}))

const Displays = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <img src={ThinkingImage} alt="SelecionarAmbiente" width={200} />
      <Typography className={classes.typo}>
        Em qual ambiente eu devo processar a sua requisição?
      </Typography>
    </Box>
  )
}

export default Displays
