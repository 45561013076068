import React, { useState } from 'react'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import useUtils from '~/hooks/useUtils'

import { formatToBRL } from 'brazilian-values'
import moment from 'moment'

const Table = (props) => {
  const { data, onItemClick, isLoading, pagination, query } = props
  const [searchText, setSearchText] = useState('')

  const { formatIdfReciboPagamento } = useUtils()

  const columns = [
    {
      name: 'idf',
      label: 'Identificador',
      options: {
        customBodyRender: (value) => {
          return formatIdfReciboPagamento(value)
        },
      },
    },
    {
      name: 'pessoaNome',
      label: 'Nome',
    },
    {
      name: 'cargoNome',
      label: 'Cargo / Nome',
    },
    {
      name: 'anoMes',
      label: 'Ano/Mês',
    },
    {
      name: 'vrTotalLiquido',
      label: 'Total Líquido',

      options: {
        customBodyRender: (value) => {
          return <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>
        },
      },
    },
    {
      name: 'dtPagamento',
      label: 'Data Pagamento',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  // const options = {
  //   filterType: "textField",
  //   selectableRows: "none",
  //   searchText,
  //   onSearchChange: (search) => {
  //     setSearchText(search);
  //   },
  //   responsive: "vertical",
  //   print: false,
  //   download: false,
  //   elevation: 0,
  //   filter: false,
  //   viewColumns: false,
  //   search: false,
  //   // tableBodyMaxHeight: "500px",
  //   rowsPerPage: 25,
  //   rowsPerPageOptions: [25, 50, 100],
  // };

  const options = {
    viewColumns: false,
    filter: false,
    search: false,
    selectableRows: 'none',
    searchText,
    print: false,
    download: false,
    elevation: 0,
    onSearchChange: (search) => {
      setSearchText(search)
    },
    responsive: 'standard',
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
  }

  return (
    <DataTable
      data={data}
      columns={columns}
      options={options}
      isLoading={isLoading}
      pagination={pagination}
      sherlock={{
        query,
        columns: ['pessoaNome'],
      }}
    />
  )
}

Table.defaultProps = {
  pagination: true,
}

export default Table
