export enum ESocialStatusEnum {
  /**Registro em Aberto */
  Aberto_10 = 10,

  /**Registro pendente de gerar o xml para envio */
  Pendente_GerarXMLEnvio_11 = 11,

  /**Registro pendente de gerar o xml do lote */
  Pendente_GerarXMLLote_12 = 12,

  /**Registro está em processo de transmissao controlado pelo lote */
  Em_Transmissao_13 = 13,

  /**Registro com recibo aguardando o processamento do retorno */
  Pendente_ProcessamentoRetorno_14 = 14,

  /**Registro finalizado com sucesso */
  Finalizado_21 = 21,

  /**Registro finalizado com advertência */
  Finalizado_Advertencia_22 = 22,

  /**Registro com erro de schema */
  Erro_Schema_41 = 41,

  /**Registro com erro no envio  */
  Erro_Envio_42 = 42,

  /**Regsitro com erro ao buscar Recibo */
  Erro_BuscarRecibo_43 = 43,

  /**Registro com erro interno do eSocial, erro 301 */
  Erro_InternoeSocial_49 = 49,

  /**Registro fechamento com erro  */
  Erro_ValidacaoFechamento_90 = 90,

  /**Registro inativo  */
  RegistroInativo_99 = 99,

  /**Todos os registros do empregador. */
  Todos_Registros_100 = 100,
}

export const ESocialStatusValues = [
  {
    name: '100 - Todos',
    value: ESocialStatusEnum.Todos_Registros_100,
  },
  {
    name: '99 - Inativos',
    value: ESocialStatusEnum.RegistroInativo_99,
  },
  {
    name: '10 - Registro em Aberto',
    value: ESocialStatusEnum.Aberto_10,
  },
  {
    name: '11 - Registro pendente de gerar o xml para envio',
    value: ESocialStatusEnum.Pendente_GerarXMLEnvio_11,
  },
  {
    name: '12 - Registro pendente de gerar o xml do lote',
    value: ESocialStatusEnum.Pendente_GerarXMLLote_12,
  },
  {
    name: '13 - Registro está em processo de transmissao controlado pelo lote',
    value: ESocialStatusEnum.Em_Transmissao_13,
  },
  {
    name: '14 - Registro com recibo aguardando o processamento do retorno',
    value: ESocialStatusEnum.Pendente_ProcessamentoRetorno_14,
  },
  {
    name: '21 - Registro finalizado com sucesso',
    value: ESocialStatusEnum.Finalizado_21,
  },
  {
    name: '22 - Registro finalizado com advertência',
    value: ESocialStatusEnum.Finalizado_Advertencia_22,
  },
  {
    name: '41 - Registro com erro de schema',
    value: ESocialStatusEnum.Erro_Schema_41,
  },
  {
    name: '42 - Registro com erro no envio ',
    value: ESocialStatusEnum.Erro_Envio_42,
  },
  {
    name: '43 - Registro com erro ao buscar Recibo',
    value: ESocialStatusEnum.Erro_BuscarRecibo_43,
  },
]
