import React, { useMemo, useState, useCallback } from 'react'

import { Box, IconButton, makeStyles } from '@material-ui/core'
import { ButtonBox, Finder } from 'mio-library-ui'
import { List as ListIcon } from '@material-ui/icons'

import { HeightDataTable, ActionDialog } from '~/components'

import DialogEpis from './components/DialogEpis'
import { agenteNocivoUnidadeMedidaValues } from '~/values/agenteNocivoUnidadeMedidaValues'
import { agenteNocivoTipoAvaliacaoValues } from '~/values/agenteNocivoTipoAvaliacaoValues'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '600px',
  },
}))

const formatTipoAvaliacao = (tipoAvaliacao) => {
  const objectFinded = agenteNocivoTipoAvaliacaoValues.find((obj) => obj.value === tipoAvaliacao)
  return objectFinded.name
}

const formatUnidadeMedida = (unidadeMedida) => {
  const objFinded = agenteNocivoUnidadeMedidaValues.find((obj) => obj.value === unidadeMedida)
  return objFinded.name
}

const DialogAgentesNociveis = (props) => {
  const { data: _data, isOpen, onClose, vinculo } = props

  const [query, setQuery] = useState('')
  const [dialogEpis, setDialogEpis] = useState({
    isOpen: false,
    data: [],
  })

  const classes = useStyles()

  const openDialogEpis = useCallback((epis) => {
    setDialogEpis({
      isOpen: true,
      data: epis,
    })
  }, [])

  function closeDialogEpis() {
    setDialogEpis({
      isOpen: false,
      data: [],
    })
  }

  const data = _data.map((item) => {
    return {
      ...item,
      agenteNocivo: item?.agenteNocivo ? `${item.agenteNocivo.id} - ${item.agenteNocivo.nome}` : '',
      tipoAvaliacao: formatTipoAvaliacao(item.tipoAvaliacao),
      unidadeMedida: formatUnidadeMedida(item.unidadeMedida),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'condicaoAmbienteTrabalhoAgenteNocivoEPIItens',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'agenteNocivo',
        label: 'Agente Nocivo',
      },
      {
        name: 'tipoAvaliacao',
        label: 'Tipo de Avaliação',
      },
      {
        name: 'intensidadeConcentracao',
        label: 'Intensidade Concentração',
      },
      {
        name: 'limiteTolerancia',
        label: 'Limite Tolerância',
      },
      {
        name: 'unidadeMedida',
        label: 'Unidade Medida',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, { rowData }) => {
            const episRowData = rowData[0]
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => openDialogEpis(episRowData)}
                >
                  <ListIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [openDialogEpis],
  )

  return (
    <ActionDialog
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      title="Agentes Nocivos - Evento S-2240"
      subtitle={vinculo ? vinculo?.vinculoNome : '-'}
      renderRight={<Finder onSearch={setQuery} onClose={() => setQuery('')} />}
      customActions={<></>}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <Box height="100%" pb={1}>
        <HeightDataTable
          title=""
          data={data}
          columns={columns}
          sherlock={{
            query,
            columns: ['agenteNocivo', 'tipoAvaliacao', 'unidadeMedida'],
          }}
        />
      </Box>
      <DialogEpis
        data={dialogEpis.data}
        vinculo={vinculo}
        isOpen={dialogEpis.isOpen}
        onClose={closeDialogEpis}
      />
    </ActionDialog>
  )
}

export default DialogAgentesNociveis
