import { convertToArrayDescription } from '~/utils/utils'

export enum InfoProcessoOrigemEnum {
  Processo_Judicial_1 = 1,
  Demanda_Submetida_CCP_Ou_NINTER_2 = 2,
}

export const InfoProcessoOrigemLabels = {
  [InfoProcessoOrigemEnum.Processo_Judicial_1]: '1 - Processo judicial',
  [InfoProcessoOrigemEnum.Demanda_Submetida_CCP_Ou_NINTER_2]:
    '2 - Demanda submetida à CCP ou ao NINTER',
}

export const InfoProcessoOrigemValues = convertToArrayDescription(
  InfoProcessoOrigemEnum,
  InfoProcessoOrigemLabels,
)
