import React from 'react'

import { Box } from '@material-ui/core'

import { formatCurrency } from '~/hooks/useUtils'

interface AlignCellProps {
  value: string
}

export default function AlignCellValue(props: AlignCellProps) {
  const { value } = props
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
      {formatCurrency(value)}
    </Box>
  )
}
