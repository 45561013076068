import React, { useEffect, useState } from 'react'

import * as yup from 'yup'
import api from '~/services/api-pessoal'

import { TextField } from 'mio-library-ui'
import { Box, Paper, Container, Grid, Button, useTheme } from '@material-ui/core'

import { CurrencyTextField, DatePicker, PageHeader, PrintButton, PDFViewer } from '~/components'
import { AutoCompleteVinculo } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import useDialog from '~/hooks/useDialog'

const initialData = {
  vinculoId: '',
  vinculo: null,
  estabelecimento: null,
  diretorGerenteNome: '',
  bolsitaNome: '',
  areaAtuacaoNome: '',
  instituicaoEnsinoNome: '',
  valorBolsa: 0,
  diasValorBolsa: 0,
  dataInicioValorBolsa: null,
  dataFimValorBolsa: null,
  mensagemHorario: null,
  dtEmissao: null,
}

const schema = yup.object().shape({
  vinculoId: yup.string().required('Informe um Estagiário'),
  diretorGerenteNome: yup.string().required('Informe o Supervisor'),
  areaAtuacaoNome: yup.string().required('Informe a Área de atuação'),
  instituicaoEnsinoNome: yup.string().required('Informe a Instituição de Ensino'),
  valorBolsa: yup.number().min(1, 'Informe o Valor da Bolsa'),
  diasValorBolsa: yup.number().min(1, 'Informe o Prazo'),
  dataInicioValorBolsa: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Início')
    .nullable(),
  dataFimValorBolsa: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Término')
    .nullable(),
  mensagemHorario: yup.string().required('Descreva o horário').nullable(),
  dtEmissao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Emissão do Termo')
    .nullable(),
})

export default function TermoCompromissoEstagio() {
  const [data, setData] = useState(initialData)

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })
  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const theme = useTheme()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  useEffect(() => {
    async function obterDadosEstagiario() {
      try {
        if (!data?.vinculoId) return
        const response = await api.get('/VinculoEstagio/ObterDataTermoCompromisso', {
          params: {
            vinculoId: data.vinculoId,
          },
        })
        const dt = response?.data?.data || {}
        setData((prev) => ({
          ...prev,
          ...dt,
        }))
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    }
    obterDadosEstagiario()
    // eslint-disable-next-line
  }, [data.vinculoId])

  function handleSubmit() {
    openPDFViewer(data)
  }

  function handleCancel() {
    setData(initialData)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Termo de Compromisso de Estágio" />
      <Container maxWidth="sm">
        <Paper
          component="form"
          style={{
            padding: theme.spacing(2),
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoCompleteVinculo
                value={data?.vinculo || null}
                label="Estagiários"
                onChange={(_, obj) => {
                  setData((prev) => ({
                    ...prev,
                    vinculoId: obj?.id || '',
                    vinculo: obj || null,
                  }))
                }}
                optionId="id"
                vinculosTipo={[VinculoTipoEnum.Estagiario_3]}
                estabelecimentoId={estabelecimento.id}
                anoMes={anoMes}
                required
                validationErrors={validationErrors}
                name="vinculoId"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                label="Supervisor"
                value={data?.diretorGerenteNome}
                fullWidth
                variant="outlined"
                size="small"
                required
                validationErrors={validationErrors}
                name="diretorGerenteNome"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                label="Área de Atuação"
                value={data?.areaAtuacaoNome}
                fullWidth
                variant="outlined"
                size="small"
                required
                validationErrors={validationErrors}
                name="areaAtuacaoNome"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                label="Instituição de Ensino"
                value={data?.instituicaoEnsinoNome}
                fullWidth
                variant="outlined"
                size="small"
                required
                validationErrors={validationErrors}
                name="instituicaoEnsinoNome"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <CurrencyTextField
                disabled
                label="Valor da Bolsa"
                value={data?.valorBolsa}
                fullWidth
                variant="outlined"
                size="small"
                required
                validationErrors={validationErrors}
                name="valorBolsa"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Prazo(dias)"
                value={data?.diasValorBolsa}
                fullWidth
                variant="outlined"
                size="small"
                onlyNumber
                onChange={(e) => {
                  const diasValorBolsa = e?.target?.value || 0
                  setData((prev) => ({
                    ...prev,
                    diasValorBolsa,
                    dataInicioValorBolsa: null,
                    dataFimValorBolsa: null,
                  }))
                }}
                required
                validationErrors={validationErrors}
                name="diasValorBolsa"
                inputProps={{
                  maxLength: 6,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Data de Início"
                value={data?.dataInicioValorBolsa || null}
                onChange={(date) => {
                  const dataInicioValorBolsa = date ? date.format('yyyy-MM-DD') : null
                  const dataFimValorBolsa = date
                    ? date.add(data.diasValorBolsa - 1, 'days').format('yyyy-MM-DD')
                    : null
                  setData((prevState) => ({
                    ...prevState,
                    dataInicioValorBolsa,
                    dataFimValorBolsa,
                  }))
                }}
                required
                validationErrors={validationErrors}
                name="dataInicioValorBolsa"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Data de Término"
                value={data?.dataFimValorBolsa || null}
                disabled
                required
                validationErrors={validationErrors}
                name="dataFimValorBolsa"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Horário"
                value={data?.mensagemHorario || ''}
                fullWidth
                variant="outlined"
                size="small"
                multiline
                rows={6}
                onChange={(e) => {
                  const mensagemHorario = e?.target?.value || ''
                  setData((prev) => ({
                    ...prev,
                    mensagemHorario,
                  }))
                }}
                required
                validationErrors={validationErrors}
                name="mensagemHorario"
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Data de Emissão do Termo"
                value={data?.dtEmissao || null}
                onChange={(date) => {
                  const dtEmissao = date ? date.format('yyyy-MM-DD') : null
                  setData((prevState) => ({
                    ...prevState,
                    dtEmissao,
                  }))
                }}
                required
                validationErrors={validationErrors}
                name="dtEmissao"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                size="small"
                variant="outlined"
                fullWidth
                color="primary"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <PrintButton fullWidth onClick={handleValidate} />
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="TermoCompromissoEstagio"
        title="Termo de Compromisso de Estágio"
        axiosConfig={{
          method: 'post',
          data: dataPDFViewer,
          url: '/VinculoEstagio/ObterRelatorioTermoCompromisso',
        }}
      />
    </Box>
  )
}
