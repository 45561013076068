import { useMutation, useQueryClient } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'

import { empregadorObterFiltradoQuery } from './useEmpregadorObterFiltrado'

import { EmpregadorConsulta } from './EmpregadorConsulta'

export default function useManutencaoEmpregador() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(empregadores: EmpregadorConsulta[]) {
    await api.put('/EmpregadorConsulta/ManutencaoEmpregador', empregadores)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.success('Empregadores atualizados com sucesso')
      await queryClient.invalidateQueries(empregadorObterFiltradoQuery)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
