import moment from 'moment'

import { Box, CircularProgress, LinearProgress } from '@material-ui/core'

import { Button, Calendar, SimpleAlert } from '~/components'

import CalendarDay from './CalendarDay'
import Form from './Form'
import DialogDSR from './DialogDSR'
import DialogFaltasPeriodo from './DialogFaltasPeriodo'

import useObterDataFaltas from '~/hooks/queries/VinculoFaltas/useObterDataFaltas'
import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { VinculoFaltas } from '~/hooks/queries/VinculoFaltas/VinculoFaltas'
import { TipoDia } from '~/hooks/queries/VinculoFaltas/VinculoFaltasDTO'
import { VinculoDSR } from '~/hooks/queries/VinculoDSR/VinculoDSR'

interface DataForm extends Partial<VinculoFaltas> {
  datesSeraoInseridas?: string[]
}

function formatDate(date: string | Date): string {
  return moment(date).format('yyyy-MM-DD')
}

interface ContentProps {
  vinculoId: string
}

export default function Content({ vinculoId }: ContentProps) {
  const { anoMes } = useAmbiente()

  const { data, isLoading, isFetching } = useObterDataFaltas(vinculoId, anoMes)
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<DataForm>({})
  const {
    close: closeDialogDSR,
    data: dataDialogDSR,
    isOpen: isOpenDialogDSR,
    open: openDialogDSR,
  } = useDialog<VinculoDSR | null>(null)
  const {
    close: closeDialogFaltasPeriodo,
    data: dataDialogFaltasPeriodo,
    isOpen: isOpenDialogFaltasPeriodo,
    open: openDialogFaltasPeriodo,
  } = useDialog('')

  const dialogNotification = useDialogNotification()

  const diasOcorrencias = data?.diasOcorrencias || []

  const isReciboFinalizado = data?.isReciboFinalizado || false

  const dates = diasOcorrencias.map((d) => formatDate(d.data))

  function obterTipoDiaPorDate(date: string): TipoDia | null {
    const ocorrencia = diasOcorrencias.find((d) => formatDate(d.data) === date)
    if (!ocorrencia) {
      return null
    } else {
      return ocorrencia.tipo
    }
  }

  function handleClickNovo(date: string) {
    if (isReciboFinalizado) return
    openForm({
      dtFalta: date,
      vinculoId,
    })
  }

  function handleClickFaltaAtraso(date: string) {
    const vinculoFaltasFinded = data?.faltasAtrasos?.find((d) => formatDate(d.dtFalta) === date)
    if (!vinculoFaltasFinded) return
    openForm(vinculoFaltasFinded)
  }

  function handleClickDSRDescontado(date: string) {
    const dsrDescontadoFinded = data?.dsrDescontados?.find((d) => formatDate(d.dtDSR) === date)
    if (!dsrDescontadoFinded) return
    openDialogDSR(dsrDescontadoFinded)
  }

  function handleClickDate(date: string) {
    const tipo = obterTipoDiaPorDate(date)
    switch (tipo) {
      case TipoDia.Falta:
        handleClickFaltaAtraso(date)
        break
      case TipoDia.Atraso:
        handleClickFaltaAtraso(date)
        break
      case TipoDia.DSRDescontado:
        handleClickDSRDescontado(date)
        break
      case TipoDia.Ferias:
        dialogNotification.warning({
          descriptions: ['Tem certeza que deseja fazer o lançamento em uma Férias?'],
          onConfirm: () => handleClickNovo(date),
          labelOnCancel: 'Cancelar',
          labelOnConfirm: 'Confirmar',
        })
        break
      case TipoDia.Afastado:
        dialogNotification.warning({
          descriptions: ['Tem certeza que deseja fazer o lançamento em um Afastamento?'],
          onConfirm: () => handleClickNovo(date),
          labelOnCancel: 'Cancelar',
          labelOnConfirm: 'Confirmar',
        })
        break
      case TipoDia.DescansoSemanal:
        dialogNotification.warning({
          descriptions: ['Tem certeza que deseja fazer o lançamento em um Descanso Semanal?'],
          onConfirm: () => handleClickNovo(date),
          labelOnCancel: 'Cancelar',
          labelOnConfirm: 'Confirmar',
        })
        break
      case TipoDia.DiaNaoTrabalha:
        dialogNotification.warning({
          descriptions: ['Tem certeza que deseja fazer o lançamento em um dia que Não Trabalha?'],
          onConfirm: () => handleClickNovo(date),
          labelOnCancel: 'Cancelar',
          labelOnConfirm: 'Confirmar',
        })
        break
      default:
        handleClickNovo(date)
        break
    }
  }

  function handleClickMultipleDate(dateStart: string, dateEnd: string) {
    if (isReciboFinalizado) return

    const dataContadora = moment(dateStart)
    const datesSeraoInseridas: string[] = []
    const datesNaoSeraoInseridas: string[] = []

    while (dataContadora.isSameOrBefore(dateEnd)) {
      const dtFormated = dataContadora.format('yyyy-MM-DD')
      if (dates.some((d) => d === dtFormated)) {
        datesNaoSeraoInseridas.push(dtFormated)
      } else {
        datesSeraoInseridas.push(dtFormated)
      }
      dataContadora.add(1, 'day')
    }

    if (datesNaoSeraoInseridas.length > 0) {
      return dialogNotification.info({
        descriptions: [
          'As datas seguintes não serão inseridas',
          ...datesNaoSeraoInseridas.map((d) => moment(d).format('DD/MM/yyyy')),
        ],
        onConfirm: () => openFormWithMultiplesDates(datesSeraoInseridas),
        labelOnConfirm: 'Confirmar',
      })
    } else {
      openFormWithMultiplesDates(datesSeraoInseridas)
    }
  }

  function openFormWithMultiplesDates(datesSeraoInseridas: string[]) {
    if (datesSeraoInseridas.length > 0) {
      openForm({
        dtFalta: datesSeraoInseridas[0],
        vinculoId,
        datesSeraoInseridas,
      })
    }
  }

  return (
    <Box position="relative" height="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={() => openDialogFaltasPeriodo(vinculoId)}>
          Faltas por Período
        </Button>
      </Box>
      <SimpleAlert show={isReciboFinalizado} severity="warning">
        Recibo da competência atual está finalizado, lançamento de faltas apenas para consulta.
      </SimpleAlert>
      {isLoading && (
        <Box
          position="absolute"
          right={0}
          left={0}
          top={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(0,0,0,0.1)"
          zIndex={99}
        >
          <CircularProgress size={70} />
        </Box>
      )}
      <Box height={4}>{isFetching && <LinearProgress />}</Box>
      <Box flex={1}>
        <Calendar
          anoMes={anoMes}
          dates={dates}
          onClickDate={handleClickDate}
          onClickMultipleDate={handleClickMultipleDate}
          renderDate={(date) => {
            const tipo = obterTipoDiaPorDate(date)
            if (!tipo) return
            return <CalendarDay dateType={tipo} />
          }}
        />
      </Box>
      {isOpenForm && (
        <Form
          disabled={isReciboFinalizado}
          data={dataForm}
          isOpen={isOpenForm}
          configuracaoEmpregador={data?.configuracaoEmpregador}
          datesSeraoInseridas={dataForm?.datesSeraoInseridas}
          onClose={closeForm}
        />
      )}
      {isOpenDialogDSR && dataDialogDSR ? (
        <DialogDSR
          data={dataDialogDSR}
          isOpen={isOpenDialogDSR}
          onClickDate={handleClickDate}
          onClose={closeDialogDSR}
        />
      ) : (
        <></>
      )}
      {isOpenDialogFaltasPeriodo && (
        <DialogFaltasPeriodo
          vinculoId={dataDialogFaltasPeriodo}
          isOpen={isOpenDialogFaltasPeriodo}
          onClose={closeDialogFaltasPeriodo}
        />
      )}
    </Box>
  )
}
