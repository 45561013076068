import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterTodosMotivoInvalidate } from './useObterTodosMotivo'

import { notifyPut } from '~/utils/notification'

import { MotivoDTO } from './dtos/MotivoDTO'

interface RequestParams {
  data: MotivoDTO
  id: string
}

export function useUpdateMotivo() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosMotivoInvalidate()

  async function handleRequest({ data, id }: RequestParams) {
    await api.put('/Motivo/' + id, data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
