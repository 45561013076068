import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ProcessoTrabalhistaVinculo } from './dtos/ProcessoTrabalhistaVinculo'

const query = '/ProcessoTrabalhistaVinculo/'

interface RequestProps {
  vinculoId: string
}

export function useGetAllByVinculo({ vinculoId }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ProcessoTrabalhistaVinculo[] }>(query + vinculoId)
    return response.data.data
  }

  return useQuery([query, vinculoId], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useGetAllByVinculoInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
