import React from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { CopyLabel, ToolsDataTable } from '~/components'

import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  isLoading: boolean
  isFetching: boolean
  query: string
  data: EstabelecimentoConsulta[]
  onRowSelected: React.Dispatch<React.SetStateAction<number[]>>
  rowsSelected: number[]
}

export default function Table(props: TableProps) {
  const { isLoading, isFetching, query, data, onRowSelected, rowsSelected } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nrInscricao',
      label: 'CNPJ',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'nome',
      label: 'Nome',
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      sherlock={{ columns: ['codigo', 'nrInscricao', 'nome'], query }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
      disableAutoHeight
    />
  )
}
