import { Edit as EditIcon, Delete as DeleteIcon, Print } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

import { ButtonBox, ConfirmDeleteDialog, ToolsDataTable } from '~/components'

import { useObterPorVinculo } from '~/hooks/queries/VinculoAdvertencia/useObterPorVinculo'
import { useRemoveVinculoAdvertencia } from '~/hooks/queries/VinculoAdvertencia/useRemoveVinculoAdvertencia'

import { formatSimpleDate } from '~/utils/utils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import useDialog from '~/hooks/useDialog'

import { VinculoAdvertenciaDTO } from '~/hooks/queries/VinculoAdvertencia/dtos/VinculoAdvertenciaDTO'

interface TableProps {
  query: string
  vinculoId: string
  onClickEdit: (data: VinculoAdvertenciaDTO) => void
  onClickPrint: (id: string) => void
}

export default function Table({ query, vinculoId, onClickEdit, onClickPrint }: TableProps) {
  const { data: _data, isLoading, isFetching } = useObterPorVinculo({ vinculoId })
  const data = _data || []

  const { mutateAsync, isLoading: isDeleting } = useRemoveVinculoAdvertencia()
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  async function handleConfirmDelete() {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtAdvertencia',
      label: 'Data',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'motivoAdvertencia',
      label: 'Advertência',
    },
    {
      name: 'id',
      label: 'Motivo',
      options: {
        customBodyRender: (value) => {
          const rowCurrent = data.find((d) => d.id === value)
          if (!rowCurrent) return ''
          return `${rowCurrent.motivoCodigo} - ${rowCurrent.motivoNome}`
        },
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Imprimir este registro"
                color="primary"
                aria-label="Imprimir"
                onClick={() => {
                  onClickPrint(value)
                }}
              >
                <Print fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtCurrent = data.find((d) => d.id === value)
                  if (!dtCurrent) return
                  onClickEdit(dtCurrent)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => openConfirmDelete(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <>
      <ToolsDataTable
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        sherlock={{
          query,
          columns: ['codigo', 'nome', 'nrInscricao'],
        }}
        disableAutoHeight
      />
      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isDeleting}
        onCancel={closeConfirmDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  )
}
