import { convertToArrayDescription } from '~/utils/utils'

export enum IndRepercussaoProcessoEnum {
  DecisaoComRepercussaoTributaria_01 = 1,
  DecisaoSemRepercussaoTributaria_02 = 2,
  DecisaoComRepercussaoExclusivaDeclaracaoRendimentosIRRF_03 = 3,
}

export const IndRepercussaoProcessoLabels = {
  [IndRepercussaoProcessoEnum.DecisaoComRepercussaoTributaria_01]:
    '1 - Decisão com repercussão tributária e/ou FGTS',
  [IndRepercussaoProcessoEnum.DecisaoSemRepercussaoTributaria_02]:
    '2 - Decisão sem repercussão tributária ou FGTS',
  [IndRepercussaoProcessoEnum.DecisaoComRepercussaoExclusivaDeclaracaoRendimentosIRRF_03]:
    '3 - Decisão com repercussão exclusiva para declaração de rendimentos para fins de Imposto de Renda',
}

export const IndRepercussaoProcessoValues = convertToArrayDescription(
  IndRepercussaoProcessoEnum,
  IndRepercussaoProcessoLabels,
)
