import React from 'react'

import { ToolsDataTable, PercentTextField, CurrencyTextField } from '~/components'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

export default function Table(props) {
  const { data, isLoading, query, rowsSelected, handleRowSelected, onChange } = props

  const { parseStringToFloat } = useUtils()

  function changeColumnInput(value, rowIndex, event) {
    const newCollection = data.map((dt, index) => {
      if (index === rowIndex) {
        const oldValue = parseStringToFloat(dt?.aliqAdiantamentoSalario) || 0
        const newValue = parseStringToFloat(value) || 0

        if (newValue === oldValue) return dt

        const newDtItem = { ...dt }

        newDtItem.aliqAdiantamentoSalario = 0
        newDtItem.vrAdiantamentoSalario = newValue

        if (event === 'aliq') {
          newDtItem.aliqAdiantamentoSalario = newValue
          newDtItem.vrAdiantamentoSalario = 0
          return newDtItem
        }
        return newDtItem
      }
      return dt
    })
    onChange(newCollection)
  }

  const columns = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'dtAdmissao',
      label: 'Data de Admissão',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
      },
    },
    {
      name: 'vrAdiantamentoSalario',
      label: 'Valor Adiantamento',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
        customBodyRender: (value, { rowIndex }) => (
          <CurrencyTextField
            value={value || 0}
            onBlur={(e) => changeColumnInput(e?.target?.value, rowIndex, 'valor')}
          />
        ),
      },
    },
    {
      name: 'aliqAdiantamentoSalario',
      label: 'Aliq. Adiantamento',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
        customBodyRender: (value, { rowIndex }) => (
          <PercentTextField
            value={value || 0}
            onBlur={(e, value) => changeColumnInput(value, rowIndex, 'aliq')}
            decimalLimit={0}
          />
        ),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      optionsSelectable={{
        type: 'multiple',
        rowsSelected: rowsSelected,
        onRowSelected: handleRowSelected,
      }}
      sherlock={{
        columns: ['nome', 'dtAdmissao'],
        query,
      }}
    />
  )
}
