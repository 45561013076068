import { convertToArrayDescription } from '~/utils/utils'

export enum OperacaoConsultaEnum {
  TodasOperacoes,
  Alteracao,
  Inclusao,
  Exclusao,
}

export const OperacaoConsultaLabel = {
  [OperacaoConsultaEnum.TodasOperacoes]: 'Todas operações',
  [OperacaoConsultaEnum.Alteracao]: 'Alteração',
  [OperacaoConsultaEnum.Inclusao]: 'Inclusão',
  [OperacaoConsultaEnum.Exclusao]: 'Exclusão',
}

export const OperacaoConsultaValues = convertToArrayDescription(
  OperacaoConsultaEnum,
  OperacaoConsultaLabel,
)
