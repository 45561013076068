import React, { useState, useEffect } from 'react'

import { Finder } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { PageHeader, PDFViewer, PrintButton } from '~/components'

import Form from './components/Form'
import Table from './components/Table'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import { getDateMinAndDateMaxOfYearMonth } from '~/hooks/useUtils'

export default function CondicaoAmbienteTrabalhoResumo() {
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [collection, setCollection] = useState({
    eventos: [],
  })
  const [dataForm, setDataForm] = useState({})

  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  useEffect(() => {
    function resetFields() {
      const { dtMax, dtMin } = getDateMinAndDateMaxOfYearMonth(anoMes)

      setCollection({ eventos: [] })
      setDataForm({ dtInicio: dtMin, dtFim: dtMax })
    }
    resetFields()
  }, [estabelecimento.empregadorId, anoMes])

  async function handleSearch(data) {
    setLoading(true)
    try {
      const response = await api.get(
        '/CondicaoAmbienteTrabalho/ObterCondicaoAmbienteTrabalhoPorEmpregadorEPeriodo',
        {
          params: {
            empregadorId: estabelecimento.empregadorId,
            dtInicio: data.dtInicio,
            dtFim: data.dtFim,
          },
        },
      )
      setCollection(response?.data?.data || { eventos: [] })
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  function handlePrint() {
    if (!(collection.eventos.length > 0))
      return dialogNotification.warning({
        descriptions: ['Não há registros para serem imprimidos'],
      })
    openPDFViewer(collection)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Resumo condição ambiente de trabalho (2240)">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Form onSubmit={handleSearch} isLoading={isLoading} data={dataForm} />
      <Table data={collection.eventos} isLoading={isLoading} query={query} />

      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <PrintButton onClick={handlePrint} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        title="Resumo condição ambiente de trabalho (2240)"
        reportKey="CondicaoAmbienteTrabalhoResumo"
        axiosConfig={{
          method: 'post',
          url: '/CondicaoAmbienteTrabalho/ObterRelatorioCondicaoAmbienteTrabalhoResumo',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
