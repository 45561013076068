import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryObterDocumentos } from './useObterDocumentos'

interface RequestProps {
  documentosIds: string[]
  estabelecimentoId: string
}

export function useEnviarEmailEstabelecimentoVariosDocumentos() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    const { documentosIds, estabelecimentoId } = props
    await api.post(
      '/GerenciadorDocumentos/EnviarEmailEstabelecimentoVariosDocumentos',
      documentosIds,
      {
        params: {
          estabelecimentoId,
        },
      },
    )
    notification.success('Documentos enviados por email com Sucesso')
  }

  return useMutation(handleRequest, {
    onSuccess: async () => await queryClient.invalidateQueries(queryObterDocumentos),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
