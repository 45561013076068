import React, { useCallback, useEffect, useState } from 'react'

import { Box, Grid, Typography, MenuItem } from '@material-ui/core'
import { ContentDivider, TextField, Button, ButtonBox, PageHeader } from 'mio-library-ui'

import useDialogNotification from '~/hooks/useDialogNotification'
import { Checkbox, DatePicker, ActionDialog } from '~/components'
import {
  AutoCompleteFPAS,
  AutoCompleteTerceiros,
  AutoCompleteCNAE,
  AutoCompleteProcesso,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import Table from './components/Table'

import api from '~/services/api-pessoal'

import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'
import moment from 'moment'

import {
  IndSubsPatronalObraEnum,
  IndSubsPatronalObraValues,
} from '~/@types/enums/IndSubsPatronalObraEnum'
import { getDateMinAnoMes } from '~/utils/utils'

const schema = yup.object().shape({
  dtSituacao: yup
    .string()
    .required('Informe a Data da Situação Tributária')
    .when(['$anoMes', '$isUpdate'], (anoMes, isUpdate, schema, { value: dtSituacao }) => {
      if (isUpdate) return schema
      const dtSituacaoFormatted = moment(dtSituacao).format('YYYYMM')

      if (!moment(dtSituacaoFormatted).isSame(anoMes)) {
        return schema.min(
          dtSituacao,
          'A Data da Situação Tributária deve estar dentro da Competência Atual',
        )
      }

      return schema
    })
    .nullable(),
  cnae: yup.string().required('Informe o CNAE').nullable(),
  fpasId: yup.string().required('Informe o FPAS'),
  indSubsPatrObra: yup.string().required('Informe o Ind. Substituição Patronal Obra'),
  terceirosId: yup.string().required('Informe o Terceiros').nullable(),
})

const HEADER_HEIGHT = '50px'
const EmpregadorSituacao = (props) => {
  const { estabelecimentoId } = props

  const [currentList, setCurrentList] = useState([])
  const [current, setCurrent] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingOnClick, setLoadingOnClick] = useState(false)

  const { anoMes } = useAmbiente()
  const notifications = useNotification()
  const dialogNotification = useDialogNotification()

  const pageTitle = 'Estabelecimento | Situação Tributária'
  const apiRoute = '/estabelecimentoSituacao'

  const EmpregadorSituacao = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await api.get(`${apiRoute}/GetByEstabelecimento?id=${estabelecimentoId}`)
      if (response.data.data) {
        setCurrentList(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [estabelecimentoId])

  useEffect(() => {
    EmpregadorSituacao()
    // eslint-disable-next-line
  }, [EmpregadorSituacao])

  const handleStartNew = () => {
    setIsOpen(true)

    const newItem = {
      ...currentList[0],
      ...current,
      estabelecimentoId,
      indSubsPatrObra: IndSubsPatronalObraEnum.NaoInformado_9,
      contApr: '0',
      contEntEd: 'N',
      isInformarFAPESocial: false,
      dtSituacao: getDateMinAnoMes(anoMes),
    }

    delete newItem.id

    setCurrent(newItem)
  }

  const handleCancelNew = () => {
    setIsOpen(false)

    setCurrent({})
  }

  const handleEdit = async (id) => {
    setLoadingOnClick(true)
    try {
      const response = await api.get(`${apiRoute}/${id}`)
      setCurrent(response.data.data)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingOnClick(false)
    }
    setIsOpen(true)
  }

  const handleDelete = (id) => {
    setCurrent({ id })

    if (!id) {
      notifications.warning(`Selecione um ${pageTitle} para excluir!`, 'Ops!', 4000)
      return
    }

    setIsOpenDelete(true)
  }

  const handleClickItem = (event, data) => {
    const functions = {
      edit: handleEdit,
      delete: handleDelete,
    }
    functions[event](data)
  }

  const handleSave = useCallback(() => {
    const update = async () => {
      setIsLoading(true)
      try {
        const dtMovimentoESocial = moment(anoMes).format('yyyy-MM-DD')
        await api.put(`/estabelecimentoSituacao/${current.id}`, { ...current, dtMovimentoESocial })
        notifications.put()

        EmpregadorSituacao()

        setIsOpen(false)
        setCurrent({})
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    const insert = async () => {
      setIsLoading(true)
      try {
        await api.post('/estabelecimentoSituacao', current)
        notifications.post()

        EmpregadorSituacao()

        setIsOpen(false)
        setCurrent({})
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    if (current.id) {
      update()
      return
    }
    insert()
    // eslint-disable-next-line
  }, [current, anoMes])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSave,
    data: current,
    schemaOptions: {
      abortEarly: false,
      context: { anoMes, isUpdate: current?.id ? true : false },
    },
  })

  const deletar = async () => {
    setIsProcessing(true)

    try {
      const response = await api.delete(`${apiRoute}/${current.id}`)

      if (response.status === 200) {
        notifications.success('Registro foi removido', 'Sucesso!', 4000)
        EmpregadorSituacao()
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsOpenDelete(false)
    setIsProcessing(false)
    setCurrent({})
  }

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            <Button size="small" onClick={handleStartNew} color="primary" variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table
          data={currentList}
          onItemClick={handleClickItem}
          isLoading={isLoading}
          isFetching={isLoadingOnClick}
        />
      </Box>

      <ActionDialog
        title={'Cadastro de ' + pageTitle}
        okLabel="Salvar"
        isOpen={isOpen}
        onClose={handleCancelNew}
        onOkClick={handleValidate}
        onCancelClick={handleCancelNew}
        isOkProcessing={isLoading}
        dialogProps={{
          maxWidth: 'lg',
        }}
      >
        <Box p={1}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ContentDivider title="Informações primárias" />
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <DatePicker
                label="Data da Situação Tributária"
                size="small"
                value={current.dtSituacao || null}
                disabled
                // disabled={current?.id ? true : false}
                // required={!current?.id ? true : false}
                validationErrors={validationErrors}
                name="dtSituacao"
                onChange={(date) => {
                  const dtSituacao = date ? date.format('yyyy-MM-DD') : null
                  setCurrent({ ...current, dtSituacao })
                }}
              />
            </Grid>

            <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
              <AutoCompleteCNAE
                value={current?.cnae || ''}
                required
                validationErrors={validationErrors}
                name="cnae"
                optionId="id"
                onChange={(e, obj) => {
                  const cnae = obj?.id || ''
                  setCurrent((oldState) => ({ ...oldState, cnae }))
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <MUIAutoComplete
                label="Ind. Substituição Patronal Obra"
                required
                validationErrors={validationErrors}
                name="indSubsPatrObra"
                options={IndSubsPatronalObraValues}
                optionId="value"
                renderOption={(option) => option.name}
                value={current.indSubsPatrObra}
                onChange={(e, obj) => {
                  const indSubsPatrObra = obj ? obj.value : ''
                  setCurrent({ ...current, indSubsPatrObra })
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Contratação Aprendiz"
                fullWidth
                value={current.contApr || ''}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  const contApr = e.target.value
                  setCurrent({ ...current, contApr })
                }}
                select
              >
                <MenuItem value="0">Dispensado de acordo com a Lei</MenuItem>
                <MenuItem value="1">Dispensado em virtude Processo Judicial</MenuItem>
                <MenuItem value="2">Obrigado</MenuItem>
              </TextField>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Contratação Aprendiz artigo 430 inciso II e III"
                fullWidth
                value={current.contEntEd || ''}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  const contEntEd = e.target.value
                  setCurrent({ ...current, contEntEd })
                }}
                select
              >
                <MenuItem value="N">Não</MenuItem>
                <MenuItem value="S">Sim</MenuItem>
              </TextField>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                inputProps={{
                  maxLength: 20,
                }}
                label="Processo Judicial Aprendiz"
                value={current?.processoInfoApr || ''}
                onChange={(e) => {
                  const processoInfoApr = e?.target?.value || ''
                  setCurrent((oldState) => ({ ...oldState, processoInfoApr }))
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                inputProps={{
                  maxLength: 20,
                }}
                label="Processo PCD"
                value={current?.processoPCD || ''}
                onChange={(e) => {
                  const processoPCD = e?.target?.value || ''
                  setCurrent((oldState) => ({ ...oldState, processoPCD }))
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <AutoCompleteProcesso
                label="Processo Risco Ambiental do Trabalho"
                value={current?.processoRAT || ''}
                onChange={(e, obj) => {
                  const processoRAT = obj?.numeroProcesso || ''
                  setCurrent((oldState) => ({ ...oldState, processoRAT }))
                }}
                optionId="numeroProcesso"
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <AutoCompleteProcesso
                label="Processo Fator Acidentário de Prevenção"
                value={current?.processoFAP || ''}
                onChange={(e, obj) => {
                  const processoFAP = obj?.numeroProcesso || ''
                  setCurrent((oldState) => ({ ...oldState, processoFAP }))
                }}
                optionId="numeroProcesso"
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Código de Suspensão RAT"
                fullWidth
                value={current?.codigoSuspensaoRAT || ''}
                variant="outlined"
                size="small"
                inputProps={{
                  maxLength: 14,
                }}
                onChange={(e) => {
                  const codigoSuspensaoRAT = e?.target?.value || ''
                  setCurrent({ ...current, codigoSuspensaoRAT })
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Código de Suspensão FAP"
                fullWidth
                value={current?.codigoSuspensaoFAP || ''}
                variant="outlined"
                size="small"
                inputProps={{
                  maxLength: 14,
                }}
                onChange={(e) => {
                  const codigoSuspensaoFAP = e?.target?.value || ''
                  setCurrent({ ...current, codigoSuspensaoFAP })
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <AutoCompleteFPAS
                value={current?.fpasId || ''}
                required
                validationErrors={validationErrors}
                name="fpasId"
                optionId="id"
                onChange={(e, fpas) => {
                  const fpasId = fpas?.id || ''
                  setCurrent((oldState) => ({
                    ...oldState,
                    fpasId,
                    terceirosId: '',
                  }))
                }}
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AutoCompleteTerceiros
                value={current?.terceirosId || ''}
                disabled={current?.fpasId ? false : true}
                filterByFPAS={current?.fpasId}
                required
                validationErrors={validationErrors}
                name="terceirosId"
                onChange={(e, terceiros) => {
                  const terceirosId = terceiros?.codigo || ''
                  setCurrent((oldState) => ({
                    ...oldState,
                    terceirosId,
                  }))
                }}
                optionId="codigo"
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ContentDivider title="eSocial" />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                label="Recibo eSocial"
                fullWidth
                value={current.reciboEsocial || ''}
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <DatePicker
                label="Data Recibo eSocial"
                size="small"
                value={current?.dtReciboEsocial || null}
                disabled
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Checkbox
                label="Informar FAP para o eSocial"
                value={current?.isInformarFAPESocial || false}
                onChange={(e, value) =>
                  setCurrent((oldState) => ({
                    ...oldState,
                    isInformarFAPESocial: value || false,
                  }))
                }
              />
            </Grid>
          </Grid>
        </Box>
      </ActionDialog>

      <ActionDialog
        title="Excluir Registro"
        okLabel="Sim"
        isOpen={isOpenDelete}
        onClose={() => setIsOpenDelete(false)}
        onOkClick={deletar}
        onCancelClick={() => setIsOpenDelete(false)}
        isOkProcessing={isProcessing}
      >
        <Typography>Você quer mesmo excluir este registro</Typography>
      </ActionDialog>
    </>
  )
}

export default EmpregadorSituacao
