import { convertToArrayDescription } from '~/utils/utils'

export enum IndAdmissaoEnum {
  Normal_1 = '1',
  DecorrenteAcaoFiscal_2 = '2',
  DecorrenteDecisaoJudicial_3 = '3',
}

export const IndAdmissaoLabels = {
  [IndAdmissaoEnum.Normal_1]: '1 - Normal',
  [IndAdmissaoEnum.DecorrenteAcaoFiscal_2]: '2 - Decorrente de Ação Fiscal',
  [IndAdmissaoEnum.DecorrenteDecisaoJudicial_3]: '3 - Decorrente de Decisão Judicial',
}

export const IndAdmissaoValues = convertToArrayDescription(IndAdmissaoEnum, IndAdmissaoLabels)
