import { MUIAutoComplete } from '~/components/AutoComplete'

import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'

import { useObterTodos } from '~/hooks/queries/ModeloIntegracao/useObterTodos'

import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'
import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'

interface ComponentProps extends MUIAutoCompleteProps {
  value: string
  onChange: (value: ModeloIntegracao | null) => void
}

export default function AutoCompleteModeloIntegracao({ value, onChange, ...rest }: ComponentProps) {
  const { data: _data, isLoading: _isLoading, isFetching: _isFetching, refetch } = useObterTodos()
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: ModeloIntegracao) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Modelo de Integração"
      value={value}
      onChange={(_, obj: ModeloIntegracao) => onChange(obj)}
      optionId="id"
      renderOptionComponent={(option: ModeloIntegracao) => (
        <AutoCompleteOptionDescription
          title={`${option.codigo} - ${option.nome}`}
          descriptions={[
            {
              description: 'Máscara: ' + option.mascaraConta,
            },
          ]}
        />
      )}
      {...rest}
    />
  )
}
