const trabalhoUrbano_1 = 1
const trabalhoRural_2 = 2

export const naturezaAtividadeValues = [
  { value: trabalhoUrbano_1, name: '1 - Trabalhador Urbano' },
  { value: trabalhoRural_2, name: '2 - Trabalhador Rural' },
]

export const naturezaAtividadeConsts = {
  trabalhoUrbano_1,
  trabalhoRural_2,
}
