import React, { useState, useEffect, useCallback } from 'react'

import { Box, Grid, LinearProgress } from '@material-ui/core'
import { PasswordTextField } from 'mio-library-ui'

import { DragDropFile, ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useUploadFile from '~/hooks/useUploadFile'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'

const schema = yup.object().shape({
  senha: yup.string().required('Informe uma Senha antes de selecionar o arquivo'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const [handlePrepareUpload] = useUploadFile((preparedFile) => {
    const { file } = preparedFile
    const formData = new FormData()
    formData.append('file', file)

    const insert = async (formData_) => {
      setSubmitting(true)
      try {
        const response = await api.post(
          `/CertificadoDigital/Cadastrar?senha=${data?.senha}`,
          formData_,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        )
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setSubmitting(false)
      }
    }

    const update = async (formData_) => {
      setSubmitting(true)
      try {
        const response = await api.put(
          `/CertificadoDigital/Atualizar?certificadoId=${data?.id}&senha=${data?.senha}`,
          formData_,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        )
        notification.put()
        onAfterSubmitForm('update', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setSubmitting(false)
      }
    }

    if (data.id) {
      update(formData)
      return
    }
    insert(formData)
  })

  const handleDropped = useCallback(
    (files) => {
      handlePrepareUpload(files)
    },
    [handlePrepareUpload],
  )

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleDropped,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Certificado Digital"
      subtitle={data?.id && data?.requerente}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      customActions={<></>}
    >
      <Box pb={1}>
        <Grid container spacing={2}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <PasswordTextField
              label="Senha"
              name="senha"
              validationErrors={validationErrors}
              required
              variant="outlined"
              size="small"
              fullWidth
              value={data?.senha || ''}
              onChange={(e) => {
                const senha = e?.target?.value || ''
                setData((oldState) => ({
                  ...oldState,
                  senha,
                }))
              }}
            />
          </Grid>

          {isSubmitting && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <LinearProgress />
            </Grid>
          )}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <DragDropFile
              onDropped={handleValidate}
              acceptFiles={['.pfx']}
              disabled={data?.senha?.length > 0 ? false : true}
              title={data?.senha?.length > 0 ? '' : 'Informe a senha'}
            />
          </Grid>
        </Grid>
      </Box>
    </ActionDialog>
  )
}

export default Form
