import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { AssignmentInd as AssignmentIcon, List as ListIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import moment from 'moment'

const Table = (props) => {
  const { data: _data, isLoading, query, onItemClick } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtInicio: item?.dtInicio ? moment(item.dtInicio).format('DD/MM/yyyy') : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'condicaoAmbienteTrabalhoResponsavelItens',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'condicaoAmbienteTrabalhoAgenteNocivoItens',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'dtInicio',
        label: 'Data de Início',
      },
      {
        name: 'descricaoAtividade',
        label: 'Descrição do Cargo',
      },
      {
        name: 'descricaoSetor',
        label: 'Descrição do Setor',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, { rowData }) => {
            const condicaoAmbienteTrabalhoResponsavelItens = rowData[0]
            const condicaoAmbienteTrabalhoAgenteNocivoItens = rowData[1]
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Abrir responsáveis"
                  color="primary"
                  aria-label="Responsáveis"
                  onClick={() => {
                    onItemClick('responsaveis', condicaoAmbienteTrabalhoResponsavelItens)
                  }}
                >
                  <AssignmentIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Abrir Agentes Nocivos"
                  size="small"
                  color="primary"
                  aria-label="Agentes Nocivos"
                  onClick={() => {
                    onItemClick('agentesNocivos', condicaoAmbienteTrabalhoAgenteNocivoItens)
                  }}
                >
                  <ListIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['dtInicio', 'descricaoAtividade', 'descricaoSetor'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
