import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import api from '~/services/api-pessoal'

import { ApurarConvencaoColetivaProcessamentoDTO } from './ApurarConvencaoColetivaDTO'

export const queryObterConvencaoColetivaProcessada =
  '/ApurarConvencaoColetiva/ObterConvencaoColetivaProcessada'

interface RequestProps {
  anoMes: string
  estabelecimentoId: string
  sindicatoId: string
}

export default function useObterConvencaoColetivaProcessada({
  anoMes,
  estabelecimentoId,
  sindicatoId,
}: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ApurarConvencaoColetivaProcessamentoDTO[] }>(
      queryObterConvencaoColetivaProcessada,
      {
        params: {
          anoMes,
          estabelecimentoId,
          sindicatoId,
        },
      },
    )
    return response.data.data
  }

  return useQuery(
    [queryObterConvencaoColetivaProcessada, anoMes, estabelecimentoId, sindicatoId],
    handleRequest,
    {
      onError: dialogNotification.extractErrors,
    },
  )
}
