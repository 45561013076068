import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifySuccess } from '~/utils/notification'

import { useObterFaltasImportadasInvalidate } from './useObterFaltasImportadas'

export default function useRemoverFaltasImportadas() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterFaltasImportadasInvalidate()

  async function handleRequest(data: string[]) {
    await api.post('/ImportacaoPlanilhaFaltas/RemoverFaltasImportadas', data)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notifySuccess('Faltas importadas removidas com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
