import { Box, makeStyles } from '@material-ui/core'
import { ActionMenu, Button, ButtonBox } from '~/components'

export const HEIGHT_ACTIONS_BUTTON = 40

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: HEIGHT_ACTIONS_BUTTON,
    zIndex: 999,
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

interface ActionsButtonsProps {
  onCancel: () => void
  onSubmit: () => void
  onInativarEventos: () => void
  isLoadingInativarEventos: boolean
  isLoading: boolean
  disabled: boolean
}

export default function ActionsButtons(props: ActionsButtonsProps) {
  const { onCancel, onSubmit, onInativarEventos, isLoadingInativarEventos, isLoading, disabled } =
    props

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <ButtonBox>
        <ActionMenu
          options={[
            {
              label: 'Inativar Eventos',
              onClick: onInativarEventos,
              icon: 'remove_circle_outline',
            },
          ]}
          isLoading={isLoadingInativarEventos}
          disabled={disabled}
        />

        <Button onClick={onCancel}>Cancelar</Button>

        <Button variant="contained" onClick={onSubmit} isLoading={isLoading} disabled={disabled}>
          Enviar para o eSocial
        </Button>
      </ButtonBox>
    </Box>
  )
}
