import { useQuery, useQueryClient, useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'
import useSimpleStartRequest from './useSimpleStartRequest'

interface VinculoEventoAutomaticoEvento {
  id: string
  codigo: number
  fbCodigo: number
  nome: string
  descricao: string
  indEvento: number
  rubricaId: number
  codigoIncidenciaCP: number
  codigoIncidenciaIR: number
  codigoIncidenciaFG: number
  isBasePensaoAlimenticia: boolean
  indCalculo: number
  indReferencia: number
  indTRCT: number
  isVencimentoVariavel: boolean
  isPrintInformativa: boolean
  observacao: string | null
  fator: number | null
  indClassificacaoEvento: number
  formula: string
  ordemImpressao: number
  indBaseDSR: number
  dtInclusaoeSocial: string
  indModificacaoeSocial: number
  eventosRelacionados: null
  tiposRecibo: null
  rubrica: null
}

export interface VinculoEventoAutomaticoData {
  id: string
  vinculoId: string
  eventoId: string
  dtInicio: string | null
  dtFim: string | null
  quantidade: number
  vrEvento: number | null
  observacao: string | null
  vinculo: null
  evento: VinculoEventoAutomaticoEvento
}

type VinculoEventoAutomaticoDataPartial = Partial<VinculoEventoAutomaticoData>

export function useVinculoEventoAutomaticoGetAllPorVinculo(vinculoId: string | undefined) {
  const dialogNotification = useDialogNotification()

  async function getAllPorVinculo() {
    const response = await api.get<{ data: VinculoEventoAutomaticoData[] }>(
      '/VinculoEventoAutomatico/GetByVinculo/' + vinculoId,
    )
    return response.data.data
  }

  return useQuery(['VinculoEventoAutomatico', vinculoId], () => getAllPorVinculo(), {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useVinculoEventoAutomaticoById(id: string | null, vinculoId: string) {
  return useSimpleStartRequest<VinculoEventoAutomaticoDataPartial>(
    {
      url: '/VinculoEventoAutomatico/' + id,
      method: 'GET',
    },
    { vinculoId },
    [id],
    () => (id ? false : true),
  )
}

export function useVinculoEventoAutomaticoMutation() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function update(data: VinculoEventoAutomaticoDataPartial) {
    await api.put('/VinculoEventoAutomatico/' + data.id, data)
    notification.put()
  }
  async function insert(data: VinculoEventoAutomaticoDataPartial) {
    await api.post('/VinculoEventoAutomatico', data)
    notification.post()
  }

  return useMutation(
    async (data: VinculoEventoAutomaticoDataPartial) => {
      if (data.id) {
        await update(data)
        return
      }
      await insert(data)
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries('VinculoEventoAutomatico'),
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}

export function useVinculoEventoAutomaticoDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleDelete(id: string) {
    await api.delete(`/VinculoEventoAutomatico/${id}`)
    notification.remove()
  }

  return useMutation((id: string) => handleDelete(id), {
    onSuccess: async () => await queryClient.invalidateQueries('VinculoEventoAutomatico'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
