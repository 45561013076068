import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import { VinculoHorarioItem } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { DiaHorarioEnum, DiaHorarioValues } from '~/@types/enums/DiaHorarioEnum'

import useHorarioTrabalhoEmpregador from '~/hooks/queries/HorarioTrabalho/useHorarioTrabalhoEmpregador'
import useAmbiente from '~/hooks/useAmbiente'

const formatDiaHorario = (diaHorario: DiaHorarioEnum) => {
  const objectFinded = DiaHorarioValues.find((obj) => obj.value === diaHorario)
  return objectFinded?.name || ''
}

const formatNumberInHour = (number: string) => {
  if (!number) return ''
  return `${number.substring(0, 2)}:${number.substring(2)}`
}

interface TableProps {
  data: VinculoHorarioItem[]
  query: string
  onItemClick: (event: 'delete', id: string) => void
}

export default function Table(props: TableProps) {
  const { data: _data, query, onItemClick } = props

  const { empregador } = useAmbiente()
  const {
    data: _horariosTrabalho,
    isLoading,
    isFetching,
  } = useHorarioTrabalhoEmpregador(empregador.id)
  const horariosTrabalho = _horariosTrabalho || []

  const data = _data.map((item) => {
    const horarioFinded = horariosTrabalho.find((x) => x.id === item.horarioTrabalhoId)
    return {
      ...item,
      diaHorario: item?.diaHorario ? formatDiaHorario(item.diaHorario) : '',
      horarioTrabalhoNome: horarioFinded?.nome || '',
      horaEntrada: horarioFinded?.horaEntrada ? formatNumberInHour(horarioFinded.horaEntrada) : '',
      horaSaida: horarioFinded?.horaSaida ? formatNumberInHour(horarioFinded.horaSaida) : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'diaHorario',
      label: 'Dia da Semana',
    },
    {
      name: 'horarioTrabalhoNome',
      label: 'Horário de Trabalho',
    },
    {
      name: 'horaEntrada',
      label: 'Entrada',
    },
    {
      name: 'horaSaida',
      label: 'Saída',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      isLoading={isLoading}
      isFetching={isFetching}
      data={data}
      columns={columns}
      sherlock={{
        query,
        columns: ['diaHorario'],
      }}
    />
  )
}
