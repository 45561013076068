import { convertToArrayDescription } from '~/utils/utils'

export enum LayoutEnum {
  DataC = 1,
  TCS = 2,
  Foco = 3,
  MLG = 4,
}

export const LayoutLabel = {
  [LayoutEnum.DataC]: 'DataC',
  [LayoutEnum.TCS]: 'TCS',
  [LayoutEnum.Foco]: 'Foco',
  [LayoutEnum.MLG]: 'MLG',
}

export const LayoutValues = convertToArrayDescription(LayoutEnum, LayoutLabel)
