import React, { useMemo, useState, useCallback } from 'react'

import { IconButton, makeStyles } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import TableRecibosItens from './components/TableRecibosItens'
import TableBases from './components/TableBases'

import { Visibility as ViewIcon } from '@material-ui/icons'

import useDialogNotification from '~/hooks/useDialogNotification'
import useUtils from '~/hooks/useUtils'

import api from '~/services/api-pessoal'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const TableRecibos = (props) => {
  const { data: _data, isLoading } = props

  const [tableBases, setTableBases] = useState({
    isOpen: false,
    item: {},
  })
  const [isLoadingRecibosItems, setLoadingRecibosItems] = useState(false)
  const [expandedData, setExpandedData] = useState([])
  const [rowsExpanded, setRowsExpanded] = useState([])

  const { formatAnoMes, formatIdfReciboPagamento, formatCurrency } = useUtils()
  const dialogNotification = useDialogNotification()
  const classes = useStyles()

  const data = _data.map((data) => {
    return {
      ...data,
      anoMes: formatAnoMes(data?.anoMes),
      fatoGerador: formatAnoMes(data?.fatoGerador),
      idf: formatIdfReciboPagamento(data?.idf),
      vrTotalVencimento: formatCurrency(data?.vrTotalVencimento),
      vrTotalDesconto: formatCurrency(data?.vrTotalDesconto),
      vrTotalLiquido: formatCurrency(data?.vrTotalLiquido),
      lotacao: data?.lotacao?.nome,
      vrSalarioContratual: formatCurrency(data?.vrSalarioContratual),
    }
  })

  const handleClickItem = useCallback(
    (id) => {
      const item = data.find((i) => i.id === id)
      setTableBases({ item, isOpen: true })
    },
    [data],
  )

  const handleCloseTableBases = useCallback(() => {
    setTableBases({
      isOpen: false,
      item: {},
    })
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'id',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'nrRecibo',
        label: 'Identificador',
      },
      {
        name: 'anoMes',
        label: 'Ano/Mês',
      },
      {
        name: 'fatoGerador',
        label: 'Fato Gerador',
      },
      {
        name: 'idf',
        label: 'Identificação',
      },
      {
        name: 'vrTotalVencimento',
        label: 'Vencimentos',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrTotalDesconto',
        label: 'Descontos',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrTotalLiquido',
        label: 'Líquido',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'isFinalizado',
        label: 'Impresso',
        options: {
          customBodyRender: (value) => (value ? <Ativo ativo={value} /> : ''),
        },
      },
      {
        name: 'lotacaoNome',
        label: 'Lotação',
      },
      {
        name: 'nrReciboeSocial',
        label: 'Recibo eSocial',
      },
      {
        name: 'vrSalarioContratual',
        label: 'Salário Contratual',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Visualizar valores Bases INSS, FGTS e IRRF"
                  color="primary"
                  aria-label="Visualizar"
                  onClick={() => {
                    handleClickItem(value)
                  }}
                  size="small"
                >
                  <ViewIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [classes, handleClickItem],
  )

  const obterItem = async (indexs) => {
    setRowsExpanded(indexs)
    if (indexs.length === 0) return
    const id = data[indexs[0]].id
    setLoadingRecibosItems(true)
    try {
      const response = await api.get(`RPItem/GetByRp?rpId=${id}`)
      const data = response.data.data
      setExpandedData(data)
    } catch (err) {
      dialogNotification.extractErrors(err)
      return []
    }
    setLoadingRecibosItems(false)
  }

  function renderExpandableRow() {
    return <TableRecibosItens data={expandedData} isLoading={isLoadingRecibosItems} />
  }

  return (
    <>
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        pagination={false}
        disableAutoHeight
        optionsExpandable={{
          type: 'single',
          expandedChildren: renderExpandableRow,
          onRowExpanded: obterItem,
          rowsExpanded: rowsExpanded,
        }}
      />
      <TableBases
        data={tableBases.item}
        isOpen={tableBases.isOpen}
        onClose={handleCloseTableBases}
      />
    </>
  )
}

export default TableRecibos
