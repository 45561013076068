import { useEffect, useState, useContext } from 'react'
import MenuContext from '~/contexts/menu-context'

const useResize = () => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })
  const isMenuOpen = useContext(MenuContext)
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    handleResize()
    window.addEventListener('resize', () => {
      handleResize()
    })
    return () => {
      if (handleResize) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return { width: dimensions.width, height: dimensions.height, isMenuOpen }
}

export default useResize
