import React, { useState, useEffect } from 'react'

import { Box, IconButton, LinearProgress, Typography } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'

import {
  ActionDialog,
  ConfirmDeleteDialog,
  TreeView,
  PageHeader,
  Button,
  Finder,
} from '~/components'

import FlowCreate from './components/FlowCreate'
import Table from './components/Table'
import TablePropsAlteradas from './components/TablePropsAlteradas'

import { useAlteracaoContratoCadastroDelete } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/useAlteracaoContratoCadastroDelete'
import { obterDataFormulario2205 } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/obterDataFormulario2205'
import { VinculoMenuItem, useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useHistory } from 'react-router-dom'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { VinculoAlteracaoContratoCadastro } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/VinculoAlteracaoContratoCadastro'
import { VinculoAlteracaoCadastro2205DTO } from '~/hooks/queries/VinculoAlteracaoContratoCadastro/VinculoAlteracaoCadastro2205DTO'

const HEADER_HEIGHT = '70px'

export default function AlteracaoCadastral() {
  const [isLoadingOpenForm, setLoadingOpenForm] = useState(false)
  const [isLoadingEdit, setLoadingEdit] = useState(false)
  const [query, setQuery] = useState('')
  const [queryTablePropsAlteradas, setQueryTablePropsAlteradas] = useState('')
  const [vinculo, setVinculo] = useState<VinculoMenuItem | null>(null)

  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closeFlowCreate,
    data: dataFlowCreate,
    isOpen: isOpenFlowCreate,
    open: openFlowCreate,
  } = useDialog<{
    data: VinculoAlteracaoCadastro2205DTO
    isEdit: boolean
    isRetificar?: boolean
  } | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')
  const {
    close: closeTablePropsAlteradas,
    data: dataTablePropsAlteradas,
    isOpen: isOpenTablePropsAlteradas,
    open: openTablePropsAlteradas,
  } = useDialog('')

  const { mutateAsync, isLoading: isLoadingDelete } = useAlteracaoContratoCadastroDelete()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Empregador_2,
    VinculoTipoEnum.Estagiario_3,
    VinculoTipoEnum.Autonomo_4,
    VinculoTipoEnum.Cooperado_5,
  ])

  const history = useHistory()

  useEffect(() => {
    setVinculo(null)
  }, [anoMes, estabelecimento])

  async function handleClickAdd() {
    setLoadingOpenForm(true)
    try {
      if (!vinculo) return
      const response = await obterDataFormulario2205(vinculo.vinculoId, anoMes)
      openFlowCreate({ data: response, isEdit: false })
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingOpenForm(false)
    }
  }

  async function handleClickEdit(id: string, isRetificar?: boolean) {
    setLoadingEdit(true)
    try {
      if (!vinculo) return
      const response = await obterDataFormulario2205(vinculo.vinculoId, anoMes, id)
      openFlowCreate({ data: response, isEdit: true, isRetificar })
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingEdit(false)
    }
  }

  async function handleDeleteItem() {
    if (!dataConfirmDelete) return
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  const handleClickItem = (
    event: 'edit' | 'delete' | 'props-alteradas',
    idOrData: string | VinculoAlteracaoContratoCadastro,
  ) => {
    const handleClickEditItem = () => {
      if (typeof idOrData === 'string') return
      if (idOrData.reciboEsocial)
        return dialogNotification.warning({
          descriptions: [
            'Esse evento já foi enviado para o eSocial, deseja fazer uma retificação?',
          ],
          labelOnCancel: 'Não',
          labelOnConfirm: 'Sim',
          onConfirm: () => handleClickEdit(idOrData.id, true),
          onCancel: () => handleClickEdit(idOrData.id, false),
        } as FixLater)
      handleClickEdit(idOrData.id, undefined)
    }

    const handleClickDeleteItem = () => {
      if (typeof idOrData !== 'string') return
      openConfirmDelete(idOrData)
    }

    function handleRegistrosAlterados() {
      if (typeof idOrData !== 'string') return
      openTablePropsAlteradas(idOrData)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
      'props-alteradas': handleRegistrosAlterados,
    }
    functions[event]()
  }

  function handleChangeOldVersion() {
    history.push({
      pathname: '/alteracao-cadastral-old',
    })
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(_, vinculo: VinculoMenuItem) => setVinculo(vinculo)}
      searchBy="vinculoNome"
      renderOption={(option) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Alteração Cadastral" subtitle={vinculo?.vinculoNome || '-'}>
          {vinculo?.vinculoId && (
            <ButtonBox>
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
              <IconButton
                size="small"
                onClick={handleChangeOldVersion}
                title="Retornar para versão antiga"
              >
                <ErrorOutlineIcon color="primary" />
              </IconButton>
              <Button
                size="small"
                color="primary"
                onClick={handleClickAdd}
                variant="contained"
                isLoading={isLoadingOpenForm}
              >
                Adicionar
              </Button>
            </ButtonBox>
          )}
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        {isLoadingEdit ? <LinearProgress /> : <Box height={4} />}
        {vinculo ? (
          <Table query={query} onItemClick={handleClickItem} vinculoId={vinculo.vinculoId} />
        ) : (
          <Typography>Selecione um Funcionário</Typography>
        )}
      </Box>

      {isOpenFlowCreate && dataFlowCreate && vinculo ? (
        <ActionDialog
          isOpen={isOpenFlowCreate}
          onClose={closeFlowCreate}
          title="Cadastro de Alteração Cadastral"
          subtitle={vinculo?.vinculoNome || '-'}
          dialogProps={{ maxWidth: 'lg', fullWidth: true }}
          customActions={<></>}
        >
          <FlowCreate
            onCancel={closeFlowCreate}
            data={dataFlowCreate.data}
            isEdit={dataFlowCreate.isEdit}
            isRetificar={dataFlowCreate.isRetificar}
          />
        </ActionDialog>
      ) : (
        <></>
      )}

      {isOpenTablePropsAlteradas && dataTablePropsAlteradas ? (
        <ActionDialog
          isOpen={isOpenTablePropsAlteradas}
          onClose={closeTablePropsAlteradas}
          title="Propriedades Alteradas"
          subtitle={vinculo?.vinculoNome || '-'}
          dialogProps={{ maxWidth: 'md', fullWidth: true }}
          renderRight={
            <ButtonBox>
              <Finder
                onSearch={setQueryTablePropsAlteradas}
                onClose={() => setQueryTablePropsAlteradas('')}
              />
            </ButtonBox>
          }
          customActions={
            <ButtonBox right={1} bottom={1}>
              <Button variant="contained" onClick={closeTablePropsAlteradas}>
                Fechar
              </Button>
            </ButtonBox>
          }
        >
          <TablePropsAlteradas
            idTabelaPai={dataTablePropsAlteradas}
            query={queryTablePropsAlteradas}
          />
        </ActionDialog>
      ) : (
        <></>
      )}

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isLoadingDelete}
        onCancel={closeConfirmDelete}
        onConfirm={handleDeleteItem}
        description="Você quer realmente excluir este registro? As alterações que foram feitas não serão desfeitas!"
      />
    </TreeView>
  )
}
