import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { TabelaINSS } from './TabelaINSS'

export const queryUseObterTodosTabelaINSS = '/TabelaINSS'

export function useObterTodosTabelaINSS() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: TabelaINSS[] }>(queryUseObterTodosTabelaINSS)
    return response?.data?.data || []
  }

  return useQuery([queryUseObterTodosTabelaINSS], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
