import { MUIAutoComplete } from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import { useResumoConfiguracoesLotacoes } from '~/hooks/queries/ExportacaoLancamentoConfig/useResumoConfiguracoesLotacoes'

import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'
import { LotacaoConfigConsultaDTO } from '~/hooks/queries/ExportacaoLancamentoConfig/dtos/LotacaoConfigConsultaDTO'

interface AutoCompleteLotacaoConfigConsultaProps extends MUIAutoCompleteProps {
  label: string
  modeloIntegracaoId: string
  value: string
  onChange: (value: LotacaoConfigConsultaDTO | null) => void
}

export default function AutoCompleteLotacaoConfigConsulta({
  label,
  modeloIntegracaoId,
  value,
  onChange,
  ...rest
}: AutoCompleteLotacaoConfigConsultaProps) {
  const { estabelecimento } = useAmbiente()
  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
    refetch,
  } = useResumoConfiguracoesLotacoes({
    params: {
      estabelecimentoId: estabelecimento.id,
      modeloIntegracaoId,
    },
  })
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: LotacaoConfigConsultaDTO) =>
    `${option?.lotacaoCodigo || ''} - ${option?.lotacaoNome || ''}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label={label}
      value={value}
      onChange={(_, obj: LotacaoConfigConsultaDTO) => onChange(obj)}
      optionId="id"
      {...rest}
    />
  )
}
