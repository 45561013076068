import { convertToArrayDescription } from '~/utils/utils'

export enum TipoHorarioIntervaloEnum {
  Fixo_01 = 1,
  Variavel_02 = 2,
}

export const TipoHorarioIntervaloLabels = {
  [TipoHorarioIntervaloEnum.Fixo_01]: '1 - Fixo',
  [TipoHorarioIntervaloEnum.Variavel_02]: '2 - Variável',
}

export const TipoHorarioIntervaloValues = convertToArrayDescription(
  TipoHorarioIntervaloEnum,
  TipoHorarioIntervaloLabels,
)
