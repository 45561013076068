import { formatToCNPJ, formatToCPF } from 'brazilian-values'
import moment from 'moment'

import { ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ConsultaVencimentosFuncionarioDTO } from '~/hooks/queries/ConsultaVencimentos/useObterConsultaVencimentos'
import { IndConsultaVencimentosValues } from '~/@types/enums/IndConsultaVencimentosEnum'

interface TableProps {
  data: ConsultaVencimentosFuncionarioDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
}

export default function Table(props: TableProps) {
  const { data: _data, isLoading, isFetching, query } = props

  const data = _data.map((d) => ({
    ...d,
    estabelecimentoNrInscricao: formatToCNPJ(d.estabelecimentoNrInscricao),
    funcionarioCPF: formatToCPF(d.funcionarioCPF),
    funcionarioDtAdmissao: d?.funcionarioDtAdmissao
      ? moment(d.funcionarioDtAdmissao).format('DD/MM/yyyy')
      : '',
    funcionarioDtRescisao: d?.funcionarioDtRescisao
      ? moment(d.funcionarioDtRescisao).format('DD/MM/yyyy')
      : '',
    dtInicio: d?.dtInicio ? moment(d.dtInicio).format('DD/MM/yyyy') : '',
    dtVencimento: d?.dtVencimento ? moment(d.dtVencimento).format('DD/MM/yyyy') : '',
    tipoConsulta:
      IndConsultaVencimentosValues.find((ind) => ind.value === d.tipoConsulta)?.name || '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'estabelecimentoNrInscricao',
      label: 'CNPJ',
    },
    {
      name: 'estabelecimentoNome',
      label: 'Estabelecimento',
    },
    {
      name: 'funcionarioCPF',
      label: 'CPF',
    },
    {
      name: 'funcionarioNome',
      label: 'Funcionário',
    },
    {
      name: 'funcionarioDtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'funcionarioDtRescisao',
      label: 'Rescisão',
    },
    {
      name: 'dtInicio',
      label: 'Data de Início',
    },
    {
      name: 'dtVencimento',
      label: 'Data de Vencimento',
    },
    {
      name: 'tipoConsulta',
      label: 'Tipo de Consulta',
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      sherlock={{
        columns: ['estabelecimentoNome', 'funcionarioCPF', 'funcionarioNome', 'tipoConsulta'],
        query,
      }}
    />
  )
}
