import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ApuracaoEncargosFGTSDTO } from './ApuracaoEncargosFGTSDTO'

const queryObterApuracaoEncargosEstabelecimentoFGTS =
  '/ApurarEncargos/ObterApuracaoEncargosEstabelecimentoFGTS'

interface RequestProps {
  empregadorId: string
  periodoApuracao: string
  isSomenteDiferenca: boolean
}

export default function useObterApuracaoEncargosEstabelecimentoFGTS(props: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ApuracaoEncargosFGTSDTO[] }>(
      queryObterApuracaoEncargosEstabelecimentoFGTS,
      {
        params: props,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterApuracaoEncargosEstabelecimentoFGTS, props], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
