import React from 'react'

import { formatToBRL } from 'brazilian-values'
import moment from 'moment'

import { ButtonBox } from 'mio-library-ui'

import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  data: FixLater[]
  onItemClick: (event: 'edit' | 'delete', id: string) => void
  isLoading: boolean
}

export default function Table(props: TableProps) {
  const { data, onItemClick, isLoading } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtSalario',
      label: 'Data Salário',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtEfetiva',
      label: 'Data Efetiva',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'vrSalario',
      label: 'Valor',
      options: {
        customBodyRender: (value) => <ButtonBox>{formatToBRL(value).replace('R$ ', '')}</ButtonBox>,
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value: string) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}
