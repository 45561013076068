const naoInformado = 0
const sobreComercializaçãoProducao = 1
const sobreFolhaPagamento = 2

export const indProdutorRuralOpcaoPrevidenciaValues = [
  { value: naoInformado, name: '0 - Não informar' },
  {
    value: sobreComercializaçãoProducao,
    name: '1 - Sobre a comercialização da sua produção',
  },
  { value: sobreFolhaPagamento, name: '2 - Sobre a folha de pagamento' },
]

export const indProdutorRuralOpcaoPrevidenciaConsts = {
  naoInformado,
  sobreComercializaçãoProducao,
  sobreFolhaPagamento,
}
