import React, { useMemo } from 'react'

import { HeightDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

const Table = (props) => {
  const { data, isLoading, query } = props

  const { formatAnoMes } = useUtils()

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'anoMes',
        label: 'Ano/Mês',
        options: {
          customBodyRender: (value) => (value ? formatAnoMes(value) : null),
        },
      },
      {
        name: 'dtPagamentoReciboMensal',
        label: 'Pagamento Recibo Mensal',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtPagamentoProLabore',
        label: 'Pagamento Recibo ProLabore',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtPagamentoAdiantamento',
        label: 'Pagamento Recibo Adiantamento',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtPagamentoRPA',
        label: 'Pagamento Recibo RPA',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'qtdDiasUteis',
        label: 'Dias úteis',
      },
      {
        name: 'qtdDiasNaoUteis',
        label: 'Dias não úteis',
      },
    ],
    [formatAnoMes],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['nome'],
      }}
    />
  )
}

export default Table
