import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { ButtonBox } from 'mio-library-ui'

import { Container, Finder, PageHeader, Button, ActionDialog } from '~/components'
import ConfirmDeleteAlert from '~/components/ConfirmInscricaoDelete'

import Table from './Table'
import InitialForm from './InitialForm'
import FlowCreate from './FlowCreate'
import ConfirmInfoCadastro from '../Funcionario/ConfirmInfoCadastro'

import useDialog from '~/hooks/useDialog'
import { useVinculoDelete } from '~/hooks/queries/Vinculo/useVinculoDelete'

import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

export default function Autonomo() {
  const [query, setQuery] = useState('')
  const history = useHistory()

  const {
    close: closeDialogConfirmDelete,
    data: dataDialogConfirmDelete,
    isOpen: isOpenDialogConfirmDelete,
    open: openDialogConfirmDelete,
  } = useDialog({
    id: '',
    nrInscricao: '',
  })
  const {
    close: closeFlowCreate,
    data: dataFlowCreate,
    isOpen: isOpenFlowCreate,
    open: openFlowCreate,
  } = useDialog<VinculoPartial | null>(null)
  const {
    close: closeInitialForm,
    isOpen: isOpenInitialForm,
    open: openInitialForm,
  } = useDialog(null)
  const {
    data: dataConfirmInfoCadastro,
    close: closeConfirmInfoCadastro,
    isOpen: isOpenConfirmInfoCadastro,
    open: openConfirmInfoCadastro,
  } = useDialog<VinculoPartial | null>(null)

  const { mutateAsync, isLoading: isLoadingDelete } = useVinculoDelete()

  function handleClickItem(event: 'edit' | 'delete', vinculo: VinculoConsulta) {
    async function handleClickEditItem() {
      history.push({
        pathname: `/autonomo/${vinculo.id}`,
      })
    }

    function handleClickDeleteItem() {
      openDialogConfirmDelete({
        id: vinculo.id,
        nrInscricao: vinculo.pessoaCPF,
      })
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event]()
  }

  async function handleDeleteItem(id: string) {
    await mutateAsync(id)
    closeDialogConfirmDelete()
  }

  function handleClickAdd() {
    openInitialForm(null)
  }

  function handleSubmitInitialForm(dt: VinculoPartial) {
    openConfirmInfoCadastro(dt)
  }

  function handleSubmitFormConfirmInfoCadastro(dt: VinculoPartial) {
    closeConfirmInfoCadastro()
    closeInitialForm()
    openFlowCreate(dt)
  }

  return (
    <Container>
      <PageHeader title="Autônomo">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button size="small" color="primary" onClick={handleClickAdd} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Table query={query} onClickItem={handleClickItem} />

      {dataFlowCreate && (
        <ActionDialog
          title="Cadastro Inicial - Autônomo"
          isOpen={isOpenFlowCreate}
          onClose={closeFlowCreate}
          customActions={<></>}
          dialogProps={{
            fullWidth: true,
            maxWidth: 'lg',
          }}
        >
          <FlowCreate data={dataFlowCreate} onCancel={closeFlowCreate} />
        </ActionDialog>
      )}

      {isOpenInitialForm && (
        <ActionDialog
          title="Cadastro Inicial - Autônomo"
          isOpen={isOpenInitialForm}
          onClose={closeInitialForm}
          customActions={<></>}
          dialogProps={{
            fullWidth: true,
            maxWidth: 'sm',
          }}
        >
          <InitialForm onCancel={closeInitialForm} onSubmit={handleSubmitInitialForm} />
        </ActionDialog>
      )}

      {isOpenConfirmInfoCadastro && dataConfirmInfoCadastro ? (
        <ConfirmInfoCadastro
          isOpen={isOpenConfirmInfoCadastro}
          data={dataConfirmInfoCadastro}
          onCancel={closeConfirmInfoCadastro}
          onSubmit={handleSubmitFormConfirmInfoCadastro}
        />
      ) : (
        <></>
      )}

      {isOpenDialogConfirmDelete && (
        <ConfirmDeleteAlert
          isOpen={isOpenDialogConfirmDelete}
          isLoading={isLoadingDelete}
          onCancel={closeDialogConfirmDelete}
          onConfirm={() => handleDeleteItem(dataDialogConfirmDelete.id)}
          nrInscricao={dataDialogConfirmDelete.nrInscricao}
        />
      )}
    </Container>
  )
}
