import React from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: '#444499',
  },
  typo: {
    fontSize: 64,
    color: 'white',
  },
  errorMessage: {
    fontSize: 18,
    color: 'white',
  },
}))

const Displays = (props) => {
  const classes = useStyles()
  const { message } = props
  return (
    <Box className={classes.root}>
      <Typography className={classes.typo}>:(</Typography>
      <Typography className={classes.errorMessage}>{message}</Typography>
    </Box>
  )
}

export default Displays
