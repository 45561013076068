import { convertToArrayDescription } from '~/utils/utils'

export enum FiltroCompetenciaFechamentoEnum {
  TodasEmpresas,
  EmpresasAbertas,
  EmpresasFechadas,
}

export const FiltroCompetenciaFechamentoLabels = {
  [FiltroCompetenciaFechamentoEnum.TodasEmpresas]: 'Todas Empresas',
  [FiltroCompetenciaFechamentoEnum.EmpresasAbertas]: 'Empresas Abertas',
  [FiltroCompetenciaFechamentoEnum.EmpresasFechadas]: 'Empresas Fechadas',
}

export const FiltroCompetenciaFechamentoValues = convertToArrayDescription(
  FiltroCompetenciaFechamentoEnum,
  FiltroCompetenciaFechamentoLabels,
)
