import React, { useEffect, useState } from 'react'

import { makeStyles, Typography, Box } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { Checkbox } from '~/components'

import { mapsValuesMes } from '~/values/valuesMes'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  hightLightAlert: {
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
}))

export default function DialogValidadeDtBase(props) {
  const { isOpen, onClose, data: _data, onChange } = props
  const { dtMesBase, dtToDisplay, isIndenizacaoDataBase } = _data

  const [data, setData] = useState(true)

  const classes = useStyles()

  useEffect(() => {
    setData(isIndenizacaoDataBase)
  }, [isIndenizacaoDataBase])

  function handleSubmit() {
    onClose()
    onChange(data)
  }

  return (
    <ActionDialog
      title="Validade Data Base"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Continuar"
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Typography>Data base da categoria: {mapsValuesMes[dtMesBase]}</Typography>
      <Typography>
        Data de Rescisão/Projeção do Aviso: {moment(dtToDisplay).format('DD/MM/yyyy')}
      </Typography>
      <Typography variant="h6" className={classes.hightLightAlert}>
        Aviso Prévio vencendo dentro dos 30 dias que antecedem a Data Base
      </Typography>
      <Box pt={1} width="50%">
        <Checkbox
          label="Pagar Indenização Art. 9 Lei 7.238"
          value={data}
          onChange={(e, value) => setData(value)}
        />
      </Box>
    </ActionDialog>
  )
}
