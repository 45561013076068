import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'

import {
  RemoveCircle as NoneIcon,
  CheckCircle as CheckIcon,
  Error as WarningIcon,
  Cancel as ErrorIcon,
  RotateRight as WaitingIcon,
} from '@material-ui/icons'
import { Badge } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

const useStyles = makeStyles(() => ({
  badge: {
    fontSize: 10,
    fontWeight: 700,
    height: '15px',
    minWidth: '15px',
    padding: '0 2px',
    borderRadius: '50%',
    color: '#FFF',
  },
}))

const StatusIcon = ({ status, value }) => {
  const [icons, setIcons] = useState([])

  const classes = useStyles()

  useEffect(() => {
    let iconList = []

    switch (status) {
      case 'validar':
        if (value.getValidarCount.item1 > 0) {
          iconList.push({
            icon: 'error',
            count: value.getValidarCount.item1,
          })
        }

        if (value.getValidarCount.item2 > 0) {
          iconList.push({
            icon: 'warning',
            count: value.getValidarCount.item2,
          })
        }

        if (value.status === 10) {
          iconList.push({ icon: 'waiting' })
        } else if (value.status === 12 || value.status >= 20) {
          iconList.push({ icon: 'check' })
        } else {
          iconList.push({ icon: 'none' })
        }

        break
      case 'iniciar':
        if (value.getIniciarCount.item1 > 0) {
          iconList.push({
            icon: 'error',
            count: value.getIniciarCount.item1,
          })
        }

        if (value.getIniciarCount.item2 > 0) {
          iconList.push({
            icon: 'warning',
            count: value.getIniciarCount.item2,
          })
        }

        if (value.status === 20) {
          iconList.push({ icon: 'waiting' })
        } else if (value.status === 22 || value.status >= 30) {
          iconList.push({ icon: 'check' })
        } else {
          iconList.push({ icon: 'none' })
        }

        break
      case 'finalizar':
        if (value.getFinalizarCount.item1 > 0) {
          iconList.push({
            icon: 'error',
            count: value.getFinalizarCount.item1,
          })
        }

        if (value.getFinalizarCount.item2 > 0) {
          iconList.push({
            icon: 'warning',
            count: value.getFinalizarCount.item2,
          })
        }

        if (value.status === 30) {
          iconList.push({ icon: 'waiting' })
        } else if (value.status === 32) {
          iconList.push({ icon: 'check' })
        } else {
          iconList.push({ icon: 'none' })
        }

        break

      default:
        iconList.push({ icon: 'none' })
        break
    }

    setIcons([...iconList])
  }, [
    status,
    value.getFinalizarCount.item1,
    value.getFinalizarCount.item2,
    value.getIniciarCount.item1,
    value.getIniciarCount.item2,
    value.getValidarCount.item1,
    value.getValidarCount.item2,
    value.status,
  ])

  const getIcon = (type) => {
    switch (type) {
      case 'none':
        return <NoneIcon color="secondary" />

      case 'waiting':
        return <WaitingIcon color="secondary" />

      case 'warning':
        return <WarningIcon style={{ color: '#f0ad4e' }} />

      case 'error':
        return <ErrorIcon style={{ color: '#f44336' }} />

      case 'check':
        return <CheckIcon color="secondary" />

      default:
        return <NoneIcon color="secondary" />
    }
  }

  return (
    <Box>
      <ButtonBox>
        {icons.map((item, index) => (
          <Badge
            color="secondary"
            badgeContent={item.count}
            overlap="circle"
            max={9}
            classes={{
              badge: classes.badge,
            }}
            key={index}
          >
            {getIcon(item.icon)}
          </Badge>
        ))}
      </ButtonBox>
    </Box>
  )
}

export default StatusIcon
