import React, { useEffect, useState } from 'react'

import {
  ActionDialog,
  ContentDivider,
  CurrencyTextField,
  DatePicker,
  TextField,
} from '~/components'

import ConfirmSaveAlert from '~/components/ConfirmSaveAlert'

import { Grid } from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import { useSubmit } from '~/hooks/queries/useSindicatoConvencaoColetiva'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'
import useUtils from '~/hooks/useUtils'
import useDialogNotification from '~/hooks/useDialogNotification'

import * as yup from 'yup'
import moment from 'moment'

const schema = yup.object().shape({
  dtConvencaoColetiva: yup.string().required('Informe a Data da Convenção Coletiva'),
  dtPeriodoFim: yup
    .date()
    .typeError('Informe uma Data válida')
    .when('dtPeriodoInicio', (dtPeriodoInicio, schema, { value }) => {
      const dtPeriodoInicioExists = dtPeriodoInicio?.length > 0
      if (!dtPeriodoInicioExists || !value) return

      return schema.test(
        'dtEfetivaRetorno-validator',
        `A Data Período Fim não pode ser inferior a Data Período Início`,
        (dtPeriodoFim) => moment(dtPeriodoFim).isSameOrAfter(dtPeriodoInicio),
      )
    })
    .nullable(),
  vrSalarioBase: yup.number().required('Informe o Valor Salário Base').nullable(),
})

export default function Form(props) {
  const { data: _data, isOpen, onClose } = props
  const [data, setData] = useState({})

  const { formatCurrency } = useUtils()

  const history = useHistory()
  const dialogNotification = useDialogNotification()

  const {
    data: dataConfirmSaveAlert,
    isOpen: isOpenConfirmSaveAlert,
    open: openConfirmSaveAlert,
    close: closeConfirmSaveAlert,
  } = useDialog()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const { mutateAsync, isLoading } = useSubmit()

  function handleOpenConfirmSaveAlert() {
    const oldVrSalarios = {
      vrSalarioBase: _data?.vrSalarioBase ? formatCurrency(_data?.vrSalarioBase) : 0,
      vrSalarioBaseII: _data?.vrSalarioBaseII ? formatCurrency(_data?.vrSalarioBaseII) : 0,
      vrSalarioBaseIII: _data?.vrSalarioBaseIII ? formatCurrency(_data?.vrSalarioBaseIII) : 0,
      vrSalarioBaseIV: _data?.vrSalarioBaseIV ? formatCurrency(_data?.vrSalarioBaseIV) : 0,
    }

    const newVrSalarios = {
      vrSalarioBase: data?.vrSalarioBase ? formatCurrency(data?.vrSalarioBase) : 0,
      vrSalarioBaseII: data?.vrSalarioBaseII ? formatCurrency(data?.vrSalarioBaseII) : 0,
      vrSalarioBaseIII: data?.vrSalarioBaseIII ? formatCurrency(data?.vrSalarioBaseIII) : 0,
      vrSalarioBaseIV: data?.vrSalarioBaseIV ? formatCurrency(data?.vrSalarioBaseIV) : 0,
    }

    openConfirmSaveAlert({
      oldVrSalarios,
      newVrSalarios,
    })
  }

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
    closeConfirmSaveAlert()

    dialogNotification.warning({
      descriptions: ['Deseja fazer o processamento da Alteração de Salário(2206)?'],
      onConfirm: handleRedirectUser,
      labelOnCancel: 'Cancelar',
    })
  }

  function handleRedirectUser() {
    history.push({
      pathname: '/processar-alteracao-salario',
      state: {
        sindicatoId: data?.sindicatoId,
        dtAlteracao: data?.dtConvencaoColetiva,
      },
    })
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleOpenConfirmSaveAlert,
    data,
  })

  return (
    <>
      <ActionDialog
        title="Cadastro do Sindicato Convenção Coletiva"
        isOpen={isOpen}
        onClose={onClose}
        okLabel="Salvar"
        isOkProcessing={isLoading}
        onOkClick={handleValidate}
        onCancelClick={onClose}
        dialogProps={{ maxWidth: 'md', fullWidth: true }}
      >
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <DatePicker
              name="dtConvencaoColetiva"
              label="Data da Convenção Coletiva"
              size="small"
              required
              value={data?.dtConvencaoColetiva || null}
              onChange={(date) => {
                const dtConvencaoColetiva = date ? date.format('yyyy-MM-DD') : null
                setData((oldState) => ({
                  ...oldState,
                  dtConvencaoColetiva,
                }))
              }}
              validationErrors={validationErrors}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <DatePicker
              name="dtPeriodoInicio"
              label="Data Periodo Inicio"
              size="small"
              value={data?.dtPeriodoInicio || null}
              onChange={(date) => {
                const dtPeriodoInicio = date ? date.format('yyyy-MM-DD') : null
                setData((oldState) => ({
                  ...oldState,
                  dtPeriodoInicio,
                }))
              }}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <DatePicker
              name="dtPeriodoFim"
              label="Data Periodo Fim"
              size="small"
              value={data?.dtPeriodoFim || null}
              onChange={(date) => {
                const dtPeriodoFim = date ? date.format('yyyy-MM-DD') : null
                setData((oldState) => ({
                  ...oldState,
                  dtPeriodoFim,
                }))
              }}
              validationErrors={validationErrors}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <CurrencyTextField
              name="vrSalarioBase"
              label="Valor Salário Base I"
              variant="outlined"
              size="small"
              maximumValue="9999999999999"
              fullWidth
              required
              value={data?.vrSalarioBase || ''}
              onChange={(_, value) => {
                const vrSalarioBase = value
                setData((oldState) => ({
                  ...oldState,
                  vrSalarioBase,
                }))
              }}
              validationErrors={validationErrors}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <CurrencyTextField
              name="vrSalarioBaseII"
              label="Valor Salário Base II"
              variant="outlined"
              size="small"
              maximumValue="9999999999999"
              fullWidth
              value={data?.vrSalarioBaseII || ''}
              onChange={(_, value) => {
                const vrSalarioBaseII = value
                setData((oldState) => ({
                  ...oldState,
                  vrSalarioBaseII,
                }))
              }}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <CurrencyTextField
              name="vrSalarioBaseIII"
              label="Valor Salário Base III"
              variant="outlined"
              size="small"
              maximumValue="9999999999999"
              fullWidth
              value={data?.vrSalarioBaseIII || ''}
              onChange={(_, value) => {
                const vrSalarioBaseIII = value
                setData((oldState) => ({
                  ...oldState,
                  vrSalarioBaseIII,
                }))
              }}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <CurrencyTextField
              name="vrSalarioBaseIV"
              label="Valor Salário Base IV"
              variant="outlined"
              size="small"
              maximumValue="9999999999999"
              fullWidth
              value={data?.vrSalarioBaseIV || ''}
              onChange={(_, value) => {
                const vrSalarioBaseIV = value
                setData((oldState) => ({
                  ...oldState,
                  vrSalarioBaseIV,
                }))
              }}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Observação"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              inputProps={{
                maxLength: 500,
              }}
              value={data?.observacao || ''}
              onChange={(e) => {
                const observacao = e.target.value
                setData((oldState) => ({
                  ...oldState,
                  observacao,
                }))
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ContentDivider title="Configurações do Feriado" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CurrencyTextField
              label="Valor da gratificação do feriado trabalhado"
              fullWidth
              value={data?.vrGratificacaoTrabalhoFeriado || ''}
              variant="outlined"
              size="small"
              onChange={(e, value) => {
                const vrGratificacaoTrabalhoFeriado = value
                setData({ ...data, vrGratificacaoTrabalhoFeriado })
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Dias para conceder a folga no feriado trabalhado"
              onlyNumber
              value={data?.nrDiasParaConcederFolgaTrabalhoFeriado || ''}
              inputProps={{
                maxLength: 3,
              }}
              onChange={(e) => {
                const nrDiasParaConcederFolgaTrabalhoFeriado = e.target.value
                setData({ ...data, nrDiasParaConcederFolgaTrabalhoFeriado })
              }}
            />
          </Grid>
        </Grid>
      </ActionDialog>

      <ConfirmSaveAlert
        data={dataConfirmSaveAlert}
        isOpen={isOpenConfirmSaveAlert}
        onConfirm={handleSubmit}
        onCancel={closeConfirmSaveAlert}
      />
    </>
  )
}
