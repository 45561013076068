const tipico_1 = 1
const doenca_2 = 2
const trajeto_3 = 3

export const tipoAcidenteValues = [
  { value: tipico_1, name: '1 - Típico' },
  { value: doenca_2, name: '2 - Doença' },
  { value: trajeto_3, name: '3 - Trajeto' },
]

export const tipoAcidenteConsts = {
  tipico_1,
  doenca_2,
  trajeto_3,
}
