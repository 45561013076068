import { MUIAutoComplete } from '~/components/AutoComplete'

import { useObterTodosSalario } from '~/hooks/queries/Salario/useObterTodosSalario'

import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'
import { Salario } from '~/hooks/queries/Salario/dtos/Salario'
import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'
import { SalarioDTO } from '~/hooks/queries/Salario/dtos/SalarioDTO'
import { formatCurrency, formatSimpleDate } from '~/utils/utils'

interface AutoCompleteSalarioProps extends MUIAutoCompleteProps {
  value: string
  onChange: (value: Salario | null) => void
}

export default function AutoCompleteSalario(props: AutoCompleteSalarioProps) {
  const { value, onChange, ...rest } = props

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
    refetch,
  } = useObterTodosSalario()
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: SalarioDTO) =>
    `${option.codigo} - ${option.nome} - ${formatSimpleDate(option?.dtSalario)}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Salário"
      value={value}
      onChange={(_, obj: Salario) => onChange(obj)}
      optionId="id"
      renderOptionComponent={(option: SalarioDTO) => (
        <AutoCompleteOptionDescription
          title={renderOption(option)}
          descriptions={[
            {
              description: `Base I:${formatCurrency(
                option?.vrSalarioBase,
              )} - Base II:${formatCurrency(option?.vrSalarioBaseII)}`,
            },
            {
              description: `Base III:${formatCurrency(
                option?.vrSalarioBaseIII,
              )} - Base IV:${formatCurrency(option?.vrSalarioBaseIV)}`,
            },
          ]}
        />
      )}
      {...rest}
    />
  )
}
