import React, { useState } from 'react'

import moment from 'moment'
import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import { TextField, ActionDialog } from '~/components'

import { DatePickerNew } from '~/components'
import {
  AutoCompleteCargo,
  AutoCompleteDepartamento,
  AutoCompleteSetor,
} from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import { VinculoLocalPartial } from '~/hooks/queries/VinculoLocal/VinculoLocal'
import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'

const schema = yup.object().shape({
  dtLocal: yup
    .date()
    .required('Informe Data')
    .typeError('Informe uma data válida')
    .nullable()
    .when('$dtAdmissao', (dtAdmissao, schema) => {
      return schema.test(
        'testData',
        'Informe uma Data maior que a Data de Admissão',
        (dtLocal: string) => {
          if (!dtAdmissao || !dtLocal) return false
          return moment(dtAdmissao).isSameOrBefore(dtLocal)
        },
      )
    }),
  cargoId: yup.string().required('Informe Cargo'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoLocalPartial
  indexCurrent: number
  onAfterSubmitForm: (
    event: 'insert' | 'update',
    data: VinculoLocalPartial,
    indexCurrent: number,
  ) => void
  vinculo: VinculoPartial
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, indexCurrent, onAfterSubmitForm, vinculo } = props
  const [data, setData] = useState(_data)
  const notification = useNotification()

  const handleSubmit = () => {
    const update = async () => {
      onAfterSubmitForm('update', data, indexCurrent)
      notification.put()
    }

    const insert = async () => {
      onAfterSubmitForm('insert', data, indexCurrent)
      notification.post()
    }

    if (indexCurrent >= 0) {
      update()
      return
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: {
        dtAdmissao: vinculo?.dtAdmissao,
      },
    },
  })

  return (
    <ActionDialog
      title="Cadastro de Ambiente de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePickerNew
            label="Data"
            size="small"
            value={data?.dtLocal || null}
            required
            validationErrors={validationErrors}
            name="dtLocal"
            onChange={(date) => {
              const dtLocal = date || undefined
              setData({ ...data, dtLocal })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data?.observacao || ''}
            size="small"
            multiline
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteDepartamento
            onChange={(e, departamento) => {
              const departamentoId = departamento ? departamento.id : ''
              setData({ ...data, departamentoId, departamento })
            }}
            value={data?.departamento || null}
            empregadorId={vinculo?.estabelecimento?.empregadorId}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteSetor
            onChange={(e, setor) => {
              const setorId = setor ? setor.id : ''
              setData({ ...data, setorId, setor })
            }}
            value={data?.setor || null}
            empregadorId={vinculo?.estabelecimento?.empregadorId}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteCargo
            validationErrors={validationErrors}
            name="cargoId"
            required
            onChange={(e, cargo) => {
              const cargoId = cargo ? cargo.id : ''
              setData({ ...data, cargoId, cargo })
            }}
            value={data?.cargoId || ''}
            optionId="id"
            empregadorId={vinculo.estabelecimento?.empregadorId}
            disabled={data?.id ? true : false}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
