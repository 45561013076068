const proprioContribuinte_1 = 1
const outraEntidadeEmpresaOuEmpregado_2 = 2

export const processoAutoriaValues = [
  {
    value: proprioContribuinte_1,
    name: '1 - Próprio Contribuinte é autor da ação',
  },
  {
    value: outraEntidadeEmpresaOuEmpregado_2,
    name: '2 - Outra entidade, empresa ou empregado',
  },
]
