import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core'
import { ActionDialog } from '~/components'

import Table from './components/Table'

import moment from 'moment'
import _ from 'lodash'

import api from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '40%',
  },
}))

const DialogFeriados = (props) => {
  const { isOpen, onClose, anoMes, feriadosNacionais, setHaveFeriado } = props
  const [data, setData] = useState([])

  const dialogNotification = useDialogNotification()
  const classes = useStyles()

  useEffect(() => {
    const getDatesByAnoMes = async () => {
      setHaveFeriado(false)
      try {
        const response = await api.get('/feriado')
        const newList = response.data.data.filter((obj) => {
          if (
            moment(obj?.dtFeriado) >= moment(anoMes) &&
            moment(obj?.dtFeriado) <= moment(anoMes).endOf('month')
          ) {
            return obj
          } else {
            return null
          }
        })

        if (newList.length > 0) {
          setHaveFeriado(true)
        }

        if (feriadosNacionais?.length > 0) {
          feriadosNacionais.forEach((obj) => {
            newList.push(obj)
          })
        }

        setData(_.orderBy(newList, 'dtFeriado', 'asc'))
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    }
    getDatesByAnoMes()
    // eslint-disable-next-line
  }, [anoMes, feriadosNacionais])

  return (
    <ActionDialog
      title={`Feriados de ${anoMes?.substr(0, 4)}/${anoMes?.substr(4)}`}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      customActions={<div />}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <Table data={data} />
    </ActionDialog>
  )
}

export default DialogFeriados
