import api from '~/services/api-pessoal'

import { VinculoAlteracaoCadastro2205DTO } from './VinculoAlteracaoCadastro2205DTO'

export async function obterDataFormulario2205(
  vinculoId: string,
  anoMes: string,
  vinculoAlteracaoCadastroId?: string,
) {
  const response = await api.get<{ data: VinculoAlteracaoCadastro2205DTO }>(
    '/VinculoAlteracaoContratoCadastro/ObterDataFormulario2205',
    {
      params: {
        vinculoId,
        vinculoAlteracaoCadastroId,
        anoMes,
      },
    },
  )
  return response.data.data
}
