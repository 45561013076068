import { useState, useEffect } from 'react'

import * as yup from 'yup'

import { Grid, Box, MenuItem, Tooltip, IconButton, Zoom, useTheme } from '@material-ui/core'
import { FileCopy as FileCopyIcon } from '@material-ui/icons'

import {
  CollapseContent,
  DatePickerNew,
  UFSelect,
  NumeroInscricaoTextField,
  CEPTextField,
  Checkbox,
  TelefoneTextField,
  MaskedTextField,
  Button,
  TextField,
  ButtonBox,
  ContentDivider,
  PageHeader,
} from '~/components'
import {
  AutoCompleteMemoryTable,
  AutoCompletePais,
  AutoCompleteCidade,
  AutoCompleteLogradouro,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import useValidationErrors from '~/hooks/useValidationErrors'
import { copyToClipboard } from '~/hooks/useUtils'

import { imigranteCondicaoTrabalhadorValues } from '~/values/imigranteCondicaoTrabalhadorValues'

import { PessoaFisica } from '~/hooks/queries/PessoaFisica/PessoaFisica'

const schema = yup.object().shape({
  dtNascimento: yup.string().required('Informe a Data Nascimento').nullable(),
  sexo: yup.string().required('Informe o Sexo').nullable(),
  grauInstrucao: yup.string().required('Informe o Grau Instrução').nullable(),
  estadoCivil: yup.string().required('Informe o Estado Cívil').nullable(),
  racaCor: yup.string().required('Informe a Cor').nullable(),
  naturalidadeCidadeId: yup.string().required('Informe a Naturalidade').nullable(),
  nacionalidadePaisId: yup.string().required('Informe a Nacionalidade').nullable(),
  nascimentoPaisId: yup.string().required('Informe o País Nascimento').nullable(),

  nome: yup.string().required('Informe o Nome').nullable(),
  cep: yup.string().required('Informe o CEP').min(8, 'Inserir no mínimo 8 caracteres.').nullable(),
  logradouroId: yup.string().required('Informe o Logradouro').nullable(),
  endereco: yup.string().required('Informe o Endereço'),
  imigranteCondicaoTrabalhador: yup.string().required('Informe a Condição do Imigrante'),
  bairro: yup.string().required('Informe o Bairro'),
  cidadeId: yup.string().required('Informe a Cidade').nullable(),
  ctpsNumero: yup.string().required('Informe o Número CTPS'),
  ctpsSerie: yup.string().required('Informe a Série CTPS'),
})

interface CollapsesProps {
  primaria: boolean
  documentos: boolean
  outras: boolean
  imigrante: boolean
  pcd: boolean
}

interface PessoaFisicaFormProps {
  data: PessoaFisica
  onSubmit: (data: PessoaFisica) => void
}

export default function PessoaFisicaForm(props: PessoaFisicaFormProps) {
  const { data: _data, onSubmit } = props

  const [data, setData] = useState<Partial<PessoaFisica>>({})
  const [collapseItens, setCollapseItens] = useState<CollapsesProps>({
    primaria: true,
    documentos: true,
    outras: true,
    imigrante: true,
    pcd: true,
  })

  useEffect(() => {
    setData(_data)
  }, [_data])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const theme = useTheme()

  function handleSubmit() {
    onSubmit(data as PessoaFisica)
  }

  function handleCancel() {
    setData(_data)
  }

  const handleCollapseAll = (value: boolean) => {
    setCollapseItens({
      primaria: value,
      documentos: value,
      outras: value,
      imigrante: value,
      pcd: value,
    })
  }

  const toggleCollapse = (item: keyof CollapsesProps) => {
    setCollapseItens((prev) => ({ ...prev, [item]: !prev[item] }))
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <Box>
        <PageHeader>
          <ButtonBox top={1} bottom={1}>
            <Tooltip title="Expandir todos" arrow TransitionComponent={Zoom}>
              <IconButton size="small" onClick={() => handleCollapseAll(true)}>
                <CollapseOpenIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Retrair todos" arrow TransitionComponent={Zoom}>
              <IconButton size="small" onClick={() => handleCollapseAll(false)}>
                <CollapseCloseIcon />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box flex={1} minHeight={200} overflow="auto" position="relative">
        <Box position="absolute">
          <Grid container spacing={0}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CollapseContent
                title="Informações primárias"
                isOpen={collapseItens.primaria}
                onClick={() => toggleCollapse('primaria')}
              >
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      label="Nome"
                      name="nome"
                      validationErrors={validationErrors}
                      size="small"
                      fullWidth
                      autoFocus
                      value={data?.nome || ''}
                      required
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        const nome = e?.target?.value || ''
                        setData((oldState) => ({
                          ...oldState,
                          nome,
                        }))
                      }}
                    />
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
                    <Box display="flex" gridGap={theme.spacing(0.5)} alignItems="center">
                      <NumeroInscricaoTextField
                        label="CPF"
                        value={data?.nrInscricao || ''}
                        onChange={(e) =>
                          setData((prev) => ({ ...prev, nrInscricao: e.target.value }))
                        }
                      />
                      <IconButton
                        size="small"
                        title="Copiar para a Área de Transferência"
                        onClick={() => copyToClipboard(data?.nrInscricao)}
                      >
                        <FileCopyIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>

                  <Grid item xl={2} lg={4} md={4} sm={12} xs={12}>
                    <DatePickerNew
                      label="Data Nascimento"
                      size="small"
                      value={data.dtNascimento || null}
                      required
                      validationErrors={validationErrors}
                      name="dtNascimento"
                      onChange={(date) => {
                        const dtNascimento = date || undefined
                        setData({ ...data, dtNascimento })
                      }}
                    />
                  </Grid>
                  <Grid item xl={5} lg={4} md={4} sm={12} xs={12}>
                    <TextField
                      label="Nome Mãe"
                      fullWidth
                      value={data.nomeMae || ''}
                      size="small"
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        const nomeMae = e.target.value
                        setData({ ...data, nomeMae })
                      }}
                    />
                  </Grid>

                  <Grid item xl={5} lg={4} md={4} sm={12} xs={12}>
                    <TextField
                      label="Nome Pai"
                      fullWidth
                      value={data.nomePai || ''}
                      size="small"
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        const nomePai = e.target.value
                        setData({ ...data, nomePai })
                      }}
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
                    <TextField
                      select
                      label="Sexo"
                      fullWidth
                      size="small"
                      value={data.sexo || ''}
                      required
                      validationErrors={validationErrors}
                      name="sexo"
                      onChange={(e) => {
                        const sexo = e.target.value
                        setData({ ...data, sexo })
                      }}
                    >
                      <MenuItem key={1} value="M">
                        Masculino
                      </MenuItem>
                      <MenuItem key={2} value="F">
                        Feminino
                      </MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                    <AutoCompleteMemoryTable
                      table="GrauInstrucao"
                      label="Grau Instrução"
                      value={data.grauInstrucao || null}
                      required
                      validationErrors={validationErrors}
                      name="grauInstrucao"
                      onChange={(e, memoryTable) => {
                        const grauInstrucao = memoryTable ? memoryTable.codigo : null
                        setData({ ...data, grauInstrucao })
                      }}
                      optionId="codigo"
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <AutoCompleteMemoryTable
                      table="EstadoCivil"
                      label="Estado Cívil"
                      value={data.estadoCivil || null}
                      required
                      validationErrors={validationErrors}
                      name="estadoCivil"
                      optionId="codigo"
                      onChange={(e, memoryTable) => {
                        const estadoCivil = memoryTable ? memoryTable.codigo : null
                        setData({ ...data, estadoCivil })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                    <AutoCompletePais
                      label="Nacionalidade"
                      value={data?.nacionalidadePaisId || null}
                      required
                      validationErrors={validationErrors}
                      name="nacionalidadePaisId"
                      onChange={(e, obj) => {
                        const nacionalidadePaisId = obj?.id || null
                        setData({
                          ...data,
                          nacionalidadePaisId,
                        })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                    <AutoCompletePais
                      label="País Nascimento"
                      value={data?.nascimentoPaisId || null}
                      required
                      validationErrors={validationErrors}
                      name="nascimentoPaisId"
                      onChange={(e, obj) => {
                        const nascimentoPaisId = obj?.id || null
                        setData({ ...data, nascimentoPaisId })
                      }}
                    />
                  </Grid>

                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <AutoCompleteCidade
                      label="Naturalidade"
                      value={data?.naturalidadeCidadeId || null}
                      required
                      validationErrors={validationErrors}
                      name="naturalidadeCidadeId"
                      onChange={(e, obj) => {
                        const naturalidadeCidadeId = obj?.id || null
                        setData({
                          ...data,
                          naturalidadeCidadeId,
                        })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                    <AutoCompleteMemoryTable
                      table="racacor"
                      label="Cor"
                      value={data.racaCor || null}
                      required
                      validationErrors={validationErrors}
                      name="racaCor"
                      optionId="codigo"
                      onChange={(e, memoryTable) => {
                        const racaCor = memoryTable ? memoryTable.codigo : null
                        setData({ ...data, racaCor })
                      }}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <ContentDivider title="Endereço" />
                  </Grid>
                  <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                    <CEPTextField
                      name="cep"
                      label="CEP"
                      fullWidth
                      variant="outlined"
                      size="small"
                      required
                      validationErrors={validationErrors}
                      value={data?.cep || ''}
                      getAddress={
                        data?.id
                          ? undefined
                          : (address: FixLater) =>
                              setData((oldState) => ({
                                ...oldState,
                                ...address,
                              }))
                      }
                      onChange={(e: FixLater) => {
                        const cep = e?.target?.value?.replace(/\D/g, '')
                        setData({ ...data, cep })
                      }}
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                    <AutoCompleteLogradouro
                      name="logradouroId"
                      required
                      validationErrors={validationErrors}
                      value={data?.logradouroId || null}
                      optionId="id"
                      onChange={(e, logradouro) => {
                        const logradouroId = logradouro?.id || null
                        setData({ ...data, logradouroId })
                      }}
                    />
                  </Grid>
                  <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                    <TextField
                      name="endereco"
                      label="Endereço"
                      fullWidth
                      required
                      validationErrors={validationErrors}
                      value={data.endereco || ''}
                      size="small"
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        const endereco = e.target.value
                        setData({ ...data, endereco })
                      }}
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                    <TextField
                      label="Número"
                      fullWidth
                      value={data.numero || ''}
                      size="small"
                      onlyNumber
                      inputProps={{ maxLength: 10 }}
                      onChange={(e) => {
                        const numero = e.target.value
                        setData({ ...data, numero })
                      }}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={10} sm={12} xs={12}>
                    <TextField
                      name="bairro"
                      label="Bairro"
                      fullWidth
                      required
                      validationErrors={validationErrors}
                      value={data.bairro || ''}
                      size="small"
                      inputProps={{
                        maxLength: 80,
                      }}
                      onChange={(e) => {
                        const bairro = e.target.value
                        setData({ ...data, bairro })
                      }}
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <TextField
                      label="Complemento"
                      fullWidth
                      value={data.complemento || ''}
                      size="small"
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        const complemento = e.target.value
                        setData({ ...data, complemento })
                      }}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <AutoCompleteCidade
                      name="cidadeId"
                      required
                      validationErrors={validationErrors}
                      onChange={(e, cidade) => {
                        const cidadeId = cidade ? cidade.id : null
                        setData({ ...data, cidadeId })
                      }}
                      value={data?.cidadeId || null}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <TelefoneTextField
                      value={data?.telefone || ''}
                      onChange={(e, value) => {
                        const telefone = value
                        setData((oldState) => ({
                          ...oldState,
                          telefone,
                        }))
                      }}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <ContentDivider title="Informações adicionais" />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      label="Observação"
                      fullWidth
                      multiline
                      rows={4}
                      value={data.observacao || ''}
                      size="small"
                      inputProps={{
                        maxLength: 500,
                      }}
                      onChange={(e) => {
                        const observacao = e.target.value
                        setData({ ...data, observacao })
                      }}
                    />
                  </Grid>
                </Grid>
              </CollapseContent>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CollapseContent
                title="Documentos"
                isOpen={collapseItens.documentos}
                onClick={() => toggleCollapse('documentos')}
                top={1}
              >
                <Grid container spacing={2}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <ContentDivider title="Registro Geral" />
                  </Grid>

                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <TextField
                      label="RG"
                      fullWidth
                      value={data.rgNumero || ''}
                      size="small"
                      inputProps={{
                        maxLength: 14,
                      }}
                      onChange={(e) => {
                        const rgNumero = e.target.value
                        setData({ ...data, rgNumero })
                      }}
                    />
                  </Grid>

                  <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <TextField
                      label="Orgão Emissor RG"
                      fullWidth
                      value={data.rgOrgaoEmissor || ''}
                      size="small"
                      inputProps={{
                        maxLength: 20,
                      }}
                      onChange={(e) => {
                        const rgOrgaoEmissor = e.target.value
                        setData({ ...data, rgOrgaoEmissor })
                      }}
                    />
                  </Grid>

                  <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <DatePickerNew
                      label="Emissão RG"
                      size="small"
                      value={data.rgDtEmissao || null}
                      onChange={(date) => {
                        const rgDtEmissao = date
                        setData({ ...data, rgDtEmissao })
                      }}
                    />
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <ContentDivider title="CTPS" />
                  </Grid>

                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                    <TextField
                      name="ctpsNumero"
                      label="CTPS"
                      fullWidth
                      required
                      validationErrors={validationErrors}
                      value={data?.ctpsNumero || ''}
                      size="small"
                      onlyNumber
                      inputProps={{
                        maxLength: 10,
                      }}
                      onChange={(e) => {
                        const ctpsNumero = e?.target?.value || ''
                        setData({ ...data, ctpsNumero })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                    <TextField
                      name="ctpsSerie"
                      label="Série CTPS"
                      fullWidth
                      required
                      validationErrors={validationErrors}
                      value={data?.ctpsSerie || ''}
                      size="small"
                      onlyNumber
                      inputProps={{
                        maxLength: 5,
                      }}
                      onChange={(e) => {
                        const ctpsSerie = e?.target?.value || ''
                        setData({ ...data, ctpsSerie })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                    <DatePickerNew
                      label="Emissão CTPS"
                      size="small"
                      value={data.ctpsDtEmissao || null}
                      onChange={(date) => {
                        const ctpsDtEmissao = date
                        setData({ ...data, ctpsDtEmissao })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                    <UFSelect
                      label="UF CTPS"
                      value={data?.ctpsUf || ''}
                      onChange={(e: FixLater, obj: FixLater) => {
                        const ctpsUf = obj?.sigla || ''
                        setData({ ...data, ctpsUf })
                      }}
                    />
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <ContentDivider title="CNH" />
                  </Grid>

                  <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <TextField
                      label="CNH"
                      fullWidth
                      value={data.cnhNumero || ''}
                      size="small"
                      onlyNumber
                      inputProps={{
                        maxLength: 15,
                      }}
                      onChange={(e) => {
                        const cnhNumero = e.target.value
                        setData({ ...data, cnhNumero })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <TextField
                      label="Cat CNH"
                      fullWidth
                      value={data.cnhCategoria || ''}
                      size="small"
                      inputProps={{
                        maxLength: 2,
                      }}
                      onChange={(e) => {
                        const cnhCategoria = e.target.value
                        setData({ ...data, cnhCategoria })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <UFSelect
                      label="UF CNH"
                      value={data?.cnhUf || ''}
                      onChange={(e: FixLater, obj: FixLater) => {
                        const cnhUf = obj?.sigla || ''
                        setData({ ...data, cnhUf })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <TextField
                      label="Emissor CNH"
                      fullWidth
                      value={data.cnhOrgaoEmissor || ''}
                      size="small"
                      inputProps={{
                        maxLength: 20,
                      }}
                      onChange={(e) => {
                        const cnhOrgaoEmissor = e.target.value
                        setData({ ...data, cnhOrgaoEmissor })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <DatePickerNew
                      label="Emissão CNH"
                      size="small"
                      value={data.cnhDtEmissao || null}
                      onChange={(date) => {
                        // const cnhDtEmissao = e.target.value;
                        const cnhDtEmissao = date
                        setData({ ...data, cnhDtEmissao })
                      }}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <DatePickerNew
                      label="Validade CNH"
                      size="small"
                      value={data.cnhDtValidade || null}
                      onChange={(date) => {
                        // const cnhDtValidade = e.target.value;
                        const cnhDtValidade = date
                        setData({ ...data, cnhDtValidade })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <DatePickerNew
                      label="Emissão MOPP"
                      size="small"
                      value={data.moppDtEmissao || null}
                      onChange={(date) => {
                        const moppDtEmissao = date
                        setData({ ...data, moppDtEmissao })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <DatePickerNew
                      label="Vencimento MOPP"
                      size="small"
                      value={data.moppDtVencimento || null}
                      onChange={(date) => {
                        const moppDtVencimento = date
                        setData({ ...data, moppDtVencimento })
                      }}
                    />
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <ContentDivider title="Orgão de Classe" />
                  </Grid>

                  <Grid item xl={3} lg={4} md={12} sm={12} xs={12}>
                    <TextField
                      label="OC Habilitação"
                      size="small"
                      fullWidth
                      value={data.ocHabilitacao || ''}
                      inputProps={{
                        maxLength: 20,
                      }}
                      onChange={(e) => {
                        const ocHabilitacao = e.target.value
                        setData({ ...data, ocHabilitacao })
                      }}
                    />
                  </Grid>

                  <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <TextField
                      label="OC Registro"
                      fullWidth
                      value={data.ocRegistro || ''}
                      size="small"
                      inputProps={{
                        maxLength: 14,
                      }}
                      onChange={(e) => {
                        const ocRegistro = e.target.value
                        setData({ ...data, ocRegistro })
                      }}
                    />
                  </Grid>

                  <Grid item xl={2} lg={4} md={6} sm={12} xs={12}>
                    <TextField
                      label="OC Orgão Emissor"
                      fullWidth
                      value={data.ocOrgaoEmissor || ''}
                      size="small"
                      inputProps={{
                        maxLength: 20,
                      }}
                      onChange={(e) => {
                        const ocOrgaoEmissor = e.target.value
                        setData({ ...data, ocOrgaoEmissor })
                      }}
                    />
                  </Grid>

                  <Grid item xl={2} lg={6} md={6} sm={12} xs={12}>
                    <DatePickerNew
                      label="OC Dt. Expedição"
                      size="small"
                      value={data.ocDtExpedicao || null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(date) => {
                        const ocDtExpedicao = date
                        setData({ ...data, ocDtExpedicao })
                      }}
                    />
                  </Grid>

                  <Grid item xl={2} lg={6} md={6} sm={12} xs={12}>
                    <DatePickerNew
                      label="OC Dt. Validade"
                      size="small"
                      value={data.ocDtValidade || null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(date) => {
                        const ocDtValidade = date
                        setData({ ...data, ocDtValidade })
                      }}
                    />
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <ContentDivider title="PIS" />
                  </Grid>

                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                    <MaskedTextField
                      mask="999.99999-99-9"
                      label="PIS"
                      fullWidth
                      value={data?.pis || ''}
                      variant="outlined"
                      size="small"
                      onChange={(e: FixLater) => {
                        const pis = e?.target?.value?.replace(/\D/g, '') || ''
                        setData({ ...data, pis })
                      }}
                    />
                  </Grid>

                  <Grid xl={3} lg={3} md={6} sm={12} xs={12} item>
                    <DatePickerNew
                      label="Emissão PIS"
                      size="small"
                      value={data.pisDtEmissao || null}
                      onChange={(date) => {
                        // const pisDtEmissao = e.target.value;
                        const pisDtEmissao = date
                        setData({ ...data, pisDtEmissao })
                      }}
                    />
                  </Grid>
                </Grid>
              </CollapseContent>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CollapseContent
                title="Pessoa com Deficiência"
                isOpen={collapseItens.pcd}
                onClick={() => toggleCollapse('pcd')}
                top={1}
              >
                <Grid container spacing={2}>
                  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Checkbox
                      label="Deficiência Física"
                      value={data?.isDeficienciaFisica || false}
                      onChange={(e, value) => {
                        const isDeficienciaFisica = value
                        setData({ ...data, isDeficienciaFisica })
                      }}
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Checkbox
                      label="Deficiência Auditiva"
                      value={data?.isDeficienciaAuditiva || false}
                      onChange={(e, value) => {
                        const isDeficienciaAuditiva = value
                        setData({ ...data, isDeficienciaAuditiva })
                      }}
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Checkbox
                      label="Deficiência Visual"
                      value={data?.isDeficienciaVisual || false}
                      onChange={(e, value) => {
                        const isDeficienciaVisual = value
                        setData({ ...data, isDeficienciaVisual })
                      }}
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Checkbox
                      label="Deficiência Mental"
                      value={data?.isDeficienciaMental || false}
                      onChange={(e, value) => {
                        const isDeficienciaMental = value
                        setData({ ...data, isDeficienciaMental })
                      }}
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Checkbox
                      label="Deficiência Intelectual"
                      value={data?.isDeficienciaIntelectual || false}
                      onChange={(e, value) => {
                        const isDeficienciaIntelectual = value
                        setData({ ...data, isDeficienciaIntelectual })
                      }}
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Checkbox
                      label="Reabilitado"
                      value={data?.isDeficienciaReabilitado || false}
                      onChange={(e, value) => {
                        const isDeficienciaReabilitado = value
                        setData({ ...data, isDeficienciaReabilitado })
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Checkbox
                      label="Preenche Cota"
                      value={data?.isDeficienciaInfoCota || false}
                      onChange={(e, value) => {
                        const isDeficienciaInfoCota = value
                        setData({ ...data, isDeficienciaInfoCota })
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Observação"
                      fullWidth
                      value={data.deficienciaObservacao || ''}
                      size="small"
                      inputProps={{
                        maxLength: 250,
                      }}
                      onChange={(e) => {
                        const deficienciaObservacao = e.target.value
                        setData({ ...data, deficienciaObservacao })
                      }}
                    />
                  </Grid>
                </Grid>
              </CollapseContent>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CollapseContent
                title="Trabalho do Imigrante"
                isOpen={collapseItens.imigrante}
                onClick={() => toggleCollapse('imigrante')}
                top={1}
              >
                <Grid container spacing={2}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <MUIAutoComplete
                      label="Tempo Residente"
                      options={[
                        { value: '1', nome: 'Prazo determinado' },
                        { value: '2', nome: 'Prazo Inderteminado' },
                      ]}
                      optionId="value"
                      renderOption={(option) => `${option.nome}`}
                      value={data?.imigranteTempoResidencia || ''}
                      onChange={(e, obj) => {
                        const imigranteTempoResidencia = obj?.value || ''
                        setData((oldState) => ({
                          ...oldState,
                          imigranteTempoResidencia,
                          imigranteCondicaoTrabalhador: undefined,
                        }))
                      }}
                    />
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <MUIAutoComplete
                      label="Condição do Imigrante"
                      required
                      validationErrors={validationErrors}
                      name="imigranteCondicaoTrabalhador"
                      options={imigranteCondicaoTrabalhadorValues}
                      optionId="value"
                      renderOption={(option) => option.name}
                      value={data.imigranteCondicaoTrabalhador}
                      onChange={(e, obj) => {
                        const imigranteCondicaoTrabalhador = obj ? obj.value : ''
                        setData((oldState) => ({
                          ...oldState,
                          imigranteCondicaoTrabalhador,
                        }))
                      }}
                    />
                  </Grid>
                </Grid>
              </CollapseContent>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CollapseContent
                title="Outras Informações"
                isOpen={collapseItens.outras}
                onClick={() => toggleCollapse('outras')}
                top={1}
              >
                <Grid container spacing={2}>
                  <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                    <TextField
                      label="Chave PIX"
                      fullWidth
                      value={data.chavePIX || ''}
                      size="small"
                      inputProps={{
                        maxLength: 77,
                      }}
                      onChange={(e) => {
                        const chavePIX = e.target.value
                        setData({ ...data, chavePIX })
                      }}
                    />
                  </Grid>

                  <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                    <TextField
                      label="Email"
                      fullWidth
                      value={data.email || ''}
                      size="small"
                      inputProps={{
                        maxLength: 60,
                      }}
                      onChange={(e) => {
                        const email = e.target.value
                        setData({ ...data, email })
                      }}
                    />
                  </Grid>
                </Grid>
              </CollapseContent>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)}>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button variant="contained" onClick={handleValidate}>
          Salvar
        </Button>
      </Box>
    </Box>
  )
}
