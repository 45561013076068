import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ProcessoTrabalhistaTributos } from './dtos/ProcessoTrabalhistaTributos'

const query = '/ProcessoTrabalhistaTributos'

interface RequestProps {
  params: {
    processoTrabalhistaId: string
  }
}

export function useObterTodos({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ProcessoTrabalhistaTributos[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useObterTodosInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
