import { useState } from 'react'

import moment from 'moment'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'
import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'

import ListboxComponentVirtualized from '../ListboxComponentVirtualized'

export default function AutoCompleteRubricaESocial(props) {
  const { ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/RubricaEsocial')
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => `${option.id} - ${option.nome}`

  return (
    <MUIAutoComplete
      ListboxComponent={ListboxComponentVirtualized}
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label="Rúbrica eSocial"
      renderOptionComponent={(option) => (
        <AutoCompleteOptionDescription
          title={`${option.id} - ${option.nome}`}
          descriptions={[
            {
              description: `Início: ${
                option?.dtInicio ? moment(option.dtInicio).format('DD/MM/yyyy') : ''
              } - Término: ${option.dtFim ? moment(option.dtFim).format('DD/MM/yyyy') : ''}`,
            },
          ]}
        />
      )}
      {...rest}
    />
  )
}
