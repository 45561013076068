import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon, Print as PrintIcon } from '@material-ui/icons'
import moment from 'moment'

import { HeightDataTable } from '~/components'
import { ASOResultadoValues } from '~/values/ASOResultadoValues'
import { ASOTipoExameValues } from '~/values/ASOTipoExameValues'

const formatTipoASO = (tipoASO) => {
  const objectFinded = ASOTipoExameValues.find((obj) => obj.value === tipoASO)
  return objectFinded.name
}

const formatResultadoASO = (resultadoASO) => {
  const objectFinded = ASOResultadoValues.find((obj) => obj.value === resultadoASO)
  return objectFinded.name
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data?.map((item) => {
    return {
      ...item,
      tipoASO: formatTipoASO(item.tipoASO),
      dtASO: item?.dtASO ? moment(item.dtASO).format('DD/MM/YYYY') : null,
      resultadoASO: formatResultadoASO(item.resultadoASO),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'tipoASO',
        label: 'Tipo ASO',
      },
      {
        name: 'dtASO',
        label: 'Data ASO',
      },
      {
        name: 'resultadoASO',
        label: 'Resultado ASO',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Imprimir este registro"
                  color="primary"
                  aria-label="Imprimir"
                  onClick={() => {
                    onItemClick('print', value)
                  }}
                >
                  <PrintIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['tipoASO', 'dtASO', 'resultadoASO'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
