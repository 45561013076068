import React, { useCallback, useState, useEffect } from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { ActionDialog } from '~/components'

import FormulaComponent from './components/FormulaComponent'
import { IndUnidadeSalarioEnum } from '~/@types/enums/IndUnidadeSalarioEnum'

const useStyles = makeStyles((theme) => ({
  rootTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    overflow: 'auto',
  },
  titleLeft: {
    marginRight: theme.spacing(1),
    borderRight: theme.shape.border,
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '20%',
  },
  titleRight: {
    fontSize: '12pt',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: (props) => (props.isEvent ? '80%' : '100%'),
  },
  rootMessages: {
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    overflow: 'auto',
    marginTop: theme.spacing(1),
    height: '100px',
  },
  messageItem: {
    fontSize: '12pt',
    '&:hover': {
      backgroundColor: '#ffffde',
    },
  },
  rootEvent: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: '11pt',
    borderRadius: theme.shape.borderRadius,
    border: theme.shape.border,
  },
  rootTitleEvent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    overflow: 'auto',
  },
  titleLeftEvent: {
    fontWeight: 'bolder',
    borderRight: theme.shape.border,
    textAlign: 'center',
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '20%',
  },
  titleEvent: {
    fontSize: '13pt',
    color: theme.palette.success.light,
    cursor: 'pointer',
  },
  titleRightEvent: {
    fontSize: '11pt',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '80%',
  },
  eventMessages: {
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    overflow: 'auto',
    marginTop: theme.spacing(1),
    height: '100px',
  },
  messageItemError: {
    fontSize: '12pt',
    color: theme.palette.error.main,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function MemoriaCalculoTable(props) {
  const { isOpen, onClose, unidadeSalarioFixoVinculo, rpItem, memoriaCalculo, rpItens } = props

  const [memoriasCurrent, setMemoriasCurrent] = useState([])
  const [variavelCurrent, setVariavelCurrent] = useState(0)

  const [variavelEventCurrent, setVariavelEventCurrent] = useState(0)

  const classes = useStyles({
    isEvent: memoriasCurrent[0]?.isEvent ? true : false,
  })

  const getMemoriaEventoCurrent = useCallback(() => {
    let memoriaFinded = null
    memoriaCalculo.forEach((memoria) => {
      if ('E' + rpItem?.eventoEmpregador?.evento?.codigo === memoria.variavelAmbiente) {
        memoriaFinded = { ...memoria, isEvent: true }
      }
    })
    return memoriaFinded
  }, [memoriaCalculo, rpItem])

  const getMemoriasCalculo = useCallback(
    (formula, isEvent = true) => {
      const formulaSplited = formula.split(/(?=\[)(.*?\])/g)

      const testFormulaItem = new RegExp(/\[(.*?)\]/g)

      const variaveis = []
      formulaSplited.forEach((splitItem) => {
        if (testFormulaItem.test(splitItem))
          variaveis.push({
            variavelAmbiente: splitItem.replace('[', '').replace(']', ''),
          })
      })

      const variaveisWithMemoria = variaveis.map((variavel) => {
        let newVariavel = { ...variavel }
        memoriaCalculo.forEach((memoria) => {
          if (variavel.variavelAmbiente === memoria.variavelAmbiente)
            newVariavel = { ...memoria, ...variavel }
        })
        return newVariavel
      })

      const variaveisWithMemoriaWithEvent = variaveisWithMemoria.map((variavel) => {
        let newVariavel = { ...variavel }
        rpItens.forEach((rpItem_) => {
          const codigo = 'E' + rpItem_?.eventoEmpregador?.evento?.codigo
          if (variavel.variavelAmbiente === codigo) {
            let formulaCurrent = rpItem_?.eventoEmpregador?.evento?.formulaMensalista

            if (unidadeSalarioFixoVinculo === IndUnidadeSalarioEnum.Hora_1) {
              formulaCurrent = rpItem_?.eventoEmpregador?.evento?.formulaHorista
            }
            if (unidadeSalarioFixoVinculo === IndUnidadeSalarioEnum.Dia_2) {
              formulaCurrent = rpItem_?.eventoEmpregador?.evento?.formulaDiarista
            }

            const formula = formulaCurrent

            let memoriaCalculo = []

            if (formula) {
              memoriaCalculo = getMemoriasCalculo(formula, false)
            } else {
              console.warn(`Formula não encontrada para o código ${codigo}`)
            }

            newVariavel = {
              ...variavel,
              event: {
                formula: formulaCurrent,
                formulaCalc: rpItem_?.formulaCalc,
                memoriaCalculo,
              },
            }
          }
        })
        return newVariavel
      })

      const eventCurrent = getMemoriaEventoCurrent()
      if (eventCurrent && isEvent) return [eventCurrent, ...variaveisWithMemoriaWithEvent]

      return variaveisWithMemoriaWithEvent
    },
    [memoriaCalculo, rpItens, getMemoriaEventoCurrent, unidadeSalarioFixoVinculo],
  )

  let formulaCurrent = rpItem?.eventoEmpregador?.evento?.formulaMensalista || null

  if (unidadeSalarioFixoVinculo === IndUnidadeSalarioEnum.Hora_1) {
    formulaCurrent = rpItem?.eventoEmpregador?.evento?.formulaHorista || null
  }
  if (unidadeSalarioFixoVinculo === IndUnidadeSalarioEnum.Dia_2) {
    formulaCurrent = rpItem?.eventoEmpregador?.evento?.formulaDiarista || null
  }

  useEffect(() => {
    const formula = formulaCurrent
    if (!formula) return

    const memoriasCalculo = getMemoriasCalculo(formula)

    setVariavelCurrent(0)
    setMemoriasCurrent(memoriasCalculo)
  }, [formulaCurrent, getMemoriasCalculo, getMemoriaEventoCurrent])

  return (
    <ActionDialog
      title="Memória de Cálculo"
      subtitle={`${rpItem?.eventoEmpregador?.evento?.codigo} - ${rpItem?.eventoEmpregador?.evento?.nome}`}
      isOpen={isOpen}
      onClose={onClose}
      customActions={<></>}
    >
      <Box>
        <Box>
          <Box className={classes.rootTitle}>
            {memoriasCurrent[0]?.isEvent && (
              <Box className={classes.titleLeft}>
                <FormulaComponent
                  formula={`[${memoriasCurrent[0].variavelAmbiente}]`}
                  array={memoriasCurrent}
                  indexSelected={variavelCurrent}
                  fontSize="14pt"
                  onChange={setVariavelCurrent}
                />
              </Box>
            )}
            <Box className={classes.titleRight}>
              <Box>{rpItem.formulaCalc}</Box>
              <FormulaComponent
                formula={formulaCurrent || ''}
                array={memoriasCurrent}
                indexSelected={variavelCurrent}
                onChange={(index) => {
                  setVariavelCurrent(index)
                  setVariavelEventCurrent(0)
                }}
              />
            </Box>
          </Box>

          <Box className={classes.rootMessages}>
            {memoriasCurrent[variavelCurrent]?.mensagens?.length > 0 ? (
              memoriasCurrent[variavelCurrent]?.mensagens.map((message, index) => (
                <Box className={classes.messageItem} key={index}>
                  {message}
                </Box>
              ))
            ) : (
              <Box className={classes.messageItemError}>Variável sem Descrição</Box>
            )}
          </Box>
        </Box>

        {memoriasCurrent[variavelCurrent]?.event && (
          <Box className={classes.rootEvent}>
            <Box className={classes.rootTitleEvent}>
              <Box className={classes.titleLeftEvent}>
                <Box className={classes.titleEvent}>
                  {`[${memoriasCurrent[variavelCurrent].variavelAmbiente}]`}
                </Box>
              </Box>
              <Box className={classes.titleRightEvent}>
                <Box>{memoriasCurrent[variavelCurrent].event.formulaCalc}</Box>
                <FormulaComponent
                  formula={memoriasCurrent[variavelCurrent].event.formula || ''}
                  array={memoriasCurrent[variavelCurrent].event.memoriaCalculo}
                  indexSelected={variavelEventCurrent}
                  onChange={setVariavelEventCurrent}
                />
              </Box>
            </Box>
            <Box className={classes.eventMessages}>
              {memoriasCurrent[variavelCurrent]?.event?.memoriaCalculo[variavelEventCurrent]
                ?.mensagens?.length > 0 ? (
                memoriasCurrent[variavelCurrent].event.memoriaCalculo[
                  variavelEventCurrent
                ].mensagens.map((message, index) => (
                  <Box className={classes.messageItem} key={index}>
                    {message}
                  </Box>
                ))
              ) : (
                <Box className={classes.messageItemError}>Variável sem Descrição</Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </ActionDialog>
  )
}
