import React, { useMemo } from 'react'

import moment from 'moment'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { ToolsDataTable } from '~/components'

export default function Table(props) {
  const { data: _data, isLoading, query, rowsSelected, handleRowSelected } = props

  const data = _data.map((d) => ({
    ...d,
    nrInscricao: d?.nrInscricao ? formatToCPFOrCNPJ(d.nrInscricao) : '',
    dtAdmissao: d?.dtAdmissao ? moment(d.dtAdmissao).format('DD/MM/yyyy') : '',
    dtRescisao: d?.dtRescisao ? moment(d.dtRescisao).format('DD/MM/yyyy') : '',
  }))

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nrInscricao',
        label: 'CPF',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'dtAdmissao',
        label: 'Data de Admissão',
      },
      {
        name: 'dtRescisao',
        label: 'Data de Rescisão',
      },
    ],
    [],
  )

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      optionsSelectable={{
        selectOnClick: true,
        type: 'multiple',
        rowsSelected: rowsSelected,
        onRowSelected: handleRowSelected,
      }}
      sherlock={{
        columns: ['nome', 'CPF', 'codigo'],
        query,
      }}
    />
  )
}
