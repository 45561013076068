import React from 'react'

import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Close as CloseIcon, Warning as WarningIcon, Error as ErrorIcon } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import EmptyWarning from '~/components/EmptyWarning'

const useStyles = makeStyles((theme) => ({
  iconActiveWarning: {
    color: theme.palette.warning.main,
  },
  iconInactiveWarning: {
    color: 'inherit',
  },
  root: {
    width: '22rem',
    height: '25rem',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  rootHeader: {
    height: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.grey[700],
  },
  rootContent: {
    height: 'calc(100% - 4rem)',
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(1),
    overflow: 'auto',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  footer: {
    height: '2rem',
  },
}))

interface NotificationProps {
  notifications: string[]
  severity: 'warning' | 'error'
  onChange: (notifications: string[]) => void
}

export default function Notification(props: NotificationProps) {
  const { notifications, severity, onChange } = props

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'popover-notification' : undefined

  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseButton = () => {
    setAnchorEl(null)
  }

  function handleClickRemoveNotification(indexToRemove: number) {
    onChange(notifications.filter((_, index) => index !== indexToRemove))
  }

  function handleClickRemoveAllNotifications() {
    onChange([])
    handleCloseButton()
  }

  return (
    <Box>
      <IconButton size="small" onClick={handleClickButton}>
        <Badge
          badgeContent={notifications.length}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          color="primary"
        >
          {severity === 'warning' ? (
            <WarningIcon
              className={
                notifications.length > 0 ? classes.iconActiveWarning : classes.iconInactiveWarning
              }
            />
          ) : (
            <ErrorIcon color={notifications.length > 0 ? 'error' : 'inherit'} />
          )}
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseButton}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.root}>
          <Box className={classes.rootHeader}>
            <Typography className={classes.title}>Notificações</Typography>
            <IconButton size="small" onClick={handleCloseButton}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider />
          <Box className={classes.rootContent}>
            {notifications.map((notification, index) => (
              <Alert
                key={index}
                severity={severity}
                action={
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => handleClickRemoveNotification(index)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                {notification}
              </Alert>
            ))}
            {notifications.length === 0 && (
              <Box padding={4}>
                <EmptyWarning message="Nenhuma notificação no momento" />
              </Box>
            )}
          </Box>
          <Box className={classes.footer}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleClickRemoveAllNotifications}
            >
              Remover Notificações
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
