import React, { useMemo } from 'react'
import { ButtonBox } from 'mio-library-ui'
import { HeightDataTable } from '~/components'
import { useQuery } from '~/hooks/queries/useContratacaoTrabalhadorAvulso'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

export default function Table(props) {
  const { query, empregadorId, onItemClick } = props
  const { data, isLoading } = useQuery(empregadorId)

  const columns = useMemo(
    () => [
      {
        name: 'lotacao',
        label: 'Lotação',
        options: {
          customBodyRender: (value) => (value ? `${value.nome}` : ''),
        },
      },
      {
        name: 'anoMes',
        label: 'Ano/Mês',
        options: {
          customBodyRender: (value) => {
            return `${value?.substr(0, 4)}/${value?.substr(4)}`
          },
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    const dataFinded = data?.find((d) => d.id === value)
                    onItemClick('edit', dataFinded)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => onItemClick('delete', value)}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [data, onItemClick],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['lotacao.nome', 'anoMes'],
      }}
    />
  )
}
