import React, { useState, useEffect } from 'react'

import { ContentDivider } from 'mio-library-ui'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useUtils from '~/hooks/useUtils'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'

import _ from 'lodash'

const TabelaSF = () => {
  const [data, setData] = useState({
    isLoading: false,
    item: [],
  })
  const dialogNotification = useDialogNotification()

  const { formatMonth } = useUtils()
  const { anoMes } = useAmbiente(false, true)

  useEffect(() => {
    const getCollection = async () => {
      setData((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get('/tabelaSF')
        if (response.data.data) {
          const newArray = []
          response.data.data.forEach((item) => {
            if (item?.anoMes <= anoMes) {
              newArray.push(item)
            }
          })
          let newItem = {}

          newItem = _.orderBy(newArray, 'anoMes', 'desc')[0]

          if (!newItem) newItem = _.orderBy(response.data.data, 'anoMes', 'asc')[0]

          setData((oldState) => ({
            ...oldState,
            item: newItem,
            anoMes: newItem?.anoMes,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setData((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ContentDivider
        title={`${data?.anoMes?.substr(0, 4)} / ${formatMonth(data?.anoMes?.substr(4))}`}
        showDivider={false}
        bottom={1}
      />
      <Table data={[data.item]} isLoading={data.isLoading} />
    </>
  )
}

export default TabelaSF
