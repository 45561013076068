import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterPorVinculoInvalidate } from './useObterPorVinculo'

import { notifyPost } from '~/utils/notification'

import { VinculoSuspensaoCreateDTO } from './dtos/VinculoSuspensaoCreateDTO'

export function useCreateVinculoSuspensao() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterPorVinculoInvalidate()

  async function handleRequest(data: VinculoSuspensaoCreateDTO) {
    await api.post('/VinculoSuspensao', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
