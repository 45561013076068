import { useState } from 'react'

import * as yup from 'yup'
import { cnpj } from 'cpf-cnpj-validator'

import { Grid } from '@material-ui/core'

import { ActionDialog, TextField, NumeroInscricaoTextField, DatePickerNew } from '~/components'
import { AutoCompleteMedico } from '~/components/AutoComplete'

import { useSubmit } from '~/hooks/queries/useVinculoExameToxicologico'
import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoExameToxicologico } from '~/hooks/queries/VinculoExameToxicologico/dtos/VinculoExameToxicologico'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const schema = yup.object().shape({
  dtExame: yup.date().required('Informe a Data do Exame').typeError('Informe uma Data válida'),
  codigoExame: yup
    .string()
    .required('Informe o Código do Exame')
    .min(11, 'Informe o Código do Exame com 11 caracteres'),
  nrInscricaoLaboratorio: yup
    .string()
    .required('Informe o CNPJ do Laboratório')
    .test('test', 'Informe o CNPJ do Laboratório válido', (value: FixLater) => cnpj.isValid(value)),
  medicoId: yup.string().required('Informe o Médico'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoExameToxicologico
}

export default function Form({ isOpen, onClose, data: _data }: FormProps) {
  const [data, setData] = useState<VinculoExameToxicologico>(_data)

  const isNovoRegistro = _data?.id ? false : true

  const { mutateAsync, isLoading } = useSubmit()

  async function handleSubmit() {
    await mutateAsync(data as FixLater)
    onClose()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Exame Toxicológico"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePickerNew
            title="Data da realização do exame toxicológico."
            label="Data do Exame"
            value={data.dtExame || null}
            onChange={(date) => {
              const dtExame = date || ''
              setData((oldState) => ({
                ...oldState,
                dtExame,
              }))
            }}
            validationErrors={validationErrors}
            required
            name="dtExame"
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePickerNew
            name="dtVencimento"
            label="Data do Vencimento"
            value={data.dtVencimento || null}
            onChange={(date) => {
              const dtVencimento = date || ''
              setData((oldState) => ({
                ...oldState,
                dtVencimento,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <NumeroInscricaoTextField
            title="CNPJ do laboratório responsável pela realização do exame."
            label="CNPJ do Laboratório"
            typeMask={TipoInscricaoEnum.CNPJ_1}
            value={data.nrInscricaoLaboratorio || ''}
            onChange={(e) => {
              const nrInscricaoLaboratorio = e.target.value.replace(/\D/g, '') || ''
              setData((oldState) => ({
                ...oldState,
                nrInscricaoLaboratorio,
              }))
            }}
            validationErrors={validationErrors}
            name="nrInscricaoLaboratorio"
            required
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Código do Exame"
            inputProps={{ maxLength: 11 }}
            value={data.codigoExame || ''}
            onChange={(e) => {
              const codigoExame = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                codigoExame,
              }))
            }}
            validationErrors={validationErrors}
            name="codigoExame"
            required
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteMedico
            value={data.medicoId}
            onChange={(e, medico) => {
              const medicoId = medico?.id || ''
              setData((oldState) => ({
                ...oldState,
                medicoId,
              }))
            }}
            validationErrors={validationErrors}
            name="medicoId"
            required
          />
        </Grid>

        {!isNovoRegistro && (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField label="Recibo eSocial" value={data.reciboEsocial || ''} disabled />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <DatePickerNew
                label="Data Recibo eSocial"
                value={data.dtReciboEsocial || null}
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
    </ActionDialog>
  )
}
