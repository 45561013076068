import { Typography } from '@material-ui/core'
import Stack from '../Stack'

interface LabelDescriptionProps {
  label: string
  description: string
}

export default function LabelDescription({ label, description }: LabelDescriptionProps) {
  return (
    <Stack orientation="horizontal">
      <Typography
        component={'span'}
        variant={'body2'}
        style={{
          fontWeight: 'bolder',
          fontSize: '12pt',
          color: '#666',
        }}
      >
        {label}
      </Typography>

      <Typography
        component={'span'}
        variant={'body2'}
        style={{
          fontWeight: 'bolder',
          fontSize: '12pt',
          color: '#2E8BC0',
        }}
      >
        {description}
      </Typography>
    </Stack>
  )
}
