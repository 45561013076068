import React, { useEffect, useState } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { PageHeader, Button } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './Table'
import Form from './Form'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useStepperContext } from '~/components/StepperForm'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

import { MainDataForm } from '..'
import { CondicaoAmbienteTrabalhoResponsavelPartial } from '~/hooks/queries/CondicaoAmbienteTrabalhoResponsavel/CondicaoAmbienteTrabalhoResponsavel'

const HEADER_HEIGHT = '50px'

export default function TableResponsaveis() {
  const { mainData, onBack, onNext } = useStepperContext<MainDataForm>()

  const [collection, setCollection] = useState(mainData.dataTableResponsaveis)

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<{ data: CondicaoAmbienteTrabalhoResponsavelPartial; indexCurrent: number } | null>(
    null,
  )

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<number | null>(null)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    if (!(collection.length > 0)) {
      handleClickAddItem()
    }
    // eslint-disable-next-line
  }, [])

  const handleClickItem = (event: 'delete', index: number) => {
    const handleClickDeleteItem = () => {
      openConfirmDelete(index)
    }

    const functions = {
      delete: handleClickDeleteItem,
    }
    functions[event]()
  }

  const handleDeleteItem = () => {
    const newItens = collection.filter((i, indexCurrent) => indexCurrent !== dataConfirmDelete)
    setCollection(newItens)
    closeConfirmDelete()
    notification.remove()
  }

  function handleClickAddItem() {
    openForm({
      data: {
        condicaoAmbienteTrabalhoId: mainData.dataMainForm.id,
      },
      indexCurrent: -1,
    })
  }

  const handleAfterSubmitForm = (
    event: 'insert' | 'update',
    data: CondicaoAmbienteTrabalhoResponsavelPartial,
    index: number,
  ) => {
    const handleAfterInsert = () => {
      const newItens = [data, ...collection]
      setCollection(newItens)
      closeForm()
    }

    const handleAfterUpdate = () => {
      const newItens = collection.map((i, indexCurrent) => (indexCurrent === index ? data : i))
      setCollection(newItens)
      closeForm()
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    functions[event]()
  }

  function handleValidate() {
    if (!(collection.length > 0))
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um Responsável'],
      })
    onNext({
      ...mainData,
      dataTableResponsaveis: collection,
    })
  }

  return (
    <Box>
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Responsáveis">
          <ButtonBox top={1}>
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={collection} onItemClick={handleClickItem} />
      </Box>

      {dataForm && isOpenForm ? (
        <Form
          indexCurrent={dataForm.indexCurrent}
          isOpen={isOpenForm}
          data={dataForm.data}
          onClose={closeForm}
          onAfterSubmitForm={handleAfterSubmitForm}
        />
      ) : (
        <></>
      )}

      {isOpenConfirmDelete ? (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      ) : (
        <></>
      )}

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Box>
  )
}
