import React, { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, LinearProgress } from '@material-ui/core'
import { Button, TextField, ButtonBox } from 'mio-library-ui'

import { DatePicker } from '~/components'
import {
  AutoCompleteEstabelecimento,
  AutoCompletePessoaFisica,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import { getVinculoById, useVinculoMutate } from '~/hooks/queries/useVinculo'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { CategoriaEnum, CategoriaValues } from '~/@types/enums/CategoriaEnum'

const schema = yup.object().shape({
  estabelecimentoId: yup.string().required('Informe o Estabelecimento'),
  pessoaFisicaId: yup.string().required('Informe a Pessoa Física'),
  dtAdmissao: yup
    .date()
    .required('Informe a Data de Admissão')
    .typeError('Informe uma data válida')
    .nullable(),
  categoria: yup.string().required('Informe a Categoria'),
  matricula: yup.string().required('Informe a Matrícula'),
})

export default function Form(props) {
  const { funcionarioId, onClose } = props

  const { estabelecimento } = useAmbiente()

  const [data, setData] = useState({
    categoria: CategoriaEnum.Empregado_Geral_101,
    estabelecimento: estabelecimento,
    estabelecimentoId: estabelecimento?.id,
  })
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()

  const { mutateCreateVinculoSST, mutateUpdateVinculo } = useVinculoMutate()
  const notification = useNotification()
  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })

  useEffect(() => {
    async function getFuncionario() {
      if (!funcionarioId) return
      setLoading(true)
      try {
        const funcionario = await getVinculoById(funcionarioId)
        setData(funcionario)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getFuncionario()
    // eslint-disable-next-line
  }, [funcionarioId])

  async function handleSubmit() {
    delete data.estabelecimento
    delete data.pessoaFisica

    data.matricula = data.matricula.trim()

    if (data?.id) {
      await mutateUpdateVinculo.mutateAsync(data)
      notification.put()
    } else {
      await mutateCreateVinculoSST.mutateAsync(data)
      notification.post()
    }
    onClose()
  }

  if (isLoading) return <LinearProgress />

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteEstabelecimento
            disabled={data?.id ? true : false}
            required
            autoFocus
            validationErrors={validationErrors}
            name="estabelecimentoId"
            value={data.estabelecimento || null}
            onChange={(e, estabelecimento) => {
              const estabelecimentoId = estabelecimento?.id || ''
              setData((oldState) => ({
                ...oldState,
                estabelecimentoId,
                estabelecimento,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompletePessoaFisica
            disabled={data?.id ? true : false}
            required
            validationErrors={validationErrors}
            name="pessoaFisicaId"
            value={data?.pessoaFisica || null}
            onChange={(e, pessoaFisica) => {
              const pessoaFisicaId = pessoaFisica?.id || ''
              setData((oldState) => ({
                ...oldState,
                pessoaFisicaId,
                pessoaFisica,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Admissão"
            size="small"
            disabled={data?.id ? true : false}
            value={data?.dtAdmissao || null}
            required
            validationErrors={validationErrors}
            name="dtAdmissao"
            onChange={(date) => {
              const dtAdmissao = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtAdmissao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data de Desligamento"
            size="small"
            value={data?.dtRescisao || null}
            disabled={data?.id ? false : true}
            onChange={(date) => {
              const dtRescisao = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtRescisao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Categoria"
            options={CategoriaValues}
            optionId="value"
            disabled={data?.id ? true : false}
            renderOption={(option) => option.name}
            required
            name="categoria"
            validationErrors={validationErrors}
            value={data?.categoria}
            onChange={(e, obj) => {
              const categoria = obj?.value || ''
              setData((oldState) => ({
                ...oldState,
                categoria,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Matrícula"
            fullWidth
            value={data?.matricula || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 30,
            }}
            validationErrors={validationErrors}
            name="matricula"
            onChange={(e) => {
              const matricula = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                matricula,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ButtonBox>
            <Button onClick={onClose} size="small" variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button
              isLoading={mutateCreateVinculoSST.isLoading || mutateUpdateVinculo.isLoading}
              onClick={handleValidate}
              size="small"
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </ButtonBox>
        </Grid>
      </Grid>
    </Box>
  )
}
