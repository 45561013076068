import { useEffect } from 'react'

import { formatSimpleDateTime } from '~/utils/utils'

import { Badge, Box, IconButton, useTheme } from '@material-ui/core'
import { Replay, Warning } from '@material-ui/icons'

import { Stack, ToolsDataTable } from '~/components'

import IconTask from '../IconTask'
import DialogProblemas from './DialogProblemas'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useObterProcessamentoEmpregador } from '~/hooks/queries/FolhaAutomatica/useObterProcessamentoEmpregador'
import { useReprocessarTarefa } from '~/hooks/queries/FolhaAutomatica/useReprocessarTarefa'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { TaskQueueStatusEnum } from '~/@types/enums/TaskQueueStatusEnum'
import { HubConnection } from '@microsoft/signalr'

interface TableTasksProps {
  empregadorId: string
  socket: HubConnection | null
}

export default function TableTasks({ empregadorId, socket }: TableTasksProps) {
  const theme = useTheme()
  const { anoMes } = useAmbiente()
  const {
    close: closeDialogProblemas,
    data: dataDialogProblemas,
    isOpen: isOpenDialogProblemas,
    open: openDialogProblemas,
  } = useDialog<string[]>([])

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterProcessamentoEmpregador({
    params: {
      empregadorId,
      anoMes,
    },
  })
  const data = _d || []

  useEffect(() => {
    if (!socket) return
    socket.on('UpdateTask', () => refetch())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  const { mutateAsync, isLoading: isSubmitting } = useReprocessarTarefa()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'iconeTarefa',
      label: 'Indicador',
      options: {
        sort: false,
        customBodyRender: (_, { rowIndex }) => {
          const dtCurrent = data.at(rowIndex)
          if (!dtCurrent) return <div>Problema com a tarefa</div>
          return (
            <Stack alignItems="center">
              <IconTask
                status={dtCurrent?.status}
                icone={dtCurrent.iconeTarefa}
                descricao={dtCurrent.descricaoTarefa}
              />
            </Stack>
          )
        },
        setCellProps: () => ({
          style: { width: '1px' },
        }),
      },
    },
    {
      name: 'descricaoStatus',
      label: 'Status',
      options: {
        customBodyRender: (value) => <Stack px={1}>{value}</Stack>,
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'descricaoTarefa',
      label: 'Tarefa',
      options: {
        setCellProps: () => ({
          style: { whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'createdAt',
      label: 'Criação',
      options: {
        customBodyRender: (value) => <Stack px={1}>{formatSimpleDateTime(value)}</Stack>,
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'dtAgendamento',
      label: 'Agendamento',
      options: {
        customBodyRender: (value) => <Stack px={1}>{formatSimpleDateTime(value)}</Stack>,
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'updatedAt',
      label: 'Atualização',
      options: {
        customBodyRender: (value) => <Stack px={1}>{formatSimpleDateTime(value)}</Stack>,
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'errors',
      label: 'Problemas',
      options: {
        customBodyRender: (value) => (
          <Stack alignItems="center">
            <Badge badgeContent={value.length} color="error">
              <IconButton
                size="small"
                onClick={() => openDialogProblemas(value)}
                disabled={value.length > 0 ? false : true}
              >
                <Warning
                  fontSize="small"
                  style={{ color: value.length > 0 ? theme.palette.warning.main : undefined }}
                />
              </IconButton>
            </Badge>
          </Stack>
        ),
        setCellProps: () => ({
          style: { width: '1px', whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      name: 'id',
      label: 'Reprocessar',
      options: {
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return <div>Problema com a tarefa.</div>
          return (
            <Stack alignItems="center">
              <IconButton
                size="small"
                disabled={
                  dtCurrent.status === TaskQueueStatusEnum.Erro ||
                  dtCurrent.status === TaskQueueStatusEnum.Concluido
                    ? isSubmitting
                      ? true
                      : false
                    : true
                }
                onClick={async () =>
                  dtCurrent.id &&
                  (await mutateAsync({
                    params: {
                      taskQueueId: dtCurrent.id,
                    },
                  }))
                }
              >
                <Replay fontSize="small" />
              </IconButton>
            </Stack>
          )
        },
        setCellProps: () => ({
          style: { width: '1px' },
        }),
      },
    },
  ]

  return (
    <Box>
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching || isSubmitting}
        disableAutoHeight
        pagination={false}
      />
      {isOpenDialogProblemas && (
        <DialogProblemas
          isOpen={isOpenDialogProblemas}
          onClose={closeDialogProblemas}
          data={dataDialogProblemas}
        />
      )}
    </Box>
  )
}
