import { useState, useEffect, useCallback } from 'react'

import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { ActionDialog, DatePicker, SimpleAlert } from '~/components'

import { CheckList } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'

const HEIGHT_TITLE = '32px'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(1),
  },
  subtitle: {
    paddingBottom: theme.spacing(1),
    fontWeight: 500,
    color: '#666',
    height: HEIGHT_TITLE,
  },
  heightPaper: {
    height: '70%',
  },
}))

const Form = (props) => {
  const { isOpen, onClose, onAfterSubmitForm } = props
  const [isSubmitting, setSubmitting] = useState(false)

  const [dataInicioValidadeESocial, setDataInicioValidadeESocial] = useState(null)

  const [eventos, setEventos] = useState([])
  const [eventosChecked, setEventosChecked] = useState([])
  const [isLoadingEventos, setLoadingEventos] = useState(false)

  const [empregadores, setEmpregadores] = useState([])
  const [empregadoresChecked, setEmpregadoresChecked] = useState([])
  const [isLoadingEmpregadores, setLoadingEmpregadores] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  const resetFields = useCallback(() => {
    setEventos([])
    setEventosChecked([])
    setEmpregadores([])
    setEmpregadoresChecked([])
    setDataInicioValidadeESocial(null)
  }, [])

  useEffect(() => {
    const getCollectionEventos = async () => {
      if (!isOpen) return
      resetFields()
      setLoadingEventos(true)
      try {
        const response = await api.get('/Evento')
        setEventos(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingEventos(false)
      }
    }
    getCollectionEventos()
    // eslint-disable-next-line
  }, [isOpen, resetFields])

  useEffect(() => {
    const getCollectionEmpregadores = async () => {
      if (!isOpen) return
      resetFields()
      setLoadingEmpregadores(true)
      try {
        const response = await api.get('/EmpregadorConsulta/GetEmpregadoresAtivos')
        setEmpregadores(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingEmpregadores(false)
      }
    }
    getCollectionEmpregadores()
    // eslint-disable-next-line
  }, [isOpen, resetFields])

  function handleValidate() {
    try {
      if (dataInicioValidadeESocial == null || !dataInicioValidadeESocial)
        throw new Error('Informe data de início da validação eSocial.')
      if (eventosChecked?.length === 0) throw new Error('Selecione pelo menos um Evento')
      if (empregadoresChecked?.length === 0) throw new Error('Selecione pelo menos um Empregador')

      dialogNotification.info({
        descriptions: [
          `Eventos a serem configurados: ${eventosChecked.length}`,
          `Empregadores a serem configurados: ${empregadoresChecked.length}`,
          `Confirme para prosseguir`,
        ],
        onConfirm: () => handleSubmit(),
        labelOnConfirm: 'Confirmar',
      })
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  async function handleSubmit() {
    setSubmitting(true)
    try {
      const eventosId = eventosChecked.map((evento) => evento.id)
      const empregadoresId = empregadoresChecked.map((empregador) => empregador.id)
      await api.post('/EventoEmpregador/RelacionarEventoComEmpregador', {
        dataInicioValidadeESocial,
        eventosId,
        empregadoresId,
      })
      notification.post()
      onAfterSubmitForm('insert')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Evento Empregador"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <Box className={classes.root}>
        <Box>
          <DatePicker
            label="Data início da validade eSocial"
            value={dataInicioValidadeESocial || null}
            onChange={(date) => {
              const newDate = date ? date.format('yyyy-MM-DD') : null
              setDataInicioValidadeESocial(newDate)
            }}
          />
        </Box>

        <Box flex={1}>
          <Grid
            container
            spacing={1}
            style={{
              height: '100%',
            }}
          >
            <Grid item sm={6} xs={12}>
              <Typography align="center" className={classes.subtitle}>
                Eventos
              </Typography>
              <CheckList
                options={eventos}
                onChange={setEventosChecked}
                renderPrimaryOption={(option) => `${option?.codigo} - ${option?.nome}`}
                isLoading={isLoadingEventos}
                searchBy={['codigo', 'nome']}
                optionsCheckeds={eventosChecked}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Typography align="center" className={classes.subtitle}>
                Empregadores
              </Typography>
              <CheckList
                options={empregadores}
                onChange={setEmpregadoresChecked}
                renderPrimaryOption={(option) => `${option?.codigo} - ${option?.nome}`}
                isLoading={isLoadingEmpregadores}
                searchBy={['codigo', 'nome']}
                optionsCheckeds={empregadoresChecked}
              />
            </Grid>
          </Grid>
        </Box>

        <Box height={40}>
          <SimpleAlert
            severity="info"
            show={eventosChecked.length > 0 || empregadoresChecked.length > 0}
          >
            <div>
              Foram selecionados <b>{eventosChecked.length} Eventos</b> que serão configurados para{' '}
              <b>{empregadoresChecked.length} Empregadores</b>
            </div>
          </SimpleAlert>
        </Box>
      </Box>
    </ActionDialog>
  )
}

export default Form
