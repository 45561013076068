import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, useTheme, Grid, IconButton, Checkbox, Paper } from '@material-ui/core'
import {
  DiaHorarioEnum,
  DiaHorarioValuesSimple,
  DiaHorarioValues,
} from '~/@types/enums/DiaHorarioEnum'

import DayOfWeek from './DayOfWeek'

import { Button, CurrencyTextField, Fieldset } from '~/components'
import { AutoCompleteValeTransporte } from '~/components/AutoComplete'

import useVinculoValeTransporteCreate from '~/hooks/queries/VinculoValeTransporte/useVinculoValeTransporteCreate'
import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoValeTransporteCreateDTO } from '~/hooks/queries/VinculoValeTransporte/VinculoValeTransporteDTO'

const schema = yup.object().shape({
  quantidadeVales: yup
    .number()
    .required('Informe a Quantidade de Vales')
    .min(1, 'Informe a Quantidade de Vales'),
  valeTransporteId: yup.string().required('Informe a Transportadora'),
})

const primeirosDiasSemana = DiaHorarioValuesSimple.slice(0, DiaHorarioValuesSimple.length / 2)
const ultimosDiasSemana = DiaHorarioValuesSimple.slice(
  DiaHorarioValuesSimple.length / 2,
  DiaHorarioValuesSimple.length,
)

const initialWeekDaysSelected = [
  DiaHorarioEnum.SegundaFeira_1,
  DiaHorarioEnum.Terça_Feira_2,
  DiaHorarioEnum.Quarta_Feira_3,
  DiaHorarioEnum.Quinta_Feira_4,
  DiaHorarioEnum.Sexta_Feira_5,
]

const initialDataForm = {
  valeTransporteId: '',
  quantidadeVales: 0,
}

interface FormProps {
  vinculosId: string[]
  isUsarHorarioTrabalho: boolean
}

export default function Form({ vinculosId, isUsarHorarioTrabalho }: FormProps) {
  const [weekDaysSelected, setWeekDaysSelected] =
    useState<DiaHorarioEnum[]>(initialWeekDaysSelected)
  const [dataForm, setDataForm] = useState(initialDataForm)

  const theme = useTheme()

  const { mutateAsync: mutateAsyncCreate, isLoading: isSubmitting } =
    useVinculoValeTransporteCreate()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleClickAdd,
    data: dataForm,
  })

  useEffect(() => {
    setWeekDaysSelected(initialWeekDaysSelected)
    setDataForm(initialDataForm)
  }, [vinculosId])

  async function handleClickAdd() {
    const { valeTransporteId, quantidadeVales } = dataForm

    let data: VinculoValeTransporteCreateDTO[] = []

    if (isUsarHorarioTrabalho) {
      vinculosId.forEach((vinculoId) => {
        data.push({
          vinculoId,
          valeTransporteId,
          quantidadeVales,
          diaSemana: DiaHorarioEnum.SegundaFeira_1,
        })
      })
    } else {
      vinculosId.forEach((vinculoId) => {
        data = [
          ...data,
          ...weekDaysSelected.map<VinculoValeTransporteCreateDTO>((diaSemana) => ({
            vinculoId,
            valeTransporteId,
            quantidadeVales,
            diaSemana,
          })),
        ]
      })
    }

    await mutateAsyncCreate({
      data,
      isUsarHorarioTrabalho,
    })
  }

  function handleSelect(day: DiaHorarioEnum) {
    setWeekDaysSelected((prev) => {
      if (prev.some((x) => x === day)) {
        return prev.filter((x) => x !== day)
      } else {
        return [...prev, day]
      }
    })
  }

  function handleClickSelectAll() {
    setWeekDaysSelected((prev) => {
      if (prev.length > 0) {
        return []
      } else {
        return DiaHorarioValuesSimple.map((d) => d.value)
      }
    })
  }

  return (
    <Box component={Paper} p={1} display="flex" gridGap={theme.spacing(1)} flexDirection="column">
      <Fieldset disabled={isUsarHorarioTrabalho}>
        <Box display="flex" gridGap={theme.spacing(0.5)} alignItems="center">
          <IconButton size="small" color="primary" onClick={handleClickSelectAll}>
            <Checkbox
              size="small"
              color="default"
              checked={weekDaysSelected.length === DiaHorarioValuesSimple.length}
              indeterminate={
                weekDaysSelected.length > 0 &&
                weekDaysSelected.length !== DiaHorarioValuesSimple.length
              }
            />
          </IconButton>
          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <Box display="flex" overflow="auto">
                {primeirosDiasSemana.map((d) => (
                  <DayOfWeek
                    key={d.value}
                    title={d.name}
                    description={DiaHorarioValues.find((x) => x.value == d.value)?.name || ''}
                    onSelect={() => handleSelect(d.value)}
                    isSelected={weekDaysSelected.includes(d.value)}
                  />
                ))}
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <Box display="flex" overflow="auto">
                {ultimosDiasSemana.map((d) => (
                  <DayOfWeek
                    key={d.value}
                    title={d.name}
                    description={DiaHorarioValues.find((x) => x.value == d.value)?.name || ''}
                    onSelect={() => handleSelect(d.value)}
                    isSelected={weekDaysSelected.includes(d.value)}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Fieldset>
      <Box display="flex" gridGap={theme.spacing(1)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <CurrencyTextField
              label="Vales"
              value={dataForm.quantidadeVales || 0}
              validationErrors={validationErrors}
              decimalPlaces={0}
              decimalPlacesShownOnFocus={0}
              name="quantidadeVales"
              onChange={(_, number) => {
                const quantidadeVales = number || 0
                setDataForm((prev) => ({ ...prev, quantidadeVales }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <AutoCompleteValeTransporte
              value={dataForm.valeTransporteId}
              validationErrors={validationErrors}
              name="valeTransporteId"
              onChange={(_, obj) => {
                const valeTransporteId = obj?.id || ''
                setDataForm((prev) => ({ ...prev, valeTransporteId }))
              }}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            variant="contained"
            onClick={handleValidate}
            isLoading={isSubmitting}
            disabled={
              vinculosId.length === 0
                ? true
                : weekDaysSelected.length > 0 || isUsarHorarioTrabalho
                ? false
                : true
            }
          >
            Adicionar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
