import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useQuery } from 'react-query'

const query = '/LotacaoSituacao/VerificarExisteProcessosEmpregador/'

export default function useVerificarExisteProcessosEmpregador(empregadorId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: boolean }>(query + empregadorId)
    return response.data.data
  }

  return useQuery([query, empregadorId], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
