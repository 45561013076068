import { useState, useEffect, useCallback } from 'react'

import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { TextField, ContentDivider } from 'mio-library-ui'

import { MUIAutoComplete, AutoCompleteRubricaESocial } from '~/components/AutoComplete'
import { ContainerForm, CurrencyTextField } from '~/components'
import DialogMessageEvento from './components/DialogEventoEmpregador'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import { indEventoValues, indEventoConsts } from '~/values/indEventoValues'
import {
  eventoIndReferenciaConsts,
  eventoIndReferenciaValues,
} from '~/values/eventoIndReferenciaValues'
import { indClassficacaoEventoValues } from '~/values/indClassficacaoEventoValues'
import { IndBaseDSRValues } from '~/@types/enums/IndBaseDSREnum'
import { indTRCTValues } from '~/values/indTRCTValues'
import { CodigoIncidenciaCPValues } from '~/@types/enums/CodigoIncidenciaCPEnum'
import {
  CodigoIncidenciaIRCategorias,
  CodigoIncidenciaIRValues,
} from '~/@types/enums/CodigoIncidenciaIREnum'
import { CodigoIncidenciaFGValues } from '~/@types/enums/CodigoIncidenciaFGEnum'
import _ from 'lodash'
import { EventoIndCalculoEnum, EventoIndCalculoValues } from '~/@types/enums/EventoIndCalculoEnum'

const labelsFieldsWatch = {
  nome: 'Nome',
  rubricaId: 'Rúbrica eSocial',
  indEvento: 'Indicador Evento',
  codigoIncidenciaCP: 'Código Incidência CP',
  codigoIncidenciaFG: 'Código Incidência FG',
  codigoIncidenciaIR: 'Código Incidência IR',
}

const fieldsToWatch = [
  'nome',
  'rubricaId',
  'indEvento',
  'codigoIncidenciaCP',
  'codigoIncidenciaFG',
  'codigoIncidenciaIR',
]

const { nao_se_aplica } = eventoIndReferenciaConsts

function extractDataFieldsToWatch(data) {
  const values = Object.values(data)
  const keys = Object.keys(data)

  const newData = {}

  keys.forEach((keyData, index) => {
    if (fieldsToWatch.includes(keyData)) {
      newData[keyData] = values[index]
    }
  })

  return newData
}

const schema = yup.object().shape({
  nome: yup.string().required(`Informe o ${labelsFieldsWatch['nome']}`),
  indEvento: yup.string().required(`Informe o ${labelsFieldsWatch['indEvento']}`),
  rubricaId: yup.string().required(`Informe a ${labelsFieldsWatch['rubricaId']}`),
  indCalculo: yup.string().required('Informe o Indicador Cálculo'),
  indReferencia: yup.string().required('Informe o Indicador Referência'),
  indBaseDSR: yup.string().required('Informe o Indicador Base DSR'),
  indClassificacaoEvento: yup.string().required('Informe a Classificação Evento'),
  codigoIncidenciaCP: yup.string().required('Informe o Código de Incidência CP'),
  codigoIncidenciaIR: yup.string().required('Informe o Código de Incidência IR'),
  codigoIncidenciaFG: yup.string().required('Informe o Código de Incidência FG'),
})

const Form = (props) => {
  const { data: _data, onAfterSubmitForm, onClose, setWasModified } = props

  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const {
    isOpen: isOpenDialogMessageEvento,
    open: openDialogMessageEvento,
    close: closeDialogMessageEvento,
    data: dataDialogMessageEvento,
  } = useDialog([])

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setWasModified(JSON.stringify(data) !== JSON.stringify(_data))
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(
    (data) => {
      const update = async (updateData) => {
        setSubmitting(true)
        try {
          const response = await api.put(`/Evento/${updateData.id}`, updateData)
          onAfterSubmitForm('update', response.data.data)
          notification.put()
        } catch (err) {
          dialogNotification.extractErrors(err)
        } finally {
          setSubmitting(false)
        }
      }

      function insert(insertData) {
        if (insertData?.isExclusivoFerias) {
          return dialogNotification.info({
            descriptions: [
              'O evento será configurado exclusivamente para as férias, e essa ação não poderá ser desfeita.',
            ],
            onConfirm: () => insertSubmit(insertData),
            labelOnConfirm: 'Confirmar',
          })
        }
        return insertSubmit(insertData)
      }

      const insertSubmit = async (insertData) => {
        setSubmitting(true)
        try {
          const response = await api.post('/Evento', insertData)
          notification.post()
          onAfterSubmitForm('insert', response.data.data)
        } catch (err) {
          dialogNotification.extractErrors(err)
        } finally {
          setSubmitting(false)
        }
      }

      if (data.id) {
        update(data)
        return
      }
      insert(data)
    },
    //eslint-disable-next-line
    [onAfterSubmitForm],
  )

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleOpenDialogEventoEmpregador,
    data,
  })

  function deepCompareValues(valueA, valueB) {
    let valueAKeys = Object.keys(valueA)
    let areDiff = valueAKeys.filter((key) => !_.isEqual(valueA[key], valueB[key]))

    return areDiff
  }

  function handleOpenDialogEventoEmpregador() {
    if (!data?.id) return handleSubmit(data)

    const oldData = extractDataFieldsToWatch(_data)

    const newData = extractDataFieldsToWatch(data)

    const compareValuesResults = deepCompareValues(oldData, newData)

    const hasDifferentValues = compareValuesResults?.length > 0

    if (!hasDifferentValues) return handleSubmit(data)

    if (hasDifferentValues) {
      const fieldsFormateds = compareValuesResults.map(
        (field) => labelsFieldsWatch[field] || 'Campo',
      )
      openDialogMessageEvento(fieldsFormateds)
    }
  }

  return (
    <>
      <ContainerForm handleSubmit={handleValidate} isLoading={isSubmitting} onCancel={onClose}>
        <Grid container spacing={2}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <ContentDivider top={2} />
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={3} xs={12}>
            <TextField
              label="Código"
              fullWidth
              value={data.codigo || ''}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>

          <Grid item xl={5} lg={5} md={10} sm={9} xs={12}>
            <TextField
              label={labelsFieldsWatch['nome']}
              fullWidth
              value={data.nome || ''}
              variant="outlined"
              size="small"
              name="nome"
              autoFocus
              validationErrors={validationErrors}
              onChange={(e) => {
                const nome = e.target.value
                setData({ ...data, nome })
              }}
              required
            />
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              label="Descrição"
              fullWidth
              value={data.descricao || ''}
              variant="outlined"
              size="small"
              onChange={(e) => {
                const descricao = e.target.value
                setData({ ...data, descricao })
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <MUIAutoComplete
              label={labelsFieldsWatch['indEvento']}
              options={indEventoValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.indEvento}
              required
              name="indEvento"
              validationErrors={validationErrors}
              onChange={(e, obj) => {
                const indEvento = obj ? obj.value : ''
                setData({ ...data, indEvento })
              }}
            />
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
            <AutoCompleteRubricaESocial
              name="rubricaId"
              onChange={(e, rubrica) => {
                const rubricaId = rubrica?.id || ''
                setData({ ...data, rubricaId, rubrica })
              }}
              required
              value={data?.rubricaId || null}
              validationErrors={validationErrors}
              optionId="id"
            />
          </Grid>

          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <MUIAutoComplete
              label="Indicador Cálculo"
              options={EventoIndCalculoValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.indCalculo}
              required
              name="indCalculo"
              validationErrors={validationErrors}
              onChange={(e, obj) => {
                const indCalculo = obj ? obj.value : ''
                if (indCalculo === EventoIndCalculoEnum.Logico) {
                  setData((oldState) => ({
                    ...oldState,
                    indCalculo,
                    indReferencia: nao_se_aplica,
                  }))
                  return
                }
                setData((oldState) => ({ ...oldState, indCalculo }))
              }}
            />
          </Grid>

          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <MUIAutoComplete
              label="Indicador Referência"
              options={eventoIndReferenciaValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.indReferencia}
              required
              disabled={data.indCalculo === EventoIndCalculoEnum.Logico ? true : false}
              name="indReferencia"
              validationErrors={validationErrors}
              onChange={(e, obj) => {
                const indReferencia = obj ? obj.value : ''
                setData({ ...data, indReferencia })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <MUIAutoComplete
              label="Indicador Base DSR"
              required
              validationErrors={validationErrors}
              name="indBaseDSR"
              options={IndBaseDSRValues}
              renderOption={(option) => option.name}
              optionId="value"
              value={data.indBaseDSR}
              onChange={(e, obj) => {
                const indBaseDSR = obj ? obj.value : ''
                setData({ ...data, indBaseDSR })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <MUIAutoComplete
              label="TRCT"
              options={indTRCTValues}
              value={data?.indTRCT || ''}
              renderOption={(option) => option.name}
              optionId="value"
              onChange={(e, obj) => {
                const indTRCT = obj?.value || ''
                setData((oldState) => ({ ...oldState, indTRCT }))
              }}
            />
          </Grid>

          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <CurrencyTextField
              label="Fator"
              value={data.fator || ''}
              maximumValue="1000"
              onChange={(e, value) => {
                const fator = value || ''
                setData({ ...data, fator })
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <MUIAutoComplete
              label="Classificação Evento"
              required
              validationErrors={validationErrors}
              name="indClassificacaoEvento"
              options={indClassficacaoEventoValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.indClassificacaoEvento}
              onChange={(e, obj) => {
                const indClassificacaoEvento = obj ? obj.value : ''
                setData((oldState) => ({
                  ...oldState,
                  indClassificacaoEvento,
                }))
              }}
            />
          </Grid>

          <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
            <TextField
              label="Observação"
              fullWidth
              value={data.observacao || ''}
              variant="outlined"
              inputProps={{
                maxLength: 200,
              }}
              size="small"
              onChange={(e) => {
                const observacao = e.target.value
                setData({ ...data, observacao })
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <MUIAutoComplete
              required
              validationErrors={validationErrors}
              name="codigoIncidenciaCP"
              label={labelsFieldsWatch['codigoIncidenciaCP']}
              options={CodigoIncidenciaCPValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.codigoIncidenciaCP}
              onChange={(_, obj) => {
                const codigoIncidenciaCP = obj ? obj.value : ''
                setData((oldState) => ({
                  ...oldState,
                  codigoIncidenciaCP,
                }))
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <MUIAutoComplete
              required
              validationErrors={validationErrors}
              name="codigoIncidenciaIR"
              label={labelsFieldsWatch['codigoIncidenciaIR']}
              options={CodigoIncidenciaIRValues}
              groupBy={(option) => CodigoIncidenciaIRCategorias[option.categoria]}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.codigoIncidenciaIR}
              onChange={(_, obj) => {
                const codigoIncidenciaIR = obj ? obj.value : ''
                setData((oldState) => ({
                  ...oldState,
                  codigoIncidenciaIR,
                }))
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <MUIAutoComplete
              required
              validationErrors={validationErrors}
              name="codigoIncidenciaFG"
              label={labelsFieldsWatch['codigoIncidenciaFG']}
              options={CodigoIncidenciaFGValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.codigoIncidenciaFG}
              onChange={(_, obj) => {
                const codigoIncidenciaFG = obj ? obj.value : ''
                setData((oldState) => ({
                  ...oldState,
                  codigoIncidenciaFG,
                }))
              }}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <FormControlLabel
              label="Base Pensão Alimentícia"
              control={
                <Checkbox
                  size="small"
                  name="Base Pensão Alimentícia"
                  color="secondary"
                  checked={data?.isBasePensaoAlimenticia || false}
                  onChange={(e) => {
                    const isBasePensaoAlimenticia = e.target.checked
                    setData({ ...data, isBasePensaoAlimenticia })
                  }}
                />
              }
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <FormControlLabel
              label="Vencimento Variável"
              control={
                <Checkbox
                  size="small"
                  name="Vencimento Variável"
                  color="secondary"
                  checked={data?.isVencimentoVariavel || false}
                  onChange={(e) => {
                    const isVencimentoVariavel = e.target.checked
                    setData({ ...data, isVencimentoVariavel })
                  }}
                />
              }
            />
          </Grid>

          {data.indEvento === indEventoConsts.informativo_3 ||
          data.indEvento === indEventoConsts.informativoDedutora_4 ? (
            <>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <FormControlLabel
                  label="Imprimir Evento Informativo nos Recibos"
                  control={
                    <Checkbox
                      size="small"
                      name="Imprimir Evento Informativo nos Recibos"
                      color="secondary"
                      checked={data?.isPrintInformativa || false}
                      onChange={(e) => {
                        const isPrintInformativa = e.target.checked
                        setData({ ...data, isPrintInformativa })
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <FormControlLabel
                  label="Imprimir Valores nos Eventos Informativos"
                  control={
                    <Checkbox
                      size="small"
                      name="Imprimir Valores nos Eventos Informativos"
                      color="secondary"
                      checked={data?.isPrintValorInformativa || false}
                      onChange={(e) => {
                        const isPrintValorInformativa = e.target.checked
                        setData({ ...data, isPrintValorInformativa })
                      }}
                    />
                  }
                />
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <FormControlLabel
              label="Evento exclusivo para férias"
              disabled={data?.id ? true : false}
              control={
                <Checkbox
                  size="small"
                  name="Evento exclusivo para férias"
                  color="secondary"
                  checked={data?.isExclusivoFerias || false}
                  onChange={(e) => {
                    const isExclusivoFerias = e.target.checked
                    setData({ ...data, isExclusivoFerias })
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </ContainerForm>

      {isOpenDialogMessageEvento && (
        <DialogMessageEvento
          onChange={(dt) => handleSubmit({ ...data, ...dt })}
          isOpen={isOpenDialogMessageEvento}
          onClose={closeDialogMessageEvento}
          fields={dataDialogMessageEvento}
        />
      )}
    </>
  )
}

export default Form
