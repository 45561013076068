import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoConsulta } from './VinculoConsulta'

const query = '/VinculoConsulta/ObterVinculosParaRetificar'

export function useObterVinculosParaRetificar(estabelecimentoId: string, anoMes: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoConsulta[] }>(query, {
      params: {
        estabelecimentoId,
        anoMes,
      },
    })
    return response.data.data
  }

  return useQuery([query, estabelecimentoId, anoMes], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
