import React, { useState } from 'react'

import PropType from 'prop-types'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'

export default function AutoCompleteEPI(props) {
  const { label, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/EPI')
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => `${option.certificadoAvaliacao} - ${option.descricao}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label={label}
      {...rest}
    />
  )
}

AutoCompleteEPI.defaultProps = {
  label: 'EPI',
}

AutoCompleteEPI.propTypes = {
  getOptionDisabled: PropType.func,
  renderOption: PropType.func,
  onChange: PropType.func,
  options: PropType.array,
  value: PropType.any,
  optionId: PropType.string,
  required: PropType.bool,
  validationErrors: PropType.any,
  name: PropType.string,
  label: PropType.string,
  disabled: PropType.bool,
}
