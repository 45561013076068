import { useState } from 'react'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'

import { CompetenciaLogDTO } from '~/hooks/queries/Competencia/CompetenciaLogDTO'

interface DialogProblemsProps {
  isOpen: boolean
  onClose: () => void
  data: CompetenciaLogDTO[]
}

export default function DialogProblems({ isOpen, onClose, data }: DialogProblemsProps) {
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([0])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'identificador',
      label: 'Identificador',
    },
  ]

  function renderExpandableRow(index: number) {
    const dtRow = data[index]
    const dataCurrent = dtRow.mensagens.map((d) => ({ message: d }))
    return (
      <ToolsDataTable
        columns={[
          {
            name: 'message',
            label: 'Mensagem',
          },
        ]}
        data={dataCurrent}
        pagination={false}
        disableAutoHeight
        showHeader={false}
      />
    )
  }

  return (
    <ActionDialog
      title="Problemas"
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox bottom={1} right={1} top={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
      }}
    >
      <ToolsDataTable
        columns={columns}
        data={data}
        optionsExpandable={{
          type: 'single',
          expandedChildren: renderExpandableRow,
          onRowExpanded: setRowsExpanded,
          rowsExpanded,
          expandOnClick: true,
        }}
        pagination={false}
        disableAutoHeight
      />
    </ActionDialog>
  )
}
