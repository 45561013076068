import React from 'react'

import { ContentDivider, LabelValue } from 'mio-library-ui'
import { makeStyles, Box, Grid } from '@material-ui/core'

import { ContentFrame } from '~/components'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '61px',
    overflow: 'auto',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  content: {
    margin: 'auto',
    width: '99%',
  },
  colorValue: {
    color: '#2E8BC0',
    fontWeight: 'bold',
  },
  fieldObservacao: {
    paddingTop: '5px',
  },
}))

const PeriodoAquisitivo = (props) => {
  const { periodoAquisitivo } = props

  const classes = useStyles()

  return (
    <ContentFrame
      padding={1}
      headerRenderer={<ContentDivider title="Período Aquisitivo" showDivider={false} />}
    >
      <Box className={classes.root}>
        <Grid container className={classes.content}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <LabelValue
              label="Início do Período"
              value={
                <Box className={classes.colorValue}>
                  {periodoAquisitivo?.dtPeriodoInicio
                    ? moment(periodoAquisitivo.dtPeriodoInicio).format('DD/MM/YYYY')
                    : null}
                </Box>
              }
              showSeparator={false}
              direction="horizontal"
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <LabelValue
              label="Fim do Período"
              value={
                <Box className={classes.colorValue}>
                  {periodoAquisitivo?.dtPeriodoFim
                    ? moment(periodoAquisitivo.dtPeriodoFim).format('DD/MM/YYYY')
                    : null}
                </Box>
              }
              showSeparator={false}
              direction="horizontal"
              justifyContent="center"
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <LabelValue
              label="Data Limite"
              value={
                <Box className={classes.colorValue}>
                  {periodoAquisitivo?.dtLimiteConcessao
                    ? moment(periodoAquisitivo.dtLimiteConcessao).format('DD/MM/YYYY')
                    : null}
                </Box>
              }
              showSeparator={false}
              direction="horizontal"
              justifyContent="right"
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.fieldObservacao}>
            <LabelValue
              label="Observação"
              value={<Box className={classes.colorValue}>{periodoAquisitivo?.observacao}</Box>}
              showSeparator={false}
              direction="vertical"
              alignItems="center"
            />
          </Grid>
        </Grid>
      </Box>
    </ContentFrame>
  )
}

export default PeriodoAquisitivo
