import { useEffect, useState } from 'react'

import { Box, CircularProgress, LinearProgress } from '@material-ui/core'
import { Button, Stack } from '~/components'

import RowConta from '~/components/RowConta'

import { useObterLotacaoConfigPorMovimentoTipo } from '~/hooks/queries/ExportacaoLancamentoConfig/useObterLotacaoConfigPorMovimentoTipo'
import { useAtualizarMovimentoConfig } from '~/hooks/queries/ExportacaoLancamentoConfig/useAtualizarMovimentoConfig'

import { FPMovimentoTipoEnum } from '~/@types/enums/FPMovimentoTipoEnum'
import { EventoConfig } from '~/hooks/queries/ExportacaoLancamentoConfig/dtos/EventoConfig'

interface TabLotacaoConfigProps {
  lotacaoConfigId: string
  mascaraConta: string
  tipo: FPMovimentoTipoEnum
}

export default function TabLotacaoConfig({
  lotacaoConfigId,
  mascaraConta,
  tipo,
}: TabLotacaoConfigProps) {
  const [data, setData] = useState<EventoConfig[]>([])

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterLotacaoConfigPorMovimentoTipo({
    params: {
      lotacaoConfigId,
      tipo,
    },
  })
  const { mutateAsync, isLoading: isSubmitting } = useAtualizarMovimentoConfig()

  useEffect(() => {
    setData(_d?.eventos || [])
  }, [_d])

  function handleCancel() {
    setData(_d?.eventos || [])
  }

  async function handleSave() {
    if (_d) {
      await mutateAsync({
        data: {
          ..._d,
          eventos: data,
        },
        params: {
          id: _d.id,
        },
      })
    }
  }

  return (
    <Stack height="100%" position="relative" p={0} px={2}>
      <Box height={4}>{isFetching && <LinearProgress />}</Box>

      <Stack flex={1} overflow="auto">
        {isLoading ? (
          <Box
            position="absolute"
            right={0}
            left={0}
            top={0}
            bottom={0}
            zIndex={999}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={80} />
          </Box>
        ) : (
          data.map((d) => (
            <RowConta
              key={d.eventoId}
              label={`Evento: ${d.eventoCodigo} - ${d.eventoNome} ... Indicador: ${d.eventoIndicador}`}
              mask={mascaraConta}
              valueDebito={d.total.normalDebito || ''}
              onChangeDebito={(value) => {
                const normalDebito = value
                setData((prev) =>
                  prev.map((x) =>
                    x.eventoId === d.eventoId ? { ...x, total: { ...x.total, normalDebito } } : x,
                  ),
                )
              }}
              valueCredito={d.total.normalCredito || ''}
              onChangeCredito={(value) => {
                const normalCredito = value
                setData((prev) =>
                  prev.map((x) =>
                    x.eventoId === d.eventoId ? { ...x, total: { ...x.total, normalCredito } } : x,
                  ),
                )
              }}
              valueIgnorar={d.total.ignorar}
              onChangeIgnorar={(value) => {
                const ignorar = value
                setData((prev) =>
                  prev.map((x) =>
                    x.eventoId === d.eventoId ? { ...x, total: { ...x.total, ignorar } } : x,
                  ),
                )
              }}
            />
          ))
        )}
      </Stack>

      <Stack orientation="horizontal" justifyContent="flex-end">
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button variant="contained" onClick={handleSave} isLoading={isSubmitting}>
          Salvar
        </Button>
      </Stack>
    </Stack>
  )
}
