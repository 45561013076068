export enum CodigoIncidenciaCPEnum {
  NaoIncide_00 = 0,
  NaoIncideDecorrenciaAcordo_01 = 1,

  BaseCalculo_Mensal_11 = 11,
  BaseCalculo_DecimoTerceiro_12 = 12,
  BaseCalculo_ExclusivaEmpregadorMensal_13 = 13,
  BaseCalculo_ExclusivaEmpregadorDecimoTerceiro_14 = 14,
  BaseCalculo_ExclusivaSeguradoMensal_15 = 15,
  BaseCalculo_ExclusivaSeguradoDecimoTerceiro_16 = 16,
  BaseCalculo_SalarioMterniadadeMensal_21 = 21,
  BaseCalculo_SalarioMterniadadeDecimoTerceiro_22 = 22,
  BaseCalculo_SalarioMaternidadePagoPeloINSS_25 = 25,
  BaseCalculo_SalárioMaternidadeDtSalarioPagoPeloINSS_26 = 26,

  ContribuicaoDescontada_Mensal_31 = 31,
  ContribuicaoDescontada_DtSalrio_32 = 32,
  ContribuicaoDescontada_SEST_34 = 34,
  BaseCalculo_SENAT_Outros_35 = 35,

  Salario_Familia_51 = 51,

  SuspensaoIncidencia_Mensal_91 = 91,
  SuspensaoIncidencia_DtSalario_92 = 92,
  SuspensaoIncidencia_SalárioMaternidade_93 = 93,
  SuspensaoIncidencia_SalárioMaternidadeDtSalario_94 = 94,
  SuspensaoIncidencia_ExclusivaEmpregador_Mensal_95 = 95,
  SuspensaoIncidencia_ExclusivaEmpregador_DtSalario_96 = 96,
  SuspensaoIncidencia_ExclusivaEmpregador_SalarioMaternidade_97 = 97,
  SuspensaoIncidencia_ExclusivaEmpregador_SalarioMaternidadeDtSalario_98 = 98,
}

export const CodigoIncidenciaCPValues = [
  { value: CodigoIncidenciaCPEnum.NaoIncide_00, name: '0 - Não é base de cálculo', categoria: 0 },
  {
    value: CodigoIncidenciaCPEnum.NaoIncideDecorrenciaAcordo_01,
    name: '01 - Não é base de cálculo em função de acordos internacionais de previdência social',
    categoria: 0,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_Mensal_11,
    name: '11 - Base de Cálculo - Mensal',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_DecimoTerceiro_12,
    name: '12 - Base de Cálculo - 13º salário',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_ExclusivaEmpregadorMensal_13,
    name: '13 - Exclusiva do empregador - Mensal',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_ExclusivaEmpregadorDecimoTerceiro_14,
    name: '14 - Exclusiva do empregador - 13° salário',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_ExclusivaSeguradoMensal_15,
    name: '15 - Exclusiva do segurado - Mensal',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_ExclusivaSeguradoDecimoTerceiro_16,
    name: '16 - Exclusiva do segurado - 13° salário',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_SalarioMterniadadeMensal_21,
    name: '21 - Salário-maternidade mensal, pago pelo empregador',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_SalarioMterniadadeDecimoTerceiro_22,
    name: '22 - Salário-maternidade 13º salário, pago pelo empregador',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_SalarioMaternidadePagoPeloINSS_25,
    name: '25 - Salário-maternidade mensal, pago pelo INSS',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.BaseCalculo_SalárioMaternidadeDtSalarioPagoPeloINSS_26,
    name: '26 - Salário-maternidade 13° salário, pago pelo INSS',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaCPEnum.ContribuicaoDescontada_Mensal_31,
    name: '31 - Contribuição Descontada - Mensal',
    categoria: 2,
  },
  {
    value: CodigoIncidenciaCPEnum.ContribuicaoDescontada_DtSalrio_32,
    name: '32 - Contribuição Descontada - 13º salário',
    categoria: 2,
  },
  { value: CodigoIncidenciaCPEnum.ContribuicaoDescontada_SEST_34, name: '34 - SEST', categoria: 2 },
  { value: CodigoIncidenciaCPEnum.BaseCalculo_SENAT_Outros_35, name: '35 - SENAT', categoria: 2 },
  {
    value: CodigoIncidenciaCPEnum.Salario_Familia_51,
    name: '51 - Salário-família',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaCPEnum.SuspensaoIncidencia_Mensal_91,
    name: '91 - Suspensão Incidência - Mensal',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaCPEnum.SuspensaoIncidencia_DtSalario_92,
    name: '92 - Suspensão Incidência - 13º salário',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaCPEnum.SuspensaoIncidencia_SalárioMaternidade_93,
    name: '93 - Salário-maternidade',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaCPEnum.SuspensaoIncidencia_SalárioMaternidadeDtSalario_94,
    name: '94 - Salário-maternidade 13º salário',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaCPEnum.SuspensaoIncidencia_ExclusivaEmpregador_Mensal_95,
    name: '95 - Exclusiva do empregador - Mensal',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaCPEnum.SuspensaoIncidencia_ExclusivaEmpregador_DtSalario_96,
    name: '96 - Exclusiva do empregador - 13º salário',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaCPEnum.SuspensaoIncidencia_ExclusivaEmpregador_SalarioMaternidade_97,
    name: '97 - Exclusiva do empregador - Salário-maternidade',
    categoria: 4,
  },
  {
    value:
      CodigoIncidenciaCPEnum.SuspensaoIncidencia_ExclusivaEmpregador_SalarioMaternidadeDtSalario_98,
    name: '98 - Exclusiva do empregador - Salário-maternidade 13º salário',
    categoria: 4,
  },
]

export const CodigoIncidenciaCPCategorias = {
  0: 'Código de incidência tributária da rubrica para a Previdência Social',
  1: 'Base de cálculo das contribuições sociais - Salário de contribuição',
  2: 'Contribuição descontada do segurado sobre salário de contribuição',
  3: 'Outros',
  4: 'Suspensão de incidência sobre salário de contribuição em decorrência de decisão judicial',
}
