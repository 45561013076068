import { useMutation, useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'

async function getAll() {
  const response = await api.get('/PessoaFisicaConsulta')
  return response?.data?.data || []
}

export async function getPessoaFisicaById(id) {
  const response = await api.get(`/PessoaFisica/${id}`)
  return response?.data?.data || {}
}

async function remove(id) {
  await api.delete(`/PessoaFisica/${id}`)
}

async function createPessoaFisicaSST(dt) {
  await api.post('/PessoaFisica/CriarPessoaFisicaSST', dt)
}

async function updatePessoaFisica(dt) {
  await api.put(`/PessoaFisica/${dt.id}`, dt)
}

export function usePessoaFisica() {
  const dialogNotification = useDialogNotification()

  return useQuery('PessoaFisica', getAll, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function usePessoaFisicaMutate() {
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  const mutateDelete = useMutation(remove, {
    onSuccess: async () => await queryClient.invalidateQueries('PessoaFisica'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateCreatePessoaFisicaSST = useMutation(createPessoaFisicaSST, {
    onSuccess: async () => await queryClient.invalidateQueries('PessoaFisica'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateUpdatePessoaFisica = useMutation(updatePessoaFisica, {
    onSuccess: async () => await queryClient.invalidateQueries('PessoaFisica'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  return {
    mutateDelete,
    mutateCreatePessoaFisicaSST,
    mutateUpdatePessoaFisica,
  }
}
