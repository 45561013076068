import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { DataTable, ButtonBox } from 'mio-library-ui'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { formatToCNPJ, formatToCPF } from 'brazilian-values'

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data.map((item) => {
    return {
      ...item,
      nrInscricao:
        item?.nrInscricao &&
        (item.nrInscricao.length >= 14
          ? formatToCNPJ(item.nrInscricao)
          : formatToCPF(item.nrInscricao)),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
      {
        name: 'numeroCRC',
        label: 'CRC',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  size="small"
                  title="Excluir este registro"
                  color="primary"
                  aria-label="Excluir"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <DataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['nome', 'nrInscricao', 'numeroCRC'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
