import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, useTheme } from '@material-ui/core'

import { Button, Finder, PageHeader } from '~/components'

import HeaderForm from './HeaderForm'
import Table from './Table'
import TableForm from './TableForm'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import useConfigurarAntecipacao from '~/hooks/queries/AntecipacaoSalarial/useConfigurarAntecipacao'
import useObterFuncionarios, {
  query as queryObterFuncionarios,
} from '~/hooks/queries/AntecipacaoSalarial/useObterFuncionarios'

import { AntecipacaoSalarial as AntecipacaoSalarialEntity } from '~/hooks/queries/AntecipacaoSalarial/AntecipacaoSalarial'

export default function AntecipacaoSalarial() {
  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [query, setQuery] = useState('')

  const { estabelecimento } = useAmbiente()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const {
    close: closeTableForm,
    data: dataTableForm,
    isOpen: isOpenTableForm,
    open: openTableForm,
  } = useDialog<AntecipacaoSalarialEntity | null>(null)

  const { mutateAsync: configurarAntecipacao, isLoading: isSubmitting } = useConfigurarAntecipacao()
  const { data: _collection, isLoading, isFetching } = useObterFuncionarios(estabelecimento.id)
  const collection = _collection || []

  useEffect(() => {
    if (_collection) {
      setRowsSelected([])
    }
  }, [_collection])

  async function handleCancel() {
    await queryClient.invalidateQueries(queryObterFuncionarios)
  }

  async function handleSubmit() {
    await configurarAntecipacao(
      collection.map((d) => ({
        vinculoId: d.vinculoId,
        anoMesInicio: d.anoMesInicio,
        anoMesFim: d.anoMesFim,
        percentual: d.percentual,
      })),
    )
  }

  function onEditItem(id: string) {
    const antecipacaoFinded = collection.find((d) => d.antecipacaoId === id)
    if (antecipacaoFinded) {
      openTableForm({
        id,
        vinculoId: antecipacaoFinded.vinculoId,
        anoMesInicio: antecipacaoFinded.anoMesInicio as string,
        anoMesFim: antecipacaoFinded.anoMesFim,
        percentual: antecipacaoFinded.percentual as number,
      })
    }
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" gridGap={theme.spacing(1)} padding={2}>
      <PageHeader title="Antecipação Salarial">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <HeaderForm rowsSelected={rowsSelected} />

      <Box minHeight={200} flex={1} overflow="auto" position="relative">
        <Box width="100%" position="absolute">
          <Table
            data={collection}
            isLoading={isLoading}
            isFetching={isFetching}
            query={query}
            rowsSelected={rowsSelected}
            onRowSelected={(indexs) => setRowsSelected(indexs)}
            onEditItem={onEditItem}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)}>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleSubmit} isLoading={isSubmitting} variant="contained">
          Salvar Configurações
        </Button>
      </Box>

      {dataTableForm && (
        <TableForm isOpen={isOpenTableForm} onClose={closeTableForm} data={dataTableForm} />
      )}
    </Box>
  )
}
