const menusSST = [
  {
    label: 'Painel',
    link: '/painel',
    icon: 'dashboard',
  },
  {
    label: 'Cadastros',
    icon: 'storage',
    itens: [
      {
        label: 'Pessoa Física',
        link: '/pessoa-fisica-sst',
      },
      {
        label: 'Empregador',
        link: '/empregador-sst',
      },
      {
        label: 'Estabelecimento',
        link: '/estabelecimento-sst',
      },
      {
        label: 'Cargo',
        link: '/cargo',
      },
      {
        label: 'Setor',
        link: '/setor',
      },
      {
        label: 'Médico/Responsável',
        link: '/medico-responsavel',
      },
      {
        label: 'Certificado Digital',
        link: '/certificado-digital',
      },
    ],
  },
  {
    label: 'Funcionários',
    icon: 'people',
    itens: [
      {
        label: 'Cadastro',
        link: '/funcionario-sst',
      },
      {
        label: 'Ambiente de Trabalho Funcionário',
        link: '/local-trabalho-sst',
      },
      {
        label: 'Transferência de Funcionário',
        link: '/transferencia-funcionario',
      },
      {
        label: 'Cancelar Transferência',
        link: '/cancelar-transferencia-funcionario',
      },
    ],
  },
  {
    label: 'eSocial',
    icon: 'description',
    itens: [
      {
        label: 'Transmissão eSocial',
        link: '/transmissao-esocial/0',
      },
      {
        label: 'Exclusão de Eventos',
        link: '/exclusao-eventos-esocial-sst',
      },
      {
        label: 'Envio de Eventos Automáticos',
        link: '/envio-eventos-automaticos-sst',
      },
    ],
  },
  {
    label: 'SST',
    icon: 'how_to_reg',
    itens: [
      {
        label: 'S-2210 CAT',
        link: '/comunicado-acidente-trabalho',
      },
      {
        label: 'S-2220 ASO',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Atestado Saúde Ocupacional',
            link: '/atestado-saude-ocupacional',
          },
          {
            label: 'ASO Importação',
            link: '/aso-importacao',
          },
        ],
      },
      {
        label: 'S-2240 Ambiente de Trabalho',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Ambiente de Trabalho',
            link: '/condicao-ambiente-trabalho',
          },
          {
            label: 'Ambiente de Trabalho Importação',
            link: '/condicao-ambiente-trabalho-importacao',
          },
        ],
      },
      {
        label: 'Exame Toxicológico',
        link: '/exame-toxicologico',
      },
      {
        label: 'Consultar/Gerar Evento S-2240',
        link: '/condicao-ambiente-trabalho-automatico',
      },
      {
        label: 'EPI',
        link: '/equipamento-protecao-individual',
      },
      {
        label: 'Responsável Registro Ambiental',
        link: '/responsavel-registro-ambiental',
      },
      {
        label: 'Cargo Agente Nocivo',
        link: '/cargo-agente-nocivo',
      },
      {
        label: 'Setor Agente Nocivo',
        link: '/setor-agente-nocivo',
      },
      {
        label: 'Relatórios',
        showCollapseIcon: true,
        itens: [
          {
            label: 'Resumo S-2240',
            link: '/condicao-ambiente-trabalho-resumo',
          },
        ],
      },
    ],
  },
  {
    label: 'Configurações',
    icon: 'settings',
    itens: [
      {
        label: 'Parâmetros do Sistema',
        link: '/parametros-sistema',
      },
      {
        label: 'Relatório',
        link: '/configuracao-relatorio',
      },
      {
        label: 'Lotes por Empregador',
        link: '/lotes-empregador',
      },
      {
        label: 'Auditoria por Empregador',
        link: '/auditoria-empregador',
      },
      {
        label: 'Limpar ou Gerar Ambiente eSocial',
        link: '/limpar-gerar-ambiente',
      },
    ],
  },
]

export default menusSST
