import React, { useEffect, useState, useCallback } from 'react'

import api from '~/services/api-pessoal'

import { Box, CircularProgress } from '@material-ui/core'

import { Button, Container, Finder, LabelValueColor, PageHeader } from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import { formatCurrency } from '~/hooks/useUtils'
import useAmbiente from '~/hooks/useAmbiente'

import { IndSimplesEnum } from '~/@types/enums/IndSimplesEnum'
import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'

export interface ManutencaoAtividadeConcomitanteData {
  vinculoId: string
  rpId: string
  idf: ReciboIDFEnum
  nome: string
  vrBaseINSS?: number
  indSimples: IndSimplesEnum
  reciboeSocial: string
}

interface RowModify {
  index: number
  value: any
}

const totalizadorInitialState = {
  vlrTotalSubstituida: '',
  vlrTotalNaoSubstituida: '',
  vlrTotalConcomitante: '',
}

export default function ManutencaoAtividadeConcomitante() {
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isLoadingConfirm, setLoadingConfirm] = useState(false)
  const [collection, setCollection] = useState<ManutencaoAtividadeConcomitanteData[]>([])
  const [rowsModify, setRowsModify] = useState<RowModify[]>([])
  const [totalizadorFooter, setTotalizadorFooter] = useState(totalizadorInitialState)

  const dialogNotification = useDialogNotification()
  const { empregador, anoMes } = useAmbiente()

  function gerarValoresFooter(data: ManutencaoAtividadeConcomitanteData[]) {
    let vlrTotalSubstituida = 0
    let vlrTotalNaoSubstituida = 0
    let vlrTotalConcomitante = 0

    data.forEach((d) => {
      switch (d.indSimples) {
        case IndSimplesEnum.ContribuicaoSubstituida_1:
          vlrTotalSubstituida += d?.vrBaseINSS || 0
          break
        case IndSimplesEnum.ContribuicaoNaoSubstituida_2:
          vlrTotalNaoSubstituida += d?.vrBaseINSS || 0
          break
        case IndSimplesEnum.ContribuicaoConcomitante_3:
          vlrTotalConcomitante += d?.vrBaseINSS || 0
          break
      }
    })

    setTotalizadorFooter({
      vlrTotalSubstituida: formatCurrency(vlrTotalSubstituida),
      vlrTotalNaoSubstituida: formatCurrency(vlrTotalNaoSubstituida),
      vlrTotalConcomitante: formatCurrency(vlrTotalConcomitante),
    })
  }

  const getCollection = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get('/AtividadeConcomitante/ObterAtividadeConcomitante', {
        params: {
          empregadorId: empregador.id,
          anoMes,
        },
      })
      setCollection(response.data.data)
      setRowsModify([])
      gerarValoresFooter(response.data.data)
    } catch (err) {
      setCollection([])
      setRowsModify([])
      setTotalizadorFooter(totalizadorInitialState)
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [empregador, anoMes])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  function handleChangeColumnAtividade(value: any, index: number) {
    const newRowModify: RowModify = {
      index,
      value,
    }
    let oldRowsModify = rowsModify

    const rowFinded = rowsModify.find((d) => d.index === index)

    if (rowFinded) {
      oldRowsModify = rowsModify.filter((d) => d.index !== rowFinded.index)
    }

    setRowsModify([...oldRowsModify, newRowModify])
  }

  function handleCancelAlteracoes() {
    getCollection()
  }

  async function handleConfirmAlteracoes() {
    setLoadingConfirm(true)
    try {
      const dataToUpdate = rowsModify.map((d) => ({
        ...collection[d.index],
        indSimples: d.value,
      }))
      await api.put('/AtividadeConcomitante/SalvarAtividadeConcomitante', dataToUpdate, {
        params: {
          empregadorId: empregador.id,
          anoMes,
        },
      })
      getCollection()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingConfirm(false)
    }
  }

  return (
    <Container>
      <Box height={50}>
        <PageHeader title="Manutenção de Atividade Concomitante">
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </PageHeader>
      </Box>
      <Table
        data={collection}
        isLoading={isLoading}
        query={query}
        handleChangeColumnAtividade={handleChangeColumnAtividade}
      />
      <Box width="100%" display="flex" justifyContent="space-between" pt={1}>
        <Box display="flex" width="50%">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <LabelValueColor
                label="Total Atividade Substituída"
                value={totalizadorFooter.vlrTotalSubstituida}
                color="#2E8BC0"
              />
              <LabelValueColor
                label="Total Atividade Não Substituída"
                value={totalizadorFooter.vlrTotalNaoSubstituida}
                color="#2E8BC0"
              />
              <LabelValueColor
                label="Total Atividade Concomitante"
                value={totalizadorFooter.vlrTotalConcomitante}
                color="#2E8BC0"
              />
            </>
          )}
        </Box>
        <Box display="flex" gridGap={8} alignItems="center">
          <Button
            variant="outlined"
            onClick={handleCancelAlteracoes}
            disabled={!(rowsModify.length > 0)}
          >
            Cancelar Alterações
          </Button>
          <Button
            variant="contained"
            isLoading={isLoadingConfirm}
            onClick={handleConfirmAlteracoes}
            disabled={!(rowsModify.length > 0)}
          >
            Confirmar Alterações
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
