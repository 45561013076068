import React from 'react'

import moment from 'moment'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { AtestadoSaudeOcupacionalItemPartial } from '~/hooks/queries/AtestadoSaudeOcupacionalItem/AtestadoSaudeOcupacionalItem'
import { ASOIndResultadoEnum, ASOIndResultadoValues } from '~/@types/enums/ASOIndResultadoEnum'

const formatIndResultado = (indResultado: ASOIndResultadoEnum) => {
  const objectFinded = ASOIndResultadoValues.find((obj) => obj.value === indResultado)
  return objectFinded?.name || ''
}

interface TableProps {
  data: AtestadoSaudeOcupacionalItemPartial[]
  onItemClick: (event: 'delete' | 'edit', index: number) => void
}

export default function Table(props: TableProps) {
  const { data: _data, onItemClick } = props

  const data = _data?.map((item) => {
    return {
      ...item,
      dtExame: item?.dtExame ? moment(item.dtExame).format('DD/MM/yyyy') : '',
      indResultado: item?.indResultado ? formatIndResultado(item.indResultado) : '',
      dtVencimentoExame: item?.dtVencimentoExame
        ? moment(item.dtVencimentoExame).format('DD/MM/yyyy')
        : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtExame',
      label: 'Data do Exame',
    },
    {
      name: 'indResultado',
      label: 'Resultado',
    },
    {
      name: 'validadeExame',
      label: 'Validade do Exame',
    },
    {
      name: 'dtVencimentoExame',
      label: 'Data de Vencimento',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', rowIndex)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', rowIndex)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} />
}
