import React, { useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import { ConfirmDeleteDialog, TreeView } from '~/components'

import Table from './components/Table'
import Form from './components/Form'
import Container from './components/Container'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { eSocialEventoConsts } from '~/values/eSocialEventoValues'

const { S_2205 } = eSocialEventoConsts

const HEADER_HEIGHT = '70px'

const AlteracaoCadastral = () => {
  const [isFetching, setFetching] = useState(false)
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [query, setQuery] = useState('')
  const [vinculo, setVinculo] = useState({})
  const [container, setContainer] = useState({
    isOpen: false,
    vinculo: {},
    origemId: '',
  })

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { estabelecimento, anoMes } = useAmbiente()

  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])

  const getCollectionByVinculo = async (vinculoId) => {
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get('/VinculoAlteracaoContratoCadastro/GetByVinculo', {
        params: {
          vinculoId,
          evento: '2205',
        },
      })
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
  }

  useEffect(() => {
    setVinculo({})
    setCollection({
      isLoading: false,
      itens: [],
    })
  }, [anoMes, estabelecimento])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleOpenForm = useCallback(
    (
      data = {
        vinculoId: vinculo?.id,
        eSocialEvento: S_2205,
      },
    ) => {
      if (collection?.itens?.length > 0 && !collection?.itens[0]?.reciboEsocial) {
        dialogNotification.warning({
          descriptions: [
            'O último registro não foi enviado para o eSocial! Não é possível fazer essa requisição',
          ],
          time: 4000,
        })
        return
      }
      setForm((oldState) => ({
        ...oldState,
        isOpen: true,
        data,
      }))
    },
    // eslint-disable-next-line
    [vinculo?.id, collection.itens],
  )

  const onOpenContainer = useCallback(
    async (id) => {
      try {
        const response = await api.get(`/Vinculo/${vinculo?.id}`)
        if (response?.data?.data) {
          return setContainer({
            isOpen: true,
            vinculo: response.data.data,
            origemId: id,
          })
        }
        throw new Error('no data in "/Vinculo/..."')
      } catch (error) {
        console.log(error)
      }
    },
    [vinculo],
  )

  const handleConfirmRetificacao = useCallback(
    async (id) => {
      setFetching(true)
      try {
        await api.post(
          '/VinculoAlteracaoContratoCadastro/Retificar',
          {},
          {
            params: {
              vinculoAlteracaoContratoCadastroId: id,
            },
          },
        )

        onOpenContainer(id)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setFetching(false)
      }
    },
    // eslint-disable-next-line
    [onOpenContainer],
  )

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        if (item?.reciboEsocial)
          return dialogNotification.warning({
            descriptions: [
              'Alteração já foi enviada para o eSocial, se prosseguir será gerado um evento de retificação',
            ],
            onConfirm: () => handleConfirmRetificacao(id),
          })
        onOpenContainer(id)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    [onOpenContainer, handleConfirmRetificacao, collection, dialogNotification],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/VinculoAlteracaoContratoCadastro/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [handleOpenForm])

  const handleCloseForm = useCallback(() => {
    setForm({
      data: {},
      isOpen: false,
    })
  }, [])

  const onCloseContainer = () => {
    setContainer({ isOpen: false, vinculo: {}, origemId: '' })
  }

  const handleAfterSubmitForm = useCallback(
    (value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
        onOpenContainer(data.id)
      }
      handleAfterInsert(value)
    },
    [collection, handleCloseForm, onOpenContainer],
  )

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => {
        getCollectionByVinculo(vinculo.vinculoId)
        setVinculo({
          ...vinculo,
          id: vinculo?.vinculoId,
          nome: vinculo?.vinculoNome,
        })
      }}
      searchBy="vinculoNome"
      renderOption={(option) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
      isLoadingOnClick={collection.isLoading}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Alteração Cadastral" subtitle={vinculo?.nome || '-'}>
          {vinculo?.id && (
            <ButtonBox>
              <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
              <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
                Adicionar
              </Button>
            </ButtonBox>
          )}
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          query={query}
          onItemClick={handleClickItem}
          isFetching={isFetching}
        />
      </Box>

      <Form
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <Container
        isOpen={container.isOpen}
        onClose={onCloseContainer}
        vinculo={container.vinculo}
        origemId={container.origemId}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
        description="Você quer realmente excluir este registro? As alterações que foram feitas não serão desfeitas!"
      />
    </TreeView>
  )
}

export default AlteracaoCadastral
