import clsx from 'clsx'
import moment from 'moment'

import { Box, makeStyles } from '@material-ui/core'

import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'

import {
  CompetenciaProcessamentoContent,
  CompetenciaProcessamentoStatusEnum,
  CompetenciaProcessamentoStatusValues,
} from '~/hooks/queries/Competencia/CompetenciaProcessamentoContent'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { setImportantCSS } from '~/hooks/useUtils'

const useStyles = makeStyles((theme: FixLater) => ({
  fullHeight: {
    height: '100%',
  },
  successRow: {
    backgroundColor: setImportantCSS(theme.palette.success.light),
    color: setImportantCSS(theme.palette.success.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.success.lighter),
    },
  },
  errorRow: {
    backgroundColor: setImportantCSS(theme.palette.error.light),
    color: setImportantCSS(theme.palette.error.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.error.lighter),
    },
  },
  startProcessRow: {
    backgroundColor: setImportantCSS(theme.palette.primary.light),
    color: setImportantCSS(theme.palette.primary.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.primary.lighter),
    },
  },
}))

interface DialogLogProps {
  isOpen: boolean
  onClose: () => void
  logs: CompetenciaProcessamentoContent[]
}

export default function DialogLog({ isOpen, onClose, logs }: DialogLogProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'date',
      label: 'Data',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy HH:mm:ss'),
      },
    },
    {
      name: 'message',
      label: 'Mensagem',
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (value) =>
          CompetenciaProcessamentoStatusValues.find((d) => d.value === value)?.name || '',
      },
    },
  ]

  const classes = useStyles()

  return (
    <ActionDialog
      title="Logs Processamento"
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox bottom={1} right={1} top={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
    >
      <Box position="relative">
        <Box position="absolute" overflow="auto" width="100%">
          <ToolsDataTable
            data={logs}
            columns={columns}
            disableAutoHeight
            options={{
              setRowProps: (_: FixLater, index: number) => {
                const rowCurrent = logs[index]
                if (!rowCurrent) return
                return {
                  className: clsx({
                    [classes.errorRow]:
                      rowCurrent.status === CompetenciaProcessamentoStatusEnum.Erro,
                    [classes.successRow]:
                      rowCurrent.status === CompetenciaProcessamentoStatusEnum.Finalizado,
                    [classes.startProcessRow]:
                      rowCurrent.status === CompetenciaProcessamentoStatusEnum.Iniciado,
                  }),
                }
              },
            }}
          />
        </Box>
      </Box>
    </ActionDialog>
  )
}
