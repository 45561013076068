import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, AnoMesTextField, CurrencyTextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { TabelaSF } from '~/hooks/queries/TabelaSF/TabelaSF'
import { useCriarTabelaSF } from '~/hooks/queries/TabelaSF/useCriarTabelaSF'
import { useAtualizarTabelaSF } from '~/hooks/queries/TabelaSF/useAtualizarTabelaSF'

const schema = yup.object().shape({
  anoMes: yup.string().required('Informe o Ano/Mês').nullable(),
  vrFaixaI: yup.string().required('Informe o Valor Faixa I').nullable(),
  vrFaixaII: yup.string().required('Informe o Valor Faixa II').nullable(),
  vrCotaI: yup.string().required('Informe o Valor Cota I').nullable(),
  vrCotaII: yup.string().required('Informe o Valor Cota II').nullable(),
})

interface FormProps {
  data: TabelaSF | null
  isOpen: boolean
  onClose: () => void
}

export default function Form(props: FormProps) {
  const { data: _data, isOpen, onClose } = props

  const [data, setData] = useState<Nullable<TabelaSF>>(
    _data || {
      anoMes: null,
      vrFaixaI: null,
      vrFaixaII: null,
      vrCotaI: null,
      vrCotaII: null,
    },
  )

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const { mutateAsync: mutateAsyncCriar, isLoading: isLoadingCriar } = useCriarTabelaSF()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: isLoadingAtualizar } =
    useAtualizarTabelaSF()

  const isLoading = isLoadingCriar || isLoadingAtualizar

  async function handleSubmit() {
    const d = data as TabelaSF
    if (data.id) {
      await mutateAsyncAtualizar(d)
    } else {
      await mutateAsyncCriar(d)
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Tabela Salário Família Formulário"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
      okLabel="Salvar"
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AnoMesTextField
            label="Ano/Mês"
            value={data.anoMes || null}
            onChange={(e: FixLater) => {
              const anoMes = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, anoMes }))
            }}
            required
            validationErrors={validationErrors}
            name="anoMes"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            label="Valor Faixa I"
            value={data.vrFaixaI || null}
            onChange={(_, value) => {
              const vrFaixaI = value
              setData((oldState) => ({
                ...oldState,
                vrFaixaI,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrFaixaI"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            label="Valor Cota I"
            value={data.vrCotaI || null}
            onChange={(_, value) => {
              const vrCotaI = value
              setData((oldState) => ({
                ...oldState,
                vrCotaI,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrCotaI"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            label="Valor Faixa II"
            value={data.vrFaixaII || null}
            onChange={(_, value) => {
              const vrFaixaII = value
              setData((oldState) => ({
                ...oldState,
                vrFaixaII,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrFaixaII"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            label="Valor Cota II"
            value={data.vrCotaII || null}
            onChange={(_, value) => {
              const vrCotaII = value
              setData((oldState) => ({
                ...oldState,
                vrCotaII,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrCotaII"
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
