import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { ProcessoTrabalhista } from './dtos/ProcessoTrabalhista'

const query = '/ProcessoTrabalhista'

interface RequestProps {
  enabled?: boolean
  params: {
    empregadorId: string
  }
}

export function useGetAll({ enabled, params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ProcessoTrabalhista[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled,
  })
}

export function useGetAllInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
