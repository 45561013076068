import TextFieldChange from '../TextFieldChange'

import { useVinculoAtualizarEmail } from '~/hooks/queries/Vinculo/useVinculoAtualizarEmail'

interface TextFieldChangeVinculoEmailProps {
  email: string
  vinculoId: string
  afterSubmit: () => void
}

export default function TextFieldChangeVinculoEmail(props: TextFieldChangeVinculoEmailProps) {
  const { email, vinculoId, afterSubmit } = props

  const { mutateAsync, isLoading } = useVinculoAtualizarEmail()

  async function handleChange(email: string) {
    await mutateAsync({ email, vinculoId })
    afterSubmit()
  }

  return <TextFieldChange value={email} onChange={handleChange} isLoading={isLoading} />
}
