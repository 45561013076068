import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, AnoMesTextField, CurrencyTextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCriar } from '~/hooks/queries/PlanoSaudeTitularCusto/useCriar'

import { PlanoSaudeTitularCusto } from '~/hooks/queries/PlanoSaudeTitularCusto/dtos/PlanoSaudeTitularCusto'

const schema = yup.object({
  anoMes: yup.string().required('Informe o Ano/Mês').nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  initialData: PlanoSaudeTitularCusto
}

export default function Form({ isOpen, onClose, initialData }: FormProps) {
  const [data, setData] = useState(initialData)

  const { mutateAsync: mutateAsyncCreating, isLoading: isSubmitting } = useCriar()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    await mutateAsyncCreating(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Custo Plano de Saúde Titular"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AnoMesTextField
            required
            validationErrors={validationErrors}
            name="anoMes"
            label="Ano/Mês"
            value={data.anoMes}
            onChange={(e) => {
              const anoMes = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, anoMes }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            label="Valor do Custo"
            value={data.vrCusto}
            onChange={(_, value) => {
              const vrCusto = value
              setData((oldState) => ({
                ...oldState,
                vrCusto,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            label="Valor do Custo da Empresa"
            value={data.vrCustoEmpresa}
            onChange={(_, value) => {
              const vrCustoEmpresa = value
              setData((oldState) => ({
                ...oldState,
                vrCustoEmpresa,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
