import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ApuracaoEncargosFGTSLotacaoDTO } from './ApuracaoEncargosFGTSDTO'

const queryObterApuracaoEncargosLotacaoFGTS = '/ApurarEncargos/ObterApuracaoEncargosLotacaoFGTS'

interface RequestProps {
  estabelecimentoId: string
  periodoApuracao: string
}

export default function useObterApuracaoEncargosLotacaoFGTS(props: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ApuracaoEncargosFGTSLotacaoDTO[] }>(
      queryObterApuracaoEncargosLotacaoFGTS,
      {
        params: props,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterApuracaoEncargosLotacaoFGTS, props], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
