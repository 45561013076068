import React, { useEffect, useState } from 'react'

import { makeStyles, Collapse } from '@material-ui/core'
import { Alert, AlertProps } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  rootAlert: {
    paddingTop: '0.5px',
    paddingBottom: '0.5px',
  },
}))

interface SimpleAlertProps extends AlertProps {
  children: React.ReactNode | string
  show?: boolean
}

export default function SimpleAlert(props: SimpleAlertProps) {
  const { show: _show = true, children, ...rest } = props

  const [show, setShow] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    setShow(_show)
  }, [_show])

  return (
    <Collapse in={show}>
      <Alert onClose={() => setShow(false)} className={classes.rootAlert} {...rest}>
        {children}
      </Alert>
    </Collapse>
  )
}
