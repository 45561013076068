import React, { useState } from 'react'

import { Box } from '@material-ui/core'

import useUploadFile from '~/hooks/useUploadFile'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

import { DragDropFile } from '~/components'
import useOnlyDataC from '~/hooks/useOnlyDataC'

export default function Form(props) {
  const { data, onAfterSubmit } = props

  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { isUserDataC } = useOnlyDataC()

  const [handlePrepareUpload] = useUploadFile(async (preparedFile) => {
    const { file } = preparedFile
    const formData = new FormData()
    formData.append('file', file)
    setLoading(true)
    try {
      if (!isUserDataC) throw new Error('Para atualizar o XML precisa ser um usuário Data C')
      await api.put('/Relatorio/UploadFile', formData, {
        params: {
          id: data.id,
        },
      })
      notification.put()
      onAfterSubmit()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  })

  return (
    <Box pb={2}>
      <DragDropFile
        acceptFiles={['.frx', '.xml']}
        onDropped={handlePrepareUpload}
        isLoading={isLoading}
      />
    </Box>
  )
}
