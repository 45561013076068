import { Grid } from '@material-ui/core'

import { TextField, Fieldset } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { OrigemRetificacaoAfastamentoValues } from '~/@types/enums/OrigemRetificacaoAfastamentoEnum'
import { ProcessoTipoAfastamentoValues } from '~/@types/enums/ProcessoTipoAfastamentoEnum'

import { VinculoAfastamentoForm } from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'
import { useStepperContext } from '~/components/StepperForm'

import { ContextProps, titleAllFieldsDisabled } from '..'

export default function InformacaoRetificacao() {
  const {
    dataControlled: data,
    onChangeControlled: setData,
    context,
  } = useStepperContext<VinculoAfastamentoForm>()
  const { disabledAllFields, validationErrors } = context as ContextProps

  return (
    <Fieldset disabled={disabledAllFields} title={disabledAllFields ? titleAllFieldsDisabled : ''}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <MUIAutoComplete
            label="Origem Retificação"
            required
            validationErrors={validationErrors}
            name="origemRetificacao"
            options={OrigemRetificacaoAfastamentoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.origemRetificacao}
            onChange={(e, obj) => {
              const origemRetificacao = obj ? obj.value : ''
              setData({ ...data, origemRetificacao })
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MUIAutoComplete
            label="Tipo Processo"
            required
            validationErrors={validationErrors}
            name="tipoProcesso"
            options={ProcessoTipoAfastamentoValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.tipoProcesso}
            onChange={(e, obj) => {
              const tipoProcesso = obj ? obj.value : ''
              setData({ ...data, tipoProcesso })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nr. Processo"
            fullWidth
            value={data?.nrProcesso || ''}
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 21,
            }}
            onChange={(e) => {
              const nrProcesso = e.target.value
              setData({ ...data, nrProcesso })
            }}
          />
        </Grid>
      </Grid>
    </Fieldset>
  )
}
