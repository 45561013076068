import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Button, Typography, Grid, TextField } from '@material-ui/core'

import { ContentContainer, PageHeader, Finder, ButtonBox } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import Table from './components/Table'
import apiPessoal from '~/services/api-pessoal'
import { AutoCompleteCTBModelo } from '~/components/AutoComplete'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

const CTBClassificacao = () => {
  const history = useHistory()
  const [items, setItems] = useState([])
  const [current, setCurrent] = useState({})
  const [isNew, setIsNew] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [ctbModeloId, setCtbModeloId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')

  const notifications = useNotification()
  const dialogNotification = useDialogNotification()

  const pageTitle = 'CTB Classificação'
  const apiRoute = '/ctbClassificacao'
  const pageRoute = '/ctb-classificacao'

  const updateItems = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!ctbModeloId) {
        setItems([])
        return
      }

      const response = await apiPessoal.get(`${apiRoute}/${ctbModeloId}/selecionarCTBModelo`)
      if (response.data.data) {
        setItems(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [ctbModeloId])

  useEffect(() => {
    updateItems()
  }, [updateItems])

  const handleStartNew = () => {
    setIsNew(true)

    setCurrent({
      ...current,
      ctbModeloId,
    })
  }

  const handleCancelNew = () => {
    setIsNew(false)

    setCurrent({})
  }

  const handleNew = async () => {
    setIsProcessing(true)

    if (!current.codigo) {
      notifications.warning('Insira o Código do ' + pageTitle, 'Ops!', 4000)
      setIsProcessing(false)
      return
    }

    if (!current.nome) {
      notifications.warning('Insira o Nome do ' + pageTitle, 'Ops!', 4000)
      setIsProcessing(false)
      return
    }

    try {
      const response = await apiPessoal.post(apiRoute, JSON.stringify(current))

      if (response.status === 200) {
        notifications.success('Os dados foram salvos', 'Sucesso!', 4000)
        history.push(`${pageRoute}/${response.data.data.id}`)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsProcessing(false)
    setIsNew(false)
    setCurrent({})
  }

  const handleEdit = async (id) => {
    history.push(`${pageRoute}/${id}`)
  }

  const handleDelete = (id) => {
    setCurrent({ id })

    if (!id) {
      notifications.warning('Selecione um Vale Transporte para excluir!', 'Ops!', 4000)
      return
    }

    setIsOpenDelete(true)
  }

  const deletar = async () => {
    setIsProcessing(true)

    try {
      const response = await apiPessoal.delete(`${apiRoute}/${current.id}`)

      if (response.status === 200) {
        notifications.success('Registro foi removido', 'Sucesso!', 4000)
        updateItems()
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsOpenDelete(false)
    setIsProcessing(false)
    setCurrent({})
  }

  const handleClickItem = (event, data) => {
    const functions = {
      edit: handleEdit,
      delete: handleDelete,
    }
    functions[event](data)
  }

  return (
    <ContentContainer>
      <PageHeader title={pageTitle} />
      <Box>
        <AutoCompleteCTBModelo
          required
          // value={current.lotacao || null}
          onChange={(e, ctbModeloObj) => {
            const ctbModeloId = ctbModeloObj ? ctbModeloObj.id : null
            setCtbModeloId(ctbModeloId)
          }}
        />
      </Box>

      <PageHeader title={'Lançamentos'}>
        <ButtonBox>
          <Finder
            onSearch={(value) => {
              setQuery(value)
            }}
            searching={isLoading}
            onClose={() => {
              setQuery('')
            }}
          />
          <Button size="small" color="primary" onClick={handleStartNew} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Box>
        <Table data={items} onItemClick={handleClickItem} isLoading={isLoading} query={query} />
      </Box>

      <ActionDialog
        title={'Cadastro de ' + pageTitle}
        okLabel="Continuar"
        isOpen={isNew}
        onClose={handleCancelNew}
        onOkClick={handleNew}
        onCancelClick={handleCancelNew}
        isOkProcessing={isProcessing}
      >
        <Grid container spacing={2}>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
            <TextField
              label="Código"
              fullWidth
              value={current.codigo || ''}
              variant="outlined"
              size="small"
              onlyNumber
              required
              onChange={(e) => {
                const codigo = e.target.value
                setCurrent({ ...current, codigo })
              }}
            />
          </Grid>

          <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
            <TextField
              label="Nome"
              fullWidth
              value={current.nome || ''}
              variant="outlined"
              size="small"
              required
              onChange={(e) => {
                const nome = e.target.value
                setCurrent({ ...current, nome })
              }}
            />
          </Grid>
        </Grid>
      </ActionDialog>

      <ActionDialog
        title="Excluir Registro"
        okLabel="Sim"
        isOpen={isOpenDelete}
        onClose={() => setIsOpenDelete(false)}
        onOkClick={deletar}
        onCancelClick={() => setIsOpenDelete(false)}
        isOkProcessing={isProcessing}
      >
        <Typography>Você quer mesmo excluir este registro</Typography>
      </ActionDialog>
    </ContentContainer>
  )
}

export default CTBClassificacao
