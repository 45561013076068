export enum IndCadastroMatriculaEnum {
  Preencher_00,
  Sequencial_01,
  SequencialCPF_02,
}

export const IndCadastroMatriculaValues = [
  {
    name: 'Preencher',
    value: IndCadastroMatriculaEnum.Preencher_00,
  },
  {
    name: 'Sequencial',
    value: IndCadastroMatriculaEnum.Sequencial_01,
  },
  {
    name: 'Sequencial CPF',
    value: IndCadastroMatriculaEnum.SequencialCPF_02,
  },
]
