import { useState, useEffect } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { PageHeader, PDFViewer, Button, Finder, Checkbox, DatePickerNew } from '~/components'

import Table from './Table'

import { getDateMinAndDateMaxOfYearMonth } from '~/hooks/useUtils'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { EtiquetaContratoExperienciaFuncionarioDTO } from '~/hooks/queries/EtiquetaContratoExperiencia/EtiquetaContratoExperienciaFuncionarioDTO'
import { useObterFuncionarios } from '~/hooks/queries/EtiquetaContratoExperiencia/useObterFuncionarios'

const schema = yup.object().shape({
  dtInicioAdmissao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Início da Admissão')
    .nullable(),
  dtFimAdmissao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Fim da Admissão')
    .nullable(),
})

const TITLE_PAGE = 'Etiqueta Contrato Experiência'

export default function EtiquetaContratoExperiencia() {
  const [collection, setCollection] = useState<EtiquetaContratoExperienciaFuncionarioDTO[]>([])
  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [filter, setFilter] = useState<{
    estabelecimentoId?: string
    dtInicioAdmissao: string | null
    dtFimAdmissao: string | null
  }>({
    estabelecimentoId: undefined,
    dtInicioAdmissao: null,
    dtFimAdmissao: null,
  })

  const [query, setQuery] = useState('')
  const { estabelecimento, anoMes } = useAmbiente()
  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data: filter,
    handleSubmit: handleSearch,
  })
  const theme = useTheme()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<string[]>([])

  const { mutateAsync, isLoading } = useObterFuncionarios()

  useEffect(() => {
    async function getCollection() {
      const { dtMax, dtMin } = getDateMinAndDateMaxOfYearMonth(anoMes)
      const params = {
        estabelecimentoId: undefined,
        dtInicioAdmissao: dtMin,
        dtFimAdmissao: dtMax,
      }
      setFilter(params)
      const response = await mutateAsync(params)
      setCollection(response.data.data)
    }
    getCollection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anoMes, estabelecimento])

  async function handleSearch() {
    const response = await mutateAsync({
      estabelecimentoId: filter.estabelecimentoId,
      dtInicioAdmissao: filter.dtInicioAdmissao as string,
      dtFimAdmissao: filter.dtFimAdmissao as string,
    })
    setCollection(response.data.data)
    setRowsSelected([])
  }

  function handlePrint() {
    const funcionariosIds = rowsSelected.map((index) => collection[index].funcionarioId)
    openPDFViewer(funcionariosIds)
  }

  function handleClosePDFViewer() {
    closePDFViewer()
    setRowsSelected([])
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      gridGap={theme.spacing(1)}
      flexDirection="column"
    >
      <PageHeader title={TITLE_PAGE}>
        <Box>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </Box>
      </PageHeader>

      <Paper>
        <Box
          p={1.5}
          display="flex"
          gridGap={theme.spacing(1)}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4}>
                <DatePickerNew
                  label="Início da Admissão"
                  value={filter.dtInicioAdmissao}
                  onChange={(date) => {
                    const dtInicioAdmissao = date
                    setFilter((prev) => ({
                      ...prev,
                      dtInicioAdmissao,
                    }))
                  }}
                  validationErrors={validationErrors}
                  name="dtInicioAdmissao"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <DatePickerNew
                  label="Fim da Admissão"
                  value={filter.dtFimAdmissao}
                  onChange={(date) => {
                    const dtFimAdmissao = date
                    setFilter((prev) => ({
                      ...prev,
                      dtFimAdmissao,
                    }))
                  }}
                  validationErrors={validationErrors}
                  name="dtFimAdmissao"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Checkbox
                  label="Listar todos Estabelecimentos"
                  value={filter.estabelecimentoId ? false : true}
                  onChange={(e, checked) => {
                    const estabelecimentoId = checked ? undefined : estabelecimento.id
                    setFilter((prev) => ({
                      ...prev,
                      estabelecimentoId,
                    }))
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Button variant="contained" onClick={handleValidate}>
            Buscar
          </Button>
        </Box>
      </Paper>

      <Box flex={1} overflow="auto" position="relative">
        <Table
          data={collection}
          isLoading={isLoading}
          onRowSelected={setRowsSelected}
          rowsSelected={rowsSelected}
          query={query}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handlePrint}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Imprimir
        </Button>
      </Box>

      <PDFViewer
        title={TITLE_PAGE}
        reportKey="EtiquetaContratoExperiencia"
        isOpen={isOpenPDFViewer}
        onClose={handleClosePDFViewer}
        axiosConfig={{
          url: 'EtiquetaContratoExperiencia/ObterRelatorio',
          method: 'post',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
