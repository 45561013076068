import { useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, useTheme } from '@material-ui/core'

import { ActionDialog, ContentDivider, DatePickerNew, SimpleSelect } from '~/components'

import AvisoFimAfastamento from '../AvisoFimAfastamento'

import useValidationErrors from '~/hooks/useValidationErrors'
import {
  VinculoAfastamento,
  VinculoAfastamentoForm,
} from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'

import { CodigoSaidaFGTSValues } from '~/@types/enums/CodigoSaidaFGTSEnum'
import { CodigoRetornoFGTSValues } from '~/@types/enums/CodigoRetornoFGTSEnum'

const schema = yup.object().shape({
  dtRetorno: yup
    .date()
    .required('Informe a Data de Retorno do Afastamento')
    .typeError('Informe uma data válida'),
})

interface DialogInformarFimProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoAfastamentoForm
  onAfterSubmitForm: (afastamento: VinculoAfastamento) => void
  isLoading: boolean
}

export default function DialogInformarFim(props: DialogInformarFimProps) {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, isLoading } = props

  const [data, setData] = useState(_data)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const theme = useTheme()

  async function handleSubmit() {
    await onAfterSubmitForm(data as VinculoAfastamento)
    onClose()
  }

  return (
    <ActionDialog
      title="Informe o Fim do Afastamento"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
      okLabel="Salvar"
      cancelLabel="Cancelar"
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
            <AvisoFimAfastamento />
            <DatePickerNew
              label="Fim do Afastamento"
              value={data.dtRetorno}
              onChange={(date) => {
                const dtRetorno = date
                setData((prev) => ({
                  ...prev,
                  dtRetorno,
                }))
              }}
              required
              name="dtRetorno"
              validationErrors={validationErrors}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <ContentDivider title="Dados para SEFIP" />
        </Grid>

        <Grid item xs={12}>
          <SimpleSelect
            label="Código de Saída FGTS"
            options={CodigoSaidaFGTSValues}
            renderOption={(opt) => opt.name}
            optionId="value"
            value={data?.codigoSaidaFGTS || null}
            onChange={(_, codigoSaidaFGTS) => {
              setData((oldState) => ({
                ...oldState,
                codigoSaidaFGTS,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SimpleSelect
            label="Código de Retorno FGTS"
            options={CodigoRetornoFGTSValues}
            renderOption={(opt) => opt.name}
            optionId="value"
            value={data?.codigoRetornoFGTS || null}
            onChange={(_, codigoRetornoFGTS) => {
              setData((oldState) => ({
                ...oldState,
                codigoRetornoFGTS,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
