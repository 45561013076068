import { useState, useRef } from 'react'

import moment from 'moment'
import api from '~/services/api-pessoal'

import { ActionDialog } from '~/components'
import SalarioContratualForm from '~/pages/Funcionario/Forms/SalarioContratualForm'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

const Form = (props) => {
  const { isOpen, onClose, data: _data, vinculo, collection, onAfterSubmitForm } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  const buttonRef = useRef(null)

  const handleSubmit = (data) => {
    if (vinculo?.dtAdmissao) {
      if (moment(vinculo.dtAdmissao).isAfter(data?.dtSalario))
        return dialogNotification.warning({
          descriptions: ['A Data do Salário não pode ser menor que a Data de Admissão'],
        })

      if (moment(vinculo.dtAdmissao).isAfter(data?.dtEfetiva))
        return dialogNotification.warning({
          descriptions: ['A Data Efetiva não pode ser menor que a Data de Admissão'],
        })
    }

    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/vinculoSalarioBase/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/vinculoSalarioBase', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    if (data.id) {
      update()
      return
    }
    insert()
  }

  function handleClickSave() {
    buttonRef.current?.click()
  }

  return (
    <ActionDialog
      title="Cadastro de Salário Contratual"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleClickSave}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <SalarioContratualForm
        data={
          collection.length === 0 && !_data?.id
            ? { ..._data, dtSalario: vinculo?.dtAdmissao, dtEfetiva: vinculo?.dtAdmissao }
            : _data
        }
        onSubmit={handleSubmit}
        buttonRef={buttonRef}
      />
    </ActionDialog>
  )
}

export default Form
