import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { FolhaPonto } from './dtos/FolhaPonto'

const query = '/FolhaPonto/ObterFolhaPonto'

interface RequestProps {
  params: {
    vinculoId: string | null
    anoMes: string
  }
}

export function useObterFolhaPonto({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params.vinculoId) return
    const response = await api.get<{ data: FolhaPonto }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    staleTime: 0,
    cacheTime: 0,
  })
}

export function useInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
