export enum TipoInscricaoEnum {
  NaoInformar_0 = 0,
  CNPJ_1 = 1,
  CPF_2 = 2,
  CAEPF_3 = 3,
  CNO_4 = 4,
  CGC_5 = 5,
  CEI_6 = 6,
  NIT_7 = 7,
}

export const TipoInscricaoLabels = {
  [TipoInscricaoEnum.NaoInformar_0]: 'Não informar',
  [TipoInscricaoEnum.CNPJ_1]: 'CNPJ',
  [TipoInscricaoEnum.CPF_2]: 'CPF',
  [TipoInscricaoEnum.CAEPF_3]: 'CAEPF',
  [TipoInscricaoEnum.CNO_4]: 'CNO',
  [TipoInscricaoEnum.CGC_5]: 'CGC',
  [TipoInscricaoEnum.CEI_6]: 'CEI',
  [TipoInscricaoEnum.NIT_7]: 'NIT',
}

export const TipoInscricaoValues = [
  { value: TipoInscricaoEnum.NaoInformar_0, name: 'Não informar' },
  { value: TipoInscricaoEnum.CNPJ_1, name: 'CNPJ' },
  { value: TipoInscricaoEnum.CPF_2, name: 'CPF' },
  { value: TipoInscricaoEnum.CAEPF_3, name: 'CAEPF' },
  { value: TipoInscricaoEnum.CNO_4, name: 'CNO' },
  { value: TipoInscricaoEnum.CGC_5, name: 'CGC' },
  { value: TipoInscricaoEnum.CEI_6, name: 'CEI' },
  { value: TipoInscricaoEnum.NIT_7, name: 'NIT' },
]
