import { useState, useEffect, useContext } from 'react'

import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  MenuItem,
  Box,
  Typography,
  IconButton,
  Menu,
  makeStyles,
  Paper,
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { ActionDialog } from '~/components'

import menuContext from '~/contexts/menu-context'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(() => ({
  rootContent: {
    height: 'calc(100% - 48px)',
  },
  content: {
    height: '100%',
    overflow: 'auto',
  },
}))

export default function Container(props) {
  const { menu, isOpen, formWasModified, removeAutoSize, tabs, fixedContainer, tabValueStart } =
    props

  const [wasModified, setWasModified] = useState(false)
  const [preventLostData, setPreventLostData] = useState({
    isOpen: false,
    valueTab: false,
  })
  const [tabValue, setTabValue] = useState(tabValueStart)
  const [anchorEl, setAnchorEl] = useState(false)

  const classes = useStyles()
  const isMenuOpen = useContext(menuContext)

  useEffect(() => {
    if (!isOpen) {
      setTabValue(tabValueStart)
    }
  }, [isOpen, tabValueStart])

  useEffect(() => {
    setWasModified(formWasModified)
  }, [formWasModified])

  const handleChangeTabs = (e, newValue) => {
    setAnchorEl(false)
    if (wasModified) {
      setPreventLostData({
        isOpen: true,
        tabValue: newValue,
      })
    } else {
      setTabValue(newValue)
    }
  }

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onConfirmLostData = () => {
    setTabValue(preventLostData.tabValue)
    setWasModified(false)
    closePreventLostData()
  }

  const closePreventLostData = () => {
    setPreventLostData((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }

  function applyStyleByIsShow(isShow) {
    if (typeof isShow === 'boolean') {
      if (isShow) return 'inline-flex'
      return 'none'
    }
  }

  return (
    <Box
      component={Paper}
      width={
        fixedContainer && !removeAutoSize
          ? isMenuOpen
            ? 'calc(100vw - 272px)'
            : 'calc(100vw - 65px)'
          : '100%'
      }
      height={fixedContainer && !removeAutoSize ? 'calc(100% - 66px)' : '100%'}
      padding={2}
    >
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar variant="dense" disableGutters>
          {menu && (
            <>
              <IconButton
                aria-label="menu"
                aria-controls="simple-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleClickMenu}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(false)}
                variant="menu"
              >
                {tabs.map((tab, index) => (
                  <MenuItem
                    onClick={(e) => handleChangeTabs(e, index)}
                    disabled={tab.disabled}
                    key={index}
                    style={{
                      display: applyStyleByIsShow(tab?.show),
                    }}
                  >
                    {tab.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}

          <Tabs
            value={tabValue}
            onChange={handleChangeTabs}
            scrollButtons="auto"
            variant="scrollable"
            style={{ maxWidth: '100%' }}
          >
            {tabs.map((tab, index) => (
              <Tab
                style={{
                  display: applyStyleByIsShow(tab?.show),
                }}
                key={index}
                label={
                  <Box display="flex" gridGap="8px">
                    <Box>{tab.label}</Box>
                    {tab?.renderRightTab && <Box>{tab.renderRightTab}</Box>}
                  </Box>
                }
                disabled={tab.disabled}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Toolbar>
      </AppBar>

      <Box className={classes.rootContent}>
        {tabs.map((tab, index) => (
          <TabPanel value={tabValue} index={index} key={index} className={classes.content}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>

      <ActionDialog
        title="Alterações não salvas!"
        okLabel="Continuar"
        isOpen={preventLostData.isOpen}
        onClose={closePreventLostData}
        onOkClick={onConfirmLostData}
        onCancelClick={closePreventLostData}
      >
        <Typography>
          As alterações deste formulário não foram salvas. Saindo desta página, todas as alterações
          serão perdidas. Deseja continuar?
        </Typography>
      </ActionDialog>
    </Box>
  )
}

Container.defaultProps = {
  menu: false,
  isOpen: false,
  formWasModified: false,
  tabs: [],
  fixedContainer: false,
  tabValueStart: 0,
}
