import React from 'react'

import Stack, { StackProps } from '../Stack'

interface StackContainerProps extends StackProps {
  children: React.ReactNode
}

export default function StackContainer({ children, ...rest }: StackContainerProps) {
  return (
    <Stack height="100%" width="100%" p={2} {...rest}>
      {children}
    </Stack>
  )
}
