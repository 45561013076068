import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { TabelaIR } from './TabelaIR'

export const queryUseObterTodosTabelaIR = '/TabelaIR'

export function useObterTodosTabelaIR() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: TabelaIR[] }>(queryUseObterTodosTabelaIR)
    return response?.data?.data || []
  }

  return useQuery([queryUseObterTodosTabelaIR], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
