import { useEffect, useState } from 'react'

import { formatToCPF } from 'brazilian-values'

import { Box, Grid, LinearProgress, Typography, useTheme } from '@material-ui/core'

import { Checkbox, MenuSelect, PageHeader } from '~/components'

import Content from './Content'
import Form from './Form'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

export default function ValeTransporteFuncionario() {
  const [checked, setChecked] = useState<number[]>([])
  const [vinculoIndex, setVinculoIndex] = useState(-1)
  const [isUsarHorarioTrabalho, setUsarHorarioTrabalho] = useState(true)

  const theme = useTheme()
  const { estabelecimento, anoMes } = useAmbiente()

  const {
    data: _data,
    isLoading: isLoadingMenu,
    isFetching: isFetchingMenu,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    anoMes,
    estabelecimentoId: estabelecimento.id,
  })

  const dataMenu = _data || []
  const vinculo = dataMenu[vinculoIndex]
  const vinculosCheckedsIds = checked.map((index) => dataMenu[index].id)

  useEffect(() => {
    setChecked([])
    setVinculoIndex(-1)
  }, [_data])

  return (
    <Box height="100%" padding={1} display="flex" flexDirection="column">
      <Box flex={1} overflow="auto" paddingLeft={1} paddingRight={1}>
        <Grid
          container
          spacing={1}
          style={{
            height: '100%',
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            style={{
              height: '100%',
            }}
          >
            <Box display="flex" flexDirection="column" height="100%" gridGap={theme.spacing(1)}>
              <Box height={4}>{isFetchingMenu && <LinearProgress />}</Box>
              <Box flex={1} overflow="auto">
                <MenuSelect
                  options={dataMenu}
                  onCheckOption={setChecked}
                  optionsCheckeds={checked}
                  onSelect={(index: number) => setVinculoIndex(index)}
                  selectedOption={vinculoIndex}
                  renderOption={(d: VinculoConsulta) => d.pessoaNome}
                  isLoading={isLoadingMenu}
                  searchField="pessoaNome"
                  idField="id"
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            lg={9}
            style={{
              height: '100%',
            }}
          >
            <Box height="100%" width="100%" display="flex" flexDirection="column">
              <PageHeader
                title="Configuração Vale Transporte por Funcionário"
                subtitle={
                  vinculo
                    ? 'FUNCIONÁRIO: ' + vinculo.pessoaNome + ' - ' + formatToCPF(vinculo.pessoaCPF)
                    : '-'
                }
              >
                <Box width={940}>
                  <Checkbox
                    label="Usar o Horário de Trabalho do Funcionário para a Inserção dos Vales Transporte"
                    value={isUsarHorarioTrabalho}
                    onChange={(_, checked) => setUsarHorarioTrabalho(checked)}
                  />
                </Box>
              </PageHeader>
              <Box flex={1} display="flex" flexDirection="column" gridGap={theme.spacing(0.5)}>
                {vinculo ? (
                  <Box flex={1} overflow="auto" position="relative">
                    <Box position="absolute" width="100%" height="100%">
                      <Content vinculoId={vinculo.id} />
                    </Box>
                  </Box>
                ) : (
                  <Typography>Selecione um Funcionário</Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Form
        vinculosId={
          vinculosCheckedsIds.length > 0 ? vinculosCheckedsIds : vinculo?.id ? [vinculo.id] : []
        }
        isUsarHorarioTrabalho={isUsarHorarioTrabalho}
      />
    </Box>
  )
}
