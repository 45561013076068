import React from 'react'

import * as yup from 'yup'
import moment from 'moment'

import { Grid, LinearProgress } from '@material-ui/core'

import { AutoCompleteLotacao } from '~/components/AutoComplete'
import { Button, DatePickerNew, TextField } from '~/components'

import { useVinculoLotacaoById, useVinculoLotacaoMutation } from '~/hooks/queries/useVinculoLotacao'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import { ButtonBox } from 'mio-library-ui'

const schema = yup.object().shape({
  lotacaoId: yup.string().required('Informe Lotação').nullable(),
  anoMes: yup.string().required('Informe Ano/Mês').nullable(),
})

interface FormProps {
  vinculoLotacaoId: string | null
  vinculoId: string
  onClose: () => void
}

export default function Form(props: FormProps) {
  const { vinculoLotacaoId, vinculoId, onClose } = props

  const { empregador } = useAmbiente()

  const { data, setData, isLoading } = useVinculoLotacaoById(vinculoLotacaoId, vinculoId)
  const { mutateAsync, isLoading: isSubmitting } = useVinculoLotacaoMutation()
  const { handleValidate, validationErrors } = useValidationErrors({ data, schema, handleSubmit })

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
  }

  if (isLoading) {
    return <LinearProgress />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompleteLotacao
          required
          validationErrors={validationErrors}
          name="lotacaoId"
          onChange={(e: FixLater, lotacao: FixLater) => {
            const lotacaoId = lotacao?.id || ''
            setData((oldState) => ({ ...oldState, lotacaoId, lotacao }))
          }}
          value={data?.lotacaoId || ''}
          empregadorId={empregador.id}
          optionId="id"
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          label="Ano/Mês"
          fullWidth
          value={data?.anoMes || null}
          size="small"
          format="yyyy/MM"
          views={['year', 'month']}
          required
          validationErrors={validationErrors}
          name="anoMes"
          onChange={(date) => {
            const anoMes = date ? moment(date).format('yyyyMM') : null
            setData({ ...data, anoMes })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="Dias"
          value={data?.dias || ''}
          onlyNumber
          inputProps={{ maxLength: 5 }}
          onChange={(e) => {
            const diasString = e.target.value
            const dias = diasString ? parseInt(diasString) : 0
            setData({ ...data, dias })
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Observação"
          value={data?.observacao || ''}
          multiline
          inputProps={{
            maxLength: 200,
          }}
          onChange={(e) => {
            const observacao = e.target.value
            setData({ ...data, observacao })
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ButtonBox>
          <Button onClick={onClose}>Cancelar</Button>
          <Button isLoading={isSubmitting} onClick={handleValidate} variant="contained">
            Salvar
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
