import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { AnoMes } from '~/components/Displays'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { formatToBRL } from 'brazilian-values'

import { HeightDataTable } from '~/components'

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = [
    {
      name: 'planoSaude',
      label: 'Plano de Saude',
      options: {
        customBodyRender: (value) => value?.nome || null,
      },
    },
    {
      name: 'anoMesInicio',
      label: 'Início',
      options: {
        customBodyRender: (value) => <AnoMes value={value} />,
      },
    },
    {
      name: 'anoMesFim',
      label: 'Fim',
      options: {
        customBodyRender: (value) => <AnoMes value={value} />,
      },
    },
    {
      name: 'vrMensalidadeTitular',
      label: 'Valor Mensalidade Titular',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
    {
      name: 'vrMensalidadeDependente',
      label: 'Valor Mensalidade Dependente',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
    {
      name: 'vrCustoTitular',
      label: 'Valor Custo Titular',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
    {
      name: 'vrCustoDependente',
      label: 'Valor Custo Dependente',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
    {
      name: 'vrTotal',
      label: 'Valor Total',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" size="small" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" size="small" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
