import React, { useMemo } from 'react'

import { IconButton, makeStyles } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import {
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  Edit as EditIcon,
} from '@material-ui/icons'

import moment from 'moment'

import { CodigoIncidenciaCPValues } from '~/@types/enums/CodigoIncidenciaCPEnum'
import { CodigoIncidenciaIRValues } from '~/@types/enums/CodigoIncidenciaIREnum'
import { CodigoIncidenciaFGValues } from '~/@types/enums/CodigoIncidenciaFGEnum'

const useStyles = makeStyles(() => ({
  fixWidth: {
    minWidth: '145px',
  },
}))

function formatCodigoIncidenciaCP(codigoIncidenciaCP) {
  const objectFinded = CodigoIncidenciaCPValues.find((obj) => obj.value === codigoIncidenciaCP)
  return objectFinded.name
}

function formatCodigoIncidenciaIR(codigoIncidenciaIR) {
  const objectFinded = CodigoIncidenciaIRValues.find((obj) => obj.value === codigoIncidenciaIR)
  return objectFinded.name
}

function formatCodigoIncidenciaFG(codigoIncidenciaFG) {
  const objectFinded = CodigoIncidenciaFGValues.find((obj) => obj.value === codigoIncidenciaFG)
  return objectFinded.name
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const classes = useStyles()

  const data = _data.map((item) => {
    return {
      ...item,
      codigo: item?.evento?.codigo || '',
      dtReciboEsocial: item?.dtReciboEsocial
        ? moment(item.dtReciboEsocial).format('DD/MM/yyyy')
        : '',
      dtInicioValidadeESocial: item?.dtInicioValidadeESocial
        ? moment(item.dtInicioValidadeESocial).format('yyyy/MM')
        : '',
      dtFimValidadeESocial: item?.dtFimValidadeESocial
        ? moment(item.dtFimValidadeESocial).format('yyyy/MM')
        : '',
      codigoIncidenciaCP: formatCodigoIncidenciaCP(item?.codigoIncidenciaCP),
      codigoIncidenciaIR: formatCodigoIncidenciaIR(item?.codigoIncidenciaIR),
      codigoIncidenciaFG: formatCodigoIncidenciaFG(item?.codigoIncidenciaFG),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'codigoIncidenciaCP',
        label: 'Previdência',
      },
      {
        name: 'codigoIncidenciaFG',
        label: 'FGTS',
      },
      {
        name: 'codigoIncidenciaIR',
        label: 'IRRF',
      },
      {
        name: 'dtInicioValidadeESocial',
        label: 'Início eSocial',
      },
      {
        name: 'dtFimValidadeESocial',
        label: 'Fim eSocial',
      },
      {
        name: 'reciboEsocial',
        label: 'Recibo eSocial',
      },
      {
        name: 'dtReciboEsocial',
        label: 'Data Recibo eSocial',
        options: {
          setCellHeaderProps: () => ({ className: classes.fixWidth }),
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Gerar Auditoria"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('gerar-auditoria', value)
                  }}
                >
                  <DescriptionIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick, classes],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'reciboEsocial'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
