import { useMutation, useQueryClient } from 'react-query'
import api from '~/services/api-pessoal'

import { Terceiros } from './Terceiros'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryListarTerceiros } from './useListarTerceiros'

export default function useAtualizarTerceiros() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest(data: Terceiros) {
    await api.put('/Terceiros/' + data.id, data)
  }
  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
    onSuccess: async () => {
      notification.put()
      await queryClient.invalidateQueries(queryListarTerceiros)
    },
  })
}
