import React, { useState, useCallback, useEffect } from 'react'

import { PageHeader, ContentContainer } from 'mio-library-ui'
import { Box, Dialog, Slide, IconButton } from '@material-ui/core'
import { Edit as EditIcon, Close as CloseIcon } from '@material-ui/icons'

import { getToken } from '~/services/credentials'

import useResize from '~/hooks/useResize'
import ReporterSelector from './components/ReporterSelector'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const baseUrl = `${import.meta.VITE_PESSOAL_API_URL}/api/Relatorio/Redirect`

const ReportViewer = (props) => {
  const { reportKey, sourceUrl, title, open, onClose, editable } = props
  const { height, width } = useResize()
  const [report, setReport] = useState()
  const [reportUrl, setReportUrl] = useState('')
  const [mode, setMode] = useState('Preview')
  const token = getToken()

  useEffect(() => {
    const makeReportUrl = () => {
      const url = new URL(baseUrl)
      url.searchParams.append('token', token)
      url.searchParams.append('url', sourceUrl)
      url.searchParams.append('dcPrintMode', mode)
      url.searchParams.append('dcPrintWidth', width - 60)
      url.searchParams.append('dcPrintHeight', height - 190)
      url.searchParams.append('dcPrintRelatorioId', report.id)
      setReportUrl(url.href)
    }

    if (report) {
      makeReportUrl()
    }
  }, [report, height, width, sourceUrl, token, mode])

  const handleChangeReport = useCallback((_report) => {
    setReport(_report)
  }, [])

  const handleChangeMode = (m) => {
    setMode(m)
  }

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <ContentContainer>
        <PageHeader title={title}>
          {editable && (
            <Box pr={1}>
              <IconButton
                size="small"
                onClick={() => handleChangeMode(mode === 'Preview' ? 'Designer' : 'Preview')}
              >
                {mode === 'Preview' && <EditIcon fontSize="small" />}
                {mode === 'Designer' && <CloseIcon fontSize="small" />}
              </IconButton>
            </Box>
          )}
          <Box width={300}>
            <ReporterSelector reportKey={reportKey} value={report} onChange={handleChangeReport} />
          </Box>

          <Box pl={2}>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </PageHeader>

        <iframe title={title} width={width - 30} height={height - 150} src={reportUrl}></iframe>
      </ContentContainer>
    </Dialog>
  )
}

ReportViewer.defaultProps = {
  title: 'Relatório',
  editable: false,
}

export default ReportViewer
