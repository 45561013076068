import axios from 'axios'
import authApi from './api-auth'
import { getToken, getRefreshToken, setToken, setRefreshToken } from './credentials'

const api = axios.create({
  baseURL: `${import.meta.env.VITE_APILOG_URL}/api`,
})

api.interceptors.request.use(async (config) => {
  const conf = config
  const token = getToken()

  if (token && token !== 'undefined') {
    conf.headers.Authorization = `Bearer ${token}`
  }

  if (!conf.headers['Content-Type']) {
    conf.headers['Content-Type'] = 'application/json;charset=UTF-8'
  }

  conf.headers['Resposta-Compactada'] = 'Nunca'
  conf.headers['Access-Control-Allow-Origin'] = '*'
  conf.headers['Access-Control-Allow-Credentials'] = true
  conf.headers['Allow'] = 'GET, POST, PUT, DELETE, OPTIONS, HEAD'
  conf.headers['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
  conf.headers['Access-Control-Allow-Headers'] =
    'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept'
  return conf
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest.cancelToken
    ) {
      originalRequest.cancelToken = true
      const access_token = await refreshAccessToken()
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
      originalRequest.headers['Authorization'] = 'Bearer ' + access_token
      return api(originalRequest)
    }
    return Promise.reject(error)
  },
)

const refreshAccessToken = async () => {
  const token = getToken()
  const refreshToken = getRefreshToken()

  const response = await authApi.post('refresh-token', {
    token,
    refreshToken,
  })
  setToken(response.data.token)
  setRefreshToken(response.data.refreshToken)
  return response.data.token
  // console.log('RESPONSE REFRESH TOKEN', response)
}

export default api
