import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'

import { PDFViewer, TreeView, PageHeader } from '~/components'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useGetByVinculo from '~/hooks/queries/VinculoFerias/useGetByVinculo'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import {
  motivosPerdaFeriasConts,
  motivosPerdaFeriasValues,
} from '~/values/motivosPerdaFeriasValues'
import { getDateCurrent } from '~/utils/utils'

export default function ComunicadoPerdaDireitoFerias() {
  const [vinculo, setVinculo] = useState(null)

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const {
    close: closeDialogConfirm,
    data: dataDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog(null)
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Estagiario_3,
  ])
  const { anoMes, estabelecimento } = useAmbiente()
  const {
    data: _data,
    isLoading: _isLoading,
    isFetching,
    refetch,
  } = useGetByVinculo({ anoMes, vinculoId: vinculo?.vinculoId, isApenasAbertas: false })
  const isLoading = _isLoading || isFetching
  const data = _data || []

  useEffect(() => {
    setVinculo(null)
  }, [anoMes, estabelecimento])

  function handleAfterConfirm(dt) {
    openPDFViewer({
      vinculosFeriasIds: [dt.vinculoFerias.id],
      ...dt,
    })
  }

  async function handleClosePDFViewer() {
    closePDFViewer()
    await refetch()
  }

  function onClickPrintItem(id) {
    let vinculoFerias = data.find((d) => d.id === id)
    vinculoFerias.vinculo = vinculo
    const motivoPerdaItem = motivosPerdaFeriasValues.find((d) =>
      vinculoFerias?.observacao?.includes(d.name),
    )
    openDialogConfirm({
      vinculoFerias,
      dtAssinatura: vinculoFerias?.dtPeriodoPerdido || getDateCurrent(),
      motivoPerdaFeriasId: motivoPerdaItem?.value || motivosPerdaFeriasConts.faltouMais32Vezes_1,
    })
  }

  return (
    <TreeView
      onSelectItem={(e, vinculoCurrent) => setVinculo(vinculoCurrent)}
      data={dataMenu}
      isLoading={isLoadingMenu}
      searchBy={['vinculoNome']}
      renderOption={(option) => option?.vinculoNome}
    >
      <Box height={50}>
        <PageHeader
          title="Comunicado de Perda do Direito a Férias"
          subtitle={vinculo?.vinculoNome}
        />
      </Box>

      <Table data={data} isLoading={isLoading} handleClickPrint={onClickPrintItem} />

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        data={dataDialogConfirm}
        onClose={closeDialogConfirm}
        onAfterSubmit={handleAfterConfirm}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={handleClosePDFViewer}
        reportKey="ComunicadoPerdaFerias"
        title="Comunicado de Perda do Direito a Férias"
        axiosConfig={{
          method: 'post',
          url: '/VinculoFerias/ObterComunicadoFeriasPerdidas',
          data: dataPDFViewer,
        }}
      />
    </TreeView>
  )
}
