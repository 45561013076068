import { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'
import * as yup from 'yup'
import { cpf } from 'cpf-cnpj-validator'

import { Grid, makeStyles, Box, Paper, LinearProgress } from '@material-ui/core'

import {
  AlertContainer,
  DatePicker,
  NumeroInscricaoTextField,
  Button,
  ButtonBox,
  PageHeader,
  TextField,
} from '~/components'
import { AutoCompleteVinculo } from '~/components/AutoComplete'

import { verifyIfExistsMatricula } from '~/hooks/useUtils'
import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useVinculoObterMatricula } from '~/hooks/queries/Vinculo/useVinculoObterMatricula'

import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import { IndCadastroMatriculaEnum } from '~/@types/enums/IndCadastroMatriculaEnum'
import { getDateCurrent } from '~/utils/utils'

const { CPF_2 } = tipoInscricaoConsts

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  rootForm: {
    border: theme.shape.border,
    margin: 'auto',
    width: '60%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const schema = yup.object().shape({
  vinculoId: yup.string().required('Informe um Funcionário'),
  novoCPF: yup
    .string()
    .required('Informe o novo CPF')
    .test('validation-inscricao', 'Informe um CPF válido', (value) => cpf.isValid(value)),
  dtAlteracaoCPF: yup.string().required('Informe a Data de Alteração').nullable(),
  novaMatricula: yup.string().required('Informe uma Nova Matrícula'),
  observacao: yup.string().required('Informe uma Observação'),
})

const initialState = {
  dtAlteracaoCPF: getDateCurrent(),
}

const AlterarInscricao = () => {
  const [data, setData] = useState(initialState)
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const { anoMes, estabelecimento } = useAmbiente()
  const notification = useNotification()
  const classes = useStyles()

  const {
    data: matricula,
    isLoading: _isLoadingMatricula,
    isFetching: isFetchingMatricula,
  } = useVinculoObterMatricula({
    estabelecimentoId: estabelecimento.id,
    cpf: data?.CPFAtual || '',
  })
  const isLoadingMatricula = _isLoadingMatricula || isFetchingMatricula

  const isMatriculaSequencial =
    matricula?.indCadastroMatricula !== IndCadastroMatriculaEnum.Preencher_00

  useEffect(() => {
    if (!matricula) return
    setData((prev) => ({ ...prev, novaMatricula: matricula.proximaMatricula }))
  }, [matricula])

  const handleSubmit = () => {
    const insert = async () => {
      setLoading(true)
      try {
        const novaMatricula = data.novaMatricula.trim()
        await verifyIfExistsMatricula(data.empregadorId, novaMatricula)
        await api.post(`Vinculo/AlterarInscricao?vinculoId=${data?.vinculoId}`, {
          ...data,
          novaMatricula,
        })
        setData(initialState)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }

    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <Box className={classes.root}>
      <PageHeader title="Alteração de CPF" />
      <Paper className={classes.rootForm}>
        <Box width="100%" height={4}>
          {isLoadingMatricula && <LinearProgress />}
        </Box>

        <Grid container spacing={2}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <AutoCompleteVinculo
              value={data?.vinculoId || ''}
              estabelecimentoId={estabelecimento?.id}
              anoMes={anoMes}
              required
              name="vinculoId"
              optionId="id"
              validationErrors={validationErrors}
              onChange={(e, vinculo) => {
                const vinculoId = vinculo?.id || ''
                const CPFAtual = vinculo?.pessoaCPF || ''
                const matriculaAtual = vinculo?.matricula || ''
                const empregadorId = vinculo?.empregadorId || ''
                setData((oldState) => ({
                  ...oldState,
                  vinculoId,
                  CPFAtual,
                  matriculaAtual,
                  empregadorId,
                }))
              }}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <NumeroInscricaoTextField label="CPF Atual" value={data?.CPFAtual || ''} disabled />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <NumeroInscricaoTextField
              label="Novo CPF"
              typeMask={CPF_2}
              value={data?.novoCPF || ''}
              required
              name="novoCPF"
              validationErrors={validationErrors}
              onChange={(e, value) => {
                const novoCPF = value
                setData((oldState) => ({ ...oldState, novoCPF }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Matrícula Atual"
              fullWidth
              value={data?.matriculaAtual || ''}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>

          {!isMatriculaSequencial && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AlertContainer title="Matrícula anterior do empregador">
                Matrícula anterior: <strong>{matricula?.ultimaMatricula}</strong>
              </AlertContainer>
            </Grid>
          )}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Nova Matrícula"
              fullWidth
              value={data?.novaMatricula || ''}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 30,
              }}
              required
              name="novaMatricula"
              disabled={isMatriculaSequencial ? true : false}
              title={
                isMatriculaSequencial
                  ? 'A matrícula está configurada como sequêncial no empregador'
                  : ''
              }
              validationErrors={validationErrors}
              onChange={(e) => {
                const novaMatricula = e.target.value
                setData((oldState) => ({ ...oldState, novaMatricula }))
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <DatePicker
              label="Data da Alteração"
              size="small"
              value={data?.dtAlteracaoCPF || null}
              required
              name="dtAlteracaoCPF"
              validationErrors={validationErrors}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(date) => {
                const dtAlteracaoCPF = date?.format('yyyy-MM-DD') || null
                setData((oldState) => ({ ...oldState, dtAlteracaoCPF }))
              }}
            />
          </Grid>

          <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
            <TextField
              label="Observação"
              fullWidth
              value={data?.observacao || ''}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 250,
              }}
              required
              name="observacao"
              validationErrors={validationErrors}
              multiline
              onChange={(e) => {
                const observacao = e.target.value
                setData((oldState) => ({ ...oldState, observacao }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ButtonBox>
              <Button variant="outlined" onClick={() => setData(initialState)}>
                Cancelar
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleValidate}
                isLoading={isLoading}
              >
                Salvar
              </Button>
            </ButtonBox>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default AlterarInscricao
