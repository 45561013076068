import React, { useState } from 'react'

import PropType from 'prop-types'
import api from '~/services/api-pessoal'

import { Box, Tooltip } from '@material-ui/core'

import MUIAutoComplete from '../MUIAutoComplete'

export default function AutoCompleteCID(props) {
  const { label, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/CID')
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => `${option.id} - ${option.descricao}`

  return (
    <Tooltip arrow title="Classificação Internacional de Doenças">
      <Box>
        <MUIAutoComplete
          isLoading={isLoading}
          options={options}
          getOptions={getOptions}
          renderOption={renderOption}
          label={label}
          {...rest}
        />
      </Box>
    </Tooltip>
  )
}

AutoCompleteCID.defaultProps = {
  label: 'CID',
}

AutoCompleteCID.propTypes = {
  getOptionDisabled: PropType.func,
  renderOption: PropType.func,
  onChange: PropType.func,
  options: PropType.array,
  value: PropType.any,
  optionId: PropType.string,
  required: PropType.bool,
  validationErrors: PropType.any,
  name: PropType.string,
  label: PropType.string,
  disabled: PropType.bool,
}
