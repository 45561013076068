import { useEffect, useState } from 'react'

import { useQuery, useQueryClient, useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'

export interface EstabilidadeData {
  id: string
  codigo: number
  descricao: string
  numeroDias: number
}

interface EstabilidadeDataForm {
  id?: string
  codigo?: number
  descricao?: string
  numeroDias?: number
}

export function useEstabilidadeGetAll() {
  const dialogNotification = useDialogNotification()

  async function getAll() {
    const response = await api.get('/Estabilidade')
    return response.data.data
  }

  return useQuery<FixLater, FixLater, EstabilidadeData[]>('Estabilidade', () => getAll(), {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useEstabilidadeById(id: string | null) {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<EstabilidadeDataForm>({})

  const dialogNotification = useDialogNotification()

  useEffect(() => {
    async function getData() {
      if (!id) return
      setLoading(true)
      try {
        const response = await api.get(`/Estabilidade/${id}`)
        setData(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getData()
    //eslint-disable-next-line
  }, [id])

  return { data, setData, isLoading }
}

export function useEstabilidadeMutation() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function update(data: FixLater) {
    await api.put(`/Estabilidade/${data.id}`, data)
    notification.put()
  }
  async function insert(data: FixLater) {
    await api.post('/Estabilidade', data)
    notification.post()
  }

  return useMutation(
    async (data: FixLater) => {
      if (data.id) {
        await update(data)
        return
      }
      await insert(data)
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries('Estabilidade'),
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}

export function useEstabilidadeDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleDelete(id: string) {
    await api.delete(`/Estabilidade/${id}`)
    notification.remove()
  }

  return useMutation((id: string) => handleDelete(id), {
    onSuccess: async () => await queryClient.invalidateQueries('Estabilidade'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
