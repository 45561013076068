import api from '~/services/api-pessoal'
import { RelatorioConsulta } from './dtos/RelatorioConsulta'

export async function relatorioGetByChaveId(reportKey: string) {
  const response = await api.get<{ data: RelatorioConsulta[] }>('/Relatorio/GetByChaveId', {
    params: {
      chaveId: reportKey,
    },
  })

  return response?.data?.data || []
}
