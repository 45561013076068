import { useState, useEffect } from 'react'

import clsx from 'clsx'
import { formatCurrency, setImportantCSS } from '~/hooks/useUtils'

import { makeStyles } from '@material-ui/core'

import { ToolsDataTable } from '~/components'

import FGTSApuracao from './FGTSApuracao'

import useAmbiente from '~/hooks/useAmbiente'

import useObterApuracaoEncargosLotacaoFGTS from '~/hooks/queries/ApurarEncargos/useObterApuracaoEncargosLotacaoFGTS'

import { ApuracaoEncargosFGTSDTO } from '~/hooks/queries/ApurarEncargos/ApuracaoEncargosFGTSDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'

const useStyles = makeStyles((theme: FixLater) => ({
  alignRight: {
    textAlign: 'right',
  },
  hightLightRow: {
    backgroundColor: setImportantCSS(theme.palette.error.light),
    color: setImportantCSS(theme.palette.error.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.error.lighter),
    },
  },
}))

interface FGTSLotacaoProps {
  estabelecimento: ApuracaoEncargosFGTSDTO
}

export default function FGTSLotacao({ estabelecimento }: FGTSLotacaoProps) {
  const [rowsExpanded, setRowsExpanded] = useState([])

  const { anoMes } = useAmbiente()
  const classes = useStyles()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterApuracaoEncargosLotacaoFGTS({
    estabelecimentoId: estabelecimento.id,
    periodoApuracao: anoMes,
  })

  useEffect(() => {
    setRowsExpanded([])
  }, [_d])

  const _data = _d || []

  const data = _data.map((d) => {
    return {
      ...d,
      vrBaseFolha: d?.vrBaseFolha ? formatCurrency(d.vrBaseFolha) : '',
      vrFolha: d?.vrFolha ? formatCurrency(d.vrFolha) : '',
      vrBaseESocial: d?.vrBaseESocial ? formatCurrency(d.vrBaseESocial) : '',
      vrESocial: d?.vrESocial ? formatCurrency(d.vrESocial) : '',
      vrDiferenca: d?.vrDiferenca ? formatCurrency(d.vrDiferenca) : '',
      vrBaseRescisorio: d?.vrBaseRescisorio ? formatCurrency(d.vrBaseRescisorio) : '',
      vrRescisorio: d?.vrRescisorio ? formatCurrency(d.vrRescisorio) : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'vrBaseFolha',
      label: 'Folha Base',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrBaseESocial',
      label: 'ESocial Base',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrBaseRescisorio',
      label: 'Rescisão Base',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrFolha',
      label: 'Folha',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrESocial',
      label: 'ESocial',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrRescisorio',
      label: 'Rescisão',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrDiferenca',
      label: 'Diferença',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      options={{
        setRowProps: (_: FixLater, index: number) => {
          const rowCurrent = _data[index]
          if (!rowCurrent) return
          const isDiferenca = rowCurrent.temDiferenca
          return {
            className: clsx({
              [classes.hightLightRow]: isDiferenca,
            }),
          }
        },
      }}
      optionsExpandable={{
        onRowExpanded: setRowsExpanded,
        rowsExpanded: rowsExpanded,
        type: 'single',
        expandedChildren: (index) => <FGTSApuracao data={_data[index].apuracoes} />,
      }}
    />
  )
}
