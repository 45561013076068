import { useQuery, useQueryClient, useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'
import useNotification from '../useNotification'

export function useGrupoGetAll() {
  const dialogNotification = useDialogNotification()
  async function getAll() {
    const response = await api.get('/Grupo')
    return response.data.data
  }
  return useQuery('Grupo', getAll, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}

export function useGrupoMutation() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function update(data) {
    const response = await api.put(`/Grupo/${data.id}`, data)
    notification.put()
    return response.data.data
  }
  async function insert(data) {
    const response = await api.post('/Grupo', data)
    notification.post()
    return response.data.data
  }

  return useMutation(
    async (data) => {
      if (data?.id) {
        return update(data)
      }
      return insert(data)
    },
    {
      onSuccess: async (data) => {
        await queryClient.cancelQueries('Grupo')
        queryClient.setQueriesData('Grupo', (oldData) => {
          let finded = false
          const newData = oldData.map((obj) => {
            if (obj.id === data.id) {
              finded = true
              return data
            }
            return obj
          })
          if (finded) return newData
          return [data, ...oldData]
        })
      },
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}

export function useGrupoDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleDelete(id) {
    await api.delete(`/Grupo/${id}`)
    notification.remove()
    return id
  }

  return useMutation((id) => handleDelete(id), {
    onSuccess: async (id) => {
      await queryClient.cancelQueries('Grupo')
      queryClient.setQueriesData('Grupo', (old) => {
        const newData = old.filter((d) => d.id !== id)
        return newData
      })
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
