import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryKeyVinculoAfastamentoGetByVinculo } from './useVinculoAfastamentoGetByVinculo'

export function useAfastamentoDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(id: string) {
    await api.delete('/VinculoAfastamento/' + id)
    notification.remove()
  }

  return useMutation((id: string) => handleRequest(id), {
    onSuccess: async () =>
      await queryClient.invalidateQueries(queryKeyVinculoAfastamentoGetByVinculo),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
