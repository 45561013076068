import { useRef } from 'react'

import moment from 'moment'

import { ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import {
  VinculoSalarioBase,
  VinculoSalarioBasePartial,
} from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'
import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import SalarioContratualForm from '~/pages/Funcionario/Forms/SalarioContratualForm'

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoSalarioBasePartial
  vinculo: VinculoPartial
  onAfterSubmitForm: (data: VinculoSalarioBasePartial) => void
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, vinculo, onAfterSubmitForm } = props
  const buttonRef = useRef<HTMLButtonElement>(null)

  const dialogNotification = useDialogNotification()

  const handleSubmit = (data: VinculoSalarioBase) => {
    if (vinculo?.dtAdmissao) {
      if (moment(vinculo.dtAdmissao).isAfter(data?.dtSalario))
        return dialogNotification.warning({
          descriptions: ['A Data do Salário não pode ser menor que a Data de Admissão'],
        })

      if (moment(vinculo.dtAdmissao).isAfter(data?.dtEfetiva))
        return dialogNotification.warning({
          descriptions: ['A Data Efetiva não pode ser menor que a Data de Admissão'],
        })
    }
    onAfterSubmitForm(data)
  }

  function handleClickSave() {
    buttonRef.current?.click()
  }

  return (
    <ActionDialog
      title="Cadastro de Salário Contratual"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleClickSave}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <SalarioContratualForm
        data={_data as FixLater}
        onSubmit={handleSubmit}
        buttonRef={buttonRef}
      />
    </ActionDialog>
  )
}
