import { useRef, useState } from 'react'

import { Box, Paper, useTheme } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer, Finder } from '~/components'

import Table from './components/Table'
import DialogConfirm, { DataConfirm } from './components/DialogConfirm'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { extractSelectedRowsTable } from '~/utils/utils'

import { EtiquetaCartaoPontoRequestDTO } from '~/hooks/queries/EtiquetaCartaoPonto/dtos/EtiquetaCartaoPontoRequestDTO'

const TITLE_PAGE = 'Etiqueta Cartão de Ponto'

export default function EtiquetaCartaoPonto() {
  const [query, setQuery] = useState('')

  const tableRef = useRef<FixLater>(null)
  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const theme = useTheme()

  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog()

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<EtiquetaCartaoPontoRequestDTO>({
    funcionariosIds: [],
    isImprimirComHorarios: true,
    anoMes,
    observacao: '',
  })

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    estabelecimentoId: estabelecimento.id,
    anoMes,
  })
  const data = _d || []

  function handleAfterConfirm(dataConfirmed: DataConfirm) {
    const rowsSelecteds = extractSelectedRowsTable(tableRef)
    if (rowsSelecteds.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um funcionário para prosseguir'],
      })
    }
    const funcionariosIds = rowsSelecteds.map((rowIndex) => data[rowIndex].id)
    openPDFViewer({
      funcionariosIds,
      isImprimirComHorarios: dataConfirmed.isImprimirComHorarios,
      anoMes,
      observacao: dataConfirmed.observacao,
    })
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box minHeight={200} flex={1} overflow="auto" position="relative">
        <Box position="absolute" width="100%">
          <Table
            query={query}
            tableRef={tableRef}
            data={data}
            isFetching={isFetching}
            isLoading={isLoading}
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" p={1} component={Paper}>
        <PrintButton onClick={openDialogConfirm} />
      </Box>

      {isOpenDialogConfirm && (
        <DialogConfirm
          isOpen={isOpenDialogConfirm}
          onClose={closeDialogConfirm}
          onAfterSubmit={handleAfterConfirm}
        />
      )}

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="EtiquetaCartaoPonto"
          title={TITLE_PAGE}
          axiosConfig={{
            method: 'post',
            url: '/Relatorio/EtiquetaCartaoPonto/ObterRelatorio',
            data: dataPDFViewer,
          }}
        />
      )}
    </Box>
  )
}
