import React, { useEffect, useState, useCallback } from 'react'

import { Button, ButtonBox } from 'mio-library-ui'
import { Box, Collapse, Grid, makeStyles } from '@material-ui/core'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import api from '~/services/api-pessoal'
import _ from 'lodash'

import { AlertContainer } from '~/components'
import MultipleSelect from '~/components/MultipleSelect'

import Table from './components/Table'
import { GRUPO_FECHAMENTO, GRUPO_PERIODICOS } from '~/pages/TransmissaoESocial'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { IndApuracaoValues, IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

const useStyles = makeStyles((theme) => ({
  rootCollapse: {
    paddingBottom: theme.spacing(1),
  },
  contentAlert: {
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  alignCenterIndApuracao: {
    display: 'grid',
    alignItems: 'center',
  },
  alignFilters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  alignSelf: {
    alignSelf: 'center',
  },
}))

function getDataWithEmpregadorFirstPosition(data, empregadorId) {
  if (!(data?.length > 0)) return []
  let indexFinded = undefined
  const dataFinded = data.find((d, index) => {
    if (d.empregadorId === empregadorId) {
      indexFinded = index
      return true
    }
    return false
  })
  if (!dataFinded) return data
  const newData = [...data]
  newData.splice(indexFinded, 1)
  newData.unshift(dataFinded)
  return newData
}

export default function DinamicTab(props) {
  const { formCollapseIsOpen, query, grupoEvento, onAfterSubmit } = props

  const [filters, setFilters] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isLoadingSubmit, setLoadingSubmit] = useState(false)
  const [totalEvents, setTotalEvents] = useState(0)
  const [collection, setCollection] = useState([])
  const [collectionTable, setCollectionTable] = useState([])
  const [eventsSelecteds, setEventsSelecteds] = useState([])
  const [hasFilterApuracao, setHasFilterApuracao] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes, mes, empregador } = useAmbiente()
  const classes = useStyles()

  const [data, setData] = useState({
    filtersCurrent: [],
    indApuracao: IndApuracaoEnum.Mensal_1,
  })

  const getCollection = useCallback(
    async (indApuracao) => {
      setLoading(true)
      try {
        const response = await api.get('/ESocial/ConsultarAguardando', {
          params: {
            grupoEvento,
            anoMes,
            indApuracao,
          },
        })
        if (!response?.data?.data) return
        const newFilters = []
        let newTotalEvents = 0
        const newData = response.data.data.map((d) => {
          newTotalEvents += d.eventos.length
          const newEventos = d.eventos.map((evento) => {
            if (!newFilters.some((filterCurrent) => filterCurrent.value === evento.eventoEsocial)) {
              newFilters.push({
                value: evento.eventoEsocial,
                name: evento.descricao,
              })
            }
            return {
              ...evento,
              empregadorId: d.empregadorId,
            }
          })
          return {
            ...d,
            eventos: newEventos,
          }
        })
        const dataWithEmpregadorFirst = getDataWithEmpregadorFirstPosition(newData, empregador?.id)
        setTotalEvents(newTotalEvents)
        setFilters(newFilters)
        setCollection(dataWithEmpregadorFirst)
        setCollectionTable(dataWithEmpregadorFirst)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    },
    // eslint-disable-next-line
    [anoMes, grupoEvento, empregador],
  )

  useEffect(() => {
    getCollection(IndApuracaoEnum.Mensal_1)
  }, [getCollection])

  useEffect(() => {
    function verifyHaveFilterApuracao() {
      if ((grupoEvento === GRUPO_FECHAMENTO || grupoEvento === GRUPO_PERIODICOS) && mes === '12') {
        setHasFilterApuracao(true)
      } else {
        setHasFilterApuracao(false)
      }
    }
    verifyHaveFilterApuracao()
  }, [grupoEvento, mes])

  function changeTableByFilters(filters) {
    if (filters.length === 0) return setCollectionTable(collection)
    const newCollection = []
    filters.forEach((filterCurrent) => {
      collection.forEach((empregadorCurrent) => {
        empregadorCurrent.eventos.forEach((eventCurrent) => {
          if (filterCurrent.value === eventCurrent.eventoEsocial) {
            let indexFinded = null
            newCollection.forEach((oldItem, index) => {
              if (oldItem.empregadorId === empregadorCurrent.empregadorId) indexFinded = index
            })

            if (indexFinded !== null) {
              const empregadorOld = newCollection[indexFinded]
              const newEvents = [...empregadorOld.eventos, eventCurrent]
              const eventsOrdeneds = _.orderBy(newEvents, ['eventoEsocial'], 'asc')
              newCollection.push({
                ...empregadorOld,
                eventos: eventsOrdeneds,
              })
              newCollection.splice(indexFinded, 1)
              return
            }

            newCollection.push({
              ...empregadorCurrent,
              eventos: [eventCurrent],
            })
          }
        })
      })
    })
    const collectionOrdened = _.orderBy(newCollection, ['empregadorNome'], 'asc')
    setCollectionTable(collectionOrdened)
  }

  async function handleSubmitProvisorio() {
    try {
      await api.post('/ESocial/TransmitirEventos', [], {
        params: {
          anoMes,
          indApuracao: data.indApuracao,
        },
      })
      notification.post()
      setEventsSelecteds([])
      getCollection(data.indApuracao)
      onAfterSubmit()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  async function handleSubmit() {
    if (eventsSelecteds.length === 0) {
      //essa função será removida
      handleSubmitProvisorio()
      return notification.error('Informe pelo menos um Evento')
    }
    setLoadingSubmit(true)
    try {
      const newData = []
      eventsSelecteds.forEach((eventCurrent) => {
        const indexFinded = newData.findIndex(
          (oldEvent) => oldEvent.empregadorId === eventCurrent.empregadorId,
        )
        if (indexFinded !== -1) {
          newData[indexFinded].eventos = [
            ...newData[indexFinded].eventos,
            eventCurrent.eventoEsocial,
          ]
          return
        }
        const newElement = {
          empregadorId: eventCurrent.empregadorId,
          eventos: [eventCurrent.eventoEsocial],
        }
        newData.push(newElement)
      })
      await api.post('/ESocial/TransmitirEventos', newData, {
        params: {
          anoMes,
          indApuracao: data.indApuracao,
        },
      })
      notification.post()
      setEventsSelecteds([])
      getCollection(data.indApuracao)
      onAfterSubmit()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <Box height="100%" width="100%">
      <Collapse in={formCollapseIsOpen}>
        <Box p={1}>
          <Grid container spacing={1}>
            <Grid
              item
              xl={hasFilterApuracao ? 3 : 6}
              lg={hasFilterApuracao ? 3 : 6}
              md={hasFilterApuracao ? 6 : 12}
              sm={12}
              xs={12}
              className={classes.alignCenter}
            >
              <MultipleSelect
                inputProps={{
                  label: 'Filtrar por Evento',
                }}
                disabled={filters.length === 0 ? true : false}
                getOptionLabel={(option) => option.name}
                options={filters}
                onChange={(e, selecteds) => changeTableByFilters(selecteds)}
              />
            </Grid>

            {hasFilterApuracao && (
              <Grid
                item
                xl={3}
                lg={3}
                md={6}
                sm={12}
                xs={12}
                className={classes.alignCenterIndApuracao}
              >
                <MUIAutoComplete
                  label="Indicador de Apuração"
                  options={IndApuracaoValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  value={data.indApuracao}
                  onChange={(e, obj) => {
                    const indApuracao = obj ? obj.value : ''
                    if (indApuracao) getCollection(indApuracao)
                    setData((oldState) => ({ ...oldState, indApuracao }))
                  }}
                />
              </Grid>
            )}

            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <AlertContainer>
                <Grid container spacing={1}>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className={classes.alignSelf}>
                    {eventsSelecteds.length === 0 ? (
                      'Selecione os Eventos para serem Enviados para o eSocial'
                    ) : (
                      <div>
                        <strong>{`${eventsSelecteds.length || 0}/${totalEvents || 0}`}</strong> -
                        Eventos Selecionados
                      </div>
                    )}
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.alignSelf}>
                    <ButtonBox>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        isLoading={isLoadingSubmit}
                      >
                        Enviar para o eSocial
                      </Button>
                    </ButtonBox>
                  </Grid>
                </Grid>
              </AlertContainer>
            </Grid>
          </Grid>
        </Box>
      </Collapse>

      <Table
        data={collectionTable}
        isLoading={isLoading}
        query={query}
        eventsSelecteds={eventsSelecteds}
        setEventsSelecteds={setEventsSelecteds}
        triggersHeight={{ formCollapseIsOpen }}
      />
    </Box>
  )
}
