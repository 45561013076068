import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ESocialGrupoEventoConsultaEnum } from '~/@types/enums/ESocialGrupoEventoConsultaEnum'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

export const queryUseObterTotalEventosGrupoAcompanhamento =
  '/ESocial/ObterTotalEventosGrupoAcompanhamento'

export interface ESocialTotalizarEventosDTO {
  grupoEventos: ESocialGrupoEventoConsultaEnum
  totalEventos: number
}

interface UseObterTotalEventosGrupoAcompanhamentoProps {
  anoMes: string
  grupoId: string | null
  filterEventos: ESocialEventoEnum[]
  indApuracao: IndApuracaoEnum
}

export function useObterTotalEventosGrupoAcompanhamento(
  props: UseObterTotalEventosGrupoAcompanhamentoProps,
) {
  const { anoMes, grupoId, filterEventos, indApuracao } = props

  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: ESocialTotalizarEventosDTO[] }>(
      queryUseObterTotalEventosGrupoAcompanhamento,
      filterEventos,
      {
        params: { anoMes, grupoId, indApuracao },
      },
    )
    return response?.data?.data || []
  }

  const query = useQuery(
    [queryUseObterTotalEventosGrupoAcompanhamento, anoMes, grupoId, filterEventos, indApuracao],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )

  return {
    ...query,
    data: query?.data || [],
  }
}
