import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { TipoAssinaturaEnum, TipoAssinaturaValues } from '~/@types/enums/TipoAssinaturaEnum'
import { MUIAutoComplete } from '~/components/AutoComplete'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtAssinatura: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .when(['tipoAssinatura'], (tipoAssinatura: TipoAssinaturaEnum, schema) => {
      if (tipoAssinatura === TipoAssinaturaEnum.InformarData) {
        return schema.required('Informe a Data da Assinatura')
      }
      return schema
    }),
})

export interface DataFormProps {
  tipoAssinatura: TipoAssinaturaEnum
  dtAssinatura: string | null
}

interface DialogConfirmDtAssinaturaProps {
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: (data: DataFormProps) => void
  startData?: DataFormProps | null
}

export default function DialogConfirmDtAssinatura({
  isOpen,
  onClose,
  onAfterSubmit,
  startData,
}: DialogConfirmDtAssinaturaProps) {
  const [data, setData] = useState(
    startData || {
      tipoAssinatura: TipoAssinaturaEnum.InformarData,
      dtAssinatura: getDateCurrent(),
    },
  )

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Informe as seguintes informações"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIAutoComplete
            label="Informe a data de Emissão do Documento"
            options={TipoAssinaturaValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.tipoAssinatura}
            onChange={(e, obj) => {
              const tipoAssinatura =
                obj?.value in TipoAssinaturaEnum ? obj.value : TipoAssinaturaEnum.InformarData
              let dtAssinatura = null

              if (tipoAssinatura === TipoAssinaturaEnum.InformarData) {
                dtAssinatura = getDateCurrent()
              }

              setData((prev) => ({
                ...prev,
                tipoAssinatura,
                dtAssinatura,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            validationErrors={validationErrors}
            name="dtAssinatura"
            required={data.tipoAssinatura === TipoAssinaturaEnum.InformarData}
            label="Data da Assinatura"
            disabled={data.tipoAssinatura !== TipoAssinaturaEnum.InformarData}
            value={data.dtAssinatura}
            onChange={(date) => {
              const dtAssinatura = date
              setData((prev) => ({
                ...prev,
                dtAssinatura,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
