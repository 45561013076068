import { Grid } from '@material-ui/core'

import { NumeroInscricaoTextField, Checkbox, Fieldset } from '~/components'

import { VinculoAfastamentoForm } from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'

import { useStepperContext } from '~/components/StepperForm'

import { ContextProps, titleAllFieldsDisabled } from '..'

export default function MandatoEleitoral() {
  const {
    dataControlled: data,
    onChangeControlled: setData,
    context,
  } = useStepperContext<VinculoAfastamentoForm>()
  const { disabledAllFields } = context as ContextProps

  return (
    <Fieldset disabled={disabledAllFields} title={disabledAllFields ? titleAllFieldsDisabled : ''}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NumeroInscricaoTextField
            label="Eletivo Nr. Inscrição"
            value={data?.eletivoNrInscricao || ''}
            onChange={(e, value) => {
              const eletivoNrInscricao = value
              setData({ ...data, eletivoNrInscricao })
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Checkbox
            label="Eletivo Informação Ônus"
            value={data?.eletivoInformacaoRemuneracao || false}
            onChange={(e, value) => {
              const eletivoInformacaoRemuneracao = value
              setData({ ...data, eletivoInformacaoRemuneracao })
            }}
          />
        </Grid>
      </Grid>
    </Fieldset>
  )
}
