import { useState } from 'react'

export default function useDialog<T>(dataStart = null as T, isOpenStart = false) {
  const [dialog, setDialog] = useState({
    data: dataStart,
    isOpen: isOpenStart,
  })

  function close() {
    setDialog({
      data: dataStart,
      isOpen: isOpenStart,
    })
  }

  function open(data: T) {
    setDialog({ isOpen: true, data })
  }

  return {
    isOpen: dialog.isOpen,
    data: dialog.data,
    close,
    open,
  }
}
