import { CopyLabel, DatePickerNew, TextField, ToolsDataTable } from '~/components'

import { EmpregadorConsulta } from '~/hooks/queries/EmpregadorConsulta/EmpregadorConsulta'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import { Box } from '@material-ui/core'
import { AutoCompleteGrupo, MUIAutoComplete } from '~/components/AutoComplete'
import {
  IndCadastroMatriculaEnum,
  IndCadastroMatriculaValues,
} from '~/@types/enums/IndCadastroMatriculaEnum'
import { useEffect, useState } from 'react'

interface TextFieldCellProps {
  value: string
  disabled: boolean
  onBlur: (value: string) => void
}

function TextFieldCell(props: TextFieldCellProps) {
  const { value: _value, disabled, onBlur } = props
  const [value, setValue] = useState(_value)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  return (
    <TextField
      onlyNumber
      value={value}
      onChange={(e) => {
        const newValue = e.target.value
        setValue(newValue)
      }}
      onBlur={() => onBlur(value)}
      disabled={disabled}
      inputProps={{
        style: {
          cursor: disabled ? 'not-allowed' : 'auto',
        },
      }}
    />
  )
}

interface TableProps {
  data: EmpregadorConsulta[]
  isLoading: boolean
  isFetching: boolean
  query: string
  onChange: (data: EmpregadorConsulta[]) => void
}

export default function Table({ data, isLoading, isFetching, query, onChange }: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigoString',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'nrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'id',
      label: 'Grupo',
      options: {
        setCellProps: () => ({
          style: { width: 250 },
        }),
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return
          return (
            <AutoCompleteGrupo
              label=""
              value={dtCurrent.grupoId}
              onChange={(e, obj) => {
                const grupoId = obj?.id || ''
                onChange(data.map((d) => (d.id === dtCurrent.id ? { ...dtCurrent, grupoId } : d)))
              }}
            />
          )
        },
      },
    },
    {
      name: 'id',
      label: 'Fim Atividade',
      options: {
        setCellProps: () => ({
          style: { width: 180 },
        }),
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return
          return (
            <DatePickerNew
              value={dtCurrent.dtFimAtividade}
              onChange={(date) => {
                const dtFimAtividade = date
                if (!dtFimAtividade) return
                onChange(
                  data.map((d) => (d.id === dtCurrent.id ? { ...dtCurrent, dtFimAtividade } : d)),
                )
              }}
            />
          )
        },
      },
    },
    {
      name: 'id',
      label: 'Fim Contabilidade',
      options: {
        setCellProps: () => ({
          style: { width: 180 },
        }),
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return
          return (
            <DatePickerNew
              value={dtCurrent.dtFimContabilidade}
              onChange={(date) => {
                const dtFimContabilidade = date
                if (!dtFimContabilidade) return
                onChange(
                  data.map((d) =>
                    d.id === dtCurrent.id ? { ...dtCurrent, dtFimContabilidade } : d,
                  ),
                )
              }}
            />
          )
        },
      },
    },
    {
      name: 'id',
      label: 'Data Inativo',
      options: {
        setCellProps: () => ({
          style: { width: 180 },
        }),
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return
          return (
            <Box p={0.3}>
              <DatePickerNew
                value={dtCurrent.dtInativo}
                onChange={(date) => {
                  const dtInativo = date
                  if (!dtInativo) return
                  onChange(
                    data.map((d) => (d.id === dtCurrent.id ? { ...dtCurrent, dtInativo } : d)),
                  )
                }}
              />
            </Box>
          )
        },
      },
    },
    {
      name: 'id',
      label: 'Como a matrícula deve ser preenchida',
      options: {
        setCellProps: () => ({
          style: { width: 250 },
        }),
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return
          return (
            <MUIAutoComplete
              label=""
              options={IndCadastroMatriculaValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={dtCurrent.indCadastroMatricula}
              onChange={(e, obj) => {
                const indCadastroMatricula = obj.value
                onChange(
                  data.map((d) =>
                    d.id === dtCurrent.id ? { ...dtCurrent, indCadastroMatricula } : d,
                  ),
                )
              }}
            />
          )
        },
      },
    },
    {
      name: 'id',
      label: 'Matrícula de Origem',
      options: {
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return
          return (
            <TextFieldCell
              value={dtCurrent.matriculaOrigem || ''}
              disabled={dtCurrent.indCadastroMatricula !== IndCadastroMatriculaEnum.Sequencial_01}
              onBlur={(newValue) => {
                const matriculaOrigem = newValue
                onChange(
                  data.map((d) => (d.id === dtCurrent.id ? { ...dtCurrent, matriculaOrigem } : d)),
                )
              }}
            />
          )
        },
      },
    },
    {
      name: 'matricula',
      label: 'Matrícula Anterior',
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'nrInscricao'],
      }}
    />
  )
}
