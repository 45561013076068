import { useMemo } from 'react'

import { IconButton } from '@material-ui/core'

import { formatToCNPJ } from 'brazilian-values'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox } from 'mio-library-ui'

import { useQuery } from '~/hooks/queries/useVinculoExameToxicologico'

import { ActionMenu, HeightDataTable } from '~/components'

import moment from 'moment'

export default function Table(props) {
  const { vinculo, onItemClick, isLoadingRetificar } = props
  const { data: _data, isLoading } = useQuery(vinculo?.vinculoId)
  const data = _data || []

  const columns = useMemo(
    () => [
      {
        name: 'dtExame',
        label: 'Data do Exame',
        options: {
          customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
        },
      },
      {
        name: 'dtVencimento',
        label: 'Data do Vencimento',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
        },
      },
      {
        name: 'nrInscricaoLaboratorio',
        label: 'CNPJ Laboratório',
        options: {
          customBodyRender: formatToCNPJ,
        },
      },
      {
        name: 'codigoExame',
        label: 'Codigo do Exame',
      },
      {
        name: 'reciboEsocial',
        label: 'eSocial',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            const rowCurrent = _data.find((d) => d.id === value)
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <ActionMenu
                  isLoading={isLoadingRetificar}
                  options={[
                    {
                      icon: 'replay',
                      title: 'Retificar este registro',
                      label: 'Retificar',
                      onClick: () => onItemClick('retificar', value),
                      disabled: !rowCurrent?.reciboEsocial,
                    },
                  ]}
                />

                <IconButton
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  size="small"
                  onClick={() => {
                    onItemClick('edit', rowCurrent)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" size="small" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  size="small"
                  onClick={() => onItemClick('delete', value)}
                >
                  <DeleteIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [_data, onItemClick, isLoadingRetificar],
  )

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isLoadingRetificar}
    />
  )
}
