import React, { useState, useEffect } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { AutoCompleteCBO } from '~/components/AutoComplete'

import { useCargoMutation } from '~/hooks/queries/useCargo'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  cboId: yup.string().required('Informe o CBO'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data } = props
  const [data, setData] = useState({})

  const { mutateAsync, isLoading } = useCargoMutation()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = async () => {
    await mutateAsync(data)
    onClose()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Cargo"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
          <TextField
            label="Código"
            fullWidth
            value={data?.codigo || ''}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const codigo = e.target.value
              setData({ ...data, codigo })
            }}
            disabled
          />
        </Grid>

        <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data?.nome || ''}
            variant="outlined"
            size="small"
            name="nome"
            autoFocus
            required
            validationErrors={validationErrors}
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteCBO
            name="cboId"
            required
            validationErrors={validationErrors}
            value={data?.cboId || null}
            optionId="id"
            onChange={(e, cbo) => {
              const cboId = cbo?.id || ''
              setData({ ...data, cboId, cbo })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Descrição Cargo"
            value={data?.descricaoAtividades || ''}
            fullWidth
            multiline
            inputProps={{
              maxLength: 999,
            }}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const descricaoAtividades = e?.target?.value || ''
              setData((oldState) => ({ ...oldState, descricaoAtividades }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
