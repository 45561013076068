import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'
import { TransmissaoEnvioChartDTO } from './dtos/TransmissaoEnvioChartDTO'

const query = '/Painel/TransmissaoEnvioChart'

interface RequestParams {
  anoMes: string
  empregadorId?: string
}

export default function usePainelTransmissaoEnvioChart(params: RequestParams) {
  async function handleRequest() {
    const response = await api.get<{ data: TransmissaoEnvioChartDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: console.error,
  })
}
