import { useEffect, useState } from 'react'

import { Box, Paper, useTheme } from '@material-ui/core'

import { Button } from '~/components'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'
import { useValeTransporteApuracaoApurar } from '~/hooks/queries/ValeTransporteApuracao/useValeTransporteApuracaoApurar'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'

import { TabType, useValeTransporteApuracaoContext } from '../Context'

export default function Apuracao() {
  const { grupoId, isDescontarFaltas, query, setTab } = useValeTransporteApuracaoContext()

  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const { anoMes } = useAmbiente()
  const theme = useTheme()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useEstabelecimentoObterFiltrado({
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
    grupoId,
    anoMes,
  })
  const data = _data || []

  useEffect(() => {
    setRowsSelected([])
  }, [_data])

  const { mutateAsync, isLoading: isSubmitting } = useValeTransporteApuracaoApurar()

  async function handleSubmit() {
    await mutateAsync({
      data: rowsSelected.map((index) => data[index].id),
      params: {
        competencia: anoMes,
        isDescontarFaltas,
      },
    })
    setTab(TabType.Consulta)
  }

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(0.5)}
    >
      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%">
          <Table
            data={data}
            isFetching={isFetching}
            isLoading={isLoading}
            query={query}
            rowsSelected={rowsSelected}
            onRowsSelected={setRowsSelected}
          />
        </Box>
      </Box>

      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Apurar
        </Button>
      </Box>
    </Box>
  )
}
