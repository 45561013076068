import { useMemo, useState } from 'react'

import moment from 'moment'
import { distinctByField, orderBy } from '~/utils/utils'

import { Box, Grid, Paper, Slide, Typography, useTheme } from '@material-ui/core'

import { Button, CopyLabel, Finder, PDFViewer, PageHeader, ToolsDataTable } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import { useHistory, useLocation } from 'react-router-dom'

import useObterFeriasVencidas from '~/hooks/queries/FeriasVencidas/useObterFeriasVencidas'
import useEnviarPorEmail from '~/hooks/queries/FeriasVencidas/useEnviarPorEmail'

import {
  RelatorioFeriasVencidasStateTransferencia,
  timeAnimation,
} from '../RelatorioFeriasVencidasMain'
import { MUIDataTableColumnDef } from 'mui-datatables'
import useDialog from '~/hooks/useDialog'

import {
  FeriasVencidasRelatorioEnum,
  FeriasVencidasRelatorioValues,
} from '~/@types/enums/FeriasVencidasRelatorioEnum'
import { RelFeriasVencidasDTO } from '~/hooks/queries/FeriasVencidas/dtos/RelFeriasVencidasDTO'

export default function RelatorioFeriasVencidasResult() {
  const [data, setData] = useState<{
    setorId: string | null
    agrupar: FeriasVencidasRelatorioEnum
  }>({
    setorId: null,
    agrupar: FeriasVencidasRelatorioEnum.AgruparEstabelecimento,
  })
  const [query, setQuery] = useState('')

  const { state: _state } = useLocation<RelatorioFeriasVencidasStateTransferencia | undefined>()
  const history = useHistory()
  const { anoMes } = useAmbiente()
  const theme = useTheme()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<{
    data: string[]
    params: {
      dtLimite: string
      anoMes: string
      agrupar: FeriasVencidasRelatorioEnum
      setorId: string | null
    }
  }>({
    data: [],
    params: {
      anoMes,
      dtLimite: '',
      agrupar: FeriasVencidasRelatorioEnum.AgruparEstabelecimento,
      setorId: null,
    },
  })

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterFeriasVencidas({
    data: _state?.estabelecimentosId || [],
    params: {
      anoMes,
      dtLimite: _state?.dtLimite || '',
    },
  })
  const collection = useMemo(() => {
    const dt = _data || []
    if (data.setorId) {
      return dt.reduce<RelFeriasVencidasDTO[]>((prev, current) => {
        if (data.setorId == current.setorId) {
          prev.push(current)
        }
        return prev
      }, [])
    } else {
      return dt
    }
  }, [_data, data.setorId])

  const { mutateAsync, isLoading: isLoadingSendEmail } = useEnviarPorEmail()

  const setores = useMemo(() => {
    const dt = _data || []
    return orderBy(
      distinctByField(
        dt.map((d) => ({
          id: d.setorId,
          nome: d.setorNome,
        })),
        'id',
      ),
      'nome',
    )
  }, [_data])

  if (!_state) {
    history.push('/relatorio-ferias-vencidas-main')
    return <></>
  }

  const state = _state as RelatorioFeriasVencidasStateTransferencia

  function returnPageMain() {
    history.push({
      pathname: '/relatorio-ferias-vencidas-main',
      state,
    })
  }

  function handlePrint() {
    openPDFViewer({
      data: state.estabelecimentosId,
      params: {
        anoMes,
        dtLimite: state.dtLimite,
        agrupar: data.agrupar,
        setorId: data.setorId,
      },
    })
  }

  async function handleSendEmail() {
    await mutateAsync({
      data: state.estabelecimentosId,
      params: {
        anoMes,
        dtLimite: state.dtLimite,
        agrupar: data.agrupar,
        setorId: data.setorId,
      },
    })
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'CNPJ',
      name: 'estabelecimentoNrInscricao',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{value}</CopyLabel>,
      },
    },
    {
      label: 'Estabelecimento',
      name: 'estabelecimentoNome',
    },
    {
      label: 'Setor',
      name: 'setorNome',
    },
    {
      label: 'CPF',
      name: 'cpf',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{value}</CopyLabel>,
      },
    },
    {
      label: 'Nome',
      name: 'nome',
    },
    {
      label: 'Período Início',
      name: 'dtPeriodoInicioValue',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
    {
      label: 'Período Fim',
      name: 'dtPeriodoFimValue',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
    {
      label: 'Limite Concessão',
      name: 'dtLimiteConcessaoValue',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
    {
      label: 'Dias Direito',
      name: 'nrDiasDireito',
    },
    {
      label: 'Dias Concedidos',
      name: 'nrDiasConcedidos',
    },
    {
      label: 'Faltas',
      name: 'nrFaltas',
    },
    {
      label: 'Dias Saldo',
      name: 'nrDiasSaldo',
    },
  ]

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={timeAnimation}>
      <Box
        height="100%"
        width="100%"
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(1)}
      >
        <PageHeader title="Relatório de Férias Vencidas" onBack={returnPageMain}>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </PageHeader>

        <Box component={Paper} p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                style={{
                  display: 'flex',
                  gap: theme.spacing(1),
                }}
              >
                Empresas Selecionadas:
                <Typography
                  component={'span'}
                  variant={'body2'}
                  style={{
                    fontWeight: 'bolder',
                    fontSize: '12pt',
                    color: '#666',
                  }}
                >
                  {state.estabelecimentosId.length}
                </Typography>
              </Typography>
              <Typography
                style={{
                  display: 'flex',
                  gap: theme.spacing(1),
                }}
              >
                Data de Vencimento:
                <Typography
                  component={'span'}
                  variant={'body2'}
                  style={{
                    fontWeight: 'bolder',
                    fontSize: '12pt',
                    color: '#2E8BC0',
                  }}
                >
                  {moment(state.dtLimite).format('DD/MM/yyyy')}
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <MUIAutoComplete
                    label="Setores"
                    options={setores}
                    optionId="id"
                    renderOption={(option) => option.nome}
                    value={data.setorId}
                    onChange={(e, obj) => {
                      const setorId = obj?.id || null
                      setData((prev) => ({
                        ...prev,
                        setorId,
                      }))
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <MUIAutoComplete
                    label="Forma de Impressão"
                    options={FeriasVencidasRelatorioValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    value={data.agrupar}
                    onChange={(e, obj) => {
                      const agrupar =
                        obj?.value || FeriasVencidasRelatorioEnum.AgruparEstabelecimento
                      setData((prev) => ({
                        ...prev,
                        agrupar,
                      }))
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box flex={1} position="relative" minHeight={300} overflow="auto">
          <Box position="absolute" width="100%">
            <ToolsDataTable
              data={collection}
              isLoading={isLoading}
              isFetching={isFetching}
              columns={columns}
              disableAutoHeight
              sherlock={{
                query,
                columns: ['estabelecimentoNome', 'setorNome', 'nome'],
              }}
            />
          </Box>
        </Box>

        <Box
          component={Paper}
          p={1}
          display="flex"
          justifyContent="flex-end"
          gridGap={theme.spacing(1)}
        >
          <Button
            onClick={handleSendEmail}
            title="Enviar para o email de cada estabelecimento"
            isLoading={isLoadingSendEmail}
            disabled={collection.length === 0}
          >
            Enviar para Email
          </Button>

          <Button variant="contained" onClick={handlePrint} disabled={collection.length === 0}>
            Imprimir
          </Button>
        </Box>
        <PDFViewer
          onClose={closePDFViewer}
          isOpen={isOpenPDFViewer}
          title="Férias Vencidas"
          reportKey="FeriasVencidas"
          axiosConfig={{
            method: 'post',
            url: '/Relatorio/FeriasVencidas/ObterRelatorio',
            data: dataPDFViewer.data,
            params: dataPDFViewer.params,
          }}
        />
      </Box>
    </Slide>
  )
}
