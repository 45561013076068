import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import { useTheme } from '@material-ui/core'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
)

export interface EmpregadorLineChartProps {
  id: string
  label: string
  totalFuncionarios: number
}

interface LineChartProps {
  subtitle: string
  empregadores: EmpregadorLineChartProps[]
}

export default function LineChart({ subtitle, empregadores }: LineChartProps) {
  const theme = useTheme()

  return (
    <Line
      data={{
        labels: empregadores.map((d, index) => index + 1),
        datasets: [
          {
            label: 'Funcionários',
            data: empregadores.map((d) => d.totalFuncionarios),
            borderColor: theme.palette.warning.light,
          },
        ],
      }}
      options={{
        responsive: true,

        plugins: {
          title: {
            display: true,
            text: 'Processamento Empregadores',
          },
          subtitle: {
            display: true,
            text: subtitle,
          },
          tooltip: {
            callbacks: {
              title: (elements) => {
                const element = elements[0]
                const dtCurrent = empregadores[element.dataIndex]
                return dtCurrent.label
              },
            },
          },
        },
      }}
    />
  )
}
