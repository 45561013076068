import { useCallback, useEffect, useState } from 'react'

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'

export { HubConnection }

interface UseSocketProps {
  urlHub: string
  onStartConnection: (socket: HubConnection) => void
}

export default function useSocket({ urlHub, onStartConnection }: UseSocketProps) {
  const [socket, setSocket] = useState<HubConnection | null>(null)

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(import.meta.env.VITE_PESSOAL_API_URL + urlHub)
      .withAutomaticReconnect()
      .build()
    setSocket(connection)
  }, [urlHub])

  const startConnection = useCallback(async (skt: HubConnection) => {
    await skt
      .start()
      .then(() => onStartConnection(skt))
      .catch((e) => console.info(`[${skt.baseUrl}] Socket connection failed: `, e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const stopConnection = useCallback(async (skt: HubConnection) => {
    await skt.stop().catch((e) => console.info(`[${skt.baseUrl}] Socket disconnection failed: `, e))
  }, [])

  useEffect(() => {
    if (!socket) return
    startConnection(socket)
    return () => {
      stopConnection(socket)
    }
  }, [socket, startConnection, stopConnection])

  return socket
}
