export enum ReciboIDFEnum {
  Mensal_1 = 1,
  Ferias_2 = 2,
  Complementar_3 = 3,
  Adiantamento_4 = 4,
  Rescisao_5 = 5,
  RescisaoComplementar_6 = 6,
  DecimoTerceiro1aParcela_7 = 7,
  DecimoTerceiro2aParcela_8 = 8,
  PrimeiraQuinzena_9 = 9,
  SegundaQuinzena_10 = 10,
  RescisaoSimulada_11 = 11,
  RescisaoZerada_12 = 12,
  Padrao_13 = 13,
  Resilicao_14 = 14,
  Socio_15 = 15,
  Autonomo_16 = 16,
  ApuraracaoConvencaoColetiva_17 = 17,
  ConvencaoColetiva_18 = 18,
  Cooperado_19 = 19,
  RescisaoTSV_20 = 20,
  Ferias_Coletivas_21 = 21,
}

export const ReciboIDFValues = [
  { value: ReciboIDFEnum.Mensal_1, name: '1 - Mensal' },
  { value: ReciboIDFEnum.Ferias_2, name: '2 - Férias' },
  { value: ReciboIDFEnum.Complementar_3, name: '3 - Complementar' },
  { value: ReciboIDFEnum.Adiantamento_4, name: '4 - Adiantamento' },
  { value: ReciboIDFEnum.Rescisao_5, name: '5 - Rescisão' },
  { value: ReciboIDFEnum.RescisaoComplementar_6, name: '6 - Rescisão Complementar' },
  { value: ReciboIDFEnum.DecimoTerceiro1aParcela_7, name: '7 - 13° Primeira Parcela' },
  { value: ReciboIDFEnum.DecimoTerceiro2aParcela_8, name: '8 - 13° Segunda Parcela' },
  { value: ReciboIDFEnum.PrimeiraQuinzena_9, name: '9 - Primeira Quinzena' },
  { value: ReciboIDFEnum.SegundaQuinzena_10, name: '10 - Segunda Quinzena' },
  { value: ReciboIDFEnum.RescisaoSimulada_11, name: '11 - Rescisão Simulada' },
  { value: ReciboIDFEnum.RescisaoZerada_12, name: '12 - Rescisão Zerada' },
  { value: ReciboIDFEnum.Padrao_13, name: '13 - Padrão' },
  { value: ReciboIDFEnum.Resilicao_14, name: '14 - Resilição' },
  { value: ReciboIDFEnum.Socio_15, name: '15 - Pró-Labore' },
  { value: ReciboIDFEnum.Autonomo_16, name: '16 - Autônomo' },
  { value: ReciboIDFEnum.ApuraracaoConvencaoColetiva_17, name: '17 - Apuração Convenção Coletiva' },
  { value: ReciboIDFEnum.ConvencaoColetiva_18, name: '18 - Convenção Coletiva' },
  { value: ReciboIDFEnum.Cooperado_19, name: '19 - Cooperado' },
  { value: ReciboIDFEnum.RescisaoTSV_20, name: '20 - Rescisão TSV' },
  { value: ReciboIDFEnum.Ferias_Coletivas_21, name: '21 - Férias Coletivas' },
]

export const ReciboIDFLabels = {
  [ReciboIDFEnum.Mensal_1]: 'Recibo Mensal',
  [ReciboIDFEnum.Ferias_2]: 'Recibo de Férias',
  [ReciboIDFEnum.Complementar_3]: 'Recibo Complementar',
  [ReciboIDFEnum.Adiantamento_4]: 'Adiantamento de Salário',
  [ReciboIDFEnum.Rescisao_5]: 'Termo de Rescisão',
  [ReciboIDFEnum.RescisaoComplementar_6]: 'Rescisão Complementar',
  [ReciboIDFEnum.DecimoTerceiro1aParcela_7]: 'Décimo Terceiro - Primeira Parcela',
  [ReciboIDFEnum.DecimoTerceiro2aParcela_8]: 'Décimo Terceiro - Segunda Parcela',
  [ReciboIDFEnum.PrimeiraQuinzena_9]: 'Primeira Quinzena',
  [ReciboIDFEnum.SegundaQuinzena_10]: 'Segunda Quinzena',
  [ReciboIDFEnum.RescisaoSimulada_11]: 'Rescisão Simulada',
  [ReciboIDFEnum.RescisaoZerada_12]: 'Rescisão Zerada',
  [ReciboIDFEnum.Padrao_13]: 'Padrão',
  [ReciboIDFEnum.Resilicao_14]: 'Termo de Resilição',
  [ReciboIDFEnum.Socio_15]: 'Pró-Labore',
  [ReciboIDFEnum.Autonomo_16]: 'Recibo de Autônomo',
  [ReciboIDFEnum.ApuraracaoConvencaoColetiva_17]: 'Apuração Convenção Coletiva',
  [ReciboIDFEnum.ConvencaoColetiva_18]: 'Convenção Coletiva',
  [ReciboIDFEnum.Cooperado_19]: 'Recibo de Cooperado',
  [ReciboIDFEnum.RescisaoTSV_20]: 'Recibo Rescisão TSV',
  [ReciboIDFEnum.Ferias_Coletivas_21]: 'Férias Coletiva',
}
