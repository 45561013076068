import React from 'react'

import clsx from 'clsx'

import { IconButton, makeStyles } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  expandIconOpen: {
    transform: 'rotate(0deg)',
    transition: '0.3s',
  },
  expandIconClose: {
    transform: 'rotate(-90deg)',
    transition: '0.3s',
  },
}))

export default function MUIExpandableButton(props: MUIExpandableButtonProps) {
  const { open, onClick } = props

  const classes = useStyles()

  return (
    <IconButton size="small" onClick={onClick}>
      <ExpandMoreIcon
        className={clsx({
          [classes.expandIconOpen]: open,
          [classes.expandIconClose]: !open,
        })}
        fontSize="small"
      />
    </IconButton>
  )
}
