import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { AtestadoSaudeOcupacional } from './AtestadoSaudeOcupacional'

export function useAtestadoSaudeOcupacionalGetByVinculo(vinculoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: AtestadoSaudeOcupacional[] }>(
      '/AtestadoSaudeOcupacional/GetByVinculo',
      {
        params: {
          vinculoId,
        },
      },
    )
    return response?.data?.data || []
  }

  return useQuery(['AtestadoSaudeOcupacionalGetByVinculo', vinculoId], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
