import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import api from '~/services/api-pessoal'

export interface ValidaVinculoFeriasRPDTO {
  id: string
  iDF: ReciboIDFEnum
  nrRecibo: number
  anoMes: string
  fatoGerador: string
  vrTotalLiquido: number | null
  isFinalizado: boolean
  reciboEsocial: string
}

export interface ValidaVinculoFeriasResponseDTO {
  recibos: ValidaVinculoFeriasRPDTO[]
  mensagem: string
}

export async function rpVerificarExisteRecibosMes(vinculoId: string, anoMes: string) {
  const response = await api.get<{ data: ValidaVinculoFeriasResponseDTO | null }>(
    'RP/VerificarExisteRecibosMes',
    {
      params: {
        vinculoId,
        anoMes,
      },
    },
  )
  return response.data.data
}
