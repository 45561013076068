import { useMutation } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { Auditoria } from '../Auditoria/Auditoria'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'
import { ESocialStatusEnum } from '~/@types/enums/ESocialStatusEnum'

interface RequestProps {
  empregadorId: string
  esocialEvento?: ESocialEventoEnum | null
  esocialStatus?: ESocialStatusEnum | null
  dtInicio?: Date | string | null
  dtFim?: Date | string | null
}

export function useObterAuditoriaPorEmpregador() {
  const dialogNotification = useDialogNotification()

  async function handleRequest(props: RequestProps) {
    const response = await api.get<{ data: Auditoria[] }>('/ESocial/ObterAuditoriaPorEmpregador', {
      params: props,
    })
    return response.data.data
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
