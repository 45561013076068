import React, { useState } from 'react'

import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid } from '@material-ui/core'

import { Button, PageHeader, TextField } from '~/components'
import {
  AutoCompleteCargo,
  AutoCompleteDepartamento,
  AutoCompleteSetor,
} from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import { useStepperContext } from '~/components/StepperForm'
import useAmbiente from '~/hooks/useAmbiente'

import { MainDataForm } from '..'

const schema = yup.object().shape({
  cargoId: yup.string().required('Informe Cargo'),
})

export default function FormAmbienteTrabalho() {
  const { mainData, onBack, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataAmbienteTrabalho)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const { empregador } = useAmbiente()

  function handleSubmit() {
    onNext({
      ...mainData,
      dataAmbienteTrabalho: data,
    })
  }

  function handleBack() {
    onBack()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <PageHeader title="Ambiente de Trabalho" />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Observação"
          fullWidth
          value={data?.observacao || ''}
          size="small"
          multiline
          inputProps={{
            maxLength: 200,
          }}
          onChange={(e) => {
            const observacao = e.target.value
            setData({ ...data, observacao })
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompleteDepartamento
          onChange={(e, departamento) => {
            const departamentoId = departamento ? departamento.id : ''
            setData({ ...data, departamentoId, departamento })
          }}
          value={data?.departamento || null}
          empregadorId={empregador.id}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompleteSetor
          onChange={(e, setor) => {
            const setorId = setor ? setor.id : ''
            setData({ ...data, setorId, setor })
          }}
          value={data?.setor || null}
          empregadorId={empregador.id}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompleteCargo
          validationErrors={validationErrors}
          name="cargoId"
          optionId="id"
          required
          onChange={(e, cargo) => {
            const cargoId = cargo ? cargo.id : ''
            setData({ ...data, cargoId })
          }}
          value={data?.cargoId || ''}
          empregadorId={empregador.id}
        />
      </Grid>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
