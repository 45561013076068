import { Box } from '@material-ui/core'
import React, { useMemo } from 'react'
import { HeightDataTable } from '~/components'
import { useQuery } from '~/hooks/queries/useHistoricoConfiguracaoEvento'
import { CodigoIncidenciaCPValues } from '~/@types/enums/CodigoIncidenciaCPEnum'
import { CodigoIncidenciaIRValues } from '~/@types/enums/CodigoIncidenciaIREnum'
import { CodigoIncidenciaFGValues } from '~/@types/enums/CodigoIncidenciaFGEnum'
import moment from 'moment'

export default function Table(props) {
  const { eventoId, empregadorId } = props

  const { data: _data, isLoading } = useQuery({ eventoId, empregadorId })

  function formatCodigoIncidenciaCP(codigoIncidenciaCP) {
    const codigoIncidenciaCPFinded = CodigoIncidenciaCPValues.find(
      (obj) => obj.value === codigoIncidenciaCP,
    )
    return codigoIncidenciaCPFinded.name
  }

  function formatCodigoIncidenciaIR(codigoIncidenciaIR) {
    const codigoIncidenciaIRFinded = CodigoIncidenciaIRValues.find(
      (obj) => obj?.value === codigoIncidenciaIR,
    )
    return codigoIncidenciaIRFinded?.name
  }

  function formatCodigoIncidenciaFG(codigoIncidenciaFG) {
    const codigoIncidenciaFGFinded = CodigoIncidenciaFGValues.find(
      (obj) => obj?.value === codigoIncidenciaFG,
    )
    return codigoIncidenciaFGFinded?.name
  }

  const data = _data?.map((item) => {
    return {
      codigoIncidenciaCP: formatCodigoIncidenciaCP(item?.codigoIncidenciaCP),
      codigoIncidenciaIR: formatCodigoIncidenciaIR(item?.codigoIncidenciaIR),
      codigoIncidenciaFG: formatCodigoIncidenciaFG(item?.codigoIncidenciaFG),
      dtInicioValidadeESocial: item?.dtInicioValidadeESocial
        ? moment(item.dtInicioValidadeESocial).format('DD/MM/yyyy')
        : '',
      dtFimValidadeESocial: item?.dtFimValidadeESocial
        ? moment(item.dtFimValidadeESocial).format('DD/MM/yyyy')
        : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'codigoIncidenciaCP',
        label: 'Previdência',
      },
      {
        name: 'codigoIncidenciaIR',
        label: 'IRRF',
      },
      {
        name: 'codigoIncidenciaFG',
        label: 'FGTS',
      },
      {
        name: 'dtInicioValidadeESocial',
        label: 'Data Inicio eSocial',
      },
      {
        name: 'dtFimValidadeESocial',
        label: 'Data Fim eSocial',
      },
      {
        name: 'reciboEsocial',
        label: 'Recibo do eSocial',
      },
    ],
    [],
  )

  return (
    <Box pt={2} height="100%">
      <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
    </Box>
  )
}
