import React, { useCallback } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { HeightDataTable } from '~/components'

import { Print as PrintIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import moment from 'moment'

const makeColumns = (onItemClick) => [
  {
    name: 'dtEmissao',
    label: 'Emissão',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'dtInicio',
    label: 'Início',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'numeroDiasDeAvisoPrevio',
    label: 'Dias Aviso',
  },
  {
    name: 'dtRescisao',
    label: 'Rescisão',
    options: {
      customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
    },
  },
  {
    name: 'observacao',
    label: 'Observação',
  },
  {
    name: 'id',
    label: 'Ações',
    options: {
      filter: true,
      sort: false,
      empty: true,
      customBodyRender: (value) => {
        return (
          <ButtonBox justifyContent="center" spacing={0}>
            <IconButton
              title="Imprimir este registro"
              color="primary"
              aria-label="Imprimir"
              onClick={() => {
                onItemClick('print', value)
              }}
              size="small"
            >
              <PrintIcon fontSize="small" color="primary" size="small" />
            </IconButton>

            <IconButton
              title="Editar este registro"
              color="primary"
              aria-label="Editar"
              onClick={() => {
                onItemClick('edit', value)
              }}
              size="small"
            >
              <EditIcon fontSize="small" color="primary" size="small" />
            </IconButton>

            <IconButton
              title="Deletar este registro"
              color="primary"
              aria-label="Deletar"
              onClick={() => {
                onItemClick('delete', value)
              }}
              size="small"
            >
              <DeleteIcon fontSize="small" color="primary" size="small" />
            </IconButton>
          </ButtonBox>
        )
      },
    },
  },
]

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = useCallback(() => {
    return makeColumns(onItemClick)
  }, [onItemClick])

  return <HeightDataTable data={data} columns={columns()} isLoading={isLoading} />
}

export default Table
