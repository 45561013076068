import React, { useMemo } from 'react'

import { HeightDataTable } from '~/components'
import { diaHorarioValues } from '~/values/diaHorarioValues'

const formatDiaHorario = (diaHorario) => {
  const objectFinded = diaHorarioValues.find((obj) => obj.value === diaHorario)
  return objectFinded.name
}

const formatNumberInHour = (number) => {
  if (!number) return ''
  return `${number.substr(0, 2)}:${number.substr(2)}`
}

const Table = (props) => {
  const { data: _data, isLoading } = props

  const data = _data.map((item) => {
    return {
      ...item,
      diaHorario: formatDiaHorario(item?.diaHorario),
      horarioTrabalhoNome: item?.horarioTrabalho?.nome || '',
      horaEntrada: formatNumberInHour(item?.horarioTrabalho?.horaEntrada),
      horaSaida: formatNumberInHour(item?.horarioTrabalho?.horaSaida),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'diaHorario',
        label: 'Dia da Semana Trabalhado',
      },
      {
        name: 'horarioTrabalhoNome',
        label: 'Horário de Trabalho',
      },
      {
        name: 'horaEntrada',
        label: 'Entrada',
      },
      {
        name: 'horaSaida',
        label: 'Saída',
      },
    ],
    [],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
