import api from '~/services/api-pessoal'

export interface RelVencimentoContratoExperienciaRequestDTO {
  anoMes: string
  estabelecimentosIds: string[]
}

export async function enviarRelatoriosPorEmail(
  props: RelVencimentoContratoExperienciaRequestDTO,
  relatorioId: string,
) {
  await api.post('/Relatorio/VencimentoContratoExperiencia/EnviarRelatoriosPorEmail', props, {
    params: {
      relatorioId,
    },
  })
}
