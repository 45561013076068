import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'

import { ReciboChartDTO } from './dtos/ReciboChartDTO'

const query = '/Painel/RecibosChart'

interface RequestParams {
  anoMes: string
  estabelecimentoId?: string
}

export default function usePainelRecibosChart(params: RequestParams) {
  async function handleRequest() {
    const response = await api.get<{ data: ReciboChartDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: console.error,
  })
}
