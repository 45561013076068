import { useState } from 'react'

import { makeStyles, Box } from '@material-ui/core'
import { TreeView as MuiTreeView, TreeItem } from '@material-ui/lab'
import { ExpandMore as ExpandMoreIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons'

import { FixedSizeList } from 'react-window'

import CardItem from './components/CardItem'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '.MuiTreeItem-root:hover > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '.MuiTreeItem-group': {
      marginLeft: theme.spacing(0.5),
    },
  },
  hightLightHover: {
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter,
      color: theme.palette.primary.main,
      borderRadius: '2px',
    },
  },
  hightLightSelected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: '2px',
  },
  iconContainerTreeItem: {
    display: 'none',
  },
}))

const extractNivel = (item = '') => {
  const splitedNode = item.split('|')
  return splitedNode[0]
}

const TreeViewChildren = (props) => {
  const { item, renderOption, renderSecondaryOption, renderRightItem, isSelect, style, ...rest } =
    props

  const [isHovering, setHovering] = useState(false)

  const classes = useStyles()
  return (
    <TreeItem
      {...rest}
      classes={{
        iconContainer: classes.iconContainerTreeItem,
        content: isSelect ? classes.hightLightSelected : classes.hightLightHover,
      }}
      style={style}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      label={
        <Box>
          <Box display="flex" alignItems="center">
            <Box width={renderRightItem ? '70%' : '100%'}>
              <CardItem
                labelText={typeof item === 'object' ? renderOption(item) : item}
                style={
                  renderSecondaryOption
                    ? {
                        minHeight: '10px',
                      }
                    : {}
                }
              />
            </Box>
            {renderRightItem && (
              <Box width="30%" display="flex" justifyContent="end">
                {renderRightItem(item, isHovering)}
              </Box>
            )}
          </Box>
          {renderSecondaryOption && (
            <CardItem
              labelText={renderSecondaryOption(item)}
              style={{
                minHeight: '10px',
                marginTop: '-4px',
              }}
            />
          )}
        </Box>
      }
    />
  )
}

const TreeViewContent = (props) => {
  const {
    groups,
    onSelectItem,
    renderOption,
    renderSecondaryOption,
    renderRightItem,
    expanded,
    setExpanded,
    selected,
    setSelected,
    changeNavigation,
    heightContentMenu,
  } = props

  const classes = useStyles()

  const handleToggle = (event, nodeIds) => {
    if (event.type === 'keydown') return
    const currentLength = expanded.length
    const lastLength = nodeIds.length
    const isBacking = lastLength < currentLength

    const currentNode = nodeIds[0]
    const currentNivel = extractNivel(currentNode)
    const lastNivel = extractNivel(expanded[0])
    if (currentNivel === lastNivel && !isBacking) {
      expanded.shift()
      expanded.unshift(nodeIds[0])
      setExpanded([...expanded])
      return
    }

    setExpanded(nodeIds)
  }

  function renderRow(params) {
    const { index, data, style } = params

    const item = data[index]

    return (
      <TreeViewChildren
        key={index}
        id={`1|${index}`}
        nodeId={`1|${index}`}
        onLabelClick={() => {
          setSelected(item)
          if (item !== selected) onSelectItem('itemSelected', item)
        }}
        item={item}
        renderOption={renderOption}
        renderSecondaryOption={renderSecondaryOption}
        renderRightItem={renderRightItem}
        isSelect={selected === item ? true : false}
        style={style}
      />
    )
  }

  function selectMenuOnKeyDown({ key }) {
    if (key === 'ArrowUp') return changeNavigation('previous')
    if (key === 'ArrowDown') return changeNavigation('next')
    if (key === 'ArrowLeft') return changeNavigation('first')
    if (key === 'ArrowRight') return changeNavigation('last')
  }

  return (
    <MuiTreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleToggle}
      disableSelection={true}
    >
      {groups.map((gruopItem, index) => {
        return (
          <TreeItem
            key={index}
            id={`0|${index}`}
            nodeId={`0|${index}`}
            label={<CardItem labelText={gruopItem.groupName} bolder={true} />}
            classes={{
              content: classes.hightLightHover,
            }}
            onKeyDown={selectMenuOnKeyDown}
          >
            <FixedSizeList
              height={heightContentMenu - 40 * groups.length}
              itemSize={renderSecondaryOption ? 44 : 32}
              itemCount={gruopItem.data.length}
              overscanCount={5}
              itemData={gruopItem.data}
              className="fixed-size-list-virtualize-treeview"
            >
              {renderRow}
            </FixedSizeList>
          </TreeItem>
        )
      })}
    </MuiTreeView>
  )
}

export default TreeViewContent

TreeViewChildren.defaultProps = {
  renderOption: () => undefined,
}
