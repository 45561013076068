import { Box, makeStyles } from '@material-ui/core'

import { PageHeader } from 'mio-library-ui'

import { ActionDialog } from '~/components'

import Container from './components/Container'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

const ContainerComponent = (props) => {
  const { title, subtitle, isOpen, onClose, fixedContainer, onBack, renderRight, maxWidth } = props

  const classes = useStyles()

  if (fixedContainer)
    return (
      <Box height="100%" width="100%" padding={2}>
        <PageHeader title={title} subtitle={subtitle} onBack={onBack} renderRight={renderRight} />
        <Container {...props} />
      </Box>
    )

  return (
    <ActionDialog
      title={title}
      subtitle={subtitle}
      renderRight={renderRight}
      isOpen={isOpen}
      onClose={onClose}
      customActions={<div />}
      dialogProps={{
        maxWidth: maxWidth,
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
    >
      <Container {...props} />
    </ActionDialog>
  )
}

ContainerComponent.defaultProps = {
  title: '',
  subtitle: '',
  isOpen: false,
  onClose: () => undefined,
  fixedContainer: false,
  maxWidth: 'lg',
  renderRight: <></>,
}

export default ContainerComponent
