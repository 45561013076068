import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { formatToCPF } from 'brazilian-values'

import { Grid, Box, IconButton, Typography, useTheme, Paper } from '@material-ui/core'
import { MonetizationOn as MonetizationOnIcon } from '@material-ui/icons'

import { MUIAutoComplete } from '~/components/AutoComplete'
import AutoCompleteOptionDescription from '~/components/AutoCompleteOptionDescription'

import DialogSalarioContratual from './DialogSalarioContratual'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { formatCurrency } from '~/hooks/useUtils'
import useDialogNotification from '~/hooks/useDialogNotification'

import obterFuncionariosComRescisao from '~/hooks/queries/RescisaoComplementar/obterFuncionariosComRescisao'
import {
  RescisaoComplementarFuncionarioDTO,
  RescisaoComplementarFuncionarioSimpleDTO,
} from '~/hooks/queries/RescisaoComplementar/RescisaoComplementarDTO'

interface CardFuncionarioProps {
  value: RescisaoComplementarFuncionarioDTO | null
  onChange: (value: RescisaoComplementarFuncionarioSimpleDTO | null) => void
  required: boolean
  name: string
  validationErrors: ValidationErrors
}

export default function CardFuncionario(props: CardFuncionarioProps) {
  const { value: funcionario, onChange, required, name, validationErrors } = props

  const [options, setOptions] = useState<RescisaoComplementarFuncionarioSimpleDTO[]>([])
  const [isLoading, setLoading] = useState(false)

  const theme = useTheme()
  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const {
    close: closeDialogSalarioContratual,
    data: dataDialogSalarioContratual,
    isOpen: isOpenDialogSalarioContratual,
    open: openDialogSalarioContratual,
  } = useDialog<string | null>(null)

  useEffect(() => {
    setOptions([])
    onChange(null)
    // eslint-disable-next-line
  }, [estabelecimento.id, anoMes])

  async function getData() {
    setLoading(true)
    let result: RescisaoComplementarFuncionarioSimpleDTO[] = []
    try {
      result = await obterFuncionariosComRescisao({ estabelecimentoId: estabelecimento.id, anoMes })
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    return result
  }

  const getOptions = async () => {
    const newOptions = await getData()
    setOptions(newOptions)
  }

  const renderOption = (option: RescisaoComplementarFuncionarioSimpleDTO) =>
    `${formatToCPF(option.nrInscricao)} - ${option.nome}`

  async function onCloseDialogSalarioContratual() {
    closeDialogSalarioContratual()
    const newOptions = await getData()
    setOptions(newOptions)

    if (funcionario) {
      const newFuncionario = newOptions.find((d) => d.id === funcionario.id) || null
      onChange(newFuncionario)
    }
  }

  return (
    <Grid container spacing={1} component={Paper} style={{ padding: theme.spacing(1) }}>
      <Grid item xs={12}>
        <MUIAutoComplete
          isLoading={isLoading}
          options={options}
          getOptions={getOptions}
          renderOption={renderOption}
          renderOptionComponent={(opt) => (
            <AutoCompleteOptionDescription
              title={`${formatToCPF(opt.nrInscricao)} - ${opt.nome}`}
              descriptions={[
                {
                  description: `Admissão: ${moment(opt.dtAdmissao).format(
                    'DD/MM/yyyy',
                  )} - Rescisão: ${
                    opt.dtRescisao ? moment(opt.dtRescisao).format('DD/MM/yyyy') : '-'
                  }`,
                },
              ]}
            />
          )}
          label="Funcionário"
          value={funcionario}
          onChange={(_, obj) => onChange(obj)}
          required={required}
          name={name}
          validationErrors={validationErrors}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box display="flex" gridGap={theme.spacing(1)}>
          <Box>
            <Typography>Data de Admissão:</Typography>
            <Typography>Data de Rescisão:</Typography>
          </Box>
          <Box>
            <Typography>
              {funcionario?.dtAdmissao ? moment(funcionario.dtAdmissao).format('DD/MM/yyyy') : ''}
            </Typography>
            <Typography>
              {funcionario?.dtRescisao ? moment(funcionario.dtRescisao).format('DD/MM/yyyy') : ''}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box display="flex">
          <Box display="flex" gridGap={theme.spacing(1)}>
            <Box>
              <Typography>Salário Anterior:</Typography>
              <Typography>Salário Atual:</Typography>
            </Box>
            <Box>
              <Typography>
                {funcionario?.vrSalarioAnterior
                  ? formatCurrency(funcionario.vrSalarioAnterior)
                  : ''}
              </Typography>
              <Typography>
                {funcionario?.vrSalarioAtual ? formatCurrency(funcionario.vrSalarioAtual) : ''}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <IconButton
                size="small"
                onClick={() => openDialogSalarioContratual(funcionario?.id || null)}
                disabled={!funcionario}
              >
                <MonetizationOnIcon
                  style={{
                    color: funcionario ? theme.palette.success.main : '',
                  }}
                  fontSize="small"
                  titleAccess="Adicionar Novo Salário Contratual"
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Grid>

      {dataDialogSalarioContratual ? (
        <DialogSalarioContratual
          isOpen={isOpenDialogSalarioContratual}
          onClose={() => onCloseDialogSalarioContratual()}
          vinculoId={dataDialogSalarioContratual}
        />
      ) : (
        <></>
      )}
    </Grid>
  )
}
