import React from 'react'

import { ToolsDataTable } from '~/components'

import SelectTable from '../SelectTable'

import { formatCurrency } from '~/hooks/useUtils'

import { ManutencaoAtividadeConcomitanteData } from '../..'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { IndSimplesEnum, IndSimplesValues } from '~/@types/enums/IndSimplesEnum'

const indValuesAtuais = IndSimplesValues.filter((d) => d.value !== IndSimplesEnum.NaoAplicavel_9)

interface TableProps {
  data: ManutencaoAtividadeConcomitanteData[]
  isLoading: boolean
  query: string
  handleChangeColumnAtividade: (value: any, index: number) => void
}

export default function Table(props: TableProps) {
  const { data: _data, isLoading, query, handleChangeColumnAtividade } = props

  const data = _data.map((d) => ({
    ...d,
    vrBaseINSS: d?.vrBaseINSS ? formatCurrency(d.vrBaseINSS) : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'reciboeSocial',
      label: 'Recibo eSocial',
    },
    {
      name: 'vrBaseINSS',
      label: 'Base INSS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'indSimples',
      label: 'Atividade',
      options: {
        customBodyRender: (value, { rowIndex }) => {
          const dataCurrent = data[rowIndex]
          const hasReciboESocial = dataCurrent.reciboeSocial ? true : false
          return (
            <SelectTable
              disabled={hasReciboESocial}
              title={
                hasReciboESocial
                  ? 'Não é possível alterar a Atividade de um recibo que foi enviado para o eSocial'
                  : ''
              }
              value={value}
              options={indValuesAtuais}
              optionId="value"
              renderOption={(option) => option.name}
              onChange={(value) => handleChangeColumnAtividade(value, rowIndex)}
            />
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        columns: ['nome', 'reciboeSocial'],
        query,
      }}
    />
  )
}
