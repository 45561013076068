import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VariavelAmbienteDTO } from './VariavelAmbienteDTO'

const queryUseEventoObterListaVariavelAmbiente = '/Evento/ObterListaVariavelAmbiente'

export function useEventoObterListaVariavelAmbiente() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VariavelAmbienteDTO[] }>(
      queryUseEventoObterListaVariavelAmbiente,
    )
    return response.data.data
  }

  return useQuery(queryUseEventoObterListaVariavelAmbiente, handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
