import { useRef, useState } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { Button, CopyLabel, Finder, ToolsDataTable } from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'
import { useGerarPlanilhas } from '~/hooks/queries/ImportacaoPlanilhaFaltas/useGerarPlanilhas'
import { useEnviarPlanilhasEmail } from '~/hooks/queries/ImportacaoPlanilhaFaltas/useEnviarPlanilhasEmail'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

export default function GerarPlanilha() {
  const [query, setQuery] = useState('')
  const [grupoId, setGrupoId] = useState('')

  const { anoMes } = useAmbiente()
  const theme = useTheme()
  const tableRef = useRef<FixLater>(null)
  const dialogNotification = useDialogNotification()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useEstabelecimentoObterFiltrado({
    anoMes,
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
    grupoId,
  })
  const data = _data || []

  const { mutateAsync, isLoading: isSubmitting } = useGerarPlanilhas()
  const { mutateAsync: mutateAsyncEmail, isLoading: isSubmittingEmail } = useEnviarPlanilhasEmail()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigoString',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'nrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{value}</CopyLabel>,
      },
    },
  ]

  async function handleSendEmail() {
    const rowsSelected: number[] = tableRef?.current?.state?.selectedRows?.data.map(
      (d: FixLater) => d.dataIndex,
    )

    if (rowsSelected.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe os Estabelecimentos que você deseja enviar a planilha por email'],
      })
    }

    await mutateAsyncEmail({
      data: rowsSelected.map((index) => data[index].id),
      params: {
        anoMes,
      },
    })
  }

  async function handleSubmit() {
    const rowsSelected: number[] = tableRef?.current?.state?.selectedRows?.data.map(
      (d: FixLater) => d.dataIndex,
    )

    if (rowsSelected.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe os Estabelecimentos que você deseja gerar a planilha'],
      })
    }

    await mutateAsync({
      data: rowsSelected.map((index) => data[index].id),
      params: {
        anoMes,
      },
    })
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <Box component={Paper} p={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8} md={8}>
            <AutoCompleteGrupo
              value={grupoId}
              onChange={(e, grupo) => {
                const grupoId = grupo?.id || null
                setGrupoId(grupoId)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box flex={1} position="relative" overflow="auto" minHeight={300}>
        <Box position="absolute" width="100%">
          <ToolsDataTable
            columns={columns}
            data={data}
            isFetching={isFetching}
            isLoading={isLoading}
            disableAutoHeight
            sherlock={{
              columns: ['codigoString', 'nome', 'nrInscricao', 'email'],
              query,
            }}
            optionsSelectable={{
              type: 'multiple',
            }}
            tableRef={tableRef}
          />
        </Box>
      </Box>

      <Box
        component={Paper}
        p={1}
        display="flex"
        justifyContent="flex-end"
        gridGap={theme.spacing(1)}
      >
        <Button onClick={handleSendEmail} isLoading={isSubmittingEmail}>
          Enviar por Email
        </Button>
        <Button variant="contained" onClick={handleSubmit} isLoading={isSubmitting}>
          Gerar Planilha
        </Button>
      </Box>
    </Box>
  )
}
