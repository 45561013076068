import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { AutonomoManutencaoEstabelecimentoDTO } from './AutonomoManutencaoDTO'

export const queryObterEstabelecimentosManutencao = '/Autonomo/ObterEstabelecimentosManutencao'

interface RequestProps {
  pessoaFisicaId: string
  anoMes: string
}

export default function useObterEstabelecimentosManutencao(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: AutonomoManutencaoEstabelecimentoDTO[] }>(
      queryObterEstabelecimentosManutencao,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterEstabelecimentosManutencao, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
