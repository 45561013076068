import { ActionDialog, Button, ButtonBox, ToolsDataTable } from '~/components'

import { epi } from '~/hooks/queries/ImportarXMLS2240/dtos/evtExpRisco'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface DialogEPCEPIProps {
  isOpen: boolean
  onClose: () => void
  data: epi[]
}

export default function DialogEPCEPI({ isOpen, onClose, data }: DialogEPCEPIProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'docAval',
      label: 'Certificado de Avaliação',
    },
  ]

  return (
    <ActionDialog
      title="EPI/EPC"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
      }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <ToolsDataTable data={data} columns={columns} disableAutoHeight pagination={false} />
    </ActionDialog>
  )
}
