export enum ESocialGrupoEventoEnum {
  Empregador = 1,
  Tabelas = 2,
  NaoPeriodicos = 3,
  Periodicos = 4,
  Fechamento = 5,
  Reabertura = 6,
  Exclusao = 7,
}

export const ESocialGrupoEventoEnumLabel = {
  [ESocialGrupoEventoEnum.Empregador]: 'Empregador',
  [ESocialGrupoEventoEnum.Tabelas]: 'Tabelas',
  [ESocialGrupoEventoEnum.NaoPeriodicos]: 'Não Periódicos',
  [ESocialGrupoEventoEnum.Periodicos]: 'Periódicos',
  [ESocialGrupoEventoEnum.Fechamento]: 'Fechamento',
  [ESocialGrupoEventoEnum.Reabertura]: 'Reabertura',
  [ESocialGrupoEventoEnum.Exclusao]: 'Exclusão',
}

export const ESocialGrupoEventoEnumColor = {
  [ESocialGrupoEventoEnum.Empregador]: '#B99470',
  [ESocialGrupoEventoEnum.Tabelas]: '#DEAC80',
  [ESocialGrupoEventoEnum.NaoPeriodicos]: '#F7DCB9',
  [ESocialGrupoEventoEnum.Periodicos]: '#B5C18E',
  [ESocialGrupoEventoEnum.Fechamento]: '#E2FFFF',
  [ESocialGrupoEventoEnum.Reabertura]: '#E2FFFF',
  [ESocialGrupoEventoEnum.Exclusao]: '#E2FFFF',
}
