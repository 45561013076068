import api from '~/services/api-pessoal'

import { PessoaFisica } from './PessoaFisica'
import { ImigranteCondicaoTrabalhadorEnum } from '~/@types/enums/ImigranteCondicaoTrabalhadorEnum'

export enum TipoCadastroInicialPessoaFisicaEnum {
  NaoEncontrado,
  EncontradoBase,
  EncontradoBaseCommon,
  EncontradoAdmissaoPreliminar,
}

export interface PessoaFisicaInicial {
  nome: string
  dtNascimento: Date | string | null
  nrInscricao: string
  ctpsNumero: string
  ctpsSerie: string
  isDeficienciaFisica: boolean
  isDeficienciaAuditiva: boolean
  isDeficienciaVisual: boolean
  isDeficienciaMental: boolean
  isDeficienciaIntelectual: boolean
  isDeficienciaReabilitado: boolean
  isDeficienciaInfoCota: boolean
  nacionalidadePaisId: string
  nascimentoPaisId: string
  imigranteCondicaoTrabalhador: ImigranteCondicaoTrabalhadorEnum
}

export interface PessoaFisicaCadastroInicialDTO {
  tipoCadastroInicial: TipoCadastroInicialPessoaFisicaEnum
  pessoaFisica: PessoaFisica
  pessoaFisicaInicial: PessoaFisicaInicial
}

export async function pessoaFisicaObterCadastroInicial(cpf: string) {
  const response = await api.get<{ data: PessoaFisicaCadastroInicialDTO }>(
    'PessoaFisica/ObterCadastroInicial',
    {
      params: {
        cpf,
      },
    },
  )
  return response.data.data
}
