import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'
import { PageHeader, TreeView } from '~/components'

import Content from './Content'

import useAmbiente from '~/hooks/useAmbiente'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

export default function ManutencaoMensalidade() {
  const [vinculo, setVinculo] = useState<VinculoMenuData | null>(null)

  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Empregador_2,
  ])
  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    setVinculo(null)
  }, [estabelecimento, anoMes])

  function handleSelectItemMenu(e: string, vinculo: VinculoMenuData) {
    setVinculo(vinculo)
  }

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={handleSelectItemMenu}
      searchBy="vinculoNome"
      renderOption={(option: VinculoMenuData) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
    >
      {!vinculo && <PageHeader title="Plano de Saúde - Manutenção de Mensalidade" subtitle="-" />}
      {vinculo ? <Content vinculo={vinculo} /> : <Typography>Selecione um Vínculo</Typography>}
    </TreeView>
  )
}
