import React from 'react'
import { useQueryClient } from 'react-query'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { TextFieldChangeEstabelecimentoEmail, ToolsDataTable } from '~/components'

import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { estabelecimentoConsultaQuery } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoConsulta'

interface TableProps {
  isLoading: boolean
  isFetching: boolean
  query: string
  data: EstabelecimentoConsulta[]
  onRowSelected: React.Dispatch<React.SetStateAction<number[]>>
  rowsSelected: number[]
}

export default function Table(props: TableProps) {
  const { isLoading, isFetching, query, data: _data, onRowSelected, rowsSelected } = props
  const queryClient = useQueryClient()

  const data = _data.map((d) => ({
    ...d,
    nrInscricao: d?.nrInscricao ? formatToCPFOrCNPJ(d.nrInscricao) : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nrInscricao',
      label: 'CNPJ',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'id',
      label: 'Email',
      options: {
        setCellHeaderProps: () => ({
          style: { width: 400 },
        }),
        customBodyRender: (id) => {
          const estabelecimento = _data.find((d) => d.id === id)
          return (
            <TextFieldChangeEstabelecimentoEmail
              estabelecimentoId={id}
              email={estabelecimento?.email || ''}
              afterSubmit={async () => {
                await queryClient.invalidateQueries(estabelecimentoConsultaQuery)
              }}
            />
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      sherlock={{ columns: ['codigo', 'nrInscricao', 'nome', 'email'], query }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
    />
  )
}
