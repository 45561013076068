import React, { useState, useRef } from 'react'

import {
  createMuiTheme,
  ThemeProvider,
  TableRow,
  TableCell,
  Box,
  LinearProgress,
  Checkbox,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'

import useHeightParents from '~/hooks/useHeightParents'

import theme from '~/styles/theme'

const HEIGHT_FOOTER = '60px'
const HEIGHT_IND_FETCHING = '4px'

const skeletonData = [
  {
    col1: '',
  },
]

const skeletonColumns = [
  {
    name: 'col1',
    label: 'Carregando...',
    options: {
      customBodyRender: () => <Skeleton />,
    },
  },
]

const DataGridTextLabels = {
  body: {
    noMatch: 'Nenhum registro encontrado',
    toolTip: 'Ordenar',
  },
  pagination: {
    next: 'Próximo',
    previous: 'Anterior',
    rowsPerPage: 'Linhas: ',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Procurar',
    downloadCsv: 'Download CSV',
    print: 'Imprimir',
    viewColumns: 'Colunas',
    filterTable: 'Procurar na Tabela',
  },
  filter: {
    all: 'Todos',
    title: 'Filtros',
    reset: 'Limpar',
  },
  viewColumns: {
    title: 'Visualizar Colunas',
    titleAria: 'Mostrar/Ocultar Colunas da Tabela',
  },
  selectedRows: {
    text: 'Linhas Selecionadas',
    delete: 'Remover',
    deleteAria: 'Remover Linhas Selecionadas',
  },
}

export default function ToolsDataTable(props) {
  const {
    data,
    columns,

    isLoading,
    isFetching,
    pagination,
    showHeader,
    disableAutoHeight,
    options: _options,
    components,
    triggersHeight,
    sherlock,
    optionsSelectable,
    optionsExpandable,

    tableRef,
    heightHeader,
  } = props

  const [rowsPerPage, setRowsPerPage] = useState(_options?.rowsPerPage || 25)

  const refRootTable = useRef(null)

  useHeightParents({
    refElement: refRootTable,
    triggers: triggersHeight,
    disabled: disableAutoHeight,
    minHeight: 300,
  })

  const heightTable = pagination ? `calc(100% - ${HEIGHT_FOOTER} - 1px)` : '100%'

  const tableTheme = createMuiTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MUIDataTableFooter: {
        ...theme?.overrides?.MUIDataTableFooter,
        root: {
          ...theme?.overrides?.MUIDataTableFooter?.root,
          height: pagination ? HEIGHT_FOOTER : '0',
          borderBottom: theme.shape.border,
        },
      },
      MUIDataTable: {
        ...theme?.overrides?.MUIDataTable,
        paper: {
          ...theme?.overrides?.MUIDataTable?.paper,
          height: `calc(100% - ${HEIGHT_IND_FETCHING})`,
        },
        responsiveBase: {
          ...theme?.overrides?.MUIDataTable?.responsiveBase,
          borderTop: theme.shape.border,
        },
      },
      MuiTableHead: {
        ...theme?.overrides?.MuiTableHead,
        root: {
          ...theme?.overrides?.MuiTableHead?.root,
          height: '0px',
          display: showHeader ? 'table-header-group' : 'none',
        },
      },
      MUIDataTableHeadCell: {
        ...theme?.overrides?.MUIDataTableHeadCell,
        toolButton: {
          height: heightHeader || '48px',
        },
      },
      MuiTableBody: {
        ...theme?.overrides?.MuiTableBody,
        root: {
          ...theme?.overrides?.MuiTableBody?.root,
          borderBottom: theme.shape.border,
        },
      },
    },
  })

  const optionsTableSelectable = {
    selectableRows: isLoading ? 'none' : optionsSelectable?.type || 'none',
    selectableRowsHeader: data.length > 0 ? true : false,
    selectableRowsOnClick: optionsSelectable?.selectOnClick || false,
    rowsSelected: optionsSelectable?.rowsSelected,
    onRowSelectionChange: optionsSelectable?.onRowSelected
      ? (rowsSelectedData, allRows, rowsSelecteds) =>
          optionsSelectable?.onRowSelected(rowsSelecteds)
      : undefined,
    isRowSelectable: optionsSelectable?.isRowSelectable || undefined,
  }

  const optionsTableExpandable = {
    expandableRowsHeader:
      optionsExpandable?.type === 'multiple' ? (data.length > 0 ? true : false) : false,
    expandableRowsOnClick: optionsExpandable?.expandOnClick || false,
    expandableRows: isLoading ? false : true,
    rowsExpanded: optionsExpandable?.rowsExpanded,
    renderExpandableRow: (rowData, { dataIndex }) => {
      const colSpan = rowData.length + 1
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>{optionsExpandable?.expandedChildren(dataIndex)}</TableCell>
        </TableRow>
      )
    },
    onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
      const indexs = allRowsExpanded.map(({ dataIndex }) => dataIndex)
      if (!optionsExpandable?.onRowExpanded) return
      if (indexs.length === 0) return optionsExpandable.onRowExpanded(indexs)
      if (optionsExpandable?.type === 'single')
        return optionsExpandable.onRowExpanded([indexs[indexs.length - 1]])
      optionsExpandable.onRowExpanded(indexs)
    },
  }

  const defaultOptions = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    selectToolbarPlacement: 'none',
    viewColumns: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    sort: true,
    elevation: 0,
    responsive: 'simple',
    textLabels: DataGridTextLabels,
    onChangeRowsPerPage: setRowsPerPage,
    rowsPerPage,
    rowsPerPageOptions: [25, 50, 100],
    searchText: sherlock?.query || '',
    customSearch: (searchQuery, currentRow, columns) =>
      columns.some(({ name }, index) => {
        if (!currentRow[index]) return false

        return (
          sherlock.columns.includes(name) &&
          currentRow[index].toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      }),
    pagination,
    tableBodyHeight: disableAutoHeight ? '' : heightTable,
  }

  let options = { ...defaultOptions }

  if (optionsSelectable) options = { ...options, ...optionsTableSelectable }
  if (optionsExpandable) options = { ...options, ...optionsTableExpandable }

  options = { ...options, ..._options }

  return (
    <ThemeProvider theme={tableTheme}>
      <Box ref={refRootTable}>
        {isFetching ? <LinearProgress /> : <Box height={HEIGHT_IND_FETCHING} />}
        <MUIDataTable
          data={isLoading ? skeletonData : data}
          columns={isLoading ? skeletonColumns : columns}
          options={options}
          components={{
            Checkbox: (props) => <Checkbox {...props} size="small" />,
            ...components,
          }}
          innerRef={tableRef}
        />
      </Box>
    </ThemeProvider>
  )
}

ToolsDataTable.defaultProps = {
  isLoading: false,
  isFetching: false,
  pagination: true,
  showHeader: true,
  disableAutoHeight: false,
  options: {},
  components: {},
  triggersHeight: {},
  sherlock: {
    query: '',
    columns: [],
  },
  optionsSelectable: undefined,
  optionsExpandable: undefined,
}

ToolsDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,

  isLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  pagination: PropTypes.bool,
  showHeader: PropTypes.bool,
  disableAutoHeight: PropTypes.bool,
  options: PropTypes.object,
  components: PropTypes.object,
  triggersHeight: PropTypes.object,
  sherlock: PropTypes.shape({
    query: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.string),
  }),
  optionsSelectable: PropTypes.shape({
    type: PropTypes.oneOf(['single', 'multiple']).isRequired,
    onRowSelected: PropTypes.func,
    rowsSelected: PropTypes.arrayOf(PropTypes.number),
    selectOnClick: PropTypes.bool,
    isRowSelectable: PropTypes.func,
  }),
  optionsExpandable: PropTypes.shape({
    type: PropTypes.oneOf(['single', 'multiple']).isRequired,
    onRowExpanded: PropTypes.func,
    rowsExpanded: PropTypes.arrayOf(PropTypes.number),
    expandOnClick: PropTypes.bool,
    expandedChildren: PropTypes.func,
  }),

  tableRef: PropTypes.any,
  heightHeader: PropTypes.string,
}
