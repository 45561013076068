import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { PlanoSaudeLancamentoDTO } from './dtos/PlanoSaudeLancamentoDTO'

const query = '/PlanoSaudeLancamento/ObterLancamentosTitular'

interface RequestProps {
  params: {
    estabelecimentoId: string
    planoSaudeId?: string
    anoMes: string
  }
}

export function useObterLancamentosTitular({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params.planoSaudeId) return
    const response = await api.get<{ data: PlanoSaudeLancamentoDTO[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
