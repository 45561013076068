import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog, CurrencyTextField } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import * as yup from 'yup'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome').nullable(),
  vrVale: yup.string().required('Informe o Valor Vale.'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/valeTransporte/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/valeTransporte', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Transportadora"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            label="Codigo"
            fullWidth
            value={data.codigo || ''}
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            autoFocus
            value={data.nome || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="nome"
            inputProps={{ maxLength: 80 }}
            onChange={(e) => {
              const nome = e?.target.value || ''
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Valor Vale"
            fullWidth
            value={data.vrVale || 0}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="vrVale"
            onChange={(e, value) => {
              const vrVale = value || ''
              setData({ ...data, vrVale })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
