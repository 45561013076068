import { CopyLabel, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { RelAniversariantesDataDTO } from '~/hooks/queries/RelAniversariantes/dtos/RelAniversariantesDataDTO'

interface TableProps {
  data: RelAniversariantesDataDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
  tableRef: FixLater
}

export default function Table({ data, isLoading, isFetching, query, tableRef }: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Código',
      name: 'funcionarioCodigo',
    },
    {
      label: 'CPF',
      name: 'funcionarioNrInscricao',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{value}</CopyLabel>,
      },
    },
    {
      label: 'Nome',
      name: 'funcionarioNome',
    },
    {
      label: 'Admissão',
      name: 'funcionarioDtAdmissao',
    },
    {
      label: 'Aniversário',
      name: 'funcionarioDtAniversario',
    },
  ]

  return (
    <ToolsDataTable
      tableRef={tableRef}
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      disableAutoHeight
      sherlock={{ columns: ['pessoaCodigo', 'pessoaCPF', 'pessoaNome'], query }}
    />
  )
}
