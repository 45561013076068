import React, { useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'

import { PageHeader, Button, ButtonBox } from 'mio-library-ui'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import { IndSalarioContratualEnum } from '~/@types/enums/IndSalarioContratualEnum'
import useAdmissaoPreliminar from '~/hooks/queries/VinculoAdmissaoPreliminar/useVinculoAdmissaoPreliminarGetByVinculo'
import useUtils from '~/hooks/useUtils'

const HEADER_HEIGHT = '50px'

const VinculoSalarioContratual = (props) => {
  const { vinculo, considerIsFinalizado, dtEfetiva, dtAlteracao } = props

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { getAdmissaoPreliminar } = useAdmissaoPreliminar()
  const { formatCurrency } = useUtils()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(`/vinculoSalarioBase/GetByVinculo/${vinculo.id}`)
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  const handleClickItem = (id) => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: true,
      id,
    }))
  }

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    const itens = collection.itens
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    try {
      await api.delete(`/vinculoSalarioBase/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleOpenForm = useCallback(
    async (
      data = {
        vinculoId: vinculo?.id,
        vinculo: vinculo,
        indSalario: IndSalarioContratualEnum.Minimo,
        dtEfetiva: dtEfetiva,
        dtSalario: dtAlteracao,
        quantidade: 1,
      },
    ) => {
      setForm((oldState) => ({
        ...oldState,
        isOpen: true,
        data,
      }))

      if (collection?.itens?.length === 0 && vinculo?.reciboAdmissaoPreliminar) {
        const nrInscricao = vinculo?.pessoaFisica?.nrInscricao
        const empregadorId = vinculo?.estabelecimento?.empregadorId

        const admissaoPreliminar = await getAdmissaoPreliminar(nrInscricao, empregadorId)

        const salario = formatCurrency(admissaoPreliminar?.vrSalarioFixo)

        dialogNotification.info({
          descriptions: [
            'Existe uma Admissão Preliminar para este funcionário',
            `O valor do salário definido na Admissão Preliminar é de R$${salario || ''}`,
          ],
        })
      }
    },
    [
      vinculo,
      dtEfetiva,
      dtAlteracao,
      collection.itens,
      dialogNotification,
      getAdmissaoPreliminar,
      formatCurrency,
    ],
  )

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [handleOpenForm])

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection],
  )

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            {vinculo?.isFinalizado && considerIsFinalizado ? (
              <></>
            ) : (
              <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
                Adicionar
              </Button>
            )}
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          onItemClick={handleClickItem}
          isFinalizado={vinculo?.isFinalizado && considerIsFinalizado}
        />
      </Box>

      <Form
        isOpen={form.isOpen}
        data={form.data}
        vinculo={vinculo}
        collection={collection.itens}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </>
  )
}

export default VinculoSalarioContratual

VinculoSalarioContratual.defaultProps = {
  considerIsFinalizado: true,
  dtEfetiva: null,
  dtAlteracao: null,
}
