import { useState } from 'react'
import { useIsFetching } from 'react-query'

import * as yup from 'yup'
import moment from 'moment'

import { Box, Grid, Paper } from '@material-ui/core'

import { Button, DatePickerNew, SimpleSelect, Stack, TextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import useCredentials from '~/hooks/useCredentials'
import { query } from '~/hooks/queries-log/useConsultaPessoal'

import {
  ConsultaPessoalEntidadeEnum,
  ConsultaPessoalEntidadeValues,
} from '~/hooks/queries-log/@types/ConsultaPessoalEntidades'
import {
  OperacaoConsultaEnum,
  OperacaoConsultaValues,
} from '~/hooks/queries-log/@types/OperacaoConsultaEnum'

import { isSameYearAndMonth } from '~/utils/utils'

const schema = yup.object().shape({
  inicio: yup
    .date()
    .required('Informe a Data Início')
    .nullable()
    .typeError('Informe uma data válida'),
  fim: yup
    .date()
    .required('Informe a Data Fim')
    .nullable()
    .typeError('Informe uma data válida')
    .when(['inicio'], (inicio, schema) => {
      return schema.test(
        'same-month',
        'Período deve pertencer ao mesmo Mês/Ano',
        (value: string) => {
          return isSameYearAndMonth(inicio, value)
        },
      )
    }),
  entidade: yup.string().required('Informe a Entidade').nullable(),
})

export interface DataProps {
  inicio?: string | Date | null
  fim?: string | Date | null
  entidade: ConsultaPessoalEntidadeEnum
  usuarioEmail?: string
  operacao: OperacaoConsultaEnum
}

interface SearchFormProps {
  onSubmit: (dt: DataProps) => void
}

export default function SearchForm({ onSubmit }: SearchFormProps) {
  const { userLogged } = useCredentials()

  const isLoading = useIsFetching({ queryKey: [query] }) > 0

  const startData = {
    inicio: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    fim: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    operacao: OperacaoConsultaEnum.TodasOperacoes,
    usuarioEmail: userLogged.email,
    entidade: ConsultaPessoalEntidadeEnum.RP,
  }

  const [data, setData] = useState<DataProps>(startData)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    onSubmit(data)
  }

  return (
    <Box component={Paper} p={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <DatePickerNew
            required
            label="Início"
            name="inicio"
            invalidDateMessage=""
            cancelLabel="Cancelar"
            validationErrors={validationErrors}
            placeholder="Selecione a data inicial"
            KeyboardButtonProps={{ size: 'small' }}
            value={data?.inicio || null}
            onChange={(date) => {
              const inicio = date
              setData((prev) => ({ ...prev, inicio }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePickerNew
            required
            label="Fim"
            name="fim"
            invalidDateMessage=""
            cancelLabel="Cancelar"
            validationErrors={validationErrors}
            placeholder="Selecione a data final"
            KeyboardButtonProps={{ size: 'small' }}
            value={data?.fim || null}
            onChange={(date) => {
              const fim = date
              setData((prev) => ({ ...prev, fim }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SimpleSelect
            label="Entidade"
            options={ConsultaPessoalEntidadeValues}
            optionId="value"
            name="entidade"
            validationErrors={validationErrors}
            required
            value={data.entidade}
            renderOption={(d) => d.name}
            onChange={(_, value) => {
              const entidade = value
              setData((prev) => ({ ...prev, entidade }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SimpleSelect
            label="Tipo de Operação"
            options={OperacaoConsultaValues}
            optionId="value"
            value={data.operacao}
            renderOption={(d) => d.name}
            onChange={(_, value) => {
              const operacao = value
              setData((prev) => ({ ...prev, operacao }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="E-mail / Usuário"
            value={data?.usuarioEmail || ''}
            onChange={(e) => {
              const usuarioEmail = e.target.value
              setData((prev) => ({
                ...prev,
                usuarioEmail,
              }))
            }}
          />
        </Grid>

        <Stack orientation="horizontal" width="100%" justifyContent="flex-end">
          <Button size="small" variant="outlined" onClick={() => setData(() => startData)}>
            Cancelar
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleValidate}
            isLoading={isLoading}
          >
            Buscar
          </Button>
        </Stack>
      </Grid>
    </Box>
  )
}
