import { useEffect, useState } from 'react'

import { Box, Grid, Paper } from '@material-ui/core'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import { useHistory } from 'react-router-dom'

import {
  ContainerTable,
  SimpleSelect,
  Stack,
  StackContainer,
  PageHeader,
  Finder,
  ButtonBox,
  Button,
} from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import Table from './components/Table'
import { eSocialEventoValues, eSocialEventoConsts } from '~/values/eSocialEventoValues'
import { IndApuracaoEnum, IndApuracaoValues } from '~/@types/enums/IndApuracaoEnum'

import { useObterEventosExclusaoESocial } from '~/hooks/queries/ESocialExclusaoEvento/useObterEventosExclusaoESocial'
import { useProcessarExclusaoEventoESocial } from '~/hooks/queries/ESocialExclusaoEvento/useProcessarExclusaoEventoESocial'

const {
  S_1200,
  S_1210,
  S_1260,
  S_1270,
  S_1280,
  S_2190,
  S_2200,
  S_2205,
  S_2206,
  S_2210,
  S_2220,
  S_2221,
  S_2230,
  S_2240,
  S_2298,
  S_2299,
  S_2300,
  S_2306,
  S_2399,
  S_2500,
  S_2501,
} = eSocialEventoConsts
const eSocialEventsToFilter = [
  S_1200,
  S_1210,
  S_1260,
  S_1270,
  S_1280,
  S_2190,
  S_2200,
  S_2205,
  S_2206,
  S_2210,
  S_2220,
  S_2221,
  S_2230,
  S_2240,
  S_2298,
  S_2299,
  S_2300,
  S_2306,
  S_2399,
  S_2500,
  S_2501,
]

const eSocialEventoValuesFiltred = eSocialEventoValues.filter((obj) =>
  eSocialEventsToFilter.includes(obj.value),
)

const initialData = { esocialEvento: '', indApuracao: IndApuracaoEnum.Mensal_1 }

export default function ExclusaoEventosESocial() {
  const [indexsSelecteds, setIndexsSelecteds] = useState([])
  const [query, setQuery] = useState('')
  const [data, setData] = useState(initialData)

  const dialogNotification = useDialogNotification()
  const { anoMes, mes, empregador } = useAmbiente()
  const history = useHistory()

  const isShowIndApuracao = mes === '12' && data.esocialEvento === S_1200

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterEventosExclusaoESocial({
    params: {
      anoMes,
      empregadorId: empregador.id,
      esocialEvento: data.esocialEvento,
      indApuracao: data.indApuracao,
    },
  })
  const collection = _d || []

  const { mutateAsync: mutateAsyncProcess, isLoading: isLoadingProcess } =
    useProcessarExclusaoEventoESocial()

  useEffect(() => {
    setIndexsSelecteds([])
  }, [_d])

  async function handleSearch() {
    if (!data.esocialEvento) {
      return dialogNotification.warning({
        descriptions: ['Informe o Evento'],
      })
    }
    await refetch()
  }

  async function handleProcess() {
    const auditoriasIds = indexsSelecteds.map(
      (indexCurrent) => collection[indexCurrent].auditoriaId,
    )
    await mutateAsyncProcess({
      params: {
        anoMes,
        empregadorId: empregador.id,
      },
      data: auditoriasIds,
    })
    history.push('/transmissao-esocial/1')
  }

  return (
    <StackContainer>
      <PageHeader title="Exclusão de Eventos">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </ButtonBox>
      </PageHeader>
      <Box component={Paper} padding={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8} md={10}>
            <Stack>
              <MUIAutoComplete
                label="Evento"
                value={data.esocialEvento}
                onChange={(e, obj) => {
                  setData((oldState) => ({
                    ...oldState,
                    esocialEvento: obj ? obj.value : '',
                  }))
                }}
                options={eSocialEventoValuesFiltred}
                renderOption={(option) => option.name}
                optionId="value"
              />
              {isShowIndApuracao && (
                <SimpleSelect
                  label="Indicador de Apuração"
                  options={IndApuracaoValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  value={data.indApuracao}
                  onChange={(e, value) => {
                    const indApuracao = value || ''
                    setData((oldState) => ({ ...oldState, indApuracao }))
                  }}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <ButtonBox>
                <Button variant="contained" onClick={handleSearch} isLoading={isLoading}>
                  Buscar Eventos
                </Button>
              </ButtonBox>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ContainerTable>
        <Table
          data={collection}
          isLoading={isLoading}
          isFetching={isFetching}
          query={query}
          onRowSelected={setIndexsSelecteds}
          rowsSelected={indexsSelecteds}
        />
      </ContainerTable>

      <Box component={Paper} p={1}>
        <ButtonBox>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleProcess}
            isLoading={isLoadingProcess}
            disabled={!(indexsSelecteds.length > 0) ? true : false}
          >
            Excluir Eventos
          </Button>
        </ButtonBox>
      </Box>
    </StackContainer>
  )
}
