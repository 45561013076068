import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtAssinatura: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data da assinatura'),
})

const initialState = {
  dtAssinatura: getDateCurrent(),
}

interface DialogConfirmProps {
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: (dtAssinatura: string) => void
}

export default function DialogConfirm({ isOpen, onClose, onAfterSubmit }: DialogConfirmProps) {
  const [data, setData] = useState(initialState)

  function handleSubmit() {
    onAfterSubmit(data.dtAssinatura)
    onClose()
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  return (
    <ActionDialog
      title="Informe a data para Assinatura"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data da assinatura"
            value={data?.dtAssinatura || null}
            name="dtAssinatura"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtAssinatura = date || ''
              setData((prevState) => ({ ...prevState, dtAssinatura }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
