import { formatToCPF } from 'brazilian-values'
import moment from 'moment'

import { Badge, IconButton, useTheme } from '@material-ui/core'
import { Warning } from '@material-ui/icons'

import { ButtonBox, CopyLabel, ToolsDataTable } from '~/components'

import { ExtrairPlanilhaResponseFuncionarioDTO } from '~/hooks/queries/ImportacaoPlanilhaFaltas/dtos/ExtrairPlanilhaResponseDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  IndLancamentoFaltaEnum,
  IndLancamentoFaltaLabel,
} from '~/@types/enums/IndLancamentoFaltaEnum'
import useDialog from '~/hooks/useDialog'
import DialogProblems from './DialogProblems'

interface TableProps {
  data: ExtrairPlanilhaResponseFuncionarioDTO[]
  query: string
}
export default function Table({ data, query }: TableProps) {
  const theme = useTheme()
  const {
    close: closeDialogProblems,
    data: dataDialogProblems,
    isOpen: isOpenDialogProblems,
    open: openDialogProblems,
  } = useDialog<{ data: string[]; subtitle: string }>({ data: [], subtitle: '' })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'cpf',
      label: 'CPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'dtFalta',
      label: 'Data',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
      },
    },
    {
      name: 'indLancamento',
      label: 'Tipo',
      options: {
        customBodyRender: (value: IndLancamentoFaltaEnum) => IndLancamentoFaltaLabel[value],
      },
    },
    {
      name: 'qtdHoras',
      label: 'Atraso em Horas',
      options: {
        customBodyRender: (value) => (value ? moment(value, 'HH:mm:ss').format('HH:mm') : ''),
      },
    },
    {
      name: 'isDSR',
      label: 'DSR',
      options: {
        customBodyRender: (value) => (value ? 'Sim' : 'Não'),
      },
    },
    {
      name: 'problemas',
      label: 'Problemas',
      options: {
        customBodyRender: (value, { rowIndex }) => (
          <ButtonBox justifyContent="center">
            <IconButton
              size="small"
              disabled={value.length === 0}
              onClick={() => {
                const dataCurrent = data[rowIndex]
                if (dataCurrent) {
                  openDialogProblems({
                    data: dataCurrent.problemas,
                    subtitle: `${dataCurrent.nome} - ${formatToCPF(dataCurrent.cpf)}`,
                  })
                }
              }}
            >
              <Badge badgeContent={value.length} color="primary">
                <Warning
                  style={{
                    color:
                      value.length === 0
                        ? theme.palette.action.disabled
                        : theme.palette.warning.main,
                  }}
                />
              </Badge>
            </IconButton>
          </ButtonBox>
        ),
      },
    },
  ]

  return (
    <>
      <ToolsDataTable
        data={data}
        columns={columns}
        disableAutoHeight
        sherlock={{
          query,
          columns: ['cpf', 'nome'],
        }}
      />
      {isOpenDialogProblems && (
        <DialogProblems
          isOpen={isOpenDialogProblems}
          onClose={closeDialogProblems}
          data={dataDialogProblems.data}
          subtitle={dataDialogProblems.subtitle}
        />
      )}
    </>
  )
}
