import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterTodosInvalidate } from './useObterTodos'

import { notifyRemove } from '~/utils/notification'

export function useRemover() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosInvalidate()

  async function handleRequest(id: string) {
    await api.delete('/ProcessoTrabalhistaTributosPeriodoContribuicao/' + id)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
