import { useRef, useState } from 'react'

import { Paper } from '@material-ui/core'

import {
  PageHeader,
  PrintButton,
  PDFViewer,
  Finder,
  StackContainer,
  ContainerTable,
  Stack,
} from '~/components'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { extractSelectedRowsTable } from '~/utils/utils'

const TITLE_PAGE = 'Recibo Entrega/Devolução CTPS'

export default function ReciboEntregaDevolucaoCTPS() {
  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const tableRef = useRef<FixLater>(null)
  const { estabelecimento, anoMes } = useAmbiente()

  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog()

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<{
    data: string[]
    params: { estabelecimentoId: string; dtRecebimento?: string; dtDevolucao?: string }
  }>({
    data: [],
    params: {
      estabelecimentoId: estabelecimento.id,
    },
  })

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    estabelecimentoId: estabelecimento.id,
    anoMes,
  })
  const data = _d || []

  function handleAfterConfirm(dtRecebimento?: string, dtDevolucao?: string) {
    const rowsSelecteds = extractSelectedRowsTable(tableRef)
    if (rowsSelecteds.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um funcionário para prosseguir'],
      })
    }
    const vinculosId = rowsSelecteds.map((rowIndex) => data[rowIndex].id)
    openPDFViewer({
      data: vinculosId,
      params: {
        estabelecimentoId: estabelecimento.id,
        dtRecebimento,
        dtDevolucao,
      },
    })
  }

  return (
    <StackContainer>
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <ContainerTable>
        <Table
          query={query}
          tableRef={tableRef}
          data={data}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      </ContainerTable>

      <Stack alignItems="flex-end" p={1} component={Paper}>
        <PrintButton onClick={openDialogConfirm} />
      </Stack>

      {isOpenDialogConfirm && (
        <DialogConfirm
          isOpen={isOpenDialogConfirm}
          onClose={closeDialogConfirm}
          onAfterSubmit={handleAfterConfirm}
        />
      )}

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="ReciboEntregaDevolucaoCTPS"
          title={TITLE_PAGE}
          axiosConfig={{
            method: 'post',
            url: '/Relatorio/ReciboEntregaDevolucaoCTPS/ObterRelatorio',
            data: dataPDFViewer.data,
            params: dataPDFViewer.params,
          }}
          isSalvarDocumento
        />
      )}
    </StackContainer>
  )
}
