import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'
import StepperForm from '~/components/StepperForm'

import TabCadastro from './Tabs/TabCadastro'
import TabPeriodoValores from './Tabs/TabPeriodoValores'
import TabRemuneracoes from './Tabs/TabRemuneracoes'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCreate } from '~/hooks/queries/ProcessoTrabalhistaVinculo/useCreate'

import { TipoContratoProcessoEnum } from '~/@types/enums/TipoContratoProcessoEnum'
import { IndRepercussaoProcessoEnum } from '~/@types/enums/IndRepercussaoProcessoEnum'
import { ProcessoTrabalhistaVinculoCreateDTO } from '~/hooks/queries/ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculoCreateDTO'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

export enum TabProcessoTrabalhista {
  Cadastro,
  PeirodoValores,
  Remuneracoes,
}

const schema = yup.object().shape({
  processoTrabalhistaId: yup.string().required('Informe o Processo Trabalhista'),
  dtEmissao: yup.date().required('Informe a Data de Emissão').typeError('Informe uma data válida'),
  tipoContratoProcesso: yup
    .mixed()
    .oneOf(Object.values(TipoContratoProcessoEnum), 'Informe o Tipo de Contrato'),
  competenciaInicio: yup.string().required('Informe a Competência Início'),
  competenciaFim: yup.string().required('Informe a Competência Fim'),
  indRepercussaoProcesso: yup
    .mixed()
    .oneOf(Object.values(IndRepercussaoProcessoEnum), 'Informe a Repercussão do Processo'),

  tipoInscricaoResponsavel: yup
    .mixed()
    .when(['tipoContratoProcesso'], (tipoContratoProcesso: TipoContratoProcessoEnum, schema) => {
      if (tipoContratoProcesso === TipoContratoProcessoEnum.Responsabilidade_Indireta_8) {
        return schema.test(
          'validate',
          'Informe o Tipo Inscrição Responsável',
          (value: TipoInscricaoEnum) =>
            value === TipoInscricaoEnum.CPF_2 || value === TipoInscricaoEnum.CNPJ_1,
        )
      }
      return schema
    }),
  nrInscricaoResponsavel: yup
    .mixed()
    .when(['tipoContratoProcesso'], (tipoContratoProcesso: TipoContratoProcessoEnum, schema) => {
      if (tipoContratoProcesso === TipoContratoProcessoEnum.Responsabilidade_Indireta_8) {
        return yup.string().required('Informe o Número de Inscrição Responsável')
      }
      return schema
    }),
})

interface FormCreateProps {
  isOpen: boolean
  onClose: () => void
  vinculoId: string
}

export default function FormCreate({ isOpen, onClose, vinculoId }: FormCreateProps) {
  const [tab, setTab] = useState<TabProcessoTrabalhista>(TabProcessoTrabalhista.Cadastro)
  const [data, setData] = useState<ProcessoTrabalhistaVinculoCreateDTO>({
    processoTrabalhistaId: '',
    vinculoId,
    dtEmissao: '',
    tipoContratoProcesso:
      TipoContratoProcessoEnum.Formalizado_Sem_Alteracoes_Admissao_Desligamento_1,
    isCadastradoESocial: false,
    dtAdmissaoOriginal: '',
    isIndReintegracao: false,
    isIndCategoria: false,
    isIndNaturezaAtividade: false,
    isIndMotivoDesligamento: false,
    competenciaInicio: '',
    competenciaFim: '',
    indRepercussaoProcesso: IndRepercussaoProcessoEnum.DecisaoComRepercussaoTributaria_01,
    isIndenizacaoSeguroDesemprego: false,
    isIndenizacaoAbonoSalarial: false,
    nrInscricaoResponsavel: '',
    tipoInscricaoResponsavel: TipoInscricaoEnum.NaoInformar_0,
    bases: [],
    remuneracoes: [],
  })

  const { mutateAsync, isLoading: isSubmitting } = useCreate()

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Processo Trabalhista Funcionário"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={() => {
        setTab(TabProcessoTrabalhista.Cadastro)
        handleValidate(null)
      }}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
    >
      <StepperForm.Provider
        stepCurrent={tab}
        setStepCurrent={setTab}
        steps={[
          {
            icon: 'account_circle',
            label: 'Cadastro',
            children: (
              <TabCadastro
                data={data}
                setData={setData}
                validationErrors={validationErrors}
                setTab={setTab}
              />
            ),
          },
          {
            icon: 'chrome_reader_mode',
            label: 'Períodos e Valores',
            children: <TabPeriodoValores data={data} setData={setData} />,
          },
          {
            icon: 'attach_money',
            label: 'Remunerações',
            children: <TabRemuneracoes data={data} setData={setData} />,
          },
        ]}
      >
        <Grid container spacing={0}>
          <Grid item xs={1} md={2}>
            <StepperForm.Steps />
          </Grid>
          <Grid item xs={11} md={10}>
            <StepperForm.Content />
          </Grid>
        </Grid>
      </StepperForm.Provider>
    </ActionDialog>
  )
}
