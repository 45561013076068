import React, { useState, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { PageHeader, Button } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import useDialog from '~/hooks/useDialog'

import { PessoaFisicaDependentePartial } from '~/hooks/queries/PessoaFisicaDependente/PessoaFisicaDependente'

import { useStepperContext } from '~/components/StepperForm'
import { MainDataForm } from '..'

const HEADER_HEIGHT = '50px'

export default function FormPessoaFisicaDependente() {
  const { mainData, onBack, onSubmit, onSkipAndSubmit } = useStepperContext<MainDataForm>()

  const [collection, setCollection] = useState(mainData.dataDependentes)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<{ data: PessoaFisicaDependentePartial; index: number } | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog(-1)

  const handleClickItem = useCallback(
    (event: 'delete' | 'edit', index: number) => {
      const handleClickEditItem = () => {
        const item = collection.find((i, indexCurrent) => indexCurrent === index)
        if (!item) return
        openForm({ data: item, index })
      }

      const handleClickDeleteItem = () => {
        openConfirmDelete(index)
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event]()
    },
    //eslint-disable-next-line
    [collection],
  )

  const handleDeleteItem = () => {
    try {
      const newItens = collection.filter((i, indexCurrent) => indexCurrent !== dataConfirmDelete)
      closeConfirmDelete()
      setCollection(newItens)
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  const handleAfterSubmitForm = (
    event: 'update' | 'insert',
    data: PessoaFisicaDependentePartial,
  ) => {
    const handleAfterInsert = () => {
      const newItens = [...collection, data]
      setCollection(newItens)
      closeForm()
    }

    const handleAfterUpdate = () => {
      const newItens = collection.map((i, indexCurrent) =>
        indexCurrent === dataForm?.index ? data : i,
      )
      setCollection(newItens)
      closeForm()
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    functions[event]()
  }

  function onCancel() {
    onBack()
  }

  function handleFinish() {
    const data = { ...mainData, dataDependentes: collection }
    if (collection.length > 0) {
      onSubmit(data)
    } else {
      onSkipAndSubmit(data)
    }
  }

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            <Button
              size="small"
              color="primary"
              onClick={() =>
                openForm({
                  data: {
                    pessoaFisicaId: mainData.dataPessoaFisica.id,
                    isDependenteIR: true,
                    isDependenteSF: true,
                    isDeficiente: false,
                    isBeneficiarioPensaoAlimenticia: false,
                  },
                  index: -1,
                })
              }
              variant="contained"
            >
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={collection} onItemClick={handleClickItem} />
      </Box>

      {dataForm && (
        <Form
          isOpen={isOpenForm}
          data={dataForm.data}
          isUpdate={dataForm.index >= 0}
          onClose={closeForm}
          onAfterSubmitForm={handleAfterSubmitForm}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onCancel}>Voltar</Button>
          <Button onClick={handleFinish} variant="contained">
            Finalizar
          </Button>
        </ButtonBox>
      </Box>
    </>
  )
}
