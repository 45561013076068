import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import api from '~/services/api-pessoal'

import { ApurarConvencaoColetivaCompetenciaDTO } from './ApurarConvencaoColetivaDTO'

const queryObterConvencaoColetivaPorVinculo =
  '/ApurarConvencaoColetiva/ObterConvencaoColetivaPorVinculo'

interface RequestProps {
  vinculoId: string
  anoMes: string
}

export default function useObterConvencaoColetivaPorVinculo({ vinculoId, anoMes }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ApurarConvencaoColetivaCompetenciaDTO[] }>(
      queryObterConvencaoColetivaPorVinculo,
      {
        params: {
          vinculoId,
          anoMes,
        },
      },
    )
    return response.data.data
  }

  return useQuery([queryObterConvencaoColetivaPorVinculo, vinculoId, anoMes], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
