import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { HeightDataTable } from '~/components'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import moment from 'moment'

import { ASOIndResultadoValues } from '~/values/ASOIndResultadoValues'

const formatIndResultado = (indResultado) => {
  const objectFinded = ASOIndResultadoValues.find((obj) => obj.value === indResultado)
  return objectFinded.name
}

const TableItem = (props) => {
  const { data: _data, onItemClick, isLoading } = props

  const data = _data?.map((item) => {
    return {
      ...item,
      dtExame: item?.dtExame ? moment(item.dtExame).format('DD/MM/YYYY') : null,
      indResultado: formatIndResultado(item.indResultado),
      dtVencimentoExame: item?.dtVencimentoExame
        ? moment(item.dtVencimentoExame).format('DD/MM/YYYY')
        : null,
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtExame',
        label: 'Data do Exame',
      },
      {
        name: 'indResultado',
        label: 'Resultado',
      },
      {
        name: 'validadeExame',
        label: 'Validade do Exame',
      },
      {
        name: 'dtVencimentoExame',
        label: 'Data de Vencimento',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable title="" data={data} columns={columns} isLoading={isLoading} />
}

export default TableItem
