import React, { useEffect, useMemo, useState } from 'react'

import { Box, Checkbox, makeStyles } from '@material-ui/core'

import useAmbiente from '~/hooks/useAmbiente'

import { ToolsDataTable } from '~/components'

const CustomCheckboxExpanded = (props) => {
  let newProps = Object.assign({}, props)
  return <Checkbox size="small" {...newProps} />
}

const useStyles = makeStyles((theme) => ({
  hightLightRow: {
    backgroundColor: theme.palette.secondary.lighter,
    '&:hover': {
      backgroundColor: '#DCF2F8!important',
    },
  },
}))

const Table = (props) => {
  const { data, isLoading, query, eventsSelecteds, setEventsSelecteds, triggersHeight } = props

  const [rowsExpanded, setRowsExpanded] = useState([])
  const [dataExpanded, setDataExpanded] = useState([])

  const [indexsFirstLevel, setIndexsFirstLevel] = useState([])
  const [indexsSecondLevel, setIndexsSecondLevel] = useState([])

  const { empregador } = useAmbiente()
  const classes = useStyles()

  useEffect(() => {
    function resetData() {
      setRowsExpanded([])
      setDataExpanded([])
      setEventsSelecteds([])
      setIndexsFirstLevel([])
      setIndexsSecondLevel([])
    }
    resetData()
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    function changeFirstLevel() {
      const indexSelecteds = []
      eventsSelecteds.forEach((eventCurrent) => {
        const indexFinded = data.findIndex(
          (dataCurrent) => dataCurrent.empregadorId === eventCurrent.empregadorId,
        )
        if (indexSelecteds.some((oldIndex) => oldIndex === indexFinded)) return
        indexSelecteds.push(indexFinded)
      })
      setIndexsFirstLevel(indexSelecteds)
    }

    function changeSecondLevel() {
      const indexSelecteds = []
      eventsSelecteds.forEach((eventCurrent) => {
        const indexFinded = dataExpanded.findIndex(
          (event) =>
            event.eventoEsocial === eventCurrent.eventoEsocial &&
            event.empregadorId === eventCurrent.empregadorId,
        )
        if (indexFinded !== -1) indexSelecteds.push(indexFinded)
      })
      setIndexsSecondLevel(indexSelecteds)
    }

    changeFirstLevel()
    changeSecondLevel()
    // eslint-disable-next-line
  }, [eventsSelecteds])

  const columns = useMemo(
    () => [
      {
        name: 'empregadorId',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'empregadorNome',
        label: 'Empregador',
      },
      {
        name: 'nrRegistro',
        label: 'Total de Registros',
      },
    ],
    [],
  )

  const columnsTableExpanded = useMemo(
    () => [
      {
        name: 'nrRegistro',
        label: 'Registros',
        options: {
          display: 'excluded',
        },
      },
      {
        name: 'descricao',
        label: 'Evento',
        options: {
          customBodyRender: (value, { rowData }) => {
            const nrRegistro = rowData[0]
            return `${value} - Registros: ${nrRegistro}`
          },
        },
      },
    ],
    [],
  )

  function handleExpandRow(indexs) {
    setRowsExpanded(indexs)
    if (indexs.length === 0) return
    const dataCurrent = data[indexs[0]]
    const eventosCurrent = dataCurrent.eventos

    const indexSelecteds = []
    eventsSelecteds.forEach((eventSelected) => {
      const indexFinded = eventosCurrent.findIndex(
        (eventCurrent) =>
          eventSelected.empregadorId === eventCurrent.empregadorId &&
          eventSelected.eventoEsocial === eventCurrent.eventoEsocial,
      )

      if (indexFinded !== -1) indexSelecteds.push(indexFinded)
    })

    setDataExpanded(eventosCurrent)
    setIndexsSecondLevel(indexSelecteds)
  }

  function handleSelectFirstLevel(indexs) {
    const events = []
    indexs.forEach((currentIndex) => {
      const dataCurrent = data[currentIndex]
      const eventsCurrent = dataCurrent.eventos

      const eventsFindeds = []
      eventsSelecteds.forEach((eventSelected) => {
        if (eventSelected.empregadorId === dataCurrent.empregadorId) {
          eventsFindeds.push(eventSelected)
        }
      })

      if (eventsFindeds.length === 0 || eventsFindeds.length === eventsCurrent.length) {
        events.push(...eventsCurrent)
      } else {
        events.push(...eventsFindeds)
      }
    })
    setEventsSelecteds(events)
  }

  function handleSelectSecondLevel(indexs) {
    const events = indexs.map((indexCurrent) => dataExpanded[indexCurrent])
    const oldEvents = eventsSelecteds.filter(
      (eventOld) => eventOld.empregadorId !== dataExpanded[0].empregadorId,
    )
    setEventsSelecteds([...oldEvents, ...events])
  }

  const CustomCheckbox = (props) => {
    let newProps = Object.assign({}, props)

    const descriptionSelect = props['data-description']

    if (descriptionSelect !== 'row-select') return <Checkbox {...newProps} />

    const index = props['data-index'] || 0

    const newChecked = props.checked
    const dataCurrent = data[index]
    const eventsCurrent = data[index]?.eventos || []

    const eventsSelectsByData = []
    eventsSelecteds.forEach((event) => {
      if (event?.empregadorId === dataCurrent?.empregadorId) eventsSelectsByData.push(event)
    })

    return (
      <Checkbox
        checked={eventsCurrent.length === eventsSelectsByData.length ? newChecked : undefined}
        indeterminate={eventsCurrent.length !== eventsSelectsByData.length ? newChecked : undefined}
        {...newProps}
      />
    )
  }

  function renderExpandableRow() {
    return (
      <Box mx="21px">
        <ToolsDataTable
          data={dataExpanded}
          pagination={false}
          columns={columnsTableExpanded}
          options={{
            selectableRowsHeader: false,
          }}
          components={{
            Checkbox: CustomCheckboxExpanded,
          }}
          showHeader={false}
          disableAutoHeight
          optionsSelectable={{
            type: 'multiple',
            onRowSelected: handleSelectSecondLevel,
            rowsSelected: indexsSecondLevel,
          }}
        />
      </Box>
    )
  }

  return (
    <ToolsDataTable
      data={data}
      triggersHeight={triggersHeight}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: ['empregadorNome'],
      }}
      components={{
        Checkbox: CustomCheckbox,
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected: handleSelectFirstLevel,
        rowsSelected: indexsFirstLevel,
      }}
      optionsExpandable={{
        type: 'single',
        expandedChildren: renderExpandableRow,
        onRowExpanded: handleExpandRow,
        rowsExpanded: rowsExpanded,
      }}
      options={{
        setRowProps: (row) => {
          const empregadorId = row[0]
          const isHightLightRow = empregador?.id === empregadorId ? true : false
          return {
            className: isHightLightRow ? classes.hightLightRow : '',
          }
        },
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
