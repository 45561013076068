import { Box, Typography, CircularProgress, useTheme, Paper } from '@material-ui/core'

import { ExcelButton, PrintButton } from '~/components'

import useUtils from '~/hooks/useUtils'

export default function Footer(props) {
  const { isLoading, data, onClickPrintButton, isLoadingCSV, onClickCSVButton } = props

  const { formatCurrency } = useUtils()
  const theme = useTheme()

  let totalEmpresa = 0

  data.forEach((d) => (totalEmpresa += d.vrLiquido))

  return (
    <Box
      component={Paper}
      display="flex"
      justifyContent="flex-end"
      padding={1}
      gridGap={theme.spacing(2)}
    >
      <Typography variant="h6">
        Total da Empresa:{' '}
        {isLoading ? (
          <CircularProgress size={20} />
        ) : totalEmpresa > 0 ? (
          formatCurrency(totalEmpresa)
        ) : (
          '0.00'
        )}
      </Typography>

      <ExcelButton onClick={onClickCSVButton} isLoading={isLoadingCSV} />
      <PrintButton onClick={onClickPrintButton} />
    </Box>
  )
}
