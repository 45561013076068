import React, { useState, useMemo } from 'react'

import { Button } from 'mio-library-ui'
import { makeStyles, Box } from '@material-ui/core'
import { FileCopy as FileCopyIcon } from '@material-ui/icons'
import XMLViewer from 'react-xml-viewer'

import { ToolsDataTable, ActionDialog } from '~/components'

import useNotification from '~/hooks/useNotification'

const useStyles = makeStyles((theme) => ({
  hightLightContent: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  heightPaper: {
    height: '100%',
  },
  contentDialog: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflow: 'auto',
    height: '100%',
  },
}))

function XMLContent(props) {
  const { value } = props
  return <XMLViewer xml={value} />
}

export function ErrosSchemaContent(props) {
  const { value: values, subtitle } = props

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'Nome',
      },
      {
        name: 'value',
        label: 'Valor',
      },
      {
        name: 'message',
        label: 'Mensagem',
      },
      {
        name: 'parentXml',
        label: 'XML',
        options: {
          customBodyRender: (value) => (
            <TableCellContent value={value} type="xml" subtitle={subtitle}>
              XML
            </TableCellContent>
          ),
        },
      },
    ],
    [subtitle],
  )

  return <ToolsDataTable data={values} columns={columns} pagination={false} />
}

export function OcorrenciasContent(props) {
  const { value: values } = props

  const newValues = values.map((stringOcorrencia) => ({
    ocorrencia: stringOcorrencia,
  }))

  const columns = useMemo(
    () => [
      {
        name: 'ocorrencia',
        label: 'Ocorrências',
        options: {
          setCellProps: () => ({
            style: {
              whiteSpace: 'pre-line',
            },
          }),
        },
      },
    ],
    [],
  )

  return <ToolsDataTable data={newValues} columns={columns} pagination={false} />
}

export default function TableCellContent(props) {
  const { value, children, type, subtitle } = props

  const [isOpenDialog, setOpenDialog] = useState(false)

  const classes = useStyles()
  const notification = useNotification()

  function handleClickCellContent() {
    if (!value) return
    setOpenDialog(true)
  }

  function handleCloseDialog() {
    setOpenDialog(false)
  }

  function renderContent() {
    if (type === 'xml') return <XMLContent value={value} />
    if (type === 'errosSchema') return <ErrosSchemaContent value={value} subtitle={subtitle} />
    if (type === 'ocorrencias') return <OcorrenciasContent value={value} />
    return ''
  }

  const handleCopy = (copy) => {
    if (!navigator.clipboard)
      return notification.error('Não foi possível copiar o conteúdo clicado')
    navigator.clipboard.writeText(copy)
    notification.success('Conteúdo copiado para a Área de transferência')
  }

  return (
    <>
      <Box
        className={value ? classes.hightLightContent : ''}
        title={value || ''}
        onClick={handleClickCellContent}
      >
        {children}
      </Box>
      <ActionDialog
        title={children}
        subtitle={subtitle}
        isOpen={isOpenDialog}
        onClose={handleCloseDialog}
        renderRight={
          type === 'xml' && (
            <Button
              onClick={() => handleCopy(value)}
              size="small"
              title="Copiar"
              color="primary"
              variant="outlined"
            >
              <Box paddingRight={1}>Copiar</Box>
              <FileCopyIcon fontSize="small" />
            </Button>
          )
        }
        customActions={<></>}
        dialogProps={{
          fullWidth: true,
          maxWidth: 'md',
          classes: {
            paper: classes.heightPaper,
          },
        }}
      >
        <Box className={classes.contentDialog}>{renderContent()}</Box>
      </ActionDialog>
    </>
  )
}
