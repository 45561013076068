import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPut } from '~/utils/notification'
import { useInvalidate } from './useObterTodos'

import { ModeloIntegracaoAlterarDTO } from './dtos/ModeloIntegracaoAlterarDTO'
import { ModeloIntegracao } from './dtos/ModeloIntegracao'

interface RequestProps {
  data: ModeloIntegracaoAlterarDTO
  params: {
    id: string
  }
}

export function useAtualizar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.put<{ data: ModeloIntegracao }>(
      '/ModeloIntegracao/Atualizar',
      data,
      { params },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
