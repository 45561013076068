import { Box } from '@material-ui/core'

import { Button, ButtonBox } from '~/components'

import PropTypes from 'prop-types'

const HEIGHT_ACTIONS = '50px'

export default function ContainerForm(props) {
  const {
    handleSubmit,
    onCancel,
    isLoading,
    labelOk,
    labelCancel,
    children,
    removeHeight,
    customActions,
  } = props

  return (
    <Box height={`calc(100% - ${removeHeight})`} width="100%" px={1}>
      <Box height={`calc(100% - ${HEIGHT_ACTIONS})`} overflow="auto" p={1}>
        {children}
      </Box>
      <Box width="100%" height={HEIGHT_ACTIONS} display="flex" alignItems="center">
        {customActions ? (
          customActions
        ) : (
          <ButtonBox spacing={2}>
            <Button onClick={onCancel} size="small" color="primary" variant="outlined">
              {labelCancel}
            </Button>
            <Button
              onClick={handleSubmit}
              isLoading={isLoading}
              size="small"
              color="primary"
              variant="contained"
            >
              {labelOk}
            </Button>
          </ButtonBox>
        )}
      </Box>
    </Box>
  )
}

ContainerForm.defaultProps = {
  isLoading: false,
  labelOk: 'Salvar',
  labelCancel: 'Cancelar',
  removeHeight: '0px',
}

ContainerForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  labelOk: PropTypes.string,
  labelCancel: PropTypes.string,
  children: PropTypes.node,
  removeHeight: PropTypes.string,
  customActions: PropTypes.node,
}
