import React, { useMemo } from 'react'

import { ToolsDataTable } from '~/components'

import { formatToCPFOrCNPJ } from 'brazilian-values'

const Table = (props) => {
  const { data: _data, isLoading, onRowSelected, rowsSelecteds, query } = props

  const data = _data.map((obj) => {
    return {
      ...obj,
      nrInscricao: obj?.nrInscricao ? formatToCPFOrCNPJ(obj?.nrInscricao) : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'qtdRecibos',
        label: 'Quantidade de Recibos',
      },
    ],
    [],
  )

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      disableAutoHeight
      sherlock={{
        columns: ['nrInscricao', 'nome'],
        query,
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected: onRowSelected,
        rowsSelected: rowsSelecteds,
        selectOnClick: true,
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
