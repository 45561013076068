import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { VinculoLembrete } from './VinculoLembrete'

export const queryGetByVinculoPeriodo = '/VinculoLembrete/GetByVinculoPeriodo'

interface RequestProps {
  vinculoId: string
  anoMes: string
}

export function useGetByVinculoPeriodo(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoLembrete[] }>(queryGetByVinculoPeriodo, {
      params,
    })
    return response.data.data
  }

  return useQuery([queryGetByVinculoPeriodo, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
