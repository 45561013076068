import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { CNAB } from './dtos/CNAB'

const query = '/CNAB/GetByEstabelecimento'

interface RequestProps {
  params: {
    id: string
  }
}

export default function useGetByEstabelecimento({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: CNAB[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
