import React, { useState } from 'react'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'
import { formatToCPF } from 'brazilian-values'

export default function AutoCompletePessoaFisica(props) {
  const { label, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/PessoaFisicaConsulta')
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) =>
    option ? `${formatToCPF(option?.nrInscricao)} - ${option?.nome}` : ''

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label={label}
      optionId="id"
      {...rest}
    />
  )
}

AutoCompletePessoaFisica.defaultProps = {
  label: 'Pessoa Física',
}
