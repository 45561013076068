import { convertToArrayDescription } from '~/utils/utils'

export enum IndConsultaVencimentosEnum {
  AvisoPrevioDemissao,
  ContratoExperiencia,
  RetornoAfastamento,
  RetornoFerias,
  FeriasVencidas,
  AdmissoesPreliminaresAbertas,
  ExameToxicolico,
  ProgramacaoFerias,
}

export const IndConsultaVencimentosLabel = {
  [IndConsultaVencimentosEnum.AvisoPrevioDemissao]: 'Aviso Prévio / Pedido de Demissão',
  [IndConsultaVencimentosEnum.ContratoExperiencia]: 'Contrato de Experiência',
  [IndConsultaVencimentosEnum.RetornoAfastamento]: 'Retorno de Afastamento',
  [IndConsultaVencimentosEnum.RetornoFerias]: 'Retorno de Férias',
  [IndConsultaVencimentosEnum.FeriasVencidas]: 'Férias Vencidas',
  [IndConsultaVencimentosEnum.AdmissoesPreliminaresAbertas]: 'Admissões Preliminares Abertas',
  [IndConsultaVencimentosEnum.ExameToxicolico]: 'Exame Toxicológico',
  [IndConsultaVencimentosEnum.ProgramacaoFerias]: 'Programação de Férias',
}

export const IndConsultaVencimentosColor = {
  [IndConsultaVencimentosEnum.AvisoPrevioDemissao]: '#FF6868',
  [IndConsultaVencimentosEnum.ContratoExperiencia]: '#F6B17A',
  [IndConsultaVencimentosEnum.RetornoAfastamento]: '#86A7FC',
  [IndConsultaVencimentosEnum.RetornoFerias]: '#FFAF45',
  [IndConsultaVencimentosEnum.FeriasVencidas]: '#FCDC2A',
  [IndConsultaVencimentosEnum.AdmissoesPreliminaresAbertas]: '#A1EEBD',
  [IndConsultaVencimentosEnum.ExameToxicolico]: '#7FA1C3',
  [IndConsultaVencimentosEnum.ProgramacaoFerias]: '#BEDC74',
}

export const IndConsultaVencimentosValues = convertToArrayDescription(
  IndConsultaVencimentosEnum,
  IndConsultaVencimentosLabel,
)
