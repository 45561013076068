import React, { useState, useCallback, useEffect } from 'react'

import { Grid } from '@material-ui/core'

import { ButtonBox, Button } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useCredentials from '~/hooks/useCredentials'

import PasswordTextField from '~/components/PasswordTextField'

import * as yup from 'yup'

const schema = yup.object().shape({
  pass: yup.string().required('Informe a Senha Atual'),
  newPass: yup.string().required('Informe a Nova Senha'),
  confirmNewPass: yup
    .string()
    .required('Informe a Nova Senha a ser Confirmada')
    .oneOf([yup.ref('newPass')], 'A nova senha e a senha a ser confirmada devem ser iguais'),
})

const initialStateForm = {
  pass: '',
  newPass: '',
  confirmNewPass: '',
}

const AlterarSenha = (props) => {
  const { isOpen, onClose } = props

  const [data, setData] = useState(initialStateForm)
  const notification = useNotification()

  const { alterarSenha, isSubmitting } = useCredentials()

  useEffect(() => {
    if (!isOpen) return
    setData(initialStateForm)
  }, [isOpen])

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleAlterarSenha = async () => {
    const { pass, newPass } = data
    const response = await alterarSenha(pass, newPass)

    if (response) {
      notification.success('A sua senha foi alterada')
      handleClose()
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleAlterarSenha,
    data,
  })

  return (
    <ActionDialog
      isOpen={isOpen}
      onClose={handleClose}
      onCancelClick={handleClose}
      title="Alterar Senha"
      customActions={<div />}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PasswordTextField
            autoFocus
            value={data?.pass || ''}
            onChange={(e) => {
              const pass = e?.target?.value || ''
              setData((oldState) => ({ ...oldState, pass }))
            }}
            label="Senha Atual"
            size="small"
            fullWidth
            variant="outlined"
            required
            validationErrors={validationErrors}
            name="pass"
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PasswordTextField
            value={data?.newPass || ''}
            onChange={(e) => {
              const newPass = e?.target?.value || ''
              setData((oldState) => ({ ...oldState, newPass }))
            }}
            label="Nova Senha"
            size="small"
            fullWidth
            variant="outlined"
            validationErrors={validationErrors}
            required
            name="newPass"
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PasswordTextField
            value={data?.confirmNewPass || ''}
            onChange={(e) => {
              const confirmNewPass = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                confirmNewPass,
              }))
            }}
            label="Confirmar Nova Senha"
            size="small"
            fullWidth
            variant="outlined"
            validationErrors={validationErrors}
            required
            name="confirmNewPass"
          />
        </Grid>
      </Grid>
      <ButtonBox top={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleValidate}
          isLoading={isSubmitting}
        >
          Alterar
        </Button>
      </ButtonBox>
    </ActionDialog>
  )
}

export default AlterarSenha
