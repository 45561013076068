import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoConsulta } from './VinculoConsulta'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'

interface RequestProps {
  estabelecimentoId: string
  vinculoTipo?: VinculoTipoEnum[]
  anoMes?: string
  value?: string
  categoria?: CategoriaEnum
}

export function useVinculoConsultaGetByEstabelecimento(props: RequestProps) {
  const { vinculoTipo, ...rest } = props

  const dialogNotification = useDialogNotification()

  async function vinculoConsultaGetByEstabelecimento() {
    const response = await api.post<{ data: VinculoConsulta[] }>(
      '/VinculoConsulta/GetByEstabelecimento',
      vinculoTipo,
      {
        params: rest,
      },
    )
    return response.data.data
  }

  return useQuery(
    ['VinculoConsultaGetByEstabelecimento', props],
    vinculoConsultaGetByEstabelecimento,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}
