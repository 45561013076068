import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { HorarioTrabalhoIntervalo } from './HorarioTrabalhoIntervalo'

const queryGetByHorarioTrabalho = '/HorarioTrabalhoIntervalo/GetByHorarioTrabalho'

export default function useGetByHorarioTrabalho(horarioTrabalhoId: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: HorarioTrabalhoIntervalo[] }>(
      queryGetByHorarioTrabalho,
      {
        params: {
          horarioTrabalhoId,
        },
      },
    )
    return response?.data?.data || []
  }

  return useQuery([queryGetByHorarioTrabalho, horarioTrabalhoId], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
