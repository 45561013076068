import { useState, useCallback, memo } from 'react'

import { makeStyles } from '@material-ui/core'
import { ActionDialog } from '~/components'

import TableFeriasItens from './components/TableFeriasItens'
import Table from './components/Table'
import Form from './components/Form'

import api from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'

import useGetByVinculo from '~/hooks/queries/VinculoFerias/useGetByVinculo'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '100%',
  },
}))

const MemoTable = memo(Table)

const Ferias = (props) => {
  const { isOpen, onClose, vinculo } = props

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const [dialogTableFeriasItens, setDialogTableFeriasItens] = useState({
    isOpen: false,
    isLoading: false,
    itens: [],
  })

  const dialogNotification = useDialogNotification()
  const classes = useStyles()

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching,
  } = useGetByVinculo({
    vinculoId: vinculo?.id,
    isApenasAbertas: false,
  })
  const data = _data || []
  const isLoading = _isLoading || isFetching

  const handleOpenForm = (
    data = {
      vinculoId: vinculo?.id,
      vinculo: vinculo,
    },
  ) => {
    setForm((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickViewItem = (id) => {
        const item = data.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickPeriodoFeriasItem = async (id) => {
        if (!id) {
          setDialogTableFeriasItens({
            isLoading: false,
            isOpen: false,
            itens: [],
          })
          return
        }
        setDialogTableFeriasItens((oldState) => ({
          ...oldState,
          isLoading: true,
        }))
        try {
          const response = await api.get(
            `/VinculoFeriasItem/GetByVinculoFerias?vinculoFeriasId=${id}`,
          )
          if (response.data.data) {
            setDialogTableFeriasItens((oldState) => ({
              ...oldState,
              isOpen: true,
              itens: response.data.data,
              isLoading: false,
            }))
          }
        } catch (err) {
          dialogNotification.extractErrors(err)
        }
        setDialogTableFeriasItens((oldState) => ({
          ...oldState,
          isLoading: false,
        }))
      }

      const functions = {
        view: handleClickViewItem,
        periodoFerias: handleClickPeriodoFeriasItem,
      }
      functions[event](value)
    },
    //eslint-disable-next-line
    [data, handleOpenForm],
  )

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleCloseDialogTableFeriasItens = () => {
    setDialogTableFeriasItens((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }

  return (
    <ActionDialog
      title="Férias"
      subtitle={vinculo?.pessoaFisica?.nome || ''}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      customActions={<></>}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: { paper: classes.heightPaper },
      }}
    >
      <MemoTable data={data} isLoading={isLoading} onItemClick={handleClickItem} />

      <Form isOpen={form.isOpen} data={form.data} onClose={handleCloseForm} />

      <TableFeriasItens
        isOpen={dialogTableFeriasItens.isOpen}
        onClose={handleCloseDialogTableFeriasItens}
        data={dialogTableFeriasItens.itens}
        isLoading={dialogTableFeriasItens.isLoading}
      />
    </ActionDialog>
  )
}

export default Ferias
