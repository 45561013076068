import { Grid } from '@material-ui/core'

import { NumeroInscricaoTextField, Fieldset } from '~/components'
import { useStepperContext } from '~/components/StepperForm'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { ContextProps, titleAllFieldsDisabled } from '..'

import { CessaoInformacaoOnusValues } from '~/@types/enums/CessaoInformacaoOnusEnum'

import { VinculoAfastamentoForm } from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'

export default function InformacaoCessao() {
  const {
    dataControlled: data,
    onChangeControlled: setData,
    context,
  } = useStepperContext<VinculoAfastamentoForm>()
  const { disabledAllFields, validationErrors } = context as ContextProps

  return (
    <Fieldset disabled={disabledAllFields} title={disabledAllFields ? titleAllFieldsDisabled : ''}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NumeroInscricaoTextField
            label="Cessão Nr. Inscrição"
            value={data?.cessaoNrInscricao || ''}
            onChange={(e, value) => {
              const cessaoNrInscricao = value
              setData({ ...data, cessaoNrInscricao })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MUIAutoComplete
            label="Cessão Informação Ônus"
            options={CessaoInformacaoOnusValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="cessaoInformacaoOnus"
            value={data.cessaoInformacaoOnus}
            onChange={(e, obj) => {
              const cessaoInformacaoOnus = obj ? obj.value : ''
              setData({ ...data, cessaoInformacaoOnus })
            }}
          />
        </Grid>
      </Grid>
    </Fieldset>
  )
}
