import clsx from 'clsx'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  makeStyles,
  Divider,
  Chip,
  IconButton,
  Checkbox,
} from '@material-ui/core'
import { CheckCircleRounded as CheckCircleRoundedIcon } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { formatAnoMes, formatCurrency, formatIdfReciboPagamento } from '~/hooks/useUtils'

import { RP } from '~/hooks/queries/RP/RP'

const useStyles = makeStyles((theme) => ({
  rootSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  flexContent: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexContentSmall: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  rootTotal: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  total: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  totalVencimentos: {
    color: theme.palette.grey[700],
  },
  totalDescontos: {
    color: theme.palette.error.light,
  },
  totalLiquido: {
    color: theme.palette.secondary.main,
  },
  disabledBackground: {
    background: theme.palette.background.default,
  },
}))

interface ReciboItemProps {
  data: RP
  onCheck: (data: RP) => void
  isChecked: boolean
}

export default function ReciboItem(props: ReciboItemProps) {
  const { data, onCheck, isChecked } = props

  const classes = useStyles()

  const isReciboEnviadoESocial = data.reciboEsocial ? true : false
  const isReciboFinalizado = data.isFinalizado ? true : false

  const isDisabled = isReciboEnviadoESocial || isReciboFinalizado

  return (
    <Accordion className={clsx({ [classes.disabledBackground]: isDisabled })}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className={classes.rootSummary}>
          <Box className={classes.flexContent}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                onCheck(data)
              }}
              disabled={isDisabled}
            >
              <Checkbox size="small" checked={isChecked} disabled={isDisabled} />
            </IconButton>
            <Chip variant="outlined" size="small" label={formatAnoMes(data.anoMes)} />
            <Typography>{formatIdfReciboPagamento(data.idf)}</Typography>
          </Box>

          <Box className={classes.flexContent}>
            {isReciboFinalizado && (
              <Typography
                variant="body2"
                title="Recibo Finalizado"
                className={classes.flexContentSmall}
              >
                Finalizado
                <CheckCircleRoundedIcon fontSize="small" className={classes.colorSuccess} />
              </Typography>
            )}

            {isReciboEnviadoESocial && (
              <Typography
                variant="body2"
                title="Recibo enviado para o eSocial"
                className={classes.flexContentSmall}
              >
                eSocial
                <CheckCircleRoundedIcon fontSize="small" className={classes.colorSuccess} />
              </Typography>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography variant="body2">Número Recibo</Typography>
            <Typography color="textSecondary">{data.nrRecibo}</Typography>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography variant="body2">Recibo eSocial</Typography>
            <Typography color="textSecondary">{data?.reciboEsocial || ' - '}</Typography>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography variant="body2">Fato Gerador</Typography>
            <Typography color="textSecondary">{formatAnoMes(data.fatoGerador)}</Typography>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography variant="body2">Data eSocial</Typography>
            <Typography color="textSecondary">{data?.dtReciboEsocial || ' - '}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box className={classes.rootTotal}>
              <Box>
                <Typography variant="body2">Total Vencimento</Typography>
                <Typography className={clsx(classes.total, classes.totalVencimentos)}>
                  {formatCurrency(data.vrTotalLiquido)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">Total Desconto</Typography>
                <Typography className={clsx(classes.total, classes.totalDescontos)}>
                  {formatCurrency(data.vrTotalLiquido)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">Total Líquido</Typography>
                <Typography className={clsx(classes.total, classes.totalLiquido)}>
                  {formatCurrency(data.vrTotalLiquido)}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
