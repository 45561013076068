import React, { useState, useEffect, useCallback } from 'react'

import { Grid, Box, MenuItem, Tooltip, IconButton, Zoom } from '@material-ui/core'
import { TextField, ButtonBox, ContentDivider, PageHeader } from 'mio-library-ui'

import {
  CEPTextField,
  CollapseContent,
  DatePicker,
  NumeroInscricaoTextField,
  ContainerForm,
  TelefoneTextField,
  CurrencyTextField,
  Checkbox,
} from '~/components'
import {
  AutoCompleteCidade,
  AutoCompleteEmpregador,
  MUIAutoComplete,
  AutoCompleteHorarioTrabalho,
  AutoCompleteLogradouro,
  AutoCompleteLotacao,
  AutoCompleteSindicato,
  AutoCompleteTabelaMes,
} from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import { cnpj } from 'cpf-cnpj-validator'
import * as yup from 'yup'

import api from '~/services/api-pessoal'

import {
  tipoInscricaoValues as _tipoInscricaoValues,
  tipoInscricaoConsts,
} from '~/values/tipoInscricaoValues'
import { tipoCAEPFConsts, tipoCAEPFValues } from '~/values/tipoCAEPFValues'
import { sindicatoCategoriaConsts } from '~/values/sindicatoCategoriaValues'
import { codigoGpsValues } from '~/values/codigoGpsValues'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const { CNPJ_1, CAEPF_3, CNO_4 } = tipoInscricaoConsts
const { naoInformado_0 } = tipoCAEPFConsts

const tipoInscricaoValues = _tipoInscricaoValues.filter(
  (obj) => obj.value === CNPJ_1 || obj.value === CAEPF_3 || obj.value === CNO_4,
)

const schema = yup.object().shape({
  empregadorId: yup.string().required('Informe o Empregador'),
  nome: yup.string().required('Informe o Nome'),
  tipoInscricao: yup.string().required('Informe o Tipo de Inscrição'),
  nrInscricao: yup
    .string()
    .required('Informe o Número de Inscrição')
    .when(['tipoInscricao'], (tipoInscricao, schema) =>
      schema.test('test', 'Informe um Número de Inscriçao válido', (nrInscricao) => {
        if (parseInt(tipoInscricao) === CNPJ_1) return cnpj.isValid(nrInscricao)
        if (parseInt(tipoInscricao) === CAEPF_3) return nrInscricao?.length === 14
        if (parseInt(tipoInscricao) === CNO_4) return nrInscricao?.length === 12
      }),
    ),
  cep: yup.string().required('Informe o CEP').min(8, 'Inserir no mínimo 8 caracteres.').nullable(),
  logradouroId: yup.string().required('Informe o Logradouro').nullable(),
  endereco: yup.string().required('Informe o Endereço'),
  bairro: yup.string().required('Informe o Bairro'),
  cidadeId: yup.string().required('Informe a Cidade'),
  tipoCAEPF: yup.string().when(['tipoInscricao'], (tipoInscricao, schema) => {
    if (parseInt(tipoInscricao) === CAEPF_3) {
      return schema
        .test(
          'test',
          'Informe um Tipo CAEPF válido',
          (_tipoCAEPF) => parseInt(_tipoCAEPF) !== naoInformado_0,
        )
        .required('Informe um CAEPF')
    }

    return schema
  }),
  caepf: yup
    .string()
    .when(['tipoInscricao'], (tipoInscricao, schema) => {
      if (parseInt(tipoInscricao) === CAEPF_3) {
        return schema
          .required('Informe um CAEPF')
          .test('test', 'Informe um CAEPF válido', (_caepf) => _caepf?.length === 14)
      }

      return schema
    })
    .nullable(),
  codigoSEFIP: yup.string().required('Informe o código SEFIP'),
  tabelaMesId: yup.string().required('Informe a Tabela mês'),
  modalidadeSEFIP: yup.string().required('Informe a Modalidade SEFIP'),
})

const HEADER_HEIGHT = '70px'

const CadastroEstabelecimento = (props) => {
  const { data: _data, onAfterSubmitForm, onClose, setWasModified } = props
  const [data, setData] = useState({})
  const [collapseItens, setCollapseItens] = useState({
    primaria: true,
    outras: true,
    eSocial: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setWasModified(JSON.stringify(data) !== JSON.stringify(_data))
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    setData(_data)
    const dialogRoot = document.getElementsByClassName('MuiDialogContent-root')[0]
    dialogRoot.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setIsLoading(true)
      try {
        const response = await api.put(`/estabelecimento/${data.id}`, data)
        onClose()
        if (onAfterSubmitForm) onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    const insert = async () => {
      setIsLoading(true)
      try {
        const response = await api.post('/estabelecimento', data)
        onAfterSubmitForm('insert', response.data.data)
        notification.post()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    data.nome = data.nome.trim()
    if (data.id) {
      update()
      return
    }
    insert()
    // eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const toggleCollapse = (item) => {
    setCollapseItens({ ...collapseItens, [item]: !collapseItens[item] })
  }

  const handleCollapseAll = (open) => {
    let newItens = {}

    for (const item in collapseItens) {
      newItens = { ...newItens, [item]: open }
    }

    setCollapseItens({ ...newItens })
  }

  return (
    <Box height="100%">
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1} bottom={1}>
            <Tooltip title="Expandir todos" arrow TransitionComponent={Zoom}>
              <IconButton size="small" onClick={() => handleCollapseAll(true)}>
                <CollapseOpenIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Retrair todos" arrow TransitionComponent={Zoom}>
              <IconButton size="small" onClick={() => handleCollapseAll(false)}>
                <CollapseCloseIcon />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        </PageHeader>
      </Box>

      <ContainerForm
        handleSubmit={handleValidate}
        onCancel={onClose}
        isLoading={isLoading}
        removeHeight={HEADER_HEIGHT}
      >
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Informações primárias"
              isOpen={collapseItens.primaria}
              onClick={() => toggleCollapse('primaria')}
            >
              <Grid container spacing={2}>
                <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
                  <TextField
                    label="Código"
                    fullWidth
                    value={data.codigo || ''}
                    variant="outlined"
                    size="small"
                    disabled
                  />
                </Grid>
                <Grid item xl={10} lg={10} md={10} sm={8} xs={12}>
                  <AutoCompleteEmpregador
                    label="Empregador"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="empregadorId"
                    required={data?.id ? false : true}
                    disabled={data?.id ? true : false}
                    validationErrors={validationErrors}
                    value={data?.empregadorId}
                    optionId="id"
                    onChange={(e, empregador) => {
                      const empregadorId = empregador?.id || ''
                      setData((oldState) => ({
                        ...oldState,
                        empregadorId,
                      }))
                      if (empregador?.tipoInscricao === CNPJ_1) {
                        const nrInscricao = empregador?.nrInscricao || ''
                        const nome = empregador?.nome || ''
                        setData((oldState) => ({
                          ...oldState,
                          nrInscricao,
                          nome,
                        }))
                      }
                      if (empregador?.tipoInscricao !== CAEPF_3) {
                        const tipoCAEPF = naoInformado_0
                        setData((oldState) => ({ ...oldState, tipoCAEPF }))
                      }
                    }}
                  />
                </Grid>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
                  <MUIAutoComplete
                    label="Tipo de Inscrição"
                    name="tipoInscricao"
                    required
                    validationErrors={validationErrors}
                    options={tipoInscricaoValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    value={data?.tipoInscricao || ''}
                    onChange={(e, obj) => {
                      const tipoInscricao = obj?.value
                      setData((oldState) => ({
                        ...oldState,
                        tipoInscricao,
                      }))
                      if (tipoInscricao !== CAEPF_3) {
                        const tipoCAEPF = naoInformado_0
                        setData((oldState) => ({
                          ...oldState,
                          tipoCAEPF,
                          caepf: '',
                        }))
                      }
                      if (tipoInscricao !== CNO_4) {
                        const cnpjRespCNO = ''
                        setData((oldState) => ({
                          ...oldState,
                          cnpjRespCNO,
                        }))
                      }
                    }}
                  />
                </Grid>

                <Grid xl={4} lg={4} md={4} sm={8} xs={12} item>
                  <NumeroInscricaoTextField
                    typeMask={data?.tipoInscricao}
                    label="Número de Inscrição"
                    value={data?.nrInscricao || ''}
                    name="nrInscricao"
                    required
                    validationErrors={validationErrors}
                    onChange={(e, value) => {
                      const nrInscricao = value
                      setData((oldState) => ({
                        ...oldState,
                        nrInscricao,
                      }))
                    }}
                    getDataCNPJ={
                      !data?.id
                        ? (response) =>
                            setData((oldState) => ({
                              ...oldState,
                              ...response,
                            }))
                        : undefined
                    }
                  />
                </Grid>

                <Grid xl={4} lg={4} md={4} sm={12} xs={12} item>
                  <NumeroInscricaoTextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    typeMask={CNPJ_1}
                    label="CNPJ Responsável pelo CNO"
                    value={data?.cnpjRespCNO || ''}
                    disabled={data?.tipoInscricao !== CNO_4}
                    onChange={(e, value) => {
                      const cnpjRespCNO = value
                      setData((oldState) => ({
                        ...oldState,
                        cnpjRespCNO,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Nome"
                    name="nome"
                    validationErrors={validationErrors}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data?.nome || ''}
                    required
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const nome = e?.target?.value || ''
                      setData((oldState) => ({
                        ...oldState,
                        nome,
                      }))
                    }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <MUIAutoComplete
                    label="Tipo CAEPF"
                    options={tipoCAEPFValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required={data?.tipoInscricao === CAEPF_3 ? true : false}
                    validationErrors={validationErrors}
                    name="tipoCAEPF"
                    value={data.tipoCAEPF}
                    onChange={(e, obj) => {
                      const tipoCAEPF = obj ? obj.value : ''
                      setData({ ...data, tipoCAEPF })
                    }}
                    disabled={data?.tipoInscricao !== CAEPF_3 ? true : false}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <NumeroInscricaoTextField
                    label="CAEPF"
                    variant="outlined"
                    value={data?.caepf || ''}
                    typeMask={CAEPF_3}
                    size="small"
                    fullWidth
                    disabled={data?.tipoInscricao !== CAEPF_3 ? true : false}
                    required={data?.tipoInscricao === CAEPF_3 ? true : false}
                    validationErrors={validationErrors}
                    name="caepf"
                    onChange={(e, value) => {
                      const caepf = value
                      setData((oldState) => ({
                        ...oldState,
                        caepf,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={data.email || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 200,
                    }}
                    onChange={(e) => {
                      const email = e.target.value || ''
                      setData({ ...data, email })
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Endereço" />
                </Grid>
                <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                  <CEPTextField
                    name="cep"
                    label="CEP"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    validationErrors={validationErrors}
                    value={data?.cep || ''}
                    getAddress={
                      data?.id
                        ? undefined
                        : (address) =>
                            setData((oldState) => ({
                              ...oldState,
                              ...address,
                            }))
                    }
                    onChange={(e) => {
                      const cep = e?.target?.value?.replace(/\D/g, '') || ''
                      setData({ ...data, cep })
                    }}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <AutoCompleteLogradouro
                    name="logradouroId"
                    required
                    validationErrors={validationErrors}
                    value={data?.logradouroId || null}
                    optionId="id"
                    onChange={(e, logradouro) => {
                      const logradouroId = logradouro?.id || null
                      setData({ ...data, logradouroId, logradouro })
                    }}
                  />
                </Grid>
                <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                  <TextField
                    name="endereco"
                    label="Endereço"
                    fullWidth
                    required
                    validationErrors={validationErrors}
                    value={data.endereco || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const endereco = e.target.value
                      setData({ ...data, endereco })
                    }}
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                  <TextField
                    label="Número"
                    fullWidth
                    value={data.numero || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      const numero = e.target.value
                      setData({ ...data, numero })
                    }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={10} sm={12} xs={12}>
                  <TextField
                    name="bairro"
                    label="Bairro"
                    fullWidth
                    required
                    validationErrors={validationErrors}
                    value={data.bairro || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 80,
                    }}
                    onChange={(e) => {
                      const bairro = e.target.value
                      setData({ ...data, bairro })
                    }}
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    label="Complemento"
                    fullWidth
                    value={data.complemento || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const complemento = e.target.value
                      setData({ ...data, complemento })
                    }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <AutoCompleteCidade
                    name="cidadeId"
                    required
                    validationErrors={validationErrors}
                    onChange={(e, cidade) => {
                      const cidadeId = cidade ? cidade.id : ''
                      setData({ ...data, cidadeId, cidade })
                    }}
                    value={data?.cidadeId || ''}
                    optionId="id"
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <TelefoneTextField
                    value={data?.telefone || ''}
                    onChange={(e, value) => {
                      const telefone = value
                      setData((oldState) => ({
                        ...oldState,
                        telefone,
                      }))
                    }}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Informações adcionais" />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Observação"
                    fullWidth
                    multiline
                    rows={4}
                    value={data.observacao || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 500,
                    }}
                    onChange={(e) => {
                      const observacao = e.target.value
                      setData({ ...data, observacao })
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Outras informações"
              isOpen={collapseItens.outras}
              onClick={() => toggleCollapse('outras')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <CurrencyTextField
                    label="Capital Social"
                    fullWidth
                    value={data?.vrCapitalSocial || 0}
                    variant="outlined"
                    size="small"
                    onChange={(e, value) => {
                      const vrCapitalSocial = value || 0
                      setData({ ...data, vrCapitalSocial })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <DatePicker
                    label="Início Atividade"
                    size="small"
                    value={data?.dtInicioAtividade || null}
                    onChange={(date) => {
                      const dtInicioAtividade = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtInicioAtividade })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <DatePicker
                    label="Fim Atividade"
                    size="small"
                    value={data?.dtFimAtividade || null}
                    onChange={(date) => {
                      const dtFimAtividade = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtFimAtividade })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <DatePicker
                    label="Data Início da Inatividade"
                    size="small"
                    value={data.dtInativo || null}
                    onChange={(date) => {
                      const dtInativo = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtInativo })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Checkbox
                    label="O Estabelecimento é a matriz da empresa"
                    value={data?.isMatriz || false}
                    onChange={(_, isMatriz) => {
                      setData((prevState) => ({
                        ...prevState,
                        isMatriz,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Checkbox
                    label="Estabelecimento Doméstico"
                    value={data?.isDomestico || false}
                    onChange={(_, isDomestico) => {
                      setData((prevState) => ({
                        ...prevState,
                        isDomestico,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Checkbox
                    label="Recibo por Lotação"
                    value={data?.isRPLotacao || false}
                    onChange={(_, isRPLotacao) => {
                      setData((prevState) => ({
                        ...prevState,
                        isRPLotacao,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Checkbox
                    title="No local onde é impresso o código será impresso o número da matrícula"
                    label="Ficha Registro Funcionário, imprimir matrícula no lugar do código"
                    value={data?.isFichaFuncionarioCodigoIgualMatricula || false}
                    onChange={(_, isFichaFuncionarioCodigoIgualMatricula) => {
                      setData((prevState) => ({
                        ...prevState,
                        isFichaFuncionarioCodigoIgualMatricula,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <NumeroInscricaoTextField
                    label="CEI"
                    value={data?.cei || ''}
                    typeMask={TipoInscricaoEnum.CEI_6}
                    validationErrors={validationErrors}
                    onChange={(e, value) => {
                      const cei = value
                      setData((prev) => ({ ...prev, cei }))
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Tabelas" />
                </Grid>

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <AutoCompleteSindicato
                    label="Sindicato Empresa"
                    value={data?.sindicatoPatronal || null}
                    categoriaSindicato={sindicatoCategoriaConsts.empresa}
                    onChange={(e, sindicatoPatronal) => {
                      const sindicatoPatronalId = sindicatoPatronal?.id || null
                      setData({
                        ...data,
                        sindicatoPatronalId,
                        sindicatoPatronal,
                      })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <AutoCompleteSindicato
                    label="Sindicato Funcionários"
                    value={data?.sindicatoLaboral || null}
                    categoriaSindicato={sindicatoCategoriaConsts.funcionario}
                    onChange={(e, sindicatoLaboral) => {
                      const sindicatoLaboralId = sindicatoLaboral?.id || null
                      setData({ ...data, sindicatoLaboralId, sindicatoLaboral })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <AutoCompleteLotacao
                    empregadorId={data?.empregadorId}
                    value={data?.lotacaoId || ''}
                    optionId="id"
                    onChange={(e, lotacao) => {
                      const lotacaoId = lotacao?.id || ''
                      setData({ ...data, lotacaoId, lotacao })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <AutoCompleteHorarioTrabalho
                    value={data?.horarioTrabalho}
                    empregadorId={data?.empregadorId}
                    onChange={(e, horarioTrabalho) => {
                      const horarioTrabalhoId = horarioTrabalho?.id || null
                      setData({ ...data, horarioTrabalhoId, horarioTrabalho })
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <AutoCompleteTabelaMes
                    label="Tabela Mês"
                    fullWidth
                    value={data?.tabelaMes || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e, obj) => {
                      const tabelaMesId = obj?.id || ''
                      const tabelaMes = obj || null
                      setData({ ...data, tabelaMesId, tabelaMes })
                    }}
                    required
                    validationErrors={validationErrors}
                    name="tabelaMesId"
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    label="Tabela Vale Refeição"
                    fullWidth
                    value={data?.tabelaValeRefeicao || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e, obj) => {
                      const tabelaValeRefeicaoId = obj?.id || null
                      const tabelaValeRefeicao = obj || null
                      setData({
                        ...data,
                        tabelaValeRefeicaoId,
                        tabelaValeRefeicao,
                      })
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    label="Tabela Vale Alimentação"
                    fullWidth
                    value={data?.tabelaValeAlimentacao || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e, obj) => {
                      const tabelaValeAlimentacaoId = obj?.id || null
                      const tabelaValeAlimentacao = obj || null
                      setData({
                        ...data,
                        tabelaValeAlimentacaoId,
                        tabelaValeAlimentacao,
                      })
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="FGTS" />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    name="codigoSEFIP"
                    label="Código SEFIP"
                    required
                    validationErrors={validationErrors}
                    fullWidth
                    value={data?.codigoSEFIP || ''}
                    variant="outlined"
                    size="small"
                    select
                    onChange={(e) => {
                      const codigoSEFIP = e?.target?.value || ''
                      setData((oldState) => ({ ...oldState, codigoSEFIP }))
                    }}
                  >
                    <MenuItem value="115">
                      115 - Recolhimento ao FGTS e informações a previdência social (no prazo ou em
                      atraso)
                    </MenuItem>
                    <MenuItem value="130">
                      130 - Recolhimento ao FGTS e informações a previdência social relativas ao
                      trabalhador avulso (no prazo ou em atraso)
                    </MenuItem>
                    <MenuItem value="145">
                      145 - Recolhimento ao FGTS de diferenças apuradas pela caixa
                    </MenuItem>
                    <MenuItem value="150">
                      150 - Recolhimento ao FGTS e informações a previdência social de empresa
                      prestadora de serviços com cessão
                    </MenuItem>
                    <MenuItem value="155">
                      155 - Recolhimento ao FGTS e informações a previdência social de obra de
                      construção civil - empreitada total ou obra própria
                    </MenuItem>
                    <MenuItem value="307">
                      307 - Recolhimento de parcelamento do FGTS e informações a previdência social
                    </MenuItem>
                    <MenuItem value="317">
                      317 - Recolhimento de parcelamento do FGTS de empresa com tomador de serviços
                      e informações a previdência
                    </MenuItem>
                    <MenuItem value="327">
                      327 - Recolhimento de parcelamento do FGTS contratado segundo resolução ccfgts
                      325/1999 e informações a previdência
                    </MenuItem>
                    <MenuItem value="337">
                      337 - Recolhimento de parcelamento do FGTS contratado segundo resolução ccfgts
                      325/1999 de empresas com tomador de serviços, priorizando os valores devidos
                      aos trabalhadores
                    </MenuItem>
                    <MenuItem value="345">
                      345 - Recolhimento ao FGTS de diferenças apuradas pela caixa de parcelamento
                      contratados segundo resolução
                    </MenuItem>
                    <MenuItem value="418">418 - Recolhimento recursal para o FGTS</MenuItem>
                    <MenuItem value="604">
                      604 - Recolhimento ao FGTS de entidades com fins filantrópicos - Decreto-Lei
                      n° 194, de 24/02/1967
                    </MenuItem>
                    <MenuItem value="608">
                      608 - Recolhimento ao FGTS e informações a previdência social relativos a
                      dirigente sindical (no prazo ou em atraso)
                    </MenuItem>
                    <MenuItem value="640">
                      640 - Recolhimento ao FGTS para empregado nao optante (competencia anterior a
                      10/88)
                    </MenuItem>
                    <MenuItem value="650">
                      650 - Recolhimento ao FGTS e informações a previdência social relativos a
                      dissídio coletivo ou reclamatória
                    </MenuItem>
                    <MenuItem value="660">
                      660 - Recolhimento exclusivo ao FGTS referente a reclamatória trabalhista (no
                      prazo ou em atraso)
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    name="modalidadeSEFIP"
                    label="Modalidade SEFIP"
                    fullWidth
                    required
                    validationErrors={validationErrors}
                    value={data?.modalidadeSEFIP || ''}
                    variant="outlined"
                    size="small"
                    select
                    onChange={(e) => {
                      const modalidadeSEFIP = e?.target?.value || ''
                      setData((oldState) => ({ ...oldState, modalidadeSEFIP }))
                    }}
                  >
                    <MenuItem value="0">
                      0 - Recolhimento ao FGTS e Declaração à Previdência
                    </MenuItem>
                    <MenuItem value="1">1 - Declaração ao FGTS e à Previdência</MenuItem>
                    <MenuItem value="9">
                      9 - Confirmação inf.anteriores - Rec/Decl ao FGTS e Dec.à Previdência
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Código de GPS"
                    options={codigoGpsValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    value={data?.gps}
                    onChange={(e, obj) => {
                      const gps = obj ? obj.value : ''
                      setData({ ...data, gps })
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Relatórios" />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Relatório Compensação Horas Trabalhadas - Motivo da Compensação"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data?.motivoCompensacaoHorasTrabalhadas || ''}
                    inputProps={{
                      maxLength: 500,
                    }}
                    rows={4}
                    onChange={(e) => {
                      const motivoCompensacaoHorasTrabalhadas = e?.target?.value || ''
                      setData((oldState) => ({
                        ...oldState,
                        motivoCompensacaoHorasTrabalhadas,
                      }))
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>
        </Grid>
      </ContainerForm>
    </Box>
  )
}

export default CadastroEstabelecimento
