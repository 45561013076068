import React, { useState, useEffect, useCallback } from 'react'

import { Grid, Tooltip, IconButton, Zoom, Box } from '@material-ui/core'
import { TextField, PageHeader, ButtonBox } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import {
  DatePicker,
  SimpleHoraTextField,
  CEPTextField,
  NumeroInscricaoTextField,
  CollapseContent,
  Checkbox,
} from '~/components'

import {
  AutoCompleteLogradouro,
  AutoCompleteCidade,
  AutoCompleteMedico,
  AutoCompleteCID,
  AutoCompleteSituacaoGeradora,
  AutoCompleteParteAtingida,
  AutoCompleteCausadorAcidente,
  AutoCompleteLesao,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import { dateHasBetweenYearMonth } from '~/hooks/useUtils'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import {
  tipoInscricaoConsts,
  tipoInscricaoValues as _tipoInscricaoValues,
} from '~/values/tipoInscricaoValues'
import { tipoAcidenteValues, tipoAcidenteConsts } from '~/values/tipoAcidenteValues'
import { CATTipoLocalValues, CATTipoLocalConsts } from '~/values/CATTipoLocalValues'

import { cnpj } from 'cpf-cnpj-validator'
import api from '~/services/api-pessoal'

import * as yup from 'yup'
import moment from 'moment'

import { tipoCATValues, tipoCATConsts } from '~/values/tipoCATValues'
import { CATIniciativaValues } from '~/values/CATIniciativaValues'
import { CATLateralidadeValues } from '~/values/CATLateralidadeValues'
import { simNaoConsts, simNaoValues } from '~/values/simNaoValues'

const { tipico_1, doenca_2, trajeto_3 } = tipoAcidenteConsts
const { inicial_1, reabertura_2, comunicacaoObito_3 } = tipoCATConsts
const { CNPJ_1, CNO_4, CAEPF_3 } = tipoInscricaoConsts
const {
  estabelecimentoEmpregadorBrasil_1,
  estabelecimentoTerceirosPrestadorServicos_3,
  areaRural_5,
  estabelecimentoEmpregadorExterior_2,
} = CATTipoLocalConsts
const isTipoCATReaberturaOrObito = [reabertura_2, comunicacaoObito_3]
const isTipoCATInitialOrReabertura = [inicial_1, reabertura_2]
const isTipoLocalEmprBrasilOrPrestServOrAreaRural = [
  estabelecimentoEmpregadorBrasil_1,
  estabelecimentoTerceirosPrestadorServicos_3,
  areaRural_5,
]

const tipoInscricaoValues = _tipoInscricaoValues.filter(
  (obj) => obj.value === CNPJ_1 || obj.value === CNO_4 || obj.value === CAEPF_3,
)

const schema = yup.object().shape({
  dtAcidente: yup
    .date()
    .required('Informe a Data do Acidente')
    .typeError('Informe uma Data valída')
    .test(
      'DtAcidente-validator',
      `A Data do Acidente não pode ser posterior a data atual: ${moment().format('DD/MM/yyyy')} `,
      (dtAcidente) => moment(dtAcidente).isSameOrBefore(new Date()),
    )
    .when(['$dtAdmissao'], (dtAdmissao, schema) => {
      const dtAdmissaoFormatted = moment(dtAdmissao).format('DD/MM/yyyy')

      return schema.test(
        'dtAcidente-validator',
        `A Data do Acidente deve ser igual ou posterior a Data de Adimissão: ${dtAdmissaoFormatted} `,
        (dtAcidente) => moment(dtAcidente).isSameOrAfter(dtAdmissao),
      )
    })
    .when(['$anoMes', '$isNewRegister'], (anoMes, isNewRegister, schema) => {
      if (!isNewRegister) return

      return schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data informada deve está dentro da competência atual',
        (dt) => dateHasBetweenYearMonth(dt, anoMes),
      )
    })
    .nullable(),
  tipoAcidente: yup.string().required('Informe o Tipo de Acidente'),
  horaAcidente: yup
    .string()
    .when(['tipoAcidente', 'dtAcidente'], (tipoAcidente, dtAcidente, schema) => {
      const parsedTipoAcidente = parseInt(tipoAcidente)
      const dtAcidenteFormatted = dtAcidente ? moment(dtAcidente).format('yyyy-MM-DD') : null

      if (
        parsedTipoAcidente === tipico_1 ||
        (parsedTipoAcidente === trajeto_3 &&
          moment(dtAcidenteFormatted).isSameOrAfter('2022-01-26'))
      ) {
        return schema.required('Informe a Hora do Acidente')
      }

      return schema
    })
    .nullable(),
  horasTrabalhadas: yup
    .string()
    .when(['tipoAcidente', 'dtAcidente'], (tipoAcidente, dtAcidente, schema) => {
      const parsedTipoAcidente = parseInt(tipoAcidente)
      const dtAcidenteFormatted = dtAcidente ? moment(dtAcidente).format('yyyy-MM-DD') : null

      if (
        parsedTipoAcidente === tipico_1 ||
        (parsedTipoAcidente === trajeto_3 &&
          moment(dtAcidenteFormatted).isSameOrAfter('2022-07-20'))
      ) {
        return schema.required('Informe as Horas Trabalhadas Antes do Acidente')
      }

      return schema
    })
    .nullable(),
  tipoCAT: yup.string().required('Informe o Tipo de CAT'),
  situacaoGeradoraAcidente: yup
    .string()
    .required('Informe a Situação Geradora do Acidente')
    .nullable(),
  iniciativaCAT: yup.string().required('Informe a Iniciativa CAT'),
  dtObito: yup
    .date()
    .typeError('Informe uma Data valída')
    .when(['dtAcidente', 'isObito'], (dtAcidente, isObito, schema, { value: dtObito }) => {
      const dtAcidenteFormatted = dtAcidente ? moment(dtAcidente).format('DD/MM/yyyy') : null

      let isValid = true
      let message = ''

      if (isObito === true && !moment(dtObito).isSameOrBefore(moment())) {
        isValid = false
        message = `A Data do Óbito deve ser igual ou anterior à data atual: ${moment().format(
          'DD/MM/yyyy',
        )} `
      }

      if (isObito === true && !moment(dtObito).isSameOrAfter(dtAcidente)) {
        isValid = false
        message = `A Data do Óbito deve ser igual ou posterior a Data do Acidente: ${
          dtAcidente ? dtAcidenteFormatted : ' - '
        } `
      }

      return schema.test('validator', message, () => isValid)
    })
    .when(['isObito'], (isObito, schema) =>
      isObito === true ? schema.required('Informe a Data do Óbito') : schema,
    )
    .nullable(),
  tipoLocal: yup.string().required('Informe o Tipo de Local'),
  tipoLogradouro: yup.string().required('Informe o Logradouro').nullable(),
  descricaoLogradouro: yup.string().required('Informe a Descrição do Logradouro').nullable(),
  numeroLogradouro: yup.string().required('Informe o Número').nullable(),
  tipoInscricao: yup.string().required('Informe o Tipo de Inscrição'),
  nrInscricao: yup
    .string()
    .required('Informe o Número de Inscrição')
    .nullable()
    .when(['tipoInscricao'], (tipoInscricao, schema) =>
      schema.test('test', 'Informe um Número de Inscriçao válido', (nrInscricao) => {
        if (parseInt(tipoInscricao) === CNPJ_1) return cnpj.isValid(nrInscricao)
        if (parseInt(tipoInscricao) === CAEPF_3) return nrInscricao.length === 14
        if (parseInt(tipoInscricao) === CNO_4) return nrInscricao.length === 12
      }),
    ),
  cep: yup
    .string()
    .when(['tipoLocal'], (tipoLocal, schema) =>
      isTipoLocalEmprBrasilOrPrestServOrAreaRural.includes(parseInt(tipoLocal))
        ? schema.required('Informe o CEP')
        : schema,
    )
    .nullable(),
  partesCorpo: yup.string().required('Informe a Parte Atingida').nullable(),
  lateralidade: yup.string().required('Informe a Lateralidade'),
  agenteCausadorAcidente: yup.string().required('Informe o Agente Causador').nullable(),
  dtAtendimento: yup
    .date()
    .typeError('Informe uma Data valída')
    .required('Informe a Data do Atendimento')
    .test(
      'DtAcidente-validator',
      `A Data do Atendimento deve ser igual ou anterior à data atual: ${moment().format(
        'DD/MM/yyyy',
      )} `,
      (dtAtendimento) => moment(dtAtendimento).isSameOrBefore(moment()),
    )
    .when(['dtAcidente'], (dtAcidente, schema) => {
      const dtAcidenteFormatted = moment(dtAcidente).format('DD/MM/yyyy')

      return schema.test(
        'dtAcidente-validator',
        `A Data do Atendimento deve ser igual ou posterior a Data do Acidente: ${
          dtAcidente ? dtAcidenteFormatted : ' - '
        } `,
        (dtAtendimento) => moment(dtAtendimento).isSameOrAfter(dtAcidente),
      )
    }),
  horaAtendimento: yup.string().required('Informe a Hora do Atendimento').nullable(),
  duracaoTratamento: yup.string().required('Informe a Duração do Tratamento').nullable(),
  naturezaLesao: yup.string().required('Informe a Lesão').nullable(),
  descricaoComplementoLesao: yup.string().required('Informe a Descrição da Lesão').nullable(),
  cid: yup.string().required('Informe CID').nullable(),
  medicoId: yup.string().required('Informe o Médico').nullable(),
  numeroCATOrigem: yup
    .string()
    .when(['tipoCAT'], (tipoCAT, schema) =>
      parseInt(tipoCAT) === reabertura_2 ? schema.required('Informe o Número CAT Origem') : schema,
    )
    .nullable(),
  dtUltimoDiaTrabalhado: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data do último dia trabalhado')
    .nullable(),
})

const Form = (props) => {
  const { itens, vinculo, isOpen, onClose, data: _data, onAfterSubmitForm, isRetificar } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const [collapseItens, setCollapseItens] = useState({
    dadosCAT: true,
    localAcidente: true,
    parteAtingida: true,
    atestado: true,
    reabertura: true,
  })

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes } = useAmbiente()

  useEffect(() => {
    setData(_data)
    handleCollapseAll(true)
    //eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put('/ComunicadoAcidenteTrabalho/Salvar', data, {
          params: {
            isRetificar: isRetificar || false,
          },
        })
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/ComunicadoAcidenteTrabalho', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { dtAdmissao: vinculo?.dtAdmissao, anoMes, isNewRegister: data?.id ? false : true },
    },
  })

  const toggleCollapse = (item) => {
    setCollapseItens({ ...collapseItens, [item]: !collapseItens[item] })
  }

  const handleCollapseAll = (open) => {
    let newItens = {}
    for (const item in collapseItens) {
      newItens = { ...newItens, [item]: open }
    }
    setCollapseItens({ ...newItens })
  }

  function handleChangeTipoCAT(_, obj) {
    const tipoCAT = obj ? obj?.value : ''
    setData((oldState) => ({
      ...oldState,
      tipoCAT,
    }))

    const itensExists = itens?.length > 0

    if (itensExists && isTipoCATReaberturaOrObito.includes(tipoCAT)) {
      const lastItem = itens[itens?.length - 1]
      const { dtAcidente, horaAcidente, tipoAcidente } = lastItem

      setData((oldState) => ({
        ...oldState,
        dtAcidente,
        horaAcidente,
        tipoAcidente,
      }))
    }

    const isObito = isTipoCATInitialOrReabertura.includes(tipoCAT) ? false : true

    setData((oldState) => ({
      ...oldState,
      isObito,
    }))
  }

  function handleChangeTipoAcidente(_, obj) {
    const tipoAcidente = obj ? obj.value : ''
    setData((oldState) => ({
      ...oldState,
      tipoAcidente,
    }))

    if (tipoAcidente === doenca_2) {
      setData((oldState) => ({
        ...oldState,
        horaAcidente: '',
        horasTrabalhadas: '',
      }))
    }
  }

  function handleChangeIsObito(_, obj) {
    const isObito = obj?.value === simNaoConsts.sim_1 ? true : false
    setData((oldState) => ({
      ...oldState,
      isObito,
    }))

    if (isObito !== true) {
      setData((oldState) => ({
        ...oldState,
        dtObito: undefined,
      }))
    }

    if (isObito === true) {
      setData((oldState) => ({
        ...oldState,
        IsAfastamentoDuranteTratamento: false,
      }))
    }
  }

  const isEdit = data?.id ? true : false

  const isDisabled = isEdit && (isRetificar === undefined ? false : !isRetificar)

  let styleToDisabled = {}

  if (isDisabled) {
    styleToDisabled = {
      pointerEvents: 'none',
      opacity: '0.6',
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Comunicado Acidente de Trabalho (CAT)"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
    >
      <PageHeader>
        <ButtonBox top={1} bottom={1}>
          <Tooltip title="Expandir todos" arrow TransitionComponent={Zoom}>
            <IconButton size="small" onClick={() => handleCollapseAll(true)}>
              <CollapseOpenIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Retrair todos" arrow TransitionComponent={Zoom}>
            <IconButton size="small" onClick={() => handleCollapseAll(false)}>
              <CollapseCloseIcon />
            </IconButton>
          </Tooltip>
        </ButtonBox>
      </PageHeader>
      <Grid container spacing={2} style={styleToDisabled}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CollapseContent
            title="Comunicação de Acidente de Trabalho - CAT."
            isOpen={collapseItens.dadosCAT}
            onClick={() => toggleCollapse('dadosCAT')}
          >
            <Grid container spacing={2}>
              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <Tooltip
                  arrow
                  placement="top-start"
                  title="Tipo de Comunicado de Acidente de Trabalho"
                >
                  <Box>
                    <MUIAutoComplete
                      label="Tipo de CAT"
                      options={tipoCATValues}
                      optionId="value"
                      renderOption={(option) => option.name}
                      required
                      name="tipoCAT"
                      validationErrors={validationErrors}
                      value={data.tipoCAT}
                      onChange={handleChangeTipoCAT}
                    />
                  </Box>
                </Tooltip>
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <DatePicker
                  label="Data do Acidente"
                  size="small"
                  required
                  name="dtAcidente"
                  validationErrors={validationErrors}
                  disabled={itens?.length > 0 && isTipoCATReaberturaOrObito.includes(data?.tipoCAT)}
                  value={data?.dtAcidente || null}
                  onChange={(date) => {
                    const dtAcidente = date?.format('yyyy-MM-DD') || null
                    setData((oldState) => ({
                      ...oldState,
                      dtAcidente,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <MUIAutoComplete
                  label="Tipo de Acidente"
                  options={tipoAcidenteValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  required
                  disabled={isTipoCATReaberturaOrObito.includes(data?.tipoCAT)}
                  name="tipoAcidente"
                  validationErrors={validationErrors}
                  value={data.tipoAcidente}
                  onChange={handleChangeTipoAcidente}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <SimpleHoraTextField
                  name="horaAcidente"
                  mask="99:99"
                  label="Hora do Acidente"
                  inputProps={{
                    disabled: isTipoCATReaberturaOrObito.includes(data?.tipoCAT),
                  }}
                  required={
                    data?.tipoAcidente === tipico_1 ||
                    (data?.tipoAcidente === trajeto_3 && data?.dtAcidente >= '2022-01-26')
                  }
                  value={data?.horaAcidente || ''}
                  onChange={(e) => {
                    const horaAcidente = e.target.value
                    setData((oldState) => ({
                      ...oldState,
                      horaAcidente,
                    }))
                  }}
                  onBlur={(e) => {
                    const horaAcidente = e.target.value.replace(':', '')

                    if (horaAcidente >= '2359') {
                      setData((oldState) => ({
                        ...oldState,
                        horaAcidente: '23:59',
                      }))
                    }
                  }}
                  validationErrors={validationErrors}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <Tooltip arrow title="Horas trabalhadas antes da ocorrência do acidente">
                  <Box>
                    <SimpleHoraTextField
                      name="horasTrabalhadas"
                      mask="99:99"
                      label="Horas Trabalhadas"
                      required={
                        data?.tipoAcidente === tipico_1 ||
                        (data?.tipoAcidente === trajeto_3 && data?.dtAcidente >= '2022-07-20')
                      }
                      inputProps={{
                        disabled: data?.tipoAcidente === doenca_2,
                      }}
                      value={data?.horasTrabalhadas || ''}
                      onChange={(e) => {
                        const horasTrabalhadas = e.target.value
                        setData((oldState) => ({
                          ...oldState,
                          horasTrabalhadas,
                        }))
                      }}
                      onBlur={(e) => {
                        const horasTrabalhadas = e.target.value.replace(':', '')

                        if (horasTrabalhadas >= '2359') {
                          setData((oldState) => ({
                            ...oldState,
                            horasTrabalhadas: '23:59',
                          }))
                        }
                      }}
                      validationErrors={validationErrors}
                    />
                  </Box>
                </Tooltip>
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <MUIAutoComplete
                  label="Iniciativa CAT"
                  options={CATIniciativaValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  required
                  name="iniciativaCAT"
                  validationErrors={validationErrors}
                  value={data.iniciativaCAT}
                  onChange={(e, obj) => {
                    const iniciativaCAT = obj ? obj.value : ''
                    setData((oldState) => ({
                      ...oldState,
                      iniciativaCAT,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <MUIAutoComplete
                  label="Comunicação com a Autoridade Policial"
                  value={data?.isComunicadoPolicia ? simNaoConsts.sim_1 : simNaoConsts.nao_2}
                  options={simNaoValues}
                  renderOption={(opt) => opt.name}
                  optionId="value"
                  onChange={(_, obj) => {
                    const isComunicadoPolicia = obj?.value === simNaoConsts.sim_1 ? true : false
                    setData((oldState) => ({
                      ...oldState,
                      isComunicadoPolicia,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <MUIAutoComplete
                  label="Houve Óbito"
                  disabled={isTipoCATReaberturaOrObito.includes(data?.tipoCAT)}
                  value={data?.isObito ? simNaoConsts.sim_1 : simNaoConsts.nao_2}
                  options={simNaoValues}
                  renderOption={(opt) => opt.name}
                  optionId="value"
                  onChange={handleChangeIsObito}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <DatePicker
                  name="dtObito"
                  label="Data do Óbito"
                  size="small"
                  required={data?.isObito === true}
                  disabled={data?.isObito !== true}
                  value={data?.dtObito || null}
                  onChange={(date) => {
                    const dtObito = date?.format('yyyy-MM-DD') || null
                    setData((oldState) => ({
                      ...oldState,
                      dtObito,
                    }))
                  }}
                  validationErrors={validationErrors}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <AutoCompleteSituacaoGeradora
                  value={data?.situacaoGeradoraAcidente || null}
                  required
                  name="situacaoGeradoraAcidente"
                  validationErrors={validationErrors}
                  onChange={(e, situacaoGeradoraAcidente) => {
                    const situacaoGeradoraAcidenteId = situacaoGeradoraAcidente?.id || null
                    setData((oldState) => ({
                      ...oldState,
                      situacaoGeradoraAcidente,
                      situacaoGeradoraAcidenteId,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={8} lg={8} md={8} sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Observação"
                  inputProps={{
                    maxLength: 250,
                  }}
                  multiline
                  value={data?.observacaoCAT || ''}
                  onChange={(e) => {
                    const observacaoCAT = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      observacaoCAT,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                <DatePicker
                  name="dtUltimoDiaTrabalhado"
                  label="Data do último dia trabalhado"
                  size="small"
                  required
                  value={data?.dtUltimoDiaTrabalhado || null}
                  onChange={(date) => {
                    const dtUltimoDiaTrabalhado = date?.format('yyyy-MM-DD') || null
                    setData((oldState) => ({
                      ...oldState,
                      dtUltimoDiaTrabalhado,
                    }))
                  }}
                  validationErrors={validationErrors}
                />
              </Grid>
              <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                <MUIAutoComplete
                  label="Houve Afastamento"
                  value={data?.isHouveAfastamento ? simNaoConsts.sim_1 : simNaoConsts.nao_2}
                  options={simNaoValues}
                  required
                  renderOption={(opt) => opt.name}
                  optionId="value"
                  onChange={(e, obj) => {
                    const isHouveAfastamento = obj?.value === simNaoConsts.sim_1 ? true : false
                    setData((oldState) => ({
                      ...oldState,
                      isHouveAfastamento,
                    }))
                  }}
                />
              </Grid>
            </Grid>
          </CollapseContent>
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CollapseContent
            title="Local do Acidente"
            isOpen={collapseItens.localAcidente}
            onClick={() => toggleCollapse('localAcidente')}
          >
            <Grid container spacing={2}>
              <Grid xl={4} lg={4} md={4} sm={12} xs={12} item>
                <MUIAutoComplete
                  label="Tipo de Local"
                  options={CATTipoLocalValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  required
                  name="tipoLocal"
                  validationErrors={validationErrors}
                  value={data.tipoLocal}
                  onChange={(e, obj) => {
                    const tipoLocal = obj ? obj.value : ''
                    setData((oldState) => ({
                      ...oldState,
                      tipoLocal,
                      nrInscricao: '',
                    }))

                    if (tipoLocal === estabelecimentoEmpregadorExterior_2) {
                      setData((oldState) => ({
                        ...oldState,
                        cep: '',
                      }))
                    }
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <MUIAutoComplete
                  label="Tipo de Inscrição"
                  required
                  name="tipoInscricao"
                  validationErrors={validationErrors}
                  options={tipoInscricaoValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  value={data?.tipoInscricao || ''}
                  onChange={(e, obj) => {
                    const tipoInscricao = obj?.value
                    setData((oldState) => ({
                      ...oldState,
                      tipoInscricao,
                      nrInscricao: '',
                    }))
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <NumeroInscricaoTextField
                  size="small"
                  label="Número de Inscrição"
                  variant="outlined"
                  fullWidth
                  required
                  typeMask={data?.tipoInscricao}
                  validationErrors={validationErrors}
                  value={data?.nrInscricao || ''}
                  name="nrInscricao"
                  onChange={(e, value) => {
                    const nrInscricao = value
                    setData((oldState) => ({
                      ...oldState,
                      nrInscricao,
                    }))
                  }}
                  getDataCNPJ={
                    !data?.id
                      ? (response) => setData((oldState) => ({ ...oldState, ...response }))
                      : undefined
                  }
                />
              </Grid>

              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Descrição Local"
                  inputProps={{
                    maxLength: 250,
                  }}
                  multiline
                  value={data?.descricaoLocal || ''}
                  onChange={(e) => {
                    const descricaoLocal = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      descricaoLocal,
                    }))
                  }}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
                <CEPTextField
                  name="cep"
                  label="CEP"
                  fullWidth
                  variant="outlined"
                  size="small"
                  required={isTipoLocalEmprBrasilOrPrestServOrAreaRural.includes(data?.tipoLocal)}
                  disabled={data?.tipoLocal === estabelecimentoEmpregadorExterior_2}
                  value={data?.cep || ''}
                  getAddress={
                    data?.id
                      ? undefined
                      : (address) =>
                          setData((oldState) => ({
                            ...oldState,
                            ...address,
                            tipoLogradouro: address?.logradouroId,
                            descricaoLogradouro: address?.endereco,
                          }))
                  }
                  onChange={(e) => {
                    const cep = e?.target?.value?.replace(/\D/g, '') || ''
                    setData((oldState) => ({ ...oldState, cep }))
                  }}
                  validationErrors={validationErrors}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={8} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Bairro"
                  inputProps={{
                    maxLength: 30,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  value={data?.bairro || ''}
                  onChange={(e) => {
                    const bairro = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      bairro,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={2} lg={2} md={2} sm={4} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Número"
                  inputProps={{
                    maxLength: 10,
                  }}
                  onlyNumber
                  required
                  name="numeroLogradouro"
                  validationErrors={validationErrors}
                  value={data?.numeroLogradouro || ''}
                  onChange={(e) => {
                    const numeroLogradouro = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      numeroLogradouro,
                    }))
                  }}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={8} xs={12}>
                <AutoCompleteCidade
                  value={data?.cidade || null}
                  onChange={(e, cidade) => {
                    const cidadeId = cidade?.id || null
                    setData((oldState) => ({
                      ...oldState,
                      cidadeId,
                      cidade,
                    }))
                  }}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <AutoCompleteLogradouro
                  required
                  name="tipoLogradouro"
                  validationErrors={validationErrors}
                  value={data?.tipoLogradouro || null}
                  optionId="id"
                  onChange={(e, logradouro) => {
                    const tipoLogradouro = logradouro?.id || null
                    setData((oldState) => ({
                      ...oldState,
                      tipoLogradouro,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={8} lg={8} md={8} sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Descrição do Logradouro"
                  inputProps={{
                    maxLength: 100,
                  }}
                  multiline
                  required
                  name="descricaoLogradouro"
                  validationErrors={validationErrors}
                  value={data?.descricaoLogradouro || ''}
                  onChange={(e) => {
                    const descricaoLogradouro = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      descricaoLogradouro,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Complemento"
                  inputProps={{
                    maxLength: 30,
                  }}
                  multiline
                  value={data?.complemento || ''}
                  onChange={(e) => {
                    const complemento = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      complemento,
                    }))
                  }}
                />
              </Grid>
            </Grid>
          </CollapseContent>
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CollapseContent
            title="Parte Atingida"
            isOpen={collapseItens.parteAtingida}
            onClick={() => toggleCollapse('parteAtingida')}
          >
            <Grid container spacing={2}>
              <Grid xl={4} lg={4} md={4} sm={12} xs={12} item>
                <AutoCompleteParteAtingida
                  value={data?.partesCorpo || null}
                  required
                  name="partesCorpo"
                  validationErrors={validationErrors}
                  onChange={(e, partesCorpo) => {
                    const partesCorpoId = partesCorpo?.id || null
                    setData((oldState) => ({
                      ...oldState,
                      partesCorpo,
                      partesCorpoId,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={12} xs={12} item>
                <MUIAutoComplete
                  label="Lateralidade"
                  options={CATLateralidadeValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  required
                  name="lateralidade"
                  validationErrors={validationErrors}
                  value={data.lateralidade}
                  onChange={(e, obj) => {
                    const lateralidade = obj ? obj.value : ''
                    setData((oldState) => ({
                      ...oldState,
                      lateralidade,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={12} xs={12} item>
                <AutoCompleteCausadorAcidente
                  value={data?.agenteCausadorAcidente || null}
                  required
                  name="agenteCausadorAcidente"
                  validationErrors={validationErrors}
                  onChange={(e, agenteCausadorAcidente) => {
                    const agenteCausadorAcidenteId = agenteCausadorAcidente?.id || null
                    setData((oldState) => ({
                      ...oldState,
                      agenteCausadorAcidente,
                      agenteCausadorAcidenteId,
                    }))
                  }}
                />
              </Grid>
            </Grid>
          </CollapseContent>
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CollapseContent
            title="Atestado"
            isOpen={collapseItens.atestado}
            onClick={() => toggleCollapse('atestado')}
          >
            <Grid container spacing={2}>
              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <DatePicker
                  name="dtAtendimento"
                  label="Data do Atendimento"
                  size="small"
                  required
                  value={data?.dtAtendimento || null}
                  onChange={(date) => {
                    const dtAtendimento = date?.format('yyyy-MM-DD') || null
                    setData((oldState) => ({
                      ...oldState,
                      dtAtendimento,
                    }))
                  }}
                  validationErrors={validationErrors}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <SimpleHoraTextField
                  mask="99:99"
                  label="Hora do Atendimento"
                  required
                  name="horaAtendimento"
                  validationErrors={validationErrors}
                  value={data?.horaAtendimento || ''}
                  onChange={(e) => {
                    const horaAtendimento = e.target.value
                    setData((oldState) => ({ ...oldState, horaAtendimento }))
                  }}
                  onBlur={(e) => {
                    const horaAtendimento = e.target.value.replace(':', '')

                    if (horaAtendimento >= '2359') {
                      setData((oldState) => ({
                        ...oldState,
                        horaAtendimento: '23:59',
                      }))
                    }
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <Checkbox
                  label="Houve Internação"
                  value={data?.isInternacao || false}
                  onChange={(e, value) => {
                    const isInternacao = value
                    setData((oldState) => ({
                      ...oldState,
                      isInternacao,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Duração do Tratamento"
                  inputProps={{
                    maxLength: 10,
                  }}
                  onlyNumber
                  required
                  name="duracaoTratamento"
                  validationErrors={validationErrors}
                  value={data?.duracaoTratamento || ''}
                  onChange={(e) => {
                    const duracaoTratamento = Number.parseInt(e?.target?.value) || ''
                    setData((oldState) => ({
                      ...oldState,
                      duracaoTratamento,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <MUIAutoComplete
                  label="Houve Afastamento Durante o Tratamento"
                  disabled={data?.isObito === true}
                  value={
                    data?.IsAfastamentoDuranteTratamento ? simNaoConsts.sim_1 : simNaoConsts.nao_2
                  }
                  options={simNaoValues}
                  renderOption={(opt) => opt.name}
                  optionId="value"
                  onChange={(_, obj) => {
                    const IsAfastamentoDuranteTratamento =
                      obj?.value === simNaoConsts.sim_1 ? true : false
                    setData((oldState) => ({
                      ...oldState,
                      IsAfastamentoDuranteTratamento,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
                <AutoCompleteLesao
                  value={data?.naturezaLesao || null}
                  required
                  name="naturezaLesao"
                  validationErrors={validationErrors}
                  onChange={(e, naturezaLesao) => {
                    const naturezaLesaoId = naturezaLesao?.id || null
                    setData((oldState) => ({
                      ...oldState,
                      naturezaLesao,
                      naturezaLesaoId,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Descrição da Lesão"
                  inputProps={{
                    maxLength: 200,
                  }}
                  multiline
                  required
                  name="descricaoComplementoLesao"
                  validationErrors={validationErrors}
                  value={data?.descricaoComplementoLesao || ''}
                  onChange={(e) => {
                    const descricaoComplementoLesao = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      descricaoComplementoLesao,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Diagnóstico Provável"
                  inputProps={{
                    maxLength: 100,
                  }}
                  multiline
                  value={data?.diagnosticoProvavel || ''}
                  onChange={(e) => {
                    const diagnosticoProvavel = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      diagnosticoProvavel,
                    }))
                  }}
                />
              </Grid>

              <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                <AutoCompleteCID
                  value={data?.cid || null}
                  required
                  name="cid"
                  validationErrors={validationErrors}
                  onChange={(e, cid) => {
                    const cidId = cid?.id || null
                    setData((oldState) => ({
                      ...oldState,
                      cid,
                      cidId,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={7} lg={7} md={7} sm={12} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Observação"
                  inputProps={{
                    maxLength: 250,
                  }}
                  multiline
                  value={data?.observacao || ''}
                  onChange={(e) => {
                    const observacao = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      observacao,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
                <AutoCompleteMedico
                  required
                  name="medicoId"
                  validationErrors={validationErrors}
                  value={data?.medico || null}
                  onChange={(e, medico) => {
                    const medicoId = medico?.id || ''
                    setData((oldState) => ({
                      ...oldState,
                      medicoId,
                      medico,
                    }))
                  }}
                />
              </Grid>
            </Grid>
          </CollapseContent>
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CollapseContent
            title="Reabertura ou Comunicação de Óbito"
            isOpen={collapseItens.reabertura}
            onClick={() => toggleCollapse('reabertura')}
          >
            <Grid container spacing={2}>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                <TextField
                  name="numeroCATOrigem"
                  required={data?.tipoCAT === reabertura_2}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Número CAT de Origem"
                  inputProps={{
                    maxLength: 10,
                  }}
                  onlyNumber
                  value={data?.numeroCATOrigem || ''}
                  onChange={(e) => {
                    const numeroCATOrigem = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      numeroCATOrigem,
                    }))
                  }}
                  validationErrors={validationErrors}
                />
              </Grid>
            </Grid>
          </CollapseContent>
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
