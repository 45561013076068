import { convertToArrayDescription } from '~/utils/utils'

export enum FeriasVencidasRelatorioEnum {
  AgruparEstabelecimento,
  AgruparSetor,
  NaoAgrupar,
}

export const FeriasVencidasRelatorioLabel = {
  [FeriasVencidasRelatorioEnum.AgruparEstabelecimento]: 'Estabelecimento',
  [FeriasVencidasRelatorioEnum.AgruparSetor]: 'Setor',
  [FeriasVencidasRelatorioEnum.NaoAgrupar]: 'Geral',
}

export const FeriasVencidasRelatorioValues = convertToArrayDescription(
  FeriasVencidasRelatorioEnum,
  FeriasVencidasRelatorioLabel,
)
