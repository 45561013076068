import React from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'
import { DataTable } from 'mio-library-ui'

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: 4,
    padding: theme.spacing(1),
    // margin: theme.spacing(1),
    // boxShadow: "0px 0px 3px black",
    width: '200px',
  },
  tabText: {
    textTransform: 'none',
  },

  boxMessage: {
    padding: theme.spacing(1),
    // margin: theme.spacing(1),
  },
}))

const TableLog = (props) => {
  const { data, isLoading } = props
  const query = ''

  const classes = useStyles()

  const columns = [
    {
      name: 'operacao',
      label: 'Operação',
      options: {
        customBodyRender: (value) => {
          return <Box className={classes.box}>{value}</Box>
        },
      },
    },
    {
      name: 'identificador',
      label: 'Identificador',
      options: {
        customBodyRender: (value) => {
          return <Box className={classes.box}>{value}</Box>
        },
      },
    },
    {
      name: 'mensagens',
      label: 'Menssagem',
      options: {
        customBodyRender: (value) => {
          const teste = value.map((item, index) => {
            return (
              <>
                <Typography variant="body2" key={index}>
                  {item}
                </Typography>
                {value?.length - 1 !== index && <hr />}
              </>
            )
          })

          return <Box className={classes.boxMessage}>{teste}</Box>
        },
      },
    },
  ]

  const options = {
    viewColumns: false,
    filter: false,
    search: false,
    searchText: query,
    selectableRows: 'none',
    print: false,
    download: false,
    elevation: 1,
    responsive: 'standard',
    tableBodyMaxHeight: '300px',
  }

  return (
    <DataTable
      data={data}
      columns={columns}
      options={options}
      isLoading={isLoading}
      pagination={false}
      sherlock={{
        query,
        columns: [],
      }}
    />
  )
}

export default TableLog
