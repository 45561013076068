import React, { useState } from 'react'

import { ContentContainer, PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import { useGrupoDelete } from '~/hooks/queries/useGrupo'
import useDialog from '~/hooks/useDialog'

const Grupo = () => {
  const [query, setQuery] = useState('')

  const { close: closeForm, open: openForm, data: dataForm, isOpen: isOpenForm } = useDialog()
  const {
    close: closeConfirmDelete,
    open: openConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
  } = useDialog()
  const { mutateAsync, isLoading } = useGrupoDelete()

  const handleDeleteItem = async () => {
    await mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
  }

  const handleClickItem = (event, value) => {
    const functions = {
      edit: openForm,
      delete: openConfirmDelete,
    }
    functions[event](value)
  }

  return (
    <ContentContainer>
      <PageHeader title="Grupo">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button size="small" color="primary" onClick={openForm} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>
      <Table query={query} onItemClick={handleClickItem} />

      <Form isOpen={isOpenForm} data={dataForm} onClose={closeForm} />

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isLoading}
        onCancel={closeConfirmDelete}
        onConfirm={handleDeleteItem}
      />
    </ContentContainer>
  )
}

export default Grupo
