import React from 'react'

import { Box, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons'

import { HEIGHT_ITEM_LIST } from '../..'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    height: HEIGHT_ITEM_LIST,
    borderTop: theme.shape.border,
  },
}))

export function SearchTextField(props) {
  const { onChange, value, disabled, show, width } = props

  const classes = useStyles()

  function handleChange(e) {
    const valueFormated = e?.target?.value || ''
    onChange(valueFormated)
  }

  if (!show) return <></>

  return (
    <Box className={classes.root} style={{ width }}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={value}
        placeholder="Procurar"
        onChange={handleChange}
        autoFocus
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton disabled={disabled} position="end" onClick={() => onChange('')}>
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </Box>
  )
}

SearchTextField.defaultProps = {
  onChange: () => undefined,
  value: '',
  disabled: false,
  show: false,
}
