import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { HeightDataTable } from '~/components'

import { Visibility as ViewIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import moment from 'moment'

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = useMemo(
    () => [
      {
        name: 'dtInicioAfastamento',
        label: 'Início',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtPrevistaRetorno',
        label: 'Previsão de retorno',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtRetorno',
        label: 'Retorno',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'dtInicioBeneficio',
        label: 'Início Benefício',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
        },
      },
      {
        name: 'observacao',
        label: 'Observação',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox justifyContent="center" spacing={0}>
                <IconButton
                  title="Visualizar este registro"
                  color="primary"
                  aria-label="Visualizar"
                  onClick={() => {
                    onItemClick(value)
                  }}
                  size="small"
                >
                  <ViewIcon fontSize="small" color="primary" size="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
