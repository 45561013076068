import React from 'react'

import { makeStyles } from '@material-ui/core'

import { ToolsDataTable } from '~/components'

import useUtils from '~/hooks/useUtils'
import { EventoIndCalculoValues } from '~/@types/enums/EventoIndCalculoEnum'
import { eventoIndReferenciaValues } from '~/values/eventoIndReferenciaValues'

const formatRVL = (indCalculo) => {
  const objectFinded = EventoIndCalculoValues.find((obj) => obj.value === indCalculo)
  return objectFinded.name
}

const formatMDHPQ = (indReferencia) => {
  const objectFinded = eventoIndReferenciaValues.find((obj) => obj.value === indReferencia)
  return objectFinded.name
}

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const TableRecibosItens = (props) => {
  const { data: _data, isLoading } = props

  const { formatCurrency } = useUtils()

  const data = _data.map((item) => {
    return {
      ...item,
      codigo: item?.eventoEmpregador?.evento?.codigo,
      nome: item?.eventoEmpregador?.evento?.nome,
      indCalculo: formatRVL(item?.eventoEmpregador?.evento?.indCalculo),
      indReferencia: formatMDHPQ(item?.eventoEmpregador?.evento?.indReferencia),
      quantidade: item?.quantidade,
      vrVencimento: formatCurrency(item?.vrVencimento),
      vrDesconto: formatCurrency(item?.vrDesconto),
    }
  })

  const classes = useStyles()

  const columns = [
    {
      name: 'codigo',
      label: 'Evento',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'indCalculo',
      label: 'RVL',
    },
    {
      name: 'indReferencia',
      label: 'MDHPQ',
    },
    {
      name: 'quantidade',
      label: 'Referência',
    },
    {
      name: 'vrVencimento',
      label: 'Vencimento',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrDesconto',
      label: 'Desconto',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      title=""
      data={data}
      pagination={false}
      columns={columns}
      isLoading={isLoading}
      disableAutoHeight
    />
  )
}

export default TableRecibosItens
