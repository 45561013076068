import React, { useMemo } from 'react'

import { makeStyles } from '@material-ui/core'
import { DataTable } from 'mio-library-ui'

import { ActionDialog } from '~/components'

import useUtils from '~/hooks/useUtils'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const TableBases = (props) => {
  const { data, isOpen, onClose } = props

  const { formatCurrency } = useUtils()
  const classes = useStyles()

  const dataFormated = {
    vrBaseINSS: formatCurrency(data?.vrBaseINSS),
    vrBaseINSS13: formatCurrency(data?.vrBaseINSS13),
    vrBaseFGTS: formatCurrency(data?.vrBaseFGTS),
    vrFGTS: formatCurrency(data?.vrFGTS),
    vrBaseIRRF: formatCurrency(data?.vrBaseIRRF),
    vrBaseIRRF13: formatCurrency(data?.vrBaseIRRF13),
  }

  const columns = useMemo(
    () => [
      {
        name: 'vrBaseINSS',
        label: 'Base INSS',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrBaseINSS13',
        label: 'Base INSS 13°',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrBaseFGTS',
        label: 'Base FGTS',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrFGTS',
        label: 'Valor FGTS',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrBaseIRRF',
        label: 'Base IRRF',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
      {
        name: 'vrBaseIRRF13',
        label: 'Base IRRF 13°',
        options: {
          setCellProps: () => {
            return {
              className: classes.renderRight,
            }
          },
        },
      },
    ],
    // eslint-disable-next-line
    [],
  )

  return (
    <ActionDialog
      title="Valores Bases"
      isOpen={isOpen}
      onClose={onClose}
      customActions={<div />}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <DataTable title="" data={[dataFormated]} columns={columns} pagination={false} />
    </ActionDialog>
  )
}

export default TableBases
