export enum IndPensaoAlimenticiaFGTSEnum {
  NãoExistePensãoAlimentícia_0 = 0,
  PercentualPensãoAlimentícia_1 = 1,
  ValorPensãoAlimentícia_2 = 2,
  PercentualEValorPensãoAlimentícia_3 = 3,
}

export const IndPensaoAlimenticiaFGTSValues = [
  {
    value: IndPensaoAlimenticiaFGTSEnum.NãoExistePensãoAlimentícia_0,
    name: 'Não existe pensão alimentícia',
  },
  {
    value: IndPensaoAlimenticiaFGTSEnum.PercentualPensãoAlimentícia_1,
    name: 'Percentual de pensão alimentícia',
  },
  {
    value: IndPensaoAlimenticiaFGTSEnum.ValorPensãoAlimentícia_2,
    name: 'Valor de pensão alimentícia',
  },
  {
    value: IndPensaoAlimenticiaFGTSEnum.PercentualEValorPensãoAlimentícia_3,
    name: 'Percentual e valor de pensão alimentícia',
  },
]
