import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import { AutoCompleteCidade, MUIAutoComplete } from '~/components/AutoComplete'
import { DatePicker } from '~/components'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import { processoAutoriaValues } from '~/values/processoAutoriaValues'
import { processoIndicativoMateriaValues } from '~/values/processoIndicativoMateriaValues'
import { processoExtensaoDecisaoValues } from '~/values/processoExtensaoDecisaoValues'

const schema = yup.object().shape({
  dtSituacao: yup
    .date()
    .required('Informe a Data da Situação')
    .typeError('Informe uma data válida')
    .nullable(),
  indAutoria: yup.string().required('Informe o Indicador de Autoria'),
  indMaterialidade: yup.string().required('Informe o Indicativo de Matéria do Processo'),
  cidadeId: yup.string().required('Informe a Cidade'),
  codigoVara: yup.string().required('Informe a Indentificação da Vara'),
  extensaoDeDecisao: yup.string().required('Informe a Extensão de Decisão'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/ProcessoSituacao/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/ProcessoSituacao', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Situação do Processo"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
          <DatePicker
            label="Data da Situação"
            size="small"
            value={data?.dtSituacao || null}
            required
            autoFocus
            validationErrors={validationErrors}
            name="dtSituacao"
            onChange={(date) => {
              const dtSituacao = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtSituacao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <AutoCompleteCidade
            value={data?.cidadeId || ''}
            required
            validationErrors={validationErrors}
            name="cidadeId"
            optionId="id"
            onChange={(e, cidade) => {
              const cidadeId = cidade?.id || ''
              setData({ ...data, cidadeId, cidade })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicador de Autoria"
            options={processoAutoriaValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.indAutoria}
            required
            validationErrors={validationErrors}
            name="indAutoria"
            onChange={(e, obj) => {
              const indAutoria = obj ? obj.value : ''
              setData({ ...data, indAutoria })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicativo de Matéria do Processo"
            options={processoIndicativoMateriaValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.indMaterialidade}
            required
            validationErrors={validationErrors}
            name="indMaterialidade"
            onChange={(e, obj) => {
              const indMaterialidade = obj ? obj.value : ''
              setData({ ...data, indMaterialidade })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            label="Indentificação da Vara"
            fullWidth
            value={data?.codigoVara || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 4,
            }}
            onlyNumber
            validationErrors={validationErrors}
            name="codigoVara"
            onChange={(e) => {
              const codigoVara = e.target.value
              setData({ ...data, codigoVara })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MUIAutoComplete
            label="Extensão de Decisão"
            options={processoExtensaoDecisaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.extensaoDeDecisao}
            required
            validationErrors={validationErrors}
            name="extensaoDeDecisao"
            onChange={(e, obj) => {
              const extensaoDeDecisao = obj ? obj.value : ''
              setData({ ...data, extensaoDeDecisao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data?.observacao || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 250,
            }}
            multiline
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
