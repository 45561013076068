import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoFerias } from './VinculoFerias'

export const queryGetByVinculo = '/VinculoFerias/GetByVinculo'

interface RequestParams {
  vinculoId: string
  anoMes?: string
  isApenasAbertas: boolean
}

export default function useGetByVinculo(params: RequestParams) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoFerias[] }>(queryGetByVinculo, {
      params,
    })
    return response.data.data
  }

  return useQuery([queryGetByVinculo, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
