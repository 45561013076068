import { useState, useEffect } from 'react'

interface UseValidationErrorsProps {
  data: FixLater
  schema: FixLater
  resetErrors?: FixLater
  handleSubmit: (params: FixLater) => void
  isValidateParams?: boolean
  schemaOptions?: { abortEarly: boolean; context?: FixLater }
}

export default function useValidationErrors(props: UseValidationErrorsProps) {
  const {
    data,
    schema,
    resetErrors,
    handleSubmit,
    isValidateParams = false,
    schemaOptions = { abortEarly: false },
  } = props

  const [validationErrors, setValidationErrors] = useState(false)

  useEffect(() => {
    setValidationErrors(false)
  }, [resetErrors])

  function focusInFirstElementError(arrayInnerError: FixLater) {
    const nameElement = arrayInnerError[0].path
    const element = document.getElementsByName(nameElement)[0]
    if (!element) return
    element.focus()
  }

  function onCatchError(err: FixLater) {
    if (err?.name !== 'ValidationError') return
    focusInFirstElementError(err.inner)
    setValidationErrors(err)
  }

  function handleValidate(params: FixLater) {
    setValidationErrors(false)
    schema
      .validate(isValidateParams ? params : data, schemaOptions)
      .then(() => handleSubmit(params))
      .catch(onCatchError)
  }

  return {
    handleValidate,
    validationErrors,
  }
}
