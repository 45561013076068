import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { FaltaImportadaDTO } from './dtos/FaltaImportadaDTO'

const query = '/ImportacaoPlanilhaFaltas/ObterFaltasImportadas'

interface RequestProps {
  anoMes: string
  estabelecimentoId: string
}

export default function useObterFaltasImportadas(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: FaltaImportadaDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useObterFaltasImportadasInvalidate() {
  const queryClient = useQueryClient()

  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }

  return {
    handleInvalidate,
  }
}
