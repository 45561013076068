import React, { useState, useEffect } from 'react'

import { CircularProgress, InputAdornment } from '@material-ui/core'

import MaskedTextField from '../MaskedTextField'
import ErrorMessage from '../ErrorMessage'

import usePessoaJuridica from '~/hooks/usePessoaJuridica'

import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

import { isCNPJ } from 'brazilian-values'
import PropTypes from 'prop-types'

const obterErro = (name, validationErrors) => {
  if (!validationErrors) return false

  const { inner } = validationErrors
  const erroEncontrado = inner.find((item) => {
    const { path } = item
    return name === path
  })

  if (!erroEncontrado) return false
  return erroEncontrado.message
}

const { CNPJ_1, CPF_2, CAEPF_3, CNO_4, CEI_6, NIT_7 } = tipoInscricaoConsts

const maskMaps = {
  [CNPJ_1]: '99.999.999/9999-99',
  [CPF_2]: '999.999.999-99',
  [CAEPF_3]: '999.999.999/999-99',
  [CNO_4]: '99.999.99999/99',
  [CEI_6]: '99.999.99999/99',
  [NIT_7]: '999.99999.99-9',
}

const maskCNPJ = maskMaps[CNPJ_1]
const maskCPF = maskMaps[CPF_2]

const NumeroInscricaoTextField = (props) => {
  const { name, validationErrors, typeMask, onChange, getDataCNPJ, value: _value, ...rest } = props

  const [mask, setMask] = useState(maskCPF)

  const { getFieldsPessoaJuridica, isLoading } = usePessoaJuridica()

  const mensagemValidationDeErro = obterErro(name, validationErrors)

  useEffect(() => {
    function changeMask() {
      if (typeMask) {
        const maskCurrent = maskMaps[typeMask]
        setMask(maskCurrent)
        return
      }
      if (_value?.length > 11) setMask(maskCNPJ)
    }
    changeMask()
    // eslint-disable-next-line
  }, [typeMask])

  useEffect(() => {
    async function handleGetDataCNPJ() {
      if (!getDataCNPJ) return
      if (mask !== maskCNPJ) return
      if (!isCNPJ(_value)) return
      const response = await getFieldsPessoaJuridica(_value)
      if (response) getDataCNPJ(response)
    }
    handleGetDataCNPJ()
    // eslint-disable-next-line
  }, [mask, _value, maskCNPJ])

  const changeMaskOnBeforeValueChange = (newState, oldState, userInput) => {
    if (typeMask) {
      return {
        value: newState.value,
        selection: newState.selection,
      }
    }

    if (userInput) {
      //handle reach max field
      if (newState.value === oldState.value && mask !== maskCNPJ) {
        newState.value = newState.value + userInput
        newState.selection = {
          start: newState.selection.start + 1,
          end: newState.selection.end + 1,
        }
        setMask(maskCNPJ)
      }
    }
    //handle delete
    if (newState.value.length < oldState.value.length && mask !== maskCPF) {
      let newStart = newState.selection.start
      if (newStart <= 14) {
        setMask(maskCPF)
      }
    }

    return {
      value: newState.value,
      selection: newState.selection,
    }
  }

  function handleChange(event) {
    if (!onChange) return
    const valueFormated = event?.target?.value?.replace(/\D/g, '') || ''
    onChange(event, valueFormated)
  }

  return (
    <React.Fragment>
      <MaskedTextField
        mask={mask}
        name={name}
        size="small"
        variant="outlined"
        fullWidth
        beforeMaskedValueChange={changeMaskOnBeforeValueChange}
        onChange={handleChange}
        value={_value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isLoading ? <CircularProgress size={16} /> : <></>}
            </InputAdornment>
          ),
        }}
        {...rest}
      />
      {mensagemValidationDeErro && <ErrorMessage error={mensagemValidationDeErro} />}
    </React.Fragment>
  )
}

export default NumeroInscricaoTextField

NumeroInscricaoTextField.defaultProps = {
  name: '',
  validationErrors: false,
}

NumeroInscricaoTextField.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  validationErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  typeMask: PropTypes.oneOf([CNPJ_1, CPF_2, CAEPF_3, CNO_4, CEI_6, NIT_7]),
  onChange: PropTypes.func,
  getDataCNPJ: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  InputLabelProps: PropTypes.any,
}
