import { useEffect, useState } from 'react'

import { ActionDrawer } from '~/components'

import { Grid } from '@material-ui/core'
import {
  IndAtividadeEstabelecimentoEnum,
  IndAtividadeEstabelecimentoValues,
} from '~/@types/enums/IndAtividadeEstabelecimentoEnum'
import { MUIAutoComplete, AutoCompleteGrupo } from '~/components/AutoComplete'

interface DataFilter {
  indAtividadeEstabelecimento: IndAtividadeEstabelecimentoEnum
  grupoId?: string
}

interface DrawerFilterProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: DataFilter) => void
  data: DataFilter
}

export default function DrawerFilter(props: DrawerFilterProps) {
  const { isOpen, onClose, onSubmit, data: _data } = props

  const [data, setData] = useState(_data)

  useEffect(() => {
    setData(_data)
  }, [_data, isOpen])

  function handleSubmit() {
    onSubmit(data)
    onClose()
  }

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={onClose}
      title="Estabelecimento - Filtros"
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIAutoComplete
            label="Atividade do Estabelecimento"
            options={IndAtividadeEstabelecimentoValues}
            renderOption={(d) => d.name}
            optionId="value"
            value={data.indAtividadeEstabelecimento}
            onChange={(e, d) => {
              const indAtividadeEstabelecimento = d.value
              setData((prev) => ({
                ...prev,
                indAtividadeEstabelecimento,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AutoCompleteGrupo
            value={data.grupoId}
            onChange={(e, grupo) => {
              const grupoId = grupo?.id || undefined
              setData((prev) => ({ ...prev, grupoId }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDrawer>
  )
}
