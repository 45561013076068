export enum CodigoIncidenciaFGEnum {
  /**00 - Não é base de cálculo do FGTS */
  NaoInformado = 0,

  /**11 - Base de cálculo do FGTS mensal */
  BaseCalculo_Mensal_11 = 11,

  /**12 - Base de cálculo do FGTS 13° salário */
  BaseCalculo_DecimoTerceiro_12 = 12,

  /**21 - Base de cálculo do FGTS aviso prévio indenizado */
  BaseCalculo_AvisoPrevio_21 = 21,

  /**91 - Incidência suspensa em decorrência de decisão judicial- FGTS mensal */
  MensalIncidenciaSuspensao_91 = 91,

  /**92 - Incidência suspensa em decorrência de decisão judicial- FGTS 13º salário */
  DtSalarioIncidenciaSuspensao_92 = 92,

  /**93 - Incidência suspensa em decorrência de decisão judicial- FGTS aviso prévio indenizado */
  AvisoPrevioIncidenciaSuspensao_93 = 93,
}

export const CodigoIncidenciaFGValues = [
  { value: CodigoIncidenciaFGEnum.NaoInformado, name: '0 - Não é base de cálculo do FGTS' },
  {
    value: CodigoIncidenciaFGEnum.BaseCalculo_Mensal_11,
    name: '11 - Base de cálculo do FGTS mensal',
  },
  {
    value: CodigoIncidenciaFGEnum.BaseCalculo_DecimoTerceiro_12,
    name: '12 - Base de cálculo do FGTS 13° salário',
  },
  {
    value: CodigoIncidenciaFGEnum.BaseCalculo_AvisoPrevio_21,
    name: '21 - Base de cálculo do FGTS aviso prévio indenizado',
  },
  {
    value: CodigoIncidenciaFGEnum.MensalIncidenciaSuspensao_91,
    name: '91 - Incidência suspensa em decorrência de decisão judicial- FGTS mensal',
  },
  {
    value: CodigoIncidenciaFGEnum.DtSalarioIncidenciaSuspensao_92,
    name: '92 - Incidência suspensa em decorrência de decisão judicial- FGTS 13º salário',
  },
  {
    value: CodigoIncidenciaFGEnum.AvisoPrevioIncidenciaSuspensao_93,
    name: '93 - Incidência suspensa em decorrência de decisão judicial- FGTS aviso prévio indenizado',
  },
]
