import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { LotacaoConfigConsultaDTO } from './dtos/LotacaoConfigConsultaDTO'

const query = '/ExportacaoLancamentoConfig/ResumoConfiguracoesLotacoes'

interface RequestProps {
  params: {
    estabelecimentoId: string
    modeloIntegracaoId: string
  }
}

export function useResumoConfiguracoesLotacoes({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: LotacaoConfigConsultaDTO[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
