import React, { useState, createContext, useContext } from 'react'

import PropTypes from 'prop-types'

import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  LinearProgress,
} from '@material-ui/core'

const StepContext = createContext({
  handleNext: () => undefined,
  handleBack: () => undefined,
  activeStep: 0,
  steps: [],
  isStepOptional: false,
  handleSkip: () => undefined,
})

export function useStepContext() {
  return useContext(StepContext)
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
}))

export function StepButtons(props) {
  const { handleBack, handleNext, handleSkip } = props

  const { activeStep, steps, isStepOptional } = useStepContext()

  const classes = useStyles()

  return (
    <div className={classes.buttonBox}>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
      >
        Voltar
      </Button>
      {isStepOptional(activeStep) && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSkip}
          className={classes.button}
        >
          Pular
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleNext}
        className={classes.button}
      >
        {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
      </Button>
    </div>
  )
}

export default function StepContent(props) {
  const { steps, stepsOptionals, isLoading } = props

  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = React.useState(new Set())

  const classes = useStyles()

  const isStepOptional = (step) => {
    return stepsOptionals.includes(step)
  }

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  function getStepContent(step) {
    return steps[step].content
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    if (activeStep === steps.length - 1) return
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }
    if (activeStep === steps.length - 1) return
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <StepContext.Provider
      value={{ handleNext, handleBack, activeStep, steps, isStepOptional, handleSkip }}
    >
      <div className={classes.root}>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const stepProps = {}
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={step.title + index} {...stepProps} disabled={isLoading}>
                <StepLabel optional={<Typography variant="caption">{step.subtitle}</Typography>}>
                  {step.title}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {isLoading ? <LinearProgress /> : <div style={{ height: '4px' }}></div>}
        <div>
          <div>{getStepContent(activeStep)}</div>
        </div>
      </div>
    </StepContext.Provider>
  )
}

StepContent.defaultProps = {
  stepsOptionals: [],
  isLoading: false,
}

StepContent.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
  stepsOptionals: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
}

StepButtons.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleSkip: PropTypes.func,
}
