import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

export function useAtestadoSaudeOcupacionalDelete() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(id: string) {
    await api.delete(`/AtestadoSaudeOcupacional/${id}`)
    notification.remove()
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries('AtestadoSaudeOcupacionalGetByVinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
