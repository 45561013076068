const normal_1 = 1
const SST_2 = 2

export const tipoLicencaValues = [
  { value: normal_1, name: 'Normal' },
  { value: SST_2, name: 'SST' },
]

export const tipoLicencaConsts = {
  normal_1,
  SST_2,
}
