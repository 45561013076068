import { PopoverComponent, PageHeader } from '~/components'

import { Box, Typography, makeStyles } from '@material-ui/core'

import { formatCurrency } from '~/hooks/useUtils'

import { DataTableMemoriaProp } from '..'

const useStyles = makeStyles((theme: FixLater) => ({
  root: {
    maxHeight: 250,
    width: 270,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  headerDialog: {
    display: 'flex',
    justifyContent: 'center',
  },
  hightLightTitle: {
    '&:last-child': {
      marginLeft: theme.spacing(1),
    },
    fontWeight: 'bolder',
  },
  contentDialog: {
    marginTop: theme.spacing(1),
    overflow: 'auto',
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
}))

interface PopoverMemoriaProps {
  anchorEl: HTMLDivElement | null
  data: DataTableMemoriaProp | null
  onClose: () => void
}

export default function PopoverMemoria(props: PopoverMemoriaProps) {
  const { anchorEl, onClose, data } = props

  const classes = useStyles()

  return (
    <PopoverComponent anchorEl={anchorEl} onClose={onClose}>
      <Box className={classes.root}>
        <PageHeader title="Memória de Cálculo" />
        <Box className={classes.headerDialog}>
          <Typography className={classes.hightLightTitle}>
            {data?.label || 'Sem identificação'}
          </Typography>
          <Typography className={classes.hightLightTitle} color="secondary">
            {data?.value ? formatCurrency(data.value) : ''}
          </Typography>
        </Box>
        <Box className={classes.contentDialog}>
          {data?.mensagens.map((message, index) => (
            <Box key={index}>{message}</Box>
          ))}
        </Box>
      </Box>
    </PopoverComponent>
  )
}
