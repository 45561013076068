import React, { useState } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import api from '~/services/api-pessoal'
import moment from 'moment'

import { Button } from 'mio-library-ui'
import { Box, Container, Paper, Typography, useTheme } from '@material-ui/core'

import { PageHeader } from '~/components'
import { AutoCompleteVinculoCancelarTransferencia } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

const initialStateData = {
  vinculo: null,
  vinculoId: null,
}

export default function CancelarTransferenciaSST() {
  const [data, setData] = useState(initialStateData)
  const [isSubmiting, setSubmiting] = useState(false)
  const [isResetDataAutoComplete, setResetDataAutoComplete] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const theme = useTheme()

  async function cancelarTransferencia() {
    setSubmiting(true)
    try {
      await api.delete('/Vinculo/CancelarTransferenciaSST', {
        params: {
          vinculoId: data.vinculoId,
        },
      })
      notification.success('A Transferência foi cancelada com sucesso')
      setData(initialStateData)
      setResetDataAutoComplete(true)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmiting(false)
    }
  }

  return (
    <Box padding={2}>
      <PageHeader title="Cancelar Transferência" />

      <Container maxWidth="sm">
        <Paper
          style={{
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          }}
        >
          <AutoCompleteVinculoCancelarTransferencia
            value={data?.vinculo || null}
            onChange={(_, obj) =>
              setData((prev) => ({ ...prev, vinculo: obj, vinculoId: obj?.id || null }))
            }
            resetField={isResetDataAutoComplete}
          />

          {data?.vinculo && (
            <Box
              display="flex"
              flexDirection="column"
              gridGap={2}
              padding={1}
              border={theme.shape.border}
              borderRadius={theme.shape.borderRadius}
            >
              <Box display="flex" gridGap={2}>
                <Typography style={{ fontSize: 'small' }}>Estabelecimento de Origem:</Typography>
                <Typography style={{ fontWeight: 'bolder', color: 'GrayText', fontSize: 'small' }}>
                  {data.vinculo.estabelecimentoOrigemNome} -{' '}
                  {formatToCPFOrCNPJ(data.vinculo.estabelecimentoOrigemNrInscricao)}
                </Typography>
              </Box>

              <Box display="flex" gridGap={2}>
                <Typography style={{ fontSize: 'small' }}>Data da Transferência:</Typography>
                <Typography style={{ fontWeight: 'bolder', color: 'GrayText', fontSize: 'small' }}>
                  {moment(data.vinculo.dtTransferencia).format('DD/MM/yyyy')}
                </Typography>
              </Box>
            </Box>
          )}

          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={data.vinculoId === null}
              isLoading={isSubmiting}
              onClick={cancelarTransferencia}
              variant="contained"
              color="primary"
              size="small"
            >
              Cancelar Transferência
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  )
}
