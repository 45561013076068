import React from 'react'

import { Box, Dialog, IconButton, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { Close as CloseIcon } from '@material-ui/icons'

import PageHeader from '../PageHeader'
import StackContainer from '../StackContainer'
import Stack from '../Stack'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface FullDialogProps {
  isOpen: boolean
  title: string
  subtitle: string
  onClose: () => void
  children: React.ReactNode
  renderRight?: React.ReactNode
}

export default function FullDialog({
  isOpen,
  title,
  subtitle,
  onClose,
  children,
  renderRight,
}: FullDialogProps) {
  return (
    <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
      <StackContainer>
        <PageHeader
          title={title}
          subtitle={subtitle}
          renderRight={
            <Stack orientation="horizontal">
              {renderRight}
              <IconButton onClick={onClose} size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          }
        />
        <Box flex={1} minHeight={300}>
          {children}
        </Box>
      </StackContainer>
    </Dialog>
  )
}
