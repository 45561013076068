import React from 'react'

import moment from 'moment'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon, Print as PrintIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import { useAtestadoSaudeOcupacionalGetByVinculo } from '~/hooks/queries/AtestadoSaudeOcupacional/useAtestadoSaudeOcupacionalGetByVinculo'

import { ASOTipoExameEnum, ASOTipoExameValues } from '~/@types/enums/ASOTipoExameEnum'
import { ASOResultadoEnum, ASOResultadoValues } from '~/@types/enums/ASOResultadoEnum'

import { AtestadoSaudeOcupacionalPartial } from '~/hooks/queries/AtestadoSaudeOcupacional/AtestadoSaudeOcupacional'
import { MUIDataTableColumnDef } from 'mui-datatables'

const formatTipoASO = (tipoASO: ASOTipoExameEnum) => {
  const objectFinded = ASOTipoExameValues.find((obj) => obj.value === tipoASO)
  return objectFinded?.name || ''
}

const formatResultadoASO = (resultadoASO: ASOResultadoEnum) => {
  const objectFinded = ASOResultadoValues.find((obj) => obj.value === resultadoASO)
  return objectFinded?.name || ''
}

interface TableProps {
  onItemClick: (event: 'edit' | 'delete' | 'print', data: AtestadoSaudeOcupacionalPartial) => void
  query: string
  vinculoId: string
}

export default function Table(props: TableProps) {
  const { onItemClick, query, vinculoId } = props

  const { data: _data, isLoading, isFetching } = useAtestadoSaudeOcupacionalGetByVinculo(vinculoId)

  const data = _data
    ? _data.map((item) => {
        return {
          ...item,
          tipoASO: formatTipoASO(item.tipoASO),
          dtASO: item?.dtASO ? moment(item.dtASO).format('DD/MM/yyyy') : null,
          resultadoASO: formatResultadoASO(item.resultadoASO),
        }
      })
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'tipoASO',
      label: 'Tipo ASO',
    },
    {
      name: 'dtASO',
      label: 'Data ASO',
    },
    {
      name: 'resultadoASO',
      label: 'Resultado ASO',
    },
    {
      name: 'reciboEsocial',
      label: 'Recibo eSocial',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Imprimir este registro"
                color="primary"
                aria-label="Imprimir"
                onClick={() => {
                  const dt = _data?.find((d) => d.id === value)
                  if (!dt) return
                  onItemClick('print', dt)
                }}
              >
                <PrintIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dt = _data?.find((d) => d.id === value)
                  if (!dt) return
                  onItemClick('edit', dt)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                size="small"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  const dt = _data?.find((d) => d.id === value)
                  if (!dt) return
                  onItemClick('delete', dt)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <HeightDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['tipoASO', 'dtASO', 'resultadoASO'],
      }}
    />
  )
}
