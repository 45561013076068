import { useMutation } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { Terceiros } from './Terceiros'

export default function useObterPorIdTerceiros() {
  const dialogNotification = useDialogNotification()

  async function handleRequest(id: string) {
    const response = await api.get<{ data: Terceiros }>('/Terceiros/' + id)
    return response.data.data
  }
  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
