import React, { useState } from 'react'

import moment from 'moment'
import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import { TextField, ActionDialog, DatePickerNew } from '~/components'
import { AutoCompleteProcedimentoMedico, MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import { ASOIndResultadoValues } from '~/@types/enums/ASOIndResultadoEnum'

import { ASOOrdemExameEnum, ASOOrdemExameValues } from '~/@types/enums/ASOOrdemExameEnum'
import { AtestadoSaudeOcupacionalItemPartial } from '~/hooks/queries/AtestadoSaudeOcupacionalItem/AtestadoSaudeOcupacionalItem'

const isProcedimentoMedico = [
  '0583',
  '0998',
  '0999',
  '1128',
  '1230',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '9999',
]

const schema = yup.object().shape({
  dtExame: yup.date().when('$dtASO', (dtASO, schema) => {
    return schema
      .max(
        dtASO,
        `A Data do Exame deve ser igual ou anterior à Data do ASO: ${moment(dtASO).format(
          'DD/MM/yyyy',
        )}`,
      )
      .required('Informe a Data do Exame')
      .typeError('Informe uma Data Valída')
  }),
  procedimentoMedicoId: yup.string().required('Informe o Procedimento Médico').nullable(),
  indOrdem: yup
    .number()
    .when('$procedimentoMedicoId', (procedimentoMedicoId, schema) =>
      schema.test('indOrdem-test', 'Informe a Ordem', (value: ASOOrdemExameEnum) =>
        procedimentoMedicoId === '0281' ? value !== ASOOrdemExameEnum.NaoInformar_0 : true,
      ),
    ),
  indResultado: yup.string().required('Informe o Resultado'),
  observacao: yup
    .string()
    .when('procedimentoMedicoId', (procedimentoMedicoId, schema) =>
      isProcedimentoMedico.includes(procedimentoMedicoId)
        ? schema.required('Informe a Observação')
        : schema,
    )
    .nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: AtestadoSaudeOcupacionalItemPartial
  indexCurrent: number
  onAfterSubmitForm: (
    event: 'insert' | 'update',
    data: AtestadoSaudeOcupacionalItemPartial,
    indexCurrent: number,
  ) => void
  dtASO: string
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, dtASO, indexCurrent, onAfterSubmitForm } = props
  const [data, setData] = useState(_data)
  const notification = useNotification()

  const handleSubmit = () => {
    const update = async () => {
      onAfterSubmitForm('update', data, indexCurrent)
      notification.put()
    }

    const insert = async () => {
      onAfterSubmitForm('insert', data, indexCurrent)
      notification.post()
    }

    if (indexCurrent >= 0) {
      update()
      return
    }
    insert()
  }
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    schemaOptions: {
      abortEarly: false,
      context: {
        dtASO,
        procedimentoMedicoId: data?.procedimentoMedicoId,
      },
    },
    data,
  })
  return (
    <ActionDialog
      title="Cadastro de Exame(ASO)"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
          <DatePickerNew
            label="Data do Exame"
            size="small"
            name="dtExame"
            required
            validationErrors={validationErrors}
            value={data?.dtExame || null}
            onChange={(date) => {
              const dtExame = date || undefined
              let dtVencimentoExame = ''
              if (data?.validadeExame) {
                dtVencimentoExame = moment(dtExame)
                  .add(data.validadeExame - 1, 'd')
                  .format('yyyy-MM-DD')
              }
              setData((oldState) => ({
                ...oldState,
                dtExame,
                dtVencimentoExame,
              }))
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <AutoCompleteProcedimentoMedico
            name="procedimentoMedicoId"
            required
            validationErrors={validationErrors}
            value={data?.procedimentoMedicoId || ''}
            onChange={(e, procedimentoMedico) => {
              const procedimentoMedicoId = procedimentoMedico?.id || ''
              setData((oldState) => ({
                ...oldState,
                procedimentoMedicoId,
              }))
            }}
            optionId="id"
          />
        </Grid>

        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <MUIAutoComplete
            label="Resultado"
            name="indResultado"
            required
            validationErrors={validationErrors}
            value={data.indResultado}
            options={ASOIndResultadoValues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(e, obj) => {
              const indResultado = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                indResultado,
              }))
            }}
          />
        </Grid>

        <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <MUIAutoComplete
            label="Ordem"
            name="indOrdem"
            required={data?.procedimentoMedicoId === '0281'}
            validationErrors={validationErrors}
            value={data.indOrdem}
            options={ASOOrdemExameValues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(e, obj) => {
              const indOrdem = obj ? obj.value : ASOOrdemExameEnum.NaoInformar_0
              setData((oldState) => ({
                ...oldState,
                indOrdem,
              }))
            }}
          />
        </Grid>

        <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
          <TextField
            fullWidth
            onlyNumber
            size="small"
            label="Validade em Dias"
            inputProps={{
              maxLength: 9,
            }}
            value={data?.validadeExame || ''}
            onChange={(e) => {
              const validadeExame = parseInt(e?.target?.value || '0')
              let dtVencimentoExame = ''
              if (data?.dtExame) {
                dtVencimentoExame = moment(data.dtExame)
                  .add(validadeExame - 1, 'd')
                  .format('yyyy-MM-DD')
              }
              setData((oldState) => ({
                ...oldState,
                validadeExame,
                dtVencimentoExame,
              }))
            }}
          />
        </Grid>

        <Grid xl={8} lg={8} md={8} sm={8} xs={12} item>
          <DatePickerNew
            label="Data de Validade"
            size="small"
            value={data?.dtVencimentoExame || null}
            onChange={(date) => {
              const dtVencimentoExame = date
              setData((oldState) => ({
                ...oldState,
                dtVencimentoExame,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            name="observacao"
            label="Observação"
            size="small"
            fullWidth
            required={isProcedimentoMedico.includes(data?.procedimentoMedicoId || '')}
            value={data?.observacao || ''}
            inputProps={{
              maxLength: 250,
            }}
            multiline
            onChange={(e) => {
              const observacao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
