import { useState } from 'react'

import { Box } from '@material-ui/core'

import {
  PageHeader,
  PDFViewer,
  StackContainer,
  Finder,
  ContainerTable,
  PrintButton,
} from '~/components'

import Table from './components/Table'
import StepForm from './components/StepForm'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import { useVinculoObterVinculosPorEstabelecimento } from '~/hooks/queries/Vinculo/useVinculoObterVinculosPorEstabelecimento'

import { RelCompensacaoHorasTrabalhadasRequestDTO } from '~/hooks/queries/CompensacaoHorasTrabalhadas/dtos/RelCompensacaoHorasTrabalhadasRequestDTO'

const TITLE_PAGE = 'Acordo Compensação de Horas Trabalhadas'

export default function CompensacaoHorasTrabalhadas() {
  const [query, setQuery] = useState('')
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const { estabelecimento, anoMes } = useAmbiente()

  const { data, isLoading, isFetching } = useVinculoObterVinculosPorEstabelecimento(
    estabelecimento.id,
  )

  const {
    close: closeStepForm,
    isOpen: isOpenStepForm,
    open: openStepForm,
    data: dataStepForm,
  } = useDialog('')
  const {
    close: closePDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
    data: dataPDFViewer,
  } = useDialog<RelCompensacaoHorasTrabalhadasRequestDTO[]>([])

  function handleOpenFormStep() {
    const index = rowsSelected[0]
    const vinculoRow = data[index]
    openStepForm(vinculoRow.id)
  }

  function handleAfterSubmit(d: RelCompensacaoHorasTrabalhadasRequestDTO) {
    const dataToPrint = rowsSelected.map<RelCompensacaoHorasTrabalhadasRequestDTO>((index) => ({
      vinculoId: data[index].id,
      jornada: d.jornada,
    }))
    openPDFViewer(dataToPrint)
    closeStepForm()
  }

  return (
    <StackContainer>
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <ContainerTable>
        <Table
          query={query}
          isLoading={isLoading}
          isFetching={isFetching}
          data={data}
          onRowSelected={setRowsSelected}
          rowsSelected={rowsSelected}
        />
      </ContainerTable>

      <Box display="flex" justifyContent="flex-end">
        <PrintButton
          onClick={handleOpenFormStep}
          disabled={rowsSelected.length > 0 ? false : true}
        />
      </Box>

      {isOpenStepForm && (
        <StepForm
          isOpen={isOpenStepForm}
          onClose={closeStepForm}
          vinculoId={dataStepForm}
          afterSubmit={handleAfterSubmit}
        />
      )}

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="CompensacaoHorasTrabalhadas"
          title={TITLE_PAGE}
          isSalvarDocumento
          axiosConfig={{
            method: 'post',
            url: '/Relatorio/CompensacaoHorasTrabalhadas/ObterRelatorio',
            data: dataPDFViewer,
            params: {
              estabelecimentoId: estabelecimento.id,
              anoMes,
            },
          }}
        />
      )}
    </StackContainer>
  )
}
