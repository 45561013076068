import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Grid,
  List,
  Paper,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
  Divider,
  Box,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'

import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    width: (props) => props.widthList,
    height: (props) => props.heightList,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  inputSearch: {
    padding: theme.spacing(1),
  },
  errorText: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  listItemText: {
    '& .MuiTypography-displayBlock': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}))

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

const handleSearch = (list, fieldsForSearch, query) => {
  if (list?.length === 0 || query === '') return []

  const matchList = []

  if (fieldsForSearch?.length === 0) {
    list.forEach((item) => {
      if (item.toString().toLowerCase().includes(query.toLowerCase())) matchList.push(item)
    })
  } else {
    fieldsForSearch.forEach((field) => {
      list.forEach((item) => {
        if (item[field].toString().toLowerCase().includes(query.toLowerCase())) matchList.push(item)
      })
    })
  }

  return matchList
}

export default function TransferList(props) {
  const { listLeft, listRight, renderOption, onChange, heightList, widthList, searchBy } = props

  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const [queryLeft, setQueryLeft] = useState('')
  const [queryRight, setQueryRight] = useState('')

  const [errorLeft, setErrorLeft] = useState(false)
  const [searchResultLeft, setSearchResultLeft] = useState([])

  const [errorRight, setErrorRight] = useState(false)
  const [searchResultRight, setSearchResultRight] = useState([])

  const classes = useStyles({ heightList, widthList })

  useEffect(() => {
    setLeft(listLeft)
    setRight(listRight)
  }, [listLeft, listRight])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (items) => intersection(checked, items).length

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const resetFields = () => {
    setErrorLeft(false)
    setErrorRight(false)
    setSearchResultLeft([])
    setSearchResultRight([])
    setQueryLeft('')
    setQueryRight('')
  }

  const handleCheckedLeft = () => {
    const newLeftList = left.concat(rightChecked)
    const newRightList = not(right, rightChecked)

    const newSearchResultLeft = searchResultLeft.concat(rightChecked)
    const newSearchResultRight = not(searchResultRight, rightChecked)

    setLeft(newLeftList)
    setRight(newRightList)

    setSearchResultLeft(newSearchResultLeft)
    setSearchResultRight(newSearchResultRight)

    setChecked(not(checked, rightChecked))
    onChange(newLeftList, newRightList)

    resetFields()
  }

  const handleCheckedRight = () => {
    const newLeftList = not(left, leftChecked)
    const newRightList = right.concat(leftChecked)

    const newSearchResultLeft = not(searchResultLeft, leftChecked)
    const newSearchResultRight = searchResultRight.concat(leftChecked)

    setLeft(newLeftList)
    setRight(newRightList)

    setSearchResultLeft(newSearchResultLeft)
    setSearchResultRight(newSearchResultRight)

    setChecked(not(checked, leftChecked))
    onChange(newLeftList, newRightList)

    resetFields()
  }

  const handleQuery = (eventList, q) => {
    setChecked([])

    if (eventList === 'listLeft') {
      setErrorLeft(false)
      const newListLeft = handleSearch(left, searchBy, q)
      if (newListLeft?.length === 0) {
        setErrorLeft(true)
        setSearchResultLeft([])
        return
      }
      setSearchResultLeft(newListLeft)
    }

    if (eventList === 'listRight') {
      setErrorRight(false)
      const newListRight = handleSearch(right, searchBy, q)
      if (newListRight?.length === 0) {
        setErrorRight(true)
        setSearchResultRight([])
        return
      }
      setSearchResultRight(newListRight)
    }
  }

  const customList = (eventList, items) => {
    const isShowNotFoundElementsLeft =
      errorLeft && eventList === 'listLeft' && queryLeft?.length !== 0
    const isShowNotFoundElementsRight =
      errorRight && eventList === 'listRight' && queryRight?.length !== 0

    return (
      <Paper>
        <ListItem
          role="listitem"
          button
          onClick={handleToggleAll(items)}
          disabled={items.length === 0 || isShowNotFoundElementsLeft || isShowNotFoundElementsRight}
        >
          <Checkbox
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-label': 'all items selected' }}
            color="primary"
          />
          <ListItemText
            primary={
              <Box display="flex" justifyContent="space-between" gridGap="8px">
                <Box>Selecionar todos</Box>
                <Box>
                  {numberOfChecked(items)}/{items.length}
                </Box>
              </Box>
            }
          />
        </ListItem>
        <Divider />
        <Box className={classes.list}>
          {isShowNotFoundElementsLeft || isShowNotFoundElementsRight ? (
            <div className={classes.errorText}>Nenhum registro encontrado</div>
          ) : (
            <List dense component="div" role="list">
              {items.map((value, index) => {
                const labelId = `transfer-list-all-item-${value}-label`

                return (
                  <ListItem key={index} role="listitem" button onClick={handleToggle(value)}>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      color="primary"
                    />
                    <ListItemText
                      id={labelId}
                      primary={renderOption(value)}
                      title={renderOption(value)}
                      className={classes.listItemText}
                    />
                  </ListItem>
                )
              })}
              <ListItem />
            </List>
          )}
        </Box>
        {searchBy.length > 0 && (
          <TextField
            className={classes.inputSearch}
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Procurar"
            value={eventList === 'listLeft' ? queryLeft : queryRight}
            onChange={(e) => {
              const text = e?.target?.value || ''
              handleQuery(eventList, text)
              if (eventList === 'listLeft') setQueryLeft(text)
              if (eventList === 'listRight') setQueryRight(text)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Paper>
    )
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item>
        {customList('listLeft', searchResultLeft?.length > 0 ? searchResultLeft : left)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {customList('listRight', searchResultRight?.length > 0 ? searchResultRight : right)}
      </Grid>
    </Grid>
  )
}

TransferList.defaultProps = {
  searchBy: [],
  heightList: 300,
  widthList: 300,
}

TransferList.propTypes = {
  listLeft: PropTypes.arrayOf(PropTypes.object).isRequired,
  listRight: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  renderOption: PropTypes.func.isRequired,
  heightList: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  widthList: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchBy: PropTypes.arrayOf(PropTypes.string),
}
