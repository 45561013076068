import { useState, useRef } from 'react'

import api from '~/services/api-pessoal'

import { ActionDialog } from '~/components'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import SalarioContratualFormSimple from '~/pages/Funcionario/Forms/SalarioContratualFormSimple'
import { VinculoSalarioBase } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: FixLater
  onAfterSubmitForm: (event: 'update' | 'insert', data: FixLater) => void
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleSubmit = (data: VinculoSalarioBase) => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/vinculoSalarioBase/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/vinculoSalarioBase', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    if (data.id) {
      update()
      return
    }
    insert()
  }

  function handleClickSave() {
    buttonRef.current?.click()
  }

  return (
    <ActionDialog
      title="Cadastro de Salário Contratual"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleClickSave}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <SalarioContratualFormSimple data={_data} onSubmit={handleSubmit} buttonRef={buttonRef} />
    </ActionDialog>
  )
}
