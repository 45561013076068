import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterTodosInvalidate } from './useObterTodos'

import { notifyPut } from '~/utils/notification'

import { ProcessoTrabalhistaTributosPeriodo } from './dtos/ProcessoTrabalhistaTributosPeriodo'

interface RequestProps {
  data: ProcessoTrabalhistaTributosPeriodo
  id: string
}

export function useAtualizar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosInvalidate()

  async function handleRequest({ data, id }: RequestProps) {
    await api.put('/ProcessoTrabalhistaTributosPeriodo/' + id, data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
