import { Table, TableHead, TableRow, TableCell, Box, Paper } from '@material-ui/core'
import { ColumnTableType } from '..'
import { VinculoValeTransporteDTO } from '~/hooks/queries/VinculoValeTransporte/VinculoValeTransporteDTO'

export function CellContent({
  children,
  width,
  minWidth,
  height,
}: {
  children: React.ReactNode
  width?: number
  minWidth?: number
  height?: number
}) {
  return (
    <TableCell
      height={height}
      style={{
        minWidth,
        width,
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        whiteSpace="nowrap"
        overflow="auto"
        width={width}
        minWidth={minWidth}
        height={height}
      >
        {children}
      </Box>
    </TableCell>
  )
}

export default function Header({
  columns,
}: {
  columns: ColumnTableType<VinculoValeTransporteDTO>[]
}) {
  return (
    <Box component={Paper} padding={1}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <CellContent
                key={'col-header' + col.name}
                width={col.options?.width}
                minWidth={col.options?.minWidth}
              >
                {col.label}
              </CellContent>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </Box>
  )
}
