import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { formatCurrency, formatSimpleDate } from '~/utils/utils'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Stack,
  ToolsDataTable,
} from '~/components'

import FormRemuneracao from './FormRemuneracao'

import useDialog from '~/hooks/useDialog'

import { useGetAll } from '~/hooks/queries/ProcessoTrabalhistaVinculoRemuneracao/useGetAll'
import { useRemove } from '~/hooks/queries/ProcessoTrabalhistaVinculoRemuneracao/useRemove'

import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  IndUnidadeSalarioEnum,
  IndUnidadeSalarioLabels,
} from '~/@types/enums/IndUnidadeSalarioEnum'
import { ProcessoTrabalhistaVinculoRemuneracao } from '~/hooks/queries/ProcessoTrabalhistaVinculoRemuneracao/dtos/ProcessoTrabalhistaVinculoRemuneracao'

interface TabRemuneracoesProps {
  processoTrabalhistaVinculoId: string
  onClose: () => void
}

export default function TabRemuneracoes({
  processoTrabalhistaVinculoId,
  onClose,
}: TabRemuneracoesProps) {
  const { data: _d, isLoading, isFetching } = useGetAll({ processoTrabalhistaVinculoId })
  const data = _d || []

  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } = useRemove()

  const {
    close: closeFormRemuneracao,
    data: dataFormRemuneracao,
    isOpen: isOpenFormRemuneracao,
    open: openFormRemuneracao,
  } = useDialog<ProcessoTrabalhistaVinculoRemuneracao | null>(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  async function handleDelete() {
    await mutateAsyncRemove(dataConfirmDelete)
    closeConfirmDelete()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtRemuneracao',
      label: 'Data da Remuneração',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'vrSalarioFixo',
      label: 'Valor do Salário Fixo',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'unidadeSalarioFixo',
      label: 'Unidade do Salário Fixo',
      options: {
        customBodyRender: (value: IndUnidadeSalarioEnum) => IndUnidadeSalarioLabels[value],
      },
    },
    {
      name: 'descricaoSalarioVariavel',
      label: 'Descrição do Salário',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtCurrent = data.find((d) => d.id === value)
                  if (dtCurrent) {
                    openFormRemuneracao(dtCurrent)
                  }
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => openConfirmDelete(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack>
      <ButtonBox>
        <Button variant="contained" onClick={() => openFormRemuneracao(null)}>
          Adicionar
        </Button>
      </ButtonBox>
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          disableAutoHeight
          pagination={false}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </ContainerTable>

      {isOpenFormRemuneracao && (
        <FormRemuneracao
          processoTrabalhistaVinculoId={processoTrabalhistaVinculoId}
          isOpen={isOpenFormRemuneracao}
          data={dataFormRemuneracao}
          onClose={closeFormRemuneracao}
        />
      )}

      <ButtonBox>
        <Button variant="contained" onClick={() => onClose()}>
          Fechar
        </Button>
      </ButtonBox>

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isRemoving}
          onCancel={() => closeConfirmDelete()}
          onConfirm={handleDelete}
        />
      )}
    </Stack>
  )
}
