import moment from 'moment'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { ToolsDataTable } from '~/components'

export default function Table(props) {
  const { isLoading, isFetching, query, data: _data, onRowSelected, rowsSelected } = props

  const data = _data.map((d) => ({
    ...d,
    pessoaCPF: d?.pessoaCPF ? formatToCPFOrCNPJ(d.pessoaCPF) : '',
    dtAdmissao: d?.dtAdmissao ? moment(d.dtAdmissao).format('DD/MM/yyyy') : '',
  }))

  const columns = [
    {
      label: 'Código',
      name: 'pessoaCodigo',
    },
    {
      label: 'CPF',
      name: 'pessoaCPF',
    },
    {
      label: 'Nome',
      name: 'pessoaNome',
    },
    {
      label: 'Admissão',
      name: 'dtAdmissao',
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      sherlock={{ columns: ['pessoaCodigo', 'pessoaCPF', 'pessoaNome'], query }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        selectOnClick: true,
        type: 'multiple',
      }}
    />
  )
}
