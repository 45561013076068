export enum IndMultiploVinculoEnum {
  ContribuicaoDescontadaPeloEmpregadorDeclarante_1 = 1,
  ContribuicaoDescontadaLimiteTeto_2 = 2,
  ContribuicaoDescontadaEmOutroEmpregador_3 = 3,
  NaoPossuiMultiplosVinculos_9 = 9,
}

export const IndMultiploVinculoValues = [
  { value: IndMultiploVinculoEnum.NaoPossuiMultiplosVinculos_9, name: 'Não informar' },
  {
    value: IndMultiploVinculoEnum.ContribuicaoDescontadaPeloEmpregadorDeclarante_1,
    name: '1 - O declarante aplica a(s) alíquota(s) de desconto do segurado sobre a remuneração por ele informada (o percentual da(s) alíquota(s) será(ão) obtido(s) considerando a remuneração total do trabalhador)',
  },
  {
    value: IndMultiploVinculoEnum.ContribuicaoDescontadaLimiteTeto_2,
    name: '2 - O declarante aplica a(s) alíquota(s) de desconto do segurado sobre a diferença entre o limite máximo do salário de contribuição e a remuneração de outra(s) empresa(s) para as quais o trabalhador informou que houve o desconto',
  },
  {
    value: IndMultiploVinculoEnum.ContribuicaoDescontadaEmOutroEmpregador_3,
    name: '3 - O declarante não realiza desconto do segurado, uma vez que houve desconto sobre o limite máximo de salário de contribuição em outra(s) empresa(s)',
  },
]
