export enum IndCooperativaEnum {
  NaoCooperativa_0 = 0,
  CooperativaDetrabalho_1 = 1,
  CooperativaDeProducao_2 = 2,
  OutrasCooperativas_3 = 3,
}

export const IndCooperativaValues = [
  { value: IndCooperativaEnum.NaoCooperativa_0, name: '0 - Não é cooperativa' },
  { value: IndCooperativaEnum.CooperativaDetrabalho_1, name: '1 - Cooperativa de trabalho' },
  { value: IndCooperativaEnum.CooperativaDeProducao_2, name: '2 - Cooperativa de produção' },
  { value: IndCooperativaEnum.OutrasCooperativas_3, name: '3 - Outras cooperativas' },
]
