export enum IndCumprimentoEnum {
  Cumprimento_Total = '0',
  Parcial_obtencao_novo_emprego = '1',
  Parcial_iniciativa_Empregador = '2',
  Outras_hipoteses = '3',
  Aviso_indenizado_Nao_exigivel = '4',
}

export const IndCumprimentoValues = [
  { value: IndCumprimentoEnum.Cumprimento_Total, name: 'Cumprimento Total' },
  {
    value: IndCumprimentoEnum.Parcial_obtencao_novo_emprego,
    name: 'Parcial em razão de obtenção de novo emprego',
  },
  {
    value: IndCumprimentoEnum.Parcial_iniciativa_Empregador,
    name: 'Parcial por iniciativa do Empregador',
  },
  { value: IndCumprimentoEnum.Outras_hipoteses, name: 'Outras hipóteses' },
  {
    value: IndCumprimentoEnum.Aviso_indenizado_Nao_exigivel,
    name: 'Aviso Prévio indenizado ou não exigível',
  },
]
