import {
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Icon,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[800],
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  icon: {
    height: theme.spacing(8),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.spacing(6),
  },
  cardContent: {
    padding: theme.spacing(1.5),
  },
}))

export interface IconCardProps {
  title: string
  icon: string
  link: string
}

export default function IconCard({ title, icon, link }: IconCardProps) {
  const classes = useStyles()

  return (
    <Card>
      <Link to={{ pathname: link }}>
        <CardActionArea>
          <CardMedia component={Icon} color="primary" className={classes.icon}>
            {icon}
          </CardMedia>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title}>{title}</Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  )
}
