import React, { useState } from 'react'

import api from '~/services/api-pessoal'

import MUIAutoComplete from '../MUIAutoComplete'

export default function AutoCompleteEstabilidade(props) {
  const { ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/Estabilidade')
      setOptions(response.data.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) =>
    `${option.codigo} - ${option.descricao} - ${option.numeroDias} dias`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label="Estabilidade"
      {...rest}
    />
  )
}
