import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryModeloPlanilhaImportacao } from './useModeloPlanilhaImportacao'

import { ModeloPlanilhaImportacao } from './ModeloPlanilhaImportacao'

export default function useModeloPlanilhaImportacaoAtualizar() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest(data: ModeloPlanilhaImportacao) {
    await api.put('/ModeloPlanilhaImportacao/' + data.id, data)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.put()
      await queryClient.invalidateQueries(queryModeloPlanilhaImportacao)
    },
    onError: dialogNotification.extractErrors,
  })
}
