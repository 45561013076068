import React, { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid } from '@material-ui/core'
import { Button, TextField, ButtonBox } from 'mio-library-ui'

import { DatePicker } from '~/components'
import { AutoCompleteSetor, AutoCompleteCargo } from '~/components/AutoComplete'

import { useVinculoLocalMutate } from '~/hooks/queries/useVinculoLocal'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

const schema = yup.object().shape({
  dtLocal: yup.date().required('Informe a Data').typeError('Informe uma data válida').nullable(),
  cargoId: yup.string().required('Informe o Cargo').nullable(),
})

export default function Form(props) {
  const { data: _data, onClose } = props
  const [data, setData] = useState({})

  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })
  const { mutateCreate, mutateUpdate } = useVinculoLocalMutate()
  const notification = useNotification()
  const { empregador } = useAmbiente()

  useEffect(() => {
    setData(_data)
  }, [_data])

  async function handleSubmit() {
    if (data?.id) {
      await mutateUpdate.mutateAsync(data)
      notification.put()
    } else {
      await mutateCreate.mutateAsync(data)
      notification.post()
    }
    onClose()
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePicker
            label="Data"
            size="small"
            value={data?.dtLocal || null}
            required
            validationErrors={validationErrors}
            name="dtLocal"
            onChange={(date) => {
              const dtLocal = date ? date.format('yyyy-MM-DD') : null
              setData((prev) => ({ ...prev, dtLocal }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteSetor
            onChange={(e, setor) => {
              const setorId = setor ? setor.id : ''
              setData((prev) => ({ ...prev, setorId, setor }))
            }}
            value={data?.setor || null}
            empregadorId={empregador?.id}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteCargo
            validationErrors={validationErrors}
            name="cargoId"
            required
            onChange={(e, cargo) => {
              const cargoId = cargo ? cargo.id : ''
              setData((prev) => ({ ...prev, cargoId, cargo }))
            }}
            value={data?.cargo || null}
            empregadorId={empregador?.id}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data?.observacao || ''}
            variant="outlined"
            size="small"
            multiline
            rows={4}
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData((prev) => ({ ...prev, observacao }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ButtonBox>
            <Button onClick={onClose} size="small" variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button
              isLoading={mutateCreate.isLoading || mutateUpdate.isLoading}
              onClick={handleValidate}
              size="small"
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </ButtonBox>
        </Grid>
      </Grid>
    </Box>
  )
}
