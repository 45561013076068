import React, { useMemo } from 'react'

import moment from 'moment'

import { ToolsDataTable } from '~/components'

export default function TableFuncionarios(props) {
  const { data: _data } = props

  const data = _data.map((d) => ({
    ...d,
    dtNascimento: d?.dtNascimento ? moment(d.dtNascimento).format('DD/MM/yyyy') : '',
    dtAdmissao: d?.dtAdmissao ? moment(d.dtAdmissao).format('DD/MM/yyyy') : '',
    dtRescisao: d?.dtRescisao ? moment(d.dtRescisao).format('DD/MM/yyyy') : '',
    dtTransferencia: d?.dtTransferencia ? moment(d.dtTransferencia).format('DD/MM/yyyy') : '',
    dtReciboEsocial: d?.dtReciboEsocial ? moment(d.dtReciboEsocial).format('DD/MM/yyyy') : '',
  }))

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nrInscricaoString',
        label: 'CPF',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'cargo',
        label: 'Cargo',
      },
      {
        name: 'dtNascimento',
        label: 'Nascimento',
      },
      {
        name: 'dtAdmissao',
        label: 'Admissão',
      },
      {
        name: 'dtRescisao',
        label: 'Rescisão',
      },
      {
        name: 'dtTransferencia',
        label: 'Transferência',
      },
      {
        name: 'dtReciboEsocial',
        label: 'Data eSocial',
      },
      {
        name: 'reciboEsocial',
        label: 'Recibo eSocial',
      },
      {
        name: 'tipoAdmissaoEsocial',
        label: 'Tipo Admissão eSocial',
      },
      {
        name: 'motivoDesligamentoEsocial',
        label: 'Motivo Desligamento eSocial',
      },
    ],
    [],
  )

  return <ToolsDataTable data={data} columns={columns} disableAutoHeight />
}
