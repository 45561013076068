import React, { useState, useEffect, useCallback } from 'react'

import { ButtonBox, TextField, Button, ContentDivider } from 'mio-library-ui'
import { Box, Grid, makeStyles } from '@material-ui/core'

import { ConfirmDeleteDialog, ActionDialog } from '~/components'

import Table from './components/Table'
import FormItem from './components/FormItem'

import * as yup from 'yup'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '100%',
  },
}))

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  const [collectionItem, setCollectionItem] = useState({
    isLoading: false,
    itens: [],
  })
  const [formItem, setFormItem] = useState({
    isOpen: false,
    data: {},
  })
  const [confirmDeleteDialogItem, setConfirmDeleteDialogItem] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  useEffect(() => {
    const getCollection = async () => {
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(`/indiceItem/GetByIndice?id=${_data.id}`)
        if (response.data.data) {
          setCollectionItem((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    setData(_data)
    getCollection()
    // eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/indice/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/indice', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collectionItem
        const item = itens.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialogItem((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    //eslint-disable-next-line
    [collectionItem],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    const itens = collectionItem.itens
    setConfirmDeleteDialogItem((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    try {
      await api.delete(`/indiceItem/${confirmDeleteDialogItem.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialogItem.id)
      handleCloseConfirmDeleteItem()
      setCollectionItem((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    //eslint-disable-next-line
  }, [collectionItem.itens, confirmDeleteDialogItem.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
    //eslint-disable-next-line
  }, [])

  const handleOpenForm = (data = {}) => {
    setFormItem((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }

  const handleCloseForm = () => {
    setFormItem({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitFormItem = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collectionItem
        const newItens = [data, ...itens]
        setCollectionItem((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collectionItem
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollectionItem((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    //eslint-disable-next-line
    [collectionItem],
  )

  return (
    <ActionDialog
      title="Cadastro da Tabela Índice"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: data.id ? 'lg' : 'sm',
        fullWidth: true,
        classes: {
          paper: data?.id ? classes.heightPaper : undefined,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            label="Código"
            fullWidth
            value={data.codigo || ''}
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            autoFocus
            value={data.nome || ''}
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        {data?.id && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ContentDivider />
          </Grid>
        )}
      </Grid>

      {data?.id && (
        <>
          <Box py={1}>
            <ContentDivider
              title="Índices"
              renderRight={
                <ButtonBox>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleClickAddItem}
                    variant="contained"
                  >
                    Adicionar
                  </Button>
                </ButtonBox>
              }
            />
          </Box>
          <Table
            data={collectionItem.itens}
            isLoading={collectionItem.isLoading}
            onItemClick={handleClickItem}
          />
        </>
      )}

      <FormItem
        isOpen={formItem.isOpen}
        data={formItem.data}
        indice={data}
        onClose={handleCloseForm}
        onAfterSubmitFormItem={handleAfterSubmitFormItem}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialogItem.isOpen}
        isDeleting={confirmDeleteDialogItem.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ActionDialog>
  )
}

export default Form
