import { Icon, useTheme } from '@material-ui/core'

import {
  CompetenciaEmpregadorStatusEnum,
  CompetenciaEmpregadorStatusValues,
} from '~/@types/enums/CompetenciaEmpregadorStatusEnum'

interface ComponentStatusProps {
  status: CompetenciaEmpregadorStatusEnum
}

export default function ComponentStatus({ status }: ComponentStatusProps) {
  const theme = useTheme()

  const title = CompetenciaEmpregadorStatusValues.find((d) => d.value === status)?.name

  const icons = {
    [CompetenciaEmpregadorStatusEnum.Processando]: 'hourglass_empty',
    [CompetenciaEmpregadorStatusEnum.NaoAberta]: 'pause_circle_filled',
    [CompetenciaEmpregadorStatusEnum.Aberta]: 'play_circle_outline',
    [CompetenciaEmpregadorStatusEnum.Enviado]: 'double_arrow',
    [CompetenciaEmpregadorStatusEnum.Fechada]: 'check_circle',
  }
  const iconsColor = {
    [CompetenciaEmpregadorStatusEnum.Processando]: theme.palette.info.main,
    [CompetenciaEmpregadorStatusEnum.NaoAberta]: theme.palette.action.active,
    [CompetenciaEmpregadorStatusEnum.Aberta]: theme.palette.primary.main,
    [CompetenciaEmpregadorStatusEnum.Enviado]: theme.palette.warning.main,
    [CompetenciaEmpregadorStatusEnum.Fechada]: theme.palette.success.main,
  }

  return (
    <Icon title={title} style={{ color: iconsColor[status] }}>
      {icons[status]}
    </Icon>
  )
}
