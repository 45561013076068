import api from '~/services/api-pessoal'

import { AcordoDissidioConvencao } from '../AcordoDissidioConvencao/AcordoDissidioConvencao'

export default async function obterAcordoDissidioPorCompetencia(anoMes: string) {
  const response = await api.get<{ data: AcordoDissidioConvencao[] }>(
    '/RescisaoComplementar/ObterAcordoDissidioPorCompetencia',
    {
      params: {
        anoMes,
      },
    },
  )
  return response?.data?.data || []
}
