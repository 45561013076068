import React, { useMemo, useEffect, useState } from 'react'

import { ToolsDataTable } from '~/components'

import TableRecibos from './components/TableRecibos'

import api from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'

import moment from 'moment'

const Table = (props) => {
  const {
    data: _data,
    isLoading,
    anoMesInicio,
    anoMesFim,
    reciboIdf,
    triggersHeight,
    setRowsExpanded,
    rowsExpanded,
  } = props

  const [params, setParams] = useState({
    anoMesInicio: '',
    anoMesFim: '',
    reciboIdf: '',
  })
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setParams({
      anoMesInicio: anoMesInicio,
      anoMesFim: anoMesFim,
      reciboIdf: reciboIdf,
    })
    //eslint-disable-next-line
  }, [_data])

  const data = _data.map((item) => {
    return {
      ...item,
      pessoaNome: item?.pessoaFisica?.nome || '',
      pessoaMatricula: item?.matricula || '',
      dtAdmissao: item?.dtAdmissao ? moment(item.dtAdmissao).format('DD/MM/yyyy') : '',
      dtRescisao: item?.dtRescisao ? moment(item.dtRescisao).format('DD/MM/yyyy') : '',
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'pessoaNome',
        label: 'Nome',
      },
      {
        name: 'pessoaMatricula',
        label: 'Matrícula',
      },
      {
        name: 'dtAdmissao',
        label: 'Data Admissão',
      },
      {
        name: 'dtRescisao',
        label: 'Data Recisão',
      },
    ],
    [],
  )

  const [dataOfTableExpanded, setDataOfTableExpanded] = useState({
    isLoading: false,
    data: [],
  })

  const onRowExpand = async (indexs) => {
    setRowsExpanded(indexs)
    if (indexs.length === 0) return
    const obj = data[indexs[0]]
    setDataOfTableExpanded((oldState) => ({
      ...oldState,
      isLoading: true,
    }))
    try {
      const response = await api.get('RPConsulta/GetByVinculo', {
        params: {
          vinculoId: obj.id,
          anoMesInicio: params.anoMesInicio,
          anoMesFim: params.anoMesFim,
        },
        data: params.reciboIdf,
      })
      setDataOfTableExpanded({
        data: response.data.data,
        isLoading: true,
      })
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setDataOfTableExpanded((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
  }

  function renderExpandableRow() {
    return (
      <TableRecibos data={dataOfTableExpanded.data} isLoading={dataOfTableExpanded.isLoading} />
    )
  }

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      triggersHeight={triggersHeight}
      optionsExpandable={{
        type: 'single',
        expandedChildren: renderExpandableRow,
        onRowExpanded: onRowExpand,
        rowsExpanded: rowsExpanded,
        expandOnClick: true,
      }}
    />
  )
}

export default Table
