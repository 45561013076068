export enum IndAtividadeEstabelecimentoEnum {
  Todos,
  ApenasAtivos,
  FimAtividadePreenchido,
  InicioInatividadePreenchido,
}

export const IndAtividadeEstabelecimentoValues = [
  {
    name: 'Todos os Estabelecimentos',
    value: IndAtividadeEstabelecimentoEnum.Todos,
  },
  {
    name: 'Apenas Estabelecimentos Ativos',
    value: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
  },
  {
    name: 'Estabelecimentos com Fim de Atividade',
    value: IndAtividadeEstabelecimentoEnum.FimAtividadePreenchido,
  },
  {
    name: 'Estabelecimentos Inativos',
    value: IndAtividadeEstabelecimentoEnum.InicioInatividadePreenchido,
  },
]
