import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer, Checkbox, Finder } from '~/components'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { useAutorizacaoDescontosObterFuncionarios } from '~/hooks/queries/AutorizacaoDescontos/useAutorizacaoDescontosObterFuncionarios'

import { DescontosPossiveisEnum } from '~/@types/enums/DescontosPossiveisEnum'

export interface RelAutorizacaoDescontosRequestBodyDTO {
  estabelecimentoId: string
  funcionariosIds: string[]
  descontosImprimir: DescontosPossiveisEnum[]
  outrosEspecificacao: string
  dtAssinatura: Date | string | null
}

export type AutorizacaoDescontosFormConfirm = Omit<
  RelAutorizacaoDescontosRequestBodyDTO,
  'estabelecimentoId' | 'funcionariosIds'
>

const PAGE_TITLE = 'Autorização de Descontos'

export default function AutorizacaoDescontos() {
  const [query, setQuery] = useState('')
  const [rowsSelecteds, setRowsSelected] = useState<number[]>([])
  const [isSomenteAdmitidosAnoMes, setSomenteAdmitidosAnoMes] = useState(false)

  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog(null)
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<RelAutorizacaoDescontosRequestBodyDTO | null>(null)
  const { estabelecimento, anoMes } = useAmbiente()
  const { data, isLoading, isFetching } = useAutorizacaoDescontosObterFuncionarios(
    estabelecimento.id,
    anoMes,
    isSomenteAdmitidosAnoMes,
  )

  useEffect(() => {
    setRowsSelected([])
  }, [estabelecimento, anoMes, isSomenteAdmitidosAnoMes])

  function handleAfterSubmitForm(dt: AutorizacaoDescontosFormConfirm) {
    openPDFViewer({
      estabelecimentoId: estabelecimento.id,
      funcionariosIds: rowsSelecteds.map((index) => data[index].id),
      descontosImprimir: dt.descontosImprimir,
      dtAssinatura: dt.dtAssinatura,
      outrosEspecificacao: dt.outrosEspecificacao,
    })
    setRowsSelected([])
  }

  function handleOpenDialogConfirm() {
    openDialogConfirm(null)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={PAGE_TITLE}>
        <Checkbox
          label="Somente Funcionários Admitidos na Competência"
          value={isSomenteAdmitidosAnoMes}
          onChange={(e, value) => {
            setSomenteAdmitidosAnoMes(value)
          }}
        />
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Table
        query={query}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelecteds}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
      />

      {isOpenDialogConfirm && (
        <DialogConfirm
          isOpen={isOpenDialogConfirm}
          onClose={closeDialogConfirm}
          onAfterSubmit={handleAfterSubmitForm}
        />
      )}

      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={handleOpenDialogConfirm} disabled={!(rowsSelecteds.length > 0)} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="AutorizacaoDescontos"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/AutorizacaoDescontos/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
