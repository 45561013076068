import { convertToArrayDescription } from '~/utils/utils'

export enum IndBaseInsalubridadeEnum {
  SalarioMinimo_1 = 1,
  SalarioContratual_2 = 2,
  SalarioBaseCategoria_3 = 3,
  SalarioBaseTabelaSalario_4 = 4,
}

export const IndBaseInsalubridadeLabel = {
  [IndBaseInsalubridadeEnum.SalarioMinimo_1]: '1 - Salário Mínimo',
  [IndBaseInsalubridadeEnum.SalarioContratual_2]: '2 - Salário Contratual',
  [IndBaseInsalubridadeEnum.SalarioBaseCategoria_3]: '3 - Salário Base Categoria',
  [IndBaseInsalubridadeEnum.SalarioBaseTabelaSalario_4]: '4 - Salário Tabela Salário',
}

export const IndBaseInsalubridadeValues = convertToArrayDescription(
  IndBaseInsalubridadeEnum,
  IndBaseInsalubridadeLabel,
)
