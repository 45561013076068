import { useMemo, useState } from 'react'

import { Box, Paper, useTheme } from '@material-ui/core'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { Checkbox, Finder, LabelValueColor, PageHeader } from '~/components'

import useAmbiente from '~/hooks/useAmbiente'
import useObterIRRFBeneficiario from '~/hooks/queries/ApurarESocial/useObterIRRFBeneficiario'

import Table from './Table'

import { IndApuracaoValues, IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import { DialogIRRFBeneficiarioData } from '../ApuracaoEncargos/components/DemonstracaoApuracao/PrevidenciaSocial/components/DialogIRRFBeneficiario'
import { formatCurrency } from '~/utils/utils'

export default function IRRFBeneficiario({ data }: { data: DialogIRRFBeneficiarioData | null }) {
  const [dataForm, setDataForm] = useState({
    indApuracao: data?.indApuracao || IndApuracaoEnum.Mensal_1,
    isOnlyDiferenca: false,
    query: '',
  })

  const theme = useTheme()
  const { empregador: _empregador, anoMes: _anoMes } = useAmbiente()

  const empregadorId = data?.empregadorId || _empregador.id
  const anoMes = data?.anoMes || _anoMes

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterIRRFBeneficiario({
    empregadorId,
    periodoApuracao: anoMes,
    indApuracao: dataForm.indApuracao,
    isSomenteDiferenca: dataForm.isOnlyDiferenca,
  })
  const collection = _data || []

  const { vrFolha, vrESocial, vrDiferenca } = useMemo(() => {
    let vrFolha = 0
    let vrESocial = 0
    let vrDiferenca = 0

    collection.forEach((element) => {
      vrFolha += element.vrFolha
      vrESocial += element.vrESocial
      vrDiferenca += element.vrDiferenca
    })

    return {
      vrFolha,
      vrESocial,
      vrDiferenca,
    }
  }, [collection])

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      gridGap={theme.spacing(1)}
      flexDirection="column"
    >
      <PageHeader title="IRRF por Beneficiário">
        <Finder
          onSearch={(query) => setDataForm((prev) => ({ ...prev, query }))}
          onClose={() => setDataForm((prev) => ({ ...prev, query: '' }))}
        />
      </PageHeader>

      <Box component={Paper} display="flex" gridGap={theme.spacing(1)} p={1}>
        <MUIAutoComplete
          label="Indicador de Apuração"
          options={IndApuracaoValues}
          optionId="value"
          renderOption={(option) => option.name}
          value={dataForm.indApuracao}
          onChange={(e, obj) => {
            const indApuracao = obj?.value || ''
            setDataForm((prev) => ({ ...prev, indApuracao }))
          }}
        />

        <Box width={245}>
          <Checkbox
            label="Somente com diferença"
            value={dataForm.isOnlyDiferenca}
            onChange={(e, isOnlyDiferenca) => setDataForm((prev) => ({ ...prev, isOnlyDiferenca }))}
          />
        </Box>
      </Box>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%" height="100%">
          <Table
            data={collection}
            isLoading={isLoading}
            isFetching={isFetching}
            query={dataForm.query}
          />
        </Box>
      </Box>

      <Box component={Paper} padding={1.5} display="flex" justifyContent="flex-end">
        <Box width={200}>
          <LabelValueColor value={formatCurrency(vrFolha, true)} label="Folha" />
        </Box>
        <Box width={200}>
          <LabelValueColor value={formatCurrency(vrESocial, true)} label="ESocial" />
        </Box>
        <Box width={200}>
          <LabelValueColor
            value={formatCurrency(vrDiferenca, true)}
            label="Diferença"
            color="#2E8BC0"
          />
        </Box>
      </Box>
    </Box>
  )
}
