import React from 'react'

import { Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  root: {
    pointerEvents: (props) => (props.isBackdropClick ? 'auto' : 'none'),
  },
  paper: {
    pointerEvents: 'auto',
  },
}))

export default function PopoverComponent(props) {
  const { anchorEl, onClose, anchorOrigin, transformOrigin, isBackdropClick, ...rest } = props

  const classes = useStyles({ isBackdropClick })

  const open = Boolean(anchorEl)

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      {...rest}
    />
  )
}

PopoverComponent.defaultProps = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'center',
  },
  isBackdropClick: true,
}

PopoverComponent.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  isBackdropClick: PropTypes.bool,
  children: PropTypes.node,
}
