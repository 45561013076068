import api from '~/services/api-pessoal'

import { RescisaoComplementarFuncionarioSimpleDTO } from './RescisaoComplementarDTO'

interface RequestProps {
  estabelecimentoId: string
  anoMes: string
}

export default async function obterFuncionariosComRescisao(params: RequestProps) {
  const response = await api.get<{ data: RescisaoComplementarFuncionarioSimpleDTO[] }>(
    '/RescisaoComplementar/ObterFuncionariosComRescisao',
    {
      params,
    },
  )
  return response?.data?.data || []
}
