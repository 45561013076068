import { useRef } from 'react'

import { Pie, getElementAtEvent } from 'react-chartjs-2'

interface PieChartProps {
  data: { status: number; total: number }[]
  labels: any
  colors: any
  onClick: (index: number) => void
  cutout?: string
  position?: 'bottom' | 'right'
  displayLegend?: boolean
  mainTitle?: string
  mainSubtitle?: string
}

export default function PieChart({
  data,
  labels,
  colors,
  onClick,
  cutout = '0%',
  position = 'bottom',
  displayLegend = true,
  mainTitle,
  mainSubtitle,
}: PieChartProps) {
  const chartRef = useRef() as any

  function handleClick(event: any) {
    const element = getElementAtEvent(chartRef.current, event)[0]
    if (element?.index === undefined) return
    onClick(element?.index)
  }

  return (
    <Pie
      ref={chartRef}
      data={{
        labels: data.map((d) => labels[d.status]),
        datasets: [
          {
            data: data.map((d) => d.total),
            backgroundColor: data.map((d) => colors[d.status]),
          },
        ],
      }}
      options={{
        cutout,
        plugins: {
          legend: {
            display: displayLegend,
            align: 'center',
            position,
          },
        },
        radius: '80',
        onHover: (event: any, elements) => {
          if (!elements[0]) {
            event.native.target.style.cursor = 'default'
            return
          }
          event.native.target.style.cursor = 'pointer'
        },
      }}
      plugins={[
        {
          id: 'mainTitle',
          beforeDraw(chart) {
            if (!mainTitle) return

            const { ctx, width, height } = chart

            ctx.restore()

            const totalFontSize = (height / 200).toFixed(2)
            ctx.font = `${totalFontSize}em sans-serif`
            ctx.textBaseline = 'middle'
            ctx.fillStyle = '#000'

            const totalTextX = Math.round((width - ctx.measureText(mainTitle).width) / 2)
            const totalTextY = height / 2 - 5
            ctx.fillText(mainTitle, totalTextX, totalTextY)

            const subtitleFontSize = (height / 300).toFixed(2)
            ctx.font = `${subtitleFontSize}em sans-serif`

            ctx.save()
          },
        },
        {
          id: 'mainSubtitle',
          beforeDraw(chart) {
            if (!mainSubtitle) return

            const { ctx, width, height } = chart
            ctx.restore()

            const totalFontSize = (height / 200).toFixed(2)
            ctx.font = `${totalFontSize}em sans-serif`
            ctx.textBaseline = 'middle'
            ctx.fillStyle = 'gray'

            const subtitleFontSize = (height / 300).toFixed(2)
            ctx.font = `${subtitleFontSize}em sans-serif`

            const totalTextY = height / 2 - 5

            const subtitleTextX = Math.round((width - ctx.measureText(mainSubtitle).width) / 2)
            const subtitleTextY = totalTextY + 25
            ctx.fillText(mainSubtitle, subtitleTextX, subtitleTextY)

            ctx.save()
          },
        },
      ]}
      onClick={handleClick}
    />
  )
}
