export enum TipoRelatorioFeriasColetivaEnum {
  ReciboFerias,
  AvisoFerias,
  Recibo_Aviso_Ferias,
}

export const TipoRelatorioFeriasColetivaValues = [
  {
    name: 'Recibo de Férias',
    value: TipoRelatorioFeriasColetivaEnum.ReciboFerias,
  },
  {
    name: 'Aviso de Férias',
    value: TipoRelatorioFeriasColetivaEnum.AvisoFerias,
  },
  {
    name: 'Recibo e Aviso de Férias',
    value: TipoRelatorioFeriasColetivaEnum.Recibo_Aviso_Ferias,
  },
]
