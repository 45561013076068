import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core'

import themeOriginal from '~/styles/theme'

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:nth-child(even)': {
      backgroundColor: '#FBFCFE',
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
  },
}))

const theme = createMuiTheme({
  palette: {
    primary: themeOriginal.palette.primary,
  },
})

interface TableChartColumn {
  name: string
  label: string
  options?: {
    customBodyRender?: (index: number) => React.ReactNode
  }
}

interface TableChartProps {
  onClickRow: (index: number) => void
  columns: TableChartColumn[]
  data: any[]
}

export default function TableChart({ onClickRow, columns, data }: TableChartProps) {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((d) => (
              <TableCell key={d.name}>{d.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d, index) => (
            <TableRow
              className={classes.tableRow}
              key={d.indRecibo + index}
              onClick={() => onClickRow(index)}
            >
              {columns.map(({ name, options: _opts }) => {
                const { customBodyRender } = _opts || {}
                return (
                  <TableCell key={name + 'bodycell'}>
                    {customBodyRender ? customBodyRender(index) : d[name]}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ThemeProvider>
  )
}
