import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { RelSalarioContratualFuncionarioDTO } from './RelSalarioContratualFuncionarioDTO'

export function useObterFuncionarios(estabelecimentoId: string, anoMes: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: RelSalarioContratualFuncionarioDTO[] }>(
      '/Relatorio/SalarioContratual/ObterFuncionarios',
      {
        params: {
          estabelecimentoId,
          anoMes,
        },
      },
    )
    return response.data.data
  }

  const query = useQuery(
    ['RelatorioSalarioContratualObterFuncionarios', estabelecimentoId, anoMes],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
  return {
    ...query,
    data: query.data || [],
  }
}
