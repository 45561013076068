import { ButtonBox } from 'mio-library-ui'
import React from 'react'
import { ActionDialog, Button, ToolsDataTable } from '~/components'

import { useFeriasColetiva } from '../Context'

export default function DialogAlerts() {
  const { dialogsProps } = useFeriasColetiva()
  const { isOpenDialogAlerts, closeDialogAlerts, dataDialogAlerts } = dialogsProps

  return (
    <ActionDialog
      isOpen={isOpenDialogAlerts}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button variant="contained" onClick={closeDialogAlerts}>
            Fechar
          </Button>
        </ButtonBox>
      }
      onClose={closeDialogAlerts}
      title="Alertas"
    >
      <ToolsDataTable
        pagination={false}
        data={dataDialogAlerts}
        columns={[
          {
            name: 'descricao',
            label: 'Descrição',
          },
        ]}
        disableAutoHeight
      />
    </ActionDialog>
  )
}
