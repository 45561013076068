import React, { useState, useEffect, useCallback } from 'react'

import { cnpj } from 'cpf-cnpj-validator'
import api from '~/services/api-pessoal'
import moment from 'moment'
import * as yup from 'yup'

import { Box, Grid, MenuItem, Tooltip } from '@material-ui/core'
import { ContentDivider, TextField } from 'mio-library-ui'

import { DatePicker, NumeroInscricaoTextField, ContainerForm } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { obterUltimoLocalTrabalhoVinculo } from '~/hooks/queries/useVinculoLocal'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { dateHasBetweenYearMonth } from '~/hooks/useUtils'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import {
  tipoInscricaoConsts,
  tipoInscricaoValues as _tipoInscricaoConsts,
} from '~/values/tipoInscricaoValues'

const { CNPJ_1, CAEPF_3, CNO_4 } = tipoInscricaoConsts

const tipoInscricaoValues = _tipoInscricaoConsts.filter(
  (obj) => obj.value === CNPJ_1 || obj.value === CAEPF_3 || obj.value === CNO_4,
)

const schema = yup.object().shape({
  dtInicio: yup
    .date()
    .when(['$dtAdmissao'], (dtAdmissao, schema) => {
      const dtAdmissaoFormatted = moment(dtAdmissao).format('DD/MM/yyyy')

      return schema.test(
        'DtInicio-validator',
        `A Data de Início deve ser igual ou posterior a Data de Admissão: ${dtAdmissaoFormatted} `,
        (dtInicio) => moment(dtInicio).isSameOrAfter(dtAdmissao),
      )
    })
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data informada deve está dentro da competência atual',
        (dt) => dateHasBetweenYearMonth(dt, anoMes),
      ),
    )
    .test(
      'DtInicio-validator',
      `A Data de Início não pode ser posterior a
      30 (trinta) dias da data atual: ${moment().format('DD/MM/yyyy')}`,
      (dtInicio) => {
        const dtInicioFormatted = moment(dtInicio)
        const oneMonthAfterToday = moment().add(30, 'days')

        return dtInicioFormatted.isSameOrBefore(oneMonthAfterToday)
      },
    )
    .required('Informe a Data de Início')
    .typeError('Informe uma Data válida')
    .nullable(),
  localAmbiente: yup.string().required('Informe o Tipo Local'),
  descricaoSetor: yup.string().required('Informe a Descrição do Setor'),
  tipoInscricao: yup.string().required('Informe o Tipo de Inscrição'),
  nrInscricao: yup
    .string()
    .required('Informe o Número de Inscrição')
    .when(['tipoInscricao'], (tipoInscricao, schema) =>
      schema.test('test', 'Informe um Número de Inscriçao válido', (nrInscricao) => {
        if (parseInt(tipoInscricao) === CNPJ_1) return cnpj.isValid(nrInscricao)
        if (parseInt(tipoInscricao) === CAEPF_3) return nrInscricao.length === 14
        if (parseInt(tipoInscricao) === CNO_4) return nrInscricao.length === 12
      }),
    ),
  descricaoAtividade: yup.string().required('Informe a Descrição das Atividades'),
})

const Form = (props) => {
  const {
    vinculo,
    data: _data,
    onAfterSubmitForm,
    onClose,
    setWasModified,
    estabelecimento,
  } = props

  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes } = useAmbiente()

  useEffect(() => {
    setWasModified(JSON.stringify(data) !== JSON.stringify(_data))
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    setData(_data)
  }, [_data])

  useEffect(() => {
    async function obterDescricaoDoSetorEAtividades() {
      if (!_data?.id)
        try {
          const response = await obterUltimoLocalTrabalhoVinculo(_data?.vinculoId)
          const descricaoSetor = response?.setor?.descricaoSetor || ''
          const descricaoAtividade = response?.cargo?.descricaoAtividades || ''

          setData((prev) => ({
            ...prev,
            descricaoSetor,
            descricaoAtividade,
          }))
        } catch (err) {
          console.log(err)
        }
    }
    obterDescricaoDoSetorEAtividades()
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setIsLoading(true)
      try {
        const response = await api.put(`/CondicaoAmbienteTrabalho/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    const insert = async () => {
      setIsLoading(true)
      try {
        const response = await api.post('/CondicaoAmbienteTrabalho', data)
        onAfterSubmitForm('insert', response.data.data)
        notification.post()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    // eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { dtAdmissao: vinculo?.dtAdmissao, anoMes },
    },
  })

  return (
    <ContainerForm handleSubmit={handleValidate} onCancel={onClose} isLoading={isLoading}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider top={2} />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ContentDivider bottom={1} title="Informações sobre o ambiente de trabalho" />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePicker
            label="Data de Início"
            size="small"
            value={data?.dtInicio || null}
            required
            validationErrors={validationErrors}
            name="dtInicio"
            onChange={(date) => {
              const dtInicio = date?.format('yyyy-MM-DD') || null
              setData((oldState) => ({
                ...oldState,
                dtInicio,
              }))
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <TextField
            fullWidth
            select
            variant="outlined"
            size="small"
            label="Tipo Local"
            required
            validationErrors={validationErrors}
            name="localAmbiente"
            value={data?.localAmbiente || ''}
            onChange={(e) => {
              const localAmbiente = e?.target?.value || ''
              if (localAmbiente === '1') {
                const tipoInscricao = CNPJ_1
                const nrInscricao = estabelecimento.nrInscricao
                setData((oldState) => ({
                  ...oldState,
                  tipoInscricao,
                  nrInscricao,
                }))
              }
              setData((oldState) => ({
                ...oldState,
                localAmbiente,
              }))
            }}
          >
            <MenuItem value="1">Estabelecimento do próprio Empregador</MenuItem>
            <MenuItem value="2">Estabelecimento de terceiros</MenuItem>
          </TextField>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Tooltip
            arrow
            placement="bottom-start"
            title="Descrição do lugar administrativo, na estrutura
            organizacional da empresa, onde o trabalhador exerce suas
            atividades laborais."
          >
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  maxLength: 100,
                }}
                multiline
                label="Descrição do Setor"
                required
                validationErrors={validationErrors}
                name="descricaoSetor"
                value={data?.descricaoSetor || ''}
                onChange={(e) => {
                  const descricaoSetor = e?.target?.value || ''
                  setData((oldState) => ({
                    ...oldState,
                    descricaoSetor,
                  }))
                }}
              />
            </Box>
          </Tooltip>
        </Grid>

        <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
          <MUIAutoComplete
            label="Tipo de Inscrição"
            name="tipoInscricao"
            required
            validationErrors={validationErrors}
            options={tipoInscricaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.tipoInscricao}
            onChange={(e, obj) => {
              const tipoInscricao = obj?.value
              setData((oldState) => ({
                ...oldState,
                tipoInscricao,
              }))
            }}
          />
        </Grid>

        <Grid xl={8} lg={8} md={8} sm={8} xs={12} item>
          <NumeroInscricaoTextField
            size="small"
            variant="outlined"
            fullWidth
            typeMask={data?.tipoInscricao}
            label="Número de Inscrição"
            value={data?.nrInscricao || ''}
            name="nrInscricao"
            required
            validationErrors={validationErrors}
            InputLabelProps={{ shrink: true }}
            onChange={(e, value) => {
              const nrInscricao = value
              setData((oldState) => ({
                ...oldState,
                nrInscricao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            size="small"
            label="Observação"
            inputProps={{
              maxLength: 999,
            }}
            value={data?.observacao || ''}
            onChange={(e) => {
              const observacao = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ContentDivider top={1} bottom={1} title="Descrição das atividades desempenhadas" />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Tooltip
            arrow
            placement="bottom-start"
            title="Descrição das atividades, físicas ou mentais, realizadas pelo
            trabalhador, por força do poder de comando a que se
            submete. As atividades deverão ser escritas com exatidão,
            e de forma sucinta, com a utilização de verbos no infinitivo
            impessoal. Ex.: Distribuir panfletos, operar máquina de
            envase, etc."
          >
            <Box>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                size="small"
                required
                name="descricaoAtividade"
                inputProps={{
                  maxLength: 999,
                }}
                validationErrors={validationErrors}
                label="Descrição das Atividades"
                value={data?.descricaoAtividade || ''}
                onChange={(e) => {
                  const descricaoAtividade = e?.target?.value || ''
                  setData((oldState) => ({
                    ...oldState,
                    descricaoAtividade,
                  }))
                }}
              />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </ContainerForm>
  )
}

export default Form
