import { Typography } from '@material-ui/core'
import { ActionDialog } from '~/components'

import useKeyPress from '~/hooks/useKeyPress'

const ConfirmDeleteDialog = (props) => {
  const { isOpen, onConfirm, onCancel, isDeleting, description } = props

  function handleConfirmByEnter() {
    if (!isOpen) return
    onConfirm()
  }

  useKeyPress('Enter', handleConfirmByEnter)

  return (
    <ActionDialog
      // title="Excluir Registro!"
      dialogProps={{ maxWidth: 'sm' }}
      title="Excluir Registro Permanentemente!"
      isOpen={isOpen}
      onClose={onCancel}
      onCancelClick={onCancel}
      onOkClick={onConfirm}
      okLabel="Sim"
      isOkProcessing={isDeleting}
    >
      <Typography>{description}</Typography>
    </ActionDialog>
  )
}

export default ConfirmDeleteDialog

ConfirmDeleteDialog.defaultProps = {
  // description: 'Você quer realmente excluir este registro?',
  description: 'Você não poderá recuperar este registro após excluí-lo',
}
