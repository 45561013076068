import React, { useCallback, useMemo } from 'react'

import { IconButton, makeStyles } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { HeightDataTable } from '~/components'

import { GetApp as SelectIcon } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

const Table = (props) => {
  const { isOpen, onClose, onClickItem, data } = props

  const handleGetItem = useCallback(
    (value) => {
      const saqueFGTS = data.find((item) => item.codigo === value)

      onClickItem(saqueFGTS)
    },
    [onClickItem, data],
  )

  const classes = useStyles()

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'descricao',
        label: 'Descrição',
      },
      {
        name: 'codigo',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Transportar este registro"
                  color="primary"
                  aria-label="Transportar"
                  onClick={() => {
                    handleGetItem(value)
                  }}
                >
                  <SelectIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [handleGetItem],
  )

  return (
    <ActionDialog
      title="Saque FGTS"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      customActions={<div />}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
    >
      <HeightDataTable title="" data={data} columns={columns} />
    </ActionDialog>
  )
}

export default Table
