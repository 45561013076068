import React from 'react'

import { IconButton, InputAdornment } from '@material-ui/core'
import { PlaylistAdd as PlaylistAddIcon } from '@material-ui/icons'

import { TextField } from '~/components'

interface InputMakeFormulaProps {
  label: string
  value: string
  openFormula: (formula: string) => void
}

export default function InputMakeFormula(props: InputMakeFormulaProps) {
  const { label, value, openFormula } = props
  return (
    <TextField
      label={label}
      fullWidth
      disabled
      title="Para editar, pressione o botão a direita"
      value={value}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              title="Fórmula do Evento"
              aria-label="Fórmula do Evento"
              onClick={() => openFormula(value)}
            >
              <PlaylistAddIcon color="primary" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
