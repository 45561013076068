import { useState } from 'react'

import { Box, Divider, Grid } from '@material-ui/core'

import { Stack } from '~/components'
import { AutoCompleteProcessoTrabalhistaVinculo } from '~/components/AutoComplete'

import TributosIRRF from './TributosIRRF'
import TributosPeriodos from './TributosPeriodos'

import { ProcessoTrabalhistaTributos } from '~/hooks/queries/ProcessoTrabalhistaTributos/dtos/ProcessoTrabalhistaTributos'
import { ProcessoTrabalhistaVinculo } from '~/hooks/queries/ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculo'

interface ContentProps {
  processoTrabalhistaTributos: ProcessoTrabalhistaTributos
}

export default function Content({ processoTrabalhistaTributos }: ContentProps) {
  const [processoTrabalhistaVinculo, setProcessoTrabalhistaVinculo] =
    useState<ProcessoTrabalhistaVinculo | null>(null)

  return (
    <Stack flex={1} minHeight={400} flexDirection="column">
      <Box mt={1} mb={1}>
        <Divider />
      </Box>
      <AutoCompleteProcessoTrabalhistaVinculo
        processoTrabalhistaId={processoTrabalhistaTributos.processoTrabalhistaId}
        onChange={(d) => {
          setProcessoTrabalhistaVinculo(d)
        }}
        value={processoTrabalhistaVinculo?.id || ''}
      />
      <Box flex={1}>
        <Grid container spacing={1} style={{ height: '100%' }}>
          <Grid item xs={12} sm={6}>
            <TributosPeriodos
              processoTrabalhistaTributosId={processoTrabalhistaTributos.id}
              processoTrabalhistaVinculoId={processoTrabalhistaVinculo?.id}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TributosIRRF
              processoTrabalhistaTributosId={processoTrabalhistaTributos.id}
              processoTrabalhistaVinculoId={processoTrabalhistaVinculo?.id}
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}
