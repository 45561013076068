import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { openDownloadData } from '~/hooks/useUtils'

import { notifySuccess } from '~/utils/notification'

const query = '/ImportacaoPlanilhaFaltas/GerarPlanilhas'

interface RequestProps {
  data: string[]
  params: { anoMes: string }
}

export function useGerarPlanilhas() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post(query, data, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    })
    openDownloadData('PLANILHAS-IMPORTACAO-FALTAS' + '.zip', response.data)
    notifySuccess('Planilhas geradas com sucesso')
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
