import React from 'react'

import { ActionDialog } from '~/components'

import VinculoRescisao from '~/pages/Funcionario/Container/VinculoRescisao'

interface DialogDadosRescisaoProps {
  isOpen: boolean
  vinculoId: string
  onClose: () => void
}

export default function DialogDadosRescisao(props: DialogDadosRescisaoProps) {
  const { isOpen, onClose, vinculoId } = props

  return (
    <ActionDialog
      title="Dados da Rescisão"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      customActions={<></>}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <VinculoRescisao vinculoId={vinculoId} onClose={onClose} />
    </ActionDialog>
  )
}
