import { Box, IconButton, makeStyles } from '@material-ui/core'
import {
  ChevronRight as NextIcon,
  ChevronLeft as PreviousIcon,
  FirstPage as FirstIcon,
  LastPage as LastIcon,
} from '@material-ui/icons'

import { VirtualizedMenuData } from '..'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    display: 'flex',
    justifyContent: 'space-around',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}))

interface MenuNavigatorProps {
  onChangeParent: (id: string, index: number) => void
  parentSelected: string | null
  data: VirtualizedMenuData[]
}

export default function MenuNavigator(props: MenuNavigatorProps) {
  const { onChangeParent, parentSelected, data } = props
  const classes = useStyles()

  const indexParentCurrent = data.findIndex((d) => d.id === parentSelected)
  const lastIndex = data.length - 1

  function changeNavigation(event: 'first' | 'previous' | 'next' | 'last') {
    function changeFirst() {
      if (parentSelected === data[0].id) return
      onChangeParent(data[0].id, 0)
    }

    function changePrevious() {
      const indexPrevious = indexParentCurrent - 1
      if (indexPrevious < 0) return
      onChangeParent(data[indexPrevious].id, indexPrevious)
    }

    function changeNext() {
      const indexNext = indexParentCurrent + 1
      if (indexNext > lastIndex) return
      onChangeParent(data[indexNext].id, indexNext)
    }

    function changeLast() {
      if (indexParentCurrent === lastIndex) return
      onChangeParent(data[lastIndex].id, lastIndex)
    }

    const functions = {
      first: changeFirst,
      previous: changePrevious,
      next: changeNext,
      last: changeLast,
    }

    functions[event]()
  }

  return (
    <Box className={classes.root}>
      <IconButton size="small" title="Primeiro Registro" onClick={() => changeNavigation('first')}>
        <FirstIcon fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        title="Registro Anterior"
        onClick={() => changeNavigation('previous')}
      >
        <PreviousIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" title="Próximo Registro" onClick={() => changeNavigation('next')}>
        <NextIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" title="Último Registro" onClick={() => changeNavigation('last')}>
        <LastIcon fontSize="small" />
      </IconButton>
    </Box>
  )
}
