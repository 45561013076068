import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell as MUITableCell,
  TableHead,
  TableCellProps,
  Box,
  Paper,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core'

import RowTable from './RowTable'

import { FolhaPontoDia } from '~/hooks/queries/FolhaPonto/dtos/FolhaPontoDia'

function TableCellHead(props: TableCellProps) {
  return <MUITableCell {...props} align="center" style={{ fontWeight: 'bold' }} />
}

interface TablePageProps {
  data: FolhaPontoDia[]
  tolerancia: string
  isLoading: boolean
  isFetching: boolean
}

export default function TablePage({ data, tolerancia, isLoading, isFetching }: TablePageProps) {
  return (
    <Box component={Paper} height="100%" display="flex" flexDirection="column">
      <Box height={4}>{isFetching && <LinearProgress />}</Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={5}>
          <CircularProgress size={70} />
        </Box>
      ) : (
        <Box flex={1} position="relative">
          <Box position="absolute" left={0} right={0} bottom={0} top={0}>
            <TableContainer component={Paper} style={{ height: '100%', overflow: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    style={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white' }}
                  >
                    <TableCellHead rowSpan={2} width={60}>
                      Dias Normais
                    </TableCellHead>
                    <TableCellHead colSpan={3}>1° Turno</TableCellHead>
                    <TableCellHead colSpan={3}>2° Turno</TableCellHead>
                    <TableCellHead colSpan={3}>Horas Extras</TableCellHead>
                    <TableCellHead colSpan={3}>Horas Noturnas</TableCellHead>
                    <TableCellHead colSpan={2}>Totalizador</TableCellHead>
                  </TableRow>
                  <TableRow
                    style={{ position: 'sticky', top: 30, zIndex: 1, backgroundColor: 'white' }}
                  >
                    <TableCellHead width={60}>Entrada</TableCellHead>
                    <TableCellHead width={60}>Saída</TableCellHead>
                    <TableCellHead width={60}> Horas Extras</TableCellHead>
                    <TableCellHead width={60}>Entrada</TableCellHead>
                    <TableCellHead width={60}>Saída</TableCellHead>
                    <TableCellHead width={60}>Horas Extras</TableCellHead>
                    <TableCellHead width={60}>Entrada</TableCellHead>
                    <TableCellHead width={60}>Saída</TableCellHead>
                    <TableCellHead width={60}>Horas</TableCellHead>
                    <TableCellHead width={60}>Entrada</TableCellHead>
                    <TableCellHead width={60}>Saída</TableCellHead>
                    <TableCellHead width={60}>Horas</TableCellHead>
                    <TableCellHead width={60}>Horas Extras</TableCellHead>
                    <TableCellHead width={60}>Horas Noturnas</TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((d) => (
                    <RowTable key={d.id} data={d} tolerancia={tolerancia} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </Box>
  )
}
