import React, { useState, useEffect, useCallback } from 'react'

import { ContentContainer, PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

const VinculoEmprestimo = () => {
  const [isLoadingEdit, setLoadingEdit] = useState(false)

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const [query, setQuery] = useState('')
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  const getCollection = useCallback(async () => {
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const response = await api.get('/VinculoEmprestimo')
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = async (id) => {
        setLoadingEdit(true)
        try {
          const response = await api.get(`/VinculoEmprestimo/${id}`)
          if (response.data.data) handleOpenForm(response.data.data)
        } catch (err) {
          dialogNotification.extractErrors(err)
        } finally {
          setLoadingEdit(false)
        }
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    // eslint-disable-next-line
    [collection],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))

    const itens = collection.itens

    try {
      await api.delete(`/VinculoEmprestimo/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [])

  const handleOpenForm = (data = {}) => {
    setForm((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  function handleAfterSubmitForm() {
    getCollection()
    handleCloseForm()
  }

  return (
    <ContentContainer>
      <PageHeader title="Empréstimo">
        <ButtonBox>
          <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>
      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        isFetching={isLoadingEdit}
        query={query}
        onItemClick={handleClickItem}
      />

      <Form
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </ContentContainer>
  )
}

export default VinculoEmprestimo
