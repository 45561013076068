import { useState } from 'react'

import { ContainerComponent } from '~/components'

import CadastroEstabelecimento from './components/CadastroEstabelecimento'
import EstabelecimentoSituacao from './components/EstabelecimentoSituacao'
import EstabelecimentoLembrete from './components/EstabelecimentoLembrete'
import EstabelecimentoObservacao from './components/EstabelecimentoObservacao'
import CNAB from './components/CNAB'
import FAP from './components/FAP'

const Container = (props) => {
  const { isOpen, onClose, data, anoMes, onAfterSubmitForm } = props

  const [wasModified, setWasModified] = useState(false)
  return (
    <ContainerComponent
      title="Cadastro de Estabelecimento"
      isOpen={isOpen}
      onClose={onClose}
      formWasModified={wasModified}
      tabs={[
        {
          component: (
            <CadastroEstabelecimento
              data={data}
              onAfterSubmitForm={onAfterSubmitForm}
              onClose={onClose}
              setWasModified={setWasModified}
            />
          ),
          label: 'Cadastro',
        },
        {
          component: <EstabelecimentoSituacao estabelecimentoId={data.id} anoMes={anoMes} />,
          disabled: !data?.id ? true : false,
          label: 'Situação Tributária',
        },
        {
          component: <EstabelecimentoLembrete estabelecimentoId={data.id} anoMes={anoMes} />,
          disabled: !data?.id ? true : false,
          label: 'Lembrete',
        },
        {
          component: <EstabelecimentoObservacao estabelecimentoId={data.id} anoMes={anoMes} />,
          disabled: !data?.id ? true : false,
          label: 'Observação',
        },
        {
          component: <CNAB estabelecimentoId={data.id} />,
          disabled: !data?.id ? true : false,
          label: 'CNAB',
        },
        {
          component: <FAP estabelecimentoId={data.id} anoMes={anoMes} />,
          disabled: !data?.id ? true : false,
          label: 'FAP',
        },
      ]}
    />
  )
}

export default Container
