import React, { useEffect, useState, useCallback, memo } from 'react'

import { Grid, Box, Collapse, IconButton, Paper, useTheme } from '@material-ui/core'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'

import {
  AnoMesTextField,
  Checkbox,
  Button,
  PageHeader,
  ButtonBox,
  Finder,
  DatePickerNew,
} from '~/components'
import { AutoCompleteGrupo, AutoCompleteContador, MUIAutoComplete } from '~/components/AutoComplete'

import Table from './components/Table'

import useUtils from '~/hooks/useUtils'
import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'
import moment from 'moment'

import * as yup from 'yup'

const schema = yup.object().shape({
  codigoSEFIP: yup.string().required('Informe o Código do Recolhimento'),
  modalidadeSEFIP: yup.string().required('Informe a Modalidade do Arquivo'),
  anoMes: yup.string().required('Informe o Ano/Mês'),
  contadorId: yup.string().required('Informe um Contador'),
})

const MemoTable = memo(Table)

const initialStateOfData = {
  codigoSEFIP: '115',
  modalidadeSEFIP: '0',
  anoMes: '',
  grupo: null,
  grupoId: '',
  contador: null,
  contadorId: '',
  isCompetencia13: null,
  dtGerarGuiaAtraso: null,
}

const Sefip = () => {
  const [data, setData] = useState(initialStateOfData)
  const [isLoadingGenerate, setLoadingGenerate] = useState(false)
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)
  const [selectedItems, setSelectedItems] = useState([])
  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes } = useAmbiente(false, true)
  const { openDownloadData } = useUtils()
  const theme = useTheme()

  const getContador = async () => {
    try {
      const response = await api.get('/contador')
      if (response.data.data) {
        return response.data.data[0]
      }
    } catch (error) {
      console.log(error)
    }
  }

  const resetDatas = useCallback(async () => {
    const contador = await getContador()
    initialStateOfData.anoMes = anoMes
    initialStateOfData.contador = contador
    initialStateOfData.contadorId = contador?.id
    setData(initialStateOfData)
    setCollection({
      isLoading: false,
      itens: [],
    })
  }, [anoMes])

  useEffect(() => {
    resetDatas()
  }, [resetDatas])

  const handleSubmit = (event) => {
    const handleGenerate = async () => {
      try {
        setLoadingGenerate(true)
        const ids = selectedItems.map((index) => collection.itens[index].id)
        const response = await api.post('/Sefip/GerarArquivo', ids, {
          params: {
            contadorId: data?.contadorId,
            codigoSEFIP: data?.codigoSEFIP,
            modalidadeSEFIP: data?.modalidadeSEFIP,
            anoMes: data?.anoMes,
            isCompetencia13: data?.isCompetencia13,
            dtGerarGuiaAtraso: data?.dtGerarGuiaAtraso,
          },
        })
        if (response.data) {
          openDownloadData('SEFIP.RE', response.data)
          notification.success('Registros processados com sucesso')
          resetDatas()
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingGenerate(false)
      }
    }

    const handleSearch = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))
      try {
        const response = await api.get(`Sefip/GetEstabelecimento`, {
          params: {
            codigoSEFIP: data?.codigoSEFIP,
            modalidadeSEFIP: data?.modalidadeSEFIP,
            anoMes: data?.anoMes,
            grupoId: data?.grupoId,
          },
        })
        if (response.data.data) {
          const newData = response.data.data
          setSelectedItems([])
          setCollection((oldState) => ({
            ...oldState,
            itens: newData,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setCollection((oldState) => ({
          ...oldState,
          isLoading: false,
        }))
      }
    }

    if (event === 'generate') {
      return handleGenerate()
    }
    if (event === 'search') {
      return handleSearch()
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <Box id="root-header">
        <PageHeader title="SEFIP">
          <ButtonBox>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
            <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
              {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
            </IconButton>
          </ButtonBox>
        </PageHeader>

        <Box component={Paper} padding={2}>
          <Collapse in={formCollapseIsOpen}>
            <Grid container spacing={1}>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <MUIAutoComplete
                  options={[
                    {
                      value: '115',
                      desc: '115 - Recolhimento ao FGTS e informações à Previdência Social',
                    },
                    {
                      value: '130',
                      desc: '130 - Recolhimento ao FGTS e informações à Previdência Social relativas ao trabalhador avulso portuário',
                    },
                    {
                      value: '135',
                      desc: '135 - Recolhimento ao FGTS e informações à Previdência Social relativas ao trabalhador avulso não portuário',
                    },
                    {
                      value: '145',
                      desc: '145 - Recolhimento ao FGTS de diferenças apuradas pela CAIXA',
                    },
                    {
                      value: '150',
                      desc: '150 - Recolhimento ao FGTS e informações à Previdência Social de empresa prestadora de serviços com cessão de mão-de-obra e empresa de trabalho temporário (Lei nº 6.019/74, regulamentado pelo Decreto 10.060/19), em relação aos empregados cedidos, ou de obra de construção civil – empreitada parcial',
                    },
                    {
                      value: '155',
                      desc: '155 - Recolhimento ao FGTS e informações à Previdência Social de obra de construção civil – empreitada total ou obra própria',
                    },
                    {
                      value: '211',
                      desc: '211 - Declaração para a Previdência Social de cooperativa de trabalho relativa aos contribuintes individuais cooperados que prestam serviços a tomadores',
                    },
                    {
                      value: '307',
                      desc: '307 - Recolhimento de Parcelamento do FGTS',
                    },
                    {
                      value: '317',
                      desc: '317 - Recolhimento de Parcelamento do FGTS de empresa com tomador de serviços',
                    },
                    {
                      value: '327',
                      desc: '327 - Recolhimento de Parcelamento de débito com o FGTS, priorizando os valores devidos aos trabalhadores',
                    },
                    {
                      value: '337',
                      desc: '337 - Recolhimento de Parcelamento de débito com o FGTS de empresas com tomador de serviços, priorizando os valores devidos aos trabalhadores',
                    },
                    {
                      value: '345',
                      desc: '345 - Recolhimento de parcelamento de débito com o FGTS relativo à diferença de recolhimento, priorizando os valores devidos aos trabalhadores',
                    },
                    {
                      value: '418',
                      desc: '418 - Recolhimento recursal para o FGTS',
                    },
                    {
                      value: '604',
                      desc: '604 - Recolhimento ao FGTS de entidades com fins filantrópicos – Decreto-Lei n° 194, de 24/02/1967 (competências anteriores a 10/1989',
                    },
                    {
                      value: '608',
                      desc: '608 - Recolhimento ao FGTS e informações à Previdência Social relativos a dirigente sindical',
                    },
                    {
                      value: '640',
                      desc: '640 - Recolhimento ao FGTS para empregado não optante (competência anterior a 10/1988',
                    },
                    {
                      value: '650',
                      desc: '650 - Recolhimento ao FGTS e informações à Previdência Social relativo a Anistiados, Reclamatória Trabalhista, Reclamatória Trabalhista com Reconhecimento de Vínculo, Acordo, Dissídio ou Convenção Coletiva, Comissão de Conciliação Prévia ou Núcleo Intersindical de Conciliação Trabalhista',
                    },
                    {
                      value: '660',
                      desc: '660 - Recolhimento exclusivo ao FGTS relativo a Anistiados, Conversão de Licença Saúde em Acidente de Trabalho, Reclamatória Trabalhista, Acordo, Dissídio ou Convenção Coletiva, Comissão de Conciliação Prévia ou Núcleo Intersindical de Conciliação Trabalhista',
                    },
                  ]}
                  optionId="value"
                  renderOption={(option) => option.desc}
                  label="Código do Recolhimento"
                  required
                  name="codigoSEFIP"
                  validationErrors={validationErrors}
                  value={data?.codigoSEFIP || ''}
                  onChange={(e, obj) => {
                    const codigoSEFIP = obj?.value || ''
                    setData((oldState) => ({ ...oldState, codigoSEFIP }))
                  }}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <MUIAutoComplete
                  options={[
                    {
                      value: '0',
                      desc: 'Recolhimento ao FGTS e Declaração à Previdência',
                    },
                    { value: '1', desc: 'Declaração ao FGTS e à Previdência' },
                    {
                      value: '9',
                      desc: 'Confirmação inf.anteriores - Rec/Decl ao FGTS e Dec.à Previdência',
                    },
                  ]}
                  optionId="value"
                  renderOption={(option) => option.desc}
                  label="Modalidade do Arquivo"
                  required
                  name="modalidadeSEFIP"
                  validationErrors={validationErrors}
                  value={data?.modalidadeSEFIP || ''}
                  onChange={(e, obj) => {
                    const modalidadeSEFIP = obj?.value || ''
                    setData((oldState) => ({ ...oldState, modalidadeSEFIP }))
                  }}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <Box display="flex" gridGap={theme.spacing(1)}>
                  <AnoMesTextField
                    label="Ano/Mês"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    name="anoMes"
                    validationErrors={validationErrors}
                    value={data?.anoMes || ''}
                    onChange={(e) => {
                      const anoMes = e?.target?.value ? e.target.value.replace('/', '') : ''
                      setData((oldState) => ({ ...oldState, anoMes }))
                    }}
                  />

                  {moment(data?.anoMes).month() === 11 && (
                    <Box width={250}>
                      <Checkbox
                        label="Competência 13"
                        value={data.isCompetencia13}
                        onChange={(e, obj) => {
                          const isCompetencia13 = obj
                          setData((oldState) => ({
                            ...oldState,
                            isCompetencia13,
                          }))
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <AutoCompleteContador
                  required
                  validationErrors={validationErrors}
                  name="contadorId"
                  value={data?.contador || null}
                  onChange={(e, contador) => {
                    const contadorId = contador?.id || ''
                    setData((oldState) => ({
                      ...oldState,
                      contador,
                      contadorId,
                    }))
                  }}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <AutoCompleteGrupo
                  fullWidth
                  value={data?.grupo || null}
                  onChange={(e, grupo) => {
                    const grupoId = grupo?.id || ''
                    setData((oldState) => ({ ...oldState, grupo, grupoId }))
                  }}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <DatePickerNew
                  label="Data Gerar em Atraso"
                  value={data.dtGerarGuiaAtraso}
                  onChange={(date) => setData((prev) => ({ ...prev, dtGerarGuiaAtraso: date }))}
                />
              </Grid>
            </Grid>
            <ButtonBox top={1}>
              <Button
                onClick={() => handleValidate('search')}
                isLoading={collection.isLoading}
                variant="contained"
              >
                Buscar
              </Button>
            </ButtonBox>
          </Collapse>
        </Box>
      </Box>

      <Box flex={1} position="relative" overflow="auto">
        <MemoTable
          data={collection.itens}
          isLoading={collection.isLoading}
          onRowSelected={setSelectedItems}
          rowsSelecteds={selectedItems}
          query={query}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          size="small"
          color="primary"
          onClick={() => handleValidate('generate')}
          isLoading={isLoadingGenerate}
          variant="contained"
          disabled={selectedItems.length > 0 ? false : true}
        >
          Gerar Arquivo
        </Button>
      </Box>
    </Box>
  )
}

export default Sefip
