import React from 'react'

import { formatToBRL } from 'brazilian-values'
import moment from 'moment'

import { ButtonBox } from 'mio-library-ui'

import { Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'

import {
  IndSalarioContratualValues,
  IndSalarioContratualEnum,
} from '~/@types/enums/IndSalarioContratualEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoSalarioBasePartial } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'

function formatIndSalario(indSalario: IndSalarioContratualEnum) {
  const objectFinded = IndSalarioContratualValues.find((obj) => obj.value === indSalario)
  return objectFinded?.name || ''
}

interface TableProps {
  data: VinculoSalarioBasePartial[]
  onItemClick: (index: number) => void
}

export default function Table(props: TableProps) {
  const { data, onItemClick } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtSalario',
      label: 'Data Salário',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'dtEfetiva',
      label: 'Data Efetiva',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
    },
    {
      name: 'indSalario',
      label: 'Indicador do Salário',
      options: {
        customBodyRender: (value) => (value ? formatIndSalario(value) : ''),
      },
    },
    {
      name: 'quantidade',
      label: 'Quantidade',
    },
    {
      name: 'vrSalario',
      label: 'Valor',
      options: {
        customBodyRender: (value) =>
          value ? <ButtonBox>{formatToBRL(value).replace('R$ ', '')}</ButtonBox> : '',
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => onItemClick(rowIndex)}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} />
}
