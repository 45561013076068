import { ToolsDataTable } from '~/components'

import { IndIncidenciaFGTSValues } from '~/@types/enums/IndIncidenciaFGTSEnum'
import { IndValorFGTSValues } from '~/@types/enums/IndValorFGTSEnum'

import useAmbiente from '~/hooks/useAmbiente'
import { formatCurrency } from '~/hooks/useUtils'

import useObterFGTSAnaliticoVinculo from '~/hooks/queries/ApurarESocial/useObterFGTSAnaliticoVinculo'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface FuncionarioAnaliticoProps {
  vinculoId: string
}

export function FuncionarioAnalitico({ vinculoId }: FuncionarioAnaliticoProps) {
  const { anoMes } = useAmbiente()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterFGTSAnaliticoVinculo({
    vinculoId,
    periodoApuracao: anoMes,
  })

  const _data = _d || []

  const data = _data.map((d) => {
    return {
      ...d,
      indIncidencia: d?.indIncidencia
        ? IndIncidenciaFGTSValues.find((x) => x.value == d.indIncidencia)?.name || ''
        : '',
      tpValor: d?.tpValor ? IndValorFGTSValues.find((x) => x.value == d.tpValor)?.name || '' : '',
      vrBaseFGTS: d?.vrBaseFGTS ? formatCurrency(d.vrBaseFGTS) : '',
      vrFGTS: d?.vrFGTS ? formatCurrency(d.vrFGTS) : '',
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'calculo',
      label: 'Cálculo',
    },
    {
      name: 'tpValor',
      label: 'Tipo Valor',
    },
    {
      name: 'indIncidencia',
      label: 'Incidência',
    },
    {
      name: 'vrBaseFGTS',
      label: 'Base FGTS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrFGTS',
      label: 'FGTS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      pagination={false}
      disableAutoHeight
    />
  )
}
