import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

const Observacao = (props) => {
  const { isOpen, onClose, reciboPagamento, onSubmitFormObservacao } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(reciboPagamento)
  }, [reciboPagamento])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        await api.put(`/RP/${data?.id}`, data)
        onSubmitFormObservacao()
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    update()
    //eslint-disable-next-line
  }, [data])

  return (
    <ActionDialog
      title="Observação Recibo de Pagamento"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <TextField
            label="Observação"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.observacao || ''}
            rows={4}
            multiline
            inputProps={{ maxLength: 300 }}
            onChange={(e) => {
              const observacao = e.target.value
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Observacao
