import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import { useStepperContext } from '~/components/StepperForm'

import { queryUseObterEventosTransmissaoAcompanhamento } from './useObterEventosTransmissaoAcompanhamento'
import { queryUseObterTotalEventosGrupoAcompanhamento } from './useObterTotalEventosGrupoAcompanhamento'

import { DataContextProps } from '~/pages/TransmissaoESocialNew'

export function useTransmitirEventosESocial() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const { dataControlled: dataContext } = useStepperContext<DataContextProps>()

  async function handleRequest(auditoriasIds: string[]) {
    await api.post('/ESocial/TransmitirEventosESocial', auditoriasIds)
    dataContext.changeEnvioConsulta && dataContext.changeEnvioConsulta(1)
    notification.post()
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryUseObterEventosTransmissaoAcompanhamento)
      await queryClient.invalidateQueries(queryUseObterTotalEventosGrupoAcompanhamento)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
