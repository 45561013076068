import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, AnoMesTextField, CurrencyTextField } from '~/components'
import { AutoCompletePessoaFisicaDependente } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { useCriar } from '~/hooks/queries/PlanoSaudeDependente/useCriar'
import { useAtualizar } from '~/hooks/queries/PlanoSaudeDependente/useAtualizar'

import { PlanoSaudeDependente } from '~/hooks/queries/PlanoSaudeDependente/dtos/PlanoSaudeDependente'

const schema = yup.object({
  pessoaFisicaDependenteId: yup.string().required('Informe o Dependente').nullable(),
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início').nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  initialData: PlanoSaudeDependente
  pessoaFisicaId: string
}

export default function Form({ isOpen, onClose, initialData, pessoaFisicaId }: FormProps) {
  const [data, setData] = useState(initialData)

  const { anoMes } = useAmbiente()

  const isNovaData = initialData.id ? false : true

  const { mutateAsync: mutateAsyncCreating, isLoading: isCreating } = useCriar()
  const { mutateAsync: mutateAsyncUpdating, isLoading: isUpdating } = useAtualizar()

  const isSubmitting = isCreating || isUpdating

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    if (isNovaData) {
      await mutateAsyncCreating({
        data,
        params: {
          anoMes,
        },
      })
    } else {
      await mutateAsyncUpdating({
        id: data.id,
        data,
        params: {
          anoMes,
        },
      })
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Plano de Saúde Dependente"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutoCompletePessoaFisicaDependente
            required
            validationErrors={validationErrors}
            name="pessoaFisicaDependenteId"
            value={data.pessoaFisicaDependenteId}
            onChange={(e, d) => {
              const pessoaFisicaDependenteId = d?.id || ''
              setData((prev) => ({
                ...prev,
                pessoaFisicaDependenteId,
              }))
            }}
            pessoaFisicaId={pessoaFisicaId}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AnoMesTextField
            required
            validationErrors={validationErrors}
            name="anoMesInicio"
            label="Ano/Mês Início"
            value={data.anoMesInicio}
            onChange={(e) => {
              const anoMesInicio = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, anoMesInicio }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AnoMesTextField
            label="Ano/Mês Fim"
            value={data.anoMesFim}
            onChange={(e) => {
              const anoMesFim = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, anoMesFim }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Valor da Mensalidade"
            value={data.vrMensalidade}
            onChange={(_, value) => {
              const vrMensalidade = value
              setData((oldState) => ({
                ...oldState,
                vrMensalidade,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
