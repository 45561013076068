import { convertToArrayDescription } from '~/utils/utils'

export enum TipoAssinaturaEnum {
  ProprioPunho,
  InformarData,
  Admissao,
}

export const TipoAssinaturaLabel = {
  [TipoAssinaturaEnum.ProprioPunho]: 'Próprio punho',
  [TipoAssinaturaEnum.InformarData]: 'Informar data manual',
  [TipoAssinaturaEnum.Admissao]: 'Admissão',
}

export const TipoAssinaturaValues = convertToArrayDescription(
  TipoAssinaturaEnum,
  TipoAssinaturaLabel,
)
