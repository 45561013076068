const naoInformado_0 = 0
const estabelecimentoEmpregadorBrasil_1 = 1
const estabelecimentoEmpregadorExterior_2 = 2
const estabelecimentoTerceirosPrestadorServicos_3 = 3
const viaPublica_4 = 4
const areaRural_5 = 5
const embarcacao_6 = 6
const outros_9 = 9

export const CATTipoLocalValues = [
  { value: naoInformado_0, name: '0 - Não informar' },
  {
    value: estabelecimentoEmpregadorBrasil_1,
    name: '1 - Estabelecimento do empregador no Brasil',
  },
  {
    value: estabelecimentoEmpregadorExterior_2,
    name: '2 - Estabelecimento do empregador no exterior',
  },
  {
    value: estabelecimentoTerceirosPrestadorServicos_3,
    name: '3 - Estabelecimento de terceiros onde o empregador presta serviços',
  },
  { value: viaPublica_4, name: '4 - Via pública' },
  { value: areaRural_5, name: '5 - Área rural' },
  { value: embarcacao_6, name: '6 - Embarcação' },
  { value: outros_9, name: '9 - Outros' },
]

export const CATTipoLocalConsts = {
  naoInformado_0,
  estabelecimentoEmpregadorBrasil_1,
  estabelecimentoEmpregadorExterior_2,
  estabelecimentoTerceirosPrestadorServicos_3,
  viaPublica_4,
  areaRural_5,
  embarcacao_6,
  outros_9,
}
