import React, { useEffect, useState } from 'react'

import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons'

export default function SearchTextFieldDelay(props) {
  const { onChange } = props

  const [query, setQuery] = useState('')

  function handleChange(e) {
    const queryFormated = e?.target?.value || ''
    setQuery(queryFormated)
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => onChange(query), 300)
    return () => clearTimeout(timeOutId)
    // eslint-disable-next-line
  }, [query])

  function handleClickClose() {
    onChange('')
    setQuery('')
  }

  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      value={query}
      placeholder="Procurar"
      onChange={handleChange}
      autoFocus
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton size="small" position="end" onClick={handleClickClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  )
}
