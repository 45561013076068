import { useState } from 'react'

import { Box, useTheme } from '@material-ui/core'

import { Button, ButtonBox, ConfirmDeleteDialog, ContentDivider, Finder } from '~/components'

import Form from './Form'
import Table from './Table'

import useNotification from '~/hooks/useNotification'
import { orderBy } from '~/hooks/useUtils'
import useDialog from '~/hooks/useDialog'

import { VinculoHorarioItem } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'

interface DiasSemanaProps {
  data: VinculoHorarioItem[]
  onSubmit: (data: VinculoHorarioItem[]) => void
}
export default function DiasSemana(props: DiasSemanaProps) {
  const { data, onSubmit } = props

  const [query, setQuery] = useState('')

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string | null>(null)

  const theme = useTheme()
  const notification = useNotification()

  const { close: closeForm, isOpen: isOpenForm, open: openForm } = useDialog(null)

  function handleClickAdd() {
    openForm(null)
  }

  function handleClickItem(event: 'delete', id: string) {
    switch (event) {
      case 'delete':
        openConfirmDelete(id)
        break
    }
  }

  function handleDeleteItem(id: string) {
    onSubmit(data.filter((d) => d.id !== id))
    closeConfirmDelete()
    notification.remove()
  }

  function afterSubmitForm(event: 'insert', data: VinculoHorarioItem[]) {
    switch (event) {
      case 'insert':
        handleInsert(data)
        break
    }
  }

  function handleInsert(d: VinculoHorarioItem[]) {
    const diasToRemove = d.map((x) => x.diaHorario)
    const newArray = [...data.filter((x) => !diasToRemove.includes(x.diaHorario))]
    newArray.push(...d)
    onSubmit(orderBy(newArray, 'diaHorario'))
    closeForm()
  }

  return (
    <Box height="100%" padding={1} display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <ContentDivider
        title="Dias da Semana"
        renderRight={
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <Button size="small" color="primary" onClick={handleClickAdd} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        }
      />

      <Box minHeight={200} flex={1} overflow="auto" position="relative">
        <Box position="absolute" width="100%">
          <Table data={data} query={query} onItemClick={handleClickItem} />
        </Box>
      </Box>

      {isOpenForm ? (
        <Form isOpen={isOpenForm} onClose={closeForm} afterSubmit={afterSubmitForm} />
      ) : (
        <></>
      )}

      {isOpenConfirmDelete && dataConfirmDelete ? (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={() => handleDeleteItem(dataConfirmDelete)}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
