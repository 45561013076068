import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, Button, Finder, ButtonBox } from 'mio-library-ui'

import Table from './components/Table'
import Form from './components/Form'

import { ConfirmDeleteDialog, ActionDialog } from '~/components'

import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'
import { useEmpregadorMutate } from '~/hooks/queries/useEmpregador'

function EmpregadorSST() {
  const [query, setQuery] = useState('')

  const { close: closeForm, data: dataForm, isOpen: isOpenForm, open: openForm } = useDialog(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog(null)
  const { mutateDelete } = useEmpregadorMutate()
  const notification = useNotification()

  function handleClickItem(event, value) {
    const handleClickEditItem = async (id) => {
      openForm(id)
    }

    const handleClickDeleteItem = (id) => {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  async function handleConfirmDelete() {
    await mutateDelete.mutateAsync(dataConfirmDelete)
    closeConfirmDelete()
    notification.remove()
  }

  return (
    <Box padding={2}>
      <Box height={50}>
        <PageHeader title="Empregador">
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <Button size="small" color="primary" onClick={() => openForm(null)} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Table query={query} onItemClick={handleClickItem} />

      <ActionDialog
        title="Cadastro de Empregador"
        isOpen={isOpenForm}
        onClose={closeForm}
        onCancelClick={closeForm}
        customActions={<></>}
        dialogProps={{
          maxWidth: 'xs',
          fullWidth: true,
        }}
      >
        <Form empregadorId={dataForm} onClose={closeForm} />
      </ActionDialog>

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={mutateDelete.isLoading}
        onCancel={closeConfirmDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  )
}

export default EmpregadorSST
