const contratoNaoTempoParcial_0 = 0
const limitado_25_HorasSemanais_1 = 1
const limitado_30_HorasSemanais_2 = 2
const limitado_26_HorasSemanais_3 = 3

export const jornadaTempoParcialValues = [
  { value: contratoNaoTempoParcial_0, name: '0 - Não é jornada Parcial' },
  {
    value: limitado_25_HorasSemanais_1,
    name: '1 - Limitada a 25 horas semanais (Somente Domésticas)',
  },
  {
    value: limitado_30_HorasSemanais_2,
    name: '2 - Limitada a 30 horas semanais',
  },
  {
    value: limitado_26_HorasSemanais_3,
    name: '3 - Limitada a 26 horas semanais',
  },
]
export const jornadaTempoParcialConsts = {
  contratoNaoTempoParcial_0,
  limitado_25_HorasSemanais_1,
  limitado_30_HorasSemanais_2,
  limitado_26_HorasSemanais_3,
}
