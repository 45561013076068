import React, { useState, useEffect, useCallback } from 'react'

import { Box, Divider, Grid, makeStyles, Tooltip } from '@material-ui/core'
import { ContentDivider, TextField } from 'mio-library-ui'
import { ActionDialog, Checkbox, CurrencyTextField, SimpleSelect } from '~/components'

import EPI from './components/EPI'

import { MUIAutoComplete, AutoCompleteAgenteNocivo } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import { agenteNocivoUnidadeMedidaValues } from '~/values/agenteNocivoUnidadeMedidaValues'
import {
  agenteNocivoTipoAvaliacaoConsts,
  agenteNocivoTipoAvaliacaoValues,
} from '~/values/agenteNocivoTipoAvaliacaoValues'
import { simNaoConsts, simNaoBool } from '~/values/simNaoValues'
import { EPIsEPCsUtilizaConts, EPIsEPCsUtilizaValues } from '~/values/EPIsEPCsUtilizaValues'

export const schema = yup.object().shape({
  agenteNocivoId: yup.string().required('Informe o Agente Nocivo').nullable(),
  tipoAvaliacao: yup.string().required('Informe o Tipo de Avaliação'),
  intensidadeConcentracao: yup
    .string()
    .when(['tipoAvaliacao'], (tipoAvaliacao, schema) => {
      if (parseInt(tipoAvaliacao) === agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1)
        return schema.required('Informe a Intensidade da Concentração').nullable()
    })
    .nullable(),
  tecnicaMedicao: yup
    .string()
    .when(['tipoAvaliacao'], (tipoAvaliacao, schema) => {
      if (parseInt(tipoAvaliacao) === agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1)
        return schema.required('Informe a Técnica de Medição').nullable()
    })
    .nullable(),
  limiteTolerancia: yup
    .string()
    .when(['tipoAvaliacao', 'agenteNocivoId'], (tipoAvaliacao, agenteNocivoId, schema) => {
      if (
        parseInt(tipoAvaliacao) === agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1 &&
        (agenteNocivoId === '01.18.001' || agenteNocivoId === '02.01.014')
      )
        return schema.required('Informe o Limite da Tolerância').nullable()
    })
    .nullable(),
  unidadeMedida: yup
    .string()
    .when(['tipoAvaliacao'], (tipoAvaliacao, schema, { value: unidadeMedida }) => {
      const parsedTipoAvaliacao = parseInt(tipoAvaliacao)
      const parsedUnidadeMedida = parseInt(unidadeMedida)

      if (
        parsedUnidadeMedida === 0 &&
        parsedTipoAvaliacao === agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1
      ) {
        return schema.test(
          'UnidadeMedida-validator',
          'A Unidade de Medida deve possuir um valor válido',
          () => parsedUnidadeMedida !== 0,
        )
      }

      if (parsedTipoAvaliacao === agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1) {
        return schema.required('Informe a Unidade de Medida')
      }

      return schema
    })
    .nullable(),
})

export function FormAgenteNocivo(props) {
  const { data, setData, epi, validationErrors } = props

  function handleChangeAgenteNocivo(_, agenteNocivo) {
    const agenteNocivoId = agenteNocivo?.id || ''
    setData((oldState) => ({
      ...oldState,
      agenteNocivoId,
      agenteNocivo,
      limiteTolerancia: '',
    }))

    if (agenteNocivoId === '09.01.001') {
      setData((oldState) => ({
        ...oldState,
        tipoAvaliacao: agenteNocivoTipoAvaliacaoConsts.naoPreencher_0,
      }))
    }
  }

  const isHaveAgenteNocivo = data?.agenteNocivoId !== '09.01.001'

  return (
    <Box height="100%" p={1}>
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <Tooltip
            arrow
            placement="top-start"
            title="Agente(s) nocivo(s) ao(s) qual(is) o trabalhador está
            exposto."
          >
            <Box>
              <ContentDivider title="Agente(s) nocivo(s)" />
            </Box>
          </Tooltip>
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <AutoCompleteAgenteNocivo
            required
            validationErrors={validationErrors}
            name="agenteNocivoId"
            value={data?.agenteNocivo || null}
            onChange={handleChangeAgenteNocivo}
          />
        </Grid>
        {isHaveAgenteNocivo && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                <MUIAutoComplete
                  name="tipoAvaliacao"
                  label="Tipo de Avaliação"
                  options={agenteNocivoTipoAvaliacaoValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  required={data?.agenteNocivoId !== '09.01.001'}
                  disabled={data?.agenteNocivoId === '09.01.001'}
                  validationErrors={validationErrors}
                  value={data.tipoAvaliacao}
                  onChange={(e, obj) => {
                    const tipoAvaliacao =
                      obj?.value || agenteNocivoTipoAvaliacaoConsts.naoPreencher_0
                    setData((oldState) => ({
                      ...oldState,
                      tipoAvaliacao,
                      intensidadeConcentracao: '',
                      limiteTolerancia: '',
                      tecnicaMedicao: '',
                      unidadeMedida: 0,
                    }))
                  }}
                />
              </Grid>
              <Grid xl={3} lg={3} md={3} sm={6} xs={6} item>
                <CurrencyTextField
                  label="Intensidade da Concentração"
                  maximumValue="9999999999"
                  required={
                    data?.tipoAvaliacao === agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1
                      ? true
                      : false
                  }
                  disabled={
                    data?.tipoAvaliacao !== agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1
                  }
                  validationErrors={validationErrors}
                  name="intensidadeConcentracao"
                  value={data?.intensidadeConcentracao || ''}
                  onChange={(e, value) => {
                    const intensidadeConcentracao = value
                    setData((oldState) => ({
                      ...oldState,
                      intensidadeConcentracao,
                    }))
                  }}
                />
              </Grid>
              <Grid xl={3} lg={3} md={3} sm={6} xs={6} item>
                <TextField
                  fullWidth
                  onlyNumber
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 2 }}
                  label="Limite da Tolerância"
                  required={
                    data?.tipoAvaliacao ===
                      agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1 &&
                    (data?.agenteNocivoId === '01.18.001' || data?.agenteNocivoId === '02.01.014')
                      ? true
                      : false
                  }
                  disabled={
                    data?.tipoAvaliacao !==
                      agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1 ||
                    (data?.agenteNocivoId !== '01.18.001' && data?.agenteNocivoId !== '02.01.014')
                  }
                  validationErrors={validationErrors}
                  name="limiteTolerancia"
                  value={data?.limiteTolerancia || ''}
                  onChange={(e) => {
                    const limiteTolerancia = e?.target?.value || ''
                    setData((oldState) => ({
                      ...oldState,
                      limiteTolerancia,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={12} lg={12} md={12} sm={6} xs={12} item>
                <Tooltip
                  arrow
                  placement="bottom-start"
                  title="Técnica utilizada para medição da intensidade ou
              concentração."
                >
                  <Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 40 }}
                      label="Técnica de Medição"
                      required={
                        data?.tipoAvaliacao ===
                        agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1
                          ? true
                          : false
                      }
                      disabled={
                        data?.tipoAvaliacao !==
                        agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1
                      }
                      validationErrors={validationErrors}
                      name="tecnicaMedicao"
                      value={data?.tecnicaMedicao || ''}
                      onChange={(e) => {
                        const tecnicaMedicao = e?.target?.value || ''
                        setData((oldState) => ({
                          ...oldState,
                          tecnicaMedicao,
                        }))
                      }}
                    />
                  </Box>
                </Tooltip>
              </Grid>

              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                <MUIAutoComplete
                  options={agenteNocivoUnidadeMedidaValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  label="Unidade de Medida"
                  required={
                    data?.tipoAvaliacao === agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1
                  }
                  disabled={
                    data?.tipoAvaliacao !== agenteNocivoTipoAvaliacaoConsts.criterioQuantitativo_1
                  }
                  validationErrors={validationErrors}
                  name="unidadeMedida"
                  value={data.unidadeMedida}
                  onChange={(e, obj) => {
                    const unidadeMedida = obj ? obj.value : ''
                    setData((oldState) => ({
                      ...oldState,
                      unidadeMedida,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                <Tooltip
                  arrow
                  placement="top-start"
                  title="Informações relativas a Equipamentos de Proteção Coletiva
                - EPC e Equipamentos de Proteção Individual - EPI."
                >
                  <Box>
                    <ContentDivider
                      top={1}
                      bottom={1}
                      title="Informações relativas a Equipamentos de Proteção"
                    />
                  </Box>
                </Tooltip>
              </Grid>
              <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
                <MUIAutoComplete
                  label="Utiliza EPC"
                  value={data?.utilizaEPC || EPIsEPCsUtilizaConts.EPIEPCNaoSeAplica}
                  options={EPIsEPCsUtilizaValues}
                  renderOption={(opt) => opt.name}
                  optionId="value"
                  onChange={(e, obj) => {
                    const utilizaEPC = obj?.value || EPIsEPCsUtilizaConts.EPIEPCNaoSeAplica
                    setData((oldState) => ({
                      ...oldState,
                      utilizaEPC,
                    }))
                    if (utilizaEPC === EPIsEPCsUtilizaConts.EPIEPCImplementa) {
                      setData((oldState) => ({
                        ...oldState,
                        isEficienciaEPC: simNaoConsts.sim_true,
                      }))
                    } else {
                      setData((oldState) => ({
                        ...oldState,
                        isEficienciaEPC: simNaoConsts.nao_false,
                      }))
                    }
                  }}
                />
              </Grid>
              <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
                <SimpleSelect
                  label="EPC são Eficazes"
                  options={simNaoBool}
                  renderOption={(opt) => opt.name}
                  optionId="value"
                  disabled={data?.utilizaEPC !== EPIsEPCsUtilizaConts.EPIEPCImplementa}
                  value={data.isEficienciaEPC}
                  onChange={(_, isEficienciaEPC) => {
                    setData((oldState) => ({
                      ...oldState,
                      isEficienciaEPC,
                    }))
                  }}
                />
              </Grid>
              <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
                <MUIAutoComplete
                  label="Utiliza EPI"
                  value={data?.utilizaEPI || EPIsEPCsUtilizaConts.EPIEPCNaoSeAplica}
                  options={EPIsEPCsUtilizaValues}
                  renderOption={(opt) => opt.name}
                  optionId="value"
                  onChange={(e, obj) => {
                    const utilizaEPI = obj?.value || EPIsEPCsUtilizaConts.EPIEPCNaoSeAplica
                    setData((oldState) => ({
                      ...oldState,
                      utilizaEPI,
                    }))

                    if (utilizaEPI === EPIsEPCsUtilizaConts.EPIEPCImplementa) {
                      setData((oldState) => ({
                        ...oldState,
                        isEficienciaEPI: simNaoConsts.sim_true,
                      }))
                    } else {
                      setData((oldState) => ({
                        ...oldState,
                        isEficienciaEPI: simNaoConsts.nao_false,
                      }))
                    }
                  }}
                />
              </Grid>
              <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
                <SimpleSelect
                  label="EPI são Eficazes"
                  options={simNaoBool}
                  renderOption={(opt) => opt.name}
                  optionId="value"
                  disabled={data?.utilizaEPI !== EPIsEPCsUtilizaConts.EPIEPCImplementa}
                  value={data.isEficienciaEPI}
                  onChange={(_, isEficienciaEPI) => {
                    setData((oldState) => ({
                      ...oldState,
                      isEficienciaEPI,
                    }))
                  }}
                />
              </Grid>

              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                <Tooltip
                  arrow
                  placement="top-start"
                  title="Requisitos da Norma Regulamentadora 06 - NR-06 e da
            Norma Regulamentadora 09 - NR-09 pelo(s) EPI(s)
            informado(s)."
                >
                  <Box>
                    <ContentDivider top={1} title="Requisitos da Norma Regulamentadora" />
                  </Box>
                </Tooltip>
              </Grid>

              <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
                <Tooltip
                  arrow
                  title="Foi tentada a implementação de medidas de proteção
            coletiva, de caráter administrativo ou de organização,
            optando-se pelo EPI por inviabilidade técnica, insuficiência
            ou interinidade, ou ainda em caráter complementar ou
            emergencial?"
                >
                  <Checkbox
                    label="Medida de Proteção"
                    value={data?.isMedidaProtecao || false}
                    onChange={(e, value) => {
                      const isMedidaProtecao = value
                      setData({ ...data, isMedidaProtecao })
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
                <Tooltip
                  arrow
                  title="Foram observadas as condições de funcionamento do EPI
            ao longo do tempo, conforme especificação técnica do
            fabricante nacional ou importador, ajustadas às condições
            de campo?"
                >
                  <Checkbox
                    label="Condição de Funcionamento"
                    value={data?.isCondicaoFuncionamento || false}
                    onChange={(e, value) => {
                      const isCondicaoFuncionamento = value
                      setData({ ...data, isCondicaoFuncionamento })
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
                <Tooltip
                  arrow
                  title="Foi observado o uso ininterrupto do EPI ao longo do 
            tempo, conforme especificação técnica do fabricante
            nacional ou importador, ajustadas às condições de campo?"
                >
                  <Checkbox
                    label="Uso ininterrupto"
                    value={data?.isUsoIninterrupto || false}
                    onChange={(e, value) => {
                      const isUsoIninterrupto = value
                      setData({ ...data, isUsoIninterrupto })
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
                <Tooltip
                  arrow
                  title="Foi observado o prazo de validade do CA no momento da compra do EPI?"
                >
                  <Checkbox
                    label="Prazo de Validade"
                    value={data?.isPrazoValidade || false}
                    onChange={(e, value) => {
                      const isPrazoValidade = value
                      setData({ ...data, isPrazoValidade })
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
                <Tooltip
                  arrow
                  title="É observada a periodicidade de troca definida pelo
            fabricante nacional ou importador e/ou programas
            ambientais, comprovada mediante recibo assinado pelo
            usuário em época própria?"
                >
                  <Checkbox
                    label="Periodicidade Troca"
                    value={data?.isPeriodicidadeTroca || false}
                    onChange={(e, value) => {
                      const isPeriodicidadeTroca = value
                      setData({ ...data, isPeriodicidadeTroca })
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid xl={3} lg={3} md={4} sm={4} xs={6} item>
                <Tooltip
                  arrow
                  title="É observada a higienização conforme orientação do
            fabricante nacional ou importador?"
                >
                  <Checkbox
                    label="Higienização"
                    value={data?.isHigienizacao || false}
                    onChange={(e, value) => {
                      const isHigienizacao = value
                      setData({ ...data, isHigienizacao })
                    }}
                  />
                </Tooltip>
              </Grid>

              {data?.id && epi && (
                <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                  <Divider />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {data?.id && isHaveAgenteNocivo ? epi : <></>}
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '100%',
  },
}))

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/CondicaoAmbienteTrabalhoAgenteNocivo/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/CondicaoAmbienteTrabalhoAgenteNocivo', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const isHaveAgenteNocivo = data?.agenteNocivoId !== '09.01.001'

  return (
    <ActionDialog
      title="Cadastro de Agente Nocivo"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: {
          paper: data?.id && isHaveAgenteNocivo ? classes.heightPaper : undefined,
        },
      }}
    >
      <FormAgenteNocivo
        data={data}
        setData={setData}
        validationErrors={validationErrors}
        epi={
          <EPI condicaoAmbienteTrabalhoAgenteNocivoId={data.id} agenteNocivo={data?.agenteNocivo} />
        }
      />
    </ActionDialog>
  )
}

export default Form
