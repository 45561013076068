import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../../useDialogNotification'

interface PessoaFisicaObterUsoPessoaFisica {
  nome: string
  nrInscricao: string
  dtAdmissao: string
  dtRescisao: string | null
}

export function usePessoaFisicaObterUsoPessoaFisica(pessoaFisicaId: string) {
  const dialogNotification = useDialogNotification()

  async function obterUsoPessoaFisica() {
    const response = await api.get<{ data: PessoaFisicaObterUsoPessoaFisica[] }>(
      '/PessoaFisica/ObterUsoPessoaFisica',
      {
        params: {
          pessoaFisicaId,
        },
      },
    )
    return response?.data?.data || []
  }

  return useQuery('PessoaFisicaObterUsoPessoaFisica', obterUsoPessoaFisica, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
