import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

export default function useMutationLimparCamposESocial() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(rpId: string) {
    await api.patch('/RP/LimparCamposESocial/' + rpId)
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Recibo eSocial limpo com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
