import { formatPrettyAnoMes } from '~/hooks/useUtils'

import { Grid } from '@material-ui/core'
import { AnoMesTextField, Checkbox, DatePickerNew, NumeroInscricaoTextField } from '~/components'
import { AutoCompleteProcessoTrabalhista, MUIAutoComplete } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'

import {
  TipoContratoProcessoEnum,
  TipoContratoProcessoValues,
} from '~/@types/enums/TipoContratoProcessoEnum'
import { IndRepercussaoProcessoValues } from '~/@types/enums/IndRepercussaoProcessoEnum'
import { IndGrauExposicaoEnum } from '~/@types/enums/IndGrauExposicaoEnum'

import {
  ProcessoTrabalhistaVinculoBaseDTO,
  ProcessoTrabalhistaVinculoCreateDTO,
} from '~/hooks/queries/ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculoCreateDTO'
import { TabProcessoTrabalhista } from '../..'
import { TipoInscricaoValues, TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const tiposInscricao = TipoInscricaoValues.filter(
  (obj) => obj.value === TipoInscricaoEnum.CNPJ_1 || obj.value === TipoInscricaoEnum.CPF_2,
)

interface TabCadastroProps {
  data: ProcessoTrabalhistaVinculoCreateDTO
  setData: React.Dispatch<React.SetStateAction<ProcessoTrabalhistaVinculoCreateDTO>>
  validationErrors: ValidationErrors
  setTab: (tab: TabProcessoTrabalhista) => void
}

export default function TabCadastro({ data, setData, validationErrors, setTab }: TabCadastroProps) {
  const dialogNotification = useDialogNotification()

  function handleChangePeriodos(competenciaInicio?: string, competenciaFim?: string) {
    if (competenciaInicio?.length !== 6) return
    if (competenciaFim?.length !== 6) return
    dialogNotification.info({
      descriptions: [
        `Deseja gerar os Períodos e Valores das competências ${formatPrettyAnoMes(
          competenciaInicio,
        )}-${formatPrettyAnoMes(competenciaFim)}?`,
      ],
      onConfirm: () => onChangePeriodos(competenciaInicio, competenciaFim),
      labelOnConfirm: 'Confirmar',
    })
  }

  function onChangePeriodos(competenciaInicio: string, competenciaFim: string) {
    const bases: ProcessoTrabalhistaVinculoBaseDTO[] = []

    const startDate = new Date(
      parseInt(competenciaInicio.substring(0, 4)),
      parseInt(competenciaInicio.substring(4, 6)) - 1,
    )
    const endDate = new Date(
      parseInt(competenciaFim.substring(0, 4)),
      parseInt(competenciaFim.substring(4, 6)) - 1,
    )

    const yearDiff = endDate.getFullYear() - startDate.getFullYear()
    const monthDiff = endDate.getMonth() - startDate.getMonth()
    const totalMonths = yearDiff * 12 + monthDiff

    if (totalMonths > 24) {
      return
    }

    while (startDate <= endDate) {
      const yearMonth = `${startDate.getFullYear()}${String(startDate.getMonth() + 1).padStart(
        2,
        '0',
      )}`
      bases.push({
        periodoReferencia: yearMonth,
        vrBcCpMensal: 0,
        vrBcCp13: 0,
        indGrauExposicao: IndGrauExposicaoEnum.AposentadoriaNormal,
        vrBcFGTSProcessoTrabalhista: 0,
        vrBcFGTSSefip: 0,
        vrBcFGTSDeclaradaAnteriormente: 0,
      })
      startDate.setMonth(startDate.getMonth() + 1)
    }

    setData((prev) => ({ ...prev, bases }))
    setTab(TabProcessoTrabalhista.PeirodoValores)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <AutoCompleteProcessoTrabalhista
          required
          validationErrors={validationErrors}
          name="processoTrabalhistaId"
          value={data.processoTrabalhistaId}
          onChange={(d) => {
            const processoTrabalhistaId = d?.id || ''
            setData((prev) => ({ ...prev, processoTrabalhistaId }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DatePickerNew
          label="Data de Emissão"
          required
          validationErrors={validationErrors}
          name="dtEmissao"
          value={data.dtEmissao || null}
          onChange={(value) => {
            const dtEmissao = value || ''
            setData((prevState) => ({ ...prevState, dtEmissao }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <MUIAutoComplete
          label="Tipo de Contrato"
          required
          validationErrors={validationErrors}
          name="tipoContratoProcesso"
          options={TipoContratoProcessoValues}
          renderOption={(option) => option.name}
          optionId="value"
          value={data.tipoContratoProcesso}
          onChange={(e, obj) => {
            const tipoContratoProcesso = obj?.value || ''
            setData((prev) => ({ ...prev, tipoContratoProcesso }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Checkbox
          label="Funcionário já cadastrado no eSocial"
          value={data.isCadastradoESocial}
          onChange={(_, checked) => {
            const isCadastradoESocial = checked
            setData((prev) => ({ ...prev, isCadastradoESocial }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Checkbox
          label="Indicativo de Reintegração"
          value={data.isIndReintegracao}
          onChange={(_, checked) => {
            const isIndReintegracao = checked
            setData((prev) => ({ ...prev, isIndReintegracao }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Checkbox
          label="Houve alteração de Categoria"
          value={data.isIndCategoria}
          onChange={(_, checked) => {
            const isIndCategoria = checked
            setData((prev) => ({ ...prev, isIndCategoria }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Checkbox
          label="Houve alteração de Natureza de Atividade"
          value={data.isIndNaturezaAtividade}
          onChange={(_, checked) => {
            const isIndNaturezaAtividade = checked
            setData((prev) => ({ ...prev, isIndNaturezaAtividade }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Checkbox
          label="Houve alteração no Motivo de Desligamento"
          value={data.isIndMotivoDesligamento}
          onChange={(_, checked) => {
            const isIndMotivoDesligamento = checked
            setData((prev) => ({ ...prev, isIndMotivoDesligamento }))
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <DatePickerNew
          label="Data de Admissão Original"
          size="small"
          value={data.dtAdmissaoOriginal || null}
          onChange={(value) => {
            const dtAdmissaoOriginal = value || ''
            setData((prevState) => ({ ...prevState, dtAdmissaoOriginal }))
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MUIAutoComplete
          label="Tipo Inscrição Responsável"
          options={tiposInscricao}
          optionId="value"
          disabled={
            data.tipoContratoProcesso !== TipoContratoProcessoEnum.Responsabilidade_Indireta_8
          }
          renderOption={(option) => option.name}
          required={
            data.tipoContratoProcesso === TipoContratoProcessoEnum.Responsabilidade_Indireta_8
          }
          InputLabelProps={{
            shrink: true,
          }}
          name="tipoInscricaoResponsavel"
          validationErrors={validationErrors}
          value={data.tipoInscricaoResponsavel}
          onChange={(e, obj) => {
            const tipoInscricaoResponsavel = obj?.value
            setData((oldState) => ({
              ...oldState,
              tipoInscricaoResponsavel,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <NumeroInscricaoTextField
          label="Número de Inscrição Responsável"
          InputLabelProps={{
            shrink: true,
          }}
          value={data.nrInscricaoResponsavel || ''}
          typeMask={data.tipoInscricaoResponsavel}
          disabled={
            data.tipoContratoProcesso !== TipoContratoProcessoEnum.Responsabilidade_Indireta_8
          }
          required={
            data.tipoContratoProcesso === TipoContratoProcessoEnum.Responsabilidade_Indireta_8
          }
          name="nrInscricaoResponsavel"
          validationErrors={validationErrors}
          onChange={(e, value) => {
            const nrInscricaoResponsavel = value
            setData((prev) => ({ ...prev, nrInscricaoResponsavel }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <MUIAutoComplete
          label="Repercussão do Processo"
          required
          validationErrors={validationErrors}
          name="indRepercussaoProcesso"
          options={IndRepercussaoProcessoValues}
          renderOption={(option) => option.name}
          optionId="value"
          value={data.indRepercussaoProcesso}
          onChange={(e, obj) => {
            const indRepercussaoProcesso = obj?.value || ''
            setData((prev) => ({ ...prev, indRepercussaoProcesso }))
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Checkbox
          label="Houve decisão para pagamento da indenização substitutiva do seguro-desemprego?"
          value={data.isIndenizacaoSeguroDesemprego}
          onChange={(_, checked) => {
            const isIndenizacaoSeguroDesemprego = checked
            setData((prev) => ({ ...prev, isIndenizacaoSeguroDesemprego }))
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Checkbox
          label="Houve decisão para pagamento da indenização substitutiva de abono salarial?"
          value={data.isIndenizacaoAbonoSalarial}
          onChange={(_, checked) => {
            const isIndenizacaoAbonoSalarial = checked
            setData((prev) => ({ ...prev, isIndenizacaoAbonoSalarial }))
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <AnoMesTextField
          label="Competência Início"
          InputLabelProps={{
            shrink: true,
          }}
          required
          validationErrors={validationErrors}
          name="competenciaInicio"
          value={data.competenciaInicio || null}
          onChange={(e) => {
            const competencia = e.target.value.replace('/', '')
            setData((prev) => ({ ...prev, competenciaInicio: competencia }))
            handleChangePeriodos(competencia, data.competenciaFim)
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <AnoMesTextField
          label="Competência Fim"
          InputLabelProps={{
            shrink: true,
          }}
          required
          validationErrors={validationErrors}
          name="competenciaFim"
          value={data.competenciaFim || null}
          onChange={(e) => {
            const competencia = e.target.value.replace('/', '')
            setData((prev) => ({ ...prev, competenciaFim: competencia }))
            handleChangePeriodos(data.competenciaInicio, competencia)
          }}
        />
      </Grid>
    </Grid>
  )
}
