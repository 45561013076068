import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'
import * as yup from 'yup'

import { Box, Grid, Paper } from '@material-ui/core'
import { Finder } from 'mio-library-ui'

import { AnoMesTextField, PageHeader, PDFViewer, PrintButton, Checkbox } from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

export default function RelatorioResumoFolha() {
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelecteds] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [data, setData] = useState({
    anoMesInicio: '',
    anoMesFim: '',
    isDecimoTerceiro: false,
    isImprimirLotacoes: true,
  })

  const dialogNotification = useDialogNotification()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const { anoMes, estabelecimento } = useAmbiente()
  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit: handlePrint,
  })

  useEffect(() => {
    async function getCollection() {
      setLoading(true)
      try {
        const response = await api.get('/EstabelecimentoConsulta')
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
    }))
    // eslint-disable-next-line
  }, [anoMes])

  function handlePrint() {
    const estabelecimentosIds = rowsSelecteds.map((rowIndex) => collection[rowIndex].id)
    openPDFViewer({
      estabelecimentosIds,
      anoMesInicio: data.anoMesInicio,
      anoMesFim: data.anoMesFim,
      isDecimoTerceiro: data.isDecimoTerceiro,
      isImprimirLotacoes: data.isImprimirLotacoes,
    })
    setRowsSelecteds([])
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Resumo Folha">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Box pb={1} component={Paper} padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <AnoMesTextField
              label="Ano/Mês Início"
              value={data.anoMesInicio}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  anoMesInicio: e?.target?.value?.replace('/', '') || '',
                }))
              }
              required
              validationErrors={validationErrors}
              name="anoMesInicio"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AnoMesTextField
              label="Ano/Mês Fim"
              value={data.anoMesFim}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  anoMesFim: e?.target?.value?.replace('/', '') || '',
                }))
              }
              required
              validationErrors={validationErrors}
              name="anoMesFim"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Checkbox
              label="Somente Recibos 13°"
              value={data.isDecimoTerceiro}
              onChange={(e, value) => {
                const isDecimoTerceiro = value
                setData((prev) => ({ ...prev, isDecimoTerceiro }))
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Checkbox
              label="Imprimir por Lotação"
              value={data.isImprimirLotacoes}
              onChange={(e, value) => {
                const isImprimirLotacoes = value
                setData((prev) => ({ ...prev, isImprimirLotacoes }))
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Table
        data={collection}
        query={query}
        isLoading={isLoading}
        rowsSelected={rowsSelecteds}
        handleRowSelected={setRowsSelecteds}
      />
      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="ResumoFolha"
        title="Resumo Folha"
        isSalvarDocumento
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/ResumoFolha/ObterRelatorio',
          data: dataPDFViewer,
          params: {
            estabelecimentoId: estabelecimento.id,
            anoMes,
          },
        }}
      />
      <Box display="flex" justifyContent="flex-end" pt={1}>
        <PrintButton disabled={!(rowsSelecteds.length > 0)} onClick={handleValidate} />
      </Box>
    </Box>
  )
}
