import React from 'react'

import { DataTable } from 'mio-library-ui'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

const Table = (props) => {
  const { data: _data } = props

  const { formatCurrency } = useUtils()

  const data = _data.map((d) => ({
    dtSalario: d?.dtSalario ? moment(d.dtSalario).format('DD/MM/yyyy') : '',
    vrHora: d?.vrHora ? formatCurrency(d.vrHora) : '',
    vrDia: d?.vrDia ? formatCurrency(d.vrDia) : '',
    vrMensal: d?.vrMensal ? formatCurrency(d.vrMensal) : '',
  }))

  const columns = [
    {
      name: 'dtSalario',
      label: 'Data',
    },
    {
      name: 'vrHora',
      label: 'Valor Hora',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrDia',
      label: 'Valor Dia',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrMensal',
      label: 'Valor Mensal',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
  ]

  return <DataTable data={data} columns={columns} pagination={false} />
}

export default Table
