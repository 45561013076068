import { ButtonBox } from 'mio-library-ui'
import React from 'react'

import { ActionDialog, Button } from '~/components'

import AcordoDissidioConvencao from '~/pages/AcordoDissidioConvencao'

interface DialogAcordoDissidioProps {
  isOpen: boolean
  onClose: () => void
}

export default function DialogAcordoDissidio(props: DialogAcordoDissidioProps) {
  const { isOpen, onClose } = props

  return (
    <ActionDialog
      // title="Informe dados para o Acordo Banco de Horas"
      isOpen={isOpen}
      onClose={onClose}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
      }}
    >
      <AcordoDissidioConvencao />
    </ActionDialog>
  )
}
