import { useState } from 'react'

import * as yup from 'yup'

import { ActionDialog, DatePickerNew, PDFViewer } from '~/components'

import useDialog from '~/hooks/useDialog'
import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoSuspensaoRelatorioRequestDTO } from '~/hooks/queries/VinculoSuspensao/dtos/VinculoSuspensaoRelatorioRequestDTO'

import { PAGE_TITLE } from '..'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtAssinatura: yup
    .date()
    .typeError('Informe uma dava válida')
    .required('Informe a Data da Assinatura'),
})

interface DialogRelatorioProps {
  isOpen: boolean
  onClose: () => void
  vinculoSuspensaoId: string
  subtitle: string
}

export default function DialogRelatorio({
  isOpen,
  onClose,
  vinculoSuspensaoId,
  subtitle,
}: DialogRelatorioProps) {
  const [data, setData] = useState({
    dtAssinatura: getDateCurrent(),
    testemunha1: '',
    testemunha2: '',
  })

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<VinculoSuspensaoRelatorioRequestDTO>({
    suspensaoId: vinculoSuspensaoId,
    dtAssinatura: '',
  })

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    openPDFViewer({
      suspensaoId: vinculoSuspensaoId,
      dtAssinatura: data.dtAssinatura,
    })
  }

  return (
    <ActionDialog
      title="Imprimir Suspensão"
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Imprimir"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <DatePickerNew
        label="Data da Assinatura"
        value={data.dtAssinatura || null}
        onChange={(time) => {
          const dtAssinatura = time || ''
          setData((prev) => ({ ...prev, dtAssinatura }))
        }}
        required
        validationErrors={validationErrors}
        name="dtAssinatura"
      />

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="AvisoSuspensao"
          title={PAGE_TITLE}
          subtitle={subtitle}
          axiosConfig={{
            method: 'post',
            url: '/VinculoSuspensao/ObterRelatorio',
            data: dataPDFViewer,
          }}
        />
      )}
    </ActionDialog>
  )
}
