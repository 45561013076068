import React from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, Checkbox, DatePickerNew, SimpleSelect, TextField } from '~/components'

import useNotificacaoCriar from '~/hooks/queries/Notificacao/useNotificacaoCriar'
import useValidationErrors from '~/hooks/useValidationErrors'

import { NotificacaoCriarDTO } from '~/hooks/queries/Notificacao/dtos/NotificacaoCriarDTO'
import { IndNotificacaoEnum, IndNotificacaoValues } from '~/@types/enums/IndNotificacaoEnum'

const schema = yup.object().shape({
  dtInicio: yup.date().typeError('Informe uma data válida').required('Informe a Data de Início'),
  titulo: yup.string().required('Informe o Título'),
})

interface FormCreateProps {
  isOpen: boolean
  onClose: () => void
  isEnvironmentCommon: boolean
}

export default function FormCreate({ isOpen, onClose, isEnvironmentCommon }: FormCreateProps) {
  const [data, setData] = React.useState<NotificacaoCriarDTO>({
    dtInicio: '',
    dtFim: '',
    isNotificarAnual: false,
    titulo: '',
    descricao: '',
    indNotificacao: IndNotificacaoEnum.Normal_00,
  })

  const { mutateAsync: mutateAsyncNotificacaoCriar, isLoading: isLoadingNotificacaoCriar } =
    useNotificacaoCriar()

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  async function handleSubmit() {
    await mutateAsyncNotificacaoCriar({
      data,
      params: {
        isEnvironmentCommon,
      },
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Notificação"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoadingNotificacaoCriar}
      okLabel="Salvar"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Título"
            value={data.titulo}
            inputProps={{ maxLength: 50 }}
            required
            name="titulo"
            validationErrors={validationErrors}
            onChange={(e) => {
              const titulo = e.target.value
              setData((prev) => ({ ...prev, titulo }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Descrição"
            value={data.descricao}
            inputProps={{ maxLength: 200 }}
            rows={3}
            multiline
            onChange={(e) => {
              const descricao = e.target.value
              setData((prev) => ({ ...prev, descricao }))
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <DatePickerNew
            label="Data de Início"
            value={data.dtInicio || null}
            required
            name="dtInicio"
            validationErrors={validationErrors}
            onChange={(dt) =>
              setData((prev) => ({
                ...prev,
                dtInicio: dt || '',
                dtFim: dt || '',
              }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <DatePickerNew
            label="Data de Fim"
            value={data.dtFim || null}
            onChange={(dt) =>
              setData((prev) => ({
                ...prev,
                dtFim: dt || '',
              }))
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Checkbox
            label="Repetir todos os anos"
            value={data.isNotificarAnual}
            onChange={(e, check) =>
              setData((prev) => ({
                ...prev,
                isNotificarAnual: check,
              }))
            }
          />
        </Grid>

        <Grid item xs={12}>
          <SimpleSelect
            label="Indicador da Notificação"
            options={IndNotificacaoValues}
            optionId="value"
            value={data.indNotificacao}
            renderOption={(d) => d.name}
            onChange={(_, value) => setData((prev) => ({ ...prev, indNotificacao: value }))}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
