import React from 'react'
import clsx from 'clsx'

import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.70rem',
    fontWeight: 500,
    color: '#666',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: '#f5fffb',
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(0.3),
  },
  pointed: {
    cursor: 'pointer',
  },
}))

interface InfoItemProps {
  icon: React.ReactNode
  title: string
  description: string
  value?: string | number
  onClick?: () => void
  isPointed: boolean
}

export default function InfoItem(props: InfoItemProps) {
  const { icon, title, description, value, onClick, isPointed } = props
  const classes = useStyles()
  return (
    <Box
      className={clsx(classes.root, { [classes.pointed]: isPointed })}
      onClick={onClick}
      title={description}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {icon}
      </Box>
      <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
        {title}
      </Box>
      <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
        {value}
      </Box>
    </Box>
  )
}
