export enum IndLayoutCNABEnum {
  NaoInformar,
  CEF150,
  CEF240,
}

export const IndLayoutCNABValues = [
  {
    name: 'Não Informar',
    value: IndLayoutCNABEnum.NaoInformar,
  },
  {
    name: 'Caixa Econômica Federal CNAB 150',
    value: IndLayoutCNABEnum.CEF150,
  },
  {
    name: 'Caixa Econômica Federal CNAB 240',
    value: IndLayoutCNABEnum.CEF240,
  },
]
