import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { PlanoSaudeTitular } from './dtos/PlanoSaudeTitular'

const query = '/PlanoSaudeTitular/ObterPorVinculo'

interface RequestProps {
  params: {
    vinculoId: string
  }
}

export function useObterPorVinculo({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: PlanoSaudeTitular[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useObterPorVinculoInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
