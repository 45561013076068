import React, { useState } from 'react'

import { ContainerComponent } from '~/components'

import Form from './components/Form'
import Processo from './components/Processo'
import Situacao from './components/Situacao'

const Container = (props) => {
  const { isOpen, onClose, data, onAfterSubmitForm, estabelecimento, anoMes } = props
  const [wasModified, setWasModified] = useState(false)

  return (
    <ContainerComponent
      title="Cadastro de Lotação"
      isOpen={isOpen}
      onClose={onClose}
      formWasModified={wasModified}
      tabs={[
        {
          component: (
            <Form
              data={data}
              onFormSubmit={onAfterSubmitForm}
              onCancelar={onClose}
              setWasModified={setWasModified}
            />
          ),
          label: 'Cadastro',
        },
        {
          component: (
            <Situacao lotacaoId={data.id} estabelecimento={estabelecimento} anoMes={anoMes} />
          ),
          disabled: !data?.id ? true : false,
          label: 'Situação Tributária',
        },
        {
          component: <Processo lotacaoId={data.id} />,
          disabled: !data?.id ? true : false,
          label: 'Processos',
        },
      ]}
    />
  )
}

export default Container
