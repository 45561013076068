export enum TipoContratoTrabalhoEnum {
  PrazoIndeterminado_1 = 1,
  PrazoDeterminado_2 = 2,
  PrazoDeterminadoOcorrencia_3 = 3,
}

export const TipoContratoTrabalhoValues = [
  { value: TipoContratoTrabalhoEnum.PrazoIndeterminado_1, name: '1 - Prazo Indeterminado' },
  {
    value: TipoContratoTrabalhoEnum.PrazoDeterminado_2,
    name: '2 - Prazo Determinado definido em dias',
  },
  {
    value: TipoContratoTrabalhoEnum.PrazoDeterminadoOcorrencia_3,
    name: '3 - Prazo Determinado, vinculado à ocorrência de um fato',
  },
]
