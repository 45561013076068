export enum IdentificacaoDiaEnum {
  Normal,
  NaoTrabalha,
  Feriado,
  DescansoSemanal,
  Ferias,
  Afastamento,
  AntesAdmissao,
}

// public static class IdentificacaoDiaEnumExtension
// {
//     public static string ObterDescricao(this IdentificacaoDiaEnum valor)
//     {
//         switch (valor)
//         {
//             case IdentificacaoDiaEnum.Normal:
//                 return "Normal";
//             case IdentificacaoDiaEnum.NaoTrabalha:
//                 return "Não trabalha";
//             case IdentificacaoDiaEnum.Feriado:
//                 return "Feriado";
//             case IdentificacaoDiaEnum.DescansoSemanal:
//                 return "Descanso semanal";
//             case IdentificacaoDiaEnum.Ferias:
//                 return "Férias";
//             case IdentificacaoDiaEnum.Afastamento:
//                 return "Afastamento";
//             case IdentificacaoDiaEnum.AntesAdmissao:
//                 return "Antes da admissão";
//             default:
//                 throw new NotImplementedException();
//         }
//     }
// }
