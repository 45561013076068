import { useEffect } from 'react'

export default function useKeyPress(key, handlePressKey) {
  useEffect(() => {
    function onKeyDown(e) {
      if (e.key === key) handlePressKey(e)
    }
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [key, handlePressKey])
}
