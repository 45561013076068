import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { VinculoCadastroInicialDTO } from './VinculoCadastroInicialDTO'
import { isCPF } from 'brazilian-values'

export interface RequestParams {
  estabelecimentoId: string
  vinculoTipo: VinculoTipoEnum
  cpf: string
  anoMes: string
}

const query = '/Vinculo/ObterDataInicial'

export function useVinculoObterDataInicial(params: RequestParams) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!isCPF(params.cpf)) return
    const response = await api.get<{ data: VinculoCadastroInicialDTO }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    retry: false,
  })
}
