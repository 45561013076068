import { useEffect, useState } from 'react'

import { TreeView } from '~/components'

import Content from './components/Content'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

export default function VinculoAfastamento() {
  const [vinculo, setVinculo] = useState(null)

  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
    VinculoTipoEnum.Empregador_2,
  ])
  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    setVinculo(null)
  }, [anoMes, estabelecimento])

  return (
    <TreeView
      onSelectItem={(event, value) => setVinculo(value)}
      data={dataMenu}
      isLoading={isLoadingMenu}
      searchBy={['vinculoNome']}
      renderOption={(option) => option?.vinculoNome}
    >
      <Content vinculo={vinculo} />
    </TreeView>
  )
}
