import { useState } from 'react'
import api from '~/services/api-pessoal'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { PageHeader, DatePickerNew, Button } from '~/components'

import { MUIAutoComplete } from '~/components/AutoComplete'

import Table from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'

import { useObterAuditoriaPorEmpregador } from '~/hooks/queries/ESocial/useObterAuditoriaPorEmpregador'

import { Auditoria } from '~/hooks/queries/Auditoria/Auditoria'

import { ESocialEventoEnum, ESocialEventoValues } from '~/@types/enums/ESocialEventoEnum'
import { ESocialStatusEnum, ESocialStatusValues } from '~/@types/enums/ESocialStatusEnum'

interface DataForm {
  esocialEvento?: ESocialEventoEnum | null
  esocialStatus?: ESocialStatusEnum | null
  dtInicio?: Date | string | null
  dtFim?: Date | string | null
}

export default function AuditoriaPorEmpregador() {
  const [collection, setCollection] = useState<Auditoria[]>([])
  const [data, setData] = useState<DataForm>({
    esocialEvento: null,
    esocialStatus: null,
    dtInicio: null,
    dtFim: null,
  })
  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [isLoadingAlterarStatus, setLoadingAlterarStatus] = useState(false)
  const [statusAlterar, setStatusAlterar] = useState(ESocialStatusEnum.Aberto_10)

  const notification = useNotification()
  const { empregador } = useAmbiente()
  const theme = useTheme()

  const { mutateAsync, isLoading } = useObterAuditoriaPorEmpregador()

  async function handleSearch() {
    const response = await mutateAsync({
      empregadorId: empregador.id,
      esocialEvento: data.esocialEvento,
      esocialStatus: data.esocialStatus,
      dtInicio: data.dtInicio,
      dtFim: data.dtFim,
    })
    setCollection(response)
    setRowsSelected([])
  }

  async function handleChangeStatus() {
    setLoadingAlterarStatus(true)
    try {
      const ids = rowsSelected.map((d) => collection[d].id)
      await api.post('/ESocial/AlterarStatusEvento', ids, {
        params: {
          status: statusAlterar,
        },
      })
      notification.success('O status foi alterado para aguardando com sucesso')
      handleSearch()
    } catch (err) {
      notification.error(err)
    } finally {
      setLoadingAlterarStatus(false)
    }
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      gridGap={theme.spacing(1)}
      flexDirection="column"
    >
      <PageHeader title="Auditoria por Empregador" />
      <Box component={Paper} padding={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MUIAutoComplete
              label="eSocial Evento"
              options={ESocialEventoValues}
              renderOption={(opt) => opt.name}
              optionId="value"
              value={data?.esocialEvento}
              onChange={(_, obj) =>
                setData((prevState) => ({
                  ...prevState,
                  esocialEvento: obj?.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MUIAutoComplete
              label="eSocial Status"
              options={ESocialStatusValues}
              renderOption={(opt) => opt.name}
              optionId="value"
              value={data?.esocialStatus}
              onChange={(_, obj) =>
                setData((prevState) => ({
                  ...prevState,
                  esocialStatus: obj?.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerNew
              label="Data de Início"
              size="small"
              value={data?.dtInicio || null}
              onChange={(value) => {
                const dtInicio = value || null
                setData((prevState) => ({ ...prevState, dtInicio }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerNew
              label="Data de Fim"
              size="small"
              value={data?.dtFim || null}
              onChange={(value) => {
                const dtFim = value || null
                setData((prevState) => ({ ...prevState, dtFim }))
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                size="small"
                variant="contained"
                isLoading={isLoading}
                onClick={() => handleSearch()}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box flex={1} overflow="auto" position="relative" width="85vw">
        <Table
          data={collection}
          isLoading={isLoading}
          isFetching={isLoadingAlterarStatus}
          rowsSelected={rowsSelected}
          onRowSelected={setRowsSelected}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" alignItems="center" gridGap={theme.spacing(1)}>
        <Box width="300px">
          <MUIAutoComplete
            label="Alterar para o Status"
            options={ESocialStatusValues}
            renderOption={(opt) => opt.name}
            optionId="value"
            value={statusAlterar}
            onChange={(_, obj) => setStatusAlterar(obj?.value || ESocialStatusEnum.Aberto_10)}
          />
        </Box>

        <Button
          variant="contained"
          onClick={() => handleChangeStatus()}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Alterar Status
        </Button>
      </Box>
    </Box>
  )
}
