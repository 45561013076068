import { Box, Divider, IconButton } from '@material-ui/core'
import { Cached } from '@material-ui/icons'
import { LabelDescription, Stack } from '~/components'

import { FolhaPontoDia } from '~/hooks/queries/FolhaPonto/dtos/FolhaPontoDia'

import { converterParaMinutos, converterTimeSpan } from '../Table/RowTable'

import { useInvalidate } from '~/hooks/queries/FolhaPonto/useObterFolhaPonto'

interface TotalizadorHorasExtraProps {
  data: FolhaPontoDia[]
}

export default function TotalizadorHorasExtra({ data }: TotalizadorHorasExtraProps) {
  const { handleInvalidate } = useInvalidate()

  const minutosExtras = data.reduce(
    (prev, current) => (prev += converterParaMinutos(current.horaExtraTotal || '')),
    0,
  )
  const minutosExtrasNoturnos = data.reduce(
    (prev, current) => (prev += converterParaMinutos(current.horaNoturnaTotal || '')),
    0,
  )

  return (
    <Stack orientation="horizontal" justifyContent="center" alignItems="center">
      <LabelDescription
        label="Total de Horas Extras"
        description={converterTimeSpan(minutosExtras)}
      />
      <Box height="30px" width="4px">
        <Divider orientation="vertical" />
      </Box>
      <LabelDescription
        label="Total de Horas Noturnas"
        description={converterTimeSpan(minutosExtrasNoturnos)}
      />
      <Box height="30px" width="4px">
        <Divider orientation="vertical" />
      </Box>
      <IconButton size="small" onClick={() => handleInvalidate()}>
        <Cached fontSize="small" />
      </IconButton>
    </Stack>
  )
}
