import { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { ActionDialog, AnoMesTextField, CurrencyTextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'
import { IndSubsPatronalValues } from '~/@types/enums/IndSubsPatronalEnum'
import { useSubmit } from '~/hooks/queries/useInformacaoComplementarEmpregador'
import useValidationErrors from '~/hooks/useValidationErrors'
import * as yup from 'yup'
import moment from 'moment'

const schema = yup.object().shape({
  anoMes: yup
    .string()
    .required('Infome o Ano/Mês')
    .when('$competenciaAnoMes', (competenciaAnoMes, schema) =>
      schema.test('Ano/Mês-validator', 'O Ano/Mês deve ser igual a Competência Atual ', (anoMes) =>
        moment(anoMes).isSame(competenciaAnoMes),
      ),
    ),
  indSubstituicaoPatronal: yup.string().required('Informe a Substituição Patronal').nullable(),
})

export default function Form(props) {
  const { anoMes, data: _data, isOpen, onClose } = props
  const [data, setData] = useState({})
  const { mutateAsync, isLoading } = useSubmit()

  useEffect(() => {
    setData(_data)
  }, [_data])

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { competenciaAnoMes: anoMes },
    },
  })

  return (
    <ActionDialog
      title="Cadastro de Informação Complementar"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <AnoMesTextField
            name="anoMes"
            label="Ano/Mês"
            required
            value={data?.anoMes || ''}
            onChange={(e) => {
              const anoMes = e?.target?.value?.replace('/', '') || ''
              setData((oldState) => ({
                ...oldState,
                anoMes,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            name="percentualReducaoContribuicao"
            label="Percentual de Redução Contribuição"
            variant="outlined"
            size="small"
            maximumValue="9999"
            fullWidth
            value={data?.percentualReducaoContribuicao || 0}
            onChange={(_, value) => {
              const percentualReducaoContribuicao = value || 0
              setData((oldState) => ({
                ...oldState,
                percentualReducaoContribuicao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            name="indSubstituicaoPatronal"
            label="Substituição Patronal"
            optionId="value"
            required
            options={IndSubsPatronalValues}
            renderOption={(option) => option.name}
            value={data?.indSubstituicaoPatronal}
            onChange={(_, obj) => {
              const indSubstituicaoPatronal = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                indSubstituicaoPatronal,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <CurrencyTextField
            label="Fator Mês"
            variant="outlined"
            maximumValue="9999"
            size="small"
            fullWidth
            value={data?.fatorMes || 0}
            onChange={(_, value) => {
              const fatorMes = value || 0
              setData((oldState) => ({
                ...oldState,
                fatorMes,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <CurrencyTextField
            label="Fator 13°"
            maximumValue="9999"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.fatorDecimoTerceiro || 0}
            onChange={(_, value) => {
              const fatorDecimoTerceiro = value || 0
              setData((oldState) => ({
                ...oldState,
                fatorDecimoTerceiro,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
