export enum NaturezaAtividadeEnum {
  Nao_Informar_0 = 0,
  TrabalhoUrbano_1 = 1,
  TrabalhoRural_2 = 2,
}

export const NaturezaAtividadeValues = [
  { value: NaturezaAtividadeEnum.Nao_Informar_0, name: '0 - Não Informar' },
  { value: NaturezaAtividadeEnum.TrabalhoUrbano_1, name: '1 - Trabalhador Urbano' },
  { value: NaturezaAtividadeEnum.TrabalhoRural_2, name: '2 - Trabalhador Rural' },
]
