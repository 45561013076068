export enum IndPensaoAlimenticiaEnum {
  SalarioMinimo_1 = 1,
  SalarioLiquido_2 = 2,
  SalarioBruto_3 = 3,
  Valor_4 = 4,
  NaoAplicavel_9 = 9,
}

export const IndPensaoAlimenticiaValues = [
  { value: IndPensaoAlimenticiaEnum.NaoAplicavel_9, name: '9 - Não informar' },
  { value: IndPensaoAlimenticiaEnum.SalarioMinimo_1, name: '1 - Percentual Salário Mínimo' },
  { value: IndPensaoAlimenticiaEnum.SalarioLiquido_2, name: '2 - Percentual Liquido' },
  { value: IndPensaoAlimenticiaEnum.SalarioBruto_3, name: '3 - Percentual Bruto' },
  { value: IndPensaoAlimenticiaEnum.Valor_4, name: '4 - Valor' },
]
