import React, { useRef, useState } from 'react'

import { List, AutoSizer } from 'react-virtualized'

import {
  Box,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core'

import RowRenderer from './RowRenderer'
import MenuNavigator from './MenuNavigator'

import SearchTextFieldDelay from '../SearchTextFieldDelay'

export const HEIGHT_ROW = 40
const HEIGHT_SEARCH_FIELD = 55
const HEIGHT_MENU_NAVIGATOR = 40
const HEIGHT_LOADING = 4

const useStyles = makeStyles((theme: FixLater) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(0.5),
  },
  subheader: {
    height: HEIGHT_SEARCH_FIELD,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
  },
  content: {
    height: `calc(100% - ${HEIGHT_MENU_NAVIGATOR}px - ${HEIGHT_SEARCH_FIELD}px)`,
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
  },
  isLoadingAbsolute: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navigator: {
    height: HEIGHT_MENU_NAVIGATOR,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
  },
}))

export type VirtualizedMenuDataChildren = Omit<VirtualizedMenuData, 'children'>

export interface VirtualizedMenuData {
  id: any
  label: string
  children: VirtualizedMenuDataChildren[]
}

interface VirtualizedMenuProps {
  data: VirtualizedMenuData[]
  onChangeParent: (id: string | null) => void
  parentSelected: string | null
  onChangeChildren?: (id: string) => void
  childrenSelected?: string
  renderRightParent?: (id: string) => React.ReactNode
  renderRightChildren?: (id: string) => React.ReactNode
  isLoading?: boolean
  isFetching?: boolean
  onCheckParent?: (indexs: string[]) => void
  parentsChecked?: string[]
}

export default function VirtualizedMenu(props: VirtualizedMenuProps) {
  const {
    data: _data,
    onChangeParent,
    parentSelected,
    onChangeChildren,
    childrenSelected,
    renderRightParent,
    renderRightChildren,
    isLoading,
    isFetching,
    onCheckParent,
    parentsChecked,
  } = props

  const [searchText, setSearchText] = useState('')

  const data = searchText
    ? _data.filter((d) => d.label.toLowerCase().includes(searchText.toLowerCase()))
    : _data

  const classes = useStyles()
  const listRef = useRef(null)

  function handleChangeRow(id: string, index: number) {
    if (parentSelected === id) {
      onChangeParent(null)
    } else {
      onChangeChildren && onChangeChildren('')
      onChangeParent(id)
    }
    if (listRef.current) {
      const listComponent: List = listRef.current
      listComponent.scrollToRow(index)
      listComponent.recomputeRowHeights()
    }
  }

  function handleChangeChildren(id: string) {
    if (!onChangeChildren) return
    if (childrenSelected === id) {
      onChangeChildren('')
    } else {
      onChangeChildren(id)
    }
  }

  function calculateRowHeight(dataCurrent: VirtualizedMenuData) {
    if (parentSelected === dataCurrent.id) {
      return dataCurrent.children.length * HEIGHT_ROW + HEIGHT_ROW
    }
    return HEIGHT_ROW
  }

  function handleCheckAll() {
    if (!onCheckParent || !parentsChecked) return
    if (parentsChecked.length > 0) {
      onCheckParent([])
    } else {
      const allIds = data.map((d) => d.id)
      onCheckParent(allIds)
    }
  }

  const isCheckedAll = parentsChecked?.length === data.length

  return (
    <Box className={classes.root}>
      <Box className={classes.subheader}>
        {parentsChecked && (
          <Checkbox
            edge="start"
            onClick={handleCheckAll}
            checked={isCheckedAll}
            indeterminate={isCheckedAll ? undefined : parentsChecked?.length > 0}
            disabled={data.length === 0}
            size="small"
            color="secondary"
          />
        )}
        <SearchTextFieldDelay onChange={setSearchText} />
      </Box>
      <Box className={classes.content}>
        {isFetching ? <LinearProgress /> : <Box height={HEIGHT_LOADING} />}
        {isLoading ? (
          <Box className={classes.isLoadingAbsolute}>
            <CircularProgress size={50} />
          </Box>
        ) : data.length > 0 ? (
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={listRef}
                width={width}
                height={height - HEIGHT_LOADING}
                rowHeight={({ index }) => calculateRowHeight(data[index])}
                rowRenderer={(props) => (
                  <RowRenderer
                    key={props.key}
                    listRowProps={props}
                    onChangeParent={handleChangeRow}
                    parentSelected={parentSelected}
                    onChangeChildren={handleChangeChildren}
                    childrenSelected={childrenSelected}
                    renderRightParent={renderRightParent}
                    renderRightChildren={renderRightChildren}
                    data={data}
                    parentsChecked={parentsChecked}
                    onCheckParent={onCheckParent}
                  />
                )}
                rowCount={data.length}
              />
            )}
          </AutoSizer>
        ) : (
          <Typography align="center">Registros não encontrados</Typography>
        )}
      </Box>

      <Box className={classes.navigator}>
        <MenuNavigator
          onChangeParent={handleChangeRow}
          parentSelected={parentSelected}
          data={data}
        />
      </Box>
    </Box>
  )
}
