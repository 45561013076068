import React, { useEffect, useState, createContext, useContext } from 'react'

import useDialogNotification from '~/hooks/useDialogNotification'

type IconStep = string | React.ReactNode

export interface StepProps {
  label: string
  children: React.ReactNode
  icon?: IconStep | ((active: boolean) => IconStep)
  renderRight?: React.ReactNode
  onClick?: () => void
}

export type StateStepsInfo = { [index: number]: boolean }

interface CreateStepperContext<DataType> {
  onSubmit: (d: DataType) => void
  onSkipAndSubmit: (d: DataType) => void
  onCancel: () => void
  steps: StepProps[]
  mainData: DataType
  dataControlled: DataType
  onChangeControlled: React.Dispatch<React.SetStateAction<DataType>>
  isLoading?: boolean
  disabled?: boolean
  stepCurrent: number
  setStepCurrent: React.Dispatch<React.SetStateAction<number>>
  stepsCompleted: StateStepsInfo
  stepsSkipped: StateStepsInfo
  onNext: (data: DataType) => void
  onBack: () => void
  onSkip: (d: DataType) => void
  context?: any
}

const StepperContext = createContext<CreateStepperContext<any>>({
  onSubmit: () => undefined,
  onSkipAndSubmit: () => undefined,
  onCancel: () => undefined,
  steps: [],
  mainData: null,
  dataControlled: null,
  onChangeControlled: () => undefined,
  isLoading: false,
  disabled: false,
  stepCurrent: 0,
  setStepCurrent: () => undefined,
  stepsCompleted: {},
  stepsSkipped: {},
  onNext: () => undefined,
  onBack: () => undefined,
  onSkip: () => undefined,
})

interface StepperProviderProps<DataType> {
  onSubmit?: (d: DataType) => void
  onCancel?: () => void
  data?: DataType
  dataControlled?: DataType
  onChangeControlled?: React.Dispatch<React.SetStateAction<DataType>>
  steps: StepProps[]
  isLoading?: boolean
  disabled?: boolean
  disabledAlert?: boolean
  children: React.ReactNode
  context?: any
  initialStep?: number
  stepCurrent?: number
  setStepCurrent?: React.Dispatch<React.SetStateAction<number>>
  stepsCompleted?: StateStepsInfo
  setStepsCompleted?: React.Dispatch<React.SetStateAction<StateStepsInfo>>
}

export default function StepperProvide<DataType>(props: StepperProviderProps<DataType>) {
  const {
    onSubmit: handleFinish,
    onCancel: handleCancel,
    data,
    dataControlled,
    onChangeControlled: handleChangeControlled,
    steps,
    isLoading,
    disabled,
    children,
    disabledAlert = false,
    context,
    initialStep = 0,
    stepCurrent: _stepCurrent,
    setStepCurrent: _setStepCurrent,
    stepsCompleted: _stepsCompleted,
    setStepsCompleted: _setStepsCompleted,
  } = props
  const [mainData, setMainData] = useState<DataType | null>(data || null)

  const [stepCurrentLocal, setStepCurrentLocal] = useState(initialStep)
  const [stepsCompletedLocal, setStepsCompletedLocal] = useState<StateStepsInfo>({})
  const [stepsSkipped, setStepsSkipped] = useState<StateStepsInfo>({})

  const stepCurrent = _stepCurrent ? _stepCurrent : stepCurrentLocal
  const setStepCurrent = _setStepCurrent ? _setStepCurrent : setStepCurrentLocal

  const stepsCompleted = _stepsCompleted ? _stepsCompleted : stepsCompletedLocal
  const setStepsCompleted = _setStepsCompleted ? _setStepsCompleted : setStepsCompletedLocal

  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setStepCurrent(initialStep)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStep])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (disabledAlert) return
      event.preventDefault()
      event.returnValue = 'Tem certeza que deseja sair? Você pode perder dados não salvos.'
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [disabledAlert])

  function onNext(data: DataType) {
    setMainData(data)
    setStepCurrent((prevStepCurrent) => {
      setStepsCompleted((prevStepsCompleted) => ({
        ...prevStepsCompleted,
        [prevStepCurrent]: true,
      }))
      setStepsSkipped((prevStepsSkip) => ({
        ...prevStepsSkip,
        [prevStepCurrent]: false,
      }))
      return prevStepCurrent + 1
    })
  }

  function onSkip(data: DataType) {
    setMainData(data)
    setStepCurrent((prevStepCurrent) => {
      setStepsSkipped((prevStepsSkip) => ({
        ...prevStepsSkip,
        [prevStepCurrent]: true,
      }))
      setStepsCompleted((prevStepsCompleted) => ({
        ...prevStepsCompleted,
        [prevStepCurrent]: false,
      }))
      return prevStepCurrent + 1
    })
  }

  function onBack() {
    setStepCurrent((prevStepCurrent) => prevStepCurrent - 1)
  }

  function handleValidateSteps(
    data: DataType,
    stepsCompleted: StateStepsInfo,
    stepsSkipped: StateStepsInfo,
  ) {
    try {
      steps.forEach((step, index) => {
        if (!stepsCompleted[index] && !stepsSkipped[index]) {
          setStepCurrent(index)
          throw new Error(`A etapa "${step.label}" não foi informada corretamente`)
        }
      })
      handleFinish && handleFinish(data)
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  function onSubmit(data: DataType) {
    const stepsCompletedCurrent = { ...stepsCompleted, [stepCurrent]: true }
    const stepsSkippedCurrent = { ...stepsSkipped, [stepCurrent]: false }
    setMainData(data)
    setStepsCompleted(stepsCompletedCurrent)
    setStepsSkipped(stepsSkippedCurrent)
    handleValidateSteps(data, stepsCompletedCurrent, stepsSkippedCurrent)
  }

  function onSkipAndSubmit(data: DataType) {
    const stepsCompletedCurrent = { ...stepsCompleted, [stepCurrent]: false }
    const stepsSkippedCurrent = { ...stepsSkipped, [stepCurrent]: true }
    setMainData(data)
    setStepsCompleted(stepsCompletedCurrent)
    setStepsSkipped(stepsSkippedCurrent)
    handleValidateSteps(data, stepsCompletedCurrent, stepsSkippedCurrent)
  }

  function onCancel() {
    handleCancel && handleCancel()
  }

  function onChangeControlled(value: React.SetStateAction<DataType>) {
    handleChangeControlled && handleChangeControlled(value)
  }

  return (
    <StepperContext.Provider
      value={{
        onSubmit,
        onSkipAndSubmit,
        onCancel,
        steps,
        mainData,
        dataControlled,
        onChangeControlled,
        isLoading,
        disabled,
        stepCurrent,
        setStepCurrent,
        stepsCompleted,
        stepsSkipped,
        onNext,
        onBack,
        onSkip,
        context,
      }}
    >
      {children}
    </StepperContext.Provider>
  )
}

export function useStepperContext<DataType>() {
  return useContext<CreateStepperContext<DataType>>(StepperContext)
}
