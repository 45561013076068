import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { IconButton } from '@material-ui/core'
import { Visibility as ViewIcon } from '@material-ui/icons'

import { ToolsDataTable } from '~/components'

import StatusIcon from '../StatusIcon'

import { formatToCPFOrCNPJ } from 'brazilian-values'

const Table = (props) => {
  const {
    data: _data,
    isLoading,
    query,
    onResumoClick,
    onRowSelected,
    rowsSelecteds,
    triggersHeight,
  } = props

  const data = _data.map((item) => {
    return {
      ...item,
      codigo: item?.estabelecimento?.codigo || '',
      nome: item?.estabelecimento?.nome || '',
      nrInscricao: formatToCPFOrCNPJ(item?.estabelecimento?.nrInscricao || ''),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'nrInscricao',
        label: 'Nr. Inscrição',
      },
      {
        name: 'statusDescricao',
        label: 'Status',
      },
      {
        name: 'status',
        label: 'Validação',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const dataValue = data[dataIndex]
            return <StatusIcon status="validar" value={dataValue || {}} />
          },
        },
      },
      {
        name: 'status',
        label: 'Inicializada',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const dataValue = data[dataIndex]
            return <StatusIcon status="iniciar" value={dataValue || {}} />
          },
        },
      },
      {
        name: 'status',
        label: 'Finalizado',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const dataValue = data[dataIndex]
            return <StatusIcon status="finalizar" value={dataValue || {}} />
          },
        },
      },
      {
        name: 'id',
        label: 'Resumo',
        options: {
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onResumoClick(value)
                  }}
                >
                  <ViewIcon fontSize="small" color="secondary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onResumoClick, data],
  )

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      triggersHeight={triggersHeight}
      sherlock={{
        columns: ['codigo', 'nome', 'nrInscricao'],
        query,
      }}
      optionsSelectable={{
        type: 'multiple',
        onRowSelected: onRowSelected,
        rowsSelected: rowsSelecteds,
      }}
    />
  )
}

Table.defaultProps = {
  pagination: false,
}

export default Table
