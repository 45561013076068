import React, { useState } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { Box, Typography } from '@material-ui/core'

import { ActionDialog, Button, ConfirmDeleteDialog, Finder, PageHeader } from '~/components'

import Table from './Table'
import Form from './Form'

import { useVinculoEventoAutomaticoDelete } from '~/hooks/queries/useVinculoEventoAutomatico'
import { VinculoMenuItem } from '~/hooks/queries/useEstabelecimento'
import useDialog from '~/hooks/useDialog'

interface ContentProps {
  title?: string
  vinculo: VinculoMenuItem | null
}

export default function Content(props: ContentProps) {
  const { vinculo, title } = props

  const [query, setQuery] = useState('')

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<string | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string | null>(null)

  const { mutateAsync: mutateAsyncDelete, isLoading: isLoadingDelete } =
    useVinculoEventoAutomaticoDelete()

  function handleClickItem(event: 'edit' | 'delete', id: string) {
    async function handleClickEditItem(id: string) {
      openForm(id)
    }

    function handleClickDeleteItem(id: string) {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](id)
  }

  async function handleConfirmDelete(idToDelete: string) {
    await mutateAsyncDelete(idToDelete)
    closeConfirmDelete()
  }

  return (
    <Box height="100%" width="100%">
      <Box height={50}>
        <PageHeader title={title} subtitle={vinculo?.vinculoNome ? vinculo.vinculoNome : '-'}>
          {vinculo?.vinculoNome ? (
            <ButtonBox>
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
              <Button
                size="small"
                color="primary"
                onClick={() => openForm(null)}
                variant="contained"
              >
                Adicionar
              </Button>
            </ButtonBox>
          ) : (
            <></>
          )}
        </PageHeader>
      </Box>
      {vinculo?.vinculoId ? (
        <Table vinculoId={vinculo.vinculoId} onItemClick={handleClickItem} query={query} />
      ) : (
        <Typography>Selecione um Funcionário</Typography>
      )}

      {isOpenForm && vinculo && (
        <ActionDialog
          isOpen={isOpenForm}
          title={title ? title + ' - Cadastro' : 'Cadastro Evento Automático'}
          subtitle={vinculo.vinculoNome}
          onClose={closeForm}
          customActions={<></>}
        >
          <Form
            vinculoEventoAutomaticoId={dataForm}
            vinculoId={vinculo.vinculoId}
            onClose={closeForm}
          />
        </ActionDialog>
      )}

      {dataConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isLoadingDelete}
          onCancel={closeConfirmDelete}
          onConfirm={() => handleConfirmDelete(dataConfirmDelete)}
        />
      )}
    </Box>
  )
}
