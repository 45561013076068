import { useState, useEffect, useCallback } from 'react'

import { LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ContainerTable, Stack, Button, ButtonBox, PageHeader } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import api from '~/services/api-pessoal'
import moment from 'moment'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useProcessaEventoEmpregador } from '~/hooks/queries/Empregador/useProcessaEventoEmpregador'

import { IndCooperativaEnum } from '~/@types/enums/IndCooperativaEnum'
import { IndConstrutoraEnum } from '~/@types/enums/IndConstrutoraEnum'
import { indProdutorRuralOpcaoPrevidenciaConsts } from '~/values/indProdutorRuralOpcaoPrevidenciaValues'
import { indDesoneracaoConsts } from '~/values/indDesoneracaoValues'

const { naoInformado } = indProdutorRuralOpcaoPrevidenciaConsts
const { naoAplicavel_0 } = indDesoneracaoConsts

const EmpregadorSituacao = (props) => {
  const { empregador, anoMes } = props

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const {
    mutateAsync: mutateAsyncProcessaEventoEmpregador,
    isLoading: isLoadingProcessaEventoEmpregador,
  } = useProcessaEventoEmpregador()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(`/empregadorSituacao/GetByEmpregador?id=${empregador.id}`)
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    //eslint-disable-next-line
    [collection],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    const itens = collection.itens
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    try {
      await api.delete(`/empregadorSituacao/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    let newItem = {
      empregadorId: empregador.id,
      naturezaJuridicaId: '2062',
      indCooperativa: IndCooperativaEnum.NaoCooperativa_0,
      indConstrutora: IndConstrutoraEnum.NaoConstrutora_0,
      indProdutorRuralOpcaoPrevidencia: naoInformado,
      indOptRegEletronico: '1',
      indDesoneracao: naoAplicavel_0,
    }

    if (collection.itens.length > 0) {
      newItem = { ...collection.itens[0] }
      delete newItem.id
      delete newItem.dtSituacao
    }

    handleOpenForm(newItem)
    //eslint-disable-next-line
  }, [empregador, collection.itens])

  const handleOpenForm = (data) => {
    setForm((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const processarEnvioTabelasESocial = useCallback(
    async (dtInicioValidadeTabelas) => {
      if (collection.itens.length > 0) return
      dialogNotification.info({
        descriptions: [
          `Cadastro de uma nova empresa, deseja criar os eventos da tabela S-1010 com o Início do Envio para o eSocial na data de ${moment(
            dtInicioValidadeTabelas,
          ).format('DD/MM/yyyy')}?`,
        ],
        onCancel: () => undefined,
        onConfirm: () =>
          mutateAsyncProcessaEventoEmpregador({
            params: {
              dtInicioValidade: dtInicioValidadeTabelas,
              empregadorId: empregador.id,
            },
          }),
        labelOnConfirm: 'Sim',
        labelOnCancel: 'Não',
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collection],
  )

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = async (data) => {
        await processarEnvioTabelasESocial(value.dtSituacao)

        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection, processarEnvioTabelasESocial],
  )

  return (
    <Stack height="100%">
      <PageHeader>
        <ButtonBox top={1}>
          <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>
      {isLoadingProcessaEventoEmpregador && (
        <Alert severity="info">
          Eventos da tabela S-1010 estão sendo processados no momento. <LinearProgress />
        </Alert>
      )}

      <ContainerTable>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          onItemClick={handleClickItem}
        />
      </ContainerTable>

      <Form
        empregador={empregador}
        anoMes={anoMes}
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </Stack>
  )
}

export default EmpregadorSituacao
