import React from 'react'

export default function PageNaoEncontrada() {
  return (
    <h1
      style={{
        padding: '10px 30px',
      }}
    >
      Página não encontrada!
    </h1>
  )
}
