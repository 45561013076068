import { useState, useEffect, useCallback } from 'react'

import { IconButton, Box, Collapse, Grid, Paper } from '@material-ui/core'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'
import {
  ContainerTable,
  StackContainer,
  ButtonBox,
  Finder,
  Button,
  PageHeader,
  Stack,
} from '~/components'

import { AutoCompleteEvento, MUIAutoComplete } from '~/components/AutoComplete'

import Table from './components/Table'
import FormResumo from './components/FormResumo'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import { ReciboIDFEnum, ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'

const recibosIdfsFiltred = ReciboIDFValues.filter(
  (d) => d.value === ReciboIDFEnum.Mensal_1 || d.value === ReciboIDFEnum.Complementar_3,
)

const schema = yup.object().shape({
  eventoId: yup.string().required('Informe um Evento').nullable(),
})

const LancamentoColetivo = () => {
  const [data, setData] = useState({
    eventoId: '',
    evento: null,
    reciboIDF: ReciboIDFEnum.Mensal_1,
  })
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [formResumo, setFormResumo] = useState({
    isOpen: false,
  })
  const [query, setQuery] = useState('')
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { anoMes, estabelecimento } = useAmbiente()

  const resetData = useCallback(() => {
    setCollection({
      isLoading: false,
      itens: [],
    })
    setData({
      eventoId: '',
      evento: null,
      reciboIDF: ReciboIDFEnum.Mensal_1,
    })
  }, [])

  useEffect(() => {
    resetData()
  }, [estabelecimento, anoMes, resetData])

  useEffect(() => {
    const resetCollection = () => {
      setCollection({
        isLoading: false,
        itens: [],
      })
    }
    resetCollection()
  }, [data?.eventoId])

  const handleCarregar = useCallback(
    async () => {
      setCollection((oldState) => ({ ...oldState, isLoading: true }))
      try {
        const response = await api.get('/LancamentoColetivo/GetByEvento', {
          params: {
            estabelecimentoId: estabelecimento?.id,
            eventoId: data?.eventoId,
            anoMes,
          },
        })
        setCollection({
          itens: response.data.data,
          isLoading: false,
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setCollection((oldState) => ({ ...oldState, isLoading: false }))
      }
    },
    // eslint-disable-next-line
    [anoMes, estabelecimento, data],
  )

  const handleProcessar = useCallback(async () => {
    try {
      await api.get('/LancamentoColetivo/ProcessaEvento', {
        params: {
          estabelecimentoId: estabelecimento?.id,
          eventoId: data?.eventoId,
          anoMes,
          reciboIDF: data?.reciboIDF,
        },
      })
      notification.success('Lançamentos processados para o Recibo de Pagamento com sucesso.')
      resetData()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    // eslint-disable-next-line
  }, [anoMes, estabelecimento, data, resetData])

  const handleSubmit = useCallback(
    (event) => {
      if (event === 'carregar') {
        return handleCarregar()
      }
      return dialogNotification.info({
        descriptions: ['Essa operação vai processar os Lançamentos para o Recibo de Pagamento'],
        onConfirm: handleProcessar,
      })
    },
    // eslint-disable-next-line
    [data, handleCarregar, handleProcessar],
  )

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleOpenFormResumo = () => {
    setFormResumo({
      isOpen: true,
    })
  }

  const handleCloseFormResumo = () => {
    setFormResumo({
      isOpen: false,
    })
  }

  const handleQuery = useCallback(
    (q) => {
      handleCarregar()
      setQuery(q)
    },
    [handleCarregar],
  )

  return (
    <StackContainer>
      <Box id="root-actions">
        <PageHeader title="Lançamento Coletivo">
          <ButtonBox>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
            <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
              {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
            </IconButton>
          </ButtonBox>
        </PageHeader>

        <Box component={Paper} padding={1}>
          <Collapse in={formCollapseIsOpen}>
            <Grid container spacing={2}>
              <Grid item xl={10} lg={10} md={8} sm={8} xs={12}>
                <AutoCompleteEvento
                  value={data?.evento || null}
                  required
                  validationErrors={validationErrors}
                  name="eventoId"
                  onChange={(e, evento) => {
                    const eventoId = evento?.id || null
                    setData((prev) => ({ ...prev, evento, eventoId }))
                  }}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
                <Stack orientation="horizontal" alignItems="center" height="100%">
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={() => handleValidate('carregar')}
                  >
                    Carregar
                  </Button>

                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleOpenFormResumo}
                  >
                    Resumo
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Box>

      <ContainerTable>
        <Table data={collection.itens} isLoading={collection.isLoading} query={query} />
      </ContainerTable>

      <Stack
        orientation="horizontal"
        alignItems="center"
        justifyContent="flex-end"
        component={Paper}
        padding={1}
      >
        <Box width="400px">
          <MUIAutoComplete
            fullWidth
            label="Identificação do Recibo"
            options={recibosIdfsFiltred}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.reciboIDF}
            onChange={(e, obj) => {
              const reciboIDF = obj?.value || ReciboIDFEnum.Mensal_1
              setData((prev) => ({ ...prev, reciboIDF }))
            }}
          />
        </Box>

        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleValidate('processar')}
        >
          Processar
        </Button>
      </Stack>

      <FormResumo
        isOpen={formResumo.isOpen}
        onClose={handleCloseFormResumo}
        anoMes={anoMes}
        estabelecimento={estabelecimento}
      />
    </StackContainer>
  )
}

export default LancamentoColetivo
