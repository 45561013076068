import React from 'react'

import { ContainerComponent } from '~/components'

import Form from './components/Cadastro'
import SindicatoConvencaoColetiva from './components/SindicatoConvencaoColetiva'

export default function Container(props) {
  const { isOpen, onClose, data: sindicato } = props

  return (
    <ContainerComponent
      title="Cadastro de Sindicato"
      isOpen={isOpen}
      onClose={onClose}
      tabs={[
        {
          label: 'Cadastro',
          disabled: sindicato?.id ? false : true,
          component: <Form data={sindicato} onClose={onClose} />,
        },
        {
          component: <SindicatoConvencaoColetiva sindicato={sindicato} />,
          disabled: sindicato?.id ? false : true,
          label: 'Sindicato Convenção Coletiva',
        },
      ]}
    />
  )
}
