import { useAlert } from 'react-alert'

export const extract = (error) => {
  const responseError = error?.response?.data

  let typeExtracted = 'error'
  let descriptionsExtracted = responseError?.errors
  let detailsExtracted = responseError?.internalErrors

  if (!responseError) {
    if (error?.message) {
      return {
        typeExtracted: 'warning',
        descriptionsExtracted: [error.message],
        detailsExtracted,
      }
    }

    return {
      typeExtracted,
      descriptionsExtracted,
      detailsExtracted,
    }
  }

  if (error.response.status >= 500) {
    return {
      typeExtracted,
      descriptionsExtracted,
      detailsExtracted,
    }
  }

  if (error.response.status >= 400) {
    typeExtracted = 'warning'
    return {
      typeExtracted,
      descriptionsExtracted,
      detailsExtracted,
    }
  }
}

const useDialogNotification = () => {
  const alert = useAlert()

  const error = ({
    title = 'Algo Inesperado aconteceu!',
    descriptions = ['Ocorreu um erro ao tentar processar a requisição'],
    details = [],
    onConfirm = false,
    onCancel = false,
    labelOnConfirm = 'OK',
    labelOnCancel = 'Cancelar',
    time = 0,
  }) => {
    alert.show(null, {
      type: 'error',
      title,
      descriptions,
      details,
      onConfirm,
      onCancel,
      labelOnConfirm,
      labelOnCancel,
      time,
    })
  }

  const warning = ({
    title = 'Ocorreu um problema com a requisição',
    descriptions = ['Ocorreu um problema ao tentar processar a requisição'],
    details = [],
    onConfirm = false,
    onCancel = false,
    labelOnConfirm = 'OK',
    labelOnCancel = 'Cancelar',
    time = 0,
  }) => {
    alert.show(null, {
      type: 'warning',
      title,
      descriptions,
      details,
      onConfirm,
      onCancel,
      labelOnConfirm,
      labelOnCancel,
      time,
    })
  }

  const info = (props) => {
    const {
      title = 'Temos uma informação para você!',
      descriptions = [''],
      details = [],
      onConfirm = false,
      onCancel = false,
      labelOnConfirm = 'OK',
      labelOnCancel = 'Cancelar',
      time = 0,
    } = props

    alert.show(null, {
      type: 'info',
      title,
      descriptions,
      details,
      onConfirm,
      onCancel,
      labelOnConfirm,
      labelOnCancel,
      time,
    })
  }

  const success = ({
    title = 'Sucesso! Requisição foi feita com sucesso!',
    descriptions = ['A requisição foi feita com sucesso!'],
    details = [],
    onConfirm = false,
    onCancel = false,
    labelOnConfirm = 'OK',
    labelOnCancel = 'Cancelar',
    time = 0,
  }) => {
    alert.show(null, {
      type: 'success',
      title,
      descriptions,
      details,
      onConfirm,
      onCancel,
      labelOnConfirm,
      labelOnCancel,
      time,
    })
  }

  const extractErrors = async (err, options) => {
    const { typeExtracted, descriptionsExtracted, detailsExtracted } = extract(err)

    const descriptions =
      options?.descriptions?.length > 0 ? options.descriptions : descriptionsExtracted
    const details = options?.details?.length > 0 ? options.details : detailsExtracted

    const maps = {
      error: error,
      warning: warning,
    }

    maps[typeExtracted]({ descriptions, details, ...options })
  }

  return { extractErrors, error, warning, info, success }
}

export default useDialogNotification
