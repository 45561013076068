import React, { useState, useEffect } from 'react'

import { Grid, Box } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker } from '~/components'

const Form = (props) => {
  const { isOpen, onClose, data: _data } = props

  const [data, setData] = useState({})

  useEffect(() => {
    setData(_data)
  }, [_data])

  return (
    <ActionDialog
      title="Detalhes das Férias"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      customActions={<></>}
    >
      <Box pb={1}>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <DatePicker
              label="Data Início"
              size="small"
              value={data.dtPeriodoInicio || null}
              disabled
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <DatePicker label="Data Fim" size="small" value={data.dtPeriodoFim || null} disabled />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <DatePicker
              label="Data Limite Concessão"
              size="small"
              value={data.dtLimiteConcessao || null}
              disabled
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <DatePicker
              label="Data Programação"
              size="small"
              value={data.dtProgramacao || null}
              disabled
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <DatePicker
              label="Data Período Perdido"
              size="small"
              value={data?.dtPeriodoPerdido || null}
              disabled
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <DatePicker
              label="Data Período Concedido"
              size="small"
              value={data?.dtConcedida || null}
              disabled
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Observação"
              fullWidth
              value={data.observacao || ''}
              variant="outlined"
              size="small"
              multiline
              disabled
            />
          </Grid>
        </Grid>
      </Box>
    </ActionDialog>
  )
}

export default Form
