import React, { useState, useEffect } from 'react'

import * as yup from 'yup'
import { isCPF } from 'brazilian-values'
import { useHistory } from 'react-router-dom'

import { CircularProgress, Divider, Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import {
  Checkbox,
  DatePicker,
  NumeroInscricaoTextField,
  CurrencyTextField,
  ContainerForm,
} from '~/components'
import {
  AutoCompleteAgenteIntegracao,
  MUIAutoComplete,
  AutoCompleteInstituicaoEnsino,
} from '~/components/AutoComplete'

import {
  useVinculoEstagioMutation,
  useVinculoGetByVinculo,
} from '~/hooks/queries/useVinculoEstagio'

import { nivelEstagioValues } from '~/values/nivelEstagioValues'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import useValidationErrors from '~/hooks/useValidationErrors'

const schema = yup.object().shape({
  nivelEstagio: yup.string().required('Informe o Nível do Estágio'),
  dtPrevistaTermino: yup
    .date()
    .required('Informe a Data Prevista para o Término')
    .typeError('Informe uma data válida')
    .nullable(),
  supervisorCPF: yup
    .string()
    .nullable()
    .test('cpf-validate', 'Informe um CPF válido', (supervisorCPF) => {
      if (supervisorCPF?.length > 0) return isCPF(supervisorCPF)
      return true
    }),
  instituicaoEnsinoId: yup.string().required('Informe a Instituição de Ensino'),
})

const Form = (props) => {
  const { vinculoId } = props

  const [data, setData] = useState(null)

  const { mutateAsync, isLoading: isSubmiting } = useVinculoEstagioMutation()
  const { data: _data, isLoading } = useVinculoGetByVinculo(vinculoId)
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const history = useHistory()

  useEffect(() => {
    setData(_data)
  }, [_data])

  async function handleSubmit() {
    await mutateAsync(data)
    history.push({
      pathname: '/estagiario',
    })
  }

  return (
    <ContainerForm
      handleSubmit={handleValidate}
      isLoading={isSubmiting}
      onCancel={() =>
        history.push({
          pathname: '/estagiario',
        })
      }
    >
      {isLoading ? (
        <CircularProgress size={50} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
            <MUIAutoComplete
              label="Nível do Estágio"
              required
              validationErrors={validationErrors}
              name="nivelEstagio"
              options={nivelEstagioValues}
              renderOption={(option) => option.name}
              optionId="value"
              value={data?.nivelEstagio || ''}
              onChange={(e, obj) => {
                const nivelEstagio = obj ? obj.value : ''
                setData((oldState) => ({
                  ...oldState,
                  nivelEstagio,
                }))
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Checkbox
              label="Estágio Obrigatório"
              value={data?.naturezaEstagio || false}
              onChange={(e, value) => {
                const naturezaEstagio = value
                setData((oldState) => ({ ...oldState, naturezaEstagio }))
              }}
            />
          </Grid>

          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <CurrencyTextField
              label="Valor da Bolsa"
              fullWidth
              value={data?.vrBolsa || ''}
              variant="outlined"
              size="small"
              onChange={(e, value) => {
                const vrBolsa = value
                setData((oldState) => ({ ...oldState, vrBolsa }))
              }}
            />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <DatePicker
              label="Data Prevista para o Término"
              required
              validationErrors={validationErrors}
              name="dtPrevistaTermino"
              size="small"
              value={data?.dtPrevistaTermino || null}
              onChange={(date) => {
                const dtPrevistaTermino = date?.format('yyyy-MM-DD') || null
                setData((oldState) => ({
                  ...oldState,
                  dtPrevistaTermino,
                }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Área de Atuação"
              fullWidth
              value={data?.areaAtuacao || ''}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 50,
              }}
              onChange={(e) => {
                const areaAtuacao = e?.target?.value || ''
                setData((oldState) => ({ ...oldState, areaAtuacao }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Número da Apólice"
              fullWidth
              value={data?.numeroApolice || ''}
              variant="outlined"
              size="small"
              onlyNumber
              inputProps={{
                maxLength: 30,
              }}
              onChange={(e) => {
                const numeroApolice = e?.target?.value || ''
                setData((oldState) => ({ ...oldState, numeroApolice }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <AutoCompleteAgenteIntegracao
              value={data?.agenteIntegracaoId || ''}
              optionId="id"
              onChange={(e, obj) => {
                const agenteIntegracaoId = obj?.id || ''
                setData((oldState) => ({ ...oldState, agenteIntegracaoId }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <AutoCompleteInstituicaoEnsino
              value={data?.instituicaoEnsinoId || ''}
              optionId="id"
              required
              validationErrors={validationErrors}
              name="instituicaoEnsinoId"
              onChange={(e, obj) => {
                const instituicaoEnsinoId = obj?.id || ''
                setData((oldState) => ({ ...oldState, instituicaoEnsinoId }))
              }}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Nome do Supervisor"
              fullWidth
              value={data?.supervisorNome || ''}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 70,
              }}
              onChange={(e) => {
                const supervisorNome = e?.target?.value || ''
                setData((oldState) => ({ ...oldState, supervisorNome }))
              }}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <NumeroInscricaoTextField
              label="CPF do Supervisor"
              validationErrors={validationErrors}
              name="supervisorCPF"
              fullWidth
              typeMask={tipoInscricaoConsts.CPF_2}
              value={data?.supervisorCPF || ''}
              variant="outlined"
              size="small"
              onChange={(e, value) => {
                const supervisorCPF = value
                setData((oldState) => ({ ...oldState, supervisorCPF }))
              }}
            />
          </Grid>
        </Grid>
      )}
    </ContainerForm>
  )
}

export default Form
