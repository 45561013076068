import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

import { ImportarXMLS2220DataDTO } from './dtos/ImportarXMLS2220DataDTO'

interface RequestProps {
  params: { estabelecimentoId: string }
  data: FormData
}

export function useExtrairXML() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{ data: ImportarXMLS2220DataDTO }>(
      '/ImportarXMLS2220/ExtrairXML',
      data,
      {
        params,
      },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Atestado Saúde Ocupacional extraído com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
