import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterLancamentosTitular'

import { PlanoSaudeLancamentoDTO } from './dtos/PlanoSaudeLancamentoDTO'

export function useGerarLancamentoTitular() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest(data: PlanoSaudeLancamentoDTO) {
    await api.post('/PlanoSaudeLancamento/GerarLancamentoTitular', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
