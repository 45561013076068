import { useState } from 'react'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Finder,
  PageHeader,
  Stack,
} from '~/components'

import Table from './Table'
import Form from './Form'
import DialogCustoTitular from './DialogCustoTitular'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import { useObterPorVinculo } from '~/hooks/queries/PlanoSaudeTitular/useObterPorVinculo'
import { useDeletar } from '~/hooks/queries/PlanoSaudeTitular/useDeletar'
import { PlanoSaudeTitular } from '~/hooks/queries/PlanoSaudeTitular/dtos/PlanoSaudeTitular'

interface ContentProps {
  vinculo: VinculoMenuData
}

export default function Content({ vinculo }: ContentProps) {
  const vinculoId = vinculo.vinculoId

  const [query, setQuery] = useState('')

  const { anoMes } = useAmbiente()

  const {
    close: closeDialogCustoTitular,
    data: dataDialogCustoTitular,
    isOpen: isOpenDialogCustoTitular,
    open: openDialogCustoTitular,
  } = useDialog('')

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<PlanoSaudeTitular>({} as PlanoSaudeTitular)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterPorVinculo({
    params: {
      vinculoId,
    },
  })
  const data = _d || []

  const { mutateAsync: mutateAsyncDeletar, isLoading: isRemoving } = useDeletar()

  function handleClickItem(event: 'edit' | 'delete' | 'custo', value: string) {
    switch (event) {
      case 'custo':
        openDialogCustoTitular(value)
        break
      case 'edit':
        handleClickEdit(value)
        break
      case 'delete':
        openConfirmDelete(value)
        break
    }
  }

  function handleClickEdit(id: string) {
    const dtCurrent = data.find((d) => d.id === id)
    if (dtCurrent) {
      openForm(dtCurrent)
    }
  }

  async function handleDeleteItem() {
    await mutateAsyncDeletar({
      id: dataConfirmDelete,
      params: {
        anoMes,
      },
    })
    closeConfirmDelete()
  }

  async function handleAfterSubmit(event: 'create' | 'update', d: PlanoSaudeTitular) {
    closeForm()
    switch (event) {
      case 'create':
        openForm(d)
        break
    }
  }

  return (
    <Stack height="100%">
      <PageHeader
        title="Plano de Saúde - Manutenção de Mensalidade"
        subtitle={vinculo?.vinculoNome || '-'}
      >
        {vinculoId && (
          <ButtonBox>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <Button
              size="small"
              color="primary"
              onClick={() =>
                openForm({
                  vinculoId,
                } as PlanoSaudeTitular)
              }
              variant="contained"
            >
              Adicionar
            </Button>
          </ButtonBox>
        )}
      </PageHeader>

      <ContainerTable>
        <Table
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          query={query}
          onItemClick={handleClickItem}
        />
      </ContainerTable>

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          initialData={dataForm}
          afterSubmit={handleAfterSubmit}
        />
      )}

      {isOpenDialogCustoTitular && (
        <DialogCustoTitular
          isOpen={isOpenDialogCustoTitular}
          onClose={closeDialogCustoTitular}
          planoSaudeTitularId={dataDialogCustoTitular}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isRemoving}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}
    </Stack>
  )
}
