import React from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import moment from 'moment'

import { Box } from '@material-ui/core'

import { HeightDataTable } from '~/components'

import { usePessoaFisicaObterUsoPessoaFisica } from '~/hooks/queries/PessoaFisica/usePessoaFisicaObterUsoPessoaFisica'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface EstabelecimentosProps {
  pessoaFisicaId: string
}

export default function Estabelecimentos(props: EstabelecimentosProps) {
  const { pessoaFisicaId } = props

  const { data: _data, isLoading, isFetching } = usePessoaFisicaObterUsoPessoaFisica(pessoaFisicaId)

  const data = _data
    ? _data.map((d) => ({
        ...d,
        nrInscricao: formatToCPFOrCNPJ(d.nrInscricao),
        dtAdmissao: moment(d.dtAdmissao).format('DD/MM/yyyy'),
        dtRescisao: d?.dtRescisao ? moment(d.dtRescisao).format('DD/MM/yyyy') : '',
      }))
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'nrInscricao',
      label: 'Número de Inscrição',
    },
    {
      name: 'dtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'dtRescisao',
      label: 'Rescisão',
    },
  ]

  return (
    <Box height="100%" pt={2}>
      <HeightDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </Box>
  )
}
