import React from 'react'

import { makeStyles } from '@material-ui/core'
import { ActionDialog } from '~/components'

import PageTabelaMes from '~/pages/TabelaMes'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '100%',
  },
}))

export default function DialogTabelaMes(props) {
  const { isOpen, onClose } = props

  const classes = useStyles()

  return (
    <ActionDialog
      title="Tabela Mês"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      customActions={<></>}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <PageTabelaMes noTitle />
    </ActionDialog>
  )
}
