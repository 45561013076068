import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EstabelecimentoConsulta } from './EstabelecimentoConsulta'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'

export const estabelecimentoObterFiltradoQuery = '/EstabelecimentoConsulta/ObterFiltrado'

interface RequestProps {
  indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum
  anoMes?: string
  grupoId?: string
}

export function useEstabelecimentoObterFiltrado(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params.anoMes) return
    const response = await api.get<{ data: EstabelecimentoConsulta[] }>(
      estabelecimentoObterFiltradoQuery,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([estabelecimentoObterFiltradoQuery, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
