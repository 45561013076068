import React from 'react'

import { CurrencyTextField, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { SimularRescisaoVinculo } from '..'

interface TableProps {
  value: SimularRescisaoVinculo[]
  onChange: (data: SimularRescisaoVinculo[]) => void
  rowsSelected: number[]
  setRowsSelected: (indexs: number[]) => void
  isLoading: boolean
  query: string
}

export default function Table(props: TableProps) {
  const { value, onChange, isLoading, setRowsSelected, rowsSelected, query } = props

  function changeRow(
    field: 'diasAvisoPrevioIndenizados' | 'diasAvisoPrevioTrabalhados' | 'vrSaldoFGTS',
    valueCurrent: number,
    index: number,
  ) {
    const rowCurrent = value[index]
    const newData = value.map((d) => {
      if (d.vinculoId === rowCurrent.vinculoId) {
        return {
          ...d,
          [field]: valueCurrent,
        }
      }
      return d
    })
    onChange(newData)
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'vinculoNrInscricao',
      label: 'CPF',
    },
    {
      name: 'vinculoNome',
      label: 'Nome',
    },
    {
      name: 'diasAvisoPrevioTrabalhados',
      label: 'Avio Prévio Dias Trabalhados',
      options: {
        customBodyRender: (value, { rowIndex }) =>
          rowsSelected.includes(rowIndex) ? (
            <CurrencyTextField
              fullWidth
              value={value}
              onBlur={(e, newValue) => changeRow('diasAvisoPrevioTrabalhados', newValue, rowIndex)}
            />
          ) : (
            <div style={{ textAlign: 'end' }}>0,00</div>
          ),
        setCellHeaderProps: () => ({
          style: { width: '15rem' },
        }),
      },
    },
    {
      name: 'diasAvisoPrevioIndenizados',
      label: 'Avio Prévio Dias Indenizados',
      options: {
        customBodyRender: (value, { rowIndex }) =>
          rowsSelected.includes(rowIndex) ? (
            <CurrencyTextField
              fullWidth
              value={value}
              onBlur={(e, newValue) => changeRow('diasAvisoPrevioIndenizados', newValue, rowIndex)}
            />
          ) : (
            <div style={{ textAlign: 'end' }}>0,00</div>
          ),
        setCellHeaderProps: () => ({
          style: { width: '15rem' },
        }),
      },
    },
    {
      name: 'vrSaldoFGTS',
      label: 'Saldo FGTS',
      options: {
        customBodyRender: (value, { rowIndex }) =>
          rowsSelected.includes(rowIndex) ? (
            <CurrencyTextField
              fullWidth
              value={value}
              onBlur={(e, newValue) => changeRow('vrSaldoFGTS', newValue, rowIndex)}
            />
          ) : (
            <div style={{ textAlign: 'end' }}>0,00</div>
          ),
        setCellHeaderProps: () => ({
          style: { width: '15rem' },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={value}
      disableAutoHeight
      isLoading={isLoading}
      sherlock={{
        columns: ['vinculoNrInscricao', 'vinculoNome'],
        query,
      }}
      columns={columns}
      optionsSelectable={{
        onRowSelected: setRowsSelected,
        rowsSelected: rowsSelected,
        type: 'multiple',
      }}
    />
  )
}
