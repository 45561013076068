import { formatToCPF } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo } from 'react'
import {
  TipoContratoProcessoEnum,
  TipoContratoProcessoLabels,
} from '~/@types/enums/TipoContratoProcessoEnum'
import { ActionDialog, Button, ButtonBox, ContainerTable, ToolsDataTable } from '~/components'

import { useObterProcessosTrabalhistasFuncionarios } from '~/hooks/queries/ProcessoTrabalhista/useObterProcessosTrabalhistasFuncionarios'
import { formatSimpleDate } from '~/utils/utils'

interface DialogProcessosFuncProps {
  isOpen: boolean
  onClose: () => void
  processoTrabalhistaId: string
}

export default function DialogProcessosFunc({
  isOpen,
  onClose,
  processoTrabalhistaId,
}: DialogProcessosFuncProps) {
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterProcessosTrabalhistasFuncionarios({
    processoTrabalhistaId,
  })
  const _data = useMemo(() => _d || [], [_d])

  const data = useMemo(
    () =>
      _data.map((d) => ({
        ...d,
        funcionarioNrInscricao: d.vinculo?.pessoaFisica?.nrInscricao || '',
        funcionarioNome: d.vinculo?.pessoaFisica?.nome || '',
      })),
    [_data],
  )

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtEmissao',
      label: 'Emissão',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'funcionarioNrInscricao',
      label: 'CPF',
      options: {
        customBodyRender: formatToCPF,
      },
    },
    {
      name: 'funcionarioNome',
      label: 'Nome',
    },
    {
      name: 'tipoContratoProcesso',
      label: 'Tipo de Contrato',
      options: {
        customBodyRender: (value: TipoContratoProcessoEnum) => TipoContratoProcessoLabels[value],
      },
    },
    {
      name: 'reciboEsocial',
      label: 'Recibo eSocial',
    },
  ]

  return (
    <ActionDialog
      title="Processo Trabalhista Funcionário"
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      customActions={
        <ButtonBox bottom={1} right={1} top={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          disableAutoHeight
          pagination={false}
        />
      </ContainerTable>
    </ActionDialog>
  )
}
