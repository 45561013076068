import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker, Checkbox } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import * as yup from 'yup'

import useValidationErrors from '~/hooks/useValidationErrors'

import { IndAcordoColetivoValues } from '~/@types/enums/IndAcordoColetivoEnum'
import { useSubmit } from '~/hooks/queries/useAcordoDissidioConvencao'

const schema = yup.object().shape({
  dtADC: yup.string().required('Informe a Data Acordo').nullable(),
  dtEfetiva: yup.string().required('Informe a Data Efetiva').nullable(),
  indADC: yup.string().required('Informe o Tipo Acordo'),
  descricao: yup.string().required('Informe a Descrição'),
})

const CadastroAcordoDissidioConvencao = (props) => {
  const { isOpen, onClose, data: _data } = props
  const [data, setData] = useState({})

  const { mutateAsync, isLoading } = useSubmit()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(async () => {
    await mutateAsync(data)
    onClose()
  }, [data, mutateAsync, onClose])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Acordo"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Acordo"
            size="small"
            value={data?.dtADC || null}
            required
            validationErrors={validationErrors}
            name="dtADC"
            onChange={(date) => {
              const dtADC = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtADC })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Efetiva"
            size="small"
            value={data?.dtEfetiva || null}
            required
            validationErrors={validationErrors}
            name="dtEfetiva"
            onChange={(date) => {
              const dtEfetiva = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtEfetiva })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Checkbox
            label="Remuneração de Sucessão"
            value={data?.isRemuneracaoSucessao || false}
            onChange={(e, value) => {
              const isRemuneracaoSucessao = value
              setData({ ...data, isRemuneracaoSucessao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Tipo Acordo"
            value={data?.indADC}
            required
            validationErrors={validationErrors}
            name="indADC"
            onChange={(e, obj) => {
              const indADC = obj ? obj.value : ''
              setData({ ...data, indADC })
            }}
            options={IndAcordoColetivoValues}
            renderOption={(option) => option.name}
            optionId="value"
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Descrição"
            fullWidth
            value={data?.descricao || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 200,
            }}
            validationErrors={validationErrors}
            name="descricao"
            onChange={(e) => {
              const descricao = e.target.value
              setData({ ...data, descricao })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default CadastroAcordoDissidioConvencao
