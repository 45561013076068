export enum IndSalarioContratualEnum {
  Minimo = 1,
  CategoriaSindical = 2,
  Valor = 3,
  TabelaSalario = 4,
}

export const IndSalarioContratualLabels = {
  [IndSalarioContratualEnum.Minimo]: '1 - Salário Mínimo',
  [IndSalarioContratualEnum.CategoriaSindical]: '2 - Convenção Coletiva',
  [IndSalarioContratualEnum.Valor]: '3 - Valor',
  [IndSalarioContratualEnum.TabelaSalario]: '4 - Tabela Salário',
}

export const IndSalarioContratualValues = [
  { value: IndSalarioContratualEnum.Minimo, name: '1 - Salário Mínimo' },
  { value: IndSalarioContratualEnum.CategoriaSindical, name: '2 - Convenção Coletiva' },
  { value: IndSalarioContratualEnum.Valor, name: '3 - Valor' },
  { value: IndSalarioContratualEnum.TabelaSalario, name: '4 - Tabela Salário' },
]
