import React, { useState, useEffect, useCallback } from 'react'

import { ActionDialog } from '~/components'

import {
  FormAgenteNocivo,
  schema,
} from '~/pages/CondicaoAmbienteTrabalho/components/Container/components/AgenteNocivo/components/Form'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, setor } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/SetorAgenteNocivo/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/SetorAgenteNocivo', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Setor Agente Nocivo"
      subtitle={setor ? `${setor?.codigo} - ${setor?.nome}` : '-'}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
    >
      <FormAgenteNocivo data={data} setData={setData} validationErrors={validationErrors} />
    </ActionDialog>
  )
}

export default Form
