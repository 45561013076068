import { Box } from '@material-ui/core'

interface OptionDescription {
  description: string
}

interface AutoCompleteOptionDescription {
  title: string
  descriptions: OptionDescription[]
}

export default function AutoCompleteOptionDescription({
  title,
  descriptions,
}: AutoCompleteOptionDescription) {
  return (
    <Box width="100%">
      <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" title={title}>
        {title}
      </Box>
      <Box color="text.secondary" width="100%">
        {descriptions.map((d, index) => (
          <Box
            key={d.description + index}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            title={d.description}
          >
            {d.description}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
