import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'
import * as yup from 'yup'
import _ from 'lodash'

import { Box, Grid, Paper } from '@material-ui/core'
import { Button, Finder } from 'mio-library-ui'

import { AnoMesTextField, PageHeader, PDFViewer, PrintButton } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import {
  tipoMovimentoAdmissaoDemissaoConsts,
  tipoMovimentoAdmissaoDemissaoValues,
} from '~/values/tipoMovimentoAdmissaoDemissao'

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

export default function ResumoAdmissaoDemissao() {
  const [collection, setCollection] = useState({
    estabelecimentos: [],
  })
  const [rowsSelecteds, setRowsSelecteds] = useState([])
  const [rowsExpanded, setRowsExpanded] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [data, setData] = useState({
    anoMesInicio: '',
    anoMesFim: '',
    tipoMovimento: tipoMovimentoAdmissaoDemissaoConsts.todos_0,
  })

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const { anoMes } = useAmbiente()
  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit: handleSearch,
  })

  useEffect(() => {
    setData({
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
      tipoMovimento: tipoMovimentoAdmissaoDemissaoConsts.todos_0,
    })
  }, [anoMes])

  useEffect(() => {
    setCollection({
      estabelecimentos: [],
    })
    setRowsSelecteds([])
    setRowsExpanded([])
  }, [data])

  async function handleSearch() {
    setLoading(true)
    try {
      const response = await api.post('/Relatorio/ResumoAdmissaoDemissao/ObterEstabelecimentos', {
        anoMesInicio: data.anoMesInicio,
        anoMesFim: data.anoMesFim,
        tipoMovimento: data.tipoMovimento,
      })
      setCollection(response?.data?.data)
      notification.success()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  function handlePrint() {
    const estabelecimentos = rowsSelecteds.map((rowIndex) => collection.estabelecimentos[rowIndex])
    const estabelecimentosInOrder = _.orderBy(estabelecimentos, 'nome')
    openPDFViewer({
      ...collection,
      estabelecimentos: estabelecimentosInOrder,
    })
    setData({
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
      tipoMovimento: tipoMovimentoAdmissaoDemissaoConsts.todos_0,
    })
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Resumo Admissão e Demissão">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Box component={Paper} padding={2}>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <AnoMesTextField
              label="Ano/Mês Início"
              value={data.anoMesInicio}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  anoMesInicio: e?.target?.value?.replace('/', '') || '',
                }))
              }
              required
              validationErrors={validationErrors}
              name="anoMesInicio"
            />
          </Grid>

          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <AnoMesTextField
              label="Ano/Mês Fim"
              value={data.anoMesFim}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  anoMesFim: e?.target?.value?.replace('/', '') || '',
                }))
              }
              required
              validationErrors={validationErrors}
              name="anoMesFim"
            />
          </Grid>

          <Grid item xl={5} lg={5} md={10} sm={10} xs={12}>
            <MUIAutoComplete
              label="Tipo de Movimentação"
              options={tipoMovimentoAdmissaoDemissaoValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.tipoMovimento}
              onChange={(e, obj) => {
                const tipoMovimento = obj?.value || tipoMovimentoAdmissaoDemissaoConsts.todos_0
                setData((prev) => ({
                  ...prev,
                  tipoMovimento,
                }))
              }}
            />
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={2}
            sm={2}
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              isLoading={isLoading}
              onClick={handleValidate}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Table
        data={collection.estabelecimentos}
        query={query}
        isLoading={isLoading}
        rowsSelected={rowsSelecteds}
        handleRowSelected={setRowsSelecteds}
        rowsExpanded={rowsExpanded}
        setRowsExpanded={setRowsExpanded}
      />
      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="ResumoAdmissaoDemissao"
        title="Resumo Admissão e Demissão"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/ResumoAdmissaoDemissao/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
      <Box display="flex" justifyContent="flex-end" pt={1}>
        <PrintButton disabled={!(rowsSelecteds.length > 0)} onClick={handlePrint} />
      </Box>
    </Box>
  )
}
