import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import { Checkbox, DatePicker } from '~/components'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import { MUIAutoComplete } from '~/components/AutoComplete'
import { processoIndicativoSuspencaoValues } from '~/values/processoIndicativoSuspencaoValues'

const schema = yup.object().shape({
  codigoSuspensao: yup.string().required('Informe o Código Suspensão'),
  dtDecisaoSuspensao: yup.string().required('Informe a Data Suspensão').nullable(),
  indSuspensao: yup.string().required('Informe o Ind. Suspensão'),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/ProcessoSituacaoItem/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/ProcessoSituacaoItem', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Item do Processo Situação"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TextField
            label="Código Suspensão"
            fullWidth
            value={data.codigoSuspensao || ''}
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 9 }}
            onlyNumber
            required
            validationErrors={validationErrors}
            name="codigoSuspensao"
            onChange={(e) => {
              const codigoSuspensao = e.target.value
              setData({ ...data, codigoSuspensao })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePicker
            label="Data Suspensão"
            size="small"
            value={data.dtDecisaoSuspensao || null}
            required
            validationErrors={validationErrors}
            name="dtDecisaoSuspensao"
            onChange={(date) => {
              const dtDecisaoSuspensao = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtDecisaoSuspensao })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Checkbox
            label="Depósito"
            value={data?.isDeposito || false}
            onChange={(e, value) => {
              const isDeposito = value
              setData({ ...data, isDeposito })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Ind. Suspensão"
            options={processoIndicativoSuspencaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.indSuspensao}
            required
            validationErrors={validationErrors}
            name="indSuspensao"
            onChange={(e, obj) => {
              const indSuspensao = obj ? obj.value : ''
              setData({ ...data, indSuspensao })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
