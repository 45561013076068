import api from '~/services/api-pessoal'

import { CondicaoAmbienteTrabalhoSaveDTO } from './CondicaoAmbienteTrabalhoSaveDTO'

export async function obterCondicaoAmbienteTrabalhoCompleto(
  condicaoAmbienteTrabalhoId: string,
): Promise<CondicaoAmbienteTrabalhoSaveDTO | null> {
  const response = await api.get(
    '/CondicaoAmbienteTrabalho/ObterCondicaoAmbienteTrabalhoCompleto',
    {
      params: {
        condicaoAmbienteTrabalhoId,
      },
    },
  )
  return response?.data?.data || null
}
