import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterTodosInvalidate } from './useObterTodos'

import { notifyPost } from '~/utils/notification'
import { ProcessoTrabalhistaTributosPeriodoContribuicao } from './dtos/ProcessoTrabalhistaTributosPeriodoContribuicao'

export function useCriar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosInvalidate()

  async function handleRequest(data: ProcessoTrabalhistaTributosPeriodoContribuicao) {
    const response = await api.post('/ProcessoTrabalhistaTributosPeriodoContribuicao', data)
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
