import React from 'react'

import { Box, CircularProgress } from '@material-ui/core'

import useSimpleStartRequest from '~/hooks/queries/useSimpleStartRequest'

import { IndMotivoDesligamentoEnum } from '~/@types/enums/IndMotivoDesligamentoEnum'

interface ContentProps {
  vinculoRescisaoSimuladaId: string
}

export interface VinculoRescisaoSimuladaData {
  id: string
  vinculoId: string
  dtRescisao: string
  motivoDesligamento: IndMotivoDesligamentoEnum
  diasAvisoPrevioIndenizados: number
  diasAvisoPrevioTrabalhados: number
  vrSaldoFGTS: number
  pdf: string | null
  vrTotalDesconto: number
  vrTotalLiquido: number
  vrTotalVencimento: number
  vrFGTS: number
  vrTotalRecolherFGTS: number
}

export default function Content(props: ContentProps) {
  const { vinculoRescisaoSimuladaId } = props

  const { data, isLoading } = useSimpleStartRequest<VinculoRescisaoSimuladaData | null>(
    {
      url: `/VinculoRescisaoSimulada/${vinculoRescisaoSimuladaId}`,
      method: 'GET',
    },
    null,
    [vinculoRescisaoSimuladaId],
  )

  if (isLoading)
    return (
      <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={70} />
      </Box>
    )

  if (!data) return <></>

  return (
    <Box height="100%">
      {data.pdf ? (
        <iframe
          title="Rescisão Simulada"
          src={'data:application/pdf;base64,' + data.pdf}
          height="100%"
          width="100%"
        />
      ) : (
        <>Arquivo não encontrado</>
      )}
    </Box>
  )
}
