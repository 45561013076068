import theme from '~/styles/theme'
import { convertToArrayDescription } from '~/utils/utils'

export enum IndNotificacaoEnum {
  Normal_00,
  Medio_01,
  Alto_02,
}

export const IndNotificacaoLabel = {
  [IndNotificacaoEnum.Normal_00]: 'Normal',
  [IndNotificacaoEnum.Medio_01]: 'Médio',
  [IndNotificacaoEnum.Alto_02]: 'Alto',
}

export const IndNotificacaoColor = {
  [IndNotificacaoEnum.Normal_00]: theme.palette.success.main,
  [IndNotificacaoEnum.Medio_01]: theme.palette.warning.main,
  [IndNotificacaoEnum.Alto_02]: theme.palette.error.main,
}

export const IndNotificacaoValues = convertToArrayDescription(
  IndNotificacaoEnum,
  IndNotificacaoLabel,
)
