import { useEffect, useState } from 'react'

import { PageHeader, Stack, TreeView } from '~/components'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'

import useAmbiente from '~/hooks/useAmbiente'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import Content from './Content'
import { Box, Typography } from '@material-ui/core'

export default function AtualizarCTPS() {
  const [vinculo, setVinculo] = useState<VinculoMenuData | null>(null)

  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])
  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    setVinculo(null)
  }, [estabelecimento, anoMes])

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(_, obj: VinculoMenuData) => setVinculo(obj)}
      searchBy="vinculoNome"
      renderOption={(option: VinculoMenuData) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
    >
      <Stack height="100%" gap={0.5}>
        <PageHeader title="Atualizar CTPS" />
        <Box flex={1}>
          {vinculo ? (
            <Content vinculoId={vinculo.vinculoId} />
          ) : (
            <Typography>Selecione um Funcionário</Typography>
          )}
        </Box>
      </Stack>
    </TreeView>
  )
}
