import { useState } from 'react'

import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { Box, Grid, makeStyles, useTheme } from '@material-ui/core'
import { ActionDialog, ContentDivider, TextField, DatePickerNew } from '~/components'

import DiasSemana from './DiasSemana'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import { orderBy } from '~/hooks/useUtils'

import { VinculoHorario } from '~/hooks/queries/VinculoHorario/VinculoHorario'

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '100%',
  },
}))

const schema = yup.object().shape({
  dtIncio: yup.string().required('Informe a Data de Início').nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  afterSubmit: (event: 'update' | 'insert', data: VinculoHorario) => void
  data: Partial<VinculoHorario>
}

export function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, afterSubmit } = props

  const [data, setData] = useState(_data)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()
  const theme = useTheme()

  const handleSubmit = () => {
    const diasSemana = data.vinculoHorarioItens || []

    if (!(diasSemana.length > 0)) {
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um dia da semana para prosseguir'],
      })
    }

    const update = () => {
      const horarioTrabalho = {
        ...data,
        vinculoHorarioItens: diasSemana.map((d) => ({
          ...d,
          vinculoHorarioId: data.id as string,
        })),
      } as VinculoHorario

      afterSubmit('update', horarioTrabalho)
      notification.put()
    }

    const insert = () => {
      data.id = uuidv4()

      const horarioTrabalho = {
        ...data,
        vinculoHorarioItens: diasSemana.map((d) => ({
          ...d,
          vinculoHorarioId: data.id as string,
        })),
      } as VinculoHorario

      afterSubmit('insert', horarioTrabalho)
      notification.post()
    }

    if (data.id) {
      return update()
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Horário de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: {
          paper: classes.heightPaper,
        },
      }}
    >
      <Box height="100%" display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <DatePickerNew
              label="Data de início"
              size="small"
              value={data?.dtIncio || null}
              validationErrors={validationErrors}
              name="dtIncio"
              required
              onChange={(date) => {
                const dtIncio = date || undefined
                setData({ ...data, dtIncio })
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Observação"
              fullWidth
              value={data?.observacao || ''}
              size="small"
              inputProps={{
                maxLength: 200,
              }}
              multiline
              onChange={(e) => {
                const observacao = e?.target.value || ''
                setData({ ...data, observacao })
              }}
            />
          </Grid>
        </Grid>

        <ContentDivider />

        <Box minHeight={200} flex={1} overflow="auto" position="relative">
          <Box width="100%" height="100%" position="absolute">
            <DiasSemana
              data={orderBy(data.vinculoHorarioItens || [], 'diaHorario')}
              onSubmit={(d) => {
                setData((prev) => ({ ...prev, vinculoHorarioItens: d }))
              }}
            />
          </Box>
        </Box>
      </Box>
    </ActionDialog>
  )
}

export default Form
