export enum IndReciboChartEnum {
  Mensal_00,
  Ferias_01,
  Rescisao_02,
  Outros_03,
}

export const IndReciboChartLabel = {
  [IndReciboChartEnum.Mensal_00]: 'Mensal',
  [IndReciboChartEnum.Ferias_01]: 'Ferias',
  [IndReciboChartEnum.Rescisao_02]: 'Rescisao',
  [IndReciboChartEnum.Outros_03]: 'Outros',
}

export const IndReciboChartColor = {
  [IndReciboChartEnum.Mensal_00]: '#78ABA8',
  [IndReciboChartEnum.Ferias_01]: '#C8CFA0',
  [IndReciboChartEnum.Rescisao_02]: '#FCDC94',
  [IndReciboChartEnum.Outros_03]: '#EF9C66',
}
