import { convertToArrayDescription } from '~/utils/utils'

export enum TipoContratoProcessoEnum {
  Formalizado_Sem_Alteracoes_Admissao_Desligamento_1 = 1,
  Formalizado_Com_Alteracao_Admissao_2 = 2,
  Formalizado_Com_Inclusao_Ou_Alteracao_Dt_Desligamento_3 = 3,
  Formalizado_Com_Alteracao_DtAdmissao_DtDesligamento_4 = 4,
  Empregado_Reconhecimento_Vinculo_5 = 5,
  Vinculo_TSV_Sem_Reconhecimento_Empregaticio_6 = 6,
  Vinculo_Com_Vinculo_Anterior_ESocial_7 = 7,
  Responsabilidade_Indireta_8 = 8,
  Trabalhador_Cujos_Contratos_Unificados_9 = 9,
}

export const TipoContratoProcessoLabels = {
  [TipoContratoProcessoEnum.Formalizado_Sem_Alteracoes_Admissao_Desligamento_1]:
    '1 - Trabalhador com vínculo formalizado, sem alteração nas datas de admissão e de desligamento',
  [TipoContratoProcessoEnum.Formalizado_Com_Alteracao_Admissao_2]:
    '2 - Trabalhador com vínculo formalizado, com alteração na data de admissão',
  [TipoContratoProcessoEnum.Formalizado_Com_Inclusao_Ou_Alteracao_Dt_Desligamento_3]:
    '3 - Trabalhador com vínculo formalizado, com inclusão ou alteração de data de desligamento',
  [TipoContratoProcessoEnum.Formalizado_Com_Alteracao_DtAdmissao_DtDesligamento_4]:
    '4 - Trabalhador com vínculo formalizado, com alteração na data de admissão e inclusão ou alteração de data de desligamento',
  [TipoContratoProcessoEnum.Empregado_Reconhecimento_Vinculo_5]:
    '5 - Empregado com reconhecimento de vínculo',
  [TipoContratoProcessoEnum.Vinculo_TSV_Sem_Reconhecimento_Empregaticio_6]:
    '6 - Trabalhador sem vínculo de emprego/estatutário (TSVE), sem reconhecimento de vínculo empregatício',
  [TipoContratoProcessoEnum.Vinculo_Com_Vinculo_Anterior_ESocial_7]:
    '7 - Trabalhador com vínculo de emprego formalizado em período anterior ao eSocial',
  [TipoContratoProcessoEnum.Responsabilidade_Indireta_8]: '8 - Responsabilidade indireta',
  [TipoContratoProcessoEnum.Trabalhador_Cujos_Contratos_Unificados_9]:
    '9 - Trabalhador cujos contratos foram unificados (unicidade contratual)',
}

export const TipoContratoProcessoValues = convertToArrayDescription(
  TipoContratoProcessoEnum,
  TipoContratoProcessoLabels,
)
