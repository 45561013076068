import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { RelFeriasVencidasDTO } from './dtos/RelFeriasVencidasDTO'

const query = '/Relatorio/FeriasVencidas/ObterFeriasVencidas'

interface RequestProps {
  data: string[]
  params: { dtLimite: string; anoMes: string }
}

export default function useObterFeriasVencidas({ data, params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: RelFeriasVencidasDTO[] }>(query, data, {
      params,
    })
    return response.data.data
  }

  return useQuery([data, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
