import { convertToArrayDescription } from '~/utils/utils'

export enum FPMovimentoTipoEnum {
  ReciboMensal = 0,
  Ferias = 1,
  Rescisao = 2,
  GRFC = 3,
  ProLabore = 4,
  RPA = 5,
  DecimoTerceiroPrimeiraParcela = 6,
  DecimoTerceiroSegundaParcela = 7,
  ParametrosGerais = 999,
}

export const FPMovimentoTipoLabel = {
  [FPMovimentoTipoEnum.ReciboMensal]: 'Recibo Mensal',
  [FPMovimentoTipoEnum.Ferias]: 'Férias',
  [FPMovimentoTipoEnum.Rescisao]: 'Rescisão',
  [FPMovimentoTipoEnum.GRFC]: 'GRFC',
  [FPMovimentoTipoEnum.ProLabore]: 'Pró-Labore',
  [FPMovimentoTipoEnum.RPA]: 'Recibo Autônomo',
  [FPMovimentoTipoEnum.DecimoTerceiroPrimeiraParcela]: '13° Primeira Parcela',
  [FPMovimentoTipoEnum.DecimoTerceiroSegundaParcela]: '13° Segunda Parcela',
  [FPMovimentoTipoEnum.ParametrosGerais]: 'Parâmetros Gerais',
}

export const FPMovimentoTipoValues = convertToArrayDescription(
  FPMovimentoTipoEnum,
  FPMovimentoTipoLabel,
)
