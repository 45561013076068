export enum MotivoAfastamentoEnum {
  Doenca = 1,
  AcidenteTrabalho = 2,
  Ferias = 3,
  AcidenteOutros = 4,
}

export const MotivoAfastamentoValues = [
  { name: 'Doença', value: MotivoAfastamentoEnum.Doenca },
  { name: 'Acidente do Trabalho', value: MotivoAfastamentoEnum.AcidenteTrabalho },
  { name: 'Férias', value: MotivoAfastamentoEnum.Ferias },
  { name: 'Acidente de Qualquer Natureza', value: MotivoAfastamentoEnum.AcidenteOutros },
]
