import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import { openDownloadData } from '~/hooks/useUtils'

export interface RequestProps {
  empregadorId: string
  anoBase: number
  nrInscricaoSocioResponsavel: string
  nrInscricaoResponsavelArquivo: string
}

export function useDIRFGerarArquivo() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    const response = await api.post('/DIRF/GerarArquivo', null, {
      params: {
        ...props,
      },
    })
    openDownloadData('DIRF.txt', response.data)

    notification.success('Arquivo DIRF gerado com sucesso')
  }

  return useMutation(handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
