import { useMemo } from 'react'

import { Box } from '@material-ui/core'

import { CurrencyTextField, DatePickerNew, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'

import {
  AutonomoManutencaoDTO,
  AutonomoManutencaoEstabelecimentoDTO,
} from '~/hooks/queries/Autonomo/AutonomoManutencaoDTO'

interface TableNovoSalarioProps {
  query: string
  isLoading: boolean
  isFetching: boolean
  data: AutonomoManutencaoEstabelecimentoDTO[]
  dataSubmit: AutonomoManutencaoDTO[]
  changeDataSubmit: (dt: AutonomoManutencaoDTO[]) => void
}

export default function TableNovoSalario({
  data,
  isFetching,
  isLoading,
  query,
  dataSubmit,
  changeDataSubmit,
}: TableNovoSalarioProps) {
  const columns: MUIDataTableColumnDef[] = useMemo(() => {
    return [
      {
        name: 'autonomoId',
        label: 'Novo Salário',
        options: {
          sort: false,
          customBodyRender: (value) => {
            const dtCurrent = dataSubmit.find((d) => d.autonomoId === value)
            if (!dtCurrent) return
            return (
              <Box p={0.5}>
                <DatePickerNew
                  value={dtCurrent.dtNovoSalario || ''}
                  onChange={(date) => {
                    const dtNovoSalario = date || ''
                    if (!dtNovoSalario) return
                    changeDataSubmit(
                      dataSubmit.map((d) =>
                        d.autonomoId === dtCurrent.autonomoId ? { ...dtCurrent, dtNovoSalario } : d,
                      ),
                    )
                  }}
                  autoOk
                />
              </Box>
            )
          },
        },
      },
      {
        name: 'autonomoId',
        label: 'Novo Valor',
        options: {
          sort: false,
          customBodyRender: (value) => {
            const dtCurrent = dataSubmit.find((d) => d.autonomoId === value)
            if (!dtCurrent) return
            return (
              <Box p={0.5}>
                <CurrencyTextField
                  value={dtCurrent.valorNovoSalario || ''}
                  onBlur={(e, value) => {
                    const valorNovoSalario = value || 0
                    changeDataSubmit(
                      dataSubmit.map((d) =>
                        d.autonomoId === dtCurrent.autonomoId
                          ? { ...dtCurrent, valorNovoSalario }
                          : d,
                      ),
                    )
                  }}
                />
              </Box>
            )
          },
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubmit])

  return (
    <ToolsDataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      pagination={false}
      sherlock={{
        query,
        columns: ['estabelecimentoNrInscricao', 'estabelecimentoNome'],
      }}
    />
  )
}
