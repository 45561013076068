import { useState } from 'react'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Finder,
  PageHeader,
  StackContainer,
} from '~/components'

import Table, { TableActionEvent } from './Table'
import Form from './Form'
import DialogProcessosFunc from './DialogProcessosFunc'

import useDialog from '~/hooks/useDialog'
import { useRemove } from '~/hooks/queries/ProcessoTrabalhista/useRemove'

import { ProcessoTrabalhista } from '~/hooks/queries/ProcessoTrabalhista/dtos/ProcessoTrabalhista'

export default function ProcessoTrabalhistaPage() {
  const [query, setQuery] = useState('')

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<ProcessoTrabalhista | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')
  const {
    close: closeProcessosFunc,
    data: dataProcessosFunc,
    isOpen: isOpenProcessosFunc,
    open: openProcessosFunc,
  } = useDialog('')

  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } = useRemove()

  async function handleActionClick(event: TableActionEvent, row: ProcessoTrabalhista) {
    switch (event) {
      case 'delete':
        openConfirmDelete(row.id)
        break
      case 'edit':
        openForm(row)
        break
      case 'processos-funcionarios':
        openProcessosFunc(row.id)
        break
    }
  }

  return (
    <StackContainer>
      <PageHeader title="Processo Trabalhista">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button variant="contained" onClick={() => openForm(null)}>
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>
      <ContainerTable>
        <Table query={query} onActionClick={handleActionClick} />
      </ContainerTable>
      {isOpenForm && <Form isOpen={isOpenForm} onClose={closeForm} data={dataForm} />}
      {isOpenProcessosFunc && (
        <DialogProcessosFunc
          isOpen={isOpenProcessosFunc}
          onClose={closeProcessosFunc}
          processoTrabalhistaId={dataProcessosFunc}
        />
      )}
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={async () => {
            await mutateAsyncRemove(dataConfirmDelete)
            closeConfirmDelete()
          }}
          isDeleting={isRemoving}
        />
      )}
    </StackContainer>
  )
}
