import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Box, CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Button } from 'mio-library-ui'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { Creators as AppActions } from '~/store/ducks/app'
import useCredentials from '~/hooks/useCredentials'
import { notifyWarning } from '~/utils/notification'

const useStyles = makeStyles(() => ({
  box: {
    minWidth: '316px',
  },
  circularProgress: {
    textAlign: 'center',
  },
}))

const SelecaoEmpresaForm = (props) => {
  const { loginData, onSelecionarLicencaSuccess } = props

  const [empresaId, setEmpresaId] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const classes = useStyles()

  const dispatch = useDispatch()

  const { selecionarLicenca } = useCredentials()

  useEffect(() => {
    if (Array.isArray(loginData?.empresas)) {
      const empresa = loginData?.empresas[0]
      setEmpresaId(empresa.uuid)
    }
  }, [loginData])

  const doLogin = async () => {
    if (!empresaId) return notifyWarning('Informe a Empresa para prosseguir')
    setSubmitting(true)
    try {
      const response = await selecionarLicenca(loginData, empresaId)
      await onSelecionarLicencaSuccess(response)
    } catch (err) {
      dispatch(AppActions.doLogout())
      window.location.href = '/'
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Box className={classes.box}>
      {isSubmitting ? (
        <Box className={classes.circularProgress}>
          <CircularProgress size={32} color="secondary" />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MUIAutoComplete
              label="Licenças"
              options={loginData?.empresas || []}
              optionId="uuid"
              renderOption={(option) => option.nome}
              value={empresaId}
              onChange={(e, obj) => {
                const id = obj?.uuid || ''
                setEmpresaId(id)
              }}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              color="primary"
              size="small"
              fullWidth
              variant="contained"
              onClick={doLogin}
            >
              Entrar
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default SelecaoEmpresaForm
