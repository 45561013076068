import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import { ErrorMessage } from 'mio-library-ui'

import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/dist/locale/pt'
import PropTypes from 'prop-types'

moment.locale('pt', { week: { dow: 0 } })

const obterErro = (name, validationErrors) => {
  if (!validationErrors) return false

  const { inner } = validationErrors
  const erroEncontrado = inner.find((item) => {
    const { path } = item
    return name === path
  })

  if (!erroEncontrado) return false

  return erroEncontrado.message
}

const DatePicker = (props) => {
  const { validationErrors, name, dateTime, ...rest } = props

  const mensagemDeErro = obterErro(name, validationErrors)

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt" {...rest}>
        {dateTime ? (
          <KeyboardDateTimePicker
            inputVariant="outlined"
            variant="outlined"
            fullWidth
            size="small"
            format="DD/MM/YYYY HH:mm:ss"
            ampm={false}
            {...rest}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            invalidDateMessage="Data inválida"
            name={name}
          />
        ) : (
          <KeyboardDatePicker
            inputVariant="outlined"
            variant="inline"
            size="small"
            fullWidth
            format="DD/MM/yyyy"
            {...rest}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            invalidDateMessage="Data inválida"
            name={name}
          />
        )}
      </MuiPickersUtilsProvider>
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </>
  )
}

DatePicker.propTypes = {
  dateTime: PropTypes.bool,
}

DatePicker.defaultProps = {
  dateTime: false,
}

export default DatePicker
