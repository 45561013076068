import React from 'react'

import moment from 'moment'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { useVinculoLocal } from '~/hooks/queries/useVinculoLocal'

function Table(props) {
  const { onItemClick, query, vinculoId } = props

  const { data: _data, isLoading, isFetching } = useVinculoLocal(vinculoId)

  const data =
    _data?.length > 0
      ? _data.map((d) => ({
          ...d,
          dtLocal: d?.dtLocal ? moment(d.dtLocal).format('DD/MM/yyyy') : '',
          setorCodigo: d?.setor?.codigo || '',
          setor: d?.setor?.nome || '',
          cargoCodigo: d?.cargo?.codigo || '',
          cargo: d?.cargo?.nome || '',
        }))
      : []

  const columns = [
    {
      name: 'dtLocal',
      label: 'Data',
    },
    {
      name: 'setorCodigo',
      label: 'Setor Código',
    },
    {
      name: 'setor',
      label: 'Setor',
    },
    {
      name: 'cargoCodigo',
      label: 'Cargo Código',
    },
    {
      name: 'cargo',
      label: 'Cargo',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtFinded = _data.find((d) => d.id === value)
                  onItemClick('edit', dtFinded)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" size="small" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" size="small" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <DataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['dtLocal', 'setorCodigo', 'setor', 'cargoCodigo', 'cargo'],
      }}
    />
  )
}

export default Table
