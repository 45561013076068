import React, { useState, useEffect } from 'react'

import { makeStyles, Grid, Paper, Box, IconButton } from '@material-ui/core'
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  DoneAll as DoneAllIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons'

import YearMonthItem from './components/YearMonthItem'

import PropTypes from 'prop-types'
import moment from 'moment'

function getYearsMonths(yearMonth) {
  const year = yearMonth.substring(0, 4)
  const yearsMonths = []
  for (let index = 1; index <= 12; index++) {
    const month = Number(index).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
    yearsMonths.push(year + month)
  }

  return yearsMonths
}

const useStyles = makeStyles((theme) => ({
  titleYearMonth: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 400,
    gap: theme.spacing(2),
  },
  totalSelected: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 400,

    backgroundColor: 'rgb(21, 101, 192)',
    borderRadius: '50%',
    color: 'white',
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
  },
}))

export default function YearMonthPicker(props) {
  const { startYearMonth, value, onChange, disabledOldYearsMonths } = props

  const [yearMonthCurrent, setYearMonthCurrent] = useState(moment().format('yyyyMM'))
  const [selecteds, setSelecteds] = useState([])

  const classes = useStyles()

  useEffect(() => {
    setSelecteds(value)
  }, [value])

  useEffect(() => {
    if (startYearMonth) setYearMonthCurrent(startYearMonth)
  }, [startYearMonth])

  const yearsMonths = getYearsMonths(yearMonthCurrent)

  function isDisabled(yearMonth) {
    if (!disabledOldYearsMonths) return false
    if (!startYearMonth) {
      console.warn("need prop 'startYearMonth' to validate what yearMonth is disabled")
      return false
    }
    return moment(yearMonth, 'yyyyMM').isBefore(moment(startYearMonth, 'yyyyMM'))
  }

  function handleToggle(yearMonth) {
    const newSelecteds = [...selecteds]
    const indexFinded = selecteds.findIndex((selected) => selected === yearMonth)
    if (indexFinded !== -1) {
      newSelecteds.splice(indexFinded, 1)
    } else {
      newSelecteds.push(yearMonth)
    }
    setSelecteds(newSelecteds)
    onChange(newSelecteds)
  }

  function handleTogglerAll() {
    const newSelecteds = []
    if (yearsMonths.some((yearMonth) => selecteds.some((selected) => yearMonth === selected))) {
      const selectedsFiltreds = selecteds.filter(
        (selected) => !yearsMonths.some((yearMonth) => yearMonth === selected),
      )
      newSelecteds.push(...selectedsFiltreds)
    } else {
      const yearsMonthsFiltreds = yearsMonths.filter((yearMonth) => !isDisabled(yearMonth))
      newSelecteds.push(...selecteds, ...yearsMonthsFiltreds)
    }
    setSelecteds(newSelecteds)
    onChange(newSelecteds)
  }

  function beforeYear() {
    setYearMonthCurrent((oldState) => {
      const year = parseInt(oldState.substring(0, 4))
      const newYear = (year - 1).toString()
      return newYear + oldState.substring(4)
    })
  }

  function nextYear() {
    setYearMonthCurrent((oldState) => {
      const year = parseInt(oldState.substring(0, 4))
      const newYear = (year + 1).toString()
      return newYear + oldState.substring(4)
    })
  }

  const yearsMonthsFiltreds = yearsMonths.filter((yearMonth) => !isDisabled(yearMonth))

  const isAllSelecteded = yearsMonthsFiltreds.some((yearMonth) =>
    selecteds.some((selected) => yearMonth === selected),
  )

  return (
    <Grid container spacing={2} component={Paper}>
      <Grid item xs={4} className={classes.titleYearMonth}>
        {selecteds.length > 0 && (
          <Box title="Selecionados" className={classes.totalSelected}>
            {selecteds.length}
          </Box>
        )}
      </Grid>
      <Grid item xs={4} className={classes.titleYearMonth}>
        <IconButton title="Anterior" onClick={beforeYear}>
          <NavigateBeforeIcon fontSize="small" color="primary" />
        </IconButton>
        {yearMonthCurrent.substring(0, 4)}
        <IconButton title="Próximo" onClick={nextYear}>
          <NavigateNextIcon fontSize="small" color="primary" />
        </IconButton>
      </Grid>
      <Grid item xs={4} className={classes.titleYearMonth}>
        <IconButton title="Selecionar Todos" onClick={handleTogglerAll}>
          {isAllSelecteded ? (
            <RemoveIcon fontSize="small" color="primary" />
          ) : (
            <DoneAllIcon fontSize="small" color="primary" />
          )}
        </IconButton>
      </Grid>

      {yearsMonths.map((yearMonth, index) => {
        const disabled = isDisabled(yearMonth)
        return (
          <Grid item xs={4} key={index}>
            <YearMonthItem
              disabled={disabled}
              yearMonth={yearMonth}
              isSelected={selecteds.includes(yearMonth)}
              onClick={handleToggle}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

YearMonthPicker.defaultProps = {
  disabledOldYearsMonths: false,
}

YearMonthPicker.propTypes = {
  startYearMonth: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  disabledOldYearsMonths: PropTypes.bool,
}
