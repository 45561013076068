import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'

import { Container, Finder, PDFViewer, PageHeader, PrintButton } from '~/components'

import Table from './Table'
import DialogConfirm from './DialogConfirm'

import { useVinculoObterVinculosPorEstabelecimento } from '~/hooks/queries/Vinculo/useVinculoObterVinculosPorEstabelecimento'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { DialogConfirmData } from './DialogConfirm'

const PAGE_TITLE = 'Contrato Trabalho Indeterminado'

export default function ContratoTrabalhoIndeterminado() {
  const [query, setQuery] = useState('')
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const { estabelecimento, anoMes } = useAmbiente()

  const { data, isLoading } = useVinculoObterVinculosPorEstabelecimento(estabelecimento.id)

  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog(null)
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  useEffect(() => {
    setRowsSelected([])
  }, [estabelecimento])

  function handlePrint() {
    openDialogConfirm(null)
  }

  function handleAfterSubmitDialogConfirm(dt: DialogConfirmData) {
    const ids = rowsSelected.map((indexCurrent) => data[indexCurrent].id)
    const newData = {
      dtAssinatura: dt.dtAssinatura,
      vinculosIds: ids,
    }
    setRowsSelected([])
    openPDFViewer(newData)
  }

  return (
    <Container>
      <PageHeader title={PAGE_TITLE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Table
        isLoading={isLoading}
        query={query}
        data={data}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelected}
      />

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        onClose={closeDialogConfirm}
        onAfterSubmit={handleAfterSubmitDialogConfirm}
      />

      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={handlePrint} disabled={!(rowsSelected.length > 0)} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="ContratoTrabalhoIndeterminado"
        title={PAGE_TITLE}
        isSalvarDocumento
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/ContratoTrabalhoIndeterminado/ObterRelatorio',
          data: dataPDFViewer,
          params: {
            estabelecimentoId: estabelecimento.id,
            anoMes,
          },
        }}
      />
    </Container>
  )
}
