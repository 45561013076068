import React from 'react'

import { ToolsDataTable } from '~/components'

import { DialogBaseCalculoVinculoBaseCalculoESocial } from '../DialogBaseCalc'

import { formatCurrency } from '~/hooks/useUtils'

import { IndBaseCSESocialValues } from '~/@types/enums/IndBaseCSESocialEnum'

interface DialogESocialProps {
  data: DialogBaseCalculoVinculoBaseCalculoESocial[]
}

export default function TableESocial(props: DialogESocialProps) {
  const { data: _data } = props

  const data = _data.map((d) => ({
    ...d,
    vrContribuicao: formatCurrency(d.vrContribuicao),
    tpValor: IndBaseCSESocialValues.find((indBaseCS) => indBaseCS.value === d.tpValor)?.name || '',
  }))

  const columns = [
    {
      name: 'tpValor',
      label: 'Tipo Valor',
    },
    {
      name: 'vrContribuicao',
      label: 'Valor Contribuição',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
  ]

  return <ToolsDataTable data={data} columns={columns} disableAutoHeight pagination={false} />
}
