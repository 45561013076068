import React, { useState } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid } from '@material-ui/core'

import { Button, PageHeader, TextField } from '~/components'
import { useStepperContext } from '~/components/StepperForm'

import { MainDataForm } from '..'
import { IndObservacaoEnum } from '~/@types/enums/IndObservacaoEnum'

export default function FormObservacao() {
  const { mainData, onBack, onSkipAndSubmit, onSubmit } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataObservacao)

  function handleBack() {
    onBack()
  }

  function handleSubmit() {
    if (data?.observacao) {
      onSubmit({
        ...mainData,
        dataObservacao: {
          ...data,
          dtObservacao: mainData.dataInitialForm.dtAdmissao,
          indObservacao: IndObservacaoEnum.eSocial,
        },
      })
    } else {
      onSkipAndSubmit({ ...mainData, dataObservacao: undefined })
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <PageHeader title="Observação" />
      </Grid>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <TextField
          label="Observação"
          multiline
          rows={10}
          inputProps={{
            maxLength: 255,
          }}
          value={data?.observacao || ''}
          onChange={(e) => {
            const observacao = e.target.value
            setData((prev) => ({ ...prev, observacao }))
          }}
        />
      </Grid>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleSubmit} variant="contained">
            Finalizar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
