import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifySuccess } from '~/utils/notification'

interface RequestParams {
  exameToxicologicoId: string
}

export function useRetificar() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()

  async function handleRequest({ exameToxicologicoId }: RequestParams) {
    await api.post('/VinculoExameToxicologico/Retificar/' + exameToxicologicoId)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('ExameToxicologico')
      notifySuccess('Exame Toxicológico retificado com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
