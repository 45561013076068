import React, { useState } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Button, Container, Finder, PageHeader } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'

import useDialog from '~/hooks/useDialog'
import { useEstabilidadeDelete } from '~/hooks/queries/useEstabilidade'

export default function Estabilidade() {
  const [query, setQuery] = useState('')

  const {
    close: closeFormEstabilidade,
    data: idFormEstabilidade,
    isOpen: isOpenFormEstabilidade,
    open: openFormEstabilidade,
  } = useDialog<string | null>(null)
  const {
    close: closeDialogConfirmDelete,
    data: idDialogConfirmDelete,
    isOpen: isOpenDialogConfirmDelete,
    open: openDialogConfirmDelete,
  } = useDialog<string | null>(null)

  const { mutateAsync, isLoading } = useEstabilidadeDelete()

  function handleClickItem(event: 'edit' | 'delete', id: string) {
    async function handleClickEditItem(id: string) {
      openFormEstabilidade(id)
    }

    function handleClickDeleteItem(id: string) {
      openDialogConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](id)
  }

  async function handleDeleteItem() {
    if (!idDialogConfirmDelete) return
    await mutateAsync(idDialogConfirmDelete)
    closeDialogConfirmDelete()
  }

  return (
    <Container>
      <PageHeader title="Estabilidade">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button
            size="small"
            color="primary"
            onClick={() => openFormEstabilidade(null)}
            variant="contained"
          >
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Table query={query} onItemClick={handleClickItem} />

      {isOpenFormEstabilidade && (
        <Form
          isOpen={isOpenFormEstabilidade}
          estabilidadeId={idFormEstabilidade}
          onClose={closeFormEstabilidade}
        />
      )}

      <ConfirmDeleteDialog
        isOpen={isOpenDialogConfirmDelete}
        isDeleting={isLoading}
        onCancel={closeDialogConfirmDelete}
        onConfirm={handleDeleteItem}
      />
    </Container>
  )
}
