import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestParams {
  rpId: string
  eventoEmpregadorId: string
  referencia: number | null
  valor: number | null
  complemento: string | null
  isCalculoManual: boolean | null
}

export function useRPInsereEvento() {
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  async function handleRequest(params: RequestParams) {
    await api.post('/RP/InsereEvento', null, {
      params,
    })
    notification.success('Evento processado com sucesso')
  }

  return useMutation(handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
