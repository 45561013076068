import React, { useEffect, useState } from 'react'

import { Grid, TextField, IconButton, Box, Grow } from '@material-ui/core'
import { AddCircle as AddCircleIcon, Close as CloseIcon } from '@material-ui/icons'

import { ActionDialog } from '~/components'

import useNotification from '~/hooks/useNotification'

export default function DialogConfirm(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [text, setText] = useState('')
  const [data, setData] = useState([])

  const notification = useNotification()

  useEffect(() => {
    if (isOpen) return
    setData([])
  }, [isOpen])

  function handleClickAdd() {
    if (!text) return notification.warning('Adicione o nome da Coluna')
    if (data.length >= 10) return notification.warning('Limite de 10 colunas atingido')
    setData((prev) => [...prev, text])
    setText('')
  }

  function handleClickRemove(index) {
    const newData = data.filter((d, indexCurrent) => indexCurrent !== index)
    setData(newData)
  }

  function handleSubmit() {
    if (!(data.length > 0)) return notification.warning('Insira pelo menos uma Coluna')
    onAfterSubmit({ colunas: data })
    onClose()
  }

  return (
    <ActionDialog
      title="Informe dados para a Lista de Ponto"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid
        container
        spacing={2}
        style={{
          margin: 0,
          width: '100%',
        }}
      >
        {data.map((col, index) => (
          <Grid item xs={12} key={index}>
            <Grow in={true}>
              <Box display="flex" gridGap={8}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={'Coluna ' + (index + 1)}
                  value={col}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <IconButton
                  title="Remover coluna"
                  size="small"
                  color="primary"
                  onClick={() => handleClickRemove(index)}
                >
                  <CloseIcon color="primary" />
                </IconButton>
              </Box>
            </Grow>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Box display="flex" gridGap={8}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Adicionar Coluna"
              value={text}
              inputProps={{
                maxLength: 100,
              }}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleClickAdd()}
            />
            <IconButton
              title="Adicionar coluna"
              size="small"
              color="primary"
              onClick={handleClickAdd}
            >
              <AddCircleIcon color="primary" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
