import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestProps {
  anoMes: string
}

export default function useDesfazerImportacaoEventos() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(params: RequestProps) {
    await api.delete('/ImportarEventosRP/DesfazerImportacaoEventos', {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Importações foram desfeitas com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
