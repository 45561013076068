import { useEffect, useState } from 'react'

import { Box, Paper } from '@material-ui/core'

import {
  PageHeader,
  PDFViewer,
  Button,
  Finder,
  DialogConfirmDtAssinatura,
  Stack,
  ContainerTable,
} from '~/components'
import { DataFormProps } from '~/components/DialogConfirmDtAssinatura'

import { Table } from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'
import { TipoAssinaturaEnum } from '~/@types/enums/TipoAssinaturaEnum'
import {
  ContratoIntermitenteRequestDTO,
  ContratoIntermitenteRequestItemDTO,
} from '~/hooks/queries/ContratoIntermitente/dtos/ContratoIntermitenteRequestDTO'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'
import { IndUnidadeSalarioEnum } from '~/@types/enums/IndUnidadeSalarioEnum'

export interface VinculoIntermitenteData extends VinculoConsulta {
  vrServico: number
  indUnidadeSalario: IndUnidadeSalarioEnum
}

const TITLE_PAGE = 'Contrato Intermitente'

export default function ContratoIntermitente() {
  const [collection, setCollection] = useState<VinculoIntermitenteData[]>([])
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const [query, setQuery] = useState('')

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<ContratoIntermitenteRequestDTO>({
    estabelecimentoId: '',
    tipoAssinatura: TipoAssinaturaEnum.Admissao,
    dtAssinatura: null,
    vinculos: [],
  })

  const {
    close: closeConfirmDtAssinatura,
    isOpen: isOpenConfirmDtAssinatura,
    open: openConfirmDtAssinatura,
  } = useDialog(null)

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    estabelecimentoId: estabelecimento.id,
    anoMes,
    categoria: CategoriaEnum.Empregado_Intermitente_111,
  })

  useEffect(() => {
    setRowsSelected([])
    if (_d) {
      const newData = _d.map<VinculoIntermitenteData>((d) => ({
        ...d,
        vrServico: 0,
        indUnidadeSalario: IndUnidadeSalarioEnum.Mes_5,
      }))
      setCollection(newData)
    }
  }, [_d])

  function handlePrint(d: DataFormProps) {
    const dt = rowsSelected.map<ContratoIntermitenteRequestItemDTO>((index) => {
      const dtCurrent = collection[index]
      return {
        vinculoId: dtCurrent.id,
        vrServico: dtCurrent.vrServico,
        indUnidadeSalario: dtCurrent.indUnidadeSalario,
      }
    })
    openPDFViewer({
      estabelecimentoId: estabelecimento.id,
      tipoAssinatura: d.tipoAssinatura,
      dtAssinatura: d.dtAssinatura,
      vinculos: dt,
    })
  }

  function handleClosePDFViewer() {
    setRowsSelected([])
    closePDFViewer()
  }

  function handleConfirmDtAssinatura() {
    openConfirmDtAssinatura(null)
  }

  return (
    <Stack height="100%" width="100%" p={2}>
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <ContainerTable>
        <Table
          data={collection}
          setData={setCollection}
          isLoading={isLoading}
          isFetching={isFetching}
          onRowSelected={(indexs) => setRowsSelected(indexs)}
          rowsSelected={rowsSelected}
          query={query}
        />
      </ContainerTable>

      <Box component={Paper} p={1} width="100%" display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleConfirmDtAssinatura}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Imprimir
        </Button>
      </Box>

      {isOpenPDFViewer && (
        <PDFViewer
          title={TITLE_PAGE}
          reportKey="ContratoIntermitente"
          isOpen={isOpenPDFViewer}
          onClose={handleClosePDFViewer}
          isSalvarDocumento
          axiosConfig={{
            url: 'ContratoIntermitente/ObterRelatorio',
            method: 'post',
            data: dataPDFViewer,
          }}
        />
      )}

      {isOpenConfirmDtAssinatura && (
        <DialogConfirmDtAssinatura
          isOpen={isOpenConfirmDtAssinatura}
          onAfterSubmit={handlePrint}
          onClose={closeConfirmDtAssinatura}
        />
      )}
    </Stack>
  )
}
