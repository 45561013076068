import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterTodosInvalidate } from './useObterTodos'

import { notifySuccess } from '~/utils/notification'

interface RequestParams {
  id: string
}

export function useRetificar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterTodosInvalidate()

  async function handleRequest({ id }: RequestParams) {
    await api.post('/ProcessoTrabalhistaTributos/Retificar/' + id)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      handleInvalidate()
      notifySuccess('Processo Trabalhista Tributos retificado com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
