import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterTodos'

import { notifyRemove } from '~/utils/notification'

interface RequestProps {
  params: {
    id: string
  }
}

export function useRemover() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ params }: RequestProps) {
    await api.delete('/EventosNaoIntegrados/Remover', { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
