import { useQuery } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import api from '~/services/api-pessoal'

import { CondicaoAmbienteTrabalhoVinculoDTO } from './CondicaoAmbienteTrabalhoVinculoDTO'

export function useObterVinculosParaGerarEvento(
  estabelecimentoId: string,
  anoMes: string,
  isSomenteAdmitidosAnoMes: boolean,
) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: CondicaoAmbienteTrabalhoVinculoDTO[] }>(
      '/CondicaoAmbienteTrabalho/ObterVinculosParaGerarEvento',
      {
        params: {
          estabelecimentoId,
          anoMes,
          isSomenteAdmitidosAnoMes,
        },
      },
    )
    return response.data.data
  }

  return useQuery(
    [
      'CondicaoAmbienteTrabalhoObterVinculosParaGerarEvento',
      estabelecimentoId,
      anoMes,
      isSomenteAdmitidosAnoMes,
    ],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )
}
