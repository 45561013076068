import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useObterFeriadosPorVinculoInvalidate } from './useObterFeriadosPorVinculo'

import { notifyPut } from '~/utils/notification'

import { VinculoTrabalhoFeriado } from './dtos/VinculoTrabalhoFeriado'

interface RequestProps {
  data: VinculoTrabalhoFeriado
  id: string
}

export function useAtualizarVinculoTrabalhoFeriado() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useObterFeriadosPorVinculoInvalidate()

  async function handleRequest({ data, id }: RequestProps) {
    await api.put('/VinculoTrabalhoFeriado/' + id, data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
