import React, { useEffect, useState } from 'react'

import { MobileStepper, Box, makeStyles, IconButton } from '@material-ui/core'

import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  steperRoot: {
    padding: 0,
    marginTop: theme.spacing(1),
  },
}))

interface SimpleCarouselProps<T> {
  data: T[]
  render: (dataItem: T, index: number) => React.ReactNode
  activeStep?: number
  onChange?: (event: 'next' | 'back', index: number) => void
}

export default function SimpleCarousel<T>(props: SimpleCarouselProps<T>) {
  const { data, render, activeStep: _activeStep, onChange } = props

  const [activeStep, setActiveStep] = useState(0)

  const maxSteps = data.length

  const classes = useStyles()

  useEffect(() => {
    if (!_activeStep) return
    setActiveStep(_activeStep)
  }, [_activeStep])

  const handleNext = () => {
    const stepCurrent = activeStep + 1
    setActiveStep(stepCurrent)
    if (onChange) onChange('next', stepCurrent)
  }

  const handleBack = () => {
    const stepCurrent = activeStep - 1
    setActiveStep(stepCurrent)
    if (onChange) onChange('back', stepCurrent)
  }

  return (
    <Box>
      {render(data[activeStep], activeStep)}
      <MobileStepper
        className={classes.steperRoot}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <IconButton size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            <KeyboardArrowRightIcon />
          </IconButton>
        }
        backButton={
          <IconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeftIcon />
          </IconButton>
        }
      />
    </Box>
  )
}
