import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { VinculoAlteracaoCadastro2205DTO } from './VinculoAlteracaoCadastro2205DTO'

export function useCriarEvento2205() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(data: VinculoAlteracaoCadastro2205DTO) {
    await api.post('/VinculoAlteracaoContratoCadastro/CriarEvento2205', data, {})
    notification.post()
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries('VinculoAlteracaoContratoCadastroGetByVinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
