import moment from 'moment'

import { ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { AtestadoSaudeOcupacionalItem } from '~/hooks/queries/AtestadoSaudeOcupacionalItem/AtestadoSaudeOcupacionalItem'
import { ASOIndResultadoEnum, ASOIndResultadoLabels } from '~/@types/enums/ASOIndResultadoEnum'
import { ASOOrdemExameEnum, ASOOrdemExameLabels } from '~/@types/enums/ASOOrdemExameEnum'

interface TableExamesProps {
  data: AtestadoSaudeOcupacionalItem[]
}

export default function TableExames({ data }: TableExamesProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Data do Exame',
      name: 'dtExame',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
      },
    },
    {
      label: 'Procedimento Médico',
      name: 'procedimentoMedicoId',
    },
    {
      label: 'Resultado',
      name: 'indResultado',
      options: {
        customBodyRender: (value: ASOIndResultadoEnum) => ASOIndResultadoLabels[value],
      },
    },
    {
      label: 'Ordem',
      name: 'indOrdem',
      options: {
        customBodyRender: (value: ASOOrdemExameEnum) => ASOOrdemExameLabels[value],
      },
    },
  ]

  return <ToolsDataTable columns={columns} data={data} disableAutoHeight pagination={false} />
}
