import { create } from 'zustand'

export enum TabType {
  Apuracao,
  Consulta,
}

interface ContextType {
  query: string
  grupoId: string
  isDescontarFaltas: boolean
  tab: TabType

  setQuery: (query: string) => void
  setGrupoId: (grupoId: string) => void
  setDescontarFaltas: (isDescontarFaltas: boolean) => void
  setTab: (tab: TabType) => void
}

export const useValeTransporteApuracaoContext = create<ContextType>((set) => ({
  query: '',
  grupoId: '',
  isDescontarFaltas: false,
  tab: TabType.Apuracao,

  setQuery: (query) => set({ query }),
  setGrupoId: (grupoId) => set({ grupoId }),
  setDescontarFaltas: (isDescontarFaltas) => set({ isDescontarFaltas }),
  setTab: (tab) => set({ tab }),
}))
