const PISPASEP_1 = 1
const Matricula_2 = 2
const CartaoPonto_3 = 3
const CodigoCadastro_4 = 4
const CPF_5 = 5

export const indPontoEletronicoValues = [
  { value: PISPASEP_1, name: '1 - PISPASEP' },
  { value: Matricula_2, name: '2 - Matrícula' },
  { value: CartaoPonto_3, name: '3 - Cartão de Ponto' },
  { value: CodigoCadastro_4, name: '4 - Código Cadastro' },
  { value: CPF_5, name: '5 - CPF' },
]

export const indPontoEletronicoConsts = {
  PISPASEP_1,
  Matricula_2,
  CartaoPonto_3,
  CodigoCadastro_4,
  CPF_5,
}
