import { useState } from 'react'

import { Box, Grid, makeStyles } from '@material-ui/core'
import { TextField, Button } from 'mio-library-ui'

import * as yup from 'yup'

import PasswordTextField from '~/components/PasswordTextField'

import useValidationErrors from '~/hooks/useValidationErrors'
import useCredentials from '~/hooks/useCredentials'

const useStyles = makeStyles(() => ({
  boxButtons: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  esqueciSenha: {
    marginTop: '8px',
  },
}))

const loginSchema = yup.object().shape({
  email: yup.string().email('Informe um email válido').required('Informe o email'),
  senha: yup.string().required('Informe a senha'),
})

export default function LoginForm(props) {
  const classes = useStyles()
  const { onEsqueciSenhaClick, onUserLogged } = props
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')

  const { login, isSubmitting } = useCredentials()

  const doLogin = async () => {
    const loginResponse = await login(email, senha)

    if (loginResponse) {
      onUserLogged(loginResponse)
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema: loginSchema,
    handleSubmit: doLogin,
    data: { email, senha },
  })

  return (
    <Box component="form">
      <Grid container spacing={3}>
        <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
          <TextField
            autoFocus
            validationErrors={validationErrors}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="none"
            variant="outlined"
            fullWidth
            label="E-mail"
            placeholder="Informe o seu e-mail de acesso"
            size="small"
          />
        </Grid>
        <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
          <PasswordTextField
            validationErrors={validationErrors}
            name="senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            fullWidth
            variant="outlined"
            label="Senha"
            placeholder="Informe a sua senha"
            size="small"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                doLogin()
              }
            }}
          />
        </Grid>
        <Grid xs={12} lg={12} md={12} sm={12} xl={12} item>
          <Button
            color="primary"
            variant="contained"
            size="small"
            fullWidth
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={handleValidate}
          >
            Entrar
          </Button>

          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={onEsqueciSenhaClick}
            className={classes.esqueciSenha}
          >
            Esqueci a senha
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
