import { Box } from '@material-ui/core'

import { PageHeader, StackContainer } from '~/components'
import StepperForm from '~/components/StepperForm'

import FeriasColetivaContent from './Content/FeriasColetiva'
import ImprimirFeriasColetiva from './Content/ImprimirFeriasColetiva'

export default function FeriasColetiva() {
  return (
    <StepperForm.Provider
      onSubmit={() => undefined}
      onCancel={() => undefined}
      disabledAlert
      data={{}}
      steps={[
        {
          label: 'Férias Coletiva',
          children: <FeriasColetivaContent />,
          icon: 'people',
        },
        {
          label: 'Imprimir Recibos',
          children: <ImprimirFeriasColetiva />,
          icon: 'print',
        },
      ]}
    >
      <StackContainer>
        <PageHeader title="Férias Coletiva">
          <StepperForm.Steps orientation="horizontal" />
        </PageHeader>
        <Box flex={1}>
          <StepperForm.Content
            style={{
              height: '100%',
            }}
          />
        </Box>
      </StackContainer>
    </StepperForm.Provider>
  )
}
