import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { query } from './useObterFuncionarios'
import { AntecipacaoSalarialConfigurarDTO } from './AntecipacaoSalarialDTO'

export default function useConfigurarAntecipacao() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(antecipacaoSalarialConfigurar: AntecipacaoSalarialConfigurarDTO[]) {
    await api.post('/AntecipacaoSalarial/ConfigurarAntecipacao', antecipacaoSalarialConfigurar)
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
    onSuccess: async () => {
      notification.success('Configuração de Antecipação Salarial aplicadas com sucesso')
      await queryClient.invalidateQueries(query)
    },
  })
}
