import React, { useMemo } from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { Edit as EditIcon, Delete as DeleteIcon, List as ListIcon } from '@material-ui/icons'

import { HeightDataTable } from '~/components'

import moment from 'moment'
import { processoAutoriaValues } from '~/values/processoAutoriaValues'
import { processoIndicativoMateriaValues } from '~/values/processoIndicativoMateriaValues'
import { processoExtensaoDecisaoValues } from '~/values/processoExtensaoDecisaoValues'

const formatIndAutoria = (indAutoria) => {
  const objectFinded = processoAutoriaValues.find((obj) => obj.value === indAutoria)
  return objectFinded.name
}

const formatIndMaterialidade = (indMaterialidade) => {
  const objectFinded = processoIndicativoMateriaValues.find((obj) => obj.value === indMaterialidade)
  return objectFinded.name
}

const formatExtensaoDeDecisao = (extensaoDeDecisao) => {
  const objectFinded = processoExtensaoDecisaoValues.find((obj) => obj.value === extensaoDeDecisao)
  return objectFinded?.name || ''
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query } = props

  const data = _data.map((item) => {
    return {
      ...item,
      dtSituacao: item?.dtSituacao ? moment(item.dtSituacao).format('DD/MM/yyyy') : '',
      dtReciboEsocial: item?.dtReciboEsocial
        ? moment(item.dtReciboEsocial).format('DD/MM/yyyy')
        : '',
      indAutoria: formatIndAutoria(item?.indAutoria),
      indMaterialidade: formatIndMaterialidade(item?.indMaterialidade),
      extensaoDeDecisao: formatExtensaoDeDecisao(item?.extensaoDeDecisao),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'dtSituacao',
        label: 'Data da Situação',
      },
      {
        name: 'indAutoria',
        label: 'Indicador de Autoria',
      },
      {
        name: 'indMaterialidade',
        label: 'Indicativo de Matéria do Processo',
      },
      {
        name: 'codigoVara',
        label: 'Indentificação da Vara',
      },
      {
        name: 'extensaoDeDecisao',
        label: 'Extensão de Decisão',
      },
      {
        name: 'reciboEsocial',
        label: 'Recibo eSocial',
      },
      {
        name: 'dtReciboEsocial',
        label: 'Data Recibo eSocial',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Abrir Processos Itens"
                  color="primary"
                  aria-label="Processos Itens"
                  onClick={() => {
                    onItemClick('openItens', value)
                  }}
                >
                  <ListIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>

                <IconButton
                  title="Deletar este registro"
                  size="small"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return (
    <HeightDataTable
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        query,
        columns: [
          'dtSituacao',
          'indAutoria',
          'indMaterialidade',
          'codigoVara',
          'extensaoDeDecisao',
          'reciboEsocial',
        ],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
