import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import { VinculoFaltasDTO } from './VinculoFaltasDTO'

export const queryObterDataFaltas = '/VinculoFaltas/ObterDataFaltas'

export default function useObterDataFaltas(vinculoId: string, anoMes: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoFaltasDTO }>(queryObterDataFaltas, {
      params: {
        vinculoId,
        anoMes,
      },
    })
    return response.data.data
  }

  return useQuery([queryObterDataFaltas, vinculoId, anoMes], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
