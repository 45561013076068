import { Box, IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { Button, ButtonBox, ToolsDataTable } from '~/components'

import FormColuna from './FormColuna'

import useDialog from '~/hooks/useDialog'

import { ModeloColunaImportacao } from '~/hooks/queries/ModeloPlanilhaImportacao/ModeloColunaImportacao'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { orderBy } from '~/hooks/useUtils'

interface TableColunasProps {
  data: ModeloColunaImportacao[]
  onChange: (data: ModeloColunaImportacao[]) => void
}

export default function TableColunas({ data, onChange }: TableColunasProps) {
  const {
    close: closeFormColuna,
    data: dataFormColuna,
    isOpen: isOpenFormColuna,
    open: openFormColuna,
  } = useDialog<ModeloColunaImportacao | null>(null)

  function handleRemove(rowIndex: number) {
    onChange(data.filter((d, index) => index !== rowIndex))
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'ordem',
      label: 'Ordem',
    },
    {
      name: 'label',
      label: 'Identificação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => handleRemove(rowIndex)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  function handleAfterSubmit(newData: ModeloColunaImportacao) {
    onChange(orderBy([...data, newData], 'ordem'))
  }

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={() => openFormColuna(null)}>
          Adicionar Coluna
        </Button>
      </Box>
      <ToolsDataTable data={data} columns={columns} disableAutoHeight pagination={false} />

      {isOpenFormColuna && (
        <FormColuna
          data={dataFormColuna}
          isOpen={isOpenFormColuna}
          onClose={closeFormColuna}
          onAfterSubmit={handleAfterSubmit}
          previuosOrdem={data[data.length - 1]?.ordem || 0}
        />
      )}
    </>
  )
}
