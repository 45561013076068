import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestProps {
  rpIds: string[]
  reportId: string
}

export function useEnviarRecibosEstabelecimentos() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    await api.post('/RPAutonomo/EnviarRecibosEstabelecimentos', props.rpIds, {
      headers: {
        'dc-printmode': 'PDF',
        'dc-printrelatorioid': props.reportId,
      },
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Recibos Enviados com sucesso para os Estabelecimentos'),
    onError: dialogNotification.extractErrors,
  })
}
