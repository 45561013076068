import React, { useEffect, useState, useMemo, useCallback } from 'react'

import { Box, IconButton, makeStyles } from '@material-ui/core'
import { ButtonBox, TextField, Button } from 'mio-library-ui'
import { GetApp as SelectIcon } from '@material-ui/icons'

import { HeightDataTable, ActionDialog } from '~/components'
import Form from './components/Form'

import api from '~/services/api-pessoal'

import theme from '~/styles/theme'

import useDialogNotification from '~/hooks/useDialogNotification'
import useDialog from '~/hooks/useDialog'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '400px',
  },
}))

export default function SuperFinderServicos(props) {
  const { isOpen, onClose, onChange } = props

  const [isLoading, setLoading] = useState(false)
  const [collection, setCollection] = useState([])
  const [searchText, setSearchText] = useState('')
  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const classes = useStyles()

  const { open: openForm, close: closeForm, isOpen: isOpenForm } = useDialog()

  useEffect(() => {
    async function getCollection() {
      if (!isOpen) return
      setLoading(true)
      try {
        const response = await api.get('/Servico')
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    //eslint-disable-next-line
  }, [isOpen])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setQuery(searchText)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])

  const onItemClick = useCallback(
    (descricao) => {
      onChange(descricao)
      onClose()
    },
    [onChange, onClose],
  )

  const columns = useMemo(
    () => [
      {
        name: 'descricao',
        label: 'Descrição do Serviço',
      },
      {
        name: 'descricao',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Transportar este registro"
                  color="primary"
                  aria-label="Transportar"
                  onClick={() => onItemClick(value)}
                >
                  <SelectIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  function handleAfterSubmit(data) {
    console.log({ data, collection })
    setCollection((oldState) => [...oldState, data])
    closeForm()
  }

  return (
    <ActionDialog
      title="Serviços"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        classes: {
          paper: classes.fullHeight,
        },
      }}
      customActions={<></>}
    >
      <Box pb={2} height="100%">
        <Box pb={2} id="box-search" display="flex" gridGap={theme.spacing(1)}>
          <TextField
            placeholder="Faça sua pesquisa..."
            fullWidth
            size="small"
            variant="outlined"
            value={searchText || ''}
            onChange={(e) => setSearchText(e?.target?.value || '')}
          />

          <Box display="flex" alignItems="center">
            <Button variant="outlined" color="primary" size="small" onClick={() => openForm()}>
              Adicionar
            </Button>
          </Box>
        </Box>

        <HeightDataTable
          data={collection}
          columns={columns}
          isLoading={isLoading}
          sherlock={{
            query,
            columns: ['descricao'],
          }}
        />
      </Box>
      <Form isOpen={isOpenForm} onClose={closeForm} handleAfterSubmit={handleAfterSubmit} />
    </ActionDialog>
  )
}
