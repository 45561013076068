import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'

import { TreeView } from '~/components'

import Content from './Content'

import { VinculoMenuItem, useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const vinculosTiposFuncionario = [VinculoTipoEnum.Funcionario_1]
const vinculosTiposRestante = [
  VinculoTipoEnum.Empregador_2,
  VinculoTipoEnum.Estagiario_3,
  VinculoTipoEnum.Autonomo_4,
  VinculoTipoEnum.Cooperado_5,
]

export default function VinculoEventoAutomatico() {
  const [vinculo, setVinculo] = useState<VinculoMenuItem | null>(null)

  const url = window.location.href
  const params = url.split('/')
  const ultimoParametro = params[params.length - 1]

  const isVinculosTSV = ultimoParametro === 'tsv' ? true : false

  const { estabelecimento, anoMes } = useAmbiente()

  useEffect(() => {
    setVinculo(null)
  }, [estabelecimento, anoMes])

  const {
    data: dataMenu,
    isLoading: isLoadingMenu,
    isFetching: isFetchingMenu,
  } = useEstabelecimentoVinculosMenu(
    isVinculosTSV ? vinculosTiposRestante : vinculosTiposFuncionario,
  )

  return (
    <Box height="100%" width="100%" padding={1}>
      <TreeView
        data={dataMenu}
        onSelectItem={(e: FixLater, vinculo: VinculoMenuItem) => setVinculo(vinculo)}
        searchBy="vinculoNome"
        renderOption={(option: VinculoMenuItem) => `${option.vinculoNome}`}
        isLoading={isLoadingMenu}
        isLoadingOnClick={isFetchingMenu}
      >
        <Content
          vinculo={vinculo}
          title={isVinculosTSV ? 'Eventos Automáticos TSV' : 'Eventos Automáticos Funcionário'}
        />
      </TreeView>
    </Box>
  )
}
