import { useState, useEffect } from 'react'

import { formatToBRL } from 'brazilian-values'

import { ContentContainer, PageHeader, ButtonBox } from 'mio-library-ui'
import { Box, IconButton, LinearProgress, Paper, Tooltip } from '@material-ui/core'

import {
  AnoSelect,
  Button,
  CollapsableListYears,
  CollapseContent,
  ConfirmDeleteDialog,
} from '~/components'

import Form from './components/Form'

import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import useOnlyDataC from '~/hooks/useOnlyDataC'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useObterTodosTabelaSF } from '~/hooks/queries/TabelaSF/useObterTodosTabelaSF'
import { useRemoverTabelaSF } from '~/hooks/queries/TabelaSF/useRemoverTabelaSF'

const columns = [
  {
    name: 'vrFaixaI',
    label: 'Valor da Primeira Faixa',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'vrCotaI',
    label: 'Valor da Primeira Cota',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'vrFaixaII',
    label: 'Valor da Segunda Faixa',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
  {
    name: 'vrCotaII',
    label: 'Valor da Segunda Cota',
    options: {
      customBodyRender: (value) => {
        return <ButtonBox>{formatToBRL(value).replace('R$', '')}</ButtonBox>
      },
    },
  },
]

const TabelaSF = () => {
  const [anoCurrent, setAnoCurrent] = useState('')

  const [collapseItens, setCollapseItens] = useState([])

  const { ano } = useAmbiente(false, true)
  const { isUserDataC } = useOnlyDataC()
  const { data: _d, isLoading, isFetching } = useObterTodosTabelaSF()
  const itens = _d || []

  const { mutateAsync: mutateAsyncRemoverTabelaSF, isLoading: isLoadingRemoverTabelaSF } =
    useRemoverTabelaSF()

  const { close: closeForm, data: dataForm, isOpen: isOpenForm, open: openForm } = useDialog(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog(null)

  useEffect(() => {
    setAnoCurrent(ano)
  }, [ano])

  const handleCollapseAll = (value) => {
    const newArray = collapseItens.map(() => value)
    setCollapseItens(newArray)
  }

  async function handleConfirmDelete(id) {
    await mutateAsyncRemoverTabelaSF(id)
    closeConfirmDelete()
  }

  return (
    <ContentContainer>
      <PageHeader title="Tabela Salário Família">
        <ButtonBox top={1} bottom={1}>
          <Tooltip title="Expandir todos">
            <IconButton size="small" onClick={() => handleCollapseAll(true)}>
              <CollapseOpenIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Retrair todos">
            <IconButton size="small" onClick={() => handleCollapseAll(false)}>
              <CollapseCloseIcon />
            </IconButton>
          </Tooltip>
        </ButtonBox>
      </PageHeader>

      {isFetching ? <LinearProgress /> : <Box height={4} />}

      <Paper style={{ padding: '16px' }}>
        <Box display="flex" justifyContent="space-between">
          <CollapseContent title="Pesquisar por Ano" top={1} bottom={1} showContentFrame={false}>
            <AnoSelect
              onChange={(e) => {
                const ano = e.target.value
                setAnoCurrent(ano)
              }}
              value={anoCurrent || ''}
            />
          </CollapseContent>

          {isUserDataC && (
            <Box>
              <Button variant="contained" onClick={() => openForm(null)}>
                Adicionar
              </Button>
            </Box>
          )}
        </Box>

        <CollapsableListYears
          isLoading={isLoading}
          ano={anoCurrent}
          itens={itens}
          collapseItens={collapseItens}
          setCollapseItens={setCollapseItens}
          columns={columns}
          onClickEdit={openForm}
          onClickRemove={openConfirmDelete}
        />
      </Paper>
      {isOpenForm && <Form isOpen={isOpenForm} data={dataForm} onClose={closeForm} />}
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={() => handleConfirmDelete(dataConfirmDelete)}
          isDeleting={isLoadingRemoverTabelaSF}
        />
      )}
    </ContentContainer>
  )
}

export default TabelaSF
