import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew, SimpleAlert } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import { useGerarRescisaoTSV } from '~/hooks/queries/RescisaoTSV/useGerarRescisaoTSV'
import useAmbiente from '~/hooks/useAmbiente'

import { getDateMinAnoMes, dateHasInAnoMes } from '~/utils/utils'

import { VinculoMenuItem } from '~/hooks/queries/useEstabelecimento'
import {
  IndMotivoDesligamentoTSVEnum,
  IndMotivoDesligamentoTSVValue,
} from '~/@types/enums/IndMotivoDesligamentoTSVEnum'
import { VinculoTipoEnum, VinculoTipoLabels } from '~/@types/enums/VinculoTipoEnum'
import {
  CategoriaEnum,
  cateogriaEmpregadorContribuinteIndividual,
} from '~/@types/enums/CategoriaEnum'

const schema = yup.object().shape({
  dtRescisao: yup
    .date()
    .required('Informe a Data de Desligamento')
    .typeError('Informe uma data válida')
    .nullable()
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data informada deve está dentro da competência atual',
        (dt: string) => dateHasInAnoMes(dt, anoMes),
      ),
    ),
})

interface FormDataProps {
  dtRescisao: string | null
  motivoDesligamentoEmpregador721: IndMotivoDesligamentoTSVEnum | null
}

interface ReciboRescisaoTSVProps {
  isOpen: boolean
  onClose: () => void
  vinculo: VinculoMenuItem
  onAfterSubmit: (rpId: string | null) => void
}

export default function ReciboRescisaoTSV({
  isOpen,
  onClose,
  vinculo,
  onAfterSubmit,
}: ReciboRescisaoTSVProps) {
  const isEmpregador721 =
    vinculo.vinculoTipo === VinculoTipoEnum.Empregador_2 &&
    vinculo.vinculoCategoria === CategoriaEnum.Contribuinte_Individual_Diretor_Com_FGTS_721

  const isEmpregadorContribuinte = cateogriaEmpregadorContribuinteIndividual.includes(
    vinculo.vinculoCategoria,
  )

  const isAlertarRescisaoSimples =
    vinculo.vinculoTipo === VinculoTipoEnum.Autonomo_4 ||
    vinculo.vinculoTipo === VinculoTipoEnum.Cooperado_5 ||
    (vinculo.vinculoTipo === VinculoTipoEnum.Empregador_2 && !isEmpregadorContribuinte)

  const { anoMes } = useAmbiente()

  const initialState: FormDataProps = {
    dtRescisao: getDateMinAnoMes(anoMes),
    motivoDesligamentoEmpregador721: null,
  }

  const [data, setData] = useState<FormDataProps>(initialState)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { anoMes },
    },
  })

  const { mutateAsync, isLoading } = useGerarRescisaoTSV()

  async function handleSubmit() {
    const rpId = await mutateAsync({
      params: {
        dtRescisao: data.dtRescisao as string,
        motivoDesligamentoEmpregador721: data.motivoDesligamentoEmpregador721,
        vinculoId: vinculo.vinculoId,
      },
    })
    onAfterSubmit(rpId)
  }

  return (
    <ActionDialog
      title="Informe os dados para a Rescisão TSV"
      subtitle={vinculo.vinculoNome}
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      isOkProcessing={isLoading}
      okLabel="Gerar Rescisão"
    >
      <Grid container spacing={2}>
        {isAlertarRescisaoSimples && (
          <Grid item xs={12}>
            <SimpleAlert severity="info">
              <div>
                Para o tipo de vínculo <b>{VinculoTipoLabels[vinculo.vinculoTipo]}</b> vamos aplicar
                as seguintes configurações:
                <ul>
                  <li>
                    Gerar o evento <b>S-2399</b>.
                  </li>
                  <li>Gerar rescisão do vínculo.</li>
                </ul>
              </div>
            </SimpleAlert>
          </Grid>
        )}

        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Desligamento"
            value={data.dtRescisao}
            required
            name="dtRescisao"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtRescisao = date
              setData((prevState) => ({ ...prevState, dtRescisao }))
            }}
          />
        </Grid>

        {isEmpregador721 && (
          <Grid item xs={12}>
            <MUIAutoComplete
              label="Motivo de Desligamento / Empregador 721"
              value={data.motivoDesligamentoEmpregador721}
              onChange={(e, obj) => {
                const motivoDesligamentoEmpregador721 = obj?.value || null
                setData((prev) => ({ ...prev, motivoDesligamentoEmpregador721 }))
              }}
              options={IndMotivoDesligamentoTSVValue}
              renderOption={(option) => option.name}
              optionId="value"
            />
          </Grid>
        )}
      </Grid>
    </ActionDialog>
  )
}
