import { useEffect, useState } from 'react'

import { Finder } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer } from '~/components'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

export default function RelatorioFichaSalarioFamilia() {
  const [query, setQuery] = useState('')
  const [rowsSelecteds, setRowsSelected] = useState([])

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    estabelecimentoId: estabelecimento.id,
    anoMes,
  })
  const collection = _d || []

  useEffect(() => {
    setRowsSelected([])
  }, [_d])

  function handleAfterConfirm(dtAssinatura) {
    const vinculosId = rowsSelecteds.map((rowIndex) => collection[rowIndex].id)
    openPDFViewer({
      vinculosId,
      dtAssinatura,
    })
    setRowsSelected([])
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Ficha de Salário Família">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Table
        isLoading={isLoading}
        isFetching={isFetching}
        query={query}
        data={collection}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelecteds}
      />

      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={openDialogConfirm} disabled={!(rowsSelecteds.length > 0)} />
      </Box>

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        onClose={closeDialogConfirm}
        onAfterSubmit={handleAfterConfirm}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="FichaSalarioFamilia"
        title="Ficha de Salário Família"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/FichaSalarioFamilia/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
