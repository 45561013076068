import { formatToCNPJ } from 'brazilian-values'

import { IconButton } from '@material-ui/core'

import { CopyLabel, Stack, ToolsDataTable } from '~/components'

import TableTasks from './TableTasks'
import IconTask from './IconTask'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { FolhaAutomaticaTaskSimplesDTO } from '~/hooks/queries/FolhaAutomatica/dtos/FolhaAutomaticaTaskSimplesDTO'
import { FolhaAutomaticaDataDTO } from '~/hooks/queries/FolhaAutomatica/dtos/FolhaAutomaticaDataDTO'
import { HubConnection } from '@microsoft/signalr'

interface TableProps {
  data: FolhaAutomaticaDataDTO[]
  rowsExpanded: number[]
  setRowsExpanded: (rows: number[]) => void
  query: string
  isLoading: boolean
  isFetching: boolean
  tableRef: FixLater
  socket: HubConnection | null
}

export default function Table({
  data,
  rowsExpanded,
  setRowsExpanded,
  query,
  isLoading,
  isFetching,
  tableRef,
  socket,
}: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Código',
      name: 'empregadorCodigo',
      options: {
        setCellProps: () => ({
          style: { width: '1px' },
        }),
      },
    },
    {
      label: 'Empregador',
      name: 'empregadorNome',
      options: {
        setCellProps: () => ({
          style: { whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      label: 'CNPJ',
      name: 'empregadorNrInscricao',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCNPJ(value)}</CopyLabel>,
        setCellProps: () => ({
          style: { whiteSpace: 'nowrap' },
        }),
      },
    },
    {
      label: 'Tarefas',
      name: 'tarefas',
      options: {
        customBodyRender: (tarefas: FolhaAutomaticaTaskSimplesDTO[], { rowIndex }) => (
          <Stack orientation="horizontal" px={1}>
            {tarefas.map((d, index) => (
              <IconButton
                key={index}
                size="small"
                onClick={() => setRowsExpanded([rowIndex])}
                disabled={d?.id ? false : true}
              >
                <IconTask status={d?.status} icone={d.iconeTarefa} descricao={d.descricaoTarefa} />
              </IconButton>
            ))}
          </Stack>
        ),
        setCellProps: () => ({
          style: { width: '1px' },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      tableRef={tableRef}
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      optionsSelectable={{
        type: 'multiple',
      }}
      optionsExpandable={{
        type: 'single',
        rowsExpanded,
        onRowExpanded: setRowsExpanded,
        expandedChildren: (index) => {
          const empregadorId = data.at(index)?.empregadorId
          if (!empregadorId) return <div>Problema com o empregador detectado</div>
          return <TableTasks empregadorId={empregadorId} socket={socket} />
        },
      }}
      sherlock={{
        query,
        columns: ['empregadorCodigo', 'empregadorNome', 'empregadorNrInscricao'],
      }}
    />
  )
}
