export enum CompetenciaEmpregadorStatusEnum {
  Processando,
  NaoAberta,
  Aberta,
  Enviado,
  Fechada,
}

export const CompetenciaEmpregadorStatusValues = [
  {
    name: 'Processando',
    value: CompetenciaEmpregadorStatusEnum.Processando,
  },
  {
    name: 'Não Aberta',
    value: CompetenciaEmpregadorStatusEnum.NaoAberta,
  },
  {
    name: 'Aberta',
    value: CompetenciaEmpregadorStatusEnum.Aberta,
  },
  {
    name: 'Enviado',
    value: CompetenciaEmpregadorStatusEnum.Enviado,
  },
  {
    name: 'Fechada',
    value: CompetenciaEmpregadorStatusEnum.Fechada,
  },
]
