import { useEffect, useState, useCallback } from 'react'

import { Finder, Button } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer } from '~/components'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'

const PAGE_TITLE = 'Declaração para Salário Contribuição'

export default function DeclaracaoSalarioContribuicao() {
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isLoadingSendForEmail, setLoadingSendForEmail] = useState(false)
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelected] = useState([])
  const [report, setReport] = useState(null)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { estabelecimento } = useAmbiente()
  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
    data: dataDialogConfirm,
  } = useDialog()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  const getCollection = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get('/Vinculo/ObterVinculosPorEstabelecimento', {
        params: {
          estabelecimentoId: estabelecimento.id,
        },
      })
      setCollection(response?.data?.data || [])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [estabelecimento])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  useEffect(() => {
    async function getReportKey() {
      const response = await api.get('/Relatorio/GetByChaveId', {
        params: {
          chaveId: 'DeclaracaoSalarioContribuicao',
        },
      })
      setReport(response?.data?.data[0] || null)
    }
    getReportKey()
  }, [])

  function handleSendForEmail() {
    let haveWarning = false
    rowsSelecteds.forEach((rowIndex) => {
      if (!collection[rowIndex].email) haveWarning = true
    })
    if (haveWarning) {
      return dialogNotification.warning({
        descriptions: [
          'Alguns dos funcionários selecionados não tem um email cadastrado, deseja prosseguir?',
        ],
        onConfirm: () => openDialogConfirm('sendEmail'),
      })
    }
    openDialogConfirm('sendEmail')
  }

  function handlePrint() {
    openDialogConfirm('print')
  }

  function handleAfterSubmitDialogConfirm(dt, event) {
    const vinculosIds = rowsSelecteds.map((indexCurrent) => collection[indexCurrent].id)
    const newData = {
      vinculosIds,
      ...dt,
    }
    const functionsEvents = {
      sendEmail: sendForEmail,
      print: openPDFViewer,
    }
    setRowsSelected([])
    functionsEvents[event](newData)
  }

  async function sendForEmail(dt) {
    setLoadingSendForEmail(true)
    try {
      await api.post('/Relatorio/DeclaracaoSalarioContribuicao/EnviarDeclaracoesPorEmails', dt, {
        headers: {
          'dc-printmode': 'PDF',
          'dc-printrelatorioid': report?.id,
        },
      })
      notification.success('As Declarações foram enviadas por emails com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingSendForEmail(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={PAGE_TITLE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Table
        isLoading={isLoading}
        query={query}
        data={collection}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelecteds}
        afterSubmit={getCollection}
      />

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        onClose={closeDialogConfirm}
        onAfterSubmit={handleAfterSubmitDialogConfirm}
        data={dataDialogConfirm}
      />

      <Box display="flex" justifyContent="flex-end" py={1} gridGap={8}>
        <Button
          title="Enviar a Declaração para os emails selecionados"
          isLoading={isLoadingSendForEmail}
          disabled={!(rowsSelecteds.length > 0)}
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleSendForEmail}
        >
          Enviar por Email
        </Button>
        <PrintButton onClick={handlePrint} disabled={!(rowsSelecteds.length > 0)} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="DeclaracaoSalarioContribuicao"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/DeclaracaoSalarioContribuicao/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
