import React from 'react'

import { ErrorMessage } from 'mio-library-ui'
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  FormControlLabelProps,
  CheckboxProps as MUICheckboxProps,
} from '@material-ui/core'

import { obterErroValidationErrors } from '~/hooks/useUtils'

interface CheckboxProps extends Omit<FormControlLabelProps, 'value' | 'control' | 'label'> {
  value?: boolean
  validationErrors?: ValidationErrors
  checkBoxProps?: MUICheckboxProps
  fullWidth?: boolean
  component?: React.ElementType<any>
  label?: string | React.ReactNode
  indeterminate?: boolean
}

// eslint-disable-next-line react/display-name
const Checkbox = React.forwardRef((props: CheckboxProps, ref) => {
  const {
    value = false,
    name,
    validationErrors,
    checkBoxProps,
    fullWidth = true,
    component = 'fieldset',
    label,
    indeterminate,
    ...rest
  } = props
  const mensagemDeErro = obterErroValidationErrors(name, validationErrors)

  return (
    <FormControl component={component} fullWidth={fullWidth} ref={ref}>
      <FormControlLabel
        control={
          <MuiCheckbox
            size="small"
            name="ativo"
            color="secondary"
            checked={value && Boolean(value)}
            indeterminate={indeterminate}
            {...checkBoxProps}
          />
        }
        label={label}
        {...rest}
      />
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </FormControl>
  )
})

export default Checkbox
