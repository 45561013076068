export enum IndComissionistaEnum {
  Nao_1 = 1,
  ComissionistaIntegral_2 = 2,
  ComissionistaMisto_3 = 3,
}

export const IndComissionistaValues = [
  { value: IndComissionistaEnum.Nao_1, name: 'Não é Comissionista' },
  { value: IndComissionistaEnum.ComissionistaIntegral_2, name: 'Comissionista Integral' },
  { value: IndComissionistaEnum.ComissionistaMisto_3, name: 'Comissionista Misto' },
]
