import { useState } from 'react'

import { formatToCPF } from 'brazilian-values'
import { cpf } from 'cpf-cnpj-validator'
import * as yup from 'yup'

import { Box, Typography } from '@material-ui/core'

import { NumeroInscricaoTextField, ActionDialog } from '~/components'

import useDialog from '~/hooks/useDialog'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import {
  TipoCadastroInicialPessoaFisicaEnum,
  pessoaFisicaObterCadastroInicial,
} from '~/hooks/queries/PessoaFisica/pessoaFisicaObterCadastroInicial'

import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const schema = yup.object().shape({
  nrInscricao: yup
    .string()
    .required('Informe o CPF')
    .test('test-nrInscricao', 'CPF Inválido', (nrInscricao_) => cpf.isValid(nrInscricao_)),
})

export default function FormInitial(props) {
  const { onClose, isOpen, onAfterSubmitFormInitial } = props

  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()

  const {
    close: closeDialogAlredyPessoa,
    data: dataDialogAlredyPessoa,
    isOpen: isOpenDialogAlredyPessoa,
    open: openDialogAlredyPessoa,
  } = useDialog(null)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const response = await pessoaFisicaObterCadastroInicial(data.nrInscricao)

      switch (response.tipoCadastroInicial) {
        case TipoCadastroInicialPessoaFisicaEnum.EncontradoBase:
          openDialogAlredyPessoa(response)
          break
        case TipoCadastroInicialPessoaFisicaEnum.EncontradoBaseCommon:
          onAfterSubmitFormInitial(response.tipoCadastroInicial, response.pessoaFisica)
          break
        default:
          onAfterSubmitFormInitial(response.tipoCadastroInicial, response.pessoaFisicaInicial)
          break
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function submitFormAlredyExistPessoaFisica(response) {
    closeDialogAlredyPessoa()
    onAfterSubmitFormInitial(response.tipoCadastroInicial, response.pessoaFisica)
  }

  return (
    <ActionDialog
      title="Cadastro Pessoa Física"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      okLabel="Continuar"
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <NumeroInscricaoTextField
        typeMask={TipoInscricaoEnum.CPF_2}
        label="CPF"
        value={data?.nrInscricao || ''}
        name="nrInscricao"
        required
        validationErrors={validationErrors}
        onChange={(e, value) => {
          const nrInscricao = value
          setData((oldState) => ({
            ...oldState,
            nrInscricao,
          }))
        }}
      />

      {isOpenDialogAlredyPessoa && (
        <ActionDialog
          title="Temos um aviso"
          okLabel="Sim"
          cancelLabel="Não"
          isOpen={isOpenDialogAlredyPessoa}
          onClose={closeDialogAlredyPessoa}
          onCancelClick={closeDialogAlredyPessoa}
          onOkClick={() => submitFormAlredyExistPessoaFisica(dataDialogAlredyPessoa)}
        >
          <Box>
            <Typography variant="body1" component="div">
              A pessoa <b>{dataDialogAlredyPessoa.pessoaFisica.nome}</b> -{' '}
              <b>{formatToCPF(dataDialogAlredyPessoa.pessoaFisica.nrInscricao)}</b> já está
              cadastrada no sistema!
            </Typography>
            <Typography variant="body1" component="div">
              Você deseja acessar o cadastro?
            </Typography>
          </Box>
        </ActionDialog>
      )}
    </ActionDialog>
  )
}
