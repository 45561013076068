import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: FormData
  params: {
    anoMes: string
    estabelecimentoId: string
  }
}

export default function useImportarPlanilha() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{ data: string[] }>(
      '/ImportacaoPlanilhaFaltas/ImportarPlanilha',
      data,
      {
        params,
      },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Planilha importada com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
