import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { AutoCompleteLotacao } from '~/components/AutoComplete'
import { DatePicker } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import * as yup from 'yup'

const schema = yup.object().shape({
  lotacao: yup.string().required('Informe Lotação').nullable(),
})

const VinculoLotacao = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, empregadorId } = props
  const [data, setData] = useState({})

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    onAfterSubmitForm(data)

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Lotação"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteLotacao
            required
            validationErrors={validationErrors}
            name="lotacao"
            onChange={(e, lotacao) => {
              const lotacaoId = lotacao ? lotacao.id : ''
              setData({ ...data, lotacaoId, lotacao })
            }}
            value={data?.lotacao || null}
            empregadorId={empregadorId}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Ano/Mês"
            fullWidth
            value={data?.anoMes || null}
            size="small"
            format="yyyy/MM"
            views={['year', 'month']}
            disabled
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Dias"
            fullWidth
            value={data?.dias || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{ maxLength: 5 }}
            onChange={(e) => {
              const dias = e.target.value
              setData({ ...data, dias })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data?.observacao || ''}
            variant="outlined"
            size="small"
            multiline
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default VinculoLotacao
