import React from 'react'

import { useTheme, Grid } from '@material-ui/core'

import LabelValueNew from '~/components/LabelValueNew'

import { formatCurrencyAcceptZeros } from '~/hooks/useUtils'

interface ValroesBaseProps {
  vrBaseINSS: number
  vrBaseINSS13: number
  vrBaseIRRF: number
  vrBaseIRRF13: number
  vrBaseFGTS: number
  vrBaseFGTS13: number
}
export default function ValoresBase(props: ValroesBaseProps) {
  const { vrBaseINSS, vrBaseINSS13, vrBaseIRRF, vrBaseIRRF13, vrBaseFGTS, vrBaseFGTS13 } = props

  const theme: FixLater = useTheme()

  const styleBorderRight: React.CSSProperties = {
    borderRight: theme.shape.border,
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4} lg={2} style={styleBorderRight}>
        <LabelValueNew
          value={formatCurrencyAcceptZeros(vrBaseINSS)}
          label="INSS:"
          title="Base INSS"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} style={styleBorderRight}>
        <LabelValueNew
          value={formatCurrencyAcceptZeros(vrBaseINSS13)}
          label="INSS13°:"
          title="Base INSS13°"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} style={styleBorderRight}>
        <LabelValueNew
          value={formatCurrencyAcceptZeros(vrBaseIRRF)}
          label="IRRF:"
          title="Base IRRF"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} style={styleBorderRight}>
        <LabelValueNew
          value={formatCurrencyAcceptZeros(vrBaseIRRF13)}
          label="IRRF13°:"
          title="Base IRRF13°"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} style={styleBorderRight}>
        <LabelValueNew
          value={formatCurrencyAcceptZeros(vrBaseFGTS)}
          label="FGTS:"
          title="Base FGTS"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <LabelValueNew
          value={formatCurrencyAcceptZeros(vrBaseFGTS13)}
          label="FGTS13°:"
          title="Base FGTS13°"
        />
      </Grid>
    </Grid>
  )
}
