import { useEffect, useState } from 'react'

import clsx from 'clsx'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { IconButton, Tooltip, makeStyles } from '@material-ui/core'
import { People as PeopleIcon } from '@material-ui/icons'

import {
  ActionDialog,
  Button,
  ButtonBox,
  Checkbox,
  CopyLabel,
  Finder,
  ToolsDataTable,
} from '~/components'

import FGTSBeneficiario from '~/pages/FGTSBeneficiario'

import FGTSLotacao from './FGTSLotacao'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { formatCurrency, setImportantCSS } from '~/hooks/useUtils'

import useObterApuracaoEncargosEstabelecimentoFGTS from '~/hooks/queries/ApurarEncargos/useObterApuracaoEncargosEstabelecimentoFGTS'

import { ApuracaoEncargosFGTSDTO } from '~/hooks/queries/ApurarEncargos/ApuracaoEncargosFGTSDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'

const useStyles = makeStyles((theme: FixLater) => ({
  fullHeight: {
    height: '100%',
  },
  alignRight: {
    textAlign: 'right',
  },
  hightLightRow: {
    backgroundColor: setImportantCSS(theme.palette.error.light),
    color: setImportantCSS(theme.palette.error.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.error.lighter),
    },
  },
}))

interface FGTSEstabelecimentoProps {
  isOpen: boolean
  onClose: () => void
  empregador: ApuracaoEncargosFGTSDTO
  isSomenteDiferenca: boolean
}

export default function FGTSEstabelecimento({
  isOpen,
  onClose,
  empregador,
  isSomenteDiferenca: _isSomenteDiferenca,
}: FGTSEstabelecimentoProps) {
  const [query, setQuery] = useState('')
  const [isSomenteDiferenca, setSomenteDiferenca] = useState(_isSomenteDiferenca)
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])

  const { anoMes } = useAmbiente()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterApuracaoEncargosEstabelecimentoFGTS({
    empregadorId: empregador.id,
    periodoApuracao: anoMes,
    isSomenteDiferenca,
  })

  useEffect(() => {
    setRowsExpanded([])
  }, [_d])

  const _data = _d || []

  const data = _data.map((d) => {
    return {
      ...d,
      vrBaseFolha: d?.vrBaseFolha ? formatCurrency(d.vrBaseFolha) : '',
      vrFolha: d?.vrFolha ? formatCurrency(d.vrFolha) : '',
      vrBaseESocial: d?.vrBaseESocial ? formatCurrency(d.vrBaseESocial) : '',
      vrESocial: d?.vrESocial ? formatCurrency(d.vrESocial) : '',
      vrDiferenca: d?.vrDiferenca ? formatCurrency(d.vrDiferenca) : '',
      vrBaseRescisorio: d?.vrBaseRescisorio ? formatCurrency(d.vrBaseRescisorio) : '',
      vrRescisorio: d?.vrRescisorio ? formatCurrency(d.vrRescisorio) : '',
    }
  })

  const {
    close: closeDialogFGTSBeneficiario,
    data: dataDialogFGTSBeneficiario,
    isOpen: isOpenDialogFGTSBeneficiario,
    open: openDialogFGTSBeneficiario,
  } = useDialog<string | null>(null)
  const classes = useStyles()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'vrBaseFolha',
      label: 'Folha Base',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrBaseESocial',
      label: 'ESocial Base',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrBaseRescisorio',
      label: 'Rescisão Base',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrFolha',
      label: 'Folha',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrESocial',
      label: 'ESocial',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrRescisorio',
      label: 'Rescisão',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
    {
      name: 'vrDiferenca',
      label: 'Diferença',
      options: {
        setCellProps: () => ({ className: classes.alignRight }),
      },
    },
  ]

  return (
    <ActionDialog
      title={`Apuração Encargos - ${
        formatToCPFOrCNPJ(empregador.nrInscricao) + ' - ' + empregador.nome
      }`}
      isOpen={isOpen}
      dialogProps={{
        maxWidth: 'xl',
        fullWidth: true,
      }}
      onClose={onClose}
      customActions={
        <ButtonBox bottom={1} right={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      renderRight={
        <ButtonBox>
          <Checkbox
            label="Somente com Diferença"
            value={isSomenteDiferenca}
            onChange={(e, checked) => setSomenteDiferenca(checked)}
          />
          <Tooltip title="FGTS por Beneficiário" arrow>
            <span>
              <IconButton
                color="primary"
                size="small"
                onClick={() => openDialogFGTSBeneficiario(empregador.id)}
              >
                <PeopleIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </ButtonBox>
      }
    >
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        disableAutoHeight
        options={{
          setRowProps: (_: FixLater, index: number) => {
            const rowCurrent = _data[index]
            if (!rowCurrent) return
            const isDiferenca = rowCurrent.temDiferenca
            return {
              className: clsx({
                [classes.hightLightRow]: isDiferenca,
              }),
            }
          },
        }}
        sherlock={{
          columns: ['nrInscricao', 'nome'],
          query,
        }}
        optionsExpandable={{
          onRowExpanded: setRowsExpanded,
          rowsExpanded: rowsExpanded,
          type: 'single',
          expandedChildren: (index) => <FGTSLotacao estabelecimento={_data[index]} />,
        }}
      />
      <ActionDialog
        title={`Apuração Encargos - ${
          formatToCPFOrCNPJ(empregador.nrInscricao) + ' - ' + empregador.nome
        }`}
        isOpen={isOpenDialogFGTSBeneficiario}
        dialogProps={{
          maxWidth: 'md',
          fullWidth: true,
          classes: {
            paper: classes.fullHeight,
          },
        }}
        onClose={closeDialogFGTSBeneficiario}
        customActions={
          <ButtonBox bottom={1} right={1}>
            <Button variant="contained" onClick={closeDialogFGTSBeneficiario}>
              Fechar
            </Button>
          </ButtonBox>
        }
      >
        {dataDialogFGTSBeneficiario && (
          <FGTSBeneficiario
            empregadorId={dataDialogFGTSBeneficiario}
            isSomenteDiferenca={isSomenteDiferenca}
          />
        )}
      </ActionDialog>
    </ActionDialog>
  )
}
