import { useState, ReactNode } from 'react'

import { Box, Icon, makeStyles } from '@material-ui/core'
import { copyToClipboard } from '~/hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

interface CopyLabelProps {
  value: string
  children: ReactNode
}

export default function CopyLabel({ value, children }: CopyLabelProps) {
  const [isHover, setHover] = useState(false)

  const classes = useStyles()

  return (
    <Box display="flex">
      <Box
        className={classes.root}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => copyToClipboard(value)}
      >
        {children}
        <Icon fontSize="small" color="primary">
          {isHover ? 'file_copy' : 'none'}
        </Icon>
      </Box>
    </Box>
  )
}
