import React, { useState } from 'react'

import { Box, useTheme } from '@material-ui/core'

import { PageHeader, Finder, ExcelButton, PDFViewer, PDFButton } from '~/components'

import Table from './Table'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useObterFuncionarios } from '~/hooks/queries/RelatorioSalarioContratual/useObterFuncionarios'
import { relatorioSalarioContratualObterRelatorioExcel } from '~/hooks/queries/RelatorioSalarioContratual/relatorioSalarioContratualObterRelatorioExcel'

const PAGE_TITLE = 'Relatório - Salário Contratual'

export default function RelSalarioContratual() {
  const [query, setQuery] = useState('')
  const [isLoadingPrintExcel, setLoadingPrintExcel] = useState(false)

  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<{ estabelecimentoId: string; anoMes: string }>({
    anoMes,
    estabelecimentoId: estabelecimento.id,
  })
  const { data, isLoading, isFetching } = useObterFuncionarios(estabelecimento.id, anoMes)
  const theme = useTheme()

  async function handlePrintExcel() {
    setLoadingPrintExcel(true)
    try {
      await relatorioSalarioContratualObterRelatorioExcel(estabelecimento, anoMes)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingPrintExcel(false)
    }
  }

  async function handlePrintPDF() {
    openPDFViewer({
      anoMes,
      estabelecimentoId: estabelecimento.id,
    })
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={PAGE_TITLE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Table query={query} data={data} isFetching={isFetching} isLoading={isLoading} />

      <Box display="flex" justifyContent="flex-end" py={1} gridGap={theme.spacing(2)}>
        <ExcelButton
          disabled={!(data.length > 0)}
          isLoading={isLoadingPrintExcel}
          onClick={handlePrintExcel}
        />
        <PDFButton disabled={!(data.length > 0)} onClick={handlePrintPDF} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="SalarioContratual"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'get',
          url: '/Relatorio/SalarioContratual/ObterRelatorioPDF',
          params: {
            estabelecimentoId: dataPDFViewer.estabelecimentoId,
            anoMes: dataPDFViewer.anoMes,
          },
        }}
      />
    </Box>
  )
}
