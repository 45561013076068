import api from '~/services/api-pessoal'

import { useQuery, useQueryClient } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoFeriasItem } from './dtos/VinculoFeriasItem'

const query = '/VinculoFeriasItem/GetByVinculoFerias'

interface RequestParams {
  vinculoFeriasId: string
}

export default function useGetByVinculoFerias(params: RequestParams) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoFeriasItem[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useGetByVinculoFeriasInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
