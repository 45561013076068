import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import { IndGrauExposicaoEnum, IndGrauExposicaoValues } from '~/@types/enums/IndGrauExposicaoEnum'

import { ActionDialog, AnoMesTextField, ContentDivider, CurrencyTextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import { ProcessoTrabalhistaVinculoBaseDTO } from '~/hooks/queries/ProcessoTrabalhistaVinculo/dtos/ProcessoTrabalhistaVinculoCreateDTO'

const schema = yup.object().shape({
  periodoReferencia: yup.string().required('Informe a Competência'),
  indGrauExposicao: yup
    .mixed()
    .oneOf(Object.values(IndGrauExposicaoEnum), 'Informe o Grau de Exposição'),
})

interface FormPeriodoProps {
  isOpen: boolean
  onClose: () => void
  data: ProcessoTrabalhistaVinculoBaseDTO
  onChange: (d: ProcessoTrabalhistaVinculoBaseDTO) => void
}

export default function FormPeriodo({ isOpen, onClose, onChange, data: _data }: FormPeriodoProps) {
  const [data, setData] = useState(_data)

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  function handleSubmit() {
    onChange(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Período"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      onOkClick={handleValidate}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AnoMesTextField
            label="Competência"
            required
            validationErrors={validationErrors}
            name="periodoReferencia"
            value={data.periodoReferencia || null}
            onChange={(e) => {
              const competencia = e.target.value.replace('/', '')
              setData((prev) => ({ ...prev, periodoReferencia: competencia }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIAutoComplete
            label="Grau de Exposição"
            required
            validationErrors={validationErrors}
            name="indGrauExposicao"
            options={IndGrauExposicaoValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.indGrauExposicao}
            onChange={(e, obj) => {
              const indGrauExposicao = obj?.value || ''
              setData((prev) => ({ ...prev, indGrauExposicao }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ContentDivider title="Base de Cálculo de Contribuição Previdenciária" />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo sobre a remuneração mensal do trabalhador"
            value={data.vrBcCpMensal || ''}
            onChange={(_, value) => {
              const vrBcCpMensal = value
              setData((oldState) => ({
                ...oldState,
                vrBcCpMensal,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo sobre a remuneração mensal do trabalhador referente ao 13° Salário"
            value={data.vrBcCp13 || ''}
            onChange={(_, value) => {
              const vrBcCp13 = value
              setData((oldState) => ({
                ...oldState,
                vrBcCp13,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ContentDivider title="Base de Cálculo para o FGTS" />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo ainda não declarada em SEFIP ou eSocial, inclusiva de verbas reconhecidas no processo"
            value={data.vrBcFGTSProcessoTrabalhista || ''}
            onChange={(_, value) => {
              const vrBcFGTSProcessoTrabalhista = value
              setData((oldState) => ({
                ...oldState,
                vrBcFGTSProcessoTrabalhista,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo declarada apenas em SEFIP, não informada no eSocial e ainda não recolhida"
            value={data.vrBcFGTSSefip || ''}
            onChange={(_, value) => {
              const vrBcFGTSSefip = value
              setData((oldState) => ({
                ...oldState,
                vrBcFGTSSefip,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Base cálculo FGTS declarada anteriormente no eSocial e não recolhida"
            value={data.vrBcFGTSDeclaradaAnteriormente || ''}
            onChange={(_, value) => {
              const vrBcFGTSDeclaradaAnteriormente = value
              setData((oldState) => ({
                ...oldState,
                vrBcFGTSDeclaradaAnteriormente,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
