import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterLotacaoConfigPorId'

import { notifyPut } from '~/utils/notification'

import { LotacaoConfig } from './dtos/LotacaoConfig'

interface RequestProps {
  data: LotacaoConfig
  params: { id: string }
}

export function useAtualizarLotacaoConfigProvisao() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.put<{ data: LotacaoConfig }>(
      '/ExportacaoLancamentoConfig/AtualizarLotacaoConfigProvisao',
      data,
      { params },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
