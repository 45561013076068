import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate } from './useObterConfiguracoes'

import { RelatorioAdmissionalConfigMultiploDTO } from './dtos/RelatorioAdmissionalConfigMultiploDTO'
import { notifySuccess } from '~/utils/notification'

export function useSalvarMultiplasConfiguracoes() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest(data: RelatorioAdmissionalConfigMultiploDTO) {
    await api.post('/RelatorioAdmissional/SalvarMultiplasConfiguracoes', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Configurações múltiplas processadas com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
