import React, { useCallback, useEffect, useState } from 'react'

import moment from 'moment'
import * as yup from 'yup'
import api from '~/services/api-pessoal'

import { Box, Grid } from '@material-ui/core'

import { AnoMesTextField, Button } from '~/components'

import Table from './Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'

const schema = yup.object().shape({
  anoInicio: yup.string().required('Informe o Ano/Mês Início').min(6, 'Informe o Ano/Mês válido'),
  anoFim: yup
    .string()
    .required('Informe o Ano/Mês Fim')
    .min(6, 'Informe o Ano/Mês válido')
    .when(['anoInicio'], (anoInicio, schema, { value }) => {
      if (moment(anoInicio).isAfter(value)) {
        return schema.min(anoInicio, 'Ano Fim deve ser maior que Ano Início')
      }
    }),
})

export interface RPConsultaData {
  id: string
  vinculoId: string
  vinculoNome: string
  vinculoEndereco: string
  vinculoCidadeId: string
  vinculoCidadeNome: string
  vinculoCidadeUF: string
  estabelecimentoId: string
  estabelecimentoNome: string
  lotacaoNome: string
  idf: ReciboIDFEnum
  nrRecibo: number
  anoMes: string
  fatoGerador: string
  lotacaoId: string
  vrSalarioContratual: number
  vrTotalVencimento: number
  vrTotalDesconto: number
  vrTotalLiquido: number
  vrBaseINSS: number
  vrBaseIRRF: number
  vrBaseIRRFBruto: number
  vrBaseFGTS: number
  vrBaseFGTSAvisoPrevio: number
  vrBaseINSS13: number
  vrBaseIRRF13: number
  vrBaseIRRFBruto13: number
  vrBaseFGTS13: number
  isFinalizado: boolean
  nrReciboeSocial: string | null
  lotacao: null
  vinculo: null
  rpItens: null
}

interface Params {
  anoInicio: string
  anoFim: string
}

interface DialogRPsConsultaProps {
  vinculoId: string
}

export default function DialogRPsConsulta(props: DialogRPsConsultaProps) {
  const { vinculoId } = props

  const [data, setData] = useState<RPConsultaData[]>([])
  const [isLoading, setLoading] = useState(false)

  const { anoMes } = useAmbiente()
  const anoMesLess = moment(anoMes).subtract(1, 'month').format('yyyyMM')

  const [params, setParams] = useState<Params>({ anoInicio: anoMesLess, anoFim: anoMesLess })

  const dialogNotification = useDialogNotification()

  const getData = useCallback(
    async (params: Params) => {
      setLoading(true)
      try {
        const response = await api.get<{ data: RPConsultaData[] }>('RPConsulta/GetByVinculo', {
          params: {
            vinculoId,
            anoMesInicio: params.anoInicio,
            anoMesFim: params.anoFim,
          },
          data: [],
        })
        setData(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    },
    // eslint-disable-next-line
    [vinculoId],
  )

  useEffect(() => {
    getData(params)
    // eslint-disable-next-line
  }, [getData])

  const { handleValidate, validationErrors } = useValidationErrors({
    data: params,
    schema,
    handleSubmit,
  })

  function handleSubmit() {
    getData(params)
  }

  return (
    <Box height="100%">
      <Box height={50}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5}>
            <AnoMesTextField
              label="Ano/Mês Início"
              fullWidth
              variant="outlined"
              size="small"
              validationErrors={validationErrors}
              name="anoInicio"
              value={params.anoInicio || ''}
              onChange={(e: FixLater) => {
                const anoInicio = e.target.value.replace('/', '') || ''
                setParams((prev) => ({ ...prev, anoInicio }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <AnoMesTextField
              label="Ano/Mês Fim"
              fullWidth
              variant="outlined"
              size="small"
              validationErrors={validationErrors}
              name="anoFim"
              value={params.anoFim || ''}
              onChange={(e: FixLater) => {
                const anoFim = e.target.value.replace('/', '') || ''
                setParams((prev) => ({ ...prev, anoFim }))
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={2}
            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
          >
            <Button variant="contained" onClick={handleValidate} isLoading={isLoading}>
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Table data={data} isLoading={isLoading} />
    </Box>
  )
}
