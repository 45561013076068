export enum HomologNetEnum {
  RescisaoPorCulpaReciproca_CRO = 1,
  RescisãoPorFalecimentoEmpregadorOpcaoEmempregado_FE1 = 2,
  RescisãoPorFalecimentoEmpregadorSemContinuiadeAtividadeDaEmpresa_FE2 = 3,
  RescisãoPorForcaMaior_FM0 = 4,
  RescisãoPorFalecimentoEmpregado_FT1 = 5,
  DespedidaPorJustaCausaPeloEmpregador_JC2 = 6,
  ExtinçãoNormalNormalContratoPrazoDeterminado_PD0 = 7,
  RescisãoAntecipadaPeloEmpregadoContratoPrazoDeterminado_RA1 = 8,
  RescisãoAntecipadaPeloEmpregadorContratoPrazoDeterminado_RA2 = 9,
  Rescisão_Indireta_RI2 = 10,
  RescisãoContratualPedidoEmpregado_SJ1 = 11,
  DespedidaSemJustaCausaPeloEmpregador_SJ2 = 12,
  NaoSeApica_99 = 99,
}

export const HomologNetValues = [
  {
    value: HomologNetEnum.RescisaoPorCulpaReciproca_CRO,
    name: 'CRO - Rescisão por culpa recíproca',
  },
  {
    value: HomologNetEnum.RescisãoPorFalecimentoEmpregadorOpcaoEmempregado_FE1,
    name: 'FE1 - Rescisão do contrato de trabalho por falecimento do empregador indiv. por opção do empregado',
  },
  {
    value: HomologNetEnum.RescisãoPorFalecimentoEmpregadorSemContinuiadeAtividadeDaEmpresa_FE2,
    name: 'FE2 - Rescisão do contrato de trabalho por falecimento do empregador indiv. sem cont. da ativ.da empresa',
  },
  { value: HomologNetEnum.RescisãoPorForcaMaior_FM0, name: 'FM0 - Rescisao por forca maior' },
  {
    value: HomologNetEnum.RescisãoPorFalecimentoEmpregado_FT1,
    name: 'FT1 - Rescisão do contrato de trabalho por falecimento do empregado',
  },
  {
    value: HomologNetEnum.DespedidaPorJustaCausaPeloEmpregador_JC2,
    name: 'JC2 - Despedida por justa causa, pelo empregador',
  },
  {
    value: HomologNetEnum.ExtinçãoNormalNormalContratoPrazoDeterminado_PD0,
    name: 'PD0 - Extinção normal do contrato de trabalho por prazo determinado',
  },
  {
    value: HomologNetEnum.RescisãoAntecipadaPeloEmpregadoContratoPrazoDeterminado_RA1,
    name: 'RA1 - Rescisão antecipada, pelo empregado, do contrato de trabalho por prazo determinado',
  },
  {
    value: HomologNetEnum.RescisãoAntecipadaPeloEmpregadorContratoPrazoDeterminado_RA2,
    name: 'RA2 - Rescisão antecipada, pelo empregador, do contrato de trabalho por prazo determinado',
  },
  {
    value: HomologNetEnum.Rescisão_Indireta_RI2,
    name: 'RI2 - Rescisão Indireta',
  },
  {
    value: HomologNetEnum.RescisãoContratualPedidoEmpregado_SJ1,
    name: 'SJ1 - Rescisão contratual a pedido do empregado',
  },
  {
    value: HomologNetEnum.DespedidaSemJustaCausaPeloEmpregador_SJ2,
    name: 'SJ2 - Despedida sem justa causa, pelo empregador',
  },
]
