import React, { useEffect, useState } from 'react'
import { AppBar, Box, Grid, makeStyles, Toolbar, Typography, Tab, Tabs } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import apiPessoal from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'
import { TabPanel } from '~/components'
import TableLog from './components/TableLog'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '450px',
  },
  content: {
    // padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  box: {
    borderRadius: 4,
    padding: theme.spacing(1),
    boxShadow: '0px 0px 3px black',
    marginBottom: '10px',
  },
  tabText: {
    textTransform: 'none',
  },
}))

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const ResumoCompetencia = ({ competenciaEstabelecimentoId }) => {
  const [competencia, setCompetencia] = useState({})
  const [tabValue, setTabValue] = useState(0)
  const [totais, setTotais] = useState({
    error: 0,
    warning: 0,
  })
  const [logs, setLogs] = useState({
    error: [],
    warning: [],
  })

  const dialogNotification = useDialogNotification()
  const classes = useStyles()

  const apiRoute = 'competenciaempregador'

  useEffect(() => {
    const getCompetencia = async () => {
      try {
        const response = await apiPessoal.get(
          `${apiRoute}/GetResumoCompetencia?competenciaEstabelecimentoId=${competenciaEstabelecimentoId}`,
        )
        const { data } = response.data

        setCompetencia(data)

        // set totais
        let error = 0
        let warning = 0

        error += data.getValidarCount.item1
        warning += data.getValidarCount.item2

        error += data.getIniciarCount.item1
        warning += data.getIniciarCount.item2

        error += data.getFinalizarCount.item1
        warning += data.getFinalizarCount.item2

        setTotais({ error, warning })

        // logs
        let errorLogs = []
        let warningLogs = []

        const validarLogs = data?.validarLogs || []
        const iniciarLogs = data?.iniciarLogs || []
        const finalizarLogs = data?.finalizarLogs || []

        validarLogs.forEach((log) => {
          if (log.isErro) {
            errorLogs.push({ operacao: 'Validar', ...log })
          }

          warningLogs.push({ operacao: 'Validar', ...log })

          return null
        })

        iniciarLogs.forEach((log) => {
          if (log.isErro) {
            errorLogs.push({ operacao: 'Iniciar', ...log })
          }

          warningLogs.push({ operacao: 'Iniciar', ...log })
          return null
        })

        finalizarLogs.forEach((log) => {
          if (log.isErro) {
            errorLogs.push({ operacao: 'Finalizar', ...log })
          }

          warningLogs.push({ operacao: 'Finalizar', ...log })
          return null
        })

        setLogs({ error: errorLogs, warning: warningLogs })
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    }

    getCompetencia()

    // eslint-disable-next-line
  }, [])

  const handleChangeTabs = (e, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography>
              <strong>Estabelecimento: </strong>
              {competencia?.estabelecimento?.nome}
            </Typography>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography>
              <strong>Status:</strong> {competencia?.statusDescricao}
            </Typography>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <ButtonBox>
              <Typography>
                <strong>Total de Recibos:</strong> {competencia?.totalRecibos}
              </Typography>
            </ButtonBox>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.content}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar variant="dense" disableGutters>
            <Tabs
              value={tabValue}
              onChange={handleChangeTabs}
              scrollButtons="on"
              variant="scrollable"
              style={{ textTransform: 'none' }}
            >
              <Tab
                label={`Avisos (${totais.warning})`}
                {...a11yProps(0)}
                className={classes.tabText}
              />
              <Tab
                label={`Erros (${totais.error})`}
                {...a11yProps(1)}
                className={classes.tabText}
              />
            </Tabs>
          </Toolbar>
        </AppBar>
      </Box>

      <Box className={classes.content}>
        <TabPanel value={tabValue} index={0}>
          <TableLog data={logs.warning} />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <TableLog data={logs.error} />
        </TabPanel>
      </Box>
    </Box>
  )
}

export default ResumoCompetencia
