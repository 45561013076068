import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, CurrencyTextField, DatePickerNew, TextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCreateSalarioItem } from '~/hooks/queries/SalarioItem/useCreateSalarioItem'
import { useUpdateSalarioItem } from '~/hooks/queries/SalarioItem/useUpdateSalarioItem'

import { SalarioItem } from '~/hooks/queries/SalarioItem/SalarioItem'

const schema = yup.object().shape({
  dtSalario: yup.date().required('Informe a Data').typeError('Informe uma data válida'),
  vrSalarioBase: yup.number().min(0.0001, 'Informe o Valor Base I'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: SalarioItem
}

export default function Form({ isOpen, onClose, data: _data }: FormProps) {
  const isUpdate = _data.id ? true : false

  const [data, setData] = useState(_data)

  const { mutateAsync: mutateAsyncCreate, isLoading: isCreating } = useCreateSalarioItem()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } = useUpdateSalarioItem()

  const isLoading = isCreating || isUpdating

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    const { id, ...rest } = data
    if (isUpdate) {
      await mutateAsyncUpdate({
        data: rest,
        id: id,
      })
    } else {
      await mutateAsyncCreate(rest)
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Item do Salário"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isLoading}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data"
            value={data.dtSalario || null}
            onChange={(time) => {
              const dtSalario = time || ''
              setData((oldState) => ({
                ...oldState,
                dtSalario,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="dtSalario"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <CurrencyTextField
            label="Valor Base I"
            value={data.vrSalarioBase || ''}
            onChange={(_, value) => {
              const vrSalarioBase = value
              setData((oldState) => ({
                ...oldState,
                vrSalarioBase,
              }))
            }}
            required
            validationErrors={validationErrors}
            name="vrSalarioBase"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CurrencyTextField
            label="Valor Base II"
            value={data.vrSalarioBaseII || ''}
            onChange={(_, value) => {
              const vrSalarioBaseII = value
              setData((oldState) => ({
                ...oldState,
                vrSalarioBaseII,
              }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CurrencyTextField
            label="Valor Base III"
            value={data.vrSalarioBaseIII || ''}
            onChange={(_, value) => {
              const vrSalarioBaseIII = value
              setData((oldState) => ({
                ...oldState,
                vrSalarioBaseIII,
              }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CurrencyTextField
            label="Valor Base IV"
            value={data.vrSalarioBaseIV || ''}
            onChange={(_, value) => {
              const vrSalarioBaseIV = value
              setData((oldState) => ({
                ...oldState,
                vrSalarioBaseIV,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Observação"
            value={data.observacao}
            inputProps={{
              maxLength: 100,
            }}
            multiline
            rows={2}
            onChange={(e) => {
              const observacao = e.target.value
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
