import React, { useEffect, useState } from 'react'

import * as yup from 'yup'
import { isCNPJ } from 'brazilian-values'

import { Box, Grid, LinearProgress } from '@material-ui/core'
import { Button, TextField, ButtonBox } from 'mio-library-ui'

import { NumeroInscricaoTextField, DatePickerNew } from '~/components'
import { AutoCompleteEmpregador, MUIAutoComplete } from '~/components/AutoComplete'

import {
  getEstabelecimentoById,
  useEstabelecimentoMutate,
} from '~/hooks/queries/useEstabelecimentoOld'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import { tipoInscricaoConsts, tipoInscricaoValues } from '~/values/tipoInscricaoValues'

const tiposInscricao = tipoInscricaoValues.filter(
  (obj) =>
    obj.value === tipoInscricaoConsts.CNPJ_1 ||
    obj.value === tipoInscricaoConsts.CAEPF_3 ||
    obj.value === tipoInscricaoConsts.CNO_4,
)

const schema = yup.object().shape({
  empregadorId: yup.string().required('Informe o Empregador'),
  nome: yup.string().required('Informe o Nome'),
  tipoInscricao: yup.string().required('Informe o Tipo de Inscrição'),
  nrInscricao: yup
    .string()
    .required('Informe o Número de Inscrição')
    .when(['tipoInscricao'], (tipoInscricao, schema) => {
      if (parseInt(tipoInscricao) === tipoInscricaoConsts.CNPJ_1) {
        return schema.test('cnpj-test', 'Informe um CNPJ válido', (value) => isCNPJ(value))
      }
      if (parseInt(tipoInscricao) === tipoInscricaoConsts.CNO_4) {
        return schema.test('cno-test', 'Informe um CNO válido', (value) => value.length === 12)
      }
      if (parseInt(tipoInscricao) === tipoInscricaoConsts.CAEPF_3) {
        return schema.test('caepf-test', 'Informe um CAEPF válido', (value) => value.length === 14)
      }
      return schema
    }),
})

export default function Form(props) {
  const { estabelecimentoId, onClose } = props
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })
  const { mutateCreateEstabelecimentoSST, mutateUpdateEstabelecimento } = useEstabelecimentoMutate()
  const notification = useNotification()

  useEffect(() => {
    async function getEstabelecimentoFisica() {
      if (!estabelecimentoId) return
      setLoading(true)
      try {
        const estabelecimento = await getEstabelecimentoById(estabelecimentoId)
        setData(estabelecimento)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getEstabelecimentoFisica()
    // eslint-disable-next-line
  }, [estabelecimentoId])

  async function handleSubmit() {
    delete data.empregador

    if (data?.id) {
      await mutateUpdateEstabelecimento.mutateAsync(data)
      notification.put()
    } else {
      await mutateCreateEstabelecimentoSST.mutateAsync(data)
      notification.post()
    }
    onClose()
  }

  if (isLoading) return <LinearProgress />

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteEmpregador
            name="empregadorId"
            autoFocus
            required
            disabled={data?.id ? true : false}
            validationErrors={validationErrors}
            value={data?.empregador}
            onChange={(e, empregador) => {
              const empregadorId = empregador?.id || ''
              setData((oldState) => ({
                ...oldState,
                empregador,
                empregadorId,
                nrInscricao: '',
                nome: '',
                tipoInscricao: '',
              }))
              if (empregador?.tipoInscricao === tipoInscricaoConsts.CNPJ_1) {
                const nrInscricao = empregador?.nrInscricao || ''
                const nome = empregador?.nome || ''
                const tipoInscricao = tipoInscricaoConsts.CNPJ_1
                setData((oldState) => ({
                  ...oldState,
                  nrInscricao,
                  nome,
                  tipoInscricao,
                }))
              }
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Nome"
            name="nome"
            validationErrors={validationErrors}
            variant="outlined"
            size="small"
            fullWidth
            value={data?.nome || ''}
            required
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const nome = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                nome,
              }))
            }}
          />
        </Grid>

        <Grid xl={5} lg={5} md={5} sm={5} xs={12} item>
          <MUIAutoComplete
            label="Tipo Inscrição"
            name="tipoInscricao"
            required
            disabled={data?.id ? true : false}
            validationErrors={validationErrors}
            options={tiposInscricao}
            optionId="value"
            renderOption={(option) => option.name}
            value={data?.tipoInscricao || ''}
            onChange={(e, obj) => {
              const tipoInscricao = obj?.value
              setData((oldState) => ({
                ...oldState,
                nrInscricao: '',
                tipoInscricao,
              }))
            }}
          />
        </Grid>

        <Grid xl={7} lg={7} md={7} sm={7} xs={12} item>
          <NumeroInscricaoTextField
            label="Número de Inscrição"
            value={data?.nrInscricao || ''}
            required
            disabled={data?.id ? true : false}
            typeMask={data?.tipoInscricao || tipoInscricaoConsts.CNPJ_1}
            name="nrInscricao"
            validationErrors={validationErrors}
            onChange={(e, value) => {
              const nrInscricao = value
              setData((oldState) => ({
                ...oldState,
                nrInscricao,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePickerNew
            label="Fim Atividade"
            size="small"
            value={data.dtFimAtividade}
            onChange={(date) => {
              const dtFimAtividade = date
              setData((prev) => ({ ...prev, dtFimAtividade }))
            }}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ButtonBox>
            <Button onClick={onClose} size="small" variant="outlined" color="primary">
              Cancelar
            </Button>
            <Button
              isLoading={
                mutateCreateEstabelecimentoSST.isLoading || mutateUpdateEstabelecimento.isLoading
              }
              onClick={handleValidate}
              size="small"
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </ButtonBox>
        </Grid>
      </Grid>
    </Box>
  )
}
