import React, { useEffect, useState } from 'react'

import {
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  TextField as MuiTextField,
  MenuItem,
} from '@material-ui/core'
import { ContentDivider, TextField } from 'mio-library-ui'

import {
  CEPTextField,
  NumeroInscricaoTextField,
  ContainerForm,
  TelefoneTextField,
  CurrencyTextField,
} from '~/components'
import {
  AutoCompleteLogradouro,
  AutoCompleteCidade,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

import useValidationErrors from '~/hooks/useValidationErrors'
import { useSubmit } from '~/hooks/queries/useSindicato'

import * as yup from 'yup'
import { cnpj } from 'cpf-cnpj-validator'
import { sindicatoCategoriaValues } from '~/values/sindicatoCategoriaValues'
import { indEntidadeSindicalValues } from '~/values/indEntidadeSindicalValues'
import { indATSValues } from '~/values/indATSValues'
import { sindicatoValeTransporteBaseCalculoValues } from '~/values/sindicatoValeTransporteBaseCalculoValues'
import { sindicatoValeTransporteBaseDescontoValues } from '~/values/sindicatoValeTransporteBaseDescontoValues'
import { mapsConstsMes, mapsValuesMes } from '~/values/valuesMes'

const schema = yup.object().shape({
  mesDtBase: yup.string().required('Informe o Mês Data Base'),
  indCategoriaEntidade: yup.string().required('Informe a Categoria Entidade'),
  indEntidade: yup.string().required('Informe o Tipo Entidade'),
  indATS: yup.string().required('Informe o Tipo ATS'),
  indBaseCalculoValeTransporte: yup
    .string()
    .required('Informe o Indicador Base Cálculo Vale Transporte'),
  indBaseDescontoValeTransporte: yup
    .string()
    .required('Informe o Indicador Base Desconto Vale Transporte'),

  nome: yup.string().required('Informe o Nome'),
  nrInscricao: yup
    .string()
    .required('Informe o CNPJ')
    .test('test', 'Informe um CNPJ válido', (value) => cnpj.isValid(value)),
  cep: yup.string().required('Informe o CEP').min(8, 'Inserir no mínimo 8 caracteres.').nullable(),
  logradouroId: yup.string().required('Informe o Logradouro').nullable(),
  endereco: yup.string().required('Informe o Endereço'),
  bairro: yup.string().required('Informe o Bairro'),
  cidadeId: yup.string().required('Informe a Cidade'),
})

export default function Form(props) {
  const { data: _data, onClose } = props

  const [data, setData] = useState({})
  const { mutateAsync, isLoading } = useSubmit()

  useEffect(() => {
    setData(_data)
  }, [_data])

  async function handleSubmit() {
    await mutateAsync(data)
    onClose()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ContainerForm handleSubmit={handleValidate} onCancel={onClose} isLoading={isLoading}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider />
        </Grid>
        <Grid xl={4} lg={4} md={4} sm={12} xs={12} item>
          <NumeroInscricaoTextField
            typeMask={tipoInscricaoConsts.CNPJ_1}
            label="CNPJ"
            value={data?.nrInscricao || ''}
            name="nrInscricao"
            required
            autoFocus
            validationErrors={validationErrors}
            onChange={(e, value) => {
              const nrInscricao = value
              setData((oldState) => ({
                ...oldState,
                nrInscricao,
              }))
            }}
            getDataCNPJ={
              !data?.id
                ? (response) =>
                    setData((oldState) => ({
                      ...oldState,
                      ...response,
                    }))
                : undefined
            }
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            label="Nome"
            name="nome"
            validationErrors={validationErrors}
            variant="outlined"
            size="small"
            fullWidth
            value={data?.nome || ''}
            required
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const nome = e?.target?.value || ''
              setData((oldState) => ({
                ...oldState,
                nome,
              }))
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            select
            label="Mês Data Base"
            variant="outlined"
            fullWidth
            size="small"
            required
            validationErrors={validationErrors}
            name="mesDtBase"
            value={data?.mesDtBase || ''}
            onChange={(e) => {
              const mesDtBase = e?.target?.value || ''
              setData((oldState) => ({ ...oldState, mesDtBase }))
            }}
            InputLabelProps={{ shrink: true }}
          >
            {Object.values(mapsConstsMes).map((d) => (
              <MenuItem key={d} value={d}>
                {mapsValuesMes[d]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <Tooltip arrow title="Cadastro Nacional de Entidades Sindicais">
            <MuiTextField
              label="CNES"
              fullWidth
              value={data?.cnes || ''}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 20,
              }}
              onChange={(e) => {
                const cnes = e.target.value
                setData({ ...data, cnes })
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <MUIAutoComplete
            label="Categoria Entidade"
            options={sindicatoCategoriaValues}
            renderOption={(option) => option?.name || ''}
            optionId="value"
            required
            validationErrors={validationErrors}
            name="indCategoriaEntidade"
            value={data?.indCategoriaEntidade || ''}
            onChange={(e, obj) => {
              const indCategoriaEntidade = obj ? obj.value : ''
              setData({ ...data, indCategoriaEntidade })
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <MUIAutoComplete
            label="Tipo Entidade"
            options={indEntidadeSindicalValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indEntidade"
            value={data?.indEntidade}
            onChange={(e, obj) => {
              const indEntidade = obj ? obj.value : ''
              setData({ ...data, indEntidade })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TextField
            label="Meses para Média Comissionista"
            onlyNumber
            fullWidth
            value={data?.numeroMesMediaComissao || ''}
            variant="outlined"
            inputProps={{
              maxLength: 10,
            }}
            size="small"
            onChange={(e) => {
              const numeroMesMediaComissao = e.target.value
              setData({ ...data, numeroMesMediaComissao })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TextField
            label="Meses para Homologação"
            onlyNumber
            fullWidth
            value={data?.numeroMesHomologacao || ''}
            inputProps={{
              maxLength: 10,
            }}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const numeroMesHomologacao = e.target.value
              setData({ ...data, numeroMesHomologacao })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TextField
            label="Nr. Mês Limite Banco Horas"
            onlyNumber
            fullWidth
            value={data?.numeroMesLimiteBancoDeHoras || ''}
            inputProps={{
              maxLength: 10,
            }}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const numeroMesLimiteBancoDeHoras = e.target.value
              setData({ ...data, numeroMesLimiteBancoDeHoras })
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Endereço" />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <CEPTextField
            name="cep"
            label="CEP"
            fullWidth
            variant="outlined"
            size="small"
            required
            validationErrors={validationErrors}
            value={data?.cep || ''}
            getAddress={
              data?.id
                ? undefined
                : (address) =>
                    setData((oldState) => ({
                      ...oldState,
                      ...address,
                    }))
            }
            onChange={(e) => {
              const cep = e?.target?.value?.replace(/\D/g, '') || ''
              setData({ ...data, cep })
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
          <AutoCompleteLogradouro
            name="logradouroId"
            required
            validationErrors={validationErrors}
            value={data?.logradouroId || null}
            optionId="id"
            onChange={(e, logradouro) => {
              const logradouroId = logradouro?.id || null
              setData({ ...data, logradouroId, logradouro })
            }}
          />
        </Grid>
        <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
          <TextField
            name="endereco"
            label="Endereço"
            fullWidth
            required
            validationErrors={validationErrors}
            value={data?.endereco || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const endereco = e.target.value
              setData({ ...data, endereco })
            }}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
          <TextField
            label="Número"
            fullWidth
            value={data?.numero || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{ maxLength: 10 }}
            onChange={(e) => {
              const numero = e.target.value
              setData({ ...data, numero })
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={10} sm={12} xs={12}>
          <TextField
            name="bairro"
            label="Bairro"
            fullWidth
            required
            validationErrors={validationErrors}
            value={data?.bairro || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const bairro = e.target.value
              setData({ ...data, bairro })
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <TextField
            label="Complemento"
            fullWidth
            value={data?.complemento || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const complemento = e.target.value
              setData({ ...data, complemento })
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <AutoCompleteCidade
            name="cidadeId"
            required
            validationErrors={validationErrors}
            onChange={(e, cidade) => {
              const cidadeId = cidade?.id || ''
              setData({ ...data, cidadeId, cidade })
            }}
            value={data?.cidadeId || ''}
            optionId="id"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TelefoneTextField
            value={data?.telefone || ''}
            onChange={(e, value) => {
              const telefone = value
              setData((oldState) => ({
                ...oldState,
                telefone,
              }))
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Adicional Tempo de Serviço" />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <MUIAutoComplete
            label="Tipo ATS"
            options={indATSValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indATS"
            value={data?.indATS}
            onChange={(e, obj) => {
              const indATS = obj ? obj.value : ''
              setData({ ...data, indATS })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <TextField
            label="Aliq. ATS"
            onlyNumber
            fullWidth
            value={data?.aliqATS || ''}
            variant="outlined"
            inputProps={{
              maxLength: 3,
            }}
            size="small"
            onChange={(e) => {
              const aliqATS = e.target.value
              setData({ ...data, aliqATS })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <TextField
            label="Limite ATS"
            onlyNumber
            fullWidth
            value={data?.limiteATS || ''}
            variant="outlined"
            inputProps={{
              maxLength: 13,
            }}
            size="small"
            onChange={(e) => {
              const limiteATS = e.target.value
              setData({ ...data, limiteATS })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <CurrencyTextField
            label="Vr. ATS"
            fullWidth
            value={data?.vrATS || ''}
            variant="outlined"
            maximumValue="99999999"
            inputProps={{
              maxLength: 8,
            }}
            size="small"
            onChange={(e, value) => {
              const vrATS = value
              setData({ ...data, vrATS })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Outras Informações" />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <CurrencyTextField
            label="Aliq. Hora Extra"
            fullWidth
            value={data?.aliqHoraExtra || ''}
            variant="outlined"
            size="small"
            maximumValue="999"
            onBlur={(e, value) => {
              const aliqHoraExtra = value < 50 ? 50 : value
              setData({
                ...data,
                aliqHoraExtra,
              })
            }}
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e, value) => {
              const aliqHoraExtra = value
              setData({ ...data, aliqHoraExtra })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <CurrencyTextField
            label="Aliq. Adicional Noturno"
            fullWidth
            value={data?.aliqAdicionalNoturno || ''}
            variant="outlined"
            size="small"
            maximumValue="999"
            onBlur={(e, value) => {
              const aliqAdicionalNoturno = value < 20 ? 20 : value
              setData({
                ...data,
                aliqAdicionalNoturno,
              })
            }}
            onChange={(e, value) => {
              const aliqAdicionalNoturno = value
              setData({ ...data, aliqAdicionalNoturno })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            label="Aliq. Vale Transporte"
            onlyNumber
            fullWidth
            value={data?.aliqValeTransporte || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const aliqValeTransporte = e.target.value
              setData({ ...data, aliqValeTransporte })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicador Base Cálculo Vale Transporte"
            options={sindicatoValeTransporteBaseCalculoValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indBaseCalculoValeTransporte"
            value={data?.indBaseCalculoValeTransporte}
            onChange={(e, obj) => {
              const indBaseCalculoValeTransporte = obj ? obj.value : ''
              setData({ ...data, indBaseCalculoValeTransporte })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicador Base Desconto Vale Transporte"
            options={sindicatoValeTransporteBaseDescontoValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indBaseDescontoValeTransporte"
            value={data?.indBaseDescontoValeTransporte}
            onChange={(e, obj) => {
              const indBaseDescontoValeTransporte = obj ? obj.value : ''
              setData({ ...data, indBaseDescontoValeTransporte })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Contribuição Assistêncial" />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TextField
            label="Alíquota Contribuição Assistencial"
            onlyNumber
            fullWidth
            value={data?.aliqContribuicaoAssistencial || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => {
              const aliqContribuicaoAssistencial = e.target.value
              setData({ ...data, aliqContribuicaoAssistencial })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Vr. Mín. Contrib. Assistencial"
            fullWidth
            value={data?.vrMinimoContribuicaoiAssistencial || ''}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const vrMinimoContribuicaoiAssistencial = value
              setData({
                ...data,
                vrMinimoContribuicaoiAssistencial,
              })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <CurrencyTextField
            label="Vr. Máx. Contrib. Assistencial"
            fullWidth
            value={data?.vrMaximoContribuicaoAssistencial || ''}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              const vrMaximoContribuicaoAssistencial = value
              setData({
                ...data,
                vrMaximoContribuicaoAssistencial,
              })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Forma de cálculo da insalubridade" />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Parâmentros" />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <FormControlLabel
                label="Insalubridade como base para ATS"
                control={
                  <Checkbox
                    checked={Boolean(data?.isAdicionalATS) || false}
                    size="small"
                    color="primary"
                    onChange={(e) => {
                      const isAdicionalATS = e.target.checked
                      setData({ ...data, isAdicionalATS })
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <FormControlLabel
                label="Aviso Misto"
                control={
                  <Checkbox
                    checked={Boolean(data?.isAvisoMisto) || false}
                    size="small"
                    color="primary"
                    onChange={(e) => {
                      const isAvisoMisto = e.target.checked
                      setData({ ...data, isAvisoMisto })
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <FormControlLabel
                label="Insalubridade como base para Adcional Noturno"
                control={
                  <Checkbox
                    checked={Boolean(data?.isInsalubridadeBaseAdicionalNoturno) || false}
                    size="small"
                    color="primary"
                    onChange={(e) => {
                      const isInsalubridadeBaseAdicionalNoturno = e.target.checked
                      setData({
                        ...data,
                        isInsalubridadeBaseAdicionalNoturno,
                      })
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <FormControlLabel
                label="Periculosidade como base para Adcional Noturno"
                control={
                  <Checkbox
                    checked={Boolean(data?.isPericulosidadeBaseAdicionalNoturno) || false}
                    size="small"
                    color="primary"
                    onChange={(e) => {
                      const isPericulosidadeBaseAdicionalNoturno = e.target.checked
                      setData({
                        ...data,
                        isPericulosidadeBaseAdicionalNoturno,
                      })
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerForm>
  )
}
