import React, { useState } from 'react'

import api from '~/services/api-pessoal'

import { IconButton } from '@material-ui/core'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'

import { ActionDialog } from '~/components'
import FeriasFormComponent, { FeriasFormData } from '~/components/FeriasForm'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface VinculoReciboPagamento {
  qtdRecibos: number
  recibos: any[]
  vinculoId: string
  vinculoNome: string
  vinculoTipo: number
}

interface ReciboFeriasFormProps {
  isOpen: boolean
  vinculo: VinculoReciboPagamento
  onClose: () => void
  changeOldVersion: (vinculo: VinculoReciboPagamento) => void
  onAfterAddReciboFerias: (reciboId: string, vinculoId: string) => void
}

export default function ReciboFeriasForm(props: ReciboFeriasFormProps) {
  const { isOpen, vinculo, onClose, changeOldVersion, onAfterAddReciboFerias } = props
  const [isLoading, setLoading] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleSubmit(data: FeriasFormData) {
    setLoading(true)
    try {
      const response = await api.post('RP/NovoReciboFerias', data)
      onAfterAddReciboFerias(response.data.data.id, response.data.data.vinculoId)
      onClose()
      notification.success('Recibo de férias criado com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ActionDialog
      title="Recibo de Férias"
      subtitle={vinculo.vinculoNome}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Criar Recibo"
      renderRight={
        <IconButton
          size="small"
          onClick={() => changeOldVersion(vinculo)}
          title="Retornar para versão antiga"
        >
          <ErrorOutlineIcon color="primary" />
        </IconButton>
      }
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      customActions={<></>}
    >
      <FeriasFormComponent
        onSubmit={handleSubmit}
        onCancel={onClose}
        vinculoId={vinculo.vinculoId}
        isLoading={isLoading}
        isFeriasProgramacao={false}
        dataForm={null}
      />
    </ActionDialog>
  )
}
