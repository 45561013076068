import React, { useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'
import { Button, ContentContainer, Finder, ButtonBox, PageHeader } from 'mio-library-ui'

import { ConfirmDeleteDialog } from '~/components'

import Table from './components/Table'
import CadastroMedico from './components/Cadastro'

import apiPessoal from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

const Medico = () => {
  const [_data, setData] = useState([])
  const [dialogCadastro, setDialogCadastro] = useState({
    isOpen: false,
    dialogInicial: {},
  })
  const [dialogDelete, setDialogDelete] = useState({
    isOpen: false,
  })
  const [isProcessing, setIsProcessing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  const updateList = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await apiPessoal.get('/medico', {
        headers: {
          'Resposta-Compactada': 'Nunca',
        },
      })
      if (response.data.data) {
        setData(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    updateList()
  }, [updateList])

  const handleDialogSubmetido = (event, data) => {
    const functionsMap = {
      onAdicionar: onAdd,
      onAtualizar: onEditado,
      onDeletar: onDeletado,
    }
    functionsMap[event](data)
  }

  const onAdd = (data) => {
    setData([..._data, data])
    closeDialogCadastro()
  }

  const onEditado = (data) => {
    const novaLista = _data.map((obj) => {
      if (obj.id === data.id) {
        return data
      } else {
        return obj
      }
    })
    setData(novaLista)
    closeDialogCadastro()
  }

  const onDeletado = (data) => {
    const novaLista = _data.filter((obj) => obj.id !== data.id)
    setData(novaLista)
    closeDialogCadastro()
  }

  const handleClickItem = (event, data) => {
    const functionsMap = {
      edit: openDialogCadastro,
      delete: openConfirmaDeletar,
    }
    functionsMap[event](data)
  }

  const openDialogCadastro = (id) => {
    const data = _data.find((obj) => obj.id === id)
    setDialogCadastro({
      ...dialogCadastro,
      isOpen: true,
      data,
    })
  }

  const closeDialogCadastro = () => {
    setDialogCadastro({
      ...dialogCadastro,
      isOpen: false,
    })
  }

  const openConfirmaDeletar = (id) => {
    const data = _data.find((obj) => obj.id === id)
    setDialogDelete({
      ...dialogDelete,
      isOpen: true,
      data,
    })
  }

  const closeConfirmaDeletar = () => {
    setDialogDelete({
      ...dialogDelete,
      isOpen: false,
    })
  }

  const deletar = async () => {
    setIsProcessing(true)
    try {
      const objDeletar = dialogDelete.data
      await apiPessoal.delete(`/medico/${objDeletar.id}`)
      handleDialogSubmetido('onDeletar', objDeletar)
      notification.remove()
      closeConfirmaDeletar()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsProcessing(false)
  }

  return (
    <ContentContainer>
      <PageHeader title="Médico/Responsável">
        <ButtonBox>
          <Finder onSearch={setQuery} searching={isLoading} onClose={() => setQuery('')} />
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              setDialogCadastro({
                ...dialogCadastro,
                isOpen: true,
                data: dialogCadastro.dialogInicial,
              })
            }}
          >
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Box>
        <Table data={_data} onItemClick={handleClickItem} isLoading={isLoading} query={query} />
      </Box>

      <CadastroMedico
        isOpen={dialogCadastro.isOpen}
        data={dialogCadastro.data}
        onClose={closeDialogCadastro}
        onFormSubmit={handleDialogSubmetido}
      />

      <ConfirmDeleteDialog
        isOpen={dialogDelete.isOpen}
        isDeleting={isProcessing}
        onCancel={closeConfirmaDeletar}
        onConfirm={deletar}
      />
    </ContentContainer>
  )
}

export default Medico
