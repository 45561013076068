import TextFieldChange from '../TextFieldChange'

import { useEstabelecimentoAtualizarEmail } from '~/hooks/queries/Estabelecimento/useEstabelecimentoAtualizarEmail'

interface TextFieldChangeEstabelecimentoEmailProps {
  email: string
  estabelecimentoId: string
  afterSubmit: () => void
}

export default function TextFieldChangeEstabelecimentoEmail(
  props: TextFieldChangeEstabelecimentoEmailProps,
) {
  const { email, estabelecimentoId, afterSubmit } = props

  const { mutateAsync, isLoading } = useEstabelecimentoAtualizarEmail()

  async function handleChange(email: string) {
    await mutateAsync({ email, estabelecimentoId })
    afterSubmit()
  }

  return <TextFieldChange value={email} onChange={handleChange} isLoading={isLoading} />
}
