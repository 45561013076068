import React, { useState } from 'react'

import { Typography, Popover, Collapse, Divider, makeStyles } from '@material-ui/core'
import { ErrorMessage } from 'mio-library-ui'

import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    background: '#00000066',
  },
  popoverPaper: {
    padding: theme.spacing(1),
    // minHeight: '80px',
  },
  highlight: {
    // '-webkit-text-stroke': '1px #f0ad4e',
    // '-webkit-text-fill-color': '#5cb85c',
    // 'transition-timing-function': 'ease-in-out',
    cursor: 'pointer',
    '& *': {
      fontSize: '11pt',
      fontWeight: 'bolder',
    },
  },
  title: {
    fontWeight: 'bolder',
  },
  messagesListContainer: {
    marginTop: theme.spacing(1),
  },
  variable: {
    color: (props) => (props.selected ? 'green' : 'blue'),
    cursor: 'pointer',
  },
}))

// const extractFormula = (formula) => {
//   const extracted = formula.match(/\[(.*?)\]/g)
//   return extracted || []
// }

const extractMessages = (memorias, variavelAmbiente) => {
  if (!memorias) return []
  const memoria = memorias.find((m) => {
    return m.variavelAmbiente === variavelAmbiente
  })

  return memoria?.mensagens || []
}

const Variable = (props) => {
  const { variable, selected, ...rest } = props
  const classes = useStyles({ selected })
  return (
    <span className={classes.variable} {...rest}>
      {variable}
    </span>
  )
}

const Formula = (props) => {
  const { formula, onVariableClick } = props
  const [selected, setSelected] = useState()

  const splited = formula.split(/(?=\[)(.*?\])/g)

  const handleClick = (variable) => {
    if (variable === selected) {
      onVariableClick(null)
      setSelected('')
      return
    }
    setSelected(variable)
    onVariableClick(variable)
  }

  const f = splited.map((s, index) => {
    let regexp = new RegExp(/\[(.*?)\]/g)
    if (regexp.test(s)) {
      return (
        <Variable
          key={index}
          variable={s}
          selected={selected === s}
          onClick={() => handleClick(s)}
        />
      )
    }
    return s
  })

  return <div>{f}</div>
}

const Message = ({ message }) => {
  const classes = useStyles()
  return (
    <Typography variant="body2" className={classes.messageContainer}>
      {message}
    </Typography>
  )
}

const MemoriaCalculoFormula = (props) => {
  const {
    children,
    formula: _formula,
    memorias,
    propriedade,
    highlight,
    formulaCalc,
    title,
  } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const [messages, setMessages] = useState([])

  const handlePopoverOpen = (event) => {
    if (highlight && _formula) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handlePopoverClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleVariableClick = (variable) => {
    if (variable) {
      const v = variable.replaceAll('[', '').replaceAll(']', '')
      const messages = extractMessages(memorias, v)
      setMessages(messages)
      setExpanded(true)
      return
    }
    setExpanded(false)
  }

  const open = Boolean(anchorEl)
  return (
    <div
      className={clsx({
        [classes.highlight]: highlight && _formula,
      })}
      onClick={handlePopoverOpen}
    >
      {children}
      <Popover
        id={`memcal-${propriedade}`}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popoverPaper,
        }}
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
        <div>
          {title && (
            <>
              <Typography className={classes.title} variant="subtitle2">
                {title}
              </Typography>
              <Divider bottom={2} />
              <Typography className={classes.messagesListContainer}>{formulaCalc}</Typography>
            </>
          )}
          <div className={classes.messagesListContainer}>
            <Formula formula={_formula} onVariableClick={handleVariableClick} />
          </div>
          <Collapse in={expanded}>
            <div className={classes.messagesListContainer}>
              <Divider />
            </div>

            <div className={classes.messagesListContainer}>
              {messages.length > 0 ? (
                messages.map((m, i) => <Message key={`id-key-${i}`} message={m} />)
              ) : (
                <ErrorMessage error="Variável sem descrição" />
              )}
            </div>
          </Collapse>
        </div>
      </Popover>
    </div>
  )
}

export default MemoriaCalculoFormula
