import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Box, IconButton, makeStyles, useTheme } from '@material-ui/core'

import AlignCellValue from '../AlignCellValue'

import { ButtonBox, CopyLabel, MUITableTwoRows } from '~/components'

import { ApuracaoESocialDTO } from '~/hooks/queries/ApurarEncargos/ApuracaoESocialDTO'
import { Encargos } from '~/hooks/queries/ApurarEncargos/Encargos'
import { Visibility } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  tableWithError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.error.light,
    },
  },
}))

interface TableProps {
  data: ApuracaoESocialDTO[]
  query: string
  onClickRow: (empregadorId: string) => void
  isLoading: boolean
  isFetching: boolean
}

export default function Table(props: TableProps) {
  const { data, query, onClickRow, isLoading, isFetching } = props

  const classes = useStyles()
  const theme = useTheme()

  function makeStylesCellIfHaveDiffValues(
    indexCurrent: number,
    fieldToCompare: keyof Encargos,
  ): string {
    const dataCurrent = data[indexCurrent]
    const haveDiffValues =
      dataCurrent.folhaPagamento[fieldToCompare] !== dataCurrent.eSocial[fieldToCompare]
    if (!haveDiffValues) return ''
    return classes.tableWithError
  }

  return (
    <MUITableTwoRows
      data={data}
      options={{
        sherlock: {
          query,
          columns: ['nrInscriao', 'empregadorNome'],
        },
      }}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={[
        {
          label: 'Número Inscrição',
          firstRow: 'nrInscriao',
          options: {
            customBodyRenderFirstRow: (value, indexCurrent) => {
              const dataCurrent = data[indexCurrent]
              return (
                <Box display="flex" gridGap={10} alignItems="center">
                  <Box
                    bgcolor={dataCurrent.isErro ? theme.palette.error.light : undefined}
                    height="54px"
                    width="8px"
                    borderRadius="0px 32px 32px 0px"
                    ml={-1.5}
                  />
                  <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
                </Box>
              )
            },
          },
        },
        {
          label: 'Empregador',
          firstRow: 'empregadorNome',
          options: {
            minWidth: '20rem',
          },
        },
        {
          label: 'Apuração',
          firstRow: 'empregadorNome',
          secondRow: 'empregadorNome',
          options: {
            customBodyRenderFirstRow: () => 'Folha',
            customBodyRenderSecondRow: () => 'eSocial',
          },
        },
        {
          label: 'Segurados',
          firstRow: 'folhaPagamento.vrSegurados',
          secondRow: 'eSocial.vrSegurados',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrSegurados'),
            customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
            customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
          },
        },
        {
          label: 'Individual',
          firstRow: 'folhaPagamento.vrContribuinteIndividual',
          secondRow: 'eSocial.vrContribuinteIndividual',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrContribuinteIndividual'),
            customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
            customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
          },
        },
        {
          label: 'Patronal',
          firstRow: 'folhaPagamento.vrPatronal',
          secondRow: 'eSocial.vrPatronal',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrPatronal'),
            customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
            customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
          },
        },
        {
          label: 'Terceiros',
          firstRow: 'folhaPagamento.vrTerceiros',
          secondRow: 'eSocial.vrTerceiros',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrTerceiros'),
            customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
            customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
          },
        },
        {
          label: 'Salário Família',
          firstRow: 'folhaPagamento.vrSalarioFamilia',
          secondRow: 'eSocial.vrSalarioFamilia',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrSalarioFamilia'),
            customBodyRenderFirstRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
            customBodyRenderSecondRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
          },
        },
        {
          label: 'Salário Maternidade',
          firstRow: 'folhaPagamento.vrSalarioMaternidade',
          secondRow: 'eSocial.vrSalarioMaternidade',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrSalarioMaternidade'),
            customBodyRenderFirstRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
            customBodyRenderSecondRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
          },
        },
        {
          label: 'IRRF',
          firstRow: 'folhaPagamento.vrIRRF',
          secondRow: 'eSocial.vrIRRF',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrIRRF'),
            customBodyRenderFirstRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
            customBodyRenderSecondRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
          },
        },
        {
          label: 'PIS',
          firstRow: 'folhaPagamento.vrPIS',
          secondRow: 'eSocial.vrPIS',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrPIS'),
            customBodyRenderFirstRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
            customBodyRenderSecondRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
          },
        },
        {
          label: 'REINF',
          firstRow: 'folhaPagamento.vrREINF',
          secondRow: 'eSocial.vrREINF',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrREINF'),
            customBodyRenderFirstRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
            customBodyRenderSecondRow: (value) => (value ? <AlignCellValue value={value} /> : ''),
          },
        },
        {
          label: 'Líquido',
          firstRow: 'folhaPagamento.vrTotalLiquido',
          secondRow: 'eSocial.vrTotalLiquido',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrTotalLiquido'),
            customBodyRenderFirstRow: (value) => <AlignCellValue value={value} />,
            customBodyRenderSecondRow: (value) => <AlignCellValue value={value} />,
          },
        },
        {
          label: 'Valor Diferença',
          firstRow: 'isErro',
          options: {
            customStyleCell: (indexCurrent) =>
              makeStylesCellIfHaveDiffValues(indexCurrent, 'vrTotalLiquido'),
            customBodyRenderFirstRow: (value, index) => {
              const dataCurrent = data[index]
              const vrTotalLiquidoFolha = dataCurrent.folhaPagamento?.vrTotalLiquido || 0
              const vrTotalLiquidoESocial = dataCurrent.eSocial?.vrTotalLiquido || 0
              const vlrDiferenca = vrTotalLiquidoFolha - vrTotalLiquidoESocial
              return <AlignCellValue value={vlrDiferenca.toString()} />
            },
          },
        },
        {
          label: 'Ações',
          firstRow: 'empregadorId',
          options: {
            customBodyRenderFirstRow: (value) => {
              return (
                <ButtonBox spacing={0} justifyContent="center">
                  <IconButton
                    size="small"
                    title="Detalhar registro"
                    color="primary"
                    aria-label="Detalhar"
                    onClick={() => onClickRow(value)}
                  >
                    <Visibility fontSize="small" color="primary" />
                  </IconButton>
                </ButtonBox>
              )
            },
          },
        },
      ]}
    />
  )
}
