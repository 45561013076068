import { useState } from 'react'
import PropType from 'prop-types'

import MUIAutoComplete from '../MUIAutoComplete'

import api from '~/services/api-pessoal'

export default function AutoCompleteEmpregador(props) {
  const { label, ...rest } = props

  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/Empregadorconsulta')
      setOptions(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderOption = (option) => `${option.codigo} - ${option.nome}`

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={getOptions}
      renderOption={renderOption}
      label={label}
      {...rest}
    />
  )
}

AutoCompleteEmpregador.defaultProps = {
  label: 'Empregador',
}

AutoCompleteEmpregador.propTypes = {
  onChange: PropType.func,
  value: PropType.any,
  validationErrors: PropType.any,
  empregadorId: PropType.string,
  name: PropType.string,
  optionId: PropType.string,
  required: PropType.bool,
  disabled: PropType.bool,
}
