const umSexto_1 = 1
const diasUteisNaoUteis_2 = 2
const jornadaDiaria_3 = 3

export const indDSRValues = [
  { value: umSexto_1, name: '1 - Um sexto do Valor Apurado' },
  {
    value: diasUteisNaoUteis_2,
    name: '2 - Valor Apurado / Dias Úteis * ( Domingos + Feriado ) [ Dias Úteis Tabela Mês ]',
  },
  {
    value: jornadaDiaria_3,
    name: '3 - Jornada Diária * ( Domingos + Feriado )',
  },
]

export const indDSRConsts = {
  umSexto_1,
  diasUteisNaoUteis_2,
  jornadaDiaria_3,
}
