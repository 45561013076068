import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidate as useInvalidateMain } from './useObterProcessamentoFolha'
import { useInvalidate as useInvalidateEmp } from './useObterProcessamentoEmpregador'

import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  params: {
    taskQueueId: string
  }
}

export function useReprocessarTarefa() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate: handleInvalidateMain } = useInvalidateMain()
  const { handleInvalidate: handleInvalidateEmp } = useInvalidateEmp()

  async function handleRequest({ params }: RequestProps) {
    await api.put('/FolhaAutomatica/ReprocessarTarefa', null, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Reprocessamento da tarefa foi iniciado com sucesso')
      handleInvalidateMain()
      handleInvalidateEmp()
    },
    onError: dialogNotification.extractErrors,
  })
}
