const todos_0 = 0
const admitidos_1 = 1
const demitidos_2 = 2

export const tipoMovimentoAdmissaoDemissaoValues = [
  {
    name: 'Todos',
    value: todos_0,
  },
  {
    name: 'Admitidos',
    value: admitidos_1,
  },
  {
    name: 'Demitidos',
    value: demitidos_2,
  },
]

export const tipoMovimentoAdmissaoDemissaoConsts = {
  todos_0,
  admitidos_1,
  demitidos_2,
}
