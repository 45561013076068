const naoPreencher_0 = 0
const criterioQuantitativo_1 = 1
const criterioQualitativo_2 = 2

export const agenteNocivoTipoAvaliacaoValues = [
  { value: naoPreencher_0, name: '0 - Não preencher' },
  { value: criterioQuantitativo_1, name: '1 - Critério quantitativo' },
  { value: criterioQualitativo_2, name: '2 - Critério qualitativo' },
]

export const agenteNocivoTipoAvaliacaoConsts = {
  naoPreencher_0,
  criterioQuantitativo_1,
  criterioQualitativo_2,
}
