import React, { useState, useEffect, useCallback } from 'react'

import { Grid, Box, makeStyles } from '@material-ui/core'
import { TextField, ContentDivider } from 'mio-library-ui'

import {
  AutoCompleteBanco,
  AutoCompleteEvento,
  AutoCompleteVinculo,
} from '~/components/AutoComplete'
import { DatePicker, ActionDialog, CurrencyTextField } from '~/components'

import Table from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import { formatToCPF } from 'brazilian-values'
import * as yup from 'yup'
import _ from 'lodash'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { indClassficacaoEventoConsts } from '~/values/indClassficacaoEventoValues'

const { emprestimo } = indClassficacaoEventoConsts

const useStyles = makeStyles(() => ({
  heigthPaper: {
    height: '100%',
  },
}))

const schema = yup.object().shape({
  vinculo: yup.string().required('Informe o Funcionário').nullable(),
  dtEmissao: yup.string().required('Infore a Data de Emissão').nullable(),
  banco: yup.string().required('Informe o Banco').nullable(),
  numeroParcelas: yup.number().required('Informe o Número de Parcelas'),
  vrEmprestimo: yup.number().required('Informe o Valor do Empréstimo'),
  evento: yup.string().required('Informe o Evento').nullable(),
  dtInicioParcelas: yup.string().required('Informe o Início das Parcelas').nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props

  const [data, setData] = useState({})
  const [collectionItens, setCollectionItens] = useState({
    isLoading: false,
    itens: [],
  })

  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  const { estabelecimento, anoMes } = useAmbiente()

  useEffect(() => {
    setData(_data)

    const { vinculoEmprestimoItens } = _data

    const newItens = _.orderBy(vinculoEmprestimoItens, 'numeroParcela', 'asc')

    setCollectionItens((oldState) => ({
      oldState,
      itens: newItens,
    }))
  }, [_data])

  function verificarQuantidadeParcelas() {
    if (data?.numeroParcelas > 48) {
      dialogNotification.warning({
        descriptions: ['O número de Parcelas ultrapassa 48, está correto essa informação?'],
        onConfirm: handleSubmit,
      })
      return
    }
    handleSubmit()
  }

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        await api.put(`/VinculoEmprestimo/${data.id}`, data)
        onAfterSubmitForm('update')
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        await api.post('/VinculoEmprestimo', data)
        onAfterSubmitForm('insert')
        notification.post()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    delete data.vinculo

    if (data.id) {
      update()
      return
    }
    insert()

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: verificarQuantidadeParcelas,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Empréstimo"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'md',
        fullWidth: true,
        classes: {
          paper: data?.id ? classes.heigthPaper : undefined,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <AutoCompleteVinculo
            required
            vinculosTipo={[VinculoTipoEnum.Funcionario_1]}
            label="Funcionário"
            estabelecimentoId={estabelecimento?.id}
            anoMes={anoMes}
            value={data?.vinculo || null}
            validationErrors={validationErrors}
            name="vinculo"
            disabled={data?.id ? true : false}
            renderOption={(option) =>
              option?.pessoaFisica
                ? `${formatToCPF(option.pessoaFisica?.nrInscricao || '')} - ${
                    option.pessoaFisica?.nome
                  }`
                : `${formatToCPF(option?.pessoaCPF || '')} - ${option?.pessoaNome || ''}`
            }
            onChange={(e, vinculo) => {
              const vinculoId = vinculo?.id || ''
              setData((oldState) => ({
                ...oldState,
                vinculoId,
                vinculo,
              }))
            }}
          />
        </Grid>

        <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
          <DatePicker
            label="Data de Emissão"
            required
            size="small"
            value={data.dtEmissao || null}
            validationErrors={validationErrors}
            name="dtEmissao"
            disabled={data?.id ? true : false}
            onChange={(obj) => {
              const dtEmissao = obj?.format('yyyy-MM-DD') || null
              const dtInicioParcelas = dtEmissao
              setData((oldState) => ({
                ...oldState,
                dtEmissao,
                dtInicioParcelas,
              }))
            }}
          />
        </Grid>

        <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
          <AutoCompleteBanco
            required
            value={data?.banco || null}
            validationErrors={validationErrors}
            name="banco"
            disabled={data?.id ? true : false}
            onChange={(e, banco) => {
              const bancoId = banco?.id || null
              setData((oldState) => ({
                ...oldState,
                bancoId,
                banco,
              }))
            }}
          />
        </Grid>

        <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
          <AutoCompleteEvento
            required
            value={data?.evento || null}
            validationErrors={validationErrors}
            name="evento"
            classificacao={emprestimo}
            disabled={data?.id ? true : false}
            onChange={(e, evento) => {
              const eventoId = evento?.id || null
              setData((oldState) => ({
                ...oldState,
                eventoId,
                evento,
              }))
            }}
          />
        </Grid>

        <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
          <TextField
            label="Número de Parcelas"
            variant="outlined"
            size="small"
            fullWidth
            required
            disabled={data?.id ? true : false}
            value={data?.numeroParcelas || ''}
            validationErrors={validationErrors}
            name="numeroParcelas"
            onChange={(e) => {
              let numeroParcelas = parseInt(e?.target.value.replace(/\D/gim, '')) || 0
              if (numeroParcelas > 99) numeroParcelas = 99
              const vrParcela =
                data.vrEmprestimo !== 0 && numeroParcelas !== 0
                  ? data.vrEmprestimo / numeroParcelas
                  : 0
              setData((oldState) => ({
                ...oldState,
                numeroParcelas,
                vrParcela,
              }))
            }}
          />
        </Grid>
        <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
          <CurrencyTextField
            label="Valor do Empréstimo"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.vrEmprestimo || 0}
            required
            disabled={data?.id ? true : false}
            validationErrors={validationErrors}
            name="vrEmprestimo"
            onChange={(e, value) => {
              const vrEmprestimo = value || 0
              const vrParcela =
                vrEmprestimo !== 0 && data.numeroParcelas !== 0
                  ? vrEmprestimo / data.numeroParcelas
                  : 0
              setData((oldState) => ({
                ...oldState,
                vrEmprestimo,
                vrParcela,
              }))
            }}
          />
        </Grid>
        <Grid xl={4} lg={4} md={4} sm={6} xs={12} item>
          <CurrencyTextField
            label="Valor das Parcelas"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.vrParcela || 0}
            validationErrors={validationErrors}
            name="vrParcela"
            disabled
          />
        </Grid>

        <Grid xl={8} lg={8} md={8} sm={8} xs={12} item>
          <TextField
            label="Observação"
            variant="outlined"
            size="small"
            fullWidth
            value={data?.observacao || ''}
            validationErrors={validationErrors}
            name="observacao"
            inputProps={{ maxLength: 250 }}
            disabled={data?.id ? true : false}
            onChange={(e) => {
              const observacao = e?.target.value || ''
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
          />
        </Grid>

        <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
          <DatePicker
            label="Início das Parcelas"
            required
            size="small"
            value={data.dtInicioParcelas || null}
            validationErrors={validationErrors}
            name="dtInicioParcelas"
            disabled={data?.id ? true : false}
            onChange={(obj) => {
              const dtInicioParcelas = obj?.format('yyyy-MM-DD') || null
              setData((oldState) => ({
                ...oldState,
                dtInicioParcelas,
              }))
            }}
          />
        </Grid>
      </Grid>

      {data?.vinculoEmprestimoItens?.length > 0 && (
        <>
          <Box py={1}>
            <ContentDivider title="Parcelas" />
          </Box>
          <Table data={collectionItens.itens} isLoading={collectionItens.isLoading} />
        </>
      )}
    </ActionDialog>
  )
}

export default Form
