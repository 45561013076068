import { Box } from '@material-ui/core'

import Table from './components/Table'
import DialogBaseCalculoEstabelecimento from './components/DialogBaseCalculoEsta'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import useObterApuracaoEncargos from '~/hooks/queries/ApurarEncargos/useObterApuracaoEncargos'

import { useApuracaoEncargos } from '~/pages/ApuracaoEncargos'
import { ApuracaoESocialDTO } from '~/hooks/queries/ApurarEncargos/ApuracaoESocialDTO'

export default function PrevidenciaSocial() {
  const { anoMes } = useAmbiente()

  const {
    close: closeDialogPrevidenciaSocial,
    data: dataDialogPrevidenciaSocial,
    isOpen: isOpenDialogPrevidenciaSocial,
    open: openDialogPrevidenciaSocial,
  } = useDialog<ApuracaoESocialDTO | null>(null)

  const { indApuracao, grupoId, isOnlyError, query } = useApuracaoEncargos()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterApuracaoEncargos({
    indApuracao,
    periodoApuracao: anoMes,
    grupoId,
    isOnlyError,
  })
  const data = _d || []

  function handleClickRow(empregadorId: string) {
    const rowCurrent = data.find((d) => d.empregadorId === empregadorId)
    if (!rowCurrent) return
    openDialogPrevidenciaSocial(rowCurrent)
  }

  return (
    <Box>
      <Table
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        query={query}
        onClickRow={handleClickRow}
      />

      {isOpenDialogPrevidenciaSocial && dataDialogPrevidenciaSocial ? (
        <DialogBaseCalculoEstabelecimento
          data={dataDialogPrevidenciaSocial}
          indApuracao={indApuracao}
          close={closeDialogPrevidenciaSocial}
          isOpen={isOpenDialogPrevidenciaSocial}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
