export enum TipoAdmissaoEnum {
  Admissao_1 = 1,
  TransferenciaEmpresaMesmoGrupo_2 = 2,
  TransferenciaEmpresaConsorcio_3 = 3,
  TransferenciaSucessao_4 = 4,
  TransferenciaDomestico_5 = 5,
  MudancaDeCPF_6 = 6,
}

export const TipoAdmissaoValues = [
  { value: TipoAdmissaoEnum.Admissao_1, name: '1 - Admissão Normal' },
  {
    value: TipoAdmissaoEnum.TransferenciaEmpresaMesmoGrupo_2,
    name: '2 - Transferência de empresa do mesmo grupo econômico',
  },
  {
    value: TipoAdmissaoEnum.TransferenciaEmpresaConsorcio_3,
    name: '3 - Transferência de empresa consorciada ou de consórcio',
  },
  {
    value: TipoAdmissaoEnum.TransferenciaSucessao_4,
    name: '4 - Transferência por motivo de sucessão, incorporação, cisão ou fusão',
  },
  {
    value: TipoAdmissaoEnum.TransferenciaDomestico_5,
    name: '5 - Transferência do empregado doméstico para outro representante da mesma unidade familiar',
  },
  { value: TipoAdmissaoEnum.MudancaDeCPF_6, name: '6 - Mudança de CPF' },
]
