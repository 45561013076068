import React from 'react'

import { v4 as uuidv4 } from 'uuid'

import { ActionDialog } from '~/components'

import useNotification from '~/hooks/useNotification'

import { VinculoSalarioBase } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'

import SalarioContratualForm from '~/pages/Funcionario/Forms/SalarioContratualForm'

interface FormProps {
  isOpen: boolean
  onClose: () => void
  afterSubmit: (event: 'update' | 'insert', data: VinculoSalarioBase) => void
  data: Partial<VinculoSalarioBase>
}

export function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, afterSubmit } = props

  const notification = useNotification()
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const handleSubmit = (data: VinculoSalarioBase) => {
    const update = () => {
      afterSubmit('update', data)
      notification.put()
    }

    const insert = () => {
      data.id = uuidv4()
      afterSubmit('insert', data)
      notification.post()
    }

    if (data.id) {
      return update()
    }
    insert()
  }

  function handleClickSave() {
    buttonRef.current?.click()
  }

  return (
    <ActionDialog
      title="Cadastro de Salário Contratual"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleClickSave}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <SalarioContratualForm
        data={_data as FixLater}
        onSubmit={handleSubmit}
        buttonRef={buttonRef}
      />
    </ActionDialog>
  )
}

export default Form
