import { useMutation, useQueryClient } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryListarTerceiros } from './useListarTerceiros'

export default function useRemoveTerceiros() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const queryClient = useQueryClient()

  async function handleRequest(id: string) {
    await api.delete('/Terceiros/' + id)
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
    onSuccess: async () => {
      notification.remove()
      await queryClient.invalidateQueries(queryListarTerceiros)
    },
  })
}
