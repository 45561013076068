import React from 'react'

import { Box, Dialog, Slide, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { PageHeader } from '~/components'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const HEIGHT_HEADER = 50

export default function FullDialog(props) {
  const { title, isOpen, onClose, pdfUrl } = props

  return (
    <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
      <Box height="100%" width="100%" p={2}>
        <Box height={HEIGHT_HEADER}>
          <PageHeader title={title}>
            <Box pl={2}>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </PageHeader>
        </Box>
        <iframe
          title={title}
          src={pdfUrl}
          height="100%"
          width="100%"
          style={{
            height: `calc(100% - ${HEIGHT_HEADER}px)`,
          }}
        />
      </Box>
    </Dialog>
  )
}
