export enum IndAtividadeEmpregadorEnum {
  Todos,
  ApenasAtivos,
  FimAtividadePreenchido,
  FimContabilidadePreenchido,
  InicioInatividadePreenchido,
}

export const IndAtividadeEmpregadorValues = [
  {
    name: 'Todos os Empregadores',
    value: IndAtividadeEmpregadorEnum.Todos,
  },
  {
    name: 'Apenas Empregadores Ativos',
    value: IndAtividadeEmpregadorEnum.ApenasAtivos,
  },
  {
    name: 'Empregadores com Fim de Atividade',
    value: IndAtividadeEmpregadorEnum.FimAtividadePreenchido,
  },
  {
    name: 'Empregadores com Fim de Atividade no Escritório Contábil',
    value: IndAtividadeEmpregadorEnum.FimContabilidadePreenchido,
  },
  {
    name: 'Empregadores Inativos',
    value: IndAtividadeEmpregadorEnum.InicioInatividadePreenchido,
  },
]
