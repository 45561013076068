import { formatToCPFOrCNPJ } from 'brazilian-values'

import { CopyLabel, CurrencyTextField, ToolsDataTable } from '~/components'

import { VinculoIntermitenteData } from '../..'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatSimpleDate } from '~/utils/utils'
import { MUIAutoComplete } from '~/components/AutoComplete'
import {
  IndUnidadeSalarioEnum,
  IndUnidadeSalarioValues,
} from '~/@types/enums/IndUnidadeSalarioEnum'

interface TableProps {
  data: VinculoIntermitenteData[]
  setData: (d: VinculoIntermitenteData[]) => void
  isLoading: boolean
  isFetching: boolean
  onRowSelected: (index: number[]) => void
  rowsSelected: number[]
  query: string
}

export function Table(props: TableProps) {
  const { data, setData, isLoading, isFetching, onRowSelected, rowsSelected, query } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Código',
      name: 'pessoaCodigo',
    },
    {
      label: 'CPF',
      name: 'pessoaCPF',
      options: {
        customBodyRender: (value) =>
          value ? <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel> : '',
      },
    },
    {
      label: 'Nome',
      name: 'pessoaNome',
    },
    {
      label: 'Admissão',
      name: 'dtAdmissao',
      options: {
        customBodyRender: (value) => formatSimpleDate(value),
      },
    },
    {
      label: 'Valor do Serviço',
      name: 'id',
      options: {
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return
          return (
            <CurrencyTextField
              value={dtCurrent.vrServico}
              onBlur={(e, valueServ) => {
                const vrServico = valueServ
                setData(data.map((d) => (d.id === value ? { ...dtCurrent, vrServico } : d)))
              }}
            />
          )
        },
      },
    },
    {
      label: 'Unidade Salário',
      name: 'id',
      options: {
        customBodyRender: (value) => {
          const dtCurrent = data.find((d) => d.id === value)
          if (!dtCurrent) return
          return (
            <MUIAutoComplete
              options={IndUnidadeSalarioValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={dtCurrent.indUnidadeSalario}
              onChange={(e, obj) => {
                const indUnidadeSalario: IndUnidadeSalarioEnum = obj
                  ? obj?.value
                  : IndUnidadeSalarioEnum.Mes_5
                setData(data.map((d) => (d.id === value ? { ...dtCurrent, indUnidadeSalario } : d)))
              }}
            />
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        columns: ['pessoaCodigo', 'pessoaCPF', 'pessoaNome'],
        query,
      }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
      disableAutoHeight
    />
  )
}
