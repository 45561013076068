import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { useListarTerceiros } from '~/hooks/queries/Terceiros/useListarTerceiros'
import { formatCurrency } from '~/hooks/useUtils'

interface TableProps {
  query: string
  onItemClick: (event: 'edit' | 'delete', id: string) => void
}
export default function Table(props: TableProps) {
  const { query, onItemClick } = props
  const { data: _d, isLoading, isFetching } = useListarTerceiros()
  const data = _d
    ? _d.map((d) => ({
        ...d,
        aliqTerceiros: d?.aliqTerceiros ? formatCurrency(d.aliqTerceiros) : '',
      }))
    : []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'fpasId',
      label: 'FPAS',
    },
    {
      name: 'aliqTerceiros',
      label: 'Alíquota',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      isLoading={isLoading}
      isFetching={isFetching}
      data={data}
      columns={columns}
      sherlock={{
        columns: ['codigo', 'nome', 'aliqTerceiros'],
        query,
      }}
    />
  )
}
