import { formatToCNPJ } from 'brazilian-values'
import moment from 'moment'

import { Box, Checkbox } from '@material-ui/core'

import { CopyLabel, ToolsDataTable } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { IndMultiploVinculoValues } from '~/@types/enums/IndMultiploVinculoEnum'

import { useMemo } from 'react'

import {
  AutonomoManutencaoDTO,
  AutonomoManutencaoEstabelecimentoDTO,
} from '~/hooks/queries/Autonomo/AutonomoManutencaoDTO'

interface TableProps {
  query: string
  isLoading: boolean
  isFetching: boolean
  data: AutonomoManutencaoEstabelecimentoDTO[]
  dataSubmit: AutonomoManutencaoDTO[]
  changeDataSubmit: (dt: AutonomoManutencaoDTO[]) => void
}

export default function Table({
  data,
  isFetching,
  isLoading,
  query,
  dataSubmit,
  changeDataSubmit,
}: TableProps) {
  const columns: MUIDataTableColumnDef[] = useMemo(() => {
    return [
      {
        name: 'estabelecimentoNrInscricao',
        label: 'CNPJ',
        options: {
          sort: false,
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              width: 0,
            },
          }),
          customBodyRender: (value) => <CopyLabel value={value}>{formatToCNPJ(value)}</CopyLabel>,
        },
      },
      {
        name: 'estabelecimentoNome',
        label: 'Estabelecimento',
        options: {
          sort: false,
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              width: 0,
            },
          }),
        },
      },
      {
        name: 'dtAdmissao',
        label: 'Admissão',
        options: {
          sort: false,
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              width: 0,
            },
          }),
          customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
        },
      },
      {
        name: 'autonomoId',
        label: 'Recorrente',
        options: {
          sort: false,
          setCellProps: () => ({
            style: {
              width: 0,
            },
          }),
          customBodyRender: (value) => {
            const dtCurrent = dataSubmit.find((d) => d.autonomoId === value)
            if (!dtCurrent) return
            return (
              <Box display="flex" justifyContent="center">
                <Checkbox
                  size="small"
                  checked={dtCurrent.isReciboRecorrente}
                  onChange={(e, checked) => {
                    const isReciboRecorrente = checked
                    changeDataSubmit(
                      dataSubmit.map((d) =>
                        d.autonomoId === dtCurrent.autonomoId
                          ? { ...dtCurrent, isReciboRecorrente }
                          : d,
                      ),
                    )
                  }}
                />
              </Box>
            )
          },
        },
      },
      {
        name: 'autonomoId',
        label: 'Ind. Múltiplos Vínculos',
        options: {
          sort: false,
          setCellProps: () => ({
            style: {
              minWidth: 200,
            },
          }),
          customBodyRender: (value) => {
            const dtCurrent = dataSubmit.find((d) => d.autonomoId === value)
            if (!dtCurrent) return
            return (
              <Box p={0.5}>
                <MUIAutoComplete
                  options={IndMultiploVinculoValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  value={dtCurrent.indMultiploVinculo}
                  onChange={(e, obj) => {
                    const indMultiploVinculo = obj?.value || ''
                    changeDataSubmit(
                      dataSubmit.map((d) =>
                        d.autonomoId === dtCurrent.autonomoId
                          ? { ...dtCurrent, indMultiploVinculo }
                          : d,
                      ),
                    )
                  }}
                />
              </Box>
            )
          },
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubmit])

  return (
    <ToolsDataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      pagination={false}
      sherlock={{
        query,
        columns: ['estabelecimentoNrInscricao', 'estabelecimentoNome'],
      }}
    />
  )
}
