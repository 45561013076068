import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryUseObterTodosTabelaINSS } from './useObterTodosTabelaINSS'

import { TabelaINSS } from './TabelaINSS'

export function useAtualizarTabelaINSS() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(tabelaINSS: TabelaINSS) {
    await api.put('/TabelaINSS/' + tabelaINSS.id, tabelaINSS)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.put()
      await queryClient.invalidateQueries(queryUseObterTodosTabelaINSS)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
