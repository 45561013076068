import React from 'react'
import { Box, Divider, Typography, makeStyles } from '@material-ui/core'

import TableRPs from '../TableRPs'
import TableESocial from '../TableESocial'

import { DialogBaseCalculoVinculoData } from '../DialogBaseCalc'

interface FuncExpandedProps {
  funcionario: DialogBaseCalculoVinculoData
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    border: 'solid 1px ' + theme.palette.grey[300],
    padding: theme.spacing(1) + 'px !important',
    margin: theme.spacing(1),
  },
  title: {
    fontWeight: 'bold',
    color: 'gray',
    fontSize: '1rem',
  },
}))

export default function FuncExpanded(props: FuncExpandedProps) {
  const { funcionario } = props

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>Recibos</Typography>
      <Divider />
      <TableRPs data={funcionario.rp} />
      <Typography className={classes.title}>Base de Cálculo</Typography>
      <Divider />
      <TableESocial data={funcionario.baseCalculoESocial} />
    </Box>
  )
}
