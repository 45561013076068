import { useState } from 'react'

import * as yup from 'yup'

import { Box, useTheme } from '@material-ui/core'

import { ActionDialog, DatePickerNew, PDFViewer, TextField } from '~/components'

import useDialog from '~/hooks/useDialog'
import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoAdvertenciaRelatorioRequestDTO } from '~/hooks/queries/VinculoAdvertencia/dtos/VinculoAdvertenciaRelatorioRequestDTO'

import { PAGE_TITLE } from '..'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtAssinatura: yup
    .date()
    .typeError('Informe uma dava válida')
    .required('Informe a Data da Assinatura'),
})

interface DialogRelatorioProps {
  isOpen: boolean
  onClose: () => void
  vinculoAdvertenciaId: string
  subtitle: string
}

export default function DialogRelatorio({
  isOpen,
  onClose,
  vinculoAdvertenciaId,
  subtitle,
}: DialogRelatorioProps) {
  const [data, setData] = useState({
    dtAssinatura: getDateCurrent(),
    testemunha1: '',
    testemunha2: '',
  })

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<VinculoAdvertenciaRelatorioRequestDTO>({
    advertenciaId: vinculoAdvertenciaId,
    dtAssinatura: '',
    testemunha1: '',
    testemunha2: '',
  })

  const theme = useTheme()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    openPDFViewer({
      advertenciaId: vinculoAdvertenciaId,
      dtAssinatura: data.dtAssinatura,
      testemunha1: data.testemunha1,
      testemunha2: data.testemunha2,
    })
  }

  return (
    <ActionDialog
      title="Imprimir Advertência"
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Imprimir"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Box display="flex" gridGap={theme.spacing(1)} flexDirection="column">
        <DatePickerNew
          label="Data da Assinatura"
          value={data.dtAssinatura || null}
          onChange={(time) => {
            const dtAssinatura = time || ''
            setData((prev) => ({ ...prev, dtAssinatura }))
          }}
          required
          validationErrors={validationErrors}
          name="dtAssinatura"
        />
        <TextField
          label="Primeira Testemunha"
          value={data.testemunha1}
          onChange={(e) => {
            const testemunha1 = e.target.value
            setData((prev) => ({
              ...prev,
              testemunha1,
            }))
          }}
        />
        <TextField
          label="Segunda Testemunha"
          value={data.testemunha2}
          onChange={(e) => {
            const testemunha2 = e.target.value
            setData((prev) => ({
              ...prev,
              testemunha2,
            }))
          }}
        />
      </Box>

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="AvisoAdvertencia"
          title={PAGE_TITLE}
          subtitle={subtitle}
          axiosConfig={{
            method: 'post',
            url: '/VinculoAdvertencia/ObterRelatorio',
            data: dataPDFViewer,
          }}
        />
      )}
    </ActionDialog>
  )
}
