import { useEffect, useMemo, useState } from 'react'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { Checkbox, LabelValueColor, PageHeader } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useUtils from '~/hooks/useUtils'

import api from '~/services/api-pessoal'

import Table from './components/Table'

import { IndApuracaoValues, IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import { formatCurrency } from '~/utils/utils'

export default function PrevidenciaBeneficiario(props) {
  const { data: _data } = props
  const theme = useTheme()

  const [dataForm, setDataForm] = useState({
    indApuracao: IndApuracaoEnum.Mensal_1,
    isOnlyDiferenca: false,
  })

  const [collection, setCollection] = useState([])
  const [collectionToDisplay, setCollectionToDisplay] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isOnlyDiferenca, setOnlyDiferenca] = useState(false)

  const dialogNotification = useDialogNotification()
  const { empregador, anoMes } = useAmbiente()
  const { parseStringToFloat } = useUtils()

  const havePropsPrevidenciaBeneficiario = _data ? true : false

  useEffect(() => {
    async function getCollection(empregadorId, periodoApuracao, indApuracao) {
      setLoading(true)
      try {
        const response = await api.get('/ApurarESocial/GetContribuicaoPrevidencia', {
          params: {
            empregadorId,
            periodoApuracao,
            indApuracao,
          },
        })
        setCollection(response?.data?.data || [])
        setCollectionToDisplay(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    if (havePropsPrevidenciaBeneficiario) {
      getCollection(_data.empregadorId, _data.anoMes, _data.indApuracao)
    } else {
      getCollection(empregador?.id, anoMes, dataForm.indApuracao)
    }
    // eslint-disable-next-line
  }, [empregador, anoMes, _data, dataForm.indApuracao])

  function changeSomenteDiferenca(isOnlyDiferenca) {
    setOnlyDiferenca(isOnlyDiferenca)
    if (isOnlyDiferenca) {
      const newData = []
      collection.forEach((d) => {
        if (parseStringToFloat(d?.vrDiferenca) > 0 ? true : false) {
          newData.push(d)
        }
      })
      setCollectionToDisplay(newData)
      return
    }
    setCollectionToDisplay(collection)
  }

  const { contribuicaoDescontada, contribuicaoSegurado, diferença } = useMemo(() => {
    let contribuicaoDescontada = 0
    let contribuicaoSegurado = 0
    let diferença = 0

    collectionToDisplay.forEach((element) => {
      contribuicaoDescontada += element.vrContribuicaoDescontada
      contribuicaoSegurado += element.vrContribuicaoSegurado
      diferença += element.vrDiferenca
    })

    return {
      contribuicaoDescontada,
      contribuicaoSegurado,
      diferença,
    }
  }, [collectionToDisplay])

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title="Previdência por Beneficiário">
        <Box width="80%">
          <Grid container spacing={2}>
            <Grid item xs={12} md={havePropsPrevidenciaBeneficiario ? 12 : 4}>
              <Box width="100%" display="flex" justifyContent="flex-end">
                <Checkbox
                  label="Somente com diferença"
                  value={isOnlyDiferenca}
                  onChange={(e, value) => changeSomenteDiferenca(value)}
                />
              </Box>
            </Grid>

            {!havePropsPrevidenciaBeneficiario && (
              <Grid item xs={12} md={8}>
                <MUIAutoComplete
                  label="Indicador de Apuração"
                  options={IndApuracaoValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  value={dataForm.indApuracao}
                  onChange={(e, obj) => {
                    const indApuracao = obj?.value || ''
                    setDataForm((prev) => ({ ...prev, indApuracao }))
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </PageHeader>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%">
          <Table data={collectionToDisplay} isLoading={isLoading} />
        </Box>
      </Box>

      <Box component={Paper} padding={1.5} display="flex" justifyContent="flex-end">
        <Box width={200}>
          <LabelValueColor
            value={formatCurrency(contribuicaoDescontada, true)}
            label="Contribuição Descontada"
          />
        </Box>
        <Box width={200}>
          <LabelValueColor
            value={formatCurrency(contribuicaoSegurado, true)}
            label="Contribuição Segurado"
          />
        </Box>
        <Box width={200}>
          <LabelValueColor
            value={formatCurrency(diferença, true)}
            label="Diferença"
            color="#2E8BC0"
          />
        </Box>
      </Box>
    </Box>
  )
}
