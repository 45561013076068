import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import { openDownloadData } from '~/hooks/useUtils'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'

interface RequestProps {
  indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum
  anoMes: string
  grupoId?: string
  isExcel?: boolean
}

export function useEstabelecimentoObterRelatorio() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(params: RequestProps) {
    const response = await api.get('/EstabelecimentoConsulta/ObterRelatorio', {
      params,
      headers: {
        'DC-XlsResponse': true,
      },
      responseType: 'blob',
    })
    return response.data
  }

  return useMutation(handleRequest, {
    onSuccess: async (data) => {
      notification.success('Relatório gerado com sucesso')
      openDownloadData('RELACAO-ESTABELECIMENTO.xlsx', data)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
