import React from 'react'

import clsx from 'clsx'
import { Box, makeStyles } from '@material-ui/core'
import { FaCircle } from 'react-icons/fa'

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingTop: (props) => theme.spacing(props.top),
    paddingBottom: (props) => theme.spacing(props.bottom),
    paddingRight: (props) => theme.spacing(props.right),
    paddingLeft: (props) => theme.spacing(props.left),
  },
  ativo: {
    color: theme.palette.alerts.success,
  },
  inativo: {
    color: theme.palette.alerts.danger,
  },
}))

const Ativo = (props) => {
  const { ativo, title, ...rest } = props
  const classes = useClasses({ ...rest })
  return (
    <Box className={classes.root} title={title}>
      <FaCircle
        className={clsx({
          [classes.ativo]: ativo,
          [classes.inativo]: !ativo,
        })}
      />
    </Box>
  )
}

export default Ativo

Ativo.defaultProps = {
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  title: '',
}
