import React, { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import YearMonthPicker from '~/components/YearMonthPicker'
import { ActionDialog } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'

import theme from '~/styles/theme'

function addOneMonthInYearMonth(anoMes) {
  let currentYear = parseInt(anoMes.substring(0, 4))
  let currentMonth = parseInt(anoMes.substring(4)) + 1

  if (currentMonth > 12) {
    currentMonth = 1
    currentYear = (currentYear + 1).toString()
  }

  const newMonth = currentMonth.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  return currentYear + newMonth
}

const FormReplicar = (props) => {
  const { isOpen, onClose, rpAutonomoId, onAfterSubmitForm } = props
  const [isLoading, setLoading] = useState(false)
  const [anoMesSelected, setAnoMesSelected] = useState([])

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes } = useAmbiente()

  const anoMesToStart = addOneMonthInYearMonth(anoMes)

  useEffect(() => {
    if (!isOpen) return
    setAnoMesSelected([])
  }, [isOpen])

  async function handleSubmit() {
    if (!(anoMesSelected.length > 0)) return notification.error('Informe pelo menos um Ano/Mês')
    setLoading(true)
    try {
      await api.post('/RPAutonomo/Replicar', anoMesSelected, {
        params: {
          rpAutonomoId,
        },
      })
      notification.post()
      onAfterSubmitForm('replicate')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ActionDialog
      title="Replicar Registro"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Continuar"
      isOkProcessing={isLoading}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Typography align="center" style={{ paddingBottom: theme.spacing(1) }}>
        Informe os meses que deseja replicar as configurações do lançamento
      </Typography>
      <Box margin={1}>
        <YearMonthPicker
          startYearMonth={anoMesToStart}
          value={anoMesSelected}
          onChange={setAnoMesSelected}
          disabledOldYearsMonths
        />
      </Box>
    </ActionDialog>
  )
}

export default FormReplicar
