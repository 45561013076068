import { useState } from 'react'

import { Box, useTheme } from '@material-ui/core'

import { PageHeader, PDFViewer, Finder } from '~/components'

import Form from './components/Form'
import Table from './components/Table'
import Footer from './components/Footer'

import useDialogNotification from '~/hooks/useDialogNotification'
import useDialog from '~/hooks/useDialog'
import useObterRelatorioCSV from '~/hooks/queries/RelacaoBancaria/useObterRelatorioCSV'

import api from '~/services/api-pessoal'

const titlePage = 'Relação Bancária'

export default function RelacaoBancaria() {
  const [response, setResponse] = useState(null)
  const [rowsSelecteds, setRowsSelected] = useState([])

  const [isLoading, setLoading] = useState(false)

  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const theme = useTheme()

  const { mutateAsync, isLoading: isLoadingCSV } = useObterRelatorioCSV()

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog(null)

  async function handleProcess(data) {
    setLoading(true)
    try {
      const response = await api.post('/Relatorio/RelacaoBancaria/ObterRelacaoBancaria', data)
      const responseObject = response?.data?.data || {}
      setResponse(responseObject)
      setRowsSelected(responseObject.movimentoBancario.map((_, index) => index))
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  function handleClickAction(event) {
    if (rowsSelecteds.length === 0)
      return dialogNotification.warning({
        descriptions: ['Selecione pelo menos um movimento para realizar a impressão.'],
      })
    const movimentoArray = response?.movimentoBancario || []
    const movimentoBancario = rowsSelecteds.map((index) => movimentoArray[index])

    const newData = { ...response, movimentoBancario }

    switch (event) {
      case 'print':
        handlePrint(newData)
        break
      case 'csv':
        handleCSV(newData)
        break
    }
  }

  async function handleCSV(data) {
    await mutateAsync({ data })
  }

  function handlePrint(data) {
    openPDFViewer(data)
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title={titlePage}>
        <Finder onSearch={(q) => setQuery(q)} onClose={() => setQuery('')} />
      </PageHeader>
      <Form onProcess={handleProcess} isLoading={isLoading} />
      <Box flex={1} position="relative" overflow="auto" minHeight={300}>
        <Box position="absolute" width="100%">
          <Table
            data={response?.movimentoBancario || []}
            isLoading={isLoading}
            query={query}
            rowsSelecteds={rowsSelecteds}
            setRowsSelected={setRowsSelected}
          />
        </Box>
      </Box>
      <Footer
        data={response?.movimentoBancario || []}
        isLoading={isLoading}
        isLoadingCSV={isLoadingCSV}
        onClickCSVButton={() => handleClickAction('csv')}
        onClickPrintButton={() => handleClickAction('print')}
      />

      <PDFViewer
        title={titlePage}
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="RelacaoBancaria"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/RelacaoBancaria/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
