import React, { useEffect, useState } from 'react'

import { MenuItem, TextField, TextFieldProps } from '@material-ui/core'

interface SelectTableProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
  value: any
  options: any[]
  optionId: string
  renderOption: (option: any) => React.ReactNode
  onChange: (value: any) => void
}

export default function SelectTable(props: SelectTableProps) {
  const { value: _value, options, optionId, renderOption, onChange, ...rest } = props

  const [value, setValue] = useState<any>('')

  useEffect(() => {
    setValue(_value)
  }, [_value])

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    onChange(e.target.value)
    setValue(e.target.value)
  }

  return (
    <TextField
      {...rest}
      fullWidth
      size="small"
      variant="outlined"
      color="primary"
      select
      onChange={handleChange}
      value={value}
    >
      {options.map((opt) => (
        <MenuItem value={opt[optionId]} key={opt[optionId]}>
          {renderOption(opt)}
        </MenuItem>
      ))}
    </TextField>
  )
}
