import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { CondicaoAmbienteTrabalhoSaveDTO } from './CondicaoAmbienteTrabalhoSaveDTO'

interface RequestProps {
  data: CondicaoAmbienteTrabalhoSaveDTO
  isRetificar: boolean
}

export function useCondicaoAmbienteTrabalhoAtualizar() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(props: RequestProps) {
    const { data, isRetificar } = props
    await api.put('/CondicaoAmbienteTrabalho/AtualizarCondicaoAmbienteTrabalho', data, {
      params: {
        isRetificar,
      },
    })
    notification.put()
  }

  return useMutation(handleRequest, {
    onSuccess: async () =>
      await queryClient.invalidateQueries('CondicaoAmbienteTrabalhoGetByVinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
