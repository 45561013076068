export enum DiaHorarioEnum {
  SegundaFeira_1 = 1,
  Terça_Feira_2 = 2,
  Quarta_Feira_3 = 3,
  Quinta_Feira_4 = 4,
  Sexta_Feira_5 = 5,
  Sabado_6 = 6,
  Domingo_7 = 7,
  Dia_Variável_8 = 8,
}

export const DiaHorarioValues = [
  { value: DiaHorarioEnum.SegundaFeira_1, name: 'Segunda-Feira' },
  { value: DiaHorarioEnum.Terça_Feira_2, name: 'Terça-Feira' },
  { value: DiaHorarioEnum.Quarta_Feira_3, name: 'Quarta-Feira' },
  { value: DiaHorarioEnum.Quinta_Feira_4, name: 'Quinta-Feira' },
  { value: DiaHorarioEnum.Sexta_Feira_5, name: 'Sexta-Feira' },
  { value: DiaHorarioEnum.Sabado_6, name: 'Sábado' },
  { value: DiaHorarioEnum.Domingo_7, name: 'Domingo' },
  { value: DiaHorarioEnum.Dia_Variável_8, name: 'Variável' },
]

export const DiaHorarioValuesSimple = [
  { value: DiaHorarioEnum.SegundaFeira_1, name: 'SEG' },
  { value: DiaHorarioEnum.Terça_Feira_2, name: 'TER' },
  { value: DiaHorarioEnum.Quarta_Feira_3, name: 'QUA' },
  { value: DiaHorarioEnum.Quinta_Feira_4, name: 'QUI' },
  { value: DiaHorarioEnum.Sexta_Feira_5, name: 'SEX' },
  { value: DiaHorarioEnum.Sabado_6, name: 'SAB' },
  { value: DiaHorarioEnum.Domingo_7, name: 'DOM' },
  { value: DiaHorarioEnum.Dia_Variável_8, name: 'VAR' },
]
