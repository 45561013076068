import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'
import { openDownloadData } from '~/hooks/useUtils'

interface RequestProps {
  params: {
    nrRemessa: string
  }
}

export default function useGerarPlanilhaRemessas(nomeArquivo: string) {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ params }: RequestProps) {
    const response = await api.get<{ data: Blob }>('/CNAB/GerarPlanilhaRemessas', {
      params,
      responseType: 'blob',
    })
    return response.data
  }

  return useMutation(handleRequest, {
    onSuccess: (d) => {
      openDownloadData(nomeArquivo, d)
      notifySuccess('Planilha gerada com sucesso')
    },
    onError: dialogNotification.extractErrors,
  })
}
