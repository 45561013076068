import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { SalarioItem } from './SalarioItem'

const query = '/SalarioItem'

interface RequestParams {
  salarioId: string
}

export function useObterTodosSalarioItem(params: RequestParams) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: SalarioItem[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useObterTodosSalarioItemInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
