const naoInformado = 1
const anuenio = 2
const bienio = 3
const trienio = 4
const quinquenio = 5

export const indATSValues = [
  { value: naoInformado, name: '1 - Não informar' },
  { value: anuenio, name: '2 - Anuênio' },
  { value: bienio, name: '3 - Biênio' },
  { value: trienio, name: '4 - Triênio' },
  { value: quinquenio, name: '5 - Quinquênio' },
]

export const indATSConsts = {
  naoInformado,
  anuenio,
  bienio,
  trienio,
  quinquenio,
}
