import React, { useCallback } from 'react'

import { IconButton } from '@material-ui/core'
import { DataTable, ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { formatToCNPJ, formatToCPF } from 'brazilian-values'

import { tipoInscricaoValues } from '~/values/tipoInscricaoValues'

function formatByTipoInscricao(tipoInscricao) {
  const objFinded = tipoInscricaoValues.find((obj) => obj.value === tipoInscricao)
  return objFinded.name
}

const makeColumns = (onItemClick) => [
  {
    name: 'nome',
    label: 'Nome',
  },
  {
    name: 'nrInscricao',
    label: 'Número de Inscrição',
    options: {
      customBodyRender: (value) => (value?.length >= 14 ? formatToCNPJ(value) : formatToCPF(value)),
    },
  },
  {
    name: 'tipoInscricao',
    label: 'Tipo de Inscrição',
    options: {
      customBodyRender: (value) => formatByTipoInscricao(value),
    },
  },
  {
    name: 'id',
    label: 'Ações',
    options: {
      filter: true,
      sort: false,
      empty: true,
      customBodyRender: (value) => {
        return (
          <ButtonBox spacing={0} justifyContent="center">
            <IconButton
              size="small"
              title="Editar este registro"
              color="primary"
              aria-label="Editar"
              onClick={() => {
                onItemClick('edit', value)
              }}
            >
              <EditIcon fontSize="small" color="primary" />
            </IconButton>

            <IconButton
              title="Deletar este registro"
              size="small"
              color="primary"
              aria-label="Deletar"
              onClick={() => {
                onItemClick('delete', value)
              }}
            >
              <DeleteIcon fontSize="small" color="primary" />
            </IconButton>
          </ButtonBox>
        )
      },
    },
  },
]

const Table = (props) => {
  const { data, onItemClick, isLoading, query } = props

  const columns = useCallback(() => {
    return makeColumns(onItemClick)
  }, [onItemClick])

  return (
    <div>
      <DataTable
        title=""
        data={data}
        columns={columns()}
        options={options}
        isLoading={isLoading}
        sherlock={{
          query,
          columns: ['nome', 'nrInscricao'],
        }}
      />
    </div>
  )
}

const options = {
  viewColumns: false,
  filter: false,
  search: false,
  selectableRows: 'none',
  print: false,
  download: false,
  elevation: 0,
  responsive: 'standard',
}

Table.defaultProps = {
  query: '',
}

export default Table
