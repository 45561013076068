import React from 'react'

import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  boxComponent: {
    border: '1px solid #CCC',
    borderRadius: 4,
    padding: (props) => theme.spacing(props.padding || 2),
    width: '100%',
    marginTop: (props) => theme.spacing(props.top || 0),
    marginBottom: (props) => theme.spacing(props.bottom || 0),
    marginLeft: (props) => theme.spacing(props.left || 0),
    marginRight: (props) => theme.spacing(props.right || 0),
  },
}))

const ContentFrame = (props) => {
  const { headerRenderer, children, top, bottom, left, right, padding } = props
  const classes = useStyles({ top, bottom, left, right, padding })
  return (
    <Box className={classes.boxComponent}>
      {headerRenderer}
      {children}
    </Box>
  )
}

ContentFrame.defaultProps = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}

export default ContentFrame
