import React, { useState, useCallback, useMemo } from 'react'

import moment from 'moment'

import { Button } from 'mio-library-ui'
import { Box, makeStyles } from '@material-ui/core'

import { ToolsDataTable } from '~/components'

import TableCellContent from './components/TableCellContent'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'

import { statusProcessTransmissaoConsts } from '~/values/statusProcessTransmissaoValues'
import { eSocialStatusConsts } from '~/values/eSocialStatusValues'

const {
  statusProcessTransmissao_wait_1,
  statusProcessTransmissao_process_2,
  statusProcessTransmissao_finish_3,
  statusProcessTransmissao_error_5,
  statusProcessTransmissao_finish_warning_4,
} = statusProcessTransmissaoConsts

const isFinalizado = [statusProcessTransmissao_finish_3, statusProcessTransmissao_finish_warning_4]
const isErrorsWarnings = [
  statusProcessTransmissao_finish_warning_4,
  statusProcessTransmissao_error_5,
]

const isFinishedWarningsErrors = [
  statusProcessTransmissao_process_2,
  statusProcessTransmissao_finish_3,
  statusProcessTransmissao_finish_warning_4,
  statusProcessTransmissao_error_5,
]

const useStyles = makeStyles((theme) => ({
  minWidthColumn: {
    minWidth: '120px',
  },
  boxHeaderTableExpanded: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    gap: theme.spacing(2),
    borderTop: theme.shape.border,
  },
}))

export default function TableDetailsExpanded(props) {
  const { data, isLoading, status, handleReloadData } = props

  const isStatusError = status === statusProcessTransmissao_error_5
  const isStatusAguardando = status === statusProcessTransmissao_wait_1

  const [indexsSelecteds, setIndexsSelecteds] = useState([])
  const [isLoadingAlterarStatus, setLoadingAlterarStatus] = useState(false)

  const classes = useStyles()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const verifyIfRenderColumn = useCallback(
    (type) => {
      return type.includes(status) ? true : 'excluded'
    },
    [status],
  )

  function formatColumnOrigemInformacao(value) {
    const maps = {
      EmpregadorSituacao: 'Situação do Empregador',
      EstabelecimentoSituacao: 'Situação do Estabelecimento',
      EventoEmpregador: 'Evento / Evento Empregador',
      LotacaoSituacao: 'Situação da Lotação',
      ProcessoSituacao: 'Situação do Processo',
      PessoaFisica: 'Pessoa Física',
      AdquirenteItem: 'Comercialização Rural',
      ContratacaoTrabalhadorAvulso: 'Contratação Trabalhador Avulso',
      EmpregadorInformacaoComplementar: 'Empregador Informação Complementar',
      VinculoAdmissaoPreliminar: 'Admissão Preliminar',
      Vinculo: 'Vínculo',
      VinculoAlteracaoContratoCadastro: 'Vínculo - Alteração Contrato / Alteração Cadastro',
      ComunicadoAcidenteTrabalho: 'Comunicado Acidente de Trabalho (CAT)',
      AtestadoSaudeOcupacional: 'Atestado de Saúde Ocupacional',
      VinculoAfastamento: 'Afastamento',
      CondicaoAmbienteTrabalho: 'Condição Ambiente de Trabalho',
      RP: 'Recibo de Pagamento',
      VinculoProcesso: 'Processo Trabalhista',
    }
    return maps[value] || value
  }

  const columns = useMemo(
    () => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'eSocialRecibo',
        label: 'Número do Recibo',
        options: {
          display: verifyIfRenderColumn(isFinalizado),
        },
      },
      {
        name: 'dtESocialRecibo',
        label: 'Data do Recibo',
        options: {
          customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy - HH:mm:ss') : ''),
          display: verifyIfRenderColumn(isFinalizado),
        },
      },
      {
        name: 'tabelaOrigem',
        label: 'Origem da informação',
        options: {
          customBodyRender: formatColumnOrigemInformacao,
          setCellProps: () => {
            return {
              className: classes.minWidthColumn,
            }
          },
        },
      },
      {
        name: 'dtMovimento',
        label: 'Data do Movimento',
      },
      {
        name: 'retorno_DataHora',
        label: 'Data/Hora Retorno',
        options: {
          display: verifyIfRenderColumn(isErrorsWarnings),
        },
      },
      {
        name: 'ocorrencias',
        label: 'Ocorrências',
        options: {
          display: verifyIfRenderColumn(isErrorsWarnings),
          customBodyRender: (value, { rowData }) => {
            const nome = rowData[1]
            const newValue = value?.length > 0 ? value : undefined
            return (
              <TableCellContent value={newValue} type="ocorrencias" subtitle={nome}>
                Ocorrências
              </TableCellContent>
            )
          },
        },
      },
      {
        name: 'errosSchema',
        label: 'Erros Schema',
        options: {
          display: verifyIfRenderColumn(isErrorsWarnings),
          customBodyRender: (value, { rowData }) => {
            const nome = rowData[1]
            const newValue = value?.length > 0 ? value : undefined
            return (
              <TableCellContent value={newValue} type="errosSchema" subtitle={nome}>
                Erros Schema
              </TableCellContent>
            )
          },
        },
      },
      {
        name: 'xmlEnvio',
        label: 'XML Envio',
        options: {
          display: verifyIfRenderColumn(isFinishedWarningsErrors),
          customBodyRender: (value, { rowData }) => {
            const nome = rowData[1]
            return (
              <TableCellContent value={value} type="xml" subtitle={nome}>
                XML Envio
              </TableCellContent>
            )
          },
        },
      },
      {
        name: 'retorno_Xml',
        label: 'XML Retorno',
        options: {
          display: verifyIfRenderColumn(isFinishedWarningsErrors),
          customBodyRender: (value, { rowData }) => {
            const nome = rowData[1]
            return (
              <TableCellContent value={value} type="xml" subtitle={nome}>
                XML Retorno
              </TableCellContent>
            )
          },
        },
      },
    ],
    [classes, verifyIfRenderColumn],
  )

  async function handleAlterStatusEvents(statusParaInativar) {
    setLoadingAlterarStatus(true)
    try {
      const idsSelecteds = indexsSelecteds.map((index) => data[index].id)
      await api.post('/ESocial/AlterarStatusEvento', idsSelecteds, {
        params: {
          status: statusParaInativar,
        },
      })
      notification.success('O status foi alterado para aguardando com sucesso')
      handleReloadData()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingAlterarStatus(false)
    }
  }

  const optionsTableSelectable = {
    optionsSelectable: {
      type: 'multiple',
      onRowSelected: setIndexsSelecteds,
      rowsSelected: indexsSelecteds,
    },
  }

  return (
    <Box>
      {isStatusError && (
        <Box className={classes.boxHeaderTableExpanded}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() =>
              handleAlterStatusEvents(eSocialStatusConsts.eSocialStatusValues_Aberto_10)
            }
            disabled={!(indexsSelecteds.length > 0)}
            isLoading={isLoadingAlterarStatus}
          >
            Alterar Status para Aguardando
          </Button>
        </Box>
      )}
      {isStatusAguardando && (
        <Box className={classes.boxHeaderTableExpanded}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() =>
              handleAlterStatusEvents(eSocialStatusConsts.eSocialStatusValues_inativos)
            }
            disabled={!(indexsSelecteds.length > 0)}
            isLoading={isLoadingAlterarStatus}
          >
            Inativar Eventos
          </Button>
        </Box>
      )}
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        disableAutoHeight
        {...(isStatusError && optionsTableSelectable)}
        {...(isStatusAguardando && optionsTableSelectable)}
      />
    </Box>
  )
}
