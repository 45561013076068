import { useEffect, useState } from 'react'

import { Box, useTheme } from '@material-ui/core'

import { Button, ButtonBox, Checkbox, PageHeader } from '~/components'

import FormCreate from './FormCreate'
import Table from './Table'

import useOnlyDataC from '~/hooks/useOnlyDataC'
import useAmbiente from '~/hooks/useAmbiente'

import { Cached } from '@material-ui/icons'

export default function ConfiguracoesNotificacoes() {
  const { anoMes: _anoMes } = useAmbiente()

  const [isOpenFormCreate, setOpenFormCreate] = useState(false)
  const [isEnvironmentCommon, setEnvironmentCommon] = useState(false)
  const [anoMes, setAnoMes] = useState(_anoMes)

  const theme = useTheme()
  const { isUserDataC } = useOnlyDataC()

  useEffect(() => {
    setAnoMes(_anoMes)
  }, [_anoMes])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
      padding={2}
      height="100%"
      width="100%"
    >
      <PageHeader
        title={'Configurações das Notificações' + (isEnvironmentCommon ? ' - Globais' : '')}
      >
        <ButtonBox>
          <Checkbox
            label="Mostrar apenas notificações da competência"
            value={anoMes ? true : false}
            onChange={() => setAnoMes((prev) => (prev ? '' : _anoMes))}
          />
          {isUserDataC && (
            <Button variant="outlined" onClick={() => setEnvironmentCommon((prev) => !prev)}>
              <Cached style={{ marginRight: theme.spacing(1) }} />
              {isEnvironmentCommon
                ? 'Mostrar notificações da Licença'
                : 'Mostrar notificações Globais'}
            </Button>
          )}
          <Button variant="contained" onClick={() => setOpenFormCreate(true)}>
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%">
          <Table isEnvironmentCommon={isEnvironmentCommon} anoMes={anoMes} />
        </Box>
      </Box>

      {isOpenFormCreate && (
        <FormCreate
          isOpen={isOpenFormCreate}
          onClose={() => setOpenFormCreate(false)}
          isEnvironmentCommon={isEnvironmentCommon}
        />
      )}
    </Box>
  )
}
