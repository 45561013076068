import { useState, useCallback, useEffect } from 'react'

import { makeStyles } from '@material-ui/core'
import { Finder } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { ToolsDataTable } from '~/components'

import TableVinculos from './components/TableVinculos'

import useDialogNotification from '~/hooks/useDialogNotification'
import useUtils from '~/hooks/useUtils'

import api from '~/services/api-pessoal'

const useStyles = makeStyles(() => ({
  renderRight: {
    textAlign: 'right',
  },
  fullHeight: {
    height: '100%',
  },
}))

const FormResumo = (props) => {
  const { isOpen, onClose, anoMes, estabelecimento } = props

  const [collection, setCollection] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [rowsExpanded, setRowsExpanded] = useState([0])

  const dialogNotification = useDialogNotification()
  const { formatCurrency } = useUtils()
  const classes = useStyles()

  useEffect(() => {
    const getCollection = async () => {
      if (!isOpen) return
      setLoading(true)
      try {
        const response = await api.get(`/LancamentoColetivo/GetResumo`, {
          params: {
            estabelecimentoId: estabelecimento?.id,
            anoMes,
          },
        })
        const newData = response.data.data.map((d) => {
          return {
            ...d,
            vrTotal: d?.vrTotal ? formatCurrency(d.vrTotal) : '',
          }
        })
        setCollection(newData)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [isOpen, estabelecimento, anoMes])

  const columns = [
    {
      name: 'eventoId',
      label: 'Evento',
      options: {
        customBodyRender: (value) => {
          const dataCurrent = collection.find((d) => d.eventoId === value)
          if (!dataCurrent) return
          return `${dataCurrent.eventoCodigo} - ${dataCurrent.eventoNome}`
        },
      },
    },
    {
      name: 'vrTotal',
      label: 'Valor Total',
      options: {
        setCellProps: () => ({ className: classes.renderRight }),
      },
    },
  ]
  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  function renderExpandableRow(indexParent) {
    const dataExpanded = collection[indexParent]?.vinculos
    return <TableVinculos data={dataExpanded} />
  }

  return (
    <ActionDialog
      title="Resumo Lançamento Coletivo"
      subtitle={estabelecimento ? `${estabelecimento?.codigo} - ${estabelecimento?.nome}` : ''}
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
      customActions={<></>}
      renderRight={<Finder onSearch={handleQuery} onClose={() => handleQuery('')} />}
    >
      <ToolsDataTable
        data={collection}
        columns={columns}
        isLoading={isLoading}
        sherlock={{
          columns: ['eventoNome'],
          query,
        }}
        optionsExpandable={{
          type: 'multiple',
          onRowExpanded: setRowsExpanded,
          expandedChildren: renderExpandableRow,
          rowsExpanded: rowsExpanded,
          expandOnClick: true,
        }}
      />
    </ActionDialog>
  )
}

export default FormResumo
