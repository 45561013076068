import { useEffect, useRef, useState } from 'react'

import TextField from '../TextField'

import { Box, CircularProgress, IconButton, InputAdornment } from '@material-ui/core'
import { Check, Close, Edit } from '@material-ui/icons'

import useKeyPress from '~/hooks/useKeyPress'

interface TextFieldChangeProps {
  value: string
  onChange: (value: string) => void
  isLoading: boolean
}

export default function TextFieldChange(props: TextFieldChangeProps) {
  const { value: _value, onChange, isLoading } = props
  const [value, setValue] = useState(_value)
  const [isHover, setHover] = useState(false)
  const [isEditable, setEditable] = useState(false)

  const refTextField = useRef<FixLater>(null)

  useKeyPress('F2', isHover ? () => handleClickEdit() : () => undefined)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  useEffect(() => {
    if (refTextField.current && isEditable) {
      refTextField.current.focus()
    }
  }, [isEditable])

  function handleSubmit() {
    onChange(value)
    setEditable(false)
  }

  function handleCancel() {
    setValue(_value)
    setEditable(false)
  }

  function handleClickEdit() {
    setEditable(true)
  }

  function handleBlur() {
    if (isHover) {
      return
    }
    handleCancel()
  }

  function handleKeyDown(key: string) {
    if (!isEditable) {
      return
    }
    switch (key) {
      case 'Enter':
        handleSubmit()
        break
      case 'Escape':
        handleCancel()
        break
      default:
        break
    }
  }

  return (
    <Box width={400} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <TextField
        inputRef={refTextField}
        value={value}
        variant="standard"
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={({ key }) => handleKeyDown(key)}
        inputProps={{
          readOnly: isEditable ? false : true,
        }}
        onBlur={() => handleBlur()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isEditable && !isLoading && (
                <>
                  <IconButton size="small" onClick={() => handleCancel()}>
                    <Close fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleSubmit()}>
                    <Check fontSize="small" />
                  </IconButton>
                </>
              )}
              {isHover && !isEditable && (
                <IconButton size="small" onClick={() => handleClickEdit()}>
                  <Edit fontSize="small" />
                </IconButton>
              )}
              {isLoading && <CircularProgress size={20} />}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}
