import { useEffect } from 'react'

import { useMutation, useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '../useDialogNotification'
import useAmbiente from '../useAmbiente'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

async function getAll(estabelecimentoId, anoMes) {
  const response = await api.post(
    '/VinculoConsulta/GetByEstabelecimento',
    [VinculoTipoEnum.Funcionario_1],
    {
      params: {
        estabelecimentoId,
        anoMes,
      },
    },
  )
  return response?.data?.data || []
}

export async function getVinculoById(id) {
  const response = await api.get(`/Vinculo/${id}`)
  return response?.data?.data || {}
}

async function remove(id) {
  await api.delete(`/Vinculo/${id}`)
}

async function createVinculoSST(dt) {
  await api.post('/Vinculo/CriarVinculoSST', dt)
}

async function updateVinculo(dt) {
  await api.put(`/Vinculo/${dt.id}`, dt)
}

export function useVinculo() {
  const dialogNotification = useDialogNotification()
  const { estabelecimento, anoMes } = useAmbiente()

  const query = useQuery('Vinculo', () => getAll(estabelecimento?.id, anoMes), {
    onError: (err) => dialogNotification.extractErrors(err),
  })

  useEffect(() => {
    query.refetch()
    // eslint-disable-next-line
  }, [estabelecimento, anoMes])

  return query
}

export function useVinculoMutate() {
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  const mutateDelete = useMutation(remove, {
    onSuccess: async () => await queryClient.invalidateQueries('Vinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateCreateVinculoSST = useMutation(createVinculoSST, {
    onSuccess: async () => await queryClient.invalidateQueries('Vinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  const mutateUpdateVinculo = useMutation(updateVinculo, {
    onSuccess: async () => await queryClient.invalidateQueries('Vinculo'),
    onError: (err) => dialogNotification.extractErrors(err),
  })

  return {
    mutateDelete,
    mutateCreateVinculoSST,
    mutateUpdateVinculo,
  }
}
