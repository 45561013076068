import { useEffect } from 'react'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

export async function handleRequest(anoMes: string) {
  await api.post('/TabelaMes/InserirTabelaMes', null, {
    params: {
      anoMes,
    },
  })
}

export function useTabelaMesInserirTabelaMes(anoMes?: string) {
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    async function onRequest() {
      try {
        if (!anoMes) return
        await handleRequest(anoMes)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    }
    onRequest()
    // eslint-disable-next-line
  }, [anoMes])
}
