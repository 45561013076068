import React, { useRef } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { Pie, getElementAtEvent } from 'react-chartjs-2'

import { Paper, styled, CircularProgress } from '@material-ui/core'

ChartJS.register(ArcElement, Tooltip, Legend, Title)

const PaperRoot = styled(Paper)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
}))

export default function PieChart(props) {
  const { isLoading, data, hasData, onClick } = props

  const chartRef = useRef()

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Transmissão eSocial Geral',
      },
    },
    onHover: (event, elements) => {
      if (!hasData) return
      if (!elements[0]) {
        event.native.target.style.cursor = 'default'
        return
      }
      event.native.target.style.cursor = 'pointer'
    },
  }

  function handleClick(event) {
    if (!hasData) return
    const element = getElementAtEvent(chartRef.current, event)[0]
    if (element?.index === undefined) return
    const { index } = element
    const status = index + 1 // = 1,2,3 4,5,
    onClick(status)
  }

  return (
    <PaperRoot elevation={5}>
      {isLoading ? (
        <CircularProgress size={50} />
      ) : (
        <Pie ref={chartRef} data={data} options={options} onClick={handleClick} />
      )}
    </PaperRoot>
  )
}
