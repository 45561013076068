import { useMutation, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryUseObterTodosTabelaSF } from './useObterTodosTabelaSF'

import { TabelaSF } from './TabelaSF'

export function useAtualizarTabelaSF() {
  const queryClient = useQueryClient()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(tabelaSF: TabelaSF) {
    await api.put('/TabelaSF/' + tabelaSF.id, tabelaSF)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.put()
      await queryClient.invalidateQueries(queryUseObterTodosTabelaSF)
    },
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
