import { useState } from 'react'
import { useQueryClient } from 'react-query'

import jwtDecode from 'jwt-decode'
import _ from 'lodash'

import { useHistory } from 'react-router-dom'
import useDialogNotification from './useDialogNotification'
import credentialsConfig from '~/configs/credentials'

import apiAuth from '~/services/api-auth'

export default function useCredentials() {
  const [isSubmitting, setSubmitting] = useState(false)

  const navigate = useHistory()
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  const appPermissionsIds = credentialsConfig.applicationsIds

  const appPermissionId =
    appPermissionsIds?.split(',').find((i) => i == 'app-pessoal') || 'app-pessoal'

  const TOKEN_KEY = credentialsConfig.tokenKey || 'persist:@datac-pessoal:token'
  const REFRESH_TOKEN_KEY =
    credentialsConfig.refreshTokenKey || 'persist:@datac-pessoal:refresh-token'

  function setToken(token, refreshToken) {
    localStorage.setItem(TOKEN_KEY, token)
    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    } else {
      localStorage.removeItem(REFRESH_TOKEN_KEY)
    }
  }

  function logout() {
    localStorage.removeItem(TOKEN_KEY)
    navigate.push('/')
  }

  function getToken() {
    return localStorage.getItem(TOKEN_KEY)
  }

  function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  }

  function decodeToken() {
    const token = getToken()

    if (!token) return null

    const { data } = jwtDecode(token)
    return data
  }

  function isLogged() {
    const token = getToken()
    if (token === null) return false
    try {
      const { exp } = jwtDecode(token)
      const expDate = new Date(exp * 1000).getTime()
      const nowDate = new Date().getTime()
      return nowDate < expDate
    } catch (err) {
      return false
    }
  }

  async function login(email, password) {
    setSubmitting(true)
    try {
      const response = await apiAuth.post(
        'sessions',
        { email, password },
        {
          headers: {
            'DC-SISTEMA': appPermissionId,
          },
        },
      )
      queryClient.clear()
      const toReturn = response?.data
      if (toReturn) {
        const token = response.data.token
        const { data: decodedToken } = jwtDecode(token)
        const permissoes = decodedToken.permissoes
        const permissao = permissoes['app-pessoal']

        toReturn.email = email
        toReturn.password = password
        toReturn.empresas = _.orderBy(permissao.empresas, 'nome')
      }
      return toReturn
    } catch (err) {
      const message = err?.response?.data?.message || 'Erro Inesperado'
      dialogNotification.warning({
        descriptions: [message],
      })
    } finally {
      setSubmitting(false)
    }
  }

  async function alterarSenha(senhaAtual, senhaNova) {
    setSubmitting(true)
    try {
      const token = getToken()
      if (token === null) throw new Error('Não foi possível alterar sua senha')

      const response = await apiAuth.put(
        'passwords/change',
        {
          old: senhaAtual,
          new: senhaNova,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response) throw new Error('Ocorreu um erro ao tentar alterar sua senha')
      return response?.data
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  async function selecionarLicenca(loginData, empresaId) {
    setSubmitting(true)
    try {
      if (!loginData) throw new Error('Login indefinido')
      const response = await apiAuth.post(
        'selecionar-empresa',
        {
          permissao: 'app-pessoal',
          empresaUuid: empresaId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + loginData.token,
          },
        },
      )
      if (!response) throw new Error('Erro no login')
      const { token, refreshToken } = response.data
      setToken(token, refreshToken)
      return response.data
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  const isAuthenticated = isLogged()
  const userLogged = decodeToken()
  const token = getToken()
  const refreshToken = getRefreshToken()

  return {
    login,
    logout,
    isSubmitting,
    token,
    refreshToken,
    userLogged,
    isAuthenticated,
    selecionarLicenca,
    alterarSenha,
  }
}
