import { formatToCPFOrCNPJ } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { CopyLabel, ToolsDataTable } from '~/components'
import { VinculoDTO } from '~/hooks/queries/Vinculo/VinculoDTO'

interface TableProps {
  isLoading: boolean
  isFetching: boolean
  query: string
  data: VinculoDTO[]
  onRowSelected: (d: number[]) => void
  rowsSelected: number[]
}

export default function Table({
  isLoading,
  isFetching,
  query,
  data,
  onRowSelected,
  rowsSelected,
}: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
      options: {
        customBodyRender: (value) =>
          value ? <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel> : '',
      },
    },
    {
      name: 'nome',
      label: 'Nome',
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      sherlock={{ columns: ['codigo', 'nrInscricao', 'nome'], query }}
      disableAutoHeight
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
    />
  )
}
