import React, { useState } from 'react'

import clsx from 'clsx'

import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons'

import SimpleCarousel from '~/components/SimpleCarousel'

import ContainerFerias from '../components/ContainerFerias'
import SimpleLabelValue from '../components/SimpleLabelValue'

const useStyles = makeStyles((theme) => ({
  stepSelected: {
    color: theme.palette.success.main,
  },
}))

interface FeriasProgramadaProps {
  dtFimAbono: string
  dtFimConcessao: string
  dtInicioAbono: string
  dtInicioConcessao: string
  nrDiasAbono: number
  nrDiasConcedidos: number
}

interface PeriodoProgramadoProps {
  data: FeriasProgramadaProps[]
  indexPeriodoSelected: number
  onChangePeriodoSelected: (indexSelected: number) => void
}

export default function PeriodoProgramado(props: PeriodoProgramadoProps) {
  const { data, indexPeriodoSelected, onChangePeriodoSelected } = props

  const [activeStep, setActiveStep] = useState(0)

  const classes = useStyles()

  const isStepSelected = indexPeriodoSelected === activeStep
  const hasMoreThanZero = data.length > 0

  if (!hasMoreThanZero) {
    return (
      <ContainerFerias
        title="Período Programado"
        style={{
          paddingBottom: '16px',
        }}
      >
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Nenhum período programado encontrado
        </Typography>
      </ContainerFerias>
    )
  }

  function handleChangeCarousel(event: 'next' | 'back', index: number) {
    setActiveStep(index)
  }

  return (
    <ContainerFerias
      title="Período Programado"
      style={{
        paddingBottom: '8px',
      }}
      renderRight={
        hasMoreThanZero
          ? () => (
              <IconButton
                className={clsx({
                  [classes.stepSelected]: isStepSelected,
                })}
                onClick={() => onChangePeriodoSelected(activeStep)}
                size="small"
                title="Selecionar o Período Programado"
              >
                <CheckCircleIcon fontSize="small" />
              </IconButton>
            )
          : undefined
      }
    >
      <SimpleCarousel<FeriasProgramadaProps>
        data={data}
        onChange={handleChangeCarousel}
        activeStep={activeStep}
        render={(
          {
            dtFimAbono,
            dtFimConcessao,
            dtInicioAbono,
            dtInicioConcessao,
            nrDiasAbono,
            nrDiasConcedidos,
          },
          index,
        ) => (
          <Grid container key={index}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Inicio das Férias"
                color="primary"
                value={dtInicioConcessao}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Fim das Férias"
                color="primary"
                value={dtFimConcessao}
                align="center"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Dias Férias"
                color="primary"
                value={nrDiasConcedidos}
                align="end"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue label="Inicio Abono" color="primary" value={dtInicioAbono} />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Fim Abono"
                color="primary"
                value={dtFimAbono}
                align="center"
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <SimpleLabelValue
                label="Dias Abono"
                color="primary"
                value={nrDiasAbono}
                align="end"
              />
            </Grid>
          </Grid>
        )}
      />
    </ContainerFerias>
  )
}
