export enum IndCardChartEnum {
  Keep,
  Increase,
  Decrease,
}

export const IndCardChartColor = {
  [IndCardChartEnum.Keep]: 'alerts.primary',
  [IndCardChartEnum.Increase]: 'alerts.success',
  [IndCardChartEnum.Decrease]: 'alerts.danger',
}

export const IndCardChartIcon = {
  [IndCardChartEnum.Keep]: 'trending_flat',
  [IndCardChartEnum.Increase]: 'trending_up',
  [IndCardChartEnum.Decrease]: 'trending_down',
}
