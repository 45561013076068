import { useEffect, useState } from 'react'

import { MenuSelect, StackContainer } from '~/components'

import Content from './Content'

import { useEstabelecimentoVinculosMenu, VinculoMenuItem } from '~/hooks/queries/useEstabelecimento'

import useAmbiente from '~/hooks/useAmbiente'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { Box, Grid, Typography } from '@material-ui/core'

export default function ConsultaFeriado() {
  const [indexSelected, setIndexSelected] = useState(-1)

  const { data: _d, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])
  const dataMenu = (_d && _d[0]?.data) || []

  const vinculo = dataMenu[indexSelected]

  const { anoMes, estabelecimento } = useAmbiente()

  useEffect(() => {
    setIndexSelected(-1)
  }, [estabelecimento, anoMes])

  return (
    <StackContainer p={0} orientation="horizontal" mt={-1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <Box height="100%" position="relative" minHeight={300} overflow="auto">
            <Box position="absolute" width="100%">
              <MenuSelect
                options={dataMenu}
                onSelect={(index: number) => setIndexSelected(index)}
                selectedOption={indexSelected}
                idField="vinculoId"
                searchField="vinculoNome"
                renderOption={(option: VinculoMenuItem) => `${option.vinculoNome}`}
                isLoading={isLoadingMenu}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          {vinculo ? (
            <Content vinculoId={vinculo.vinculoId} />
          ) : (
            <Typography>Selecione um Funcionário</Typography>
          )}
        </Grid>
      </Grid>
    </StackContainer>
  )
}
